import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, message, Space } from "antd";
import { useParams } from "react-router";
import { CustomSpin } from "../../general/CustomSpin";
import { ToolBar } from "../common/ToolBar";
import { insertItemInArray, updateItemInArray } from "../../../utils/utils";
import {
  DefaultColumnType,
  HeightWidthMapType,
} from "../../../utils/newTermsheet";
import {
  KeyValueType,
  UiDataType,
  WebFormConfigType,
  WebFormTemplateType,
} from "../../../utils/types";
import { dropLast, equals, isNil, last, path, sort } from "ramda";
import {
  createTemplate,
  editTemplateById,
  getEmptyTemplate,
} from "../../../services/services";
import { ResponseType } from "../../../utils/uiTypes";
import { UserContext } from "../../../context/UserContext";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import { TemplateTermsheetRows } from "./TemplateTermsheetRows";
import { SelectedCellType, SelectedItemType } from "../../../utils/termsheet";

export const MESSAGE_CONTENT = {
  saving: "Saving ...",
  creating: "Creating ...",
  saved: "Template has been saved successfully!",
  created: "Template has been created successfully!",
  saveError: "Unable to save the template",
  createError: "Unable to create the template",
};

const initialWebformTemplate = {
  name: "New Template",
  id: "",
  companyId: "",
  data: "",
};

export const TemplateTermsheet: FC<TemplateTermsheetType> = function ({
  create = false,
  onCreate = () => {},
}: TemplateTermsheetType) {
  const { user } = useContext(UserContext);
  const { templateId } = useParams<ParamsType>();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [undoItems, setUndoItems] = useState<Array<Array<WebFormConfigType>>>(
    []
  );
  const [redoItems, setRedoItems] = useState<Array<Array<WebFormConfigType>>>(
    []
  );
  const [selectedCell, setSelectedCell] = useState<SelectedCellType | null>(
    null
  );
  const [selectedItem, setSelectedItem] = useState<SelectedItemType | null>(
    null
  );
  const [webformConfigs, setWebformConfigs] = useState<
    Array<WebFormConfigType>
  >([]);
  const [webformTemplate, setWebformTemplate] = useState<WebFormTemplateType>(
    initialWebformTemplate
  );

  /** Update Cell Content **/
  const onUpdateRow: onUpdateRowType = (row, i) => {
    setUndoItems((items) =>
      insertItemInArray(items.length, items, webformConfigs)
    );
    setWebformConfigs((configs) => updateItemInArray(i, configs, row));
  };

  const parseTemplate = (data: WebFormTemplateType) => {
    setWebformTemplate(data);
    try {
      let configs: Array<WebFormConfigType> = JSON.parse(data.data);
      configs = sort(
        (a: WebFormConfigType, b: WebFormConfigType) =>
          parseInt(a.position) - parseInt(b.position),
        configs
      );
      setWebformConfigs(configs);
    } catch {
      message.error("Something went wrong! Please refresh or try again later!");
    }
  };

  const onStyleChange: onStyleChangeType = (style) => {
    if (webformConfigs && selectedCell) {
      const config = webformConfigs[selectedCell.rowIndex];
      if (selectedCell.column === DefaultColumnType.BID_METRICS) {
        onUpdateRow(
          { ...config, uiKey: { ...config.uiKey, style } },
          selectedCell.rowIndex
        );
      } else if (selectedCell.column === user?.companyId) {
        onUpdateRow(
          {
            ...config,
            dataMap: {
              ...config.dataMap,
              [user?.companyId]: {
                ...(path(
                  ["dataMap", parseInt(user?.companyId)],
                  config
                ) as UiDataType),
                style,
              },
            },
          },
          selectedCell.rowIndex
        );
      }
      setSelectedCell((cell) => cell && { ...cell, style });
    }
  };

  /** Undo and Redo functionality **/
  const undoOrRedo: undoOrRedoType = (
    removeArray,
    insertArray,
    insertCallback,
    dropCallback
  ) => {
    const item = last(removeArray);
    if (!isNil(item)) {
      insertCallback(
        insertItemInArray(insertArray.length, insertArray, webformConfigs)
      );
      setWebformConfigs(() => item);
      dropCallback(dropLast(1, removeArray));
    }
  };

  const onSave = () => {
    createTemplate({
      body: JSON.stringify({
        ...webformTemplate,
        id: create ? user?.companyId : templateId,
        data: JSON.stringify(webformConfigs),
      }),
    })
      .then(({ data }: ResponseType<WebFormTemplateType>) => {
        message.success(
          create
            ? "Template has been created successfully!"
            : "Template has been saved successfully!"
        );
        parseTemplate(data);
        if (create) {
          onCreate(data.id);
        }
      })
      .catch((error: string) => {
        console.error(error);
        message.error(
          "Something went wrong! Please refresh or try again later!"
        );
      })
      .then(() => {
        setLoading(false);
      });
  };

  const editTemplate = (id: string) => {
    editTemplateById({
      segments: {
        id: id,
      },
    })
      .then(({ data }: ResponseType) => {
        parseTemplate(data);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(
          "Something went wrong! Please refresh or try again later!"
        );
      })
      .then(() => {
        setLoading(false);
      });
  };

  const getBlankTemplate = () => {
    getEmptyTemplate({
      segments: {
        id: user?.companyId,
      },
    })
      .then(({ data = [] }: ResponseType<Array<WebFormConfigType>>) => {
        setWebformConfigs(data);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(
          "Something went wrong! Please refresh or try again later!"
        );
      })
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    create ? getBlankTemplate() : editTemplate(templateId);
  }, [create, templateId]);

  return (
    <div className={"relative max-h-full h-full w-full bg-gray-100"}>
      <CustomSpin loading={loading} loadingText={"Loading Template ..."} />
      <div className={"h-full max-h-full w-full overflow-y-hidden pb-[45px]"}>
        <div className={"absolute sticky top-0 z-[100]"}>
          <ToolBar
            name={webformTemplate.name}
            selectedStyle={selectedCell?.style}
            showStyleChangeButtons={true}
            onStyleChange={onStyleChange}
            onNameChange={(name) => {
              setWebformTemplate((template) => ({ ...template, name }));
            }}
            onButtonClick={(action) => {
              switch (action) {
                case "UNDO":
                  return undoOrRedo(
                    undoItems,
                    redoItems,
                    setRedoItems,
                    setUndoItems
                  );
                case "REDO":
                  return undoOrRedo(
                    redoItems,
                    undoItems,
                    setUndoItems,
                    setRedoItems
                  );
              }
            }}
          >
            <Space className={"gap-2"}>
              <Button
                disabled={
                  saving || equals(webformTemplate, initialWebformTemplate)
                }
                loading={saving}
                type={"primary"}
                className={PRIMARY_BUTTON_STYLE}
                onClick={() => {
                  setLoading(true);
                  onSave();
                }}
              >
                {saving ? MESSAGE_CONTENT.saving : "Save"}
              </Button>
            </Space>
          </ToolBar>
        </div>
        {user && user.companyId && !loading && webformConfigs && (
          <div className={"max-h-full h-full w-full"}>
            <div
              className={
                "relative max-h-full w-full overflow-x-scroll overscroll-none scroll-smooth"
              }
            >
              <TemplateTermsheetRows
                elementId={user?.companyId}
                configs={webformConfigs}
                selectedItem={selectedItem}
                onSelectItem={setSelectedItem}
                onWebFormConfigUpdate={(configs) => {
                  setUndoItems((items) =>
                    insertItemInArray(items.length, items, webformConfigs)
                  );
                  setWebformConfigs(configs);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

type ParamsType = {
  templateId: string;
};

type TemplateTermsheetType = {
  create?: boolean;
  onCreate?: (o: string) => void;
};

type onStyleChangeType = (style: KeyValueType) => void;
type onUpdateRowType = (row: WebFormConfigType, index: number) => void;
type undoOrRedoType = (
  removeArray: Array<Array<WebFormConfigType>>,
  insertArray: Array<Array<WebFormConfigType>>,
  insertCallback: Dispatch<SetStateAction<Array<Array<WebFormConfigType>>>>,
  dropCallback: Dispatch<SetStateAction<Array<Array<WebFormConfigType>>>>
) => void;
