import React, { FC } from "react";
import { message, Modal } from "antd";
import { CodeVerification } from "../2fa/CodeVerification";
import { verifyPhoneCode } from "../../services/services";
import { ResponseType, voidType } from "../../utils/uiTypes";
import { Verify2FaPhoneCodeType } from "../../utils/types";

export const VerificationSettings: FC<verificationSettingsType> = function ({
  visible,
  onClose,
  onVerify,
  phoneNumber,
}: verificationSettingsType) {
  const verifyCode: verifyCodeType = (code) => {
    verifyPhoneCode({
      body: JSON.stringify({
        verificationCode: code,
      }),
    })
      .then(({ data }: ResponseType<Verify2FaPhoneCodeType>) => {
        if (data.verified) {
          onClose(false);
          onVerify();
        } else {
          message.error("Your code is incorrect");
        }
      })
      .catch(() => {
        message.error("Please match the requested format");
      });
  };

  return (
    <Modal
      title={"Verification"}
      open={visible}
      onCancel={(): void => {
        onClose(false);
      }}
      destroyOnClose
      footer={null}
    >
      <div className={"px-5 mx-auto"}>
        <CodeVerification onSubmit={verifyCode} phoneNumber={phoneNumber} />
      </div>
    </Modal>
  );
};

type verificationSettingsType = {
  visible: boolean;
  onClose: (status: boolean) => void;
  onVerify: voidType;
  phoneNumber?: string;
};

type verifyCodeType = (code: string) => void;
