import React, {
  FC,
  HTMLAttributes,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  FileElementType,
  FileType,
  LenderDownLoadInfoType,
  LenderType,
  TransactionType,
  UserType,
} from "../../../../utils/types";
import {
  Avatar,
  Breadcrumb,
  Button,
  Checkbox,
  Input,
  message,
  Popover,
  Table,
  Tooltip,
} from "antd";
import { convertDateToFormat } from "../../../../utils/moment";
import {
  fetchWithIdType,
  FileResponseType,
  LoadingType,
  ResponseType,
} from "../../../../utils/uiTypes";
import {
  addFile,
  addFolder,
  deleteFile,
  downloadAccessReport,
  downloadFolder,
  getDataRoom,
  getLenderDownloadInfo,
  moveFilesToNewLocation,
  renameFileOrFolder,
  searchFilesAndFolders,
  shareFile,
  toggleWatermark,
} from "../../../../services/services";
import { CustomAvatar } from "../../../general/CustomAvatar";
import { assocPath, equals, flatten, has, path, prop, union } from "ramda";
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
import {
  populateArrayWithPropertyPath,
  removeItemFromArray,
  updateItemInArray,
  valOrDefault,
} from "../../../../utils/utils";
import { NewFolderForm } from "../../../forms/NewFolderForm";
import { EllipsisOutlined, HomeFilled } from "@ant-design/icons";
import {
  ElementType,
  PermissionType,
  PETransactionCreationType,
} from "../../../../utils/enums";
import {
  ConfirmDelete,
  ConfirmDownload,
} from "../../../../utils/confirmationModals";
import { usePageTitle } from "../../../../customHooks/usePageTitle";
import { ShareOptions } from "./ShareOptions";
import { TransactionContext } from "../../../../context/TransactionContext";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ACTION_BUTTON_CSS } from "../../../../utils/cssConfigs";
import { tableColumnHeader } from "../../../../utils/componentUtils";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { FileOrFolderRenameForm } from "../../../forms/FileOrFolderRenameForm";
import { ColumnsType } from "antd/es/table";
import { uploadMultipleFiles } from "../../../../utils/file";
import { FilePicker } from "../../../general/FilePicker";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import {
  DataRoomFilesNavigationType,
  dataRoomLocationType,
  DEFAULT_DATAROOM_LOCATION,
  parseLocationForElement,
} from "../../../../utils/dataroom";
import { FileName } from "./FileName";

const getURLElementId = (search: string) =>
  new URLSearchParams(search)?.get("elementId");

const isItemStagedToMove = (
  file: FileElementType,
  moveFiles: FileElementType[]
): boolean =>
  moveFiles.length > 0 &&
  moveFiles.map((file) => file.elementId).includes(file.elementId);

const getParentElementId = (
  location: dataRoomLocationType,
  allData: FileElementType[]
): string | null => {
  const file = path(location.slice(0, -1), allData) as FileElementType;
  return file.elementId !== "0" ? file.elementId : null;
};

export const DataRoom: FC = function () {
  usePageTitle("DataRoom");

  const history = useHistory();
  const { search } = useLocation();

  const { transaction, belongToDealTeam, permissions } =
    useContext(TransactionContext);

  const [loading, setLoading] = useState<LoadingType>({
    loading: false,
    label: "",
  });
  const [files, setFiles] = useState<FileElementType[]>([]);
  const [lenderData, setLendersData] = useState<Record<string, LenderType>>({});
  const [{ location, currentPath, currentLocationStructure }, setNavigation] =
    useState<DataRoomFilesNavigationType>({
      location: [],
      currentLocationStructure: null,
      currentPath: [],
    });
  const [lenderDownloadInfo, setLenderDownloadInfo] =
    useState<LenderDownLoadInfoType>({});
  const [areResultsFiltered, setAreResultsFiltered] = useState<boolean>(false);
  const [moveFiles, setMoveFiles] = useState<FileElementType[]>([]);
  const { height: windowHeight } = useWindowDimensions();
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const initiateToggleWatermark: initiateToggleWatermarkType = (e) => {
    toggleWatermark({
      segments: {
        id: transaction?.peTransactionId,
        status: e.target.checked,
      },
    }).catch((error: string) => {
      message.error(valOrDefault("Error", error));
    });
  };

  const addFilesToData: addFilesToDataType = (
    petransactionId,
    files,
    location,
    parentFileElement
  ) => {
    setLoading({
      loading: true,
      label: `Adding ${files.length} Files to Folder...`,
    });
    message.loading({
      key: "Add Multiple Files",
      content: `Adding ${files.length} Files to Folder`,
      duration: 0,
    });
    setLoading({ loading: false });
    Promise.all(
      files.map((response) =>
        addNewFileToData(petransactionId, response, location, parentFileElement)
      )
    )
      .then(() => {
        setLoading({ loading: false });
        message.success({
          key: "Add Multiple Files",
          content: `Successfully Added ${files.length} Files to Folder`,
        });
      })
      .catch((error: string) => {
        setLoading({ loading: false });
        message.error({
          key: "Add Multiple Files",
          content: `Error while adding some files to the Folder`,
        });
        message.error(error);
      });
  };

  const addNewFileToData: addNewFileToDataType = (
    petransactionId,
    file,
    location,
    parentFileElement
  ) => {
    return new Promise((resolve, reject) => {
      addFile({
        headers: {
          Accept: ["application/json", "text/plain", "*/*"],
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          fileDTO: file,
          fileElementType: ElementType.PETRANSACTION,
          fileURL: file.fileURL,
          isFolder: false,
          name: file.fileName,
          parentFileElement,
          petransactionId,
        }),
      })
        .then(({ data }: ResponseType<FileElementType>) => {
          setFiles((files) =>
            assocPath(
              location,
              [
                ...(valOrDefault(
                  [],
                  path(location, files)
                ) as Array<FileElementType>),
                { ...data, fileId: data?.fileDTO?.fileId },
              ],
              files
            )
          );
        })
        .then(() => resolve())
        .catch((error: string) => {
          reject(
            valOrDefault(
              `Error uploading file "${file.fileName}" to your location`,
              error
            )
          );
        });
    });
  };

  const deleteFileFromData: deleteFileFromData = (
    transactionId,
    fileId,
    index,
    location
  ) => {
    deleteFile({
      segments: {
        transactionId,
        fileId,
      },
      params: {
        peTransactionCreationType: transaction?.peTransactionCreationType,
      },
    })
      .then(() => {
        setFiles((files) =>
          assocPath(
            location,
            [
              ...valOrDefault(
                [],
                removeItemFromArray(
                  index,
                  valOrDefault(
                    [],
                    path(location, files) as Array<FileElementType>
                  )
                )
              ),
            ],
            files
          )
        );
      })
      .catch((error: string) => {
        message.error(valOrDefault("Error deleting file from server", error));
      });
  };

  const addFolderToFileStructure: addFolderToFileStructureType = (
    name,
    petransactionId,
    location,
    currentLocationStructure
  ) => {
    setLoading({ loading: true, label: "Creating new folder..." });
    addFolder({
      body: JSON.stringify({
        fileElementType: ElementType.PETRANSACTION,
        isFolder: true,
        name,
        petransactionId,
        parentFileElement:
          location.length > 2 && currentLocationStructure
            ? { elementId: currentLocationStructure?.elementId }
            : undefined,
      }),
    })
      .then(({ data }: ResponseType<FileElementType>) => {
        setFiles((files) =>
          assocPath(
            location,
            [
              ...(valOrDefault(
                [],
                path(location, files)
              ) as Array<FileElementType>),
              data,
            ],
            files
          )
        );
      })
      .then(() => {
        setLoading({ loading: false });
      })
      .catch((error: string) => {
        setLoading({ loading: false });
        message.error(valOrDefault("Error uploading folder to server", error));
      });
  };

  const rename: renameType = (
    name,
    fileData,
    index,
    petransactionId,
    location
  ) => {
    renameFileOrFolder({
      body: JSON.stringify({
        name,
        elementId: fileData.elementId,
        petransactionId,
      }),
      params: {
        peTransactionCreationType: transaction?.peTransactionCreationType,
      },
    })
      .then(() => {
        const currentData: Array<FileElementType> = path(
          location,
          files
        ) as Array<FileElementType>;
        setFiles((files) =>
          assocPath(
            location,
            updateItemInArray(index, currentData, { ...fileData, name }),
            files
          )
        );
      })
      .catch((error: string) => {
        message.error(valOrDefault("Error renaming the file", error));
      });
  };

  const getAddLenderFilePermissionDTO: getAddLenderFilePermissionDTOType = (
    shareList,
    petransactionId,
    lenderData
  ) => {
    return shareList
      ?.filter(
        (id) =>
          has(id, lenderData) && prop(id, lenderData)?.userDTOs?.length > 0
      )
      .reduce<ShareType[]>((acc, id) => {
        return [
          ...acc,
          ...((path([id], lenderData) as LenderType)?.userDTOs ?? []).map(
            ({ userId }): ShareType => ({
              userId,
              name: lenderData[id]?.name,
              elementId: id,
              petransactionId,
            })
          ),
        ];
      }, []);
  };

  const getElementIdsForAllChildElements = (
    file: FileElementType
  ): string[] => {
    if (file?.isFolder) {
      return flatten(
        union(
          populateArrayWithPropertyPath(["elementId"], file?.childFileElements),
          file?.childFileElements?.map((file) =>
            getElementIdsForAllChildElements(file)
          )
        )
      );
    } else return [];
  };

  const parseShareFileData: parseShareFileDataType = (
    fileData,
    index,
    newShareList,
    petransactionId,
    location,
    lenderData,
    sharedWith
  ) => {
    return {
      addLenderFilePermissionDTOs: getAddLenderFilePermissionDTO(
        newShareList,
        petransactionId,
        lenderData
      ),
      removeLenderFilePermission: {
        elementIds: getElementIdsForAllChildElements(fileData),
        lenderIds: valOrDefault(
          [],
          sharedWith?.filter(
            (id) => has(id, lenderData) && !newShareList?.includes(id)
          )
        ),
      },
    };
  };

  const updateFileElementsWithSharePermissions = (
    file: FileElementType,
    shareData: string[]
  ): FileElementType => {
    if (file?.isFolder) {
      return {
        ...file,
        childFileElements: file?.childFileElements?.map((file) =>
          updateFileElementsWithSharePermissions(file, shareData)
        ),
        rootSharedWith: union(shareData, valOrDefault([], file.sharedWith)),
      };
    } else {
      return { ...file, rootSharedWith: shareData };
    }
  };

  const initiateShareFile: initiateShareFileType = (
    fileData,
    index,
    newShareList,
    petransactionId,
    location,
    sharedWith
  ) => {
    message.loading({
      content: "Sharing...",
      key: "shareFile-" + index,
      duration: 0,
    });
    shareFile({
      segments: {
        elementId: fileData?.elementId,
      },
      params: {
        peTransactionId: petransactionId,
      },
      body: JSON.stringify(
        parseShareFileData(
          fileData,
          index,
          newShareList,
          petransactionId,
          location,
          lenderData,
          sharedWith
        )
      ),
    })
      .then(({ data }: ResponseType<FileElementType>) => {
        setFiles((files) =>
          assocPath(
            location,
            [
              ...updateItemInArray(
                index,
                valOrDefault(
                  [],
                  path(location, files)
                ) as Array<FileElementType>,
                {
                  ...fileData,
                  childFileElements: fileData?.childFileElements?.map((file) =>
                    updateFileElementsWithSharePermissions(
                      file,
                      valOrDefault([], data?.sharedWith)
                    )
                  ),
                  sharedWith: data?.sharedWith,
                }
              ),
            ],
            files
          )
        );
      })
      .then(() => {
        message.success({
          content: "Shared Successfully!",
          key: "shareFile-" + index,
          duration: 2,
        });
      })
      .catch((error: string) => {
        message.error({
          content: valOrDefault("Server Error", error),
          key: "shareFile-" + index,
          duration: 3,
        });
      });
  };

  const navigateIntoSpecificFolder = (
    data: FileElementType[],
    folderId: string | null
  ) => {
    if (folderId) {
      setNavigation(parseLocationForElement(data, folderId));
    } else {
      setNavigation(DEFAULT_DATAROOM_LOCATION(data));
    }
  };

  const fetchDataRoom: fetchDataRoomType = (id) => {
    getDataRoom({
      segments: {
        id,
        type: "getFoldersForPETransaction",
      },
    })
      .then(({ data = [] }: ResponseType<FileElementType[]>) => {
        setFiles(data);
        navigateIntoSpecificFolder(data, getURLElementId(search));
        setAreResultsFiltered(false);
      })
      .then(() => {
        setLoading({ loading: false });
      })
      .catch((error: string) => {
        message.error(valOrDefault("Error fetching files and folders", error));
        setLoading({ loading: false });
      });
  };

  const searchFilesInDataRoom: fetchFileStructureType = (id, search) => {
    searchFilesAndFolders({
      segments: {
        id,
      },
      params: {
        search,
      },
    })
      .then(({ data = [] }: ResponseType<FileElementType[]>) => {
        setFiles(data);
        setNavigation({
          location: [0, "childFileElements"],
          currentLocationStructure: path([0], data) as FileElementType,
          currentPath: ["Home"],
        });
        setAreResultsFiltered(true);
      })
      .then(() => {
        setLoading({ loading: false });
      })
      .catch(() => {
        message.error("Error Searching File or Folder");
      });
  };

  const fetchFileStructure: fetchFileStructureType = (
    transactionId,
    search
  ) => {
    setLoading({ loading: true, label: "Loading Files..." });
    setAreResultsFiltered(false);

    search
      ? searchFilesInDataRoom(transactionId, search)
      : fetchDataRoom(transactionId);
  };

  const initiateFolderDownload: initiateFileDownloadType = (id, elementId) => {
    message.loading({
      content: "Processing Folder",
      duration: 0,
      key: "download-" + elementId,
    });
    downloadFolder({
      segments: {
        elementId,
        id,
      },
    })
      .then(() => {
        message.info({
          content: "Folder Link sent successfully sent to your email.",
          key: "download-" + elementId,
        });
      })
      .catch((error: string) => {
        message.error({
          content: valOrDefault("Error Downloading Folder!", error),
          key: "download-" + elementId,
        });
      });
  };

  const initiateDownloadAccessReport: initiateFileDownloadType = (
    transactionId,
    elementId
  ) => {
    message.loading({
      content: "Processing Access Report",
      duration: 0,
      key: "access-report-" + elementId,
    });
    downloadAccessReport({
      segments: {
        elementId,
        transactionId,
      },
    })
      .then(({ url, filename }: FileResponseType) => {
        message.info({
          content: "Access Report Ready to Download",
          key: "access-report-" + elementId,
        });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        message.error({
          content: valOrDefault("Error Downloading Report!", error),
          key: "access-report-" + elementId,
        });
      });
  };

  const fetchLendersData: fetchLendersDataType = (transaction) => {
    transaction &&
      setLendersData(
        transaction?.lenderDTOs?.reduce(
          (prev, curr) => ({ ...prev, [curr.id]: curr }),
          {}
        )
      );
  };

  const fetchLenderDownloadInfo: fetchWithIdType = (transactionId) => {
    getLenderDownloadInfo({
      segments: {
        transactionId,
      },
    }).then(({ data = {} }: ResponseType<LenderDownLoadInfoType>) => {
      setLenderDownloadInfo(data);
    });
  };

  const navigateIntoFolder: navigateIntoFolderType = (
    file,
    index,
    location
  ): void => {
    history.push(`/transactions/${transaction?.peTransactionId}/dataroom`);
    if (file.isFolder) {
      setNavigation(({ currentPath }) => ({
        location: [...location, index, "childFileElements"],
        currentLocationStructure: path(
          [...location, index],
          files
        ) as FileElementType,
        currentPath: [...currentPath, file?.name],
      }));
    }
  };

  const columns: ColumnsType<FileElementType> = [
    {
      title: tableColumnHeader("Name"),
      align: "left" as const,
      key: "name",
      dataIndex: "name",
      width: "30%",
      className: "group-hover:bg-blue-50 min-w-[85px] max-w-[300px]",
      render: function display(
        name: string,
        fileData: FileElementType
      ): ReactNode {
        return (
          <FileName name={name} fileData={fileData} transaction={transaction} />
        );
      },
    },
    {
      title: tableColumnHeader("Shared With"),
      align: "left" as const,
      key: "sharedWith",
      dataIndex: "sharedWith",
      width: "25%",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: function sharedWith(
        data: string[],
        fileData: FileElementType
      ): ReactNode {
        const sharedIds: string[] = (
          union(fileData?.sharedWith ?? [], fileData?.rootSharedWith ?? []) ??
          []
        )
          ?.filter((val) => val !== transaction?.peTransactionId)
          ?.sort((a, b): number =>
            lenderData[a].name.toLowerCase() > lenderData[b].name.toLowerCase()
              ? 1
              : -1
          );
        return transaction?.peTransactionCreationType !==
          PETransactionCreationType.OFFLINE ? (
          <div
            className={"flex flex-row items-center min-w-[20px]"}
            onClick={(e): void => e.stopPropagation()}
          >
            {sharedIds
              ?.slice(0, 4)
              ?.filter((id) => has(id, lenderData))
              .map((id) => (
                <CustomAvatar
                  key={id}
                  data={lenderData[id].name[0]}
                  color={lenderData[id].name[0]}
                  size={"small"}
                  label={lenderData[id].name}
                />
              ))}
            {sharedIds.length > 0 && (
              <Popover
                title={`Sharing with ${sharedIds.length} Institutions`}
                placement={"rightTop"}
                content={
                  <div
                    className={"flex flex-col gap-y-2 max-h-60 overflow-y-auto"}
                  >
                    {sharedIds
                      ?.filter((id) => has(id, lenderData))
                      .map((id) => (
                        <div
                          key={id}
                          className={"flex flex-row gap-x-2 items-center"}
                        >
                          <CustomAvatar
                            key={id}
                            data={lenderData[id].name[0]}
                            color={lenderData[id].name[0]}
                            size={"small"}
                          />
                          {lenderData[id].name}
                          {path(
                            [fileData.elementId, id],
                            lenderDownloadInfo
                          ) !== undefined && (
                            <i className="fas fa-check text-green-500" />
                          )}
                        </div>
                      ))}
                  </div>
                }
              >
                <Avatar className={"ml-1"} size={"small"}>
                  <EllipsisOutlined />
                </Avatar>
              </Popover>
            )}
          </div>
        ) : (
          <div />
        );
      },
    },
    {
      title: tableColumnHeader("Last Edited"),
      align: "left" as const,
      key: "modifiedDate",
      dataIndex: "modifiedDate",
      width: "10%",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: (data: string): ReactNode =>
        convertDateToFormat(data, "D.MMM.YY", false),
    },
    {
      title: tableColumnHeader("Created By"),
      align: "left" as const,
      key: "createdByUserDTO",
      dataIndex: "createdByUserDTO",
      width: "15%",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: (data: UserType): ReactNode =>
        data && data.firstName + " " + data.lastName,
    },
    {
      title: tableColumnHeader("Actions"),
      width: "20%",
      dataIndex: "elementId",
      key: "elementId",
      align: "right" as const,
      className: "group-hover:bg-blue-50",
      render: function actions(
        fileId: string,
        fileData: FileElementType,
        index: number
      ): ReactNode {
        return (
          !isItemStagedToMove(fileData, moveFiles) &&
          transaction && (
            <div
              className={"relative flex flex-row items-center"}
              onClick={(e): void => e.stopPropagation()}
            >
              <Button
                type={"text"}
                icon={<i className="fas fa-ellipsis-h" />}
                className={
                  "absolute mx-1 right-0 border-0 px-2 text-gray-900 opacity-50 group-hover:opacity-0"
                }
              />
              <div
                className={
                  "shrink flex flex-row gap-x-2 items-center opacity-0 group-hover:opacity-100 ml-auto"
                }
              >
                {((belongToDealTeam &&
                  permissions.includes(PermissionType.ADMIN_PETRANSACTION)) ||
                  transaction?.peTransactionCreationType ===
                    PETransactionCreationType.OFFLINE) && (
                  <>
                    {/*<Tooltip*/}
                    {/*  title={fileData?.isFolder ? "Move Folder" : "Move File"}*/}
                    {/*>*/}
                    {/*  <Button*/}
                    {/*    className={*/}
                    {/*      "border-0 shadow bg-white text-gray-500 hover:border-primary hover:text-primary hover:border"*/}
                    {/*    }*/}
                    {/*    onClick={(): void => {*/}
                    {/*      setMoveFiles([...moveFiles, fileData]);*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    Move*/}
                    {/*  </Button>*/}
                    {/*</Tooltip>*/}
                    {transaction?.peTransactionCreationType !==
                      PETransactionCreationType.OFFLINE && (
                      <ShareOptions
                        lenders={valOrDefault(
                          [],
                          transaction?.lenderDTOs.filter(
                            (item) => item.userDTOs?.length
                          )
                        )}
                        shareWith={valOrDefault([], fileData?.sharedWith)}
                        isFolder={fileData?.isFolder}
                        onSubmit={(values): void => {
                          initiateShareFile(
                            fileData,
                            index,
                            values,
                            valOrDefault("", transaction?.peTransactionId),
                            location,
                            fileData.sharedWith
                          );
                        }}
                      />
                    )}
                    <Tooltip title={"Delete"}>
                      <Button
                        className={`${ACTION_BUTTON_CSS} hover:text-red-500 opacity-0 group-hover:opacity-100`}
                        icon={<i className="fas fa-trash-alt" />}
                        onClick={(): void => {
                          ConfirmDelete(
                            `Confirm Delete ${
                              fileData.isFolder ? "Folder" : "File"
                            }?`,
                            () => {
                              deleteFileFromData(
                                valOrDefault("", transaction?.peTransactionId),
                                fileId,
                                index,
                                location
                              );
                            },
                            `${fileData.isFolder ? "Folder" : "File"} Name: ${
                              fileData.name
                            }`
                          );
                        }}
                      />
                    </Tooltip>
                  </>
                )}
                {(belongToDealTeam ||
                  transaction?.peTransactionCreationType ===
                    PETransactionCreationType.OFFLINE) && (
                  <FileOrFolderRenameForm
                    isFolder={valOrDefault(false, fileData.isFolder)}
                    formData={{ name: fileData.name }}
                    onSubmit={({ name }): void => {
                      rename(
                        name,
                        fileData,
                        index,
                        valOrDefault("", transaction?.peTransactionId),
                        location
                      );
                    }}
                  />
                )}
                <Tooltip
                  title={
                    fileData.isFolder ? "Download Folder" : "Download File"
                  }
                >
                  {fileData.isFolder ? (
                    <Button
                      className={`${ACTION_BUTTON_CSS} opacity-0 group-hover:opacity-100 hover:text-green-500`}
                      icon={<i className="fas fa-download" />}
                      onClick={(): void => {
                        initiateFolderDownload(
                          transaction.peTransactionId,
                          fileData.elementId
                        );
                      }}
                    />
                  ) : (
                    <Button
                      className={`${ACTION_BUTTON_CSS} opacity-0 group-hover:opacity-100 hover:text-green-500`}
                      icon={<i className="fas fa-download" />}
                      rel={"noreferrer"}
                      href={`/api/rest/media/${
                        fileData.fileId ?? fileData.fileDTO?.fileId
                      }?transactionId=${transaction?.peTransactionId ?? ""}`}
                      target={"_blank"}
                    />
                  )}
                </Tooltip>
                {(valOrDefault([], fileData?.sharedWith) as string[]).filter(
                  (val) => val !== transaction?.peTransactionId
                ).length > 0 &&
                  permissions.includes(PermissionType.ADMIN_PETRANSACTION) &&
                  transaction?.peTransactionCreationType !==
                    PETransactionCreationType.OFFLINE && (
                    <Tooltip title={"Access Report"}>
                      <Button
                        className={`${ACTION_BUTTON_CSS} opacity-0 group-hover:opacity-100 hover:text-amber-500`}
                        icon={<i className="fas fa-file-pdf" />}
                        onClick={(): void => {
                          initiateDownloadAccessReport(
                            transaction.peTransactionId,
                            fileData.elementId
                          );
                        }}
                      />
                    </Tooltip>
                  )}
              </div>
            </div>
          )
        );
      },
    },
  ];

  const onFailure: VoidFunction = () => {
    setLoading({ loading: false, label: "" });
    message.error("Unable to upload file");
  };

  const onSuccess: onSuccessType =
    (transactionId, location, parentFileElement) => (files) => {
      setLoading({ loading: false, label: "" });
      addFilesToData(
        transaction?.peTransactionId ?? "",
        files ?? [],
        location,
        parentFileElement
      );
    };

  const onFileUpload: onFileUploadType =
    (transactionId, location, parentFileElement) =>
    (files = []) => {
      if (files && files?.length > 0) {
        setLoading({ loading: true, label: "Uploading Files" });
        uploadMultipleFiles({
          onSuccess: onSuccess(transactionId, location, parentFileElement),
          onFailure,
          files: files,
          mediaURL: "/api/rest/media/uploadFile/1",
        });
      }
    };

  const backtrackPath: backtrackPathType = (name, index) => {
    history.push(`/transactions/${transaction?.peTransactionId}/dataroom`);
    setNavigation(({ location, currentPath }) => ({
      location: location.slice(0, (index + 1) * 2),
      currentLocationStructure: path(
        [...location.slice(0, (index + 1) * 2 - 1)],
        files
      ) as FileElementType,
      currentPath: currentPath.slice(0, index + 1),
    }));
  };

  const submitMoveFilesToNewLocation: submitMoveFilesToNewLocationType = (
    files,
    allData
  ) => {
    setLoading({ loading: true, label: "Moving Files" });
    moveFilesToNewLocation({
      body: JSON.stringify({
        elementIds: files.map((file) => file.elementId),
        parentElementId: getParentElementId(location, allData),
        transactionOrPortfolioId: transaction?.peTransactionId,
      }),
    })
      .then(({ data = [] }: ResponseType<FileElementType[]>) => {
        setMoveFiles([]);
        setFiles(data);
        setSearchValue(null);
        setNavigation(DEFAULT_DATAROOM_LOCATION(data));
        setAreResultsFiltered(false);
        setLoading({ loading: false });
      })
      .catch(() => {
        setLoading({ loading: false });
        message.error("Unable to move data");
      });
  };

  useEffect(() => {
    if (transaction) {
      fetchFileStructure(transaction.peTransactionId);
      fetchLendersData(transaction);
      fetchLenderDownloadInfo(transaction.peTransactionId);
    }
  }, [transaction]);

  return (
    <div className={"w-full"}>
      <div className={"p-6 h-full"}>
        <div className={"flex flex-col lg:flex-row items-start gap-3"}>
          {transaction && (
            <div className={"flex flex-col w-full lg:w-5/12"}>
              <Input.Search
                placeholder={"Search File/Folder by Name"}
                className={"w-full"}
                allowClear={true}
                value={searchValue || ""}
                onSearch={(value): void => {
                  setSearchValue(value);
                  fetchFileStructure(transaction.peTransactionId, value);
                }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          )}

          {transaction &&
            (belongToDealTeam ||
              transaction?.peTransactionCreationType ===
                PETransactionCreationType.OFFLINE) && (
              <div
                className={
                  "mx-auto lg:mr-0 flex flex-col sm:flex-row items-center gap-2"
                }
              >
                {transaction &&
                  transaction.peTransactionCreationType !==
                    PETransactionCreationType.OFFLINE && (
                    <Checkbox
                      defaultChecked={transaction.watermark}
                      className={
                        "font-medium text-gray-500 select-none whitespace-nowrap"
                      }
                      onChange={(e): void =>
                        initiateToggleWatermark(e, transaction.peTransactionId)
                      }
                      disabled={
                        !(
                          permissions.includes(
                            PermissionType.ADMIN_PETRANSACTION
                          ) ||
                          permissions.includes(
                            PermissionType.NON_ADMIN_PETRANSACTION
                          )
                        )
                      }
                    >
                      WATERMARK PDF?
                    </Checkbox>
                  )}
                <NewFolderForm
                  onSubmit={({ name }): void => {
                    addFolderToFileStructure(
                      name,
                      transaction?.peTransactionId,
                      location,
                      currentLocationStructure
                    );
                  }}
                />
                <FilePicker
                  disabled={loading.loading}
                  max={15}
                  sizeLimit={600000000}
                  multiple={true}
                  onChange={onFileUpload(
                    transaction?.peTransactionId,
                    location,
                    location.length > 2 && currentLocationStructure
                      ? { elementId: currentLocationStructure?.elementId }
                      : undefined
                  )}
                >
                  <Button
                    className={
                      "text-white w-full bg-primary hover:bg-hover border-0"
                    }
                    icon={<i className="fas fa-upload mr-2" />}
                  >
                    Upload Files (Max 15)
                  </Button>
                </FilePicker>
              </div>
            )}
        </div>
        <div className={"p-2 flex flex-row items-center gap-x-3"}>
          <HomeFilled
            className={"text-blue-500 cursor-pointer hover:shadow"}
            onClick={(): void => {
              history.push(
                `/transactions/${transaction?.peTransactionId}/dataroom`
              );
              setNavigation(DEFAULT_DATAROOM_LOCATION(files));
            }}
          />
          <Breadcrumb className={"text-lg flex flex-wrap"}>
            {currentPath?.map((name, index) => (
              <BreadcrumbItem
                key={`${name}-${index}`}
                className={`${
                  index === currentPath?.length - 1
                    ? "text-blue-500 cursor-default"
                    : "cursor-pointer"
                }`}
                onClick={() => backtrackPath(name, index)}
              >
                {name}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>
        {/*{moveFiles.length > 0 && (*/}
        {/*  <MoveFile*/}
        {/*    loading={loading.loading}*/}
        {/*    files={moveFiles}*/}
        {/*    updateFiles={setMoveFiles}*/}
        {/*    transaction={transaction}*/}
        {/*    onMoveFiles={() => {*/}
        {/*      submitMoveFilesToNewLocation(moveFiles, files);*/}
        {/*    }}*/}
        {/*    currentPath={currentPath[currentPath.length - 1] || "Home"}*/}
        {/*  />*/}
        {/*)}*/}
        {areResultsFiltered && (
          <div
            className={
              "flex w-full bg-emerald-500 p-1 text-xs text-white border border-b-0 border-gray-200"
            }
          >
            Showing Filtered Results...
          </div>
        )}

        <Table
          loading={loading.loading}
          locale={{
            emptyText: (
              <span className={"select-none"}>
                No files have been shared yet!
              </span>
            ),
          }}
          className={"border"}
          dataSource={path(location, files)}
          columns={
            (permissions.includes(PermissionType.ADMIN_PETRANSACTION) ||
              permissions.includes(PermissionType.NON_ADMIN_PETRANSACTION)) &&
            transaction?.peTransactionCreationType !==
              PETransactionCreationType.OFFLINE
              ? columns
              : columns.filter(
                  ({ key }) => key !== "modifiedDate" && key !== "sharedWith"
                )
          }
          pagination={false}
          rowKey={"elementId"}
          rowClassName={(fileData): string => {
            return `${
              !isItemStagedToMove(fileData, moveFiles)
                ? "group editable-row hover:bg-blue-50 " +
                  (fileData.isFolder ? "cursor-pointer" : "cursor-default")
                : "opacity-60 cursor-not-allowed"
            } ${
              equals(fileData.elementId, getURLElementId(search))
                ? "shadow shadow-amber-400 border-1 border-amber-600 bg-amber-100"
                : ""
            }`;
          }}
          scroll={{ x: true, y: windowHeight - 320 }}
          onRow={(fileData, index): HTMLAttributes<HTMLElement> => ({
            onClick: (): void => {
              !isItemStagedToMove(fileData, moveFiles) &&
                navigateIntoFolder(fileData, valOrDefault(0, index), location);
            },
          })}
        />
      </div>
    </div>
  );
};

type fetchLendersDataType = (transaction: TransactionType) => void;
type fetchDataRoomType = (id: string) => void;
type fetchFileStructureType = (transactionId: string, search?: string) => void;
type initiateShareFileType = (
  fileData: FileElementType,
  index: number,
  newShareList: string[],
  petransactionId: string,
  location: dataRoomLocationType,
  sharedWith?: string[]
) => void;
type renameType = (
  name: string,
  fileData: FileElementType,
  index: number,
  petransactionId: string,
  location: dataRoomLocationType
) => void;
type initiateFileDownloadType = (transactionId: string, fileId: string) => void;
type addFolderToFileStructureType = (
  name: string,
  petransactionId: string,
  location: dataRoomLocationType,
  currentLocationStructure: FileElementType | null
) => void;
type deleteFileFromData = (
  transactionId: string,
  fileId: string,
  index: number,
  location: dataRoomLocationType
) => void;
type addNewFileToDataType = (
  petransactionId: string,
  file: FileType,
  location: dataRoomLocationType,
  parentFileElement?: { elementId: string }
) => Promise<void>;
type addFilesToDataType = (
  petransactionId: string,
  file: FileType[],
  location: dataRoomLocationType,
  parentFileElement?: { elementId: string }
) => void;
type navigateIntoFolderType = (
  file: FileElementType,
  index: number,
  location: dataRoomLocationType
) => void;
type getAddLenderFilePermissionDTOType = (
  shareList: string[],
  petransactionId: string,
  lenderData: Record<string, LenderType>
) => ShareType[];
type parseShareFileDataType = (
  fileData: FileElementType,
  index: number,
  newShareList: string[],
  petransactionId: string,
  location: dataRoomLocationType,
  lenderData: Record<string, LenderType>,

  sharedWith?: string[]
) => shareDataRoomFileType;
type initiateToggleWatermarkType = (e: CheckboxChangeEvent, id: string) => void;
type shareDataRoomFileType = {
  addLenderFilePermissionDTOs: ShareType[];
  removeLenderFilePermission: {
    elementIds: string[];
    lenderIds: string[];
  };
};
type ShareType = {
  userId: string;
  elementId: string;
  name: string;
  petransactionId: string;
};

type onFileUploadType = (
  petransactionId: string,
  location: dataRoomLocationType,
  parentFileElement?: { elementId: string }
) => (files: Array<File> | null) => void;

type onSuccessType = (
  petransactionId: string,
  location: dataRoomLocationType,
  parentFileElement?: { elementId: string }
) => (files: Array<FileType> | null) => void;

type backtrackPathType = (name: string, index: number) => void;

type submitMoveFilesToNewLocationType = (
  files: FileElementType[],
  allData: FileElementType[]
) => void;
