import React, { FC } from "react";
import {
  Button,
  Menu,
  Popover,
  Select,
  Tooltip,
  Tree,
  TreeSelectProps,
} from "antd";
import {
  ColumnHeightOutlined,
  MonitorOutlined,
  FontSizeOutlined,
  DiffOutlined,
  FlagOutlined,
  ColumnWidthOutlined,
  UnorderedListOutlined,
  ArrowLeftOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  DANGER_BUTTON_STYLE,
  SUCCESS_BUTTON_STYLE,
  tableSelectCss,
} from "../../../utils/cssConfigs";
import { isEmpty } from "ramda";
import { PSColWidth, PSFontSize } from "./utils/css";
import { tableSelectSuffix } from "../../../utils/componentUtils";
import { PSParams } from "./PrecedentDetails";

export const PrecedentToolbar: FC<PrecedentToolbarType> = ({
  redline,
  setRedline,
  heatmap,
  setHeatMap,
  showMatchedRows,
  setShowMatchedRows,
  matchCount = 0,
  sheetOptions,
  selectedSheets,
  setSelectedSheets,
  setFontSize,
  fontSize,
  setWidth,
  width,
  params,
  onUpdateParams,
  goBack,
  onExport,
}) => {
  return (
    <div
      className={`sticky top-0 z-[100] w-full flex pl-0.5 p-2 px-5 bg-gray-50 drop-shadow border-y border-y-slate-200 justify-between group`}
    >
      <div
        className={
          "flex flex-row items-center gap-px overflow-x-scroll hide-scrollbar"
        }
      >
        <div className={"pl-4"}>
          <Tooltip title={"Back to Precedent Search"}>
            <Button
              size={"small"}
              shape={"circle"}
              type={"text"}
              icon={<ArrowLeftOutlined />}
              onClick={goBack}
            />
          </Tooltip>
        </div>
        <Popover
          showArrow={false}
          trigger={"click"}
          placement={"bottomRight"}
          content={
            <Tree
              height={500}
              defaultExpandedKeys={selectedSheets}
              treeData={sheetOptions}
              className={"w-56 md:w-96"}
              checkable={true}
              checkedKeys={selectedSheets}
              onCheck={(e: any): void => setSelectedSheets(e)}
            />
          }
        >
          <Button type={"text"} icon={<UnorderedListOutlined />}>
            Show/Hide
          </Button>
        </Popover>

        <div>
          <Tooltip
            className={redline ? DANGER_BUTTON_STYLE : ""}
            title={redline ? "Hide diff" : "Show diff"}
            placement={"bottom"}
          >
            <Button
              type={"text"}
              onClick={(): void => {
                setRedline(!redline);
              }}
              icon={<DiffOutlined />}
            >
              Redline
            </Button>
          </Tooltip>
        </div>
        <div>
          <Tooltip
            className={heatmap ? DANGER_BUTTON_STYLE : ""}
            title={heatmap ? "Hide diff" : "Show diff"}
            placement={"bottom"}
          >
            <Button
              type={"text"}
              onClick={(): void => {
                setHeatMap(!heatmap);
              }}
              icon={<FlagOutlined />}
            >
              Heat Map
            </Button>
          </Tooltip>
        </div>
        <div className={`flex items-center pl-1.5 group ${tableSelectCss()}`}>
          <FontSizeOutlined />
          <Select
            size={"small"}
            dropdownMatchSelectWidth={120}
            className={"w-full"}
            bordered={false}
            suffixIcon={tableSelectSuffix()}
            value={fontSize}
            onChange={setFontSize}
            options={Object.entries(PSFontSize).map(([k, v]) => {
              return {
                value: v,
                label: (
                  <span className={"capitalize"}>
                    {k.toLowerCase().replaceAll("_", " ")}
                  </span>
                ),
              };
            })}
          />
        </div>

        <div className={`flex items-center group ${tableSelectCss()}`}>
          <ColumnWidthOutlined />
          <Select
            size={"small"}
            dropdownMatchSelectWidth={120}
            className={"w-full"}
            bordered={false}
            suffixIcon={tableSelectSuffix()}
            value={width}
            onChange={setWidth}
            options={Object.entries(PSColWidth)
              .filter(([v]) => isNaN(Number(v)))
              .map(([k, v]) => {
                return {
                  value: v,
                  label: (
                    <span className={"capitalize"}>
                      {k.toLowerCase().replaceAll("_", " ")}
                    </span>
                  ),
                };
              })}
          />
        </div>
        {/*<div>*/}
        {/*  <Tooltip title={"Export"}>*/}
        {/*    <Button*/}
        {/*      type={"text"}*/}
        {/*      shape={"circle"}*/}
        {/*      icon={<DownloadOutlined />}*/}
        {/*      onClick={onExport}*/}
        {/*    />*/}
        {/*  </Tooltip>*/}
        {/*</div>*/}
      </div>
      <div className={"flex flex-row items-center gap-2"}>
        {!isEmpty(params.keywords) && matchCount > 0 && (
          <>
            <div>Matches: {matchCount}</div>
            {/*<Divider type={"vertical"} className={"h-full"} />*/}
          </>
        )}
        {!isEmpty(params.keywords) && (
          <div>
            <Button
              className={showMatchedRows ? SUCCESS_BUTTON_STYLE : ""}
              type={"text"}
              onClick={(): void => {
                setShowMatchedRows(!showMatchedRows);
              }}
              icon={<ColumnHeightOutlined />}
            >
              Showing {showMatchedRows ? "Matches" : "All"}
            </Button>
          </div>
        )}
        {!isEmpty(params.keywords) && (
          <>
            <div>
              <Button
                className={params.semanticSearch ? SUCCESS_BUTTON_STYLE : ""}
                type={"text"}
                onClick={(): void => {
                  onUpdateParams({
                    ...params,
                    semanticSearch: !params.semanticSearch,
                  });
                }}
                icon={<MonitorOutlined />}
              >
                Semantic Search {params.semanticSearch ? "ON" : "OFF"}
              </Button>
            </div>
            {/*<Divider type={"vertical"} className={"h-full"} />*/}
          </>
        )}
        <Select
          mode={"tags"}
          className={"w-56"}
          value={params.keywords}
          onChange={(v): void => {
            onUpdateParams({ ...params, keywords: v });
          }}
          maxTagCount={"responsive"}
          allowClear={true}
          placeholder={"Search Bid Metrics"}
          notFoundContent={
            "Enter keyword(s) to filter Bid Metrics by any of them"
          }
        />
      </div>
    </div>
  );
};

type PrecedentToolbarType = {
  redline: boolean;
  setRedline: (v: boolean) => void;
  heatmap: boolean;
  setHeatMap: (v: boolean) => void;
  showMatchedRows: boolean;
  setShowMatchedRows: (v: boolean) => void;
  matchCount?: number;
  selectedSheets: string[];
  setSelectedSheets: (v: string[]) => void;
  sheetOptions: TreeSelectProps["treeData"];
  fontSize: PSFontSize;
  setFontSize: (v: PSFontSize) => void;
  width: PSColWidth;
  setWidth: (v: PSColWidth) => void;
  params: PSParams;
  onUpdateParams: (v: PSParams) => void;
  goBack: VoidFunction;
  onExport: VoidFunction;
};
