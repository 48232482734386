import React, { FC } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const Spinner: FC = function () {
  const antIcon = <LoadingOutlined style={{ fontSize: 70 }} spin />;

  return (
    <div
      className={"top-0 left-0 fixed w-full h-full"}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2 )",
      }}
    >
      <Spin indicator={antIcon} className={"top-1/3 absolute w-full"} />
    </div>
  );
};
