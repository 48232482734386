import { RenderDOMFunc } from "rc-select/lib/BaseSelect";

export const getContainerForFullScreen: RenderDOMFunc = () => {
  return document.getElementById("full-screen") ?? document.documentElement;
};

export const getModalContainerForFullScreen = () =>
  document.getElementById("full-screen") ?? document.documentElement;

/**
 * To make sure that the dropdown sticks to its parent
 * @param trigger param coming from the usage
 */
export const getPopupContainerForSelect: RenderDOMFunc = (trigger) =>
  trigger.parentElement;
