import React, { CSSProperties, FC, memo, useMemo } from "react";
import { diff_match_patch } from "diff-match-patch";
import { intersperse } from "ramda";

const dmp = new diff_match_patch();

const RedLineContent: FC<RedLineContentType> = function ({
  text = "",
  style = {},
  compareText = "",
  onClick,
}: RedLineContentType) {
  const diffs = useMemo(() => {
    const computedDiffs = dmp.diff_main(compareText, text);
    dmp.diff_cleanupSemantic(computedDiffs);
    return computedDiffs;
  }, [compareText, text]);

  const redLines = useMemo(() => {
    let beforeLineIndex = 0;
    let afterLineIndex = 0;

    const lines = diffs.map(([op, text], i) => {
      if (op === 0) {
        // No change
        const lineDivs = text
          .split("\n")
          .map((line, j) => <span key={j}>{line}</span>);
        beforeLineIndex += lineDivs.length;
        afterLineIndex += lineDivs.length;
        return intersperse(
          <span>
            <br />
          </span>,
          lineDivs
        );
      } else if (op === -1) {
        // Deletion
        const lineDivs = (
          <del className={"text-danger"} key={i}>
            {text}
          </del>
        );
        beforeLineIndex += 1;
        return lineDivs;
      } else if (op === 1) {
        // Insertion
        const lineDivs = (
          <ins className={"underline text-primary"} key={i}>
            {text}
          </ins>
        );
        afterLineIndex += 1;
        return lineDivs;
      }
      return null; // Handle other cases if necessary
    });

    return lines;
  }, [diffs]);

  return (
    <div
      style={style}
      className="w-full h-full z-40 py-1 overflow-hidden whitespace-pre-line"
      onClick={onClick}
    >
      <span>{redLines}</span>
    </div>
  );
};

type RedLineContentType = {
  text: string;
  style?: CSSProperties;
  compareText?: string;
  onClick?: () => void;
};

export default memo(RedLineContent);
