import { Modal, ModalFuncProps } from "antd";
import React, { ReactNode } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getModalContainerForFullScreen } from "./container";
import { PRIMARY_BUTTON_STYLE } from "./cssConfigs";

export const ConfirmDelete: modalPropsType = (
  title,
  onOk,
  content,
  props,
  okText = "Delete"
) => {
  Modal.confirm({
    width: "30%",
    getContainer: getModalContainerForFullScreen,
    title,
    onOk,
    centered: true,
    content: <span className={""}>{content}</span>,
    icon: <QuestionCircleOutlined style={{ color: "red" }} />,
    okText,
    okButtonProps: {
      className: "bg-danger text-white border-0",
    },
    cancelButtonProps: {
      className: "border-primary text-primary",
    },
    ...props,
  });
};

export const ConfirmDownload: confirmDownloadType = (
  filename = "",
  href,
  content,
  props
) => {
  Modal.confirm({
    getContainer: getModalContainerForFullScreen,
    title: `Do you want to download ${filename}?`,
    cancelText: "No",
    okText: "Yes",
    centered: true,
    icon: <QuestionCircleOutlined style={{ color: "blue" }} />,
    content,
    okButtonProps: {
      className: "border-0 bg-primary hover:bg-hover text-white",
      href,
      download: filename,
      target: "_blank",
    },
    ...props,
  });
};

export const ConfirmAction: modalPropsType = (
  title,
  onOk,
  content,
  props,
  okText = "Delete"
) => {
  Modal.confirm({
    getContainer: getModalContainerForFullScreen,
    title,
    cancelText: "No",
    centered: true,
    okText,
    onOk,
    content,
    okButtonProps: {
      className: PRIMARY_BUTTON_STYLE,
      target: "_blank",
      ...props?.okButtonProps,
    },
    ...props,
  });
};

type modalPropsType = (
  title: ReactNode,
  onOk: () => void,
  content?: ReactNode,
  props?: ModalFuncProps,
  okText?: string
) => void;

type confirmDownloadType = (
  filename: string,
  url?: string,
  content?: string,
  props?: ModalFuncProps
) => void;
