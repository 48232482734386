import React, { FC, useEffect, useState } from "react";
import { CRMFilterType } from "../../utils/types";
import { Button, DatePicker, Select } from "antd";
import {
  AnalyticsCurrencyType,
  ElementType,
  RelationshipModuleLabelType,
} from "../../utils/enums";
import moment from "moment";
import { complement, equals, isNil, omit } from "ramda";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import {
  DEFAULT_CRM_ANALYTICS_FILTERS,
  RelationshipModuleType,
} from "../../utils/relationship";

/**
 * Filters Component for Analytics Component
 * @param filters - current filters in the Analytics/Parent Component
 * @param onChange - is for sending back the updated filters to the parent component
 * @param keys - is for getting the list of allocation Keys
 * @constructor
 * @returns FC
 * @type AnalyticsFiltersType
 */
export const RelationshipFilters: FC<AnalyticsFiltersType> = ({
  filters,
  onChange,
  since,
  relationshipModules,
}) => {
  const [currentFilters, setCurrentFilters] = useState<CRMFilterType | null>(
    null
  );

  useEffect(() => {
    setCurrentFilters(filters);
  }, [filters]);

  return (
    <div
      className={
        "flex flex-wrap gap-x-4 gap-y-2 items-center w-full justify-end"
      }
    >
      <span>Filters</span>
      <DatePicker.RangePicker
        ranges={{
          ...(since ? { Since: [moment(Number(since)), moment()] } : {}),
          "This Year": [moment().startOf("year"), moment()],
          "Last Year": [
            moment().subtract(1, "year").startOf("year"),
            moment().subtract(1, "year").endOf("year"),
          ],
        }}
        defaultValue={[
          moment(
            new URLSearchParams(location.search).get("from") ??
              DEFAULT_CRM_ANALYTICS_FILTERS.from
          ),
          moment(
            new URLSearchParams(location.search).get("to") ??
              DEFAULT_CRM_ANALYTICS_FILTERS.to
          ),
        ]}
        value={[moment(currentFilters?.from), moment(currentFilters?.to)]}
        format={"DD MMMM YYYY"}
        onChange={(values): void => {
          if (values) {
            const [start, end] = values;
            start &&
              end &&
              setCurrentFilters({
                ...currentFilters,
                from: start.format("YYYY-MM-DD").toString(),
                to: end.format("YYYY-MM-DD").toString(),
              });
          } else {
            setCurrentFilters({
              ...currentFilters,
              from: moment().startOf("year").format("YYYY-MM-DD").toString(),
              to: moment().format("YYYY-MM-DD").toString(),
            });
          }
        }}
      />
      <div className={"flex flex-row items-center gap-2"}>
        <span>Currency:</span>
        <Select<AnalyticsCurrencyType>
          className={"overflow-hidden max-h-[32px]"}
          placeholder={"Choose Currency"}
          options={Object.values(AnalyticsCurrencyType).map((value) => ({
            label: AnalyticsCurrencyType[value],
            value,
          }))}
          value={currentFilters?.fxRateTargetCurrency}
          onChange={(value): void => {
            setCurrentFilters((filters) => ({
              ...filters,
              fxRateTargetCurrency: value,
            }));
          }}
        />
      </div>
      <div className={"flex flex-row items-center gap-2"}>
        <span>FX Rate (as of):</span>
        <DatePicker
          disabledDate={(currentDate) =>
            moment(currentDate).valueOf() > moment().valueOf()
          }
          className={"overflow-hidden max-h-[32px]"}
          value={moment(currentFilters?.fxRateDate)}
          format={"YYYY-MM-DD"}
          onChange={(value): void => {
            if (value) {
              setCurrentFilters({
                ...currentFilters,
                fxRateDate: value.format("YYYY-MM-DD").toString(),
              });
            } else {
              setCurrentFilters(omit(["fxRateDate"], currentFilters));
            }
          }}
        />
      </div>
      {relationshipModules.length > 1 && (
        <Select
          dropdownMatchSelectWidth={150}
          defaultValue={relationshipModules[0]}
          value={currentFilters?.crmElementType}
          onChange={(value): void => {
            setCurrentFilters((filters) => ({
              ...filters,
              crmElementType: value as ElementType,
            }));
          }}
          options={relationshipModules
            .filter(complement(isNil))
            .map((value) => ({
              label: (
                <span className={"capitalize"}>
                  {value && RelationshipModuleLabelType[value]}
                </span>
              ),
              value: value ?? "",
            }))}
        />
      )}
      <Button
        disabled={
          filters && currentFilters
            ? equals(filters, currentFilters) ?? false
            : true
        }
        onClick={(): void => {
          if (currentFilters) {
            onChange(currentFilters);
          }
        }}
        className={PRIMARY_BUTTON_STYLE}
      >
        Apply Filters
      </Button>
      <Button
        disabled={equals(filters, DEFAULT_CRM_ANALYTICS_FILTERS)}
        onClick={() =>
          onChange({
            ...DEFAULT_CRM_ANALYTICS_FILTERS,
            crmElementType: filters?.crmElementType,
          })
        }
      >
        Reset Filters
      </Button>
    </div>
  );
};

type AnalyticsFiltersType = {
  since?: number;
  filters: CRMFilterType | null;
  onChange: (filters: CRMFilterType) => void;
  relationshipModules: RelationshipModuleType[];
};
