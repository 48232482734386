import React, { FC } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { SpinSize } from "antd/lib/spin";

export const CustomSpin: FC<CustomSpinType> = ({
  loading = true,
  loadingText = "Loading ...",
  size = "large",
  transparent = true,
}: CustomSpinType) => (
  <div
    className={`${
      loading
        ? `absolute visible bg-gray-100 ${
            transparent ? "bg-opacity-60" : "bg-opacity-100"
          } cursor-not-allowed`
        : "hidden invisible"
    } flex h-full w-full max-w-full items-center justify-center z-[200]`}
  >
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined spin={true} />}
      tip={loadingText}
      size={size}
    />
  </div>
);

type CustomSpinType = {
  loading?: boolean;
  loadingText?: string;
  size?: SpinSize;
  transparent?: boolean;
};
