import React, { FC } from "react";
import { Tooltip } from "antd";
import { convertDateToFormat } from "../../utils/moment";
import { NotificationActivityTitleEnumType } from "../../utils/enums";
import { ActivityType } from "../../utils/types";
import { getNotificationIcon } from "../../utils/notifications";
import { isEmpty } from "ramda";
import { ViewEmail } from "../general/ViewEmail";

export const GroupedActivities: FC<GroupedActivitiesType> = ({
  activities,
  transactionId,
}: GroupedActivitiesType) => {
  return (
    <>
      {activities
        .filter(({ petransactionId }) => {
          if (isEmpty(transactionId)) {
            return activities;
          } else {
            return petransactionId?.includes(transactionId as string);
          }
        })
        .map(
          (
            { activityType, createdFor, createDate, dataMap, message, id },
            index
          ) => (
            <div key={id} className="bg-white group hover:bg-blue-50">
              <tr className="flex">
                <td className="p-3 border-b w-80">
                  <span className="text-xs">
                    {(index === 0 ||
                      activities[index - 1].activityType !==
                        activities[index].activityType) &&
                      NotificationActivityTitleEnumType[activityType]}
                  </span>
                </td>
                <td className="p-3 border-b w-11/12 relative">
                  <div className="group truncate w-96 ml-8">
                    <Tooltip placement="topLeft" title={message}>
                      {message}
                    </Tooltip>
                  </div>
                  <span className="absolute top-1">
                    {getNotificationIcon(
                      activityType,
                      dataMap.CREATOR_NAME,
                      dataMap.COMPANY,
                      dataMap.LENDER_TITLE
                    )}
                  </span>

                  <span className="opacity-50 text-xs ml-8">
                    created by: {dataMap.CREATOR_NAME}
                  </span>
                  <br />
                  <span className="opacity-50 text-xs ml-8">
                    created for: {ViewEmail(createdFor)}
                  </span>
                </td>
                <td className="p-3 border-b w-60">
                  <span className="opacity-50 text-xs">
                    {convertDateToFormat(
                      createDate,
                      "DD.MMM.YYYY - hh:mm A",
                      false
                    )}
                  </span>
                </td>
              </tr>
            </div>
          )
        )}
    </>
  );
};
type GroupedActivitiesType = {
  activities: ActivityType[];
  transactionId: string | null;
};
