import {
  CurrencySymbolType,
  CurrencyType,
  LeakageDataTypes,
  UnitLabelType,
  UnitType,
} from "./enums";
import { EditableTableCellProps } from "../components/general/EditableTableCell";
import React, { ReactNode } from "react";
import { getPopupContainerForSelect } from "./container";
import { tableSelectCss } from "./cssConfigs";
import { tableSelectSuffix } from "./componentUtils";
import { InputNumberProps, Select } from "antd";
import { LeakageTemplate } from "./types";
import { formatTotal } from "./allocation";
import { capitalizeText } from "./utils";

const numericParser: InputNumberProps["parser"] = (v = "") =>
  v?.replace(/[^\d.]/g, "");

export const getCellDataType = (
  type: LeakageDataTypes,
  enumMap: LeakageTemplate["enumMap"],
  companyOptions: EditableTableCellProps["selectOptions"]
): Partial<EditableTableCellProps> => {
  switch (type) {
    case LeakageDataTypes.PERCENTAGE:
      return {
        type: "number",
        placeholder: "Percent Value",
        parser: numericParser,
        formatter: (v) => formatTotal(v) + "%",
      };
    case LeakageDataTypes.RATIO:
      return {
        type: "number",
        placeholder: "Ratio Value",
        parser: numericParser,
        formatter: (v) => formatTotal(v) + "x",
      };
    case LeakageDataTypes.DAYS:
      return {
        type: "number",
        placeholder: "Enter Days",
        parser: numericParser,
        formatter: (v) => formatTotal(v) + " Days",
      };
    case LeakageDataTypes.MONTHS:
      return {
        type: "number",
        placeholder: "Enter Months",
        parser: numericParser,
        formatter: (v) => formatTotal(v) + " Months",
      };
    case LeakageDataTypes.NUMBER:
    case LeakageDataTypes.CURRENCY:
      return {
        type: "number",
        placeholder: "Enter Amount",
      };
    case LeakageDataTypes.STRING:
      return { type: "text", placeholder: "Enter Information" };
    case LeakageDataTypes.COMPANY:
      return {
        type: "select",
        placeholder: "Select Company",
        multiple: true,
        selectOptions: companyOptions,
      };
    case LeakageDataTypes.DECISION_ENUM:
    case LeakageDataTypes.MONTHS_RANGE_ENUM:
    case LeakageDataTypes.MARGIN_ENUM:
    case LeakageDataTypes.COLOR_ENUM:
    case LeakageDataTypes.BOOLEAN_ENUM:
      return {
        type: "select",
        placeholder: "Select",
        selectOptions: (enumMap?.[type] ?? []).map((value) => ({
          value: capitalizeText(value.toLowerCase()),
          key: value,
        })),
      };
    default:
      return { type: "text" };
  }
};

export const getSuffixByDataType = (
  type: LeakageDataTypes,
  unit?: UnitType,
  onUpdateSuffix?: (key: string, value: string) => void
): ReactNode => {
  switch (type) {
    case LeakageDataTypes.CURRENCY:
      return (
        <Select
          getPopupContainer={getPopupContainerForSelect}
          onChange={(value) => onUpdateSuffix && onUpdateSuffix("unit", value)}
          bordered={false}
          size={"small"}
          // className={"select-none "}
          className={tableSelectCss(true)}
          suffixIcon={tableSelectSuffix(true)}
          value={unit}
          options={Object.values(UnitType).map((value) => ({
            label: UnitLabelType[value],
            value,
          }))}
        />
      );
    case LeakageDataTypes.MONTHS_RANGE_ENUM:
      return <span className={"text-xs text-muted"}>Months</span>;
    default:
      return false;
  }
};

export const getPrefixByDataType = (
  type: LeakageDataTypes,
  currency?: CurrencyType,
  onUpdatePrefix?: (key: string, value: string) => void
): ReactNode => {
  switch (type) {
    case LeakageDataTypes.CURRENCY:
      return (
        <Select
          getPopupContainer={getPopupContainerForSelect}
          onChange={(value) =>
            onUpdatePrefix && onUpdatePrefix("currency", value)
          }
          size={"small"}
          bordered={false}
          // className={"select-none "}
          // className={tableSelectCss(true)}
          className="min-w-[60px]"
          suffixIcon={tableSelectSuffix(true)}
          value={currency}
          options={Object.values(CurrencyType).map((value) => ({
            label: CurrencySymbolType[value],
            value,
          }))}
        />
      );
    default:
      return false;
  }
};
