import { AnalyticsChartType } from "./enums";
import { arrayToMapConversion, populateArrayWithPropertyPath } from "./utils";
import {
  forEach,
  groupBy,
  indexOf,
  map,
  path,
  prop,
  uniq,
  update,
} from "ramda";
import {
  Options,
  SeriesClickEventObject,
  SeriesOptionsType,
  XAxisOptions,
} from "highcharts";

const HighchartsType = {
  STACKED_BAR: "column",
  GROUPED_BAR: "column",
  PIE: "pie",
  BAR: "column",
  HORIZONTAL_BAR: "bar",
  LINE: "line",
  SINGLE_VALUE: "",
  TABLE: "",
  TREEMAP: "treemap",
};

const parseDataForStackedBar: dataParseType = (metrics = [], properties) => {
  const groupByKey =
    path<string>(["groupByKey", "value"], properties ?? {}) ?? "";
  const xAxisValues = uniq(
    populateArrayWithPropertyPath(
      [path(["xKey", "value"], properties ?? {}) ?? ""],
      metrics
    )
  );
  const yAxisValues = path<string>(["yKey", "value"], properties ?? {}) ?? "";

  const refactoredData: [string, Record<string, number | string>][] = (
    Object.entries(groupBy(prop(groupByKey ?? ""))(metrics) ?? {}) ?? []
  ).map(([key, val]) => {
    return [
      key,
      arrayToMapConversion(
        val,
        path(["xKey", "value"], properties ?? {}) ?? "",
        [yAxisValues]
      ),
    ];
  });

  const data: ParsedDataType = {
    seriesData: {
      data: refactoredData.map(([key, val]) => {
        let dataValue = new Array(xAxisValues.length).fill(
          null,
          0,
          xAxisValues.length
        );

        forEach(([innerKey, innerValue]) => {
          dataValue = update(
            indexOf(innerKey, xAxisValues),
            Number(innerValue) ?? 0,
            dataValue
          );
        }, Object.entries(val));

        return {
          name: key,
          data: dataValue,
        };
      }) as SeriesOptionsType[],
      labels: {
        x: (path(["xKey", "label"], properties ?? {}) ?? "") as string,
        y: (path(["yKey", "label"], properties ?? {}) ?? "") as string,
      },
    },
    categories: xAxisValues,
  };
  return data;
};

const parseDataForBars: dataParseType = (metrics = [], properties) => {
  const groupByKey = path<string>(
    ["0", path<string>(["groupByKey", "value"], properties ?? {}) ?? ""],
    metrics
  );
  return {
    seriesData: {
      data: [
        {
          data: map<string, number>(
            (x) => Number(x),
            populateArrayWithPropertyPath(
              [path<string>(["yKey", "value"], properties ?? []) ?? ""],
              metrics
            ) as string[]
          ),
          name: groupByKey ?? path<string>(["yKey", "label"], properties ?? []),
        },
      ] as SeriesOptionsType[],
      labels: {
        x: path<string>(["xKey", "label"], properties ?? []) ?? "",
        y: path<string>(["yKey", "label"], properties ?? []) ?? "",
      },
    },
    categories: populateArrayWithPropertyPath(
      [path<string>(["xKey", "value"], properties ?? []) ?? ""],
      metrics
    ) as string[],
  };
};

const parseDataForPie: dataParseType = (metrics, properties?) => {
  const yAxisValues = path(["yKey", "value"], properties ?? []) ?? "";
  const xAxisValues = path(["xKey", "value"], properties ?? []) ?? "";
  const groupByKey = path(
    [
      "0",
      (path(["groupByKey", "value"], properties ?? {}) as string) ??
        ("" as string),
    ],
    metrics
  );
  return {
    seriesData: {
      data: [
        {
          data: metrics?.map((metric) => ({
            name: path([xAxisValues as string], metric),
            y: Number(path([yAxisValues as string], metric)),
          })),
          name: groupByKey ?? path(["yKey", "label"], properties ?? []),
        },
      ] as SeriesOptionsType[],
    },
  };
};

const parseDataForTreemap: dataParseType = (metrics, properties?) => {
  const yAxisValues = path(["yKey", "value"], properties ?? []) ?? "";
  const xAxisValues = path(["xKey", "value"], properties ?? []) ?? "";
  return {
    seriesData: {
      data: [
        {
          type: "treemap",
          layoutAlgorithm: "squarified",
          alternateStartingDirection: true,
          levels: [
            {
              level: 1,
              dataLabels: {
                enabled: true,
                align: "center",
                verticalAlign: "top",
                style: {
                  fontSize: "15px",
                  fontWeight: "bold",
                },
              },
            },
          ],
          data: metrics?.map((metric, i) => ({
            name: path([xAxisValues as string], metric),
            value: Number(path([yAxisValues as string], metric)),
            colorValue: i,
          })),
        },
      ] as SeriesOptionsType[],
    },
  };
};

export const getSeriesObject: getSeriesObjectType = (
  type,
  metrics,
  colors,
  properties
) => {
  switch (type) {
    case AnalyticsChartType.GROUPED_BAR:
    case AnalyticsChartType.STACKED_BAR:
    case AnalyticsChartType.LINE:
      return parseDataForStackedBar(metrics, properties);
    case AnalyticsChartType.HORIZONTAL_BAR:
    case AnalyticsChartType.BAR:
      return parseDataForBars(metrics, properties);
    case AnalyticsChartType.PIE:
      return parseDataForPie(metrics, properties);
    case AnalyticsChartType.TREEMAP:
      return parseDataForTreemap(metrics, properties);
    default:
      return {
        seriesData: {
          data: [],
        },
      };
  }
};

export const getHighchartsConfig: getHighchartsConfigType = (
  allowExport,
  title = "",
  type,
  metrics,
  min,
  max,
  colors,
  yThreshold,
  properties,
  onClick
) => {
  const series = getSeriesObject(type, metrics, colors, properties);
  return {
    credits: {
      enabled: false,
    },
    chart: {
      type: HighchartsType[type],
      zoomType: "xy",
      reflow: true,
      className: "flex w-full p-2 pb-0",
      height: 500,
      parallelAxes: {
        showEmpty: false,
      },
    },
    title: {
      text: title,
    },
    colorAxis:
      type === AnalyticsChartType.TREEMAP
        ? {
            minColor: "#E6F7FF",
            maxColor: "#1890FF",
          }
        : undefined,
    xAxis: {
      categories: series?.categories ?? [],
      title: series?.seriesData?.labels?.x ?? "",
      showEmpty: false,
      scrollbar: {
        enabled: true,
        showFull: false,
      },
    } as XAxisOptions,
    yAxis: {
      allowDecimals: false,
      title: {
        text: series?.seriesData?.labels?.y ?? "",
      },
      min: 0,
      showEmpty: false,
      stackLabels: {
        format: "{total:.1f}",
        enabled: true,
        style: {
          fontWeight: "bold",
        },
      },
    },
    legend: {
      enabled: type !== AnalyticsChartType.TREEMAP,
      backgroundColor: "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
      align: "right",
    },
    subtitle: {
      align: "left",
      text: "",
    },
    tooltip: {
      valueDecimals: 1,
      pointFormat: "<b>{point.x}</b><br/>{series.name}: {point.y:.1f}",
    },
    plotOptions: {
      treemap: {
        cursor: "pointer",
        showInLegend: false,
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.value:.1f}",
        },
        tooltip: {
          headerFormat: "",
          pointFormat: "<b>{point.name}</b>: {point.value}",
        },
        events: {
          click: (e: SeriesClickEventObject): void => {
            onClick &&
              onClick([`${properties?.xKey?.value ?? ""}:${e.point.name}`]);
          },
        },
      },
      column: {
        stacking:
          type === AnalyticsChartType.STACKED_BAR ? "normal" : undefined,
        dataLabels: {
          enabled: true,
          format: "{point.y:.1f}",
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          pointFormat:
            type === AnalyticsChartType.STACKED_BAR ||
            type == AnalyticsChartType.GROUPED_BAR
              ? "{series.name}: {point.y:.1f}<br/>Total: {point.stackTotal:.1f}"
              : "{series.name}: {point.y:.1f}",
        },
        events: {
          click: (e: SeriesClickEventObject): void => {
            onClick &&
              onClick([
                `${properties?.xKey?.value ?? ""}:${e.point.category}`,
                ...(type === AnalyticsChartType.STACKED_BAR ||
                type == AnalyticsChartType.GROUPED_BAR
                  ? [
                      `${properties?.groupByKey?.value ?? ""}:${
                        e.point.series.name
                      }`,
                    ]
                  : []),
              ]);
          },
        },
      },
      pie: {
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
        tooltip: {
          valueDecimals: 1,
          headerFormat: "",
          pointFormat: "<b>{point.name}</b>: {point.y:.1f}",
        },
        showInLegend: true,
        events: {
          click: (e: SeriesClickEventObject): void => {
            onClick &&
              onClick([`${properties?.xKey?.value ?? ""}:${e.point.name}`]);
          },
        },
      },
      bar: {
        events: {
          click: (e: SeriesClickEventObject): void =>
            onClick &&
            onClick([`${properties?.xKey?.value ?? ""}:${e.point.category}`]),
        },
      },
    },
    series: series.seriesData.data,
  };
};

type getSeriesObjectType = (
  type: AnalyticsChartType,
  metrics: Record<string, string>[],
  colors: Array<string>,
  properties?: PropertiesType
) => {
  seriesData: SeriesObjectType;
  categories?: string[];
};

type getHighchartsConfigType = (
  allowExport: boolean,
  title: string,
  type: AnalyticsChartType,
  metrics: Record<string, string>[],
  min: number | undefined,
  max: number | undefined,
  colors: Array<string>,
  yThreshold: number | boolean,
  properties?: PropertiesType,
  onClick?: (e: string[]) => void
) => Options;

type SeriesObjectType = {
  data: SeriesOptionsType[];
  labels?: { x: string; y: string };
  name?: string;
  categories?: string[];
};

export type PropertiesType = {
  groupByKey?: PropertyType;
  xKey: PropertyType;
  yKey: PropertyType;
};

export type PropertyType = {
  key: "xKey" | "yKey" | "groupByKey";
  label: string;
  value: string;
};

type ParsedDataType = {
  seriesData: SeriesObjectType;
  categories?: string[];
};

type dataParseType = (
  metrics: Record<string, string>[],
  properties?: PropertiesType
) => ParsedDataType;
