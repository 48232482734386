import React, { FC, ReactElement, ReactNode } from "react";
import { Spin } from "antd";
import { SpinIndicator } from "antd/es/spin";
import { SpinFCType, SpinSize } from "antd/lib/spin";

export const SpinnerOverlay: FC<SpinnerOverlayType> = function ({
  spinning,
  opaque = true,
  hidden = false,
  size,
  children,
  indicator,
}) {
  return (
    <div className={"relative h-full w-full"}>
      <div
        className={`${
          spinning
            ? `absolute visible top-0 left-0 cursor-not-allowed`
            : "hidden invisible"
        } ${
          opaque && !hidden ? "bg-gray-100 bg-opacity-80" : ""
        } flex h-full w-full max-w-full items-center justify-center z-50`}
      >
        <Spin spinning={spinning} indicator={indicator} size={size} />
      </div>
      {spinning && hidden ? <></> : children}
    </div>
  );
};

export type SpinnerOverlayType = {
  spinning?: boolean;
  opaque?: boolean;
  hidden?: boolean;
  size?: SpinSize;
  children: ReactElement | ReactElement[];
  indicator?: SpinIndicator;
};
