import React, { FC, useEffect, useState } from "react";
import {
  createNewWebFormConfig,
  deleteWebForm,
  getAllWebFormsByTransaction,
  renameWebForm,
} from "../../../services/services";
import { WebFormType } from "../../../utils/types";
import { LoadingType, ResponseType } from "../../../utils/uiTypes";
import { WebFormCard } from "../common/WebFormCard";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Empty, Input, message, Modal, Space } from "antd";
import { findIndex, isEmpty, propEq, remove } from "ramda";
import { useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router";
import { CustomSpin } from "../../general/CustomSpin";
import { insertItemInArray, updateItemInArray } from "../../../utils/utils";
import { ConfirmDelete } from "../../../utils/confirmationModals";

export const TermsheetList: FC<TermsheetListType> = function ({
  transactionId,
  isAdmin,
  isInstitution,
}: TermsheetListType) {
  const { url } = useRouteMatch();
  const history = useHistory();
  const [loading, setLoading] = useState<LoadingType | null>({
    loading: true,
    label: "Loading Termsheets ...",
  });
  const [selectedWebForm, setSelectedWebForm] =
    useState<EditWebFormType | null>(null);
  const [webForms, setWebForms] = useState<WebFormType[]>([]);

  const renameWebform: renameWebformType = (id: string, name: string) => {
    renameWebForm({
      segments: {
        id,
      },
      body: name,
    })
      .then(({ data }: ResponseType) => {
        const index = findIndex(propEq("id", id))(webForms);
        setWebForms(
          updateItemInArray(index, webForms, { ...webForms[index], name })
        );
      })
      .then(() => {
        message.success("Termsheet name has been updated!");
        setSelectedWebForm(null);
      })
      .catch(() => {
        message.error("Something went wrong, please try again later!");
      });
  };

  const create = () => {
    setLoading({ loading: true, label: "" });
    transactionId &&
      createNewWebFormConfig({
        segments: {
          id: transactionId,
        },
        body: JSON.stringify({
          name: "Untitled Termsheet",
        }),
      })
        .then(({ data }: ResponseType<WebFormType>) => {
          setWebForms(insertItemInArray(webForms.length, webForms, data));
          history.push(`${url}/${data.id}`);
        })
        .then(() => {
          setLoading(null);
        })
        .catch(() => {});
  };

  useEffect(() => {
    transactionId &&
      getAllWebFormsByTransaction({
        segments: {
          id: transactionId,
        },
      })
        .then(({ data = [] }: ResponseType) => {
          setWebForms(data);
        })
        .catch(() => {})
        .then(() => {
          setLoading(null);
        });
  }, [transactionId]);

  const onWebformDelete = (id: string) => {
    ConfirmDelete(
      "Are you sure you want to delete this termsheet? Only termsheets without any published versions can be deleted. Deleting a termsheet is irreversible, so please proceed with caution.",
      () => {
        message.loading({
          content: "Deleting version...",
          duration: 0,
          key: "deleting-" + id,
        });
        deleteWebForm({
          segments: {
            id,
          },
        })
          .then(() => {
            message.success({
              content: "Termsheet has been deleted!",
              key: "deleting-" + id,
            });
            const index = findIndex(propEq("id", id))(webForms);
            if (index >= 0) {
              setWebForms(remove(index, 1, webForms));
            }
          })
          .catch((error: string) => {
            message.error({
              style: {
                marginLeft: 100,
                marginRight: 100,
                justifyContent: "center",
              },
              content:
                error ??
                "Something went wrong, unable to delete this termsheet. Please try again later!",
              key: "deleting-" + id,
              duration: 5,
            });
          });
      },
      <></>
    );
  };
  const getWebForms = () => {
    return (
      <div className={"relative w-full h-full"}>
        {loading && (
          <CustomSpin loading={loading.loading} loadingText={loading.label} />
        )}
        <div>
          {isEmpty(webForms) && !isAdmin && (
            <Empty
              description={
                <span className={"text-muted"}>
                  The owner has not yet published a Term Sheet
                </span>
              }
            />
          )}
          <div className={"flex flex-row flex-wrap gap-2"}>
            {webForms.map(
              ({
                id,
                name = "",
                createdByUserDTO,
                createDate,
                sponsorPublishedDate,
              }) => {
                return (
                  <WebFormCard
                    key={id}
                    name={name}
                    isAdmin={isAdmin}
                    path={`${url}/${id}`}
                    creator={
                      createdByUserDTO
                        ? `${createdByUserDTO.firstName} ${createdByUserDTO.lastName}`
                        : ""
                    }
                    createDate={
                      isInstitution ? sponsorPublishedDate ?? "" : createDate
                    }
                    onSelect={() => {
                      history.push(`${url}/${id}`);
                    }}
                    onEdit={() => {
                      setSelectedWebForm({ id, name });
                    }}
                    onDelete={() => {
                      onWebformDelete(id);
                    }}
                  />
                );
              }
            )}
            {isAdmin && (
              <div
                onClick={create}
                role={"button"}
                className={
                  "group h-36 w-48 m-1 bg-white border border-gray-300 shadow-sm hover:border-blue-400"
                }
              >
                <div className={"pt-10 text-center"}>
                  <PlusOutlined
                    className={
                      "text-3xl text-gray-400 group-hover:text-blue-400"
                    }
                  />
                  <div
                    className={
                      "text-xs text-gray-400 group-hover:text-blue-400"
                    }
                  >
                    New Termsheet
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={"p-6 w-full h-full"}>{getWebForms()}</div>
      {selectedWebForm && (
        <Modal
          width={400}
          open={true}
          onCancel={() => {
            setSelectedWebForm(null);
          }}
          title={
            <span className={"text-xs text-secondary"}>Rename Termsheet</span>
          }
          closable={true}
          footer={
            <Space className={"flex justify-end"}>
              <Button
                className={"w-20"}
                type={"default"}
                onClick={() => {
                  setSelectedWebForm(null);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  renameWebform(selectedWebForm.id, selectedWebForm.name);
                }}
                type={"primary"}
                className={"bg-primary hover:bg-hover text-white w-20"}
              >
                Save
              </Button>
            </Space>
          }
        >
          <div className={"pb-4"}>
            Please enter a new name for the termsheet
          </div>
          <Input
            value={selectedWebForm?.name}
            onChange={(e) => {
              setSelectedWebForm(
                (webForm) => webForm && { ...webForm, name: e.target.value }
              );
            }}
          />
        </Modal>
      )}
    </>
  );
};

type TermsheetListType = {
  transactionId: string;
  isAdmin: boolean;
  isInstitution: boolean;
};

type EditWebFormType = {
  id: string;
  name: string;
};

type renameWebformType = (id: string, name: string) => void;
