import React, { FC, useEffect, useState, useMemo } from "react";
import { ElementTag } from "../company/tags/ElementTag";
import { LenderType } from "../../utils/types";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Checkbox, Divider } from "antd";
import { tableColumnHeader } from "../../utils/componentUtils";

export const TransactionTags: FC<TransactionTagsType> = ({
  lenders,
  setCheckedList,
  checkedList,
  shareWith,
}) => {
  const [checkAll, setCheckAll] = useState(false);
  const [selected, setSelected] = useState<selectedType>({});

  const tagGroups = useMemo(() => {
    const groups: tagLenderType = lenders?.reduce((acc, lender) => {
      const { tagDTOs = [], ...rest } = lender;
      const tagId = tagDTOs[0]?.id || "untagged";
      acc[tagId] = [...(acc[tagId] || []), { ...rest, tagDTOs }];
      return acc;
    }, {} as tagLenderType);
    return groups;
  }, [lenders]);

  const handleSelectionChange = (tagId: string, list: string[]) => {
    const updatedSelected = { ...selected, [tagId]: list };
    const uniqueValues = new Set(Object.values(updatedSelected).flat());
    setCheckedList(Array.from(uniqueValues));
    setSelected(updatedSelected);
    const totalLenders = Object.values(tagGroups).flat().length;
    setCheckAll(uniqueValues.size === totalLenders);
  };

  const handleCheckAllChange = (e: CheckboxChangeEvent) => {
    const allChecked = e.target.checked;

    if (allChecked) {
      const allIds = Object.values(tagGroups)
        .flat()
        .map((lender) => lender.id);

      const updatedSelected = Object.keys(tagGroups)?.reduce((acc, tagId) => {
        acc[tagId] = tagGroups[tagId]?.map((lender) => lender.id);
        return acc;
      }, {} as selectedType);

      setCheckedList(allIds);
      setSelected(updatedSelected);
    } else {
      setCheckedList([]);
      setSelected({});
    }
    setCheckAll(allChecked);
  };

  const handleTagClick = (tagId: string, checked: boolean) => {
    const checkedSet = new Set(checkedList);
    const tagIds = tagGroups[tagId].map((lender) => lender.id);

    tagIds.forEach((id) =>
      checked ? checkedSet.add(id) : checkedSet.delete(id)
    );

    setSelected((prev) => ({
      ...prev,
      [tagId]: checked ? tagIds : [],
    }));
    setCheckedList(Array.from(checkedSet));

    const totalLenders = Object.values(tagGroups)?.flat().length;

    setCheckAll(
      shareWith
        ? checkedSet.size - 1 === totalLenders
        : checkedSet.size === totalLenders
    );
  };

  useEffect(() => {
    if (shareWith) {
      const updatedSelected = Object.keys(tagGroups)?.reduce((acc, tagId) => {
        acc[tagId] = tagGroups[tagId]
          .filter((lender) => shareWith?.includes(lender.id))
          .map((lender) => lender.id);
        return acc;
      }, {} as selectedType);

      setSelected(updatedSelected);
      setCheckedList(shareWith);

      const totalLenders = Object.values(tagGroups)?.flat().length;
      setCheckAll(shareWith.length - 1 === totalLenders);
    }
  }, [shareWith, tagGroups]);

  return (
    <div className="flex flex-col gap-y-5 w-full overflow-x-hidden">
      <Checkbox
        onChange={handleCheckAllChange}
        checked={checkAll}
        indeterminate={!checkAll && checkedList.length > 0}
      >
        Select All
      </Checkbox>
      <div className="flex flex-wrap">
        {Object.keys(tagGroups)?.map((tagId) => (
          <div key={tagId} className="flex inline-flex gap-2">
            <Checkbox
              onChange={(e) => handleTagClick(tagId, e.target.checked)}
              checked={
                (selected[tagId]?.length || 0) === tagGroups[tagId]?.length
              }
            />
            {tagId !== "untagged" && tagGroups[tagId]?.[0]?.tagDTOs[0] && (
              <ElementTag
                tooltipPlacement="right"
                tag={tagGroups[tagId]?.[0]?.tagDTOs[0]}
              />
            )}
            {tagId === "untagged" && tableColumnHeader("No Tag")}
          </div>
        ))}
      </div>
      <div className="flex flex-wrap">
        {Object.keys(tagGroups).map((tagId) => (
          <Checkbox.Group
            key={tagId}
            onChange={(list) => handleSelectionChange(tagId, list as string[])}
            value={checkedList}
            className="flex flex-wrap"
          >
            {tagGroups[tagId].map((lender) => (
              <Checkbox key={lender.id} value={lender.id} className="ml-0">
                {lender.name}
              </Checkbox>
            ))}
          </Checkbox.Group>
        ))}
      </div>
    </div>
  );
};

export type selectedType = { [key: string]: string[] };
type TransactionTagsType = {
  lenders: LenderType[];
  checkedList: string[];
  setCheckedList: (o: string[]) => void;
  shareWith?: string[];
};
export type tagLenderType = { [tagId: string]: LenderType[] };
