import React, { FC, useEffect } from "react";
import { TRANSACTIONS_PAGE_URL } from "../utils/redirect";
import { useHistory } from "react-router";
import { usePageTitle } from "../customHooks/usePageTitle";

export const RedirectToHomePage: FC = function () {
  usePageTitle("TermGrid", false);

  const history = useHistory();

  useEffect(() => {
    history.push(TRANSACTIONS_PAGE_URL);
  }, []);
  return <></>;
};
