import React, { FC, ReactNode, useEffect, useState } from "react";
import { Checkbox, message, Table, Tag, Tooltip } from "antd";
import {
  getAllocationKeys,
  getCrmAllocationSummary,
} from "../../services/services";
import { ResponseType } from "../../utils/uiTypes";
import {
  AllocationKeyType,
  AllocationType,
  CrmAllocationResponseType,
  CRMAllocationType,
} from "../../utils/types";
import { ColumnsType } from "antd/es/table";
import { tableColumnHeader } from "../../utils/componentUtils";
import {
  AllocationTableType,
  AnalyticsCurrencyType,
  UnitLabelType,
} from "../../utils/enums";
import { convertDateToFormat } from "../../utils/moment";
import { and, equals, isEmpty, isNil, or, pathOr } from "ramda";
import { arrayToMapConversion, isEmptyOrNil } from "../../utils/utils";
import {
  columnSort,
  fetchWithIdAndParamsType,
  ParamsType,
  showPercentage,
} from "../../utils/relationship";
import { useParams } from "react-router";
import { MultipleSelect } from "../general/MultipleSelect";
import { RelationshipCard } from "./RelationshipCard";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
import { formatDecimalAndAddCommas } from "../../utils/allocation";
import { InfoCircleOutlined } from "@ant-design/icons";

const getCellValue: GetCellValueType = (
  { value, unit, currency },
  fieldType,
  exposure
) => {
  if (isEmpty(value) || isNil(value)) {
    return <span className={"text-muted"}>-</span>;
  }
  if (exposure && fieldType === "NUMERIC") {
    return (+value / exposure) * 100;
  }
  switch (fieldType) {
    case "TEXT" || "DROP_DOWN":
      return value;
    case "DATE":
      return convertDateToFormat(value, "D.MMM.YY", true);
    case "NUMERIC":
      return `${AnalyticsCurrencyType[currency]} ${formatDecimalAndAddCommas(
        value
      )} ${UnitLabelType[unit]}`;
    default:
      return value;
  }
};

const formatTotals = (
  allocations: CRMAllocationType[]
): CRMAllocationType[] => {
  let groupTotal = 0; // Running total for each portfolioId group

  return allocations.reduce<CRMAllocationType[]>((p, c, i, a) => {
    groupTotal += Number(c.value) ?? 0; // Accumulate group total

    const isLastInGroup = c.portfolioId !== a?.[i + 1]?.portfolioId;

    const cn = isLastInGroup
      ? [
          c,
          {
            ...c,
            isTotal: true,
            // allocationKeyDTO: { ...c.allocationKeyDTO, id: "" },
            value: groupTotal.toString(), // Use accumulated groupTotal
            prevTotal: 0, // Reset prevTotal after the total row is added
          } as CRMAllocationType,
        ]
      : [
          {
            ...c,
            prevTotal: groupTotal, // Keep track of the running group total
          },
        ];

    if (isLastInGroup) {
      groupTotal = 0;
    }

    // Append the current allocation and possibly the total row
    return [...p, ...cn];
  }, []);
};

export const AllocationInfoTable: FC<AllocationInfoTableType> = ({
  tableType,
}) => {
  const { id } = useParams<ParamsType>();
  const { search } = useLocation();

  const [allocationData, setAllocationData] =
    useState<CrmAllocationResponseType | null>(null);
  const [keys, setKeys] = useState<AllocationKeyType[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedPortfolios, setSelectedPortfolios] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [showTotals, setShowTotals] = useState(true);
  const [showExposure, setShowExposure] = useState(true);

  const fetchAllocations: fetchWithIdAndParamsType = (id, params) => {
    setLoading(true);
    getCrmAllocationSummary({ segments: { id, type: tableType }, params })
      .then(({ data }: ResponseType<CrmAllocationResponseType>) => {
        setLoading(false);
        setAllocationData({
          allocationDTOList: formatTotals(data?.allocationDTOList),
          totalExposure: Number(data.totalExposure),
          totalExposureForCompany: data.totalExposureForCompany,
        });
      })
      .catch((err: string): void => {
        message.error(err ?? "Something went wrong.", 3);
      });
  };

  const fetchAllocationKeys: GetAllocationTableKeysType = () => {
    getAllocationKeys({}).then(
      ({ data = [] }: ResponseType<AllocationKeyType[]>) => {
        setKeys(
          data.filter(({ allocationTableType, canDelete }) =>
            equals(allocationTableType, tableType)
          )
        );
      }
    );
  };

  useEffect(() => {
    fetchAllocationKeys();
  }, []);

  useEffect(() => {
    fetchAllocations(id, search);
  }, [id, search]);

  const columns = (
    keys: Record<string, AllocationKeyType>
  ): ColumnsType<CRMAllocationType> => [
    {
      title: tableColumnHeader("PORTFOLIO COMPANY NAME"),
      key: "portfolioName",
      render: (record, v) =>
        record?.isTotal ? (
          ""
        ) : (
          <>
            {!isNil(record.portfolioName) ? (
              <span>{record.portfolioName}</span>
            ) : (
              <span className={"text-muted"}>-</span>
            )}
          </>
        ),
      width: "20%",
      className: "group-hover:bg-blue-50 min-w-[150px]",
    },
    {
      key: "facilities",
      title: tableColumnHeader(
        tableType === AllocationTableType.FEES ? "FEES TYPE" : "FACILITY TYPE"
      ),
      width: "20%",
      className: "group-hover:bg-blue-50 min-w-[120px]",
      render: ({ allocationKeyDTO }: AllocationType, v): ReactNode => {
        return v.isTotal ? (
          <div>Sub Total</div>
        ) : (
          pathOr("", [allocationKeyDTO.id, "keyName"], keys)
        );
      },
      onCell: (data) => {
        return {
          align: data.isTotal ? "right" : "left",
        };
      },
    },
    {
      key: "Value",
      title: tableColumnHeader("VALUE"),
      width: "20%",
      className: "group-hover:bg-blue-50 min-w-[120px]",
      render: (record: CRMAllocationType): ReactNode =>
        getCellValue(
          record,
          pathOr("", [record.allocationKeyDTO.id, "fieldType"], keys)
        ),
    },
    {
      key: "exposure",
      title: tableColumnHeader("% Exposure"),
      width: "15%",
      className: "group-hover:bg-blue-50 min-w-[120px]",
      render: (record: CRMAllocationType): ReactNode =>
        showPercentage(+record.value, allocationData?.totalExposure),
    },
    {
      key: "overallExposure",
      title: tableColumnHeader("% Overall Exposure"),
      width: "15%",
      className: "group-hover:bg-blue-50 min-w-[120px]",
      render: (record: CRMAllocationType): ReactNode =>
        showPercentage(+record.value, allocationData?.totalExposureForCompany),
    },
  ];

  return (
    <RelationshipCard
      label={
        equals(tableType, AllocationTableType.FEES) ? "Fees" : "Allocation"
      }
      actions={
        <div className={"inline-flex items-center gap-x-2 ml-auto"}>
          <div className={"px-1"}>
            <Checkbox
              checked={showTotals}
              onChange={(e) => {
                setShowTotals(e.target.checked);
              }}
            />
            &nbsp;
            <span>Show Cumulative Totals</span>
          </div>
          <div className={"px-1"}>
            <Checkbox
              checked={showExposure}
              onChange={(e) => {
                setShowExposure(e.target.checked);
              }}
            />
            &nbsp;
            <span>Show Exposure</span>
          </div>
          <MultipleSelect
            options={keys
              .filter(({ canDelete }) => canDelete)
              .map(({ keyName, id }) => ({
                value: id,
                label: keyName,
              }))}
            value={selectedIds}
            placeholder={
              equals(tableType, AllocationTableType.FEES)
                ? "Select Funds"
                : "Select Facilities"
            }
            label={
              equals(tableType, AllocationTableType.FEES)
                ? "Funds"
                : "Facilities"
            }
            className={"max-w-xs"}
            onChange={setSelectedIds}
          />
          <MultipleSelect
            options={Object.entries(
              arrayToMapConversion(
                allocationData?.allocationDTOList ?? [],
                "portfolioId"
              )
            )
              ?.map(([k, v]) => ({
                value: k,
                label: (v as CRMAllocationType)?.portfolioName ?? "",
              }))
              ?.sort((a, b) => columnSort(a?.label, b?.label, "TEXT"))}
            value={selectedPortfolios}
            placeholder={"Select Portfolios"}
            label={"Portfolios"}
            className={"max-w-xs"}
            onChange={setSelectedPortfolios}
          />
          {/*<AllocationsExcel id={id} tableType={tableType} />*/}
        </div>
      }
    >
      <div className={`relative h-full overflow-y-auto flex border-t`}>
        <Table<CRMAllocationType>
          bordered={true}
          className={`w-full`}
          size={"small"}
          dataSource={(allocationData?.allocationDTOList ?? []).filter(
            ({ allocationKeyDTO, portfolioId = "", isTotal }) =>
              or(
                isEmpty(selectedIds),
                selectedIds.includes(allocationKeyDTO.id) && !isTotal
              ) &&
              or(
                isEmpty(selectedPortfolios),
                selectedPortfolios.includes(portfolioId)
              ) &&
              (isTotal ? showTotals : true)
          )}
          columns={columns(arrayToMapConversion(keys, "id")).filter((value) =>
            showExposure
              ? true
              : value.key !== "exposure" && value.key !== "overallExposure"
          )}
          loading={loading}
          pagination={false}
          rowClassName={(v) =>
            `${v.isTotal ? "!bg-gray-100 font-bold" : "group"} `
          }
          sticky={true}
          rowKey={({ id }) => id}
          scroll={{
            x: true,
          }}
          summary={() =>
            isEmptyOrNil(selectedIds) && isEmptyOrNil(selectedPortfolios) ? (
              <Table.Summary fixed>
                <Table.Summary.Row className={"bg-gray-200 font-bold"}>
                  <Table.Summary.Cell index={0} colSpan={2} align={"right"}>
                    Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    {allocationData?.allocationDTOList?.[0]?.currency
                      ? AnalyticsCurrencyType[
                          allocationData?.allocationDTOList?.[0]?.currency
                        ]
                      : ""}
                    &nbsp;
                    {formatDecimalAndAddCommas(
                      allocationData?.totalExposure || 0
                    )}
                    &nbsp;
                    {allocationData?.allocationDTOList?.[0]?.unit
                      ? UnitLabelType[
                          allocationData?.allocationDTOList?.[0]?.unit
                        ]
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <div
                      className={"flex flex-row items-center justify-between"}
                    >
                      100%
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <div
                      className={"flex flex-col items-start justify-between"}
                    >
                      <div>
                        {showPercentage(
                          allocationData?.totalExposure,
                          allocationData?.totalExposureForCompany
                        )}
                      </div>
                      <div className={"flex items-center gap-2"}>
                        <div className={"text-xs text-muted"}>
                          {allocationData?.allocationDTOList?.[0]?.currency
                            ? AnalyticsCurrencyType[
                                allocationData?.allocationDTOList?.[0]?.currency
                              ]
                            : ""}
                          &nbsp;
                          {formatDecimalAndAddCommas(
                            allocationData?.totalExposureForCompany || 0
                          )}
                          &nbsp;
                          {allocationData?.allocationDTOList?.[0]?.unit
                            ? UnitLabelType[
                                allocationData?.allocationDTOList?.[0]?.unit
                              ]
                            : ""}
                        </div>
                        <Tooltip
                          placement={"topLeft"}
                          title={"Total allocation amount across portfolios"}
                        >
                          <InfoCircleOutlined color={"#1890FF"} />
                        </Tooltip>
                      </div>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            ) : (
              <></>
            )
          }
        />
      </div>
    </RelationshipCard>
  );
};

type GetAllocationTableKeysType = () => void;
type AllocationInfoTableType = {
  tableType: AllocationTableType;
  companyId: string;
};
type GetCellValueType = (
  val: CRMAllocationType,
  fieldType: string,
  exposure?: number
) => ReactNode;
