import React, { FC, useContext, useEffect, useState } from "react";
import { NoteDataType, NotesType, UserType } from "../../../../utils/types";
import {
  getDealTeam,
  getNotes,
  getRecentNotes,
  searchNoteInTransaction,
} from "../../../../services/services";
import { Alert, Button, Empty, message, Spin, Tooltip } from "antd";
import { Thread } from "../../../general/Thread";
import { TransactionContext } from "../../../../context/TransactionContext";
import { fetchWithIdType, ResponseType } from "../../../../utils/uiTypes";
import { NotesThread } from "./NotesThread";
import { updateItemInArray, valOrDefault } from "../../../../utils/utils";
import { CustomSpin } from "../../../general/CustomSpin";
import { useHistory } from "react-router";
import { usePageTitle } from "../../../../customHooks/usePageTitle";
import { threadPrefix } from "../../../../utils/componentUtils";
import { PrintNotes } from "./PrintNotes";
import { ElementType } from "../../../../utils/enums";
import { TextOverFlowHandle } from "../../../general/TextOverFlowHandle";
import { findIndex, isNil, pathEq } from "ramda";
import Search from "antd/es/input/Search";
import { LoadingOutlined } from "@ant-design/icons";

export const Notes: FC = function () {
  usePageTitle("Notes");

  const { transaction } = useContext(TransactionContext);

  const history = useHistory();

  const [notes, setNotes] = useState<NotesType[]>([]);
  const [currentActiveLender, setCurrentActiveLender] = useState<number | null>(
    0
  );
  const [loading, setLoading] = useState(false);
  const [dealTeam, setDealTeam] = useState<UserType[]>([]);
  const [recentNotes, setRecentNotes] = useState<NoteDataType[]>([]);
  const [recentLoading, setRecentLoading] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
  const [searchActive, setSearchActive] = useState<boolean>(false);

  const fetchDealTeam: fetchWithIdType = (id) => {
    getDealTeam({
      segments: {
        id,
      },
      params: {
        isTeamPage: true,
        pageName: "notes",
      },
    })
      .then(({ data = [] }: ResponseType<UserType[]>) => {
        setDealTeam(data);
      })
      .catch((error: string) => {
        message.error(error ? error : "Failed Fetching the Deal Team!");
      });
  };
  const searchNote: searchNoteType = (transactionId, searchKey) => {
    searchNoteInTransaction({
      segments: { transactionId },
      body: JSON.stringify({
        searchKey: searchKey,
        note: "",
      }),
    })
      .then(({ data = [] }: ResponseType<NotesType[]>) => {
        setNotes(data);
        setSearchActive(true);
      })
      .catch(console.error);
  };

  const fetchNotes: fetchNotesType = (id) => {
    setLoading(true);

    getNotes({
      segments: {
        id,
      },
    })
      .then(({ data = [] }: ResponseType<NotesType[]>) => {
        setNotes(data);
      })
      .then(() => setLoading(false))
      .catch((error: string) => {
        message.error(valOrDefault("Failed loading notes...", error));
      });
  };

  const fetchRecentNotes: fetchRecentNotesType =
    (notesElementId) => (pageIndex) => {
      setRecentLoading(true);
      getRecentNotes({
        params: {
          notesElementType: ElementType.PETRANSACTION,
          notesElementId,
          pageIndex,
          limit: 10,
        },
      })
        .then(({ data = [] }: ResponseType<NoteDataType[]>) => {
          if (pageIndex === 0) {
            setRecentNotes(data);
          } else {
            setRecentNotes((notes) => [...notes, ...data]);
          }
          setRecentLoading(false);
        })
        .catch((error: any) => {
          console.error(error);
          setRecentLoading(false);
        });
    };

  const navigateToLender: navigateToLenderType = (lenderId, noteId) => {
    return new Promise((resolve, reject) => {
      const lenderIndex = findIndex(
        pathEq(["lenderDTO", "id"], lenderId),
        notes ?? []
      );
      setCurrentActiveLender(lenderIndex);
      if (lenderIndex !== -1 && noteId) {
        const noteIndex = findIndex(
          pathEq(["id"], noteId),
          notes[lenderIndex]?.notes ?? []
        );
        history.push({
          pathname: `/transactions/${transaction?.peTransactionId}/notes`,
          search: `?lenderId=${lenderId}&noteId=${noteId}`,
        });
        resolve(noteIndex === -1 ? 0 : noteIndex);
      } else if (lenderIndex !== -1) {
        resolve(0);
      }
      reject();
    });
  };

  useEffect(() => {
    if (transaction) {
      fetchNotes(transaction?.peTransactionId);
      fetchDealTeam(transaction?.peTransactionId);
    }
  }, [transaction, transaction?.peTransactionId]);

  useEffect(() => {
    const lenderId = new URLSearchParams(location.search).get("lenderId");
    if (!isNil(notes) && lenderId) {
      navigateToLender(lenderId);
    }
  }, [notes, location.search]);

  return (
    <div className={"relative max-h-full h-full w-full flex flex-col"}>
      <Alert
        className={"py-1.5 text-xs"}
        type={"warning"}
        showIcon={true}
        banner
        message={"The notes are private within your organization."}
      />
      <CustomSpin
        loading={loading}
        loadingText={
          transaction ? "Fetching Notes..." : "Fetching Transaction..."
        }
      />
      <div
        className={`p-0 grid grid-cols-12 h-full max-h-full ${
          notes?.length === 0 && "flex items-center"
        }`}
      >
        {notes?.length === 0 && !loading ? (
          <Empty
            className={"col-span-12 text-gray-400"}
            description={"No Institutions History!"}
          >
            <Button
              className={"bg-primary hover:bg-hover border-0 text-white"}
              icon={<i className="fas fa-plus mr-1" />}
              onClick={(): void => {
                transaction &&
                  history.push(
                    `/transactions/${transaction.peTransactionId}/institutions`
                  );
              }}
            >
              Add New Institution
            </Button>
          </Empty>
        ) : (
          <>
            <div
              className={
                "bg-secondary-background col-span-1 md:col-span-2 border-r flex flex-col h-full z-40 border-r border-gray-200 overflow-auto max-h-full"
              }
            >
              <Thread
                active={isNil(currentActiveLender)}
                onClick={(): void => {
                  setCurrentActiveLender(null);
                  history.push(
                    `/transactions/${transaction?.peTransactionId ?? ""}/notes`
                  );
                }}
                colorLiteral={"default"}
                prefix={threadPrefix(
                  "R",
                  "default",
                  isNil(currentActiveLender)
                )}
                icon={
                  recentLoading && (
                    <Spin
                      spinning={true}
                      size={"small"}
                      indicator={<LoadingOutlined spin={true} />}
                    />
                  )
                }
              >
                <TextOverFlowHandle text={"Recent Notes"} />
              </Thread>
              <div
                className={
                  "hidden md:flex flex-row gap-x-2 items-center text-subtitle uppercase mx-3 mt-3 mb-2 text-xs text-gray-400 font-medium "
                }
              >
                MY TEAM
              </div>
              {notes
                ?.filter(
                  ({ lenderDTO }) =>
                    lenderDTO?.companyDTO?.id !== transaction?.companyId &&
                    lenderDTO.id === transaction?.peTransactionId
                )
                .map(({ lenderDTO, notes }) => (
                  <Thread
                    key={lenderDTO.id}
                    active={currentActiveLender === 0}
                    onClick={(): void => {
                      setCurrentActiveLender(0);
                      setRecentNotes([]);
                      history.push({
                        pathname: `/transactions/${transaction?.peTransactionId}/notes`,
                        search: `?lenderId=${lenderDTO.id}`,
                      });
                    }}
                    colorLiteral={
                      lenderDTO?.id === transaction?.peTransactionId
                        ? "default"
                        : lenderDTO.name?.charAt(0)?.toUpperCase()
                    }
                    prefix={threadPrefix(
                      lenderDTO.name[0],
                      lenderDTO?.id === transaction?.peTransactionId
                        ? "default"
                        : lenderDTO.name[0],
                      currentActiveLender === 0
                    )}
                    icon={
                      <span
                        className={
                          "flex flex-row items-center gap-x-1 text-gray-400"
                        }
                      >
                        <i className="far fa-file" />
                        {notes?.length ? notes?.length : 0}
                      </span>
                    }
                  >
                    <TextOverFlowHandle text={lenderDTO?.name ?? ""} />
                  </Thread>
                ))}
              <div
                className={
                  "hidden md:flex flex-row justify-between gap-x-2 items-center text-subtitle uppercase mx-3 mt-3 mb-2 text-xs text-gray-400 font-medium "
                }
              >
                <div>
                  INSTITUTIONS
                  <Tooltip
                    placement={"bottom"}
                    title={"Institutions cannot view this"}
                  >
                    <i className="fas fa-lock ml-2" />
                  </Tooltip>
                </div>
                <div>
                  {/*<Button*/}
                  {/*  type={"text"}*/}
                  {/*  icon={<i className="fas fa-search" />}*/}
                  {/*  className={`${ACTION_BUTTON_CSS} text-gray-400 ml-auto `}*/}
                  {/*  onClick={(): void => setShowSearchBox(!showSearchBox)}*/}
                  {/*/>*/}
                  {transaction && notes?.length > 0 && (
                    <PrintNotes
                      notes={notes}
                      transactionId={transaction.peTransactionId}
                    />
                  )}
                </div>
              </div>
              <div>
                {transaction && showSearchBox && (
                  <Search
                    className={"w-full"}
                    allowClear={true}
                    placeholder="Input search text"
                    onSearch={(value): void => {
                      searchNote(transaction?.peTransactionId, value);
                    }}
                  />
                )}
              </div>
              <div
                className={
                  "relative m-0 p-0 flex-initial max-h-full overflow-y-scroll hide-scrollbar"
                }
              >
                {notes
                  ?.filter(
                    ({ lenderDTO }) =>
                      lenderDTO?.companyDTO?.id !== transaction?.companyId &&
                      lenderDTO.id !== transaction?.peTransactionId
                  )
                  .map(({ lenderDTO, notes }, index) => (
                    <Thread
                      key={lenderDTO.id}
                      active={currentActiveLender === index + 1}
                      onClick={(): void => {
                        setCurrentActiveLender(index + 1);
                        setRecentNotes([]);
                        history.push({
                          pathname: `/transactions/${transaction?.peTransactionId}/notes`,
                          search: `?lenderId=${lenderDTO.id}`,
                        });
                      }}
                      colorLiteral={
                        lenderDTO?.id === transaction?.peTransactionId
                          ? "default"
                          : lenderDTO.name?.charAt(0)?.toUpperCase()
                      }
                      prefix={threadPrefix(
                        lenderDTO.name[0],
                        lenderDTO?.id === transaction?.peTransactionId
                          ? "default"
                          : lenderDTO.name[0],
                        currentActiveLender === index + 1
                      )}
                      icon={
                        <span
                          className={
                            "flex flex-row items-center gap-x-1 text-gray-400"
                          }
                        >
                          <i className="far fa-file" />
                          {notes?.length ? notes?.length : 0}
                        </span>
                      }
                    >
                      <TextOverFlowHandle text={lenderDTO?.name ?? ""} />
                    </Thread>
                  ))}
              </div>
            </div>
            <div className={"col-span-10 max-h-full h-full overflow-auto"}>
              <div className={"flex flex-col h-full w-full"}>
                {transaction && notes.length > 0 && (
                  <NotesThread
                    isDealTeam={true}
                    enableDeepLink={true}
                    navigateToLender={navigateToLender}
                    readOnly={false}
                    transactionId={transaction?.peTransactionId}
                    dealTeam={dealTeam}
                    notes={
                      currentActiveLender !== null
                        ? notes[currentActiveLender]
                        : null
                    }
                    recentNotes={recentNotes}
                    fetchRecentNotes={fetchRecentNotes(
                      transaction?.peTransactionId ?? ""
                    )}
                    activeLender={currentActiveLender}
                    setNotes={(updatedNote, index): void => {
                      setNotes(
                        notes
                          ? [
                              ...updateItemInArray(index, notes, {
                                ...updatedNote,
                                notes: updatedNote.notes,
                              }),
                            ]
                          : []
                      );
                    }}
                  />
                )}
              </div>
              {searchActive && notes.length === 0 && (
                <Empty
                  className={"col-span-12 text-gray-400"}
                  description={"No Match Found!"}
                ></Empty>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

type fetchRecentNotesType = (id: string) => (page: number) => void;
type fetchNotesType = (id: string) => void;
type searchNoteType = (transactionId: string, searchKey: string) => void;
type navigateToLenderType = (
  lenderId: string,
  noteId?: string
) => Promise<number>;
