import React, { FC, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Spin,
} from "antd";
import { useHistory } from "react-router";
import { LOGIN_PAGE_URL } from "../utils/redirect";
import { register } from "../services/services";
import { ErrorType, ResponseType } from "../utils/uiTypes";
import { UserType } from "../utils/types";
import { Terms } from "../static/Terms";
import { usePageTitle } from "../customHooks/usePageTitle";
import {
  namePatternRule,
  parsePhoneNumber,
  PhoneNumber,
  phoneNumberPatternRule,
} from "../utils/formUtils";
import TermgridLogo from "../images/logo/TermgridLogoLight.svg";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { PhoneNumberInput } from "../components/general/PhoneNumberInput";

export const Register: FC = function () {
  usePageTitle("Register");

  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | null>(null);
  const [passwordCriteria, setPasswordCriteria] = useState<criteriaType[]>([
    { valid: false, regex: /^.{8,}$/, text: "Must be at least 8 characters." },
    {
      valid: false,
      regex: /^.*\d.*$/,
      text: "Must contain at least 1 Number.",
    },
    {
      valid: false,
      regex: /^.*[A-Z].*$/,
      text: "Must contain at least 1 Letter Upper Case.",
    },
    {
      valid: false,
      regex: /^.*[a-z].*$/,
      text: "Must contain at least 1 Letter Lower Case.",
    },
    {
      valid: false,
      regex: /^.*[\W_].*$/,
      text: "Must contain at least 1 Special Character.",
    },
  ]);

  const onSuccess: onSuccessType = (user) => {
    message.success(
      `You have been registered as ${user.firstName} ${user.lastName}`,
      3
    );
    message.info("Please verify to login into your account.", 3);
    history.push(LOGIN_PAGE_URL);
  };

  const onFailure: onFailureType = (message: string) => {
    setError({ error: true, message });
    setLoading(false);
  };

  const signUp: registerType = (
    firstName,
    lastName,
    phoneNumber,
    email,
    password,
    repassword
  ) => {
    register({
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        phoneNumber: parsePhoneNumber(phoneNumber),
        password,
        repassword,
      }),
    })
      .then(({ data }: ResponseType<UserType>) => {
        onSuccess(data);
      })
      .catch((error: string) => {
        onFailure(error);
      });
  };

  return (
    <div
      style={{
        backgroundImage: "url('../../images/hero.jpg')",
      }}
      className={
        "h-screen w-screen bg-cover bg-center flex flex-col overflow-y-auto"
      }
    >
      <img
        className={"mx-auto pt-5 w-48"}
        src={TermgridLogo}
        alt={"Termgrid Logo"}
      />
      <div className={"px-5 mx-auto"}>
        <div className={"bg-white border mx-auto mt-16 p-10 max-w-lg"}>
          <div>
            <div className={"text-center text-2xl font-medium"}>Register</div>
            <div className={"text-center text-sm font-normal mt-2 mb-8"}>
              Please enter your email and password for signing up
            </div>
            <Form
              form={form}
              initialValues={{ remember: true }}
              className={"flex flex-col gap-y-1"}
            >
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    type: "string",
                    message: "Please enter your first name!",
                  },
                  namePatternRule,
                ]}
                validateTrigger={[]}
              >
                <Input placeholder={"First Name"} autoFocus={true} />
              </Form.Item>

              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    type: "string",
                    message: "Please enter your last name!",
                  },
                  namePatternRule,
                ]}
                validateTrigger={[]}
              >
                <Input placeholder={"Last Name"} />
              </Form.Item>

              <Form.Item
                name="phoneNumber"
                rules={[
                  phoneNumberPatternRule(true),
                  {
                    required: true,
                    message: "Please enter your phone number!",
                  },
                ]}
                initialValue={{
                  short: "GB",
                  code: "44",
                }}
              >
                <PhoneNumberInput placeholder={"Phone Number"} />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter your email address!",
                  },
                ]}
                validateTrigger={[]}
              >
                <Input placeholder={"Email"} type={"email"} />
              </Form.Item>

              <Form.Item
                name="password"
                className={"mb-0"}
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                    validator: (): Promise<void> =>
                      passwordCriteria.filter((item) => !item.valid).length > 0
                        ? Promise.reject(new Error(""))
                        : Promise.resolve(),
                  },
                ]}
                validateTrigger={[]}
              >
                <Input.Password
                  onChange={(e): void =>
                    setPasswordCriteria((list) =>
                      list.map((criteria) => ({
                        ...criteria,
                        valid: criteria.regex.test(e.target.value),
                      }))
                    )
                  }
                  placeholder={"Password"}
                  type={"password"}
                />
              </Form.Item>
              <div className={"mb-6 mt-2"}>
                {passwordCriteria.map((item) => (
                  <p
                    key={item.text}
                    className={`${
                      item.valid ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {item.valid ? (
                      <CheckCircleOutlined />
                    ) : (
                      <CloseCircleOutlined />
                    )}{" "}
                    {item.text}
                  </p>
                ))}
              </div>
              <Form.Item
                name="repassword"
                rules={[
                  {
                    required: true,
                    message: "Please re-enter the password!",
                  },
                ]}
                validateTrigger={[]}
              >
                <Input.Password
                  placeholder={"Re-enter Password"}
                  type={"password"}
                />
              </Form.Item>
              <Form.Item
                name={"terms"}
                valuePropName={"checked"}
                rules={[
                  {
                    validator: (_, value): Promise<void> =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error("Must accept agreement")),
                  },
                ]}
              >
                <Checkbox>
                  I have read and agree to the{" "}
                  <Button
                    type={"link"}
                    className={"px-0 hover:border-b"}
                    onClick={(): void => {
                      Modal.info({
                        width: 900,
                        title: (
                          <>
                            <p className={"text-xl my-0"}>Terms of Service</p>
                            <span className="text-sm text-gray-500">
                              Effective Date: April 2022
                            </span>
                          </>
                        ),
                        content: <Terms />,
                        okText: "Close",
                        okButtonProps: {
                          className: "bg-primary hover:bg-hover text-white",
                        },
                      });
                    }}
                  >
                    terms of service
                  </Button>
                  .
                </Checkbox>
              </Form.Item>
              {error && (
                <Alert
                  className={"mb-8"}
                  message={error.message}
                  closable={true}
                  type="error"
                />
              )}
              <Form.Item>
                <Button
                  className={
                    "w-32 bg-primary hover:bg-hover text-white border-0"
                  }
                  disabled={loading}
                  htmlType={"submit"}
                  onClick={(): void => {
                    setError(null);
                    setLoading(true);
                    form
                      .validateFields()
                      .then(() => {
                        if (
                          form.getFieldValue("password") ===
                          form.getFieldValue("repassword")
                        ) {
                          signUp(
                            form.getFieldValue("firstName"),
                            form.getFieldValue("lastName"),
                            form.getFieldValue("phoneNumber"),
                            form.getFieldValue("email"),
                            form.getFieldValue("password"),
                            form.getFieldValue("repassword")
                          );
                        } else {
                          setError({
                            error: true,
                            message: "Password do not match!",
                          });
                          throw new Error();
                        }
                      })
                      .catch((info) => {
                        console.error("Validate Failed:", info);
                        setLoading(false);
                      });
                  }}
                >
                  {loading && <Spin className={"mr-2"} size={"small"} />}
                  Register
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

type onSuccessType = (data: UserType) => void;
type onFailureType = (error: string) => void;
type registerType = (
  firstName: string,
  lastName: string,
  phoneNumber: PhoneNumber,
  email: string,
  password: string,
  repassword: string
) => void;
type criteriaType = {
  valid: boolean;
  regex: RegExp;
  text: string;
};
