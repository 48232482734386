import React, {
  FC,
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Button, Input } from "antd";
import {
  enforceFontSizeBounds,
  heatMapComparison,
  renameStyleProperties,
} from "../../../utils/termsheet";
import { valOrDefault } from "../../../utils/utils";
import RedLineContent from "./RedLineContent";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const Cell: FC<CellType> = function ({
  index,
  elementId,
  text = "",
  className = "",
  width = 300,
  style = JSON.stringify({}),
  heatMap = false,
  compareText = "",
  editable = false,
  selectedCell = false,
  selectable = false,
  selectedSeries = false,
  showQuickSelection = false,
  showRedLine = false,
  onChange,
  onSelect,
}: CellType) {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [textCompare, setTextCompare] = useState(false);
  const [selected, setSelected] = useState(false);
  const [value, setValue] = useState<string>("");
  const [formattedStyle, setFormattedStyle] = useState<StyleType>({});

  const cellStyle = {
    color: "rgba(0, 0, 0, 0.85)",
    ...formattedStyle,
    background: heatMap
      ? "transparent"
      : valOrDefault("transparent", formattedStyle["background"]),
  };

  const onClick = () => {
    setSelected(true);
    onSelect(index, formattedStyle, elementId);
  };

  const onClickOrFocus = () => {
    onClick();
    const element = ref.current as HTMLTextAreaElement;
    if (element) {
      element.focus();
    }
  };

  useEffect(() => {
    setValue(text);
  }, [text]);

  useEffect(() => {
    setFormattedStyle(
      enforceFontSizeBounds(renameStyleProperties(JSON.parse(style)))
    );
  }, [style]);

  useLayoutEffect(() => {
    heatMap && setTextCompare(heatMapComparison(text, compareText));
  }, [heatMap, compareText, text]);

  useEffect(() => {
    setSelected(selectedCell);
  }, [selectedCell]);
  return (
    <div
      style={{
        width,
        ...formattedStyle,
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
      }}
      className={`relative min-h-full h-full group overflow-hidden ${className} ${
        selectedSeries &&
        "bg-primary bg-opacity-50 outline-primary border-y border-primary"
      } ${selected && "border border-2 border-primary"} ${
        heatMap &&
        (textCompare ? "bg-success bg-opacity-50" : "bg-danger bg-opacity-50")
      }`}
    >
      {showRedLine && !selected ? (
        <RedLineContent
          text={text}
          style={cellStyle}
          compareText={compareText}
          onClick={onClickOrFocus}
        />
      ) : (
        <TextArea
          disabled={!selectable}
          ref={ref}
          onFocus={onClickOrFocus}
          onBlur={(e) => {
            if (value !== text) {
              onChange(index, value, elementId);
            }
            // setSelected(false);
          }}
          onChange={(e) => {
            editable && setValue(e.currentTarget.value);
          }}
          autoSize={true}
          bordered={false}
          className={`z-10 min-h-full hide-scrollbar rounded-none py-1 resize-none border-none outline-none ${className} ${
            editable ? "cursor-text" : "caret-transparent cursor-pointer"
          }`}
          style={{ paddingLeft: 0, paddingRight: 0, ...cellStyle }}
          value={value}
        />
      )}
      {editable && showQuickSelection && (
        <div
          className={
            "absolute right-1 bottom-1 z-50 opacity-0 group-hover:opacity-100"
          }
        >
          <div className={"flex flex-row"}>
            <Button
              icon={<CheckOutlined />}
              type={"primary"}
              size={"small"}
              ghost
              onClick={() => onChange(index, "OK", elementId)}
            >
              OK
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

type CellType = {
  index: number;
  elementId: string;
  text: string;
  style?: string;
  className?: string;
  width?: number;
  heatMap?: boolean;
  compareText?: string;
  editable?: boolean;
  selectedCell: boolean;
  selectable?: boolean;
  selectedSeries?: boolean;
  showQuickSelection?: boolean;
  showRedLine?: boolean;
  onChange: (i: number, o: string, elementId: string) => void;
  onSelect: (i: number, style: StyleType, elementId: string) => void;
};

type StyleType = { [k: string]: string };

export default memo(Cell);
