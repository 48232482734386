export const getParams = (
  search: Record<string, string | number | Array<string> | Array<number>>
) =>
  Object.entries(search)
    .map(([key, value]) =>
      typeof value === "string" || typeof value === "number"
        ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        : value.length > 0
        ? value
            .map((o) => `${encodeURIComponent(key)}=${encodeURIComponent(o)}`)
            .join("&")
        : `${encodeURIComponent(key)}=Not Specified`
    )
    .join("&");
