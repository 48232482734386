import React, { FC, useContext, useEffect, useState } from "react";
import { InputField, InputType } from "../../../relationship/InputField";
import {
  AllocationTableType,
  CompanyEnumType,
  CurrencySymbolType,
  CurrencyType,
  noYesLabelType,
  noYesType,
  ownershipLabelType,
  ownershipType,
  PermissionType,
  UnitLabelType,
  UnitTitleType,
  UnitType,
} from "../../../../utils/enums";
import { ResponseType, voidType } from "../../../../utils/uiTypes";
import {
  getAllocationKeys,
  getCountries,
  getDebtStructure,
  getDebtType,
  getIndustries,
  getOrCreateKPI,
  updateKPI,
} from "../../../../services/services";
import { Alert, Button, message, Tooltip } from "antd";
import { ParamsType } from "../../../../utils/relationship";
import { Prompt, useParams } from "react-router";
import { usePageTitle } from "../../../../customHooks/usePageTitle";
import {
  AllocationKeyType,
  CountryType,
  DebtType,
  IndustryType,
  MonetaryRangeType,
} from "../../../../utils/types";
import { UserContext } from "../../../../context/UserContext";
import { TransactionContext } from "../../../../context/TransactionContext";
import { useConfirmReload } from "../../../../customHooks/useConfirmReload";
import { isEmpty } from "ramda";
import { isEmptyOrNil } from "../../../../utils/utils";

export const KpiComponent: FC<KpiComponentType> = ({
  orientation = "horizontal",
  showBanner = false,
}) => {
  usePageTitle("Deal Metrics");
  const { permissions, transaction } = useContext(TransactionContext);
  const [industries, setIndustries] = useState<IndustryType[]>([]);
  const [kpi, setKpi] = useState<kpiType | null>(null);
  const [loading, setLoading] = useState(true);
  const [allocationKeys, setAllocationKeys] = useState<AllocationKeyType[]>([]);
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [debtStructures, setDebtStructures] = useState<DebtType[]>([]);
  const [debtTypes, setDebtTypes] = useState<DebtType[]>([]);
  const [isUpdated, setIsUpdated] = useState(false);

  const { id }: ParamsType = useParams();
  const { user } = useContext(UserContext);

  useConfirmReload(!!isUpdated, [isUpdated]);

  const canEdit =
    permissions.includes(PermissionType.ADMIN_PETRANSACTION) ||
    permissions.includes(PermissionType.ADMIN_LENDER);

  const fetchKpi: voidType = () => {
    setLoading(() => true);
    getOrCreateKPI({
      segments: {
        peTransactionId: id,
      },
    })
      .then(({ data }: ResponseType<kpiType>) => {
        setKpi(data);
        setLoading(() => false);
      })
      .catch((error: string) => {
        setLoading(() => false);

        message.error(error ?? "Error Fetching Kpis");
      });
  };

  const fetchIndustries: voidType = () => {
    getIndustries({})
      .then(({ data = [] }: ResponseType<IndustryType[]>) => {
        setIndustries(data);
      })
      .catch((error: string) => {
        message.error(error ?? "Error Fetching Industries");
      });
  };

  const fetchAllocationKeys: voidType = () => {
    getAllocationKeys({}).then(
      ({ data = [] }: ResponseType<AllocationKeyType[]>) =>
        setAllocationKeys(data)
    );
  };

  const changeKpi: changeKPIType = (property, value) => {
    setIsUpdated(true);
    setKpi((prevKpi) => (prevKpi ? { ...prevKpi, [property]: value } : null));
  };

  const updateKpi: voidType = () => {
    if (isEmptyOrNil(kpi?.dealName))
      return message.error("Deal Name should not be empty");

    setLoading(() => true);
    updateKPI({
      body: JSON.stringify(kpi),
    })
      .then(() => {
        setLoading(false);
        setIsUpdated(false);
        message.success("Deal Metrics Updated Successfully!");
      })
      .catch((error: string) => {
        console.error(error);
        message.error(error ? error : "Failed Updating the kpi!");
        setLoading(false);
      });
  };

  const fetchCountries: voidType = () => {
    getCountries({})
      .then(({ data = [] }: ResponseType<CountryType[]>) => {
        setCountries(data);
      })
      .catch(console.error);
  };
  const fetchDebtStructure: voidType = () => {
    getDebtStructure({})
      .then(({ data = [] }: ResponseType<DebtType[]>) => {
        setDebtStructures(data);
      })
      .catch(console.error);
  };
  const fetchDebtType: voidType = () => {
    getDebtType({})
      .then(({ data = [] }: ResponseType<DebtType[]>) => {
        setDebtTypes(data);
      })
      .catch(console.error);
  };
  useEffect(() => {
    fetchIndustries();
    fetchKpi();
    fetchAllocationKeys();
    fetchCountries();
    fetchDebtStructure();
    fetchDebtType();
  }, []);

  return (
    <div
      className={
        "relative max-h-full h-full w-full overflow-y-scroll hide-scrollbar"
      }
    >
      <Prompt
        when={isUpdated}
        message={"Changes you made might not be saved!"}
      />
      <div className={"h-full max-h-full relative flex flex-col p-6 mx-auto"}>
        <div className={"flex flex-row justify-between mb-3 p-0 w-full"}>
          <p className={"text-xl font-medium flex"}>Deal Metrics</p>
          {canEdit && (
            <Button
              type={"primary"}
              className={"bg-primary hover:bg-hover text-white"}
              onClick={updateKpi}
            >
              Save
            </Button>
          )}
        </div>

        {showBanner && (
          <Alert
            className={"py-1.5 text-xs"}
            type={"warning"}
            showIcon={true}
            banner={true}
            message="The data entered here is private to your institution and will help in your data analytics and relationship management."
          />
        )}
        <div className={"h-full overflow-y-auto bg-white"}>
          <div
            className={`flex ${
              orientation === "horizontal" ? "flex-row" : "flex-col"
            } gap-4 bg-gray-50 min-h-full`}
          >
            <div className="bg-white border border-gray-200 w-full">
              <div
                className={
                  " w-full grow flex items-stretch flex-col bg-gray-50 p-2 text-gray-500 text-sm"
                }
              >
                General Information
              </div>
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Deal name"}
                placeholder="Add Deal name"
                value={kpi?.dealName}
                type={InputType.TEXT}
                onChange={(e): void => {
                  changeKpi("dealName", e);
                }}
              />
              <Tooltip
                title={
                  "Deal Description - e.g. 'Leading software company for Private Capital markets'"
                }
                placement={"topLeft"}
              >
                <div>
                  <InputField
                    disabled={!canEdit}
                    loading={loading}
                    label={"Deal Description"}
                    placeholder="Add Deal Description"
                    value={kpi?.description}
                    type={InputType.DESCRIPTION}
                    onChange={(e): void => {
                      changeKpi("description", e);
                    }}
                  />
                </div>
              </Tooltip>

              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Industries"}
                type={InputType.MULTIPLE_SELECT}
                placeholder={"For example 'Energy, Education'"}
                onChange={(value: industrySelectType): void => {
                  if (Array.isArray(value)) {
                    const selectedIndustries = industries?.filter(
                      (industry: IndustryType) =>
                        value.includes(industry?.id.toString())
                    );

                    changeKpi("industries", selectedIndustries);
                  }
                }}
                value={kpi?.industries?.map(
                  (industry: IndustryType) => industry.id
                )}
                options={industries.map(({ id, name }: IndustryType) => ({
                  label: name,
                  key: id,
                }))}
              />
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Transaction type"}
                value={kpi?.transactionTypeId}
                type={InputType.SELECT}
                placeholder={"For example 'New'"}
                onChange={(e): void => {
                  changeKpi("transactionTypeId", e);
                }}
                options={allocationKeys
                  .filter(
                    ({ allocationTableType }) =>
                      allocationTableType === AllocationTableType.TRANSACTION
                  )
                  .map(({ id, keyName }) => ({
                    label: keyName,
                    key: id,
                  }))}
              />

              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Investment type"}
                type={InputType.SELECT}
                placeholder={"For example 'Growth'"}
                onChange={(e): void => {
                  changeKpi("investmentTypeId", e);
                }}
                value={kpi?.investmentTypeId}
                options={allocationKeys
                  .filter(
                    ({ allocationTableType }) =>
                      allocationTableType === AllocationTableType.INVESTMENT
                  )
                  .map(({ id, keyName }) => ({
                    label: keyName,
                    key: id,
                  }))}
              />
              {user?.companyDTO?.companyType !== CompanyEnumType.SPONSOR && (
                <InputField
                  disabled={!canEdit}
                  loading={loading}
                  label={"SPONSOR-BACKED"}
                  value={kpi?.sponsorBacked}
                  placeholder={"Add sponsor backed"}
                  options={Object.keys(noYesType).map((value) => ({
                    key: value,
                    label: noYesLabelType[value as noYesType],
                  }))}
                  type={InputType.SELECT}
                  onChange={(e): void => {
                    changeKpi("sponsorBacked", e);
                  }}
                />
              )}

              <InputField
                // to be made editable multi select of companyIds in future
                disabled={true}
                loading={loading}
                label={"SPONSOR/CORPORATE"}
                // sponsor/corporate is txn creator company , should display value from kpi's sponsor_corporate and not borrower
                value={kpi?.sponsorCorporate}
                placeholder={"Add SPONSOR/CORPORATE"}
                type={InputType.TEXT}
                onChange={(e): void => {
                  changeKpi("sponsorCorporate", e);
                }}
              />
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Public vs Private"}
                value={kpi?.ownershipType}
                placeholder={"Public vs Private"}
                options={Object.keys(ownershipType).map((value) => ({
                  key: value,
                  label: ownershipLabelType[value as ownershipType],
                }))}
                type={InputType.SELECT}
                onChange={(e): void => {
                  changeKpi("ownershipType", e);
                }}
              />
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Borrower"}
                placeholder={"For example 'Borrower'"}
                // all kpi fields should display from kpi only and not txn data
                value={kpi?.borrowerName}
                type={InputType.TEXT}
                onChange={(e): void => {
                  changeKpi("borrowerName", e);
                }}
              />
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Borrower Domicile"}
                type={InputType.SELECT}
                placeholder={"For example 'United States'"}
                options={countries
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map(({ name, imageUrl }) => ({
                    icon: imageUrl,
                    label: name,
                    key: name,
                  }))}
                onChange={(e): void => {
                  changeKpi("borrowerDomicile", e);
                }}
                value={kpi?.borrowerDomicile}
              />
              {transaction?.creatorCompanyId === kpi?.companyId && (
                <InputField
                  loading={loading}
                  label={"Creation Date"}
                  type={InputType.DATE}
                  disabled
                  placeholder={"Add creation Date"}
                  onChange={(e): void => {
                    changeKpi("creationDate", e);
                  }}
                  value={
                    kpi?.creationDate
                      ? new Date(kpi?.creationDate).getTime()
                      : null
                  }
                />
              )}
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Expected Signing Date"}
                type={InputType.DATE}
                placeholder={"Add Expected Signing Date"}
                onChange={(e): void => {
                  changeKpi("signingDate", e);
                }}
                value={
                  kpi?.signingDate ? new Date(kpi?.signingDate).getTime() : null
                }
              />
            </div>
            <div className="bg-white border border-gray-200 w-full">
              <div
                className={
                  " w-full grow flex items-stretch flex-col bg-gray-50 p-2 text-gray-500 text-sm"
                }
              >
                Financial Information
              </div>
              <InputField
                loading={loading}
                disabled={!canEdit}
                label={"Facility type(s)"}
                type={InputType.MULTIPLE_SELECT}
                placeholder={
                  "Select Facility types, created for your institution"
                }
                onChange={(value: industrySelectType): void => {
                  if (Array.isArray(value)) {
                    const _debtStructures = debtStructures?.filter(
                      (debt: DebtType) => value.includes(debt?.id.toString())
                    );
                    changeKpi("debtStructure", _debtStructures);
                  }
                }}
                value={kpi?.debtStructure?.map(
                  (industry: DebtType) => industry.id
                )}
                options={debtStructures.map(({ id, name }: DebtType) => ({
                  label: name,
                  key: id,
                }))}
              />
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Debt Type"}
                type={InputType.MULTIPLE_SELECT}
                placeholder={"For example 'Syndicated Loan'"}
                onChange={(value: industrySelectType): void => {
                  if (Array.isArray(value)) {
                    const debts = debtTypes?.filter((debt: DebtType) =>
                      value.includes(debt?.id.toString())
                    );
                    changeKpi("debtType", debts);
                  }
                }}
                value={kpi?.debtType?.map((industry: DebtType) => industry.id)}
                options={debtTypes.map(({ id, name }: DebtType) => ({
                  label: name,
                  key: id,
                }))}
              />
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Currency"}
                type={InputType.SELECT}
                placeholder={"For example 'USD($)'"}
                onChange={(e): void => {
                  changeKpi("currency", e);
                }}
                value={kpi?.currency}
                options={Object.keys(CurrencyType).map((value) => ({
                  key: value,
                  label: `${CurrencySymbolType[value as CurrencyType]}`, // Added currency code to label
                }))}
              />
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={`Unit`}
                type={InputType.SELECT}
                placeholder={"For example 'Millions(K)'"}
                onChange={(e): void => {
                  changeKpi("unit", e);
                }}
                value={kpi?.unit || UnitType.MILLION}
                options={Object.keys(UnitType).map((value) => ({
                  key: value,
                  label: UnitTitleType[value as UnitType],
                }))}
              />

              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Annual Revenue"}
                type={InputType.NUMBER}
                placeholder={"Enter approx if unknown"}
                prefix={
                  kpi?.currency
                    ? CurrencySymbolType[kpi?.currency as CurrencyType]
                    : ""
                }
                suffix={kpi?.unit ? UnitLabelType[kpi?.unit as UnitType] : ""}
                value={kpi?.annualRevenue}
                onChange={(e): void => {
                  changeKpi("annualRevenue", e);
                }}
              />
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Annual EBITDA"}
                type={InputType.NUMBER}
                placeholder={"Enter approx if unknown"}
                prefix={
                  kpi?.currency
                    ? CurrencySymbolType[kpi?.currency as CurrencyType]
                    : ""
                }
                suffix={kpi?.unit ? UnitLabelType[kpi?.unit as UnitType] : ""}
                value={kpi?.ebitda}
                onChange={(e): void => {
                  changeKpi("ebitda", e);
                }}
              />
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Senior Debt Quantum"}
                type={InputType.NUMBER}
                placeholder={"Enter approx if unknown"}
                prefix={
                  kpi?.currency
                    ? CurrencySymbolType[kpi?.currency as CurrencyType]
                    : ""
                }
                suffix={kpi?.unit ? UnitLabelType[kpi?.unit as UnitType] : ""}
                value={kpi?.sdpAmount}
                onChange={(e): void => {
                  changeKpi("sdpAmount", e);
                }}
              />
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"TOTAL DEBT QUANTUM "}
                type={InputType.NUMBER}
                placeholder={"Enter approx if unknown"}
                prefix={
                  kpi?.currency
                    ? CurrencySymbolType[kpi?.currency as CurrencyType]
                    : ""
                }
                suffix={kpi?.unit ? UnitLabelType[kpi?.unit as UnitType] : ""}
                value={kpi?.targetTotalDebtQuantum}
                onChange={(e): void => {
                  changeKpi("targetTotalDebtQuantum", e);
                }}
              />
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Total LTV %"}
                placeholder={"For example 13%"}
                type={InputType.NUMBER}
                suffix={"%"}
                value={kpi?.expectedLtv}
                onChange={(e): void => {
                  changeKpi("expectedLtv", e);
                }}
              />
              <InputField
                disabled={!canEdit}
                loading={loading}
                label={"Total Leverage"}
                placeholder={"Total Debt Quantum / Annual EBITDA"}
                type={InputType.NUMBER}
                suffix={"x"}
                value={kpi?.totalLeverage}
                onChange={(e): void => {
                  changeKpi("totalLeverage", e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type kpiType = {
  id: number;
  companyId: number;
  peTransactionId: number;
  ownershipType: string;
  dealName: string;
  description: string;
  sponsorBacked: string;
  sponsorCorporate: string;
  borrowerName: string;
  borrowerDomicile: string;
  industries: IndustryType[];
  transactionTypeId: string;
  investmentTypeId: string;
  debtStructure: DebtType[];
  debtType: DebtType[];
  annualRevenue: number;
  ebitda: number;
  targetTotalDebtQuantum: number;
  expectedLtv: number;
  creationDate: Date;
  signingDate: Date;
  currency: string;
  unit: string;
  totalLeverage: number;
  sdpAmount: number;
};

type industrySelectType =
  | string
  | number
  | string[]
  | MonetaryRangeType
  | undefined;

type KPIValueType = IndustryType[] | industrySelectType | DebtType[];

type changeKPIType = (property: string, value: KPIValueType) => void;

type KpiComponentType = {
  orientation?: "vertical" | "horizontal";
  showBanner?: boolean;
};
