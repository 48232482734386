import React, { FC, useContext, useEffect, useState } from "react";
import { usePageTitle } from "../../../customHooks/usePageTitle";
import { LockOutlined } from "@ant-design/icons";
import { Select, Switch } from "antd";
import {
  createConfig,
  getConfigs,
  updateConfig,
} from "../../../services/services";
import { ResponseType } from "../../../utils/uiTypes";
import {
  CompanyPasswordConfigType,
  CompanySettingConfigType,
} from "../../../utils/types";
import { UserContext } from "../../../context/UserContext";
import { getObjectFromPropertyValue } from "../../../utils/utils";
import { isEmpty, isNil } from "ramda";
import { CustomSpin } from "../../general/CustomSpin";
import {
  ConfigType,
  ConfigurationSettingType,
  ElementType,
} from "../../../utils/enums";

export const PasswordPolicy: FC = () => {
  usePageTitle("Password Policy");
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [companySettingConfig, setCompanySettingConfig] =
    useState<CompanySettingConfigType<CompanyPasswordConfigType> | null>(null);

  const initiateUpdateConfig: handleChangeType =
    (companySettingConfig, property) => (value) => {
      if (!isNil(companySettingConfig))
        updateConfig({
          segments: {
            id: user?.companyId,
          },
          body: JSON.stringify({
            ...companySettingConfig,
            configValue: {
              ...companySettingConfig?.configValue,
              [property]: value,
            },
          }),
        })
          .then(
            ({
              data,
            }: ResponseType<
              CompanySettingConfigType<CompanyPasswordConfigType>
            >) => {
              setCompanySettingConfig(data);
              setLoading(false);
            }
          )
          .catch((e: string) => {
            console.error(e);
            setLoading(false);
          });
    };

  const initiateCreateConfig: handleChangeType =
    (companySettingConfig, property) => (value) => {
      createConfig({
        body: JSON.stringify({
          elementId: user?.companyId ?? "",
          elementType: ElementType.COMPANY,
          settingType: ConfigurationSettingType.SETTINGS,
          configType: ConfigType.PASSWORD_POLICY,
          configValue: {
            minPasswordLength: 8,
            passwordPolicyEnabled: false,
            maxPasswordAge: 90,
            unusablePassword: 2,
            [property]: value,
          },
        } as CompanySettingConfigType<CompanyPasswordConfigType>),
      })
        .then(
          ({
            data,
          }: ResponseType<
            CompanySettingConfigType<CompanyPasswordConfigType>
          >) => {
            setCompanySettingConfig(data);
          }
        )
        .catch(console.error)
        .then(() => {
          setLoading(false);
        });
    };

  const handleChange: handleChangeType =
    (companySettingConfig, property) => (value) => {
      if (!isEmpty(companySettingConfig) && !isNil(companySettingConfig)) {
        initiateUpdateConfig(companySettingConfig, property)(value);
      } else {
        initiateCreateConfig(companySettingConfig, property)(value);
      }
    };

  const getCompanySettingConfiguration: getCompanySettingConfigurationType = (
    companyId
  ) => {
    getConfigs({
      segments: {
        companyId,
        elementType: "COMPANY",
        settingType: ConfigurationSettingType.SETTINGS,
      },
    })
      .then(
        ({
          data = [],
        }: ResponseType<
          CompanySettingConfigType<CompanyPasswordConfigType>[]
        >) => {
          if (!isEmpty(data)) {
            setCompanySettingConfig(
              getObjectFromPropertyValue("configType", "PASSWORD_POLICY", data)
            );
          }
          setLoading(false);
        }
      )
      .catch((e: string) => {
        console.error(e);
        setLoading(false);
      });
  };
  /**
   * Fetch Required datasets on load
   */
  useEffect(() => {
    user?.companyId && getCompanySettingConfiguration(user.companyId);
  }, [user]);

  return (
    <div className={"relative w-full h-full bg-gray-100 flex flex-col pt-5"}>
      <CustomSpin loading={loading} />
      <div className={"bg-white h-full mx-6 mb-6"}>
        <div className={"max-w-screen-md mx-auto w-3/6 py-7"}>
          <p
            className={"max-w-xs text-center mx-auto py-2 text-2xl font-medium"}
          >
            <LockOutlined className="mr-3" />
            Password Policy
          </p>
          <div className={"flex flex-col gap-2"}>
            <div>
              <h1 className="text-base mt-7 font-bold">
                Enable password policy
                <Switch
                  defaultChecked={false}
                  className={`float-right mr-8 ${
                    companySettingConfig?.configValue?.passwordPolicyEnabled
                      ? "bg-success"
                      : "bg-muted"
                  }`}
                  checked={
                    companySettingConfig?.configValue?.passwordPolicyEnabled
                  }
                  onChange={handleChange(
                    companySettingConfig,
                    "passwordPolicyEnabled"
                  )}
                />
              </h1>
            </div>
            <div>
              <span>Password expire after </span>
              <Select
                disabled={
                  !companySettingConfig?.configValue?.passwordPolicyEnabled
                }
                bordered={false}
                className={"border-b "}
                size={"small"}
                style={{ width: 120 }}
                defaultValue={90}
                onChange={handleChange(companySettingConfig, "maxPasswordAge")}
                value={companySettingConfig?.configValue?.maxPasswordAge}
                options={[90, 120, 150, 180].map((i) => ({
                  label: i,
                  value: i,
                }))}
              />
              <span> days</span>
            </div>
            <div>
              <span>Minimum password length </span>
              <Select
                disabled={
                  !companySettingConfig?.configValue?.passwordPolicyEnabled
                }
                bordered={false}
                className={"border-b"}
                size={"small"}
                style={{ width: 120 }}
                defaultValue={8}
                onChange={handleChange(
                  companySettingConfig,
                  "minPasswordLength"
                )}
                value={companySettingConfig?.configValue?.minPasswordLength}
                options={[8, 12, 16, 20, 24].map((i) => ({
                  label: i,
                  value: i,
                }))}
              />
              <span> characters</span>
            </div>
            <div>
              <span>User cannot reuse last </span>
              <Select
                disabled={
                  !companySettingConfig?.configValue?.passwordPolicyEnabled
                }
                bordered={false}
                className={"border-b "}
                size={"small"}
                defaultValue={2}
                value={companySettingConfig?.configValue?.unusablePassword}
                style={{ width: 120 }}
                onChange={handleChange(
                  companySettingConfig,
                  "unusablePassword"
                )}
                options={[12, 10, 8, 6, 4, 2].map((i) => ({
                  label: i,
                  value: i,
                }))}
              />
              <span> passwords</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
type getCompanySettingConfigurationType = (companyId: string) => void;
type handleChangeType = (
  config: CompanySettingConfigType<CompanyPasswordConfigType> | null,
  property: string
) => (value: boolean | number) => void;
