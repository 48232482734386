import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import { Button, message, Switch, Tooltip } from "antd";
import {
  arrayToMapConversion,
  getObjectFromPropertyValue,
  insertItemInArray,
  removeItemFromArray,
  sortByPathToProperty,
  updateItemInArray,
  valOrDefault,
} from "../../../utils/utils";
import {
  addNewRowForOfflineTermsheet,
  createNewWebFormConfig,
  getWebformBySelectedTemplate,
  getWebFormByVersion,
  markInActiveWebFormConfig,
  saveOfflineTermsheet,
  saveOrPublishWebFormData,
  updateOrPublishTermSheet,
} from "../../../services/services";
import { ResponseType, voidType } from "../../../utils/uiTypes";
import {
  DataMapType,
  ElementObjectType,
  KeyValueType,
  SaveOrPublishTermsheetType,
  VersionDataType,
  WebFormConfigType,
  WebFormType,
} from "../../../utils/types";
import { CustomSpin } from "../../general/CustomSpin";
import { DefaultColumnType, DrawerType } from "../../../utils/newTermsheet";
import { TermsheetContext } from "../../../context/TermsheetContext";
import { TermsheetRows } from "./TermsheetRows";
import { dropLast, findIndex, isNil, last, propEq } from "ramda";
import {
  ColumnElementType,
  EMPTY_WEB_FORM_CONFIG,
  MESSAGE_CONTENT,
  RightClickActionType,
  SelectedCellType,
  updateDataMapByElementId,
} from "../../../utils/termsheet";
import { TermsheetToolbarActions } from "./TermsheetToolbarActions";
import { ElementType } from "../../../utils/enums";
import { useHistory } from "react-router";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";

export const TermsheetBody: FC<TermsheetType> = function ({
  version,
  versions,
  published,
  elements,
  onCreateNewVersion,
}: TermsheetType) {
  const {
    transactionId,
    webFormId,
    isAdmin,
    isInstitution,
    element,
    isOffline,
    onReloadVersions,
  } = useContext(TermsheetContext);
  const history = useHistory();
  const [actionType, setActionType] = useState<ButtonActionType>({
    save: false,
    publish: false,
  });
  const [edited, setEdited] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [heatMap, setHeatMap] = useState<boolean>(false);
  const [redLine, setRedLine] = useState<boolean>(false);
  const [selectedCell, setSelectedCell] = useState<SelectedCellType | null>(
    null
  );
  const [undoItems, setUndoItems] = useState<Array<WebFormType>>([]);
  const [redoItems, setRedoItems] = useState<Array<WebFormType>>([]);
  const [webForm, setWebForm] = useState<WebFormType | null>(null);

  /** Reset fields on termsheet version update or add/delete row **/
  const resetFields: voidType = () => {
    setEdited(false);
    setSelectedCell(null);
    setUndoItems([]);
    setRedoItems([]);
  };

  /** Reset loading on action complete **/
  const resetLoading: voidType = () => {
    setActionType({ save: false, publish: false });
    setLoading(false);
  };

  /** Sort webform configs based on position **/
  const getSortedWebform: getSortedWebformType = (webform, configs = []) => {
    return {
      ...webform,
      webFormConfigDTOs: sortByPathToProperty(["position"], configs),
    };
  };

  /** Undo and Redo functionality **/
  const undoOrRedo: undoOrRedoType = (
    removeArray,
    insertArray,
    insertCallback,
    dropCallback
  ) => {
    const item = last(removeArray);
    if (!isNil(item)) {
      insertCallback(
        insertItemInArray(insertArray.length, insertArray, webForm)
      );
      setWebForm(item);
      dropCallback(dropLast(1, removeArray));
    }
  };

  /** Update row based on element and permissions **/
  const updateRowBasedOnElementTypeAndPermissions = (
    config: WebFormConfigType,
    column: string,
    style: KeyValueType,
    { elementType, elementId }: ElementObjectType
  ) => {
    if ((isAdmin && column === DefaultColumnType.BID_METRICS) || isOffline) {
      return { ...config, uiKey: { ...config.uiKey, style } };
    } else if (
      (isAdmin &&
        column === transactionId &&
        elementType === ElementType.PETRANSACTION) ||
      isOffline
    ) {
      return updateDataMapByElementId(config, transactionId, style);
    } else if (
      (isInstitution &&
        column === elementId &&
        elementType === ElementType.LENDER) ||
      isOffline
    ) {
      return updateDataMapByElementId(config, elementId, style);
    }
    return null;
  };

  const onUpdateRow: onUpdateRowType = (row, i) => {
    setEdited(true);
    setUndoItems((items) => insertItemInArray(items.length, items, webForm));
    setWebForm((webForm) =>
      webForm
        ? {
            ...webForm,
            webFormConfigDTOs: updateItemInArray(
              i,
              valOrDefault([], webForm?.webFormConfigDTOs),
              row
            ),
          }
        : null
    );
  };

  /** Update cell style based on toolbar actions for different user types **/
  const onStyleChange: onStyleChangeType = (style) => {
    if (element && webForm && webForm.webFormConfigDTOs && selectedCell) {
      const config = webForm.webFormConfigDTOs[selectedCell.rowIndex];
      const updatedConfig = updateRowBasedOnElementTypeAndPermissions(
        config,
        selectedCell.column,
        style,
        element
      );
      if (updatedConfig) {
        onUpdateRow(updatedConfig, selectedCell.rowIndex);
        setSelectedCell({ ...selectedCell, style });
      }
    }
  };

  /** On item selection from drawer **/
  const onDrawerItemSelect: onDrawerItemSelectType = (selectedId, type) => {
    setLoading(true);
    switch (type) {
      case "TEMPLATES":
        webForm &&
          openSelectedTemplate(transactionId, selectedId, webFormId, webForm);
        return;
      case "TERMSHEETS":
        return history.push(
          `/transactions/${transactionId}/termsheet/${selectedId}`
        );
    }
  };

  /** Right click event logic **/
  const positionIncrementAtIndex: positionIncrementAtIndexType = (
    config,
    index,
    configs = []
  ) => {
    return [
      ...configs.slice(0, index),
      config,
      ...configs.slice(index).map((o) => {
        return { ...o, position: (parseInt(o.position) + 1).toString() };
      }),
    ];
  };

  const onRightClick: onRightClickType = (action, id, index) => {
    if (webForm?.webFormConfigDTOs) {
      setActionType({ save: true, publish: false });
      switch (action) {
        case "ADD_BELOW":
        case "ADD_ABOVE": {
          const position = webForm.webFormConfigDTOs[index]?.position;
          return onAddRow(
            transactionId,
            position,
            positionIncrementAtIndex(
              EMPTY_WEB_FORM_CONFIG(position, transactionId),
              index,
              webForm.webFormConfigDTOs
            )
          );
        }
        case "DELETE": {
          return onDeleteRow(transactionId, index, id);
        }
      }
    }
  };

  const onAddRowForOnline: onAddRowType = (
    transactionId,
    position,
    webFormConfigDTOs
  ) => {
    createNewWebFormConfig({
      segments: {
        id: transactionId,
      },
      body: JSON.stringify({
        ...webForm,
        webFormConfigDTOs,
      }),
    })
      .then(({ data }: ResponseType<WebFormType>) => {
        const item = getObjectFromPropertyValue(
          "position",
          position,
          data?.webFormConfigDTOs ?? []
        );
        if (item) {
          const index = findIndex(propEq("position", position))(
            webFormConfigDTOs
          );
          webForm &&
            setWebForm({
              ...webForm,
              webFormConfigDTOs: updateItemInArray(index, webFormConfigDTOs, {
                ...webFormConfigDTOs[index],
                id: item.id,
              }),
            });
        }
        resetFields();
        resetLoading();
      })
      .catch(() => {
        message.error("Unable to add row");
        resetLoading();
      });
  };

  const onAddRowForOffline: onAddRowType = (
    transactionId,
    position,
    webFormConfigDTOs
  ) => {
    addNewRowForOfflineTermsheet({
      segments: {
        peTransactionId: webForm?.peTransactionId,
        webFormId: webForm?.id,
        position,
      },
    })
      .then(({ data }: ResponseType<WebFormConfigType>) => {
        const index = findIndex(propEq("position", position))(
          webFormConfigDTOs
        );
        webForm &&
          setWebForm({
            ...webForm,
            webFormConfigDTOs: updateItemInArray(
              index,
              webFormConfigDTOs,
              data
            ),
          });
        resetFields();
        resetLoading();
      })
      .catch(() => {
        message.error("Unable to add row");
        resetLoading();
      });
  };

  /** Add new row **/
  const onAddRow: onAddRowType = (
    transactionId,
    position,
    webFormConfigDTOs
  ) => {
    if (isOffline) {
      onAddRowForOffline(transactionId, position, webFormConfigDTOs);
    } else {
      onAddRowForOnline(transactionId, position, webFormConfigDTOs);
    }
  };

  const onDeleteRow: onDeleteRowType = (transactionId, index, rowId) => {
    markInActiveWebFormConfig({
      segments: {
        id: transactionId,
        rowId,
      },
    })
      .then(() => {
        resetFields();
        webForm?.webFormConfigDTOs &&
          setWebForm({
            ...webForm,
            webFormConfigDTOs: removeItemFromArray(
              index,
              webForm?.webFormConfigDTOs
            ),
          });
      })
      .catch(() => {
        message.error("Unable to delete row");
      })
      .then(() => {
        resetLoading();
      });
  };

  /** Update webform based on selected template **/
  const openSelectedTemplate: openTemplateType = (
    id,
    selectedWebFormId,
    webformId,
    webForm
  ) => {
    message.loading({
      content: MESSAGE_CONTENT.switching,
      duration: 0,
      key: "dealTeamMessage",
    });
    getWebformBySelectedTemplate({
      segments: {
        id,
        selectedWebFormId,
        webformId,
      },
      body: JSON.stringify(webForm),
    })
      .then(({ data = [] }: ResponseType<Array<WebFormConfigType>>) => {
        setWebForm({ ...webForm, webFormConfigDTOs: data });
        message.success({
          content: MESSAGE_CONTENT.switched,
          key: "dealTeamMessage",
        });
        resetLoading();
      })
      .catch(() => {
        message.loading({
          content: MESSAGE_CONTENT.switchError,
          duration: 0,
          key: "dealTeamMessage",
        });
        resetLoading();
      });
  };

  /** Generate data object to save or publish termsheet for Institutions and Owners **/
  const onGenerateDataForSaveOrPublish: onGenerateDataForSaveOrPublishType = (
    peTransactionId,
    webFormId,
    version,
    elementId,
    lenderPublish,
    publishingLenderIds,
    webform
  ) => {
    return {
      peTransactionId,
      webFormDTO: webform,
      webFormDataDTO: {
        data: arrayToMapConversion(
          valOrDefault([], webform.webFormConfigDTOs),
          "id",
          ["dataMap", elementId]
        ) as DataMapType,
        versionId: version,
      },
      webFormId,
      version,
      publishingLenderIds,
      lenderPublish,
    };
  };

  /** Make API call to save or publish termsheet from deal team or institution side **/
  const saveOrPublishTermsheetInstitution: saveOrPublishWebFormOrTermsheetType =
    (
      webForm,
      elementId,
      publish,
      publishingInstitutions,
      loadMessage,
      successMessage,
      errorMessage
    ) => {
      message.loading({
        content: loadMessage,
        duration: 0,
        key: "institutionMessage",
      });
      saveOrPublishWebFormData({
        body: JSON.stringify(
          onGenerateDataForSaveOrPublish(
            transactionId,
            webFormId,
            version.toString(),
            elementId,
            publish,
            null,
            webForm
          )
        ),
      })
        .then(({ data }: ResponseType<SaveOrPublishTermsheetType>) => {
          message.success({
            content: successMessage,
            key: "institutionMessage",
          });
          resetLoading();
          publish &&
            onReloadVersions(transactionId, webFormId, version.toString());
        })
        .catch(() => {
          message.error({
            content: errorMessage,
            key: "institutionMessage",
          });
          resetLoading();
        });
    };

  const saveOrPublishTermsheetDealTeam: saveOrPublishWebFormOrTermsheetType = (
    webForm: WebFormType,
    elementId,
    publish,
    publishingInstitutions,
    loadMessage,
    successMessage,
    errorMessage
  ) => {
    message.loading({
      content: loadMessage,
      duration: 0,
      key: "dealTeamMessage",
    });
    updateOrPublishTermSheet({
      body: JSON.stringify(
        onGenerateDataForSaveOrPublish(
          transactionId,
          webFormId,
          version.toString(),
          elementId,
          false,
          publishingInstitutions,
          webForm
        )
      ),
    })
      .then(({ data }: ResponseType<SaveOrPublishTermsheetType>) => {
        message.success({
          content: successMessage,
          key: "dealTeamMessage",
        });
        resetLoading();
        publish &&
          onReloadVersions(transactionId, webFormId, version.toString());
      })
      .catch(() => {
        message.error({
          content: errorMessage,
          key: "dealTeamMessage",
        });
        resetLoading();
      });
  };

  /** Fetch webform data **/
  const getWebformByVersionId: getWebformByTransactionIdAndWebformIdAndVersionType =
    (id, webFormId, currentVersion) => {
      getWebFormByVersion({
        segments: {
          id,
          webFormId,
          version: currentVersion,
        },
      })
        .then(({ data }: ResponseType<WebFormType>) => {
          resetFields();
          setWebForm(getSortedWebform(data, data?.webFormConfigDTOs));
        })
        .catch(() => {
          message.error("Sorry, unable to load the termsheet!");
        })
        .then(() => {
          resetLoading();
        });
    };

  const initiateSaveOfflineTermsheet: saveOrPublishWebFormOrTermsheetType = (
    webForm: WebFormType,
    elementId,
    publish,
    publishingInstitutions,
    loadMessage,
    successMessage,
    errorMessage
  ) => {
    message.loading({
      content: loadMessage,
      duration: 0,
      key: "offlineSave",
    });
    saveOfflineTermsheet({
      body: JSON.stringify(webForm),
    })
      .then(({ data }: ResponseType<SaveOrPublishTermsheetType>) => {
        setEdited(false);
        message.success({
          content: successMessage,
          key: "offlineSave",
        });
        resetLoading();
      })
      .catch(() => {
        message.error({
          content: errorMessage,
          key: "offlineSave",
        });
        resetLoading();
      });
  };

  useLayoutEffect(() => {
    setLoading(true);
    getWebformByVersionId(transactionId, webFormId, version.toString());
  }, [transactionId, webFormId, version]);

  return (
    <div className={`relative h-full w-full ${!isOffline && "pb-[90px]"}`}>
      <CustomSpin loading={loading} loadingText={"Loading Termsheet ..."} />
      <TermsheetToolbarActions
        edited={edited}
        name={webForm?.name}
        version={version}
        versions={versions}
        published={published}
        disableActions={actionType.save || actionType.publish}
        publishLoading={actionType.publish}
        style={selectedCell?.style}
        onStyleChange={onStyleChange}
        onUndoOrRedo={(undo) => {
          undo
            ? undoOrRedo(undoItems, redoItems, setRedoItems, setUndoItems)
            : undoOrRedo(redoItems, undoItems, setUndoItems, setRedoItems);
        }}
        onDealTeamPublish={(values = []) => {
          if (element && webForm) {
            setActionType({ ...actionType, publish: true });
            saveOrPublishTermsheetDealTeam(
              webForm,
              element.elementId,
              true,
              values,
              MESSAGE_CONTENT.publishing,
              MESSAGE_CONTENT.published,
              MESSAGE_CONTENT.publishError
            );
          }
        }}
        onInstitutionPublish={() => {
          if (element && webForm) {
            setActionType({ ...actionType, publish: true });
            saveOrPublishTermsheetInstitution(
              webForm,
              element.elementId,
              true,
              null,
              MESSAGE_CONTENT.publishing,
              MESSAGE_CONTENT.published,
              MESSAGE_CONTENT.publishError
            );
          }
        }}
        onCreateNewVersion={onCreateNewVersion}
        onRefresh={() => {
          getWebformByVersionId(transactionId, webFormId, version.toString());
        }}
        onDrawerItemSelect={onDrawerItemSelect}
        isHeatMap={heatMap}
        isRedLine={redLine}
      >
        {element && webForm && (
          <>
            {!isOffline && (
              <Tooltip placement="bottom" title={"Heat Map"}>
                <Switch
                  defaultChecked={false}
                  checked={heatMap}
                  className={`${heatMap ? "bg-primary" : "bg-muted"}`}
                  onChange={setHeatMap}
                />
                <span className={"text-xs font-medium pl-2"}>Heat Map</span>
              </Tooltip>
            )}
            {!isOffline && (
              <Tooltip placement="bottom" title={"Redline"}>
                <Switch
                  defaultChecked={false}
                  checked={redLine}
                  className={`${redLine ? "bg-primary" : "bg-muted"}`}
                  onChange={setRedLine}
                />
                <span className={"text-xs font-medium pl-2"}>Redline</span>
              </Tooltip>
            )}
            {!published && !isOffline && (
              <>
                {isAdmin && (
                  <Button
                    disabled={actionType.save || actionType.publish}
                    loading={actionType.save}
                    type={"primary"}
                    className={PRIMARY_BUTTON_STYLE}
                    onClick={() => {
                      setActionType({ ...actionType, save: true });
                      saveOrPublishTermsheetDealTeam(
                        webForm,
                        element.elementId,
                        false,
                        null,
                        MESSAGE_CONTENT.saving,
                        MESSAGE_CONTENT.saved,
                        MESSAGE_CONTENT.saveError
                      );
                    }}
                  >
                    {actionType.save ? MESSAGE_CONTENT.saving : "Save"}
                  </Button>
                )}
                {isInstitution && (
                  <Button
                    disabled={actionType.save || actionType.publish}
                    loading={actionType.save}
                    type={"primary"}
                    className={PRIMARY_BUTTON_STYLE}
                    onClick={() => {
                      setActionType({ ...actionType, save: true });
                      saveOrPublishTermsheetInstitution(
                        webForm,
                        element.elementId,
                        false,
                        null,
                        MESSAGE_CONTENT.saving,
                        MESSAGE_CONTENT.saved,
                        MESSAGE_CONTENT.saveError
                      );
                    }}
                  >
                    {actionType.save ? MESSAGE_CONTENT.saving : "Save"}
                  </Button>
                )}
              </>
            )}
            {isOffline && !published && (
              <Button
                disabled={actionType.save}
                loading={actionType.save}
                type={"primary"}
                className={PRIMARY_BUTTON_STYLE}
                onClick={() => {
                  setActionType({ ...actionType, save: true });
                  initiateSaveOfflineTermsheet(
                    webForm,
                    element.elementId,
                    false,
                    null,
                    MESSAGE_CONTENT.saving,
                    MESSAGE_CONTENT.saved,
                    MESSAGE_CONTENT.saveError
                  );
                }}
              >
                {actionType.save ? MESSAGE_CONTENT.saving : "Save"}
              </Button>
            )}
          </>
        )}
      </TermsheetToolbarActions>
      {!loading && webForm && webForm.webFormConfigDTOs && (
        <div className={"max-h-full h-full w-full"}>
          <div
            className={
              "relative max-h-full w-full overflow-x-auto overscroll-none scroll-smooth"
            }
          >
            <TermsheetRows
              redLine={redLine}
              heatMap={heatMap}
              published={published}
              elements={elements}
              configs={webForm.webFormConfigDTOs}
              selectedCell={selectedCell}
              onSelectCell={setSelectedCell}
              onAddOrDeleteRow={
                (isAdmin && !published) || isOffline ? onRightClick : undefined
              }
              onWebFormConfigUpdate={(webFormConfigDTOs) => {
                setEdited(true);
                setUndoItems((items) =>
                  insertItemInArray(items.length, items, webForm)
                );
                setWebForm({ ...webForm, webFormConfigDTOs });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

type TermsheetType = {
  versions: VersionDataType;
  version: number;
  published: boolean;
  elements: Array<ColumnElementType>;
  onCreateNewVersion: () => void;
};

type ButtonActionType = {
  save: boolean;
  publish: boolean;
};

type getColumnsListType = () => Array<string>;

type getVersionDataType = (id: string, webformId: string) => void;
type undoOrRedoType = (
  removeArray: Array<WebFormType>,
  insertArray: Array<WebFormType>,
  insertCallback: Dispatch<SetStateAction<WebFormType[]>>,
  dropCallback: Dispatch<SetStateAction<WebFormType[]>>
) => void;
type getWebformByTransactionIdAndWebformIdAndVersionType = (
  transactionId: string,
  webFormId: string,
  version: string
) => void;
type saveOrPublishWebFormOrTermsheetType = (
  data: WebFormType,
  elementId: string,
  publish: boolean,
  publishingInstitutions: Array<string> | null,
  loadMessage: string,
  successMessage: string,
  errorMessage: string
) => void;
type openTemplateType = (
  id: string,
  templateId: string,
  webFormId: string,
  webForm: WebFormType
) => void;
type onAddRowType = (
  transactionId: string,
  position: string,
  config: Array<WebFormConfigType>
) => void;
type onDeleteRowType = (
  transactionId: string,
  index: number,
  key: string
) => void;
type positionIncrementAtIndexType = (
  config: WebFormConfigType,
  index: number,
  configs: Array<WebFormConfigType>
) => Array<WebFormConfigType>;
type onGenerateDataForSaveOrPublishType = (
  transactionId: string,
  webformId: string,
  version: string,
  elementId: string,
  lenderPublish: boolean,
  publishLendersList: Array<string> | null,
  webform: WebFormType
) => SaveOrPublishTermsheetType;
type onDrawerItemSelectType = (
  selectedId: string,
  drawerType: DrawerType
) => void;
type onStyleChangeType = (style: KeyValueType) => void;
type onUpdateRowType = (row: WebFormConfigType, index: number) => void;
type getSortedWebformType = (
  webform: WebFormType,
  configs?: Array<WebFormConfigType>
) => WebFormType;
type onRightClickType = (
  key: RightClickActionType,
  id: string,
  index: number
) => void;
