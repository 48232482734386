import {
  AllocationKeyType,
  CompanyType,
  TransactionType,
  UserType,
} from "../../../../../utils/types";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { Avatar, Badge, Col, List, Row, Spin, Timeline, Tooltip } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { is } from "ramda";
import {
  TRANSACTION_STATUSES,
  transactionTagsArray,
} from "../../../../../utils/transaction";
import { arrayToMapConversion } from "../../../../../utils/utils";
import { PastTransactionAllocation } from "../../pastTransactions/PastTransactionAllocation";
import { PastTransactionFees } from "../../pastTransactions/PastTransactionFees";
import { getColorPallet } from "../../../../../utils/colors";
import { getDealTeamForPortfolioLinkedTransaction } from "../../../../../services/services";
import { ResponseType } from "../../../../../utils/uiTypes";
import { PETransactionTagType } from "../../../../../utils/enums";
import { convertDateToFormat } from "../../../../../utils/moment";

const ListItem: FC<ListItemType> = ({ label, value, hidden = false }) => (
  <List.Item
    hidden={hidden}
    className={
      "py-1 mb-3 md:mb-0 relative w-full hover:bg-gray-100 group border-0"
    }
  >
    <Row className={"w-full"}>
      <Col xs={24} md={6}>
        <div className={"text-left md:text-right w-full h-full"}>
          <p className={"px-4 text-gray-400 inline"}>{label}</p>
        </div>
      </Col>
      <Col xs={24} md={18}>
        <div className={`w-full h-full px-1 md:px-4 group`}>
          <div className={"px-3"}>{value ?? "-"}</div>
        </div>
      </Col>
    </Row>
  </List.Item>
);

const TransactionsTagsMap = arrayToMapConversion(
  transactionTagsArray,
  "value",
  []
);

export const PastTransaction: FC<PastTransactionType> = ({
  transaction,
  companies,
  allocationKeys,
}) => {
  const [hidden, setHidden] = useState<boolean>(false);
  const [dealTeam, setDealTeam] = useState<UserType[]>([]);
  const [dealTeamLoading, setDealTeamLoading] = useState<boolean>(true);

  const getDate = (value = ""): ReactNode => moment(value).format("MM/DD/YYYY");

  const renderValues = (val: PETransactionTagType): ReactNode => {
    if (TRANSACTION_STATUSES.includes(val)) {
      const { color, label } = TransactionsTagsMap[val];
      return (
        <span className={"flex flex-row"}>
          {color && <Badge offset={[0, 0]} dot color={color} />}&nbsp;
          {label}
        </span>
      );
    }
    return "-";
  };

  const renderTextArray = (values: string[]) =>
    values.length > 0 && is(Array, values) ? (
      <>
        {values
          .slice(0, 5)
          .map((item, i) => `${item}${i !== values.length - 1 ? "," : ""} `)}
        {values.length > 5 && (
          <Tooltip
            className={"cursor-pointer underline"}
            title={values
              .slice(5)
              .map(
                (item, i) => `${item}${i !== values.length - 1 ? "," : ""} `
              )}
            placement="top"
          >{`+${values.length - 5}`}</Tooltip>
        )}
      </>
    ) : (
      "-"
    );
  const renderAvatars = (values: string[]): ReactNode => (
    <Avatar.Group
      maxCount={6}
      size="small"
      maxStyle={{ backgroundColor: "#808080" }}
    >
      {values.map((value, index) => (
        <Tooltip key={index} title={value} placement="top">
          <Avatar
            className={`${getColorPallet(
              value?.charAt(0),
              "bg"
            )} uppercase cursor-pointer`}
            size="small"
          >
            {value?.charAt(0)}
          </Avatar>
        </Tooltip>
      ))}
    </Avatar.Group>
  );

  const fetchDealTeam = (): void => {
    getDealTeamForPortfolioLinkedTransaction({
      segments: { id: transaction.peTransactionId },
    })
      .then(({ data }: ResponseType<UserType[]>) => {
        setDealTeam(data);
      })
      .catch(console.error)
      .then(() => setDealTeamLoading(false));
  };

  useEffect(() => {
    fetchDealTeam();
  }, []);

  return (
    <Timeline.Item
      color={"gray"}
      className={"mb-5 mt-0"}
      dot={
        hidden ? (
          <Tooltip title={"Expand"}>
            <PlusCircleOutlined
              onClick={(): void => setHidden(false)}
              className={"text-xl cursor-pointer"}
            />
          </Tooltip>
        ) : (
          <Tooltip title={"Hide"}>
            <MinusCircleOutlined
              onClick={(): void => setHidden(true)}
              className={"text-xl cursor-pointer"}
            />
          </Tooltip>
        )
      }
    >
      <div className={"absolute -left-32 -top-0.5 text-gray-400"}>
        {transaction.creationDate
          ? convertDateToFormat(transaction.creationDate, "MM/DD/YYYY", true)
          : "-"}
      </div>
      <List>
        <ListItem
          label={"Project Name"}
          value={transaction.peTransactionTitle}
        />
        <ListItem
          hidden={hidden}
          label={"Target Company"}
          value={transaction.companyToBuy}
        />

        <ListItem
          hidden={hidden}
          label={"Created By"}
          value={`${transaction?.createdByUserDTO?.firstName} ${transaction?.createdByUserDTO?.lastName}`}
        />
        <ListItem
          hidden={hidden}
          label={"Creation Date"}
          value={
            transaction.creationDate
              ? convertDateToFormat(
                  transaction.creationDate,
                  "MM/DD/YYYY",
                  true
                )
              : "-"
          }
        />
        <ListItem
          hidden={hidden}
          label={"Closing Date"}
          value={
            is(String, transaction.closingDate)
              ? getDate(transaction.closingDate)
              : "-"
          }
        />
        <ListItem
          label={"Status"}
          value={renderValues(transaction.peTransactionTagType)}
        />
        <ListItem
          hidden={hidden}
          label={"Investment Type"}
          value={transaction.investmentTypeName}
        />
        <ListItem
          hidden={hidden}
          label={"Industry Type"}
          value={renderTextArray(
            transaction?.industryDTOSet?.map(({ name }) => name) ?? "-"
          )}
        />
        <ListItem
          hidden={hidden}
          label={"Transaction Type"}
          value={transaction.peTransactionTypeName}
        />
        <ListItem
          hidden={hidden}
          label={"Deal Team"}
          value={
            !dealTeamLoading ? (
              renderTextArray(
                dealTeam.map(
                  ({ firstName, lastName }) => `${firstName} ${lastName}`
                )
              ) ?? "-"
            ) : (
              <Spin size={"small"} spinning={true} />
            )
          }
        />
        <ListItem
          label={"Institution(s) Shown To"}
          value={
            renderAvatars(transaction?.lenderDTOs?.map(({ name }) => name)) ??
            "-"
          }
        />
        <ListItem
          hidden={hidden}
          label={"Allocation Table"}
          value={
            <PastTransactionAllocation
              name={transaction.peTransactionTitle}
              transactionId={transaction.peTransactionId}
              companies={companies}
              allocationKeys={allocationKeys}
            />
          }
        />
        <ListItem
          hidden={hidden}
          label={"Fees Table"}
          value={
            <PastTransactionFees
              name={transaction.peTransactionTitle}
              transactionId={transaction.peTransactionId}
              companies={companies}
              allocationKeys={allocationKeys}
            />
          }
        />
      </List>
    </Timeline.Item>
  );
};

type PastTransactionType = {
  transaction: TransactionType;
  companies: CompanyType[];
  allocationKeys: AllocationKeyType[];
};

type ListItemType = {
  value: ReactNode;
  hidden?: boolean;
  label: string;
};
