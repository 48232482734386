import React, { FC } from "react";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { formItemRequiredRule } from "../../utils/formUtils";

export const NewTrackerForm: FC<NewTrackerFormType> = ({
  onSubmit,
}: NewTrackerFormType) => {
  const [form] = useForm();

  return (
    <Form
      form={form}
      className={"flex flex-row items-center gap-x-2 mb-3 w-full"}
      layout={"horizontal"}
      onFinish={(val): void => {
        onSubmit(val);
        form.resetFields();
      }}
    >
      <Form.Item
        name={"name"}
        style={{ width: "100%" }}
        className={"m-0"}
        rules={[formItemRequiredRule]}
      >
        <Input placeholder={"Tracker Name"} autoFocus={true} />
      </Form.Item>

      <Form.Item className={"m-0"}>
        <div className={"flex flex-row gap-y-1 gap-x-2"}>
          <Button
            className={"bg-primary hover:bg-hover text-white border-0"}
            htmlType={"submit"}
          >
            Add Step
          </Button>
          <Button
            className={
              "bg-white border hover:border-primary hover:text-primary"
            }
            onClick={(): void => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

type NewTrackerFormType = {
  onSubmit: (o: { name: string }) => void;
};
