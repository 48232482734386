import React, { useEffect, useRef } from "react";

export const useClickOutside: UseClickOutside = (fn) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref?.current && !ref.current.contains(event.target as Node)) {
        fn();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [fn, ref]);

  return ref;
};

type UseClickOutside = (fn: () => void) => React.RefObject<HTMLDivElement>;
