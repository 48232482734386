import { MenuRouteType } from "./uiTypes";
import React, { ReactNode } from "react";
import { Route } from "react-router-dom";
import { Menu } from "antd";
import { TGBadge } from "../components/general/TGBadge";

export const getRoutes = (menu: MenuRouteType[]): ReactNode[] => {
  return menu
    .filter(({ permission = true }) => permission === true)
    .map(({ key, exact, path, Component, subMenu, props = {} }) =>
      subMenu ? (
        getRoutes(subMenu)
      ) : (
        <Route key={key} exact={exact} path={path}>
          {Component && <Component {...props} />}
        </Route>
      )
    );
};

export const getMenu = (menu: MenuRouteType[]): ReactNode =>
  menu
    .filter(
      ({ permission = true, disabled = false, visible = true }) =>
        permission && visible && !disabled
    )
    .map(
      ({
        subMenu,
        key,
        title = "",
        disabled = false,
        badge,
        icon,
        isPrivate,
      }) =>
        subMenu ? (
          <Menu.SubMenu
            icon={icon}
            className={`ml-auto select-none px-1`}
            title={title}
            key={key}
          >
            {menu?.map(({ subMenu = [] }) => {
              return getMenu(subMenu);
            })}
          </Menu.SubMenu>
        ) : (
          <Menu.Item
            key={key}
            icon={icon}
            disabled={disabled}
            className={`select-none m-0 relative ${isPrivate ? "private" : ""}`}
          >
            {title}
            <TGBadge color={badge?.color}>{badge?.label}</TGBadge>
          </Menu.Item>
        )
    );
