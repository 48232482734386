import React, { FC, useEffect, useRef, useState } from "react";
import { NoteDataType, NotesType, UserType } from "../../../../utils/types";
import { Button, DatePicker, Empty, message, Tag } from "antd";
import { Thread } from "../../../general/Thread";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  createNote,
  deleteNote,
  editNote,
  setPriorities,
  shareElement,
} from "../../../../services/services";
import TextArea from "antd/es/input/TextArea";
import { Note } from "./Note";
import { LoadingType, ResponseType } from "../../../../utils/uiTypes";
import {
  doNothing,
  getIndexByKeyVal,
  removeItemFromArray,
  updateItemInArray,
} from "../../../../utils/utils";
import { CustomSpin } from "../../../general/CustomSpin";
import { ElementType } from "../../../../utils/enums";
import ButtonGroup from "antd/es/button/button-group";
import { convertDateToFormat } from "../../../../utils/moment";
import { and, equals, isNil, or } from "ramda";
import { useHistory } from "react-router";
import { useIntersectionView } from "../../../../customHooks/useIntersectionView";
import { getColorPallet } from "../../../../utils/colors";

export const NotesThread: FC<NotesThreadsType> = function ({
  notes,
  activeLender,
  setNotes,
  transactionId,
  dealTeam = [],
  recentNotes = [],
  fetchRecentNotes = doNothing,
  navigateToLender,
  readOnly = false,
  enableDeepLink = false,
  isDealTeam = false,
}: NotesThreadsType) {
  const history = useHistory();
  const ref = useRef();

  const [loading, setLoading] = useState<LoadingType>({
    loading: false,
    label: "",
  });
  const [filters, setFilters] = useState<FilterType>({
    priority: false,
    calendar: false,
    startDate: "",
    endDate: "",
  });
  const [currentActiveNoteThread, setCurrentActiveNoteThread] =
    useState<number>(0);
  const [datesShow, setDatesShow] = useState<boolean>(false);
  const [editingNote, setEditingNote] = useState(false);

  const [, maxIndex, resetScroll] = useIntersectionView(
    ".recent-notes-thread",
    ref.current,
    [ref, recentNotes, activeLender]
  );

  const togglePriority: noteType = (noteIndex, id, notes, lenderIndex) => {
    setPriorities({
      segments: { id, type: "notes" },
    }).then(({ data }: ResponseType<NotesType>) => {
      setNotes &&
        setNotes(
          { ...notes, notes: updateItemInArray(noteIndex, notes.notes, data) },
          lenderIndex
        );
    });
  };

  const addNote: addNoteType = (lenderId, elementId, notes, lenderIndex) => {
    setLoading({ loading: true, label: "Adding a New Note..." });
    createNote({
      body: JSON.stringify({
        lenderId,
        elementType: ElementType.PETRANSACTION,
        elementId,
        note: "",
      }),
    })
      .then(({ data = [] }: ResponseType<NoteDataType[]>) => {
        setNotes &&
          setNotes(
            {
              ...notes,
              notes: data,
              count: (parseInt(notes?.count) + 1).toString(),
            },
            lenderIndex
          );
      })
      .then(() => {
        setLoading({ loading: false });
        message.success("Added New Note!", 0.5);
      })
      .catch((error: string) => {
        setLoading({ loading: false });
        message.error(error ? error : "Error adding a new note!");
      });
  };

  const updateNote: updateNoteType = (
    noteIndex,
    noteId,
    note,
    elementId,
    notes,
    lenderIndex
  ) => {
    message.loading({
      key: "update-" + noteId,
      content: "Updating Note",
      duration: 0,
    });
    editNote({
      body: JSON.stringify({
        elementId,
        id: noteId,
        note,
      }),
    })
      .then(({ data }: ResponseType<NoteDataType>) => {
        setNotes &&
          setNotes(
            {
              ...notes,
              notes: updateItemInArray(noteIndex, notes.notes, data),
              count: parseInt(notes?.count).toString(),
            },
            lenderIndex
          );
      })
      .then(() => {
        message.success({
          key: "update-" + noteId,
          content: "Note Updated",
          duration: 2,
        });
      })
      .catch((error: string) => {
        message.success({
          key: "update-" + noteId,
          content: error ? error : "Error Updating Note",
          duration: 3,
        });
      });
  };

  const removeNote: noteType = (noteIndex, id, notes, lenderIndex) => {
    setLoading({ loading: true, label: "Deleting Note..." });
    deleteNote({
      segments: {
        id,
      },
    })
      .then(() => {
        setNotes &&
          setNotes(
            {
              ...notes,
              notes: removeItemFromArray(noteIndex, notes.notes),
              count: (parseInt(notes?.count) - 1).toString(),
            },
            lenderIndex
          );
        message.success("Note Deleted!", 0.5);
        setLoading({ loading: false });
      })
      .catch((error: string) => {
        message.error(error ? error : "Error deleting note!");
        setLoading({ loading: false });
      });
  };

  const shareNote: shareNoteType = (
    noteIndex,
    elementId,
    userIds,
    notes,
    lenderIndex
  ) => {
    shareElement({
      body: JSON.stringify({
        elementId,
        userIds,
        elementType: ElementType.NOTE,
      }),
    })
      .then(({ data = [] }: ResponseType<UserType[]>) => {
        setNotes &&
          setNotes(
            {
              ...notes,
              notes: updateItemInArray(noteIndex, notes.notes, {
                ...notes.notes[noteIndex],
                sharedWith: data,
              }),
            },
            lenderIndex
          );
      })
      .catch((error: string) => {
        message.error(error ? error : "Error sharing note!");
        setLoading({ loading: false });
      });
  };

  const checkFilters: checkFiltersType = (filters, priorities, date) =>
    and(!filters.priority, !filters.calendar) ||
    (and(!filters.priority, filters.calendar) &&
      moment(date).isBetween(
        filters.startDate,
        filters.endDate,
        "day",
        "[]"
      )) ||
    (filters.priority && !filters.calendar && priorities) ||
    (filters.priority &&
      filters.calendar &&
      priorities &&
      moment(date).isBetween(filters.startDate, filters.endDate, "day", "[]"));

  const setFiltersWithDates = (values: any): void => {
    if (values) {
      const [start, end] = values;
      start &&
        end &&
        setFilters({
          ...filters,
          calendar: true,
          startDate: start.valueOf(),
          endDate: end?.valueOf(),
        });
    }
  };

  const navigateToNote = (lenderId: string, noteId: string) => {
    navigateToLender && navigateToLender(lenderId, noteId);
  };

  useEffect(() => {
    if (
      and(
        isNil(activeLender),
        or(equals(maxIndex, 0), equals(0, (maxIndex + 1) % 10))
      )
    ) {
      fetchRecentNotes(Math.floor((maxIndex + 1) / 10));
    } else if (!isNil(activeLender)) {
      resetScroll();
    }
  }, [activeLender, maxIndex]);

  useEffect(() => {
    setCurrentActiveNoteThread(0);
    const noteId = new URLSearchParams(location.search).get("noteId");
    if (noteId) {
      const noteIndex = getIndexByKeyVal(notes?.notes ?? [], "id", noteId);
      setCurrentActiveNoteThread(noteIndex);
    }
  }, [activeLender]);

  return (
    <div className={"relative p-0 grid grid-cols-12 h-full max-h-full"}>
      <CustomSpin loading={loading.loading} loadingText={loading.label} />
      {
        <div
          className={
            "col-span-1 md:col-span-3 border-r flex flex-col max-h-full h-full overflow-y-auto z-30 bg-white"
          }
        >
          {Number(notes?.count ?? "0") > 0 && (
            <div
              className={
                "flex flex-col justify-center items-center gap border-b border-gray-200"
              }
            >
              {!isNil(activeLender) && notes && !readOnly && (
                <div className={"flex flex-row items-center w-full pt-2"}>
                  <div
                    className={
                      "flex flex-row uppercase text-xs text-gray-400 font-medium items-center gap-x-1 p-2 flex-nowrap"
                    }
                  >
                    Filter By:&nbsp;
                    <Button
                      shape={"circle"}
                      size={"small"}
                      className={`border-0 p-0 flex items-center justify-center bg-gray-50 motion-safe:hover:font-medium text-gray-500 shadow-[0_2px_4px_rgba(0,0,0,0.18)]`}
                      onClick={(): void => {
                        setFilters({ ...filters, priority: !filters.priority });
                      }}
                      icon={
                        !filters.priority ? (
                          <i className="far fa-star text-gray-400" />
                        ) : (
                          <i className="fas fa-star text-blue-500" />
                        )
                      }
                    />
                    <Button
                      size={"small"}
                      shape={"circle"}
                      className={
                        "border-0 p-0 shadow flex items-center justify-center bg-gray-50 motion-safe:hover:font-medium text-gray-500 shadow-[0_2px_4px_rgba(0,0,0,0.18)]"
                      }
                      icon={
                        !filters.calendar ? (
                          <i className="far fa-calendar-alt text-gray-400" />
                        ) : (
                          <i className="fas fa-calendar-alt text-blue-500" />
                        )
                      }
                      onClick={(): void => setDatesShow(!datesShow)}
                    />
                  </div>
                  <Button
                    disabled={editingNote}
                    className={`ml-auto bg-primary hover:bg-hover text-white border-0 disabled:bg-primary/60`}
                    onClick={(): void =>
                      addNote(
                        notes.lenderDTO.id,
                        transactionId,
                        notes,
                        activeLender
                      )
                    }
                    icon={<PlusOutlined />}
                  >
                    New Note
                  </Button>
                </div>
              )}
              {datesShow && (
                <div className={"w-full flex flex-col gap-1"}>
                  <DatePicker.RangePicker
                    value={
                      filters.calendar
                        ? [moment(filters.startDate), moment(filters.endDate)]
                        : null
                    }
                    className={"w-full"}
                    placeholder={["From", "To"]}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "This Year": [
                        moment().startOf("year"),
                        moment().endOf("year"),
                      ],
                    }}
                    format="MM/DD/YYYY"
                    onChange={setFiltersWithDates}
                    allowClear={false}
                  />
                  <ButtonGroup className={"w-full gap-2"}>
                    <Button
                      className={
                        "bg-primary hover:bg-hover text-white border-0 w-full"
                      }
                      onClick={(): void => {
                        setFilters({
                          ...filters,
                          calendar: false,
                          startDate: "",
                          endDate: "",
                        });
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      className={
                        "bg-primary hover:bg-hover text-white border-0 w-full"
                      }
                      onClick={(): void => {
                        setDatesShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              )}
            </div>
          )}

          {notes && activeLender !== null && (
            <div
              className={
                "flex-initial h-full max-h-full overflow-y-auto hide-scrollbar"
              }
            >
              {Number(notes?.count ?? "0") > 0 ? (
                notes.notes?.map(
                  (
                    { id, note, priorities, createDate, lenderId, elementId },
                    index
                  ) => {
                    return (
                      filters &&
                      checkFilters(filters, priorities, createDate) && (
                        <Thread
                          key={id}
                          active={currentActiveNoteThread === index}
                          onClick={(): void => {
                            setCurrentActiveNoteThread(index);
                            enableDeepLink &&
                              history.push(
                                `/transactions/${transactionId}/notes?lenderId=${notes?.lenderDTO?.id}&noteId=${id}`
                              );
                          }}
                          colorLiteral={
                            equals(elementId, lenderId)
                              ? "default"
                              : notes.lenderDTO.name?.charAt(0)?.toUpperCase()
                          }
                          description={convertDateToFormat(
                            createDate,
                            "MMM Do YYYY, hh:mm A",
                            false
                          )}
                          icon={
                            setNotes && (
                              <Button
                                size={"small"}
                                className={`border-0 p-0 shadow flex items-center justify-center bg-gray-50 motion-safe:hover:font-medium text-gray-500 shadow-[0_2px_4px_rgba(0,0,0,0.18)]`}
                                shape={"circle"}
                                onClick={(event): void => {
                                  event.stopPropagation();
                                  togglePriority(
                                    index,
                                    id,
                                    notes,
                                    activeLender
                                  );
                                }}
                              >
                                {!priorities ? (
                                  <i className="far fa-star text-gray-400" />
                                ) : (
                                  <i className="fas fa-star text-blue-500" />
                                )}
                              </Button>
                            )
                          }
                        >
                          <TextArea
                            disabled={true}
                            className={`first-line:font-semibold bg-transparent border-0 p-0 ring-0 cursor-default pointer-events-none text-black overflow-hidden`}
                            autoSize={{ minRows: 2, maxRows: 2 }}
                            value={
                              note && note.length !== 0 ? note : "New Note"
                            }
                          />
                        </Thread>
                      )
                    );
                  }
                )
              ) : (
                <div
                  className={
                    "h-full flex justify-center items-center text-lg font-medium text-gray-400"
                  }
                >
                  <Empty
                    image={
                      <i className="fa-regular fa-note-sticky text-8xl " />
                    }
                    className={"text-gray-400"}
                    description={"No Notes Available"}
                  >
                    {setNotes && (
                      <Button
                        className={
                          "bg-primary hover:bg-hover border-0 text-white"
                        }
                        icon={<i className="fas fa-plus mr-1" />}
                        onClick={(): void =>
                          addNote(
                            notes.lenderDTO.id,
                            transactionId,
                            notes,
                            activeLender
                          )
                        }
                      >
                        Add New Note
                      </Button>
                    )}
                  </Empty>
                </div>
              )}
            </div>
          )}
          {activeLender === null && (
            <div
              className={
                "flex-initial h-full max-h-full overflow-y-auto hide-scrollbar"
              }
              ref={ref.current}
            >
              {recentNotes?.map(
                ({ id, note, priorities, createDate, lenderName }, index) => {
                  return (
                    filters &&
                    checkFilters(filters, priorities, createDate) && (
                      <div
                        data-rowindex={index}
                        key={id}
                        className={"recent-notes-thread"}
                      >
                        <Thread
                          key={index}
                          active={currentActiveNoteThread === index}
                          onClick={(): void => {
                            history.push(
                              `/transactions/${transactionId}/notes`
                            );
                            setCurrentActiveNoteThread(index);
                          }}
                          colorLiteral={lenderName?.charAt(0)?.toUpperCase()}
                          description={
                            <div>
                              {convertDateToFormat(
                                createDate,
                                "MMM Do YYYY, hh:mm A",
                                false
                              )}
                              <br />
                              <Tag
                                className={`${getColorPallet(
                                  lenderName?.charAt(0)?.toUpperCase(),
                                  "text"
                                )} ${getColorPallet(
                                  lenderName?.charAt(0)?.toUpperCase(),
                                  "border"
                                )}`}
                              >
                                {lenderName}
                              </Tag>
                            </div>
                          }
                          icon={
                            priorities && (
                              <i className="fas fa-star text-blue-500" />
                            )
                          }
                        >
                          <TextArea
                            disabled={true}
                            className={`first-line:font-semibold bg-transparent border-0 p-0 ring-0 cursor-default pointer-events-none text-black overflow-hidden`}
                            autoSize={{ minRows: 2, maxRows: 2 }}
                            value={
                              note && note.length !== 0 ? note : "New Note"
                            }
                          />
                        </Thread>
                      </div>
                    )
                  );
                }
              )}
            </div>
          )}
        </div>
      }
      <div className={"col-span-9 h-full h-full p-5"}>
        {activeLender !== null && notes?.notes ? (
          and(
            parseInt(notes?.count ?? "0") >= 1,
            checkFilters(
              filters,
              notes?.notes[currentActiveNoteThread]?.priorities,
              notes?.notes[currentActiveNoteThread]?.createDate
            )
          ) && (
            <Note
              offlineMode={readOnly}
              readOnly={readOnly}
              onView={navigateToLender && navigateToNote}
              setEditingNote={setEditingNote}
              transactionId={transactionId}
              note={notes.notes[currentActiveNoteThread]}
              togglePriority={(id): void =>
                togglePriority(currentActiveNoteThread, id, notes, activeLender)
              }
              removeNote={(id): void =>
                setNotes &&
                removeNote(currentActiveNoteThread, id, notes, activeLender)
              }
              updateNote={(noteId, note, elementId): void =>
                setNotes &&
                updateNote(
                  currentActiveNoteThread,
                  noteId,
                  note,
                  elementId,
                  notes,
                  activeLender
                )
              }
              dealTeam={dealTeam}
              shareNote={(elementId, userIds): void =>
                setNotes &&
                shareNote(
                  currentActiveNoteThread,
                  elementId,
                  userIds,
                  notes,
                  activeLender
                )
              }
              isDealTeam={isDealTeam}
            />
          )
        ) : recentNotes?.length >= currentActiveNoteThread ? (
          <Note
            note={recentNotes[currentActiveNoteThread]}
            transactionId={transactionId}
            readOnly={true}
            onView={navigateToLender && navigateToNote}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

type fetchRecentNotesType = (page: number) => void;

type NotesThreadsType = {
  readOnly?: boolean;
  notes: NotesType | null;
  recentNotes?: NoteDataType[];
  activeLender: number | null;
  setNotes?: (NotesDTO: NotesType, index: number) => void;
  transactionId: string;
  dealTeam?: UserType[];
  fetchRecentNotes?: fetchRecentNotesType;
  navigateToLender?: (lenderId: string, noteId?: string) => void;
  enableDeepLink?: boolean;
  isDealTeam?: boolean;
};
type FilterType = {
  priority: boolean;
  calendar: boolean;
  startDate: string;
  endDate: string;
};
type noteType = (
  noteIndex: number,
  id: string,
  notes: NotesType,
  lenderIndex: number
) => void;
type updateNoteType = (
  noteIndex: number,
  noteId: string,
  note: string,
  elementId: string,
  notes: NotesType,
  lenderIndex: number
) => void;
type shareNoteType = (
  noteIndex: number,
  elementId: string,
  userIds: string[],
  notes: NotesType,
  lenderIndex: number
) => void;
type checkFiltersType = (
  filters: FilterType,
  priorities: boolean,
  date: string
) => boolean;
type addNoteType = (
  lenderId: string,
  elementId: string,
  notes: NotesType,
  lenderIndex: number
) => void;
