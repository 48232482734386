import { FileElementType } from "./types";
import { concat, equals, path } from "ramda";

export const DEFAULT_DATAROOM_LOCATION = (
  data: FileElementType[]
): DataRoomFilesNavigationType => ({
  location: [0, "childFileElements"],
  currentLocationStructure: path([0], data) as FileElementType,
  currentPath: ["Home"],
});

const findPath = (
  tree: FileElementType,
  target: string
): DataRoomFilesNavigationType | null => {
  if (!tree) return null;
  if (equals(tree?.elementId, target)) {
    return {
      location: [],
      currentLocationStructure: tree,
      currentPath: [],
    };
  }

  for (let index = 0; index < tree?.childFileElements.length; ++index) {
    const fileElement = tree.childFileElements[index];
    const path = findPath(fileElement, target);

    if (path) {
      if (fileElement?.isFolder && fileElement.elementId !== target) {
        return {
          location: [index, "childFileElements", ...path.location],
          currentLocationStructure: path.currentLocationStructure,
          currentPath: [fileElement.name, ...path.currentPath],
        };
      } else {
        return {
          location: [],
          currentLocationStructure: tree,
          currentPath: [],
        };
      }
    }
  }
  return null;
};

export const parseLocationForElement: parseLocationForElementType = (
  data,
  elementId
) => {
  const defaultLocation = DEFAULT_DATAROOM_LOCATION(data);
  const location = findPath(data[0], elementId);
  if (location)
    return {
      currentPath: concat(defaultLocation.currentPath, location?.currentPath),
      location: concat(defaultLocation.location, location?.location),
      currentLocationStructure: location.currentLocationStructure,
    };
  else return defaultLocation;
};

type parseLocationForElementType = (
  data: FileElementType[],
  elementId: string
) => DataRoomFilesNavigationType;

export type dataRoomLocationType = ("childFileElements" | number)[];
export type DataRoomFilesNavigationType = {
  location: dataRoomLocationType;
  currentPath: string[];
  currentLocationStructure: FileElementType | null;
};
