import React, { FC, useEffect, useState } from "react";
import {
  getAllocationsForTable,
  getAllocationTable,
} from "../../../../services/services";
import { AllocationTableType, ElementType } from "../../../../utils/enums";
import { ResponseType } from "../../../../utils/uiTypes";
import { filter, isEmpty, pathOr, propEq } from "ramda";
import {
  percentAllocatedColumn,
  TableDataType,
} from "../../../../utils/allocation";
import {
  AllocationKeyType,
  AllocationType,
  CompanyType,
} from "../../../../utils/types";
import { AllocationTable } from "../../../transaction/menu/allocation/AllocationTable";
import { tableColumnHeader } from "../../../../utils/componentUtils";
import { Button, message, Modal } from "antd";
import { getAllocationTableDataType } from "../../../transaction/menu/allocation/Allocation";

export const PastTransactionAllocation: FC<PastTransactionAllocationType> = ({
  name,
  transactionId,
  companies,
  allocationKeys,
}) => {
  const [tableConfig, setTableConfig] = useState<TableDataType | null>(null);
  const [tableData, setTableData] = useState<AllocationType[]>([]);
  const [showAllocation, setShowAllocation] = useState(false);

  const fetchAllocationKeys: fetchAllocationKeysType = (transactionId) => {
    getAllocationTable({
      segments: {
        elementId: transactionId,
        allocationElementType: ElementType.PORTFOLIO,
      },
      params: {
        allocationType: AllocationTableType.FINAL_HOLD,
        isPortfolio: true,
      },
    }).then(({ data = [] }: ResponseType<TableDataType[]>) => {
      if (data.length > 0) {
        setTableConfig(data[0]);
        fetchAllocationTableData(transactionId, pathOr("", [0, "id"], data));
      }
    });
  };

  const fetchAllocationTableData: getAllocationTableDataType = (
    elementId,
    tableId
  ) => {
    getAllocationsForTable({
      segments: {
        elementId,
        tableId,
        allocationElementType: ElementType.PETRANSACTION,
      },
      params: { isPortfolio: true },
    })
      .then(({ data = [] }: ResponseType<AllocationType[]>) => {
        setTableData(data);
      })
      .catch(() => {
        message.error("Unable to get Allocation Data");
      });
  };

  useEffect(() => {
    if (transactionId) {
      fetchAllocationKeys(transactionId);
    }
  }, [transactionId]);
  return (
    <div className={""}>
      {isEmpty(tableData) ? (
        tableColumnHeader("No Allocation Created Yet")
      ) : (
        <Button
          size={"small"}
          type={"dashed"}
          onClick={(): void => setShowAllocation(true)}
        >
          Show Allocation
        </Button>
      )}
      <Modal
        open={showAllocation}
        title={`${name} Allocations`}
        onCancel={() => setShowAllocation(false)}
        okText={false}
        width={"80%"}
        footer={false}
      >
        <AllocationTable
          pageType={ElementType.PORTFOLIO}
          peTransactionIdOrPortfolioId={transactionId ?? ""}
          keys={filter(
            propEq("allocationTableType", AllocationTableType.FINAL_HOLD),
            allocationKeys
          )}
          companies={companies}
          editPermission={false}
          config={tableConfig}
          data={tableData}
          extraColumns={percentAllocatedColumn}
        />
      </Modal>
    </div>
  );
};

type PastTransactionAllocationType = {
  name: string;
  transactionId: string;
  companies: CompanyType[];
  allocationKeys: AllocationKeyType[];
};
type fetchAllocationKeysType = (transactionId: string) => void;
