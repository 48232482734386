import React, { FC, ReactNode, useContext, useEffect, useState } from "react";

import {
  deleteTeamMember,
  getAllTeamMembers,
  invite,
} from "../../services/services";
import { Button, message, Modal, Table, Tag, Tooltip } from "antd";
import { deleteUserFromTeamType, TeamMemberType } from "../../utils/types";
import { InviteType, ResponseType, voidType } from "../../utils/uiTypes";

import { CompanyTeamForm } from "../forms/CompanyTeamForm";
import { removeItemFromArray, valOrDefault } from "../../utils/utils";
import { UserContext } from "../../context/UserContext";
import { LeftOutlined, MailOutlined, RightOutlined } from "@ant-design/icons";
import {
  ElementType,
  InvitationStatusType,
  PermissionType,
} from "../../utils/enums";
import { ConfirmDelete } from "../../utils/confirmationModals";
import { usePageTitle } from "../../customHooks/usePageTitle";
import {
  invitationStatusTag,
  resendTeamInvite,
  tableColumnHeader,
} from "../../utils/componentUtils";
import { ACTION_BUTTON_CSS } from "../../utils/cssConfigs";
import useWindowDimensions from "../../customHooks/useWindowDimensions";
import { ColumnsType } from "antd/es/table";
import { ViewEmail } from "../general/ViewEmail";

export const TeamManage: FC<TeamManageType> = ({
  initialSetup = false,
  onSubmit,
}: TeamManageType) => {
  usePageTitle("Company Team");
  const { user } = useContext(UserContext);

  const [teamMembers, setTeamMembers] = useState<TeamMemberType[]>([]);
  const [newMemberModal, setNewMemberModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { height: windowHeight } = useWindowDimensions();

  const getTeamMembers: voidType = () => {
    setLoading(true);
    getAllTeamMembers({})
      .then(({ data = [] }: ResponseType<TeamMemberType[]>) => {
        setTeamMembers(data);
        setLoading(false);
      })
      .catch((e: string) => {
        console.error(e);
        setLoading(false);
      });
  };

  const submitAddTeamMember: submitAddTeamMemberType = (
    data: InviteType,
    companyId
  ): void => {
    message.loading({ key: "addMember", content: "Adding Team Member" });
    invite({
      body: JSON.stringify({
        ...data,
        elementType: ElementType.LENDER,
        companyId,
      }),
    })
      .then(() => {
        getTeamMembers();
        message.success({
          key: "addMember",
          content: "Team member has been added successfully",
        });
        setNewMemberModal(false);
      })
      .catch((error: string) => {
        message.error({
          key: "addMember",
          content: error ? error : "Something went wrong. Please try again",
        });
      });
  };

  const submitDeleteTeamMember: submitDeleteTeamMemberType = (
    data: deleteUserFromTeamType,
    teamMembers,
    i: number
  ): void => {
    message.loading({ key: "deleteMember", content: "Removing Team Member" });
    deleteTeamMember({
      body: JSON.stringify(data),
    })
      .then(() => {
        setTeamMembers(removeItemFromArray(i, teamMembers));
        message.success({
          key: "addMember",
          content: "Team member has been deleted successfully",
        });
      })
      .catch((error: string) => {
        message.error({
          key: "addMember",
          content: error ? error : "Something went wrong. Please try again",
        });
      });
  };

  const getDeleteUserFromTeamData: getDeleteUserFromTeamDataType = (
    email,
    companyId
  ) => {
    return {
      companyId,
      elementId: null,
      elementType: ElementType.LENDER,
      email,
    };
  };

  const renderOptionalValues: renderOptionalValuesType = (
    value: string
  ): ReactNode => {
    return value ? (
      <>{value}</>
    ) : (
      <Tag className={"text-danger bg-transparent border-none p-0"}>
        Not Provided
      </Tag>
    );
  };

  const renderUserRole: renderUserRoleType = (
    permissions: string
  ): ReactNode => {
    if (permissions && permissions.length) {
      return (
        <>
          {permissions?.includes(PermissionType.ADMIN)
            ? "Admin"
            : permissions?.includes(PermissionType.NON_ADMIN)
            ? "User"
            : "-"}
        </>
      );
    } else {
      return (
        <Tag className={"text-danger bg-transparent border-none p-0"}>
          No Permissions!
        </Tag>
      );
    }
  };

  const columns: ColumnsType<TeamMemberType> = [
    {
      title: tableColumnHeader("First Name"),
      dataIndex: ["userDTO", "firstName"],
      key: "firstName",
      className: "group-hover:bg-blue-50 min-w-[85px]",
    },
    {
      title: tableColumnHeader("Last Name"),
      dataIndex: ["userDTO", "lastName"],
      key: "lastName",
      className: "group-hover:bg-blue-50 min-w-[85px]",
    },
    {
      title: tableColumnHeader("E-mail"),
      dataIndex: ["userDTO", "email"],
      key: "userDTO.email",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      width: "fit-content",
      render: function email(
        email: string,
        { userDTO }: TeamMemberType
      ): ReactNode {
        return (
          <span>
            {ViewEmail(email)}
            &nbsp;&nbsp;
            {userDTO.userId === user?.userId && <Tag color={"green"}>YOU</Tag>}
          </span>
        );
      },
    },
    {
      title: tableColumnHeader("Phone Number"),
      dataIndex: ["userDTO", "phoneNumber"],
      key: "phoneNumber",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: renderOptionalValues,
    },
    {
      title: tableColumnHeader("Title"),
      dataIndex: ["userDTO", "title"],
      key: "title",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: renderOptionalValues,
    },
    {
      title: tableColumnHeader("Status"),
      dataIndex: "invitationStatus",
      key: "invitationStatus",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: function resendInvite(status: InvitationStatusType): ReactNode {
        return <>{invitationStatusTag(status)}</>;
      },
    },
    {
      title: tableColumnHeader("Role"),
      dataIndex: ["permissions"],
      key: "permissions",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: renderUserRole,
    },
    {
      title: tableColumnHeader("Actions"),
      dataIndex: "operation",
      key: "operation",
      align: "right",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: function operation(
        _: string,
        teamMember: TeamMemberType,
        index: number
      ): ReactNode {
        return (
          teamMember.userDTO.userId !== user?.userId && (
            <div className={"flex gap-1 justify-center relative"}>
              <Button
                type={"text"}
                icon={<i className="fas fa-ellipsis-h" />}
                className={
                  "absolute mx-auto border-0 flex items-center px-2 text-gray-900 hidden md:block opacity-50 group-hover:opacity-0"
                }
              />
              {(!teamMember.invitationStatus ||
                teamMember.invitationStatus ===
                  InvitationStatusType.PENDING) && (
                <Tooltip title={"Resend invite"}>
                  <Button
                    className={`hover:text-primary opacity-0 group-hover:opacity-100 ${ACTION_BUTTON_CSS}`}
                    onClick={(): void =>
                      resendTeamInvite(
                        teamMember.userDTO.userId,
                        user?.companyId,
                        undefined,
                        undefined,
                        "COMPANY"
                      )
                    }
                  >
                    <MailOutlined className={" cursor-pointer"} />
                  </Button>
                </Tooltip>
              )}
              <Button
                className={`${ACTION_BUTTON_CSS} hover:text-red-500 opacity-0 group-hover:opacity-100`}
                icon={<i className="fas fa-trash-alt" />}
                onClick={(): void => {
                  ConfirmDelete(
                    "Confirm Delete Member?",
                    () =>
                      submitDeleteTeamMember(
                        getDeleteUserFromTeamData(
                          teamMember?.userDTO?.email,
                          valOrDefault(teamMember?.companyId, user?.companyId)
                        ),
                        teamMembers,
                        index
                      ),
                    <>
                      Name:{" "}
                      {teamMember?.userDTO?.firstName +
                        " " +
                        teamMember?.userDTO?.lastName}
                      <br />
                      E-mail: {teamMember?.userDTO?.email}
                    </>
                  );
                }}
              />
            </div>
          )
        );
      },
    },
  ];

  useEffect(() => {
    getTeamMembers();
  }, []);

  return (
    <div className={"relative mx-auto mx-2 p-6 h-full flex flex-col"}>
      <div className={"mb-5 flex flex-row items-start"}>
        <span className="text-2xl font-medium">
          {initialSetup ? "Add your team" : "Team"}
        </span>
        {user && (user.isAdmin || initialSetup) && (
          <Button
            icon={<i className="fas fa-user-plus text-white mr-1" />}
            className={
              "border-0 bg-primary hover:bg-hover text-white border-0 sm:ml-auto"
            }
            onClick={(): void => {
              setNewMemberModal(true);
            }}
          >
            Add Team Member
          </Button>
        )}
      </div>
      {teamMembers && (
        <>
          {user && (user.isAdmin || initialSetup) && (
            <Modal
              title={"Add Team Member"}
              open={newMemberModal}
              destroyOnClose={true}
              footer={false}
              closable={true}
              onCancel={(): void => setNewMemberModal(false)}
            >
              <CompanyTeamForm
                onSubmit={(data): void => {
                  user?.companyId &&
                    submitAddTeamMember(data, user?.companyId, teamMembers);
                }}
              />
            </Modal>
          )}
          <div className={"h-full "}>
            <Table
              loading={loading}
              locale={{
                emptyText: "No Team Members",
              }}
              columns={
                user?.isAdmin
                  ? columns
                  : columns?.filter((col) => col?.key !== "operation")
              }
              dataSource={teamMembers}
              pagination={false}
              className={"bg-transparent"}
              rowKey={(record): string => record?.userDTO?.userId}
              scroll={{ x: true, y: windowHeight - 300 }}
              rowClassName={"group relative hover:bg-blue-50"}
            />
            {initialSetup && (
              <div className={"mt-6 flex justify-end"}>
                <Button
                  className={" w-32 mr-6"}
                  onClick={(): void => {
                    onSubmit(0);
                  }}
                >
                  <LeftOutlined /> Back
                </Button>
                <Button
                  className={
                    "bg-primary hover:bg-hover border-0 w-32 text-white"
                  }
                  onClick={(): void => {
                    onSubmit(2);
                  }}
                >
                  Next <RightOutlined />
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

type TeamManageType = {
  initialSetup: boolean;
  onSubmit: (val: number) => void;
};
type submitAddTeamMemberType = (
  data: InviteType,
  companyId: string,
  teamMembers: TeamMemberType[]
) => void;
type submitDeleteTeamMemberType = (
  data: deleteUserFromTeamType,
  teamMembers: TeamMemberType[],
  i: number
) => void;
type getDeleteUserFromTeamDataType = (
  email: string,
  companyId: string
) => deleteUserFromTeamType;
type renderOptionalValuesType = (value: string) => ReactNode;
type renderUserRoleType = (permissions: string) => ReactNode;
