import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CompanyModuleAccessType,
  CompanyModuleType,
  LenderNdaSignType,
  NdaConfigType,
  TransactionType,
} from "../utils/types";
import {
  addUserClickThroughLog,
  checkClickThroughRequired,
  getLenderClickThroughConfigs,
  getModulePreference,
  getPETransaction,
} from "../services/services";
import { Button, Progress, Result } from "antd";
import { ResponseType } from "../utils/uiTypes";
import { useHistory } from "react-router";
import { PermissionType } from "../utils/enums";
import { TRANSACTIONS_PAGE_URL } from "../utils/redirect";
import { valOrDefault } from "../utils/utils";
import { and, equals, isEmpty, isNil, pathOr } from "ramda";
import { ClickThroughConfirmationModal } from "../components/transaction/ClickThroughConfirmationModal";
import { UserContext } from "./UserContext";

enum LoadingType {
  NDA,
  NONE,
}

export const TransactionContext = createContext<TransactionContextType>({
  transaction: null,
  setTransaction: () => {
    return;
  },
  transactionId: null,
  setTransactionId: () => {
    return;
  },
  permissions: [],
  fetchTransaction: () => {
    return;
  },
  modules: null,
  belongToDealTeam: null,
  belongToTxCreatorCompany: false,
});

export const TransactionProvider: FC<TransactionProviderType> = function ({
  children,
}: TransactionProviderType) {
  const { user } = useContext(UserContext);
  const [transaction, setTransaction] = useState<null | TransactionType>(null);
  const [transactionId, setTransactionId] = useState<null | string>(null);
  const [permissions, setPermissions] = useState<PermissionType[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<LoadingType>(LoadingType.NONE);
  const [modules, setModules] = useState<CompanyModuleType | null>(null);
  const [belongToDealTeam, setBelongToDealTeam] = useState<boolean | null>(
    null
  );
  const [NDARequired, setNDARequired] = useState<boolean | null>(null);
  const [ndaSettings, setNdaSettings] = useState<NdaConfigType[]>([]);

  const [progress, setProgress] = useState<number>(0);

  const history = useHistory();

  const initiateTransition = (progress: number | null): void => {
    if (!equals(progress, 101)) {
      setProgress(() => 95);
      setTimeout(() => {
        setProgress(() => 100);
      }, 500);
      setTimeout(() => {
        setProgress(() => 101);
      }, 1000);
    }
  };

  const getData = (id: string): void => {
    Promise.all([fetchTransaction(id), fetchModules(id)])
      .then(() => {
        initiateTransition(progress);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const fetchTransaction = (id: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      getPETransaction({
        segments: {
          id,
        },
      })
        .then(({ data }: ResponseType<TransactionType>) => {
          setTransaction(data);
          setPermissions(valOrDefault([], data?.permissionDTO?.permissions));
          setBelongToDealTeam(
            data.permissionDTO.permissions.includes(
              PermissionType.ADMIN_PETRANSACTION
            ) ||
              data.permissionDTO.permissions.includes(
                PermissionType.NON_ADMIN_PETRANSACTION
              )
          );
          resolve();
        })
        .catch((error: string) => {
          reject({
            error: true,
            label: error ? error : "Failed Fetching Transaction!",
          });
        });
    });
  };

  const fetchModules: transactionFetchType = (id) => {
    return new Promise((resolve, reject) => {
      getModulePreference({
        segments: {
          id,
        },
      })
        .then(({ data }: ResponseType<CompanyModuleAccessType>) => {
          setModules(data.moduleAccessDTO);
          resolve();
        })
        .catch((error: string) => {
          reject({
            error: true,
            label: error ? error : "Error getting Access Module",
          });
        });
    });
  };

  const initiateConfirmNda = (
    transactionId: string,
    body: Partial<LenderNdaSignType>
  ) => {
    setLoading(LoadingType.NDA);
    addUserClickThroughLog({ body: JSON.stringify(body) })
      .then(() => {
        if (body?.ndaAccepted) {
          checkNDA(transactionId, true);
        } else {
          history.push("/transactions");
        }
        setLoading(LoadingType.NONE);
      })
      .catch((error: string) => {
        setError(error ?? "Unable to get access");
        setLoading(LoadingType.NONE);
      });
  };

  const fetchNDASettingsForTransaction: getTransactionData = (
    transactionId
  ) => {
    getLenderClickThroughConfigs({ segments: { transactionId } })
      .then(({ data }: ResponseType<NdaConfigType[]>) => {
        setNdaSettings(
          data.sort((a, b) =>
            a.lenderName.toLowerCase().localeCompare(b.lenderName.toLowerCase())
          )
        );
        !equals(progress, 101) && setProgress(60);
      })
      .catch((error: string) => {
        setError(error ?? "Server Error");
      });
  };

  const checkNDA: getTransactionData = (
    transactionId,
    fetchTransaction = false
  ) => {
    checkClickThroughRequired({ segments: { transactionId } })
      .then(({ data }: ResponseType<boolean>) => {
        !equals(progress, 101) && setProgress(30);
        setNDARequired(data);

        if (data) {
          fetchNDASettingsForTransaction(transactionId);
        } else if (!data && fetchTransaction) {
          getData(transactionId);
        }
      })
      .catch((error: string) => {
        setError(error ?? "Unable to check your access for this transaction");
      });
  };

  const onClickThroughAction = (ndaAccepted: boolean): void => {
    initiateConfirmNda(transactionId ?? "", {
      transactionId: transactionId ?? "",
      ndaAccepted,
      ndaUploadedElementId: pathOr(
        "",
        [0, "fileElementDTOS", 0, "elementId"],
        ndaSettings ?? []
      ),
      ndaElementId: pathOr("", [0, "ndaConfigId"], ndaSettings ?? []),
    });
  };

  useEffect(() => {
    if (transactionId !== null) {
      setProgress(0);
      checkNDA(transactionId, true);
    }
  }, [transactionId]);

  return (
    <TransactionContext.Provider
      value={{
        transaction,
        setTransaction,
        transactionId,
        setTransactionId,
        permissions,
        fetchTransaction,
        modules,
        belongToDealTeam,
        belongToTxCreatorCompany:
          transaction?.creatorCompanyId === user?.companyId,
      }}
    >
      <div className={"relative h-screen w-full"}>
        {error && (
          <div className={"w-full h-full items-center flex"}>
            <Result
              className={"w-full"}
              status={"500"}
              title={"500"}
              subTitle={error}
              extra={
                <>
                  <Button type={"default"} onClick={window.location.reload}>
                    Try Again!
                  </Button>
                  <Button
                    className={"bg-primary hover:bg-hover text-white border-0"}
                    onClick={(): void => history.push(TRANSACTIONS_PAGE_URL)}
                  >
                    Back Home
                  </Button>
                </>
              }
            />
          </div>
        )}
        {and(!error, NDARequired) &&
          and(!isNil(NDARequired), !isEmpty(ndaSettings)) && (
            <ClickThroughConfirmationModal
              fileId={pathOr(
                "",
                [0, "fileElementDTOS", 0, "fileDTO", "fileId"],
                ndaSettings ?? []
              )}
              ndaConfigId={pathOr("", [0, "ndaConfigId"], ndaSettings ?? [])}
              transactionId={transactionId ?? ""}
              onAction={onClickThroughAction}
              loading={equals(loading, LoadingType.NDA)}
            />
          )}
        {
          <>
            {and(!error, !isNil(transactionId)) && !equals(progress, 101) && (
              <Progress
                type={"circle"}
                trailColor={"#BAE7FF"}
                strokeColor={"#12264F"}
                className={`absolute flex h-full w-full max-w-full items-center justify-center z-[1000] bg-gray-100 duration-1000 transition-all ease-in-out ${
                  progress < 100 ? "bg-opacity-100" : "bg-opacity-0"
                }`}
                percent={progress}
                status={"active"}
                showInfo={true}
              />
            )}
            {children}
          </>
        }
      </div>
    </TransactionContext.Provider>
  );
};

type TransactionProviderType = {
  children: ReactNode;
};

type TransactionContextType = {
  transaction: null | TransactionType;
  setTransaction: Dispatch<SetStateAction<TransactionType | null>>;
  transactionId: null | string;
  setTransactionId: (u: string | null) => void;
  permissions: Array<PermissionType>;
  fetchTransaction: (id: string) => void;
  modules: CompanyModuleType | null;
  belongToDealTeam: boolean | null;
  belongToTxCreatorCompany: boolean;
};

type transactionFetchType = (id: string) => Promise<void>;
type getTransactionData = (id: string, fetchTransaction?: boolean) => void;
