import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { useHistory, useParams } from "react-router";
import { getCrmCompanyProfile } from "../../services/services";
import {
  fetchWithIdType,
  MenuRouteType,
  ResponseType,
} from "../../utils/uiTypes";
import { CRMCompanyMappingType, CRMFilterType } from "../../utils/types";
import { AllocationTableType } from "../../utils/enums";
import { RelationshipProfile } from "./RelationshipProfile";
import { DealActivity } from "./DealActivity";
import { KeyPersonnel } from "./KeyPersonnel";
import { AllocationInfoTable } from "./AllocationInfoTable";
import { Notes } from "./Notes";
import { UserContext } from "../../context/UserContext";
import CompanyBuildingIcon from "../../images/CompanyBuildingIcon.svg";
import { WithSideNav } from "../layout/WithSideNav";
import { RelationshipInsights } from "./RelationshipInsights";
import { LoadingOverlay } from "../../utils/componentUtils";
import { RelationshipFilters } from "./RelationshipFilters";
import {
  getRelationshipModules,
  isCRM,
  isSponsorCoverage,
  parseFilters,
  parseFiltersAndNavigate,
} from "../../utils/relationship";
import { message } from "antd";
import { FeesInfoTable } from "./FeesInfoTable";

const ROUTES = (
  data: CRMCompanyMappingType | null,
  onSave: Dispatch<SetStateAction<CRMCompanyMappingType | null>>,
  isCRM: boolean,
  isSponsorCoverage: boolean
): MenuRouteType[] => [
  {
    icon: <i className="fa-solid fa-circle-info text-primary" />,
    title: "Profile",
    key: "profile",
    Component: RelationshipProfile,
    path: ["/relationship/:id/profile"],
    exact: true,
    props: {
      data,
      onSave,
    },
  },
  {
    icon: <i className="fa-solid fa-list-check text-primary" />,
    title: "Deal Activities",
    key: "deals",
    Component: DealActivity,
    path: ["/relationship/:id/deals"],
    exact: true,
  },
  {
    icon: <i className="fa-solid fa-users text-primary" />,
    title: "Key Personnel",
    key: "key-personnel",
    Component: KeyPersonnel,
    path: ["/relationship/:id/key-personnel"],
    exact: true,
  },
  {
    icon: <i className="fa-solid fa-money-bill-transfer text-primary" />,
    title: "Allocation",
    key: "allocations",
    Component: AllocationInfoTable,
    props: {
      tableType: AllocationTableType.FINAL_HOLD,
    },
    path: ["/relationship/:id/allocations"],
    exact: true,
  },
  {
    icon: <i className="fa-solid fa-coins text-primary" />,
    title: "Fees",
    key: "fees",
    Component: FeesInfoTable,
    props: {
      tableType: AllocationTableType.FEES,
    },
    path: ["/relationship/:id/fees"],
    exact: true,
  },
  {
    icon: <i className="fa-solid fa-note-sticky text-primary"></i>,
    title: "Notes",
    key: "notes",
    Component: Notes,
    path: ["/relationship/:id/notes"],
    exact: true,
  },
  {
    icon: <i className="fa-solid fa-chart-simple text-primary"></i>,
    title: "Insights",
    key: "insights",
    Component: RelationshipInsights,
    path: ["/relationship/:id/insights"],
    exact: true,
  },
];

export const RelationshipDashboard: FC = () => {
  const { id, crmMenu } = useParams<ParamsType>();
  const { user, companyModulePreference } = useContext(UserContext);
  const history = useHistory();

  const [companyProfile, setCompanyProfile] =
    useState<CRMCompanyMappingType | null>(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<CRMFilterType>(
    parseFilters(location.search)
  );

  usePageTitle("Relationship Dashboard");

  const fetchCompanyProfile: fetchWithIdType = (id) => {
    setLoading(true);
    getCrmCompanyProfile({
      segments: { id },
    })
      .then(({ data }: ResponseType<CRMCompanyMappingType>) => {
        setLoading(false);
        setCompanyProfile(data);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCompanyProfile(id);
  }, [id]);

  useEffect(() => {
    history.push(
      parseFiltersAndNavigate(
        id,
        crmMenu,
        user,
        companyModulePreference,
        filters
      )
    );
  }, [filters, crmMenu, companyModulePreference, user]);

  return (
    <WithSideNav
      loading={loading}
      title={
        <div
          className={
            "flex flex-row justify-between items-end gap-2 bg-white print:hidden"
          }
        >
          <div className={"flex flex-row items-center gap-2"}>
            <img
              src={CompanyBuildingIcon}
              className={"h-16"}
              alt={"Company Profile"}
            />
            <div className={"flex flex-col"}>
              <span className={"text-sm font-medium"}>
                Relationship Dashboard
              </span>
              <span className={"text-2xl font-medium"}>
                <LoadingOverlay loading={loading}>
                  {companyProfile?.companyDTO?.name || "-"}
                </LoadingOverlay>
              </span>
            </div>
          </div>
        </div>
      }
      defaultValue={crmMenu ?? "profile"}
      selectedKeys={[crmMenu]}
      menu={ROUTES(
        companyProfile,
        setCompanyProfile,
        isCRM(user),
        isSponsorCoverage(user)
      )}
      gotoRoute={({ key }) => {
        history.push({
          pathname: `/relationship/${id}/${key}`,
          search: location.search,
        });
      }}
      header={
        <RelationshipFilters
          since={companyProfile?.since}
          filters={filters}
          onChange={setFilters}
          relationshipModules={getRelationshipModules(
            user?.elementPermissions ?? [],
            companyModulePreference
          )}
        />
      }
    />
  );
};

type ParamsType = {
  id: string;
  crmMenu: string;
};
