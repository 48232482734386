import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { LenderEngagementDataType, LenderType } from "../../../../utils/types";
import { TransactionContext } from "../../../../context/TransactionContext";
import { CustomSpin } from "../../../general/CustomSpin";
import { usePageTitle } from "../../../../customHooks/usePageTitle";
import { filterListOfObjectsByStringValue } from "../../../../utils/utils";
import { Alert, Empty, Input, Switch, Table } from "antd";
import { StatisticValue } from "../../../general/StatisticValue";
import { groupBy, isEmpty, isNil, pathOr } from "ramda";
import { DollarOutlined } from "@ant-design/icons";
import { columnSort } from "../../../../utils/relationship";
import { tableColumnHeader } from "../../../../utils/componentUtils";
import { getLenderEngagement } from "../../../../services/services";
import { ResponseType } from "../../../../utils/uiTypes";
import { ColumnsType } from "antd/es/table";
import { TextOverFlowHandle } from "../../../general/TextOverFlowHandle";
import moment from "moment/moment";
import {
  ELEMENT_TYPE_LABELS,
  LENDER_ACTION_LABELS,
  MODULE_LABELS,
} from "./utils/utils";
import { Link } from "react-router-dom";

export const LenderEngagement: FC = function () {
  usePageTitle("Lender Engagement");

  const { transaction } = useContext(TransactionContext);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isGrouped, setIsGrouped] = useState(false);
  const [data, setData] = useState<LenderEngagementDataType[]>([]);
  const [statistics, setStatistics] = useState<statisticType>(null);
  useEffect(() => {
    setLoading(true);
    if (transaction) {
      getLenderEngagement({
        params: {
          transactionIds: transaction?.peTransactionId,
        },
      })
        .then((data: ResponseType<LenderEngagementDataType[]>) => {
          setData(data.data);
          const groupedData = data.data?.reduce(
            (
              acc: {
                [module: string]: {
                  [action: string]: number;
                };
              },
              item
            ) => {
              const { module, action, actionCount } = item;
              const totalCount = parseInt(actionCount);

              if (!acc[module]) {
                acc[module] = {};
              }

              if (!acc[module][action]) {
                acc[module][action] = totalCount;
              } else {
                acc[module][action] += totalCount;
              }

              return acc;
            },
            {}
          );
          setStatistics(groupedData);
          setLoading(false);
        })
        .catch((error: string) => console.error(error));
    }
  }, [transaction, transaction?.peTransactionId]);

  const leColumns: ColumnsType<LenderEngagementDataType> = [
    {
      title: tableColumnHeader("ACTION DATE"),
      key: "lastActionDate",
      dataIndex: "lastActionDate",
      width: "10%",
      render: (record) => moment(record).format("ll"),
      sorter: (a, b) =>
        columnSort(a.lastActionDate, b.lastActionDate, "NUMERIC"),
      defaultSortOrder: "descend",
      className: "group-hover:bg-blue-50 min-w-[130px]",
    },
    {
      title: tableColumnHeader("User"),
      align: "left",
      dataIndex: "userName",
      key: "userName",
      className:
        "group-hover:bg-blue-50 min-w-[130px] max-w-[300px] overflow-hidden",
      sorter: (a, b) => columnSort(a.userName ?? "", b.userName ?? "", "TEXT"),
      render: function name(deleted: boolean, record): ReactNode {
        return <TextOverFlowHandle text={record.userName} />;
      },
    },
    {
      title: tableColumnHeader("INSTITUTION"),
      dataIndex: "userCompany",
      key: "userCompany",
      render: (record) =>
        !isNil(record) ? (
          <span>{record}</span>
        ) : (
          <span className={"text-muted"}>-</span>
        ),
      sorter: (a, b) =>
        columnSort(a.userCompany ?? "", b.userCompany ?? "", "TEXT"),
      className: "group-hover:bg-blue-50 min-w-[130px]",
    },
    {
      title: tableColumnHeader("MODULE"),
      dataIndex: "module",
      key: "module",
      render: (record) =>
        !isNil(record) ? (
          <span>{`${pathOr(record, [record], MODULE_LABELS)}`}</span>
        ) : (
          <span className={"text-muted"}>-</span>
        ),
      sorter: (a, b) => columnSort(a.module ?? "", b.module ?? "", "TEXT"),
      className: "group-hover:bg-blue-50 min-w-[150px]",
    },
    {
      title: tableColumnHeader("ACTION"),
      dataIndex: "action",
      key: "action",
      render: (record) =>
        !isNil(record) ? (
          <span>{`${pathOr(record, [record], LENDER_ACTION_LABELS)}`}</span>
        ) : (
          <span className={"text-muted"}>-</span>
        ),
      sorter: (a, b) => columnSort(a.module ?? "", b.module ?? "", "TEXT"),
      className: "group-hover:bg-blue-50 min-w-[150px]",
    },
    {
      title: tableColumnHeader("ELEMENT"),
      dataIndex: "elementName",
      key: "elementName",
      render: (i, record) =>
        record.elementType === "FILE" ? (
          <Link
            to={`/transactions/${
              transaction?.peTransactionId ?? ""
            }/dataroom?elementId=${record?.elementId}`}
          >
            {record?.elementName ?? ""}
          </Link>
        ) : !isNil(i) ? (
          <span>{`${pathOr(i, [i], ELEMENT_TYPE_LABELS)}`}</span>
        ) : (
          <span className={"text-muted"}>-</span>
        ),
      sorter: (a, b) => columnSort(a.module ?? "", b.module ?? "", "TEXT"),
      className: "group-hover:bg-blue-50 min-w-[150px]",
    },
    {
      title: tableColumnHeader("COUNT"),
      width: "10%",
      dataIndex: "actionCount",
      key: "actionCount",
      render: (record) =>
        !isNil(record) ? (
          <span>{record}</span>
        ) : (
          <span className={"text-muted"}>-</span>
        ),
      sorter: (a, b) =>
        columnSort(a.actionCount ?? "", b.actionCount ?? "", "TEXT"),
      className: "group-hover:bg-blue-50 min-w-[150px]",
    },
  ];

  const groupedColumns: ColumnsType<LenderUserMapping> = [
    {
      title: tableColumnHeader("LENDER"),
      dataIndex: "lender",
      key: "lender",
      render: (record) => <span>{record.name}</span>,
      defaultSortOrder: "descend",
      className: "min-w-[130px]",
    },
  ];

  return (
    <div className={"relative max-h-full h-full w-full"}>
      <Alert
        className={"py-1.5 text-xs"}
        type={"warning"}
        showIcon={true}
        banner={true}
        message="The engagement analytics is private to your organization."
      />
      <CustomSpin
        loading={loading}
        loadingText={
          transaction ? "Fetching Data..." : "Fetching Transaction..."
        }
      />

      <div className={`p-5 pt-2 w-full h-full max-h-full`}>
        <div className={`flex flex-col h-full w-full`}>
          <div className={"flex flex-row items-center py-3"}>
            <div
              className={
                "justify-center m-1.5 w-full  grow flex items-stretch flex-col "
              }
            >
              <div className={"flex flex-row items-center gap-x-2"}>
                <StatisticValue label={"Data Room Download"} loading={loading}>
                  {statistics?.DATA_ROOM?.DOWNLOAD || 0}
                </StatisticValue>
                <StatisticValue label={"NDA"} loading={loading}>
                  {(statistics?.NDA?.NDA_ACCEPTED || 0) +
                    (statistics?.NDA?.NDA_REJECTED || 0)}
                </StatisticValue>
                <StatisticValue label={"Termsheet Download"} loading={loading}>
                  {statistics?.TERM_SHEET?.DOWNLOAD || 0}
                </StatisticValue>
                <StatisticValue label={"Termsheet Published"} loading={loading}>
                  {statistics?.TERM_SHEET?.PUBLISHED || 0}
                </StatisticValue>
              </div>
            </div>
          </div>
          <div
            className={`py-3 w-full border-b font-medium flex flex-row items-center justify-between`}
          >
            <Input
              placeholder={"Search lender engagements..."}
              allowClear={true}
              className={"max-w-xs"}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />

            <Switch
              className={`${isGrouped ? "bg-success" : "bg-muted"}`}
              checked={isGrouped}
              checkedChildren={"Grouped by Lender"}
              unCheckedChildren={"Ungrouped"}
              onChange={setIsGrouped}
            />
          </div>
          <div className={`relative h-full overflow-y-auto flex border-t`}>
            {isGrouped ? (
              <Table<LenderUserMapping>
                locale={{
                  emptyText: (
                    <Empty
                      image={
                        <DollarOutlined
                          className={"text-8xl mt-1 text-gray-300"}
                        />
                      }
                      className={"text-gray-400"}
                      description={"No Activity"}
                    />
                  ),
                }}
                expandable={{
                  expandRowByClick: true,
                  expandedRowClassName: () => "m-2",
                  rowExpandable: ({ users }) => !isEmpty(users),
                  expandedRowRender: ({ users = [] }) => (
                    <div className="p-5">
                      <Table
                        bordered={true}
                        size="small"
                        dataSource={users ?? []}
                        columns={leColumns}
                        pagination={false}
                        style={{ marginLeft: 0 }}
                      />
                    </div>
                  ),
                }}
                size="small"
                rowClassName={"group"}
                className={`w-full`}
                sticky={true}
                dataSource={getLenderDataMapping(
                  transaction?.lenderDTOs ?? [],
                  data
                )}
                columns={groupedColumns}
                loading={loading}
                rowKey={({ lender }) => lender.id}
                pagination={false}
                scroll={{
                  x: true,
                }}
              />
            ) : (
              <Table<LenderEngagementDataType>
                locale={{
                  emptyText: (
                    <Empty
                      image={
                        <DollarOutlined
                          className={"text-8xl mt-1 text-gray-300"}
                        />
                      }
                      className={"text-gray-400"}
                      description={"No Activity"}
                    />
                  ),
                }}
                size="small"
                rowClassName={"group"}
                className={`w-full`}
                sticky={true}
                dataSource={filterListOfObjectsByStringValue(searchValue, data)}
                columns={leColumns}
                loading={loading}
                rowKey={({ id }) => id}
                pagination={false}
                scroll={{
                  x: true,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const getLenderDataMapping = (
  lenders: LenderType[],
  data: LenderEngagementDataType[]
): LenderUserMapping[] => {
  const grouped = groupBy(({ userCompany }) => userCompany, data);
  return lenders.reduce<LenderUserMapping[]>((acc, lender) => {
    const users: LenderEngagementDataType[] = pathOr(
      [],
      [lender.name],
      grouped
    );
    if (users.length > 0) {
      acc.push({
        lender: lender,
        users: users,
      });
    }

    return acc;
  }, []);
};

type LenderUserMapping = {
  lender: LenderType;
  users: LenderEngagementDataType[];
};
type statisticType = { [module: string]: { [action: string]: number } } | null;
