import React, { ReactElement, ReactNode } from "react";
import { Button, Tooltip } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

export const CustomButton = ({
  size,
  label,
  outlined = false,
  className = "",
  children,
  onClick,
  disabled,
  type = "text",
}: CustomAvatarType): ReactElement => (
  <Tooltip title={label} placement={"bottom"}>
    <Button
      size={size}
      type={type}
      disabled={disabled}
      className={`flex flex-row items-center justify-center text-gray-400 ${
        outlined && "border rounded-full border-gray-400 hover:shadow-xl"
      } ${className} p-1.5 ${disabled && "p-1"}`}
      onClick={onClick}
    >
      {children}
    </Button>
  </Tooltip>
);

type CustomAvatarType = {
  size?: SizeType;
  label?: ReactNode;
  outlined?: boolean;
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  type?: "default" | "primary" | "dashed" | "ghost" | "link" | "text";
};
