import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { updateItemInArray, valOrDefault } from "../../../utils/utils";
import {
  DefaultColumnType,
  HeightWidthMapType,
} from "../../../utils/newTermsheet";
import { KeyValueType, PortfolioBidMetricType } from "../../../utils/types";
import { ColumnHeaderCell } from "../common/ColumnHeaderCell";
import {
  ItemType,
  RightClickActionType,
  SelectedItemType,
} from "../../../utils/termsheet";
import RowIndexCell from "../common/RowIndexCell";
import Cell from "../common/Cell";
import { useIntersectionView } from "../../../customHooks/useIntersectionView";
import Row from "../common/Row";

export const PortfolioTermsheetRows: FC<PortfolioTermsheetRowsType> =
  function ({
    elementId,
    configs = [],
    selectedItem = null,
    editable,
    onSelectItem,
    onWebFormConfigUpdate,
    onAddOrDeleteRow,
  }) {
    const ref = useRef();
    const [columnWidth, setColumnWidth] = useState<HeightWidthMapType>({});
    const [webFormConfigs, setWebFormConfigs] = useState<
      Array<PortfolioBidMetricType>
    >([]);

    const onChangeA = useCallback(
      (i: number, text: string) => {
        const config = webFormConfigs[i];
        onWebFormConfigUpdate(
          updateItemInArray(i, webFormConfigs, {
            ...config,
            bidMetricData: text,
          })
        );
      },
      [webFormConfigs]
    );

    const onSelectA = useCallback(
      (i: number, style: KeyValueType) => {
        const config = webFormConfigs[i];
        onSelectItem({
          type: ItemType.CELL,
          rowId: config.id,
          rowIndex: i,
          rowPosition: config.position,
          column: DefaultColumnType.BID_METRICS,
          style: style,
        });
      },
      [webFormConfigs]
    );

    const onChangeB = useCallback(
      (i: number, text: string) => {
        const config = webFormConfigs[i];
        onWebFormConfigUpdate(
          updateItemInArray(i, webFormConfigs, {
            ...config,
            portfolioDealTeamAskDTO: {
              ...config.portfolioDealTeamAskDTO,
              dealTeamAskData: text,
            },
          })
        );
      },
      [webFormConfigs, elementId]
    );

    const onSelectB = useCallback(
      (i: number, style: KeyValueType) => {
        const config = webFormConfigs[i];
        onSelectItem({
          type: ItemType.CELL,
          rowId: config.id,
          rowIndex: i,
          rowPosition: config.position,
          column: elementId,
          style: style,
        });
      },
      [webFormConfigs, elementId]
    );

    const [minIndex, maxIndex] = useIntersectionView(
      ".termsheet-row",
      ref.current,
      [webFormConfigs]
    );

    useEffect(() => {
      setWebFormConfigs(configs);
    }, [configs]);

    return (
      <>
        <div className={"sticky top-0 z-[1000] bg-gray-100 table-row"}>
          <div className={`flex border-b border-gray-200 z-[10]`}>
            <div className={"sticky left-0 z-[100]"}>
              <div
                className={
                  "h-full w-10 min-w-[2.5rem] text-xxs border-gray-200 bg-gray-50 text-gray-400"
                }
              />
            </div>
            <div className={"border-l border-r"}>
              <ColumnHeaderCell
                selected={
                  selectedItem?.column === DefaultColumnType.BID_METRICS
                }
                width={valOrDefault(
                  300,
                  columnWidth[DefaultColumnType.BID_METRICS]
                )}
                content={"Bid Metrics"}
                onSelect={(): void => {
                  return;
                }}
                onWidthChange={(o): void => {
                  setColumnWidth((columnWidth) => ({
                    ...columnWidth,
                    [DefaultColumnType.BID_METRICS]: o,
                  }));
                }}
                editColumn={false}
                onClear={(): void => {
                  return;
                }}
              />
            </div>
            <div className={"border-r"}>
              <ColumnHeaderCell
                selected={selectedItem?.column === elementId}
                width={valOrDefault(300, columnWidth[elementId])}
                content={"Deal Team Ask"}
                onSelect={(): void => {
                  return;
                }}
                onWidthChange={(o): void => {
                  setColumnWidth({
                    ...columnWidth,
                    [elementId]: o,
                  });
                }}
                editColumn={false}
                onClear={(): void => {
                  return;
                }}
              />
            </div>
          </div>
        </div>
        <div
          ref={ref.current}
          className={"border-l border-gray-200 table-row-group scroll-smooth"}
        >
          {webFormConfigs.map(
            (
              {
                id,
                position,
                portfolioDealTeamAskDTO,
                bidMetricData,
                styleData,
              },
              i
            ) => {
              return (
                <Row
                  key={i}
                  id={i.toString()}
                  index={i}
                  allowRightClick={editable}
                  onAddOrDeleteRow={onAddOrDeleteRow}
                >
                  <div
                    data-rowindex={i}
                    className={`termsheet-row flex bg-white border-b border-gray-200 z-[10] min-h-[35px]`}
                  >
                    <RowIndexCell
                      position={i + 1}
                      selected={
                        selectedItem?.rowPosition === position &&
                        selectedItem.rowId === id &&
                        selectedItem?.rowIndex === i
                      }
                    />
                    {i >= minIndex && i <= maxIndex + 35 && (
                      <>
                        <div className={"bg-white border-r"}>
                          <Cell
                            index={i}
                            selectable={true}
                            className={
                              selectedItem?.rowIndex === i &&
                              (selectedItem?.type === ItemType.ROW ||
                                selectedItem?.type === ItemType.COLUMN) &&
                              selectedItem?.rowIndex === i
                                ? "border-l border-primary"
                                : ""
                            }
                            selectedCell={
                              selectedItem?.type === ItemType.CELL &&
                              selectedItem?.rowIndex === i &&
                              selectedItem?.column ===
                                DefaultColumnType.BID_METRICS
                            }
                            selectedSeries={
                              (selectedItem?.type === ItemType.ROW &&
                                selectedItem?.rowIndex === i) ||
                              (selectedItem?.type === ItemType.COLUMN &&
                                selectedItem.column ===
                                  DefaultColumnType.BID_METRICS)
                            }
                            elementId={DefaultColumnType.BID_METRICS}
                            editable={editable}
                            style={JSON.stringify(styleData.style)}
                            text={bidMetricData}
                            width={valOrDefault(
                              300,
                              columnWidth[DefaultColumnType.BID_METRICS]
                            )}
                            onChange={onChangeA}
                            onSelect={onSelectA}
                          />
                        </div>
                        <div className={"bg-white border-r"}>
                          <Cell
                            index={i}
                            elementId={elementId}
                            selectable={true}
                            className={
                              selectedItem?.type === ItemType.ROW &&
                              selectedItem?.rowIndex === i
                                ? "border-r border-primary"
                                : ""
                            }
                            selectedCell={
                              selectedItem?.type === ItemType.CELL &&
                              selectedItem?.rowIndex === i &&
                              selectedItem?.column === elementId
                            }
                            selectedSeries={
                              selectedItem?.type === ItemType.ROW &&
                              selectedItem?.rowIndex === i
                            }
                            editable={editable}
                            style={JSON.stringify(
                              valOrDefault(
                                {},
                                portfolioDealTeamAskDTO.styleData.style
                              )
                            )}
                            text={valOrDefault(
                              "",
                              portfolioDealTeamAskDTO.dealTeamAskData
                            )}
                            width={valOrDefault(300, columnWidth[elementId])}
                            onChange={onChangeB}
                            onSelect={onSelectB}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </Row>
              );
            }
          )}
        </div>
      </>
    );
  };

type PortfolioTermsheetRowsType = {
  elementId: string;
  editable: boolean;
  configs: Array<PortfolioBidMetricType>;
  selectedItem: SelectedItemType | null;
  onSelectItem: (o: SelectedItemType) => void;
  onWebFormConfigUpdate: (o: Array<PortfolioBidMetricType>) => void;
  onAddOrDeleteRow: addOrDeleteRowType;
};
type addOrDeleteRowType = (
  key: RightClickActionType,
  id: string,
  index: number
) => void;
