import React, { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { TemplateTermsheet } from "../termsheet/template/TemplateTermsheet";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { useHistory } from "react-router";
import { TermsheetTemplates } from "./TermsheetTemplates";

export const TermsheetTemplateRoutes: FC<TermSheetTemplatesManageType> = ({
  initialSetup,
  onSubmit,
}: TermSheetTemplatesManageType) => {
  usePageTitle("Templates");

  const { path } = useRouteMatch();
  const history = useHistory();

  return (
    <Switch>
      <Route exact={true} path={path}>
        <TermsheetTemplates initialSetup={initialSetup} onSubmit={onSubmit} />
      </Route>
      <Route path={`${path}/:id/create`}>
        <TemplateTermsheet
          create={true}
          onCreate={(id): void => {
            history.push(`${path}/${id}`);
          }}
        />
      </Route>
      <Route path={`${path}/:templateId`}>
        <TemplateTermsheet />
      </Route>
    </Switch>
  );
};

type TermSheetTemplatesManageType = {
  initialSetup: boolean;
  onSubmit: (val: number) => void;
};
