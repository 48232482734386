import React, { FC, useEffect, useState } from "react";
import { CompanyModuleType } from "../../utils/types";
import { Button, Drawer } from "antd";
import { Permissions } from "../company/Permissions";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { DEFAULT_COMPANY_MODULE_CONFIG } from "../../utils/module-util";

export const PermissionsModal: FC<PermissionsModal> = ({
  modules,
  id,
  onClose,
  searchValue = "",
  companyName,
}) => {
  const [parsedModules, setParsedModules] = useState<CompanyModuleType | null>(
    null
  );

  useEffect(() => {
    setParsedModules(modules);
  }, [modules]);

  return (
    <Drawer
      onClose={onClose}
      bodyStyle={{ padding: 0 }}
      width={"90%"}
      footer={[
        <Button
          className={PRIMARY_BUTTON_STYLE}
          key={"close"}
          onClick={onClose}
        >
          Close
        </Button>,
      ]}
      visible={true}
      title={`${companyName ?? "Company"} Team Permissions`}
    >
      {parsedModules && (
        <Permissions
          modules={parsedModules}
          companyId={id}
          isAdminPage={true}
          searchValue={searchValue}
        />
      )}
    </Drawer>
  );
};

type PermissionsModal = {
  id: string;
  modules: CompanyModuleType;
  onClose: () => void;
  searchValue: string;
  companyName?: string;
};
