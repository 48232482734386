import React, { FC } from "react";
import { Button, Tooltip } from "antd";

export const TableColumnHiddenItem: FC<TableColumnHiddenItemProperties> =
  function ({ label = "", onShow }: TableColumnHiddenItemProperties) {
    return (
      <div className={`px-2 py-0.5`}>
        <div className={"flex flex-row gap-4 justify-between items-center"}>
          <div className={"text-sm truncate"}>{label}</div>
          <div>
            <Tooltip title={"Show"} placement={"bottom"}>
              <Button
                shape={"circle"}
                className={"p-0"}
                type={"text"}
                size={"small"}
                onClick={onShow}
              >
                <i className={"text-xs fa fa-eye"} />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

type TableColumnHiddenItemProperties = {
  label?: string;
  onShow?: () => void;
};
