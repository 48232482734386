export const roundAndFormat: roundAndFormatType = (input = "") => {
  if (input === undefined || input === "") return "";
  const number = typeof input === "string" ? parseFloat(input) : input;
  // Round to one decimal place
  const rounded = Math.round(number * 10) / 10;
  // Convert to a formatted string with commas
  return rounded.toLocaleString("en-US", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
};

type roundAndFormatType = (input?: string | number) => string | number;
