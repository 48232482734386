import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { getUserToken, resetUserToken } from "../../../services/services";
import { ResponseType } from "../../../utils/uiTypes";
import { UserTokenType } from "../../../utils/types";
import { equals, isNil } from "ramda";
import { LockOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import {
  DANGER_BUTTON_STYLE,
  PRIMARY_BUTTON_STYLE,
} from "../../../utils/cssConfigs";
import { CustomSpin } from "../../general/CustomSpin";
import { TextOverFlowHandle } from "../../general/TextOverFlowHandle";
import { CopyToClipboard } from "../../general/CopyToClipboard";
import { convertDateToFormat } from "../../../utils/moment";
import moment, { Duration } from "moment";
import { useInterval } from "../../../customHooks/useInterval";

export const TokenKeysInfo = () => {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<UserTokenType | null>(null);

  const tokenAction: tokenActionType = (type) => {
    setLoading(true);
    (equals(type, "fetch") ? getUserToken({}) : resetUserToken({}))
      .then(({ data }: ResponseType<UserTokenType>) => {
        setToken(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setToken(null);
      });
  };

  const getTimeRemaining = (seconds: string): string => {
    const duration = moment.duration(Number(seconds) * 1000);
    return `${duration
      .asHours()
      .toFixed(
        0
      )} hours ${duration.minutes()} minutes ${duration.seconds()} seconds`;
  };

  useEffect(() => {
    tokenAction("fetch");
  }, []);

  return (
    <div className={"bg-white mx-6 mb-6"}>
      <p className={"max-w-xs text-center mx-auto py-2 text-2xl font-medium"}>
        <LockOutlined className="mr-3" />
        Access Token
      </p>
      <div className={"max-w-screen-md mx-auto w-3/6 py-7"}>
        <div className={"flex flex-row h-full gap-1 items-center flex-wrap"}>
          <div className={"flex flex-col gap-1 h-full flex-wrap"}>
            <div
              className={"flex flex-row gap-x-3 h-full items-center flex-wrap"}
            >
              <h1 className="text-base font-bold">Token: </h1>
              {!loading ? (
                <div className={`${isNil(token) ? "text-danger" : ""}`}>
                  <p>
                    {isNil(token) ? (
                      "Unable to fetch Token"
                    ) : (
                      <CopyToClipboard value={token?.token}>
                        <div className={"max-w-xs truncate w-fit"}>
                          <TextOverFlowHandle text={token?.token} />
                        </div>
                      </CopyToClipboard>
                    )}
                  </p>
                </div>
              ) : (
                <div className={"w-min relative"}>
                  <CustomSpin
                    loadingText={"Resetting Access Token"}
                    transparent={true}
                    size={"default"}
                  />
                </div>
              )}
            </div>
            <div
              className={"flex flex-row gap-2 h-full items-center flex-wrap"}
            >
              <h1 className="text-base font-bold">Expires in</h1>
              {!loading ? (
                <div className={`${isNil(token) ? "text-danger" : ""}`}>
                  <p>
                    {isNil(token) ? (
                      "-"
                    ) : (
                      <div className={"max-w-xs truncate w-fit"}>
                        {getTimeRemaining(token?.expiryInSeconds)}
                      </div>
                    )}
                  </p>
                </div>
              ) : (
                <div className={"w-min relative"}>
                  <CustomSpin
                    loadingText={"Resetting Access Token"}
                    transparent={true}
                    size={"default"}
                  />
                </div>
              )}
            </div>
          </div>
          <Button
            loading={loading}
            disabled={isNil(token?.token)}
            onClick={() => tokenAction("fetch")}
            className={`${PRIMARY_BUTTON_STYLE} float-right ml-auto`}
          >
            Refresh
          </Button>
          <Button
            loading={loading}
            disabled={isNil(token?.token)}
            onClick={() => tokenAction("reset")}
            className={`${DANGER_BUTTON_STYLE} float-right`}
          >
            Reset Token
          </Button>
        </div>
      </div>
    </div>
  );
};

type tokenActionType = (type: "fetch" | "reset") => void;
type DurationType = {
  duration: Duration;
  id: string;
};
