import React, { FC, SetStateAction, useEffect, useState } from "react";
import { CommentType, LenderType, UserType } from "../../../../utils/types";
import {
  addComment,
  getComments,
  readComment,
  setPriorities,
} from "../../../../services/services";
import { CommentsList } from "./CommentsList";
import { ResponseType } from "../../../../utils/uiTypes";
import { updateItemInArray, valOrDefault } from "../../../../utils/utils";
import { Button, message as msg } from "antd";
import { ConversationInputForm } from "../../../forms/ConversationInputForm";
import { CustomAvatar } from "../../../general/CustomAvatar";
import { ElementType, PermissionType } from "../../../../utils/enums";
import { CustomSpin } from "../../../general/CustomSpin";
import { pick as Pick, keys as Keys, assoc as Assoc } from "ramda";

export const CommunicationsThreads: FC<CommunicationsThreadsType> = function ({
  peTransactionId,
  activeThreadId,
  refreshUnread,
  dealTeam,
  permissions,
  belongToDealTeam,
  threadType,
  lender,
  conversations = [],
  setConversations = (): void => {
    return;
  },
}: CommunicationsThreadsType) {
  const [team, setTeam] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState<{ loading: boolean; label?: string }>({
    loading: false,
    label: "",
  });
  const [filters, setFilters] = useState<FiltersType>({
    priority: false,
    unread: false,
  });

  const getTeam: getTeamType = (lender, dealTeam) =>
    dealTeam.reduce(
      (prev, curr) => ({ ...prev, [curr.userId]: "default" }),
      valOrDefault(
        {},
        lender?.userDTOs?.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.userId]: lender?.name[0]?.toUpperCase(),
          }),
          {}
        )
      )
    );

  const uploadConversation: uploadConversationType = (
    comment,
    rootCommentId,
    elementId,
    elementType,
    commentType = "TEXT"
  ) => {
    setLoading({ loading: true, label: "Uploading Conversation..." });
    const data = {
      comment,
      commentType,
      elementId,
      elementType,
      peTransactionId,
      petransactionId: peTransactionId,
      rootCommentId,
    };

    addComment({
      body: JSON.stringify(data),
    })
      .then(({ data }: ResponseType<CommentType>) => {
        if (!data.rootCommentId && conversations) {
          setConversations([data, ...conversations]);
        } else {
          const commentIndex = conversations.findIndex(
            (val) => val.commentId === data.rootCommentId
          );
          const comment = conversations[commentIndex];

          setConversations(
            updateItemInArray(commentIndex, conversations, {
              ...comment,
              commentDTOs: comment.commentDTOs
                ? [...comment.commentDTOs, data]
                : [data],
            })
          );
        }
        refreshUnread();
      })
      .then(() => setLoading({ loading: false, label: "" }))
      .catch((error: string) => {
        msg.error(error);
        setLoading({ loading: false });
      });
  };

  const getCommentById: getCommentByIdType = (id: string) => {
    const commentIndex = conversations.findIndex((val) => val.commentId === id);
    const comment = conversations[commentIndex];
    return comment;
  };

  const togglePriority: togglePriorityType = (id) => {
    const comment = getCommentById(id);
    let payload = Pick(["elementId", "elementType", "commentId"], comment);
    payload = Assoc("petransactionId", peTransactionId, payload);
    setPriorities({
      body: JSON.stringify(payload),
      segments: { id, type: "comment" },
    }).then(({ data }: ResponseType<CommentType>) => {
      const conversationIndex = conversations.findIndex(
        (val) => val.commentId === id
      );
      setConversations([
        ...updateItemInArray(conversationIndex, conversations, data),
      ]);
    });
  };

  const setRead: setReadType = (transactionId: string, elementId, type) => {
    readComment({
      segments: {
        elementId,
        type,
        transactionId,
      },
    }).then(() => {
      const conversationIndex = conversations.findIndex(
        (val) => val.commentId === elementId
      );
      setConversations([
        ...updateItemInArray(conversationIndex, conversations, {
          ...conversations[conversationIndex],
          read: true,
        }),
      ]);
      refreshUnread();
    });
  };

  useEffect(() => {
    setTeam(getTeam(lender, dealTeam));
  }, [lender, dealTeam]);

  return (
    <div className={"relative flex-initial flex flex-col w-full h-full"}>
      <CustomSpin loading={loading.loading} loadingText={loading.label} />
      <div className={"flex flex-row items-center p-3 border-b"}>
        <div className={"flex flex-row items-center p-3"}>
          <CustomAvatar
            data={lender ? lender.name[0] : "G"}
            color={lender ? lender.name[0] : "default"}
            size={"large"}
            className={"text-xl shadow-xxs"}
          />
          <span className={"font-bold text-lg ml-1"}>
            {lender ? lender.name : "General Thread"}
          </span>
        </div>
        <div className={"ml-auto flex flex-col p-3 gap-y-2"}>
          <span
            className={
              "select-none ml-auto uppercase text-xs text-gray-400 font-medium"
            }
          >
            In this conversation
          </span>
          <div className={"flex flex-row flex-row-reverse flex-wrap"}>
            {lender &&
              lender.userDTOs?.map(
                ({ firstName, lastName, userId }: UserType) => (
                  <CustomAvatar
                    key={userId}
                    data={
                      firstName?.charAt(0)?.toUpperCase() +
                      lastName?.charAt(0)?.toUpperCase()
                    }
                    color={lender ? lender.name[0] : "default"}
                    label={firstName + " " + lastName}
                    size={"small"}
                  />
                )
              )}
            <span className={"border-gray-300 border-l"}>{""}</span>
            {dealTeam?.length > 4 && (
              <CustomAvatar
                key={"extra-count"}
                data={"+".concat((dealTeam?.length - 4).toString())}
                color={"default"}
                size={"small"}
                className={"cursor-pointer"}
                label={
                  <span className={"flex flex-row"}>
                    Team:
                    {dealTeam
                      ?.slice(4)
                      .map(({ firstName, lastName, userId }: UserType) => (
                        <CustomAvatar
                          key={"Tooltip-Team-" + userId}
                          data={
                            firstName?.charAt(0)?.toUpperCase() +
                            lastName?.charAt(0)?.toUpperCase()
                          }
                          color={"default"}
                          label={firstName + " " + lastName}
                          outlined={true}
                          size={"small"}
                        />
                      ))}
                  </span>
                }
              />
            )}
            {dealTeam
              ?.slice(0, 4)
              .map(({ firstName, lastName, userId }: UserType) => (
                <CustomAvatar
                  key={"Tooltip-Team-" + userId}
                  data={
                    firstName?.charAt(0)?.toUpperCase() +
                    lastName?.charAt(0)?.toUpperCase()
                  }
                  color={"default"}
                  label={firstName + " " + lastName}
                  outlined={true}
                  size={"small"}
                />
              ))}
          </div>
          <div
            className={
              "ml-auto flex flex-row uppercase text-xs text-gray-400 font-medium items-center gap-x-2 divide-x divide-gray-400 pt-2"
            }
          >
            Filter By:&nbsp;
            <Button
              className={`border-0 p-0 shadow flex items-center justify-center bg-gray-50 motion-safe:hover:font-medium text-gray-500 shadow-[0_2px_4px_rgba(0,0,0,0.18)]`}
              shape={"circle"}
              size={"small"}
              onClick={(): void => {
                setFilters({ ...filters, priority: !filters.priority });
              }}
            >
              {!filters.priority ? (
                <i className="far fa-star text-gray-400" />
              ) : (
                <i className="fas fa-star text-blue-500" />
              )}
            </Button>
            <div
              className={`pl-2 select-none cursor-pointer ${
                !filters.unread ? "text-gray-400" : "text-blue-500"
              }`}
              onClick={(): void => {
                setFilters({ ...filters, unread: !filters.unread });
              }}
            >
              Unread
            </div>
          </div>
        </div>
      </div>
      {(permissions.includes(PermissionType.CREATE_COMMUNICATION) ||
        !belongToDealTeam) && (
        <div className={"p-2"}>
          <ConversationInputForm
            onSubmit={({ message }): void =>
              uploadConversation(
                message,
                null,
                lender ? lender.id : peTransactionId,
                threadType
              )
            }
          />
        </div>
      )}
      <CommentsList
        loading={loading}
        permissions={permissions}
        conversations={conversations
          .filter(({ priorities }) => (filters.priority ? priorities : true))
          .filter(({ read = true }) => (filters.unread ? !read : true))}
        team={team}
        uploadConversation={uploadConversation}
        togglePriority={togglePriority}
        setRead={(elementId, type): void =>
          setRead(peTransactionId, elementId, type)
        }
      />
    </div>
  );
};

type CommunicationsThreadsType = {
  activeThreadId: string;
  refreshUnread: () => void;
  dealTeam: UserType[];
  permissions: PermissionType[];
  belongToDealTeam: boolean;
  threadType: ElementType.PETRANSACTION | ElementType.LENDER;
  lender: LenderType | null;
  peTransactionId: string;
  conversations: CommentType[];
  setConversations: (o: SetStateAction<CommentType[]>) => void;
};

type uploadConversationType = (
  message: string,
  root: null | string,
  elementId: string,
  elementType: ElementType.PETRANSACTION | ElementType.LENDER,
  commentType?: string
) => void;

type setReadType = (
  transactionId: string,
  elementId: string,
  type: ElementType.PETRANSACTION | ElementType.LENDER
) => void;

type togglePriorityType = (id: string) => void;
type FiltersType = { priority: boolean; unread: boolean };

type getTeamType = (
  lender: LenderType | null,
  dealTeam: UserType[]
) => Record<string, string>;

type fetchCommentsType = (
  threadType: ElementType.PETRANSACTION | ElementType.LENDER,
  activeThreadId: string,
  peTransactionId: string
) => void;

type getCommentByIdType = (id: string) => CommentType;
type PartialCommentType = {
  elementId: string;
  elementType: ElementType.PETRANSACTION | ElementType.LENDER;
  commentId: string;
  petransactionId: string;
};
