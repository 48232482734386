import React, { FC, useEffect, useState } from "react";
import { valOrDefault } from "../../utils/utils";
import { Button, message, Modal } from "antd";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import { AnalyticsTable } from "./AnalyticsTable";
import {
  downloadAnalyticsData,
  generateAnalyticsData,
} from "../../services/services";
import { FileResponseType, ResponseType } from "../../utils/uiTypes";
import { ConfirmDownload } from "../../utils/confirmationModals";

export const AnalyticsChartModal: FC<AnalyticsChartModalType> = ({
  visible,
  params = "",
  name = "",
  title,
  onClose,
}) => {
  const [data, setData] = useState<DataType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [downloading, setDownloading] = useState<boolean>(false);

  const fetchAnalyticsData = () => {
    setLoading(true);
    generateAnalyticsData({
      segments: {
        type: "generate",
      },
      params: params + `&name=${name}`,
    })
      .then(({ data }: ResponseType) => {
        setData(data);
        setLoading(false);
      })
      .catch((error: string) => {
        setLoading(false);
        setData(null);
        console.error(error ?? `Error fetching ${name}`);
      });
  };

  const onDownload = (): void => {
    setDownloading(true);
    message.loading({
      content: "Processing File",
      duration: 0,
      key: "download-" + name,
    });
    downloadAnalyticsData({
      segments: {
        type: "csv",
      },
      params: params + `&name=${name}`,
    })
      .then(({ url, filename }: FileResponseType) => {
        setDownloading(false);
        message.info({
          content: "File Ready to Download",
          key: "download-" + name,
        });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        setDownloading(false);
        message.error({
          content: valOrDefault("Error Downloading File!", error),
          key: "download-" + name,
        });
      });
  };

  useEffect(fetchAnalyticsData, [params, name]);

  return (
    <Modal
      open={visible}
      width={"80%"}
      cancelText={"Close"}
      onCancel={onClose}
      closable={true}
      okButtonProps={{ className: "bg-primary border-0 hover:bg-hover" }}
      cancelButtonProps={{
        className: "text-primary border border-primary",
      }}
      title={title}
      bodyStyle={{ padding: 0, overflow: "hidden" }}
      footer={
        <div className={"flex flex-row justify-end items-center"}>
          <Button
            loading={loading}
            disabled={downloading || loading}
            className={"ml-auto"}
            type={"text"}
            icon={<ReloadOutlined className={"text-primary"} />}
            onClick={fetchAnalyticsData}
          />
          <Button
            loading={downloading}
            disabled={downloading || loading}
            type={"text"}
            icon={<DownloadOutlined className={"text-primary"} />}
            onClick={onDownload}
          />
        </div>
      }
      destroyOnClose={true}
    >
      <AnalyticsTable
        data={data}
        loading={loading}
        footer={false}
        showActionItems={false}
      />
    </Modal>
  );
};

type DataType = {
  data: Record<string, string>[];
  configuration?: string;
  from: string;
  to: string;
  report: { name: string };
};

type AnalyticsChartModalType = {
  visible: boolean;
  title: string;
  name?: string;
  params?: string;
  onClose: () => void;
};
