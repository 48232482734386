import React, { FC, ReactNode, useState } from "react";
import { TagType } from "../../../utils/types";
import { Badge, Dropdown, Tag } from "antd";
import { equals } from "ramda";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import { TagModal } from "./TagModal";

export const AddTagButton = () => {
  return (
    <Tag className="site-tag-plus">
      <PlusOutlined /> Add Tag
    </Tag>
  );
};

export const TagsDropdown: FC<AddTagType> = ({
  onSelect,
  list,
  onAdd,
  children,
  disableCreate,
  currentSelected = [],
  disabled,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Dropdown
        disabled={disabled}
        forceRender={true}
        trigger={["click"]}
        menu={{
          items: [
            ...list.map(({ id, label, color }) => ({
              label,
              key: id,
              disabled: currentSelected.includes(id),
              icon: <Badge color={color} />,
            })),
            ...(!disableCreate
              ? [
                  {
                    key: "ADD",
                    label: "Add Tag",
                    className: "text-primary ",
                    icon: <SettingOutlined />,
                  },
                ]
              : []),
          ],
          onClick: ({ key }) =>
            equals(key, "ADD") ? setModalOpen(true) : onSelect(key),
        }}
      >
        <div
          className={""}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {children}
        </div>
      </Dropdown>
      {modalOpen && onAdd && (
        <TagModal
          onClose={() => setModalOpen(false)}
          onSubmit={(tag) => {
            onAdd(tag);
            setModalOpen(false);
          }}
        />
      )}
    </>
  );
};

type AddTagType = {
  list: TagType[];
  onSelect: (tagId: string) => Promise<void>;
  children: ReactNode | ReactNode[];
  onAdd?: (tag: TagType) => void;
  currentSelected?: string[];
  disableCreate: boolean;
  disabled: boolean;
};
