const colorPallet: Record<string, Record<string, string>> = {
  A: {
    bg: "bg-[#0000b4]",
    text: "text-[#0000b4]",
    border: "border border-[#0000b4]",
  },
  B: {
    bg: "bg-[#af0d66]",
    text: "text-[#af0d66]",
    border: "border border-[#af0d66]",
  },
  C: {
    bg: "bg-[#92f846]",
    text: "text-[#92f846]",
    border: "border border-[#92f846]",
  },
  D: {
    bg: "bg-[#ffc82f]",
    text: "text-[#ffc82f]",
    border: "border border-[#ffc82f]",
  },
  E: {
    bg: "bg-[#ff7600]",
    text: "text-[#ff7600]",
    border: "border border-[#ff7600]",
  },
  F: {
    bg: "bg-[#009a25]",
    text: "text-[#009a25]",
    border: "border border-[#009a25]",
  },
  G: {
    bg: "bg-[#792187]",
    text: "text-[#792187]",
    border: "border border-[#792187]",
  },
  H: {
    bg: "bg-[#646464]",
    text: "text-[#646464]",
    border: "border border-[#646464]",
  },
  I: {
    bg: "bg-[#00bfff]",
    text: "text-[#00bfff]",
    border: "border border-[#00bfff]",
  },
  J: {
    bg: "bg-[#371370]",
    text: "text-[#371370]",
    border: "border border-[#371370]",
  },
  K: {
    bg: "bg-[#db7093]",
    text: "text-[#db7093]",
    border: "border border-[#db7093]",
  },
  L: {
    bg: "bg-[#CA3E5EFF]",
    text: "text-[#CA3E5EFF]",
    border: "border border-[#CA3E5EFF]",
  },
  M: {
    bg: "bg-[#CD913FFF]",
    text: "text-[#CD913FFF]",
    border: "border border-[#CD913FFF]",
  },
  N: {
    bg: "bg-[#0C4B64FF]",
    text: "text-[#0C4B64FF]",
    border: "border border-[#0C4B64FF]",
  },
  O: {
    bg: "bg-[#FF0000FF]",
    text: "text-[#FF0000FF]",
    border: "border border-[#FF0000FF]",
  },
  P: {
    bg: "bg-[#AF9B32FF]",
    text: "text-[#AF9B32FF]",
    border: "border border-[#AF9B32FF]",
  },
  Q: {
    bg: "bg-[#000000FF]",
    text: "text-[#000000FF]",
    border: "border border-[#000000FF]",
  },
  R: {
    bg: "bg-[#254619FF]",
    text: "text-[#254619FF]",
    border: "border border-[#254619FF]",
  },
  S: {
    bg: "bg-[#792187FF]",
    text: "text-[#792187FF]",
    border: "border border-[#792187FF]",
  },
  T: {
    bg: "bg-[#538CD0FF]",
    text: "text-[#538CD0FF]",
    border: "border border-[#538CD0FF]",
  },
  U: {
    bg: "bg-[#009A25FF]",
    text: "text-[#009A25FF]",
    border: "border border-[#009A25FF]",
  },
  V: {
    bg: "bg-[#B2DCCDFF]",
    text: "text-[#B2DCCDFF]",
    border: "border border-[#B2DCCDFF]",
  },
  W: {
    bg: "bg-[#FF98D5FF]",
    text: "text-[#FF98D5FF]",
    border: "border border-[#FF98D5FF]",
  },
  X: {
    bg: "bg-[#00004AFF]",
    text: "text-[#00004AFF]",
    border: "border border-[#00004AFF]",
  },
  Y: {
    bg: "bg-[#AFC84AFF]",
    text: "text-[#AFC84AFF]",
    border: "border border-[#AFC84AFF]",
  },
  Z: {
    bg: "bg-[#3F190CFF]",
    text: "text-[#3F190CFF]",
    border: "border border-[#3F190CFF]",
  },
  default: {
    bg: "bg-[#00bec1]",
    text: "text-[#00bec1]",
    border: "border border-[#00bec1]",
  },
  favourite: {
    bg: "bg-[#1890ff]",
    text: "text-[#1890ff]",
    border: "border border-[#1890ff]",
  },
};

export const getColorPallet = (
  literal: string,
  type: "border" | "text" | "bg"
): string => {
  if (!literal) return "";
  const data =
    colorPallet[literal.length === 1 ? literal.toUpperCase() : literal];
  return data ? data[type] : `${type}-${literal}`;
};
