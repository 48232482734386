import React, { FC } from "react";
import { AnalyticsDataRenderType } from "./AnalyticsData";
import { path } from "ramda";
import { CustomSpin } from "../general/CustomSpin";

export const AnalyticsSingleValue: FC<AnalyticsDataRenderType> = ({
  data,
  loading,
  label,
}) => {
  return (
    <div
      className={
        "m-1.5 w-full md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6 bg-white grow flex items-stretch flex-col border border-gray-200 drop-shadow-sm"
      }
    >
      <div
        className={
          "flex flex-row items-center p-2 border-b border-gray-200 bg-gray-50 select-all"
        }
      >
        {label}
      </div>
      <div
        className={
          "relative flex items-center justify-center h-36 text-3xl font-extralight text-gray-400"
        }
      >
        <CustomSpin
          loading={loading}
          size={"small"}
          loadingText={""}
          transparent={false}
        />
        {path(["data", "0", "value"], data ?? {}) ?? "-"}
      </div>{" "}
    </div>
  );
};
