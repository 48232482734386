import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import building from "../../../images/icons/Building.svg";
import { useHistory, useParams } from "react-router";
import { PortfolioSectionType } from "../../../utils/types";
import {
  createNewPortfolioSection,
  updatePortfolioName,
} from "../../../services/services";
import { ResponseType } from "../../../utils/uiTypes";
import {
  ElementType,
  PermissionType,
  PortfolioSectionEnumType,
} from "../../../utils/enums";
import FormItem from "antd/es/form/FormItem";
import { isEmpty, isNil, path } from "ramda";
import { getObjectFromPropertyValue } from "../../../utils/utils";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import { PortfolioAdminPermissionBlock } from "../PortfolioAdminPermissionBlock";

const SELECTED_STYLE = "text-primary bg-blue-50 border-r-4 border-r-blue-400";
const NOT_SELECTED_STYLE =
  "border-r-4 border-r-transparent hover:text-primary hover:bg-blue-50";

export const PortfolioViewNav: FC<PortfolioViewNavType> = ({
  companyTitle,
  companyId,
  sectionList,
  onCreateNewSection,
  permissions,
  portfolioId,
  onSelectSection,
  allowCreateNewSection = false,
}) => {
  const { sectionId, ...other } = useParams<ParamsType>();
  const history = useHistory();
  const [editingName, setEditingName] = useState(false);
  const [portfolioName, setPortfolioName] = useState(companyTitle);
  const [updatingName, setUpdatingName] = useState(false);

  const [newSection, setNewSection] = useState<boolean>(false);

  useEffect(() => {
    console.log(sectionId, other);
  }, [sectionId, other]);

  const createNewSection = (sectionName: string, portfolioId: string): void => {
    if (!isEmpty(sectionName?.trim())) {
      createNewPortfolioSection({
        body: JSON.stringify({
          elementId: portfolioId,
          rank: sectionList.length + 2,
          sectionElementType: ElementType.PORTFOLIO,
          sectionName,
        }),
      })
        .then(({ data }: ResponseType<PortfolioSectionType>) => {
          onCreateNewSection([
            ...sectionList,
            { ...data, sectionType: PortfolioSectionEnumType.CUSTOM },
          ]);
          setNewSection(false);
        })
        .catch((err: string): void => {
          console.error(err);
          message.error("Error creating section.", 5);
        });
    }
  };

  const initiatePortfolioNameUpdate = (portfolioCompanyName: string) => {
    setUpdatingName(true);
    updatePortfolioName({
      body: JSON.stringify({
        portfolioCompanyName,
        id: portfolioId,
        companyId,
      }),
    })
      .then(() => {
        setPortfolioName(portfolioCompanyName);
        setEditingName(false);
        setUpdatingName(false);
      })
      .catch(() => {
        message.error("Error Updating Portfolio Name");
        setEditingName(false);
        setUpdatingName(false);
      });
  };

  useEffect(() => {
    setPortfolioName(companyTitle);
  }, [companyTitle]);

  useEffect(() => {
    if (!isEmpty(sectionList)) {
      if (!isEmpty(sectionId)) {
        const section = getObjectFromPropertyValue(
          "id",
          sectionId,
          sectionList ?? []
        );

        if (isEmpty(section) || isNil(section)) {
          onSelectSection(path([0, "id"], sectionList) as string);
        } else onSelectSection(section?.id);
      } else {
        onSelectSection(path([0, "id"], sectionList) as string);
      }
    }
  }, [sectionList]);

  return (
    <div
      className={
        "p-4 pb-0 md:pb-4 md:pr-0 w-full md:flex-none md:h-full max-h-full md:w-[20%]"
      }
    >
      <div className={"flex flex-col h-full w-full bg-white shadow"}>
        {companyTitle && (
          <div className={`w-full px-5 py-3 border-b font-medium`}>
            <img className={"mb-4"} alt={"building"} src={building} />
            <div className={"flex items-center justify-between group"}>
              {!editingName ? (
                <span className={"text-lg"}>{portfolioName}</span>
              ) : (
                <Input
                  disabled={updatingName}
                  suffix={updatingName && <LoadingOutlined />}
                  defaultValue={portfolioName}
                  onPressEnter={(value) => {
                    initiatePortfolioNameUpdate(value.currentTarget.value);
                  }}
                />
              )}
              <PortfolioAdminPermissionBlock permissions={permissions}>
                {!editingName && (
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => setEditingName(!editingName)}
                    className={`group-hover:opacity-100 opacity-0 border-0 ${PRIMARY_BUTTON_STYLE}`}
                  />
                )}
              </PortfolioAdminPermissionBlock>
            </div>
          </div>
        )}
        <div className={"h-full max-h-full overflow-y-auto"}>
          {sectionList?.map((s) => (
            <div
              key={s.id}
              onClick={(): void => onSelectSection(s?.id)}
              className={`cursor-pointer py-2 px-5 border-0  ${
                s.id === sectionId ? SELECTED_STYLE : NOT_SELECTED_STYLE
              }`}
            >
              {s.sectionName}
            </div>
          ))}
          {portfolioId && newSection && allowCreateNewSection && (
            <Form
              className={"border-0"}
              onFinish={({ sectionName }): void => {
                if (!isNil(sectionName.trim()))
                  createNewSection(sectionName.trim(), portfolioId);
              }}
            >
              <FormItem
                required={true}
                className={"mx-5 border-b border-primary"}
                name={"sectionName"}
              >
                <Input
                  placeholder={"Section Name"}
                  className={"px-0"}
                  bordered={false}
                  suffix={<i className={`fa fa-pencil-alt text-primary`} />}
                />
              </FormItem>
              <div className={"w-full px-6 flex justify-end my-2"} key={0}>
                <Button
                  onClick={(): void => setNewSection(false)}
                  className={`mx-2 text-primary border border-primary`}
                >
                  Cancel
                </Button>
                <Button
                  htmlType={"submit"}
                  className={`bg-primary hover:bg-hover text-white border-0`}
                >
                  Confirm
                </Button>
              </div>
            </Form>
          )}
          {!newSection &&
            allowCreateNewSection &&
            permissions.includes(PermissionType.ADMIN_PORTFOLIO) && (
              <div
                onClick={(): void => setNewSection(true)}
                className={`py-2 px-5 border-0 cursor-pointer text-blue-400 ${NOT_SELECTED_STYLE}`}
              >
                + New Section
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

type PortfolioViewNavType = {
  companyTitle?: string;
  portfolioId?: string;
  companyId: string;
  sectionList: Array<PortfolioSectionType>;
  onCreateNewSection: (sections: PortfolioSectionType[]) => void;
  permissions: PermissionType[];
  onSelectSection: (sectionId: string) => void;
  allowCreateNewSection: boolean;
};

type goToRouteType = (section: PortfolioSectionType | null) => void;
type ParamsType = { sectionId: string };
