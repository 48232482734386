import { Table } from "antd";
import React, { FC, ReactNode } from "react";
import { AllocationType } from "../../../../utils/types";
import { ColumnsType } from "antd/es/table";
import { isEmpty } from "ramda";

export const AllocationFooter: FC<AllocationFooterType> = ({
  data,
  rows,
  disabledAddInstitution,
  columns,
  editPermission = false,
  institutionModal,
  onAddInstitutions,
  selectedCompanies = [],
}) => {
  return (
    <>
      {editPermission && institutionModal && (
        <Table.Summary.Row className={"bg-gray-100"}>
          <Table.Summary.Cell
            className={"p-0 !z-20"}
            key={"addRow"}
            index={0}
            colSpan={1}
          >
            <div
              className={
                "text-blue-400 cursor-pointer hover:underline flex w-full"
              }
            >
              {institutionModal &&
                institutionModal(
                  (ids) => {
                    onAddInstitutions(ids);
                  },
                  selectedCompanies,
                  disabledAddInstitution
                )}
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell
            className={"p-0 bg-white"}
            key={"addRow-static"}
            index={1}
            colSpan={columns.length - 1}
          />
        </Table.Summary.Row>
      )}
      {!isEmpty(data) && (
        <Table.Summary.Row>
          {columns.map(({ render }, index) => (
            <Table.Summary.Cell
              className={`p-0 whitespace-nowrap !bg-zinc-200 font-bold ${
                index === 0 && "!z-20"
              }`}
              index={index}
              key={index}
            >
              {
                (render && !isEmpty(rows) ? (
                  render("", rows[0], index)
                ) : (
                  <></>
                )) as ReactNode
              }
            </Table.Summary.Cell>
          ))}
        </Table.Summary.Row>
      )}
    </>
  );
};

export type InstitutionModalRenderType = (
  onSubmit: (ids: string[]) => void,
  selectedCompanies: string[],
  disabled: boolean
) => ReactNode;

type AllocationFooterType = {
  rows: AllocationType[][];
  disabledAddInstitution: boolean;
  columns: ColumnsType<AllocationType[]>;
  data: AllocationType[];
  editPermission?: boolean;
  institutionModal?: InstitutionModalRenderType;
  onAddInstitutions: (ids: string[]) => void;
  selectedCompanies?: string[];
};
