import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import {
  InviteUserType,
  LenderType,
  TagType,
  TeamMemberType,
  TransactionType,
  UserType,
} from "../../../../utils/types";
import {
  deleteLenderDTO,
  deleteUserDTO,
  exportTransaction,
  getCompanyTags,
  getCompanyTeamById,
  invite,
  linkTagToElement,
  unLinkTagFromElement,
} from "../../../../services/services";
import {
  Button,
  Collapse,
  Empty,
  message,
  Modal,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";

import {
  DownloadOutlined,
  MailOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { AddInstitution } from "./AddInstitutions";
import { InstitutionUserForm } from "../../../forms/InstitutionUserForm";
import {
  getObjectFromPropertyValue,
  populateArrayWithPropertyPath,
  removeItemFromArray,
  updateItemInArray,
} from "../../../../utils/utils";
import {
  fetchWithIdType,
  FileResponseType,
  LoadingType,
  ResponseType,
  voidType,
} from "../../../../utils/uiTypes";
import { CustomSpin } from "../../../general/CustomSpin";
import { CustomAvatar } from "../../../general/CustomAvatar";
import {
  ElementType,
  InvitationStatusType,
  PermissionType,
  PETransactionCreationType,
  SmartTagActionType,
} from "../../../../utils/enums";
import {
  ConfirmAction,
  ConfirmDelete,
  ConfirmDownload,
} from "../../../../utils/confirmationModals";
import { usePageTitle } from "../../../../customHooks/usePageTitle";
import {
  invitationStatusTag,
  resendTeamInvite,
  tableColumnHeader,
} from "../../../../utils/componentUtils";
import { TransactionContext } from "../../../../context/TransactionContext";
import {
  ACTION_BUTTON_CSS,
  PRIMARY_BUTTON_STYLE,
} from "../../../../utils/cssConfigs";
import { ColumnsType } from "antd/es/table";
import { and, any, equals, isEmpty, or, pathOr } from "ramda";
import { AddTagButton, TagsDropdown } from "../../../company/tags/TagsDropdown";
import { ElementTag } from "../../../company/tags/ElementTag";
import { UserContext } from "../../../../context/UserContext";
import {
  isOfflineBorrower,
  isOfflineLender,
} from "../../../../utils/transaction";
import { MultipleSelect } from "../../../general/MultipleSelect";
import { ViewEmail } from "../../../general/ViewEmail";

export const ManageInstitutions: FC = function () {
  usePageTitle("Institutions");

  const { user } = useContext(UserContext);
  const {
    transaction,
    setTransaction,
    permissions,
    belongToDealTeam,
    modules,
    belongToTxCreatorCompany,
  } = useContext(TransactionContext);

  const [newLenderModalVisible, setNewLenderModalVisible] =
    useState<boolean>(false);

  const [newInstitutionIdUser, setNewInstitutionIdUser] = useState<
    string | null
  >(null);

  const [companyTeams, setCompanyTeams] = useState<
    Record<string, TeamMemberType[]>
  >({});
  const [loading, setLoading] = useState<LoadingType>({
    loading: false,
    label: "",
  });
  const [formLoading, setFormLoading] = useState<FormLoadingType>({
    message: "",
    status: FormStatusType.NONE,
  });
  const [tags, setTags] = useState<TagType[]>([]);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);

  const fetchTags: voidType = () => {
    getCompanyTags({
      params: { peTransactionId: transaction?.peTransactionId ?? "" },
    })
      .then(({ data }: ResponseType<TagType[]>) => {
        setTags(data);
      })
      .catch(console.error);
  };

  const onRemoveTag =
    (lender: LenderType, lenderIndex: number) => (): Promise<void> => {
      return new Promise((resolve, reject) =>
        unLinkTagFromElement({
          segments: {
            elementId: lender.id,
            elementType: ElementType.LENDER,
          },
        })
          .then(() => {
            transaction &&
              setTransaction({
                ...transaction,
                lenderDTOs: updateItemInArray(
                  lenderIndex,
                  transaction.lenderDTOs,
                  {
                    ...lender,
                    tagDTOs: [],
                  }
                ),
              });
            resolve();
          })
          .catch((error: string) => {
            message.error(error ?? "Error removing tag");
            reject();
          })
      );
    };

  const onUpdateTag =
    (lender: LenderType, lenderIndex: number) =>
    (tagId: string | null): Promise<void> => {
      if (tagId) {
        return onLinkTag(lender, lenderIndex)(tagId);
      } else {
        return onRemoveTag(lender, lenderIndex)();
      }
    };

  const onLinkTag =
    (lender: LenderType, lenderIndex: number) =>
    (tagId: string): Promise<void> => {
      const tagDTO: TagType = getObjectFromPropertyValue("id", tagId, tags);

      return new Promise((resolve, reject) => {
        const initiateLinkTag = (): void => {
          linkTagToElement({
            segments: {
              tagId,
              elementId: lender.id,
              elementType: ElementType.LENDER,
            },
            params: {
              smartAction: true,
            },
          })
            .then(() => {
              if (transaction) {
                setTransaction({
                  ...transaction,
                  lenderDTOs: updateItemInArray(
                    lenderIndex,
                    transaction.lenderDTOs,
                    {
                      ...lender,
                      userDTOs: tagDTO?.smartActions?.includes(
                        SmartTagActionType.REMOVE_ALL_USERS
                      )
                        ? []
                        : lender?.userDTOs,
                      tagDTOs: [tagDTO],
                    } as LenderType
                  ),
                });
              }
              resolve();
            })
            .catch((error: string) => {
              message.error(error || "Error adding tag");
              reject(error);
            });
        };

        if (tagDTO)
          if (
            tagDTO?.smartActions?.length > 0 &&
            lender?.userDTOs?.length > 0
          ) {
            ConfirmAction(
              "Smart Action Warning",
              () => initiateLinkTag(),
              <div>
                {tagDTO?.smartActions?.[0]?.replaceAll("_", " ")} will be
                executed after assigning the institution to this particular tag.
                To remove the smart action, please update the smart actions for
                the tag from manage tags under the company settings.
              </div>,
              { okButtonProps: { className: PRIMARY_BUTTON_STYLE } },
              "Update"
            );
          } else {
            initiateLinkTag();
          }
      });
    };

  const extraNode = (
    lender: LenderType,
    deletePermission: boolean,
    addPermission: boolean,
    lenderIndex: number,
    isOffline = false
  ): ReactNode => {
    return (
      <span
        className={"flex items-center gap-x-2 w-full flex h-8 cursor-pointer"}
      >
        {belongToDealTeam && modules?.tags && (
          <TagsDropdown
            disabled={!deletePermission}
            list={tags}
            disableCreate={!user?.isAdmin || !belongToTxCreatorCompany}
            currentSelected={lender?.tagDTOs?.map(({ id }) => id)}
            onSelect={(val) => {
              return onLinkTag(lender, lenderIndex)(val);
            }}
            onAdd={(tag) => {
              setTags([...tags, tag]);
            }}
          >
            {!isEmpty(lender?.tagDTOs ?? []) ? (
              <ElementTag
                tooltipPlacement={"left"}
                tag={lender?.tagDTOs[0]}
                onDelete={
                  deletePermission
                    ? () => onUpdateTag(lender, lenderIndex)(null)
                    : undefined
                }
              />
            ) : (
              deletePermission && <AddTagButton />
            )}
          </TagsDropdown>
        )}
        {!isOffline && (
          <Tag
            color={"blue"}
            className={"ml-2 hidden sm:flex flex-initial items-center my-auto"}
          >
            <UserOutlined className={"mr-2"} /> :&nbsp;
            {lender?.userDTOs ? lender?.userDTOs?.length : 0}
          </Tag>
        )}
        {transaction && (
          <div
            className={"relative p-0 m-0 flex flex-row gap-x-2 items-center"}
          >
            {addPermission && (
              <Button
                className={`${ACTION_BUTTON_CSS} hover:text-primary`}
                icon={<i className="fas fa-user-plus" />}
                onClick={(e): void => {
                  e.stopPropagation();
                  setNewInstitutionIdUser(lender?.id);
                }}
              />
            )}
            {deletePermission && (
              <Button
                className={`${ACTION_BUTTON_CSS} hover:text-red-500`}
                icon={<i className="fas fa-trash-alt" />}
                onClick={(e): void => {
                  e.stopPropagation();
                  ConfirmDelete(
                    `Are you sure you want to delete "${lender?.name}" from your transaction?`,
                    (): void => deleteLender(lender, transaction, lenderIndex)
                  );
                }}
              />
            )}
          </div>
        )}
      </span>
    );
  };

  const initiateExportTransaction: fetchWithIdType = (id) => {
    message.loading({
      content: "Processing File...",
      duration: 0,
      key: "export",
    });
    exportTransaction({
      segments: {
        id,
      },
    })
      .then(({ url, filename }: FileResponseType) => {
        message.success({ key: "export", content: "File Ready to Download" });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) =>
        message.error({ content: error, key: "export" })
      );
  };

  const initiateInvite: initiateInviteType = (
    values,
    lender,
    transaction,
    lenderIndex
  ) => {
    invite({
      body: JSON.stringify(values),
    })
      .then(({ data }: ResponseType<InviteUserType>) => {
        setTransaction({
          ...transaction,
          lenderDTOs: updateItemInArray(lenderIndex, transaction?.lenderDTOs, {
            ...lender,
            userDTOs: [...(lender?.userDTOs ?? []), data],
          }),
        });
      })
      .then(() => {
        message.success("Added User Successfully!");
        setFormLoading({ message: "", status: FormStatusType.SUCCESS });
        setNewInstitutionIdUser(null);
      })
      .catch((error: string) => {
        message.error(error ? error : "Error Adding User");
        setFormLoading({
          message: error ? error : "Error Adding User",
          status: FormStatusType.FAILED,
        });
      });
  };

  const requestAddUserDTO: requestAddUserDTOType = (
    values,
    lender,
    transaction,
    lenderIndex
  ) => {
    setFormLoading({ message: "", status: FormStatusType.LOADING });
    const data: InstitutionUserType = {
      ...values,
      invitationStatus: InvitationStatusType.PENDING,
      companyId: lender?.companyDTO?.id,
      peTransactionId: transaction?.peTransactionId,
      elementId: lender?.id,
      elementType: ElementType.LENDER,
    };

    initiateInvite(data, lender, transaction, lenderIndex);
  };

  const deleteUser: deleteUserType = (
    lenderId,
    lenderUser,
    transaction,
    lenderIndex,
    userIndex
  ) => {
    setLoading({ loading: true, label: "Deleting User..." });
    deleteUserDTO({
      segments: {
        id: lenderId,
      },
      body: JSON.stringify([lenderUser.userId]),
    })
      .then(() => {
        setTransaction({
          ...transaction,
          lenderDTOs: updateItemInArray(
            lenderIndex,
            transaction?.lenderDTOs ?? [],
            {
              ...transaction?.lenderDTOs[lenderIndex],
              userDTOs: removeItemFromArray(
                userIndex,
                transaction?.lenderDTOs[lenderIndex]?.userDTOs
              ),
            }
          ),
        });
      })
      .then(() => {
        message.success("Deleted User Successfully!");
        setLoading({ loading: false });
      })
      .catch((error: string) => {
        message.error(
          error ? error : "Error Deleting existing User from Institution!"
        );
        setLoading({ loading: false });
      });
  };

  const deleteLender: deleteLenderType = (lender, transaction, lenderIndex) => {
    setLoading({ loading: true, label: "Deleting Institution..." });
    deleteLenderDTO({
      segments: {
        id: lender?.id,
      },
      body: JSON.stringify(
        populateArrayWithPropertyPath(["userId"], lender?.userDTOs)
      ),
    })
      .then(() => {
        setTransaction({
          ...transaction,
          lenderDTOs: removeItemFromArray(
            lenderIndex,
            transaction?.lenderDTOs ?? []
          ),
        });
      })
      .then(() => {
        message.success("Deleted Institution Successfully!");
        setLoading({ loading: false });
      })
      .catch((error: string) => {
        message.error(error ? error : "Error Deleting Institution!");
        setLoading({ loading: false });
      });
  };

  const updateCompanyTeams: updateTeamsType = (lender, teams) => {
    const id = lender?.companyDTO?.id;
    getCompanyTeamById({
      segments: {
        id,
      },
    })
      .then(({ data = [] }: ResponseType<TeamMemberType[]>) => {
        if (lender?.userDTOs) {
          const userIDs = populateArrayWithPropertyPath(
            ["userId"],
            lender?.userDTOs
          );
          const filtered = data.filter(
            ({ userDTO }: TeamMemberType) => !userIDs.includes(userDTO.userId)
          );
          setCompanyTeams({ ...teams, [id]: filtered });
        } else {
          setCompanyTeams({ ...teams, [id]: data });
        }
      })
      .catch((error: string) => {
        message.error(error ? error : "Error fetching the users list!");
      });
  };

  const columns: columnsType = (id, lenderIndex) => [
    {
      title: tableColumnHeader("First Name"),
      dataIndex: "firstName",
      key: "firstName",
      width: "25%",
      className: "group-hover:bg-blue-50",
    },
    {
      title: tableColumnHeader("Last Name"),
      dataIndex: "lastName",
      key: "lastName",
      width: "25%",
      className: "group-hover:bg-blue-50",
    },
    {
      title: tableColumnHeader("Email"),
      dataIndex: "email",
      key: "email",
      width: "35%",
      className: "group-hover:bg-blue-50",
      render: ViewEmail,
    },
    {
      title: tableColumnHeader("Phone Number"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "15%",
      className:
        "group-hover:bg-blue-50 empty:text-red-500 empty:text-xs empty:after:content-['Not_Provided']",
    },
    {
      title: tableColumnHeader("Status"),
      dataIndex: "invitationStatus",
      key: "invitationStatus",
      width: "10%",
      className: "group-hover:bg-blue-50",
      render: function resendInvite(status: InvitationStatusType): ReactNode {
        return <>{invitationStatusTag(status)}</>;
      },
    },
    {
      title: tableColumnHeader("Actions"),
      dataIndex: "operation",
      key: "operation",
      width: "5%",
      align: "center",
      className: "group-hover:bg-blue-50",
      render: function operation(
        _: string,
        lenderUser: UserType,
        userIndex: number
      ): ReactNode {
        return (
          <div className={"flex gap-1 justify-center relative"}>
            {transaction && (
              <>
                {lenderUser.invitationStatus ===
                  InvitationStatusType.PENDING && (
                  <Tooltip title={"Resend invite"}>
                    <Button
                      className={`hover:text-primary opacity-0 group-hover:opacity-100 ${ACTION_BUTTON_CSS}`}
                      onClick={(): void =>
                        resendTeamInvite(
                          lenderUser.userId,
                          lenderUser.companyDTO?.id,
                          transaction?.peTransactionId,
                          id,
                          belongToDealTeam ? "LENDER" : "LENDER_TRANSACTION"
                        )
                      }
                    >
                      <MailOutlined className={" cursor-pointer"} />
                    </Button>
                  </Tooltip>
                )}
                {user?.userId !== lenderUser.userId && (
                  <Button
                    className={`hover:text-red-500 opacity-0 group-hover:opacity-100 ${ACTION_BUTTON_CSS}`}
                    onClick={(): void => {
                      ConfirmDelete(
                        "Please confirm you want to delete this user",
                        () =>
                          deleteUser(
                            id,
                            lenderUser,
                            transaction,
                            lenderIndex,
                            userIndex
                          ),
                        <>
                          User Name:&nbsp;
                          {`${lenderUser?.firstName} ${lenderUser?.lastName}`}
                          <br />
                          Email: {lenderUser?.email}
                        </>
                      );
                    }}
                  >
                    <i className="fas fa-trash-alt" />
                  </Button>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (belongToDealTeam) {
      fetchTags();
    }
  }, [belongToDealTeam]);

  return (
    <>
      {transaction && (
        <div
          className={
            "relative mx-auto p-6 h-full max-h-full overflow-y-auto flex flex-col w-full"
          }
        >
          <CustomSpin loading={loading.loading} loadingText={loading.label} />
          <div className={"flex flex-col sm:flex-row gap-4 items-center pb-3"}>
            <div className={"m-0 text-xl font-medium"}>
              {permissions.includes(PermissionType.ADMIN_PETRANSACTION) ||
              permissions.includes(PermissionType.NON_ADMIN_PETRANSACTION)
                ? "Manage Institutions"
                : "Add Team Members"}
              &nbsp;
            </div>
            {permissions.includes(PermissionType.ADMIN_PETRANSACTION) && (
              <Tag
                color={"blue"}
                className={
                  "hidden sm:flex flex-initial items-center my-auto mr-auto"
                }
              >
                <i className="fa-regular fa-building mr-2"></i>:&nbsp;
                {transaction?.lenderDTOs?.length ?? 0}
              </Tag>
            )}
            {belongToDealTeam && modules?.tags && (
              <MultipleSelect
                label={"Tags"}
                value={selectedTagIds}
                defaultValue={[]}
                onChange={setSelectedTagIds}
                placeholder={"Select Tags to Filter"}
                className={"!flex-none ml-auto max-w-xs"}
                options={tags.map(({ id, label }) => ({
                  label,
                  value: id,
                  filterValue: label,
                }))}
              />
            )}
            {permissions.includes(PermissionType.ADMIN_PETRANSACTION) && (
              <>
                {transaction?.lenderDTOs?.length > 0 &&
                  transaction?.peTransactionCreationType !==
                    PETransactionCreationType.OFFLINE && (
                    <Button
                      icon={<DownloadOutlined />}
                      className={PRIMARY_BUTTON_STYLE}
                      onClick={(): void =>
                        initiateExportTransaction(transaction.peTransactionId)
                      }
                    >
                      Export
                    </Button>
                  )}
                <Button
                  disabled={newLenderModalVisible}
                  className={`bg-primary hover:bg-hover text-white border-0`}
                  onClick={(): void => setNewLenderModalVisible(true)}
                  icon={<PlusOutlined />}
                >
                  Add Institution(s)
                </Button>
              </>
            )}
          </div>
          <Modal
            open={newLenderModalVisible}
            title={"Add Institution(s)"}
            onCancel={(): void => setNewLenderModalVisible(false)}
            footer={false}
            destroyOnClose={true}
            maskClosable={false}
          >
            <AddInstitution
              transaction={transaction}
              updateTransaction={setTransaction}
              onClear={(): void => {
                setNewLenderModalVisible(false);
              }}
            />
          </Modal>
          <div className={`h-full m-0 p-0 overflow-y-auto`}>
            <Space direction={"vertical"} className={`w-full gap-y-0`}>
              {transaction?.lenderDTOs.length === 0 && (
                <Empty
                  image={
                    <i className="fa-solid fa-building-columns text-8xl text-gray-300"></i>
                  }
                  className={"text-gray-400"}
                  description={"No Institutions have been added!"}
                />
              )}
              {transaction?.lenderDTOs
                .filter(({ tagDTOs }) =>
                  isEmpty(selectedTagIds)
                    ? true
                    : selectedTagIds.includes(pathOr("", [0, "id"], tagDTOs))
                )
                .map((lender: LenderType, lenderIndex: number) => {
                  return (
                    lender &&
                    (belongToDealTeam
                      ? lender?.companyDTO?.id !== transaction?.companyId
                      : true) && (
                      <Tooltip
                        title={
                          isOfflineBorrower(transaction)
                            ? "Cannot add Users in Offline Transation"
                            : ""
                        }
                        key={lender?.id}
                      >
                        <Collapse
                          className={`bg-white my-1 border hover:shadow hover:bg-blue-50 !cursor-default`}
                          ghost={true}
                          expandIconPosition={"end"}
                        >
                          <Collapse.Panel
                            collapsible={
                              isOfflineBorrower(transaction)
                                ? "disabled"
                                : undefined
                            }
                            key={lender?.id}
                            header={
                              <div
                                className={"w-full truncate pr-2 text-black"}
                              >
                                <CustomAvatar
                                  data={(
                                    lender?.name?.charAt(0) ?? ""
                                  ).toUpperCase()}
                                  color={lender?.name?.charAt(0) ?? ""}
                                />
                                <span className={"truncate"}>
                                  {lender?.name}
                                </span>
                              </div>
                            }
                            showArrow={or(
                              permissions.includes(PermissionType.VIEW_LENDER),
                              isOfflineLender(transaction)
                            )}
                            extra={extraNode(
                              lender,
                              permissions.includes(
                                PermissionType.ADMIN_PETRANSACTION
                              ),
                              and(
                                modules?.inviteLenderUsers ?? false,
                                transaction.peTransactionCreationType ===
                                  PETransactionCreationType.ONLINE
                                  ? any(
                                      (permission) =>
                                        permissions.includes(permission),
                                      [
                                        PermissionType.ADMIN_LENDER,
                                        PermissionType.ADMIN_PETRANSACTION,
                                        PermissionType.EDIT_LENDER,
                                      ]
                                    )
                                  : isOfflineLender(transaction)
                              ),
                              lenderIndex,
                              equals(
                                transaction.peTransactionCreationType,
                                PETransactionCreationType.OFFLINE
                              )
                            )}
                            className={"rounded p-0"}
                          >
                            {permissions.includes(PermissionType.VIEW_LENDER) &&
                            (lender?.userDTOs ?? []).length !== 0 ? (
                              <Table
                                key={lender?.id}
                                columns={
                                  or(
                                    any(
                                      (permission) =>
                                        permissions.includes(permission),
                                      [
                                        PermissionType.ADMIN_LENDER,
                                        PermissionType.ADMIN_PETRANSACTION,
                                      ]
                                    ),
                                    isOfflineLender(transaction)
                                  )
                                    ? columns(lender?.id, lenderIndex)
                                    : columns(lender?.id, lenderIndex).filter(
                                        ({ key }) => key !== "operation"
                                      )
                                }
                                dataSource={lender?.userDTOs}
                                pagination={false}
                                className={"bg-transparent border"}
                                rowKey={"userId"}
                                scroll={{ x: true }}
                                rowClassName={"group hover:bg-blue-50"}
                              />
                            ) : (
                              <div className={"text-center text-muted"}>
                                No Users have been added to the Institution!
                              </div>
                            )}
                          </Collapse.Panel>
                        </Collapse>
                        <Modal
                          open={newInstitutionIdUser === lender?.id}
                          title={`Add User for ${lender?.name} Institution`}
                          footer={false}
                          destroyOnClose={true}
                          onCancel={(): void => setNewInstitutionIdUser(null)}
                        >
                          <InstitutionUserForm
                            onSubmit={(values): void => {
                              requestAddUserDTO(
                                values,
                                lender,
                                transaction,
                                lenderIndex
                              );
                            }}
                            options={companyTeams[lender?.companyDTO.id] ?? []}
                            onUpdate={(lender): void =>
                              updateCompanyTeams(lender, companyTeams)
                            }
                            lender={lender}
                            formStatus={formLoading.status}
                            message={formLoading.message}
                          />
                        </Modal>
                      </Tooltip>
                    )
                  );
                })}
            </Space>
          </div>
        </div>
      )}
    </>
  );
};

type InstitutionUserType = {
  companyId: string;
  elementId: string;
  elementType: string;
  email: string;
  firstName: string;
  lastName: string;
  invitationStatus: InvitationStatusType;
  peTransactionId: string;
  userId: string;
};
type deleteUserType = (
  id: string,
  lenderUser: UserType,
  transaction: TransactionType,
  lenderIndex: number,
  userIndex: number
) => void;
type deleteLenderType = (
  lender: LenderType,
  transaction: TransactionType,
  lenderIndex: number
) => void;
type updateTeamsType = (
  lender: LenderType,
  teams: Record<string, TeamMemberType[]>
) => void;
type initiateInviteType = (
  values: InstitutionUserType,
  lender: LenderType,
  transaction: TransactionType,
  lenderIndex: number
) => void;
type requestAddUserDTOType = (
  values: InstitutionUserType,
  lender: LenderType,
  transaction: TransactionType,
  lenderIndex: number
) => void;
type columnsType = (id: string, lenderIndex: number) => ColumnsType<UserType>;
enum FormStatusType {
  LOADING,
  SUCCESS,
  FAILED,
  NONE,
}
type FormLoadingType = {
  status: FormStatusType;
  message: string;
};
