import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  clearColumn,
  ColumnElementType,
  isAgreementResponse,
  RightClickActionType,
  SelectedCellType,
} from "../../../utils/termsheet";
import { updateItemInArray, valOrDefault } from "../../../utils/utils";
import {
  DefaultColumnType,
  HeightWidthMapType,
} from "../../../utils/newTermsheet";
import { WebFormConfigType } from "../../../utils/types";
import { ColumnHeaderCell } from "../common/ColumnHeaderCell";
import { TermsheetContext } from "../../../context/TermsheetContext";
import { ConfirmDelete } from "../../../utils/confirmationModals";
import { Tooltip } from "antd";
import RowIndexCell from "../common/RowIndexCell";
import Cell from "../common/Cell";
import { isEmpty } from "ramda";
import { useIntersectionView } from "../../../customHooks/useIntersectionView";
import { ElementType } from "../../../utils/enums";
import Row from "../common/Row";

export const TermsheetRows: FC<TermsheetRowsType> = function ({
  redLine = false,
  heatMap = false,
  published,
  elements,
  configs = [],
  selectedCell = null,
  onSelectCell,
  onAddOrDeleteRow,
  onWebFormConfigUpdate,
}: TermsheetRowsType) {
  const {
    transactionId,
    isAdmin,
    isNonAdmin,
    isInstitution,
    element,
    isOffline,
  } = useContext(TermsheetContext);
  const ref = useRef();
  const [selectedSeries, setSelectedSeries] =
    useState<SelectedSeriesType | null>(null);
  const [rowHeight, setRowHeight] = useState<HeightWidthMapType>({});
  const [columnWidth, setColumnWidth] = useState<HeightWidthMapType>({});
  const [webFormConfigs, setWebFormConfigs] = useState<
    Array<WebFormConfigType>
  >([]);

  const onChangeA = useCallback(
    (i: any, text: any) => {
      const config = webFormConfigs[i];
      onWebFormConfigUpdate(
        updateItemInArray(i, webFormConfigs, {
          ...config,
          uiKey: { ...config.uiKey, text },
        })
      );
    },
    [webFormConfigs]
  );

  const onSelectA = useCallback(
    (i: any, style: any) => {
      setSelectedSeries(null);
      const config = webFormConfigs[i];
      onSelectCell({
        rowId: config.id,
        rowIndex: i,
        rowPosition: config.position,
        column: DefaultColumnType.BID_METRICS,
        style: style,
      });
    },
    [webFormConfigs]
  );

  const onChangeB = useCallback(
    (i: any, text: any, elementId: any) => {
      const config = webFormConfigs[i];
      onWebFormConfigUpdate(
        updateItemInArray(i, webFormConfigs, {
          ...config,
          dataMap: {
            ...config.dataMap,
            [elementId]: { ...config.dataMap[elementId], text },
          },
        })
      );
    },
    [webFormConfigs]
  );

  const onSelectB = useCallback(
    (i: any, style: any, elementId: any) => {
      const config = webFormConfigs[i];
      setSelectedSeries(null);
      onSelectCell({
        rowId: config.id,
        rowIndex: i,
        rowPosition: config.position,
        column: elementId,
        style: style,
      });
    },
    [webFormConfigs]
  );

  const [minIndex, maxIndex] = useIntersectionView(
    ".termsheet-row",
    ref.current,
    [webFormConfigs]
  );

  useEffect(() => {
    setWebFormConfigs(configs);
  }, [configs]);

  return (
    <>
      <div className={"sticky top-0 z-[90] bg-gray-100 table-row"}>
        <div className={`flex border-b border-gray-200 z-[10]`}>
          <div className={"sticky left-0 z-[100]"}>
            <div
              className={
                "h-full w-10 min-w-[2.5rem] text-xxs border-gray-200 bg-gray-50 text-gray-400"
              }
            />
          </div>
          <div className={"border-l border-r"}>
            <ColumnHeaderCell
              selected={selectedCell?.column === DefaultColumnType.BID_METRICS}
              width={valOrDefault(
                300,
                columnWidth[DefaultColumnType.BID_METRICS]
              )}
              content={"Bid Metrics"}
              onSelect={() => {
                //setSelectedColumn(DefaultColumnType.BID_METRICS);
              }}
              onWidthChange={(o) => {
                setColumnWidth((columnWidth) => ({
                  ...columnWidth,
                  [DefaultColumnType.BID_METRICS]: o,
                }));
              }}
              editColumn={isAdmin && !published}
              onClear={() => {
                ConfirmDelete(
                  "Are you sure you want to clear the bid metrics column?",
                  () => {
                    onWebFormConfigUpdate(
                      clearColumn(transactionId, "BID_METRICS", webFormConfigs)
                    );
                  },
                  <></>,
                  undefined,
                  "Clear"
                );
              }}
            />
          </div>
          {elements.map(({ id, label }) => {
            return (
              <div key={id} className={"border-r"}>
                <ColumnHeaderCell
                  selected={selectedCell?.column === id}
                  width={valOrDefault(300, columnWidth[id])}
                  content={
                    id === transactionId ? (
                      label
                    ) : (
                      <div
                        className={
                          "w-full inline-flex justify-between items-center"
                        }
                      >
                        <span>{label}</span>
                        {published && !isOffline && (
                          <Tooltip
                            placement={"bottom"}
                            title={
                              isAdmin || isNonAdmin
                                ? `Only you and ${label} can see this`
                                : "Only you and the Deal Team can see this"
                            }
                          >
                            <i
                              className={`fa fa-lock ${
                                selectedCell?.column === id
                                  ? "text-white"
                                  : "text-muted"
                              }`}
                            />
                          </Tooltip>
                        )}
                      </div>
                    )
                  }
                  onSelect={() => {
                    //      setSelectedColumn(id);
                  }}
                  onWidthChange={(o) => {
                    setColumnWidth({
                      ...columnWidth,
                      [id]: o,
                    });
                  }}
                  editColumn={
                    (id === transactionId ? isAdmin : isInstitution) &&
                    !published
                  }
                  onClear={() => {
                    ConfirmDelete(
                      `Are you sure you want to clear ${
                        id === transactionId
                          ? "the Deal Team Ask column"
                          : "this column"
                      }?`,
                      () =>
                        onWebFormConfigUpdate(
                          clearColumn(
                            id,
                            id === transactionId
                              ? "DEAL_TEAM_ASK"
                              : "INSTITUTION",
                            webFormConfigs
                          )
                        ),
                      <></>,
                      undefined,
                      "Clear"
                    );
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        ref={ref.current}
        className={"border-l border-gray-200 table-row-group scroll-smooth"}
      >
        {webFormConfigs.map(({ id, position, uiKey, dataMap }, i) => {
          return (
            <div key={id} data-rowindex={i} className={"termsheet-row"}>
              <Row
                key={id}
                id={id}
                index={i}
                allowRightClick={isAdmin && !published}
                onAddOrDeleteRow={onAddOrDeleteRow}
              >
                <div
                  className={`flex bg-white border-b border-gray-200 z-[10] min-h-[35px]`}
                >
                  <RowIndexCell
                    position={i + 1}
                    selected={
                      (selectedSeries?.type === "ROW" &&
                        selectedSeries?.id === id) ||
                      (selectedCell?.rowPosition === position &&
                        selectedCell.rowId === id &&
                        selectedCell?.rowIndex === i)
                    }
                    onSelect={() => {
                      /*onSelectCell({
                        column: DefaultColumnType.BID_METRICS,
                        rowId: id,
                        rowIndex: i,
                        rowPosition: position,
                        style: uiKey.style,
                      });*/
                      //  setSelectedSeries({ id, index: i, type: "ROW" });
                    }}
                  />
                  {i >= minIndex && i <= maxIndex + 35 && (
                    <>
                      <div className={"bg-white border-r"}>
                        <Cell
                          className={
                            selectedSeries?.index === i
                              ? "border-l border-primary"
                              : ""
                          }
                          selectedSeries={selectedSeries?.index === i}
                          index={i}
                          selectedCell={
                            selectedCell?.rowIndex === i &&
                            selectedCell?.column ===
                              DefaultColumnType.BID_METRICS
                          }
                          selectable={isAdmin || isNonAdmin}
                          elementId={DefaultColumnType.BID_METRICS}
                          editable={isAdmin && !published}
                          style={JSON.stringify(uiKey.style)}
                          text={uiKey.text}
                          width={valOrDefault(
                            300,
                            columnWidth[DefaultColumnType.BID_METRICS]
                          )}
                          onChange={onChangeA}
                          onSelect={onSelectA}
                        />
                      </div>
                      {elements.map(({ id, label }, index) => {
                        const bidMetrics = valOrDefault("", uiKey.text);
                        const dealTeamAsk = valOrDefault(
                          "",
                          dataMap[transactionId]?.text
                        );
                        const text = valOrDefault("", dataMap[id]?.text);
                        const style = valOrDefault({}, dataMap[id]?.style);
                        return (
                          <div key={id} className={"bg-white border-r"}>
                            <Cell
                              className={
                                selectedSeries?.index === i &&
                                index === elements?.length - 1
                                  ? "border-r border-primary"
                                  : ""
                              }
                              selectedCell={
                                selectedCell?.rowIndex === i &&
                                selectedCell?.column === id
                              }
                              selectedSeries={selectedSeries?.index === i}
                              index={i}
                              elementId={id}
                              selectable={
                                isAdmin ||
                                isNonAdmin ||
                                (isInstitution &&
                                  element?.elementType === ElementType.LENDER &&
                                  id === element?.elementId)
                              }
                              editable={
                                isOffline
                                  ? isAdmin && !published
                                  : (id === transactionId
                                      ? isAdmin
                                      : isInstitution) &&
                                    id === element?.elementId &&
                                    !published
                              }
                              showRedLine={
                                redLine &&
                                id !== transactionId &&
                                !isAgreementResponse(text)
                              }
                              showQuickSelection={
                                !isEmpty(dealTeamAsk) && isEmpty(text)
                              }
                              style={JSON.stringify(style)}
                              text={text}
                              width={valOrDefault(300, columnWidth[id])}
                              heatMap={
                                heatMap &&
                                id !== transactionId &&
                                !isEmpty(dealTeamAsk.trim())
                              }
                              compareText={dealTeamAsk}
                              onChange={onChangeB}
                              onSelect={onSelectB}
                            />
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </Row>
            </div>
          );
        })}
      </div>
    </>
  );
};

type TermsheetRowsType = {
  redLine?: boolean;
  heatMap: boolean;
  published: boolean;
  elements: Array<ColumnElementType>;
  configs: Array<WebFormConfigType>;
  selectedCell: SelectedCellType | null;
  onSelectCell: (o: SelectedCellType) => void;
  onAddOrDeleteRow?: (
    key: RightClickActionType,
    id: string,
    index: number
  ) => void;
  onWebFormConfigUpdate: (o: Array<WebFormConfigType>) => void;
};

type SelectedSeriesType = {
  type: "ROW" | "COLUMN";
  id: string;
  index: number;
};
