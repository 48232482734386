import React, { FC } from "react";

import { Button, Space } from "antd";
import { ManageInstitutions } from "../menu/institutions/ManageInstitutions";
import { NewTransactionPaginationType } from "./NewTransaction";
import { PETransactionCreationType } from "../../../utils/enums";

export const NewTransactionLender: FC<NewTransactionPaginationType> =
  function ({
    onNext,
    onBack,
    transactionId,
    transaction,
  }: NewTransactionPaginationType) {
    return transactionId ? (
      <div className={"flex flex-col h-full bg-gray-100"}>
        <div className={"h-full"}>
          <ManageInstitutions />
        </div>
        <Space className={"gap-2 justify-center"}>
          <Button
            type={"default"}
            className={
              "w-full mt-5 bg-white border hover:border-primary hover:text-primary"
            }
            onClick={onBack}
          >
            Go Back
          </Button>
          <Button
            className={`w-28 mt-5 w-full bg-primary hover:bg-hover text-white border-0`}
            onClick={onNext}
          >
            Next Step
          </Button>
        </Space>
      </div>
    ) : (
      <></>
    );
  };
