import {
  all,
  any,
  curry,
  defaultTo,
  find,
  groupBy,
  isEmpty,
  isNil,
  mergeAll,
  path,
  pathEq,
  pluck,
  prop,
  propEq,
  sort,
  sum,
  values,
} from "ramda";
import findIndex from "ramda/es/findIndex";
import { voidType } from "./uiTypes";

export const isNotNil: isNotNilType = (v) => !isNil(v);
export const valOrDefault: valOrDefaultType = (d, v) => (v ? v : d);
export const insertItemInArray: insertOrUpdateType = (i, a, v) => [
  ...a.slice(0, i),
  v,
  ...a.slice(i),
];
export const updateItemInArray: insertOrUpdateType = (i, a, v) => [
  ...a.slice(0, i),
  v,
  ...a.slice(i + 1),
];
export const removeItemFromArray: removeType = (i, arr) => [
  ...arr.slice(0, i),
  ...arr.slice(i + 1),
];
export const groupByProperty: groupByPropertyType = (arr, property) =>
  Object.entries(groupBy(prop(property))(arr)).map(([, value]) => value);

export const getPercentage = (a: number, b = 1): number =>
  defaultTo(0, Number(((a / b) * 100).toFixed(2)));

export const getDeltaPercentage = (a: number, b = 1): number =>
  a !== 0 && b === 0
    ? 100
    : defaultTo(0, Number((((a - b) / (b === 0 ? 1 : b)) * 100).toFixed(2)));

export const sumOfProp = curry((property: string, list: Array<any>) =>
  sum(pluck(property, list))
);
export const getObjectFromPropertyValue: getObjectFromPropertyValueType = (
  property,
  value,
  array
) => find(propEq(property, value))(array);

export const getObjectFromPropertyPathValue: getObjectFromPropertyPathValueType =
  (propertyPath, value, array) => find(pathEq(propertyPath, value))(array);

export const getOptionsByKey: getOptionsByKeyType = (
  selectedValues,
  options,
  key
) => options.filter((o) => selectedValues.indexOf(o[key]) >= 0);

export const populateArrayWithPropertyPath: arrayToArrayConversionType = (
  propertyPath,
  data
) => (data ? data.map((o) => path(propertyPath, o)) : []);

/** Map array of objects to single object by specified property **/
export const arrayToMapConversion: arrayToMapConversionType = (
  data = [],
  property,
  pathToObject = []
) =>
  mergeAll(
    data.map((o) => ({
      [o[property]]: pathToObject ? path(pathToObject, o) : o,
    }))
  );

/** Map array of objects to single object by specified nested property path key **/
export const arrayToMapConversionWithNestedPath: arrayToMapConversionWithPropertyPathType =
  (data = [], property, pathToObject = []) =>
    mergeAll(
      data.map((o) => ({
        [pathToObject ? path([...pathToObject, property], o) : o[property]]:
          pathToObject ? path(pathToObject, o) : o,
      }))
    );

/** Check if all items in sublist are contained in a list **/
export const containsAll = curry((sublist: Array<any>, list: Array<any>) =>
  all((val) => list.includes(val), sublist)
);

/** Check if at least one item in sublist are contained in a list **/
export const containsAny = curry((sublist: Array<any>, list: Array<any>) =>
  any((val) => list.includes(val), sublist)
);

export const filterOption: filterType = (a, b) =>
  valOrDefault("", b?.children.toString())
    .toLowerCase()
    ?.indexOf(valOrDefault("", a?.toLowerCase())) >= 0;

export const filterOptionWithTitle: filterType = (a, b) =>
  valOrDefault("", b?.title.toString())
    ?.toString()
    .toLowerCase()
    ?.indexOf(valOrDefault("", a?.toLowerCase())) >= 0;

export const filterSort: filterType = (a, b) =>
  a?.children
    ?.toString()
    ?.toLowerCase()
    ?.localeCompare(b?.children?.toString()?.toLowerCase());

export const findItemByPropertyValue: findItemByPropertyValue = (
  data,
  val,
  propertyPath
) => data.findIndex((o) => path(propertyPath, o) === val);

export const sortByPathToProperty: sortByPathToPropertyType = (
  propertyPath = [],
  arr = []
) =>
  sort(
    (a, b) => Number(path(propertyPath, a)) - Number(path(propertyPath, b)),
    arr
  );

export const filterByKeyAndVal: filterByKeyAndValType = (arr, key, val) =>
  arr.filter((item: Record<string, string>) => item[key] === val);

export const filterListOfObjectsByStringValue: filterListOfObjectsByStringValueType =
  (val, data) =>
    data.filter((item) =>
      any(
        (kv) => String(kv).toLowerCase().includes(val.toLowerCase()),
        values(item)
      )
    );

export const filterListOfObjectsByKeyPathAndStringValue: filterListOfObjectsByKeyPathAndStringValueType =
  (val, data, keyPath) => {
    return data.filter((item) =>
      String(path(keyPath, item)).toLowerCase().includes(val.toLowerCase())
    );
  };

export const getIndexByKeyVal: getIndexByKeyValType = (arr, key, val) =>
  arr.findIndex((item) => item[key] === val);

export const getIndexByKey: getIndexByKeyValType = (arr, key, val) =>
  findIndex(propEq(key, val), arr);

export const buildQueryParams: buildQueryParamsType = <T>(
  list: T[],
  paramKey: string,
  paramName: string,
  prefix = "",
  suffix = ""
) =>
  prefix +
  list
    .map((obj) => "&" + paramName + "=" + prop(paramKey, obj ?? {}))
    .join("") +
  suffix;

export const doNothing: voidType = () => {
  return;
};

export const removeMultipleSpaces: removeSpacesType = (val) =>
  defaultTo("", val)?.trim()?.replace(/\s\s+/g, " ");

/**
 * Checks if a file is a Word document based on its MIME type or file extension.
 *
 * @param {string} mimeType - The MIME type of the file.
 * @param {string} fileName - The name of the file.
 * @returns {boolean} - Returns true if the file is a Word document (either .doc or .docx), false otherwise.
 * @note This function supports both the MIME type and file extension checks.
 * The `mimeType` parameter should be a valid MIME type string, and the `fileName` parameter should include the file's extension.
 * Make sure to provide valid inputs for accurate results.
 */
const wordMimeTypes = [
  "application/msword", // .doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
];
const wordExtTypes = ["docx", "docs", "doc"];

export const isWordDocument: IsWordDocument = (fileName, mimeType) => {
  return mimeType
    ? wordMimeTypes.includes(mimeType)
    : wordExtTypes.includes(
        fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase()
      );
};

export const defaultIfEmptyOrNil: DefaultIfEmptyOrNil = (def, v) =>
  isEmpty(v) || isNil(v) ? def : v;

export const isEmptyOrNil = (value: any): value is undefined | null =>
  isEmpty(value) || isNil(value);

export const capitalizeText = (text: string): string => {
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
};

type isNotNilType = (value: any) => boolean;
type valOrDefaultType = (defaultValue: any, value: any) => any;
type insertOrUpdateType = (index: number, array: any[], value: any) => any[];
type removeType = (index: number, array: any[]) => any[];
type groupByPropertyType = (array: any[], property: string) => any[];
type getOptionsByKeyType = (
  selectedValues: any[],
  options: any[],
  key: string
) => any[];

type filterType = (a: any, b: any, property?: string) => any;

type arrayToMapConversionType = (
  data: any[],
  property: string,
  pathToObject?: string[]
) => any;

type arrayToMapConversionWithPropertyPathType = (
  data: any[],
  property: string,
  pathToObject?: string[]
) => any;
type arrayToArrayConversionType = (
  propertyPath: string[],
  data: any[] | undefined
) => any[];
type getObjectFromPropertyValueType = (
  property: string,
  value: any,
  array: any[]
) => any;
type getObjectFromPropertyPathValueType = (
  propertyPath: string[],
  value: any,
  array: any[]
) => any;
type findItemByPropertyValue = (
  data: any[],
  val: string,
  propertyPath: string[]
) => number;
type sortByPathToPropertyType = (
  path: (string | number)[],
  arr?: any[]
) => any[];
type filterByKeyAndValType = (arr: any[], key: string, val: string) => any[];
type getIndexByKeyValType = (arr: any[], key: string, val: string) => number;
type buildQueryParamsType = <T>(
  list: T[],
  paramKey: string,
  paramName: string,
  prefix?: string,
  suffix?: string
) => string;
type filterListOfObjectsByStringValueType = <T extends Record<any, any>>(
  val: string,
  data: T[]
) => T[];

type filterListOfObjectsByKeyPathAndStringValueType = (
  val: string,
  data: any[],
  keyPath: string[]
) => any[];

type removeSpacesType = (val: string) => string;

type IsWordDocument = (fileName: string, mimeType?: string) => boolean;
type DefaultIfEmptyOrNil = <T>(def: T, v?: T) => T;
