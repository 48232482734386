import React, { FC, ReactNode } from "react";
import { Button, Dropdown } from "antd";
import { RenderDOMFunc } from "rc-select/lib/BaseSelect";

export const COLORS = {
  gray: [
    "#000000",
    "#141414",
    "#262626",
    "#434343",
    "#595959",
    "#8c8c8c",
    "#bfbfbf",
    "#f0f0f0",
    "#ffffff",
  ],
  purple: [
    "#120338",
    "#22075e",
    "#391085",
    "#531dab",
    "#722ed1",
    "#9254de",
    "#b37feb",
    "#d3adf7",
    "#efdbff",
  ],
  blue: [
    "#030852",
    "#061178",
    "#10239e",
    "#1d39c4",
    "#2f54eb",
    "#597ef7",
    "#85a5ff",
    "#adc6ff",
    "#d6e4ff",
  ],
  cyan: [
    "#002329",
    "#00474f",
    "#006d75",
    "#08979c",
    "#13c2c2",
    "#36cfc9",
    "#5cdbd3",
    "#87e8de",
    "#b5f5ec",
  ],
  green: [
    "#092b00",
    "#135200",
    "#237804",
    "#389e0d",
    "#52c41a",
    "#73d13d",
    "#95de64",
    "#b7eb8f",
    "#d9f7be",
  ],
  red: [
    "#5c0011",
    "#820014",
    "#a8071a",
    "#cf1322",
    "#f5222d",
    "#ff4d4f",
    "#ff7875",
    "#ffa39e",
    "#ffccc7",
  ],
  magenta: [
    "#520339",
    "#780650",
    "#9e1068",
    "#c41d7f",
    "#eb2f96",
    "#f759ab",
    "#ff85c0",
    "#ffadd2",
    "#ffd6e7",
  ],
  orange: [
    "#612500",
    "#873800",
    "#ad4e00",
    "#d46b08",
    "#fa8c16",
    "#ffa940",
    "#ffc069",
    "#ffd591",
    "#ffe7ba",
  ],
  yellow: [
    "#614700",
    "#876800",
    "#ad8b00",
    "#d4b106",
    "#fadb14",
    "#ffec3d",
    "#fff566",
    "#fffb8f",
    "#ffffb8",
  ],
};

export const ColorPicker: FC<ColorPickerType> = function ({
  placement = "bottomRight",
  previewClassName = "w-6 h-2 border border-gray-200",
  buttonClassName = "border-none bg-transparent p-0",
  selected = "#000000",
  children,
  onSelect,
  disabled = false,
  getPopupContainer,
  trigger,
}: ColorPickerType) {
  const menu = (
    <div
      className={
        "shadow-xl grid grid-cols-9 bg-white border border-gray-200 p-1"
      }
    >
      {Object.values(COLORS).map((v, i) => {
        return (
          <div key={i} className={"flex flex-col flex-col-reverse"}>
            {v.map((o) => {
              return (
                <div
                  role="button"
                  key={o}
                  className={"p-px cursor-pointer"}
                  onClick={(e): void => {
                    e.preventDefault();
                    onSelect(o, e);
                  }}
                >
                  <div
                    className={
                      "w-5 h-5 border border-gray-200 hover:shadow-xl hover:border-blue-400"
                    }
                    style={{ backgroundColor: o }}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
  return (
    <Dropdown
      getPopupContainer={getPopupContainer}
      overlay={menu}
      placement={placement}
      disabled={disabled}
      trigger={trigger ?? ["click", "hover"]}
      forceRender={true}
    >
      <Button
        type={"text"}
        className={`${buttonClassName}`}
        disabled={disabled}
      >
        {children}
        <div
          className={previewClassName}
          style={{ backgroundColor: selected, color: selected }}
        ></div>
      </Button>
    </Dropdown>
  );
};
type ColorPickerType = {
  placement?:
    | "bottomLeft"
    | "bottomCenter"
    | "bottomRight"
    | "topLeft"
    | "topCenter"
    | "topRight";
  previewClassName?: string;
  buttonClassName?: string;
  selected?: string;
  children?: ReactNode;
  onSelect: (o: string, event: React.MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  getPopupContainer?: RenderDOMFunc;
  trigger?: ("click" | "hover")[];
};
