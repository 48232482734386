import React, { FC, ReactNode, useEffect, useState } from "react";
import { PortfolioCard } from "../PortfolioCard";
import { useParams } from "react-router";
import { Button, Drawer, message, Popover, Table } from "antd";
import { HistoryOutlined } from "@ant-design/icons";
import {
  getPortfolioFinancialsHistory,
  saveFinancials,
  updateFinancialFields,
} from "../../../../services/services";
import { ResponseType } from "../../../../utils/uiTypes";
import {
  FinanceHistoryType,
  PortfolioFinanceFieldValueType,
  PortfolioFinanceType,
  PortfolioSectionType,
  PortfolioTableFieldType,
  QueryResultType,
} from "../../../../utils/types";
import {
  find,
  findIndex,
  flatten,
  forEach,
  isEmpty,
  isNil,
  omit,
  pathOr,
  pipe,
  propEq,
  uniq,
} from "ramda";
import { HistoryItem } from "../financials/HistoryItem";
import { ColumnsType } from "antd/lib/table";
import { PermissionType, QuarterType } from "../../../../utils/enums";
import { FinanceField } from "../financials/FinanceField";
import { updateItemInArray } from "../../../../utils/utils";
import moment from "moment";
import {
  FinancialsFieldType,
  FinancialsType,
  getUnit,
  initiateFetchSection,
  makeTree,
} from "../../../../utils/portfolio";
import { PRIMARY_BUTTON_STYLE } from "../../../../utils/cssConfigs";
import { tableColumnHeader } from "../../../../utils/componentUtils";
import { EditFinancialSettings } from "../financials/EditFinancialSettings";
import { UnitSelector } from "../financials/UnitSelector";
import { PromptDialog } from "../../PromptDialog";
import {
  FullScreenButton,
  FullScreenWidget,
} from "../../../general/FullScreenWidget";
import {
  getContainerForFullScreen,
  getModalContainerForFullScreen,
} from "../../../../utils/container";
import { usePageTitle } from "../../../../customHooks/usePageTitle";

const QUARTERS = [
  QuarterType.Q1,
  QuarterType.Q2,
  QuarterType.Q3,
  QuarterType.Q4,
];

const getExpressionObject = (expression: string, touched = false) => ({
  Q1: {
    touched,
    expression,
  },
  Q2: {
    touched,
    expression,
  },
  Q3: {
    touched,
    expression,
  },
  Q4: {
    touched,
    expression,
  },
});

const defaultToZero = (v: PortfolioFinanceFieldValueType | undefined): string =>
  !isNil(v) && !isNil(v.value) ? v.value : "0";

const onCell = ({ formula }: FinancialsFieldType) => ({
  className: formula ? "bg-gray-50 hover:bg-gray-50" : "group-hover:bg-blue-50",
});

const getValuesList = (
  year: string,
  values: Array<PortfolioFinanceFieldValueType>
): Array<PortfolioFinanceFieldValueType> =>
  QUARTERS.map((o) => {
    const value = find<PortfolioFinanceFieldValueType>(propEq("quarter", o))(
      values
    );
    return value ? value : { year, quarter: o, value: "" };
  });

const getValue: getValueType = ({ expression, touched = false }) => {
  try {
    const evaluatedExpression = eval(expression);
    return isNaN(evaluatedExpression) ||
      !isFinite(evaluatedExpression) ||
      !touched
      ? ""
      : evaluatedExpression.toFixed(2);
  } catch (e) {
    console.error(e);
    return "";
  }
};

const evaluateExpression = (
  formula: string,
  values: Array<PortfolioFinanceFieldValueType>,
  data: Array<PortfolioTableFieldType>
) => {
  const parsedFormula: FormulaType = JSON.parse(formula);
  const expressionObject = getExpressionObject(parsedFormula.expression);
  parsedFormula?.variables.map((o) => {
    const obj = find<PortfolioTableFieldType>(propEq("tag", o))(data);
    forEach(
      (v: QuarterType) => {
        const value = find<PortfolioFinanceFieldValueType>(
          propEq("quarter", v)
        )(obj?.values ?? []);
        expressionObject[v] = {
          touched:
            isNil(value?.value) || isEmpty(value?.value)
              ? expressionObject[v].touched
              : true,
          expression: expressionObject[v].expression.replace(
            o,
            defaultToZero(value)
          ),
        };
      },
      [QuarterType.Q1, QuarterType.Q2, QuarterType.Q3, QuarterType.Q4]
    );
  });
  return values.map((o) => ({
    ...o,
    value: getValue(expressionObject[o.quarter]),
  }));
};

const parseFormula = ({ formula }: PortfolioTableFieldType) => {
  return formula ? JSON.parse(formula).variables : [];
};
const filterByFormula = (data: Array<PortfolioTableFieldType>) =>
  data.filter((o) => o.formula).map(parseFormula);

const calculateAutomaticFields = (
  year: string,
  data: Array<PortfolioTableFieldType> = []
) => {
  const affectedVariables = pipe(filterByFormula, flatten, uniq)(data);
  return data.map((o) => ({
    ...o,
    affected: affectedVariables.includes(o.tag),
    values:
      o.formula && !isEmpty(o.formula)
        ? evaluateExpression(o.formula, getValuesList(year, o.values), data)
        : o.values,
  }));
};

const getLatestHistoryItem = (
  {
    lastUpdated,
    user,
    field,
    quarter,
    year,
    previousValue,
    value,
    unit,
    fieldType,
  }: FinanceHistoryType,
  currency = ""
) => {
  return (
    <div>
      <span className={"font-bold"}>
        {moment(lastUpdated).format("ll")}:&nbsp;
      </span>
      <>
        <span className={"font-medium"}>{user}</span> changed {field} for{" "}
        {quarter} {year} from{" "}
        <span className={"font-medium"}>
          {previousValue
            ? `${previousValue} ${getUnit(unit)} ${
                fieldType === "MONETARY" ? currency : ""
              }`
            : "N/A"}
        </span>
        &nbsp;to&nbsp;
        <span className={"font-medium"}>
          {value
            ? `${value} ${getUnit(unit)} ${
                fieldType === "MONETARY" ? currency : ""
              }`
            : "N/A"}
        </span>
      </>
    </div>
  );
};
export const Financials: FC<FinancialsCompType> = ({
  permissions,
  portfolioId,
}) => {
  usePageTitle("Financials");

  const { sectionId }: ParamsType = useParams();
  const [section, setSection] = useState<PortfolioSectionType | null>(null);
  const [loading, setLoading] = useState<Loading>({
    records: true,
    saving: false,
  });
  const [touched, setTouched] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [history, setHistory] = useState<Array<FinanceHistoryType>>([]);
  const [financials, setFinancials] = useState<FinancialsType | null>(null);

  const toggleCalendarYear = (): ReactNode => {
    return financials ? (
      <div className={"flex flex-row gap-2 items-center justify-center w-full"}>
        <i
          className="fa-solid fa-chevron-left hover:text-primary text-md cursor-pointer"
          onClick={(): void => {
            setLoading((v) => ({ ...v, records: true }));
            fetchSection(sectionId, parseInt(financials.year) - 1);
          }}
        />
        <span className={"text-lg font-semibold"}>CY {financials?.year}</span>
        <i
          className="fa-solid fa-chevron-right hover:text-primary text-md cursor-pointer"
          onClick={(): void => {
            setLoading((v) => ({ ...v, records: true }));
            fetchSection(sectionId, parseInt(financials.year) + 1);
          }}
        />
      </div>
    ) : (
      <></>
    );
  };
  const getRenderMethod: getRenderMethodType =
    // eslint-disable-next-line react/display-name
    (quarter: QuarterType) => (value, record) => {
      return (
        <FinanceField
          year={financials?.year}
          type={record.fieldType}
          record={record}
          quarter={quarter}
          unit={record.unit}
          editable={permissions.includes(PermissionType.ADMIN_PORTFOLIO)}
          currency={section?.reportingCurrency ?? ""}
          onSave={(v): void => {
            setTouched(true);
            const index = findIndex(
              propEq("id", v.id),
              financials?.fields ?? []
            );
            setFinancials((financials) =>
              financials
                ? {
                    ...financials,
                    fields: v.affected
                      ? calculateAutomaticFields(
                          financials.year,
                          updateItemInArray(index, financials.fields, v)
                        )
                      : updateItemInArray(index, financials.fields, v),
                  }
                : null
            );
          }}
        />
      );
    };

  const columns: ColumnsType<PortfolioTableFieldType> = [
    {
      title: tableColumnHeader(toggleCalendarYear()),
      dataIndex: "label",
      key: "id",
      className: "group-hover:bg-blue-50 min-w-[20%]",
      width: "fit-content",
      render: (value, { rootFieldId, label, formula }): ReactNode => {
        const parsedFormula: FormulaType = JSON.parse(formula ?? "{}");
        return (
          <div
            className={`${
              isNil(rootFieldId) ? "font-medium" : "font-normal"
            } flex flex-row items-center justify-between`}
          >
            {label}
            {formula && (
              <Popover
                getPopupContainer={getContainerForFullScreen}
                title={"Required Fields For Calculation"}
                content={
                  <>
                    {parsedFormula.variables.map((val) => (
                      <div key={val}>
                        {
                          find<FinancialsFieldType>(propEq("tag", val))(
                            financials?.fields ?? []
                          )?.label
                        }
                      </div>
                    ))}
                  </>
                }
              >
                <i className="fa-solid fa-circle-info text-gray-400 cursor-pointer" />
              </Popover>
            )}
          </div>
        );
      },
    },
    {
      title: tableColumnHeader("Q1", "text-base"),
      key: "id",
      onCell,
      render: getRenderMethod(QuarterType.Q1),
    },
    {
      title: tableColumnHeader("Q2", "text-base"),
      key: "id",
      onCell,
      render: getRenderMethod(QuarterType.Q2),
    },
    {
      title: tableColumnHeader("Q3", "text-base"),
      key: "id",
      onCell,
      render: getRenderMethod(QuarterType.Q3),
    },
    {
      title: tableColumnHeader("Q4", "text-base"),
      key: "id",
      onCell,
      render: getRenderMethod(QuarterType.Q4),
    },
  ];

  const onClose = (): void => {
    setDrawer(false);
  };

  const updateFields = (fields: Array<PortfolioTableFieldType>): void => {
    setLoading((v) => ({ ...v, records: true }));
    updateFinancialFields({
      segments: {
        portfolioId,
        sectionId,
      },
      body: JSON.stringify(fields),
    })
      .then(() => {
        setFinancials((v) =>
          v
            ? {
                ...v,
                fields,
              }
            : null
        );
      })
      .catch(() => {
        message.error("Sorry, something went wrong! Please try again later.");
      })
      .then(() => {
        setLoading((v) => ({ ...v, records: false }));
      });
  };

  const onSave = (): void => {
    setLoading((loading) => ({ ...loading, saving: true }));
    saveFinancials({
      segments: {
        portfolioId,
        sectionId,
        year: financials?.year,
      },
      body: JSON.stringify({
        ...financials,
        fields: financials?.fields?.map(omit("affected")),
      }),
    })
      .then(({ data }: ResponseType<PortfolioFinanceType>) => {
        getHistory();
        setFinancials(() => ({
          ...data,
          fields: calculateAutomaticFields(data.year, data.fields),
        }));
        setTouched(false);
        message.success("Table saved successfully");
      })
      .catch(() => {
        //message cannot load history
      })
      .then(() => {
        setLoading((v) => ({ ...v, records: false, saving: false }));
      });
  };
  const getHistory = (): void => {
    getPortfolioFinancialsHistory({
      segments: {
        portfolioId,
        sectionId,
      },
    })
      .then(({ data }: ResponseType<QueryResultType<FinanceHistoryType>>) => {
        setHistory(pathOr([], ["data"], data));
      })
      .catch(() => {
        //message cannot load history
      })
      .then(() => {
        setLoading((v) => ({ ...v, history: false }));
      });
  };

  const fetchSection = (sectionId: string, year: string | number): void => {
    initiateFetchSection(sectionId, portfolioId, year)
      .then((section) => {
        setSection(section);
        setFinancials(() => ({
          ...section.portfolioFinanceDTO,
          fields: calculateAutomaticFields(
            year?.toString() ?? "",
            section?.portfolioFinanceDTO?.fields ?? []
          ),
        }));
      })
      .catch((e: string) => {
        message.error(e);
      })
      .then(() => setLoading((v) => ({ ...v, records: false })));
  };

  useEffect(() => {
    getHistory();
    fetchSection(sectionId, moment().year());
  }, [sectionId]);

  return (
    <>
      <PromptDialog block={touched} />
      <PortfolioCard
        permissions={permissions}
        title={"Financials"}
        editMode={editMode}
        onClick={(): void => setEditMode((v) => !v)}
      >
        <FullScreenWidget>
          {(isFullScreen, trigger) =>
            editMode ? (
              <EditFinancialSettings
                portfolioId={portfolioId}
                sectionId={sectionId}
                data={financials?.fields ?? []}
                onSave={(): void => {
                  setEditMode(false);
                  fetchSection(sectionId, financials?.year ?? moment().year());
                }}
              />
            ) : (
              <div
                className={`h-full flex flex-col gap-y-4 ${
                  isFullScreen &&
                  "bg-gray-50 h-screen w-screen overflow-y-scroll p-4 pb-1"
                } `}
              >
                {financials && (
                  <>
                    {(permissions.includes(PermissionType.ADMIN_PORTFOLIO) ||
                      section?.reportingCurrency) && (
                      <div
                        className={
                          "flex flex-row items-center justify-end gap-2"
                        }
                      >
                        {section?.reportingCurrency && (
                          <div
                            className={
                              "text-gray-700 rounded-sm border border-[#D9D9D9] p-2 py-1"
                            }
                          >
                            {section?.reportingCurrency}
                          </div>
                        )}
                        {permissions.includes(
                          PermissionType.ADMIN_PORTFOLIO
                        ) && (
                          <>
                            <UnitSelector
                              value={pathOr(
                                "Unit",
                                ["unit"],
                                find(propEq("fieldType", "MONETARY"))(
                                  financials?.fields ?? []
                                ) as PortfolioTableFieldType
                              )}
                              onSelect={(unit): void => {
                                updateFields(
                                  financials?.fields?.map((o) =>
                                    o.fieldType === "MONETARY"
                                      ? {
                                          ...o,
                                          unit,
                                        }
                                      : o
                                  )
                                );
                              }}
                            />
                            <Button
                              onClick={onSave}
                              loading={loading.saving}
                              className={PRIMARY_BUTTON_STYLE}
                            >
                              Save
                            </Button>
                            <FullScreenButton
                              isFullScreen={isFullScreen}
                              trigger={trigger}
                            />
                          </>
                        )}
                      </div>
                    )}
                    <div className={"overflow-y-auto max-h-full border-b"}>
                      <Table
                        sticky={true}
                        className={"transition duration-300 ease-out transform"}
                        rowClassName={"group last:border-b-0"}
                        size={"small"}
                        bordered={true}
                        loading={loading.records}
                        expandable={{
                          fixed: "right",
                          defaultExpandAllRows: true,
                          childrenColumnName: "nestedFields",
                        }}
                        scroll={{ x: "max-content" }}
                        pagination={false}
                        rowKey={(record): string => record.id}
                        columns={columns}
                        dataSource={makeTree(
                          financials.fields ?? [],
                          undefined
                        )}
                      />
                    </div>
                  </>
                )}
                <div className={"flex items-center justify-end w-full mt-auto"}>
                  {history.length > 0 &&
                    getLatestHistoryItem(
                      history[0],
                      section?.reportingCurrency
                    )}
                  <Button
                    type={"link"}
                    icon={<HistoryOutlined />}
                    onClick={(): void => {
                      setDrawer(true);
                      getHistory();
                    }}
                  >
                    Browse Edit History
                  </Button>
                </div>
              </div>
            )
          }
        </FullScreenWidget>
      </PortfolioCard>

      <Drawer
        getContainer={getModalContainerForFullScreen}
        title={"Edit History"}
        destroyOnClose
        width={450}
        visible={drawer}
        onClose={onClose}
      >
        <div
          className={
            "flex flex-col gap-2 w-full h-full overflow-y-auto hide-scrollbar"
          }
        >
          {history.map((o, i) => {
            return (
              <HistoryItem
                key={i}
                {...o}
                currency={section?.reportingCurrency}
              />
            );
          })}
        </div>
      </Drawer>
    </>
  );
};

type FormulaType = {
  variables: Array<string>;
  expression: string;
};
type getRenderMethodType = (
  quarter: QuarterType
) => (value: any, record: FinancialsFieldType, index: number) => ReactNode;
type ParamsType = {
  portfolioId: string;
  sectionId: string;
};
type Loading = {
  records: boolean;
  saving: boolean;
};
type FinancialsCompType = {
  permissions: PermissionType[];
  portfolioId: string;
};
type ExpressionObject = {
  expression: string;
  touched?: boolean;
};
type getValueType = (expressionObject: ExpressionObject) => string;
