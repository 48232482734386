import { RefObject, useEffect, useState } from "react";

const getWindowDimensions: getWindowDimensionsType = (ref) => {
  const { innerWidth, innerHeight } = window;
  return {
    width: ref?.current?.clientHeight ?? innerWidth,
    height: ref?.current?.clientHeight ?? innerHeight,
  };
};

export default function useWindowDimensions(): WindowType {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize: () => void = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", handleResize);
    return (): void => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}

type WindowType = {
  width: number;
  height: number;
};
type getWindowDimensionsType = (ref?: RefObject<HTMLDivElement>) => WindowType;
