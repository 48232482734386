import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { Button, Input, message, Select, Table, Tooltip } from "antd";
import {
  deleteCompany,
  changeCompanyType,
  getAllCompaniesAndTeamMembers,
  getAllCompanyModulePreference,
  getApplicationModuleLevels,
  getInstitutions,
  renameCompany,
  resetLoginCount,
  updateAdmin2FA,
  updateCompanyModule,
  updateTeamMemberTransactionRights,
} from "../../services/services";
import {
  CompanyModuleAccessType,
  CompanyModuleType,
  CompanyType,
  ModuleAccessLevelType,
  UserType,
} from "../../utils/types";
import { fetchWithIdType, ResponseType, voidType } from "../../utils/uiTypes";
import { ColumnsType } from "antd/es/table";
import { CompanyModuleModal } from "../modals/CompanyModuleModal";
import { ViewTeamModal } from "../modals/ViewTeamModal";
import {
  ACTION_BUTTON_CSS,
  PRIMARY_BUTTON_STYLE,
  tableSelectCss,
} from "../../utils/cssConfigs";
import {
  any,
  equals,
  findIndex,
  isEmpty,
  join,
  path,
  pathOr,
  pluck,
  propEq,
  remove,
  update,
} from "ramda";
import useWindowDimensions from "../../customHooks/useWindowDimensions";
import { PermissionsModal } from "../modals/PermissionsModal";
import { ConfirmDelete } from "../../utils/confirmationModals";
import {
  AccessLevelType,
  CompanyEnumType,
  ModuleType,
  PETransactionCreationType,
} from "../../utils/enums";
import { TextOverFlowHandle } from "../general/TextOverFlowHandle";
import { EditOutlined } from "@ant-design/icons";
import {
  tableColumnHeader,
  tableSelectSuffix,
} from "../../utils/componentUtils";
import { updateItemInArray } from "../../utils/utils";
import { useDebounce } from "../../customHooks/useDebounce";

export const ModuleAndCreateTx: FC = () => {
  usePageTitle("Module And Create Tx");
  const { height: windowHeight } = useWindowDimensions();

  const [companyAdmin, setCompanyAdmin] = useState<CompanyType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [companySearchInput, setCompanySearchInput] = useState<string>("");
  const [emailSearchInput, setEmailSearchInput] = useState<string>("");

  const debouncedCompanySearch = useDebounce(companySearchInput, 300);
  const debouncedEmailSearch = useDebounce(emailSearchInput, 300);

  const [companyAndTeam, setCompanyAndTeam] = useState<
    Record<string, UserType[]>
  >({});
  const [companyModulePreference, setCompanyModulePreference] = useState<
    Record<string, CompanyModuleAccessType>
  >({});
  const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null);
  const [renameIndex, setRenameIndex] = useState<null | number>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const initiateCompanyRename: initiateCompanyRenameType = (id, name) => {
    message.loading({ key: "rename", content: "Renaming Company" });
    const index = findIndex(propEq("id", id), companyAdmin);
    renameCompany({ segments: { id }, body: name })
      .then(() => {
        setRenameIndex(null);
        setCompanyAdmin((prev) =>
          update(index, { ...prev[index], name }, prev)
        );
        message.success({
          key: "rename",
          content: "Name Changed Successfully",
        });
      })
      .catch((error: string) => {
        message.error({
          key: "rename",
          content: error ?? "Unable to Rename Company",
        });
      });
  };

  const initiateCompanyDelete: fetchWithIdType = (id: string) => {
    ConfirmDelete(
      "Are you sure you want to delete this company? This will be irreversible and you will lose all the data for this company including any related transaction and team members. Furthermore, this will also not show up in your analytics activity reports.",
      () => {
        message.loading({ key: "delete", content: "Deleting Company" });
        const index = findIndex(propEq("id", id), companyAdmin);
        deleteCompany({ segments: { id } })
          .then(() => {
            message.success({
              key: "delete",
              content: "Company Deleted Successfully",
            });
            setCompanyAdmin((prev) => remove(index, 1, prev));
          })
          .catch((error: string) => {
            message.error({
              key: "delete",
              content: error ?? "Unable to Delete Company",
            });
          });
      }
    );
  };

  const [defaultModuleSettings, setDefaultModuleSettings] = useState<Record<
    AccessLevelType,
    CompanyModuleType
  > | null>(null);

  const [moduleSettings, setModuleSettings] = useState<Record<
    AccessLevelType,
    CompanyModuleType
  > | null>(null);

  const onChangeCompanyType = (id: string) => (type: string) => {
    changeCompanyType({
      segments: { id, type },
    })
      .then(() => {
        const index = findIndex(propEq("id", id), companyAdmin);
        setCompanyAdmin((prev) =>
          updateItemInArray(index, prev, {
            ...prev.at(index),
            companyType: type,
          })
        );
        if (type === CompanyEnumType.LENDER && moduleSettings) {
          setCompanyModulePreference({
            ...companyModulePreference,
            [id]: {
              ...companyModulePreference[id],
              accessLevel: AccessLevelType.LENDER_DEFAULT,
              moduleAccessDTO: moduleSettings?.LENDER_DEFAULT,
            },
          });
        }
      })
      .catch(message.error);
  };

  const columns: ColumnsType<CompanyType> = [
    {
      title: tableColumnHeader("Company Name"),
      dataIndex: "name",
      ellipsis: true,
      className: "bg-transparent",
      width: "20%",
      render: (name, record, index): ReactNode =>
        equals(renameIndex, index) ? (
          <Input.Search
            defaultValue={name}
            enterButton={<Button icon={<EditOutlined />} />}
            onSearch={(name): void => initiateCompanyRename(record.id, name)}
          />
        ) : (
          <TextOverFlowHandle text={name} />
        ),
    },
    {
      title: tableColumnHeader("Industry"),
      ellipsis: true,
      className: "bg-transparent",
      render: (text, { industries = [] }): ReactNode => {
        const value = join(", ", pluck("name", industries));
        return (
          <Tooltip placement="topLeft" title={value}>
            <div className="empty:text-muted empty:after:content-['-'] truncate overflow-ellipsis">
              {value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Type",
      ellipsis: true,
      dataIndex: "companyType",
      className: "bg-transparent",
      render: (type: CompanyEnumType, { id }, index) => {
        return (
          <Select
            dropdownMatchSelectWidth={80}
            bordered={false}
            className={tableSelectCss()}
            placeholder={"Type"}
            suffixIcon={tableSelectSuffix()}
            options={Object.keys(CompanyEnumType).map((value) => ({
              value,
              label: (
                <span className={"capitalize"}>
                  {value.replaceAll("_", " ").toLowerCase()}
                </span>
              ),
              key: value,
            }))}
            value={type}
            onSelect={onChangeCompanyType(id)}
          />
        );
      },
    },
    {
      title: tableColumnHeader("Team"),
      className: "bg-transparent",
      align: "center",
      render: (link, record): ReactNode => {
        return (
          <Button
            onClick={(): void => {
              setModalConfig({
                type: ModuleConfigType.TEAM,
                companyName: record.name,
                id: record.id,
                companyType: record?.companyType,
              });
            }}
            className={PRIMARY_BUTTON_STYLE}
          >
            View Team
          </Button>
        );
      },
    },
    {
      title: tableColumnHeader("Team Permissions"),
      className: "bg-transparent",
      align: "center",
      render: (link, record): ReactNode => {
        return (
          <Button
            onClick={(): void => {
              setModalConfig({
                type: ModuleConfigType.PERMISSIONS,
                companyName: record.name,
                id: record.id,
                companyType: record?.companyType,
              });
            }}
            className={PRIMARY_BUTTON_STYLE}
          >
            Edit Permissions
          </Button>
        );
      },
    },
    {
      title: tableColumnHeader("Company Modules"),
      align: "center",
      className: "bg-transparent",
      render: (link, record): ReactNode => {
        return (
          <Button
            onClick={(): void => {
              setModalConfig({
                type: ModuleConfigType.MODULE,
                companyName: record.name,
                id: record.id,
                companyType: record?.companyType,
              });
            }}
            className={PRIMARY_BUTTON_STYLE}
          >
            View / Edit Module
          </Button>
        );
      },
    },
    {
      title: tableColumnHeader("Actions"),
      dataIndex: "",
      key: "",
      align: "right",
      className: "group-hover:bg-blue-50",
      render: (_, company, index): ReactNode => {
        return (
          <span
            className={
              "relative flex flex-row flex-row-reverse items-center gap-x-3"
            }
            onClick={(event): void => event.stopPropagation()}
          >
            <Button
              type={"text"}
              icon={<i className="fas fa-ellipsis-h" />}
              className={
                "absolute right-0 border-0 flex items-center px-2 text-gray-900 block opacity-50 group-hover:opacity-0"
              }
            />
            <Button
              type={"text"}
              icon={<i className="fas fa-trash-alt" />}
              className={`${ACTION_BUTTON_CSS} hover:text-primary opacity-0 group-hover:opacity-100`}
              onClick={(): void => {
                initiateCompanyDelete(company.id);
              }}
            />
            <Button
              onClick={(): void => {
                setRenameIndex(index);
              }}
              disabled={equals(renameIndex, index)}
              type={"text"}
              icon={<i className="fas fa-pencil-alt" />}
              className={`${ACTION_BUTTON_CSS}  hover:text-primary opacity-0 group-hover:opacity-100`}
            />
          </span>
        );
      },
    },
  ];

  const onModalClose: voidType = () => {
    setModalConfig(null);
  };

  const updateCompanyModulePreference: updateCompanyModulePreferenceType =
    (id: string) => (modules) => {
      return new Promise((resolve, reject) => {
        updateCompanyModule({
          body: JSON.stringify({
            ...modules,
            companyId: id,
          }),
        })
          .then(({ data }: ResponseType<CompanyModuleAccessType>) => {
            setCompanyModulePreference((v) => ({
              ...v,
              [data.companyId]: data,
            }));
            onModalClose();
            message.success("Module successfully updated");
            resolve();
          })
          .catch((error: string) => {
            message.error(error ?? "Try again later!");
            reject();
          });
      });
    };

  const updateCompanyTeamMember = (
    team: UserType[],
    email: string,
    value: Partial<UserType>
  ): UserType[] => {
    const index = findIndex(propEq("email", email), team);
    if (index >= 0) {
      return updateItemInArray(index, team, {
        ...team[index],
        ...value,
      }) as UserType[];
    } else return team;
  };

  const updateTransactionRights: updateTransactionRightsType =
    (companyId) => (email, peTransactionCreationType, status) => {
      updateTeamMemberTransactionRights({
        body: JSON.stringify({
          email,
          status,
          peTransactionCreationType,
        }),
      })
        .then(() => {
          message.success("Transaction rights successfully updated");
          setCompanyAndTeam((team) => ({
            ...team,
            [companyId]: updateCompanyTeamMember(team[companyId], email, {
              [equals(
                peTransactionCreationType,
                PETransactionCreationType.ONLINE
              )
                ? "createTransaction"
                : "createOfflineTransaction"]: status,
            }),
          }));
        })
        .catch((error: string) => {
          message.error(error ?? "Try again later!");
        });
    };

  const getCompanyForAdminInfo: voidType = () => {
    getInstitutions({})
      .then(({ data }: ResponseType<CompanyType[]>) => {
        setLoading(false);
        setCompanyAdmin(data);
      })
      .catch((error: string) => {
        message.error(error ?? "Try again later!");
        setLoading(false);
      });
  };

  const getAllCompanyModulePreferenceInfo: voidType = () => {
    getAllCompanyModulePreference({})
      .then(
        ({ data }: ResponseType<Record<string, CompanyModuleAccessType>>) => {
          setCompanyModulePreference(data);
        }
      )
      .catch((error: string) => {
        message.error(error ?? "Try again later!");
      });
  };

  const resetLogin: resetLoginType = (companyId) => (emailId) => {
    resetLoginCount({
      body: emailId,
    })
      .then(() => {
        message.success("Login count successfully updated");
        setCompanyAndTeam((team) => ({
          ...team,
          [companyId]: updateCompanyTeamMember(team[companyId], emailId, {
            failedLoginCount: 0,
          }),
        }));
      })
      .catch((error: string) => {
        message.error(error ?? "Try again later!");
      });
  };

  const getAllCompanyAndTeamInfo: voidType = () => {
    getAllCompaniesAndTeamMembers({})
      .then(({ data }: ResponseType) => {
        setCompanyAndTeam(data);
      })
      .catch((error: string) => {
        message.error(error ?? "Try again later!");
      });
  };

  const initiateUpdate2FA: initiateUpdate2FAType =
    (modalId) => (userId) => (value) => {
      const index = findIndex<UserType>(
        propEq("userId", userId),
        path<UserType[]>([modalId], companyAndTeam) ?? []
      );
      updateAdmin2FA({
        segments: {
          userId,
          type: value ? "enable" : "disable",
        },
      }).then(() => {
        if (index >= 0)
          setCompanyAndTeam((team) => ({
            ...team,
            [modalId]: updateCompanyTeamMember(
              team[modalId],
              team[modalId][index].email,
              {
                sms2FaEnabled: value,
              }
            ),
          }));
      });
    };

  useEffect(() => {
    getCompanyForAdminInfo();
    getAllCompanyModulePreferenceInfo();
    getAllCompanyAndTeamInfo();
  }, []);

  useEffect(() => {
    getApplicationModuleLevels({})
      .then(({ data }: ResponseType<ModuleAccessLevelType[]>) => {
        setModuleSettings(
          data.reduce<Record<string, CompanyModuleType>>(
            (previousValue, currentValue) => ({
              ...previousValue,
              [currentValue.level]: currentValue.config,
            }),
            {}
          )
        );
      })
      .catch(console.error);
  }, []);

  // Memoize filtered companies using the debounced search values.
  const filteredCompanies = useMemo(() => {
    return companyAdmin.filter((company) => {
      const matchesCompanyName = company.name
        .toLowerCase()
        .includes(debouncedCompanySearch.toLowerCase());
      const teamMembers = companyAndTeam[company.id] || [];
      const matchesEmail =
        isEmpty(debouncedEmailSearch) ||
        teamMembers.some((user) =>
          user.email.toLowerCase().includes(debouncedEmailSearch.toLowerCase())
        );
      return matchesCompanyName && matchesEmail;
    });
  }, [
    companyAdmin,
    companyAndTeam,
    debouncedCompanySearch,
    debouncedEmailSearch,
  ]);

  const paginatedCompanies = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    return filteredCompanies.slice(startIndex, startIndex + pageSize);
  }, [filteredCompanies, currentPage, pageSize]);

  return (
    <div>
      <div className="p-6">
        <div className="flex justify-between">
          <h1
            className={`${
              loading ? "text-muted text-3xl mb-2" : "text-3xl mb-2"
            }`}
          >
            Admin Dashboard
          </h1>
          <div className={"flex items-center gap-4"}>
            <Input.Search
              className="w-56"
              disabled={loading}
              placeholder="Search by User email"
              value={emailSearchInput}
              onChange={(e): void => {
                setEmailSearchInput(e.target.value);
                setCurrentPage(1);
              }}
            />
            <Input.Search
              className="w-56"
              disabled={loading}
              placeholder="Search by company name"
              value={companySearchInput}
              onChange={(e): void => {
                setCompanySearchInput(e.target.value);
                setCurrentPage(1);
              }}
            />
          </div>
        </div>

        <Table
          dataSource={paginatedCompanies}
          columns={columns}
          loading={loading}
          rowKey={(record) => record?.id}
          scroll={{ x: 1000, y: windowHeight - 250 }}
          rowClassName="group hover:bg-blue-50 group"
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: filteredCompanies.length,
            onChange: (page, newPageSize) => {
              setPageSize(newPageSize);
              setCurrentPage(page);
            },
            className: "flex justify-center",
          }}
        />

        {modalConfig &&
          moduleSettings &&
          modalConfig.type === ModuleConfigType.MODULE && (
            <CompanyModuleModal
              companyType={modalConfig.companyType}
              onClose={onModalClose}
              onSave={updateCompanyModulePreference(modalConfig.id)}
              info={companyModulePreference[modalConfig.id]}
              companyName={modalConfig.companyName ?? ""}
              moduleSettings={moduleSettings}
            />
          )}
        {equals(modalConfig?.type, ModuleConfigType.TEAM) &&
          modalConfig?.id && (
            <ViewTeamModal
              onClose={onModalClose}
              info={companyAndTeam[modalConfig.id].filter(({ email }) =>
                email.toLowerCase().includes(debouncedEmailSearch.toLowerCase())
              )}
              onSave={updateTransactionRights(modalConfig.id)}
              onReset={resetLogin(modalConfig.id)}
              change2FA={initiateUpdate2FA(modalConfig.id)}
              companyName={modalConfig.companyName ?? ""}
              hasOnlineTransaction={pathOr(
                false,
                [modalConfig.id, "moduleAccessDTO", ModuleType.TRANSACTION],
                companyModulePreference
              )}
              hasOfflineTransaction={pathOr(
                false,
                [
                  modalConfig.id,
                  "moduleAccessDTO",
                  ModuleType.OFFLINE_TRANSACTION,
                ],
                companyModulePreference
              )}
            />
          )}
        {equals(modalConfig?.type, ModuleConfigType.PERMISSIONS) &&
          modalConfig?.id && (
            <PermissionsModal
              id={modalConfig.id}
              modules={companyModulePreference[modalConfig.id]?.moduleAccessDTO}
              onClose={onModalClose}
              searchValue={debouncedEmailSearch}
              companyName={modalConfig.companyName ?? ""}
            />
          )}
      </div>
    </div>
  );
};

type updateCompanyModulePreferenceType = (
  id: string
) => (modules: CompanyModuleAccessType) => Promise<void>;
type updateTransactionRightsType = (
  companyId: string
) => (
  emailId: string,
  type: PETransactionCreationType,
  status: boolean | undefined
) => void;

type resetLoginType = (companyId: string) => (emailId: string) => void;
type ModalConfig = {
  type: ModuleConfigType;
  id: string;
  companyName?: string;
  companyType?: CompanyEnumType;
};
enum ModuleConfigType {
  MODULE = "MODULE",
  TEAM = "TEAM",
  PERMISSIONS = "PERMISSIONS",
}

type initiateUpdate2FAType = (
  modalId: string
) => (userId: string, index: number) => (val: boolean) => void;
type initiateCompanyRenameType = (id: string, name: string) => void;
