import { Button, Form, Input, message, Modal, Select } from "antd";
import React, { FC, useContext, useEffect, useState } from "react";
import { TagType } from "../../../utils/types";
import { ResponseType, voidType } from "../../../utils/uiTypes";
import FormItem from "antd/es/form/FormItem";
import { ColorPicker } from "../../general/ColorPicker";
import {
  getContainerForFullScreen,
  getModalContainerForFullScreen,
  getPopupContainerForSelect,
} from "../../../utils/container";
import { useForm, useWatch } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import {
  createCompanyTag,
  updateCompanyTag,
  getSmartActions,
} from "../../../services/services";
import { UserContext } from "../../../context/UserContext";
import { formItemRequiredRule } from "../../../utils/formUtils";

export const TagModal: FC<TagModalType> = ({ tag, onClose, onSubmit }) => {
  const { user } = useContext(UserContext);

  const [form] = useForm<TagType>();
  const tagColor = useWatch("color", form);
  const [loading, setLoading] = useState(false);
  const [smartActions, setSmartActions] = useState<smartActionsType[]>([]);
  const fetchSmartActions = () => {
    setLoading(true);
    getSmartActions({})
      .then(({ data = [] }: ResponseType<smartActionsType[]>) => {
        setLoading(false);
        setSmartActions(data);
      })
      .catch((error: string) => {
        setLoading(false);
        message.error(error);
      });
  };

  const createTag = (tagDTO: TagType): void => {
    createCompanyTag({
      body: JSON.stringify({ ...tagDTO, companyId: user?.companyId }),
    })
      .then(({ data }: ResponseType<TagType>) => {
        onSubmit(data);
        setLoading(false);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(error);
        setLoading(false);
      });
  };

  const updateTag = (tagDTO: TagType): void => {
    updateCompanyTag({
      segments: { id: tagDTO.id },
      body: JSON.stringify({ ...tagDTO, companyId: user?.companyId }),
    })
      .then(({ data }: ResponseType<TagType>) => {
        onSubmit(data);
        setLoading(false);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchSmartActions();
  }, []);
  return (
    <>
      <Modal
        open={true}
        destroyOnClose={true}
        onCancel={onClose}
        getContainer={getModalContainerForFullScreen}
        confirmLoading={loading}
        title={tag ? "Update Tag" : "Create Tag"}
        footer={
          <div className={"flex items-center justify-end gap-x-2"}>
            <Button disabled={loading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                form.submit();
              }}
              className={PRIMARY_BUTTON_STYLE}
            >
              {tag ? "Update" : "Create"}
            </Button>
          </div>
        }
      >
        <Form<TagType>
          form={form}
          onFinish={(values) => {
            setLoading(true);
            tag ? updateTag({ ...tag, ...values }) : createTag(values);
          }}
          initialValues={tag}
          layout={"vertical"}
        >
          <div className={"flex items-start w-full gap-x-2"}>
            <FormItem
              rules={[formItemRequiredRule]}
              name={"label"}
              label={"Label"}
              className={"w-full"}
            >
              <Input
                disabled={loading}
                placeholder={"Tag Label"}
                className={"w-full"}
              />
            </FormItem>
            <FormItem
              rules={[formItemRequiredRule]}
              name={"color"}
              label={"Color"}
              shouldUpdate={true}
            >
              <ColorPicker
                getPopupContainer={getContainerForFullScreen}
                disabled={loading}
                trigger={["click"]}
                selected={tagColor}
                placement={"topRight"}
                onSelect={(color) => form.setFieldValue("color", color)}
                previewClassName={
                  "py-4 px-8 content-['Pick_Color'] rounded shadow border-white border"
                }
              />
            </FormItem>
          </div>
          <FormItem name={"description"} label={"Description"}>
            <TextArea disabled={loading} placeholder={"Tag Description"} />
          </FormItem>
          <FormItem name={"smartActions"} label={"Smart Actions"}>
            <Select
              getPopupContainer={getPopupContainerForSelect}
              disabled={loading}
              mode={"multiple"}
              placeholder={"Select Smart Actions"}
              options={smartActions?.map((action: smartActionsType) => ({
                value: action.smartTagActionEnum,
                label: action.label,
              }))}
            />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

type TagModalType = {
  tag?: TagType;
  onClose: voidType;
  onSubmit: (tag: TagType) => void;
};

type smartActionsType = {
  smartTagActionEnum: string;
  label: string;
};
