import { InvitationStatusType } from "./enums";
import React, { FC, Key, ReactNode } from "react";
import { Badge, message, Select, Spin, Tag } from "antd";
import { getColorPallet } from "./colors";
import { resendInvite } from "../services/services";
import { LoadingOutlined } from "@ant-design/icons";
import { SpinSize } from "antd/lib/spin";
import RedLineContent from "../components/termsheet/common/RedLineContent";

export const invitationStatusTag = (
  invitationStatus: InvitationStatusType
): ReactNode => {
  let color;
  switch (invitationStatus) {
    case InvitationStatusType.ACCEPTED:
      color = "success";
      break;
    case InvitationStatusType.PENDING:
      color = "warning";
      break;
    default:
      color = "red";
      break;
  }
  return invitationStatus ? (
    <Tag color={color} key={0} className={"uppercase"}>
      {invitationStatus === InvitationStatusType.ACCEPTED
        ? "ACTIVE"
        : invitationStatus}
    </Tag>
  ) : (
    <></>
  );
};

export const resendTeamInvite = (
  userId: string,
  companyId?: string,
  transactionId?: string,
  lenderId?: string,
  inviteType?: "PETRANSACTION" | "LENDER_TRANSACTION" | "LENDER" | "COMPANY"
): void => {
  resendInvite({
    body: JSON.stringify({
      userId,
      transactionId,
      companyId,
      inviteType,
      lenderId,
    }),
  })
    .then(() => {
      message.success("Invite sent successfully.");
    })
    .catch(() => {
      message.error("Something went wrong. Please try again");
    });
};

export const threadPrefix = (
  data: string,
  colorLiteral: string,
  active = false
): ReactNode => {
  const textColor = getColorPallet(colorLiteral, "text");
  const bgColor = getColorPallet(colorLiteral, "bg");
  return (
    <div
      className={`flex items-center px-2 h-full min-h-16 key py-2 m-0 text-2xl font-light transition-none duration-[0ms] ${
        active ? bgColor + " text-white" : "bg-gray-100" + " " + textColor
      } min-w-11 md:min-w-8`}
    >
      <span className={"flex items-center m-auto h-full uppercase"}>
        {data}
      </span>
    </div>
  );
};

export const getSelectOptionWithBadge: getSelectOptionWithBadgeType = (
  options
): ReactNode[] =>
  options.map(({ value, color, label }) => (
    <Select.Option key={value as Key} value={value} className={"flex flex-row"}>
      {color && <Badge offset={[0, 0]} dot color={color} />}&nbsp;&nbsp;
      {label}
    </Select.Option>
  ));

export const tableSelectSuffix = (enabled = true, loading = false): ReactNode =>
  loading ? (
    <Spin size={"small"} indicator={<LoadingOutlined spin={true} />} />
  ) : (
    enabled && (
      <i className="fas fa-sort-down text-blue-500 opacity-0 group-hover:opacity-100" />
    )
  );

export const tableColumnHeader = (
  header: ReactNode,
  className = ""
): ReactNode => (
  <div
    className={`w-full uppercase text-gray-500 font-medium text-xs select-none whitespace-nowrap whitespace-pre ${className} overflow-hidden truncate`}
  >
    {header}
  </div>
);

export const LoadingOverlay: FC<LoadingOverlayType> = ({
  children,
  size = "default",
  loading,
}) => {
  return (
    <>
      {loading ? (
        <Spin size={size} indicator={<LoadingOutlined spin={true} />} />
      ) : (
        children
      )}
    </>
  );
};
export type getSelectOptionWithBadgeType = <T>(
  options: SelectWithBadgeOptionType<T>[]
) => ReactNode[];

export type SelectWithBadgeOptionType<T = any> = {
  value: T;
  color?: string;
  label: ReactNode;
};

type LoadingOverlayType = {
  children: ReactNode | ReactNode[];
  loading: boolean;
  size?: SpinSize;
};
