import React, { FC, useState } from "react";
import { Avatar, DatePicker, Timeline, Tooltip } from "antd";
import moment from "moment";
import { DeadlineEventModal } from "../../../modals/DeadlineEventModal";
import { getColorPallet } from "../../../../utils/colors";
import placeholderTimeline from "../../../../images/icons/PlaceholderTimeline.svg";
import { updateTransactionDueDate } from "../../../../services/services";
import {
  DeadLineType,
  LenderType,
  TransactionType,
} from "../../../../utils/types";
import { DeleteDeadlineModal } from "../../../modals/DeleteDeadlineModal";
import { CalendarOutlined } from "@ant-design/icons";
import { TextOverFlowHandle } from "../../../general/TextOverFlowHandle";
import { equals } from "ramda";
import { PETransactionCreationType } from "../../../../utils/enums";

export const DashboardTimeline: FC<DashboardTimelineType> = function ({
  deadlines,
  onUpdate,
  onCreate,
  onDelete,
  transaction,
  setTransaction,
  editPermission,
}: DashboardTimelineType) {
  const [modalOpen, setModalOpen] = useState<string | null>(null);
  const [pastLoaded, setPastLoaded] = useState<boolean>(false);
  const [deleteModalItem, setDeleteModalItem] = useState<DeadLineType | null>(
    null
  );

  const resetDate = (): void => {
    if (transaction) {
      updateTransactionDueDate({
        body: JSON.stringify({
          ...transaction,
          targetDate: null,
        }),
      }).then(() =>
        setTransaction({
          ...transaction,
          targetDate: "",
        })
      );
    }
  };

  const setDate: setDateType = (date) => {
    if (date && transaction) {
      const targetDate = moment.utc(date).valueOf().toString();
      updateTransactionDueDate({
        body: JSON.stringify({
          ...transaction,
          targetDate,
        }),
      }).then(() => {
        setTransaction({
          ...transaction,
          targetDate,
        });
      });
    }
  };
  return (
    <div className={"flex"}>
      {deadlines && deadlines.length > 0 ? (
        <Timeline className={"flex-1 overflow-y-auto max-h-52 h-52 pt-2"}>
          {pastLoaded ? null : (
            <>
              {deadlines.filter((item) =>
                moment(Number(item.targetDate)).isBefore(
                  moment().subtract(1, "days")
                )
              ).length > 0 && (
                <Timeline.Item
                  key={0}
                  className={
                    "h-10 text-gray-400 hover:underline cursor-pointer"
                  }
                >
                  <span onClick={(): void => setPastLoaded(true)}>
                    View Past Deadlines...
                  </span>
                </Timeline.Item>
              )}
            </>
          )}
          {deadlines
            .filter((item) =>
              pastLoaded
                ? true
                : moment(Number(item.targetDate)).isSameOrAfter(
                    moment().subtract(1, "days")
                  )
            )
            .map((deadline) => {
              return (
                <Timeline.Item key={deadline.id} className={"group h-10"}>
                  <div className={"flex hover:bg-gray-100 mr-3"}>
                    <span className={"flex-none bg-gray-100 py-1.5 px-4"}>
                      {moment(Number(deadline.targetDate)).format("DD MMM YY")}
                    </span>
                    <div
                      className={`py-1.5 font-bold ml-3 w-[90%] overflow-x-hidden pr-8`}
                    >
                      <TextOverFlowHandle text={deadline.title} />
                    </div>
                    <div className={"flex-none flex"}>
                      <Avatar.Group
                        className={"pt-1.5"}
                        maxCount={2}
                        size="small"
                        maxStyle={{ backgroundColor: "#808080" }}
                      >
                        {deadline.lenderDTOS &&
                          deadline.lenderDTOS.map((lender: LenderType) => {
                            return (
                              <Tooltip
                                key={lender.id}
                                title={lender.name}
                                placement="top"
                              >
                                <Avatar
                                  className={`${getColorPallet(
                                    lender?.name?.charAt(0),
                                    "bg"
                                  )} uppercase`}
                                  size="small"
                                >
                                  {lender?.name?.charAt(0)}
                                </Avatar>
                              </Tooltip>
                            );
                          })}
                      </Avatar.Group>
                      {editPermission && (
                        <div className={"py-1.5"}>
                          <i
                            onClick={(): void => setModalOpen(deadline.id)}
                            className={
                              "ml-5 fa mr-2 fa-pencil-alt cursor-pointer group-hover:opacity-50 opacity-50 md:opacity-0"
                            }
                          />
                          <i
                            onClick={(): void => setDeleteModalItem(deadline)}
                            className={
                              "mr-2 fa fa-trash-alt cursor-pointer group-hover:opacity-50 opacity-50 md:opacity-0"
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Timeline.Item>
              );
            })}
        </Timeline>
      ) : (
        <div className={"flex-1"}>
          <img className={"h-full"} src={placeholderTimeline} alt="empty" />
        </div>
      )}
      <div className={"flex-none justify-self-center w-52 ml-5"}>
        <Avatar
          className={
            "bg-gray-100 text-gray-400 h-full w-52 flex flex-col items-center justify-center"
          }
          icon={
            <div className={"flex flex-col items-center"}>
              <CalendarOutlined className={"text-6xl mb-3"} />
              <p className={"text-base m-0 uppercase text-sm"}>
                Expected signing date
              </p>
              <div className={"text-black flex flex-row items-center gap-x-1"}>
                <Tooltip title={editPermission && "Edit date"}>
                  {transaction?.targetDate
                    ? moment(Number(transaction.targetDate)).format(
                        "DD MMM YYYY"
                      )
                    : "No Date Set"}
                  {editPermission && transaction && (
                    <>
                      <DatePicker
                        format={"DD MMM YYYY"}
                        defaultValue={
                          transaction.targetDate
                            ? moment(Number(transaction.targetDate))
                            : moment()
                        }
                        className={
                          "w-3/4 absolute left-0 opacity-0 p-0 cursor-pointer"
                        }
                        disabledDate={(current): boolean =>
                          current && current < moment().subtract(1, "days")
                        }
                        onChange={(date): void => setDate(date)}
                      />
                    </>
                  )}
                </Tooltip>
                {editPermission && (
                  <>
                    <i
                      className={
                        "text-gray-400 text-xs m-0 fa fa-pencil-alt cursor-pointer"
                      }
                    />
                    <Tooltip title={"Remove date"}>
                      <i
                        onClick={(): void => resetDate()}
                        className={
                          "text-gray-400 text-xs fa fa-trash-alt cursor-pointer"
                        }
                      />
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          }
        />
      </div>

      <DeadlineEventModal
        onUpdate={onUpdate}
        onCreate={onCreate}
        visible={!!modalOpen}
        editDeadline={deadlines?.find((dl) => dl.id === modalOpen)}
        dashboardTransaction={transaction}
        isOffline={equals(
          PETransactionCreationType.OFFLINE,
          transaction?.peTransactionCreationType
        )}
        handleCancel={(): void => setModalOpen(null)}
      />
      {deleteModalItem && (
        <DeleteDeadlineModal
          deadline={deleteModalItem}
          visible={!!deleteModalItem}
          setVisible={(): void => setDeleteModalItem(null)}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

type DashboardTimelineType = {
  deadlines: Array<DeadLineType>;
  onUpdate: (a: DeadLineType) => void;
  onCreate: (a: DeadLineType) => void;
  onDelete: (id: string) => void;
  transaction: TransactionType | null;
  setTransaction: (u: TransactionType | null) => void;
  editPermission: boolean;
};
type setDateType = (date: moment.Moment | null) => void;
