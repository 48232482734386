import React, { FC, useEffect, useState } from "react";
import { Alert, Button, DatePicker, Input, message, Modal } from "antd";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import {
  AllocationTableMappingType,
  PortfolioSectionTableVersionDTO,
} from "../../../utils/types";
import { compose, filter, isEmpty, isNil, not, without } from "ramda";
import {
  editAllocationTableVersion,
  updateCapitalStructureVersion,
} from "../../../services/services";
import { ResponseType } from "../../../utils/uiTypes";
import { stopPropagationOnEnter } from "../../../utils/keyboardUtils";
import moment from "moment";
import {
  getModalContainerForFullScreen,
  getPopupContainerForSelect,
} from "../../../utils/container";

const isEmptyOrNil = (v: any) => isEmpty(v) || isNil(v);
export const filterByNotEmptyOrNil = filter(compose(not, isEmptyOrNil));

export const EditCapStructureVersionModal: FC<
  EditCapStructureVersionModalType
> = ({
  visible,
  version,
  dates = [],
  onSave,
  onCancel,
  portfolioId,
  sectionId,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] =
    useState<Partial<PortfolioSectionTableVersionDTO> | null>(null);

  const onClear = () => {
    setValue(null);
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);
    setError(null);
    updateCapitalStructureVersion({
      segments: {
        portfolioId: portfolioId,
        sectionId: sectionId,
      },
      body: JSON.stringify({
        ...value,
        versionDate: value?.versionDate
          ? moment(value.versionDate).valueOf()
          : undefined,
      }),
    })
      .then(({ data }: ResponseType<PortfolioSectionTableVersionDTO>) => {
        onClear();
        onSave(data);
        message.success("Version Updated Successfully");
      })
      .catch((e: string) => {
        setLoading(false);
        setError(e);
      });
  };

  useEffect(() => {
    if (version && visible) {
      setValue(version);
    }
  }, [version, visible]);

  return (
    <Modal
      getContainer={getModalContainerForFullScreen}
      title={"Edit Version Information"}
      open={visible}
      width={600}
      onCancel={() => {
        onClear();
        onCancel();
      }}
      destroyOnClose={true}
      footer={[
        <div className={"w-full text-right"} key={0}>
          <Button
            disabled={loading}
            onClick={() => {
              onClear();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              loading ||
              !value?.versionDate?.trim() ||
              !value?.versionName?.trim()
            }
            loading={loading}
            className={PRIMARY_BUTTON_STYLE}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>,
      ]}
    >
      <div className={"flex flex-col gap-4"}>
        <div className={"grid grid-cols-2 gap-4"}>
          <div>
            <div className={"w-full mb-2"}>Name</div>
            <Input
              placeholder={"Enter table name"}
              value={value?.versionName}
              onChange={(e) =>
                setValue((v) =>
                  v ? { ...v, versionName: e.target.value } : null
                )
              }
            />
          </div>
          <div>
            <div className={"w-full mb-2"}>Date</div>
            <DatePicker
              getPopupContainer={getPopupContainerForSelect}
              disabledDate={(current): boolean => {
                console.log(version?.versionDate, dates);
                const list = version?.versionDate
                  ? without(version?.versionDate, dates)
                  : dates;
                const match = filterByNotEmptyOrNil(list).find((o) =>
                  moment(o)
                    .startOf("day")
                    .isSame(moment(current).startOf("day"))
                );
                console.log("match " + match);
                return match != undefined;
              }}
              onChange={(v) => {
                setValue((k) =>
                  k
                    ? {
                        ...k,
                        versionDate: v?.format().toString(),
                      }
                    : null
                );
              }}
              value={
                !isEmpty(value?.versionDate) && !isNil(value?.versionDate)
                  ? moment(value?.versionDate)
                  : null
              }
              className={`w-full`}
              format={"ll"}
              placeholder={"Select date"}
              onKeyDown={stopPropagationOnEnter}
            />
          </div>
        </div>
        <div>
          <div className={"w-full mb-2"}>Description</div>
          <Input
            placeholder={"Description"}
            value={value?.description}
            onChange={(e) =>
              setValue((v) => ({ ...v, description: e.target.value }))
            }
          />
        </div>
        {error && <Alert message={error} type={"error"} className={"mb-2"} />}
      </div>
    </Modal>
  );
};

type EditCapStructureVersionModalType = {
  visible: boolean;
  version: PortfolioSectionTableVersionDTO;
  dates: string[];
  onSave: (value: PortfolioSectionTableVersionDTO) => void;
  onCancel: () => void;
  portfolioId: string;
  sectionId: string;
};
