import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, Popover, Tooltip } from "antd";
import { useForm } from "antd/es/form/Form";
import { ACTION_BUTTON_CSS } from "../../utils/cssConfigs";

export const FileOrFolderRenameForm: FC<RenameFormType> = ({
  onSubmit,
  formData,
  isFolder = false,
}: RenameFormType) => {
  const [form] = useForm();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    formData && form.setFieldsValue(formData);
  }, [formData]);

  return (
    <>
      <Tooltip title={"Rename"}>
        <Popover
          trigger={"click"}
          placement={"left"}
          open={visible}
          onOpenChange={setVisible}
          content={
            <Form
              form={form}
              className={"flex flex-row items-center gap-x-2"}
              layout={"inline"}
              onFinish={(val): void => {
                onSubmit(val);
                setVisible(false);
                form.resetFields();
              }}
            >
              <Form.Item
                name={"name"}
                label={`${isFolder ? "Folder" : "File"} Name`}
              >
                <Input placeholder={"New Name"} autoFocus={true} />
              </Form.Item>

              <Form.Item className={"m-0 flex flex-row"}>
                <Button
                  className={"bg-primary hover:bg-hover text-white border-0"}
                  htmlType={"submit"}
                >
                  Rename
                </Button>
              </Form.Item>
            </Form>
          }
        >
          <Button
            className={`${ACTION_BUTTON_CSS} hover:text-blue-500 opacity-0 group-hover:opacity-100`}
            icon={<i className="fas fa-pencil-alt" />}
          />
        </Popover>
      </Tooltip>
    </>
  );
};

type RenameFormType = {
  onSubmit: (o: { name: string }) => void;
  formData: Record<string, string>;
  isFolder: boolean;
};
