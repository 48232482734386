export const ALPHABETS: Array<string> = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const MODAL_TYPES: Record<ModalType, string> = {
  COMPARE: "SELECT THE RELEVANT COLUMNS TO BE COMPARED",
  PUBLISH_DEAL_TEAM:
    "CHOOSE THE INSTITUTION(S) YOU WANT TO SHARE THIS GRID WITH",
  PUBLISH_INSTITUTION: "PUBLISH",
  UPLOAD: "SELECT FILE TO UPLOAD",
  ADD_LENDER: "SELECT THE INSTITUTION(S) TO ADD TO THIS VERSION",
  REMOVE_LENDER: "SELECT THE INSTITUTION(S) TO REMOVE FROM THIS VERSION",
  RESIZE_COLUMN: "RESIZE COLUMN",
};
export const DRAWER_TYPES: Record<DrawerType, string> = {
  TEMPLATES: "Templates",
  TERMSHEETS: "Termsheets",
};

export const MODAL_PRIMARY_ACTION_TEXT = {
  REMIND_LENDER: "Remind Institution(s)",
  ADD_LENDER: "Add Institution(s)",
  REMOVE_LENDER: "Remove Institution(s)",
  PUBLISH_DEAL_TEAM: "Publish",
  PUBLISH_INSTITUTION: "Publish",
  COMPARE: "Compare",
  UPLOAD: "Upload",
  RESIZE_COLUMN: "Resize",
};

export const MODAL_SECONDARY_ACTION_TEXT = {
  COMPARE: "Download",
};

export const MODAL_SECONDARY_ICON = {
  COMPARE: "fa fa-download",
};

export enum DefaultColumnType {
  BID_METRICS = "BID_METRICS",
}

export type ModalType =
  | "PUBLISH_DEAL_TEAM"
  | "PUBLISH_INSTITUTION"
  | "ADD_LENDER"
  | "REMOVE_LENDER"
  | "COMPARE"
  | "UPLOAD"
  | "RESIZE_COLUMN";

export type DrawerType = "TEMPLATES" | "TERMSHEETS";

export type ActionType =
  | "COMPARE"
  | "CLEAR_COLUMN"
  | "TERMSHEETS"
  | "TEMPLATES"
  | "UPLOAD"
  | "DOWNLOAD_TERMSHEET_EXCEL"
  | "DOWNLOAD_TERMSHEET_WORD"
  | "DOWNLOAD_ASKS_EXCEL"
  | "DOWNLOAD_ASKS_WORD"
  | "DOWNLOAD_PORTFOLIO_EXCEL"
  | "DOWNLOAD"
  | "DOWNLOAD_WORD"
  | "UNDO"
  | "REDO";

export type HeightWidthMapType = { [k: string]: number };
