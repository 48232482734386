import React, { FC, ReactNode } from "react";

import {
  FullScreenButton,
  FullScreenWidget,
} from "../general/FullScreenWidget";

export const RelationshipCard: FC<CRMCardType> = ({
  children,
  actions,
  label,
}) => {
  return (
    <FullScreenWidget>
      {(isFullScreen, trigger) => (
        <div
          className={`p-5 pt-2 w-full h-full max-h-full ${
            isFullScreen && "bg-gray-100 border shadow"
          }`}
        >
          <div className={`flex flex-col h-full w-full bg-white shadow`}>
            <div
              className={`px-5 py-3 w-full border-b font-medium flex flex-row items-center gap-x-2`}
            >
              <div className={"text-lg"}>{label}</div>
              <div className={"flex flex-row gap-x-2 max-w-2xl ml-auto"}>
                {actions}
                <FullScreenButton
                  trigger={trigger}
                  isFullScreen={isFullScreen}
                />
              </div>
            </div>
            {children}
          </div>
        </div>
      )}
    </FullScreenWidget>
  );
};

type CRMCardType = {
  label: ReactNode;
  actions?: ReactNode | ReactNode[];
  children: ReactNode | ReactNode[];
};
