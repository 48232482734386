import React, { FC, useCallback, useState, Fragment, useContext } from "react";
import {
  CompareScreenRowType,
  CompareScreenType,
  KeyValueNumberType,
} from "../../../utils/types";
import {
  all,
  equals,
  findIndex,
  isEmpty,
  isNil,
  path,
  pathOr,
  propEq,
  propOr,
  sum,
} from "ramda";
import { getKeyByType } from "./utils/key-by-type";
import PSCell from "./elements/PSCell";
import PSHeader from "./elements/PSHeader";
import { onColumnResize } from "./elements/ColumnResizeBlock";
import { tableColumnHeader } from "../../../utils/componentUtils";
import { convertDateToFormat } from "../../../utils/moment";
import { SheetsSelectionType } from "./utils/sheets-selection";
import {
  PSColWidth,
  PSFontSize,
  SHEETS_SEPARATION_BORDER_COLOR,
  SHEETS_SEPARATION_COL_WIDTH,
} from "./utils/css";
import { DEFAULT_SIZES } from "./utils/default-dimensions";
import { UserContext } from "../../../context/UserContext";
import { CompanyEnumType } from "../../../utils/enums";

const PrecedentSheets: FC<PrecedentDetailsType> = ({
  sheets = [],
  rows = [],
  redline,
  searchMode = false,
  heatMap = false,
  fontSize,
  width,
}) => {
  const [colsWidth, setColsWidth] = useState<KeyValueNumberType>({});
  const [currentResizeColumn, setCurrentResizeColumn] = useState<string | null>(
    null
  );
  const { user } = useContext(UserContext);

  const hideRow = useCallback<hideRowType>(
    (row) => {
      return (
        searchMode &&
        all(({ sheetId }) => {
          return isNil(
            pathOr(null, [sheetId, "highlight", "matchedMetric"], row)
          );
        }, sheets)
      );
    },
    [searchMode, sheets, rows]
  );

  const handleMouseDown = useCallback<onColumnResize>(
    (e, { columnId, startWidth }) => {
      // Set mouse down position
      const mouseDownX = e.clientX;

      setCurrentResizeColumn(() => columnId);
      // Add mouse move event listener
      const handleMouseMove: EventListenerOrEventListenerObject = (
        moveEvent
      ): void => {
        const newWidth =
          startWidth +
          ((propOr(0, "clientX", moveEvent) as number) - mouseDownX);
        if (newWidth > DEFAULT_SIZES.minW)
          setColsWidth((prevColsWidth) => ({
            ...prevColsWidth,
            [columnId]: newWidth,
          }));
      };

      // Add mouse up event listener
      const handleMouseUp = (): void => {
        setCurrentResizeColumn(() => null);
        // Remove event listeners when mouse button is released
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      };

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    },
    []
  );

  return (
    <>
      <div className="relative overflow-x-auto shadow-md bg-white">
        <table
          style={{ width: "100%", tableLayout: "fixed" }}
          className={`border-separate border-spacing-0 ${fontSize}`}
        >
          <colgroup>
            <col style={{ width: "40px" }} />
            {sheets?.map(
              ({
                sheetId,
                visibleResponseKeys,
                showResponses,
                showMetric,
                showAsk,
              }) => {
                const bidMetricKey = getKeyByType(sheetId, "BID_METRIC");
                const dealAskKey = getKeyByType(sheetId, "ASK");
                return (
                  <Fragment key={sheetId}>
                    {showMetric && (
                      <col
                        style={{
                          width: colsWidth[bidMetricKey] || width,
                        }}
                        className={"!border-l !border-l-blue-400"}
                      />
                    )}
                    {showAsk && (
                      <col
                        style={{
                          width: colsWidth[dealAskKey] || width,
                        }}
                      />
                    )}
                    {showResponses &&
                      visibleResponseKeys?.map((val) => {
                        const responseKey = getKeyByType(
                          sheetId,
                          "RESPONSE",
                          val
                        );
                        return (
                          <col
                            key={val}
                            style={{
                              width: colsWidth[responseKey] || width,
                            }}
                          />
                        );
                      })}
                    <col style={{ width: SHEETS_SEPARATION_COL_WIDTH }} />
                  </Fragment>
                );
              }
            )}
          </colgroup>
          <thead
            className={"z-50 sticky top-0 bg-gray-50 border-b-0 drop-shadow-sm"}
          >
            <tr className={"bg-light"}>
              <th
                className={
                  "left-0 top-0 sticky z-[100] bg-gray-50 p-2 border-r border-r-slate-200"
                }
                rowSpan={3}
              >
                {tableColumnHeader("No.", "!capitalize")}
              </th>
              {sheets.map((sheet) => {
                return (
                  <Fragment key={sheet.sheetId}>
                    <PSHeader
                      colSpan={isNaN(sheet.colCount) ? 2 : sheet.colCount}
                    >
                      <div
                        className={"flex items-center justify-between text-xs"}
                      >
                        <div>{sheet.transactionName}</div>
                        <div>{sheet.termSheetName}</div>
                        <div>
                          {convertDateToFormat(
                            sheet.createdDate,
                            "DD MMM YYYY",
                            false
                          )}
                        </div>
                      </div>
                    </PSHeader>
                    <th
                      className={`z-10 ${SHEETS_SEPARATION_BORDER_COLOR} relative`}
                      rowSpan={3}
                    />
                  </Fragment>
                );
              })}
            </tr>
            <tr>
              {sheets.map(
                ({
                  sheetId,
                  showMetric,
                  showAsk,
                  showResponses,
                  visibleResponseKeys,
                }) => {
                  return (
                    <Fragment key={sheetId}>
                      {(showAsk || showMetric) && (
                        <PSHeader
                          colSpan={sum([showAsk ? 1 : 0, showMetric ? 1 : 0])}
                        >
                          {tableColumnHeader("Deal Team")}
                        </PSHeader>
                      )}
                      {showResponses && visibleResponseKeys?.length && (
                        <PSHeader colSpan={visibleResponseKeys?.length | 0}>
                          {tableColumnHeader("Responses")}
                        </PSHeader>
                      )}
                    </Fragment>
                  );
                }
              )}
            </tr>
            <tr>
              {sheets?.map(
                ({
                  sheetId,
                  visibleResponseKeys,
                  showResponses,
                  showMetric,
                  showAsk,
                  dealTeamName,
                  isPortfolio,
                }) => {
                  const bidMetricKey = getKeyByType(sheetId, "BID_METRIC");
                  const dealAskKey = getKeyByType(sheetId, "ASK");
                  return (
                    <Fragment key={sheetId}>
                      {showMetric && (
                        <PSHeader
                          resizeProps={{
                            isResizing: equals(
                              bidMetricKey,
                              currentResizeColumn
                            ),
                            onResize: handleMouseDown,
                            id: bidMetricKey,
                            startWidth: colsWidth[bidMetricKey] || width,
                          }}
                        >
                          {tableColumnHeader(
                            isPortfolio ? "Category" : "Bid Metric"
                          )}
                        </PSHeader>
                      )}

                      {showAsk && (
                        <PSHeader
                          resizeProps={{
                            isResizing: equals(dealAskKey, currentResizeColumn),
                            onResize: handleMouseDown,
                            id: dealAskKey,
                            startWidth: colsWidth[dealAskKey] || width,
                          }}
                        >
                          {tableColumnHeader(
                            isPortfolio
                              ? "Agreed Position"
                              : `${dealTeamName} Ask`
                          )}
                        </PSHeader>
                      )}

                      {showResponses &&
                        visibleResponseKeys?.length &&
                        visibleResponseKeys?.map((val) => {
                          const responseKey = getKeyByType(
                            sheetId,
                            "RESPONSE",
                            val
                          );
                          return (
                            <PSHeader
                              key={val}
                              resizeProps={{
                                isResizing: equals(
                                  responseKey,
                                  currentResizeColumn
                                ),
                                onResize: handleMouseDown,
                                id: responseKey,
                                startWidth: colsWidth[responseKey] || width,
                              }}
                            >
                              {tableColumnHeader(val)}
                            </PSHeader>
                          );
                        })}
                    </Fragment>
                  );
                }
              )}
            </tr>
          </thead>
          <tbody className={"relative overflow-y-auto "}>
            {rows?.map((row, rowIndex) => {
              return hideRow(row) ? (
                <></>
              ) : (
                <tr
                  key={rowIndex}
                  className={"hover:bg-blue-100/50 border border-slate-200"}
                  style={{
                    display: hideRow(row) ? "none" : "table-row",
                  }}
                >
                  <td className="p-2 bg-gray-50 z-10 sticky left-0 border-r border-r-slate-200 border-b border-b-slate-200">
                    <span
                      className={"!text-xxs text-center w-full text-secondary"}
                    >
                      {rowIndex + 1}
                    </span>
                  </td>
                  {sheets?.map(
                    (
                      {
                        sheetId,
                        visibleResponseKeys,
                        showResponses,
                        showMetric,
                        showAsk,
                      },
                      colIndex
                    ) => {
                      const bidMetricKey = getKeyByType(sheetId, "BID_METRIC");
                      const dealAskKey = getKeyByType(sheetId, "ASK");
                      const hideData =
                        searchMode &&
                        isEmpty(
                          row[sheetId]?.highlight?.matchedMetric?.trim() ?? ""
                        );

                      return (
                        <Fragment key={colIndex}>
                          {showMetric && (
                            <PSCell
                              hideData={hideData}
                              isResizing={equals(
                                bidMetricKey,
                                currentResizeColumn
                              )}
                              text={row[sheetId]?.metric}
                              highlightedMetric={
                                row[sheetId]?.highlight?.matchedMetric
                              }
                              key={bidMetricKey}
                            />
                          )}

                          {showAsk && (
                            <PSCell
                              hideData={hideData}
                              isResizing={equals(
                                dealAskKey,
                                currentResizeColumn
                              )}
                              // highlight={highlight}
                              text={row[sheetId]?.ask}
                            />
                          )}

                          {showResponses &&
                            visibleResponseKeys?.map((item: string) => {
                              const key = getKeyByType(
                                sheetId,
                                "RESPONSE",
                                item
                              );
                              return (
                                <PSCell
                                  hideData={hideData}
                                  heatMap={heatMap}
                                  key={item}
                                  isResizing={equals(key, currentResizeColumn)}
                                  text={
                                    row[sheetId]?.lenderDataMap?.[item] ?? ""
                                  }
                                  // highlight={highlight}
                                  redline={redline}
                                  compareText={row[sheetId]?.ask}
                                />
                              );
                            })}
                          <td
                            className={SHEETS_SEPARATION_BORDER_COLOR}
                            style={{
                              width: SHEETS_SEPARATION_COL_WIDTH,
                            }}
                          />
                        </Fragment>
                      );
                    }
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PrecedentSheets;

type PrecedentDetailsType = {
  sheets: SheetsSelectionType[];
  rows: CompareScreenType["sheetsDataByRow"];
  redline: boolean;
  searchMode: boolean;
  heatMap?: boolean;
  fontSize: PSFontSize;
  width: PSColWidth;
};

type hideRowType = (row: CompareScreenRowType) => boolean;
