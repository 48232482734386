const getParams = (params: any) => new URLSearchParams(params).toString();

export const fileUpload = (url: any, options: any): Promise<any> => {
  const config = {
    ...options,
    headers: {
      ...options.headers,
    },
    method: "POST",
  };
  return fetch(url, config).then((response) => {
    return new Promise((resolve, reject) => {
      response
        .json()
        .then((data) => {
          const { ok, status, headers, redirected } = response;
          if (ok) {
            resolve({ ok, status, headers, redirected, data });
          } else {
            reject("Unable to upload File");
          }
        })
        .catch(reject);
    });
  });
};

const requestDownloadFile = function (options: any): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(
      options.params
        ? `${options.url}?${getParams(options.params)}`
        : options.url,
      options
    )
      .then((response: Response) => {
        if (response.ok && response.status >= 200 && response.status <= 299) {
          response
            .blob()
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const filename = response.headers
                .get("Content-Disposition")
                ?.split("filename=")[1]
                .replaceAll('"', "");
              resolve({
                url,
                filename,
                blob,
              });
            })
            .catch((error) => {
              console.error(error);
              reject(
                error.message
                  ? error.message.toString()
                  : "Error Downloading File!"
              );
            });
        } else {
          reject("Error downloading File");
        }
      })
      .catch((error) => {
        console.error(error);
        reject(
          error.message
            ? error.message.toString()
            : "Error performing the Action!"
        );
      });
  });
};

const request = function (options: any): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(
      options.params
        ? `${options.url}?${getParams(options.params)}`
        : options.url,
      options
    )
      .then((response) => {
        response.text().then((data) => {
          if (response.ok && response.status >= 200 && response.status <= 299) {
            resolve({
              ok: response.ok,
              status: response.status,
              data: data?.length > 0 ? JSON.parse(data) : undefined,
            });
          } else {
            reject(
              JSON.parse(data)?.message
                ? JSON.parse(data)?.message.toString()
                : "Error performing the Action!"
            );
          }
        });
      })
      .catch((error) => {
        console.error(error);
        reject(
          error.message
            ? error.message.toString()
            : "Error performing the Action!"
        );
      });
  });
};

const getUrl = (segments: any, url: urlType): string =>
  typeof url === "string" ? url : url(segments);

export const DOWNLOAD_GET =
  (url: urlType): any =>
  (options: optionsType) =>
    requestDownloadFile({
      method: "get",
      ...options,
      url: getUrl(options.segments, url),
    });

export const DOWNLOAD_POST =
  (url: urlType): any =>
  ({
    segments,
    params,
    body,
    headers = {
      Accept: ["application/json", "text/plain", "*/*"],
      "Content-Type": "application/json",
    },
  }: optionsType) =>
    requestDownloadFile({
      method: "post",
      segments,
      params,
      body,
      headers,
      url: getUrl(segments, url),
    });

export const GET =
  (url: urlType): any =>
  (options: optionsType) =>
    request({ ...options, method: "get", url: getUrl(options.segments, url) });

export const POST =
  (url: urlType): any =>
  ({
    segments,
    params,
    body,
    headers = {
      Accept: ["application/json", "text/plain", "*/*"],
      "Content-Type": "application/json",
    },
  }: optionsType) =>
    request({
      params: params,
      body: body,
      headers: headers,
      method: "post",
      url: getUrl(segments, url),
    });

export const PUT =
  (url: urlType): any =>
  ({
    segments,
    params,
    body,
    headers = {
      Accept: ["application/json", "text/plain", "*/*"],
      "Content-Type": "application/json",
    },
  }: optionsType) =>
    request({
      params: params,
      body: body,
      headers: headers,
      method: "put",
      url: getUrl(segments, url),
    });

export const DELETE =
  (url: urlType): any =>
  (options: optionsType) =>
    request({
      ...options,
      method: "delete",
      url: getUrl(options.segments, url),
    });

type urlFnType = (v: any) => string;
type urlType = string | urlFnType;
type optionsType = {
  url?: string;
  segments?: any;
  params?: any;
  body?: any;
  headers?: any;
};
type fetchOptionsType = {
  url?: string;
  params?: any;
  body?: any;
};
