import { keys, path } from "ramda";
import { tableColumnHeader } from "../../utils/componentUtils";
import { Badge, Button, Table } from "antd";
import React, { FC, ReactNode } from "react";
import { AnalyticsDataRenderType } from "./AnalyticsData";
import {
  TRANSACTION_STATUSES,
  TransactionsTagsMap,
} from "../../utils/transaction";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import { SpinnerOverlay } from "../general/SpinnerOverlay";
import { onSort } from "../../utils/table-utils";
import useWindowDimensions from "../../customHooks/useWindowDimensions";
import { roundAndFormat } from "../../utils/roundAndFormat";

const BLACKLIST_COLUMNS = ["Portfolio Id", "Lender Id"];
export const AnalyticsTable: FC<AnalyticsDataRenderType> = ({
  loading,
  data,
  label,
  onRefresh,
  onDownload,
  footer,
  showActionItems,
}) => {
  const { height } = useWindowDimensions();
  const parseValues = (val: string | number): ReactNode => {
    const statuses = TRANSACTION_STATUSES.map((val) => val.toString());
    if (statuses.includes(val?.toString())) {
      const { color, label } = TransactionsTagsMap[val];
      return (
        <span className={"flex flex-row"}>
          {color && <Badge offset={[0, 0]} dot color={color} />}&nbsp;&nbsp;
          {label}
        </span>
      );
    }
    const isNumber = !isNaN(Number(val)) && val !== "" && val !== null;
    return isNumber
      ? roundAndFormat(val)
      : val?.toString()?.replaceAll(", ", `\n`);
  };

  const renderValues = (val: string | number): ReactNode => {
    return val?.toString()?.replaceAll(", ", `\n`);
  };

  return (
    <div className={"p-1.5 w-full"}>
      <div
        className={
          "bg-white flex items-stretch flex-col drop-shadow-sm border border-gray-200"
        }
      >
        {showActionItems && !footer && (
          <div
            className={
              "flex flex-row items-center p-2 border-b border-gray-200 bg-gray-50 select-all sticky"
            }
          >
            {label}
            {onRefresh && (
              <Button
                className={"ml-auto"}
                type={"text"}
                icon={<ReloadOutlined className={"text-primary"} />}
                onClick={onRefresh}
              />
            )}
            {onDownload && (
              <Button
                type={"text"}
                icon={<DownloadOutlined className={"text-primary"} />}
                onClick={onDownload}
              />
            )}
          </div>
        )}
        <SpinnerOverlay hidden={false} opaque={true} spinning={loading}>
          <div className={"w-full"} style={{ minHeight: "150px" }}>
            <Table
              size={"small"}
              rowClassName={`hover:bg-blue-50`}
              dataSource={
                data?.data?.map((val: any, index) => ({
                  ...val,
                  index,
                })) ?? []
              }
              columns={keys(path(["0"], data?.data ?? [])).map((val) => ({
                title: tableColumnHeader(val, "!whitespace-pre-wrap"),
                dataIndex: val,
                sorter: (a: any, b: any) => {
                  return onSort(a[val], b[val]);
                },
                className:
                  "whitespace-pre-wrap bg-transparent flex-initial items-start justify-start min-w-[180px] ",
                render: (v, e) => {
                  return BLACKLIST_COLUMNS.includes(val)
                    ? renderValues(v)
                    : parseValues(v);
                },
              }))}
              scroll={{ x: true, y: height - 150 }}
              pagination={false}
              rowKey={"index"}
            />
          </div>
        </SpinnerOverlay>
      </div>
    </div>
  );
};
