import { useState } from "react";

export const useCopyToClipboard: UseCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);

  const copy: ReturnType<UseCopyToClipboard>["copy"] = (text) => {
    navigator.clipboard.writeText(text?.toString()).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    });
  };

  return { copy, copied };
};

type UseCopyToClipboard = () => {
  copy: (text: string | number) => void;
  copied: boolean;
};
