import { Button, Form, Input, message, Modal, Select, Tree } from "antd";
import type { DataNode, TreeProps } from "antd/lib/tree";
import React, { FC, useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { find, isEmpty, isNil, pathOr, propEq } from "ramda";
import { PortfolioTableFieldType } from "../../../../utils/types";
import { insertItemInArray } from "../../../../utils/utils";
import { makeTree } from "../../../../utils/portfolio";
import { ConfirmDelete } from "../../../../utils/confirmationModals";
import {
  addFinancialField,
  deleteFinancialField,
  reorderFinancialFields,
  updateFinancialFields,
} from "../../../../services/services";
import { ResponseType } from "../../../../utils/uiTypes";
import { formItemRequiredRule } from "../../../../utils/formUtils";
import { PRIMARY_BUTTON_STYLE } from "../../../../utils/cssConfigs";
import { getPopupContainerForSelect } from "../../../../utils/container";

const FIELD_TYPES = [
  { label: "Currency", value: "MONETARY" },
  { label: "Other", value: "NUMERIC" },
];

const removeElementFromTree = (
  array: Array<TreeDataFieldType>,
  nodeId: string
): Array<TreeDataFieldType> =>
  array.reduce(
    (acc: Array<TreeDataFieldType>, obj) =>
      obj.id === nodeId
        ? acc
        : [
            ...acc,
            {
              ...obj,
              ...(obj.nestedFields && {
                nestedFields: removeElementFromTree(obj.nestedFields, nodeId),
              }),
            },
          ],
    []
  );
const loop = (
  data: Array<TreeDataFieldType>,
  key: React.Key,
  callback: (
    node: TreeDataFieldType,
    i: number,
    data: TreeDataFieldType[]
  ) => void
) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].id === key) {
      return callback(data[i], i, data);
    }
    if (data[i].nestedFields) {
      loop(data[i].nestedFields!, key, callback);
    }
  }
};
export const EditFinancialSettings: FC<EditFinancialSettingsType> = ({
  portfolioId,
  sectionId,
  data,
  onSave,
}: EditFinancialSettingsType) => {
  const [form] = Form.useForm();
  const [selectedField, setSelectedField] = useState<TreeDataFieldType | null>(
    null
  );
  const [modal, setModal] = useState<ModalType | null>(null);
  const [treeData, setTreeData] = useState<TreeNodesType | null>(null);

  const onCancelModal = () => {
    setModal(null);
    form.resetFields();
  };
  const onDrop: TreeProps["onDrop"] = ({
    node,
    dragNode,
    dropPosition,
    dropToGap,
  }) => {
    const dropKey = node.key;
    const dragKey = dragNode.key;
    const dropPos = node.pos.split("-");
    dropPosition = dropPosition - Number(dropPos[dropPos.length - 1]);

    const data = [...(treeData?.nodes ?? [])];

    // Find dragObject
    let dragObj: TreeDataFieldType;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (
      !dropToGap ||
      (!isEmpty(pathOr([], ["nestedFields"], node)) &&
        node.expanded &&
        dropPosition === 1)
    ) {
      loop(data, dropKey, (item) => {
        item.nestedFields = insertItemInArray(
          0,
          item?.nestedFields ?? [],
          dragObj
        );
      });
    } else {
      let ar: TreeDataFieldType[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!);
      } else {
        ar.splice(i! + 1, 0, dragObj!);
      }
    }
    setTreeData((v) => (v ? { ...v, nodes: data } : null));
  };

  const addField = (data: any) => {
    addFinancialField({
      segments: {
        portfolioId,
        sectionId,
      },
      body: JSON.stringify(data),
    })
      .then(({ data }: ResponseType<PortfolioTableFieldType>) => {
        setTreeData((v) =>
          v ? { nodes: insertItemInArray(v.nodes.length, v.nodes, data) } : null
        );
      })
      .catch(() => {
        message.error(
          "Sorry, unable to add this field. Please try again later!"
        );
      })
      .then(() => {
        onCancelModal();
      });
  };

  const updateField = (data: any) => {
    selectedField &&
      updateFinancialFields({
        segments: {
          portfolioId,
          sectionId,
        },
        body: JSON.stringify([{ ...data, id: selectedField?.id }]),
      })
        .then(({ data }: ResponseType<Array<PortfolioTableFieldType>>) => {
          console.log(selectedField);
          /*setTreeData((v) =>
              v ? { nodes: insertItemInArray(v.nodes.length, v.nodes, data) } : null
          );*/
        })
        .catch(() => {
          message.error(
            "Sorry, unable to add this field. Please try again later!"
          );
        })
        .then(() => {
          onCancelModal();
        });
  };
  const deleteField = (fieldId: string) => {
    deleteFinancialField({
      segments: {
        portfolioId,
        sectionId,
        fieldId,
      },
    })
      .then(() => {
        setTreeData((v) =>
          v ? { nodes: removeElementFromTree(v?.nodes, fieldId) } : null
        );
      })
      .catch(() => {
        message.error(
          "Sorry, unable to delete this field. Please try again later!"
        );
      });
  };

  const reorder = (data: Array<TreeDataFieldType>) => {
    reorderFinancialFields({
      segments: {
        portfolioId,
        sectionId,
      },
      body: JSON.stringify(data),
    })
      .then(() => {
        onSave();
      })
      .catch(() => {
        message.error(
          "Sorry, unable to delete this field. Please try again later!"
        );
      });
  };
  useEffect(() => {
    setTreeData({ nodes: makeTree(data, undefined) });
  }, [data]);

  return (
    <div className={"flex flex-col w-full h-full"}>
      <div className={"flex flex-row items-center justify-end gap-2"}>
        <Button
          className={PRIMARY_BUTTON_STYLE}
          onClick={(): void => {
            setModal("ADD");
          }}
        >
          + Add Field
        </Button>
        <Button
          className={PRIMARY_BUTTON_STYLE}
          onClick={() => {
            reorder(treeData?.nodes ?? []);
          }}
        >
          Save
        </Button>
      </div>
      {treeData && treeData?.nodes?.length > 0 && (
        <Tree<TreeDataFieldType>
          fieldNames={{
            key: "id",
            children: "nestedFields",
          }}
          titleRender={(node) => {
            return (
              <span>
                {node.label}
                {isNil(node.portfolioCompanyTableFieldId) && (
                  <>
                    <i
                      onClick={(): void => {
                        ConfirmDelete(
                          "Are you sure you want to remove this field?",
                          () => {
                            deleteField(node.id);
                          },
                          <span>Field Name: {node.label}</span>
                        );
                      }}
                      className="cursor-pointer ml-2 mr-1 text-danger hover:text-danger-light fa fa-trash-alt"
                    />
                    {/*<i
                      onClick={(): void => {
                        console.log(node)
                        setSelectedField(node)
                        setModal('EDIT');
                        form.setFieldsValue({
                          label: node.label,
                          fieldType: node.fieldType,
                          unit: node.unit
                        });
                      }}
                      className="cursor-pointer ml-1 mr-2 text-primary hover:bg-opacity-80 fa fa-edit"
                  />*/}
                  </>
                )}
              </span>
            );
          }}
          defaultExpandAll={true}
          treeData={treeData.nodes}
          draggable={{
            icon: <MenuOutlined className={"cursor-grab"} />,
          }}
          blockNode
          onDrop={onDrop}
        />
      )}

      <Modal
        open={modal !== null}
        title={"Add Field"}
        closable={true}
        footer={[
          <Button key={"cancel"} onClick={onCancelModal}>
            Cancel
          </Button>,
          <Button
            key={"submit"}
            form={"form"}
            htmlType={"submit"}
            className={PRIMARY_BUTTON_STYLE}
          >
            {modal === "ADD" ? "Create" : "Edit"}
          </Button>,
        ]}
        destroyOnClose={true}
        onCancel={onCancelModal}
      >
        <Form
          form={form}
          name={"form"}
          onFinish={(values): void => {
            const monetaryValue = find<PortfolioTableFieldType>(
              propEq("fieldType", "MONETARY")
            )(data);
            modal === "ADD"
              ? addField({
                  ...values,
                  unit:
                    values.fieldType === "MONETARY"
                      ? monetaryValue?.unit
                      : values.unit,
                })
              : updateField({
                  ...values,
                  unit:
                    values.fieldType === "MONETARY"
                      ? monetaryValue?.unit
                      : values.unit,
                });
          }}
          colon={false}
          requiredMark={false}
        >
          <Form.Item
            name={"fieldType"}
            label={<span className={"w-20"}>Field Type</span>}
            rules={[formItemRequiredRule]}
          >
            <Select
              disabled={modal === "EDIT"}
              getPopupContainer={getPopupContainerForSelect}
              options={FIELD_TYPES}
            />
          </Form.Item>
          <Form.Item
            name={"label"}
            label={<span className={"w-20"}>Field Name</span>}
            rules={[formItemRequiredRule]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.fieldType !== currentValues.fieldType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("fieldType") === "NUMERIC" ? (
                <Form.Item
                  name={"unit"}
                  label={<span className={"w-20"}>Field Unit</span>}
                >
                  <Select
                    getPopupContainer={getPopupContainerForSelect}
                    defaultValue={""}
                    options={[
                      { label: "Numeric", value: "" },
                      { label: "%", value: "%" },
                      { label: "X", value: "X" },
                      { label: "Years", value: "Years" },
                    ]}
                  />
                </Form.Item>
              ) : null
            }
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

type EditFinancialSettingsType = {
  portfolioId: string;
  sectionId: string;
  data: Array<PortfolioTableFieldType>;
  onSave: () => void;
};
type TreeDataFieldType = PortfolioTableFieldType & Partial<DataNode>;
type TreeNodesType = {
  nodes: Array<TreeDataFieldType>;
};
type ModalType = "ADD" | "EDIT";
