import React, { FC, useContext, useEffect } from "react";
import { WithTopNav } from "../components/layout/WithTopNav";
import { MenuRouteType } from "../utils/uiTypes";
import { CompanyProfileForm } from "../components/company/CompanyProfileForm";
import { useHistory, useParams } from "react-router";
import { AllocationTypeSettings } from "../components/company/AllocationTypeSettings";
import { TermsheetTemplateRoutes } from "../components/company/TermsheetTemplateRoutes";
import { TeamManage } from "../components/company/TeamManage";
import { Permissions } from "../components/company/Permissions";
import { UserContext } from "../context/UserContext";
import { CompanyModuleType, UserType } from "../utils/types";
import { usePageTitle } from "../customHooks/usePageTitle";
import { InternalPageNotFound } from "./InternalPageNotFound";
import { CompanySettings } from "../components/company/CompanySettings";
import { DealCloud } from "../components/company/settings/DealCloud";
import { valOrDefault } from "../utils/utils";
import { CompanyTags } from "../components/company/tags/CompanyTags";
import { Webhooks } from "./Webhooks";
import { LeakageTemplateComponent } from "./LeakageTemplate";

const COMPANY_MENU_OPTIONS = (
  user: UserType,
  modulePreference: CompanyModuleType
): MenuRouteType[] => [
  {
    title: "Company Profile",
    Component: CompanyProfileForm,
    exact: true,
    key: "profile",
    path: "/company/profile",
    permission: true,
  },
  {
    title: "Team",
    Component: TeamManage,
    exact: true,
    key: "team",
    path: "/company/team",
    permission: true,
  },
  {
    title: "Templates",
    Component: TermsheetTemplateRoutes,
    exact: false,
    key: "templates",
    path: "/company/templates",
    permission: modulePreference.termsheet,
  },
  {
    title: "Field Settings",
    Component: AllocationTypeSettings,
    exact: true,
    key: "field-settings",
    path: "/company/field-settings",
    permission: true,
  },
  {
    title: "Tags",
    Component: CompanyTags,
    exact: true,
    key: "tags",
    path: "/company/tags",
    permission: modulePreference.tags,
  },
  {
    title: "Permissions",
    Component: Permissions,
    exact: true,
    key: "permissions",
    path: "/company/permissions",
    permission: user.isAdmin && modulePreference.permissions,
  },
  {
    title: "Settings",
    Component: CompanySettings,
    exact: false,
    key: "settings",
    path: "/company/settings",
    permission: user.isAdmin && modulePreference.security,
  },
  {
    title: "Integrations",
    Component: DealCloud,
    exact: false,
    key: "integrations",
    path: "/company/integrations",
    permission: user.isAdmin && valOrDefault(false, modulePreference.dealCloud),
  },
  {
    title: "Webhooks",
    Component: Webhooks,
    exact: true,
    key: "webhooks",
    path: ["/company/webhooks", "/company/webhooks/:menu"],
    permission: user?.isAdmin && valOrDefault(false, modulePreference.webhooks),
  },
  {
    Component: InternalPageNotFound,
    exact: false,
    path: "*",
    permission: true,
    props: { url: "/company/profile" },
    title: "",
    key: "not-found",
    visible: false,
  },
];

export const Company: FC = () => {
  usePageTitle("Company");

  const { user, companyModulePreference } = useContext(UserContext);
  const params: ParamsType = useParams();
  const history = useHistory();

  useEffect(() => {
    if (user && (!user.companyId || !user.hasTeam)) {
      history.push("/company/add");
    }
  }, [user]);

  return (
    <>
      {companyModulePreference && (
        <WithTopNav
          gotoRoute={(link): void => history.push(`/company/${link.key}`)}
          selectedKeys={[params.companyMenu]}
          menu={user ? COMPANY_MENU_OPTIONS(user, companyModulePreference) : []}
          title={user?.companyDTO?.name}
          defaultValue={"profile"}
        />
      )}
    </>
  );
};

type ParamsType = {
  companyMenu: string;
  id: string;
};
