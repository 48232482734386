import React, { FC } from "react";
import CSVIcon from "../../images/fileTypeIcons/csv.svg";
import DOCIcon from "../../images/fileTypeIcons/doc.svg";
import JPGIcon from "../../images/fileTypeIcons/jpg.svg";
import PDFIcon from "../../images/fileTypeIcons/pdf.svg";
import PNGIcon from "../../images/fileTypeIcons/png.svg";
import PPTIcon from "../../images/fileTypeIcons/ppt.svg";
import TXTIcon from "../../images/fileTypeIcons/txt.svg";
import XLSIcon from "../../images/fileTypeIcons/xls.svg";
import ZIPIcon from "../../images/fileTypeIcons/zip.svg";
import RTFIcon from "../../images/fileTypeIcons/rtf.svg";
import DEFAULTIcon from "../../images/fileTypeIcons/file.svg";
import { SizeType } from "antd/es/config-provider/SizeContext";

export const FileIcon: FC<FileIconType> = ({ name, size }: FileIconType) => {
  const parseIcon = (name: string): string => {
    const [extension] = name.split(".").slice(-1);

    // Missing ones: HTML, JPEF, XML
    switch (extension) {
      case "csv":
        return CSVIcon;
      case "doc":
        return DOCIcon;
      case "docx":
        return DOCIcon;
      case "jpg":
        return JPGIcon;
      case "pdf":
        return PDFIcon;
      case "png":
        return PNGIcon;
      case "ppt":
        return PPTIcon;
      case "pptx":
        return PPTIcon;
      case "txt":
        return TXTIcon;
      case "xls":
        return XLSIcon;
      case "xlsx":
        return XLSIcon;
      case "zip":
        return ZIPIcon;
      case "rtf":
        return RTFIcon;
      default:
        return DEFAULTIcon;
    }
  };

  return (
    <img
      alt={""}
      src={parseIcon(name)}
      style={
        size === "small" ? { height: 20, width: 20 } : { height: 35, width: 35 }
      }
    />
  );
};

type FileIconType = {
  name: string;
  size?: SizeType;
};
