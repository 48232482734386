import React, { FC, ReactNode, useEffect, useState } from "react";
import {
  FieldType,
  PortfolioSectionFieldType,
  PortfolioSectionType,
  UnderWritingRowDTO,
  UnderWritingType,
} from "../../../../utils/types";
import { PortfolioInputField } from "../PortfolioInputField";
import { PortfolioCard } from "../PortfolioCard";
import { DisconnectOutlined } from "@ant-design/icons";
import { EmptyPortfolioCard } from "../EmptyPortfolioCard";
import { InputFieldModal } from "../InputFieldModal";
import {
  addUnderwritingTermsRow,
  createNewPortfolioSectionField,
  deletePortfolioSection,
  deleteUnderwritingTermsRow,
  getUnderwritingTerms,
  removeSectionField,
  updatePortfolioRank,
  updatePortfolioSectionField,
  updatePortfolioSectionFieldAnswer,
  updateUnderwritingTermsRow,
} from "../../../../services/services";
import { ResponseType } from "../../../../utils/uiTypes";
import { useHistory, useParams } from "react-router";
import { getIndexByKey, updateItemInArray } from "../../../../utils/utils";
import { Button, Divider, message, Table } from "antd";
import {
  compose,
  filter,
  find,
  findIndex,
  isEmpty,
  isNil,
  pathOr,
  propEq,
  sortBy,
} from "ramda";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import {
  PermissionType,
  PortfolioSectionEnumType,
  SectionFieldType,
  UnitLabelType,
  UnitType,
} from "../../../../utils/enums";
import { initiateFetchSection } from "../../../../utils/portfolio";
import { ConfirmDelete } from "../../../../utils/confirmationModals";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { ACTION_BUTTON_CSS } from "../../../../utils/cssConfigs";
import { ColumnType } from "antd/lib/table";
import EditableTableCell from "../../../general/EditableTableCell";
import "./styles/covenant-table-styles.css";
import { PortfolioAdminPermissionBlock } from "../../PortfolioAdminPermissionBlock"; // Import your stylesheet

const R = require("ramda");

type CovenantSectionType = {
  permissions: PermissionType[];
};

export const UnderwritingTerms: FC<CovenantSectionType> = ({ permissions }) => {
  const history = useHistory();
  const { height: windowHeight } = useWindowDimensions();
  const { portfolioId, sectionId }: ParamsType = useParams();
  const [section, setSection] = useState<PortfolioSectionType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchingUnderwritingTerms, setFetchingUnderwritingTerms] =
    useState<boolean>(false);
  const [addRowModal, setAddRowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [editMode, setEditMode] = useState<editModeType>({ edit: false });
  const [isNewInputOpen, setIsNewInputOpen] = useState<boolean>(false);
  const [portfolioSections, setPortfolioSections] = useState<
    PortfolioSectionFieldType[]
  >([]);

  const [underWritingType, setUnderWritingType] = useState<string>("");

  const [underwritingTableFields, setUnderwritingTableFields] = useState<
    UnderWritingRowDTO[]
  >([]);

  const editPermission = permissions.includes(PermissionType.ADMIN_PORTFOLIO);

  const isDealUnderwritten = (sections: PortfolioSectionFieldType[]) => {
    const underwrittenField = find(
      ({ fieldNameSystemLabel }) => fieldNameSystemLabel === "dealUnderwritten",
      sections
    );
    if (underwrittenField) {
      const yesOption = underwrittenField.options?.find(
        ({ optionValueSystemLabel }) => optionValueSystemLabel === "yes"
      );
      return (
        (underwrittenField?.selectedAnswerIds ?? [])?.length > 0 &&
        (underwrittenField?.selectedAnswerIds ?? [])?.includes(
          yesOption?.id ?? ""
        )
      );
    }
    return false;
  };

  const fetchSection = (sectionId: string): void => {
    setLoading(true);
    initiateFetchSection(sectionId, portfolioId)
      .then(setSection)
      .catch((e: string) => e && message.error(e))
      .then(() => setLoading(false));
  };

  const fetchUnderwritingTerms = (type: string): void => {
    setFetchingUnderwritingTerms(true);
    getUnderwritingTerms({ segments: { portfolioId, sectionId, type } })
      .then((data: ResponseType<UnderWritingRowDTO[]>) => {
        setFetchingUnderwritingTerms(false);
        setUnderwritingTableFields(data.data);
      })
      .catch((e: string) => e && message.error(e));
  };

  const onUpdate: onUpdateType = (field) => {
    updatePortfolioSectionFieldAnswer({
      body: JSON.stringify({ ...field, portfolioId }),
    })
      .then(() => {
        if (section) {
          setSection({
            ...section,
            portfolioSectionFieldDTOS: updateItemInArray(
              getIndexByKey(
                section?.portfolioSectionFieldDTOS ?? [],
                "id",
                field.id
              ),
              section.portfolioSectionFieldDTOS ?? [],
              field
            ),
          });
        }
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Something went wrong, input data not saved.", 3);
      });
  };

  const getMaxRank: getMaxRankType = (list = []) => {
    const ranks = list.map((v) => (v.rank ? Number(v.rank) : 0));
    return Math.max(...ranks);
  };
  const addNewField: addNewFieldType = (fieldData) => {
    if (!isEmpty(fieldData?.label?.trim())) {
      const maxRank = getMaxRank(section?.portfolioSectionFieldDTOS ?? []);
      createNewPortfolioSectionField({
        body: JSON.stringify({
          fieldName: fieldData.label,
          options: fieldData.options,
          portfolioSectionId: section?.id,
          required: true,
          sectionFieldType: fieldData.sectionFieldType,
          rank:
            (maxRank != null || maxRank != undefined) &&
            section &&
            section?.portfolioSectionFieldDTOS?.length > 0
              ? maxRank + 1
              : 0,
          portfolioId,
        }),
      })
        .then(({ data }: ResponseType<PortfolioSectionFieldType>) => {
          const portfolioSectionFieldDTOS = portfolioSections
            ? [...portfolioSections, data]
            : [data];
          if (section)
            setSection({
              ...section,
              portfolioSectionFieldDTOS,
            });
          setPortfolioSections(portfolioSectionFieldDTOS);
        })
        .catch((err: string): void => {
          console.error(err);
          message.error("Error creating new field.", 3);
        })
        .then((): void => {
          setIsNewInputOpen(false);
          setEditMode({ edit: true });
        });
    }
  };

  const updateSectionField: updateFieldType = (fieldData) => {
    updatePortfolioSectionField({
      body: JSON.stringify(fieldData),
    })
      .then(({ data }: ResponseType<PortfolioSectionFieldType>) => {
        const index = findIndex(propEq("id", data.id))(portfolioSections);
        if (index >= 0) {
          setPortfolioSections((list) => updateItemInArray(index, list, data));
          if (section)
            setSection({
              ...section,
              portfolioSectionFieldDTOS: updateItemInArray(
                index,
                portfolioSections,
                data
              ),
            });
        }
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Error updating field.", 3);
      })
      .then((): void => {
        setIsNewInputOpen(false);
        setEditMode({ edit: true });
      });
  };

  const removeField: onUpdateType = (fieldData) => {
    removeSectionField({
      body: JSON.stringify({ ...fieldData, portfolioId }),
    })
      .then(() => {
        const portfolioSectionFieldDTOS = portfolioSections
          ? portfolioSections.filter((sect) => sect.id !== fieldData.id)
          : [];
        if (section)
          setSection({
            ...section,
            portfolioSectionFieldDTOS,
          });
        setPortfolioSections(portfolioSectionFieldDTOS);
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Error removing field.", 3);
      })
      .then((): void => setIsNewInputOpen(false));
  };

  const getPortfolioStatus: getPortfolioStatusType = (
    portfolioSectionsList
  ) => {
    const statusField = portfolioSectionsList.find(
      (item) => item.fieldNameSystemLabel === "portfolioStatus"
    );
    const activeId = statusField?.options?.find(
      (option) => option.optionValueSystemLabel === "active"
    )?.id;
    return (
      !statusField?.selectedAnswerIds ||
      (statusField?.selectedAnswerIds &&
        statusField?.selectedAnswerIds[0] === activeId)
    );
  };

  const openEditField: onUpdateType = (fieldData) => {
    setEditMode({ ...editMode, field: fieldData });
    setIsNewInputOpen(true);
  };

  const onDragging = (oldIndex: number, newIndex: number): void => {
    setPortfolioSections((portfolios) => [
      ...R.move(oldIndex, newIndex, portfolios).map(
        (val: PortfolioSectionFieldType, index: number) => ({
          ...val,
          rank: index.toString(),
        })
      ),
    ]);
  };
  const onDrop = (): void => {
    const updateRankDTO = portfolioSections.map((item) => ({
      id: item.id,
      rank: item.rank,
      portfolioId,
      portfolioSectionId: section?.id,
    }));
    updatePortfolioRank({
      body: JSON.stringify(updateRankDTO),
    }).catch((err: string): void => {
      console.error(err);
      message.error("Error. Update not saved.", 3);
    });
  };

  const initiateSectionDelete = (sectionId: string): void => {
    ConfirmDelete("Confirm Remove Section?", () => {
      setLoading(true);
      deletePortfolioSection({ segments: { sectionId } })
        .then(() => {
          message.success("Section Removed Successfully! ");
          history.go(0);
        })
        .catch((error: string) => {
          message.error("Unable to Delete Section");
          console.error(error);
        })
        .then(() => setLoading(false));
    });
  };

  const sortUnderWritingData = (data: UnderWritingRowDTO[]) => {
    return sortBy(({ rank }) => Number(rank), data);
  };

  const handleAddRow = (tableType: UnderWritingType): void => {
    setSubmitting(true);
    addUnderwritingTermsRow({
      segments: { portfolioId, sectionId, type: underWritingType, tableType },
    })
      .then((data: ResponseType<UnderWritingRowDTO[]>) => {
        setAddRowModal(false);
        setSubmitting(false);
        setUnderwritingTableFields(data.data);
      })
      .catch((error: string) => {
        message.error("Unable to Add Row");
        console.error(error);
      });
  };

  const handleDeleteRow = (
    rowId: string,
    tableType: UnderWritingType
  ): void => {
    setSubmitting(true);
    deleteUnderwritingTermsRow({
      segments: {
        portfolioId,
        sectionId,
        type: underWritingType,
        rowId,
        tableType,
      },
    })
      .then((data: ResponseType<UnderWritingRowDTO[]>) => {
        setSubmitting(false);
        setUnderwritingTableFields(data.data);
      })
      .catch((error: string) => {
        message.error("Unable to Delete Row");
        console.error(error);
      });
  };

  const handleUpdateCellValue = (
    rowId: string,
    body: UnderWritingRowDTO,
    type: UnderWritingType
  ): void => {
    setSubmitting(true);
    updateUnderwritingTermsRow({
      segments: { portfolioId, sectionId, rowId, type },
      body: JSON.stringify(body),
    })
      .then(() => {
        setSubmitting(false);
      })
      .catch((error: string) => {
        message.error("Error. Update not saved.");
        console.error(error);
      });
  };

  useEffect(() => {
    if (section) {
      setPortfolioSections(
        section?.portfolioSectionFieldDTOS?.sort(
          (a, b) => Number(a?.rank) - Number(b?.rank)
        )
      );

      const underWritingType = find(
        (field) => field.fieldNameSystemLabel === "loanOrBonds",
        section?.portfolioSectionFieldDTOS ?? []
      );

      if (underWritingType) {
        const selectedAnswerId = pathOr(
          "",
          ["selectedAnswerIds", 0],
          underWritingType
        );
        const selectedAnswer = find(
          (option) => option.id === selectedAnswerId,
          underWritingType?.options ?? []
        );
        setUnderWritingType(selectedAnswer?.optionValue.toUpperCase() ?? "");
      }
    }
  }, [section]);

  useEffect(() => {
    setEditMode({ edit: false });
    fetchSection(sectionId);
  }, [sectionId]);

  useEffect(() => {
    if (underWritingType) {
      fetchUnderwritingTerms(underWritingType);
    }
  }, [underWritingType]);

  const getFieldTypeData = (
    type: SectionFieldType,
    fieldUnit?: UnitType,
    formula?: string
  ): FieldDataType | undefined => {
    switch (type) {
      case "TEXT":
        return { type: "textarea" };
      case "DROP_BOX":
        return { type: "select" };
      case "CURRENCY":
        return { type: "select" };
      case "DATE":
        return { type: "date" };
      case "MONETARY":
        return !isEmpty(formula) && !isNil(formula)
          ? {
              type: "formula",
              unit: UnitLabelType[fieldUnit ?? UnitType.MILLION],
              currency: section?.reportingCurrency ?? "",
              formula,
            }
          : {
              type: "number",
              unit: UnitLabelType[fieldUnit ?? UnitType.MILLION],
              currency: section?.reportingCurrency ?? "",
            };
      case "NUMERIC":
        return { type: "number", unit: fieldUnit };
      case "LONG_TEXT":
        return { type: "textarea" };
    }
  };

  const deleteCol = (
    tableType: UnderWritingType
  ): ColumnType<UnderWritingRowDTO> => ({
    title: <div className="p-4 whitespace-nowrap bg-gray-700">&nbsp;</div>,
    dataIndex: "",
    key: "delete",
    width: 50,
    fixed: underwritingTableFields.length > 0 ? "right" : false,
    align: "right" as const,
    className:
      "p-0 whitespace-nowrap group-hover:bg-blue-50 transition-none !bg-gray-100",
    render: function actions(_: string, record): ReactNode {
      return record.canDelete ? (
        <div
          className={
            "relative flex flex-row flex-row-reverse items-center gap-x-3 pr-2"
          }
          onClick={(event): void => event.stopPropagation()}
        >
          <Button
            type={"text"}
            icon={<i className="fas fa-ellipsis-h" />}
            className={
              "absolute right-0 border-0 flex items-center px-2 text-gray-900 block opacity-50 group-hover:opacity-0 mr-2"
            }
          />
          <Button
            type={"text"}
            icon={<i className="fas fa-trash-alt" />}
            className={`${ACTION_BUTTON_CSS}  hover:text-primary opacity-0 group-hover:opacity-100`}
            onClick={(): void => {
              ConfirmDelete(
                "Are you sure you want to remove this row? This will erase all previously saved data.",
                () => handleDeleteRow(record.rank, tableType),
                <></>
              );
            }}
          />
        </div>
      ) : (
        <></>
      );
    },
  });

  const getColumns = (
    type: UnderWritingType
  ): ColumnType<UnderWritingRowDTO>[] => [
    {
      title: (
        <div className="p-4 text-white bg-gray-700 whitespace-nowrap">
          {"Term"}
        </div>
      ),
      className: "p-0 whitespace-nowrap !z-20 !bg-gray-100",
      fixed: "left",
      dataIndex: "",
      key: "termName",
      render: (data: UnderWritingRowDTO): ReactNode => {
        return (
          <EditableTableCell
            key={data?.id}
            editable={data.canDelete ? editPermission : false}
            bordered={false}
            OId={data?.id ?? ""}
            value={data?.fieldName ?? ""}
            onChange={(val): void => {
              handleUpdateCellValue(
                data.id,
                {
                  ...data,
                  fieldName: val.toString(),
                },
                type
              );
            }}
            defaultCell={false}
            placeholder={"-"}
          />
        );
      },
    },
    {
      title: (
        <div className="p-4 text-white bg-gray-500 whitespace-nowrap">
          {"Value"}
        </div>
      ),
      className: "p-0 whitespace-nowrap",
      dataIndex: "",
      key: "value",
      render: (data: UnderWritingRowDTO): ReactNode => {
        const fieldType = getFieldTypeData(
          data.fieldType,
          data.unit as UnitType
        );
        return (
          <EditableTableCell
            key={data?.id}
            editable={editPermission}
            bordered={false}
            OId={data?.id ?? ""}
            value={data?.value ?? ""}
            onChange={(val): void => {
              handleUpdateCellValue(
                data.id,
                {
                  ...data,
                  value: val.toString(),
                },
                type
              );
            }}
            defaultCell={false}
            placeholder={"-"}
            prefix={
              fieldType?.currency && section?.reportingCurrency
                ? section.reportingCurrency
                : undefined
            }
            suffix={fieldType?.unit}
            type={fieldType?.type}
          />
        );
      },
    },
  ];

  const RenderLoanTable = (className: string) => (
    <div className={className}>
      <div className={"text-md font-medium text-center"}>
        {"Underwriting Loan Terms"}
      </div>
      <Table
        loading={fetchingUnderwritingTerms}
        pagination={false}
        columns={[
          ...getColumns(UnderWritingType.LOAN),
          // ...(editPermission ? [deleteCol(UnderWritingType.LOAN)] : []),
        ]}
        dataSource={sortUnderWritingData(
          underwritingTableFields.filter(
            ({ underWritingType }) => underWritingType === UnderWritingType.LOAN
          )
        )}
        rowKey={(record, index): string => pathOr("", ["key"], record) ?? index}
        scroll={{ x: "max-content", y: windowHeight / 3 }}
        bordered={true}
        className={"mt-4 shadow"}
        size={"middle"}
        locale={{
          emptyText: "No data in this table yet.",
        }}
        rowClassName={"group "}
        /*
                        summary={(): ReactNode => (
                          <Table.Summary fixed={true}>
                            <PortfolioAdminPermissionBlock permissions={permissions}>
                              <Table.Summary.Row className={"bg-gray-100"}>
                                <Table.Summary.Cell
                                  className={"p-0 !z-20"}
                                  key={"addRow"}
                                  index={0}
                                  colSpan={1}
                                >
                                  <Button
                                    type={"text"}
                                    disabled={true}
                                    //disabled={submitting}
                                    className={
                                      "text-left pl-5 border-0 text-primary disabled:border-opacity-60 font-medium w-full hover:bg-blue-50"
                                    }
                                    onClick={() => handleAddRow(UnderWritingType.LOAN)}
                                  >
                                    + Add Row
                                  </Button>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </PortfolioAdminPermissionBlock>
                          </Table.Summary>
                        )}
                
                         */
      />
    </div>
  );

  const RenderBondTable = (className: string) => (
    <div className={className}>
      <div className={"text-md font-medium text-center"}>
        {"Underwriting Bond Terms"}
      </div>
      <Table
        loading={fetchingUnderwritingTerms}
        pagination={false}
        columns={[
          ...getColumns(UnderWritingType.BOND),
          //...(editPermission ? [deleteCol(UnderWritingType.BRIDGE)] : []),
        ]}
        dataSource={sortUnderWritingData(
          underwritingTableFields.filter(
            ({ underWritingType }) =>
              underWritingType === UnderWritingType.BRIDGE
          )
        )}
        rowKey={(record, index): string => pathOr("", ["key"], record) ?? index}
        scroll={{ x: "max-content", y: windowHeight / 3 }}
        bordered={true}
        className={"mt-4 shadow"}
        size={"middle"}
        locale={{
          emptyText: "No data in this table yet.",
        }}
        rowClassName={"group "}
        /*
                        summary={(): ReactNode => (
                          <Table.Summary fixed={true}>
                            <PortfolioAdminPermissionBlock permissions={permissions}>
                              <Table.Summary.Row className={"bg-gray-100"}>
                                <Table.Summary.Cell
                                  className={"p-0 !z-20"}
                                  key={"addRow"}
                                  index={0}
                                  colSpan={1}
                                >
                                  <Button
                                    type={"text"}
                                    disabled={true}
                                    //disabled={submitting}
                                    className={
                                      "text-left pl-5 border-0 text-primary disabled:border-opacity-60 font-medium w-full hover:bg-blue-50"
                                    }
                                    onClick={() => handleAddRow(UnderWritingType.BRIDGE)}
                                  >
                                    + Add Row
                                  </Button>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </PortfolioAdminPermissionBlock>
                          </Table.Summary>
                        )}
                                                                                                        
                       */
      />
    </div>
  );

  return (
    <React.Fragment>
      <PortfolioCard
        permissions={permissions}
        key={section?.id}
        title={section?.sectionName ?? "Underwriting Terms"}
        editMode={editMode.edit}
        loading={loading}
        deleteMode={section?.sectionType === PortfolioSectionEnumType.CUSTOM}
        onClick={(): void => setEditMode(({ edit }) => ({ edit: !edit }))}
        onDelete={(): void => initiateSectionDelete(sectionId)}
      >
        {portfolioSections ? (
          <>
            <DndProvider backend={HTML5Backend}>
              <div className={"flex flex-col gap-y-1"}>
                {portfolioSections
                  ?.filter(({ fieldNameSystemLabel }) => {
                    if (fieldNameSystemLabel === "loanOrBonds") {
                      return isDealUnderwritten(portfolioSections);
                    } else {
                      return true;
                    }
                  })
                  .map((field, index) => (
                    <PortfolioInputField
                      key={field.id}
                      index={index}
                      onDragging={onDragging}
                      onDrop={onDrop}
                      field={field}
                      disabled={
                        !permissions.includes(PermissionType.ADMIN_PORTFOLIO)
                      }
                      onDelete={removeField}
                      onUpdate={onUpdate}
                      openEditField={openEditField}
                      customizeView={editMode.edit}
                    />
                  ))}
              </div>
            </DndProvider>
          </>
        ) : (
          <EmptyPortfolioCard
            permissions={permissions}
            message={"No input fields in this section yet"}
            buttonText={"Add new data field"}
            onClick={(): void => setIsNewInputOpen(true)}
            image={
              <DisconnectOutlined className={"py-3 text-gray-400 text-7xl"} />
            }
          />
        )}
        <Divider />

        {isDealUnderwritten(portfolioSections) && (
          <>
            {underWritingType === UnderWritingType.BOTH ? (
              <div
                className={
                  "w-auto flex flex-col gap-y-5 lg:flex-row lg:gap-x-5"
                }
              >
                {RenderLoanTable("w-6/12")}
                {RenderBondTable("w-6/12")}
              </div>
            ) : (
              <div>
                {underWritingType === UnderWritingType.LOAN &&
                  RenderLoanTable("w-full")}
                {underWritingType === UnderWritingType.BOND &&
                  RenderBondTable("w-full")}
              </div>
            )}
          </>
        )}
      </PortfolioCard>
      <InputFieldModal
        isVisible={isNewInputOpen}
        editField={editMode.field ?? null}
        onUpdate={updateSectionField}
        onClose={(isOpen): void => {
          setIsNewInputOpen(isOpen);
          if (!isOpen && editMode.field)
            setEditMode({ ...editMode, field: undefined });
        }}
        onCreate={addNewField}
      />
    </React.Fragment>
  );
};

const filterByTypeAndSort = (
  type: UnderWritingType,
  data: UnderWritingRowDTO[]
) =>
  compose(
    filter<UnderWritingRowDTO>(
      ({ underWritingType }) => underWritingType === type
    ),
    sortBy<UnderWritingRowDTO>(({ rank }) => rank)
  )(data);

const UNDERWRITING_DATA = {
  [UnderWritingType.LOAN]: [
    { value: 5, editable: false },
    { value: 1, editable: false },
    {
      value: 0.25,
      editable: false,
    },
    { value: 0, editable: false },
    { value: "", editable: true },
    { value: "", editable: true },
    { value: "", editable: true },
    { value: 3, editable: false },
    { value: 1.25, editable: false },
    { value: 0.63, editable: false },
    { value: 1.88, editable: false },
    { value: 5.62, editable: false },
    { value: 2.88, editable: false },
  ],
  [UnderWritingType.BRIDGE]: [
    { value: "Secured", editable: false },
    { value: 5, editable: false },
    {
      value:
        "0-90 days:75% rebate / 91-180:50% / 181-270:25% / thereafter none",
      editable: false,
    },
    { value: 1.50625, editable: false },
    { value: "3 months from closing", editable: false },
    { value: 3, editable: false },
    { value: "E100MM", editable: false },
    { value: "None", editable: false },
    { value: "None", editable: false },
    { value: "", editable: true },
    { value: "EURIBOR", editable: false },
    { value: "25bps each quarter", editable: false },
    { value: 10, editable: false },
    { value: 1.25, editable: false },
    { value: 1.5, editable: false },
    { value: 1.5, editable: false },
    {
      value:
        "0-90 days:75% rebate / 91-180:50% / 181-270:25% / thereafter none",
      editable: false,
    },
    { value: 1.5, editable: false },
  ],
};

type FieldDataType = {
  unit?: string;
  currency?: string;
  formula?: string;
  type:
    | "number"
    | "select"
    | "textarea"
    | "text"
    | "date"
    | "formula"
    | undefined;
};

type ParamsType = {
  portfolioId: string;
  sectionId: string;
};
type getMaxRankType = (list: PortfolioSectionFieldType[]) => number | undefined;
type onUpdateType = (field: PortfolioSectionFieldType) => void;
type addNewFieldType = (fieldData: FieldType) => void;
type updateFieldType = (fieldData: PortfolioSectionFieldType) => void;
type getPortfolioStatusType = (
  portfolioSectionsList: PortfolioSectionFieldType[]
) => boolean | undefined;
type editModeType = {
  edit: boolean;
  field?: PortfolioSectionFieldType;
};
