import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { ResponseType, voidType } from "../../utils/uiTypes";
import TransactionIcon from "../../images/icons/Transaction.svg";
import {
  getAllocationKeys,
  getIndustries,
  getInstitutions,
} from "../../services/services";
import moment from "moment";
import { Button, DatePicker, Form, Input, message, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  AllocationKeyType,
  CompanyType,
  DealCloudTransactionDTOType,
  IndustryType,
  TransactionType,
} from "../../utils/types";
import { useHistory } from "react-router";
import {
  filterOption,
  getObjectFromPropertyValue,
  getOptionsByKey,
  populateArrayWithPropertyPath,
  valOrDefault,
} from "../../utils/utils";
import {
  AllocationTableType,
  CompanyEnumType,
  PETransactionCreationType,
  PETransactionCreatorType,
  ThirdPartyIntegrationType,
} from "../../utils/enums";
import { equals, pathOr, prop } from "ramda";
import { TRANSACTIONS_PAGE_URL } from "../../utils/redirect";
import { stopPropagationOnEnter } from "../../utils/keyboardUtils";
import { formItemRequiredRule } from "../../utils/formUtils";
import { NewTransactionPaginationType } from "../transaction/newTransaction/NewTransaction";
import { NewOrEditTransactionValueType } from "../../pages/CreateTransaction";
import { getPopupContainerForSelect } from "../../utils/container";
import { DealConnect } from "../dealCloud/DealConnect";
import { UserContext } from "../../context/UserContext";
import { FuzzySearchSelect } from "../general/FuzzySearchSelect";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { CreateInstitutionModal } from "../modals/CreateInstitutionModal";
import { getAbbreviation } from "../../utils/fuzzy-search";
import { statusColumnHeader } from "../../utils/transaction";

export const NewTransactionForm: FC<NewTransactionPaginationType> = function ({
  onNext,
  companyId,
  transaction,
  loading = false,
  isOffline = false,
}: NewTransactionPaginationType) {
  const history = useHistory();
  const [form] = Form.useForm();
  const { companyModulePreference, user } = useContext(UserContext);

  const [industries, setIndustries] = useState<IndustryType[]>([]);
  const [allocationKeys, setAllocationKeys] = useState<AllocationKeyType[]>([]);
  const [institutions, setInstitutions] = useState<CompanyType[]>([]);

  const [newInstitutionInput, setNewInstitutionInput] =
    useState<boolean>(false);
  const [thirdPartyEntity, setThirdPartyEntity] =
    useState<DealCloudTransactionDTOType | null>(null);

  const fetchAllocationKeys: voidType = () => {
    getAllocationKeys({}).then(
      ({ data = [] }: ResponseType<AllocationKeyType[]>) =>
        setAllocationKeys(data)
    );
  };

  const fetchInstitutions: voidType = () => {
    getInstitutions({
      params: {
        isLite: true,
      },
    })
      .then(({ data }: ResponseType<CompanyType[]>) => {
        setInstitutions(data);
      })
      .catch((e: string) => {
        message.error(e ?? "Unable to get Institutions");
      });
  };

  const fetchIndustries: voidType = () => {
    getIndustries({}).then(({ data = [] }: ResponseType<IndustryType[]>) => {
      setIndustries(data);
    });
  };

  const onFinish: onFinishType = (
    values,
    companyId,
    allocationKeys,
    industries
  ) => {
    const parsedDealSponsorId =
      values.sponsorId && JSON.parse(values.sponsorId);

    onNext(
      {
        ...values,
        sponsorId: parsedDealSponsorId?.id || null,
        companyId,
        industryDTOSet: values.industryDTOSet
          ? getOptionsByKey(values.industryDTOSet, industries, "id")
          : [],
        peTransactionTypeName:
          values.peTransactionTypeId &&
          prop(
            "keyName",
            getObjectFromPropertyValue(
              "id",
              values.peTransactionTypeId.toString(),
              allocationKeys
            )
          ),
        investmentTypeName:
          values.investmentTypeId &&
          prop(
            "keyName",
            getObjectFromPropertyValue(
              "id",
              values.investmentTypeId.toString(),
              allocationKeys
            )
          ),
        targetDate: values.targetDate
          ? Date.parse(
              moment(values.targetDate).format("MM/DD/YYYY")
            ).toString()
          : null,
        creationDate: Date.parse(
          moment(values.creationDate).format("MM/DD/YYYY")
        ).toString(),
        ...(thirdPartyEntity
          ? {
              thirdPartyIntegrationType: ThirdPartyIntegrationType.DEAL_CLOUD,
              thirdPartyId: thirdPartyEntity?.EntryId,
            }
          : {}),
      },
      transaction
    );
  };

  useEffect(() => {
    fetchIndustries();
    fetchAllocationKeys();
    fetchInstitutions();
  }, []);

  useEffect(() => {
    if (transaction) {
      form.setFieldsValue({
        ...transaction,
        targetDate: transaction.targetDate
          ? moment(parseInt(transaction.targetDate), "x")
          : null,
        creationDate: transaction.creationDate
          ? moment(parseInt(transaction.creationDate), "x")
          : moment(),
        industryDTOSet: populateArrayWithPropertyPath(
          ["id"],
          transaction.industryDTOSet
        ),
        thirdPartyId: transaction?.thirdPartyId,
      });
    }
  }, [transaction]);

  return (
    <div
      className={"p-6 flex flex-col h-full container mx-auto max-w-screen-lg"}
    >
      {newInstitutionInput && (
        <CreateInstitutionModal
          onClose={() => setNewInstitutionInput(false)}
          onCreate={(v) => {
            const companyDTO = {
              ...v,
              abbreviation: getAbbreviation(v.name),
              CompanyType: null,
              payment: false,
              tncApproved: false,
              industries: [],
              geographiests: [],
              geographies: [],
            };
            setInstitutions((prevState) => [
              ...prevState,
              { ...companyDTO } as CompanyType,
            ]);

            form.setFieldValue("sponsorId", JSON.stringify(companyDTO));
          }}
        />
      )}
      <img src={TransactionIcon} className={"h-32 w-32 mx-auto"} alt={""} />
      <p className={"text-gray-400 font-light mx-auto w-min whitespace-nowrap"}>
        Add the name and description of your transaction
      </p>
      <Form<TransactionType>
        layout={"vertical"}
        onKeyDown={(event): void => {
          if (event.code === "Enter" || event.code === "NumpadEnter")
            form.submit();
        }}
        onFinish={(values): void =>
          onFinish(
            {
              ...values,
              peTransactionCreationType: isOffline
                ? PETransactionCreationType.OFFLINE
                : PETransactionCreationType.ONLINE,
            },
            companyId,
            allocationKeys,
            industries
          )
        }
        form={form}
        initialValues={{
          peTransactionCreationType: isOffline
            ? PETransactionCreationType.OFFLINE
            : PETransactionCreationType.ONLINE,
        }}
        className={"flex flex-col h-full pb-3"}
      >
        {valOrDefault(false, companyModulePreference?.dealCloud) && (
          <Form.Item
            label={statusColumnHeader(
              "Connect DealCloud (Optional)",
              isOffline ? "" : "Private"
            )}
            name={"thirdPartyId"}
          >
            <DealConnect
              onSelect={(entry) => {
                setThirdPartyEntity(entry);
                form.setFieldsValue({
                  thirdPartyId: entry?.EntryId,
                  peTransactionTitle: entry?.DealName?.name ?? "",
                  companyToBuy: entry?.Company?.name ?? "",
                });
              }}
              value={thirdPartyEntity?.EntryId ?? transaction?.thirdPartyId}
              onClear={() => {
                setThirdPartyEntity(null);
                form.resetFields([
                  "peTransactionTitle",
                  "companyToBuy",
                  "thirdPartyId",
                ]);
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          className={"border-0"}
          label={"Name"}
          name={"peTransactionTitle"}
          rules={[formItemRequiredRule]}
        >
          <Input placeholder={"Transaction Title - e.g. 'Project Buyout'"} />
        </Form.Item>
        {isOffline && (
          <Form.Item
            label={statusColumnHeader(
              "Are you creating this offline deal as a Borrower or a Lender?",
              isOffline ? "" : "Private"
            )}
            className={"m-0 mt-5 border-0"}
            name={"peTransactionCreatorType"}
            rules={[formItemRequiredRule]}
          >
            <Select
              disabled={!!transaction}
              getPopupContainer={getPopupContainerForSelect}
              placeholder={"Select Type"}
              options={[
                {
                  value: PETransactionCreatorType.BORROWER,
                  label: "Borrower",
                },
                {
                  value: PETransactionCreatorType.LENDER,
                  label: "Lender",
                },
              ]}
            />
          </Form.Item>
        )}
        {Form.useWatch("peTransactionCreatorType", form) ===
          PETransactionCreatorType.LENDER && (
          <Form.Item
            className={"m-0 mt-5 border-0"}
            name={"dealTeamCompanyId"}
            rules={[formItemRequiredRule]}
            label={statusColumnHeader(
              "Which Borrower institution are you working with?",
              isOffline ? "" : "Private"
            )}
          >
            <Select
              getPopupContainer={getPopupContainerForSelect}
              showSearch={true}
              filterOption={filterOption}
              placeholder={"Select Borrower Institution"}
            >
              {institutions
                .filter(({ id }) => !equals(companyId, id))
                .map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          className={"border-0"}
          label={"Description"}
          name={"description"}
        >
          <TextArea
            placeholder={
              "Deal Description - e.g. 'New financing for buyout of a leading services provider'"
            }
            onKeyDown={stopPropagationOnEnter}
            maxLength={249}
          />
        </Form.Item>
        <Form.Item
          className={"border-0"}
          name={"industryDTOSet"}
          label={statusColumnHeader(
            "Select Industries",
            isOffline ? "" : "Private"
          )}
        >
          <Select
            notFoundContent={"No Industries Available"}
            getPopupContainer={getPopupContainerForSelect}
            mode={"multiple"}
            maxTagCount={"responsive"}
            placeholder={"Select Industries"}
            showArrow={true}
            dropdownRender={(menu): ReactElement => {
              return <div className={"p-0 m-0"}>{menu}</div>;
            }}
            showSearch={true}
            filterOption={filterOption}
            onKeyDown={stopPropagationOnEnter}
          >
            {industries.map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className={"border-0"}
          name={"peTransactionTypeId"}
          label={statusColumnHeader(
            "Transaction Type",
            isOffline ? "" : "Private"
          )}
        >
          <Select
            notFoundContent={"No Types Available"}
            getPopupContainer={getPopupContainerForSelect}
            placeholder={"Transaction Type"}
            showArrow={true}
            dropdownRender={(menu): ReactElement => {
              return <div className={"p-0 m-0"}>{menu}</div>;
            }}
            onKeyDown={stopPropagationOnEnter}
          >
            {allocationKeys
              .filter(
                ({ allocationTableType }) =>
                  allocationTableType === AllocationTableType.TRANSACTION
              )
              .map(({ id, keyName }) => (
                <Select.Option key={id} value={id}>
                  {keyName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        {user?.companyDTO?.companyType !==
          CompanyEnumType.DEBT_ADVISOR_COMPANY && (
          <Form.Item
            className={"border-0"}
            name={"investmentTypeId"}
            label={statusColumnHeader(
              "Investment Type",
              isOffline ? "" : "Private"
            )}
          >
            <Select
              notFoundContent={"No Types Available"}
              getPopupContainer={getPopupContainerForSelect}
              placeholder={"Investment Type"}
              showArrow={true}
              dropdownRender={(menu): ReactElement => {
                return <div className={"p-0 m-0"}>{menu}</div>;
              }}
              onKeyDown={stopPropagationOnEnter}
            >
              {allocationKeys
                .filter(
                  ({ allocationTableType }) =>
                    allocationTableType === AllocationTableType.INVESTMENT
                )
                .map(({ id, keyName }) => (
                  <Select.Option key={id} value={id}>
                    {keyName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          trigger={"onChange"}
          help={
            thirdPartyEntity && (
              <div className={"mt-1 mb-4"}>
                <span className={"text-black"}>DealCloud Company: </span>
                {thirdPartyEntity?.Company?.name}
              </div>
            )
          }
          className={"border-0"}
          label={"Target Company"}
          name={"companyToBuy"}
        >
          <Input placeholder={"Target Company - e.g. 'Termgrid Capital'"} />
        </Form.Item>
        <Form.Item
          trigger={"onChange"}
          hidden={
            user?.companyDTO?.companyType !==
              CompanyEnumType.DEBT_ADVISOR_COMPANY ||
            (isOffline &&
              form.getFieldValue("peTransactionCreatorType") ===
                PETransactionCreatorType.LENDER)
          }
          help={
            thirdPartyEntity && (
              <div className={"mt-1 mb-4"}>
                <span className={"text-black"}>Client Company: </span>
                {thirdPartyEntity?.Company?.name}
              </div>
            )
          }
          className={"border-0"}
          label={statusColumnHeader(
            "Client Company",
            isOffline ? "" : "Private"
          )}
          rules={[
            user?.companyDTO?.companyType ===
              CompanyEnumType.DEBT_ADVISOR_COMPANY &&
            !(
              isOffline &&
              form.getFieldValue("peTransactionCreatorType") ===
                PETransactionCreatorType.LENDER
            )
              ? formItemRequiredRule
              : {},
          ]}
          name={"sponsorId"}
        >
          <FuzzySearchSelect<CompanyType>
            items={institutions}
            getOptionKey={pathOr("", ["id"])}
            getOptionLabel={pathOr("", ["name"])}
            getOptionValue={(item) => (item ? JSON.stringify(item) : "")}
            getAbbrevationPath={pathOr("", ["name"])}
            searchPaths={["name"]}
            notFoundContent={"No Institutions Available"}
            getPopupContainer={getPopupContainerForSelect}
            placeholder={"Client Company"}
            showArrow={true}
            autoFocus={true}
            showSearch={true}
            // mode="single"
            maxTagCount={"responsive"}
            dropdownRender={(menu): ReactElement => {
              return (
                <div className={"p-0 m-0"}>
                  {menu}
                  <div
                    onMouseDown={(e): void => {
                      e.preventDefault();
                      setNewInstitutionInput(true);
                    }}
                    className={`${PRIMARY_BUTTON_STYLE} p-2`}
                  >
                    Create New Institution
                  </div>
                </div>
              );
            }}
            dropdownClassName={"pb-0 mb-0"}
          />
        </Form.Item>

        <Form.Item
          className={"border-0"}
          name={"targetDate"}
          label={isOffline ? "Signing Date" : "Expected Signing Date"}
        >
          <DatePicker
            className={`w-full`}
            format={"MM/DD/YYYY"}
            placeholder={isOffline ? "Signing Date" : "Expected Signing Date"}
            onKeyDown={stopPropagationOnEnter}
          />
        </Form.Item>
        <Form.Item
          className={"border-0"}
          name={"creationDate"}
          initialValue={!isOffline ? moment() : null}
          label={
            <>
              {isOffline ? (
                <div>
                  {statusColumnHeader(
                    "Please enter the creation date for this transaction.",
                    isOffline ? "" : "Private to Deal Team"
                  )}

                  <br />
                  <span className={"text-gray-500 text-xxs"}>
                    (Note: For historical deals, use the correct past date as
                    that will get reflected in analytics)
                  </span>
                </div>
              ) : (
                <>
                  {statusColumnHeader(
                    "Creation Date",
                    isOffline ? "" : "Private to Deal Team"
                  )}
                </>
              )}
            </>
          }
          rules={[formItemRequiredRule]}
        >
          <DatePicker
            className={`w-full`}
            format={"MM/DD/YYYY"}
            placeholder={"Creation Date"}
          />
        </Form.Item>
        <div className={"justify-end w-full flex flex gap-x-2"}>
          {!transaction && (
            <Button
              className={
                "bg-white border hover:border-primary hover:text-primary"
              }
              disabled={loading}
              onClick={(): void => {
                history.push(TRANSACTIONS_PAGE_URL);
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            loading={loading}
            className={"mt-auto bg-primary hover:bg-hover text-white border-0"}
            onClick={form.submit}
          >
            {!transaction ? "Create Transaction" : "Next Step"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

type onFinishType = (
  values: NewOrEditTransactionValueType,
  companyId: string,
  allocationKeys: AllocationKeyType[],
  industries: IndustryType[]
) => void;
