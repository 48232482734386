import React, { FC, useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Tag,
} from "antd";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import {
  AllocationTableMappingType,
  PortfolioAllocationAndFeesType,
} from "../../../utils/types";
import { findIndex, isEmpty, isNil, omit, propEq, values } from "ramda";
import { copyAllocation } from "../../../services/services";
import { ResponseType } from "../../../utils/uiTypes";
import { stopPropagationOnEnter } from "../../../utils/keyboardUtils";
import moment from "moment";
import { PortfolioSectionEnumType } from "../../../utils/enums";
import {
  getModalContainerForFullScreen,
  getPopupContainerForSelect,
} from "../../../utils/container";
import { AllocationTableVersionTypes } from "../../../utils/allocation";

export const NewTableVersionModal: FC<NewTableVersionModalType> = ({
  title,
  visible,
  portfolioId,
  sectionId,
  sectionType,
  versions,
  dates,
  onSave,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);
  const [copyFromVersion, setCopyFromVersion] = useState(false);
  const [tableMapping, setTableMapping] = useState<
    Partial<AllocationTableMappingType>
  >({
    name: "",
  });

  const onClear = () => {
    setCopyFromVersion(false);
    setLoading(false);
    setTableMapping({
      name: "",
    });
  };

  const isDisabled: IsDisabled = (type) => {
    const defaultDisable =
      loading ||
      !tableMapping?.name?.trim() ||
      !tableMapping.allocationDate?.trim();

    switch (type) {
      case PortfolioSectionEnumType.ALLOCATION:
        return copyFromVersion
          ? defaultDisable || !tableMapping.tableVersionType || !tableMapping.id
          : defaultDisable || !tableMapping.tableVersionType;

      case PortfolioSectionEnumType.FEES:
        return copyFromVersion
          ? defaultDisable || !tableMapping.id
          : defaultDisable;

      default:
        return defaultDisable;
    }
  };

  const onSubmit = () => {
    setLoading(true);
    copyAllocation({
      segments: {
        portfolioId,
        sectionId,
      },
      body: JSON.stringify({
        ...tableMapping,
        allocationDate: tableMapping?.allocationDate
          ? moment(tableMapping.allocationDate).valueOf()
          : undefined,
      }),
    })
      .then(({ data }: ResponseType<AllocationTableMappingType>) => {
        onClear();
        onSave(data);
        message.success("Version Added Successfully");
      })
      .catch(() => {
        setLoading(false);
        message.error("Unknown Error");
      });
  };

  return (
    <Modal
      getContainer={getModalContainerForFullScreen}
      title={title}
      open={visible}
      width={600}
      onCancel={() => {
        onClear();
        onCancel();
      }}
      destroyOnClose={true}
      footer={[
        <div className={"w-full text-right"} key={0}>
          <Button
            disabled={loading}
            onClick={() => {
              onClear();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isDisabled(sectionType)}
            loading={loading}
            className={PRIMARY_BUTTON_STYLE}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>,
      ]}
    >
      <div className={"flex flex-col gap-4"}>
        <div className={"grid grid-cols-2 gap-4"}>
          <div>
            <div className={"w-full mb-2"}>Name</div>
            <Input
              placeholder={"Enter table name"}
              value={tableMapping?.name}
              onChange={(e) =>
                setTableMapping((v) => ({ ...v, name: e.target.value }))
              }
            />
          </div>
          <div>
            <div className={"w-full mb-2"}>Date</div>
            <DatePicker
              getPopupContainer={getPopupContainerForSelect}
              disabledDate={(current): boolean => {
                const match = dates.find((o) =>
                  moment(o)
                    .startOf("day")
                    .isSame(moment(current).startOf("day"))
                );
                return match != undefined;
              }}
              onChange={(v) => {
                setTableMapping((k) => ({
                  ...k,
                  allocationDate: v?.format().toString(),
                }));
              }}
              value={
                !isEmpty(tableMapping.allocationDate) &&
                !isNil(tableMapping.allocationDate)
                  ? moment(tableMapping.allocationDate)
                  : null
              }
              className={`w-full`}
              format={"ll"}
              placeholder={"Select date"}
              onKeyDown={stopPropagationOnEnter}
            />
          </div>
        </div>
        {sectionType === PortfolioSectionEnumType.ALLOCATION && (
          <>
            <div>Financing or Holding Event Type</div>
            <Select
              notFoundContent={"No Types Available"}
              getPopupContainer={(trigger): HTMLElement =>
                trigger.parentElement
              }
              placeholder="Select financing or holding event type”"
              showSearch={true}
              optionFilterProp={"title"}
              onSelect={(v) => {
                setTableMapping((k) => ({ ...k, tableVersionType: v }));
              }}
              value={tableMapping?.tableVersionType}
            >
              {values(AllocationTableVersionTypes).map((type) => (
                <Select.Option
                  key={type.value}
                  value={type.value}
                  title={type.label}
                >
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
        <div>
          <div>
            Would you like to copy from a pre-existing version of{" "}
            {sectionType === PortfolioSectionEnumType.ALLOCATION
              ? "mandated allocation"
              : "fees"}
            ?
          </div>
          <Radio.Group
            className={"mt-2"}
            value={copyFromVersion}
            onChange={(e) => {
              const value = e.target.value;
              setCopyFromVersion(value);
              if (!value) {
                setTableMapping((v) => omit(["id"], v));
              }
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>

        {copyFromVersion && (
          <div>
            <div className={"w-full mb-2"}>Select Version</div>
            <Select
              placeholder={"Select Version"}
              getPopupContainer={getPopupContainerForSelect}
              className={"w-60"}
              onSelect={(v: any) => {
                const index = findIndex(propEq("id", v), versions);
                if (index >= 0) {
                  setTableMapping((v) => ({ ...v, id: versions[index]?.id }));
                }
              }}
              suffixIcon={false}
              value={tableMapping.id}
            >
              {versions?.map(({ id, name, allocationDate }) => (
                <Select.Option key={id} value={id}>
                  <div className={"flex flex-row justify-between"}>
                    <div>{name}</div>
                    {!isEmpty(allocationDate) && !isNil(allocationDate) && (
                      <Tag color={"blue"}>
                        {moment(allocationDate).format("ll")}
                      </Tag>
                    )}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
      </div>
    </Modal>
  );
};

type IsDisabled = (sectionType: PortfolioSectionEnumType) => boolean;

type NewTableVersionModalType = {
  title: string;
  visible: boolean;
  portfolioId: string;
  sectionId: string;
  sectionType:
    | PortfolioSectionEnumType.ALLOCATION
    | PortfolioSectionEnumType.FEES;
  versions: AllocationTableMappingType[];
  dates: string[];
  onSave: (value: AllocationTableMappingType) => void;
  onCancel: () => void;
};
