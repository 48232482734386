import React, { FC, Key, ReactNode, useContext, useState } from "react";
import { getSearchResults } from "../../services/services";
import { ResponseType } from "../../utils/uiTypes";
import {
  SearchResponseType,
  SearchResultsResponseType,
} from "../../utils/types";
import { Badge, Button, Checkbox, message, Table, Tag, Tooltip } from "antd";
import { PrecedentSearchFilters } from "./PrecedentSearchFilters";
import { ColumnsType } from "antd/es/table";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import {
  activePastArray,
  TRANSACTION_STATUSES,
  transactionTagsArray,
} from "../../utils/transaction";
import { arrayToMapConversion } from "../../utils/utils";
import { convertDateToFormat } from "../../utils/moment";
import {
  LeftOutlined,
  MergeCellsOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router";
import { dropLast, isEmpty, isNil, last, uniq, without } from "ramda";
import {
  getDefaultPrecedentFilters,
  mapResponsesReverse,
} from "../../utils/precedent";
import { PETransactionTagType } from "../../utils/enums";
import { tableColumnHeader } from "../../utils/componentUtils";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { UserContext } from "../../context/UserContext";

const TransactionsTagsMap = arrayToMapConversion(
  transactionTagsArray,
  "value",
  []
);
const ActivePastArray = arrayToMapConversion(activePastArray, "value", []);

export const PrecedentSearch: FC = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<PSFiltersType>(
    getDefaultPrecedentFilters(user?.elementPermissions)
  );

  const [searchResults, setSearchResults] =
    useState<SearchResultsResponseType | null>(null);
  const [selectedTermSheets, setSelectedTermSheets] = useState<Key[]>([]);
  const [navigation, setNavigation] = useState<(string | null)[]>([]);

  const fetchSearchResults = (filters: PSFiltersType): void => {
    setLoading(true);
    const searchFilters = {
      ...filters,
      versions: [filters.versions],
      portfolioEnabled:
        filters.listType === "PORTFOLIO" || filters.listType === "ALL",
      dealsEnabled:
        filters.listType === "TRANSACTION" || filters.listType === "ALL",
    };

    getSearchResults({ body: JSON.stringify(searchFilters) })
      .then(({ data }: ResponseType<SearchResultsResponseType>) => {
        setSearchResults(data);
        setLoading(false);
      })
      .catch((error: string) => {
        message.error(error);
        console.error(error);
        setLoading(false);
      });
  };

  const onChangeSelection = (value: string) => (e: CheckboxChangeEvent) => {
    if (selectedTermSheets.length < 20 && e.target.checked)
      setSelectedTermSheets((selected) => uniq([...selected, value]));
    else if (!e.target.checked) {
      setSelectedTermSheets((selected) => without([value], selected));
    }
  };

  const columns: ColumnsType<SearchResponseType> = [
    {
      dataIndex: "id",
      render: (value) => (
        <Checkbox
          checked={selectedTermSheets.includes(value)}
          onChange={onChangeSelection(value)}
        />
      ),
    },
    {
      title: tableColumnHeader("Transaction Name"),
      width: "fit-content",
      dataIndex: "transactionName",
      className: "min-w-[80px]",
      render: (
        transactionName: string,
        { isOffline = false, isPortfolio = false }
      ) => (
        <div>
          {transactionName}&nbsp;
          {isOffline && <Tag color={"gold"}>OFFLINE</Tag>}
          {isPortfolio && <Tag color={"volcano"}>PORTFOLIO</Tag>}
        </div>
      ),
    },
    {
      title: tableColumnHeader("Target Company"),
      width: "fit-content",
      dataIndex: "targetCompany",
      className: "min-w-[140px]",
    },
    {
      title: tableColumnHeader("Status"),
      width: "fit-content",
      dataIndex: "status",
      className: "min-w-[80px]",
      render: (status: PETransactionTagType): ReactNode => {
        if (TRANSACTION_STATUSES.includes(status)) {
          const { color, label } = TransactionsTagsMap[status];
          return (
            <span className={"flex flex-row"}>
              {color && <Badge offset={[0, 0]} dot color={color} />}&nbsp;
              {label}
            </span>
          );
        }
        if (status) {
          const { color, label } = ActivePastArray[status];
          return (
            <span className={"flex flex-row"}>
              {color && <Badge offset={[0, 0]} dot color={color} />}&nbsp;
              {label}
            </span>
          );
        }
        return null;
      },
    },
    {
      title: tableColumnHeader("Termsheet Name"),
      width: "fit-content",
      dataIndex: "termSheetName",
      className: "min-w-[140px]",
    },
    {
      title: tableColumnHeader("Version Date"),
      width: "fit-content",
      dataIndex: "createdDate",
      className: "min-w-[120px]",

      render: (date: string) => convertDateToFormat(date, "D.MMM.YY", false),
    },
    {
      title: tableColumnHeader("Created By"),
      width: "fit-content",
      dataIndex: "createdByUser",
      className: "min-w-[80px]",
    },
    {
      title: tableColumnHeader("Create by Institution"),
      width: "fit-content",
      dataIndex: "dealTeamName",
      className: "min-w-[180px]",
    },
    {
      title: tableColumnHeader("Shared With Institution"),
      width: "fit-content",
      dataIndex: "lenderCompanyName",
      className: "min-w-[180px]",
      render: (responded: boolean, record): ReactNode => (
        <div className={"flex flex-row items-center gap-2"}>
          {record.lenderCompanyName}
          {!isNil(record?.lenderActive) && record?.lenderActive === false && (
            <Tag color={"red"} key={0} className={"uppercase"}>
              REMOVED
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: tableColumnHeader("Responded"),
      width: "fit-content",
      dataIndex: "lenderPublished",
      className: "min-w-[100px]",
      align: "center",
      render: (responded: boolean): ReactNode =>
        responded ? (
          <Tooltip title={"Institution Responded"} placement={"bottom"}>
            <i className="fa-solid fa-check-double text-success"></i>
          </Tooltip>
        ) : (
          <></>
        ),
    },
  ];

  const stringifyParams = (filters: PSFiltersType): string => {
    return new URLSearchParams([
      ["size", (filters.size ?? "25").toString()],
      // ["bidMetricSearchText", filters.bidMetricSearchText ?? ""],
      ["lenderPublished", mapResponsesReverse(filters.lenderPublished)],
      filters.fromTimestamp
        ? ["fromTimestamp", filters.fromTimestamp.toString()]
        : ["", ""],
      filters.toTimestamp
        ? ["toTimestamp", filters.toTimestamp.toString()]
        : ["", ""],
      ["versions", filters?.versions || "LAST"],
      ...(filters.industryIds ?? []).map((val) => ["industryIds", val]),
      ...(filters.companyIds ?? []).map((val) => ["companyIds", val]),
      ...(filters.dealStatuses ?? []).map((val) => ["dealStatuses", val]),
      ["listType", filters?.listType || "ALL"],
    ]).toString();
  };

  const onFiltersChange = (filters: PSFiltersType): void => {
    fetchSearchResults(filters);
    setSelectedFilters(filters);
    const params = new URLSearchParams(stringifyParams(filters)).toString();

    history.replace({
      pathname: "/search",
      search: params,
    });
  };
  return (
    <div
      className={
        "relative max-h-full w-full h-screen bg-gray-100 flex flex-col"
      }
    >
      <div className={"mb-3 flex flex-row items-center p-6 pb-0"}>
        <span className={"text-2xl font-medium"}>Precedent Search</span>
      </div>
      <div className={`w-full h-full flex flex-col overflow-y-auto p-6 pt-0`}>
        <PrecedentSearchFilters
          selectedFilters={selectedFilters}
          onChange={(filters) => {
            onFiltersChange({ ...filters, pageToken: null });
            setNavigation([]);
          }}
        />
        <div className={"max-h-full overflow-y-scroll mt-2"}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={loading ? [] : searchResults?.results ?? []}
            pagination={false}
            scroll={{ x: true }}
            sticky={true}
            rowKey={"id"}
            className={"border"}
            rowClassName={"group"}
          />
        </div>
        <div
          className={
            "flex flex-col-reverse sm:flex-row items-center justify-between mt-4 mr-2 gap-3"
          }
        >
          <Button
            disabled={isEmpty(selectedTermSheets)}
            onClick={(): void => {
              const queries = new URLSearchParams([
                ...selectedTermSheets.map((id) => ["ids", id.toString()]),
                ["semanticSearch", "true"],
              ]).toString();

              history.push({ pathname: "/search/details", search: queries });
            }}
            className={`${PRIMARY_BUTTON_STYLE} w-full sm:w-fit`}
            icon={<MergeCellsOutlined />}
          >
            Show ({selectedTermSheets.length})
          </Button>
          <div className={"inline-flex items-center gap-2 w-full sm:w-fit"}>
            <Button
              className={`${PRIMARY_BUTTON_STYLE} w-full sm:w-fit`}
              onClick={(): void => {
                onFiltersChange({
                  ...selectedFilters,
                  pageToken: last(navigation) ?? null,
                });
                setNavigation(dropLast(1, navigation));
              }}
              icon={<LeftOutlined />}
              disabled={isEmpty(navigation)}
            >
              Previous
            </Button>
            <Button
              className={`${PRIMARY_BUTTON_STYLE} w-full sm:w-fit`}
              icon={<RightOutlined />}
              onClick={(): void => {
                setNavigation([...navigation, selectedFilters.pageToken]);
                onFiltersChange({
                  ...selectedFilters,
                  pageToken: searchResults?.nextPageToken ?? null,
                });
              }}
              disabled={
                isEmpty(searchResults?.nextPageToken) ||
                isNil(searchResults?.nextPageToken) ||
                (searchResults?.results ?? [])?.length < selectedFilters.size
              }
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export type PSFiltersType = {
  size: number;
  // bidMetricSearchText?: string;
  industryIds: string[];
  companyIds: string[];
  fromTimestamp: number | null;
  toTimestamp: number | null;
  pageToken: string | null;
  lenderPublished: boolean | null;
  dealStatuses: string[];
  listType: string;
  versions: string;
};
