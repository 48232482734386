import { createContext, Dispatch, SetStateAction } from "react";
import { ConfigType } from "../../utils/enums";
import {
  CompanySettingConfigType,
  DealCloudConfigType,
} from "../../utils/types";

export const ConfigurationContext = createContext<ConfigurationContextType>({
  configValues: { DEAL_CLOUD_CONFIG: null, PASSWORD_POLICY: null },
  setConfigValues: () => {},
});

type ConfigurationContextType = {
  configValues: Record<ConfigType, CompanySettingConfigType | null>;
  setConfigValues: (
    config: ConfigType,
    value: CompanySettingConfigType
  ) => void;
};
