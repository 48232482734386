import React, { FC, ReactNode } from "react";
import { equals } from "ramda";

const getBgColor = (color?: BadgeColor): string => {
  switch (color) {
    case "primary":
      return "bg-primary";
    case "secondary":
      return "bg-secondary";
    case "success":
      return "bg-success";
    case "danger":
      return "bg-danger";
    case "warning":
      return "bg-warning";
    default:
      return "bg-primary";
  }
};

export const TGBadge: FC<TGBadgeType> = ({
  className,
  children,
  size = "small",
  color,
}) => {
  return children ? (
    <span
      className={`text-white ${getBgColor(color)} p-0.5 rounded ${
        equals(size, "small") ? "text-xxs" : "text-sm"
      } normal-case mb-1 mx-1 ${className}`}
    >
      {children}
    </span>
  ) : (
    <></>
  );
};

export type BadgeColor =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning";
type TGBadgeType = {
  children?: ReactNode | ReactNode[];
  className?: string;
  size?: "small" | "normal";
  color?: BadgeColor;
};
