import React, { FC, useState } from "react";
import { Button, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";

const MAX_VISIBLE_SIZE = 500;
const renderJson: renderJsonType = (
  jsonString,
  isCollapsed,
  indentSize = 2
): string => {
  const displayJsonString =
    isCollapsed && jsonString.length > MAX_VISIBLE_SIZE
      ? jsonString.slice(0, MAX_VISIBLE_SIZE) + "..."
      : jsonString;
  return `<pre><code>${displayJsonString}</code></pre>`;
};

export const JSONCodeBlock: FC<JSONCodeBlockType> = ({
  width = "fit",
  indentSize = 2,
  json,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const jsonString = JSON.stringify(json, null, indentSize);
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(JSON.stringify(json, null, 2))
      .then(() => {
        message.success("JSON copied");
      })
      .catch(() => {
        message.error("JSON could not be copied");
      });
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div
      className={`bg-gray-100 border border-gray-300  rounded ${
        width === "fit" ? "w-fit" : "w-full"
      }`}
    >
      <div className="flex flex-row justify-between items-center bg-gray-200 px-6 py-1">
        <Button
          type={"link"}
          className={"text-gray-700 hover:text-gray-800 px-0"}
          icon={<CopyOutlined className={"w-fit"} />}
          onClick={handleCopyClick}
        />
        <Button
          hidden={jsonString.length <= MAX_VISIBLE_SIZE}
          type={"link"}
          className={"text-gray-700 hover:text-gray-800 px-0"}
          onClick={toggleCollapse}
        >
          {collapsed ? `Show More` : `Show Less`}
        </Button>
      </div>
      <div className={"p-6 pr-24"}>
        <div
          dangerouslySetInnerHTML={{
            __html: renderJson(jsonString, collapsed, indentSize),
          }}
        ></div>
      </div>
    </div>
  );
};

type renderJsonType = (
  jsonString: string,
  isCollapsed: boolean,
  indentSize?: number
) => string;
type JSONCodeBlockType = {
  width?: "full" | "fit";
  indentSize?: number;
  json: Record<string, any>;
};
