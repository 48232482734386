import { SelectWithBadgeOptionType } from "./componentUtils";
import {
  arrayToMapConversion,
  defaultIfEmptyOrNil,
  isEmptyOrNil,
} from "./utils";
import { PeTransactionFilter, TransactionType } from "./types";
import { and, equals, mergeDeepRight, pathOr } from "ramda";
import {
  CompanyEnumType,
  PermissionType,
  PETransactionCreationType,
  PETransactionCreatorType,
  PETransactionTagType,
  SortDirection,
  StatusType,
} from "./enums";
import React, { ReactNode } from "react";
import { Badge, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

export enum TRANSACTION_USER_ROLE {
  ONLINE_DEAL,
  ONLINE_LENDER,
  OFFLINE_DEAL,
  OFFLINE_LENDER,
}

export const statusColumnHeader = (field: string, title: string) => (
  <span>
    {field}&nbsp;
    {title && (
      <Tooltip title={title}>
        <InfoCircleFilled size={1} />
      </Tooltip>
    )}
  </span>
);

export const getUserRole = (
  transaction: TransactionType
): TRANSACTION_USER_ROLE => {
  const { permissionDTO, peTransactionCreationType: creationType } =
    transaction;

  const isDealTeam =
    permissionDTO.permissions.includes(PermissionType.ADMIN_PETRANSACTION) ||
    permissionDTO.permissions.includes(PermissionType.NON_ADMIN_PETRANSACTION);

  if (equals(creationType, PETransactionCreationType.ONLINE)) {
    if (isDealTeam) return TRANSACTION_USER_ROLE.ONLINE_DEAL;
    else return TRANSACTION_USER_ROLE.ONLINE_LENDER;
  } else {
    if (isDealTeam) return TRANSACTION_USER_ROLE.OFFLINE_DEAL;
    else return TRANSACTION_USER_ROLE.OFFLINE_LENDER;
  }
};

export const transactionTagsArray: SelectWithBadgeOptionType[] = [
  { value: PETransactionTagType.LIVE, color: "blue", label: "Live" },
  {
    value: PETransactionTagType.SIGNED_NOT_CLOSED,
    color: "green",
    label: "Signed, not closed",
  },
  { value: PETransactionTagType.COMPLETED, color: "green", label: "Completed" },
  { value: PETransactionTagType.DROPPED, color: "red", label: "Dropped" },
  { value: PETransactionTagType.LOST, color: "red", label: "Lost" },
];

export const TRANSACTION_STATUSES = [
  PETransactionTagType.LIVE,
  PETransactionTagType.COMPLETED,
  PETransactionTagType.SIGNED_NOT_CLOSED,
  PETransactionTagType.LOST,
  PETransactionTagType.DROPPED,
];

export const TransactionsTagsMap = arrayToMapConversion(
  transactionTagsArray,
  "value",
  []
);
export const isOffline: isType = (transaction) =>
  equals(
    transaction?.peTransactionCreationType,
    PETransactionCreationType.OFFLINE
  );
export const isBorrower: isType = (transaction) =>
  equals(
    transaction?.peTransactionCreatorType,
    PETransactionCreatorType.BORROWER
  );
export const isLender: isType = (transaction) =>
  equals(
    transaction?.peTransactionCreatorType,
    PETransactionCreatorType.LENDER
  );

export const isOfflineLender: isType = (transaction): boolean =>
  and(isLender(transaction), isOffline(transaction));
export const isOfflineBorrower: isType = (transaction): boolean =>
  and(isBorrower(transaction), isOffline(transaction));

export const renderTransactionTagStatus = (
  val: PETransactionTagType
): ReactNode => {
  if (TRANSACTION_STATUSES.includes(val)) {
    const { color, label } = TransactionsTagsMap[val];
    return (
      <span className={"flex flex-row"}>
        {color && <Badge offset={[0, 0]} dot color={color} />}&nbsp;&nbsp;
        {label}
      </span>
    );
  }
  return "-";
};

type isType = (transaction?: TransactionType | null) => boolean;
export const activePastArray: SelectWithBadgeOptionType[] = [
  {
    value: StatusType.ACTIVE,
    color: "green",
    label: StatusType.ACTIVE,
  },
  { value: StatusType.PAST, color: "red", label: StatusType.PAST },
];

export const DEFAULT_TRANSACTION_FILTERS: PeTransactionFilter = {
  searchKey: "",
  activeLendersOnly: false,
  createdByMe: false,
  debtType: [],
  direction: SortDirection.DESC,
  industries: [],
  investmentType: [],
  lenders: [],
  size: 10,
  pageIndex: 0,
  sortColumn: "creationDate",
  sponsor: [],
  status: [],
  transactionType: [],
  fromTimestamp: null,
  toTimestamp: null,
  region: [],
  createdBy: [],
};

export const getSelectedFilters = (): PeTransactionFilter => {
  const search = new URLSearchParams(location.search);

  return {
    searchKey: defaultIfEmptyOrNil(
      DEFAULT_TRANSACTION_FILTERS.searchKey,
      search.get("searchKey") ?? ""
    ),
    activeLendersOnly: defaultIfEmptyOrNil(
      DEFAULT_TRANSACTION_FILTERS.activeLendersOnly,
      Boolean(search.get("activeLendersOnly"))
    ),
    createdByMe:
      search.get("createdByMe") === "true"
        ? true
        : DEFAULT_TRANSACTION_FILTERS.createdByMe,
    debtType: defaultIfEmptyOrNil(
      DEFAULT_TRANSACTION_FILTERS.debtType,
      search.getAll("debtType")
    ),
    direction: defaultIfEmptyOrNil(
      DEFAULT_TRANSACTION_FILTERS.direction,
      SortDirection.DESC
    ),
    industries: defaultIfEmptyOrNil(
      DEFAULT_TRANSACTION_FILTERS.industries,
      search.getAll("industries")
    ),
    investmentType: defaultIfEmptyOrNil(
      DEFAULT_TRANSACTION_FILTERS.investmentType,
      search.getAll("investmentType")
    ),
    lenders: defaultIfEmptyOrNil(
      DEFAULT_TRANSACTION_FILTERS.lenders,
      search.getAll("lenders")
    ),
    size: defaultIfEmptyOrNil(DEFAULT_TRANSACTION_FILTERS.size, 10),
    pageIndex: defaultIfEmptyOrNil(DEFAULT_TRANSACTION_FILTERS.pageIndex, 0),
    sortColumn: defaultIfEmptyOrNil(
      DEFAULT_TRANSACTION_FILTERS.sortColumn,
      "creationDate"
    ),
    sponsor: defaultIfEmptyOrNil(
      DEFAULT_TRANSACTION_FILTERS.sponsor,
      search.getAll("sponsor")
    ),
    status: defaultIfEmptyOrNil(
      DEFAULT_TRANSACTION_FILTERS.status,
      search.getAll("status")
    ),
    transactionType: defaultIfEmptyOrNil(
      DEFAULT_TRANSACTION_FILTERS.transactionType,
      search.getAll("transactionType")
    ),
    region: defaultIfEmptyOrNil(
      DEFAULT_TRANSACTION_FILTERS.region,
      search.getAll("region")
    ),
    createdBy: defaultIfEmptyOrNil(
      DEFAULT_TRANSACTION_FILTERS.createdBy,
      search.getAll("createdBy")
    ),
    fromTimestamp: isEmptyOrNil(search.get("fromTimestamp"))
      ? DEFAULT_TRANSACTION_FILTERS.fromTimestamp
      : Number(search.get("fromTimestamp")),
    toTimestamp: isEmptyOrNil(search.get("toTimestamp"))
      ? DEFAULT_TRANSACTION_FILTERS.toTimestamp
      : Number(search.get("toTimestamp")),
  };
};

export const COLUMN_BY_COMPANY: Record<string, CompanyEnumType[]> = {
  dealCompany: [CompanyEnumType.LENDER, CompanyEnumType.LENDER_SPONSOR],
  clientCompany: [CompanyEnumType.DEBT_ADVISOR_COMPANY],
  investmentTypeId: [
    CompanyEnumType.LENDER,
    CompanyEnumType.LENDER_SPONSOR,
    CompanyEnumType.SPONSOR,
  ],
  createdByUserDTO: [
    CompanyEnumType.SPONSOR,
    CompanyEnumType.DEBT_ADVISOR_COMPANY,
    CompanyEnumType.LENDER_SPONSOR,
  ],
  creatorCompanyName: [
    CompanyEnumType.SPONSOR,
    CompanyEnumType.DEBT_ADVISOR_COMPANY,
    CompanyEnumType.LENDER_SPONSOR,
  ],
};
