import React, { FC, useEffect, useState } from "react";
import { ResponseType } from "../../../../utils/uiTypes";
import { Button, Form, Input, message, Modal, Spin } from "antd";
import { addInstitution, getInstitutions } from "../../../../services/services";
import {
  CompanyType,
  LenderType,
  TransactionType,
} from "../../../../utils/types";
import { AddInstitutionForm } from "../../../forms/AddInstitutionForm";
import {
  populateArrayWithPropertyPath,
  valOrDefault,
} from "../../../../utils/utils";
import { useForm } from "antd/es/form/Form";
import { CreateInstitutionModal } from "../../../modals/CreateInstitutionModal";
import { getAbbreviation } from "../../../../utils/fuzzy-search";

export const AddInstitution: FC<AddInstitutionType> = function ({
  transaction,
  updateTransaction,
  onClear,
}: AddInstitutionType) {
  const [newInstitutionInput, setNewInstitutionInput] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [institutions, setInstitutions] = useState<CompanyType[]>([]);

  const [addInstitutionFormInstance] = useForm();

  const fetchInstitutions: fetchInstitutionsType = (lenderDTOs) => {
    getInstitutions({
      params: {
        isLite: "true",
      },
    })
      .then(({ data = [] }: ResponseType<CompanyType[]>) => {
        const lenderIds = populateArrayWithPropertyPath(
          ["companyDTO", "id"],
          lenderDTOs
        );
        const filtered = data?.filter(
          (val: CompanyType) =>
            !lenderIds?.includes(val.id) && val.id !== transaction.companyId
        );
        setInstitutions(filtered);
      })
      .catch((error: string) => {
        message.error(error ? error : "Error Fetching Institutions!");
      });
  };

  const addLenders: addLenderType = (values, transactionId) => {
    setLoading(true);
    const parseData = (values: {
      companyDTO: Array<string>;
    }): Array<Record<string, string>> => {
      return values.companyDTO.map((val) => {
        return {
          companyDTO: JSON.parse(val),
          privateEquityTransactionId: transactionId,
        };
      });
    };

    addInstitution({
      body: JSON.stringify(parseData(values)),
    })
      .then(({ data = [] }: ResponseType<LenderType[]>) => {
        updateTransaction({
          ...transaction,
          lenderDTOs: valOrDefault([], data),
        });
      })
      .then(() => {
        message.success("Added Institution Successfully!");
        setLoading(false);
        onClear();
      })
      .catch((error: string) => {
        message.error(error ? error : "Error Adding New Institution!");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchInstitutions(transaction.lenderDTOs);
  }, [transaction?.lenderDTOs]);

  return (
    <Spin spinning={loading}>
      <AddInstitutionForm
        onSubmit={(values): void => {
          addLenders(values, transaction.peTransactionId);
        }}
        setNewInstitutionInput={setNewInstitutionInput}
        institutions={institutions}
        close={onClear}
        form={addInstitutionFormInstance}
      />
      {newInstitutionInput && (
        <CreateInstitutionModal
          onClose={() => setNewInstitutionInput(false)}
          onCreate={(value) => {
            const values = addInstitutionFormInstance.getFieldsValue();
            addInstitutionFormInstance.setFieldsValue({
              ...values,
              companyDTO: [
                JSON.stringify({
                  ...value,
                  abbreviation: getAbbreviation(value.name),
                }),
                ...(values.companyDTO ?? []),
              ],
            });
            fetchInstitutions(transaction?.lenderDTOs);
          }}
        />
      )}
    </Spin>
  );
};

type AddInstitutionType = {
  transaction: TransactionType;
  onClear: () => void;
  updateTransaction: (data: TransactionType) => void;
};

type fetchInstitutionsType = (lenderDTOs: LenderType[]) => void;
export type newInstitutionType = (
  value: { name: string },
  lenderDTOs: LenderType[]
) => void;
type addLenderType = (
  values: { companyDTO: Array<string> },
  transactionId: string
) => void;
