import React, { FC } from "react";
import { RESIZE_COL } from "../utils/css";

export const ColumnResizeBlock: FC<ColumnResizeBlockType> = ({
  id,
  onResize,
  startWidth,
  isResizing = false,
}) => {
  return (
    <div
      className={`flex w-0.5 h-full cursor-col-resize hover:bg-primary focus:bg-primary py-1.5 ${
        isResizing ? RESIZE_COL : "bg-transparent"
      }`}
      onMouseDownCapture={(event): void =>
        onResize(event, {
          columnId: id,
          startWidth,
        })
      }
    >
      &nbsp;
    </div>
  );
};

type ColumnResizeBlockType = {
  isResizing: boolean;
  id: string;
  onResize: onColumnResize;
  startWidth: number;
};

type MouseDownParams = {
  columnId: string;
  startWidth: number;
};

export type onColumnResize = (
  e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  { columnId, startWidth }: MouseDownParams
) => void;
