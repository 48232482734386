export enum ComparisonColumnType {
  BASE = "BASE",
  METRICS = "METRICS",
  COLUMNS = "COLUMNS",
}
export type CompareSheetSelectedCell = {
  type: ComparisonColumnType;
  columnIndex?: number;
  rowIndex: number;
};
