import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { InputField, InputType } from "./InputField";
import {
  RelationshipInstitutionLabelType,
  RelationshipInstitutionType,
  RelationshipInvestmentLabelType,
  RelationshipInvestmentType,
  RelationshipStructureLabelType,
  RelationshipStructureType,
} from "../../utils/enums";
import {
  getAllTeamMembers,
  getCountries,
  getGeographies,
  getIndustries,
  getPortfoliosForCrmMappings,
  updateCrmCompanyProfile,
} from "../../services/services";
import { columnSort, isAdmin } from "../../utils/relationship";
import {
  CountryType,
  CRMCompanyMappingType,
  GeographyType,
  IndustryType,
  MonetaryRangeType,
  PortfolioType,
  TeamMemberType,
  UserType,
} from "../../utils/types";
import { ResponseType, voidType } from "../../utils/uiTypes";
import { isNil, lensProp, set } from "ramda";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { Button, message } from "antd";
import { RelationshipCard } from "./RelationshipCard";
import { UserContext } from "../../context/UserContext";
import { PromptDialog } from "../portfolio/PromptDialog";

export const RelationshipProfile: FC<CrmProfileType> = ({ data, onSave }) => {
  const { user } = useContext(UserContext);

  const [isSaved, setIsSaved] = useState(true);
  const [industries, setIndustries] = useState<IndustryType[]>([]);
  const [geographies, setGeographies] = useState<GeographyType[]>([]);
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState<CRMCompanyMappingType | null>(
    data
  );
  const [portfolios, setPortfolios] = useState<PortfolioType[]>([]);
  const [teamMembers, setTeamMembers] = useState<TeamMemberType[]>([]);

  const initiateUpdateProfile: initiateUpdateProfileType = (body) => {
    setLoading(true);
    updateCrmCompanyProfile({
      body: JSON.stringify(body),
      params: location.search,
    })
      .then(({ data }: ResponseType<CRMCompanyMappingType>) => {
        onSave(data);
        setLoading(false);
        setIsSaved(true);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(error);
        setLoading(false);
      });
  };

  const updateProfile: updateProfileType = (property) => (value) => {
    if (property === "relationshipOwners" && Array.isArray(value)) {
      const valueSet = new Set((value as string[]).map((v: string) => +v));
      const members = teamMembers.filter((teamMember) =>
        valueSet.has(+teamMember.userDTO.userId)
      );

      setProfileData((data) =>
        set(
          lensProp(property),
          members.map((item) => {
            return { userId: item.userDTO.userId };
          }),
          data
        )
      );
    } else {
      setProfileData((data) => set(lensProp(property), value, data));
    }
    setIsSaved(false);
  };

  const isFieldDisabled: isFieldDisabledType = (user, data) =>
    isNil(data) || !isAdmin(user);

  useEffect(() => {
    getIndustries({})
      .then(({ data }: ResponseType<IndustryType[]>) => {
        setIndustries(data);
      })
      .catch(console.error);

    getGeographies({})
      .then(({ data = [] }: ResponseType<GeographyType[]>) => {
        setGeographies(data);
      })
      .catch(console.error);

    getCountries({})
      .then(({ data = [] }: ResponseType<CountryType[]>) => {
        setCountries(data);
      })
      .catch(console.error);

    getPortfoliosForCrmMappings({
      params: location.search,
    })
      .then(({ data = [] }: ResponseType<PortfolioType[]>) => {
        setPortfolios(
          data?.sort((a, b) =>
            columnSort(a?.portfolioCompanyName, b?.portfolioCompanyName, "TEXT")
          )
        );
      })
      .catch(console.error);
  }, []);
  const getTeamMembers: voidType = () => {
    setLoading(true);
    getAllTeamMembers({})
      .then(({ data = [] }: ResponseType<TeamMemberType[]>) => {
        setTeamMembers(data);
        setLoading(false);
      })
      .catch((e: string) => {
        console.error(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    setProfileData(data);
    getTeamMembers();
  }, [data]);

  return (
    <RelationshipCard
      label={"Profile"}
      actions={
        !isSaved && (
          <Button
            className={PRIMARY_BUTTON_STYLE}
            onClick={() => profileData && initiateUpdateProfile(profileData)}
          >
            Save
          </Button>
        )
      }
    >
      <PromptDialog block={!isSaved} />
      <div
        className={`relative h-full overflow-y-auto print:overflow-y-visible print:block p-4 gap-y-2`}
      >
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"Description"}
          value={profileData?.description}
          type={InputType.DESCRIPTION}
          onChange={updateProfile("description")}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"ROLE TYPE"}
          value={profileData?.investmentTypes}
          placeholder={"For example 'Lead'"}
          options={Object.keys(RelationshipInvestmentType).map((value) => ({
            key: value,
            label:
              RelationshipInvestmentLabelType[
                value as RelationshipInvestmentType
              ],
          }))}
          type={InputType.MULTIPLE_SELECT}
          onChange={updateProfile("investmentTypes")}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"Geography"}
          type={InputType.MULTIPLE_SELECT}
          placeholder={"For example 'Europe'"}
          onChange={updateProfile("geographies")}
          value={profileData?.geographies}
          options={geographies.map(({ id, name }) => ({
            label: name,
            key: id,
          }))}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"Countries"}
          type={InputType.MULTIPLE_SELECT}
          placeholder={"For example 'United Kingdom'"}
          onChange={updateProfile("countries")}
          value={profileData?.countries}
          options={countries.map(({ id, name, imageUrl }) => ({
            icon: imageUrl,
            label: name,
            key: id,
          }))}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"Target Industries"}
          type={InputType.MULTIPLE_SELECT}
          placeholder={"For example 'Financial Services'"}
          onChange={updateProfile("industries")}
          value={profileData?.industries}
          options={industries.map(({ id, name }) => ({
            label: name,
            key: id,
          }))}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"Institution Type"}
          value={profileData?.institutionTypes}
          placeholder={"For example 'Lender'"}
          options={Object.keys(RelationshipInstitutionType).map((value) => ({
            key: value,
            label:
              RelationshipInstitutionLabelType[
                value as RelationshipInstitutionType
              ],
          }))}
          type={InputType.MULTIPLE_SELECT}
          onChange={updateProfile("institutionTypes")}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"STRUCTURES"}
          value={profileData?.structuresTypes}
          placeholder={"For example 'Revolver'"}
          options={Object.keys(RelationshipStructureType).map((value) => ({
            key: value,
            label:
              RelationshipStructureLabelType[
                value as RelationshipStructureType
              ],
          }))}
          type={InputType.MULTIPLE_SELECT}
          onChange={updateProfile("structuresTypes")}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"Target EBITDA"}
          type={InputType.MONETARY_RANGE}
          value={profileData?.ebitdaRangeDTO}
          onChange={(value) => {
            updateProfile("ebitdaRangeDTO")({
              ...(value as MonetaryRangeType),
            });
          }}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"Target Hold Size"}
          type={InputType.MONETARY_RANGE}
          value={profileData?.targetHoldSizeDTO}
          onChange={(value) => {
            updateProfile("targetHoldSizeDTO")({
              ...(value as MonetaryRangeType),
            });
          }}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"Relationship Since"}
          type={InputType.DATE}
          value={profileData?.since}
          onChange={updateProfile("since")}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"External Id"}
          placeholder={"Add External Id"}
          value={profileData?.externalId}
          type={InputType.TEXT}
          onChange={updateProfile("externalId")}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"Summary Note"}
          placeholder={"Add Summary Notes"}
          value={profileData?.note}
          type={InputType.DESCRIPTION}
          onChange={updateProfile("note")}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"Portfolio Interest"}
          type={InputType.MULTIPLE_SELECT}
          placeholder={"Select Portfolios"}
          onChange={updateProfile("portfolioIds")}
          value={profileData?.portfolioIds}
          options={portfolios.map(({ id, portfolioCompanyName }) => ({
            label: portfolioCompanyName,
            key: id,
          }))}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"Relationships owner"}
          placeholder={"Key relationships owner'"}
          value={profileData?.relationshipOwners?.map((item) => item.userId)}
          options={teamMembers
            ?.filter((item) => item?.userDTO?.userId)
            .map((value) => ({
              key: value?.userDTO?.userId,
              label: value?.userDTO?.firstName + " " + value?.userDTO?.lastName,
            }))}
          type={InputType.MULTIPLE_SELECT}
          onChange={updateProfile("relationshipOwners")}
        />
        <InputField
          loading={loading}
          disabled={isFieldDisabled(user, profileData)}
          label={"Last Meeting Date"}
          placeholder={"Add Last Meeting Date"}
          value={profileData?.lastMeetingDate}
          type={InputType.DATE}
          onChange={updateProfile("lastMeetingDate")}
        />
      </div>
    </RelationshipCard>
  );
};

type initiateUpdateProfileType = (body: CRMCompanyMappingType) => void;
type updateProfileType = (
  property: keyof CRMCompanyMappingType
) => (value: CRMCompanyMappingType[keyof CRMCompanyMappingType]) => void;

type CrmProfileType = {
  data: CRMCompanyMappingType | null;
  onSave: Dispatch<SetStateAction<CRMCompanyMappingType | null>>;
};
type isFieldDisabledType = (
  user: UserType | null,
  data: CRMCompanyMappingType | null
) => boolean;
