import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import {
  createTag,
  deletePETransaction,
  editTransaction,
  getAllocationKeys,
  getTransactions,
  updateTag,
} from "../services/services";
import {
  AllocationKeyType,
  PeTransactionFilter,
  PeTransactionResponseType,
  TransactionType,
  UserType,
} from "../utils/types";
import {
  Badge,
  Button,
  Empty,
  message,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { convertDateToFormat } from "../utils/moment";
import {
  AllocationTableType,
  PermissionType,
  PETransactionCreationType,
  SortDirection,
} from "../utils/enums";
import { useHistory } from "react-router";
import { UserContext } from "../context/UserContext";
import { ConfirmDelete } from "../utils/confirmationModals";
import { isEmptyOrNil, updateItemInArray, valOrDefault } from "../utils/utils";
import { ResponseType, voidType } from "../utils/uiTypes";
import { usePageTitle } from "../customHooks/usePageTitle";
import {
  getSelectOptionWithBadge,
  tableColumnHeader,
  tableSelectSuffix,
} from "../utils/componentUtils";
import { ACTION_BUTTON_CSS, tableSelectCss } from "../utils/cssConfigs";
import useWindowDimensions from "../customHooks/useWindowDimensions";

import { ColumnsType } from "antd/es/table";
import { all, any, equals, filter, is, isEmpty, propEq } from "ramda";
import { SortOrder } from "antd/es/table/interface";
import {
  DEFAULT_TRANSACTION_FILTERS,
  COLUMN_BY_COMPANY,
  isOffline,
  isOfflineLender,
  statusColumnHeader,
  transactionTagsArray,
} from "../utils/transaction";
import { ColumnType } from "antd/lib/table";
import { TransactionFilters } from "../components/transaction/TransactionsFilters";
import { TextOverFlowHandle } from "../components/general/TextOverFlowHandle";

const SORT_MAP: Record<SortDirection, SortOrder> = {
  [SortDirection.ASC]: "ascend",
  [SortDirection.DESC]: "descend",
};

const canEditTransaction = (transaction: TransactionType): boolean => {
  return any(equals(true), [
    (transaction?.permissionDTO?.permissions ?? []).includes(
      PermissionType.ADMIN_PETRANSACTION
    ),
    isOfflineLender(transaction),
  ]);
};

const showColumn: showColumnType = (user) => (column) => {
  const permissions = COLUMN_BY_COMPANY[column?.key as string];
  return isEmptyOrNil(permissions)
    ? true
    : any(equals(user?.companyDTO?.companyType), permissions);
};

const canDeleteTransaction = (transaction: TransactionType): boolean =>
  any(equals(true), [
    (transaction?.permissionDTO?.permissions ?? []).includes(
      PermissionType.ADMIN_PETRANSACTION
    ),
    isOfflineLender(transaction),
  ]);

export const Transactions: FC = function () {
  usePageTitle("Transactions");
  const { user, companyModulePreference } = useContext(UserContext);
  const { height: windowHeight } = useWindowDimensions();
  const history = useHistory();

  const [filters, setFilters] = useState<PeTransactionFilter>(
    DEFAULT_TRANSACTION_FILTERS
  );
  const [transactions, setTransactions] = useState<PeTransactionResponseType>({
    results: [],
    totalCount: 0,
  });
  const [allocationKeys, setAllocationKeys] = useState<AllocationKeyType[]>([]);

  const [loading, setLoading] = useState(true);

  const fetchAllocationKeys: voidType = () => {
    getAllocationKeys({}).then(
      ({ data = [] }: ResponseType<AllocationKeyType[]>) => {
        setAllocationKeys(data);
      }
    );
  };

  const fetchTransactions: fetchTransactionsType = (filters) => {
    setLoading(true);
    getTransactions({
      body: JSON.stringify(filters),
    })
      .then(({ data }: ResponseType<PeTransactionResponseType>) => {
        setTransactions(data);
        setLoading(false);
      })
      .catch((error: string) => {
        setLoading(false);
        message.error(error ? error : "Error Fetching Transactions!");
      });
  };

  const requestDeleteTransaction: requestDeleteTransactionType = (
    peTransactionId
  ) => {
    deletePETransaction({
      segments: {
        id: peTransactionId,
      },
    })
      .then(() => {
        message.success("Transaction Deleted!");
        fetchTransactions(filters);
      })
      .catch((error: string) => {
        message.error(error ? error : "Error deleting Transaction!");
      });
  };

  const initiateEditTransaction: initiateEditTransactionType = (
    transaction,
    index
  ) => {
    editTransaction({
      body: JSON.stringify(transaction),
    })
      .then(({ data }: ResponseType<TransactionType>) => {
        setTransactions((v) => ({
          ...v,
          results: updateItemInArray(index, v.results, data),
        }));
      })
      .catch((error: string) => {
        message.error(
          error ? error : "Error making changes to the Transaction!"
        );
      });
  };

  const initiateUpdateTag: initiateCreateTagType = (
    user,
    transaction,
    value,
    index
  ) => {
    updateTag({
      body: JSON.stringify({
        companyId: user?.companyId ?? "",
        peTransactionId: transaction.peTransactionId,
        peTransactionTagType: value,
      }),
    })
      .then(() => {
        setTransactions((v) => ({
          ...v,
          results: updateItemInArray(index, v.results, {
            ...transaction,
            peTransactionTagType: value,
          }),
        }));
      })
      .catch((error: string) => {
        message.error(
          error ? error : "Error making changes to the Transaction!"
        );
      });
  };

  const initiateCreateTag: initiateCreateTagType = (
    user,
    transaction,
    value,
    index
  ) => {
    createTag({
      body: JSON.stringify({
        companyId: user?.companyId ?? "",
        peTransactionId: transaction.peTransactionId,
        peTransactionTagType: value,
      }),
    })
      .then(() => {
        setTransactions((v) => ({
          ...v,
          data: updateItemInArray(index, v.results, {
            ...transaction,
            peTransactionTagType: value,
          }),
        }));
      })
      .catch((error: string) => {
        message.error(
          error ? error : "Error making changes to the Transaction!"
        );
      });
  };

  const getAllocationKeysByType = useCallback(
    (v: AllocationTableType) =>
      filter<AllocationKeyType>(propEq("allocationTableType", v)),
    [allocationKeys]
  );

  const columns = (
    allocationKeys: AllocationKeyType[]
  ): ColumnsType<TransactionType> => {
    const cols: ColumnsType<TransactionType> = [
      {
        title: tableColumnHeader("Name & Description"),
        align: "left",
        dataIndex: ["peTransactionTitle", "peTransactionId", "description"],
        key: "peTransactionTitle",
        className: `group-hover:bg-blue-50 min-w-[180px] max-w-[260px] overflow-hidden ${
          !isEmptyOrNil(filters.searchKey) && "!bg-emerald-50"
        }`,
        render: function name(text: string, transaction): ReactNode {
          return (
            <Link
              to={`/transactions/${transaction.peTransactionId}/${
                isOfflineLender(transaction) ? "termsheet" : "dashboard"
              }`}
              style={{ color: "inherit" }}
              className={`font-medium text-sm cursor-pointer text-primary flex flex-col whitespace-normal items-start`}
            >
              <div className={"flex flex-row items-center gap-2"}>
                {transaction.peTransactionTitle}&nbsp;
                {transaction.peTransactionCreationType ===
                  PETransactionCreationType.OFFLINE && (
                  <Tag color={"gold"}>
                    {transaction.peTransactionCreationType}
                  </Tag>
                )}
              </div>
              <Tooltip title={transaction.description} placement={"bottomLeft"}>
                <div
                  className={
                    "cursor-default text-xs font-normal text-gray-400 normal-case break-all truncate min-w-[180px] max-w-[300px]"
                  }
                >
                  {transaction.description}
                </div>
              </Tooltip>
            </Link>
          );
        },
      },
      {
        className: "group-hover:bg-blue-50",
        render: (
          _,
          {
            peTransactionCreationType,
            peTransactionNotificationCount,
            peTransactionId,
          }
        ) =>
          all(equals(true), [
            peTransactionCreationType !== PETransactionCreationType.OFFLINE,
            is(Number, Number(peTransactionNotificationCount)),
            Number(peTransactionNotificationCount) > 0,
          ]) && (
            <Button
              type={"text"}
              className={
                "flex items-center px-2 bg-transparent text-secondary mr-auto"
              }
              onClick={(): void =>
                history.push(
                  `/transactions/${peTransactionId}/${
                    equals(
                      peTransactionCreationType,
                      PETransactionCreationType.OFFLINE
                    )
                      ? "communications"
                      : "dashboard"
                  }`
                )
              }
            >
              <Badge count={peTransactionNotificationCount}>
                <i className="fa-solid fa-bell text-lg text-secondary"></i>
              </Badge>
            </Button>
          ),
      },
      {
        title: tableColumnHeader("Created By"),
        key: "dealCompany",
        className: "group-hover:bg-blue-50 min-w-[150px]",
        render: function createdBy({
          companyName,
          creatorCompanyName,
          companyId,
          createdByUserDTO,
        }: TransactionType): ReactNode {
          return equals(companyId, user?.companyId) ? (
            <span className={"text-muted text-xs mx-2 select-none"}>-</span>
          ) : (
            <div
              className={
                "font-medium text-sm flex flex-col items-stretch w-full h-full cursor-pointer"
              }
            >
              {creatorCompanyName || companyName}
              <br />
              <span className={"text-gray-400 text-xs normal-case"}>
                by{" "}
                {createdByUserDTO.firstName + " " + createdByUserDTO.lastName}
              </span>
            </div>
          );
        },
      },
      {
        title: tableColumnHeader(
          statusColumnHeader("Client Company", "Private to you")
        ),
        key: "clientCompany",
        className: "group-hover:bg-blue-50 min-w-[150px]",
        render: function createdBy({
          companyName,
          companyId,
          createdByUserDTO,
        }: TransactionType): ReactNode {
          return equals(companyId, user?.companyId) ? (
            <span className={"text-muted text-xs mx-2 select-none"}>-</span>
          ) : (
            <div
              className={
                "font-medium text-sm flex flex-col items-stretch w-full h-full cursor-pointer"
              }
            >
              {companyName}
            </div>
          );
        },
      },

      {
        title: tableColumnHeader(
          statusColumnHeader("Transaction Type", "Private to you")
        ),
        align: "left",
        dataIndex: "peTransactionTypeId",
        key: "peTransactionTypeId",
        className: `group-hover:bg-blue-50 min-w-[180px]  ${
          !isEmptyOrNil(filters.transactionType) && "!bg-emerald-50"
        }`,
        render: function status(
          peTransactionTypeId: string,
          transaction,
          index
        ): ReactNode {
          const canEdit = canEditTransaction(transaction);
          return (
            <div onClick={(e): void => e.stopPropagation()}>
              {canEdit ? (
                <Select
                  value={peTransactionTypeId}
                  dropdownMatchSelectWidth={120}
                  bordered={false}
                  className={tableSelectCss(canEdit)}
                  placeholder={
                    canEdit ? (
                      <span className={`${canEdit && "text-red-500"} text-xs`}>
                        Please Select
                      </span>
                    ) : (
                      <span className={`text-xs`}>-</span>
                    )
                  }
                  size={"small"}
                  suffixIcon={tableSelectSuffix(canEdit)}
                  onSelect={(
                    _: string,
                    option: { key: string; label: string }
                  ): void => {
                    initiateEditTransaction(
                      {
                        ...transaction,
                        peTransactionTypeId: option.key as string,
                        peTransactionTypeName: option.label as string,
                      },
                      index
                    );
                  }}
                >
                  {getAllocationKeysByType(AllocationTableType.TRANSACTION)(
                    allocationKeys
                  ).map(({ id, keyName }) => (
                    <Select.Option
                      value={id}
                      className={"flex flex-row"}
                      title={keyName}
                      key={id}
                    >
                      {keyName}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                valOrDefault(
                  <span className={"text-muted text-xs mx-2 select-none"}>
                    -
                  </span>,
                  transaction.peTransactionTypeName ? (
                    <span className={"pl-[7px]"}>
                      {transaction.peTransactionTypeName}
                    </span>
                  ) : null
                )
              )}
            </div>
          );
        },
      },
      {
        title: tableColumnHeader(
          statusColumnHeader("Investment Type", "Private to you")
        ),
        dataIndex: "investmentTypeId",
        key: "investmentTypeId",
        className: `group-hover:bg-blue-50 min-w-[180px]  ${
          !isEmptyOrNil(filters.investmentType) && "!bg-emerald-50"
        }`,
        render: function status(
          investmentTypeId: string,
          transaction,
          index
        ): ReactNode {
          const canEdit = canEditTransaction(transaction);
          return (
            <div onClick={(e): void => e.stopPropagation()}>
              {canEdit ? (
                <Select
                  value={investmentTypeId}
                  dropdownMatchSelectWidth={120}
                  className={tableSelectCss(canEdit)}
                  bordered={false}
                  placeholder={
                    canEdit ? (
                      <span className={`${canEdit && "text-red-500"} text-xs`}>
                        Please Select
                      </span>
                    ) : (
                      <span className={`text-xs`}>-</span>
                    )
                  }
                  size={"small"}
                  suffixIcon={tableSelectSuffix(canEdit)}
                  onSelect={(
                    _: string,
                    option: { key: string; label: string }
                  ): void => {
                    initiateEditTransaction(
                      {
                        ...transaction,
                        investmentTypeId: option.key as string,
                        investmentTypeName: option.label as string,
                      },
                      index
                    );
                  }}
                >
                  {getAllocationKeysByType(AllocationTableType.INVESTMENT)(
                    allocationKeys
                  ).map(({ id, keyName }) => (
                    <Select.Option
                      value={id}
                      className={"flex flex-row"}
                      title={keyName}
                      key={id}
                    >
                      {keyName}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                valOrDefault(
                  <span className={"text-muted text-xs mx-2 select-none"}>
                    -
                  </span>,
                  transaction.investmentTypeName ? (
                    <span className={"pl-[7px]"}>
                      {transaction.investmentTypeName}
                    </span>
                  ) : null
                )
              )}
            </div>
          );
        },
      },
      {
        title: (
          <div style={{ display: "flex", alignItems: "center" }}>
            {tableColumnHeader(statusColumnHeader("Status", "Private to you"))}
            {/*{equals(*/}
            {/*  CompanyEnumType.LENDER,*/}
            {/*  user?.companyDTO?.companyType*/}
            {/*) && <TGBadge color={"warning"}>Private</TGBadge>}*/}
          </div>
        ),
        align: "left",
        dataIndex: "peTransactionTagType",
        key: "peTransactionTagType",
        className: `group-hover:bg-blue-50  ${
          !isEmptyOrNil(filters.status) && "!bg-emerald-50"
        }`,
        render: function status(
          peTransactionTagType: string,
          transaction,
          index
        ): ReactNode {
          const canEdit =
            (transaction?.permissionDTO?.permissions ?? []).includes(
              PermissionType.VIEW_PETRANSACTION
            ) || isOffline(transaction);
          return (
            <div onClick={(e): void => e.stopPropagation()}>
              {canEdit ? (
                <Select
                  value={peTransactionTagType}
                  dropdownMatchSelectWidth={120}
                  bordered={false}
                  className={tableSelectCss()}
                  placeholder={
                    <span className={`${canEdit && "text-red-500"} text-xs`}>
                      Not Specified
                    </span>
                  }
                  size={"small"}
                  suffixIcon={tableSelectSuffix(canEdit)}
                  onSelect={(value: string): void => {
                    if (user) {
                      peTransactionTagType
                        ? initiateUpdateTag(user, transaction, value, index)
                        : initiateCreateTag(user, transaction, value, index);
                    }
                  }}
                >
                  {getSelectOptionWithBadge(transactionTagsArray)}
                </Select>
              ) : (
                valOrDefault(
                  <span className={"text-muted text-xs mx-2 select-none"}>
                    Not Specified
                  </span>,
                  peTransactionTagType
                )
              )}
            </div>
          );
        },
      },
      {
        title: (
          <div style={{ display: "flex", alignItems: "center" }}>
            {tableColumnHeader("Created")}
          </div>
        ),
        sortOrder: equals("creationDate", filters.sortColumn)
          ? SORT_MAP[filters.direction]
          : null,
        dataIndex: "createdByUserDTO",
        key: "createdByUserDTO",
        align: "left",
        sorter: true,
        showSorterTooltip: false,
        className: `group-hover:bg-blue-50 min-w-[120px] ${
          (filters.createdByMe || !isEmptyOrNil(filters.createdBy)) &&
          "!bg-emerald-50"
        }`,
        onHeaderCell: () => ({
          onClick: (): void => {
            fetchTransactions({
              ...filters,
              sortColumn: "creationDate",
              direction:
                equals("creationDate", filters.sortColumn) &&
                filters.direction === SortDirection.DESC
                  ? SortDirection.ASC
                  : SortDirection.DESC,
            });
          },
        }),
        render: function createdBy(
          _: UserType,
          { createDate, creationDate, creatorCompanyId }: TransactionType
        ): ReactNode {
          return equals(creatorCompanyId, user?.companyId) ? (
            <div
              className={
                "font-medium text-sm flex flex-col items-stretch w-full h-full cursor-pointer"
              }
            >
              {convertDateToFormat(
                creationDate || createDate,
                "D.MMM.YY",
                !!creationDate
              )}
            </div>
          ) : (
            <span className={"text-muted text-xs mx-2 select-none"}>-</span>
          );
        },
      },
      {
        title: tableColumnHeader("Creator"),
        align: "left",
        dataIndex: "creatorCompanyName",
        key: "creatorCompanyName",
        className: "min-w-[80px] max-w-[150px]",
        render: (v: string, { creatorCompanyId, createdByUserDTO }) => {
          return (
            <div
              className={
                "font-medium text-sm flex flex-col items-stretch w-full h-full cursor-pointer"
              }
            >
              <TextOverFlowHandle text={v} />
              <span className={"text-gray-400 text-xs normal-case"}>
                by{" "}
                {createdByUserDTO.firstName + " " + createdByUserDTO.lastName}
              </span>
            </div>
          );
        },
      },
      {
        title: tableColumnHeader("Target Company"),
        align: "left",
        dataIndex: "companyToBuy",
        key: "companyToBuy",
        className: "group-hover:bg-blue-50 min-w-[180px] max-w-[250px]",
        render: function company(companyToBuy: string, transaction): ReactNode {
          const canEdit =
            (transaction?.permissionDTO?.permissions ?? []).includes(
              PermissionType.ADMIN_PETRANSACTION
            ) ||
            (transaction?.permissionDTO?.permissions ?? []).includes(
              PermissionType.NON_ADMIN_PETRANSACTION
            );
          return (
            <span
              className={`empty:after:content-['Not_Specified'] empty:text-xs ${
                canEdit ? "empty:text-red-500" : "empty:text-muted"
              }`}
            >
              {valOrDefault("", companyToBuy)}
            </span>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        sorter: true,
        sortOrder: equals("lastActivity", filters.sortColumn)
          ? SORT_MAP[filters.direction]
          : null,
        key: "",
        showSorterTooltip: false,
        align: "right",
        className: "group-hover:bg-blue-50",
        onHeaderCell: () => ({
          onClick: (): void => {
            fetchTransactions({
              ...filters,
              sortColumn: "lastActivity",
              direction:
                equals("lastActivity", filters.sortColumn) &&
                filters.direction === SortDirection.DESC
                  ? SortDirection.ASC
                  : SortDirection.DESC,
            });
          },
        }),
        render: function actions(_: string, transaction, index): ReactNode {
          const canEdit = canEditTransaction(transaction);
          const canDelete = canDeleteTransaction(transaction);

          return (
            <span
              className={"relative flex flex-row-reverse items-center gap-x-3"}
              onClick={(event): void => event.stopPropagation()}
            >
              {(canDelete || canEdit) && (
                <Button
                  type={"text"}
                  icon={<i className="fas fa-ellipsis-h" />}
                  className={
                    "absolute right-0 border-0  items-center px-2 text-gray-900 block opacity-50 group-hover:opacity-0"
                  }
                />
              )}
              {canDelete && (
                <Button
                  type={"text"}
                  icon={<i className="fas fa-trash-alt" />}
                  className={`${ACTION_BUTTON_CSS} hover:text-danger opacity-0 group-hover:opacity-100`}
                  onClick={(): void => {
                    ConfirmDelete(
                      "Are you sure you want to delete this transaction? This will be irreversible and you will lose all the data within the transaction. Furthermore, this will also not show up in your analytics activity reports.",
                      () =>
                        requestDeleteTransaction(
                          transaction.peTransactionId,
                          index
                        ),
                      <>
                        Transaction Title: {transaction.peTransactionTitle}
                        <br />
                        Create Date:&nbsp;
                        {convertDateToFormat(
                          transaction.createDate,
                          "D.MMM.YY",
                          false
                        )}
                        <br />
                        Created By:&nbsp;
                        {transaction?.createdByUserDTO?.firstName +
                          " " +
                          transaction?.createdByUserDTO?.lastName}
                      </>
                    );
                  }}
                />
              )}
              {canEdit && (
                <Button
                  onClick={(): void => {
                    history.push(
                      `/transactions/${transaction.peTransactionId}/edit`
                    );
                  }}
                  type={"text"}
                  icon={<i className="fas fa-pencil-alt" />}
                  className={`${ACTION_BUTTON_CSS}  hover:text-primary opacity-0 group-hover:opacity-100`}
                />
              )}
            </span>
          );
        },
      },
    ];
    return cols.filter(showColumn(user)) as ColumnsType<TransactionType>;
  };
  const stringifyParams = (filters: PeTransactionFilter): string => {
    return new URLSearchParams([
      ["size", (filters.size ?? "25").toString()],
      filters.fromTimestamp
        ? ["fromTimestamp", filters.fromTimestamp.toString()]
        : ["", ""],
      filters.toTimestamp
        ? ["toTimestamp", filters.toTimestamp.toString()]
        : ["", ""],
      ["createdByMe", filters.createdByMe?.toString() || ""],

      ...(filters.industries ?? []).map((val) => ["industries", val]),
      ...(filters.investmentType ?? []).map((val) => ["investmentType", val]),
      ...(filters.debtType ?? []).map((val) => ["debtType", val]),
      ...(filters.transactionType ?? []).map((val) => ["transactionType", val]),
      ...(filters.region ?? []).map((val) => ["region", val]),
      ...(filters.status ?? []).map((val) => ["status", val]),
      ...(filters.createdBy ?? []).map((val) => ["createdBy", val]),
    ]).toString();
  };
  const onChangeFilters = (filters: PeTransactionFilter): void => {
    fetchTransactions(filters);
    setFilters(() => filters);
    const params = new URLSearchParams(stringifyParams(filters)).toString();
    history.replace({
      pathname: "/transactions",
      search: params,
    });
  };

  useEffect(() => {
    if (user && (!user.companyId || !user.hasTeam)) {
      message.info("Create a company first!");
      history.replace("/company/add");
    } else {
      fetchAllocationKeys();
    }
  }, [user]);

  return (
    <div
      className={
        "relative max-h-full w-full h-screen bg-gray-100 flex flex-col"
      }
    >
      <>
        <div
          className={"mb-2 flex flex-row flex-wrap items-center p-6 pb-0 gap-3"}
        >
          <span className={"text-2xl font-medium mr-auto"}> Transactions</span>
          {/*WIP BLOCKED ON UI*/}
          {/*{equals(CompanyEnumType.LENDER, user?.companyDTO?.companyType) && (*/}
          {/*  <Link to={"/wip"}>*/}
          {/*    <Button type={"link"}>*/}
          {/*      Deal Collaboration<TGBadge color={"success"}>New</TGBadge>*/}
          {/*    </Button>*/}
          {/*  </Link>*/}
          {/*)}*/}

          {user?.createTransaction &&
            user?.companyId &&
            companyModulePreference?.transaction && (
              <Link to={"/transactions/create"} className={"hidden md:block"}>
                <Button
                  className={"bg-primary hover:bg-hover text-white border-0"}
                  icon={<PlusOutlined />}
                >
                  New Transaction
                </Button>
              </Link>
            )}
          {user?.createOfflineTransaction &&
            user?.companyId &&
            companyModulePreference?.offlineTransaction && (
              <Link
                to={"/transactions/create?isOffline=true"}
                className={"hidden md:block"}
              >
                <Button
                  className={`bg-white px-2 text-gray-500 block hover:text-primary`}
                  icon={<PlusOutlined />}
                  type={"dashed"}
                >
                  Offline Transaction
                </Button>
              </Link>
            )}
        </div>
        <div
          className={"flex flex-wrap items-center w-full px-6 pt-0 pb-3 gap-3"}
        >
          <TransactionFilters
            loading={loading}
            transactionTypes={getAllocationKeysByType(
              AllocationTableType.TRANSACTION
            )(allocationKeys)}
            investmentTypes={getAllocationKeysByType(
              AllocationTableType.INVESTMENT
            )(allocationKeys)}
            selectedFilters={filters}
            onChange={(v) =>
              onChangeFilters({ ...filters, ...v, pageIndex: 0 })
            }
          />
          {/*<Search*/}
          {/*  loading={loading}*/}
          {/*  disabled={loading}*/}
          {/*  className={"mr-0 md:mr-auto max-w-md"}*/}
          {/*  placeholder={"Search Transaction"}*/}
          {/*  defaultValue={new URLSearchParams(search).get("searchKey") ?? ""}*/}
          {/*  onSearch={(searchKey): void =>*/}
          {/*    onChangeFilters({ ...filters, searchKey, pageIndex: 0 })*/}
          {/*  }*/}
          {/*  allowClear={true}*/}
          {/*/>*/}
          {/*<span>Sort By:</span>*/}
          {/*<Select*/}
          {/*  disabled={loading}*/}
          {/*  value={filters.sortColumn}*/}
          {/*  onChange={(sortColumn): void =>*/}
          {/*    fetchTransactions({*/}
          {/*      ...filters,*/}
          {/*      sortColumn,*/}
          {/*      direction:*/}
          {/*        equals(sortColumn, filters.sortColumn) &&*/}
          {/*        filters.direction === SortDirection.DESC*/}
          {/*          ? SortDirection.ASC*/}
          {/*          : SortDirection.DESC,*/}
          {/*    })*/}
          {/*  }*/}
          {/*  options={[*/}
          {/*    { value: "creationDate", label: "Create Date" },*/}
          {/*    { value: "lastActivity", label: "Last Activity" },*/}
          {/*  ]}*/}
          {/*/>*/}
          {/*<Segmented*/}
          {/*  disabled={loading}*/}
          {/*  value={filters.direction}*/}
          {/*  onChange={(direction): void =>*/}
          {/*    fetchTransactions({*/}
          {/*      ...filters,*/}
          {/*      sortColumn: filters.sortColumn,*/}
          {/*      direction: direction as SortDirection,*/}
          {/*    })*/}
          {/*  }*/}
          {/*  options={[*/}
          {/*    { value: SortDirection.ASC, label: "Ascending" },*/}
          {/*    { value: SortDirection.DESC, label: "Descending" },*/}
          {/*  ]}*/}
          {/*/>*/}
        </div>
        <div className={`w-full h-full flex flex-col overflow-y-auto p-6 pt-0`}>
          <div className={"pb-4"}>
            <Table
              loading={loading}
              rowKey={"peTransactionId"}
              className={"transition duration-300 ease-out transform border"}
              columns={columns(allocationKeys)}
              pagination={{
                position: ["bottomCenter"],
                responsive: true,
                total: transactions?.totalCount,
                showSizeChanger: true,
                pageSize: filters.size,
                showQuickJumper: true,
                showPrevNextJumpers: true,
                onChange: (index, pageSize) =>
                  onChangeFilters({
                    ...filters,
                    pageIndex: index - 1,
                    size: pageSize,
                  }),
                showTotal: (total, range) => (
                  <>
                    Showing {range[0]} - {range[1]} from {total}
                  </>
                ),
                current: Number(filters.pageIndex) + 1,
                hideOnSinglePage: false,
              }}
              dataSource={transactions?.results}
              rowClassName={`group hover:bg-blue-50`}
              scroll={{ x: true, y: windowHeight - 300 }}
              locale={{
                emptyText: (
                  <Empty
                    description={"No Transactions"}
                    className={"my-auto text-gray-400"}
                  >
                    {user?.createTransaction &&
                      user?.companyId &&
                      filters.pageIndex === 0 &&
                      isEmpty(filters.searchKey) && (
                        <Link
                          to={"/transactions/create"}
                          className={"ml-auto hidden md:block"}
                        >
                          <Button
                            className={
                              "bg-primary hover:bg-hover text-white border-0"
                            }
                            icon={<PlusOutlined />}
                          >
                            New Transaction
                          </Button>
                        </Link>
                      )}
                  </Empty>
                ),
              }}
            />
          </div>
        </div>
      </>
    </div>
  );
};

type fetchTransactionsType = (filters: PeTransactionFilter) => void;
type requestDeleteTransactionType = (
  peTransactionId: string,
  index: number
) => void;
type initiateEditTransactionType = (
  transaction: TransactionType,
  index: number
) => void;
type initiateCreateTagType = (
  user: UserType,
  transaction: TransactionType,
  value: string,
  index: number
) => void;
type showColumnType = (
  user?: UserType | null
) => (column: ColumnType<TransactionType>) => boolean;
