import React, { FC, ReactNode, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Affix, Layout, Menu } from "antd";
import {
  BellOutlined,
  CalendarOutlined,
  DollarOutlined,
  FileSearchOutlined,
  LineChartOutlined,
  LogoutOutlined,
  FileExclamationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { UserContext } from "../../context/UserContext";
import { CustomAvatar } from "../general/CustomAvatar";
import { CompanyModuleType, UserType } from "../../utils/types";
import {
  CompanyEnumType,
  ElementType,
  PermissionType,
  RoleType,
} from "../../utils/enums";
import { checkElementViewPermission } from "../../utils/permissions";
import { TRANSACTIONS_PAGE_URL } from "../../utils/redirect";
import Logo from "../../images/logo/LogoLight.svg";
import TermgridLogoLight from "../../images/logo/TermgridLogoLight.svg";
import { equals, isEmpty, isNil, path } from "ramda";
import { useHistory } from "react-router";
import { valOrDefault } from "../../utils/utils";
import { DEFAULT_COMPANY_MODULE_CONFIG } from "../../utils/module-util";
import { getRelationshipModules } from "../../utils/relationship";
import { TGBadge } from "../general/TGBadge";

const { Sider } = Layout;
const MENU_ITEM_STYLE = {
  marginLeft: "-3px",
  marginRight: "3px",
  fontSize: "16px",
};
const MENU_ITEMS: getMenuItemsType = (
  { authorities, elementPermissions, companyDTO },
  modulePreference = DEFAULT_COMPANY_MODULE_CONFIG
) => {
  const hasAuthority = authorities?.includes(RoleType.ROLE_ADMIN);
  return [
    {
      icon: <DollarOutlined style={{ ...MENU_ITEM_STYLE }} />,
      url: "/transactions",
      name: "Transactions",
      permission: true,
    },
    {
      icon: <UserOutlined style={{ ...MENU_ITEM_STYLE }} />,
      url: "/company",
      name: "Company Profile",
      permission: true,
    },
    {
      icon: <CalendarOutlined style={{ ...MENU_ITEM_STYLE }} />,
      url: "/calendar",
      name: "Calendar",
      permission: true,
    },
    {
      icon: <BellOutlined style={{ ...MENU_ITEM_STYLE }} />,
      url: "/notifications",
      name: "Notifications",
      permission: modulePreference.notifications,
    },
    {
      icon: <FileSearchOutlined style={{ ...MENU_ITEM_STYLE }} />,
      url: "/search",
      name: "Search",
      permission:
        modulePreference.precedentSearch &&
        checkElementViewPermission(
          ElementType.PRECEDENT_SEARCH,
          elementPermissions,
          [PermissionType.VIEW_PRECEDENT_SEARCH],
          true
        ),
    },
    {
      icon: <i className={"fa fa-briefcase"} style={{ ...MENU_ITEM_STYLE }} />,
      url: "/portfolio",
      name: "Portfolio",
      permission:
        modulePreference.portfolioManagement &&
        checkElementViewPermission(
          ElementType.PORTFOLIO,
          elementPermissions,
          [PermissionType.VIEW_PORTFOLIO],
          true
        ),
    },
    // {
    //   icon: (
    //     <i className={"fa-solid fa-cloud"} style={{ ...MENU_ITEM_STYLE }} />
    //   ),
    //   url: "/cloud",
    //   name: "DealCloud",
    //   permission: true,
    // },
    {
      icon: <LineChartOutlined style={{ ...MENU_ITEM_STYLE }} />,
      url: "/insights",
      name: "Insights",
      permission:
        modulePreference.insights &&
        checkElementViewPermission(
          ElementType.INSIGHTS,
          elementPermissions,
          [PermissionType.VIEW_INSIGHTS],
          true
        ),
    },
    {
      icon: <i className={"fas fa-users-cog"} style={{ ...MENU_ITEM_STYLE }} />,
      url: "/admin/company-admin",
      name: "System Settings",
      permission: hasAuthority,
    },
    {
      icon: (
        <i
          className="fa-solid fa-handshake-simple"
          style={{ ...MENU_ITEM_STYLE }}
        />
      ),
      url: "/relationship",
      name: "Relationships",
      permission: !isEmpty(
        getRelationshipModules(elementPermissions, modulePreference)
      ),
    },
    {
      icon: <LogoutOutlined style={{ ...MENU_ITEM_STYLE }} />,
      url: "/logout",
      name: "Logout",
      permission: true,
    },
  ];
};

export const SideNav: FC<StatusType> = function ({
  collapsed = true,
  onCollapse,
}: StatusType) {
  const { user, companyModulePreference } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();

  return (
    <div className={"bg-navbar z-[1000]"}>
      {user && companyModulePreference && (
        <>
          <Affix>
            <Sider
              className={"h-screen"}
              collapsible
              collapsed={collapsed}
              onCollapse={onCollapse}
              collapsedWidth={60}
            >
              <div>
                <div className={"border-b border-gray-500"}>
                  <Link to={TRANSACTIONS_PAGE_URL}>
                    <>
                      {collapsed ? (
                        <div
                          className={`inline-flex h-14 w-full items-center justify-center`}
                        >
                          <img
                            src={Logo}
                            className={"h-8"}
                            alt={"Termgrid Logo"}
                          />
                        </div>
                      ) : (
                        <img
                          src={TermgridLogoLight}
                          className={"w-full p-5"}
                          alt={"Termgrid Logo"}
                        />
                      )}
                    </>
                  </Link>
                </div>
                <div
                  className={"overflow-y-auto hide-scrollbar"}
                  style={{ height: "calc(100vh - 64px)" }}
                >
                  <Menu
                    className={"mb-5 pb-20"}
                    selectedKeys={[
                      `/${path(["1"], location.pathname.split("/"))}`,
                    ]}
                    onClick={({ key }): void => history.push(key)}
                    mode="inline"
                    theme="dark"
                  >
                    {[
                      ...(user
                        ? [
                            <Menu.Item
                              key={"/user/profile"}
                              icon={
                                <CustomAvatar
                                  className={`leading-normal bg-transparent ${
                                    collapsed ? "-ml-1.5" : "-ml-2"
                                  }`}
                                  data={
                                    (user?.firstName?.[0] ?? "") +
                                    (user?.lastName?.[0] ?? "")
                                  }
                                  color={"default"}
                                  size={"small"}
                                  outlined={true}
                                />
                              }
                            >
                              {user.firstName + " " + user.lastName}
                            </Menu.Item>,
                          ]
                        : []),
                      ...MENU_ITEMS(user, companyModulePreference)
                        .filter(({ permission }) => permission)
                        .map(({ name, url, icon, badge }) => (
                          <Menu.Item key={url} icon={icon}>
                            <Link to={url} style={{ color: "inherit" }}>
                              {name}
                              <TGBadge>{badge}</TGBadge>
                            </Link>
                          </Menu.Item>
                        )),
                    ]}
                  </Menu>
                </div>
              </div>
            </Sider>
          </Affix>
        </>
      )}
    </div>
  );
};

type StatusType = {
  collapsed: boolean;
  onCollapse: (o: boolean) => void;
};

type MenuItemType = {
  icon: ReactNode;
  url: string;
  name: string;
  permission: boolean;
  badge?: string;
};

type getMenuItemsType = (
  user: UserType,
  companyModulePreference: CompanyModuleType
) => Array<MenuItemType>;
