import React, {
  ChangeEvent,
  ClipboardEvent,
  FC,
  useContext,
  useRef,
  useState,
} from "react";
import { Button, Form, Input, InputRef, message, Statistic } from "antd";
import { updateItemInArray, valOrDefault } from "../../utils/utils";
import { path } from "ramda";
import { voidType } from "../../utils/uiTypes";
import {
  send2FAEmailCode,
  send2FAPhoneCode,
  sendPhoneVerificationCode,
} from "../../services/services";
import { UserContext } from "../../context/UserContext";
import { MailOutlined, MobileOutlined } from "@ant-design/icons";

const { Countdown } = Statistic;

enum VerifyType {
  SMS = "SMS",
  EMAIL = "EMAIL",
}

export const maskingHandler: maskingHandlerType = (input = "") => {
  if (Number(input) && input.length > 4) {
    return input?.slice(-4)?.padStart(input.length, "*");
  } else {
    const atIndex = input.indexOf("@");
    return atIndex > 0
      ? input?.slice(atIndex - input.length)?.padStart(input.length, "*")
      : input;
  }
};

export const CodeVerification: FC<codeVerificationType> = function ({
  onSubmit,
  showEmailOrSmsOption,
  phoneNumber,
}: codeVerificationType) {
  const [form] = Form.useForm();
  const [loading] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const [inputValues, setInputValues] = useState<Array<string>>(
    Array(6).fill("")
  );
  const [timer, setTimer] = useState<boolean>(false);
  const [resendCodeText, setResendCodeText] = useState<string | null>(
    "Resend Code?"
  );
  const [
    resendSMSOrEmailCodeFromCodeVerification,
    setResendSMSOrEmailCodeFromCodeVerification,
  ] = useState<boolean>(false);
  const [verifyType, setVerifyType] = useState<VerifyType>(VerifyType.SMS);
  const inputRefs = useRef<Record<number, InputRef | null>>({});

  const onFinishTimer: voidType = () => {
    setTimer(false);
    showEmailOrSmsOption &&
      showEmailOrSmsOption(
        setResendCodeText,
        setResendSMSOrEmailCodeFromCodeVerification
      );
  };

  const onClickHandler: onClickHandlerType = (enabled, phoneNumber) => {
    if (enabled) {
      send2FaPhoneVerificationCode(phoneNumber);
    } else {
      sendVerificationCode(phoneNumber);
    }
  };

  const sendVerificationCode: sendVerificationCodeType = (phoneNumber) => {
    sendPhoneVerificationCode({
      body: JSON.stringify({
        phoneNumber: phoneNumber,
      }),
    })
      .then(() => {
        setVerifyType(VerifyType.SMS);
        setTimer(true);
        message.success("A new code has been sent to your phone");
        setInputValues(Array(6).fill(""));
      })
      .catch(() => {
        setTimer(false);
        message.error("Something went wrong!");
      });
  };

  const send2FaPhoneVerificationCode: send2FaPhoneVerificationCodeType = (
    phoneNumber
  ) => {
    send2FAPhoneCode({
      body: JSON.stringify({
        phoneNumber: phoneNumber,
      }),
    })
      .then(() => {
        setVerifyType(VerifyType.SMS);
        setResendSMSOrEmailCodeFromCodeVerification(false);
        setTimer(true);
        message.success("A new code has been sent to your phone");
        setInputValues(Array(6).fill(""));
      })
      .catch(() => {
        setTimer(false);
        message.error("Something went wrong!");
      });
  };

  const send2FaEmailVerificationCode: send2FaEmailVerificationCodeType = (
    email
  ) => {
    send2FAEmailCode({
      body: JSON.stringify({
        email: email,
      }),
    })
      .then(() => {
        setVerifyType(VerifyType.EMAIL);
        setResendSMSOrEmailCodeFromCodeVerification(false);
        setTimer(true);
        message.success("A new code has been sent to your email");
        setInputValues(Array(6).fill(""));
      })
      .catch(() => {
        setTimer(false);
        message.error("Something went wrong!");
      });
  };

  const doFocus: doFocusType = (index) => {
    if (inputRefs && inputRefs.current) {
      inputRefs.current[index]?.focus();
    }
  };
  const numericValidator: numericValidatorType = (e, index) => {
    if (
      inputRefs?.current[index]?.input?.value === "" &&
      e?.key &&
      !/[0-9, "v"]/.test(e.key)
    ) {
      e.preventDefault();
    } else if (e?.key === "ArrowRight" && index < 5) {
      doFocus(index + 1);
    } else if (e?.key === "ArrowLeft" && index >= 0) {
      doFocus(index - 1);
    } else if (e?.key === "Delete" || (e?.key === "Backspace" && index >= 0)) {
      setInputValues(updateItemInArray(index, inputValues, ""));
      if (index !== 0) {
        doFocus(index - 1);
      }
    } else if (e?.key && !/[0-9]/.test(e.key)) {
      return "";
    } else if (e?.code === "Enter") {
      onSubmit(inputValues.join(""), verifyType);
    } else if (e?.currentTarget.value && e.currentTarget.value.length === 1) {
      e.currentTarget.value = "";
    }
  };

  const handleOnCopyAndPaste: handleOnCopyAndPasteType = (e, index) => {
    if (e?.clipboardData && isNaN(Number(e.clipboardData.getData("text")))) {
      message.error("Only numbers allowed");
      e.preventDefault();
    } else {
      const pastedValues =
        e?.clipboardData && e.clipboardData.getData("Text").split("");
      if (pastedValues) {
        setInputValues((inputValues) =>
          inputValues.map((val, i) =>
            i > pastedValues.length + 3 ? val : pastedValues[i - index]
          )
        );
      }
    }
  };

  const inputHandler: inputHandlerType = (e, index) => {
    const currentInput = e.currentTarget.value;
    if (currentInput.length === 1 && index < 5) {
      setInputValues(updateItemInArray(index, inputValues, currentInput));
      doFocus(index + 1);
    } else if (index === 5) {
      setInputValues(updateItemInArray(index, inputValues, currentInput));
    }
  };

  return (
    <Form
      name="basic"
      form={form}
      onFinish={(): void => {
        onSubmit(inputValues.join(""), verifyType);
      }}
      initialValues={{ remember: true }}
      preserve={false}
    >
      {verifyType === VerifyType.EMAIL ? (
        <div className="text-center mb-4">
          <MailOutlined className="text-3xl text-primary" />
        </div>
      ) : (
        <div className="text-center mb-4">
          <MobileOutlined className="text-3xl text-primary" />
        </div>
      )}

      <div className={"text-center text-sm font-normal mb-6"}>
        <>
          Enter the 6-digit verification code sent to{" "}
          {verifyType === VerifyType.EMAIL
            ? maskingHandler(user?.email)
            : maskingHandler(phoneNumber ?? user?.sms2FaPhoneNumber)}
        </>
      </div>

      <Form.Item name="verification">
        <div className="flex justify-center gap-x-4">
          {inputValues.map((val, index) => (
            <Input
              key={index}
              className="text-center caret-transparent"
              onKeyDown={(e): void => numericValidator(e, index)}
              maxLength={1}
              onPaste={(e): void => handleOnCopyAndPaste(e, index)}
              ref={(element): void => {
                if (inputRefs && inputRefs.current) {
                  inputRefs.current[index] = element;
                }
              }}
              value={valOrDefault(null, path([index], inputValues))}
              onChange={(e): void => inputHandler(e, index)}
            />
          ))}
        </div>
      </Form.Item>
      <div className="mt-6">
        <div className="flex justify-between">
          {timer ? (
            <div className="mt-1">
              <span className="text-muted">Resending code in&nbsp;</span>
              <Countdown
                valueStyle={{
                  fontSize: "14px",
                  display: "inline",
                  color: "rgba(0, 0, 0, 0.25)",
                }}
                value={Date.now() + 1000 * 60}
                format="ss"
                onFinish={onFinishTimer}
                className="inline"
              />
              <span className="text-muted">&nbsp;seconds</span>
            </div>
          ) : (
            <a
              className="mt-1 text-primary font-medium hover:underline"
              onClick={(): void =>
                onClickHandler(
                  user?.sms2FaEnabled,
                  phoneNumber ?? user?.sms2FaPhoneNumber
                )
              }
            >
              {resendCodeText}
            </a>
          )}

          {resendSMSOrEmailCodeFromCodeVerification && (
            <div className="mt-1 flex-1">
              Resend code to&nbsp;
              <a
                className="text-primary font-medium hover:underline"
                onClick={(): void => send2FaEmailVerificationCode(user?.email)}
              >
                Email
              </a>
              &nbsp;or&nbsp;
              <a
                className="text-primary font-medium hover:underline"
                onClick={(): void =>
                  send2FaPhoneVerificationCode(user?.sms2FaPhoneNumber)
                }
              >
                SMS
              </a>
              ?
            </div>
          )}

          <Form.Item shouldUpdate className="submit">
            <Button
              className={`w-32 
                ${
                  loading
                    ? "bg-muted"
                    : "bg-primary hover:bg-hover text-white border-0"
                }`}
              disabled={inputValues.join("").length < 6}
              htmlType={"submit"}
              loading={loading}
            >
              Verify
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

type codeVerificationType = {
  onSubmit: (a: string, type: string) => void;
  showEmailOrSmsOption?: (
    a: React.Dispatch<React.SetStateAction<string | null>>,
    b: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  phoneNumber?: string;
};
type send2FaEmailVerificationCodeType = (email: string | undefined) => void;
type send2FaPhoneVerificationCodeType = (
  phoneNumber: string | undefined
) => void;
type onClickHandlerType = (
  enabled: boolean | undefined,
  phoneNumber: string | undefined
) => void;
type sendVerificationCodeType = (phoneNumber: string | undefined) => void;
type numericValidatorType = (
  e: React.KeyboardEvent<HTMLInputElement> | undefined,
  index: number
) => void;
type handleOnCopyAndPasteType = (
  e: ClipboardEvent<HTMLInputElement> | undefined,
  index: number
) => void;
type inputHandlerType = (
  e: ChangeEvent<HTMLInputElement>,
  index: number
) => void;

type doFocusType = (index: number) => void;
type maskingHandlerType = (maskType?: string) => void;
