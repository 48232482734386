import React, { FC, useContext, useEffect, useState } from "react";
import {
  FullScreenButton,
  FullScreenWidget,
} from "../../../../general/FullScreenWidget";
import { Button, Switch } from "antd";
import { equals, findIndex, lensPath, path, pathOr, propEq, set } from "ramda";
import {
  LenderType,
  MileStoneType,
  TagType,
  TransactionType,
} from "../../../../../utils/types";
import {
  getCompanyTags,
  toggleMilestone,
} from "../../../../../services/services";
import { ResponseType, voidType } from "../../../../../utils/uiTypes";
import { DashboardTrackersLenders } from "../DashboardTrackersLenders";
import { DashboardTrackersTags } from "./DashboardTrackersTags";
import { TagModal } from "../../../../company/tags/TagModal";
import { PlusOutlined } from "@ant-design/icons";
import { TRANSACTION_USER_ROLE } from "../../../../../utils/transaction";
import { UserContext } from "../../../../../context/UserContext";
import { TransactionContext } from "../../../../../context/TransactionContext";
import { updateItemInArray, valOrDefault } from "../../../../../utils/utils";

export const DashboardTrackers: FC<DashboardTrackersType> = ({
  transactionId,
  showNotes,
  canEdit = false,
}) => {
  const { user, companyModulePreference } = useContext(UserContext);
  const {
    transaction,
    setTransaction,
    belongToDealTeam,
    belongToTxCreatorCompany,
  } = useContext(TransactionContext);
  const [tags, setTags] = useState<TagType[]>([]);
  const [loadingUpdates, setLoading] = useState<LoadingType>({});
  const [isGrouped, setIsGrouped] = useState(
    !!belongToDealTeam && companyModulePreference?.tags
  );
  const [modalOpen, setModalOpen] = useState(false);

  const toggleMilestoneState = (
    lenderId: string,
    mileStoneId: string
  ): void => {
    if (transaction) {
      const lenderIndex = findIndex(
        propEq("id", lenderId),
        transaction?.lenderDTOs
      );

      const milestoneDTOs = pathOr<MileStoneType[], MileStoneType[]>(
        [],
        ["lenderDTOs", lenderIndex, "milestoneDTOs"],
        transaction
      );

      const mileStoneIndex = findIndex(
        propEq("mileStoneId", mileStoneId),
        milestoneDTOs
      );

      const doneMilestone: boolean = pathOr(
        false,
        [mileStoneIndex, "done"],
        milestoneDTOs
      );

      setTransaction({
        ...transaction,
        lenderDTOs: updateItemInArray(lenderIndex, transaction?.lenderDTOs, {
          ...transaction?.lenderDTOs?.[lenderIndex],
          milestoneDTOs: set(
            lensPath([mileStoneIndex, "done"]),
            !doneMilestone,
            milestoneDTOs
          ),
        } as LenderType),
      } as TransactionType);
    }
  };

  const toggleMilestoneData: toggleMilestoneDataType =
    (petransactionId) => (lenderId, milestoneId) => {
      setLoading(
        (loading) =>
          set(lensPath([lenderId, milestoneId]), true, loading) as LoadingType
      );
      toggleMilestone({
        segments: {
          lenderId,
          milestoneId,
          petransactionId,
        },
      })
        .then(() => {
          toggleMilestoneState(lenderId, milestoneId);
          setLoading(
            (loading) =>
              set(
                lensPath([lenderId, milestoneId]),
                false,
                loading
              ) as LoadingType
          );
        })
        .catch(() => {
          setLoading(
            (loading) =>
              set(
                lensPath([lenderId, milestoneId]),
                false,
                loading
              ) as LoadingType
          );
        });
    };

  const fetchTags: voidType = () => {
    getCompanyTags({
      params: { peTransactionId: transaction?.peTransactionId ?? "" },
    })
      .then(({ data }: ResponseType<TagType[]>) => {
        setTags(data);
      })
      .catch(console.error);
  };

  useEffect(() => {
    setIsGrouped(!!belongToDealTeam);
    if (belongToDealTeam) {
      fetchTags();
    }
  }, [belongToDealTeam]);

  return (
    <FullScreenWidget>
      {(isFullScreen, trigger) => (
        <div
          className={`${
            isFullScreen &&
            "p-6 bg-gray-50 h-screen w-screen overflow-x-visible"
          } h-full flex flex-col`}
        >
          <div className={"flex items-center justify-end gap-x-4 mb-2"}>
            <div className={"w-fit mr-auto text-lg font-medium self-end"}>
              Institutions Tracker
            </div>
            {belongToDealTeam && companyModulePreference?.tags && (
              <Switch
                className={`${isGrouped ? "bg-success" : "bg-muted"}`}
                checked={isGrouped}
                checkedChildren={"GROUPED"}
                unCheckedChildren={"UN-GROUPED"}
                onChange={setIsGrouped}
              />
            )}
            <FullScreenButton isFullScreen={isFullScreen} trigger={trigger} />
          </div>
          {isGrouped ? (
            <>
              <DashboardTrackersTags
                tags={tags}
                lenders={transaction?.lenderDTOs ?? []}
                transactionId={transactionId}
                canEdit={canEdit}
                isFullScreen={isFullScreen}
                toggleMilestoneData={toggleMilestoneData(transactionId)}
                loading={loadingUpdates}
                showNotes={showNotes}
              />
              {user?.isAdmin && belongToTxCreatorCompany && (
                <div className="bg-white border-t shadow">
                  <Button
                    onClick={() => setModalOpen(true)}
                    className="text-left pl-5 border-0 text-primary disabled:border-opacity-60 font-medium w-full hover:bg-blue-50"
                    icon={<PlusOutlined />}
                  >
                    Add Tag
                  </Button>
                </div>
              )}
            </>
          ) : (
            <DashboardTrackersLenders
              id={transactionId}
              lenders={transaction?.lenderDTOs ?? []}
              canEdit={canEdit}
              toggleMilestoneData={toggleMilestoneData(transactionId)}
              loading={loadingUpdates}
              showNotes={showNotes}
              showTags={
                !!belongToDealTeam &&
                valOrDefault(false, companyModulePreference?.tags)
              }
            />
          )}
          {modalOpen && belongToTxCreatorCompany && (
            <TagModal
              onClose={() => setModalOpen(false)}
              onSubmit={(tag) => {
                if (tag.companyId === transaction?.companyId) {
                  setTags([...tags, tag]);
                }
                setModalOpen(false);
              }}
            />
          )}
        </div>
      )}
    </FullScreenWidget>
  );
};

type DashboardTrackersType = {
  transactionId: string;
  loading: boolean;
  canEdit?: boolean;
  showNotes: boolean;
};

type toggleMilestoneDataType = (
  petransactionId: string
) => (lenderId: string, milestoneId: string) => void;

type LoadingType = {
  [id: string]: Record<string, boolean>;
};
