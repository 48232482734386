import { CaretDownOutlined } from "@ant-design/icons";
import { Col, DatePicker, Input, InputNumber, Row, Select } from "antd";
import React, { FC, ReactNode } from "react";
import { KeyValueType, MonetaryRangeType } from "../../utils/types";
import moment from "moment/moment";
import { MonetaryRangeInput } from "../general/MonetaryRangeInput";
import { LoadingOverlay } from "../../utils/componentUtils";
import { getContainerForFullScreen } from "../../utils/container";

export const getInputField: FC<InputFieldProps<InputType>> = ({
  type,
  value,
  disabled,
  onChange,
  placeholder,
  options,
  suffix,
  prefix,
}) => {
  switch (type) {
    case InputType.TEXT:
      return (
        <div className={"relative"}>
          <Input
            value={value as string}
            className={`${disabled && "text-black pointer-events-none"}`}
            placeholder={placeholder ?? "Add Information"}
            bordered={false}
            onChange={(e): void => {
              onChange(e.target.value as any);
            }}
            defaultValue={value as string}
          />
          <i
            className={`absolute fa fa-pencil-alt text-transparent ${
              !disabled &&
              "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
            } right-0 bottom-0 mr-3 mb-2`}
          />
        </div>
      );
    case InputType.NUMBER:
      return (
        <div className={"relative flex flex-row w-full items-center"}>
          <Input
            prefix={prefix}
            width={"100%"}
            value={value as string}
            type={"number"}
            className={`w-full ${disabled && "text-black pointer-events-none"}`}
            placeholder={placeholder ?? "Add Information"}
            bordered={false}
            onChange={(e): void => {
              if (e) onChange(e.target.value);
            }}
            defaultValue={value as string}
            suffix={suffix ?? <></>}
          />
          <i
            className={`fa fa-pencil-alt text-transparent ${
              !disabled &&
              "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
            } right-0 bottom-0`}
          />
        </div>
      );
    case InputType.DESCRIPTION:
      return (
        <div className={"relative"}>
          <Input.TextArea
            value={value as string}
            className={`${disabled && "text-black pointer-events-none"}`}
            placeholder={placeholder ?? "Add Information"}
            autoSize={{ minRows: 1 }}
            maxLength={249}
            bordered={false}
            onChange={(e): void => {
              onChange(e.target.value as any);
            }}
            defaultValue={value as string}
          />
          <i
            className={`absolute fa fa-pencil-alt text-transparent ${
              !disabled &&
              "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
            } right-0 bottom-0 mr-3 mb-2`}
          />
        </div>
      );
    case InputType.MONETARY_RANGE:
      return (
        <MonetaryRangeInput
          value={value as MonetaryRangeType}
          onChange={onChange}
          disabled={disabled}
        />
      );
    case InputType.SELECT:
      return (
        <Select
          notFoundContent={"No Options Available"}
          className={`w-full ${disabled && "text-black pointer-events-none"}`}
          bordered={false}
          getPopupContainer={getContainerForFullScreen}
          placeholder={placeholder ?? "Add Information"}
          suffixIcon={
            <CaretDownOutlined
              className={`text-transparent ${
                !disabled &&
                "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
              }`}
            />
          }
          allowClear
          value={value}
          defaultValue={value}
          onChange={onChange}
          showSearch
          optionFilterProp={"label"}
        >
          {options?.map(({ key, label, icon }) => (
            <Select.Option key={key} value={key} label={label}>
              <div className={"flex items-center gap-x-2"}>
                {icon && (
                  <img
                    src={icon}
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                  />
                )}
                {label}
              </div>
            </Select.Option>
          ))}
        </Select>
      );
    case InputType.MULTIPLE_SELECT:
      return (
        <Select
          notFoundContent={"No Options Available"}
          className={`w-full ${disabled && "text-black pointer-events-none"}`}
          bordered={false}
          getPopupContainer={getContainerForFullScreen}
          placeholder={placeholder ?? "Add Information"}
          suffixIcon={
            <CaretDownOutlined
              className={`text-transparent ${
                !disabled &&
                "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
              }`}
            />
          }
          optionFilterProp={"label"}
          value={value}
          defaultValue={value}
          onChange={onChange}
          mode={"multiple"}
        >
          {options?.map(({ key, label, icon }) => (
            <Select.Option key={key} value={key} label={label}>
              <div className={"flex items-center gap-x-2"}>
                {icon && (
                  <img
                    src={icon}
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                  />
                )}
                {label}
              </div>
            </Select.Option>
          ))}
        </Select>
      );
    case InputType.DATE:
      return (
        <DatePicker
          className={`w-full ${disabled && "text-black pointer-events-none"}`}
          placeholder={placeholder ?? "MM/DD/YYYY"}
          format={"LL"}
          getPopupContainer={getContainerForFullScreen}
          bordered={false}
          onChange={(e): void => {
            onChange((e ? Date.parse(e?.format()) : null) as any);
          }}
          value={value ? moment(Number(value)).utc() : undefined}
          defaultValue={value ? moment(Number(value)).utc() : undefined}
          suffixIcon={
            <i
              className={`far fa-calendar text-transparent ${
                !disabled &&
                "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
              }`}
            />
          }
        />
      );
    default:
      return <></>;
  }
};

export const InputField: FC<InputFieldProps<InputType>> = ({
  type,
  onChange,
  placeholder,
  options,
  disabled,
  value,
  label,
  loading = false,
  prefix,
  suffix,
}) => {
  return (
    <div className={"flex flex-row items-start group hover:!bg-gray-100"}>
      <Row className={`py-1 relative w-full`}>
        <Col xs={24} md={8}>
          <div className={"text-left md:text-right w-full h-full"}>
            <p className={"px-4 text-gray-400/90 inline align-sub font-medium"}>
              {label?.toUpperCase()}
            </p>
          </div>
        </Col>
        <Col xs={24} md={16}>
          <div
            className={`min-h-[33px] px-1 md:px-2 flex flex-row items-start justify-between w-full`}
          >
            <div
              className={`border-b min-w-[50%] ${
                !disabled &&
                "group-hover:border-blue-400 group-focus-within:border-blue-400"
              }  md:border-transparent`}
            >
              <LoadingOverlay loading={loading}>
                {getInputField({
                  type,
                  onChange,
                  placeholder,
                  options,
                  disabled,
                  value,
                  label,
                  prefix,
                  suffix,
                })}
              </LoadingOverlay>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export enum InputType {
  DESCRIPTION,
  MONETARY_RANGE,
  SELECT,
  MULTIPLE_SELECT,
  DATE,
  TEXT,
  NUMBER,
}

type InputFieldProps<T extends InputType> = {
  label: string;
  disabled?: boolean;
  type: T;
  placeholder?: string;
  value?: ValueType<T> | null;
  onChange: (value: ValueType<T>) => void;
  options?: KeyValueType[];
  loading?: boolean;
  suffix?: ReactNode;
  prefix?: ReactNode;
};

type ValueType<T extends InputType> = T extends InputType.DESCRIPTION
  ? string
  : T extends InputType.SELECT
  ? string
  : T extends InputType.MULTIPLE_SELECT
  ? string[]
  : T extends InputType.DATE
  ? number
  : T extends InputType.MONETARY_RANGE
  ? MonetaryRangeType
  : undefined;
