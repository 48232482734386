import React, { FC, ReactNode } from "react";
import { CustomSpin } from "./CustomSpin";
import { path } from "ramda";
import { tableColumnHeader } from "../../utils/componentUtils";

export const StatisticValue: FC<StatisticValueType> = ({
  children,
  extras,
  label,
  loading = false,
  onClick,
  isActive = false,
}) => {
  return (
    <div
      onClick={onClick}
      className={`m-1.5 w-full bg-white grow flex items-stretch flex-col border border-gray-200 ${
        onClick && "cursor-pointer hover:drop-shadow-md"
      } ${isActive ? "drop-shadow-md" : "drop-shadow-sm"}`}
    >
      <div
        className={
          "flex flex-row items-center p-2 border-b border-gray-200 bg-gray-50 select-all justify-between"
        }
      >
        {tableColumnHeader(label)}
        {extras && (
          <div className={"flex flex-row items-center gap-x-2"}>{extras}</div>
        )}
      </div>
      <div
        className={
          "relative flex items-center justify-center text-3xl font-extralight text-gray-400 py-4"
        }
      >
        <CustomSpin
          loading={loading}
          size={"small"}
          loadingText={""}
          transparent={false}
        />
        {children}
      </div>
    </div>
  );
};

type StatisticValueType = {
  children: ReactNode;
  isActive?: boolean;
  label: ReactNode;
  extras?: ReactNode | ReactNode[];
  loading?: boolean;
  onClick?: () => void;
};
