import React, { FC } from "react";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { PasswordPolicy } from "./settings/PasswordPolicy";

export const CompanySettings: FC = () => {
  usePageTitle("Settings");

  return <PasswordPolicy />;
};

type ParamsType = {
  companyMenu: string;
  id: string;
};
