import React, { FC, ReactNode, useEffect, useState } from "react";
import { Button, Drawer, Segmented, Switch, Table } from "antd";
import {
  CompanyModuleAccessType,
  CompanyModuleType,
  ModuleAccessLevelType,
} from "../../utils/types";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { equals, path, pathOr } from "ramda";
import { KeyLabelType } from "../../utils/uiTypes";
import { ColumnsType } from "antd/es/table";
import { tableColumnHeader } from "../../utils/componentUtils";
import { AccessLevelType, CompanyEnumType } from "../../utils/enums";
import {
  DEFAULT_COMPANY_MODULE_PREFERENCE,
  MODULES,
} from "../../utils/module-util";

export const CompanyModuleModal: FC<CompanyModuleModalType> = function ({
  onClose,
  onSave,
  companyName,
  info = DEFAULT_COMPANY_MODULE_PREFERENCE,
  moduleSettings,
  companyType,
}: CompanyModuleModalType) {
  const [companyModulePreference, setCompanyModulePreference] =
    useState<CompanyModuleAccessType>(DEFAULT_COMPANY_MODULE_PREFERENCE);
  const [loading, setLoading] = useState(false);

  const onChange: onChangeType = (module, checked) => {
    setCompanyModulePreference((v) => ({
      ...v,
      moduleAccessDTO: { ...v.moduleAccessDTO, [module]: checked },
    }));
  };

  const getModuleData = (
    key: string,
    moduleData: CompanyModuleType,
    config: AccessLevelType
  ): boolean => {
    if (config === AccessLevelType.CUSTOM) {
      return pathOr(false, [key], moduleData);
    } else {
      return pathOr(false, [config, key], moduleSettings);
    }
  };

  const columns: ColumnsType<KeyLabelType<string, ReactNode>> = [
    {
      title: tableColumnHeader("Module Name"),
      dataIndex: "label",
      className: "capitalize group-hover:bg-blue-50",
    },
    {
      title: tableColumnHeader("Status"),
      dataIndex: "key",
      className: "group-hover:bg-blue-50",
      render: (key) => (
        <Switch
          loading={loading}
          disabled={
            companyModulePreference.accessLevel !== AccessLevelType.CUSTOM ||
            loading
          }
          key={key}
          onChange={(checked) => onChange(key, checked)}
          checked={getModuleData(
            key,
            companyModulePreference.moduleAccessDTO,
            companyModulePreference.accessLevel
          )}
          checkedChildren={<span className={"text-xxs"}>ENABLED</span>}
          unCheckedChildren={<span className="text-xxs">DISABLED</span>}
          className={
            getModuleData(
              key,
              companyModulePreference.moduleAccessDTO,
              companyModulePreference.accessLevel
            )
              ? "bg-success"
              : "bg-muted"
          }
        />
      ),
    },
  ];

  const onDrawerClose: VoidFunction = () => {
    onClose();
    setCompanyModulePreference(DEFAULT_COMPANY_MODULE_PREFERENCE);
  };

  const isSettingsDisabled: isSettingsDisabledType = (value, companyType) => {
    if (equals(CompanyEnumType.LENDER, companyType))
      return (
        (value as AccessLevelType) !== AccessLevelType.LENDER_DEFAULT &&
        (value as AccessLevelType) !== AccessLevelType.CUSTOM
      );
    else if (equals(CompanyEnumType.LENDER_SPONSOR, companyType)) {
      return (
        (value as AccessLevelType) !== AccessLevelType.ADVANCED &&
        (value as AccessLevelType) !== AccessLevelType.CUSTOM
      );
    }
    return false;
  };

  useEffect(() => {
    setCompanyModulePreference(info);
  }, [info]);

  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      title={
        <div className={"flex items-center justify-between"}>
          <span>{companyName}&nbsp;Company Modules&nbsp;</span>
          <Segmented
            disabled={loading}
            options={Object.keys(AccessLevelType).map((value) => ({
              label: value.replaceAll("_", " "),
              value: value,
              disabled: isSettingsDisabled(
                value as AccessLevelType,
                companyType
              ),
            }))}
            defaultValue={info.accessLevel}
            onChange={(accessLevel) =>
              setCompanyModulePreference({
                ...companyModulePreference,
                accessLevel: accessLevel as AccessLevelType,
              })
            }
          />
        </div>
      }
      open={true}
      destroyOnClose={true}
      closable={!loading}
      width={"90%"}
      onClose={onClose}
      footer={
        <div className={"flex flex-row items-center gap-x-2"}>
          <Button key="close" onClick={onClose} disabled={loading}>
            Close
          </Button>
          <Button
            className={PRIMARY_BUTTON_STYLE}
            loading={loading}
            disabled={loading}
            onClick={() => {
              setLoading(true);
              onSave(companyModulePreference)
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
            }}
            key="submit"
          >
            Submit
          </Button>
        </div>
      }
    >
      <div className={"flex h-full overflow-y-auto"}>
        <Table
          columns={columns}
          dataSource={MODULES}
          pagination={false}
          sticky={true}
          rowClassName={"group"}
        />
      </div>
    </Drawer>
  );
};

type CompanyModuleModalType = {
  onClose: () => void;
  onSave: (accessData: CompanyModuleAccessType) => Promise<void>;
  companyName?: string;
  info: CompanyModuleAccessType;
  moduleSettings: Record<AccessLevelType, CompanyModuleType>;
  companyType?: CompanyEnumType;
};

type parseDataType = (info: string) => void;

type onChangeType = (module: string, checked: boolean) => void;

type isSettingsDisabledType = (
  value: AccessLevelType,
  companyType?: CompanyEnumType
) => boolean;
