import React, { FC, useEffect, useState } from "react";
import { CompanyType, MergeCompanyType } from "../../utils/types";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { Alert, Button, Form, message, Modal, Select, Table } from "antd";
import {
  checkPortfolioIntersections,
  getInstitutions,
  mergeInstitutions,
} from "../../services/services";

import { findIndex, propEq } from "ramda";
import { ConfirmAction } from "../../utils/confirmationModals";
import {
  filterOption,
  filterSort,
  removeItemFromArray,
} from "../../utils/utils";
import { ResponseType, voidType } from "../../utils/uiTypes";
import { formItemRequiredRule } from "../../utils/formUtils";
import { CompanyDetails } from "./CompanyDetails";
import {
  DANGER_BUTTON_STYLE,
  PRIMARY_BUTTON_STYLE,
  SUCCESS_BUTTON_STYLE,
} from "../../utils/cssConfigs";

const columns = [
  {
    title: "Company Name",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "Portfolio Name",
    dataIndex: "portfolioName",
    key: "portfolioName",
  },
];
export const CompanyMerger: FC = () => {
  usePageTitle("Company Merger");

  const [companyAdmin, setCompanyAdmin] = useState<CompanyType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [merging, setMerging] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [openPortfolioIntersection, setOpenPortfolioIntersection] =
    useState(false);
  const [portfolioIntersections, setPortfolioIntersections] = useState<
    portfolioIntersectionsType[]
  >([]);
  const [form] = Form.useForm();
  const nameValue = Form.useWatch("fromInstitution", form);
  const toInstitution = Form.useWatch("toInstitution", form);

  const getCompanyForAdminInfo: voidType = () => {
    getInstitutions({})
      .then(({ data }: ResponseType<CompanyType[]>) => {
        setLoading(false);
        setCompanyAdmin(data);
      })
      .catch((error: string) => {
        message.error(error ?? "Try again later!");
        setLoading(false);
      });
  };

  const mergeCompanies: mergeCompaniesType = (value) => {
    return new Promise((resolve, reject) => {
      ConfirmAction(
        `Confirm Merge`,
        () => {
          message.loading({ key: "merge", content: "Merging Companies" });
          mergeInstitutions({
            segments: {
              toCompany: value.toInstitution,
              fromCompany: value.fromInstitution,
            },
          })
            .then(() => {
              const index = findIndex(
                propEq("id", value.fromInstitution),
                companyAdmin
              );
              setCompanyAdmin((prevState) =>
                removeItemFromArray(index, prevState)
              );
              message.success({
                key: "merge",
                content: "Merged institutions successfully!",
              });
              resolve();
            })
            .catch((error: string) => {
              reject();
              message.error({
                key: "merge",
                content: error ? error : "Error merging Institutions!",
              });
            });
        },
        <>
          Are you sure you want to merge these institutions? This will be
          irreversible.
          <br />
        </>,
        {
          onCancel: () => reject(),
        },
        "Confirm"
      );
    });
  };

  const getPortfolioIntersections = () => {
    console.log(nameValue, toInstitution);

    checkPortfolioIntersections({
      segments: {
        fromCompany: nameValue,
        toCompany: toInstitution,
      },
    })
      .then(({ data }: ResponseType<portfolioIntersectionsType[]>) => {
        setLoading(false);
        setPortfolioIntersections(data);
        setOpenPortfolioIntersection(true);
      })
      .catch((error: string) => {
        message.error(error ?? "Try again later!");
        setLoading(false);
      });
  };

  useEffect(() => {
    setReviewed(false);
  }, [nameValue, toInstitution]);

  useEffect(() => {
    getCompanyForAdminInfo();
  }, []);

  return (
    <div>
      <div className="p-6">
        <h1
          className={`${
            loading ? "text-muted text-3xl mb-5" : "text-3xl mb-5"
          }`}
        >
          Company Merger
        </h1>
        <div className={"bg-white border p-6"}>
          <div className={"flex flex-col gap-y-5 h-full"}>
            <Alert
              showIcon={true}
              message={
                <div>
                  <u>To Proceed with the merge, read the following steps:</u>
                  <br />
                  <ol className={"list-decimal list-inside ml-5"}>
                    <li>Select 1st Company you want to merge.</li>
                    <li>
                      Select 2nd company in which you want to merge 1st Company.
                    </li>
                    <li>Review the Details.</li>
                    <li>Click on Merge.</li>
                  </ol>
                </div>
              }
              type={"warning"}
            />
            <Alert
              type={"error"}
              showIcon={true}
              message={
                <div>
                  The Merge Action will be <b>Irreversible</b>. Please proceed
                  with <b>Caution!!</b>
                </div>
              }
            />
            <Form
              form={form}
              name="form"
              onFinish={(values) => {
                setMerging(true);
                mergeCompanies(values)
                  .then(() => setMerging(false))
                  .catch(() => setMerging(false));
              }}
              className={
                "flex flex-row items-center gap-x-10 w-full items-stretch justify-between p-1 shadow rounded-sm"
              }
            >
              <Form.Item
                className={"w-full mb-0"}
                name={"fromInstitution"}
                rules={[formItemRequiredRule]}
              >
                <Select
                  disabled={merging}
                  className={`w-full`}
                  bordered={false}
                  showArrow={true}
                  placeholder={"Select Institution to Merge"}
                  showSearch={true}
                  filterOption={filterOption}
                  filterSort={filterSort}
                >
                  {companyAdmin
                    .filter((company) => company.id !== toInstitution)
                    ?.map(({ name, id }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <div>
                <i className="fa-solid fa-arrow-up-from-bracket text-xl rotate-90" />
              </div>
              <Form.Item
                className={"w-full mb-0"}
                name={"toInstitution"}
                rules={[formItemRequiredRule]}
              >
                <Select
                  disabled={merging}
                  className={`w-full`}
                  bordered={false}
                  showArrow={true}
                  placeholder={"Select Institution To Into"}
                  showSearch={true}
                  filterOption={filterOption}
                  filterSort={filterSort}
                >
                  {companyAdmin
                    .filter((company) => company.id !== nameValue)
                    ?.map(({ id, name }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Form>
            <div className={"flex flex-row gap-x-2"}>
              <div className={"w-1/2"}>
                <CompanyDetails id={nameValue} />
              </div>
              <div className={"w-1/2"}>
                <CompanyDetails id={toInstitution} />
              </div>
            </div>
            {nameValue && toInstitution && (
              <Button
                className={`${
                  reviewed ? SUCCESS_BUTTON_STYLE : PRIMARY_BUTTON_STYLE
                } w-full mt-auto`}
                onClick={() => {
                  getPortfolioIntersections();
                }}
                disabled={reviewed}
              >
                {reviewed
                  ? "Click On Merge"
                  : "Validate Portfolio intersections"}
              </Button>
            )}
          </div>
          <div
            className={"flex flex-row items-center gap-x-2 justify-end pt-3"}
          >
            <Button
              loading={merging}
              className={DANGER_BUTTON_STYLE}
              key="submit"
              form={"form"}
              disabled={!reviewed}
              htmlType={"submit"}
            >
              Merge
            </Button>
            ,
          </div>
        </div>
      </div>
      <Modal
        title="Validate Portfolio Intersections"
        centered
        open={openPortfolioIntersection}
        onOk={() => setOpenPortfolioIntersection(false)}
        onCancel={() => setOpenPortfolioIntersection(false)}
        footer={[
          <Button
            key="back"
            onClick={() => setOpenPortfolioIntersection(false)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              setOpenPortfolioIntersection(false);
              setReviewed(true);
            }}
          >
            Everything Looks Good! Proceed
          </Button>,
        ]}
      >
        <Table dataSource={portfolioIntersections} columns={columns} />
      </Modal>
    </div>
  );
};

type mergeCompaniesType = (value: MergeCompanyType) => Promise<void>;

type portfolioIntersectionsType = {
  companyName: string;
  portfolioName: string;
};
