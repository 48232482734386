import React, {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Divider,
  Dropdown,
  Input,
  List,
  Menu,
  message,
  Popover,
  Tooltip,
} from "antd";
import moment from "moment";
import { LenderType } from "../../../utils/types";
import { CustomAvatar } from "../../general/CustomAvatar";
import { TextOverFlowHandle } from "../../general/TextOverFlowHandle";
import { isEmptyOrNil } from "../../../utils/utils";
import { pathOr } from "ramda";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  deleteWebFormVersion,
  renameWebFormVersion,
} from "../../../services/services";
import { ResponseType } from "../../../utils/uiTypes";
import { ConfirmDelete } from "../../../utils/confirmationModals";

export const VersionTab: FC<VersionTabType> = function ({
  versionName,
  versionId,
  webformId,
  isLastVersion,
  selected = false,
  isOffline,
  isOnlyVersion,
  date,
  published,
  numberOfLenders = 0,
  numberOfRespondants = 0,
  respondStatus = {},
  showRespondantData = false,
  sponsorPublishDate = {},
  canRemind = false,
  canEdit = false,
  institutions = [],
  onSelect,
  onRemind = () => {},
  onDelete = () => {},
  onRename = () => {},
}: VersionTabType) {
  const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteVersion = () => {
    ConfirmDelete(
      "Are you sure you want to delete this version? Deleting a termsheet version cannot be undone. Please proceed with caution.",
      () => {
        setDeleting(true);
        message.loading({
          content: "Deleting version...",
          duration: 0,
          key: "deleting-" + versionId,
        });
        deleteWebFormVersion({
          segments: {
            id: webformId,
            versionId,
          },
        })
          .then(({ data }: ResponseType) => {
            message.success({
              content: "Version has been deleted!",
              key: "deleting-" + versionId,
            });
            onDelete();
            setDeleting(false);
          })
          .catch(() => {
            message.error({
              content: "Something went wrong, please try again later!",
              key: "deleting-" + versionId,
            });
            setDeleting(false);
          });
      },
      <></>
    );
  };

  const renameWebform = () => {
    setSaving(true);
    renameWebFormVersion({
      segments: {
        id: webformId,
        versionId,
      },
      body: JSON.stringify({
        versionName: newName,
      }),
    })
      .then(({ data }: ResponseType) => {
        onRename(newName);
        setSaving(false);
        setIsEditing(false);
        message.success("Version name has been updated!");
      })
      .catch(() => {
        setSaving(false);
        setIsEditing(false);
        message.error("Something went wrong, please try again later!");
      });
  };

  const menu = (
    <Menu>
      <Menu.Item
        key={1}
        onClick={() => {
          setDropdownVisible(false);
          setIsEditing(true);
        }}
        icon={<EditOutlined />}
      >
        Rename
      </Menu.Item>
    </Menu>
  );
  const handleRename = () => {
    setIsEditing(true);
  };

  const handleRenameSave = () => {
    if (newName !== versionName && !isEmptyOrNil(newName)) {
      renameWebform();
    } else {
      setIsEditing(false);
    }
  };
  const content = () => {
    return (
      <div className={"w-72"}>
        <div className={"flex justify-between"}>
          <div className={"text-xs text-secondary font-medium uppercase"}>
            {isOffline ? "Institutions" : "Shared With"}
          </div>
          <>
            {canRemind && Object.values(respondStatus).includes(false) && (
              <div
                role={"button"}
                onClick={(e) => {
                  onRemind();
                }}
                className={"text-xs text-primary font-medium uppercase"}
              >
                Remind Institution(s)
              </div>
            )}
          </>
        </div>
        <Divider className={"mt-3 mb-0"} />
        <div>
          <List
            itemLayout="horizontal"
            className={"max-h-72 overflow-y-scroll hide-scrollbar"}
          >
            {institutions?.map(({ id, name }) => {
              const lenderDate = pathOr<string, Record<string, string>>(
                "",
                [id],
                sponsorPublishDate
              ) as string;
              return (
                <List.Item key={id} className={"py-1 h-10 w-full"}>
                  <div className={"flex flex-row h-full w-full items-center"}>
                    <CustomAvatar
                      data={name[0]}
                      color={name[0]}
                      size={"small"}
                      className={"flex-none text-xxs"}
                    />
                    <div
                      className={"flex flex-col ml-1 flex-grow text-xs w-1/2"}
                    >
                      <TextOverFlowHandle text={name} />
                      <span className={"flex-none text-muted text-xxs"}>
                        {isEmptyOrNil(lenderDate)
                          ? ""
                          : moment(lenderDate).format("ll")}
                      </span>
                    </div>
                    <span className={"flex-none mx-1 text-muted text-xxs"}>
                      {!respondStatus[id] && "Awaiting Reply"}
                    </span>
                  </div>
                </List.Item>
              );
            })}
          </List>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (selected) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selected]);
  return (
    <div
      ref={ref}
      className={`relative group inline-flex h-full w-full items-center justify-center cursor-pointer border-x ${
        selected ? "bg-[#e6f6ff]" : "bg-gray-50 hover:bg-[#e6f6ff]"
      }`}
    >
      {isEditing ? (
        <Input
          disabled={saving}
          suffix={saving ? <LoadingOutlined /> : undefined}
          autoFocus
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          onBlur={handleRenameSave}
          onPressEnter={handleRenameSave}
          className="w-56 m-1"
        />
      ) : (
        <div
          className={`w-64 flex justify-between group-hover:text-primary ${
            selected ? "text-primary" : "text-dark"
          }`}
        >
          {!isEmptyOrNil(date) && (
            <div
              className={"flex-1 pl-2 overflow-hidden"}
              role={"button"}
              onClick={onSelect}
            >
              {!isEmptyOrNil(versionName) ? (
                <>
                  <div className={"flex flex-row flex-grow gap-1"}>
                    <Tooltip title={"Version name is private to you"}>
                      <InfoCircleOutlined />
                    </Tooltip>
                    <div
                      className={
                        "font-medium text-xs flex-grow overflow-hidden"
                      }
                    >
                      <TextOverFlowHandle text={versionName} />
                    </div>
                  </div>
                  <div className={"font-light text-xxs"}>
                    {moment(date).format("LLL")}
                  </div>
                </>
              ) : (
                <>
                  <div className={"font-medium text-xs"}>
                    {moment(date).format("ll")}
                  </div>
                  <div className={"font-light text-xxs"}>
                    {moment(date).format("LT")}
                  </div>
                </>
              )}
            </div>
          )}
          <div className={"flex-initial pr-2"}>
            {showRespondantData ? (
              <Popover
                content={content}
                placement="topRight"
                destroyTooltipOnHide={true}
              >
                {isOffline ? (
                  <div>
                    {isLastVersion ? (
                      <div>
                        <div className={"text-xs"}>
                          <i className="mx-1 text-success fa fa-circle fa-xs"></i>
                          Draft{" "}
                          <span
                            className={"ml-1 font-light text-xxs text-right"}
                          >
                            {numberOfLenders > 0 ? numberOfLenders : ""}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className={"text-xs"}>
                          <i className="mx-1 text-secondary fa fa-circle fa-xs"></i>
                          Complete{" "}
                          <span
                            className={"ml-1 font-light text-xxs text-right"}
                          >
                            {numberOfLenders > 0 ? numberOfLenders : ""}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {numberOfLenders === numberOfRespondants ? (
                      <div>
                        <div className={"text-xs"}>
                          <i className="mx-1 text-secondary fa fa-circle fa-xs"></i>
                          Complete{" "}
                          <span
                            className={"ml-1 font-light text-xxs text-right"}
                          >
                            {numberOfRespondants} / {numberOfLenders}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className={"text-xs"}>
                          <i className="mx-1 text-success fa fa-circle fa-xs"></i>
                          Live{" "}
                          <span
                            className={"ml-1 font-light text-xxs text-right"}
                          >
                            {numberOfRespondants} / {numberOfLenders}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Popover>
            ) : (
              <div>
                {published ? (
                  <>
                    {numberOfLenders === numberOfRespondants ? (
                      <div className={"text-xs"}>
                        <i className="mx-1 text-secondary fa fa-circle fa-xs"></i>
                        Complete
                      </div>
                    ) : (
                      <div>
                        <div className={"text-xs"}>
                          <i className="mx-1 text-success fa fa-circle fa-xs"></i>
                          Live
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    <div className={"text-xs"}>
                      <i className="mx-1 text-primary fa fa-circle fa-xs"></i>
                      Draft
                    </div>
                  </div>
                )}
              </div>
            )}
            {canEdit && (
              <div
                className={`absolute bottom-0.5 right-1 inline-flex justify-center items-center rounded-full hover:bg-blue-200 h-5 w-5 ${
                  dropdownVisible && "bg-blue-200"
                }`}
              >
                <Dropdown
                  disabled={deleting}
                  onOpenChange={setDropdownVisible}
                  trigger={["click"]}
                  overlay={menu}
                  placement={"bottomRight"}
                  overlayClassName={"shadow-sm"}
                >
                  <EllipsisOutlined
                    rotate={90}
                    className={`${
                      dropdownVisible && "text-primary"
                    } group-hover:text-primary`}
                  />
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

type VersionTabType = {
  versionId: string;
  webformId: string;
  versionName: string;
  isOnlyVersion: boolean;
  isLastVersion: boolean;
  selected?: boolean;
  isOffline: boolean;
  date: string;
  published: boolean;
  numberOfLenders?: number;
  sponsorPublishDate?: Record<string, string>;
  numberOfRespondants?: number;
  respondStatus: { [k: string]: boolean };
  showRespondantData?: boolean;
  canRemind?: boolean;
  canEdit?: boolean;
  institutions?: Array<LenderType>;
  onSelect: () => void;
  onRemind?: () => void;
  onDelete?: () => void;
  onRename?: (newName: string) => void;
};
