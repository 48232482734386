import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { TransactionContext } from "../../../../context/TransactionContext";
import {
  getAllocationTableKeys,
  getPrimaryList,
  percentAllocatedColumn,
  TableDataType,
} from "../../../../utils/allocation";
import {
  getAllCompanies,
  getAllocationsForTable,
  getAllocationTable,
} from "../../../../services/services";
import {
  AllocationTableType,
  AnalyticsChartType,
  ElementType,
  PermissionType,
} from "../../../../utils/enums";
import { fetchWithIdType, ResponseType } from "../../../../utils/uiTypes";
import { AllocationTable } from "./AllocationTable";
import { filter, isEmpty, pathOr, propEq } from "ramda";
import { AnalyticsData } from "../../../analytics/AnalyticsData";
import { UserContext } from "../../../../context/UserContext";
import { buildQueryParams } from "../../../../utils/utils";
import {
  AllocationKeyType,
  AllocationType,
  CompanyType,
} from "../../../../utils/types";
import moment from "moment/moment";
import { CustomSpin } from "../../../general/CustomSpin";
import { Button, Empty, message } from "antd";
import { usePageTitle } from "../../../../customHooks/usePageTitle";
import { useHistory } from "react-router";
import { ACTION_BUTTON_CSS } from "../../../../utils/cssConfigs";
import {
  FullScreenButton,
  FullScreenWidget,
} from "../../../general/FullScreenWidget";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { AddAllocationModal } from "./AddAllocationModal";

export const Allocation: FC = () => {
  usePageTitle("Allocation");
  const history = useHistory();
  const { height: windowHeight } = useWindowDimensions();

  const { transactionId, transaction, permissions } =
    useContext(TransactionContext);
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [tableConfig, setTableConfig] = useState<TableDataType | null>(null);
  const [tableData, setTableData] = useState<AllocationType[]>([]);
  const [isInsightsAdmin, setIsInsightsAdmin] = useState<boolean>(false);
  const [chartParams, setChartParams] = useState<string>("");
  const [allocationTableKeys, setAllocationTableKeys] = useState<
    AllocationKeyType[]
  >([]);
  const [allCompanies, setAllCompanies] = useState<CompanyType[]>([]);

  const fetchAllocationKeys: fetchWithIdType = (transactionId) => {
    getAllocationTable({
      segments: {
        elementId: transactionId,
        allocationElementType: ElementType.PETRANSACTION,
      },
      params: { allocationType: AllocationTableType.FINAL_HOLD },
    }).then(({ data = [] }: ResponseType<TableDataType[]>) => {
      if (data.length > 0) {
        setTableConfig(data[0]);
        fetchAllocationTableData(transactionId, pathOr("", [0, "id"], data));
      }
    });
  };

  const updateChartParams: keyVoidType = (keys): void => {
    setChartParams(
      String(
        buildQueryParams<AllocationKeyType>(
          keys.filter(
            ({ allocationTableType, canDelete }) =>
              allocationTableType === AllocationTableType.FINAL_HOLD &&
              canDelete
          ),
          "keyName",
          "allocationKeys",
          "&allocationTables=Final Hold",
          "&transactionIds=" + transactionId
        ) +
          `&from=${moment(transaction?.createDate).valueOf()}&to=${moment(
            Number(transaction?.targetDate)
          ).valueOf()}&now=${moment().valueOf()}`
      )
    );
  };

  const fetchAllocationTableData: getAllocationTableDataType = (
    elementId,
    tableId
  ) => {
    getAllocationsForTable({
      segments: {
        elementId,
        tableId,
        allocationElementType: ElementType.PETRANSACTION,
      },
    })
      .then(({ data = [] }: ResponseType<AllocationType[]>) => {
        setTableData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        message.error("Unable to get Allocation Data");
      });
  };

  const initiateKeysData: keyVoidType = (keys) => {
    setAllocationTableKeys(keys);
    updateChartParams(keys);
  };

  useEffect(() => {
    getAllCompanies({})
      .then(({ data = [] }: ResponseType<CompanyType[]>) => {
        setAllCompanies(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    setIsInsightsAdmin(
      !!user?.elementPermissions
        .find((item) => item.elementType === "INSIGHTS")
        ?.permissions.includes(PermissionType.ADMIN_INSIGHTS)
    );
  }, [user]);

  useEffect(() => {
    getAllocationTableKeys(initiateKeysData);
  }, []);

  useEffect(() => {
    if (transactionId) {
      fetchAllocationKeys(transactionId);
    }
  }, [transactionId]);

  return (
    <FullScreenWidget>
      {(isFullScreen, trigger): ReactNode => (
        <div
          className={`${
            isFullScreen && "bg-gray-50 h-screen w-screen overflow-y-scroll"
          } h-full`}
        >
          {loading && (
            <div className={"w-full h-full absolute top-0 left-0"}>
              <CustomSpin loading={loading} />
            </div>
          )}
          {transaction?.lenderDTOs.length === 0 ? (
            <div
              className={
                "p-0 grid grid-cols-12 h-full max-h-full flex items-center"
              }
            >
              <Empty
                className={"col-span-12 text-gray-400"}
                description={"No Institutions History!"}
              >
                <Button
                  className={"bg-primary hover:bg-hover border-0 text-white"}
                  icon={<i className="fas fa-plus mr-1" />}
                  onClick={(): void => {
                    transaction &&
                      history.push(
                        `/transactions/${transaction.peTransactionId}/institutions`
                      );
                  }}
                >
                  Add New Institution
                </Button>
              </Empty>
            </div>
          ) : (
            <>
              <div className={"p-6"}>
                <AllocationTable
                  extraOptions={
                    <FullScreenButton
                      isFullScreen={isFullScreen}
                      trigger={trigger}
                    />
                  }
                  loading={
                    loading || isEmpty(tableConfig) || isEmpty(allCompanies)
                  }
                  hasDate={true}
                  title={"Final Allocation"}
                  pageType={ElementType.PETRANSACTION}
                  editPermission={permissions.includes(
                    PermissionType.ADMIN_PETRANSACTION
                  )}
                  peTransactionIdOrPortfolioId={transactionId ?? ""}
                  keys={filter(
                    propEq(
                      "allocationTableType",
                      AllocationTableType.FINAL_HOLD
                    ),
                    allocationTableKeys
                  )}
                  config={tableConfig}
                  data={tableData}
                  extraColumns={percentAllocatedColumn}
                  toolbar={true}
                  tableHeight={
                    isFullScreen ? windowHeight - 275 : windowHeight / 3
                  }
                  triggerUpdate={(): void =>
                    updateChartParams(allocationTableKeys)
                  }
                  companies={allCompanies}
                  institutionModal={(
                    onSubmit,
                    selectedCompanies,
                    disabled
                  ): ReactNode => (
                    <AddAllocationModal
                      disabled={disabled}
                      onSubmit={onSubmit}
                      companies={getPrimaryList(
                        selectedCompanies,
                        transaction?.lenderDTOs ?? []
                      )}
                    />
                  )}
                >
                  {() => <div className={"w-full flex justify-between"}></div>}
                </AllocationTable>
              </div>
              {isInsightsAdmin && !isFullScreen && (
                <div className={"p-6 md:flex"}>
                  <AnalyticsData
                    chartType={AnalyticsChartType.BAR}
                    label={"Total Amount By Company"}
                    name={"TOTAL_AMOUNT_BY_COMPANY"}
                    params={chartParams}
                    footer={false}
                  />
                  <AnalyticsData
                    chartType={AnalyticsChartType.PIE}
                    label={"Total Amount By Company"}
                    name={"TOTAL_AMOUNT_BY_COMPANY"}
                    params={chartParams}
                    footer={false}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </FullScreenWidget>
  );
};

export type getAllocationTableDataType = (
  elementId: string,
  tableId: string
) => void;
type keyVoidType = (keys: AllocationKeyType[]) => void;
