import React, { FC, ReactNode } from "react";
import { getColorPallet } from "../../utils/colors";
import { ElementTag } from "../company/tags/ElementTag";
import { TagType } from "../../utils/types";

export const Thread: FC<ThreadType> = function ({
  prefix = <></>,
  active = false,
  colorLiteral,
  description,
  onClick,
  icon = <></>,
  minHeight = "16",
  children,
  suffix,
  tag,
}: ThreadType) {
  return (
    <div
      className={`relative cursor-pointer flex flex-row items-center overflow-hidden w-13 md:w-full border-b p-0 hover:bg-blue-50 bg-white ${"min-h-".concat(
        minHeight.toString()
      )} `}
      onClick={onClick}
    >
      {prefix}
      <div
        className={`flex flex-row items-center h-full ${"min-h-".concat(
          minHeight.toString()
        )} p-0 md:p-4 overflow-hidden w-0 md:w-full ${
          active ? "bg-gray-100" : "bg-transparent"
        }`}
      >
        <p
          className={`flex flex-col mb-0 ${
            description && "gap-y-4"
          } truncate whitespace-nowrap`}
        >
          <span className="tracking-wide whitespace-normal">{children}</span>
          <span className={"text-xs font-medium text-gray-400"}>
            {description && description}
          </span>
        </p>
        <span className="ml-auto text-black" />
        {tag && tag.length ? (
          <div>
            <ElementTag tag={tag[0]} />
          </div>
        ) : (
          ""
        )}
        {icon}
        {suffix ? (
          suffix
        ) : (
          <i
            style={{ fontSize: 8, fontWeight: "bold" }}
            className={`far fa-chevron-right ml-5 ${!active && "invisible"}`}
          />
        )}
      </div>
      {colorLiteral && (
        <div
          className={`absolute right-0 flex items-center pr-0.5 pl-1 ${"min-h-".concat(
            minHeight.toString()
          )} h-full py-2 m-0 text-2xl font-light text-default ${
            active ? getColorPallet(colorLiteral, "bg") : "bg-transparent"
          }`}
        >
          {""}
        </div>
      )}
    </div>
  );
};

type ThreadType = {
  prefix?: ReactNode;
  active?: boolean;
  description?: ReactNode;
  onClick: () => void;
  icon?: ReactNode;
  colorLiteral?: string;
  minHeight?: string | number;
  children?: ReactNode;
  suffix?: ReactNode;
  tag?: TagType[];
};
