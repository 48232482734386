import { Button, Modal } from "antd";
import { DashboardNotifications } from "./DashboardNotifications";
import emptyCommunication from "../../../../images/icons/EmptyCommunacations_grey.svg";
import React, { FC, useContext, useState } from "react";
import { NotificationActivityType } from "../../../../utils/types";
import { TransactionContext } from "../../../../context/TransactionContext";
import { MultipleSelect } from "../../../general/MultipleSelect";
import { filter, isEmpty } from "ramda";

const getFilteredActivities: getFilteredActivitiesType = (
  { externalIds, type },
  activities
) => {
  switch (type) {
    case FilterOptionType.ALL:
    case FilterOptionType.INTERNAL:
    case FilterOptionType.EXTERNAL:
      return isEmpty(externalIds)
        ? activities
        : filter(
            ({ dataMap }) =>
              externalIds.includes(dataMap?.LENDER_TITLE) ||
              externalIds.includes(dataMap?.COMPANY),
            activities
          );
  }
};

export const DashboardNotificationsModal: FC<
  DashboardNotificationsModalType
> = ({ onClose, notifications, name, transactionId = "", showFilters }) => {
  const { transaction } = useContext(TransactionContext);

  const DEFAULT_FILTERS: FilterType = {
    externalIds: [],
    type: FilterOptionType.ALL,
    transactionId: transaction?.peTransactionId ?? "",
  };

  const [filters, setFilters] = useState<FilterType>(DEFAULT_FILTERS);

  const onModalClose = () => {
    setFilters(DEFAULT_FILTERS);
    onClose();
  };

  return (
    <Modal
      title={
        <div className={"flex items-center gap-x-2 justify-between pr-8"}>
          <div className={"text-gray-400"}>{name}</div>
          {showFilters && (
            <div className={"flex items-center gap-x-2"}>
              <MultipleSelect
                label={"Institutions"}
                onChange={(value) =>
                  setFilters({ ...filters, externalIds: value })
                }
                placeholder={"Select Institutions"}
                value={filters?.externalIds}
                options={(transaction?.lenderDTOs ?? [])?.map(({ name }) => ({
                  filterValue: name,
                  value: name,
                  label: name,
                }))}
              />
            </div>
          )}
        </div>
      }
      open={!!notifications}
      width={"65%"}
      bodyStyle={{ padding: "24px 0 0 24px" }}
      onCancel={onModalClose}
      footer={[
        <Button key="close" onClick={onModalClose}>
          Close
        </Button>,
      ]}
    >
      <div className={"overflow-y-auto max-h-[25rem]"}>
        <DashboardNotifications
          title={(name ?? "").toLocaleLowerCase()}
          icon={emptyCommunication}
          notifications={
            getFilteredActivities(filters, notifications ?? []) ?? []
          }
          id={transactionId}
        />
      </div>
    </Modal>
  );
};

type DashboardNotificationsModalType = {
  notifications?: NotificationActivityType[];
  onClose: VoidFunction;
  name?: string | null;
  transactionId: string;
  showFilters: boolean;
};

enum FilterOptionType {
  ALL = "ALL",
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

type FilterType = {
  type: FilterOptionType;
  externalIds: string[];
  transactionId: string;
};

type getFilteredActivitiesType = (
  filters: FilterType,
  activities: NotificationActivityType[]
) => NotificationActivityType[];
