import React, { FC, useEffect, useState } from "react";
import { Button, message, Modal, Switch, Table, Tag, Tooltip } from "antd";
import { WebhookEventType, SubscriptionType } from "../../utils/types";
import { usePageTitle } from "../../customHooks/usePageTitle";
import {
  ACTION_BUTTON_CSS,
  PRIMARY_BUTTON_STYLE,
} from "../../utils/cssConfigs";
import { CustomSpin } from "../general/CustomSpin";
import { EditOutlined } from "@ant-design/icons";
import { WebhookForm } from "./WebhookForm";
import { removeItemFromArray, updateItemInArray } from "../../utils/utils";
import { findIndex, propEq } from "ramda";
import {
  deleteSubscription,
  editSubscription,
  getEventTypes,
  getSubscriptions,
} from "../../services/services";
import { ResponseType } from "../../utils/uiTypes";
import { ConfirmDelete } from "../../utils/confirmationModals";
import { tableColumnHeader } from "../../utils/componentUtils";
import { PresetColorTypes } from "antd/lib/_util/colors";

export const WebhooksSubscriptions: FC = function () {
  usePageTitle("Webhooks Subscriptions");

  const [modal, setModal] = useState<ModalProperties | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [eventTypes, setEventTypes] = useState<WebhookEventType[]>([]);
  const [subscriptions, setSubscriptions] = useState<Array<SubscriptionType>>(
    []
  );

  const DEFAULT_COLUMNS = [
    {
      title: tableColumnHeader("Id"),
      className: "w-[150px] truncate",
      render: function name(record: SubscriptionType) {
        return <span>{record?.id}</span>;
      },
    },
    {
      title: tableColumnHeader("URL"),
      className: "min-w-[80px] max-w-[200px] truncate",
      render: function name(record: SubscriptionType) {
        return <span>{record?.url}</span>;
      },
    },
    {
      title: tableColumnHeader("Event Types"),
      className: "w-[450px] truncate",
      render: function name(record: SubscriptionType) {
        return (
          <div className={"flex flex-row flex-wrap gap-2"}>
            {record.eventTypes.map((v, i) => {
              return (
                <Tag
                  color={PresetColorTypes[i % PresetColorTypes.length]}
                  key={v.id}
                >
                  {v?.name}
                </Tag>
              );
            })}
          </div>
        );
      },
    },
    {
      title: tableColumnHeader("Enabled"),
      className: "group-hover:bg-blue-50 min-w-[80px] max-w-[200px] truncate",
      render: function enable(record: SubscriptionType) {
        return (
          <Switch
            className={record?.enabled ? "bg-primary" : "bg-muted"}
            checked={record?.enabled}
            onChange={(v) => {
              onUpdate({ ...record, enabled: v });
            }}
          />
        );
      },
    },
    {
      title: tableColumnHeader("Actions"),
      key: "operation",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: function operation(record: SubscriptionType) {
        return (
          <div className={"flex gap-2 justify-end items-center"}>
            <Tooltip title={"Edit Subscription"}>
              <Button
                className={`hover:text-primary opacity-0 group-hover:opacity-100 ${ACTION_BUTTON_CSS}`}
                onClick={(): void => {
                  setModal({ value: record });
                }}
              >
                <EditOutlined className={" cursor-pointer"} />
              </Button>
            </Tooltip>
            <Tooltip title={"Delete Subscription"}>
              <Button
                className={`${ACTION_BUTTON_CSS} hover:text-red-500 opacity-0 group-hover:opacity-100`}
                icon={<i className="fas fa-trash-alt" />}
                onClick={(): void => {
                  ConfirmDelete(
                    "Are you sure?",
                    () => {
                      removeSubscription(record);
                    },
                    <span>
                      Id: {record.id}
                      <br />
                      Url: {record.url}
                    </span>
                  );
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onUpdate = (value: SubscriptionType) => {
    editSubscription({
      segments: {
        id: value.id,
      },
      body: JSON.stringify(value),
    })
      .then(({ data }: ResponseType<SubscriptionType>) => {
        const index = findIndex(propEq("id", value.id), subscriptions);
        if (index >= 0) {
          setSubscriptions(updateItemInArray(index, subscriptions, data));
        }
      })
      .catch(() => {
        message.error("Sorry, something went wrong, please try again!");
      });
  };

  const removeSubscription = (value: SubscriptionType) => {
    deleteSubscription({
      segments: {
        id: value?.id,
      },
    })
      .then(() => {
        const index = findIndex(propEq("id", value.id), subscriptions);
        if (index >= 0) {
          setSubscriptions(removeItemFromArray(index, subscriptions));
        }
      })
      .catch(() => {
        message.error("Sorry, something went wrong, please try again!");
      });
  };

  useEffect(() => {
    Promise.all([getSubscriptions({}), getEventTypes({})])
      .then(([subscriptions, eventTypes]) => {
        setSubscriptions(subscriptions.data);
        setEventTypes(eventTypes.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div
      className={`relative max-h-full w-full h-full overflow-y-auto flex flex-col`}
    >
      <CustomSpin loading={loading} />
      <div
        className={
          "mb-5 flex flex-row justify-between items-center pb-0 p-6 w-full"
        }
      >
        <span className={"text-2xl font-medium"}>Webhooks</span>
        <Button
          className={PRIMARY_BUTTON_STYLE}
          onClick={() => {
            setModal({});
          }}
        >
          Add Subscription
        </Button>
      </div>
      <div className={`w-full h-full flex-initial p-6 pt-0`}>
        <Table<SubscriptionType>
          rowClassName={() => "relative group hover:bg-blue-50"}
          rowKey={(v) => v.id}
          className={`border transition duration-300 ease-out transform`}
          tableLayout={"auto"}
          columns={DEFAULT_COLUMNS}
          pagination={false}
          dataSource={subscriptions}
        />
      </div>
      {modal && (
        <Modal
          title={modal?.value ? "Edit Subscription" : "Add Subscription"}
          visible={modal !== null}
          destroyOnClose={true}
          footer={false}
          closable={true}
          onCancel={(): void => setModal(null)}
        >
          <WebhookForm
            eventTypes={eventTypes}
            value={modal.value}
            onSubmit={(v) => {
              const index = findIndex(propEq("id", v.id), subscriptions);
              if (index >= 0) {
                setSubscriptions(updateItemInArray(index, subscriptions, v));
              } else {
                setSubscriptions([...subscriptions, v]);
              }
              setModal(null);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

type ModalProperties = {
  value?: SubscriptionType;
};
