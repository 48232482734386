import React, { FC, ReactNode, useContext, useEffect } from "react";
import {
  TransactionContext,
  TransactionProvider,
} from "../context/TransactionContext";
import { WithTopNav } from "../components/layout/WithTopNav";
import { useHistory, useParams } from "react-router";
import { MenuRouteType } from "../utils/uiTypes";
import { Tooltip } from "antd";
import { Dashboard } from "../components/transaction/menu/dashboard/Dashboard";
import { Communications } from "../components/transaction/menu/communications/Communications";
import { DataRoom } from "../components/transaction/menu/dataroom/DataRoom";
import { Allocation } from "../components/transaction/menu/allocation/Allocation";
import { Notes } from "../components/transaction/menu/notes/Notes";
import { TaskList } from "../components/transaction/menu/taskList/Tasklist";
import { ManageDealTeam } from "../components/transaction/menu/ManageDealTeam";
import { ManageTrackers } from "../components/transaction/menu/milestones/ManageTrackers";
import {
  PermissionType,
  PETransactionCreationType,
  PETransactionCreatorType,
  PETransactionTagType,
} from "../utils/enums";
import { ManageInstitutions } from "../components/transaction/menu/institutions/ManageInstitutions";
import { TermsheetRoutes } from "../components/termsheet/transaction-termsheet/TermsheetRoutes";
import { CompanyModuleType } from "../utils/types";
import { usePageTitle } from "../customHooks/usePageTitle";
import { InternalPageNotFound } from "./InternalPageNotFound";
import { Fees } from "../components/transaction/menu/Fees";
import { ManageNDAConfig } from "../components/transaction/menu/ndaConfig/ManageNDAConfig";
import { all, and, any, equals, or } from "ramda";
import { UserContext } from "../context/UserContext";
import { LenderNotes } from "../components/transaction/menu/notes/LenderNotes";
import { KpiComponent } from "../components/transaction/menu/dashboard/Kpi";
import { LongFormTemplate } from "../components/transaction/menu/long-form-template/LongFormTemplate";
import { ManagePermission } from "../components/transaction/menu/permissions/ManagePermission";
import { LenderEngagement } from "../components/transaction/menu/lender-engagement/LenderEngagement";

const getTransactionTag = (peTransactionTagType: string): ReactNode =>
  peTransactionTagType && (
    <div
      className={`flex gap-x-2 items-center text-xxs rounded-md p-1 tracking-widest font-bold text-white cursor-default select-none ${
        peTransactionTagType === PETransactionTagType.LIVE &&
        "group-hover:bg-primary"
      } ${
        (peTransactionTagType === PETransactionTagType.COMPLETED ||
          peTransactionTagType === PETransactionTagType.SIGNED_NOT_CLOSED) &&
        "group-hover:bg-green-500"
      } ${
        (peTransactionTagType === PETransactionTagType.DROPPED ||
          peTransactionTagType === PETransactionTagType.LOST) &&
        "group-hover:bg-red-500"
      }`}
    >
      <i
        className={`fas fa-circle text-xxs group-hover:hidden block ${
          peTransactionTagType === PETransactionTagType.LIVE && "text-primary"
        } ${
          (peTransactionTagType === PETransactionTagType.COMPLETED ||
            peTransactionTagType === PETransactionTagType.SIGNED_NOT_CLOSED) &&
          "text-green-500"
        } ${
          (peTransactionTagType === PETransactionTagType.DROPPED ||
            peTransactionTagType === PETransactionTagType.LOST) &&
          "text-red-500"
        }`}
      />
      <span className={"hidden group-hover:inline-flex"}>
        {peTransactionTagType}
      </span>
    </div>
  );

export const MENU_OPTIONS = (
  id: string,
  permissions: Array<PermissionType>,
  belongToDealTeam: boolean,
  transactionModules: CompanyModuleType,
  companyModules: CompanyModuleType,
  offline = false,
  creatorType: PETransactionCreatorType
): MenuRouteType[] => [
  {
    Component: Dashboard,
    path: "/transactions/:id/dashboard",
    exact: true,
    title: "Dashboard",
    key: "dashboard",
    permission:
      transactionModules.dashboard &&
      any(equals(true), [
        and(!offline, permissions.includes(PermissionType.VIEW_PETRANSACTION)),
        and(offline, equals(creatorType, PETransactionCreatorType.BORROWER)),
      ]),
  },
  {
    Component: Communications,
    path: "/transactions/:id/communications",
    exact: true,
    title: "Communications",
    key: "communications",
    permission: all(equals(true), [
      transactionModules.communication,
      or(
        permissions.includes(PermissionType.VIEW_COMMUNICATION),
        !belongToDealTeam
      ),
    ]),
  },
  {
    Component: TermsheetRoutes,
    path: [
      "/transactions/:id/termsheet",
      "/transactions/:id/termsheet/:webFormId",
    ],
    exact: false,
    title: "Termsheet",
    key: "termsheet",
    permission:
      transactionModules.termsheet &&
      or(permissions.includes(PermissionType.VIEW_BID_FORM), !belongToDealTeam),
  },
  {
    Component: DataRoom,
    path: "/transactions/:id/dataroom",
    exact: true,
    title: "Data Room",
    key: "dataroom",
    permission:
      transactionModules.dataroom &&
      or(
        permissions.includes(PermissionType.VIEW_DATA_ROOM),
        !belongToDealTeam
      ),
  },
  {
    Component: KpiComponent,
    path: "/transactions/:id/deal-metrics",
    exact: true,
    isPrivate: true,
    props: { showBanner: true },
    key: "deal-metrics",
    badge: { label: "New", color: "success" },
    title: (
      <Tooltip
        title={
          "Update the key deal details for your record. This is private to your deal team and will help you analyze your deal activity as an institution"
        }
      >
        Deal Metrics
      </Tooltip>
    ),
    permission:
      transactionModules.dealMetrics &&
      or(
        permissions.includes(PermissionType.VIEW_DEAL_METRICS),
        !belongToDealTeam
      ),
  },
  {
    Component: Allocation,
    path: "/transactions/:id/allocation",
    exact: true,
    isPrivate: true,
    title: (
      <Tooltip title={"Tab is only visible to the members of your deal team"}>
        Allocation
      </Tooltip>
    ),
    key: "allocation",
    permission:
      transactionModules.allocation &&
      belongToDealTeam &&
      permissions.includes(PermissionType.VIEW_ALLOCATION),
  },
  {
    Component: Fees,
    path: "/transactions/:id/fees",
    exact: true,
    isPrivate: true,
    title: (
      <Tooltip title={"Tab is only visible to the members of your deal team"}>
        Fees
      </Tooltip>
    ),
    key: "fees",
    permission:
      transactionModules.fees &&
      belongToDealTeam &&
      permissions.includes(PermissionType.VIEW_FEES),
  },
  {
    Component: Notes,
    path: "/transactions/:id/notes",
    exact: true,
    isPrivate: true,
    title: (
      <Tooltip title={"Tab is only visible to the members of your deal team"}>
        Notes
      </Tooltip>
    ),
    key: "notes",
    permission:
      transactionModules.notes &&
      belongToDealTeam &&
      permissions.includes(PermissionType.VIEW_NOTE),
  },
  {
    Component: LenderNotes,
    path: "/transactions/:id/notes",
    exact: true,
    isPrivate: true,
    title: (
      <Tooltip title={"Tab is only visible to the members of your Institution"}>
        Notes
      </Tooltip>
    ),
    key: "notes",
    permission: companyModules?.notes && !belongToDealTeam,
  },
  {
    Component: TaskList,
    path: "/transactions/:id/tasklist",
    exact: true,
    isPrivate: true,
    title: (
      <Tooltip title={"Tab is only visible to the members of your deal team"}>
        Task List
      </Tooltip>
    ),
    key: "tasklist",
    permission:
      transactionModules.taskList &&
      belongToDealTeam &&
      permissions.includes(PermissionType.VIEW_TASK),
  },
  {
    Component: LongFormTemplate,
    path: [
      "/transactions/:id/completions",
      "/transactions/:id/completions/:sectionId",
    ],
    badge: { label: "New", color: "success" },
    exact: true,
    title: (
      <Tooltip
        title={
          "Update key details across the deal & portfolio company for your record. Accessible once a deal is linked to a portfolio company, this info is private to your deal team."
        }
      >
        Completion Memo
      </Tooltip>
    ),
    key: "completions",
    isPrivate: true,

    permission:
      transactionModules.completionsMemo &&
      or(
        permissions.includes(PermissionType.VIEW_COMPLETION_MEMO),
        !belongToDealTeam
      ),
  },
  {
    Component: LenderEngagement,
    path: "/transactions/:id/engagement",
    exact: true,
    isPrivate: true,
    visible: true,
    badge: { label: "New", color: "success" },
    permission: belongToDealTeam && transactionModules.lenderEngagement,
    title: (
      <Tooltip title={"Tab is only visible to the members of your deal team"}>
        Engagement
      </Tooltip>
    ),
    key: "engagement",
  },
  {
    Component: ManageInstitutions,
    path: "/transactions/:id/team",
    exact: true,
    title: "Manage Team",
    key: "team",
    isPrivate: true,
    permission:
      !belongToDealTeam && permissions.includes(PermissionType.VIEW_LENDER),
  },
  {
    path: "",
    exact: true,
    title: "Manage",
    key: "manage",
    permission: belongToDealTeam,
    subMenu: [
      {
        Component: ManageInstitutions,
        path: "/transactions/:id/institutions",
        exact: true,
        title: "Manage Institutions",
        isPrivate: true,
        key: "institutions",
        permission: belongToDealTeam,
      },
      {
        Component: ManageDealTeam,
        path: "/transactions/:id/team",
        exact: true,
        title: "Manage Deal Team",
        isPrivate: true,
        key: "team",
        permission: and(
          transactionModules.dealTeam,
          and(offline, creatorType === PETransactionCreatorType.BORROWER) ||
            belongToDealTeam
        ),
      },
      {
        Component: ManageTrackers,
        path: "/transactions/:id/trackers",
        exact: true,
        isPrivate: true,
        title: "Manage Tracker",
        key: "trackers",
        permission: belongToDealTeam && transactionModules.trackers,
      },
      {
        Component: ManageNDAConfig,
        path: "/transactions/:id/nda",
        exact: true,
        isPrivate: true,
        title: "Manage Click-through",
        key: "nda",
        permission: all(equals(true), [
          !offline,
          belongToDealTeam,
          transactionModules.nda ?? false,
          permissions.includes(PermissionType.ADMIN_PETRANSACTION),
        ]),
      },
      {
        Component: ManagePermission,
        path: "/transactions/:id/permission",
        exact: true,
        title: "Manage Permissions",
        key: "permission",
        permission: and(
          and(transactionModules.dealTeam, belongToDealTeam),
          permissions.includes(PermissionType.ADMIN_PETRANSACTION)
        ),
      },
    ],
  },
  {
    Component: InternalPageNotFound,
    props: {
      url: `/transactions/${id}/dashboard`,
    },
    exact: false,
    path: "*",
    permission: true,
    title: "",
    key: "not-found",
    visible: false,
  },
];

const GetMenu: FC = function () {
  usePageTitle("Transaction");
  const {
    transaction,
    belongToDealTeam,
    permissions,
    modules,
    setTransactionId,
  } = useContext(TransactionContext);
  const { companyModulePreference } = useContext(UserContext);

  const history = useHistory();
  const { transactionMenu, id }: ParamsType = useParams();

  useEffect(() => {
    setTransactionId(id);
  }, [id]);

  return (
    transaction &&
    companyModulePreference &&
    modules && (
      <WithTopNav
        defaultValue={"dashboard"}
        selectedKeys={[transactionMenu]}
        menu={MENU_OPTIONS(
          transaction?.peTransactionId,
          permissions,
          belongToDealTeam ?? false,
          modules,
          companyModulePreference,
          transaction.peTransactionCreationType ===
            PETransactionCreationType.OFFLINE,
          transaction.peTransactionCreatorType
        )}
        title={
          <div
            className={
              "flex flex-col md:flex-row justify-between items-start md:items-center gap-1 leading-3 pt-1"
            }
          >
            <span className={"inline-flex items-center gap-x-1 group"}>
              {transaction.peTransactionTitle}
              {getTransactionTag(transaction.peTransactionTagType)}
            </span>
            {transaction.peTransactionCreationType ===
              PETransactionCreationType.OFFLINE && (
              <div
                className={`flex gap-x-2 items-center text-xxs p-1 tracking-widest text-white font-bold text-white cursor-default select-none bg-amber-500`}
              >
                <span className={"inline-flex"}>OFFLINE TRANSACTION</span>
              </div>
            )}
          </div>
        }
        gotoRoute={({ key }): void => {
          if (transaction?.portfolioId && key === "completions") {
            history.push(`/transactions/${id}/${key}/deal-metrics`);
          } else {
            history.push(`/transactions/${id}/${key}`);
          }
        }}
      />
    )
  );
};

export const Transaction: FC = function () {
  return (
    <TransactionProvider>
      <GetMenu />
    </TransactionProvider>
  );
};

type ParamsType = {
  transactionMenu: string;
  id: string;
};
