import React, { FC, useEffect, useState } from "react";
import { Button, DatePicker, Input, message, Modal, Select } from "antd";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import { AllocationTableMappingType } from "../../../utils/types";
import { compose, filter, isEmpty, isNil, not, values, without } from "ramda";
import { editAllocationTableVersion } from "../../../services/services";
import { ResponseType } from "../../../utils/uiTypes";
import { stopPropagationOnEnter } from "../../../utils/keyboardUtils";
import moment from "moment";
import {
  getModalContainerForFullScreen,
  getPopupContainerForSelect,
} from "../../../utils/container";
import { AllocationTableVersionTypes } from "../../../utils/allocation";
import { PortfolioSectionEnumType } from "../../../utils/enums";

const isEmptyOrNil = (v: any) => isEmpty(v) || isNil(v);
export const filterByNotEmptyOrNil = filter(compose(not, isEmptyOrNil));

export const EditVersionModal: FC<EditVersionModalType> = ({
  visible,
  version,
  dates = [],
  onSave,
  onCancel,
  sectionType,
}) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] =
    useState<Partial<AllocationTableMappingType> | null>(null);

  const onClear = () => {
    setValue(null);
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);
    editAllocationTableVersion({
      segments: {
        id: value?.id,
      },
      body: JSON.stringify({
        ...value,
        allocationDate: value?.allocationDate
          ? moment(value.allocationDate).valueOf()
          : undefined,
      }),
    })
      .then(({ data }: ResponseType<AllocationTableMappingType>) => {
        onClear();
        onSave(data);
        message.success("Version Updated Successfully");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (version && visible) {
      setValue(version);
    }
  }, [version, visible]);

  const isDisabled =
    loading || !value?.name?.trim() || !value.allocationDate?.trim();

  return (
    <Modal
      getContainer={getModalContainerForFullScreen}
      title={"Edit Key Information"}
      open={visible}
      width={600}
      onCancel={() => {
        onClear();
        onCancel();
      }}
      destroyOnClose={true}
      footer={[
        <div className={"w-full text-right"} key={0}>
          <Button
            disabled={loading}
            onClick={() => {
              onClear();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              sectionType === PortfolioSectionEnumType.ALLOCATION
                ? isDisabled || !value?.tableVersionType?.trim()
                : isDisabled
            }
            loading={loading}
            className={PRIMARY_BUTTON_STYLE}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>,
      ]}
    >
      <div className={"flex flex-col gap-4"}>
        <div className={"grid grid-cols-2 gap-4"}>
          <div>
            <div className={"w-full mb-2"}>Name</div>
            <Input
              placeholder={"Enter table name"}
              value={value?.name}
              onChange={(e) =>
                setValue((v) => (v ? { ...v, name: e.target.value } : null))
              }
            />
          </div>
          <div>
            <div className={"w-full mb-2"}>Date</div>
            <DatePicker
              getPopupContainer={getPopupContainerForSelect}
              disabledDate={(current): boolean => {
                console.log(version?.allocationDate, dates);
                const list = version?.allocationDate
                  ? without(version?.allocationDate, dates)
                  : dates;
                const match = filterByNotEmptyOrNil(list).find((o) =>
                  moment(o)
                    .startOf("day")
                    .isSame(moment(current).startOf("day"))
                );
                console.log("match " + match);
                return match != undefined;
              }}
              onChange={(v) => {
                setValue((k) =>
                  k
                    ? {
                        ...k,
                        allocationDate: v?.format().toString(),
                      }
                    : null
                );
              }}
              value={
                !isEmpty(value?.allocationDate) && !isNil(value?.allocationDate)
                  ? moment(value?.allocationDate)
                  : null
              }
              className={`w-full`}
              format={"ll"}
              placeholder={"Select date"}
              onKeyDown={stopPropagationOnEnter}
            />
          </div>
        </div>

        {sectionType === PortfolioSectionEnumType.ALLOCATION && (
          <>
            <div>Financing or Holding Event Type</div>
            <Select
              notFoundContent={"No Types Available"}
              getPopupContainer={(trigger): HTMLElement =>
                trigger.parentElement
              }
              placeholder="Select financing or holding event type"
              showSearch={true}
              optionFilterProp={"title"}
              onSelect={(_: string, option): void => {
                setValue((k) =>
                  k
                    ? {
                        ...k,
                        tableVersionType: option.key,
                      }
                    : null
                );
              }}
              value={value?.tableVersionType}
            >
              {values(AllocationTableVersionTypes).map((type) => (
                <Select.Option
                  key={type.value}
                  value={type.value}
                  title={type.label}
                >
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
      </div>
    </Modal>
  );
};

type EditVersionModalType = {
  sectionType:
    | PortfolioSectionEnumType.ALLOCATION
    | PortfolioSectionEnumType.FEES;
  visible: boolean;
  version: AllocationTableMappingType;
  dates: string[];
  onSave: (value: AllocationTableMappingType) => void;
  onCancel: () => void;
};
