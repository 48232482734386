import React, { FC, useEffect, useState } from "react";
import { AllocationKeyType } from "../../../../utils/types";
import { isEmpty, prop, sortBy } from "ramda";
import { Button, Checkbox, Skeleton } from "antd";
import { voidType } from "../../../../utils/uiTypes";
import { useHistory } from "react-router";
import { AllocationTableType } from "../../../../utils/enums";
import { ConfirmAction } from "../../../../utils/confirmationModals";
import { PRIMARY_BUTTON_STYLE } from "../../../../utils/cssConfigs";
import { containsAll } from "../../../../utils/utils";

export const AllocationColumns: FC<AllocationColumnsType> = ({
  value = [],
  onChange,
  keys,
  onClose,
  type,
}) => {
  const history = useHistory();

  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div className={"flex flex-col items-start justify-between"}>
      {!isEmpty(keys) ? (
        <>
          <div className={"text-gray-400 pb-1 font-bold"}>
            Select Facilities
          </div>
          <Checkbox.Group
            className={"flex flex-col space-y-1"}
            options={sortBy(prop("canDelete"), keys).map(
              ({ keyName, id, canDelete, allocationTableType }) => ({
                value: id,
                label: keyName,
                disabled: !canDelete || type !== allocationTableType,
              })
            )}
            value={selected}
            onChange={(values): void => {
              setSelected(values as string[]);
            }}
          />
          <div className={"pt-3 flex space-x-2"}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={(): void => {
                if (!containsAll(value, selected)) {
                  ConfirmAction(
                    "Are you sure you want to proceed with this action?",
                    () => {
                      onChange(selected);
                      onClose();
                    },
                    <div>
                      <br />
                      <b>WARNING:</b>&nbsp;By removing a column, you will lose
                      all data entered in that column
                    </div>,
                    { okButtonProps: { className: PRIMARY_BUTTON_STYLE } },
                    "Okay"
                  );
                } else {
                  onChange(selected);
                  onClose();
                }
              }}
              className={"ml-1 bg-primary hover:bg-hover text-white border-0"}
            >
              Select
            </Button>
          </div>
        </>
      ) : (
        <div>
          <p className={"text-gray-400 pb-0 font-bold"}>
            No Facility Types Created
          </p>
          <Skeleton />
          <p
            onClick={(): void => history.push(`/company/settings`)}
            className={"text-blue-500 hover:underline mt-3 cursor-pointer"}
          >
            Link to create facility type
          </p>
        </div>
      )}
    </div>
  );
};

type AllocationColumnsType = {
  keys: AllocationKeyType[];
  onChange: (data: string[]) => void;
  value?: string[];
  onClose: voidType;
  type?: AllocationTableType;
};
