import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AllocationKeyType,
  DealCloudTransactionDTOType,
  IndustryType,
  TransactionType,
} from "../../utils/types";
import { Button, DatePicker, Form, Input, message, Select } from "antd";
import {
  editTransaction,
  getAllocationKeys,
  getIndustries,
  getPETransaction,
} from "../../services/services";
import TransactionIcon from "../../images/icons/Transaction.svg";
import moment from "moment";
import { fetchWithIdType, ResponseType, voidType } from "../../utils/uiTypes";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import {
  filterOption,
  getObjectFromPropertyValue,
  getOptionsByKey,
  populateArrayWithPropertyPath,
  valOrDefault,
} from "../../utils/utils";
import {
  AllocationTableType,
  CompanyEnumType,
  ThirdPartyIntegrationType,
} from "../../utils/enums";
import { useHistory, useParams } from "react-router";
import { equals, or, prop } from "ramda";
import { TRANSACTIONS_PAGE_URL } from "../../utils/redirect";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { NewOrEditTransactionValueType } from "../../pages/CreateTransaction";
import { formItemRequiredRule } from "../../utils/formUtils";
import { UserContext } from "../../context/UserContext";
import { isOffline, statusColumnHeader } from "../../utils/transaction";
import { DealConnect } from "../dealCloud/DealConnect";

export const EditTransaction: FC = function () {
  usePageTitle("Transaction");
  const { user, companyModulePreference } = useContext(UserContext);
  const { editTransactionId } = useParams<ParamsType>();
  const history = useHistory();
  const [form] = useForm();

  const [transaction, setTransaction] = useState<TransactionType | null>(null);
  const [industries, setIndustries] = useState<IndustryType[]>([]);
  const [allocationKeys, setAllocationKeys] = useState<AllocationKeyType[]>([]);
  const [thirdPartyEntity, setThirdPartyEntity] =
    useState<DealCloudTransactionDTOType | null>(null);

  const fetchAllocationKeys: voidType = () => {
    getAllocationKeys({}).then(
      ({ data = [] }: ResponseType<AllocationKeyType[]>) => {
        setAllocationKeys(data);
      }
    );
  };

  const submitEditRequest: submitEditRequestType = (values, transaction) => {
    message.loading({
      content: "Editing Transaction...",
      key: "editTransaction",
      duration: 0,
    });
    const data: NewOrEditTransactionValueType = {
      ...transaction,
      ...values,
      targetDate: values.targetDate
        ? new Date(values.targetDate).getTime().toString()
        : null,
      creationDate: new Date(values.creationDate).getTime().toString(),
      industryDTOSet: getOptionsByKey(values.industryDTOSet, industries, "id"),
      peTransactionTypeName:
        values.peTransactionTypeId &&
        prop(
          "keyName",
          getObjectFromPropertyValue(
            "id",
            values.peTransactionTypeId.toString(),
            allocationKeys
          )
        ),
      investmentTypeName:
        values.investmentTypeId &&
        prop(
          "keyName",
          getObjectFromPropertyValue(
            "id",
            values.investmentTypeId.toString(),
            allocationKeys
          )
        ),
      ...(thirdPartyEntity
        ? {
            thirdPartyIntegrationType: ThirdPartyIntegrationType.DEAL_CLOUD,
            thirdPartyId: thirdPartyEntity?.EntryId,
          }
        : {}),
    };
    editTransaction({
      body: JSON.stringify(data),
    })
      .then(() => {
        history.push(TRANSACTIONS_PAGE_URL);
        message.success({
          content: "Transaction Details Updated",
          key: "editTransaction",
          duration: 2,
        });
      })
      .catch((error: string) => {
        message.error({
          content: error ? error : "Error making changes to the Transaction!",
          key: "editTransaction",
          duration: 3,
        });
      });
  };

  const fetchIndustries: voidType = () => {
    getIndustries({}).then(({ data = [] }: ResponseType<IndustryType[]>) => {
      setIndustries(data);
    });
  };

  const fetchTransaction: fetchWithIdType = (id: string) => {
    getPETransaction({
      segments: {
        id,
      },
    })
      .then(({ data }: ResponseType<TransactionType>) => {
        setTransaction(data);
      })
      .catch((error: string) => {
        message.error(error ? error : "Error fetching transaction details!");
      });
  };

  useEffect(() => {
    fetchIndustries();
    fetchAllocationKeys();
  }, []);

  useEffect(() => {
    editTransactionId && fetchTransaction(editTransactionId);
  }, [editTransactionId]);

  useEffect(() => {
    transaction &&
      form.setFieldsValue({
        ...transaction,
        targetDate: transaction.targetDate
          ? moment(transaction.targetDate.toString(), "x")
          : null,
        creationDate: moment(transaction?.creationDate?.toString(), "x"),
        industryDTOSet: populateArrayWithPropertyPath(
          ["id"],
          transaction?.industryDTOSet
        ),
        ...(thirdPartyEntity
          ? {
              thirdPartyIntegrationType: ThirdPartyIntegrationType.DEAL_CLOUD,
              thirdPartyId: thirdPartyEntity?.EntryId,
            }
          : {}),
      });
  }, [transaction]);

  return (
    <div className={"h-screen p-6 flex flex-col"}>
      <div className={"mb-5 flex flex-row items-center"}>
        <span className={"text-2xl font-medium"}>Edit Transaction</span>
      </div>
      {isOffline(transaction) && (
        <div
          className={
            "flex items-center w-full text-xxs p-1 tracking-widest text-white font-bold text-white cursor-default select-none bg-amber-500 justify-center"
          }
        >
          <div>OFFLINE TRANSACTION</div>
        </div>
      )}
      <div className={"bg-white overflow-y-auto hide-scrollbar"}>
        <div
          className={
            "p-6 flex flex-col h-full container mx-auto max-w-screen-lg"
          }
        >
          <img src={TransactionIcon} className={"h-32 w-32 mx-auto"} alt={""} />
          <p
            className={
              "text-gray-400 font-light mx-auto w-min whitespace-nowrap"
            }
          >
            Edit the name, description and other details of your transaction
          </p>
          <div className={"border-t "} />
          <Form
            layout={"vertical"}
            onFinish={(values): void => {
              submitEditRequest(values, transaction);
            }}
            form={form}
            className={"flex flex-col gap-y-3 h-full pb-3"}
            onKeyDown={(event): void => {
              if (event.code === "Enter" || event.code === "NumpadEnter")
                form.submit();
            }}
          >
            {valOrDefault(false, companyModulePreference?.dealCloud) && (
              <Form.Item
                className={"m-0 my-2 border-0"}
                name={"thirdPartyId"}
                label={statusColumnHeader(
                  "Deal Cloud",
                  isOffline(transaction) ? "" : "Private"
                )}
              >
                <DealConnect
                  value={thirdPartyEntity?.EntryId}
                  onSelect={(entry) => {
                    setThirdPartyEntity(entry);
                    form.setFieldValue("thirdPartyId", entry?.EntryId);
                  }}
                  onClear={() => {
                    setThirdPartyEntity(null);
                    form.resetFields(["thirdPartyId"]);
                  }}
                />
              </Form.Item>
            )}
            <Form.Item
              className={"m-0 my-2 border-0"}
              label={"Name"}
              name={"peTransactionTitle"}
              rules={[formItemRequiredRule]}
            >
              <Input
                placeholder={"Transaction Title - e.g. 'Project Buyout'"}
                autoFocus={true}
              />
            </Form.Item>
            <Form.Item
              className={"m-0 my-2 border-0"}
              label={"Description"}
              name={"description"}
            >
              <TextArea
                placeholder={
                  "Deal Description - e.g. 'New financing for buyout of a leading services provider'"
                }
                autoSize={{ minRows: 3 }}
                maxLength={249}
              />
            </Form.Item>
            <Form.Item
              className={"m-0 my-2 border-0"}
              name={"industryDTOSet"}
              label={statusColumnHeader(
                "Select Industries",
                isOffline(transaction) ? "" : "Private to Deal Team"
              )}
            >
              <Select
                notFoundContent={"No Industries Available"}
                getPopupContainer={(trigger): HTMLElement =>
                  trigger.parentElement
                }
                mode={"multiple"}
                placeholder={"Select Industries"}
                maxTagCount={"responsive"}
                showArrow={true}
                dropdownRender={(menu): ReactElement => {
                  return <div className={"p-0 m-0"}>{menu}</div>;
                }}
                showSearch={true}
                filterOption={filterOption}
              >
                {industries.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {or(
              isOffline(transaction),
              equals(user?.companyId, transaction?.companyId)
            ) && (
              <Form.Item
                className={"m-0 my-2 border-0"}
                name={"peTransactionTypeId"}
                label={statusColumnHeader(
                  "Transaction Type",
                  isOffline(transaction) ? "" : "Private"
                )}
              >
                <Select
                  notFoundContent={"No Transactions Types Available"}
                  getPopupContainer={(trigger): HTMLElement =>
                    trigger.parentElement
                  }
                  placeholder={"Transaction Type"}
                  showArrow={true}
                  dropdownRender={(menu): ReactElement => {
                    return <div className={"p-0 m-0"}>{menu}</div>;
                  }}
                >
                  {allocationKeys
                    .filter(
                      ({ allocationTableType }) =>
                        allocationTableType === AllocationTableType.TRANSACTION
                    )
                    .map(({ id, keyName }) => (
                      <Select.Option key={id} value={id}>
                        {keyName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}

            {or(
              isOffline(transaction),
              equals(user?.companyId, transaction?.companyId)
            ) &&
              user?.companyDTO?.companyType !==
                CompanyEnumType.DEBT_ADVISOR_COMPANY && (
                <Form.Item
                  className={"m-0 my-2 border-0"}
                  name={"investmentTypeId"}
                  label={statusColumnHeader(
                    "Investment Type",
                    isOffline(transaction) ? "" : "Private"
                  )}
                >
                  <Select
                    notFoundContent={"No Investment Types Available"}
                    getPopupContainer={(trigger): HTMLElement =>
                      trigger.parentElement
                    }
                    placeholder={"Investment Type"}
                    showArrow={true}
                    dropdownRender={(menu): ReactElement => {
                      return <div className={"p-0 m-0"}>{menu}</div>;
                    }}
                  >
                    {allocationKeys
                      .filter(
                        ({ allocationTableType }) =>
                          allocationTableType === AllocationTableType.INVESTMENT
                      )
                      .map(({ id, keyName }) => (
                        <Select.Option key={id} value={id}>
                          {keyName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}

            <Form.Item
              className={"m-0 my-2 border-0"}
              label={<>Target Company</>}
              help={
                thirdPartyEntity && (
                  <div className={"mt-1 mb-4"}>
                    <span className={"text-black"}>DealCloud Company: </span>
                    {thirdPartyEntity?.Company?.name}
                  </div>
                )
              }
              name={"companyToBuy"}
            >
              <Input placeholder={"Target Company - e.g. 'Termgrid Capital'"} />
            </Form.Item>
            <Form.Item
              className={"m-0 my-2 border-0"}
              name={"targetDate"}
              label={
                isOffline(transaction)
                  ? "Signing Date"
                  : "Expected Signing Date"
              }
            >
              <DatePicker
                className={`w-full`}
                format={"MM/DD/YYYY"}
                placeholder={
                  isOffline(transaction)
                    ? "Signing Date"
                    : "Expected Signing Date"
                }
              />
            </Form.Item>
            <Form.Item
              className={"m-0 my-2 border-0"}
              name={"creationDate"}
              label={statusColumnHeader(
                "Creation Date",
                isOffline(transaction) ? "" : "Private to Deal Team"
              )}
              rules={[formItemRequiredRule]}
            >
              <DatePicker
                className={`w-full`}
                format={"MM/DD/YYYY"}
                placeholder={"Creation Date"}
              />
            </Form.Item>
            <Button
              className={
                "mt-auto bg-primary hover:bg-hover text-white border-0"
              }
              onClick={(): void => {
                form.submit();
              }}
            >
              Update
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

type EditTransactionValueType = NewOrEditTransactionValueType;
type submitEditRequestType = (
  values: EditTransactionValueType,
  transaction: TransactionType | null
) => void;
type ParamsType = {
  editTransactionId: string;
};
