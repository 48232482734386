import React, { FC, useContext, useEffect, useState } from "react";
import { PortfolioOverview } from "./pages/PortfolioOverview";
import { PortfolioKeyTerms } from "./pages/PortfolioKeyTerms";
import { PortfolioDataRoom } from "./pages/PortfolioDataRoom";
import {
  fetchWithIdType,
  MenuRouteType,
  ResponseType,
} from "../../../utils/uiTypes";
import { WithTopNav } from "../../layout/WithTopNav";
import { useHistory, useParams } from "react-router";
import {
  getModulePreferenceByCompany,
  getPortfolioData,
} from "../../../services/services";
import {
  CompanyModuleAccessType,
  CompanyModuleType,
  PortfolioSectionType,
  PortfolioType,
} from "../../../utils/types";
import { message } from "antd";
import { ElementType, PermissionType } from "../../../utils/enums";
import { UserContext } from "../../../context/UserContext";

const MENU_OPTIONS = (
  portfolio: PortfolioType,
  preference: CompanyModuleType,
  onChangeSectionList: OnChangeSectionList,
  permissions: PermissionType[],
  onChangeSection: (sectionId: string) => void
): MenuRouteType[] => [
  {
    title: "Overview",
    key: "overview",
    exact: true,
    permission: true,
    Component: PortfolioOverview,
    props: {
      companyTitle: portfolio.portfolioCompanyName,
      sectionList: portfolio.portfolioSectionDTOS,
      companyId: portfolio.companyId,
      portfolioId: portfolio.id,
      onChangeSectionList: onChangeSectionList,
      permissions,
      onChangeSection,
      allowCreateNewSection: true,
    },
    path: [
      "/portfolio/:portfolioId/overview",
      "/portfolio/:portfolioId/overview/:sectionId",
    ],
  },
  {
    title: "Key Terms",
    key: "terms",
    exact: false,
    permission: preference.termsheet,
    Component: PortfolioKeyTerms,
    path: [
      "/portfolio/:portfolioId/terms",
      "/portfolio/:portfolioId/terms/:sectionId",
    ],
  },
  {
    title: "Documents",
    key: "documents",
    exact: false,
    permission: preference.dataroom,
    Component: PortfolioDataRoom,
    path: ["/portfolio/:portfolioId/documents"],
  },
];

export const PortfolioView: FC = () => {
  const { portfolioId, portfolioMenu }: ParamsType = useParams();
  const history = useHistory();

  const { user } = useContext(UserContext);
  const [portfolio, setPortfolio] = useState<PortfolioType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [companyModulePreference, setCompanyModulePreference] =
    useState<CompanyModuleType | null>(null);

  const fetchCompanyModules: fetchWithIdType = (id: string) => {
    getModulePreferenceByCompany({
      segments: {
        id,
      },
    }).then(({ data }: ResponseType<CompanyModuleAccessType>) => {
      setCompanyModulePreference(data.moduleAccessDTO);
    });
  };

  const fetchPortfolio = (portfolioId: string): void => {
    if (Number(portfolioId)) {
      setLoading(true);
      getPortfolioData({
        segments: {
          portfolioId,
        },
      })
        .then(({ data }: ResponseType<PortfolioType>) => {
          setPortfolio(data);
          fetchCompanyModules(data.companyId);
          setLoading(false);
        })
        .catch(() => {
          message.error("Error Fetching Portfolio Data!", 5);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchPortfolio(portfolioId);
  }, [portfolioId]);

  return (
    <>
      {portfolio && companyModulePreference && (
        <WithTopNav
          height={"full"}
          loading={loading}
          defaultValue={"overview"}
          menu={MENU_OPTIONS(
            portfolio,
            companyModulePreference,
            (sections) =>
              setPortfolio({ ...portfolio, portfolioSectionDTOS: sections }),
            user?.elementPermissions?.find(
              (item) => item?.elementType === ElementType.PORTFOLIO
            )?.permissions ?? [],
            (sectionId) =>
              sectionId
                ? history.push(
                    `/portfolio/${portfolioId}/overview/${sectionId}`
                  )
                : history.push(`/portfolio/${portfolioId}/overview`)
          )}
          selectedKeys={[portfolioMenu]}
          gotoRoute={({ key }): void => {
            history.push(`/portfolio/${portfolioId}/${key}`);
          }}
        />
      )}
    </>
  );
};

type ParamsType = {
  portfolioId: string;
  portfolioMenu: string;
};
type OnChangeSectionList = (sections: PortfolioSectionType[]) => void;
