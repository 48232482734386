import React, { FC, ReactNode, useEffect, useState } from "react";
import {
  CompanyTagType,
  CompanyType,
  KeyContactsType,
  PortfolioSectionType,
} from "../../../../utils/types";
import { PortfolioCard } from "../PortfolioCard";
import {
  createOrUpdateKeyContact,
  deleteKeyContact,
  getKeyContactTags,
} from "../../../../services/services";
import { ResponseType } from "../../../../utils/uiTypes";
import { Button, message, Table } from "antd";
import { useParams } from "react-router";
import { CreateContactModal } from "../../portfolioModals/CreateContactModal";
import { ConfirmDelete } from "../../../../utils/confirmationModals";
import { initiateFetchSection } from "../../../../utils/portfolio";
import { PermissionType } from "../../../../utils/enums";
import { ColumnType } from "antd/es/table/interface";
import { ACTION_BUTTON_CSS } from "../../../../utils/cssConfigs";
import { ColumnsType } from "antd/es/table";
import { removeItemFromArray } from "../../../../utils/utils";
import { PortfolioAdminPermissionBlock } from "../../PortfolioAdminPermissionBlock";
import { usePageTitle } from "../../../../customHooks/usePageTitle";

const colData: ColumnsType<KeyContactsType> = [
  {
    title: "Company",
    dataIndex: "companyName",
    className: "group-hover:bg-blue-50",
    sorter: (a, b): number => a.companyName.localeCompare(b.companyName),
  },
  {
    title: "Name",
    dataIndex: "name",
    className: "group-hover:bg-blue-50",
    sorter: (a, b): number => a.name.localeCompare(b.name),
  },
  {
    title: "Title",
    dataIndex: "title",
    className: "group-hover:bg-blue-50",
    sorter: (a, b): number => a.title.localeCompare(b.title),
  },
  {
    title: "Role",
    dataIndex: "tagId",
    className: "group-hover:bg-blue-50",
    sorter: (a, b): number => a.tagId.localeCompare(b.tagId),
  },
  {
    title: "Email",
    dataIndex: "emailId",
    className: "group-hover:bg-blue-50",
    sorter: (a, b): number => a.emailId.localeCompare(b.emailId),
  },
];

export const KeyContacts: FC<KeyContactsCompType> = ({
  companies,
  permissions,
  portfolioId,
}) => {
  usePageTitle("Key Contacts");

  const { sectionId }: ParamsType = useParams();

  const [section, setSection] = useState<PortfolioSectionType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [addContactModal, setAddContactModal] = useState<boolean>(false);
  const [keyContacts, setKeyContacts] = useState<KeyContactsType[]>([]);
  const [companyTags, setCompanyTags] = useState<CompanyTagType[]>([]);

  const deleteCol: ColumnType<KeyContactsType> = {
    title: <div className="p-4 whitespace-nowrap" />,
    dataIndex: "delete",
    key: "delete",
    align: "right" as const,
    width: 50,
    className: "p-0 whitespace-nowrap  group-hover:bg-blue-50",
    render: function actions(_: string, record, index): ReactNode {
      return (
        <span
          className={
            "relative flex flex-row flex-row-reverse items-center gap-x-3 pr-2"
          }
          onClick={(event): void => event.stopPropagation()}
        >
          <Button
            type={"text"}
            icon={<i className="fas fa-ellipsis-h" />}
            className={
              "absolute right-0 border-0 flex items-center px-2 text-gray-900 block opacity-50 group-hover:opacity-0 mr-2"
            }
          />
          <Button
            type={"text"}
            icon={<i className="fas fa-trash-alt" />}
            className={`${ACTION_BUTTON_CSS}  hover:text-primary opacity-0 group-hover:opacity-100`}
            onClick={(): void => {
              ConfirmDelete(
                "Are you sure you want to remove this row? This will erase all previously saved data.",
                () => deleteContact(record.id, index),
                <></>
              );
            }}
          />
        </span>
      );
    },
  };

  const fetchSection = (sectionId: string, portfolioId: string): void => {
    setLoading(true);
    initiateFetchSection(sectionId, portfolioId)
      .then((section) => {
        setSection(section);
        setCompanyTagData(section.portfolioKeyContactDTOList);
      })
      .catch((e: string) => e && message.error(e))
      .then(() => setLoading(false));
  };

  const deleteContact = (contactId: string, index: number): void => {
    deleteKeyContact({
      segments: {
        portfolioId,
        contactId,
      },
    })
      .then((): void => {
        setKeyContacts((list) => removeItemFromArray(index, list ?? []));
        message.success("Contact removed successfully.");
      })
      .catch((error: string) => {
        console.error(error);
        message.error("Something went wrong. Please try again");
      });
  };

  const addContact = (contactData: SendContactType): Promise<void> => {
    return new Promise((resolve, reject) => {
      createOrUpdateKeyContact({
        body: JSON.stringify({ ...contactData, portfolioId }),
      })
        .then(({ data }: ResponseType<KeyContactsType>) => {
          setKeyContacts((list) => [
            ...list,
            {
              ...data,
              tagId:
                companyTags?.find((tag) => tag.id === data.tagId)?.label || "",
            },
          ]);
          message.success("Contact added successfully.");
          resolve();
          setAddContactModal(false);
        })
        .catch((err: string): void => {
          console.error(err);
          message.error("Unable to add contact", 3);
          reject();
        });
    });
  };

  const setKeyContactsData = (
    contacts: KeyContactsType[],
    tags: CompanyTagType[]
  ): void => {
    setKeyContacts(
      contacts?.map((contact) => ({
        ...contact,
        tagId: tags?.find((tag) => tag?.id === contact?.tagId)?.label || "",
      })) ?? []
    );
  };

  const setCompanyTagData = (contacts: KeyContactsType[]): void => {
    getKeyContactTags({})
      .then(({ data = [] }: ResponseType<CompanyTagType[]>) => {
        setCompanyTags(data);
        setKeyContactsData(contacts ?? [], data);
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Something went wrong, tag data not retrieved.", 3);
      });
  };

  useEffect(() => {
    fetchSection(sectionId, portfolioId);
  }, [sectionId, portfolioId]);

  return (
    <>
      <PortfolioCard
        permissions={permissions}
        key={section?.id}
        title={section?.sectionName ?? "Key Contacts"}
        bodyStyle={{ padding: "1px 5px 0 5px" }}
        loading={loading}
      >
        <Table
          bordered={true}
          showSorterTooltip={false}
          columns={[
            ...colData,
            ...(permissions.includes(PermissionType.ADMIN_PORTFOLIO)
              ? [deleteCol]
              : []),
          ]}
          pagination={false}
          rowClassName={"group"}
          rowKey={(record): string => record.id}
          dataSource={keyContacts}
          scroll={{ x: "max-content" }}
        />
        <PortfolioAdminPermissionBlock permissions={permissions}>
          <div className={`text-left pt-3 px-5 text-blue-400`}>
            <span
              className={"cursor-pointer hover:underline"}
              onClick={(): void => setAddContactModal(true)}
            >
              + New Contact
            </span>
          </div>
        </PortfolioAdminPermissionBlock>
        <div className={"w-full text-right p-1 text-muted"}>
          {keyContacts?.length} contact(s)
        </div>
      </PortfolioCard>

      <CreateContactModal
        setVisible={setAddContactModal}
        visible={addContactModal}
        companies={companies}
        companyTags={companyTags}
        onSuccess={addContact}
      />
    </>
  );
};

type KeyContactsCompType = {
  companies: CompanyType[];
  permissions: PermissionType[];
  portfolioId: string;
};
type ParamsType = {
  portfolioId: string;
  sectionId: string;
};
export type SendContactType = {
  companyName: string;
  emailId: string;
  name: string;
  portfolioId: string;
  tagId: string;
  title: string;
};
