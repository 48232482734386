import React, { FC, ReactElement, ReactNode, useState } from "react";
import {
  AllocationTableType,
  CurrencySymbolType,
  CurrencyType,
  PortfolioSectionEnumType,
  UnitTitleType,
  UnitType,
} from "../../../../utils/enums";
import { AllocationKeyType } from "../../../../utils/types";
import moment, { Moment } from "moment/moment";
import {
  Button,
  DatePicker,
  message,
  Popover,
  Segmented,
  Select,
  Space,
  Spin,
  Tag,
} from "antd";
import { customDateFormat, ToolbarData } from "../../../../utils/allocation";
import {
  CalendarOutlined,
  LoadingOutlined,
  DisconnectOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { getPopupContainerForSelect } from "../../../../utils/container";
import {
  ACTION_BUTTON_CSS,
  PRIMARY_BUTTON_STYLE,
} from "../../../../utils/cssConfigs";
import { AllocationColumns } from "./AllocationColumns";
import { useParams } from "react-router";
import { downloadAllocationFeesStructure } from "../../../../services/services";
import { ConfirmDownload } from "../../../../utils/confirmationModals";
import { FileResponseType } from "../../../../utils/uiTypes";
import { valOrDefault } from "../../../../utils/utils";

export const AllocationToolbar: FC<AllocationToolbarType> = ({
  sectionType,
  showClosingDateSection = true,
  config: { hasDate, showSave, allowSave, type },
  onSummary,
  onChange,
  loading = false,
  data: { title, unit, asOfDate, currency, selectedKeys = [] },
  keys,
  editPermission = false,
  extraOptions,
  fetchingSummary = false,
  showSummary = false,
  onSave,
  tableId,
  totalCount,
  peTransactionIdOrPortfolioId,
}) => {
  const [visible, setVisible] = useState(false);
  const { portfolioId, sectionId }: ParamsType = useParams();

  const dateElement = (date: Moment | null): ReactElement => {
    return editPermission ? (
      showClosingDateSection ? (
        <DatePicker
          suffixIcon={
            loading ? (
              <Spin
                size={"small"}
                indicator={<LoadingOutlined spin={true} />}
              />
            ) : (
              <CalendarOutlined />
            )
          }
          disabled={loading}
          getPopupContainer={getPopupContainerForSelect}
          value={date}
          allowClear={false}
          format={customDateFormat}
          onChange={onChange("allocationDate")}
          placeholder={"Select Closing Date"}
          className={"text-black w-60"}
        />
      ) : (
        <></>
      )
    ) : (
      <>
        {showClosingDateSection && (
          <Tag className={"px-3 py-1"}>
            {customDateFormat(date)}
            <CalendarOutlined className={"ml-2"} />
          </Tag>
        )}
      </>
    );
  };
  const onDownload = (): void => {
    message.loading({
      content: "Processing File",
      duration: 0,
      key: "download-" + name,
    });
    downloadAllocationFeesStructure({
      segments: {
        portfolioId: peTransactionIdOrPortfolioId,
        tableId,
        type:
          sectionType === PortfolioSectionEnumType.ALLOCATION
            ? "FINAL_HOLD"
            : "FEES",
      },
    })
      .then(({ url, filename }: FileResponseType) => {
        message.info({
          content: "File Ready to Download",
          key: "download-" + name,
        });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        message.error({
          content: valOrDefault("Error Downloading File!", error),
          key: "download-" + name,
        });
      });
  };
  return (
    <div className={"mb-5"}>
      <div className={"flex flex-row md:flex-col gap-2"}>
        {title && (
          <Title level={4} className="opacity-90 w-full mb-0 my-auto">
            {title}
          </Title>
        )}
      </div>
      <div className={"flex justify-between items-center mx-auto mt-3"}>
        <Space wrap className={`w-full md:w-fit`}>
          {hasDate && dateElement(asOfDate ? moment(asOfDate) : null)}
          {editPermission && showSave && (
            <>
              <Segmented
                disabled={loading}
                onChange={onChange("unit")}
                className={"select-none"}
                value={unit}
                options={Object.values(UnitType).map((value) => ({
                  label: UnitTitleType[value],
                  value,
                }))}
              />
              <Select
                disabled={loading}
                getPopupContainer={getPopupContainerForSelect}
                loading={loading}
                onChange={onChange("currency")}
                className={"select-none"}
                value={currency}
                options={Object.values(CurrencyType).map((value) => ({
                  label: CurrencySymbolType[value],
                  value,
                }))}
              />
            </>
          )}
          {/*(sectionType === PortfolioSectionEnumType.ALLOCATION ||
            sectionType === PortfolioSectionEnumType.FEES) &&
            showSummary && (
              <Button
                onClick={onSummary}
                loading={fetchingSummary}
                icon={fetchingSummary ? <Spin /> : null}
              >
                Show Summary
              </Button>
            )*/}
        </Space>
        <Space
          wrap
          className={` ${!editPermission && "hidden"} w-full md:w-fit`}
        >
          {editPermission && (
            <Popover
              open={visible}
              placement="bottomRight"
              destroyTooltipOnHide={true}
              content={
                <AllocationColumns
                  type={type}
                  keys={keys}
                  onChange={onChange("keys")}
                  onClose={(): void => setVisible(false)}
                  value={selectedKeys}
                />
              }
              trigger="click"
              className={"bg-white"}
              getPopupContainer={getPopupContainerForSelect}
              onOpenChange={setVisible}
            >
              <Button
                className={ACTION_BUTTON_CSS}
                loading={loading}
                onClick={(): void => setVisible(!visible)}
              >
                {sectionType === PortfolioSectionEnumType.ALLOCATION
                  ? "Select Facility Types"
                  : sectionType === PortfolioSectionEnumType.FEES
                  ? "Select Fee Types"
                  : "Select Facilities"}
              </Button>
            </Popover>
          )}

          <Button
            title={"Save"}
            disabled={!allowSave}
            onClick={onSave}
            className={PRIMARY_BUTTON_STYLE}
          >
            Save
          </Button>
          {sectionId && (
            <Button
              type={"text"}
              icon={<DownloadOutlined />}
              onClick={onDownload}
              className={`${ACTION_BUTTON_CSS}`}
              disabled={!totalCount}
            />
          )}

          {extraOptions}
        </Space>
      </div>
    </div>
  );
};

type ToolbarConfig = {
  hasDate?: boolean;
  allowSave?: boolean;
  showSave?: boolean;
  type?: AllocationTableType;
};

type AllocationToolbarType = {
  showSummary?: boolean;
  sectionType: PortfolioSectionEnumType;
  showClosingDateSection: boolean;
  keys: AllocationKeyType[];
  data: ToolbarData;
  config: ToolbarConfig;
  onChange: (action: string) => (data: any) => void;
  onSummary: () => void;
  editPermission?: boolean;
  extraOptions?: ReactNode | ReactNode[];
  loading?: boolean;
  fetchingSummary?: boolean;
  onSave: VoidFunction;
  tableId: string;
  totalCount?: number;
  peTransactionIdOrPortfolioId: string;
};
type ParamsType = {
  portfolioId: string;
  sectionId: string;
};
