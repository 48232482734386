import React, { FC, ReactNode, useState } from "react";
import { DealCloudCompanyDTOType } from "../../utils/types";
import { Button, Modal, Select, Tooltip } from "antd";
import {
  ACTION_BUTTON_CSS,
  PRIMARY_BUTTON_STYLE,
} from "../../utils/cssConfigs";

export const RelationshipDealCloudIntegration: FC<
  CRMDealCloudIntegrationType
> = ({ onSubmit, disabled, integrations, children }) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setsSelectedValue] =
    useState<DealCloudCompanyDTOType | null>(null);

  return (
    <>
      <Tooltip
        title={children ? "Click to Change" : "DealCloud Integration"}
        placement="topLeft"
      >
        <div onClick={() => setOpen(!open)} className={"cursor-pointer"}>
          {children ?? (
            <Button
              className={`${ACTION_BUTTON_CSS} hover:text-primary opacity-0 group-hover:opacity-100`}
              icon={<i className="fa-solid fa-plug"></i>}
            />
          )}
        </div>
      </Tooltip>
      {open && (
        <Modal
          closable={true}
          open={open}
          onCancel={(): void => setOpen(false)}
          title={"Connect DealCloud Institution to TermGrid Institution"}
          width={"50%"}
          destroyOnClose={true}
          footer={[
            <Button
              key="submit"
              onClick={() => {
                if (selectedValue) {
                  onSubmit(
                    selectedValue?.EntryId,
                    selectedValue?.CompanyName?.name
                  );
                }
                setOpen(false);
              }}
              className={PRIMARY_BUTTON_STYLE}
              disabled={!selectedValue}
            >
              Connect
            </Button>,
          ]}
        >
          <Select
            className={"text-left w-full"}
            showSearch={true}
            optionFilterProp={"label"}
            notFoundContent={disabled ? "Loading..." : "No Match Found"}
            onSelect={(value) => {
              setsSelectedValue(JSON.parse(value));
            }}
            filterOption={true}
            placeholder={"Search DealCloud Institution"}
            disabled={disabled}
          >
            {integrations.map((company) => (
              <Select.Option
                key={company.EntryId}
                value={JSON.stringify(company)}
                label={company?.CompanyName?.name}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: company?.CompanyName?.name,
                  }}
                />
              </Select.Option>
            ))}
          </Select>
        </Modal>
      )}
    </>
  );
};

type CRMDealCloudIntegrationType = {
  onSubmit: (entryId: string, thirdPartyCompanyName: string) => void;
  integrations: DealCloudCompanyDTOType[];
  disabled?: boolean;
  children?: ReactNode;
};
