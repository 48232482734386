import React, { FC } from "react";
import { Prompt } from "react-router";
import { useConfirmReload } from "../../customHooks/useConfirmReload";

export const PromptDialog: FC<PromptDialogProperties> = ({ block }) => {
  useConfirmReload(block, [block]);
  return (
    <Prompt
      when={block}
      message="Are you sure you want to leave this page? Proceeding with this will lose all the data entered"
    />
  );
};

type PromptDialogProperties = {
  block: boolean;
};
