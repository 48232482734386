import React, { useContext, useEffect, useState } from "react";
import { usePageTitle } from "../../../../customHooks/usePageTitle";
import { TransactionContext } from "../../../../context/TransactionContext";
import { doNothing, isEmptyOrNil } from "../../../../utils/utils";
import {
  createPortfolio,
  getPortfolioData,
} from "../../../../services/services";
import { PortfolioOverview } from "../../../portfolio/portfolioView/pages/PortfolioOverview";
import { getLongFormTemplateSectionList } from "./sections";
import { useHistory } from "react-router";
import { ResponseType } from "../../../../utils/uiTypes";
import { PortfolioType } from "../../../../utils/types";
import { UserContext } from "../../../../context/UserContext";
import { ElementType, PermissionType } from "../../../../utils/enums";
import { getPortfolioType } from "../../../../utils/portfolio";
import { Alert, Button, message, Result } from "antd";
import { CustomSpin } from "../../../general/CustomSpin";
import { PRIMARY_BUTTON_STYLE } from "../../../../utils/cssConfigs";
import { checkElementViewPermission } from "../../../../utils/permissions";

enum TemplateState {
  LOADING,
  CREATING,
  CREATE,
  ERROR,
  VIEW,
  NO_VIEW_PERMISSION,
  NO_PORTFOLIO_PERMISSION,
}

export const LongFormTemplate = () => {
  const history = useHistory();

  const { transaction, setTransaction, permissions, belongToDealTeam } =
    useContext(TransactionContext);
  const { user } = useContext(UserContext);
  const hasPortfolio = !isEmptyOrNil(transaction?.portfolioId);
  usePageTitle(hasPortfolio ? "Long Form Template" : "Completion memo");

  const [templateState, setTemplateState] = useState<TemplateState>(
    hasPortfolio ? TemplateState.LOADING : TemplateState.CREATE
  );
  const [portfolio, setPortfolio] = useState<PortfolioType>();

  const canEdit =
    permissions.includes(PermissionType.ADMIN_PETRANSACTION) ||
    permissions.includes(PermissionType.ADMIN_LENDER);

  const onCreateTemplate = (): void => {
    setTemplateState(TemplateState.CREATING);
    createPortfolio({
      body: JSON.stringify({
        companyId: user?.companyId,
        portfolioCompanyName:
          transaction?.companyToBuy || transaction?.peTransactionTitle,
        portfolioType: getPortfolioType(user?.companyDTO?.companyType),
        transactionId: transaction?.peTransactionId,
      }),
    })
      .then(({ data }: ResponseType<PortfolioType>) => {
        if (transaction)
          setTransaction(() => ({ ...transaction, portfolioId: data.id }));
        fetchTemplate(data.id);
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Error creating portfolio.", 5);
        setTemplateState(TemplateState.ERROR);
      });
  };

  const fetchTemplate = (portfolioId: string) => {
    setTemplateState(TemplateState.LOADING);
    getPortfolioData({
      segments: {
        portfolioId,
      },
      params: {
        isLongFormTemplate: true,
      },
    })
      .then(({ data }: ResponseType<PortfolioType>) => {
        setPortfolio({
          ...data,
          portfolioSectionDTOS: getLongFormTemplateSectionList(
            data?.portfolioSectionDTOS ?? []
          ),
        });
        setTemplateState(TemplateState.VIEW);
      })
      .catch((e: string) => {
        console.error(e);
        setTemplateState(TemplateState.ERROR);
      });
  };

  useEffect(() => {
    if (
      !checkElementViewPermission(
        ElementType.PORTFOLIO,
        user?.elementPermissions ?? [],
        [PermissionType.VIEW_PORTFOLIO],
        true
      )
    ) {
      setTemplateState(TemplateState.NO_VIEW_PERMISSION);
    } else if (transaction?.portfolioId) {
      fetchTemplate(transaction?.portfolioId);
    }
  }, [transaction?.portfolioId]);

  return (
    <div
      className={
        "relative max-h-full w-full h-screen bg-gray-100 flex flex-col"
      }
    >
      <Alert
        className={"py-1.5 text-xs"}
        type={"warning"}
        showIcon={true}
        banner={true}
        message="The data entered here is private to your institution and will help in your data analytics and relationship management."
      />
      {templateState === TemplateState.VIEW ? (
        <>
          <PortfolioOverview
            allowCreateNewSection={false}
            permissions={
              user?.elementPermissions?.find(
                (item) => item?.elementType === ElementType.PORTFOLIO
              )?.permissions ?? []
            }
            portfolioId={transaction?.portfolioId}
            sectionList={portfolio?.portfolioSectionDTOS ?? []}
            companyId={transaction?.companyId ?? ""}
            onChangeSectionList={doNothing}
            onChangeSection={(sectionId) =>
              history.push(
                sectionId
                  ? `/transactions/${transaction?.peTransactionId}/completions/${sectionId}`
                  : `/transactions/${transaction?.peTransactionId}/completions`
              )
            }
          />
        </>
      ) : templateState === TemplateState.LOADING ? (
        <CustomSpin loadingText={"Loading Portfolio"} />
      ) : templateState === TemplateState.CREATE ? (
        <>
          <Alert
            className={"py-1.5 text-xs"}
            type={"info"}
            showIcon={true}
            banner={true}
            message={
              <>
                <span>
                  Please reach out to the TermGrid support team if you have any
                  question at
                </span>
                &nbsp;
                <a
                  href={"mailto:support@termgrid.com"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  support@termgrid.com
                </a>
              </>
            }
          />
          <div className={"my-auto"}>
            <Result
              title="To use completions within the transaction, we would need to create a portfolio company to connect with the transaction."
              extra={
                canEdit &&
                checkElementViewPermission(
                  ElementType.PORTFOLIO,
                  user?.elementPermissions ?? [],
                  [PermissionType.ADMIN_PORTFOLIO],
                  true
                ) ? (
                  <Button
                    className={PRIMARY_BUTTON_STYLE}
                    onClick={() => onCreateTemplate()}
                    disabled={!canEdit}
                    type="primary"
                    key="console"
                  >
                    Create Portfolio
                  </Button>
                ) : belongToDealTeam ? (
                  "Please reach out to the Deal/Company Admin to connect the transaction with portfolio"
                ) : (
                  "Please reach out to the Company/Portfolio Admin to connect the transaction with portfolio"
                )
              }
            />
          </div>
        </>
      ) : templateState === TemplateState.CREATING ? (
        <CustomSpin loadingText={"Creating Portfolio"} />
      ) : templateState === TemplateState.NO_VIEW_PERMISSION ? (
        <Result title="You dont have portfolio view access. Please reach out to your company admin to get access." />
      ) : (
        <Result title="Unable to create a portfolio" status={"error"} />
      )}
    </div>
  );
};
