import React, { FC, useContext, useEffect, useState } from "react";
import { TransactionContext } from "../../../../context/TransactionContext";
import { fetchWithIdType, ResponseType } from "../../../../utils/uiTypes";
import { getNotes } from "../../../../services/services";
import { Alert, message } from "antd";
import { NotesType } from "../../../../utils/types";
import { pathOr } from "ramda";
import { NotesThread } from "./NotesThread";

export const LenderNotes: FC = () => {
  const { transactionId, transaction } = useContext(TransactionContext);

  const [notes, setNotes] = useState<NotesType | null>(null);

  const fetchNotes: fetchWithIdType = (id: string) => {
    getNotes({ segments: { id } })
      .then(({ data = [] }: ResponseType<NotesType[]>) => {
        setNotes(pathOr(null, [0], data));
      })
      .catch(message.error);
  };

  useEffect(() => {
    if (transactionId) fetchNotes(transactionId);
  }, [transactionId]);

  return (
    <>
      <Alert
        className={"py-1.5 text-xs"}
        type={"warning"}
        showIcon={true}
        banner
        message={"The notes are private within your organization."}
      />
      {transactionId && (
        <NotesThread
          notes={notes}
          activeLender={0}
          transactionId={transactionId}
          readOnly={false}
          setNotes={setNotes}
          isDealTeam={false}
        />
      )}
    </>
  );
};
