import React, { FC, ReactElement, useEffect, useState } from "react";
import { LenderType, MileStoneType } from "../../../../utils/types";
import { MenuOutlined } from "@ant-design/icons";
import {
  deleteMilestone,
  getMilestones,
  newMilestone,
  updateMilestonesTemplate,
} from "../../../../services/services";
import { DraggableElement } from "../../../general/DraggableElement";
import { Button, message, Modal } from "antd";
import {
  ResponseType,
  TrackersTypes,
  voidType,
} from "../../../../utils/uiTypes";
import { NewTrackerForm } from "../../../forms/NewTrackerForm";
import { PermissionType } from "../../../../utils/enums";
import { ConfirmDelete } from "../../../../utils/confirmationModals";
import { CloneTrackers } from "./CloneTrackers";
import { PRIMARY_BUTTON_STYLE } from "../../../../utils/cssConfigs";

import { ConnectDragSource } from "react-dnd";
import { isEmpty } from "ramda";

const R = require("ramda");

const RowHandler: FC<RowHandlerType> = ({ ref }): ReactElement => (
  <div
    ref={ref}
    style={{
      marginRight: "10px",
      padding: "0 5px",
      cursor: "grab",
    }}
  >
    <Button
      className={
        "border-0 flex items-center bg-gray-50 px-2 group-hover:bg-blue-500 group-hover:text-white cursor-grab"
      }
    >
      <MenuOutlined />
    </Button>
  </div>
);

export const HandleMilestones: FC<EditTrackersType> = function ({
  milestonesTemplateId,
  peTransactionId,
  permissions,
}: EditTrackersType) {
  const [details, setDetails] = useState<Record<string, string>>({});
  const [milestones, setMilestones] = useState<MileStoneType[]>([]);
  const [cloneModal, setCloneModal] = useState<boolean>(false);

  const onDrag: onDragType = (oldIndex, newIndex) => {
    if (permissions.includes(PermissionType.ADMIN_PETRANSACTION)) {
      setMilestones((milestones) => [
        ...R.move(oldIndex, newIndex, milestones).map(
          (val: MileStoneType, index: number) => ({
            ...val,
            rank: index.toString(),
          })
        ),
      ]);
    }
  };

  const newTracker: newTrackerType = (name) => {
    if (isEmpty(name?.trim())) {
      message.error("Invalid Tracker Name");
    } else {
      newMilestone({
        body: JSON.stringify({
          mileStoneTemplateId: milestonesTemplateId,
          name: name,
          rank: milestones ? milestones.length.toString() : "0",
        }),
      })
        .then(({ data }: ResponseType<MileStoneType>) => {
          setMilestones([...milestones, data]);
        })
        .catch((error: string) => {
          message.error(error ? error : "Error adding the Tracker!");
        });
    }
  };

  const deleteTracker: deleteTrackerType = (id, index) => {
    deleteMilestone({
      segments: {
        id,
        peTransactionId,
      },
    })
      .then(() => {
        const data = milestones;
        data?.splice(index, 1);
        data &&
          setMilestones([
            ...data.map((val: MileStoneType, index: number) => ({
              ...val,
              rank: index.toString(),
            })),
          ]);
      })
      .catch((error: string) => {
        message.error(error ? error : "Error deleting Tracker!");
      });
  };

  const updateMilestones: voidType = () => {
    if (permissions.includes(PermissionType.EDIT_MILESTONE)) {
      updateMilestonesTemplate({
        headers: {
          Accept: ["application/json", "text/plain", "*/*"],
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...details, milestoneDTOs: milestones }),
      })
        .then(({ data }: ResponseType<LenderType>) => {
          setMilestones(data.milestoneDTOs);
        })
        .catch((error: string) => {
          message.error(error ? error : "Error Updating Tracker!");
        });
    } else {
      message.error("Not Allowed");
    }
  };
  const fetchMilestones = (milestonesTemplateId: string): void => {
    getMilestones({
      segments: {
        id: milestonesTemplateId,
      },
    }).then(({ data }: ResponseType<CompanyMileStoneType>) => {
      setDetails({ id: data.id, name: data.name });
      setMilestones(
        data?.milestoneDTOs?.sort((a: MileStoneType, b: MileStoneType) =>
          Number(a.rank) > Number(b.rank) ? 1 : -1
        ) ?? []
      );
    });
  };
  useEffect(() => {
    fetchMilestones(milestonesTemplateId);
  }, [milestonesTemplateId]);

  return permissions?.includes(PermissionType.ADMIN_PETRANSACTION) ||
    permissions?.includes(PermissionType.NON_ADMIN_PETRANSACTION) ? (
    <div className={""}>
      {permissions.includes(PermissionType.ADMIN_PETRANSACTION) ? (
        <div className={"flex items-start w-full gap-2"}>
          <NewTrackerForm
            onSubmit={({ name }): void => {
              newTracker(name);
            }}
          />
          <Button
            className={PRIMARY_BUTTON_STYLE}
            onClick={(): void => setCloneModal(true)}
          >
            Clone Steps
          </Button>
        </div>
      ) : (
        milestones.length === 0 && (
          <p className={"text-gray-500 font-bold text-center text-lg"}>
            No Trackers Available
          </p>
        )
      )}
      {milestones && (
        <div className={"flex flex-col gap-y-1"}>
          {milestones.map(
            ({ rank, name, mileStoneId }: MileStoneType, index) => (
              <DraggableElement
                key={index}
                id={rank}
                index={index}
                onDragging={onDrag}
                onDrop={updateMilestones}
                canDrag={permissions.includes(
                  PermissionType.ADMIN_PETRANSACTION
                )}
                accept={TrackersTypes.MILESTONE}
                className={"border"}
              >
                {(ref, drag, drop): ReactElement => {
                  drag(drop(ref));
                  return (
                    <div className={"p-3 flex flex-row items-center"}>
                      <RowHandler ref={drag} />
                      {name.toUpperCase()}
                      {permissions.includes(
                        PermissionType.ADMIN_PETRANSACTION
                      ) && (
                        <div
                          className={
                            "relative flex items-center bg-transparent ml-auto"
                          }
                        >
                          <Button
                            type={"text"}
                            icon={<i className="fas fa-ellipsis-h" />}
                            className={
                              "absolute right-0 border-0 flex items-center px-2 text-gray-900 hidden md:block opacity-50 group-hover:opacity-0"
                            }
                          />
                          <Button
                            className={
                              "border-0 shadow flex items-center bg-white px-2 motion-safe:hover:font-medium text-gray-500 hidden md:block opacity-0 motion-safe:group-hover:opacity-100 hover:text-red-500"
                            }
                            disabled={
                              !permissions.includes(
                                PermissionType.ADMIN_PETRANSACTION
                              )
                            }
                            icon={<i className="fas fa-trash-alt" />}
                            onClick={(): void => {
                              ConfirmDelete(
                                "Confirm Delete Tracker?",
                                () => deleteTracker(mileStoneId, index),
                                `Tracker Name: ${name.toUpperCase()}`
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                }}
              </DraggableElement>
            )
          )}
        </div>
      )}
      <Modal
        open={cloneModal}
        title={"Clone Trackers"}
        onCancel={(): void => setCloneModal(false)}
        footer={false}
        destroyOnClose={true}
      >
        <CloneTrackers
          transactionId={peTransactionId ?? ""}
          milestonesTemplateId={milestonesTemplateId ?? ""}
          onClone={() => {
            fetchMilestones(milestonesTemplateId);
          }}
          onClose={(): void => setCloneModal(false)}
        />
      </Modal>
    </div>
  ) : (
    <p className={"text-red-500 font-bold text-center text-lg"}>
      You are not Allowed to view Trackers
    </p>
  );
};

type CompanyMileStoneType = {
  id: string;
  name: string;
  milestoneDTOs: MileStoneType[];
};
type EditTrackersType = {
  milestonesTemplateId: string;
  peTransactionId: string;
  permissions: Array<PermissionType>;
};
type RowHandlerType = { ref: ConnectDragSource };
type onDragType = (oldIndex: number, newIndex: number) => void;
type newTrackerType = (name: string) => void;
type deleteTrackerType = (id: string, index: number) => void;
