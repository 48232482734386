import React, { FC, ReactNode, useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tag,
} from "antd";
import { inputFieldTypes } from "../../../utils/portfolio";
import { FieldType, PortfolioSectionFieldType } from "../../../utils/types";
import { CaretDownOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { voidType } from "../../../utils/uiTypes";
import { useParams } from "react-router";
import countries from "../../../assets/countryList.json";
import { AnalyticsCurrencyType } from "../../../utils/enums";

export const InputFieldModal: FC<InputFieldModalPropType> = ({
  isVisible,
  onClose,
  onCreate,
  onUpdate,
  editField,
}: InputFieldModalPropType) => {
  const { portfolioId }: ParamsType = useParams();
  const [form] = Form.useForm();
  const [fieldOptions, setFieldOptions] = useState<Array<string>>([]);

  const addFieldOption = (option: string): void => {
    if (option && !fieldOptions.includes(option))
      setFieldOptions((list) => [...list, option]);
  };
  const confirmFieldData = (): void => {
    const formValues = form.getFieldsValue();
    const options = fieldOptions.map((option) => ({
      optionValue: option,
    }));
    if (formValues.label && formValues.sectionFieldType) {
      if (editField) {
        onUpdate({
          ...editField,
          portfolioId,
          fieldName: formValues.label ?? editField.fieldName,
          sectionFieldType:
            formValues.sectionFieldType ?? editField.sectionFieldType,
          options: options.map(
            (option) =>
              editField.options?.find(
                (item) => item.optionValue === option.optionValue
              ) ?? {
                id: "",
                optionValue: option.optionValue,
                portfolioSectionFieldId: editField.portfolioSectionId,
              }
          ),
        });
        form.resetFields();
      } else {
        onCreate({
          label: formValues.label,
          sectionFieldType: formValues.sectionFieldType,
          options,
        });
        form.resetFields();
      }
    }
  };

  const onCancel: voidType = () => {
    form.resetFields();
    onClose(false);
  };
  useEffect(() => {
    if (editField) {
      form.setFieldsValue({
        label: editField.fieldName,
        sectionFieldType: editField.sectionFieldType,
      });
      setFieldOptions(
        editField.options?.map((option) => option.optionValue) ?? []
      );
    }
  }, [editField]);
  return (
    <Modal
      title={editField ? "Edit Data Field" : "Add Data Field"}
      open={isVisible}
      width={750}
      onCancel={(): void => onCancel()}
      centered={true}
      destroyOnClose={true}
      footer={[
        <div className={"w-full text-right"} key={0}>
          <Button
            onClick={(): void => onCancel()}
            className={`w-20 mx-2 text-primary border border-primary`}
          >
            Cancel
          </Button>
          {editField ? (
            <Button
              className={`bg-primary hover:bg-hover text-white border-0`}
              key={1}
              htmlType={"submit"}
              onClick={(): void => confirmFieldData()}
            >
              Update data field
            </Button>
          ) : (
            <Button
              className={`bg-primary hover:bg-hover text-white border-0`}
              key={1}
              htmlType={"submit"}
              onClick={(): void => confirmFieldData()}
            >
              Add new data field
            </Button>
          )}
        </div>,
      ]}
    >
      <Form form={form}>
        <Row>
          <Col lg={10} className={"px-1"}>
            <Form.Item
              name="label"
              rules={[{ required: true, message: "Please enter field name" }]}
            >
              <Input placeholder={"Enter field name"} maxLength={25} />
            </Form.Item>
          </Col>
          <Col lg={14} className={"px-1"}>
            <Form.Item
              name="sectionFieldType"
              className={"mb-2"}
              rules={[{ required: true, message: "Please choose input type" }]}
            >
              <Select
                placeholder={"Choose input type"}
                onChange={(): void => setFieldOptions([])}
              >
                {inputFieldTypes.map((type) => (
                  <Select.Option key={type.value} value={type.value}>
                    {type.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues): boolean =>
                prevValues?.sectionFieldType !== currentValues?.sectionFieldType
              }
            >
              {({ getFieldValue, setFieldsValue }): ReactNode =>
                ["DROP_BOX", "CHECK_BOX"].includes(
                  getFieldValue("sectionFieldType")
                ) && (
                  <>
                    <div className={"flex"}>
                      <Form.Item
                        className={"mb-2 flex-auto"}
                        name={"inputOption"}
                      >
                        <Input
                          onPressEnter={(): void => {
                            addFieldOption(getFieldValue("inputOption"));
                            setFieldsValue({ inputOption: null });
                          }}
                          placeholder={"Enter new option"}
                        />
                      </Form.Item>
                      <Form.Item className={"mb-2 flex-none"}>
                        <Button
                          className={`ml-1 p-0 w-8 text-primary bg-transparent`}
                          type={"text"}
                          onClick={(): void => {
                            addFieldOption(getFieldValue("inputOption"));
                            setFieldsValue({ inputOption: null });
                          }}
                          icon={<PlusSquareOutlined />}
                        />
                      </Form.Item>
                    </div>
                    <div className={"mb-2"}>
                      {fieldOptions.map((item) => (
                        <Tag
                          key={item}
                          color="default"
                          closable={true}
                          onClose={(): void =>
                            setFieldOptions((list) =>
                              list.filter((option) => option !== item)
                            )
                          }
                        >
                          {item}
                        </Tag>
                      ))}
                    </div>
                  </>
                )
              }
            </Form.Item>
          </Col>
        </Row>
        <div className={"border border-gray-200 w-full min-h-[200px]"}>
          <p className={"inline-block px-2 py-0.5 bg-gray-200"}>Preview</p>
          <div className={"py-4 "}>
            <Row className={"relative w-full"}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues): boolean =>
                  prevValues?.label !== currentValues?.label ||
                  prevValues?.sectionFieldType !==
                    currentValues?.sectionFieldType
                }
              >
                {({ getFieldValue }): ReactNode => (
                  <>
                    <Col xs={24} md={10}>
                      <div className={"text-left md:text-right w-full h-full"}>
                        <p
                          className={
                            "px-4 text-gray-400/90 inline align-sub uppercase"
                          }
                        >
                          {getFieldValue("label") || "LABEL"}
                        </p>
                      </div>
                    </Col>
                    <Col xs={24} md={14} className={"px-4"}>
                      <div
                        className={`min-h-[33px] w-full border-b border-blue-400`}
                      >
                        {(["TEXT", "NUMERIC"].includes(
                          getFieldValue("sectionFieldType")
                        ) ||
                          !getFieldValue("sectionFieldType")) && (
                          <Input
                            placeholder={"Add Information"}
                            bordered={false}
                            type={
                              getFieldValue("sectionFieldType") === "NUMERIC"
                                ? "number"
                                : "text"
                            }
                            suffix={
                              <i
                                className={`fa fa-pencil-alt text-primary cursor-pointer`}
                              />
                            }
                          />
                        )}
                        {["DROP_BOX", "CHECK_BOX"].includes(
                          getFieldValue("sectionFieldType")
                        ) && (
                          <Select
                            className={"w-full"}
                            bordered={false}
                            showArrow={true}
                            maxTagCount={6}
                            placeholder={"Add Information"}
                            mode={
                              getFieldValue("sectionFieldType") === "CHECK_BOX"
                                ? "multiple"
                                : undefined
                            }
                            suffixIcon={
                              <CaretDownOutlined className={"text-primary"} />
                            }
                          >
                            {fieldOptions?.map((item) => (
                              <Select.Option key={item} value={item}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                        {getFieldValue("sectionFieldType") === "COUNTRY" && (
                          <Select
                            className={"w-full"}
                            bordered={false}
                            showArrow={true}
                            placeholder={"Select Country"}
                            mode={undefined}
                            suffixIcon={
                              <CaretDownOutlined className={"text-primary"} />
                            }
                            showSearch={true}
                            optionFilterProp={"country"}
                            filterOption={true}
                          >
                            {countries
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                              .map((val) => (
                                <Select.Option
                                  title={val?.dial_code?.toString()}
                                  key={val.code}
                                  value={val.name}
                                  country={val?.name}
                                >
                                  <div
                                    className={
                                      "flex flex-row w-full items-center gap-x-1 h-fit"
                                    }
                                  >
                                    <img
                                      alt={val.emoji}
                                      src={val.image}
                                      style={{ width: "18px", height: "18px" }}
                                    />
                                    <span className={"truncate"}>
                                      {val.name}
                                    </span>
                                  </div>
                                </Select.Option>
                              ))}
                          </Select>
                        )}
                        {getFieldValue("sectionFieldType") === "CURRENCY" && (
                          <Select
                            className={"w-full"}
                            bordered={false}
                            showArrow={true}
                            placeholder={"Select Currency"}
                            suffixIcon={
                              <CaretDownOutlined className={"text-primary"} />
                            }
                            options={Object.values(AnalyticsCurrencyType).map(
                              (val) => ({
                                label: val,
                                value: val,
                              })
                            )}
                          />
                        )}
                        {getFieldValue("sectionFieldType") === "LONG_TEXT" && (
                          <div className={"relative"}>
                            <Input.TextArea
                              maxLength={255}
                              className={"min-h-7"}
                              placeholder="Add Information"
                              autoSize={{ minRows: 1, maxRows: 4 }}
                              bordered={false}
                            />
                            <i
                              className={
                                "absolute fa fa-pencil-alt text-primary cursor-pointer right-0 bottom-0 mr-3 mb-2"
                              }
                            />
                          </div>
                        )}
                        {getFieldValue("sectionFieldType") === "DATE" && (
                          <DatePicker
                            format={"MM/DD/YYYY"}
                            className={"w-full"}
                            placeholder={"MM/DD/YYYY"}
                            bordered={false}
                            suffixIcon={
                              <i className={`far fa-calendar text-primary`} />
                            }
                          />
                        )}
                      </div>
                    </Col>
                  </>
                )}
              </Form.Item>
            </Row>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

type InputFieldModalPropType = {
  isVisible: boolean;
  onCreate: (a: FieldType) => void;
  onClose: (a: boolean) => void;
  onUpdate: (a: PortfolioSectionFieldType) => void;
  editField: PortfolioSectionFieldType | null;
};
type ParamsType = {
  portfolioId: string;
};
