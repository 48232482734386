import React, { FC, ReactElement, useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import moment, { Moment } from "moment";
import { useForm } from "antd/es/form/Form";
import { TaskType, UserType } from "../../utils/types";
import { CustomAvatar } from "../general/CustomAvatar";
import { formItemRequiredRule } from "../../utils/formUtils";
import {
  arrayToMapConversion,
  filterOptionWithTitle,
  populateArrayWithPropertyPath,
  valOrDefault,
} from "../../utils/utils";
import { getPopupContainerForSelect } from "../../utils/container";

export const TaskForm: FC<NewTaskFormType> = ({
  onSubmit,
  edit = false,
  formData,
  tasks,
  onClear = (): void => {
    return;
  },
  dealTeam,
  transactionId,
  formStatus = FormStatusType.NONE,
}: NewTaskFormType) => {
  const [form] = useForm();
  const [teamMembers, setTeamMembers] = useState<Record<string, UserType>>({});
  useEffect(() => {
    // TODO: To be changed once the back-end issue is fixed for taskAssigneesDTO
    const dealTeamMembers = populateArrayWithPropertyPath(["userId"], dealTeam);
    setTeamMembers(arrayToMapConversion(dealTeam, "userId", []));
    formData &&
      form.setFieldsValue({
        ...formData,
        taskAssigneesDTO: valOrDefault(
          [],
          formData?.taskAssigneesDTO?.filter((id) =>
            dealTeamMembers.includes(id)
          )
        ),
      });
  }, [formData, dealTeam]);

  return (
    <>
      <Form
        form={form}
        layout={"vertical"}
        onFinish={(val): void => {
          onSubmit(val, transactionId);
          form.resetFields();
        }}
        className={"flex flex-col gap-y-3"}
      >
        {!edit && (
          <Form.Item
            className={"m-0 border-0"}
            name={"taskSectionId"}
            label={"Select Section"}
            rules={[
              {
                required: true,
                message: `* Required`,
              },
            ]}
          >
            <Select
              notFoundContent={"No Tasks Available"}
              disabled={formStatus === FormStatusType.LOADING}
              getPopupContainer={(trigger): HTMLElement =>
                trigger.parentElement
              }
              options={
                !edit &&
                tasks?.map(({ sectionHeading, id }) => ({
                  value: id,
                  label: sectionHeading,
                }))
              }
              placeholder={"Select Section"}
              autoFocus={true}
            />
          </Form.Item>
        )}
        <Form.Item
          className={"m-0 border-0"}
          name={"taskTitle"}
          label={"Task Name"}
          rules={[formItemRequiredRule]}
        >
          <Input
            disabled={formStatus === FormStatusType.LOADING}
            placeholder={"Task Title"}
          />
        </Form.Item>
        <Form.Item
          className={"m-0 border-0"}
          name={"dueDate"}
          label={"Due Date"}
          rules={[formItemRequiredRule]}
        >
          <DatePicker
            disabled={formStatus === FormStatusType.LOADING}
            disabledDate={(current): boolean => {
              return current && current < moment().subtract(1, "days");
            }}
            className={`w-full`}
            format={"MM/DD/YYYY"}
            placeholder={"Due Date"}
          />
        </Form.Item>
        <Form.Item
          className={"m-0 border-0"}
          name={"taskAssigneesDTO"}
          label={"Assign To"}
          rules={[formItemRequiredRule]}
        >
          <Select
            notFoundContent={"No Users Available"}
            disabled={formStatus === FormStatusType.LOADING}
            getPopupContainer={getPopupContainerForSelect}
            filterOption={filterOptionWithTitle}
            mode={"multiple"}
            maxTagCount={"responsive"}
            placeholder={"Select Assignees"}
            tagRender={({ value, onClose }): ReactElement => {
              return (
                <div
                  className={
                    "select-none text-sm bg-gray-100 border py-0.5 px-1 flex flex-row gap-x-1 m-0.5"
                  }
                >
                  {value &&
                    `${teamMembers[value as string].firstName} ${
                      teamMembers[value as string].lastName
                    }`}
                  <button onClick={onClose}>
                    <i className="cursor-pointer fas fa-times text-gray-500" />
                  </button>
                </div>
              );
            }}
          >
            {dealTeam?.map(({ userId, firstName, lastName, email }) => (
              <Select.Option
                key={userId}
                value={userId}
                title={firstName + lastName}
              >
                <div className={"flex flex-row items-start py-1"}>
                  <CustomAvatar
                    data={firstName[0] + lastName[0]}
                    color={firstName[0]}
                    size={"small"}
                  />
                  <div className={"leading-none flex flex-col"}>
                    {firstName} {lastName}
                    <span className={"text-secondary text-xs"}>{email}</span>
                  </div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={"taskDetails"} label={"Task Description"}>
          <TextArea
            placeholder={"Task Description"}
            autoSize={{ minRows: 3, maxRows: 10 }}
          />
        </Form.Item>
      </Form>
      <div className={"flex flex-row gap-y-1 w-full gap-x-2"}>
        <Button
          disabled={formStatus === FormStatusType.LOADING}
          className={
            "bg-white border hover:border-primary hover:text-primary w-full"
          }
          onClick={(): void => {
            formData ? form.setFieldsValue(formData) : form.resetFields();
            edit && onClear();
          }}
        >
          {edit ? "Cancel" : "Clear"}
        </Button>
        <Button
          loading={formStatus === FormStatusType.LOADING}
          className={"bg-primary hover:bg-hover text-white border-0 w-full"}
          onClick={(): void => form.submit()}
        >
          {edit ? "Update Task" : "Add Task"}
        </Button>
      </div>
    </>
  );
};

type NewTaskFormType = {
  onSubmit: (o: submitTaskType, id: string) => void;
  edit?: boolean;
  formData?: TaskEditType;
  tasks?: TaskType[];
  onClear?: () => void;
  dealTeam: UserType[];
  transactionId: string;
  formStatus?: FormStatusType;
  message?: string;
};

type submitTaskType = {
  dueDate: Moment;
  taskSectionId: string;
  taskDetails: string;
  taskAssigneesDTO: string[];
  taskTitle: string;
};

type TaskEditType = {
  id: string;
  dueDate: Moment;
  taskAssigneesDTO: string[];
  taskSectionId: string;
  active: boolean;
  createDate: string;
  createdBy: string;
  createdByUserDTO: UserType;
  modifiedDate: string;
  taskStatus: string;
  taskTitle: string;
  transactionId: string;
};

enum FormStatusType {
  LOADING,
  SUCCESS,
  FAILED,
  NONE,
}
