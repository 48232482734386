import React, { FC, memo, useRef, useState } from "react";

const RowIndexCell: FC<RowIndexCellType> = function ({
  position,
  selected = false,
  height = 35,
  onSelect = () => {},
}: RowIndexCellType) {
  const [y, setY] = useState<number>(height);
  const [drag, setDrag] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const [initialPos, setInitialPos] = useState<number | null>(null);
  const [initialSize, setInitialSize] = useState<number | null>(null);
  return (
    <div className={"select-none sticky left-0 z-[50] border-r"}>
      <div
        style={{ minHeight: y, height: "100%" }}
        ref={ref}
        role={"button"}
        onClick={onSelect}
        className={`min-h[${
          height + "px"
        }] relative group flex items-center justify-center w-10 min-w-[2.5rem] text-xxs ${
          selected
            ? "bg-gray-500 border-gray-500 text-white"
            : "bg-gray-50 border-gray-200 text-secondary"
        }`}
      >
        <div className={"group flex items-center justify-center select-none"}>
          {position}
        </div>

        <div
          role={"button"}
          draggable={true}
          onDragStart={(e) => {
            e.dataTransfer.setDragImage(new Image(), 0, 0);
            const resizable = ref.current;
            if (resizable) {
              setInitialPos(e.clientY);
              setInitialSize(resizable.offsetHeight);
            }
            setDrag(true);
          }}
          onDrag={(e) => {
            if (initialPos && initialSize) {
              const value = initialSize + e.clientY - initialPos;
              if (value > 20) {
                setY(value);
              }
            }
          }}
          onDragEnd={() => {
            setDrag(false);
            //  onHeightChange(y);
          }}
          className={`absolute bottom-0 cursor-move opacity-0 group-hover:opacity-100 cursor-e-resize float-right w-full h-2 bg-primary ${
            drag && "opacity-100"
          }`}
        >
          <div
            className={`${drag && "relative w-screen mt-2 h-px bg-primary"}`}
            style={{ zIndex: 2000 }}
          />
        </div>
      </div>
    </div>
  );
};

type RowIndexCellType = {
  position: number;
  selected?: boolean;
  height?: number;
  onSelect?: () => void;
  // onHeightChange?: (o: number) => void;
};

export default memo(RowIndexCell);
