import React, { FC, ReactElement } from "react";
import { Button } from "antd";
import { PermissionType } from "../../../utils/enums";

export const EmptyPortfolioCard: FC<EmptyPortfolioCardPropType> = ({
  message,
  image,
  buttonText,
  onClick,
  permissions,
}: EmptyPortfolioCardPropType) => {
  return (
    <div className={"w-full h-full flex"}>
      <div className={"w-1/2 mx-auto my-auto text-center"}>
        {image}
        <p className={"py-3"}>{message}</p>
        {buttonText &&
          onClick &&
          permissions.includes(PermissionType.ADMIN_PORTFOLIO) && (
            <Button
              onClick={onClick}
              className={`my-3 bg-primary hover:bg-hover text-white border-0`}
            >
              {buttonText}
            </Button>
          )}
      </div>
    </div>
  );
};

type EmptyPortfolioCardPropType = {
  message: string;
  image: string | ReactElement;
  buttonText?: string;
  onClick?: () => void;
  permissions: PermissionType[];
};
