import React, { FC } from "react";
import { WithTopNav } from "../components/layout/WithTopNav";
import { MenuRouteType } from "../utils/uiTypes";
import { useHistory, useParams } from "react-router";
import { ModuleAndCreateTx } from "../components/settings/ModuleAndCreateTx";
import { PendingTeam } from "../components/settings/PendingTeam";
import { Activity } from "../components/settings/Activity";
import { Verify } from "../components/settings/Verify";
import { PortfolioSettings } from "../components/settings/PortfolioSettings";
import { CompanyMerger } from "../components/settings/CompanyMerger";
import { ModuleAccessLevelSettings } from "../components/settings/ModuleAccessLevelSettings";

const SETTINGS_MENU_OPTIONS: MenuRouteType[] = [
  {
    title: "MODULE & CREATE TX",
    Component: ModuleAndCreateTx,
    exact: true,
    key: "company-admin",
    path: "/admin/company-admin",
    permission: true,
  },
  {
    title: "PENDING TEAM",
    Component: PendingTeam,
    exact: true,
    key: "pending-team",
    path: "/admin/pending-team",
    permission: true,
  },
  {
    title: "ACTIVITY",
    Component: Activity,
    exact: true,
    key: "platform-activity",
    path: "/admin/platform-activity",
    permission: true,
  },
  {
    title: "PENDING SIGN-UP",
    Component: Verify,
    exact: true,
    key: "pending-signup",
    path: "/admin/pending-signup",
    permission: true,
    visible: false,
  },
  {
    title: "PORTFOLIO SETTINGS",
    Component: PortfolioSettings,
    exact: true,
    key: "portfolio-settings-xyz",
    path: "/admin/portfolio-settings-xyz",
    permission: true,
    visible: false,
  },
  {
    title: "COMPANY MERGER",
    Component: CompanyMerger,
    exact: true,
    key: "company-merger",
    path: "/admin/company-merger",
    permission: true,
    visible: false,
  },
  {
    title: "MODULE ACCESS LEVELS",
    Component: ModuleAccessLevelSettings,
    exact: true,
    key: "access-levels",
    path: "/admin/access-levels",
    permission: true,
    badge: { label: "Read Only", color: "warning" },
  },
];

export const SystemSettings: FC = () => {
  const { adminMenu }: ParamsType = useParams();
  const history = useHistory();

  return (
    <>
      <WithTopNav
        gotoRoute={(link): void => history.push(`/admin/${link.key}`)}
        menu={SETTINGS_MENU_OPTIONS}
        defaultValue={"company-admin"}
        selectedKeys={[adminMenu]}
        title={"Admin Settings"}
      />
    </>
  );
};

type ParamsType = {
  adminMenu: string;
};
