import React, { FC, useEffect } from "react";
import { usePageTitle } from "../customHooks/usePageTitle";
import { MenuRouteType } from "../utils/uiTypes";
import { useHistory, useParams } from "react-router";
import { WebhooksOverview } from "../components/webhooks/WebhooksOverview";
import { WebhooksSubscriptions } from "../components/webhooks/WebhooksSubscriptions";
import { WebhooksEventTypes } from "../components/webhooks/WebhooksEventTypes";
import { WithTopNav } from "../components/layout/WithTopNav";

const MENU_OPTIONS = (): MenuRouteType[] => [
  {
    title: "Overview",
    Component: WebhooksOverview,
    key: "overview",
    path: "/company/webhooks/overview",
    exact: true,
    permission: true,
  },
  {
    title: "Event Types",
    Component: WebhooksEventTypes,
    key: "types",
    path: "/company/webhooks/types",
    exact: true,
    permission: true,
  },
  {
    title: "Subscriptions",
    Component: WebhooksSubscriptions,
    key: "subscriptions",
    path: "/company/webhooks/subscriptions",
    exact: true,
    permission: true,
  },
];

export const Webhooks: FC = () => {
  usePageTitle("Webhooks");
  const { menu }: ParamsType = useParams();
  const history = useHistory();

  return (
    <WithTopNav
      defaultValue={"overview"}
      selectedKeys={[menu]}
      menu={MENU_OPTIONS()}
      height={"full"}
      gotoRoute={({ key }): void => history.push(`/company/webhooks/${key}`)}
    />
  );
};

type ParamsType = {
  menu: string;
};
