import React, { FC, useEffect, useState } from "react";
import { Button, DatePicker } from "antd";
import moment from "moment";
import { MultipleSelect } from "../general/MultipleSelect";
import { transactionTagsArray } from "../../utils/transaction";
import { IndustryType, InstitutionType } from "../../utils/types";
import { useLocation } from "react-router-dom";
import { equals } from "ramda";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";

const DEFAULT_ANALYTICS_FILTERS: FiltersType = {
  from: moment().startOf("year").format("YYYY-MM-DD").toString(),
  to: moment().format("YYYY-MM-DD").toString(),
  companies: [],
  industries: [],
  dealStatuses: [],
};

/**
 * Filters Component for Analytics Component
 * @param filters - current filters in the Analytics/Parent Component
 * @param onChange - is for sending back the updated filters to the parent component
 * @param institutions - is for getting the list of institutions
 * @param industries - is for getting the list og industries
 * @constructor
 * @returns FC
 * @type AnalyticsFiltersType
 */
export const AnalyticsFilters: FC<AnalyticsFiltersType> = ({
  filters,
  onChange,
  institutions,
  industries,
}) => {
  const location = useLocation();

  const [currentFilters, setCurrentFilters] = useState<FiltersType>({});

  /**
   * Get the URL params according to the parameters
   * @param filters - is for the filters
   * @return string
   */
  const parseParams = (filters: FiltersType): string => {
    return new URLSearchParams([
      ["from", filters.from ?? ""],
      ["to", filters.to ?? ""],
      ...(filters.companies ?? []).map((val) => ["companies", val]),
      ...(filters.dealStatuses ?? []).map((val) => ["dealStatuses", val]),
      ...(filters.industries ?? []).map((val) => ["industries", val]),
    ]).toString();
  };

  useEffect(() => {
    const search = location.search;
    const filters: FiltersType = {
      from: moment(
        new URLSearchParams(search).get("from") ??
          moment().startOf("year").toString()
      )
        .format("YYYY-MM-DD")
        .toString(),
      to: moment(new URLSearchParams(search).get("to") ?? moment().toString())
        .format("YYYY-MM-DD")
        .toString(),
      dealStatuses: new URLSearchParams(search).getAll("dealStatuses") ?? [],
      industries: new URLSearchParams(search).getAll("industries") ?? [],
      companies: new URLSearchParams(search).getAll("companies") ?? [],
    };
    setCurrentFilters(filters);
    onChange(parseParams(filters));
  }, []);

  return (
    <div className={"flex flex-wrap gap-x-4 gap-y-2 items-center p-1.5 pb-2"}>
      <span>Filter By: </span>
      <DatePicker.RangePicker
        defaultValue={[
          moment(
            new URLSearchParams(location.search).get("from") ??
              moment().startOf("year").format("YYYY-MM-DD").toString()
          ),
          moment(
            new URLSearchParams(location.search).get("to") ??
              moment().format("YYYY-MM-DD").toString()
          ),
        ]}
        value={[moment(currentFilters.from), moment(currentFilters.to)]}
        format={"DD MMMM YYYY"}
        onChange={(values): void => {
          if (values) {
            const [start, end] = values;
            start &&
              end &&
              setCurrentFilters({
                ...currentFilters,
                from: start.format("YYYY-MM-DD").toString(),
                to: end.format("YYYY-MM-DD").toString(),
              });
          } else {
            setCurrentFilters({
              ...currentFilters,
              from: moment().startOf("year").format("YYYY-MM-DD").toString(),
              to: moment().format("YYYY-MM-DD").toString(),
            });
          }
        }}
      />
      <MultipleSelect
        value={currentFilters.industries}
        defaultValue={new URLSearchParams(location.search).getAll("industries")}
        className={
          "min-w-[125px] md:max-w-[50%] grow overflow-hidden max-h-[32px]"
        }
        placeholder={"Select Industry"}
        onChange={(e): void => {
          setCurrentFilters({ ...currentFilters, industries: e as string[] });
        }}
        label={"Industries"}
        options={industries.map(({ name, id }) => ({
          value: id,
          label: name,
          filterValue: name,
        }))}
      />
      <MultipleSelect
        value={currentFilters.dealStatuses}
        className={
          "min-w-[125px] md:max-w-[50%] grow overflow-hidden max-h-[32px]"
        }
        defaultValue={new URLSearchParams(location.search).getAll(
          "dealStatuses"
        )}
        placeholder={"Deal Status"}
        onChange={(e): void => {
          setCurrentFilters({ ...currentFilters, dealStatuses: e as string[] });
        }}
        label={"Statuses"}
        options={transactionTagsArray.map(({ value, label }) => ({
          value,
          label,
          filterValue: value,
        }))}
      />
      <MultipleSelect
        value={currentFilters.companies}
        className={
          "min-w-[125px] md:max-w-[50%] grow overflow-hidden max-h-[32px]"
        }
        defaultValue={new URLSearchParams(location.search).getAll("companies")}
        placeholder={"Select Institutions"}
        onChange={(e): void => {
          setCurrentFilters({ ...currentFilters, companies: e as string[] });
        }}
        label={"Institutions"}
        options={institutions
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map(({ id, name }) => ({
            value: id,
            label: name,
            filterValue: name,
          }))}
      />
      <Button
        disabled={
          filters
            ? equals(filters, parseParams(currentFilters)) ?? false
            : false
        }
        onClick={(): void => {
          onChange(parseParams(currentFilters));
        }}
        className={PRIMARY_BUTTON_STYLE}
      >
        Apply Filters
      </Button>
      <Button
        disabled={equals(filters, parseParams(DEFAULT_ANALYTICS_FILTERS))}
        onClick={(): void => {
          setCurrentFilters({
            from: moment().startOf("year").format("YYYY-MM-DD").toString(),
            to: moment().format("YYYY-MM-DD").toString(),
            companies: [],
            industries: [],
            dealStatuses: [],
          });
        }}
      >
        Clear Filters
      </Button>
    </div>
  );
};

type AnalyticsFiltersType = {
  onChange: (filters: string) => void;
  institutions: InstitutionType[];
  industries: IndustryType[];
  filters?: string | null;
};

type FiltersType = {
  from?: string;
  to?: string;
  dealStatuses?: string[];
  industries?: string[];
  companies?: string[];
};
