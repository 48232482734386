import React, { FC, useState } from "react";
import { TaskType } from "../../../../utils/types";
import { getTasks } from "../../../../services/services";
import { Button, Checkbox } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ResponseType } from "../../../../utils/uiTypes";
import { CloneTaskForm } from "../../../forms/CloneTaskForm";
import { flatten } from "ramda";

export const CloneTasks: FC<CloneTasksType> = function ({
  initiateClone,
  cancel,
  transactionId,
  disabled = false,
}: CloneTasksType) {
  const [selectedTransaction, setSelectedTransaction] = useState<string | null>(
    null
  );

  const [tasks, setTasks] = useState<TaskType[]>([]);
  // const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [tasksList, setTasksList] = useState<string[]>([]);

  const onChange: onChangeType = (list, tasklist): void => {
    setCheckedList(list);
    // setIndeterminate(!!list.length && list.length < tasklist.length);
    setCheckAll(list.length === tasklist.length);
  };

  const onCheckAllChange: onCheckAllChangeType = (e, tasklist): void => {
    setCheckedList(e.target.checked ? tasklist?.map((val) => val) ?? [] : []);
    // setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const getTransactionTasks: getTransactionTasksType = (id) => {
    getTasks({
      segments: {
        id,
      },
      params: {
        clone: true,
      },
    }).then(({ data = [] }: ResponseType<TaskType[]>) => {
      setTasks(data);
      setTasksList(
        flatten(
          data?.map(({ taskDTOs }) => (taskDTOs ?? [])?.map(({ id }) => id)) ??
            []
        )
      );
    });
  };

  return (
    <div className={""}>
      <CloneTaskForm
        disabled={disabled}
        transactionId={transactionId}
        onSubmit={({ peTransactionId }): void => {
          setSelectedTransaction(peTransactionId);
          getTransactionTasks(peTransactionId);
          setTasksList([]);
        }}
        onClear={(): void => {
          setTasks([]);
          setSelectedTransaction(null);
          setTasksList([]);
        }}
      />
      <div>
        {tasks.length > 0 && (
          <div className={"flex flex-col py-2"}>
            <Checkbox
              disabled={disabled}
              onChange={(e): void => onCheckAllChange(e, tasksList)}
              checked={checkAll}
            >
              Select All
            </Checkbox>

            <Checkbox.Group
              onChange={(e): void => onChange(e, tasksList)}
              value={checkedList}
            >
              {tasks.map(({ sectionHeading, id, taskDTOs }) => (
                <div key={id} className={"flex flex-col"}>
                  <span className={"font-medium text-lg mt-3"}>
                    {sectionHeading}
                  </span>
                  <div className={"flex flex-col gap-y"}>
                    {taskDTOs?.map(({ taskTitle, id }) => {
                      return (
                        <Checkbox
                          className={"mx-3"}
                          key={id}
                          value={id}
                          disabled={disabled}
                        >
                          {taskTitle}
                        </Checkbox>
                      );
                    })}
                  </div>
                </div>
              ))}
            </Checkbox.Group>
          </div>
        )}
      </div>
      <div className={"flex flex-row flex-row-reverse gap-x-2 mt-8"}>
        <Button
          className={"bg-primary hover:bg-hover text-white border-0"}
          loading={disabled}
          disabled={
            !selectedTransaction ||
            checkedList.length === 0 ||
            tasks.length === 0
          }
          onClick={(): void => {
            selectedTransaction &&
              initiateClone(
                transactionId,
                checkedList as string[],
                selectedTransaction
              );
          }}
        >
          Clone Tasks
        </Button>
        <Button
          className={"bg-white border hover:border-primary hover:text-primary"}
          loading={disabled}
          onClick={(): void => {
            setSelectedTransaction(null);
            setCheckedList([]);
            setCheckAll(false);
            cancel();
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

type CloneTasksType = {
  initiateClone: (id: string, commentIds: string[], from: string) => void;
  cancel: () => void;
  transactionId: string;
  disabled?: boolean;
};
type getTransactionTasksType = (id: string) => void;
type onChangeType = (list: CheckboxValueType[], tasklist: string[]) => void;
type onCheckAllChangeType = (
  e: CheckboxChangeEvent,
  tasklist: string[]
) => void;
