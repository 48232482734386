import React, { FC, memo, ReactElement, ReactNode } from "react";
import {
  AutoComplete,
  DatePicker,
  Input,
  InputNumber,
  InputNumberProps,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import { CurrencySymbolType } from "../../utils/enums";
import { formatTotal } from "../../utils/allocation";
import {
  CalendarOutlined,
  CaretDownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { defaultTo, equals, join, or, split } from "ramda";
import { getContainerForFullScreen } from "../../utils/container";
import { doNothing } from "../../utils/utils";
import { SizeType } from "antd/es/config-provider/SizeContext";

const CSS_BY_SIZE: Record<Exclude<SizeType, null | undefined>, string> = {
  large: "py-2.5 px-3",
  middle: "py-2.5 px-3",
  small: "py-1.5 px-1.5",
};

const numberParser: InputNumberProps["parser"] = (val): string | number =>
  val ? val.replace(/(,*)|\D/g, "") : "";

const EditableTableCell: FC<EditableTableCellProps> = ({
  OId,
  editable,
  value,
  className,
  children,
  defaultCell,
  prefix,
  placeholder = "",
  onChange,
  selectOptions,
  suffix = "",
  type = "text",
  loading = false,
  bordered = true,
  padding,
  size = "middle",
  autoFocus = false,
  formatter = formatTotal,
  parser = numberParser,
  multiple = false,
}: EditableTableCellProps) => {
  const formatDateValue = (
    date: string | number | undefined
  ): moment.Moment | undefined => {
    const dateNumber = date
      ? Number(defaultTo(moment(date).format("x"), Number(date)))
      : undefined;
    return dateNumber ? moment(dateNumber).utc() : undefined;
  };

  const getCellType = (): ReactElement | undefined => {
    if (type === "date") {
      return (
        <>
          <DatePicker
            autoFocus={autoFocus}
            size={size}
            getPopupContainer={getContainerForFullScreen}
            suffixIcon={false}
            onChange={(value) => onChange && onChange(moment(value).valueOf())}
            placeholder={or(!editable, loading) ? "-" : "Select Date"}
            bordered={false}
            value={formatDateValue(value)}
            className={`w-full border-0 bg-transparent ${
              or(!editable, loading) && "pointer-events-none"
            }`}
          />
          {loading ? (
            <Spin size={"small"} indicator={<LoadingOutlined spin={true} />} />
          ) : (
            <CalendarOutlined className={"text-muted"} />
          )}
        </>
      );
    }
    if (type === "formula") {
      return (
        <>
          <span>{prefix}</span>
          <InputNumber
            controls={false}
            className={`w-11/12 !bg-transparent ${
              or(!editable, loading) && "pointer-events-none"
            }`}
            disabled={loading}
            bordered={false}
            onChange={doNothing}
            stringMode={true}
            placeholder={placeholder}
            readOnly={true}
            value={value}
          />
          <div className={"text-right w-min"}>
            {loading && (
              <Spin
                size={"small"}
                indicator={<LoadingOutlined spin={true} />}
              />
            )}
            &nbsp;{suffix}
          </div>
        </>
      );
    }
    if (type === "text") {
      return (
        <>
          <Input
            autoFocus={autoFocus}
            size={size}
            placeholder={placeholder}
            className={`${
              or(!editable, loading) && "text-black pointer-events-none"
            }`}
            bordered={false}
            key={OId}
            onChange={(e): void => {
              onChange &&
                !equals(value?.toString(), e.target.value) &&
                onChange(e.target.value);
            }}
            onKeyPress={(e): void => {
              or(!editable, loading) && e.preventDefault();
            }}
            prefix={prefix}
            {...(editable ? { defaultValue: value } : { value: value })}
          />
          <span>
            {loading && (
              <Spin
                size={"small"}
                indicator={<LoadingOutlined spin={true} />}
              />
            )}
          </span>
          <span>{suffix}</span>
        </>
      );
    }
    if (type === "select") {
      return (
        <>
          <Select
            autoFocus={autoFocus}
            size={size}
            allowClear
            notFoundContent={"No Options Added"}
            className={`w-full group ${
              or(!editable, loading) && "pointer-events-none"
            }`}
            disabled={!editable || loading}
            bordered={false}
            mode={multiple ? "tags" : undefined}
            showArrow={true}
            getPopupContainer={getContainerForFullScreen}
            placeholder={placeholder}
            optionFilterProp="label"
            onClear={() => {
              onChange && onChange("");
            }}
            onSelect={(value1): void => {
              if (onChange)
                multiple
                  ? onChange(
                      join(", ", [
                        value1.toString() ?? "",
                        ...(value ? split(", ", value.toString()) : []),
                      ])
                    )
                  : onChange(value1);
            }}
            onDeselect={(value1): void => {
              if (onChange && multiple) {
                const values = split(", ", value?.toString() ?? "");
                const index = values.indexOf(value1.toString());
                if (index > -1) {
                  values.splice(index, 1);
                  onChange(join(", ", values));
                }
              }
            }}
            suffixIcon={false}
            value={
              value
                ? multiple
                  ? split(", ", value.toString() ?? "")
                  : [value.toString()]
                : []
            }
          >
            {selectOptions?.map(({ key, value }) => (
              <Select.Option key={key} value={key} label={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
          {loading ? (
            <Spin size={"small"} indicator={<LoadingOutlined spin={true} />} />
          ) : (
            <>
              {editable && (
                <CaretDownOutlined
                  className={`text-transparent group-hover:text-primary`}
                />
              )}
            </>
          )}
        </>
      );
    }
    if (type === "number") {
      return (
        <>
          <span>{prefix}</span>

          <InputNumber
            autoFocus={autoFocus}
            size={size}
            controls={false}
            className={`w-11/12 !bg-transparent ${
              or(!editable, loading) && "pointer-events-none"
            }`}
            disabled={loading}
            bordered={false}
            onChange={(e): void => {
              onChange &&
                !equals(
                  e?.toString()?.replaceAll(",", ""),
                  value?.toString().replaceAll(",", "")
                ) &&
                onChange(e?.toString() ?? "0");
            }}
            maxLength={18}
            stringMode={true}
            min={0}
            placeholder={placeholder}
            readOnly={or(!editable, loading)}
            parser={parser}
            formatter={(val): string => `${val && formatter(val)}`}
            {...(editable ? { defaultValue: value } : { value })}
          />
          <div className={"text-right w-min"}>
            {loading && (
              <Spin
                size={"small"}
                indicator={<LoadingOutlined spin={true} />}
              />
            )}
            &nbsp;{suffix}&nbsp;
          </div>
        </>
      );
    }
    if (type === "textarea") {
      return (
        <>
          <TextArea
            autoFocus={autoFocus}
            size={size}
            className={`w-full ${
              or(!editable, loading) && "pointer-events-none"
            }`}
            placeholder={placeholder}
            bordered={false}
            onChange={(e): void => {
              onChange &&
                !equals(value?.toString(), e.target.value) &&
                onChange(e.target.value);
            }}
            {...(editable ? { defaultValue: value } : { value })}
            autoSize
          />
          <span>
            {loading && (
              <Spin
                size={"small"}
                indicator={<LoadingOutlined spin={true} />}
              />
            )}
          </span>
        </>
      );
    }
    if (type === "autocomplete") {
      return (
        <>
          <AutoComplete
            getPopupContainer={getContainerForFullScreen}
            bordered={false}
            popupClassName={"!z-50"}
            className={`w-full group ${
              or(!editable, loading) && "pointer-events-none"
            }`}
            value={value}
            filterOption={(inputValue, option) =>
              option!.value
                ?.toString()
                .toUpperCase()
                ?.indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={(e): void => {
              onChange && !equals(value?.toString(), e) && onChange(e);
            }}
            placeholder={"-"}
            options={selectOptions?.map(({ value }) => ({
              value,
              label: value,
            }))}
          />
        </>
      );
    }
  };

  return (
    <div
      key={OId}
      className={`${className} m-0 w-full flex items-center ${
        padding ? padding : CSS_BY_SIZE[size]
      } border border-transparent ${
        bordered && "border-r-gray-200"
      } bg-transparent group-hover:bg-blue-50 focus-within:bg-white focus-within:border focus-within:border-sky-500 focus-within:z-50 ${
        editable && "focus-within:shadow-cell"
      }`}
    >
      {defaultCell ? children : getCellType()}
    </div>
  );
};

export type SelectOptionType = {
  key: string;
  value: string;
  label?: string;
  name?: string;
};
export type editTableDataType =
  | "date"
  | "text"
  | "number"
  | "select"
  | "textarea"
  | "autocomplete"
  | "formula";

export type EditableTableCellProps = {
  childClassName?: string;
  children?: ReactNode;
  type?: editTableDataType;
  selectOptions?: SelectOptionType[];
  className?: string;
  OId: string;
  value: string | number | undefined;
  editable?: boolean;
  defaultCell: boolean;
  prefix?: CurrencySymbolType | ReactNode;
  suffix?: ReactNode;
  placeholder?: string;
  onChange?: (a: string | number) => void;
  loading?: boolean;
  bordered?: boolean;
  size?: SizeType;
  autoFocus?: boolean;
  padding?: string;
  formatter?: (v: string | number) => string | number;
  parser?: InputNumberProps["parser"];
  multiple?: boolean;
};

export default memo(EditableTableCell);
