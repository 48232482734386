import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  getSearchExcel,
  getSearchExcelWithFilters,
  getTermsheetDetails,
} from "../../../services/services";
import { Button, message, Select, Switch, Tooltip } from "antd";
import { ExportOutlined, LeftOutlined } from "@ant-design/icons";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import { WebFormDetailsGroupedResponseType } from "../../../utils/types";
import {
  FileResponseType,
  ResponseType,
  voidType,
} from "../../../utils/uiTypes";
import { useHistory } from "react-router";
import PrecedentTermsheetTable from "./PrecedentTermsheetTable";
import { CustomSpin } from "../../general/CustomSpin";
import { any, isEmpty } from "ramda";
import { ConfirmDownload } from "../../../utils/confirmationModals";

const populateFilteredData = (
  searchValues: string[],
  results: WebFormDetailsGroupedResponseType[]
): WebFormDetailsGroupedResponseType[] =>
  results.reduce<WebFormDetailsGroupedResponseType[]>(
    (previousValue, currentValue) => {
      const data = currentValue.termSheetData.filter(({ metric }) =>
        isEmpty(searchValues)
          ? true
          : any(
              (searchValue) =>
                metric.toLowerCase().includes(searchValue.toLowerCase()),
              searchValues
            )
      );
      if (isEmpty(data)) {
        return previousValue;
      } else {
        return [...previousValue, { ...currentValue, termSheetData: data }];
      }
    },
    []
  );

export const PrecedentDetails: FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [results, setResults] = useState<WebFormDetailsGroupedResponseType[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);

  const [actions, setActions] = useState<PCActionsType>({
    redline: false,
    extendedTermsheet: false,
    filters: [],
    showResponses: false,
  });

  const fetchResults = (params: string): void => {
    setLoading(true);
    getTermsheetDetails({ params })
      .then(({ data }: ResponseType<WebFormDetailsGroupedResponseType[]>) => {
        setResults(data);
        setLoading(false);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(error);
        setLoading(false);
      });
  };

  const generateCompleteExcel = (params: string): void => {
    message.loading({
      content: "Processing File...",
      duration: 0,
      key: "export",
    });
    getSearchExcel({ params })
      .then(({ url, filename }: FileResponseType) => {
        message.success({ key: "export", content: "File Ready to Download" });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        console.log(error);
        message.error({ content: error, key: "export" });
      });
  };

  const generateFilteredDataExcel = (searchValues: string[]): void => {
    const body = populateFilteredData(searchValues, results);
    message.loading({
      content: "Processing File...",
      duration: 0,
      key: "export",
    });
    getSearchExcelWithFilters({ body: JSON.stringify(body) })
      .then(({ url, filename }: FileResponseType) => {
        message.success({ key: "export", content: "File Ready to Download" });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        console.log(error);
        message.error({ content: error, key: "export" });
      });
  };

  const exportData: voidType = () => {
    isEmpty(actions.filters)
      ? generateCompleteExcel(search)
      : generateFilteredDataExcel(actions.filters ?? []);
  };

  useEffect(() => {
    fetchResults(search);
  }, [search]);

  return (
    <div
      className={
        "relative max-h-full w-full h-screen bg-gray-100 flex flex-col"
      }
    >
      <CustomSpin loading={loading} />
      <div
        className={
          "mb-5 flex flex-col md:flex-row items-center justify-between p-6 pb-0 "
        }
      >
        <div className={"text-2xl font-medium"}>Termsheet Compare</div>
      </div>
      <div className={`w-full h-full flex flex-col overflow-y-auto p-6 pt-0`}>
        <div
          className={
            "flex flex-col md:flex-row items-center justify-between pb-3"
          }
        >
          <Button
            size={"small"}
            type={"link"}
            className={"text-primary hover:text-hover pl-0"}
            icon={<LeftOutlined />}
            onClick={(): void => {
              history.goBack();
            }}
          >
            Back to precedent Search
          </Button>
          <div className={"flex flex-row items-center gap-2"}>
            <Tooltip placement="bottom" title={"Hide Responses"}>
              <Switch
                defaultChecked={false}
                checked={actions.showResponses}
                className={`${
                  actions.showResponses ? "bg-primary" : "bg-muted"
                }`}
                onChange={(value) =>
                  setActions({ ...actions, showResponses: value })
                }
              />
              <span className={"text-xs font-medium pl-2"}>Show Responses</span>
            </Tooltip>
            {results.length > 0 && (actions.filters ?? [])?.length > 0 && (
              <Tooltip placement="bottom" title={"Expand the sheets"}>
                <Switch
                  defaultChecked={false}
                  checked={
                    actions.extendedTermsheet && !isEmpty(actions.filters)
                  }
                  className={`${
                    actions.extendedTermsheet ? "bg-primary" : "bg-muted"
                  }`}
                  onChange={(value) =>
                    setActions({ ...actions, extendedTermsheet: value })
                  }
                />
                <span className={"text-xs font-medium pl-2"}>
                  Highlight Keyword(s)
                </span>
              </Tooltip>
            )}
            <Select
              mode={"tags"}
              className={"w-56 md:w-96"}
              onChange={(value) => setActions({ ...actions, filters: value })}
              allowClear={true}
              placeholder={"Filter Bid Metrics by Keyword(s)"}
              notFoundContent={
                "Enter keyword(s) to filter Bid Metrics by any of them"
              }
            />
            {results.length > 0 && (
              <>
                {actions.showResponses && (
                  <Tooltip placement="bottom" title={"Redline"}>
                    <Switch
                      defaultChecked={false}
                      checked={actions.redline}
                      className={`${
                        actions.redline ? "bg-primary" : "bg-muted"
                      }`}
                      onChange={(value) =>
                        setActions({ ...actions, redline: value })
                      }
                    />
                    <span className={"text-xs font-medium pl-2"}>Redline</span>
                  </Tooltip>
                )}
                <Button
                  onClick={exportData}
                  icon={<ExportOutlined />}
                  className={PRIMARY_BUTTON_STYLE}
                >
                  Export
                </Button>
              </>
            )}
          </div>
        </div>
        {results.length > 0 ? (
          <div
            className={"flex flex-row items-start overflow-x-auto gap-4 h-full"}
          >
            {results.map((data, index) => (
              <PrecedentTermsheetTable
                draggable={results.length >= 2}
                initialWidthBreak={
                  results.length === 1 ? 1 : results.length === 2 ? 2 : 1.7
                }
                key={index}
                termsheet={data}
                actions={actions}
              />
            ))}
          </div>
        ) : (
          "No Results"
        )}
      </div>
    </div>
  );
};

export type PCActionsType = {
  redline?: boolean;
  filters?: string[];
  extendedTermsheet?: boolean;
  showResponses?: boolean;
};
