import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { CustomSpin } from "../general/CustomSpin";
import { Button, Empty, message, Table } from "antd";
import { LeftOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import { TemplateType, UserType } from "../../utils/types";
import { ResponseType, voidType } from "../../utils/uiTypes";
import { deleteTemplateById, getAllTemplates } from "../../services/services";
import { ACTION_BUTTON_CSS } from "../../utils/cssConfigs";
import { tableColumnHeader } from "../../utils/componentUtils";
import { convertDateToFormat } from "../../utils/moment";
import { ConfirmDelete } from "../../utils/confirmationModals";
import { UserContext } from "../../context/UserContext";
import { useHistory } from "react-router";
import { ColumnsType } from "antd/es/table";

export const TermsheetTemplates: FC<TermsheetTemplatesType> = ({
  initialSetup,
  onSubmit,
}: TermsheetTemplatesType) => {
  const { user } = useContext(UserContext);
  const history = useHistory();

  const [templates, setTemplates] = useState<TemplateType[]>([]);
  const [loading, setLoading] = useState<LoadingType>({
    data: true,
    delete: false,
  });

  const fetchTemplates: voidType = () => {
    getAllTemplates({})
      .then(({ data = [] }: ResponseType<TemplateType[]>) => {
        setTemplates(data);
        setLoading({ ...loading, data: false });
      })
      .catch((error: string) => {
        setLoading({ ...loading, data: false });
        message.error(error ? error : "Error Fetching Templates!");
      });
  };

  const onDelete: onDeleteType = (templateId, companyId): void => {
    setLoading({ ...loading, delete: true });
    deleteTemplateById({
      segments: {
        companyId,
        templateId: templateId,
      },
    })
      .then(({ data }: ResponseType<TemplateType[]>) => {
        setTemplates(data);
        message.success("Template has been deleted successfully!");
        setLoading({ ...loading, delete: false });
      })
      .catch((error: string) => {
        setLoading({ ...loading, delete: false });
        message.error(error ? error : "Error deleting Template!");
      });
  };

  const columns: ColumnsType<TemplateType> = [
    {
      title: tableColumnHeader("Templates"),
      dataIndex: "name",
      key: "name",
      width: "22%",
      className: "group-hover:bg-blue-50",
    },
    {
      title: tableColumnHeader("Created Date"),
      dataIndex: "createDate",
      align: "left",
      key: "createDate",
      width: "13%",
      className: "group-hover:bg-blue-50",
      render: function parseDate(createDate: string): ReactNode {
        return <div>{convertDateToFormat(createDate, "D.MMM.YY", false)}</div>;
      },
      sorter: {
        compare: (a: TemplateType, b: TemplateType): number => {
          return parseInt(a.createDate) - parseInt(b.createDate);
        },
      },
    },
    {
      title: tableColumnHeader("Created By"),
      dataIndex: "createdByUserDTO",
      key: "createdByUserDTO",
      align: "left",
      width: "13%",
      className: "group-hover:bg-blue-50",
      render: function createdBy(createdByUser: UserType): ReactNode {
        return `${createdByUser?.firstName || ""} ${
          createdByUser?.lastName || ""
        }`;
      },
    },
    {
      title: tableColumnHeader("Actions"),
      dataIndex: "operation",
      key: "operation",
      align: "right",
      width: "4%",
      className: "group-hover:bg-blue-50",
      render: function (_: string, record: TemplateType): ReactNode {
        return (
          <span
            className={
              "relative flex flex-row flex-row-reverse items-center gap-x-3"
            }
            onClick={(event): void => event.stopPropagation()}
          >
            <Button
              type={"text"}
              icon={<i className="fas fa-ellipsis-h" />}
              className={
                "absolute right-0 border-0 flex items-center px-2 text-gray-900 block opacity-50 group-hover:opacity-0"
              }
            />
            <Button
              className={`${ACTION_BUTTON_CSS} hover:text-red-500 opacity-0 group-hover:opacity-100`}
              icon={<i className="fas fa-trash-alt" />}
              loading={loading.delete}
              disabled={loading.delete}
              onClick={(): void => {
                ConfirmDelete(
                  "Confirm Delete Template?",
                  () => {
                    user?.companyId && onDelete(record.id, user?.companyId);
                  },
                  `Template Name: ${record.name}`
                );
              }}
            />
            <Button
              onClick={(): void =>
                history.push(`/company/templates/${record.id}`)
              }
              type={"link"}
              icon={<i className="fas fa-pencil-alt" />}
              className={`${ACTION_BUTTON_CSS} hover:text-primary opacity-0 group-hover:opacity-100`}
            />
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <div
      className={
        "relative max-h-full w-full h-full bg-gray-100 overflow-y-auto flex flex-col"
      }
    >
      <CustomSpin loading={loading.data} />
      <div className={"mb-5 flex flex-row items-center p-6 pb-0"}>
        <span className={"text-2xl font-medium"}>
          {initialSetup ? "Add Term Sheet Templates" : "Term Sheet Templates"}
        </span>
        {user?.isAdmin && (
          <Button
            className={
              "ml-auto hidden md:block bg-primary hover:bg-hover text-white border-0"
            }
            icon={<PlusOutlined />}
            onClick={(): void =>
              history.push(`/company/templates/${user?.companyId}/create`)
            }
          >
            New Template
          </Button>
        )}
      </div>
      <div className={"w-full h-full flex-initial overflow-y-auto p-6 pt-0"}>
        {!templates?.length ? (
          <Empty
            description={
              "You don't have any existing templates for the negotiation grid yet."
            }
            className={"my-auto bg-white py-6"}
          />
        ) : (
          <Table
            rowKey={"id"}
            className={"transition duration-300 ease-out transform"}
            columns={
              user?.isAdmin
                ? columns
                : columns.filter((col) => col.key !== "operation")
            }
            pagination={false}
            dataSource={templates}
            scroll={{ x: true, y: window.innerHeight - 300 }}
            rowClassName={"group relative hover:bg-blue-50"}
          />
        )}
        {initialSetup && (
          <div className={"mt-6 flex justify-end"}>
            <Button
              className={" w-32 mr-6"}
              onClick={(): void => {
                onSubmit(1);
              }}
            >
              <LeftOutlined /> Back
            </Button>
            <Button
              disabled={!templates}
              className={"bg-primary hover:bg-hover border-0 w-50 text-white"}
              href={"/transactions"}
            >
              Complete Setup <RightOutlined />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

type TermsheetTemplatesType = {
  initialSetup: boolean;
  onSubmit: (val: number) => void;
};
type onDeleteType = (templateId: string, companyId: string) => void;
type LoadingType = {
  data: boolean;
  delete: boolean;
};
