import {
  CompanyModuleType,
  CrmDealActivity,
  CRMFilterType,
  CRMPortfolio,
  ElementPermissionsType,
  TagType,
  UserType,
} from "./types";
import {
  AnalyticsCurrencyType,
  ElementType,
  PermissionType,
  PETransactionCreationType,
} from "./enums";
import { anyPass, equals, isEmpty, isNil, join, pathOr, where } from "ramda";
import { PropertyType } from "./charts";
import { checkElementViewPermission } from "./permissions";
import { ColumnsType } from "antd/es/table";
import { tableColumnHeader } from "./componentUtils";
import { convertDateToFormat } from "./moment";
import React, { ReactNode } from "react";
import { Tag } from "antd";
import { renderTransactionTagStatus } from "./transaction";
import moment from "moment/moment";
import { isEmptyOrNil, valOrDefault } from "./utils";
import { LocationDescriptor, Path } from "history";
import { TextOverFlowHandle } from "../components/general/TextOverFlowHandle";
import { ElementTag } from "../components/company/tags/ElementTag";

const NOT_SPECIFIED = "NOT SPECIFIED";

const DEAL_ACTIVITY_CHART_PROPERTIES: PropertyType[] = [
  {
    key: "xKey",
    value: "status",
    label: "Deal Status",
  },
  {
    key: "yKey",
    value: "count",
    label: "Total Count",
  },
];

export const columnSort: columnSortType = (a, b, type) => {
  switch (type) {
    case "TEXT":
      return String(a ?? "")
        .toLowerCase()
        .localeCompare(String(b ?? "").toLowerCase());
    case "DATE":
      return new Date(a).valueOf() - new Date(b).valueOf();
    case "NUMERIC":
      return Number(a ?? 0) - Number(b ?? 0);
  }
};

export const isInternalNote = (id: string) =>
  where({
    elementId: equals(id),
    elementType: anyPass([
      equals(ElementType.CRM),
      equals(ElementType.SPONSOR_COVERAGE),
    ]),
  });

const renderInstitutionTransactionStatus = (isActive: boolean) =>
  isActive ? (
    <Tag color={"success"} key={0} className={"uppercase"}>
      ACTIVE
    </Tag>
  ) : (
    <Tag color={"red"} key={0} className={"uppercase"}>
      REMOVED
    </Tag>
  );

const getModuleName: getModuleNameType = (location) => {
  const search = new URLSearchParams(location.search);
  const module = search.get("crmElementType");

  if (equals(module, ElementType.CRM)) return ElementType.CRM;
  else if (equals(module, ElementType.SPONSOR_COVERAGE))
    return ElementType.SPONSOR_COVERAGE;
  else return null;
};

export const isAdmin: isSponsorCoverageType = (user) => {
  if (equals(getModuleName(location), ElementType.CRM)) {
    return checkElementViewPermission(
      ElementType.CRM,
      user?.elementPermissions || [],
      [PermissionType.ADMIN_CRM],
      true
    );
  } else
    return checkElementViewPermission(
      ElementType.SPONSOR_COVERAGE,
      user?.elementPermissions || [],
      [PermissionType.ADMIN_SPONSOR_COVERAGE],
      true
    );
};

export const portfolioColumns: ColumnsType<CRMPortfolio> = [
  {
    title: tableColumnHeader("INVESTMENT DATE"),
    key: "investmentDate",
    dataIndex: "investmentDate",
    width: "12%",
    render: (_, record) =>
      record.investmentDate
        ? convertDateToFormat(record.investmentDate, "ll")
        : "N/A",
    sorter: (a, b) => columnSort(a.investmentDate, b.investmentDate, "NUMERIC"),
    defaultSortOrder: "descend",
    className: "group-hover:bg-blue-50 min-w-[130px]",
  },
  {
    title: tableColumnHeader("Portfolio Name"),
    key: "portfolioName",
    dataIndex: "portfolioName",
    sorter: (a, b) => columnSort(a.portfolioName, b.portfolioName, "TEXT"),
    className: "group-hover:bg-blue-50 min-w-[130px]",
    render: (title) => (
      <div className={"flex flex-row items-center gap-2 justify-between"}>
        <TextOverFlowHandle text={title} />
      </div>
    ),
  },
  {
    title: tableColumnHeader("Status"),
    width: "15%",
    dataIndex: "status",
    key: "status",
    render: (record) =>
      !isNil(record) ? (
        <span>{record}</span>
      ) : (
        <span className={"text-muted"}>-</span>
      ),
    sorter: (a, b) => columnSort(a.status ?? "", b.status ?? "", "TEXT"),
    className: "group-hover:bg-blue-50 min-w-[130px]",
  },
  {
    title: tableColumnHeader("EXIT DATE"),
    key: "exitDate",
    dataIndex: "exitDate",
    width: "10%",
    render: (_, record) =>
      record.exitDate ? convertDateToFormat(record.exitDate, "ll") : "-",
    sorter: (a, b) => columnSort(a.exitDate, b.exitDate, "NUMERIC"),
    className: "group-hover:bg-blue-50 min-w-[130px]",
  },
  {
    title: tableColumnHeader("Company"),
    width: "15%",
    dataIndex: "companyName",
    key: "companyName",
    render: (record) =>
      !isNil(record) ? (
        <span>{record}</span>
      ) : (
        <span className={"text-muted"}>-</span>
      ),
    sorter: (a, b) =>
      columnSort(a.companyName ?? "", b.companyName ?? "", "TEXT"),
    className: "group-hover:bg-blue-50 min-w-[130px]",
  },
  {
    title: tableColumnHeader("Transaction Type"),
    key: "transactionType",
    dataIndex: "transactionType",
    sorter: (a, b) => columnSort(a.transactionType, b.transactionType, "TEXT"),
    className: "group-hover:bg-blue-50 min-w-[130px]",
    width: "15%",
  },
  {
    title: tableColumnHeader("Funds"),
    key: "funds",
    dataIndex: "funds",
    defaultSortOrder: "descend",
    className: "group-hover:bg-blue-50 min-w-[130px]",
    width: "15%",
    render: (_, { funds }) => <TextOverFlowHandle text={join(", ", funds)} />,
  },
];

export const dealActivityColumns = (
  showTransactionStatus = false,
  showUserStatus = false,
  showInstitutionStatus = false
): ColumnsType<CrmDealActivity> => [
  {
    title: tableColumnHeader("DATE"),
    key: "creationDate",
    width: "10%",
    render: (record) =>
      convertDateToFormat(
        record.creationDate || record.createDate,
        "ll",
        !!record.creationDate
      ),
    sorter: (a, b) =>
      columnSort(
        a.creationDate || a.createDate,
        b.creationDate || b.creationDate,
        "NUMERIC"
      ),
    defaultSortOrder: "descend",
    className: "group-hover:bg-blue-50 min-w-[130px]",
  },
  {
    title: tableColumnHeader("Deal Name"),
    align: "left",
    dataIndex: ["deleted"],
    key: "peTransactionTitle",
    className:
      "group-hover:bg-blue-50 min-w-[130px] max-w-[300px] overflow-hidden",
    sorter: (a, b) =>
      columnSort(
        a.peTransactionTitle ?? "",
        b.peTransactionTitle ?? "",
        "TEXT"
      ),
    render: function name(deleted: boolean, transaction): ReactNode {
      return (
        <div className={"flex flex-row items-center gap-2 justify-between"}>
          <TextOverFlowHandle text={transaction.peTransactionTitle} />
          <span>
            {showTransactionStatus && deleted && (
              <Tag color={"red"} key={0} className={"uppercase"}>
                ARCHIVE
              </Tag>
            )}
            {equals(
              transaction?.peTransactionCreationType,
              PETransactionCreationType.OFFLINE
            ) && (
              <Tag color={"warning"} key={0} className={"uppercase"}>
                OFFLINE
              </Tag>
            )}
          </span>
        </div>
      );
    },
  },
  {
    title: tableColumnHeader("TARGET COMPANY"),
    width: "15%",
    dataIndex: "companyToBuy",
    key: "companyToBuy",
    render: (record) =>
      !isNil(record) ? (
        <span>{record}</span>
      ) : (
        <span className={"text-muted"}>-</span>
      ),
    sorter: (a, b) =>
      columnSort(a.companyToBuy ?? "", b.companyToBuy ?? "", "TEXT"),
    className: "group-hover:bg-blue-50 min-w-[130px]",
  },
  {
    title: tableColumnHeader("TYPE"),
    width: "15%",
    dataIndex: "peTransactionTypeName",
    key: "peTransactionTypeName",
    render: (record) =>
      !isNil(record) ? (
        <span>{record}</span>
      ) : (
        <span className={"text-muted"}>-</span>
      ),
    sorter: (a, b) =>
      columnSort(
        a.peTransactionTypeName ?? "",
        b.peTransactionTypeName ?? "",
        "TEXT"
      ),
    className: "group-hover:bg-blue-50 min-w-[150px]",
  },
  {
    title: tableColumnHeader("STATUS"),
    width: "10%",
    key: "peTransactionTagType",
    dataIndex: "peTransactionTagType",
    render: (record) =>
      !isNil(record) ? (
        renderTransactionTagStatus(record)
      ) : (
        <span className={"text-muted"}>-</span>
      ),
    className: "group-hover:bg-blue-50 min-w-[110px]",
    sorter: (a, b) =>
      columnSort(
        a.peTransactionTagType ?? "",
        b.peTransactionTagType ?? "",
        "TEXT"
      ),
  },
  ...(showInstitutionStatus
    ? [
        {
          title: tableColumnHeader("Deal Status"),
          width: "10%",
          key: "active",
          dataIndex: "lenderActive",
          render: (record: boolean) =>
            !isNil(record) ? (
              renderInstitutionTransactionStatus(record)
            ) : (
              <span className={"text-muted"}>-</span>
            ),
          className: "group-hover:bg-blue-50 min-w-[110px]",
          sorter: (a: CrmDealActivity, b: CrmDealActivity) =>
            columnSort(
              a.lenderActive ? 1 : 0,
              b.lenderActive ? 1 : 0,
              "NUMERIC"
            ),
        },
      ]
    : []),
  ...(showUserStatus
    ? [
        {
          title: tableColumnHeader("User Status"),
          width: "10%",
          key: "active",
          dataIndex: "userActive",
          render: (record: boolean) =>
            !isNil(record) ? (
              renderInstitutionTransactionStatus(record)
            ) : (
              <span className={"text-muted"}>-</span>
            ),
          className: "group-hover:bg-blue-50 min-w-[110px]",
          sorter: (a: CrmDealActivity, b: CrmDealActivity) =>
            columnSort(a.userActive ? 1 : 0, b.userActive ? 1 : 0, "NUMERIC"),
        },
      ]
    : []),
  ...(showTransactionStatus
    ? [
        {
          title: tableColumnHeader("Lender Status"),
          width: "10%",
          key: "tags",
          dataIndex: "elementTagDTOs",
          render: (record: TagType[]) =>
            isEmptyOrNil(record) ? (
              <span className={"text-muted"}>-</span>
            ) : (
              record?.map((value, i) => <ElementTag key={i} tag={value} />)
            ),
          className: "group-hover:bg-blue-50 min-w-[110px]",
          sorter: (a: CrmDealActivity, b: CrmDealActivity) =>
            columnSort(
              a.elementTagDTOs?.[0]?.label,
              b.elementTagDTOs?.[0]?.label,
              "TEXT"
            ),
        },
      ]
    : []),
];

export const DEFAULT_CRM_ANALYTICS_FILTERS: CRMFilterType = {
  from: moment()
    .set("year", 2020)
    .startOf("year")
    .format("YYYY-MM-DD")
    .toString(),
  to: moment().format("YYYY-MM-DD").toString(),
  fxRateTargetCurrency: AnalyticsCurrencyType.USD,
  fxRateDate: moment().format("YYYY-MM-DD").toString(),
};

export const parseParams = (filters: CRMFilterType): string => {
  return new URLSearchParams({
    from: filters.from ?? "",
    to: filters.to ?? "",
    fxRateTargetCurrency: filters.fxRateTargetCurrency ?? "",
    fxRateDate: filters.fxRateDate ?? "",
    ...(filters?.crmElementType
      ? { crmElementType: filters.crmElementType }
      : {}),
  }).toString();
};

export const parseFilters = (search: string): CRMFilterType => ({
  from: moment(
    new URLSearchParams(search).get("from") ??
      DEFAULT_CRM_ANALYTICS_FILTERS.from
  )
    .format("YYYY-MM-DD")
    .toString(),
  to: moment(
    new URLSearchParams(search).get("to") ?? DEFAULT_CRM_ANALYTICS_FILTERS.to
  )
    .format("YYYY-MM-DD")
    .toString(),
  fxRateTargetCurrency:
    (new URLSearchParams(search).get(
      "fxRateTargetCurrency"
    ) as AnalyticsCurrencyType) ??
    DEFAULT_CRM_ANALYTICS_FILTERS.fxRateTargetCurrency,
  fxRateDate: moment(
    new URLSearchParams(search).get("fxRateDate") ??
      DEFAULT_CRM_ANALYTICS_FILTERS.fxRateDate
  )
    .format("YYYY-MM-DD")
    .toString(),
  crmElementType:
    (new URLSearchParams(search).get("crmElementType") as ElementType) ??
    undefined,
});

export const getModule: getModuleType = (user) => {
  if (isCRM(user)) {
    return ElementType.CRM;
  } else if (isSponsorCoverage(user)) {
    return ElementType.SPONSOR_COVERAGE;
  } else {
    return null;
  }
};

export const renderData: renderDataType = (user) => (crmView, sponsorView) => {
  const moduleType = getModule(user);
  if (equals(moduleType, ElementType.CRM)) {
    return crmView;
  } else if (equals(moduleType, ElementType.SPONSOR_COVERAGE)) {
    return sponsorView;
  } else return "";
};

export const isCRM: isSponsorCoverageType = (user) =>
  equals(getModuleName(location), ElementType.CRM) &&
  checkElementViewPermission(
    ElementType.CRM,
    user?.elementPermissions || [],
    [PermissionType.VIEW_CRM],
    true
  );

export const isSponsorCoverage: isSponsorCoverageType = (user) =>
  equals(getModuleName(location), ElementType.SPONSOR_COVERAGE) &&
  checkElementViewPermission(
    ElementType.SPONSOR_COVERAGE,
    user?.elementPermissions || [],
    [PermissionType.VIEW_SPONSOR_COVERAGE],
    true
  );

export const getRelationshipModules: hasRelationshipModuleType = (
  elementPermissions,
  modules
) => {
  const accessModules: RelationshipModuleType[] = [];
  if (valOrDefault(false, modules?.crm)) {
    if (
      checkElementViewPermission(
        ElementType.CRM,
        elementPermissions || [],
        [PermissionType.VIEW_CRM],
        true
      )
    )
      accessModules.push(ElementType.CRM);
  }
  if (valOrDefault(false, modules?.sponsorCoverage)) {
    if (
      checkElementViewPermission(
        ElementType.SPONSOR_COVERAGE,
        elementPermissions || [],
        [PermissionType.VIEW_SPONSOR_COVERAGE],
        true
      )
    )
      accessModules.push(ElementType.SPONSOR_COVERAGE);
  }
  return accessModules;
};

export const parseFiltersAndNavigate: parseFiltersAndNavigateType = (
  relationshipId,
  crmMenu,
  user,
  preferences,
  filters
) => {
  const params = new URLSearchParams(filters ? parseParams(filters) : "");
  const modules = getRelationshipModules(
    user?.elementPermissions ?? [],
    preferences
  );

  if (isEmpty(modules)) {
    return "/";
  } else {
    const currentModule = params.get("crmElementType");
    if (currentModule && modules.includes(currentModule as any)) {
      params.set("crmElementType", currentModule);
    } else {
      params.set("crmElementType", pathOr("", [0], modules));
    }

    return {
      pathname: `/relationship/${relationshipId}/${crmMenu ?? "profile"}`,
      search: params.toString(),
    };
  }
};

export const showPercentage: ShowPercentage = (
  val = 0,
  total = 1,
  threshold = 0.1
) => {
  const v = (val / total) * 100;
  if (v === 0) {
    return <span className={"text-muted text-xs"}>0%</span>;
  } else if (v <= threshold && v > 0) {
    return (
      <div className={"text-muted"}>
        &le;&nbsp;
        <span className={" text-xs"}>{threshold}%</span>
      </div>
    );
  } else {
    return v.toFixed(2) + "%";
  }
};

export type columnSortType = (
  a: string | number,
  b: string | number,
  type: "TEXT" | "DATE" | "NUMERIC"
) => number;

export type RelationshipModuleType =
  | ElementType.CRM
  | ElementType.SPONSOR_COVERAGE
  | null;

export type ParamsType = {
  id: string;
  crmMenu: string;
};
export type fetchWithIdAndParamsType = (id: string, params: string) => void;
type isSponsorCoverageType = (user: UserType | null) => boolean;
type getModuleNameType = (location: Location) => RelationshipModuleType;
type renderDataType = (
  user: UserType | null
) => (crmView: string, sponsorView: string) => string;
type getModuleType = (user: UserType | null) => RelationshipModuleType;
type hasRelationshipModuleType = (
  user: ElementPermissionsType[],
  modules: CompanyModuleType | null
) => RelationshipModuleType[];
type parseFiltersAndNavigateType = (
  relationshipId: string,
  crmMenu: string,
  user: UserType | null,
  preferences: CompanyModuleType | null,
  filtes: CRMFilterType
) => Path | LocationDescriptor;
type ShowPercentage = (
  val?: number,
  total?: number,
  threshold?: number
) => ReactNode;
