import React, { FC, useEffect } from "react";
import Countdown from "antd/es/statistic/Countdown";
import { useHistory } from "react-router";
import { TRANSACTIONS_PAGE_URL } from "../utils/redirect";
import { Empty, Result } from "antd";
import { usePageTitle } from "../customHooks/usePageTitle";

export const InternalPageNotFound: FC<InternalPageNotFoundType> = function ({
  url,
}) {
  usePageTitle("Page Not Found");

  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      history.replace(url ?? TRANSACTIONS_PAGE_URL);
    }, 5000);
    return (): void => clearTimeout(timer);
  }, []);

  return (
    <div
      className={
        "my-auto h-full flex items-center flex-col justify-center gap-4"
      }
    >
      <div className={"text-center text-gray-500 text-8xl"}>404</div>
      <Result.PRESENTED_IMAGE_404 />
      <div className={"text-gray-500 text-medium text-2xl"}>Page not found</div>
      <div className={"text-center text-gray-500"}>
        <span className={"text-xs"}>Redirecting in </span>
        <div className={"inline-block"}>
          <Countdown
            valueStyle={{ fontSize: "0.75rem", fontWeight: "bold" }}
            value={Date.now() + 6000}
            format="s"
          />
        </div>
      </div>
    </div>
  );
};

type InternalPageNotFoundType = {
  url?: string;
};
