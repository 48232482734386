import React from "react";
import { App } from "./App";
import "antd/dist/antd.less";
import "./styles/tailwind.css";

import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);
