import React, { FC } from "react";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { formItemRequiredRule } from "../../utils/formUtils";

export const NewSectionForm: FC<CommentInputFormType> = ({
  onSubmit,
  formStatus = FormStatusType.NONE,
}: CommentInputFormType) => {
  const [form] = useForm();

  return (
    <Form
      form={form}
      className={`flex flex-col items-start flex items-stretch`}
      layout={"vertical"}
      onFinish={(val): void => {
        onSubmit(val);
        form.resetFields();
      }}
    >
      <Form.Item
        name={"sectionHeading"}
        style={{ width: "100%" }}
        rules={[formItemRequiredRule]}
        label={"Section Name"}
      >
        <Input
          disabled={formStatus === FormStatusType.LOADING}
          placeholder={"Section Name"}
          autoFocus={true}
        />
      </Form.Item>

      <Form.Item>
        <div className={"flex flex-row gap-y-1 w-full gap-x-2"}>
          <Button
            loading={formStatus === FormStatusType.LOADING}
            className={
              "bg-white border hover:border-primary hover:text-primary ml-auto"
            }
            onClick={(): void => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
          <Button
            loading={formStatus === FormStatusType.LOADING}
            className={"bg-primary hover:bg-hover text-white border-0"}
            htmlType={"submit"}
          >
            Add Section
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

type CommentInputFormType = {
  onSubmit: (o: { sectionHeading: string }) => void;
  formStatus?: FormStatusType;
  message?: string;
};

enum FormStatusType {
  LOADING,
  SUCCESS,
  FAILED,
  NONE,
}
