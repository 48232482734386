import React, { FC, ReactElement } from "react";
import { Button, Form, FormInstance } from "antd";
import { CompanyType } from "../../utils/types";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { FuzzySearchSelect } from "../general/FuzzySearchSelect";
import { pathOr } from "ramda";
import { getPopupContainerForSelect } from "../../utils/container";

export const AddInstitutionForm: FC<AddInstitutionFormType> = ({
  onSubmit = (values): void => {
    return;
  },
  setNewInstitutionInput,
  institutions,
  form,
  close,
}: AddInstitutionFormType) => {
  return (
    <Form
      form={form}
      onFinish={(values): void => {
        onSubmit(values);
        form.resetFields();
      }}
      layout={"vertical"}
      className={"pb-2"}
    >
      <Form.Item
        className={"mb-1"}
        name={"companyDTO"}
        label={"Select Institution(s)"}
        rules={[{ required: true, message: "Select at least one Institution" }]}
      >
        <FuzzySearchSelect<CompanyType>
          items={institutions}
          getOptionKey={pathOr("", ["id"])}
          getOptionLabel={pathOr("", ["name"])}
          getOptionValue={(item) => (item ? JSON.stringify(item) : "")}
          getAbbrevationPath={pathOr("", ["name"])}
          searchPaths={["name"]}
          notFoundContent={"No Institutions Available"}
          getPopupContainer={getPopupContainerForSelect}
          placeholder={"Name"}
          mode={"multiple"}
          showArrow={true}
          autoFocus={true}
          maxTagCount={"responsive"}
          dropdownRender={(menu): ReactElement => {
            return (
              <div className={"p-0 m-0"}>
                {menu}
                <div
                  onMouseDown={(e): void => {
                    e.preventDefault();
                    setNewInstitutionInput && setNewInstitutionInput(true);
                  }}
                  className={`${PRIMARY_BUTTON_STYLE} p-2`}
                >
                  Create New Institution
                </div>
              </div>
            );
          }}
          dropdownClassName={"pb-0 mb-0"}
        />
      </Form.Item>
      <div className={"mb-0 flex flex-row w-full gap-x-2 mt-5"}>
        <Button
          className={
            "bg-white text-gray-400 hover:border-primary hover:text-primary rounded-l-none ml-auto"
          }
          onClick={(): void => {
            form.resetFields();
            close();
          }}
        >
          Clear
        </Button>
        <Button
          className={
            "bg-primary hover:bg-hover text-white border-0 rounded-r-none"
          }
          onClick={(): void => {
            form.submit();
          }}
        >
          Add Institution(s)
        </Button>
      </div>
    </Form>
  );
};

type AddInstitutionFormType = {
  onSubmit: (data: { companyDTO: Array<string> }) => void;
  setNewInstitutionInput?: (o: boolean) => void;
  institutions: CompanyType[];
  close: () => void;
  form: FormInstance;
};
