import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import { Card, message, Spin } from "antd";
import { getNotifications, markRead } from "../services/services";
import { NotificationActivityType, NotificationType } from "../utils/types";
import moment from "moment/moment";
import Title from "antd/es/typography/Title";
import {
  CELL_STYLE,
  getActivityTypeSubHeader,
  getNotificationIcon,
} from "../utils/notifications";
import { ResponseType } from "../utils/uiTypes";
import { useHistory } from "react-router";
import { usePageTitle } from "../customHooks/usePageTitle";
import { NotificationActivityTitleEnumType } from "../utils/enums";
import { descend, equals, or, path, prop, sortWith } from "ramda";
import { useIntersectionView } from "../customHooks/useIntersectionView";
import { LoadingOutlined } from "@ant-design/icons";

export const Notifications: FC = function () {
  usePageTitle("Notifications");
  const history = useHistory();
  const ref = useRef();

  const [notifications, setNotifications] = useState<
    Array<NotificationActivityType>
  >([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [, maxIndex] = useIntersectionView(
    ".termgrid-notifications",
    ref.current,
    [ref, notifications]
  );

  const fetchNotifications = (offset: number): void => {
    setLoading(true);

    getNotifications({
      segments: {
        limit: 20,
        offset: offset,
      },
    })
      .then(({ data }: ResponseType<NotificationType>) => {
        setNotifications((notifications) =>
          sortWith(
            [
              descend<NotificationActivityType>(prop("createDate")),
              descend<NotificationActivityType>(prop("transactionName")),
            ],
            [...notifications, ...(data?.activityDTOs ?? [])] ?? []
          )
        );
      })
      .catch(() => message.error("Error getting notifications!"))
      .then(() => setLoading(false));
  };

  const initiateMarkRead = (): void => {
    markRead({}).then().catch(console.error);
  };

  useEffect(() => {
    if (or(equals(maxIndex, 0), equals(0, (maxIndex + 1) % 20))) {
      fetchNotifications(Math.floor((maxIndex + 1) / 20));
    }
  }, [maxIndex]);

  useEffect(() => initiateMarkRead(), []);

  return (
    <div
      className={
        "p-6 relative max-h-full w-full h-screen bg-gray-100 overflow-y-auto flex flex-col h-screen"
      }
    >
      <Title level={3} className="opacity-90">
        Activities
      </Title>
      {notifications.length === 0 && !loading ? (
        <Card bodyStyle={{ padding: "100px 0" }}>
          <img
            className={"mx-auto"}
            src="../images/icons/EmptyNotification.svg"
            alt="Empty Notification Icon"
            width={"80px"}
          />
          <p className="opacity-40 text-sm text-center mb-0">
            There are no notifications yet!
          </p>
        </Card>
      ) : (
        <div
          className={"flex-initial h-full max-h-full overflow-y-auto relative"}
          ref={ref.current}
        >
          {notifications.map((value, index) => {
            const isSame =
              value.petransactionId ===
              path([index - 1, "petransactionId"], notifications);
            return (
              <div
                data-rowindex={index}
                key={index}
                className={`termgrid-notifications ${
                  !isSame && index !== 0 ? "first:mt-0 mt-6" : ""
                }`}
              >
                {!isSame && (
                  <div className="sticky top-0 mb-1 font-bold text-xs opacity-40 uppercase ">
                    {value.transactionName}
                  </div>
                )}
                <Card
                  bodyStyle={{ padding: "10px" }}
                  className={
                    "border-b cursor-pointer hover:bg-blue-50 hover:shadow"
                  }
                  bordered={false}
                  onClick={(): void => {
                    history.push(
                      "/transactions/" +
                        value.petransactionId +
                        "/" +
                        NotificationActivityTitleEnumType[value.activityType]
                          ?.replaceAll(" ", "")
                          .toLowerCase(),
                      "_self"
                    );
                  }}
                >
                  <div className={"grid grid-cols-12"}>
                    <div className={"col-span-2"}>
                      <p className="m-0">
                        {(index === 0 ||
                          notifications[index - 1].activityType !==
                            notifications[index].activityType ||
                          !isSame) &&
                          NotificationActivityTitleEnumType[value.activityType]}
                      </p>
                    </div>
                    <div className={"col-span-8"}>
                      {getNotificationIcon(
                        value.activityType,
                        value.dataMap.CREATOR_NAME,
                        value.dataMap.DETAILS,
                        value.dataMap.USER_NAME,
                        value.dataMap.TITLE,
                        value.dataMap.PROPERTY_ID,
                        value.dataMap.LENDER_TITLE
                      )}
                      <p className="m-0 ml-8">{value.message}</p>
                      <span className="ml-8 opacity-50 text-xs">
                        {getActivityTypeSubHeader(
                          value.activityType,
                          value.dataMap.LENDER_TITLE,
                          value.dataMap.CREATOR_NAME,
                          value.dataMap.COMPANY,
                          value.createDate,
                          value.dataMap.SECTION_NAME
                        )}
                      </span>
                    </div>
                    <div className={"col-span-2"}>
                      <div key={value.id} className="text-right">
                        <span className="opacity-50 text-xs">
                          {moment(value.createDate).format(
                            "DD.MMM.YYYY - HH:mma"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            );
          })}
          {loading && (
            <Card>
              <div className={"flex w-full items-center justify-center"}>
                <Spin
                  spinning={loading}
                  indicator={<LoadingOutlined spin={true} />}
                />
              </div>
            </Card>
          )}
        </div>
      )}
    </div>
  );
};

type columnType = {
  key: string;
  className: string;
  render: (
    text: NotificationActivityType,
    item: NotificationActivityType,
    index: number
  ) => ReactElement;
};
type activityColumnsType = (
  value: NotificationActivityType[]
) => Array<columnType>;
