import React, {
  DragEvent,
  FC,
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  SearchDataMap,
  WebFormDetailsGroupedResponseType,
} from "../../../utils/types";
import { Table, Tag } from "antd";
import { tableColumnHeader } from "../../../utils/componentUtils";
import { any, compose, isEmpty, keys, length, path, pipe } from "ramda";
import { convertDateToFormat } from "../../../utils/moment";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import { PCActionsType } from "./PrecedentDetails";
import { ColumnsType } from "antd/es/table";
import PrecedentSearchCell from "./PrecedentSearchCell";

const PrecedentTermsheetTable: FC<PrecedentTermsheetTableType> = ({
  termsheet,
  draggable,
  initialWidthBreak,
  actions,
}) => {
  const {
    filters = [],
    extendedTermsheet = false,
    redline = false,
    showResponses = true,
  } = actions;

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const ref = useRef<HTMLDivElement>(null);

  const [drag, setDrag] = useState(false);
  const [width, setWidth] = useState(windowWidth / initialWidthBreak);

  const [initialPos, setInitialPos] = useState<number | null>(null);
  const [initialSize, setInitialSize] = useState<number | null>(null);

  const initial = (e: DragEvent<HTMLDivElement>): void => {
    const resizable = ref.current;
    e.dataTransfer.setDragImage(new Image(0, 0), 0, 0);

    setInitialPos(e.clientX);
    setInitialSize(resizable?.offsetWidth ?? 0);
    setDrag(true);
  };

  const resize = (e: DragEvent<HTMLDivElement>) => {
    setWidth((initialSize ?? 0) + (e?.clientX - (initialPos ?? 0)));
  };

  const isFilteredValue = useCallback(
    (metric: string): boolean => {
      if (!isEmpty(filters)) {
        return any(
          (searchValue) =>
            metric.toLowerCase().includes(searchValue.toLowerCase()),
          filters
        );
      } else return true;
    },
    [filters]
  );

  const getRowClassName = useCallback(
    (metric: string, extended?: boolean): string => {
      const isFiltered = isFilteredValue(metric);
      if (extended && isFiltered && !isEmpty(filters)) {
        return "!bg-success-light !hover:bg-success";
      } else if (!extended && isFiltered && !isEmpty(filters)) {
        return "";
      } else if (!extended && !isFiltered && !isEmpty(filters)) {
        return "!hidden";
      } else {
        return "";
      }
    },
    [filters]
  );

  const numLenders = pipe(
    path([0, "lenderDataMap"]),
    keys,
    length
  )(termsheet?.termSheetData) as number;

  const columnWidth = useMemo(() => {
    return `${(100 / ((showResponses ? numLenders : 0) + 2)).toString()}%`;
  }, [showResponses, numLenders]);

  const dealColumns: ColumnsType<SearchDataMap> = [
    {
      title: tableColumnHeader("No.", "!capitalize"),
      dataIndex: "",
      align: "center",
      width: "5%",
      className: "group-hover:bg-blue-50 bg-gray-200 px-1 transition-none",
      render: (_: string, __, index) => (
        <div className={"px-2 py-0.5 whitespace-pre-wrap"}>
          {tableColumnHeader((index + 1).toString())}
        </div>
      ),
    },
    {
      title: tableColumnHeader(`Deal Team`, "p-2 bg-gray-200"),
      className:
        "p-0 group-hover:bg-blue-50 bg-transparent flex-initial items-start justify-start",
      children: [
        {
          title: tableColumnHeader("Bid Metric", "p-2 bg-gray-200"),
          dataIndex: "metric",
          width: columnWidth,
          className:
            "p-0 group-hover:bg-blue-50 bg-transparent flex-initial items-start justify-start",
          render: (value = "") => <PrecedentSearchCell text={value?.trim()} />,
        },
        {
          title: tableColumnHeader(
            `${termsheet.dealTeamName} Ask`,
            "p-2 bg-gray-200"
          ),
          width: columnWidth,
          dataIndex: "ask",
          className:
            "p-0 group-hover:bg-blue-50 bg-transparent flex-initial items-start justify-start",
          render: (value = "") => <PrecedentSearchCell text={value?.trim()} />,
        },
      ],
    },
  ];

  const responseColumns = {
    title: tableColumnHeader(`Responses`, "p-2 bg-gray-200"),
    className:
      "p-0 group-hover:bg-blue-50 bg-transparent flex-initial items-start justify-start",
    children: keys(
      path([0, "lenderDataMap"], termsheet.termSheetData ?? [])
    ).map((val) => ({
      title: tableColumnHeader(`${val}`, "p-2 bg-gray-200"),
      dataIndex: ["lenderDataMap", val],
      width: columnWidth,
      className:
        "p-0 group-hover:bg-blue-50 bg-transparent flex-initial items-start justify-start",
      render: (value = "", data: SearchDataMap) => {
        return (
          <PrecedentSearchCell
            text={value?.trim()}
            compareText={data?.ask}
            showRedLine={redline}
          />
        );
      },
    })),
  };

  const columns = showResponses
    ? [...dealColumns, responseColumns]
    : dealColumns;

  return (
    <div
      style={{ width }}
      className={
        "relative flex h-full hide-scrollbar max-w-full bg-white transition-none"
      }
    >
      <Table
        ref={ref}
        style={{ width: width - 2 }}
        className={"flex-1 border-0 scroll-smooth"}
        showHeader={true}
        title={(): ReactNode => (
          <div
            className={
              "w-full flex flex-row items-center justify-between overflow-hidden overflow-x-scroll hide-scrollbar"
            }
          >
            <div className={"inline-flex items-center gap-x-2"}>
              {tableColumnHeader(termsheet.transactionName)}
              {termsheet?.isOffline && <Tag color={"gold"}>OFFLINE</Tag>}
            </div>
            <div>{tableColumnHeader(`${termsheet.termSheetName}`)}</div>
            <div>
              {tableColumnHeader(
                convertDateToFormat(termsheet.createdDate, "DD MMM YYYY", false)
              )}
            </div>
          </div>
        )}
        bordered={true}
        locale={{ emptyText: "No Matching Data" }}
        columns={columns}
        dataSource={termsheet.termSheetData}
        pagination={false}
        scroll={{ y: windowHeight - 250, x: true }}
        size={"small"}
        rowClassName={({ metric }): string =>
          `${getRowClassName(metric, extendedTermsheet)} group hover:bg-blue-50`
        }
        rowKey={"webFormConfigId"}
      />

      {draggable && (
        <div
          draggable="true"
          onDragStart={initial}
          onDrag={resize}
          onDragEnd={(): void => setDrag(false)}
          className={`transition-none bg-blue-300 focus:bg-blue-300 w-px pr-1.5 hover:cursor-ew-resize h-8 hover:h-full ${
            drag && "bg-blue-400 cursor-ew-resize !h-full"
          }`}
        />
      )}
    </div>
  );
};

type PrecedentTermsheetTableType = {
  termsheet: WebFormDetailsGroupedResponseType;
  draggable: boolean;
  initialWidthBreak: number;
  actions: PCActionsType;
};

export default memo(PrecedentTermsheetTable);
