import React, { FC, memo, ReactNode } from "react";
import { ColumnResizeBlock, onColumnResize } from "./ColumnResizeBlock";
import { HEADER_CLASSNAME } from "../utils/css";

const PSHeader: FC<tableHeaderCellType> = ({
  children,
  resizeProps,
  className = "",
  colSpan = 1,
  rowSpan = 1,
}) => {
  return (
    <th
      scope={"col"}
      colSpan={colSpan}
      rowSpan={rowSpan}
      className={`${className} p-0 select-none border-r border-r-slate-200 truncate ${HEADER_CLASSNAME}`}
    >
      <div className={"flex items-stretch justify-between"}>
        <div className={"p-2 w-full text-left"}>{children}</div>
        {resizeProps && <ColumnResizeBlock {...resizeProps} />}
      </div>
    </th>
  );
};

type tableHeaderCellType = {
  children?: ReactNode;
  className?: string;
  colSpan?: number;
  rowSpan?: number;
  resizeProps?: {
    isResizing: boolean;
    id: string;
    onResize: onColumnResize;
    startWidth: number;
  };
};

export default memo(PSHeader);
