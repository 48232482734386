import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Tooltip } from "antd";

export const TextOverFlowHandle: FC<TextOverFlowPropsType> = function ({
  text,
  tooltip,
}: TextOverFlowPropsType) {
  const textRef = useRef<HTMLInputElement | null>(null);
  const [isTooltip, setIsTooltip] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (textRef && textRef.current && tooltip === undefined) {
      setIsTooltip(textRef.current.clientWidth < textRef.current.scrollWidth);
    }
  }, [textRef, tooltip]);

  useEffect(() => {
    if (tooltip !== undefined) setIsTooltip(tooltip);
  }, [tooltip]);

  return (
    <div
      ref={textRef}
      className={`whitespace-nowrap w-full text-ellipsis overflow-x-hidden`}
    >
      <Tooltip title={isTooltip ? text : ""} placement="topLeft">
        {text}
      </Tooltip>
    </div>
  );
};

type TextOverFlowPropsType = {
  text: string;
  tooltip?: boolean;
};
