import React, { FC, ReactNode, useEffect, useState } from "react";
import { Layout, Modal } from "antd";
import { SideNav } from "./SideNav";
import { useHistory } from "react-router";

const { Content } = Layout;

export const MainLayout: FC<MainLayoutType> = function ({
  children,
}: MainLayoutType) {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const onCollapse = (o: boolean): void => {
    setCollapsed(o);
  };

  useEffect(() => {
    history.listen(() => Modal.destroyAll());
  }, []);

  return (
    <div className={"h-screen text-dark font-Roboto"}>
      <Layout className={"h-screen"}>
        <SideNav collapsed={collapsed} onCollapse={onCollapse} />
        <Layout className={"bg-gray-100"}>
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </div>
  );
};

type MainLayoutType = {
  children: ReactNode;
};
