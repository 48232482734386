import React, { FC, useState } from "react";
import { TagType } from "../../../utils/types";
import { Button, Spin, Tag, Tooltip } from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { TooltipPlacement } from "antd/es/tooltip";

export const ElementTag: FC<ElementTagType> = ({
  tag,
  onDelete,
  loading,
  tooltipPlacement,
}) => {
  return (
    <Tooltip title={tag?.description ?? ""} placement={tooltipPlacement}>
      <Tag
        className={
          "relative overflow-hidden px-0 group transition transition-all ease-in duration-75"
        }
        key={tag.id}
        style={{
          borderWidth: 1,
          borderColor: tag.color,
          color: tag.color,
        }}
      >
        <>
          <div
            className={"absolute w-full h-full"}
            style={{
              backgroundColor: tag.color,
              opacity: 0.1,
            }}
          />
          <span className={"px-2"}>{tag.label}</span>
          {!loading && onDelete && (
            <Button
              icon={<DeleteOutlined />}
              className={"pr-2 ml-0 inline-block py-0 text-xxs rounded-full"}
              type={"text"}
              onClick={(e) => {
                onDelete(tag.id);
              }}
              style={{ color: tag.color }}
              size={"small"}
            />
          )}
          {loading && (
            <Spin
              indicator={<LoadingOutlined style={{ color: tag.color }} />}
              className={"pr-2"}
              size={"small"}
            />
          )}
        </>
      </Tag>
    </Tooltip>
  );
};

type ElementTagType = {
  tag: TagType;
  onDelete?: ((tagId: string) => void) | null;
  loading?: boolean;
  tooltipPlacement?: TooltipPlacement;
};
