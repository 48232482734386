import React, { FC, ReactElement, ReactNode, useContext } from "react";
import { MainLayout } from "../layout/MainLayout";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { DefaultLayout } from "../layout/DefaultLayout";
import { UserContext } from "../../context/UserContext";
import { Redirect } from "react-router";
import {
  AUTH_REDIRECT_ROUTES,
  AUTH_ROUTES,
  RedirectRouteType,
  RouteType,
  UNAUTH_ROUTES,
} from "../../utils/routes";
import { ConfigurationProvider } from "../../context/configs/ConfigurationProvider";

const getRoutes = (routes: RouteType[]): ReactElement[] =>
  routes.map(({ path, exact, Component }) => (
    <Route key={Array.isArray(path) ? path[0] : path} exact={exact} path={path}>
      <Component />
    </Route>
  ));

const getRedirectRoutes = (routes: RedirectRouteType[]): ReactElement[] =>
  routes.map(({ redirectTo, path }) => (
    <Route
      key={redirectTo}
      exact={true}
      path={path}
      render={(): ReactNode => <Redirect to={redirectTo} />}
    />
  ));

export const Routes: FC = function () {
  const { user, companyModulePreference } = useContext(UserContext);

  return (
    <Router>
      {user && (user.sms2FaFlowCompleted || !user.sms2FaEnabled) ? (
        <ConfigurationProvider>
          <MainLayout>
            {companyModulePreference && (
              <Switch>
                {getRedirectRoutes(AUTH_REDIRECT_ROUTES)}
                {getRoutes(
                  AUTH_ROUTES(user, companyModulePreference).filter(
                    ({ permission }) => permission
                  )
                )}
              </Switch>
            )}
          </MainLayout>
        </ConfigurationProvider>
      ) : (
        <DefaultLayout>
          <Switch>{getRoutes(UNAUTH_ROUTES)}</Switch>
        </DefaultLayout>
      )}
    </Router>
  );
};
