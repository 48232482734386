import React, { FC, useEffect, useState } from "react";
import { Alert, AutoComplete, Button, Form, Input } from "antd";
import { LenderType, TeamMemberType, UserType } from "../../utils/types";
import { CustomAvatar } from "../general/CustomAvatar";
import { InvitationStatusType } from "../../utils/enums";
import {
  arrayToMapConversionWithNestedPath,
  valOrDefault,
} from "../../utils/utils";
import {
  formItemRequiredRule,
  namePatternRule,
  parsePhoneNumber,
  phoneNumberPatternRule,
  reverseParsePhoneNumber,
} from "../../utils/formUtils";
import { PhoneNumberInput } from "../general/PhoneNumberInput";
import { path } from "ramda";

export const InstitutionUserForm: FC<InstitutionUserFormType> = ({
  onSubmit,
  options,
  onUpdate,
  lender,
  formStatus = FormStatusType.NONE,
  message,
}: InstitutionUserFormType) => {
  const [form] = Form.useForm();

  const [teamRecord, setTeamRecord] = useState<Record<string, UserType>>({});
  const [selection, setSelection] = useState<UserType | null>(null);

  useEffect(() => {
    if (options)
      setTeamRecord(
        arrayToMapConversionWithNestedPath(options, "userId", ["userDTO"])
      );
  }, [options]);

  return (
    <Form
      className={`flex flex-col items-start flex items-stretch`}
      onFinish={(values): void => {
        onSubmit(
          selection?.email === form.getFieldValue("email")
            ? selection
            : {
                ...values,
                phoneNumber: parsePhoneNumber(values.phoneNumber),
              }
        );
      }}
      form={form}
      layout={"vertical"}
    >
      <Form.Item
        name={"firstName"}
        label={"First Name"}
        rules={[formItemRequiredRule, namePatternRule]}
      >
        <AutoComplete
          notFoundContent={"No Users"}
          disabled={formStatus === FormStatusType.LOADING}
          autoFocus={true}
          onFocus={(): void => {
            onUpdate && lender && onUpdate(lender);
          }}
          filterOption={(a, b): boolean => {
            return b?.title?.toLowerCase()?.indexOf(a?.toLowerCase()) >= 0;
          }}
          placeholder={"First Name"}
          showSearch={true}
          onSelect={(val: string): void => {
            setSelection(teamRecord[val]);
            form.setFieldsValue({
              ...teamRecord[val],
              phoneNumber: reverseParsePhoneNumber(
                valOrDefault("", path<UserType>([val], teamRecord)?.phoneNumber)
              ),
            });
          }}
          dropdownStyle={{
            overflow: "visible",
          }}
        >
          {options?.map(({ userDTO }: TeamMemberType) => (
            <AutoComplete.Option
              key={userDTO.userId}
              title={
                userDTO?.firstName +
                " " +
                userDTO?.lastName +
                " " +
                userDTO?.email
              }
              value={userDTO.userId}
            >
              <div className={"flex flex-row items-start py-1"}>
                <CustomAvatar
                  data={userDTO?.firstName[0] + userDTO?.lastName[0]}
                  color={userDTO?.firstName[0]}
                  size={"small"}
                />
                <div className={"leading-none flex flex-col"}>
                  {userDTO?.firstName} {userDTO?.lastName}
                  <span className={"text-secondary text-xs"}>
                    {userDTO?.email}
                  </span>
                </div>
              </div>
            </AutoComplete.Option>
          ))}
        </AutoComplete>
      </Form.Item>

      <Form.Item
        name={"lastName"}
        label={"Last Name"}
        rules={[formItemRequiredRule, namePatternRule]}
      >
        <Input
          disabled={selection !== null || formStatus === FormStatusType.LOADING}
          placeholder={"Last Name"}
        />
      </Form.Item>

      <Form.Item name={"email"} label={"Email"} rules={[formItemRequiredRule]}>
        <Input
          disabled={selection !== null || formStatus === FormStatusType.LOADING}
          placeholder={"Email"}
          type={"email"}
        />
      </Form.Item>

      <Form.Item
        name={"phoneNumber"}
        label={"Phone Number"}
        rules={[phoneNumberPatternRule()]}
        initialValue={{
          short: "GB",
          code: "44",
        }}
      >
        <PhoneNumberInput
          disabled={selection !== null || formStatus === FormStatusType.LOADING}
          placeholder={"Phone Number"}
        />
      </Form.Item>
      {formStatus === FormStatusType.FAILED && (
        <Alert message={message} type={"error"} className={"mb-2"} />
      )}
      <Form.Item>
        <div className={"flex flex-row gap-x-2"}>
          <Button
            disabled={formStatus === FormStatusType.LOADING}
            className={
              "bg-white border hover:border-primary hover:text-primary w-full"
            }
            onClick={(): void => {
              form.resetFields();
              setSelection(null);
            }}
          >
            Clear
          </Button>
          <Button
            className={"bg-primary hover:bg-hover text-white border-0 w-full"}
            htmlType={"submit"}
            disabled={formStatus === FormStatusType.LOADING}
            loading={formStatus === FormStatusType.LOADING}
          >
            Add
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

type InstitutionUserFormType = {
  onSubmit: (user: InstitutionUserType) => void;
  options?: TeamMemberType[];
  onUpdate?: (lender: LenderType) => void;
  lender?: LenderType;
  formStatus?: FormStatusType;
  message?: string;
};

type InstitutionUserType = {
  companyId: string;
  elementId: string;
  elementType: string;
  email: string;
  firstName: string;
  lastName: string;
  invitationStatus: InvitationStatusType;
  peTransactionId: string;
  userId: string;
};
enum FormStatusType {
  LOADING,
  SUCCESS,
  FAILED,
  NONE,
}
