import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { TransactionNotificationType, UserType } from "../../../utils/types";
import {
  assignPermission,
  deleteDealTeamMember,
  getDealTeam,
  getTransactionNotifications,
  invite,
  updateTransactionNotifications,
} from "../../../services/services";
import {
  Button,
  Checkbox,
  message,
  Modal,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  removeItemFromArray,
  updateItemInArray,
  valOrDefault,
} from "../../../utils/utils";
import { fetchWithIdType, ResponseType } from "../../../utils/uiTypes";
import { TransactionContext } from "../../../context/TransactionContext";
import { DealTeamForm } from "../../forms/DealTeamForm";
import {
  ElementType,
  InvitationStatusType,
  PermissionType,
} from "../../../utils/enums";
import { ConfirmDelete } from "../../../utils/confirmationModals";
import { usePageTitle } from "../../../customHooks/usePageTitle";
import {
  invitationStatusTag,
  resendTeamInvite,
  tableColumnHeader,
} from "../../../utils/componentUtils";
import { ACTION_BUTTON_CSS } from "../../../utils/cssConfigs";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import { MailOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { ViewEmail } from "../../general/ViewEmail";
import { UserContext } from "../../../context/UserContext";
import { find, path, pathOr, propEq } from "ramda";

export const ManageDealTeam: FC = function () {
  usePageTitle("Deal Team");

  const { transaction, permissions } = useContext(TransactionContext);
  const { user } = useContext(UserContext);
  const [notifications, setNotifications] = useState<boolean>(true);

  const [addNewMember, setAddNewMember] = useState<boolean>(false);
  const [dealTeam, setDealTeam] = useState<UserType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [formLoading, setFormLoading] = useState<FormLoadingType>({
    message: "",
    status: FormStatusType.NONE,
  });

  const { height: windowHeight } = useWindowDimensions();

  const fetchDealTeam: fetchWithIdType = (id) => {
    setLoading(true);
    getDealTeam({
      segments: {
        id,
      },
      params: {
        isTeamPage: true,
      },
    })
      .then(({ data = [] }: ResponseType<UserType[]>) => {
        setDealTeam(data);
      })
      .then(() => setLoading(false))
      .catch((error: string) => {
        console.error(error);
        message.error(error ? error : "Failed Fetching the Deal Team!");
      });
  };

  const requestAddUser: requestAddUserType = (
    values,
    transactionId,
    dealTeam
  ) => {
    setFormLoading({ message: "", status: FormStatusType.LOADING });
    invite({
      body: JSON.stringify({
        ...values,
        elementId: transactionId,
        peTransactionId: transactionId,
        elementType: ElementType.PETRANSACTION,
        invitationStatus: InvitationStatusType.PENDING,
      }),
    })
      .then(({ data }: ResponseType<UserType>) => {
        // Nothing coming as a response from api
        if (dealTeam) {
          setDealTeam([
            ...dealTeam,
            { ...data, invitationStatus: data?.invitationStatus },
          ]);
        } else
          setDealTeam([{ ...data, invitationStatus: data?.invitationStatus }]);
        message.success("Successfully added user to deal team");
      })
      .then(() => {
        setAddNewMember(false);
        setFormLoading({ message: "", status: FormStatusType.SUCCESS });
      })
      .catch((error: string) => {
        message.error(error ? error : "Error adding user to deal team");
        setFormLoading({
          message: error ? error : "Error adding user to deal team",
          status: FormStatusType.FAILED,
        });
      });
  };

  const requestDeleteMember: requestDeleteMemberType = (
    userId,
    index,
    transactionId,
    dealTeam
  ) => {
    deleteDealTeamMember({
      segments: {
        id: transactionId,
      },
      body: JSON.stringify([userId]),
    })
      .then(() => {
        setDealTeam(removeItemFromArray(index, dealTeam));
      })
      .catch((error: string) => {
        console.error(error);
        message.error(error ? error : "Error Deleting User!");
      });
  };

  const requestPermissionChange: requestPermissionChangeType = (
    user,
    index,
    transactionId,
    dealTeam
  ) => {
    setLoading(true);
    message.loading({
      content: "Updating User Permission",
      key: "userPermission-" + user.userId,
      duration: 0,
    });
    assignPermission({
      segments: {
        id: transactionId,
        type: ElementType.PETRANSACTION,
      },
      body: JSON.stringify([user]),
    })
      .then(({ data = [] }: ResponseType<UserType[]>) => {
        if (data.length > 0)
          setDealTeam(
            updateItemInArray(index, dealTeam, { ...user, ...data[0] })
          );
      })
      .then(() => {
        setLoading(false);
        message.success({
          content: "Updated User Permission",
          key: "userPermission-" + user?.userId,
          duration: 2,
        });
      })
      .catch((error: string) => {
        setLoading(false);
        console.error(error);
        message.error({
          content: error ? error : "Error changing user permissions!",
          key: "userPermission-" + user?.userId,
          duration: 3,
        });
      });
  };

  function renderOptionalValues(value: string): ReactNode {
    return value ? (
      <>{value}</>
    ) : (
      <Tag className={"text-danger bg-transparent border-none p-0"}>
        Not Provided
      </Tag>
    );
  }
  const updateNotification = (e: any) => {
    // setNotifications(e);
    updateTransactionNotifications({
      segments: { peTransactionId: transaction?.peTransactionId },
      params: { enableNotification: e },
    })
      .then(({ data }: ResponseType<TransactionNotificationType>) => {
        setNotifications(data?.enabled);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(error ? error : "Failed Updating the notification!");

        setLoading(false);
      });
  };

  const implicitTag = (status: string): ReactNode => {
    return (
      <Tooltip
        title={"Part of deal because of Deal Execution Admin Permission"}
        key={0}
        className={"uppercase"}
      >
        <Tag color={"cyan"} key={0} className={"uppercase"}>
          {status}
        </Tag>
      </Tooltip>
    );
  };

  const columns: ColumnsType<UserType> = [
    {
      title: tableColumnHeader("First Name"),
      dataIndex: "firstName",
      key: "firstName",
      className: "group-hover:bg-blue-50 min-w-[150px]",
      render: function status(
        name: InvitationStatusType,
        record: any
      ): ReactNode {
        const elementPermission = find(propEq("elementType", "DEAL_EXECUTION"))(
          record.elementPermissions ?? []
        );
        const isImplicit = elementPermission
          ? pathOr(false, ["implicit"], elementPermission)
          : false;
        const adminAccessTag = isImplicit ? implicitTag("ADMIN ACCESS") : null;
        return (
          <>
            {name} &nbsp;
            {adminAccessTag}
          </>
        );
      },
    },
    {
      title: tableColumnHeader("Last Name"),
      dataIndex: "lastName",
      key: "lastName",
      className: "group-hover:bg-blue-50 min-w-[150px]",
    },
    {
      title: tableColumnHeader("Email"),
      dataIndex: "email",
      key: "email",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: ViewEmail,
    },
    {
      title: tableColumnHeader("Phone Number"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "15%",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: renderOptionalValues,
    },
    {
      title: tableColumnHeader("Company"),
      dataIndex: ["companyDTO", "name"],
      key: "company",
      width: "10%",
      className: "group-hover:bg-blue-50 min-w-[150px]",
    },
    {
      title: tableColumnHeader("Status"),
      dataIndex: "invitationStatus",
      key: "invitationStatus",
      width: "10%",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: function status(status: InvitationStatusType): ReactNode {
        return (
          <>
            {invitationStatusTag(
              !status ? InvitationStatusType.PENDING : status
            )}
          </>
        );
      },
    },

    {
      title: tableColumnHeader("Notifications"),
      dataIndex: "invitationStatus",
      key: "invitationStatus",
      align: "center",
      width: "10%",
      render: (record, data) => (
        <>
          {user?.userId === data?.userId && (
            <Switch
              className={notifications ? "bg-success" : "bg-muted"}
              checked={notifications}
              checkedChildren={"ON"}
              unCheckedChildren={"OFF"}
              onChange={(e) => updateNotification(e)}
            />
          )}
        </>
      ),
    },
    {
      title: permissions.includes(PermissionType.ADMIN_PETRANSACTION)
        ? tableColumnHeader("Admin")
        : tableColumnHeader("Role"),
      dataIndex: "isAdmin",
      key: "isAdmin",
      align: "center",
      width: "10%",
      className: "group-hover:bg-blue-50 min-w-[85px]",

      render: function isAdmin(
        isAdmin: boolean,
        user: UserType,
        index: number
      ): ReactNode {
        return permissions.includes(PermissionType.ADMIN_PETRANSACTION) ? (
          <Checkbox
            checked={isAdmin}
            disabled={transaction?.createdByUserDTO?.userId === user?.userId}
            onChange={(): void => {
              transaction &&
                requestPermissionChange(
                  { ...user, isAdmin: !isAdmin },
                  index,
                  transaction?.peTransactionId,
                  dealTeam
                );
            }}
          />
        ) : isAdmin ? (
          "ADMIN"
        ) : (
          "USER"
        );
      },
    },
    {
      title: tableColumnHeader("Actions"),
      dataIndex: "userId",
      key: "operation",
      align: "center",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: function operation(
        userId: string,
        user: UserType,
        index: number
      ): ReactNode {
        return (
          <div className={"flex gap-1 justify-center relative"}>
            {(!user.invitationStatus ||
              user?.invitationStatus === InvitationStatusType.PENDING) && (
              <Tooltip title={"Resend invite"}>
                <Button
                  className={`hover:text-primary opacity-0 group-hover:opacity-100 ${ACTION_BUTTON_CSS}`}
                  onClick={(): void =>
                    resendTeamInvite(
                      user.userId,
                      undefined,
                      transaction?.peTransactionId,
                      undefined,
                      "PETRANSACTION"
                    )
                  }
                >
                  <MailOutlined className={" cursor-pointer"} />
                </Button>
              </Tooltip>
            )}
            {!(user?.userId === transaction?.createdBy) &&
              transaction?.permissionDTO?.permissions?.includes(
                PermissionType.ADMIN_PETRANSACTION
              ) && (
                <>
                  <Button
                    type={"text"}
                    icon={<i className="fas fa-ellipsis-h" />}
                    className={
                      "hidden md:absolute lg:absolute right-0 border-0 flex items-center px-2 text-gray-900 opacity-50 group-hover:opacity-0"
                    }
                  />
                  <Button
                    className={`${ACTION_BUTTON_CSS} hover:text-red-500 group-hover:opacity-100 opacity-0`}
                    icon={<i className="fas fa-trash-alt" />}
                    onClick={(): void => {
                      ConfirmDelete(
                        "Please confirm you want to delete this user",
                        () => {
                          transaction &&
                            requestDeleteMember(
                              user.userId,
                              index,
                              transaction.peTransactionId,
                              dealTeam
                            );
                        },
                        <>
                          Name: {`${user?.firstName} ${user?.lastName}`}
                          <br />
                          Email: {user?.email}
                        </>
                      );
                    }}
                  />
                </>
              )}
          </div>
        );
      },
    },
  ];
  const getNotifications = () => {
    transaction && fetchDealTeam(transaction?.peTransactionId);
    getTransactionNotifications({
      segments: { peTransactionId: transaction?.peTransactionId },
    })
      .then(({ data }: ResponseType<TransactionNotificationType>) => {
        setNotifications(data?.enabled);
      })
      .catch((error: string) => {
        console.error(error);
        setLoading(false);
        message.error(error ? error : "Failed Fetching the Notification!");
      });
  };
  useEffect(() => {
    if (transaction?.peTransactionId) {
      getNotifications();
    }
  }, [transaction?.peTransactionId]);

  return (
    <div
      className={
        "relative mx-auto p-6 h-full max-h-full overflow-y-auto flex flex-col"
      }
    >
      <div className={"flex flex-col sm:flex-row mb-3 p-0 w-full"}>
        <p className={"text-xl font-medium mb-1"}>Deal Team</p>
        {permissions?.includes(PermissionType.ADMIN_PETRANSACTION) && (
          <Button
            icon={<i className="fas fa-user-plus text-white mr-1" />}
            className={
              "border-0 bg-primary hover:bg-hover text-white border-0 sm:ml-auto"
            }
            onClick={(): void => {
              setAddNewMember(true);
            }}
          >
            Add Deal Team Member
          </Button>
        )}
      </div>
      {transaction && dealTeam && (
        <>
          <div className={"h-full"}>
            <Table
              loading={loading}
              columns={
                permissions?.includes(PermissionType.ADMIN_PETRANSACTION)
                  ? columns
                  : columns.filter((col) => col.key != "operation")
              }
              dataSource={dealTeam}
              pagination={false}
              className={"bg-transparent border"}
              rowKey={(user): string => valOrDefault(user?.email, user?.userId)}
              scroll={{ x: true, y: windowHeight - 300 }}
              rowClassName={"group relative hover:bg-blue-50"}
            />
          </div>
        </>
      )}
      {permissions?.includes(PermissionType.ADMIN_PETRANSACTION) &&
        transaction && (
          <Modal
            open={addNewMember}
            destroyOnClose={true}
            title={"Add New Deal Team Member"}
            footer={false}
            onCancel={(): void => setAddNewMember(false)}
          >
            <DealTeamForm
              onCloseModal={(): void => {
                setAddNewMember(false);
              }}
              onSubmit={(values): void => {
                transaction &&
                  requestAddUser(values, transaction.peTransactionId, dealTeam);
              }}
              dealTeam={dealTeam}
              transaction={transaction}
              formStatus={formLoading?.status}
              message={formLoading?.message}
            />
          </Modal>
        )}
    </div>
  );
};

type requestAddUserType = (
  values: UserType,
  transactionId: string,
  dealTeam: UserType[]
) => void;
type requestDeleteMemberType = (
  userId: string,
  index: number,
  transactionId: string,
  dealTeam: UserType[]
) => void;
type requestPermissionChangeType = (
  user: UserType,
  index: number,
  transactionId: string,
  dealTeam: UserType[]
) => void;

enum FormStatusType {
  LOADING,
  SUCCESS,
  FAILED,
  NONE,
}
type FormLoadingType = {
  status: FormStatusType;
  message: string;
};
