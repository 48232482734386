import React, { FC, ReactElement, useEffect, useState } from "react";
import { wipType } from "../../pages/Wip";
import { IndustryType, InstitutionType, UserType } from "../../utils/types";
import {
  Avatar,
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  List,
  Select,
  message,
} from "antd";
import { ResponseType } from "../../utils/uiTypes";
import {
  getIndustries,
  getInstitutions,
  getWip,
  updateWIP,
} from "../../services/services";

import {
  filterOption,
  filterSort,
  getOptionsByKey,
  populateArrayWithPropertyPath,
} from "../../utils/utils";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { stopPropagationOnEnter } from "../../utils/keyboardUtils";
import { getPopupContainerForSelect } from "../../utils/container";
import { priorityLabelType } from "../../utils/enums";
import countries from "../../assets/countryList.json";
import { CustomAvatar } from "../general/CustomAvatar";

export const EditWipDrawer: FC<editWipType> = function ({
  open,
  onCloseDrawer,
  transactionId,
  update,
}: editWipType) {
  const [form] = Form.useForm();

  const [companies, setCompanies] = useState<InstitutionType[]>([]);
  const [industries, setIndustries] = useState<IndustryType[]>([]);
  const [wip, setWip] = useState<wipType | null>(null);
  const [dealTeam, setDealTeam] = useState<UserType[]>([]);

  const fetchWip = (currentTransactionId: string) => {
    getWip({
      segments: { peTransactionId: currentTransactionId },
    })
      .then(({ data }: ResponseType<wipType>) => {
        const {
          projectName,
          nextStep,
          priority,
          dateAdded,
          // industries,
          // sdpAmount,
          // sponsor,
          dealLeader,
          // ebitda,
          targetCompany,
          dealTeam,
          // totalLeverage,
          comments,
          introducer,
          // country,
        } = data;
        const date_formatted = dateAdded ? moment(dateAdded).valueOf() : null;
        const dealTeamNames = dealTeam?.map(
          (item: UserType) => `${item.firstName} ${item.lastName}`
        );

        setWip(data);
        setDealTeam(data.dealTeam);
        form.setFieldsValue({
          projectName,
          nextStep,
          priority: priorityLabelType?.[priority as 1 | 2 | 3],
          dateAdded: date_formatted ? moment(date_formatted, "x") : null,
          industries: populateArrayWithPropertyPath(["id"], industries),
          // sdpAmount,
          // sponsor,
          dealLeader,
          // ebitda,
          targetCompany,
          dealTeam: dealTeamNames,
          // totalLeverage,
          comments,
          introducer,
          // country,
        });
      })
      .catch((error: string) => {
        message.error(error ?? "Error Fetching Industries");
      });
  };

  const fetchIndustries = () => {
    getIndustries({})
      .then(({ data = [] }: ResponseType<IndustryType[]>) => {
        setIndustries(data);
      })
      .catch((error: string) => {
        message.error(error ?? "Error Loading Industries");
      });
  };

  const fetchInstitutions = () => {
    getInstitutions({
      params: { isLite: true },
    })
      .then(({ data = [] }: ResponseType<InstitutionType[]>) => {
        setCompanies(data);
      })
      .catch((error: string) => {
        message.error(error ?? "Error Loading Institutions");
      });
  };

  const submitEditRequest: submitEditRequestType = (values, wip) => {
    message.loading({
      content: "Editing Wip...",
      key: "editWip",
      duration: 0,
    });
    const data = {
      ...wip,
      ...values,
      dateAdded: values.dateAdded
        ? +new Date(values.dateAdded).getTime().toString()
        : null,
      companyId: wip?.companyId,
      peTransactionId: wip?.peTransactionId,
      priority: isNaN(values?.priority ?? 0) ? wip?.priority : values?.priority,
    };

    const { ["dealTeam"]: _, ...rest } = data;

    updateWIP({
      body: JSON.stringify(rest),
      segments: { peTransactionId: transactionId },
    })
      .then(({ data }: ResponseType<wipType>) => {
        setWip(data);
        update();
        message.success({
          content: "Wip Details Updated",
          key: "editWip",
          duration: 2,
        });
      })
      .catch((error: string) => {
        message.error({
          content: error ? error : "Error making changes to the Wip!",
          key: "editWip",
          duration: 3,
        });
        console.error(error);
      });
  };

  useEffect(() => {
    fetchInstitutions();
    fetchIndustries();
  }, []);

  useEffect(() => {
    if (transactionId) {
      fetchWip(transactionId);
    }
  }, [transactionId]);

  return (
    <>
      <Drawer
        title={`Edit ${wip?.projectName}`}
        placement={"right"}
        width={700}
        onClose={onCloseDrawer}
        open={open}
      >
        <div className={"h-screen flex flex-col"}>
          <div className={"bg-white hide-scrollbar"}>
            <div
              className={
                "p-6 flex flex-col h-full container mx-auto max-w-screen-lg"
              }
            >
              <Form
                layout={"vertical"}
                onKeyDown={(event): void => {
                  if (event.code === "Enter" || event.code === "NumpadEnter")
                    form.submit();
                }}
                onFinish={(values): void => {
                  submitEditRequest(values, wip);
                }}
                form={form}
                className={"flex flex-col gap-y-3 h-full pb-3"}
              >
                <Form.Item
                  trigger={"onChange"}
                  className={"border-0 mb-1"}
                  label={"Next Step"}
                  name={"nextStep"}
                >
                  <TextArea placeholder="Next Step" maxLength={249} />
                </Form.Item>
                <Form.Item
                  className={"border-0 mb-1"}
                  name={"priority"}
                  label={"Priority"}
                >
                  <Select
                    notFoundContent={"No Priority Available"}
                    getPopupContainer={getPopupContainerForSelect}
                    maxTagCount={"responsive"}
                    placeholder={"Select Priority"}
                    showArrow={true}
                    dropdownRender={(menu): ReactElement => {
                      return <div className={"p-0 m-0"}>{menu}</div>;
                    }}
                    showSearch={true}
                    filterOption={filterOption}
                    onKeyDown={stopPropagationOnEnter}
                  >
                    {Object.entries(priorityLabelType)?.map(([value, name]) => (
                      <Select.Option key={value} value={Number(value)}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  trigger={"onChange"}
                  className={"border-0 w-full mb-1"}
                  label={"Date Added"}
                  name={"dateAdded"}
                >
                  <DatePicker className="w-full" />
                </Form.Item>
                {/*<Form.Item*/}
                {/*  className={"border-0 mb-1"}*/}
                {/*  name={"industries"}*/}
                {/*  label={"Industries"}*/}
                {/*>*/}
                {/*  <Select*/}
                {/*    notFoundContent={"No Industries Available"}*/}
                {/*    getPopupContainer={(trigger): HTMLElement =>*/}
                {/*      trigger.parentElement*/}
                {/*    }*/}
                {/*    showSearch*/}
                {/*    mode={"multiple"}*/}
                {/*    maxTagCount={"responsive"}*/}
                {/*    placeholder={"Select Industries"}*/}
                {/*    showArrow={true}*/}
                {/*    dropdownRender={(menu): ReactElement => {*/}
                {/*      return <div className={"p-0 m-0"}>{menu}</div>;*/}
                {/*    }}*/}
                {/*    optionFilterProp="children"*/}
                {/*    filterOption={filterOption}*/}
                {/*    filterSort={filterSort}*/}
                {/*  >*/}
                {/*    {industries?.map((o) => {*/}
                {/*      return (*/}
                {/*        <Select.Option key={o.id} value={o.id}>*/}
                {/*          {o.name}*/}
                {/*        </Select.Option>*/}
                {/*      );*/}
                {/*    })}*/}
                {/*  </Select>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item*/}
                {/*  className={"border-0 mb-1"}*/}
                {/*  name={"country"}*/}
                {/*  label={"Select Country"}*/}
                {/*>*/}
                {/*  <Select*/}
                {/*    className={"w-full"}*/}
                {/*    showArrow={true}*/}
                {/*    placeholder={"Select Country"}*/}
                {/*    mode={undefined}*/}
                {/*    showSearch={true}*/}
                {/*    optionFilterProp={"country"}*/}
                {/*    filterOption={true}*/}
                {/*  >*/}
                {/*    {countries*/}
                {/*      .sort((a, b) => (a.name > b.name ? 1 : -1))*/}
                {/*      .map((val) => (*/}
                {/*        <Select.Option*/}
                {/*          title={val?.dial_code?.toString()}*/}
                {/*          key={val.code}*/}
                {/*          value={val.name}*/}
                {/*          country={val?.name}*/}
                {/*        >*/}
                {/*          <div*/}
                {/*            className={*/}
                {/*              "flex flex-row w-full items-center gap-x-1 h-fit"*/}
                {/*            }*/}
                {/*          >*/}
                {/*            <img*/}
                {/*              alt={val.emoji}*/}
                {/*              src={val.image}*/}
                {/*              style={{ width: "18px", height: "18px" }}*/}
                {/*            />*/}
                {/*            <span className={"truncate"}>{val.name}</span>*/}
                {/*          </div>*/}
                {/*        </Select.Option>*/}
                {/*      ))}*/}
                {/*  </Select>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item*/}
                {/*  trigger={"onChange"}*/}
                {/*  className={"border-0 mb-1"}*/}
                {/*  label={"SDP Amount"}*/}
                {/*  name={"sdpAmount"}*/}
                {/*>*/}
                {/*  <Input placeholder="SDP Amount" />*/}
                {/*</Form.Item>*/}
                <Form.Item
                  className={"border-0 mb-1"}
                  name={"introducer"}
                  label={"Introducer"}
                >
                  <Input placeholder="Select Introducer" />
                </Form.Item>
                {/*<Form.Item*/}
                {/*  className={"border-0 mb-1"}*/}
                {/*  name={"sponsor"}*/}
                {/*  label={"Sponsor"}*/}
                {/*>*/}
                {/*  <Select*/}
                {/*    notFoundContent={"No Sponsors Available"}*/}
                {/*    getPopupContainer={getPopupContainerForSelect}*/}
                {/*    maxTagCount={"responsive"}*/}
                {/*    // disabled*/}
                {/*    placeholder={"Select Sponsor"}*/}
                {/*    showArrow={true}*/}
                {/*    dropdownRender={(menu): ReactElement => {*/}
                {/*      return <div className={"p-0 m-0"}>{menu}</div>;*/}
                {/*    }}*/}
                {/*    showSearch={true}*/}
                {/*    filterOption={filterOption}*/}
                {/*    onKeyDown={stopPropagationOnEnter}*/}
                {/*  >*/}
                {/*    {companies?.map(({ id, name }) => (*/}
                {/*      <Select.Option key={id} value={id}>*/}
                {/*        {name}*/}
                {/*      </Select.Option>*/}
                {/*    ))}*/}
                {/*  </Select>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item*/}
                {/*  trigger={"onChange"}*/}
                {/*  className={"border-0 mb-1"}*/}
                {/*  label={"Ebidta"}*/}
                {/*  name={"ebitda"}*/}
                {/*>*/}
                {/*  <Input placeholder="Ebidta" />*/}
                {/*</Form.Item>*/}
                <Form.Item
                  trigger={"onChange"}
                  className={"border-0 mb-1"}
                  label={"Target Company"}
                  name={"targetCompany"}
                >
                  <Input placeholder="Target Company" />
                </Form.Item>
                <Form.Item
                  className={"border-0 mb-1"}
                  name={"dealLeader"}
                  label={"Deal Leader"}
                >
                  <Select
                    notFoundContent={"No Deal Leader Available"}
                    getPopupContainer={getPopupContainerForSelect}
                    maxTagCount={"responsive"}
                    placeholder={"Select Deal Leader"}
                    showArrow={true}
                    dropdownRender={(menu): ReactElement => {
                      return <div className={"p-0 m-0"}>{menu}</div>;
                    }}
                    showSearch={true}
                    filterOption={filterOption}
                    onKeyDown={stopPropagationOnEnter}
                  >
                    {dealTeam?.map((value, index: number) => (
                      <Select.Option key={index} value={value?.userId}>
                        {value?.firstName} {value?.lastName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  className={"border-0 mb-1"}
                  name={"dealTeam"}
                  label={"Deal Team"}
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={dealTeam}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <CustomAvatar
                              outlined={true}
                              data={item?.firstName?.[0] + item?.lastName?.[0]}
                              color={item?.firstName?.[0]}
                              size={"small"}
                              label={item.firstName + " " + item.lastName}
                            />
                          }
                          title={
                            <a href="https://ant.design">
                              {item.firstName} {item.lastName}
                            </a>
                          }
                          description={item.email}
                        />
                      </List.Item>
                    )}
                  />
                </Form.Item>

                {/*<Form.Item*/}
                {/*  trigger={"onChange"}*/}
                {/*  className={"border-0 mb-1"}*/}
                {/*  label={"Total Leverage"}*/}
                {/*  name={"totalLeverage"}*/}
                {/*>*/}
                {/*  <Input placeholder="Total Leverage" />*/}
                {/*</Form.Item>*/}
                <Form.Item
                  trigger={"onChange"}
                  className={"border-0 mb-1"}
                  label={"Comments"}
                  name={"comments"}
                >
                  <TextArea placeholder="Comments" maxLength={249} />
                </Form.Item>
                <Button
                  className={
                    "mt-auto bg-primary hover:bg-hover text-white border-0 sticky bottom-0"
                  }
                  onClick={(): void => {
                    form.submit();
                  }}
                >
                  Update
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

type editWipType = {
  open: boolean;
  onCloseDrawer: () => void;
  transactionId: string;
  update: () => void;
};
type submitEditRequestType = (values: wipType, wip: wipType | null) => void;
