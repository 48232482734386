import React, { FC, useEffect, useState } from "react";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { Button, Empty, Form, message, Select } from "antd";
import {
  getInstitutions,
  getPlatformActivitiesInit,
  getTransactionNames,
  getTransactionsByCompany,
} from "../../services/services";
import { CloseOutlined } from "@ant-design/icons";
import { ResponseType, voidType } from "../../utils/uiTypes";
import {
  ActivityType,
  InstitutionType,
  NotificationActivityType,
  NotificationType,
  TransactionType,
} from "../../utils/types";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { groupByProperty } from "../../utils/utils";
import { GroupedActivities } from "./GroupedActivities";
import Spin from "antd/es/spin";
import { getPopupContainerForSelect } from "../../utils/container";

export const Activity: FC = () => {
  usePageTitle("Activity");
  const [form] = Form.useForm();
  const [activities, setActivities] = useState<NotificationActivityType[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [groupActivities, setGroupActivities] = useState<
    Array<Array<ActivityType>>
  >([]);
  const [transactions, setTransactions] = useState<TransactionType[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [companyNames, setCompanyNames] = useState<InstitutionType[]>([]);

  const reset: voidType = () => {
    setOffset(0);
    getActivities(0, "");
    getAllTransactions(1500, 0);
    form.resetFields();
  };

  const getAllCompanies: voidType = () => {
    getInstitutions({})
      .then(({ data }: ResponseType<InstitutionType[]>) => {
        setCompanyNames(data);
      })
      .catch((error: string) => {
        message.error(error ?? "Try again later!");
      });
  };

  const getTransactionsByCompanyId: getTransactionsByCompanyIdType = (
    companyId: string
  ) => {
    getTransactionsByCompany({
      segments: {
        companyId,
      },
    })
      .then(({ data }: ResponseType<TransactionType[]>) => {
        setTransactions(data);
      })
      .catch((error: string) => {
        message.error(error ?? "Try again later!");
      });
  };

  const getAllTransactions: getAllTransactionsType = (limit, pageNumber) => {
    getTransactionNames({
      segments: {
        limit,
        pageNumber,
      },
    })
      .then(({ data }: ResponseType<TransactionType[]>) => {
        setTransactions(data);
      })
      .catch((error: string) => {
        message.error(error ?? "Try again later!");
      });
  };

  const getActivities: getActivitiesType = (offset, peTransactionId = "") => {
    getPlatformActivitiesInit({
      segments: {
        limit: 10,
        offset,
      },
      params: {
        peTransactionId,
      },
    })
      .then(({ data }: ResponseType<NotificationType>) => {
        setLoading(false);
        setActivities(data.activityDTOs);
        setGroupActivities(
          groupByProperty(data.activityDTOs, "transactionName")
        );
      })
      .catch((error: string) => {
        message.error(error ?? "Try again later!");
      })
      .then(() => {
        setLoading(false);
      });
  };

  const onNext: voidType = () => {
    setOffset(offset + 1);
    getActivities(offset + 1, form.getFieldValue("transaction"));
  };
  const onPrevious: voidType = () => {
    setOffset(offset - 1);
    getActivities(offset - 1, form.getFieldValue("transaction"));
  };

  useEffect(() => {
    getActivities(offset);
    getAllCompanies();
    getAllTransactions(1500, 0);
  }, []);
  return (
    <div>
      <div className="p-6">
        <div className="flex justify-end">
          <h1
            className={`${
              loading
                ? "text-muted text-3xl mb-2 mr-auto"
                : "text-3xl mb-2 mr-auto"
            }`}
          >
            Activities
          </h1>
          <Form form={form} className="flex">
            <Form.Item name="company">
              <Select
                notFoundContent={"No Companies Found"}
                getPopupContainer={getPopupContainerForSelect}
                showSearch
                placeholder="Search By Company..."
                className="w-56 mr-2"
                disabled={loading}
                filterOption={true}
                onChange={(value: string) => {
                  form.setFields([{ name: "transaction", value: null }]);
                  getTransactionsByCompanyId(value);
                }}
                optionFilterProp={"children"}
              >
                {companyNames.map((val, key) => {
                  return (
                    <Select.Option key={key} value={val.id}>
                      {val.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="transaction">
              <Select
                notFoundContent={"No Transactions Found"}
                getPopupContainer={getPopupContainerForSelect}
                showSearch
                className="w-56"
                placeholder="Search By Transaction..."
                disabled={loading}
                filterOption={true}
                onChange={(value: string) => {
                  getActivities(0, value);
                }}
                optionFilterProp={"children"}
              >
                {transactions.map((val, key) => {
                  return (
                    <Select.Option key={key} value={val.peTransactionId}>
                      {val.peTransactionTitle}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <CloseOutlined
                className={`${
                  form.isFieldsTouched()
                    ? "visible text-danger ml-2 mt-2"
                    : "invisible text-danger ml-2 mt-2"
                }`}
                onClick={reset}
              />
            </Form.Item>
          </Form>
        </div>

        <Spin spinning={loading} tip="Loading...">
          {activities && activities.length === 0 ? (
            <Empty
              className="w-full bg-white p-28"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ) : (
            <div className="w-full">
              {groupActivities?.map((val, key) => {
                return (
                  <div key={key} className="mt-3">
                    <div className="mb-1 font-bold text-xs opacity-40 uppercase">
                      {val[0].transactionName}
                    </div>
                    <table key={key} className="w-full">
                      <GroupedActivities
                        key={key}
                        activities={val}
                        transactionId={form.getFieldValue("transaction") ?? ""}
                      />
                    </table>
                  </div>
                );
              })}
            </div>
          )}
        </Spin>
        <div className="flex justify-center w-full my-5 gap-2">
          <Button
            hidden={loading || (activities && activities.length === 0)}
            className={`w-24 ${PRIMARY_BUTTON_STYLE}`}
            disabled={
              loading || (activities && activities?.length === 0) || !offset
            }
            onClick={onPrevious}
          >
            Previous
          </Button>
          <Button
            hidden={loading || (activities && activities.length === 0)}
            className={`w-24 ${PRIMARY_BUTTON_STYLE}`}
            disabled={loading || (activities && activities.length < 10)}
            onClick={onNext}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

type getActivitiesType = (offset: number, peTransactionId?: string) => void;
type getTransactionsByCompanyIdType = (companyId: string) => void;
type getAllTransactionsType = (limit?: number, pageNumber?: number) => void;
