import React, { FC, useEffect, useState } from "react";
import { Button, Input, message, Table } from "antd";
import { UserType } from "../../utils/types";
import useWindowDimensions from "../../customHooks/useWindowDimensions";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { ColumnsType } from "antd/lib/table";
import { getUnverifiedEmails, verifyUser } from "../../services/services";
import { ResponseType } from "../../utils/uiTypes";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { remove } from "ramda";

export const Verify: FC = () => {
  usePageTitle("Pending Sign-Up");
  const { height: windowHeight } = useWindowDimensions();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserType[]>([]);

  const fetchUnVerifiedEmails = (): void => {
    setLoading(true);
    getUnverifiedEmails({ segments: { email: "" } })
      .then(({ data }: ResponseType<UserType[]>) => {
        setUsers(data);
      })
      .catch((error: string) => message.error(error ?? "Unable to get Users"))
      .then(() => setLoading(false));
  };

  const initiateUserVerification = (email: string, index: number) => {
    message.loading({ key: "verification", content: `Verifying ${email}` });
    verifyUser({
      segments: {
        email,
      },
    })
      .then(() => {
        message.success({ key: "verification", content: `${email} Verified` });
        setUsers(remove(index, 1, users));
      })
      .catch(() => {
        message.error({
          key: "verification",
          content: `${email} Verification Failed`,
        });
      });
  };

  const columns: ColumnsType<UserType> = [
    {
      title: "Email",
      ellipsis: true,
      className: "bg-transparent",
      dataIndex: "email",
      render: (email) => email,
    },
    {
      title: "Name",
      ellipsis: true,
      className: "bg-transparent",
      dataIndex: "email",
      render: (_, { firstName, lastName }) => `${firstName} ${lastName}`,
    },
    {
      title: "Action",
      ellipsis: true,
      className: "bg-transparent",
      dataIndex: "email",
      width: "10%",
      render: (email, _, index) => (
        <Button
          className={PRIMARY_BUTTON_STYLE}
          onClick={() => initiateUserVerification(email, index)}
        >
          Verify
        </Button>
      ),
    },
  ];

  useEffect(() => {
    fetchUnVerifiedEmails();
  }, []);

  return (
    <div className="p-6">
      <div className="flex justify-between">
        <h1
          className={`${
            loading ? "text-muted text-3xl mb-2" : "text-3xl mb-2"
          }`}
        >
          Pending Sign Up
        </h1>
      </div>
      <Table
        dataSource={users}
        columns={columns}
        loading={loading}
        pagination={false}
        scroll={{ x: 1000, y: windowHeight - 300 }}
        rowClassName={"hover:bg-blue-50"}
      />
    </div>
  );
};
