import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import {
  addAllocationKey,
  deleteAllocationKey,
  getAllocationKeys,
  updateAllocationKey,
} from "../../services/services";
import { AllocationKeyType, UserType } from "../../utils/types";
import { Button, Form, Input, message, Modal, Table, Tabs } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  filterByKeyAndVal,
  getIndexByKeyVal,
  insertItemInArray,
  removeItemFromArray,
  updateItemInArray,
} from "../../utils/utils";
import { CustomSpin } from "../general/CustomSpin";
import { CustomButton } from "../general/CustomButton";
import { UserContext } from "../../context/UserContext";
import { KeyLabelType, ResponseType } from "../../utils/uiTypes";
import { AllocationTableType } from "../../utils/enums";
import { ConfirmDelete } from "../../utils/confirmationModals";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { ACTION_BUTTON_CSS } from "../../utils/cssConfigs";
import { tableColumnHeader } from "../../utils/componentUtils";
import { ColumnsType } from "antd/es/table";

const { TabPane } = Tabs;

const ALLOCATION_TABLE_OPTIONS: Array<KeyLabelType<AllocationTableType>> = [
  {
    key: AllocationTableType.FINAL_HOLD,
    label: "Facility Types",
  },
  {
    key: AllocationTableType.FEES,
    label: "Fee Types",
  },
  {
    key: AllocationTableType.FUND,
    label: "Fund Types",
  },
  {
    key: AllocationTableType.TRANSACTION,
    label: "Transaction Types",
  },
  {
    key: AllocationTableType.INVESTMENT,
    label: "Investment Types",
  },
];

export const AllocationTypeSettings: FC = function () {
  usePageTitle("Allocation Settings");

  const { user } = useContext(UserContext);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<LoadingType>({ data: true });
  const [keys, setKeys] = useState<AllocationKeyType[]>([]);
  const [selectedTab, setSelectedTab] = useState<
    KeyLabelType<AllocationTableType>
  >(ALLOCATION_TABLE_OPTIONS[0]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] =
    useState<AllocationKeyType | null>(null);

  const getUpdatedAllocationTypes: getUpdatedAllocationTypesType = (
    data,
    newVal,
    key
  ): AllocationKeyType[] => {
    switch (key) {
      case "add":
        return insertItemInArray(data.length, data, newVal);
      case "update":
        return updateItemInArray(
          getIndexByKeyVal(data, "id", newVal?.id),
          data,
          newVal
        );
      case "delete":
        return removeItemFromArray(
          getIndexByKeyVal(data, "id", newVal?.id),
          data
        );
      default:
        return data;
    }
  };

  const getTypeCopy: getTypeCopyType = (key) => {
    switch (key) {
      case AllocationTableType.FINAL_HOLD:
        return `Facility Type Name`;
      case AllocationTableType.FEES:
        return `Fee type Name`;
      case AllocationTableType.FUND:
        return `Fund type Name`;
      case AllocationTableType.TRANSACTION:
        return `Transaction type Name`;
      default:
        return `Type Name`;
    }
  };

  const fetchAllocationKeysByCompanyId: fetchAllocationKeysByCompanyIdType = (
    companyId
  ) => {
    setLoading({ ...loading, data: true });
    getAllocationKeys({
      segments: {
        companyId,
      },
    })
      .then(({ data = [] }: ResponseType<AllocationKeyType[]>) => {
        setLoading({ ...loading, data: false });
        setKeys(data);
      })
      .catch((error: string) => {
        setLoading({ ...loading, data: false });
        message.error(
          error ? error : "Something went wrong. Please try again.",
          2
        );
      });
  };

  const submitNewAllocationKeyByType: submitNewAllocationKeyByTypeType = (
    val,
    allocationTableType,
    keys,
    selectedTab,
    user
  ): void => {
    setLoading({ ...loading, form: true });
    addAllocationKey({
      body: JSON.stringify({
        ...val,
        allocationTableType: allocationTableType,
        companyId: user?.companyId,
        userName: user?.firstName + " " + user?.lastName,
      }),
    })
      .then(({ data }: ResponseType<AllocationKeyType>) => {
        setKeys(getUpdatedAllocationTypes(keys, data, "add"));
        message.success(selectedTab.label + " has been added successfully!");
        setLoading({ ...loading, form: false });
      })
      .catch((error: string) => {
        message.error(error ? error : "Something went wrong. Please try again");
        setLoading({ ...loading, form: false });
      });
  };

  const submitUpdateAllocationKeyByType: submitUpdateAllocationKeyByTypeType = (
    val,
    allocationKey,
    keys,
    selectedTab,
    user
  ): void => {
    setLoading({ ...loading, form: true });
    updateAllocationKey({
      body: JSON.stringify({
        ...val,
        allocationTableType: allocationKey.allocationTableType,
        companyId: user?.companyId,
        id: allocationKey.id,
        userName: user?.firstName + " " + user?.lastName,
      }),
    })
      .then(({ data }: ResponseType<AllocationKeyType>) => {
        setKeys(getUpdatedAllocationTypes(keys, data, "update"));
        message.success(selectedTab.label + " has been updated successfully!");
        setLoading({ ...loading, form: false });
      })
      .catch((error: string) => {
        message.error(error ? error : "Something went wrong. Please try again");
        setLoading({ ...loading, form: false });
      });
  };

  const submitDeleteAllocationKeyByType: submitDeleteAllocationKeyByTypeType = (
    allocationKey,
    keys,
    selectedTab
  ): void => {
    deleteAllocationKey({
      segments: {
        id: allocationKey.id,
      },
    })
      .then(() => {
        setKeys(getUpdatedAllocationTypes(keys, allocationKey, "delete"));
        message.success(selectedTab.label + " has been updated successfully!");
      })
      .catch((error: string) => {
        message.error(error ? error : "Something went wrong. Please try again");
      });
  };

  const onFormSubmit: onFormSubmit = (
    data,
    allocationKey,
    keys,
    selectedTab,
    user
  ) => {
    if (selectedRecord) {
      user &&
        submitUpdateAllocationKeyByType(
          data,
          selectedRecord,
          keys,
          selectedTab,
          user
        );
    } else {
      user &&
        submitNewAllocationKeyByType(
          data,
          selectedTab.key,
          keys,
          selectedTab,
          user
        );
    }
    setModalOpen(false);
  };

  const columns: ColumnsType<AllocationKeyType> = [
    {
      title: tableColumnHeader(
        selectedTab.key === AllocationTableType.INVESTMENT
          ? "Investment Type Name"
          : getTypeCopy(selectedTab.key)
      ),
      dataIndex: "keyName",
      key: "keyName",
      width: "35%",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      defaultSortOrder: "ascend",
      sortOrder: "ascend",
    },
    {
      title: tableColumnHeader("Created By"),
      dataIndex: "userName",
      key: "userName",
      align: "left" as const,
      className: "group-hover:bg-blue-50 min-w-[85px]",
    },
    {
      title: tableColumnHeader("External Id"),
      dataIndex: "externalId",
      key: "externalId",
      align: "left" as const,
      className: "group-hover:bg-blue-50 min-w-[85px]",
    },
    {
      title: tableColumnHeader("Action"),
      dataIndex: "operation",
      key: "operation",
      align: "right" as const,
      width: "100px",
      className: "group-hover:bg-blue-50 min-w-[85px]",

      render: function actions(
        _: string,
        record: AllocationKeyType
      ): ReactNode {
        return (
          <span className={"flex flex-row items-center justify-end gap-x-3"}>
            <Button
              type={"text"}
              icon={<i className="fas fa-ellipsis-h" />}
              className={
                "absolute ml-auto border-0 flex items-center px-2 text-gray-900 hidden md:block opacity-50 group-hover:opacity-0"
              }
            />
            {
              <CustomButton
                label={"Delete"}
                type={"default"}
                className={`${ACTION_BUTTON_CSS} hover:text-red-500 opacity-0 group-hover:opacity-100`}
                onClick={(): void => {
                  ConfirmDelete(`Confirm Delete ${selectedTab.label}?`, () =>
                    submitDeleteAllocationKeyByType(record, keys, selectedTab)
                  );
                }}
              >
                <i className="fas fa-trash-alt" />
              </CustomButton>
            }
            {
              <CustomButton
                label={"Edit"}
                type={"default"}
                onClick={(): void => {
                  setSelectedRecord(record);
                  setModalOpen(true);
                }}
                className={`${ACTION_BUTTON_CSS} hover:text-blue-500 opacity-0 group-hover:opacity-100`}
              >
                <i className="fas fa-pencil-alt" />
              </CustomButton>
            }
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    user?.companyId && fetchAllocationKeysByCompanyId(user.companyId);
  }, [user?.companyId]);

  useEffect(() => {
    form.setFieldsValue(selectedRecord);
  }, [selectedRecord]);

  return (
    <div
      className={
        "relative max-h-full w-full h-full bg-gray-100 p-6 flex flex-col"
      }
    >
      <CustomSpin loading={loading?.data} />
      <Tabs
        className={"h-full flex flex-col hide-scrollbar"}
        type="card"
        animated={true}
        destroyInactiveTabPane={true}
        onChange={(activeKey): void => {
          const index = ALLOCATION_TABLE_OPTIONS.findIndex(
            ({ key }) => key === activeKey
          );
          setSelectedTab(ALLOCATION_TABLE_OPTIONS[index]);
        }}
        tabBarExtraContent={
          user?.isAdmin && (
            <Button
              type={"primary"}
              className={"bg-primary hover:bg-hover text-white border-0"}
              onClick={(): void => {
                setSelectedRecord(null);
                setModalOpen(true);
              }}
            >
              <PlusOutlined /> New {selectedTab.label}
            </Button>
          )
        }
      >
        {ALLOCATION_TABLE_OPTIONS.map(
          (tableType: KeyLabelType<AllocationTableType>) => (
            <TabPane
              tab={tableType.label}
              key={tableType.key}
              className={"w-full h-full flex flex-col"}
            >
              <Table
                rowKey={"id"}
                className={
                  "border transition duration-300 ease-out transform  overflow-y-auto"
                }
                columns={
                  user?.isAdmin
                    ? columns
                    : columns.filter((col) => col.key !== "operation")
                }
                pagination={false}
                dataSource={filterByKeyAndVal(
                  keys,
                  "allocationTableType",
                  selectedTab.key
                ).filter((item) => item.canDelete)}
                scroll={{ x: "max-content", y: window.innerHeight - 200 }}
                rowClassName={"group relative hover:bg-blue-50"}
              />
            </TabPane>
          )
        )}
      </Tabs>
      <Modal
        title={
          (selectedRecord ? "Update " : "Add ") + getTypeCopy(selectedTab.key)
        }
        open={modalOpen}
        onCancel={(): void => {
          setModalOpen(false);
          setSelectedRecord(null);
        }}
        destroyOnClose={true}
        footer={null}
        getContainer={false}
      >
        <Form
          preserve={false}
          form={form}
          layout={"vertical"}
          onFinish={(data): void => {
            user && onFormSubmit(data, selectedRecord, keys, selectedTab, user);
          }}
        >
          <Form.Item
            label={getTypeCopy(selectedTab.key)}
            name={"keyName"}
            rules={[
              {
                required: true,
                message: `Please enter ${getTypeCopy(selectedTab.key)}`,
              },
            ]}
          >
            <Input placeholder={"Enter Key Name"} className={" focus:ring-0"} />
          </Form.Item>
          <Form.Item
            label={"External Id"}
            name={"externalId"}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input placeholder={"Enter"} className={" focus:ring-0"} />
          </Form.Item>

          <Form.Item>
            <div className={"flex justify-end"}>
              <Button
                disabled={loading.form}
                onClick={(): void => {
                  setModalOpen(false);
                  setSelectedRecord(null);
                }}
                className={"w-32 mr-4"}
              >
                Cancel
              </Button>
              <Button
                loading={loading.form}
                disabled={loading.form}
                className={"bg-primary hover:bg-hover text-white w-32"}
                htmlType={"submit"}
              >
                {selectedRecord ? "Update" : "Add"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
type getUpdatedAllocationTypesType = (
  data: AllocationKeyType[],
  newVal: AllocationKeyType,
  key: string
) => AllocationKeyType[];
type getTypeCopyType = (key: AllocationTableType) => string;
type submitNewAllocationKeyByTypeType = (
  data: AllocationKeyType,
  allocationTableType: AllocationTableType,
  keys: AllocationKeyType[],
  selectedTab: KeyLabelType<AllocationTableType>,
  user: UserType
) => void;
type submitUpdateAllocationKeyByTypeType = (
  data: AllocationKeyType,
  allocationKey: AllocationKeyType,
  keys: AllocationKeyType[],
  selectedTab: KeyLabelType<AllocationTableType>,
  user: UserType
) => void;
type submitDeleteAllocationKeyByTypeType = (
  allocationKey: AllocationKeyType,
  keys: AllocationKeyType[],
  selectedTab: KeyLabelType<AllocationTableType>
) => void;
type fetchAllocationKeysByCompanyIdType = (companyId: string) => void;
type onFormSubmit = (
  data: AllocationKeyType,
  allocationKey: AllocationKeyType | null,
  keys: AllocationKeyType[],
  selectedTab: KeyLabelType<AllocationTableType>,
  user: UserType
) => void;
type LoadingType = {
  data?: boolean;
  form?: boolean;
};
