import { FC, ReactNode } from "react";
import { BadgeColor } from "../components/general/TGBadge";

export type ResponseType<ResponseDataType = any> = {
  ok: boolean;
  status: number;
  data: ResponseDataType;
  headers: Headers;
};

export type InviteType = {
  admin: boolean;
  companyId: string;
  elementType: string;
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  phoneNumber: string;
};

export type FileResponseType = {
  url: string;
  filename: string;
  blob: Blob;
};

export type ErrorType = {
  error?: any;
  message?: string;
};
export type KeyLabelType<T, V = string> = {
  key: T;
  label: V;
};
// Added for generalising Drag and Drop. Will be updated while generalising the Draggable Element

export const TrackersTypes = {
  MILESTONE: "milestone",
  PORTFOLIO_INPUT: "PortfolioInputField",
  TAGS: "tags",
  LENDER: "lender",
};

export type MenuRouteType = {
  Component?: FC<any>;
  exact: boolean;
  path: string | string[];
  extraPath?: string;
  title?: ReactNode;
  key: string;
  permission?: boolean;
  subMenu?: MenuRouteType[];
  visible?: boolean;
  disabled?: boolean;
  props?: Record<string, any>;
  badge?: { label: ReactNode; color?: BadgeColor };
  icon?: ReactNode;
  isPrivate?: boolean;
};

export type LoadingType = {
  loading: boolean;
  label?: string;
};

export type voidType = () => void;
export type fetchWithIdType = (id: string) => void;

export type PrecedentSearchRequestType = {
  industryIds: string[];
  companyIds: string[];
  size: number;
  version: string;
  bidMetricSearchText: string;
  fromDate: string;
  toDate: string;
  userId: string;
  userCompanyId: string;
  pageToken: string;
};
