import React, { FC, useContext, useEffect, useState } from "react";
import { getPopupContainerForSelect } from "../../utils/container";
import { isNil, pathOr } from "ramda";
import { getObjectFromPropertyValue } from "../../utils/utils";
import { LoadingOutlined } from "@ant-design/icons";
import { convertDateToFormat } from "../../utils/moment";
import { message, Select } from "antd";
import { ResponseType, voidType } from "../../utils/uiTypes";
import { getDealCloudTransactions } from "../../services/services";
import {
  DealCloudGetTransactionResponseDTOType,
  DealCloudTransactionDTOType,
} from "../../utils/types";
import { UserContext } from "../../context/UserContext";
import { FuzzySearchSelect } from "../general/FuzzySearchSelect";
import { useUser } from "../../hooks/useUser";
import { useConfigs } from "../../hooks/useConfigs";
import { ConfigType } from "../../utils/enums";

export const DealConnect: FC<DealConnectType> = ({
  value,
  onClear,
  onSelect,
  disabled,
}) => {
  const { companyModulePreference } = useUser();
  const { configValues } = useConfigs();
  const [dealLoading, setDealLoading] = useState(false);
  const [dealCloudTransactions, setDealCloudTransactions] = useState<
    DealCloudTransactionDTOType[]
  >([]);

  const fetchDealCloudTransactions: voidType = () => {
    setDealLoading(true);
    getDealCloudTransactions({})
      .then(
        ({ data }: ResponseType<DealCloudGetTransactionResponseDTOType>) => {
          setDealCloudTransactions(() => data?.rows ?? []);
          setDealLoading(false);
        }
      )
      .catch((e: string) => {
        console.error(e);
        message.error(e);
        setDealLoading(false);
      });
  };

  const displayValue = (
    data: DealCloudTransactionDTOType[],
    val?: string | null
  ) => {
    const obj = getObjectFromPropertyValue("EntryId", val, data);
    return obj
      ? `${pathOr("", ["Company", "name"], obj)} (${pathOr(
          "",
          ["DealName", "name"],
          obj
        )})`
      : null;
  };

  useEffect(() => {
    if (
      companyModulePreference?.dealCloud &&
      configValues[ConfigType.DEAL_CLOUD_CONFIG]?.active
    ) {
      fetchDealCloudTransactions();
    }
  }, [companyModulePreference, configValues[ConfigType.DEAL_CLOUD_CONFIG]]);

  useEffect(() => {
    onSelect(
      getObjectFromPropertyValue("EntryId", value, dealCloudTransactions)
    );
  }, [value]);

  return (
    <FuzzySearchSelect<DealCloudTransactionDTOType>
      getPopupContainer={getPopupContainerForSelect}
      value={displayValue(dealCloudTransactions, value)}
      onClear={onClear}
      allowClear={true}
      suffixIcon={dealLoading && <LoadingOutlined spin={true} />}
      disabled={dealLoading || disabled}
      showSearch={true}
      placeholder={
        dealLoading ? (
          "Loading DealCloud Transactions"
        ) : (
          <>
            <div
              className={
                "hidden group-focus-within:flex w-full items-center justify-between"
              }
            >
              <div>Company Info</div>
              <div>Deal Info</div>
            </div>
            <div className={"group-focus-within:hidden visible"}>
              Select DealCloud Transaction
            </div>
          </>
        )
      }
      notFoundContent={
        dealLoading ? "Loading DealCloud Transactions" : "No Transactions Found"
      }
      className={"w-full group"}
      onSelect={(entryId) => {
        const entity = getObjectFromPropertyValue(
          "EntryId",
          entryId,
          dealCloudTransactions
        );
        if (entity) {
          onSelect(entity);
        }
      }}
      items={dealCloudTransactions.filter(({ DealName }) => !isNil(DealName))}
      getOptionKey={pathOr("", ["EntryId"])}
      getOptionValue={pathOr("", ["EntryId"])}
      getAbbrevationPath={pathOr("", ["Company", "name"])}
      searchPaths={["Company.name", "DealName.name"]}
      getOptionLabel={({
        DealName,
        Company,
        Status,
        NewDealDate,
        DescriptionThesis,
      }) => (
        <div>
          <div className={"flex w-full gap-x-5 items-center justify-between"}>
            <div>
              <span className={"font-medium"}>{Company?.name ?? ""}</span>
              {NewDealDate && (
                <span className={"text-xs text-secondary"}>
                  &nbsp;-&nbsp;
                  {convertDateToFormat(NewDealDate, "D.MMM.YY", false)}
                </span>
              )}
            </div>
            <div className={" font-medium text-xs"}>{DealName?.name ?? ""}</div>
          </div>
          <div
            className={
              "text-xs text-secondary flex items-center justify-between gap-x-5"
            }
          >
            <p>{DescriptionThesis ?? ""}</p>
            <div className={"uppercase font-medium"}> {Status?.name ?? ""}</div>
          </div>
        </div>
      )}
    />
  );
};

type DealConnectType = {
  disabled?: boolean;
  value?: string | null;
  onSelect: (value: DealCloudTransactionDTOType) => void;
  onClear: voidType;
};
