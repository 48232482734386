import React, { CSSProperties, FC, ReactNode } from "react";
import { Button } from "antd";
import { DeleteOutlined, SettingOutlined } from "@ant-design/icons";
import { CustomSpin } from "../../general/CustomSpin";
import { PermissionType } from "../../../utils/enums";
import { PortfolioAdminPermissionBlock } from "../PortfolioAdminPermissionBlock";

export const PortfolioCard: FC<PortfolioCardType> = ({
  title,
  children,
  header,
  onClick,
  onConfirmText,
  onConfirm,
  editMode,
  bodyStyle,
  loading = false,
  permissions,
  onDelete,
  deleteMode = false,
}: PortfolioCardType) => {
  return (
    <div className={"p-4 w-full h-full max-h-full"}>
      <div className={"flex flex-col h-full w-full bg-white shadow"}>
        <div
          className={`px-5 py-3 w-full border-b font-medium flex flex-row items-center justify-between`}
        >
          <div className={"text-lg"}>{title}</div>
          <div className={"flex flex-row items-center gap-2"}>
            {deleteMode && onDelete && (
              <PortfolioAdminPermissionBlock permissions={permissions}>
                <Button
                  className={`text-blue-400 border-blue-400 disabled:opacity-50`}
                  type={"default"}
                  onClick={onDelete}
                  icon={<DeleteOutlined />}
                >
                  Delete
                </Button>
              </PortfolioAdminPermissionBlock>
            )}
            {header
              ? header
              : onClick &&
                !loading && (
                  <PortfolioAdminPermissionBlock permissions={permissions}>
                    <Button
                      className={`text-blue-400 border-blue-400 disabled:opacity-50`}
                      type={"default"}
                      onClick={onClick}
                      disabled={editMode}
                      icon={<SettingOutlined />}
                    >
                      Settings
                    </Button>
                  </PortfolioAdminPermissionBlock>
                )}
          </div>
        </div>
        <div
          className={`relative h-full max-h-full overflow-y-auto ${
            !loading && "p-6"
          }`}
          style={loading ? { padding: 0 } : bodyStyle}
        >
          {loading ? (
            <div className={"h-[200px]"}>
              <CustomSpin loading={loading} loadingText={" "} />
            </div>
          ) : (
            children
          )}
        </div>
        {editMode && !loading ? (
          <div className={"border-t px-5 py-3 w-full text-right"} key={0}>
            <Button
              onClick={onClick}
              className={`mx-2 text-primary border border-primary`}
            >
              Close Edit
            </Button>
            {onConfirm && (
              <Button
                onClick={onConfirm}
                key={1}
                className={`bg-primary hover:bg-hover text-white border-0`}
              >
                {onConfirmText || "Confirm"}
              </Button>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

type PortfolioCardType = {
  title: string;
  children: ReactNode;
  header?: ReactNode;
  onConfirmText?: string;
  onConfirm?: () => void;
  onClick?: () => void;
  editMode?: boolean;
  bodyStyle?: CSSProperties;
  loading?: boolean;
  permissions: PermissionType[];
  onDelete?: () => void;
  deleteMode?: boolean;
};
