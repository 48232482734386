import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Select, Table, Tag } from "antd";
import {
  CompanyType,
  LeakageData,
  LeakageDataRow,
  LeakageTemplate,
  LeakageTemplateRows,
  LeakageTemplateSection,
} from "../../../../utils/types";
import { ColumnsType } from "antd/lib/table";
import {
  tableColumnHeader,
  tableSelectSuffix,
} from "../../../../utils/componentUtils";
import { pathOr } from "ramda";
import { defaultIfEmptyOrNil, isNotNil } from "../../../../utils/utils";
import {
  CurrencySymbolType,
  CurrencyType,
  ElementType,
  LeakageDataTypes,
  UnitLabelType,
  UnitType,
} from "../../../../utils/enums";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import EditableTableCell, {
  EditableTableCellProps,
} from "../../../general/EditableTableCell";
import { getPopupContainerForSelect } from "../../../../utils/container";
import { tableSelectCss } from "../../../../utils/cssConfigs";
import {
  getCellDataType,
  getPrefixByDataType,
  getSuffixByDataType,
} from "../../../../utils/leakage";

type TemplateRowType = LeakageTemplateSection | LeakageTemplateRows;

const isSectionDTO = (
  data: TemplateRowType
): data is LeakageTemplateSection => {
  return (data as LeakageTemplateSection).sectionId !== undefined;
};

export const LeakageTable: FC<LeakageAnalysisTableType> = ({
  template,
  data,
  onUpdateData,
  companies,
  editable,
}) => {
  const { height: windowHeight } = useWindowDimensions();

  const getCell = useCallback(
    (key?: string | null): LeakageDataRow | null => {
      const result = pathOr(
        null,
        ["leakageElementKeyDataDTOMap", key || ""],
        data || {}
      );
      return result as unknown as LeakageDataRow | null;
    },
    [data]
  );

  const companyOptions: EditableTableCellProps["selectOptions"] =
    useMemo(() => {
      return companies.map(({ id, name }) => ({
        value: name,
        key: id,
      }));
    }, [companies]);

  const columns: ColumnsType<TemplateRowType> = [
    {
      title: tableColumnHeader(" ", "px-4 py-2"),
      dataIndex: "rank",
      align: "center",
      className: "p-0",
      width: "40px",
      render: (rank) => tableColumnHeader(rank),
    },
    {
      title: tableColumnHeader("Term", "px-4 py-2"),
      align: "left" as const,
      width: "fit-content",
      key: "sectionHeading",
      dataIndex: "sectionHeading",
      className: "min-w-[85px] p-0 whitespace-nowrap pl-2",
      render: function name(_, record): ReactNode {
        return (
          <span
            className={"flex flex-row whitespace-pre-wrap"}
            style={
              !isSectionDTO(record)
                ? JSON.parse(defaultIfEmptyOrNil("{}", record?.css))
                : {}
            }
          >
            {!isSectionDTO(record) ? (
              <div className={"flex items-center gap-x-2"}>
                <span>{record?.displayTitle}</span>
                {(record?.leakageTemplateRows?.length ?? 0) > 0 && (
                  <Tag color={"success"}>
                    {record?.leakageTemplateRows?.length} Terms
                  </Tag>
                )}
              </div>
            ) : (
              <div className={"flex items-center gap-x-2"}>
                <span className={"font-semibold"}>{record?.displayTitle}</span>
                {(record?.leakageTemplateRows?.length ?? 0) > 0 && (
                  <Tag color={"success"}>
                    {record?.leakageTemplateRows?.length} Terms
                  </Tag>
                )}
              </div>
            )}
          </span>
        );
      },
    },
    {
      title: tableColumnHeader("value", `px-4 py-2 `),
      className: `p-0 whitespace-nowrap `,
      width: "20%",
      render: (val, record) => {
        if (isSectionDTO(record)) {
          return <div className={"p-2"}>&nbsp;</div>;
        } else {
          const cell = getCell(record?.keyId);
          const props = getCellDataType(
            record?.leakageDataType,
            template?.enumMap,
            companyOptions
          );
          return (
            <EditableTableCell
              {...props}
              prefix={getPrefixByDataType(
                record?.leakageDataType,
                cell?.currency,
                (key, value) => onUpdateCell(record?.keyId, key, value)
              )}
              suffix={getSuffixByDataType(
                record?.leakageDataType,
                cell?.unit,
                (key, value) => onUpdateCell(record?.keyId, key, value)
              )}
              size={"small"}
              OId={"id"}
              value={cell?.value}
              bordered={false}
              defaultCell={false}
              editable={editable}
              onChange={(value) => {
                onUpdateCell(record?.keyId, "value", value);
              }}
            />
          );
        }
      },
    },
    {
      title: tableColumnHeader("Comments", `px-4 py-2 `),
      className: `p-0 whitespace-nowrap `,
      width: "25%",
      render: (_, record) => {
        if (isSectionDTO(record)) {
          return <div className={"p-2"}>&nbsp;</div>;
        } else {
          const cell = getCell(record?.keyId);
          return (
            <EditableTableCell
              size={"small"}
              OId={"id"}
              value={getCell(cell?.keyId)?.comments}
              bordered={false}
              defaultCell={false}
              editable={record?.commentAllowed && editable}
              onChange={(value) => {
                onUpdateCell(record?.keyId, "comments", value);
              }}
              type={"text"}
            />
          );
        }
      },
    },
  ];

  const onUpdateCell: OnUpdateCell = (keyId, id, value) => {
    const checkCurrentValue = data?.leakageElementKeyDataDTOMap?.[keyId];
    const params = {
      comments: (id === "comments"
        ? value
        : checkCurrentValue?.comments) as string,
      elementId: data?.elementId || "",
      elementType: data?.elementType as ElementType,
      keyId: keyId,
      templateId: data?.templateId || "",
      value: id === "value" ? (value as string) : checkCurrentValue?.value,
      unit: (id === "unit" ? value : checkCurrentValue?.unit) as UnitType,
      currency: (id === "currency"
        ? value
        : checkCurrentValue?.currency) as CurrencyType,
    };

    onUpdateData(params);
  };

  useEffect(() => {}, [data]);

  return (
    <Table<TemplateRowType>
      sticky={true}
      bordered={true}
      rowKey={(row) => (isSectionDTO(row) ? row.sectionId ?? "" : row.keyId)}
      pagination={false}
      columns={columns}
      dataSource={template?.leakageTemplateSections}
      className={"bg-transparent border"}
      expandable={{
        indentSize: 20,
        fixed: "right",
        childrenColumnName: "leakageTemplateRows",
        defaultExpandAllRows: true,
        expandIconColumnIndex: 1,
        rowExpandable: (record): boolean =>
          (record?.leakageTemplateRows ?? []).length > 0,
      }}
      scroll={{ x: true, y: windowHeight - 210 }}
      rowClassName={(record) =>
        `relative group ${
          isSectionDTO(record)
            ? "!bg-gray-100"
            : isNotNil(record.leakageTemplateRows)
            ? "!bg-white hover:bg-blue-50"
            : "hover:bg-blue-50"
        }`
      }
    />
  );
};

type LeakageAnalysisTableType = {
  template: LeakageTemplate;
  data: LeakageData | null; // Made mandatory
  onUpdateData: (params: LeakageDataRow) => void; // Corrected function type declaration
  elementId: string;
  companies: CompanyType[];
  editable: boolean;
};
type OnUpdateCell = (key: string, id: string, value: string | number) => void;
