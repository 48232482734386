import React, { FC, ReactNode, useEffect, useState } from "react";
import {
  AllocationKeyType,
  CapitalStructureValuesType,
  CovenantTableFieldDTO,
  CovenantTypeEnum,
  FieldType,
  PortfolioSectionFieldType,
  PortfolioSectionType,
} from "../../../../utils/types";
import { PortfolioInputField } from "../PortfolioInputField";
import { PortfolioCard } from "../PortfolioCard";
import { DisconnectOutlined, DownloadOutlined } from "@ant-design/icons";
import { EmptyPortfolioCard } from "../EmptyPortfolioCard";
import { InputFieldModal } from "../InputFieldModal";
import {
  addCovenantRow,
  createNewPortfolioSectionField,
  deleteCovenantRow,
  deletePortfolioSection,
  downloadTestScheduled,
  getAllFacilityKeys,
  getCovenantTestSchedule,
  removeSectionField,
  updateCovenantCellValue,
  updatePortfolioRank,
  updatePortfolioSectionField,
  updatePortfolioSectionFieldAnswer,
} from "../../../../services/services";
import { FileResponseType, ResponseType } from "../../../../utils/uiTypes";
import { useHistory, useParams } from "react-router";
import {
  getIndexByKey,
  updateItemInArray,
  valOrDefault,
} from "../../../../utils/utils";
import { Button, Divider, message, Table } from "antd";
import {
  assocPath,
  equals,
  findIndex,
  isEmpty,
  isNil,
  pathOr,
  propEq,
  sortBy,
} from "ramda";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import {
  AllocationTableType,
  AnalyticsCurrencyType,
  PermissionType,
  PortfolioSectionEnumType,
  SectionFieldType,
  UnitLabelType,
  UnitType,
} from "../../../../utils/enums";
import { initiateFetchSection } from "../../../../utils/portfolio";
import {
  ConfirmDelete,
  ConfirmDownload,
} from "../../../../utils/confirmationModals";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { AddCovenantModal } from "../../../transaction/menu/covenant/add-covenant-modal";
import { ACTION_BUTTON_CSS } from "../../../../utils/cssConfigs";
import { ColumnType } from "antd/lib/table";
import { ColumnsType } from "antd/es/table";
import EditableTableCell, {
  SelectOptionType,
} from "../../../general/EditableTableCell";
import "./styles/covenant-table-styles.css";
import { PortfolioAdminPermissionBlock } from "../../PortfolioAdminPermissionBlock";
import moment from "moment";

const R = require("ramda");

type CovenantSectionType = {
  permissions: PermissionType[];
};

export const Covenant: FC<CovenantSectionType> = ({ permissions }) => {
  const history = useHistory();
  const { height: windowHeight } = useWindowDimensions();
  const { portfolioId, sectionId }: ParamsType = useParams();
  const [section, setSection] = useState<PortfolioSectionType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchingTestSchedule, setFetchingTestSchedule] =
    useState<boolean>(false);
  const [addRowModal, setAddRowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [editMode, setEditMode] = useState<editModeType>({ edit: false });
  const [isNewInputOpen, setIsNewInputOpen] = useState<boolean>(false);
  const [portfolioSections, setPortfolioSections] = useState<
    PortfolioSectionFieldType[]
  >([]);
  const [testScheduleTableFields, setTestScheduleTableFields] = useState<
    CovenantTableFieldDTO[]
  >([]);
  const [allocationTableKeys, setAllocationTableKeys] = useState<
    AllocationKeyType[]
  >([]);

  const editPermission = permissions.includes(PermissionType.ADMIN_PORTFOLIO);

  const fetchSection = (sectionId: string): void => {
    setLoading(true);
    initiateFetchSection(sectionId, portfolioId)
      .then(setSection)
      .catch((e: string) => e && message.error(e))
      .then(() => setLoading(false));
  };

  const fetchTestSchedule = (): void => {
    setFetchingTestSchedule(true);
    getCovenantTestSchedule({ segments: { portfolioId, sectionId } })
      .then((data: ResponseType<CovenantTableFieldDTO[]>) => {
        setFetchingTestSchedule(false);
        setTestScheduleTableFields(data.data);
      })
      .catch((e: string) => {
        message.error(e);
        setFetchingTestSchedule(false);
      });
  };

  const onUpdate: onUpdateType = (field) => {
    updatePortfolioSectionFieldAnswer({
      body: JSON.stringify({ ...field, portfolioId }),
    })
      .then(() => {
        if (section) {
          setSection({
            ...section,
            portfolioSectionFieldDTOS: updateItemInArray(
              getIndexByKey(
                section?.portfolioSectionFieldDTOS ?? [],
                "id",
                field.id
              ),
              section.portfolioSectionFieldDTOS ?? [],
              field
            ),
          });
        }
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Something went wrong, input data not saved.", 3);
      });
  };

  const getMaxRank: getMaxRankType = (list = []) => {
    const ranks = list.map((v) => (v.rank ? Number(v.rank) : 0));
    return Math.max(...ranks);
  };
  const addNewField: addNewFieldType = (fieldData) => {
    if (!isEmpty(fieldData?.label?.trim())) {
      const maxRank = getMaxRank(section?.portfolioSectionFieldDTOS ?? []);
      createNewPortfolioSectionField({
        body: JSON.stringify({
          fieldName: fieldData.label,
          options: fieldData.options,
          portfolioSectionId: section?.id,
          required: true,
          sectionFieldType: fieldData.sectionFieldType,
          rank:
            (maxRank != null || maxRank != undefined) &&
            section &&
            section?.portfolioSectionFieldDTOS?.length > 0
              ? maxRank + 1
              : 0,
          portfolioId,
        }),
      })
        .then(({ data }: ResponseType<PortfolioSectionFieldType>) => {
          const portfolioSectionFieldDTOS = portfolioSections
            ? [...portfolioSections, data]
            : [data];
          if (section)
            setSection({
              ...section,
              portfolioSectionFieldDTOS,
            });
          setPortfolioSections(portfolioSectionFieldDTOS);
        })
        .catch((err: string): void => {
          console.error(err);
          message.error("Error creating new field.", 3);
        })
        .then((): void => {
          setIsNewInputOpen(false);
          setEditMode({ edit: true });
        });
    }
  };

  const updateSectionField: updateFieldType = (fieldData) => {
    updatePortfolioSectionField({
      body: JSON.stringify(fieldData),
    })
      .then(({ data }: ResponseType<PortfolioSectionFieldType>) => {
        const index = findIndex(propEq("id", data.id))(portfolioSections);
        if (index >= 0) {
          setPortfolioSections((list) => updateItemInArray(index, list, data));
          if (section)
            setSection({
              ...section,
              portfolioSectionFieldDTOS: updateItemInArray(
                index,
                portfolioSections,
                data
              ),
            });
        }
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Error updating field.", 3);
      })
      .then((): void => {
        setIsNewInputOpen(false);
        setEditMode({ edit: true });
      });
  };

  const removeField: onUpdateType = (fieldData) => {
    removeSectionField({
      body: JSON.stringify({ ...fieldData, portfolioId }),
    })
      .then(() => {
        const portfolioSectionFieldDTOS = portfolioSections
          ? portfolioSections.filter((sect) => sect.id !== fieldData.id)
          : [];
        if (section)
          setSection({
            ...section,
            portfolioSectionFieldDTOS,
          });
        setPortfolioSections(portfolioSectionFieldDTOS);
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Error removing field.", 3);
      })
      .then((): void => setIsNewInputOpen(false));
  };

  const getPortfolioStatus: getPortfolioStatusType = (
    portfolioSectionsList
  ) => {
    const statusField = portfolioSectionsList.find(
      (item) => item.fieldNameSystemLabel === "portfolioStatus"
    );
    const activeId = statusField?.options?.find(
      (option) => option.optionValueSystemLabel === "active"
    )?.id;
    return (
      !statusField?.selectedAnswerIds ||
      (statusField?.selectedAnswerIds &&
        statusField?.selectedAnswerIds[0] === activeId)
    );
  };

  const openEditField: onUpdateType = (fieldData) => {
    setEditMode({ ...editMode, field: fieldData });
    setIsNewInputOpen(true);
  };

  const onDragging = (oldIndex: number, newIndex: number): void => {
    setPortfolioSections((portfolios) => [
      ...R.move(oldIndex, newIndex, portfolios).map(
        (val: PortfolioSectionFieldType, index: number) => ({
          ...val,
          rank: index.toString(),
        })
      ),
    ]);
  };
  const onDrop = (): void => {
    const updateRankDTO = portfolioSections.map((item) => ({
      id: item.id,
      rank: item.rank,
      portfolioId,
      portfolioSectionId: section?.id,
    }));
    updatePortfolioRank({
      body: JSON.stringify(updateRankDTO),
    }).catch((err: string): void => {
      console.error(err);
      message.error("Error. Update not saved.", 3);
    });
  };

  const initiateSectionDelete = (sectionId: string): void => {
    ConfirmDelete("Confirm Remove Section?", () => {
      setLoading(true);
      deletePortfolioSection({ segments: { sectionId } })
        .then(() => {
          message.success("Section Removed Successfully! ");
          history.go(0);
        })
        .catch((error: string) => {
          message.error("Unable to Delete Section");
          console.error(error);
        })
        .then(() => setLoading(false));
    });
  };

  const handleAddRow = (name: string, type: string): void => {
    setSubmitting(true);
    addCovenantRow({ segments: { portfolioId, sectionId, name, type } })
      .then((data: ResponseType<CovenantTableFieldDTO[]>) => {
        setAddRowModal(false);
        setSubmitting(false);
        setTestScheduleTableFields(data.data);
      })
      .catch((error: string) => {
        message.error("Unable to Add Row");
        console.error(error);
        setSubmitting(false);
      });
  };

  const handleDeleteRow = (rowId: string): void => {
    setSubmitting(true);
    deleteCovenantRow({ segments: { portfolioId, sectionId, rowId } })
      .then((data: ResponseType<CovenantTableFieldDTO[]>) => {
        setSubmitting(false);
        setTestScheduleTableFields(data.data);
      })
      .catch((error: string) => {
        message.error("Unable to Delete Row");
        console.error(error);
      });
  };

  const handleUpdateCellValue = (
    fieldId: string,
    body: CapitalStructureValuesType
  ): void => {
    setSubmitting(true);
    updateCovenantCellValue({
      segments: { portfolioId, sectionId, fieldId },
      body: JSON.stringify(body),
    })
      .then(({ data }: ResponseType<CapitalStructureValuesType>) => {
        const index = findIndex(
          ({ id }) => id === fieldId,
          testScheduleTableFields
        );
        const valueIndex = findIndex(
          ({ id }) => id.toString() === data.id.toString(),
          testScheduleTableFields[index].testScheduleValues
        );
        setTestScheduleTableFields(
          assocPath(
            [index, "testScheduleValues", valueIndex],
            data,
            testScheduleTableFields
          )
        );
        setSubmitting(false);
      })
      .catch((error: string) => {
        message.error("Error. Update not saved.");
        console.error(error);
      });
  };

  useEffect(() => {
    if (section) {
      setPortfolioSections(
        section?.portfolioSectionFieldDTOS?.sort(
          (a, b) => Number(a?.rank) - Number(b?.rank)
        )
      );
    }
  }, [section]);

  useEffect(() => {
    setEditMode({ edit: false });
    fetchSection(sectionId);
    fetchTestSchedule();
    getAllFacilityKeys({}).then((data: ResponseType<AllocationKeyType[]>) => {
      setAllocationTableKeys(data.data);
    });
  }, [sectionId]);

  const getCovenantFieldTypeData = (
    type: SectionFieldType,
    tag: string,
    covenantType?: CovenantTypeEnum,
    fieldUnit?: UnitType,
    formula?: string
  ): FieldDataType | undefined => {
    const covenantTypeSuffix =
      covenantType === CovenantTypeEnum.MULTIPLE ? "x" : "";

    switch (type) {
      case "TEXT":
        return { type: "textarea" };
      case "DROP_BOX":
        return { type: "select" };
      case "CURRENCY":
        return { type: "select" };
      case "DATE":
        return { type: "date" };
      case "MONETARY":
        return !isEmpty(formula) && !isNil(formula)
          ? {
              type: "formula",
              unit: UnitLabelType[fieldUnit ?? UnitType.MILLION],
              currency: section?.reportingCurrency ?? "",
              formula,
            }
          : {
              type: "number",
              unit: UnitLabelType[fieldUnit ?? UnitType.MILLION],
              currency: section?.reportingCurrency ?? "",
            };
      case "NUMERIC":
        return {
          type: "number",
          unit: tag !== "headRoom" ? covenantTypeSuffix : fieldUnit,
          currency:
            covenantType === CovenantTypeEnum.CURRENCY
              ? section?.reportingCurrency
              : "",
        };
      case "LONG_TEXT":
        return { type: "textarea" };
    }
  };

  const getOptions = (
    type: SectionFieldType,
    dropdownOptions?: any[]
  ): SelectOptionType[] | undefined => {
    switch (type) {
      case "DROP_BOX":
        return dropdownOptions;
      case "CURRENCY":
        return dropdownOptions?.map((o: SelectOptionType) => {
          const value: string = pathOr(o.value, [o.key], AnalyticsCurrencyType);
          return { key: o.key, value };
        });
      case "LONG_TEXT":
        return undefined;
    }
  };

  const tableData = transformData(testScheduleTableFields);
  const groupedTableData = generateNewGroupedData(tableData);
  const sortedByLastQuarterEndDate =
    updateParentWithClosestChild(groupedTableData);

  const deleteCol: ColumnType<Record<string, CapitalStructureValuesType>> = {
    title: <div className="p-4 whitespace-nowrap bg-gray-700">&nbsp;</div>,
    dataIndex: "",
    key: "delete",
    width: 50,
    fixed: tableData.length > 0 ? "right" : false,
    align: "right" as const,
    className:
      "p-0 whitespace-nowrap  group-hover:bg-blue-50 transition-none !bg-gray-100",
    render: function actions(_: string, record): ReactNode {
      return (
        <span
          className={
            "relative flex flex-row flex-row-reverse items-center gap-x-3 pr-2"
          }
          onClick={(event): void => event.stopPropagation()}
        >
          <Button
            type={"text"}
            icon={<i className="fas fa-ellipsis-h" />}
            className={
              "absolute right-0 border-0 flex items-center px-2 text-gray-900 block opacity-50 group-hover:opacity-0 mr-2"
            }
          />
          <Button
            type={"text"}
            icon={<i className="fas fa-trash-alt" />}
            className={`${ACTION_BUTTON_CSS}  hover:text-primary opacity-0 group-hover:opacity-100`}
            onClick={(): void => {
              ConfirmDelete(
                "Are you sure you want to remove this row? This will erase all previously saved data.",
                () =>
                  handleDeleteRow(
                    pathOr("", ["covenant_type", "rank"], record)
                  ),
                <></>
              );
            }}
          />
        </span>
      );
    },
  };

  const getColumns = (
    data: CovenantTableFieldDTO[],
    editable: boolean
  ): ColumnsType<Record<string, CapitalStructureValuesType>> =>
    data.map(
      ({ label, fieldType, tag, rank, unit, id = "", options, formula }) => ({
        title: <div className={"ml-2 whitespace-nowrap"}>{label}</div>,
        className: `p-0 ${
          equals(Number(rank), 0) &&
          "!bg-gray-100 !z-20 flex flex-row items-center gap-x-1 whitespace-nowrap"
        }`,
        fixed: equals(Number(rank), 0) ? "left" : false,
        dataIndex: tag,
        key: id,
        width: 160,
        render: (data: CapitalStructureValuesType, record): ReactNode => {
          const facilityNameCol = tag === "facitlityName";
          const isHeadroom = tag === "headRoom";
          const currentValue = Number(record["current"]?.value ?? "1");
          const thresholdValue = Number(record["testThreshold"]?.value ?? "1");
          const dropdownOptions =
            isEmpty(options) || isNil(options) ? [] : JSON.parse(options);
          const covenantType = record["type"]?.value as CovenantTypeEnum;
          const type = getCovenantFieldTypeData(
            fieldType,
            tag,
            covenantType,
            unit,
            formula
          );
          const selectOptions = getOptions(fieldType, dropdownOptions);
          return (
            <EditableTableCell
              key={data?.id}
              editable={isHeadroom ? false : editable}
              bordered={false}
              OId={data?.id ?? ""}
              value={
                isHeadroom
                  ? (1 - currentValue / thresholdValue).toFixed(2)
                  : data.value
              }
              onChange={(val): void => {
                handleUpdateCellValue(id, {
                  ...data,
                  value: val.toString(),
                });
              }}
              defaultCell={false}
              placeholder={"-"}
              prefix={
                type?.currency && section?.reportingCurrency && !isHeadroom
                  ? section.reportingCurrency
                  : undefined
              }
              suffix={type?.unit}
              type={facilityNameCol ? "select" : type?.type}
              selectOptions={
                facilityNameCol
                  ? allocationTableKeys
                      .filter(
                        ({ allocationTableType, canDelete }) =>
                          allocationTableType ===
                            AllocationTableType.FINAL_HOLD && canDelete
                      )
                      .map(({ keyName }) => ({
                        value: keyName,
                        label: keyName,
                        key: keyName,
                      }))
                  : selectOptions
              }
              className={record["children"] ? "font-bold" : ""}
            />
          );
        },
      })
    );
  const onDownload = (): void => {
    message.loading({
      content: "Processing File",
      duration: 0,
      key: "download-" + name,
    });
    downloadTestScheduled({
      segments: {
        portfolioId,
        sectionId,
      },
    })
      .then(({ url, filename }: FileResponseType) => {
        setLoading(false);
        message.info({
          content: "File Ready to Download",
          key: "download-" + name,
        });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        setLoading(false);
        message.error({
          content: valOrDefault("Error Downloading File!", error),
          key: "download-" + name,
        });
      });
  };
  return (
    <React.Fragment>
      <PortfolioCard
        permissions={permissions}
        key={section?.id}
        title={section?.sectionName ?? "Information"}
        editMode={editMode.edit}
        loading={loading || fetchingTestSchedule}
        deleteMode={section?.sectionType === PortfolioSectionEnumType.CUSTOM}
        onClick={(): void => setEditMode(({ edit }) => ({ edit: !edit }))}
        onDelete={(): void => initiateSectionDelete(sectionId)}
      >
        {portfolioSections ? (
          <>
            <DndProvider backend={HTML5Backend}>
              <div className={"flex flex-col gap-y-1"}>
                {portfolioSections
                  ?.filter((field) =>
                    getPortfolioStatus(portfolioSections)
                      ? field.fieldNameSystemLabel !== "exitDate"
                      : true
                  )
                  .map((field, index) => (
                    <PortfolioInputField
                      key={field.id}
                      index={index}
                      onDragging={onDragging}
                      onDrop={onDrop}
                      field={field}
                      disabled={
                        !permissions.includes(PermissionType.ADMIN_PORTFOLIO)
                      }
                      onDelete={removeField}
                      onUpdate={onUpdate}
                      openEditField={openEditField}
                      customizeView={editMode.edit}
                    />
                  ))}
              </div>
            </DndProvider>
            {editMode.edit && (
              <div
                className={"px-4 py-1 w-full text-left md:w-1/3 md:text-right"}
              >
                <span
                  className={"cursor-pointer hover:underline text-blue-400"}
                  onClick={(): void => setIsNewInputOpen(true)}
                >
                  + New Data Field
                </span>
              </div>
            )}
          </>
        ) : (
          <EmptyPortfolioCard
            permissions={permissions}
            message={"No input fields in this section yet"}
            buttonText={"Add new data field"}
            onClick={(): void => setIsNewInputOpen(true)}
            image={
              <DisconnectOutlined className={"py-3 text-gray-400 text-7xl"} />
            }
          />
        )}

        <Divider></Divider>

        <div className="flex justify-between">
          <div className={"text-md font-medium"}>{"Test Schedule"}</div>
          <Button
            type={"text"}
            icon={<DownloadOutlined />}
            onClick={onDownload}
            className={`${ACTION_BUTTON_CSS}`}
            disabled={!sortedByLastQuarterEndDate.length}
          />
        </div>
        <Table
          loading={false}
          pagination={false}
          columns={[
            ...getColumns(
              sortBy(({ rank }) => rank, testScheduleTableFields),
              editPermission
            ),
            ...(editPermission ? [deleteCol] : []),
          ]}
          dataSource={sortedByLastQuarterEndDate}
          rowKey={(record, index): string =>
            pathOr("", ["key"], record) ?? index
          }
          scroll={{ x: "max-content", y: windowHeight / 3 }}
          bordered={true}
          className={"mt-4 shadow covenant-table allocation-table"}
          size={"middle"}
          locale={{
            emptyText: "No data in this table yet.",
          }}
          rowClassName={"group"}
          summary={(): ReactNode => (
            <Table.Summary fixed={true}>
              <PortfolioAdminPermissionBlock permissions={permissions}>
                <Table.Summary.Row className={"bg-gray-100"}>
                  <Table.Summary.Cell
                    className={"p-0 !z-20"}
                    key={"addRow"}
                    index={0}
                    colSpan={1}
                  >
                    <AddCovenantModal
                      open={addRowModal}
                      onClose={(): void => setAddRowModal(false)}
                      onOpen={(): void => setAddRowModal(true)}
                      onSubmit={(value): void => {
                        handleAddRow(value.name, value.type);
                      }}
                      isSubmitting={submitting}
                    />
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </PortfolioAdminPermissionBlock>
            </Table.Summary>
          )}
        />
      </PortfolioCard>
      <InputFieldModal
        isVisible={isNewInputOpen}
        editField={editMode.field ?? null}
        onUpdate={updateSectionField}
        onClose={(isOpen): void => {
          setIsNewInputOpen(isOpen);
          if (!isOpen && editMode.field)
            setEditMode({ ...editMode, field: undefined });
        }}
        onCreate={addNewField}
      />
    </React.Fragment>
  );
};

const generateNewGroupedData = (
  data: Record<string, CapitalStructureValuesType>[]
): Record<string, CapitalStructureValuesType>[] => {
  const parentMap: Record<string, any> = {};
  const childrenMap: Record<string, any[]> = {};
  const countMap: Record<string, number> = {};

  data.forEach((item) => {
    const covenantType = item.covenant_type?.value;

    if (covenantType) {
      if (!parentMap[covenantType]) {
        parentMap[covenantType] = {
          ...item,
          key: `parent-${covenantType}`,
        };
      }
      countMap[covenantType] = (countMap[covenantType] || 0) + 1;

      if (!childrenMap[covenantType]) {
        childrenMap[covenantType] = [];
      }
      childrenMap[covenantType].push({
        ...item,
        key: `child-${item.key}`,
      });
    }
  });

  return Object.keys(parentMap).map((covenantType) => {
    const parentRow = parentMap[covenantType];
    if (countMap[covenantType] > 1) {
      return {
        ...parentRow,
        children: childrenMap[covenantType],
      };
    }
    return parentRow;
  });
};

const transformData = (originalData: CovenantTableFieldDTO[]) => {
  const numRows = originalData[0]?.testScheduleValues.length || 0;
  const transformedData: any[] = [];

  for (let i = 0; i < numRows; i++) {
    const row: any = { key: (i + 1).toString() };

    originalData.forEach((field) => {
      const scheduleValue = field.testScheduleValues[i];
      if (scheduleValue) {
        row[field.tag] = scheduleValue;
      }
    });
    transformedData.push(row);
  }

  return transformedData;
};

const getLastQuarterEndDate = () => {
  const currentDate = moment();
  return currentDate
    .subtract(1, "quarter") // Move back one quarter
    .endOf("quarter");
};

const updateParentWithClosestChild = (data: any) => {
  const lastQuarterEndDate = getLastQuarterEndDate();

  return data.map((item: Record<string, any>) => {
    // If no children, skip the modification
    if (!item.children) {
      return item;
    }

    // Filter children that have a valid testDate and are before or on the last quarter end date
    const validChildren = item.children.filter(
      (child: Record<string, CapitalStructureValuesType>) => {
        const testDate = child.testDate?.value
          ? moment(Number(child.testDate.value))
          : null;
        return testDate && testDate.isSameOrBefore(lastQuarterEndDate);
      }
    );

    if (validChildren.length === 0) {
      return item; // If no valid children, return the original parent
    }
    // Find the child with the testDate closest to the last quarter end date
    const closestChild = validChildren.reduce(
      (
        closest: Record<string, CapitalStructureValuesType>,
        current: Record<string, CapitalStructureValuesType>
      ) => {
        // Ensure both closest and current children have valid testDate values
        const closestTestDate = closest.testDate?.value
          ? moment(Number(closest.testDate.value))
          : null;
        const currentTestDate = current.testDate?.value
          ? moment(Number(current.testDate.value))
          : null;

        // If either one is missing, skip the comparison and retain the valid one
        if (!closestTestDate) return current;
        if (!currentTestDate) return closest;

        // Compare dates and find the one closest to the last quarter end date
        return currentTestDate.isAfter(closestTestDate) ? current : closest;
      }
    );

    // Replace the parent with the closest child
    return {
      ...closestChild,
      key: `parent-${closestChild["covenant_type"]?.value}`,
      children: item.children, // Keep the children intact
    };
  });
};

type FieldDataType = {
  unit?: string;
  currency?: string;
  formula?: string;
  type:
    | "number"
    | "select"
    | "textarea"
    | "text"
    | "date"
    | "formula"
    | undefined;
};

type ParamsType = {
  portfolioId: string;
  sectionId: string;
};
type getMaxRankType = (list: PortfolioSectionFieldType[]) => number | undefined;
type onUpdateType = (field: PortfolioSectionFieldType) => void;
type addNewFieldType = (fieldData: FieldType) => void;
type updateFieldType = (fieldData: PortfolioSectionFieldType) => void;
type getPortfolioStatusType = (
  portfolioSectionsList: PortfolioSectionFieldType[]
) => boolean | undefined;
type editModeType = {
  edit: boolean;
  field?: PortfolioSectionFieldType;
};
