import React, { FC, ReactNode } from "react";
import { Badge, Layout, Menu } from "antd";
import { Route, Switch } from "react-router-dom";
import { MenuRouteType } from "../../utils/uiTypes";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { equals, flatten } from "ramda";
import { CustomSpin } from "../general/CustomSpin";
import { getMenu, getRoutes } from "../../utils/navigation";
import { Content } from "antd/es/layout/layout";

export const WithTopNav: FC<WithTopNavType> = ({
  title,
  menu,
  loading = false,
  defaultSelectedKeys,
  selectedKeys,
  defaultValue,
  gotoRoute,
  clipBody = false,
  height = "screen",
  showMenu = true,
}) => {
  return (
    <Layout className={`${equals(height, "screen") ? "h-screen" : "h-full"}`}>
      {title && (
        <div
          className={`pt-3 px-6 text-lg font-medium capitalize w-full block bg-white`}
        >
          {title}
        </div>
      )}
      {showMenu && (
        <Menu
          className={`w-full uppercase font-medium text-gray-400 px-2`}
          defaultValue={defaultValue}
          selectedKeys={selectedKeys}
          defaultSelectedKeys={defaultSelectedKeys}
          mode={"horizontal"}
          onClick={gotoRoute}
        >
          {getMenu(menu)}
        </Menu>
      )}
      <Content
        className={`relative flex-auto w-full h-full max-h-full ${
          height === "screen" ? "max-h-screen" : "max-h-full"
        } ${clipBody ? "overflow-y-clip" : "overflow-y-auto"}`}
      >
        <CustomSpin loading={loading} />
        <Switch>{flatten(getRoutes(menu))}</Switch>
      </Content>
    </Layout>
  );
};

type WithTopNavType = {
  title?: ReactNode;
  menu: MenuRouteType[];
  defaultSelectedKeys?: string[];
  selectedKeys?: string[];
  defaultValue?: string;
  gotoRoute: MenuClickEventHandler;
  clipBody?: boolean;
  height?: "screen" | "full";
  showMenu?: boolean;
  loading?: boolean;
};
