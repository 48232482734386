import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button, message, Spin, Table } from "antd";
import {
  AllocationElementType,
  AllocationTableType,
  CurrencySymbolType,
  CurrencyType,
  ElementType,
  PortfolioSectionEnumType,
  SectionFieldType,
  UnitLabelType,
  UnitType,
} from "../../../../utils/enums";
import {
  AllocationTableKeyType,
  calculateAllTotal,
  calculateTotalForColumn,
  calculateTotalForCompany,
  getAllocationDataInOrder,
  getAllocationFieldType,
  getAllocationFooterRow,
  getCellConfig,
  getInstitutionName,
  getNewAllocation,
  isFooter,
  onAllocationSort,
  onAllocationSortTotal,
  TableDataType,
} from "../../../../utils/allocation";
import {
  AllocationKeyType,
  AllocationSummaryWrapper,
  AllocationTableMappingType,
  AllocationType,
  CompanyType,
} from "../../../../utils/types";
import {
  getObjectFromPropertyPathValue,
  getObjectFromPropertyValue,
  getPercentage,
  groupByProperty,
  populateArrayWithPropertyPath,
  valOrDefault,
} from "../../../../utils/utils";
import {
  and,
  defaultTo,
  equals,
  flatten,
  isEmpty,
  isNil,
  lensPath,
  or,
  path,
  pathOr,
  set,
} from "ramda";
import { ColumnsType } from "antd/es/table";
import EditableTableCell from "../../../general/EditableTableCell";
import { ColumnType } from "antd/lib/table";
import {
  ConfirmDelete,
  ConfirmDownload,
} from "../../../../utils/confirmationModals";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import {
  AllocationFooter,
  InstitutionModalRenderType,
} from "./AllocationFooter";
import { AllocationToolbar } from "./AllocationToolbar";
import {
  ACTION_BUTTON_CSS,
  PRIMARY_BUTTON_STYLE,
} from "../../../../utils/cssConfigs";
import {
  createAllocations,
  deleteLenderFromTable,
  downloadAllocationSummary,
  getAllocationSummary,
  updateAllocationDate,
  updateAllocationKeyToTable,
  updateAllocations,
} from "../../../../services/services";
import {
  FileResponseType,
  ResponseType,
  voidType,
} from "../../../../utils/uiTypes";
import { Moment } from "moment";
import { TextOverFlowHandle } from "../../../general/TextOverFlowHandle";
import { Prompt, useParams } from "react-router";
import { useConfirmReload } from "../../../../customHooks/useConfirmReload";
import moment from "moment/moment";
import { AllocationComparison } from "./AllocationComparison";
import {
  AllocationSummaryModal,
  onDownloadType,
} from "./AllocationSummaryModal";

export const AllocationTable: FC<AllocationTablePropsType> = ({
  sectionType = PortfolioSectionEnumType.ALLOCATION,
  hasDate = false,
  pageType,
  editPermission = false,
  title = "",
  keys = [],
  loading = false,
  data = [],
  extraColumns = () => [],
  triggerUpdate = () => {},
  toolbar = false,
  peTransactionIdOrPortfolioId,
  config,
  extraOptions,
  tableHeight,
  institutionModal,
  companies = [],
  children = () => <></>,
  comparisonConfig,
  onCloseComparison,
}) => {
  const { height: windowHeight } = useWindowDimensions();
  const [tableData, setTableData] = useState<AllocationType[] | null>(null);
  const [tableConfig, setTableConfig] = useState<TableDataType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [summaryModalState, setSummaryModalState] = useState<{
    loading: boolean;
    data: AllocationSummaryWrapper | null;
    open: boolean;
    date?: string;
  }>({
    loading: false,
    data: null,
    open: false,
  });
  const { portfolioId, sectionId }: ParamsType = useParams();

  const [comparisonModalState, setComparisonModalState] = useState<{
    loading: boolean;
    data: [AllocationSummaryWrapper, AllocationSummaryWrapper] | null;
    open: boolean;
    mappings?: [AllocationTableMappingType, AllocationTableMappingType];
  }>({
    loading: false,
    data: null,
    open: false,
  });

  useConfirmReload(!!isUpdated, [isUpdated]);

  const onChangeValue = useCallback(
    (dataIndex: number) => (value: string | number) => {
      setTableData(
        (data) =>
          set(lensPath([dataIndex, "value"]), value, data) as AllocationType[]
      );
      setIsUpdated(true);
    },
    []
  );
  const totalColumn: totalColumnType = (data, mappings) => [
    {
      title: (
        <div className="p-4 text-white bg-gray-700 whitespace-nowrap">
          Total
        </div>
      ),
      className: "p-0 whitespace-nowrap",
      dataIndex: "total",
      width: 240,
      key: "total",
      sorter: (a: AllocationType[], b: AllocationType[]) => {
        return onAllocationSortTotal(a, b, mappings);
      },
      render: (_, record): ReactNode => {
        return (
          <EditableTableCell
            OId={`${record[0]?.elementId}-total`}
            value={
              isFooter(record[0]?.id)
                ? calculateAllTotal(data, mappings)
                : calculateTotalForCompany(record, mappings)
            }
            defaultCell={false}
            editable={false}
            type={"number"}
            placeholder={editPermission ? "Enter Value" : "-"}
            prefix={
              <span className={"text-muted"}>
                {CurrencySymbolType[record[0].currency]}
              </span>
            }
            suffix={
              <span className={"text-muted"}>
                {UnitLabelType[record[0].unit]}
              </span>
            }
            className={`${!isFooter(record[0]?.id) && "!bg-zinc-100"}`}
          />
        );
      },
    },
    {
      title: (
        <div className="p-4 text-white bg-gray-500 whitespace-nowrap">
          % of Total Sum
        </div>
      ),
      className: "p-0 whitespace-nowrap",
      dataIndex: "percent",
      width: 140,
      key: "percent",
      sorter: (a: AllocationType[], b: AllocationType[]) => {
        return onAllocationSortTotal(a, b, mappings);
      },
      render: (text: string, record): ReactNode => {
        return (
          !isFooter(record[0]?.id) && (
            <EditableTableCell
              OId={`${record[0]?.elementId}-total`}
              value={getPercentage(
                calculateTotalForCompany(record, mappings),
                calculateAllTotal(data, mappings)
              )}
              editable={false}
              defaultCell={false}
              type={"number"}
              placeholder={editPermission ? "Enter Value" : "-"}
              suffix={<span className={"text-muted"}>%</span>}
              className={`${!isFooter(record[0]?.id) && "!bg-zinc-50"}`}
            />
          )
        );
      },
    },
  ];

  const institutionColumn: institutionColumnType = (companies) => ({
    title: (
      <div className="p-4 text-white bg-gray-700 whitespace-nowrap">
        Institution
      </div>
    ),
    className: "p-0 whitespace-nowrap !z-20 !bg-gray-100",
    dataIndex: "lenderName",
    width: 240,
    fixed: true,
    key: "lenderName",
    render: (text: string, record): ReactElement => {
      return (
        <div
          className={`${
            !isFooter(record[0]?.id) && "!bg-zinc-100"
          } border border-transparent justify-between flex flex-row items-center gap-x-2 bg-transparent group-hover:bg-blue-50 py-3 px-5`}
        >
          <div className={`max-w-[200px]`}>
            <TextOverFlowHandle text={getInstitutionName(record, companies)} />
          </div>
          {and(editPermission, true) && !isFooter(record[0]?.id) && (
            <Button
              type={"text"}
              size={"small"}
              className={`${ACTION_BUTTON_CSS} hover:text-danger opacity-0 group-hover:opacity-100`}
              onClick={(): void => {
                ConfirmDelete(
                  "Are you sure you want to remove this institution? This will erase all previously saved data.",
                  () =>
                    deleteRow(
                      peTransactionIdOrPortfolioId,
                      pageType,
                      defaultTo("", config?.id)
                    )(record[0]?.elementId),
                  <></>
                );
              }}
            >
              <i className="fas fa-trash-alt" />
            </Button>
          )}
        </div>
      );
    },
  });

  const getNonCalculatingColumn: getAllocationColumnType = (allocationKeys) =>
    allocationKeys
      .filter(({ allocationKeyDTO }) => !allocationKeyDTO.canDelete)
      .map(({ allocationKeyDTO, id }) => ({
        title: (
          <div className="p-4 text-white bg-gray-500 whitespace-nowrap">
            {allocationKeyDTO.keyName}
          </div>
        ),
        width: 240,
        key: id,
        className: "p-0 whitespace-nowrap",
        render: (_, record): ReactNode => {
          const { valueIndex, dataIndex } = getCellConfig(
            id,
            record,
            tableData ?? []
          );
          const type =
            getAllocationFieldType[
              pathOr<SectionFieldType, SectionFieldType>(
                SectionFieldType.NUMERIC,
                ["fieldType"],
                getObjectFromPropertyValue("id", allocationKeyDTO.id, keys)
              )
            ];
          const props = {
            type,
            ...(type === "number"
              ? {
                  value: isFooter(record[0]?.id)
                    ? calculateTotalForColumn(id, tableData ?? [])
                    : Number(record[valueIndex]?.value ?? 0),
                  prefix: (
                    <span className={"text-muted"}>
                      {CurrencySymbolType[record[0].currency]}
                    </span>
                  ),
                  suffix: (
                    <span className={"text-muted"}>
                      {UnitLabelType[record[0].unit]}
                    </span>
                  ),
                }
              : { value: record[valueIndex]?.value ?? "" }),
          };
          return (
            or(
              !isFooter(record[0]?.id),
              and(isFooter(record[0]?.id), equals(type, "number"))
            ) && (
              <EditableTableCell
                loading={!isFooter(record[0]?.id) && isLoading}
                OId={record[valueIndex]?.id}
                editable={!isFooter(record[0]?.id) && editPermission}
                defaultCell={false}
                onChange={onChangeValue(dataIndex)}
                placeholder={editPermission ? "Enter Value" : "-"}
                {...props}
              />
            )
          );
        },
      }));

  const getCalculatingColumns: getAllocationColumnType = (allocationKeys) =>
    allocationKeys
      .filter(({ allocationKeyDTO }) => allocationKeyDTO.canDelete)
      .map(({ allocationKeyDTO, id }) => ({
        title: (
          <div className="p-4 text-white bg-gray-500 whitespace-nowrap">
            {allocationKeyDTO.keyName}
          </div>
        ),
        width: 240,
        key: id,
        className: "p-0 whitespace-nowrap",
        sorter: (a: AllocationType[], b: AllocationType[]) => {
          return onAllocationSort(a, b, id);
        },
        render: (_, record): ReactNode => {
          const { valueIndex, dataIndex } = getCellConfig(
            id,
            record,
            tableData ?? []
          );
          return (
            <EditableTableCell
              loading={!isFooter(record[0]?.id) && isLoading}
              OId={record[valueIndex]?.id}
              editable={!isFooter(record[0]?.id) && editPermission}
              value={
                isFooter(record[0]?.id)
                  ? calculateTotalForColumn(id, tableData ?? [])
                  : Number(record[valueIndex]?.value ?? 0)
              }
              defaultCell={false}
              onChange={onChangeValue(dataIndex)}
              type={"number"}
              placeholder={editPermission ? "Enter Value" : "-"}
              prefix={
                <span className={"text-muted"}>
                  {CurrencySymbolType[record[0].currency]}
                </span>
              }
              suffix={
                <span className={"text-muted"}>
                  {UnitLabelType[record[0].unit]}
                </span>
              }
            />
          );
        },
      }));

  const getColumns: getColumnsType = (data, mappings, extraColumns) => [
    institutionColumn(companies),
    ...getCalculatingColumns(mappings),
    ...totalColumn(data, mappings),
    ...getNonCalculatingColumn(mappings),
    ...extraColumns,
  ];

  const initiateCreateAllocations: initiateCreateAllocationsType = (
    data,
    elementId,
    allocationElementType,
    dataSaved
  ) => {
    return new Promise((resolve, reject) =>
      createAllocations({
        segments: {
          elementId,
          allocationElementType,
        },
        body: JSON.stringify(data),
      })
        .then(({ data }: ResponseType<AllocationType[]>) => {
          setTableData((tableData) => [
            ...(tableData ?? []),
            ...defaultTo([], data),
          ]);
          dataSaved && setIsUpdated(false);
          resolve();
        })
        .catch(() => {
          setIsLoading(false);
          message.error("Something went Wrong");
          reject();
        })
    );
  };

  const updateTableDate: updateTableDateType = (id, date) => {
    if (date) {
      setIsLoading(true);
      updateAllocationDate({
        body: JSON.stringify({
          id,
          elementId: peTransactionIdOrPortfolioId,
          allocationElementType: pageType,
          allocationDate: Date.parse(date?.format()),
        }),
      })
        .then(() => {
          setTableConfig((data) => {
            if (data) {
              return {
                ...data,
                allocationDate: Date.parse(date?.format()),
              };
            } else return null;
          });
          setIsLoading(false);
          triggerUpdate();
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const addKeysToTable: addKeysToTableType = (keys) => {
    return new Promise((resolve, reject) =>
      updateAllocationKeyToTable({
        segments: {
          peTransactionId: peTransactionIdOrPortfolioId,
          tablePEMappingId: tableConfig?.id ?? "",
          allocationElementType: pageType,
        },
        body: JSON.stringify(keys),
      })
        .then(({ data = [] }: ResponseType<AllocationTableKeyType[]>) => {
          setTableConfig((config) => {
            if (config) {
              return {
                ...config,
                allocationKeyTableMappingDTOS: data,
              };
            } else return null;
          });
          resolve(data);
        })
        .catch(() => {
          reject();
        })
    );
  };

  const initiateKeysUpdate: initiateKeysUpdate = (selected, keys, mappings) => {
    const deletedKeys: string[] = mappings
      .filter(({ allocationKeyDTO: { id } }) => !selected.includes(id))
      .map(({ id }) => id);

    const newKeys = selected.reduce<string[]>((previousValue, currentValue) => {
      if (
        !getObjectFromPropertyPathValue(
          ["allocationKeyDTO", "id"],
          currentValue,
          mappings
        )
      ) {
        return [...previousValue, currentValue];
      } else return previousValue;
    }, []);

    const columnDTOs: any[] = keys
      .filter(
        ({ id, canDelete }) =>
          selected.includes(id) && defaultTo(false, canDelete)
      )
      .map(({ id }) => ({
        allocationTableMappingId: tableConfig?.id ?? "",
        allocationKeyDTO: { id },
      }));

    setIsLoading(true);
    addKeysToTable(columnDTOs)
      .then((data) => {
        if (!isEmpty(newKeys)) {
          const elementIds = Array.from(
            new Set(
              populateArrayWithPropertyPath(["elementId"], tableData ?? [])
            )
          );
          initiateCreateAllocations(
            getNewAllocation(
              data,
              newKeys,
              elementIds,
              peTransactionIdOrPortfolioId,
              path<CurrencyType>([0, "currency"], tableData ?? []),
              path<UnitType>([0, "unit"], tableData ?? [])
            ),
            peTransactionIdOrPortfolioId,
            pageType,
            true
          );
        } else if (!isEmpty(deletedKeys)) {
          setTableData((data) =>
            (data ?? [])?.filter(
              ({ allocationKeyTableMappingId }) =>
                !deletedKeys.includes(allocationKeyTableMappingId)
            )
          );
        }
      })
      .then(() => setIsLoading(false))
      .then(triggerUpdate)
      .catch(() => {
        message.error("Something went wrong");
        setIsLoading(false);
      });
  };

  const onConfigurationChange: onConfigurationChangeType =
    (action) => (update) => {
      if (action === "currency") {
        initiateUpdateAllocation(
          defaultTo(
            [],
            tableData?.map((record) => ({
              ...record,
              currency: update as CurrencyType,
            }))
          ),
          pageType,
          peTransactionIdOrPortfolioId
        );
      } else if (action === "unit") {
        initiateUpdateAllocation(
          defaultTo(
            [],
            tableData?.map((record) => ({
              ...record,
              unit: update as UnitType,
            }))
          ),
          pageType,
          peTransactionIdOrPortfolioId
        );
      } else if (action === "allocationDate") {
        updateTableDate(tableConfig?.id ?? "", update);
      } else if (action === "keys") {
        initiateKeysUpdate(
          update,
          keys,
          tableConfig?.allocationKeyTableMappingDTOS ?? []
        );
      }
    };

  const deleteRow: deleteRowType =
    (peTransactionIdOrPortfolioId, allocationElementType, tableId) =>
    (rowId) => {
      setIsLoading(true);
      deleteLenderFromTable({
        segments: {
          peTransactionIdOrPortfolioId,
          allocationElementType,
          tableId,
        },
        body: JSON.stringify([rowId]),
      })
        .then(() => {
          setTableData((data) =>
            defaultTo([], data).filter(({ elementId }) => elementId !== rowId)
          );
          setIsLoading(false);
        })
        .catch(() => {
          message.error("Unable to Remove Institution From Allocation");
          setIsLoading(false);
        });
    };

  const initiateUpdateAllocation: initiateUpdateAllocationType = (
    data,
    allocationElementType,
    elementId
  ) => {
    setIsLoading(true);
    updateAllocations({
      segments: { elementId, allocationElementType: pageType },
      body: JSON.stringify(data),
    })
      .then(() => {
        message.success("Table saved successfully", 5);
        setTableData(() => data);
        setIsLoading(false);
        setIsUpdated(false);
      })
      .then(triggerUpdate)
      .catch(() => {
        message.error("Unable to save Allocations");
        setIsLoading(false);
      });
  };

  const onAddInstitutions: onAddInstitutionsType = (ids: string[]): void => {
    initiateCreateAllocations(
      flatten<Partial<AllocationType>>(
        ids.map((elementId) =>
          (tableConfig?.allocationKeyTableMappingDTOS ?? [])?.map(({ id }) => ({
            elementId,
            allocationElementType: AllocationElementType.LENDER_WITH_TX,
            peTransactionIdOrPortfolioId: peTransactionIdOrPortfolioId,
            currency: pathOr(
              CurrencyType.DOLLAR,
              ["0", "currency"],
              defaultTo([], tableData)
            ),
            unit: pathOr(
              UnitType.MILLION,
              ["0", "unit"],
              defaultTo([], tableData)
            ),
            allocationKeyTableMappingId: id,
            value: "",
          }))
        )
      ),
      peTransactionIdOrPortfolioId,
      pageType,
      false
    );
  };

  const fetchAllocationSummary = (date: string) => {
    setSummaryModalState({
      ...summaryModalState,
      loading: true,
    });
    getAllocationSummary({
      segments: {
        portfolioId: peTransactionIdOrPortfolioId,
        date: date,
        tableType: tableConfig?.allocationTableType,
      },
    })
      .then((data: ResponseType<AllocationSummaryWrapper>) => {
        setSummaryModalState({
          loading: false,
          open: true,
          data: data.data,
          date: date,
        });
      })
      .catch(() => {
        message.error("Unable to fetch summary");
        setSummaryModalState({
          ...summaryModalState,
          loading: false,
          open: false,
          data: null,
        });
      });
  };

  const onDownload: onDownloadType = (fxRateDate, currency) => {
    message.loading({
      content: "Processing File",
      duration: 0,
      key: "download-" + name,
    });

    downloadAllocationSummary({
      segments: {
        portfolioId: peTransactionIdOrPortfolioId,
        sectionId,
        fxRateDate,
        currency,
        type:
          sectionType === PortfolioSectionEnumType.ALLOCATION
            ? "FINAL_HOLD"
            : "FEES",
      },
    })
      .then(({ url, filename }: FileResponseType) => {
        message.info({
          content: "File Ready to Download",
          key: "download-" + name,
        });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        message.error({
          content: valOrDefault("Error Downloading File!", error),
          key: "download-" + name,
        });
      });
  };
  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    setTableConfig(config);
  }, [config]);

  return (
    <>
      {toolbar && (
        <div>
          <AllocationToolbar
            showSummary={false}
            sectionType={sectionType}
            showClosingDateSection={pageType === ElementType.PETRANSACTION}
            loading={isLoading}
            fetchingSummary={summaryModalState.loading}
            extraOptions={extraOptions}
            editPermission={editPermission}
            onSummary={(): void =>
              fetchAllocationSummary(moment(new Date()).format("YYYY-MM-DD"))
            }
            onSave={(): void =>
              setTableData((data) => {
                initiateUpdateAllocation(
                  data ?? [],
                  pageType,
                  peTransactionIdOrPortfolioId
                );
                return data;
              })
            }
            onChange={onConfigurationChange}
            keys={keys}
            config={{
              hasDate,
              type: config?.allocationTableType,
              allowSave: isUpdated,
              showSave: editPermission && defaultTo(0, tableData?.length) > 0,
            }}
            data={{
              currency: path(["0", "currency"], defaultTo([], tableData)),
              unit: pathOr(
                UnitType.MILLION,
                ["0", "unit"],
                defaultTo([], tableData)
              ),
              asOfDate: path(["allocationDate"], tableConfig ?? {}),
              title,
              selectedKeys: tableConfig?.allocationKeyTableMappingDTOS?.map(
                ({ allocationKeyDTO: { id } }) => id
              ),
            }}
            tableId={defaultTo("", config?.id)}
            totalCount={
              getAllocationDataInOrder(
                groupByProperty(defaultTo([], tableData), "elementId"),
                companies
              )?.length
            }
            peTransactionIdOrPortfolioId={peTransactionIdOrPortfolioId}
          />
        </div>
      )}
      <div className={"flex justify-between items-end gap-2"}>
        <div className={"flex flex-row gap-3"}>
          {children(isUpdated, isLoading)}
          {pageType === ElementType.PORTFOLIO && (
            <Button
              onClick={() =>
                fetchAllocationSummary(moment(new Date()).format("YYYY-MM-DD"))
              }
              loading={summaryModalState.loading}
              icon={summaryModalState.loading ? <Spin /> : null}
              className={"mt-2"}
            >
              Show Summary
            </Button>
          )}
        </div>
      </div>
      <Table<AllocationType[]>
        loading={or(isNil(tableData), isNil(tableConfig)) || loading}
        pagination={false}
        columns={getColumns(
          defaultTo([], tableData),
          defaultTo([], tableConfig?.allocationKeyTableMappingDTOS),
          extraColumns(
            defaultTo([], tableConfig?.allocationKeyTableMappingDTOS),
            defaultTo([], tableData)
          )
        )}
        rowKey={(record): string => pathOr("", [0, "id"], record)}
        dataSource={getAllocationDataInOrder(
          groupByProperty(defaultTo([], tableData), "elementId"),
          companies
        )}
        scroll={{ x: "max-content", y: tableHeight ?? windowHeight / 3 }}
        rowClassName={(record): string =>
          `group ${isFooter(record[0]?.id) && "font-bold"}`
        }
        bordered={false}
        className={`allocation-table ${toolbar ? "mt-4 shadow" : ""}`}
        size={"middle"}
        summary={(): ReactNode => (
          <Table.Summary fixed={true}>
            <AllocationFooter
              onAddInstitutions={onAddInstitutions}
              institutionModal={editPermission ? institutionModal : () => <></>}
              selectedCompanies={Array.from(
                new Set(
                  populateArrayWithPropertyPath(
                    ["elementId"],
                    defaultTo([], tableData)
                  )
                )
              )}
              editPermission={editPermission}
              rows={getAllocationFooterRow(defaultTo([], tableData))}
              data={defaultTo([], tableData)}
              disabledAddInstitution={
                !tableConfig?.allocationKeyTableMappingDTOS ||
                tableConfig?.allocationKeyTableMappingDTOS.length <= 0
              }
              columns={getColumns(
                defaultTo([], tableData),
                defaultTo([], tableConfig?.allocationKeyTableMappingDTOS),
                extraColumns(
                  defaultTo([], tableConfig?.allocationKeyTableMappingDTOS),
                  defaultTo([], tableData)
                )
              )}
            />
          </Table.Summary>
        )}
        locale={{
          emptyText:
            isEmpty(tableData) &&
            "There are no institutions in this table yet.",
        }}
      />
      <Prompt
        when={isUpdated}
        message={"Changes you made might not be saved!"}
      />
      {pageType === ElementType.PORTFOLIO && (
        <AllocationSummaryModal
          title={
            sectionType === PortfolioSectionEnumType.FEES
              ? "Fees Summary"
              : "Allocations Summary"
          }
          visible={summaryModalState?.open}
          tableData={summaryModalState?.data}
          fxRateDate={summaryModalState?.date ?? ""}
          onChangeDate={(date) => fetchAllocationSummary(date)}
          loading={summaryModalState.loading}
          onCancel={() =>
            setSummaryModalState({ ...summaryModalState, open: false })
          }
          onDownload={onDownload}
        />
      )}
      {pageType === ElementType.PORTFOLIO &&
        onCloseComparison &&
        comparisonConfig && (
          <AllocationComparison
            onClose={onCloseComparison}
            keys={keys}
            companies={companies}
            config={comparisonConfig.sort((a, b) => {
              const dateA = a.config?.allocationDate
                ? new Date(a.config.allocationDate).getTime()
                : new Date().getTime();

              const dateB = b.config?.allocationDate
                ? new Date(b.config.allocationDate).getTime()
                : new Date().getTime();

              return dateA - dateB;
            })}
            title={
              sectionType === PortfolioSectionEnumType.FEES
                ? "Fees Comparison"
                : "Allocations Comparison"
            }
          />
        )}
    </>
  );
};

type ExtraColumnType = (
  mappings: AllocationTableKeyType[],
  data: AllocationType[]
) => ColumnsType<AllocationType[]>;

type AllocationTablePropsType = {
  sectionType?: PortfolioSectionEnumType;
  pageType: ElementType.PETRANSACTION | ElementType.PORTFOLIO;
  peTransactionIdOrPortfolioId: string;
  hasDate?: boolean;
  title?: string;
  editPermission?: boolean;
  keys: AllocationKeyType[];
  config: TableDataType | null;
  loading?: boolean;
  data: AllocationType[];
  toolbar?: boolean;
  extraColumns?: ExtraColumnType;
  extraOptions?: ReactNode | ReactNode[];
  tableHeight?: number;
  triggerUpdate?: voidType;
  secondaryList?: CompanyType[];
  institutionModal?: InstitutionModalRenderType;
  companies?: CompanyType[];
  children?: (
    isUpdated: boolean,
    isLoading: boolean
  ) => ReactNode | ReactNode[];
  comparisonConfig?:
    | [AllocationComparisonType, AllocationComparisonType]
    | null;
  onCloseComparison?: VoidFunction;
};
type updateTableDateType = (id: string, date: Moment | null) => void;
type initiateKeysUpdate = (
  selected: string[],
  keys: AllocationKeyType[],
  mappings: AllocationTableKeyType[]
) => void;
type initiateCreateAllocationsType = (
  data: Partial<AllocationType>[],
  elementId: string,
  allocationElementType: ElementType.PETRANSACTION | ElementType.PORTFOLIO,
  dataSaved: boolean
) => Promise<void>;
type totalColumnType = (
  data: AllocationType[],
  mappings: AllocationTableKeyType[]
) => ColumnsType<AllocationType[]>;
type institutionColumnType = (
  companies: CompanyType[]
) => ColumnType<AllocationType[]>;
type getAllocationColumnType = (
  keys: AllocationTableKeyType[]
) => ColumnsType<AllocationType[]>;
type getColumnsType = (
  data: AllocationType[],
  mappings: AllocationTableKeyType[],
  extraColumns: ColumnsType<AllocationType[]>
) => ColumnsType<AllocationType[]>;
type addKeysToTableType = (
  keys: Partial<AllocationTableKeyType>[]
) => Promise<AllocationTableKeyType[]>;
type onConfigurationChangeType = (action: string) => (update: any) => void;
type deleteRowType = (
  peTransactionIdOrPortfolioId: string,
  allocationElementType: ElementType.PETRANSACTION | ElementType.PORTFOLIO,
  tableId: string
) => (rowId: string) => void;
type initiateUpdateAllocationType = (
  data: AllocationType[],
  allocationElementType: ElementType.PETRANSACTION | ElementType.PORTFOLIO,
  elementId: string
) => void;
type onAddInstitutionsType = (ids: string[]) => void;

export type AllocationComparisonType = {
  config: TableDataType;
  tableKeys: AllocationKeyType[];
  data: AllocationType[];
};
type ParamsType = {
  portfolioId: string;
  sectionId: string;
};
