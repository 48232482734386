import React, { FC } from "react";

import { Button, Space } from "antd";
import { ManageDealTeam } from "../menu/ManageDealTeam";
import { NewTransactionPaginationType } from "./NewTransaction";

export const NewTransactionTeam: FC<NewTransactionPaginationType> = function ({
  onNext,
  onBack,
  transactionId,
}: NewTransactionPaginationType) {
  return transactionId ? (
    <div className={"flex flex-col h-full bg-gray-100"}>
      <div className={"h-full"}>
        <ManageDealTeam />
      </div>
      <Space className={"gap-2 justify-center"}>
        {onBack && (
          <Button
            type={"default"}
            className={
              "w-full mt-5 bg-white border hover:border-primary hover:text-primary"
            }
            onClick={onBack}
          >
            Go Back
          </Button>
        )}
        <Button
          className={`w-28 mt-5 bg-primary hover:bg-hover text-white border-0`}
          onClick={onNext}
        >
          Next Step
        </Button>
      </Space>
    </div>
  ) : (
    <></>
  );
};
