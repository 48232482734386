import { ElementTag } from "../../../../company/tags/ElementTag";
import React, { FC, useEffect, useState } from "react";
import { LenderType, MileStoneType, TagType } from "../../../../../utils/types";
import { useDragDropManager, useDrop } from "react-dnd";
import { TrackersTypes } from "../../../../../utils/uiTypes";
import { TrackerLenderRow } from "./TrackerLenderRow";
import { equals, isEmpty, path } from "ramda";
import { BankOutlined } from "@ant-design/icons";
import { Collapse, Tag } from "antd";
import { tableColumnHeader } from "../../../../../utils/componentUtils";
import CollapsePanel from "antd/es/collapse/CollapsePanel";

export const TrackerTagRow: FC<TrackerTagRowType> = ({
  lenders,
  tag,
  canEdit = false,
  loading,
  toggleMilestoneData,
  onDrop,
  transactionId,
  showNotes,
  shuffling,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const dragDropManager = useDragDropManager();

  const [{ canDrop, isOver, didDrop }, drop] = useDrop(
    () => ({
      accept: TrackersTypes.LENDER,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        didDrop: monitor.didDrop(),
      }),
      canDrop: (item: DropDataType) =>
        !equals(tag?.id, path(["tagDTO", "id"], item)),
      drop: (item: DropDataType) => onDrop({ ...item, tagDTO: tag }),
    }),
    [TrackersTypes.LENDER]
  );

  useEffect(() => {
    setIsDragging(() => dragDropManager?.getMonitor()?.isDragging());
    return () => {
      setIsDragging(() => false);
    };
  }, [dragDropManager?.getMonitor()?.isDragging(), didDrop]);

  return (
    <div
      ref={drop}
      className={`transition-all duration-300 px-1 w-full ease-out ${
        isDragging && canDrop && "py-1"
      }`}
    >
      <Collapse
        defaultActiveKey={1}
        bordered={false}
        className={`tracker-row transition-all duration-300 ${
          canDrop &&
          isOver &&
          "bg-blue-100 border-dashed border shadow z-50 scale-[1.005]"
        } ${isDragging && !canDrop && "bg-gray-100 opacity-50"} ${
          isDragging && canDrop && "bg-blue-50 shadow z-10"
        }`}
        destroyInactivePanel={false}
      >
        <CollapsePanel
          forceRender={true}
          key={1}
          className={""}
          extra={
            <Tag icon={<BankOutlined />} color={"blue"} className={"ml-auto"}>
              {lenders?.length ?? 0}
            </Tag>
          }
          header={
            tag ? (
              <ElementTag tooltipPlacement={"right"} tag={tag} />
            ) : (
              tableColumnHeader("No Tag")
            )
          }
        >
          {lenders.map(({ name, id, milestoneDTOs, userDTOs = [] }, index) => (
            <TrackerLenderRow
              tag={tag}
              key={id}
              showNotes={showNotes}
              transactionId={transactionId}
              loading={loading}
              shuffling={shuffling(id)}
              canEdit={canEdit}
              milestones={milestoneDTOs}
              toggleMilestoneData={toggleMilestoneData}
              name={name}
              id={id}
              usersCount={(userDTOs ?? [])?.length}
            />
          ))}
          {isEmpty(lenders) && (
            <div className={"text-gray-400 pb-2 flex w-full pl-5"}>
              No Institutions!
            </div>
          )}
        </CollapsePanel>
      </Collapse>
    </div>
  );
};

type DropDataType = {
  lenderId: string;
  usersCount: number;
  tagDTO: TagType | null;
};

type TrackerTagRowType = {
  showNotes: boolean;
  milestones: MileStoneType[];
  lenders: LenderType[];
  tag: TagType | null;
  canEdit: boolean | null;
  transactionId: string;
  loading: (lenderId: string, milestoneId: string) => boolean;
  shuffling: (lenderId: string) => boolean;
  toggleMilestoneData: toggleMilestoneDataType;
  onDrop: (data: DropDataType) => void;
  isDragging?: boolean;
};
type toggleMilestoneDataType = (lenderId: string, milestoneId: string) => void;
