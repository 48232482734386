import React, { FC, ReactElement, useState } from "react";
import { Button, Form, Input, message, Modal, Tooltip } from "antd";
import { CompanyType } from "../../../../utils/types";
import { createInstitution } from "../../../../services/services";
import { ResponseType } from "../../../../utils/uiTypes";
import {
  getModalContainerForFullScreen,
  getPopupContainerForSelect,
} from "../../../../utils/container";
import { flatten, isEmpty, map, pathOr } from "ramda";
import { FuzzySearchSelect } from "../../../general/FuzzySearchSelect";

const getOptionsList: GetOptionsListFnType = (listSecondary, companies) =>
  flatten([
    !isEmpty(listSecondary)
      ? [{ label: "INSTITUTIONS IN TRANSACTION", key: 0, disabled: true }]
      : [],
    map(({ name, id }) => ({ label: name, key: id }), companies),
    !isEmpty(listSecondary)
      ? [
          { label: "OTHER INSTITUTIONS", key: 1, disabled: true },
          ...map(({ name, id }) => ({ label: name, key: id }), listSecondary),
        ]
      : [],
  ]);

export const AddAllocationModal: FC<PropType> = function ({
  disabled,
  onSubmit,
  companies = [],
  listSecondary = [],
  onAddCompany,
}: PropType) {
  const [selected, setSelected] = useState<Array<string>>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const addCompany = (companyName: { name: string }): void => {
    createInstitution({
      body: JSON.stringify(companyName),
    })
      .then(({ data }: ResponseType<CompanyType>) => {
        onAddCompany && onAddCompany(data);
      })
      .then(() => {
        setModalOpen(false);
        setVisible(true);
        message.success("Added New Institution to the list");
      })
      .catch((error: string) => {
        message.error(error ? error : "Error adding new Institution!");
      });
  };

  return (
    <>
      <Tooltip
        className={"w-fit"}
        title={disabled ? "Please select columns first" : ""}
      >
        <Button
          type={"text"}
          disabled={disabled}
          hidden={isEmpty(companies) && isEmpty(listSecondary)}
          className={
            "text-left pl-5 border-0 text-primary disabled:border-opacity-60 font-medium w-full hover:bg-blue-50"
          }
          onClick={(): void => setVisible(true)}
        >
          + Add Institutions
        </Button>
      </Tooltip>
      <Modal
        getContainer={getModalContainerForFullScreen}
        title="Choose the institutions you wish to add to the table"
        open={visible}
        onCancel={(): void => setVisible(false)}
        onOk={(): void => {
          onSubmit(selected);
          setVisible(false);
        }}
        okButtonProps={{
          className: "bg-primary hover:bg-hover text-white border-0",
        }}
        afterClose={(): void => setSelected([])}
        destroyOnClose={true}
      >
        <FuzzySearchSelect<OptionType>
          notFoundContent={"No Institutions Available"}
          getPopupContainer={getPopupContainerForSelect}
          mode="multiple"
          value={selected}
          onChange={setSelected}
          placeholder={"Select institution"}
          className={"w-full"}
          items={getOptionsList(listSecondary, companies)}
          getOptionKey={pathOr("", ["key"])}
          getOptionLabel={pathOr("", ["label"])}
          getOptionValue={pathOr("", ["key"])}
          getAbbrevationPath={pathOr("", ["label"])}
          isOptionDisabled={(item) => item.disabled || false}
          isOptionHidden={(item) => item.disabled || false}
          searchPaths={["label"]}
          showArrow={true}
          autoFocus={true}
          maxTagCount={"responsive"}
          dropdownRender={(menu): ReactElement => {
            return (
              <div>
                {menu}
                {onAddCompany && (
                  <div
                    className={
                      "flex mx-auto w-full mb-0 p-2 border-t-1 border-b-0 border-r-0 border-l-0 border-blue-500 bg-blue-100 hover:bg-blue-500 hover:text-white"
                    }
                    onMouseDown={(e): void => {
                      e.preventDefault();
                      setModalOpen(true);
                    }}
                  >
                    Create New Institution
                  </div>
                )}
              </div>
            );
          }}
        />
      </Modal>
      <Modal
        open={modalOpen}
        afterClose={(): void => {
          setModalOpen(false);
          setVisible(true);
        }}
        title={"Create New Institution"}
        closable={true}
        footer={false}
        destroyOnClose={true}
        onCancel={(): void => setModalOpen(false)}
      >
        <Form
          onFinish={(values): void => addCompany(values)}
          className={"text-right"}
        >
          <Form.Item name={"name"} label={"Institution Name"}>
            <Input placeholder={"Name"} />
          </Form.Item>
          <Form.Item className={"w-full mb-0"}>
            <Button
              onClick={(): void => {
                setModalOpen(false);
                setVisible(true);
              }}
            >
              Cancel
            </Button>
            <Button
              className={"ml-2 bg-primary hover:bg-hover text-white border-0"}
              htmlType={"submit"}
            >
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

type OptionType = {
  label: string;
  key: string | number;
  disabled?: boolean;
};

type GetOptionsListFnType = (
  listSecondary: Array<CompanyType>,
  companies: Array<CompanyType>
) => Array<OptionType>;

type PropType = {
  disabled: boolean;
  onSubmit: (a: Array<string>) => void;
  companies: Array<CompanyType>;
  listSecondary?: Array<CompanyType>;
  onAddCompany?: (a: CompanyType) => void;
};
