import React, { useEffect, useState } from "react";
import { AnalyticsConfigType, AnalyticsType } from "../../utils/types";
import {
  fetchWithIdType,
  LoadingType,
  ResponseType,
} from "../../utils/uiTypes";
import { RelationshipCard } from "./RelationshipCard";
import { useLocation } from "react-router-dom";
import { AnalyticsSection } from "../analytics/AnalyticsSection";
import { getAnalyticsDashboard } from "../../services/services";
import { message } from "antd";
import { useParams } from "react-router";
import { ParamsType } from "../../utils/relationship";

export const RelationshipInsights = () => {
  const { id } = useParams<ParamsType>();
  const { search } = useLocation();
  const [config, setConfig] = useState<AnalyticsConfigType>();
  const [loading, setLoading] = useState<LoadingType>({
    loading: false,
    label: "",
  });
  const [params, setParams] = useState<string | null>(null);

  const fetchAnalyticsDashboard: fetchWithIdType = (params) => {
    setLoading({ loading: true, label: "Loading Analytics" });

    getAnalyticsDashboard({
      params: params + "&name=CRM",
    })
      .then(({ data }: ResponseType<AnalyticsType>) => {
        setConfig(JSON.parse(data.config)?.sections[0] ?? null);
      })
      .then(() => {
        setLoading({ loading: false });
      })
      .catch((error: string) => {
        setLoading({ loading: false });
        message.error(error ?? "Error Loading Analytics");
      });
  };

  const updateParams = (search: string) => {
    const params = new URLSearchParams(search);
    params.append("crmMappingId", id);
    setParams(() => params.toString());
    fetchAnalyticsDashboard(params.toString());
  };

  useEffect(() => {
    updateParams(search);
  }, [search]);

  return (
    <RelationshipCard label={"Insights"}>
      <div className={"flex flex-wrap pt-2 overflow-y-auto"}>
        {!loading.loading && params && config && (
          <AnalyticsSection isActive={true} config={config} params={params} />
        )}
      </div>
    </RelationshipCard>
  );
};
