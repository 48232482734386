import React, { FC, ReactNode, useRef, useState } from "react";
import { Button, Menu, Tooltip } from "antd";

export const ColumnHeaderCell: FC<ColumnHeaderCellType> = function ({
  content = "",
  selected = false,
  width = 200,
  onSelect,
  editColumn = false,
  onClear = () => {},
  onResize = () => {},
  onWidthChange,
}: ColumnHeaderCellType) {
  const [x, setX] = useState<number>(width);
  const [drag, setDrag] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [initialPos, setInitialPos] = useState<number | null>(null);
  const [initialSize, setInitialSize] = useState<number | null>(null);

  const getDealTeamUnpublishedMenu = () => {
    return (
      <Menu>
        <Menu.Item
          icon={<i className="fa fa-eraser"></i>}
          key="CLEAR_COLUMN"
          onClick={() => {
            setDropdownVisible(false);
            onClear();
          }}
        >
          Clear Column
        </Menu.Item>
        {/*  <Menu.Item
          key="RESIZE_COLUMN"
          onClick={() => {
            setDropdownVisible(false);
            onResize();
          }}
        >
          Resize Column
        </Menu.Item>*/}
      </Menu>
    );
  };
  return (
    <div
      ref={ref}
      role={"button"}
      onClick={onSelect}
      className={`select-none group text-xs ${
        selected ? "bg-gray-500 text-white" : "bg-gray-50 text-secondary"
      }`}
      style={{ width: x, height: 35 }}
    >
      <div
        role={"button"}
        draggable={true}
        onDragStart={(e) => {
          e.dataTransfer.setDragImage(new Image(), 0, 0);
          const resizable = ref.current;
          if (resizable) {
            setInitialPos(e.clientX);
            setInitialSize(resizable.offsetWidth);
          }
          setDrag(true);
        }}
        onDrag={(e) => {
          if (initialPos && initialSize) {
            const value = initialSize + e.clientX - initialPos;
            if (value > 200) {
              setX(value);
            }
          }
        }}
        onDragEnd={() => {
          setDrag(false);
          onWidthChange(x);
        }}
        className={`cursor-move opacity-0 group-hover:opacity-100 cursor-e-resize float-right h-full shadow-xs w-2 bg-primary ${
          drag && "opacity-100"
        }`}
      >
        <div
          className={`${drag && "relative h-screen ml-2 w-px bg-primary"}`}
          style={{ zIndex: 2000 }}
        />
      </div>
      <div
        className={
          "group flex justify-between items-center h-full font-medium px-2"
        }
      >
        {content}
        <div
          className={`hover:opacity-100 ${
            dropdownVisible ? "opacity-100" : "opacity-50"
          }`}
        >
          {editColumn && (
            <Tooltip placement={"top"} title={"Clear Column Content"}>
              <Button
                className={`bg-transparent ${selected && "text-white"}`}
                icon={<i className="fa fa-eraser"></i>}
                key="CLEAR_COLUMN"
                type={"text"}
                onClick={() => {
                  setDropdownVisible(false);
                  onClear();
                }}
              />
            </Tooltip>
            // <Dropdown
            //   onVisibleChange={setDropdownVisible}
            //   overlay={getDealTeamUnpublishedMenu}
            //   placement={"bottomRight"}
            // >
            //   <div
            //     role={"button"}
            //     className={`inline-flex justify-center items-center bg-gray-50 hover:text-primary hover:border-primary border px-1 py-0.5 rounded-sm ${
            //       dropdownVisible ? "text-primary border-primary" : "text-dark"
            //     }`}
            //   >
            //     <CaretDownOutlined className={"text-xxs"} />
            //   </div>
            // </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
};

type ColumnHeaderCellType = {
  content: ReactNode | string;
  selected?: boolean;
  width?: number;
  onSelect: () => void;
  editColumn?: boolean;
  onClear?: () => void;
  onResize?: () => void;
  onWidthChange: (o: number) => void;
};
