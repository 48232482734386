import React, { FC, useContext } from "react";
import { HandleMilestones } from "./HandleMilestones";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { usePageTitle } from "../../../../customHooks/usePageTitle";
import { TransactionContext } from "../../../../context/TransactionContext";

export const ManageTrackers: FC = function () {
  usePageTitle("Tracker");

  const { transaction, permissions, setTransaction } =
    useContext(TransactionContext);

  return (
    <div
      className={
        "relative mx-auto p-6 h-full max-h-full overflow-y-auto flex flex-col w-full"
      }
    >
      <DndProvider backend={HTML5Backend}>
        <div>
          <p className={"text-xl font-medium"}>Tracker</p>
        </div>

        {transaction && (
          <div className={"h-full "}>
            <HandleMilestones
              milestonesTemplateId={transaction.milestonesTemplateId}
              peTransactionId={transaction.peTransactionId}
              permissions={permissions}
            />
          </div>
        )}
      </DndProvider>
    </div>
  );
};
