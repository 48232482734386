import React, { FC } from "react";
import { voidType } from "../../utils/uiTypes";
import {
  DANGER_BUTTON_STYLE,
  PRIMARY_BUTTON_STYLE,
} from "../../utils/cssConfigs";
import { PDFFileViewer } from "../general/PDFFileViewer";
import { Button, Modal } from "antd";
import useWindowDimensions from "../../customHooks/useWindowDimensions";
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

export const ClickThroughConfirmationModal: FC<NDAConfirmationModalType> = ({
  ndaConfigId,
  loading,
  onAction,
  transactionId,
  fileId,
}) => {
  const { height } = useWindowDimensions();

  return (
    <Modal
      open={true}
      centered={true}
      closable={false}
      destroyOnClose={true}
      title={false}
      width={"90%"}
      cancelText={"Reject"}
      bodyStyle={{ height: height - 100, padding: 4 }}
      footer={
        <div className={"flex flex-row items-center"}>
          <Button
            icon={<DownloadOutlined />}
            rel={"noreferrer"}
            target={"_blank"}
            href={`/api/rest/media/${fileId}?ndaConfigId=${ndaConfigId}&transactionId=${transactionId}`}
            className={PRIMARY_BUTTON_STYLE}
          >
            DOWNLOAD
          </Button>
          <Button
            onClick={() => onAction(false)}
            disabled={loading}
            icon={<CloseOutlined />}
            className={`ml-auto ${DANGER_BUTTON_STYLE}`}
          >
            DECLINE
          </Button>
          <Button
            onClick={() => onAction(true)}
            loading={loading}
            icon={<CheckOutlined />}
            className={PRIMARY_BUTTON_STYLE}
          >
            ACCEPT
          </Button>
        </div>
      }
    >
      <PDFFileViewer
        loadingText={"Loading document ..."}
        url={`${window.location.origin}/api/rest/media/${fileId}?ndaConfigId=${ndaConfigId}&transactionId=${transactionId}`}
      />
    </Modal>
  );
};

type NDAConfirmationModalType = {
  fileId: string;
  ndaConfigId: string;
  transactionId: string;
  onAction: (accepted: boolean) => void;
  loading: boolean;
};
