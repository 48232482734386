import React, { FC } from "react";
import { DashboardNotificationType } from "../../../../utils/types";
import { NotificationActivityEnumType } from "../../../../utils/enums";
import { DashboardNotifications } from "./DashboardNotifications";
import { Card } from "antd";
import { pathOr } from "ramda";

export const NotificationsCard: FC<NotificationCardType> = function ({
  icon,
  notifications,
  title,
  setSelected,
  ActivityEnum,
  id,
}: NotificationCardType) {
  return (
    <>
      <Card
        className={"max-h-72 min-w-[19rem] h-72"}
        bodyStyle={{
          padding: "20px",
          maxHeight: "260px",
          overflowY: "auto",
        }}
        title={
          <>
            <span className={"text-gray-400"}>{title}</span>
            {notifications &&
              notifications[ActivityEnum] &&
              notifications[ActivityEnum].activityDTOs.length > 4 && (
                <span
                  className={"text-blue-400 cursor-pointer float-right"}
                  onClick={(): void => {
                    setSelected({
                      notificationType: ActivityEnum,
                      name: title,
                    });
                  }}
                >
                  + SEE ALL
                </span>
              )}
          </>
        }
        size="small"
      >
        <DashboardNotifications
          title={title.toLocaleLowerCase()}
          icon={icon}
          limit={true}
          notifications={pathOr(
            [],
            [ActivityEnum, "activityDTOs"],
            notifications ?? {}
          )}
          id={id}
        />
      </Card>
    </>
  );
};

type NotificationCardType = {
  icon: string;
  notifications: DashboardNotificationType | null;
  title: string;
  ActivityEnum: NotificationActivityEnumType;
  setSelected: ({
    notificationType,
    name,
  }: {
    notificationType: NotificationActivityEnumType;
    name: string;
  }) => void;
  id: string;
};
