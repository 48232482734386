import React, { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import {
  downloadAnalyticsData,
  generateAnalyticsData,
} from "../../services/services";
import { FileResponseType, ResponseType } from "../../utils/uiTypes";
import { message } from "antd";
import { AnalyticsChartType } from "../../utils/enums";
import { AnalyticsChart } from "./AnalyticsChart";
import { AnalyticsSingleValue } from "./AnalyticsSingleValue";
import { ConfirmDownload } from "../../utils/confirmationModals";
import { valOrDefault } from "../../utils/utils";
import { AnalyticsFilters, InstitutionType } from "../../utils/types";
import { InternalFilters } from "../portfolio/insights/InternalFilters";
import { AnalyticsTableWrapper } from "./AnalyticsTableWrapper";

const parseParams = (filters: Array<string>): string => {
  return new URLSearchParams([
    ...(filters ?? []).map((val) => ["filterBy", `Lender_Company_Id:${val}`]),
  ]).toString();
};
export const AnalyticsData: FC<AnalyticsDataType> = ({
  name,
  label,
  chartType,
  params,
  showActionItems = true,
  footer = false,
  filterOptions,
  localFilters,
}) => {
  const [data, setData] = useState<DataType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [institutions, setInstitutions] = useState<Array<string>>([]);

  const fetchAnalyticsData: fetchAnalyticsDataType = (params, name) => {
    setLoading(true);
    generateAnalyticsData({
      segments: {
        type: "generate",
      },
      params: params + `&name=${name}`,
    })
      .then(({ data }: ResponseType) => {
        setData(data);
        setLoading(false);
      })
      .catch((error: string) => {
        setLoading(false);
        setData(null);
        console.error(error ?? `Error fetching ${label}`);
      });
  };

  const DownloadAnalyticsData = (): void => {
    message.loading({
      content: "Processing File",
      duration: 0,
      key: "download-" + name,
    });
    downloadAnalyticsData({
      segments: {
        type: "csv",
      },
      params: params + `&name=${name}`,
    })
      .then(({ url, filename }: FileResponseType) => {
        setLoading(false);
        message.info({
          content: "File Ready to Download",
          key: "download-" + name,
        });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        setLoading(false);
        message.error({
          content: valOrDefault("Error Downloading File!", error),
          key: "download-" + name,
        });
      });
  };

  useEffect(() => {
    fetchAnalyticsData(
      params
        ? `${params}${
            institutions.length > 0 ? `&${parseParams(institutions)}` : ""
          }`
        : institutions.length > 0
        ? `&${parseParams(institutions)}`
        : "",
      name
    );
  }, [params, name, institutions]);

  const renderAnalyticsData: renderAnalyticsDataType = ({
    chartType,
    data,
    loading,
    params,
    footer,
    showActionItems,
  }): ReactNode => {
    switch (chartType) {
      case AnalyticsChartType.TABLE:
        return (
          <AnalyticsTableWrapper
            name={name}
            data={data}
            params={params}
            loading={loading}
            label={label}
            onRefresh={(): void => fetchAnalyticsData(params ?? "", name)}
            onDownload={DownloadAnalyticsData}
            footer={footer}
            showActionItems={showActionItems}
          />
        );
      case AnalyticsChartType.SINGLE_VALUE:
        return (
          <AnalyticsSingleValue
            data={data}
            loading={loading}
            label={label}
            onRefresh={(): void => fetchAnalyticsData(params ?? "", name)}
          />
        );
      case AnalyticsChartType.STACKED_BAR:
      case AnalyticsChartType.PIE:
      case AnalyticsChartType.BAR:
      case AnalyticsChartType.HORIZONTAL_BAR:
      case AnalyticsChartType.GROUPED_BAR:
      case AnalyticsChartType.LINE:
      case AnalyticsChartType.TREEMAP:
        return (
          <AnalyticsChart
            title={label}
            data={data}
            loading={loading}
            chartType={chartType}
            params={params}
            name={name}
            label={label}
            onRefresh={(): void => fetchAnalyticsData(params ?? "", name)}
            onDownload={DownloadAnalyticsData}
            filters={
              localFilters?.includes("INSTITUTIONS") ? (
                <InternalFilters
                  filters={institutions}
                  filterOptions={{
                    INSTITUTIONS: filterOptions?.INSTITUTIONS ?? [],
                  }}
                  onChange={setInstitutions}
                />
              ) : (
                <></>
              )
            }
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {renderAnalyticsData({
        chartType,
        data,
        loading,
        params: params ?? "",
        footer,
        showActionItems,
      })}
    </>
  );
};

type AnalyticsDataType = {
  name?: string;
  label: string;
  chartType: AnalyticsChartType;
  params?: string;
  showActionItems?: boolean;
  footer?: boolean;
  filterOptions?: Record<AnalyticsFilters, Array<InstitutionType>>;
  localFilters?: AnalyticsFilters[];
};

export type AnalyticsDataRenderType = {
  data: DataType | null;
  loading: boolean;
  chartType?: AnalyticsChartType;
  title?: string; //for dev,
  params?: string;
  label?: string;
  onRefresh?: () => void;
  onDownload?: () => void;
  name?: string;
  footer?: boolean;
  filters?: ReactElement;
  showActionItems?: boolean;
  isClickable?: boolean;
};

export type DataType = {
  data: Record<string, string>[];
  configuration?: string;
  from: string;
  to: string;
  report: { name: string };
};

type renderAnalyticsDataType = (data: {
  chartType: AnalyticsChartType;
  data: DataType | null;
  loading: boolean;
  params?: string;
  footer?: boolean;
  showActionItems?: boolean;
}) => ReactNode;

type fetchAnalyticsDataType = (params: string, name?: string) => void;
