import React, { FC, useContext, useEffect, useState } from "react";
import TermgridLogo from "../images/logo/TermgridLogoLight.svg";
import { approveOrReject, getInviteData } from "../services/services";
import { ResponseType } from "../utils/uiTypes";
import { InviteRequestType } from "../utils/types";
import { useHistory, useParams } from "react-router";
import { Button, message, Spin } from "antd";
import { DANGER_BUTTON_STYLE, PRIMARY_BUTTON_STYLE } from "../utils/cssConfigs";
import { UserContext } from "../context/UserContext";

export const ApproveUser: FC = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { hash, byUserId } = useParams<ParamsType>();
  const [invitation, setInvitation] = useState<InviteRequestType | null>(null);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const fetchInviteData = (hash: string) => {
    getInviteData({ segments: { hash } })
      .then(({ data }: ResponseType<InviteRequestType>) => {
        setInvitation(data);
        if (data.approved) {
          setRegisterSuccess(true);
        }
      })
      .catch(() => {
        message.error("Invalid Invitation Link");
      });
  };

  const initiateApproveOrReject = (
    hash: string,
    isApproved: boolean,
    approvedBy: string
  ) => {
    approveOrReject({
      segments: { hash },
      params: { isApproved },
      body: JSON.stringify({ approvedBy }),
    })
      .then(({ data }: ResponseType<InviteRequestType>) => {
        setInvitation(data);
        setRegisterSuccess(true);
        history.push(user ? "/transactions" : "/login");
      })
      .catch(() => {
        message.error("Unable to Take an Action.");
      });
  };

  useEffect(() => {
    fetchInviteData(hash);
  }, [hash]);

  return (
    <div
      style={{
        backgroundImage: !user ? "url('../../../images/hero.jpg')" : "",
      }}
      className={
        "h-screen w-full bg-cover bg-center hide-scrollbar flex w-full flex-col"
      }
    >
      {!user && (
        <img
          className={"mx-auto pt-6 w-48"}
          src={TermgridLogo}
          alt={"Termgrid Logo"}
        />
      )}
      <div className={"px-5 mx-auto h-full flex items-center justify-center"}>
        <div
          className={`${
            user ? "bg-gray-50 shadow-xs" : "bg-white"
          } border p-10 max-w-lg mb-20`}
        >
          {invitation === null ? (
            <Spin spinning={true} />
          ) : !invitation?.approved ? (
            <div className={"w-full"}>
              {!registerSuccess ? (
                <>
                  <div>
                    Please Accept or Reject the deal team request for adding
                    user ({invitation?.invitedUserDTO?.firstName}&nbsp;
                    {invitation?.invitedUserDTO?.lastName}) to your
                    company&nbsp;
                    {invitation?.companyDTO?.name}.
                  </div>
                  <div
                    className={
                      "w-full space-x-5 flex w-full justify-center mt-4"
                    }
                  >
                    <Button
                      className={DANGER_BUTTON_STYLE}
                      onClick={(): void =>
                        initiateApproveOrReject(hash, false, byUserId)
                      }
                    >
                      Reject
                    </Button>
                    <Button
                      className={PRIMARY_BUTTON_STYLE}
                      onClick={(): void =>
                        initiateApproveOrReject(hash, true, byUserId)
                      }
                    >
                      Approve
                    </Button>
                  </div>
                </>
              ) : (
                <div>{invitation?.message}</div>
              )}
            </div>
          ) : (
            <div>{invitation?.message}</div>
          )}
        </div>
      </div>
    </div>
  );
};

type ParamsType = { hash: string; byUserId: string };
