import React, { FC } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";
import {
  formItemRequiredRule,
  namePatternRule,
  parsePhoneNumber,
  phoneNumberPatternRule,
} from "../../utils/formUtils";
import { InviteType } from "../../utils/uiTypes";
import { PhoneNumberInput } from "../general/PhoneNumberInput";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export const CompanyTeamForm: FC<TeamFormType> = ({
  onSubmit,
}: TeamFormType) => {
  const [form] = Form.useForm();

  const onAdminCheckboxChange: onAdminCheckboxChangeType = (e) => {
    e.target.checked = !e.target.checked;
    form.setFieldsValue({
      ...form.getFieldsValue(),
      ["admin"]: !e.target.checked,
    });
  };

  return (
    <Form
      form={form}
      className={`flex flex-col items-start items-stretch`}
      onFinish={(data): void => {
        onSubmit({
          ...data,
          phoneNumber: parsePhoneNumber(data.phoneNumber),
        });
        form.resetFields();
      }}
      layout={"vertical"}
    >
      <Form.Item
        name={"firstName"}
        label={"First Name"}
        rules={[formItemRequiredRule, namePatternRule]}
      >
        <Input placeholder={"First Name"} autoFocus={true} />
      </Form.Item>
      <Form.Item
        name={"lastName"}
        label={"Last Name"}
        rules={[formItemRequiredRule, namePatternRule]}
      >
        <Input placeholder={"Last Name"} />
      </Form.Item>
      <Form.Item name={"email"} label={"Email"} rules={[formItemRequiredRule]}>
        <Input
          type={"email"}
          placeholder={"E-mail"}
          prefix={<MailOutlined className={" text-gray-400"} />}
        />
      </Form.Item>
      <Form.Item
        name={"phoneNumber"}
        label={"Phone Number"}
        rules={[phoneNumberPatternRule()]}
        initialValue={{
          short: "GB",
          code: "44",
        }}
      >
        <PhoneNumberInput placeholder={"Phone Number"} />
      </Form.Item>
      <Form.Item name={"title"} label={"Title"}>
        <Input placeholder={"Title"} />
      </Form.Item>

      <Form.Item className={"m-0"}>
        <div className={"flex flex-row gap-x-2"}>
          <Form.Item
            valuePropName={"checked"}
            className={"inline-block"}
            name={"admin"}
          >
            <Checkbox
              className={"flex justify-center"}
              defaultChecked={false}
              checked={form.getFieldValue("isAdmin")}
              onChange={onAdminCheckboxChange}
            >
              Make Admin
            </Checkbox>
          </Form.Item>
          <Button
            className={"ml-auto"}
            onClick={(): void => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
          <Button
            htmlType={"submit"}
            className={"bg-primary hover:bg-hover text-white border-0"}
          >
            Add Member
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

type TeamFormType = {
  onSubmit: (o: InviteType) => void;
};
type onAdminCheckboxChangeType = (e: CheckboxChangeEvent) => void;
