import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ActionType, DrawerType, ModalType } from "../../../utils/newTermsheet";
import { Button, Dropdown, Menu, message, Space, Tooltip } from "antd";
import {
  generateDoc,
  generateReadlineExcel,
  renameWebForm,
} from "../../../services/services";
import { FileResponseType, voidType } from "../../../utils/uiTypes";
import { ConfirmDownload } from "../../../utils/confirmationModals";
import { KeyValueType, VersionDataType } from "../../../utils/types";
import { TermsheetContext } from "../../../context/TermsheetContext";
import { ToolBar } from "../common/ToolBar";
import {
  DANGER_BUTTON_STYLE,
  PRIMARY_BUTTON_STYLE,
} from "../../../utils/cssConfigs";
import { TermsheetModals } from "./TermsheetModals";
import { TermsheetDrawers } from "./TermsheetDrawers";
import { DownOutlined } from "@ant-design/icons";
import { hasUnpublishedVersions } from "../../../utils/termsheet";
import {
  and,
  defaultTo,
  equals,
  flatten,
  isEmpty,
  isNil,
  omit,
  or,
  path,
} from "ramda";
import { getPopupContainerForSelect } from "../../../utils/container";
import { PETransactionCreatorType } from "../../../utils/enums";
import { valOrDefault } from "../../../utils/utils";

export const TermsheetToolbarActions: FC<TermsheetToolbarActionsType> =
  function ({
    name = "",
    style = {},
    version,
    versions,
    published,
    edited,
    disableActions,
    publishLoading,
    onStyleChange,
    onUndoOrRedo,
    onDealTeamPublish,
    onInstitutionPublish,
    onCreateNewVersion,
    onDrawerItemSelect,
    onRefresh,
    children,
    isHeatMap,
    isRedLine,
  }: TermsheetToolbarActionsType) {
    const {
      transactionId,
      webFormId,
      isAdmin,
      isInstitution,
      isOffline = false,
      transactionType,
      institutions,
    } = useContext(TermsheetContext);
    const [loading, setLoading] = useState<LoadingType | null>({
      download: false,
    });
    const [updatedName, setUpdatedName] = useState<string>("");
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const [drawerType, setDrawerType] = useState<DrawerType | null>(null);

    /** Reset form and use state variables on modal close **/
    const resetOnDrawerOrModalClose: voidType = () => {
      setModalType(null);
      setDrawerType(null);
    };

    /** Deal team menu options for a published webform **/
    const DEAL_TEAM_PUBLISHED_MENU = () => {
      return (
        <Menu>
          <Menu.Item
            disabled={isOffline ? published : false}
            key="ADD_LENDER"
            onClick={() => {
              setModalType("ADD_LENDER");
            }}
          >
            Add Institution(s)
          </Menu.Item>
          <Menu.Item
            disabled={
              isOffline
                ? published
                : defaultTo(
                    false,
                    !Object?.values(
                      defaultTo(
                        [],
                        path<boolean[]>([version, "respondStatus"], versions)
                      )
                    )?.includes(false)
                  )
            }
            key="REMOVE_LENDER"
            onClick={() => {
              setModalType("REMOVE_LENDER");
            }}
          >
            Remove Institution(s)
          </Menu.Item>
          <Menu.Item
            key="NEW_VERSION"
            onClick={createNewVersion}
            disabled={
              isOffline
                ? hasUnpublishedVersions(versions) ||
                  isEmpty(versions[version]?.lenderIds ?? [])
                : hasUnpublishedVersions(versions)
            }
          >
            Create New Version
          </Menu.Item>
        </Menu>
      );
    };

    /** Callback to create new version **/
    const createNewVersion = useCallback(() => {
      onCreateNewVersion();
    }, []);

    /** Handler for toolbar events **/
    const onToolbarButtonClick: onToolbarButtonClickType = (action) => {
      switch (action) {
        case "COMPARE":
        case "UPLOAD":
          setModalType(action);
          return;
        case "DOWNLOAD_TERMSHEET_EXCEL":
          downloadFile(transactionId, webFormId, version.toString());
          return;
        case "DOWNLOAD_TERMSHEET_WORD":
          downloadFile(transactionId, webFormId, version.toString(), true);
          return;
        case "DOWNLOAD_ASKS_EXCEL":
          onDownloadComparisonWithoutRedline();
          return;
        case "DOWNLOAD_ASKS_WORD":
          onDownloadComparisonWithoutRedline(true);
          return;
        case "TEMPLATES":
        case "TERMSHEETS":
          setDrawerType(action);
          return;
        case "UNDO":
          return onUndoOrRedo(true);
        case "REDO":
          return onUndoOrRedo(false);
      }
    };

    const getMinVersion: getVersionType = (versionData = {}) => {
      return Math.min(...Object.keys(versionData).map((o) => parseInt(o)));
    };

    const getMaxVersion: getVersionType = (versionData = {}) => {
      return Math.max(...Object.keys(versionData).map((o) => parseInt(o)));
    };

    const getPublishedMaxVersion: getPublishedMaxVersionType = (
      versionData = {}
    ) => {
      const publishedVersions = Object.keys(versionData)
        .filter((v) => versionData[v].published === true)
        .map((o) => parseInt(o));
      return isEmpty(valOrDefault([], publishedVersions))
        ? null
        : Math.max(...publishedVersions);
    };

    const onDownloadComparisonWithoutRedline: downloadComparisonFileType = (
      isWord = false
    ) => {
      const minVersion = getMinVersion(versions);
      const latestVersion = isInstitution
        ? getMaxVersion(versions)
        : getPublishedMaxVersion(versions);
      if (!isEmpty(latestVersion) && !isNil(latestVersion)) {
        setLoading({ download: true });
        const lenders = institutions
          ?.filter(({ id }) =>
            isInstitution ? true : versions[latestVersion]?.respondStatus[id]
          )
          ?.map(({ id }) => ({
            webFormId,
            peTransactionId: transactionId,
            entityId: id,
            version: latestVersion.toString(),
            createDate: versions[latestVersion].createDate,
          }));
        generateReadlineExcel({
          params: {
            generateRedLine: false,
            wordFormat: isWord,
          },
          body: JSON.stringify({
            originalColumn: {
              webFormId,
              peTransactionId: transactionId,
              entityId: transactionId,
              version: minVersion,
              createDate: versions[minVersion].createDate,
            },
            changedColumn: flatten([
              Object.entries(omit(minVersion.toString(), versions)).map(
                ([o, v]) => {
                  return {
                    webFormId,
                    peTransactionId: transactionId,
                    entityId: transactionId,
                    version: o,
                    createDate: v.createDate,
                  };
                }
              ),
              ...valOrDefault([], lenders),
            ]),
            bidMetricColumn: {
              webFormId,
              peTransactionId: transactionId,
            },
          }),
        })
          .then(({ url, filename }: FileResponseType) => {
            setLoading({ download: false });
            message.success({
              key: "download",
              content: `File ready to download`,
            });
            ConfirmDownload(
              filename,
              url,
              isWord
                ? "Open downloaded Word Document in Web Layout format on MS Word for better visibility of multiple columns"
                : ""
            );
          })
          .catch((error: any) => {
            setLoading({ download: false });
            console.error(error);
            message.error({
              content: "Unable to download file",
              key: "download",
            });
          });
      }
    };

    /** Download File **/
    const downloadFile: downloadFileType = (
      id,
      webFormId,
      version,
      isWord = false
    ) => {
      setLoading({ download: true });
      message.loading({
        content: "Downloading File...",
        duration: 0,
        key: "download",
      });
      generateDoc({
        segments: {
          id,
          webFormId,
          version,
        },
        params: {
          generateRedLine: isRedLine,
          generateHeatMap: isHeatMap,
          wordFormat: isWord,
        },
      })
        .then(({ url, filename }: FileResponseType) => {
          setLoading({ download: false });
          message.success({
            key: "download",
            content: `File ready to download`,
          });
          ConfirmDownload(
            filename,
            url,
            isWord
              ? "Open downloaded Word Document in Web Layout format on MS Word for better visibility of multiple columns"
              : ""
          );
        })
        .catch((error: any) => {
          console.error(error);
          message.error({
            content: "Unable to download file",
            key: "download",
          });
        });
    };

    /** Rename Webform **/
    const renameWebform: renameWebformType = (name) => {
      renameWebForm({
        segments: {
          id: webFormId,
        },
        body: name,
      })
        .then(() => {
          setUpdatedName(name);
        })
        .catch((error: any) => {
          console.error(error);
          message.error("Sorry, unable to update termsheet name");
        });
    };

    useEffect(() => {
      setUpdatedName(name);
    }, [name]);

    return (
      <div className={"absolute sticky top-0 z-[100]"}>
        <ToolBar
          name={updatedName}
          selectedStyle={style}
          showTemplatesButton={
            isAdmin && version === 0 && !published && !isOffline
          }
          showSwitchTermsheetButton={true}
          showUploadButton={(isAdmin || isInstitution) && !published}
          downloadItems={[
            {
              label: "Download Termsheet",
              key: "DOWNLOAD_TERMSHEET",
              show: true,
              subItems: [
                { label: "Excel", key: "DOWNLOAD_TERMSHEET_EXCEL" },
                { label: "Word", key: "DOWNLOAD_TERMSHEET_WORD" },
              ],
            },
            {
              label: "Download All Deal Team Asks",
              key: "DOWNLOAD_ASKS",
              show: !isOffline && Object.keys(versions).length > 1,
              subItems: [
                { label: "Excel", key: "DOWNLOAD_ASKS_EXCEL" },
                { label: "Word", key: "DOWNLOAD_ASKS_WORD" },
              ],
            },
          ]}
          showCompareButton={true}
          showStyleChangeButtons={(isAdmin || isInstitution) && !published}
          downloading={loading?.download}
          disableActions={disableActions}
          editName={isAdmin || isOffline}
          onStyleChange={onStyleChange}
          onNameChange={renameWebform}
          onButtonClick={onToolbarButtonClick}
        >
          <Space className={"gap-2 inline-flex h-full items-center"}>
            <>{children}</>
            {or(
              and(and(isAdmin, published), !isOffline),
              and(
                isOffline,
                equals(transactionType, PETransactionCreatorType.BORROWER)
              )
            ) && (
              <Dropdown
                overlay={DEAL_TEAM_PUBLISHED_MENU}
                trigger={["click"]}
                placement={"bottomRight"}
                overlayClassName={"mt-4"}
                getPopupContainer={getPopupContainerForSelect}
                disabled={
                  (isOffline && disableActions) || (isOffline && edited)
                }
              >
                <Tooltip
                  placement={"left"}
                  title={
                    isOffline && edited
                      ? "Please save the termsheet before adding or deleting an institution."
                      : ""
                  }
                >
                  <Button
                    type={"primary"}
                    className={PRIMARY_BUTTON_STYLE}
                    disabled={
                      (isOffline && disableActions) || (isOffline && edited)
                    }
                  >
                    Actions <DownOutlined />
                  </Button>
                </Tooltip>
              </Dropdown>
            )}
            {and(and(!published, !isOffline), isAdmin) && (
              <Button
                disabled={disableActions}
                loading={publishLoading}
                onClick={() => {
                  setModalType("PUBLISH_DEAL_TEAM");
                }}
                className={DANGER_BUTTON_STYLE}
                type={"primary"}
              >
                Publish
              </Button>
            )}
            {and(and(!published, !isOffline), isInstitution) && (
              <Button
                disabled={disableActions}
                loading={publishLoading}
                onClick={() => {
                  setModalType("PUBLISH_INSTITUTION");
                }}
                className={DANGER_BUTTON_STYLE}
                type={"primary"}
              >
                Publish
              </Button>
            )}
          </Space>
        </ToolBar>
        {modalType && (
          <TermsheetModals
            published={published}
            type={modalType}
            currentVersion={version}
            versions={versions}
            onCloseOrCancel={() => {
              resetOnDrawerOrModalClose();
            }}
            onDealTeamPublish={(values) => {
              resetOnDrawerOrModalClose();
              onDealTeamPublish(values);
            }}
            onInstitutionPublish={() => {
              resetOnDrawerOrModalClose();
              onInstitutionPublish();
            }}
            onUpload={() => {
              resetOnDrawerOrModalClose();
              onRefresh();
            }}
          />
        )}
        {drawerType && (
          <TermsheetDrawers
            transactionId={transactionId}
            webformId={webFormId}
            type={drawerType}
            onClose={() => setDrawerType(null)}
            onSelect={(id) => {
              resetOnDrawerOrModalClose();
              onDrawerItemSelect(id, drawerType);
            }}
          />
        )}
      </div>
    );
  };

type TermsheetToolbarActionsType = {
  name?: string;
  style?: KeyValueType;
  version: number;
  versions: VersionDataType;
  published: boolean;
  edited: boolean;
  disableActions?: boolean;
  publishLoading?: boolean;
  onStyleChange: (o: KeyValueType) => void;
  onUndoOrRedo: (undo: boolean) => void;
  onDealTeamPublish: (values: Array<string>) => void;
  onInstitutionPublish: () => void;
  onCreateNewVersion: () => void;
  onDrawerItemSelect: (o: string, type: DrawerType) => void;
  onRefresh: () => void;
  children: ReactNode;
  isHeatMap?: boolean;
  isRedLine?: boolean;
};
type LoadingType = {
  download: boolean;
};
type onToolbarButtonClickType = (action: ActionType) => void;
type getVersionType = (versionData?: VersionDataType) => number;
type getPublishedMaxVersionType = (
  versionData?: VersionDataType
) => number | null;
type renameWebformType = (name: string) => void;
type downloadFileType = (
  transactionId: string,
  webFormId: string,
  version?: string,
  isWord?: boolean
) => void;
type downloadComparisonFileType = (isWord?: boolean) => void;
