import { login, send2FAPhoneCode } from "../../services/services";
import { ErrorType, ResponseType } from "../../utils/uiTypes";
import { UserType } from "../../utils/types";
import { loginRedirect } from "../../utils/redirect";
import { Alert, Button, Form, Input } from "antd";
import React, { FC, useContext, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useHistory } from "react-router";
import { LoginFormState, LoginFormType } from "../../pages/Login";
import { equals } from "ramda";

export const LoginForm: FC<LoginFormType> = ({
  setFormState,
  currentState,
}) => {
  return (
    <div>
      <div className={"text-center text-2xl font-medium select-none"}>
        Welcome Back
      </div>
      <div className={"text-center text-sm font-normal mt-2 mb-8 select-none"}>
        Sign in with your Termgrid email and password
      </div>
      {equals(currentState, LoginFormState.SUCCESS) && (
        <div
          className={
            "text-center text-sm font-thin text-red-500 mt-2 mb-8 select-none"
          }
        >
          Check your email for the instructions to reset your password
        </div>
      )}
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: "string",
            message: "Please enter your email address",
            whitespace: true,
          },
        ]}
        validateTrigger={[]}
      >
        <Input placeholder={"Email"} autoFocus={true} />
      </Form.Item>

      <Form.Item
        className={"mb-2"}
        name="password"
        rules={[
          {
            required: true,
            message: "Please enter your password!",
          },
        ]}
        validateTrigger={[]}
      >
        <Input.Password placeholder={"Password"} />
      </Form.Item>
      <div className={"text-right text-xs text-secondary flex flex-col"}>
        <p
          onClick={(): void => setFormState(LoginFormState.RESET)}
          className={"ml-auto cursor-pointer select-none hover:underline"}
        >
          Forgot or Reset Password?
        </p>
      </div>
      {currentState === LoginFormState.FAILURE && (
        <Alert
          className={"my-4"}
          message={"Incorrect Email Address!"}
          closable={true}
          type="error"
        />
      )}
    </div>
  );
};
