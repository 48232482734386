import React, { FC, useContext, useEffect, useState } from "react";
import {
  fetchWithIdType,
  FileResponseType,
  ResponseType,
  voidType,
} from "../../../../utils/uiTypes";
import {
  addFile,
  addLenderClickThroughConfigs,
  generateNdaLogExcel,
  getClickThroughSettings,
  getLenderClickThroughConfigs,
  getNdaLogs,
  updateClickThroughSettings,
} from "../../../../services/services";
import {
  FileElementType,
  FileType,
  NdaConfigType,
  TransactionNdaSettingDTO,
  UserNdaConsentType,
} from "../../../../utils/types";
import { TransactionContext } from "../../../../context/TransactionContext";
import {
  Button,
  Checkbox,
  Drawer,
  message,
  Modal,
  Select,
  Switch,
  Table,
  Tooltip,
  Upload,
} from "antd";
import { ColumnsType } from "antd/es/table";
import {
  ACTION_BUTTON_CSS,
  DANGER_BUTTON_STYLE,
  PRIMARY_BUTTON_STYLE,
  tableSelectCss,
} from "../../../../utils/cssConfigs";
import {
  tableColumnHeader,
  tableSelectSuffix,
} from "../../../../utils/componentUtils";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { and, isEmpty, isNil, omit, or, pathOr, update } from "ramda";
import { FileElementEnumType } from "../../../../utils/enums";
import { updateItemInArray, valOrDefault } from "../../../../utils/utils";
import { ClickThroughDocument } from "./ClickThroughDocument";
import { TextOverFlowHandle } from "../../../general/TextOverFlowHandle";
import {
  ConfirmAction,
  ConfirmDownload,
} from "../../../../utils/confirmationModals";
import {
  DownloadOutlined,
  FileOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { NdaLogsTable } from "./NdaLogsTable";

export const ManageNDAConfig: FC = () => {
  const { transactionId } = useContext(TransactionContext);
  const { height: windowHeight } = useWindowDimensions();

  const [clickThroughSettings, setClickThroughSettings] =
    useState<TransactionNdaSettingDTO | null>(null);
  const [lenderClickThroughConfigs, setLenderClickThroughConfigs] = useState<
    NdaConfigType[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [logsModal, setLogsModal] = useState<ModalLogsType>({
    data: [],
    open: false,
  });

  const fetchClickThroughSettings: voidType = () => {
    setLoading(true);
    getClickThroughSettings({ segments: { transactionId } })
      .then(({ data }: ResponseType<TransactionNdaSettingDTO>) => {
        setClickThroughSettings(data);
        setLoading(false);
      })
      .catch((e: string) => {
        console.error(e);
        setLoading(false);
      });
  };

  const fetchLenderClickThroughConfigs: fetchWithIdType = (transactionId) => {
    setLoading(true);
    getLenderClickThroughConfigs({ segments: { transactionId } })
      .then(({ data }: ResponseType<NdaConfigType[]>) => {
        setLenderClickThroughConfigs(
          data.sort((a, b) =>
            a.lenderName.toLowerCase().localeCompare(b.lenderName.toLowerCase())
          )
        );
        setLoading(false);
      })
      .catch((error: string) => {
        message.error(error ?? "Server Error");
        setLoading(false);
      });
  };

  const fetchNdaLogs: fetchWithIdType = (transactionId) => {
    getNdaLogs({ segments: { transactionId } }).then(
      ({ data }: ResponseType<UserNdaConsentType[]>) => {
        setLogsModal({ ...logsModal, data });
      }
    );
  };

  const initiateClickThroughSettingsUpdate: initiateTransactionNDAUpdateType = (
    transactionId,
    body
  ) => {
    setLoading(true);
    updateClickThroughSettings({
      body: JSON.stringify(body),
    })
      .then(({ data }: ResponseType<TransactionNdaSettingDTO>) => {
        setLoading(false);
        setClickThroughSettings(data);
        fetchLenderClickThroughConfigs(transactionId);
        message.success("Updated Successfully");
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        message.error("Unable to Upload File");
        setLoading(false);
      });
  };

  const addNewFileToData: addNewFileToDataType = (petransactionId, file) => {
    return new Promise((resolve, reject) => {
      addFile({
        headers: {
          Accept: ["application/json", "text/plain", "*/*"],
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          fileDTO: file,
          fileElementType: FileElementEnumType.NDA,
          fileURL: file.fileURL,
          isFolder: false,
          name: file.fileName,
          petransactionId,
        }),
      })
        .then(({ data }: ResponseType<FileElementType>) => {
          resolve(data);
        })
        .catch((error: string) => {
          setLoading(false);
          reject(
            valOrDefault(`Error uploading file "${file.fileName}" `, error)
          );
        });
    });
  };

  const updateLenderClickThroughConfigs: updateNDAConfigType = (configs) => {
    const updatedConfig = configs.map((config) => ({
      ...config,
      fileElementDTOS: config?.fileElementDTOS?.map((file) =>
        omit(["@id"], file)
      ),
    }));
    setLoading(true);
    addLenderClickThroughConfigs({
      segments: { transactionId },
      body: JSON.stringify(updatedConfig),
    })
      .then(() => {
        setLenderClickThroughConfigs(updatedConfig);
        setLoading(false);
      })
      .catch((e: string) => {
        console.error(e);
        setLoading(false);
      });
  };

  const columns: ColumnsType<NdaConfigType> = [
    {
      title: tableColumnHeader("Institution"),
      dataIndex: "lenderName",
      className: "group-hover:bg-blue-50 min-w-[85px]",
    },
    {
      title: (
        <div>
          {tableColumnHeader("Enable")}
          <div className={"inline-flex gap-2 items-center"}>
            {tableColumnHeader(
              "Apply to all",
              "!text-xxs !italic !normal-case"
            )}
            <Checkbox
              disabled={or(
                !clickThroughSettings?.ndaEnabled,
                isNil(clickThroughSettings?.fileId)
              )}
              checked={lenderClickThroughConfigs.reduce(
                (previousValue, currentValue) =>
                  previousValue && currentValue.ndaRequired,
                true
              )}
              onChange={(e) =>
                updateLenderClickThroughConfigs(
                  lenderClickThroughConfigs?.map((config) => ({
                    ...config,
                    ndaRequired: e.target.checked,
                  }))
                )
              }
            />
            {or(
              !clickThroughSettings?.ndaEnabled,
              isNil(clickThroughSettings?.fileId)
            ) && (
              <Tooltip title={"Upload a global document"}>
                <InfoCircleOutlined className={"text-primary"} />
              </Tooltip>
            )}
            &nbsp;
          </div>
        </div>
      ),
      dataIndex: "ndaRequired",
      align: "center",
      className: "group-hover:bg-blue-50 min-w-[120px]",

      render: (required: boolean, record, index) => (
        <>
          <Checkbox
            checked={required}
            disabled={or(
              !clickThroughSettings?.ndaEnabled,
              and(
                isEmpty(
                  pathOr(
                    "",
                    ["fileElementDTOS", 0, "fileDTO", "fileId"],
                    record
                  )
                ),
                isNil(clickThroughSettings?.fileId)
              )
            )}
            onChange={(e): void =>
              updateLenderClickThroughConfigs(
                update(
                  index,
                  {
                    ...lenderClickThroughConfigs[index],
                    ndaRequired: e.target.checked,
                  },
                  lenderClickThroughConfigs ?? []
                )
              )
            }
          />
          &nbsp;
          {and(
            isEmpty(
              pathOr("", ["fileElementDTOS", 0, "fileDTO", "fileId"], record)
            ),
            !clickThroughSettings?.fileId
          ) && (
            <Tooltip
              title={
                "To enable Click Through, either upload a global document or institution specific document here"
              }
            >
              <InfoCircleOutlined className={"text-primary"} />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: tableColumnHeader("Frequency"),
      dataIndex: "oneTimeNda",
      align: "center",
      className: "group-hover:bg-blue-50 min-w-[150px]",
      render: (oneTimeNda = false, record, index) => (
        <Select
          dropdownMatchSelectWidth={120}
          size={"small"}
          bordered={false}
          className={tableSelectCss(true)}
          suffixIcon={tableSelectSuffix(true)}
          value={oneTimeNda}
          options={[
            { label: "Everytime", value: false },
            { label: "One Time", value: true },
          ]}
          disabled={or(
            !clickThroughSettings?.ndaEnabled,
            and(
              isEmpty(
                pathOr("", ["fileElementDTOS", 0, "fileDTO", "fileId"], record)
              ),
              isNil(clickThroughSettings?.fileId)
            )
          )}
          onChange={(e): void =>
            updateLenderClickThroughConfigs(
              update(
                index,
                { ...lenderClickThroughConfigs[index], oneTimeNda: e },
                lenderClickThroughConfigs
              )
            )
          }
        />
      ),
    },
    {
      title: tableColumnHeader("Document"),
      align: "center",
      className:
        "group-hover:bg-blue-50 min-w-[85px] max-w-[300px] empty:after:content-['Document_not_added_yet'] empty:text-xs empty:text-muted",
      dataIndex: ["fileElementDTOS", 0, "fileDTO", "fileName"],
      render: (fileName: string, record) =>
        fileName && (
          <ClickThroughDocument
            fileId={pathOr(
              "",
              ["fileElementDTOS", 0, "fileDTO", "fileId"],
              record
            )}
            buttonClassName={
              "max-w-[300px] flex gap-2 items-center border-0 shadow-none justify-center mx-auto"
            }
            configId={record.ndaConfigId}
            transactionId={record.peTransactionId}
          >
            <TextOverFlowHandle text={fileName} />
          </ClickThroughDocument>
        ),
    },
    {
      title: tableColumnHeader("Action"),
      align: "center",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      key: "operation",
      render: (_, record: NdaConfigType, index) => (
        <div className={"flex items-center gap-x-2 justify-center"}>
          <Upload<FileType>
            beforeUpload={(): void => {
              setLoading(true);
            }}
            maxCount={1}
            showUploadList={false}
            type={"select"}
            accept={".pdf"}
            action={"/api/rest/media/uploadFile/1"}
            onChange={({ file }): void => {
              if (file.status === "done" && file.response) {
                addNewFileToData(record.ndaConfigId, file.response).then(
                  (file) => {
                    updateLenderClickThroughConfigs(
                      updateItemInArray(
                        index,
                        lenderClickThroughConfigs ?? [],
                        {
                          ...record,
                          fileElementDTOS: [file],
                        }
                      )
                    );
                  }
                );
              }
            }}
          >
            <Button
              className={ACTION_BUTTON_CSS}
              icon={<i className="fas fa-upload mr-2" />}
            >
              Upload File
            </Button>
          </Upload>
          <Button
            onClick={() =>
              setLogsModal({
                ...logsModal,
                open: true,
                lenderId: record?.lenderId,
              })
            }
            className={ACTION_BUTTON_CSS}
            icon={<FileOutlined />}
          >
            Audit Logs
          </Button>
        </div>
      ),
    },
  ];

  const toggleClickThrough = (ndaEnabled: boolean) => {
    if (!ndaEnabled) {
      ConfirmAction(
        "Are you sure you want to Turn Off Click Through for this Transaction?",
        () =>
          clickThroughSettings &&
          initiateClickThroughSettingsUpdate(transactionId ?? "", {
            ...clickThroughSettings,
            ndaEnabled,
          }),
        <div>
          <b>WARNING:</b>&nbsp;Disabling ClickThrough will remove Click Through
          Requirement for all lenders in this Transaction
        </div>,
        { okButtonProps: { className: DANGER_BUTTON_STYLE } },
        "Disable"
      );
    } else {
      clickThroughSettings &&
        initiateClickThroughSettingsUpdate(transactionId ?? "", {
          ...clickThroughSettings,
          ndaEnabled,
        });
    }
  };

  const toggleClickThroughAutomatic = (automaticNdaSetup: boolean) => {
    clickThroughSettings &&
      initiateClickThroughSettingsUpdate(transactionId ?? "", {
        ...clickThroughSettings,
        automaticNdaSetup,
      });
  };

  const initiateLogsExport: voidType = () => {
    message.loading({
      content: "Processing File...",
      duration: 0,
      key: "export",
    });
    generateNdaLogExcel({
      segments: { transactionId: transactionId ?? "" },
    })
      .then(({ url, filename }: FileResponseType) => {
        message.success({ key: "export", content: "File Ready to Download" });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) =>
        message.error({ content: error, key: "export" })
      );
  };

  useEffect(() => {
    fetchClickThroughSettings();
    fetchLenderClickThroughConfigs(transactionId ?? "");
    fetchNdaLogs(transactionId ?? "");
  }, []);

  return (
    <div className={"relative mx-auto mx-2 p-6 h-full flex flex-col gap-5"}>
      <div className={"flex flex-row items-start gap-x-4"}>
        <span className="text-2xl font-medium">Click Through Settings</span>
        <Button
          className={`${PRIMARY_BUTTON_STYLE} ml-auto`}
          onClick={() => setLogsModal({ ...logsModal, open: true })}
        >
          Audit Logs
        </Button>
        <Button
          className={`${ACTION_BUTTON_CSS}`}
          icon={<DownloadOutlined />}
          onClick={initiateLogsExport}
        >
          Export Logs
        </Button>
      </div>
      <div className={"bg-white flex flex-col divide-y divide-gray-100"}>
        <div
          className={
            "p-4 hover:bg-blue-50 flex flex-col md:flex-row items-start justify-start md:items-center md:justify-between gap-2"
          }
        >
          <div className={"select-none"}>
            Do you want to enable click through for this transaction?
            <br />
            <span className={"text-xs text-gray-500"}>
              <InfoCircleOutlined className={"text-primary"} />
              &nbsp; Institutions will have to accept your terms to get access
              to the transaction
            </span>
          </div>
          <Switch
            loading={loading}
            checked={clickThroughSettings?.ndaEnabled}
            onChange={toggleClickThrough}
            size={"default"}
            checkedChildren={<span className={"text-xxs"}>YES</span>}
            unCheckedChildren={<span className="text-xxs">NO&nbsp;</span>}
            className={
              clickThroughSettings?.ndaEnabled ? "bg-success" : "bg-muted"
            }
          />
        </div>
        {clickThroughSettings?.ndaEnabled && (
          <div
            className={
              "p-4 hover:bg-blue-50 flex flex-col md:flex-row items-start justify-start md:items-center md:justify-between gap-2"
            }
          >
            <div className={"select-none"}>
              Global Click Through Document
              <br />
              <span className={"text-xs text-gray-500"}>
                <InfoCircleOutlined className={"text-primary"} />
                &nbsp;This will be used for all institutions unless changed
                specifically below
              </span>
            </div>
            <div className={"inline-flex gap-2 items-center"}>
              <Upload
                beforeUpload={(): void => {
                  setLoading(true);
                }}
                disabled={loading}
                maxCount={1}
                showUploadList={false}
                type={"select"}
                accept={".pdf"}
                action={"/api/rest/media/uploadFile/1"}
                className={"flex flex-row items-center justify-center"}
                onChange={({ file }): void => {
                  if (file.status === "done" && file.response) {
                    addNewFileToData(transactionId ?? "", file.response).then(
                      (file) =>
                        clickThroughSettings &&
                        initiateClickThroughSettingsUpdate(
                          transactionId ?? "",
                          {
                            ...clickThroughSettings,
                            transactionId: transactionId ?? "",
                            fileId: file?.fileDTO?.fileId ?? "",
                            fileElementId: file.elementId,
                            fileName: file?.fileDTO?.fileName ?? "",
                          }
                        )
                    );
                  }
                }}
              >
                <Button
                  loading={loading}
                  className={PRIMARY_BUTTON_STYLE}
                  icon={<i className="fas fa-upload mr-2" />}
                >
                  Upload Document
                </Button>
              </Upload>
              {and(
                !isEmpty(clickThroughSettings?.fileElementId),
                !isEmpty(clickThroughSettings?.fileId)
              ) && (
                <ClickThroughDocument
                  fileId={clickThroughSettings?.fileId ?? ""}
                  configId={transactionId ?? ""}
                  transactionId={transactionId ?? ""}
                  buttonClassName={ACTION_BUTTON_CSS}
                >
                  {clickThroughSettings?.fileName}
                </ClickThroughDocument>
              )}
            </div>
          </div>
        )}
        {clickThroughSettings?.ndaEnabled && (
          <div
            className={
              "p-4 hover:bg-blue-50 flex flex-col md:flex-row items-start justify-start md:items-center md:justify-between gap-2"
            }
          >
            <div className={"select-none"}>
              Automatically Enable Click Through for New Institution?
            </div>
            <Tooltip
              className={"rounded-full"}
              title={
                !clickThroughSettings.fileId &&
                "Upload the Click Through to use this feature"
              }
            >
              <Switch
                loading={loading}
                disabled={!clickThroughSettings.fileId}
                checked={clickThroughSettings?.automaticNdaSetup}
                size={"default"}
                checkedChildren={<span className={"text-xxs"}>YES</span>}
                unCheckedChildren={<span className="text-xxs">NO&nbsp;</span>}
                className={
                  clickThroughSettings?.automaticNdaSetup
                    ? "bg-success"
                    : "bg-muted"
                }
                onChange={toggleClickThroughAutomatic}
              />
            </Tooltip>
          </div>
        )}
      </div>
      <Table<NdaConfigType>
        title={() => tableColumnHeader("Customize by Institution")}
        loading={loading}
        locale={{ emptyText: "No Lenders Added Yet!" }}
        dataSource={lenderClickThroughConfigs}
        columns={columns}
        rowClassName={"group cursor-pointer"}
        rowKey={({ lenderId }): string => lenderId}
        pagination={false}
        scroll={{ x: true, y: windowHeight - 550 }}
      />
      <NdaLogsTable
        open={logsModal.open}
        onClose={() =>
          setLogsModal({ ...logsModal, open: false, lenderId: null })
        }
        logs={logsModal.data}
        lenderId={logsModal.lenderId}
      />
    </div>
  );
};

type addNewFileToDataType = (
  petransactionId: string,
  file: FileType
) => Promise<FileElementType>;

type initiateTransactionNDAUpdateType = (
  transactionId: string,
  body: TransactionNdaSettingDTO
) => void;

type updateNDAConfigType = (configs: NdaConfigType[]) => void;
type ModalLogsType = {
  data: UserNdaConsentType[];
  open: boolean;
  lenderId?: string | null;
};
