import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { message, ModalFuncProps, Select, Table, Tag } from "antd";
import { CustomSpin } from "../general/CustomSpin";
import {
  CompanyModuleType,
  ElementPermissionsType,
  TeamMemberType,
  UserType,
} from "../../utils/types";
import {
  editAdminTeam,
  editTeamMember,
  getTeam,
  getTeamByCompanyId,
} from "../../services/services";
import { ResponseType, voidType } from "../../utils/uiTypes";
import {
  CompanyEnumType,
  ElementType,
  InvitationStatusType,
  PermissionType,
} from "../../utils/enums";
import { updateItemInArray, valOrDefault } from "../../utils/utils";
import { usePageTitle } from "../../customHooks/usePageTitle";
import {
  getSelectOptionWithBadge,
  invitationStatusTag,
  SelectWithBadgeOptionType,
  tableColumnHeader,
  tableSelectSuffix,
} from "../../utils/componentUtils";
import { tableSelectCss } from "../../utils/cssConfigs";
import useWindowDimensions from "../../customHooks/useWindowDimensions";
import { any, equals, filter, findIndex, isNil, pathOr, propEq } from "ramda";
import { ViewEmail } from "../general/ViewEmail";
import { ConfirmAction } from "../../utils/confirmationModals";
import { ColumnsType } from "antd/lib/table";

const R = require("ramda");
let confirmationMessage = "";
const createPermission: createPermissionType = (
  category,
  permissionType,
  permissionExists,
  permissions = []
) => {
  if (permissionExists) {
    return permissions.reduce(
      (acc: ElementPermissionsType[], currentPermission) => {
        if (currentPermission.elementType === category)
          return [
            {
              elementType: category,
              permissions:
                permissionType === PermissionType.NONE ? [] : [permissionType],
            } as ElementPermissionsType,
            ...acc,
          ];
        return acc.concat(currentPermission);
      },
      []
    );
  } else {
    return [
      {
        elementType: category,
        permissions: [permissionType],
      } as ElementPermissionsType,
      ...permissions,
    ];
  }
};

// the function returns the type of permission the deal team member has for the modules
const getElementPermissionTypes: getElementPermissionTypesType = (
  category,
  permissions = []
) => {
  for (const permission of permissions) {
    if (permission.elementType === category) {
      return valOrDefault(PermissionType.NONE, permission?.permissions[0]);
    }
  }
  return PermissionType.NONE;
};

const modulePermissionStatusArray = (
  element: string
): SelectWithBadgeOptionType[] => {
  if (element === ElementType.DEAL_EXECUTION) {
    return [
      { value: PermissionType.ADMIN, label: "Admin" },
      { value: PermissionType.NONE, label: "Non Admin" },
    ];
  } else if (element === ElementType.PRECEDENT_SEARCH) {
    return [
      { value: PermissionType.ABOVE_THE_WALL, label: "Above The Wall" },
      { value: PermissionType.ADMIN, label: "Admin" },
      { value: PermissionType.NON_ADMIN, label: "Non Admin" },
      { value: PermissionType.NONE, label: "None" },
    ];
  }
  return [
    { value: PermissionType.ADMIN, label: "Admin" },
    { value: PermissionType.NON_ADMIN, label: "Non Admin" },
    { value: PermissionType.NONE, label: "None" },
  ];
};
const companyPermissionStatusArray: SelectWithBadgeOptionType[] = [
  { value: PermissionType.ADMIN, label: "Admin" },
  { value: PermissionType.NON_ADMIN, label: "User" },
];

export const Permissions: FC<PermissionsType> = ({
  companyId,
  modules,
  isAdminPage = false,
  searchValue = "",
}) => {
  usePageTitle("Permissions");

  const { user, companyModulePreference } = useContext(UserContext);

  const { height: windowHeight } = useWindowDimensions();
  const [loading, setLoading] = useState<boolean>(true);
  const [updating, setUpdating] = useState<Record<string, boolean>>({});
  const [team, setTeam] = useState<TeamMemberType[]>([]);
  const [isLastAdmin, setIsLastAdmin] = useState(true);

  const initiateEdit: initiateEditType = (member, team, isAdmin) => {
    setLoading(true);
    setUpdating((updating) => ({ ...updating, [member.id]: true }));
    const index = findIndex(propEq("id", member.id), team);
    if (isAdmin) {
      editAdminTeam({
        body: JSON.stringify(member),
      })
        .then(() => {
          setTeam(updateItemInArray(index, team, member));
          setLoading(false);
          setUpdating((updating) => ({
            ...updating,
            [member.id]: false,
          }));
        })
        .catch((error: string) => {
          message.error(error ? error : "Error Changing User Permissions");
          console.error(error);
          setLoading(false);
          setUpdating((updating) => ({
            ...updating,
            [member.id]: false,
          }));
        });
    } else {
      editTeamMember({
        body: JSON.stringify(member),
      })
        .then(() => {
          setTeam(updateItemInArray(index, team, member));
          setLoading(false);
          setUpdating((updating) => ({
            ...updating,
            [member.id]: false,
          }));
        })
        .catch((error: string) => {
          message.error(error ? error : "Error Changing User Permissions");
          setLoading(false);
          console.error(error);
          setUpdating((updating) => ({
            ...updating,
            [member.id]: false,
          }));
        });
    }
  };

  const updateAdminPermission: updateAdminPermissionType = (
    member,
    value,
    index,
    team,
    isAdmin
  ) => {
    const currentMember: TeamMemberType = {
      ...member,
      companyId: member.companyId,
      permissions: [value],
    };
    initiateEdit(currentMember, team, isAdmin);
  };

  const updateModulePermission: updateModulePermissionType = (
    member,
    value,
    elementType,
    currentPermission,
    team,
    isAdmin
  ) => {
    if (companyId ?? user?.companyId) {
      const currentMember: TeamMemberType = {
        ...member,
        companyId: member.companyId,
        userDTO: {
          ...member.userDTO,
          elementPermissions: createPermission(
            elementType,
            value,
            currentPermission !== PermissionType.NONE,
            member.userDTO.elementPermissions
          ),
        },
      };
      if (value === "ADMIN") {
        confirmationMessage =
          "If you proceed, the user will gain View access to all deals in their company. Are you sure you want to continue?";
      } else {
        confirmationMessage =
          "If you proceed, the user will only retain view/edit access for the deals that they created or were invited to and lose view access to all other deals in the company. Are you sure you want to continue?";
      }
      if (elementType === ElementType.DEAL_EXECUTION) {
        ConfirmAction(
          confirmationMessage,
          () => {
            initiateEdit(currentMember, team, isAdmin);
          },
          "",
          {},
          "Continue"
        );
      } else {
        initiateEdit(currentMember, team, isAdmin);
      }
    }
  };

  const fetchTeam: voidType = () => {
    setLoading(true);
    getTeam({})
      .then(({ data = [] }: ResponseType<TeamMemberType[]>) => {
        setTeam(data);
      })
      .then(() => setLoading(false));
  };

  const fetchTeamByCompanyId = (id: string): void => {
    setLoading(true);
    getTeamByCompanyId({ segments: { id } })
      .then(({ data = [] }: ResponseType<TeamMemberType[]>) => {
        setTeam(data);
      })
      .then(() => setLoading(false));
  };

  const moduleStatusRender: moduleStatusRenderType = (
    elementPermissions,
    member,
    index,
    element
  ) => {
    const currentPermission = getElementPermissionTypes(
      element,
      elementPermissions
    );
    return (
      <div
        onClick={(e): void => e.stopPropagation()}
        className={`${
          member.invitationStatus !== InvitationStatusType.ACCEPTED &&
          "cursor-not-allowed"
        }`}
      >
        <Select
          getPopupContainer={(trigger): HTMLElement => trigger.parentElement}
          disabled={any(equals(true), [
            member.invitationStatus !== InvitationStatusType.ACCEPTED,
            member?.userDTO?.userId === user?.userId,
            pathOr(false, [member.id], updating),
          ])}
          value={currentPermission}
          dropdownMatchSelectWidth={160}
          className={tableSelectCss(
            member.invitationStatus !== InvitationStatusType.PENDING
          )}
          bordered={false}
          size={"small"}
          suffixIcon={tableSelectSuffix(
            member.invitationStatus !== InvitationStatusType.PENDING,
            pathOr(false, [member.id], updating)
          )}
          placeholder={"Select Permission"}
          onChange={(value): void =>
            updateModulePermission(
              member,
              value,
              element,
              currentPermission,
              team,
              isAdminPage
            )
          }
        >
          {getSelectOptionWithBadge(modulePermissionStatusArray(element))}
        </Select>
      </div>
    );
  };

  const isModuleActive = (
    enabled: boolean,
    adminModule: CompanyModuleType | null,
    companyModule: CompanyModuleType | null,
    module: string
  ): boolean => {
    if (module === "dealExecution") {
      return enabled;
    }

    if (adminModule) {
      return pathOr(false, [module], adminModule);
    } else return pathOr(false, [module], companyModule ?? {});
  };

  const DEFAULT_COLUMNS: ColumnsType<TeamMemberType> = [
    {
      title: tableColumnHeader("Name"),
      dataIndex: "userDTO",
      className: "group-hover:bg-blue-50 min-w-[80px] max-w-[200px] truncate",
      render: function name({
        firstName = "",
        lastName = "",
      }: UserType): ReactNode {
        return firstName + " " + lastName;
      },
    },
    {
      title: tableColumnHeader("Email"),
      className: "group-hover:bg-blue-50 max-w-6xl min-w-[150px]",
      dataIndex: ["userDTO", "email"],
      render: function email(
        email: string,
        { userDTO, invitationStatus }: TeamMemberType
      ): ReactNode {
        return (
          <span>
            {ViewEmail(email)}
            &nbsp;&nbsp;
            {invitationStatus !== InvitationStatusType.ACCEPTED &&
              invitationStatusTag(invitationStatus)}
            {isNil(companyId) && userDTO.userId === user?.userId && (
              <Tag color={"green"}>YOU</Tag>
            )}
          </span>
        );
      },
    },
    {
      title: tableColumnHeader("Company Admin"),
      dataIndex: "permissions",
      className: "group-hover:bg-blue-50 min-w-[150px]",
      render: function status(
        permissions: Array<PermissionType>,
        member: TeamMemberType,
        index: number
      ): ReactNode {
        return (
          <div
            onClick={(e): void => e.stopPropagation()}
            className={`${
              member.invitationStatus !== InvitationStatusType.ACCEPTED &&
              "cursor-not-allowed"
            }`}
          >
            <Select
              getPopupContainer={(trigger): HTMLElement =>
                trigger.parentElement
              }
              disabled={any(equals(true), [
                member.invitationStatus !== InvitationStatusType.ACCEPTED,
                member?.userDTO?.userId === user?.userId,
                permissions.includes(PermissionType.ADMIN) && isLastAdmin,
                pathOr(false, [member.id], updating),
              ])}
              defaultValue={PermissionType.NON_ADMIN}
              value={
                permissions.includes(PermissionType.ADMIN)
                  ? PermissionType.ADMIN
                  : PermissionType.NON_ADMIN
              }
              dropdownMatchSelectWidth={160}
              className={tableSelectCss(
                member.invitationStatus !== InvitationStatusType.PENDING
              )}
              bordered={false}
              placeholder={"Select Permission"}
              size={"small"}
              suffixIcon={tableSelectSuffix(
                member.invitationStatus !== InvitationStatusType.PENDING,
                pathOr(false, [member.id], updating)
              )}
              onChange={(value): void =>
                updateAdminPermission(member, value, index, team, isAdminPage)
              }
            >
              {getSelectOptionWithBadge(companyPermissionStatusArray)}
            </Select>
          </div>
        );
      },
    },
  ];

  const MODULES: ModuleType[] = [
    {
      enabled: equals(user?.companyDTO?.companyType, CompanyEnumType.SPONSOR),
      module: "dealExecution",
      title: "Deal execution",
      key: "dealTeam",
      elementType: ElementType.DEAL_EXECUTION,
    },
    {
      module: "portfolioManagement",
      title: "Portfolio",
      key: "portfolio",
      elementType: ElementType.PORTFOLIO,
    },
    {
      module: "insights",
      title: "Insights",
      key: "insights",
      elementType: ElementType.INSIGHTS,
    },
    {
      module: "precedentSearch",
      title: "Precedent Search",
      key: "search",
      elementType: ElementType.PRECEDENT_SEARCH,
    },

    {
      module: "crm",
      title: "Relationships",
      key: "crm",
      elementType: ElementType.CRM,
    },
    {
      module: "sponsorCoverage",
      title: "Sponsor Coverage",
      key: "sponsorCoverage",
      elementType: ElementType.SPONSOR_COVERAGE,
    },
  ];

  const MODULE_COLUMNS = MODULES.map(
    ({ module, title, key, elementType, enabled = false }) => ({
      permission: isModuleActive(
        enabled,
        modules ?? null,
        companyModulePreference,
        module
      ),
      title: tableColumnHeader(title),
      className: "group-hover:bg-blue-50",
      key: key,
      dataIndex: ["userDTO", "elementPermissions"],
      render: function status(
        elementPermissions: ElementPermissionsType[],
        member: TeamMemberType,
        index: number
      ): ReactNode {
        return moduleStatusRender(
          elementPermissions,
          member,
          index,
          elementType
        );
      },
    })
  );

  useEffect(() => {
    if (companyId) {
      fetchTeamByCompanyId(companyId);
    } else {
      fetchTeam();
    }
  }, [companyId]);

  useEffect(() => {
    setIsLastAdmin(
      R.count(
        (member: TeamMemberType) =>
          R.includes(PermissionType.ADMIN, member.permissions),
        filter(
          (x) => x.invitationStatus === InvitationStatusType.ACCEPTED,
          team
        )
      ) <= 1
    );
  }, [team]);

  return (
    <div
      className={`relative max-h-full w-full h-full ${
        !isAdminPage && "bg-gray-100"
      } overflow-y-auto flex flex-col`}
    >
      <CustomSpin loading={loading} />
      {!isAdminPage && (
        <div className={"mb-5 flex flex-row items-center p-6 pb-0"}>
          <span className={"text-2xl font-medium"}>Permissions</span>
        </div>
      )}
      <div
        className={`w-full h-full flex-initial ${!isAdminPage && "p-6 pt-0"}`}
      >
        <Table
          rowKey={(member): string => member?.userDTO?.userId}
          className={`${
            !isAdminPage && "border"
          } transition duration-300 ease-out transform`}
          tableLayout={"auto"}
          columns={[
            ...DEFAULT_COLUMNS,
            ...(MODULE_COLUMNS.filter(
              ({ permission }) => permission
            ) as ColumnsType<TeamMemberType>),
          ]}
          pagination={false}
          dataSource={team.filter(({ userDTO }) =>
            userDTO?.email
              ?.toLowerCase()
              .includes(searchValue.toLowerCase() ?? "")
          )}
          scroll={{ x: true, y: windowHeight - 300 }}
          rowClassName={(member: TeamMemberType): string =>
            `${
              member.invitationStatus !== InvitationStatusType.PENDING &&
              member?.userDTO?.userId !== user?.userId &&
              "group"
            }`
          }
        />
      </div>
    </div>
  );
};

type updateModulePermissionType = (
  member: TeamMemberType,
  value: string,
  elementType: ElementType,
  currentPermission: string,
  team: TeamMemberType[],
  isAdmin: boolean
) => void;

type updateAdminPermissionType = (
  member: TeamMemberType,
  value: string,
  index: number,
  team: TeamMemberType[],
  isAdmin: boolean
) => void;

type initiateEditType = (
  member: TeamMemberType,
  team: TeamMemberType[],
  isAdmin: boolean
) => void;

type moduleStatusRenderType = (
  elementPermissions: ElementPermissionsType[],
  member: TeamMemberType,
  index: number,
  element: COMPANY_PERMISSIONS_ELEMENT
) => ReactNode;

type createPermissionType = (
  category: ElementType,
  permissionType: string,
  permissionExists: boolean,
  permissions?: ElementPermissionsType[]
) => ElementPermissionsType[];

type getElementPermissionTypesType = (
  category: ElementType,
  permissions?: ElementPermissionsType[]
) => string;

type PermissionsType = {
  companyId?: string;
  modules?: CompanyModuleType;
  isAdminPage?: boolean;
  searchValue?: string;
};

type ModuleType = {
  enabled?: boolean; // explicitly enable a module
  module: string;
  title: string;
  key: string;
  elementType: COMPANY_PERMISSIONS_ELEMENT;
};

type COMPANY_PERMISSIONS_ELEMENT =
  | ElementType.PORTFOLIO
  | ElementType.INSIGHTS
  | ElementType.PRECEDENT_SEARCH
  | ElementType.CRM
  | ElementType.SPONSOR_COVERAGE
  | ElementType.DEAL_EXECUTION;

type modalPropsType = (
  title: ReactNode,
  onOk: () => void,
  content?: ReactNode,
  props?: ModalFuncProps,
  okText?: string
) => void;
