import React, { FC, useEffect, useState } from "react";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { Button, Divider } from "antd";
import { JSONCodeBlock } from "../general/JSONCodeBlock";
import { getEventTypes } from "../../services/services";
import { ResponseType } from "../../utils/uiTypes";
import { WebhookEventType } from "../../utils/types";

export const WebhooksEventTypes: FC = function () {
  usePageTitle("Webhooks Event Types");
  const [data, setData] = useState<EventType | null>(null);
  const [list, setList] = useState<WebhookEventType[]>([]);
  useEffect(() => {
    getEventTypes({})
      .then(({ data }: ResponseType<WebhookEventType[]>) => {
        setList(data);
      })
      .catch(() => {});
  }, []);
  return (
    <div
      className={
        "p-6 relative max-h-full w-full h-screen bg-gray-100 overflow-y-auto flex flex-col h-screen"
      }
    >
      <div className={"bg-white p-8 border border-gray-300 text-gray-700"}>
        <div className="text-lg font-bold mb-2">Types of events</div>

        <p className="mb-6 text-base">
          This list enumerates the current types of events that we send. Please
          note that additional event types may be added at any time. Therefore,
          when developing and maintaining your code, it is recommended not to
          assume that only these event types exist.
        </p>

        <div className={"grid grid-cols-5 w-full gap-8"}>
          <div className={`${data ? "col-span-3" : "col-span-5"}`}>
            <div className={`flex flex-col`}>
              {list.map(({ description, name, versions }, i) => {
                return (
                  <React.Fragment key={i}>
                    <div>
                      <div
                        className={"flex flex-row justify-between items-center"}
                      >
                        <div className={"font-medium"}>{name}</div>
                        <Button
                          hidden={!versions?.length || versions?.length === 0}
                          className={"p-0"}
                          type={"link"}
                          onClick={() => {
                            setData({
                              json: JSON.stringify(versions[0]?.example),
                              name,
                            });
                          }}
                        >
                          View Example
                        </Button>
                      </div>
                      <div className={"text-gray-500"}>{description}</div>
                    </div>
                    {i !== list.length - 1 && <Divider />}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          {data && (
            <div className={"col-span-2"}>
              <div
                className={
                  "w-full flex flex-row justify-between items-center mb-2"
                }
              >
                <span className={"text-base font-medium text-gray-600"}>
                  {data.name}
                </span>
                <Button
                  type={"link"}
                  className={"px-0"}
                  onClick={() => setData(null)}
                >
                  Close
                </Button>
              </div>
              <JSONCodeBlock width={"full"} json={JSON.parse(data.json)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

type EventType = {
  json: string;
  name: string;
};
