import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AllocationKeyType,
  FinancialFieldDataType,
  PortfolioType,
} from "../../utils/types";
import { ResponseType, voidType } from "../../utils/uiTypes";
import {
  deletePortfolioById,
  getAllocationKeys,
} from "../../services/services";
import { Badge, Button, message, Table, Tag, Tooltip } from "antd";
import { useHistory } from "react-router";
import { ElementType, PermissionType, UnitLabelType } from "../../utils/enums";
import moment from "moment";
import { UserContext } from "../../context/UserContext";
import { CreatePortfolioModal } from "./portfolioModals/CreatePortfolioModal";
import { ConfirmDelete, ConfirmDownload } from "../../utils/confirmationModals";
import { checkElementViewPermission } from "../../utils/permissions";
import { ColumnsType } from "antd/es/table";
import { path } from "ramda";
import useWindowDimensions from "../../customHooks/useWindowDimensions";
import { roundAndFormat } from "../../utils/roundAndFormat";

export const COLUMN_STYLE = "group-hover:bg-blue-50";

type PortfoliosSummaryType = {
  portfolios: PortfolioType[];
  setPortfolios: Dispatch<SetStateAction<PortfolioType[]>>;
  refreshPortfolioList: () => void;
};

export const PortfoliosSummary: FC<PortfoliosSummaryType> = ({
  portfolios,
  setPortfolios,
  refreshPortfolioList,
}) => {
  const history = useHistory();
  const { user } = useContext(UserContext);

  const { height } = useWindowDimensions();

  const [fundList, setFundList] = useState<AllocationKeyType[]>();
  const [addPortfolioModal, setAddPortfolioModal] = useState<boolean>(false);

  const columns: ColumnsType<PortfolioType> = [
    {
      title: "Company",
      key: "portfolioCompanyName",
      dataIndex: "portfolioCompanyName",
      width: 240,
      className: COLUMN_STYLE,
      sorter: {
        compare: (a, b): number =>
          a.portfolioCompanyName > b.portfolioCompanyName ? 1 : -1,
      },
      render(value: string, { id }): ReactNode {
        return (
          <div className={"flex"}>
            <div className={"flex-1"}>{value}</div>
            {user &&
              checkElementViewPermission(
                ElementType.PORTFOLIO,
                user.elementPermissions,
                [PermissionType.ADMIN_PORTFOLIO, PermissionType.EDIT_PORTFOLIO],
                false
              ) && (
                <div className={"ml-auto"}>
                  <i
                    className="fa fa-trash-alt mt-1 cursor-pointer opacity-0 group-hover:opacity-50 ml-2"
                    onClick={(e): void => {
                      e.stopPropagation();
                      ConfirmDelete(
                        "Are you sure you want to remove this portfolio? This will erase all previously saved data.",
                        () => {
                          removePortfolio(id);
                        },
                        <></>
                      );
                    }}
                  />
                </div>
              )}
          </div>
        );
      },
    },
    {
      title: "Investment Date",
      key: "investmentDate",
      dataIndex: "investmentDate",
      width: 175,
      className: COLUMN_STYLE,
      sorter: (a, b): number =>
        Number(a.investmentDate) - Number(b.investmentDate),
      render: (value: string): ReactNode =>
        value ? moment(Number(value)).format("MM/DD/yyyy") : "-",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 100,
      className: COLUMN_STYLE,
      render: (value: string, record): ReactNode =>
        record.status === undefined ? (
          "-"
        ) : record.status ? (
          <Badge color="blue" text="Active" />
        ) : (
          <Badge color="gray" text="Past" />
        ),
    },
    {
      title: "Funds(s)",
      key: "funds",
      dataIndex: "funds",
      width: 200,
      className: COLUMN_STYLE,
      render: (value, record): ReactNode =>
        record.funds && record.funds.length > 0
          ? getFundArray(record.funds)
          : "-",
    },
    {
      title: "LTM Revenue",
      key: "ltmrevenue",
      dataIndex: "ltmrevenue",
      width: 150,
      className: COLUMN_STYLE,
      render: (
        record: FinancialFieldDataType,
        { currency }: PortfolioType
      ): ReactNode =>
        record?.value
          ? `${currency?.replace(/\s+/g, "")} ${roundAndFormat(record.value)} ${
              UnitLabelType[record?.unit]
            }`
          : "-",
    },
    {
      title: "LTM EBITDA",
      key: "ltmadjebitda",
      dataIndex: "ltmadjebitda",
      width: 150,
      className: COLUMN_STYLE,
      render: (record: FinancialFieldDataType, { currency }): ReactNode =>
        record?.value
          ? `${currency?.replace(/\s+/g, "")} ${roundAndFormat(record.value)} ${
              UnitLabelType[record?.unit]
            }`
          : "-",
    },
    {
      title: "Net Debt",
      key: "netdebt",
      dataIndex: "netdebt",
      width: 150,
      className: COLUMN_STYLE,
      render: (record: FinancialFieldDataType, { currency }): ReactNode =>
        record?.value
          ? `${currency?.replace(/\s+/g, "")} ${roundAndFormat(record.value)} ${
              UnitLabelType[record?.unit]
            }`
          : "-",
    },
    {
      title: "Leverage",
      key: "netleverageratio",
      dataIndex: "netleverageratio",
      width: 150,
      className: COLUMN_STYLE,
      sorter: (a, b) => {
        const v1 = path<string>(["netleverageratio", "value"], a) ?? "";
        const v2 = path<string>(["netleverageratio", "value"], b) ?? "";
        return v1?.localeCompare(v2, undefined, { numeric: true });
      },
      render: (record: FinancialFieldDataType): ReactNode =>
        record?.value ? `${roundAndFormat(record.value)} ${record.unit}` : "-",
    },
    {
      title: "Quarter",
      key: "lastUpdated",
      dataIndex: "lastUpdated",
      width: 100,
      className: COLUMN_STYLE,
    },
  ];

  const removePortfolio: removePortfolioType = (portfolioId: string): void => {
    deletePortfolioById({
      segments: {
        portfolioId,
      },
    })
      .then(() => {
        setPortfolios((portfolios) =>
          portfolios ? portfolios.filter((o) => o.id !== portfolioId) : []
        );
        message.success("Portfolio removed successfully.");
      })
      .catch((error: string) => {
        message.error(error ? error : "Something went wrong. Please try again");
      });
  };

  const getFundArray: getFundArrayType = (funds) => {
    const fundData = funds.map((fund) =>
      fundList?.find((item) => item.id === fund)
    );
    return (
      <>
        {fundData.slice(0, 2).map((fund) => (
          <Tag className={"bg-gray-100"} key={fund?.id}>
            {fund?.keyName}
          </Tag>
        ))}
        {fundData.length > 2 && (
          <Tooltip
            title={fundData
              .slice(2)
              .map(
                (fund, i, arr) =>
                  `${fund?.keyName}${i === arr.length - 1 ? "" : ", "}`
              )}
          >
            <Tag className={"bg-gray-100"} key={"extraFunds"}>
              +{fundData.length - 2}
            </Tag>
          </Tooltip>
        )}
      </>
    );
  };

  const addNewPortfolio: addNewPortfolioType = (portfolio) => {
    setPortfolios((portfolios) =>
      portfolios ? [portfolio, ...portfolios] : [portfolio]
    );
    setAddPortfolioModal(false);
  };

  const getFundsList: voidType = () => {
    if (user)
      getAllocationKeys({
        segments: {
          companyId: user.companyId,
        },
      })
        .then(({ data = [] }: ResponseType<AllocationKeyType[]>) => {
          setFundList(
            data.filter((item) => item.allocationTableType === "FUND")
          );
        })
        .catch((error: string) => {
          message.error(
            error ? error : "Something went wrong. Please try again.",
            2
          );
        });
  };

  useEffect(() => getFundsList(), [user]);

  useEffect(() => {
    refreshPortfolioList();
  }, []);

  return (
    <div
      className={
        "relative max-h-full w-full h-screen bg-gray-100 flex flex-col p-6"
      }
    >
      <div className={"mb-5 flex flex-row items-center justify-between"}>
        <span className={"text-2xl font-medium"}>Portfolio Management</span>
        {user &&
          checkElementViewPermission(
            ElementType.PORTFOLIO,
            user.elementPermissions,
            [PermissionType.ADMIN_PORTFOLIO, PermissionType.EDIT_PORTFOLIO],
            false
          ) && (
            <div className={"flex flex-row gap-2"}>
              <Button
                onClick={(): void => setAddPortfolioModal(true)}
                className={"ml-auto bg-primary text-white border-0"}
              >
                + New Portfolio Company
              </Button>
            </div>
          )}
      </div>

      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={portfolios}
        scroll={{ x: true, y: height - 300 }}
        pagination={false}
        onRow={(record): { onClick: () => void } => ({
          onClick: (): void => history.push(`/portfolio/${record.id}/overview`),
        })}
        rowClassName={"cursor-pointer group"}
        sticky={true}
      />

      {addPortfolioModal && (
        <CreatePortfolioModal
          visible={addPortfolioModal}
          setVisible={setAddPortfolioModal}
          portfolios={portfolios}
          onSuccess={addNewPortfolio}
        />
      )}
    </div>
  );
};

type getFundArrayType = (funds: string[]) => ReactNode;
type addNewPortfolioType = (portfolio: PortfolioType) => void;
type removePortfolioType = (id: string) => void;
