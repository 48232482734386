import React, { FC, ReactNode, useEffect, useState } from "react";
import { ConfigurationContext } from "./ConfigurationContext";
import {
  CompanySettingConfigType,
  DealCloudConfigType,
} from "../../utils/types";
import { getConfig } from "../../services/services";
import { ConfigType } from "../../utils/enums";
import { ResponseType } from "../../utils/uiTypes";
import { useUser } from "../../hooks/useUser";

export const ConfigurationProvider: FC<ConfigurationProviderType> = ({
  children,
}) => {
  const { user } = useUser();
  const [configValues, setConfigValues] = useState<
    Record<ConfigType, CompanySettingConfigType | null>
  >({ DEAL_CLOUD_CONFIG: null, PASSWORD_POLICY: null });

  const getCompanySettingConfiguration = (
    configType: ConfigType,
    callBack: any
  ) => {
    getConfig({
      segments: {
        elementType: "COMPANY",
        configType,
      },
    })
      .then(
        ({
          data,
        }: ResponseType<CompanySettingConfigType<DealCloudConfigType> | null>) => {
          callBack(data ?? null);
        }
      )
      .catch((e: string) => {
        console.error(e);
      });
  };

  const updateValues = (
    config: ConfigType,
    value: CompanySettingConfigType
  ): void => {
    setConfigValues((v) => ({ ...v, [config]: value }));
  };

  useEffect(() => {
    getCompanySettingConfiguration(
      ConfigType.DEAL_CLOUD_CONFIG,
      (v: CompanySettingConfigType) =>
        setConfigValues((configValues) => ({
          ...configValues,
          [ConfigType.DEAL_CLOUD_CONFIG]: v,
        }))
    );
    getCompanySettingConfiguration(
      ConfigType.PASSWORD_POLICY,
      (v: CompanySettingConfigType) =>
        setConfigValues((configValues) => ({
          ...configValues,
          [ConfigType.PASSWORD_POLICY]: v,
        }))
    );
  }, [user]);

  return (
    <ConfigurationContext.Provider
      value={{
        configValues,
        setConfigValues: updateValues,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

type ConfigurationProviderType = {
  children: ReactNode | ReactNode[];
};
