import React, { FC, useEffect, useState } from "react";
import { Alert, Button, Form, Input, Spin } from "antd";
import { useHistory, useParams } from "react-router";
import { resetPassword } from "../services/services";
import { usePageTitle } from "../customHooks/usePageTitle";
import TermgridLogo from "../images/logo/TermgridLogoLight.svg";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { getMinPassword } from "../utils/password-criteria";

const PASSWORD_CRITERIA = (min: number | undefined = 8) => [
  {
    valid: false,
    regex: new RegExp("^.{" + min + ",}$"),
    text: `Must be at least ${min} characters.`,
  },
  {
    valid: false,
    regex: /^.*\d.*$/,
    text: "Must contain at least 1 Number.",
  },
  {
    valid: false,
    regex: /^.*[A-Z].*$/,
    text: "Must contain at least 1 Letter Upper Case.",
  },
  {
    valid: false,
    regex: /^.*[a-z].*$/,
    text: "Must contain at least 1 Letter Lower Case.",
  },
  {
    valid: false,
    regex: /^.*[\W_].*$/,
    text: "Must contain at least 1 Special Character.",
  },
];
export const ResetPassword: FC = function () {
  usePageTitle("Reset Password", false);
  const { hash }: ParamsType = useParams();

  const history = useHistory();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordUpdated, setPasswordUpdated] = useState<boolean>(false);
  const [passwordCriteria, setPasswordCriteria] = useState<criteriaType[]>([]);

  const onResetPassword: onResetPasswordType = (formValues) => {
    const { confirmPassword, newPassword } = formValues;

    if (newPassword !== confirmPassword) {
      setError("Passwords don't match.");
      return;
    } else {
      setError(null);
    }
    setLoading(true);
    resetPassword({
      segments: { hash },
      body: JSON.stringify({ confirmPassword, newPassword }),
    })
      .then(() => {
        setLoading(false);
        setPasswordUpdated(true);
      })
      .catch((error: string) => {
        setError(error);
        setLoading(false);
      });
  };

  const onSuccess = (data: string) => {
    setPasswordCriteria(PASSWORD_CRITERIA(Number(data)));
    setLoading(false);
  };

  const onFailure = () => {
    setLoading(false);
  };

  // get company config for min password
  useEffect(() => {
    getMinPassword(hash, onSuccess, onFailure);
  }, [hash]);

  return (
    <div
      style={{
        backgroundImage: "url('../../images/hero.jpg')",
      }}
      className={
        "h-screen w-screen bg-cover bg-center overflow-y-auto hide-scrollbar"
      }
    >
      <img
        className={"mx-auto pt-5 w-48"}
        src={TermgridLogo}
        alt={"Termgrid Logo"}
      />
      <div className={"px-5 mx-auto"}>
        <div className={"bg-white border mx-auto mt-16 sm:mt-24 p-10 max-w-lg"}>
          <div>
            <div className={"text-2xl font-medium"}>Reset Password</div>
            <div className={"text-sm font-normal mt-2 mb-8"}>
              Enter new password here
            </div>
            <Form
              onFinish={(values): void => {
                onResetPassword(values);
              }}
            >
              <Form.Item
                name="newPassword"
                className={"mb-0"}
                rules={[{ required: true, message: "Please enter password." }]}
              >
                <Input.Password
                  placeholder={"Password"}
                  type={"password"}
                  onChange={(e): void =>
                    setPasswordCriteria((list) =>
                      list.map((criteria) => ({
                        ...criteria,
                        valid: criteria.regex.test(e.target.value),
                      }))
                    )
                  }
                />
              </Form.Item>
              <div className={"mt-2 mb-6"}>
                {passwordCriteria.map((item) => (
                  <p
                    key={item.text}
                    className={`${
                      item.valid ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {item.valid ? (
                      <CheckCircleOutlined />
                    ) : (
                      <CloseCircleOutlined />
                    )}{" "}
                    {item.text}
                  </p>
                ))}
              </div>
              <Form.Item
                name="confirmPassword"
                rules={[
                  { required: true, message: "Please confirm your password!" },
                ]}
              >
                <Input.Password
                  placeholder={"Confirm Password"}
                  type={"password"}
                />
              </Form.Item>

              <Form.Item>
                <>
                  {passwordUpdated && (
                    <Alert
                      className={"mb-4"}
                      message={
                        <p className={"text-green-500"}>
                          Password Changed Successfully.{" "}
                          <a
                            onClick={(): void => history.push("/login")}
                            className={"text-primary"}
                          >
                            Click here for Login.
                          </a>
                        </p>
                      }
                      type="success"
                    />
                  )}
                  {error && (
                    <Alert className={"mb-4"} message={error} type="error" />
                  )}
                  <Button
                    className={
                      "w-32 bg-primary hover:bg-hover text-white border-0"
                    }
                    disabled={
                      loading ||
                      passwordCriteria.filter((item) => !item.valid).length > 0
                    }
                    htmlType={"submit"}
                  >
                    {loading && <Spin className={"mr-2"} size={"small"} />}
                    Reset
                  </Button>
                </>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

type ParamsType = {
  hash: string;
};
type onResetPasswordType = (formValues: {
  confirmPassword: string;
  newPassword: string;
}) => void;
type criteriaType = {
  valid: boolean;
  regex: RegExp;
  text: string;
};
