import React, { ReactNode, useEffect, useState } from "react";
import { Select, SelectProps } from "antd";
import Fuse from "fuse.js";
import { DefaultOptionType } from "rc-select/lib/Select";
import { getAbbreviation } from "../../utils/fuzzy-search";

const assignWeights: AssignWeights = (list, weight) => {
  return list.map((item) => {
    return {
      name: item,
      weight: weight,
    };
  });
};

export const FuzzySearchSelect = <T extends OptionsType>({
  items,
  getOptionKey,
  getOptionLabel,
  getOptionValue,
  searchPaths,
  isOptionDisabled,
  getAbbrevationPath,
  isOptionHidden,
  ...selectProps
}: FuzzySearchSelectType<T>): React.ReactElement<FuzzySearchSelectType<T>> => {
  const [mainItems, setMainItems] = useState<T[]>([]);
  const [dropDownItems, setDropDownItems] = useState<T[]>([]);
  const [fuse, setFuse] = useState<Fuse<T>>();

  const options = {
    threshold: 0.4,
    keys: [
      ...assignWeights(searchPaths, 2),
      ...(getAbbrevationPath
        ? [
            {
              name: "abbreviation",
              weight: 1,
            },
          ]
        : []),
    ],
  };

  useEffect(() => {
    const modifiedItems = items.map((item) =>
      getAbbrevationPath
        ? {
            ...item,
            abbreviation: getAbbreviation(getAbbrevationPath(item)),
          }
        : item
    );
    setMainItems(modifiedItems);
    setDropDownItems(modifiedItems);
    setFuse(new Fuse(modifiedItems, options));
  }, [items]);

  return (
    <Select
      {...selectProps}
      filterOption={
        isOptionHidden
          ? (input, option) => (option ? !isOptionHidden(option) : false)
          : false
      }
      onDeselect={(val) => {
        if (!val) {
          setDropDownItems(mainItems);
        }
      }}
      onSelect={(value, option) => {
        setDropDownItems(mainItems);
        if (selectProps.onSelect) {
          selectProps.onSelect(value, option);
        }
      }}
      onSearch={(val) => {
        setDropDownItems(
          val && fuse
            ? fuse.search(val.trim()).map((result) => result.item)
            : mainItems
        );
      }}
      onBlur={() => {
        setDropDownItems(mainItems);
      }}
    >
      {dropDownItems.map((item: T) => (
        <Select.Option
          value={getOptionValue(item)}
          key={getOptionKey(item)}
          disabled={isOptionDisabled && isOptionDisabled(item)}
        >
          {getOptionLabel(item)}
        </Select.Option>
      ))}
    </Select>
  );
};

type OptionsType = {
  [key: string]: any;
};

type AssignWeights = (list: string[], weight: number) => any;

export type FuzzySearchSelectType<T> = SelectProps & {
  items: Array<T>;
  getOptionKey: (item: T) => string;
  getOptionLabel: (item: T) => ReactNode;
  getOptionValue: (item: T) => string;
  searchPaths: string[];
  isOptionDisabled?: (item: T) => boolean;
  getAbbrevationPath?: (item: T) => string;
  isOptionHidden?: (item: DefaultOptionType) => boolean;
};
