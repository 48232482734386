import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import {
  CommentType,
  LenderType,
  UnreadCountType,
  UserType,
} from "../../../../utils/types";
import {
  getComments,
  getDealTeam,
  getUnreadCount,
  shareComment,
} from "../../../../services/services";
import { Badge, Button, Empty, message as msg, message, Modal } from "antd";
import { CommunicationsThreads } from "./CommunicationsThreads";
import { Thread } from "../../../general/Thread";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { CustomSpin } from "../../../general/CustomSpin";
import { TransactionContext } from "../../../../context/TransactionContext";
import {
  fetchWithIdType,
  LoadingType,
  ResponseType,
} from "../../../../utils/uiTypes";
import { ElementType, PermissionType } from "../../../../utils/enums";
import { useHistory } from "react-router";
import { usePageTitle } from "../../../../customHooks/usePageTitle";
import { MultipleComment } from "./MultipleComment";
import {
  getObjectFromPropertyValue,
  valOrDefault,
} from "../../../../utils/utils";
import { threadPrefix } from "../../../../utils/componentUtils";
import { useInterval } from "../../../../customHooks/useInterval";
import { path } from "ramda";
import { isOfflineBorrower } from "../../../../utils/transaction";

export const Communications: FC = function () {
  usePageTitle("Communications");

  const { transaction, permissions, belongToDealTeam } =
    useContext(TransactionContext);
  const history = useHistory();

  const [unreadCount, setUnreadCount] = useState<UnreadCountType>({});
  const [refreshingCurrent, setRefreshingCurrent] = useState<boolean>(false);
  const [loading, setLoading] = useState<LoadingType>({
    loading: false,
    label: "",
  });
  const [multipleCommentModal, setMultipleCommentModal] =
    useState<boolean>(false);
  const [conversationThreads, setConversationThreads] = useState<
    Record<string, ElementType.LENDER | ElementType.PETRANSACTION>
  >({});
  const [currentActiveThread, setCurrentActiveThread] =
    useState<ThreadDataType>({
      type: null,
      lender: null,
      id: null,
    });
  const [dealTeam, setDealTeam] = useState<UserType[]>([]);
  const [conversations, setConversations] = useState<CommentType[]>([]);

  const threadSuffixIcon = (count: string, current = false): ReactNode => (
    <span className={"flex flex-row items-center gap-x-3"}>
      {current && (
        <Button
          className={`border-0 p-0 shadow flex items-center justify-center bg-white motion-safe:hover:font-medium text-gray-500 shadow-[0_2px_4px_rgba(0,0,0,0.18)] hover:bg-secondary-background hover:shadow-inner`}
          shape={"circle"}
          size={"small"}
          onClick={(): void => {
            transaction &&
              fetchComments(
                currentActiveThread?.type,
                currentActiveThread?.id,
                transaction.peTransactionId
              );
          }}
        >
          <i
            className={`${
              refreshingCurrent && "animate-spin"
            } fas fa-sync-alt text-gray-400`}
          />
        </Button>
      )}
      {(Number(count) ?? 0) > 0 && (
        <Badge
          count={count ? Number(count) ?? 0 : 0}
          size={"small"}
          className={"mt-1"}
        >
          <i className="far fa-envelope text-gray-400" />
        </Badge>
      )}
    </span>
  );

  const getThreadData: getThreadDataType = (type, lender, id) => ({
    type,
    lender,
    id,
  });

  const getConversationThreads: getConversationThreadsType = (
    peTransactionId,
    lenderDTOs
  ) =>
    valOrDefault(
      {},
      lenderDTOs.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: ElementType.LENDER }),
        belongToDealTeam && peTransactionId
          ? { [peTransactionId]: ElementType.PETRANSACTION }
          : {}
      )
    );

  const fetchDealTeam: fetchWithIdType = (id) => {
    setLoading({ loading: true, label: "Fetching Deal Team..." });
    getDealTeam({
      segments: {
        id,
      },
      params: {
        isTeamPage: true,
        pageName: "communication",
      },
    })
      .then(({ data = [] }: ResponseType<UserType[]>) => {
        setDealTeam(data);
        setLoading({ loading: false });
      })
      .catch((error: string) => {
        message.error(error ? error : "Failed Fetching the Deal Team!");
        setLoading({ loading: false });
      });
  };

  const refreshUnreadCount: refreshUnreadCountType = (conversationThreads) => {
    getUnreadCount({
      body: JSON.stringify(conversationThreads),
    }).then(({ data }: ResponseType<UnreadCountType>) => {
      setUnreadCount(data);
    });
  };

  const addMultipleConversations: addMultipleConversationsType = (
    values,
    id
  ) => {
    setLoading({
      loading: true,
      label: "Sending Messages to Multiple Institutions...",
    });
    shareComment({
      segments: {
        id,
      },
      body: JSON.stringify(values),
    })
      .then(() => setLoading({ loading: false }))
      .catch((error: string) => {
        message.error(error);
        setLoading({ loading: false });
      });
  };

  const fetchComments: fetchCommentsType = (
    threadType,
    activeThreadId,
    peTransactionId
  ) => {
    if (threadType && activeThreadId) {
      setRefreshingCurrent(true);
      getComments({
        segments: {
          type: threadType,
          id: activeThreadId,
          transactionId: peTransactionId,
        },
      })
        .then(({ data = [] }: ResponseType<CommentType[]>) => {
          setConversations(data);
          setRefreshingCurrent(false);
        })
        .catch((error: string) => {
          msg.error(error ? error : "Error Loading Comments!");
          setConversations([]);
        });
    }
  };

  useEffect(() => {
    const lenderId = new URLSearchParams(location.search).get("lenderId");
    setCurrentActiveThread(
      getThreadData(
        lenderId
          ? ElementType.LENDER
          : belongToDealTeam
          ? ElementType.PETRANSACTION
          : ElementType.LENDER,
        lenderId
          ? getObjectFromPropertyValue(
              "id",
              lenderId,
              transaction?.lenderDTOs ?? []
            )
          : belongToDealTeam
          ? null
          : path([0], transaction?.lenderDTOs ?? []) ?? null,
        lenderId
          ? path<string>(
              ["id"],
              getObjectFromPropertyValue(
                "id",
                lenderId,
                transaction?.lenderDTOs ?? []
              )
            ) ?? null
          : belongToDealTeam
          ? transaction?.peTransactionId ?? null
          : path<string>([0, "id"], transaction?.lenderDTOs ?? []) ?? null
      )
    );
  }, [transaction?.lenderDTOs, transaction?.peTransactionId, belongToDealTeam]);

  useEffect(() => {
    transaction &&
      setConversationThreads(
        getConversationThreads(
          transaction?.peTransactionId,
          transaction?.lenderDTOs
        )
      );
  }, [transaction?.lenderDTOs, transaction?.peTransactionId]);

  useEffect(() => {
    transaction && fetchDealTeam(transaction?.peTransactionId);
  }, [transaction, transaction?.peTransactionId]);

  useInterval(
    (): void => {
      refreshUnreadCount(conversationThreads);
    },
    5000,
    [conversationThreads]
  );

  useEffect(() => {
    transaction &&
      fetchComments(
        currentActiveThread?.type,
        currentActiveThread?.id,
        transaction?.peTransactionId
      );
  }, [currentActiveThread?.type, currentActiveThread?.id]);

  return (
    <div className={"relative max-h-full h-full w-full"}>
      <CustomSpin
        loading={loading.loading || transaction === null}
        loadingText={!transaction ? "Fetching Transaction..." : loading?.label}
      />
      {transaction &&
      permissions?.includes(PermissionType.VIEW_COMMUNICATION) &&
      currentActiveThread?.id !== null ? (
        <>
          <div className={"p-0 grid grid-cols-12 h-full max-h-full w-full"}>
            <div
              className={
                "col-span-1 md:col-span-3 pt-5 flex flex-col border-r h-full overflow-auto w-full max-h-full bg-secondary-background"
              }
            >
              {transaction?.peTransactionId && belongToDealTeam && (
                <>
                  <div
                    className={
                      "hidden md:flex text-subtitle uppercase mx-auto md:ml-5 mb-1 text-xs text-gray-400 font-medium"
                    }
                  >
                    MY TEAM
                  </div>
                  <Thread
                    active={
                      currentActiveThread?.id === transaction?.peTransactionId
                    }
                    onClick={(): void => {
                      setCurrentActiveThread(
                        getThreadData(
                          ElementType.PETRANSACTION,
                          null,
                          transaction?.peTransactionId
                        )
                      );
                      history.push(
                        `/transactions/${transaction?.peTransactionId}/communications`
                      );
                    }}
                    prefix={threadPrefix(
                      "G",
                      "default",
                      currentActiveThread?.id === transaction?.peTransactionId
                    )}
                    icon={threadSuffixIcon(
                      unreadCount[transaction?.peTransactionId],
                      currentActiveThread?.id === transaction?.peTransactionId
                    )}
                    colorLiteral={"default"}
                  >
                    General Thread
                  </Thread>
                </>
              )}
              {!isOfflineBorrower(transaction) && (
                <>
                  <div
                    className={`hidden md:flex flex-col flex-wrap lg:flex-row items-center text-subtitle uppercase mx-5 mb-1 ${
                      belongToDealTeam && `mt-5`
                    } text-xs text-gray-400 font-medium `}
                  >
                    INSTITUTIONS
                    <span className={"w-2 mx-auto"} />
                    {belongToDealTeam &&
                      transaction?.lenderDTOs?.length > 1 && (
                        <Button
                          className={
                            "m-0 text-xs font-medium p-0 whitespace-pre-line"
                          }
                          onClick={(): void =>
                            setMultipleCommentModal(!multipleCommentModal)
                          }
                          disabled={
                            !permissions?.includes(
                              PermissionType.CREATE_COMMUNICATION
                            )
                          }
                          type={"link"}
                        >
                          MESSAGE MULTIPLE INSTITUTIONS
                        </Button>
                      )}
                  </div>
                  <div
                    className={`m-0 p-0 flex-initial max-h-full h-full overflow-y-auto ${
                      transaction?.lenderDTOs?.length === 0 && "flex flex-col"
                    }`}
                  >
                    {transaction?.lenderDTOs?.length === 0 ? (
                      <Empty
                        className={"my-auto text-gray-400"}
                        description={"No Institutions have been added yet"}
                      >
                        <Button
                          className={
                            "bg-primary hover:bg-hover border-0 text-white"
                          }
                          icon={<i className="fas fa-plus mr-1" />}
                          onClick={(): void =>
                            history.push(
                              `/transactions/${transaction?.peTransactionId}/institutions`
                            )
                          }
                        >
                          Add New Institution
                        </Button>
                      </Empty>
                    ) : (
                      transaction?.lenderDTOs?.map((lender: LenderType) => (
                        <Thread
                          key={lender?.id}
                          active={currentActiveThread?.id === lender?.id}
                          colorLiteral={lender?.name?.charAt(0)?.toUpperCase()}
                          icon={threadSuffixIcon(
                            unreadCount[lender?.id],
                            currentActiveThread?.id === lender?.id
                          )}
                          tag={lender.tagDTOs}
                          onClick={(): void => {
                            setCurrentActiveThread(
                              getThreadData(
                                ElementType.LENDER,
                                lender,
                                lender?.id ?? ""
                              )
                            );
                            history.push({
                              pathname: `/transactions/${transaction?.peTransactionId}/communications`,
                              search: `?lenderId=${lender.id}`,
                            });
                          }}
                          prefix={threadPrefix(
                            lender?.name?.charAt(0)?.toUpperCase(),
                            lender?.name?.charAt(0)?.toUpperCase(),
                            currentActiveThread?.id === lender?.id
                          )}
                        >
                          {lender?.name}
                        </Thread>
                      ))
                    )}
                  </div>
                </>
              )}
            </div>
            <div
              className={"col-span-11 md:col-span-9 max-h-full overflow-y-auto"}
            >
              {!loading?.loading && currentActiveThread?.type !== null && (
                <CommunicationsThreads
                  threadType={currentActiveThread?.type}
                  activeThreadId={currentActiveThread?.id}
                  refreshUnread={(): void =>
                    refreshUnreadCount(conversationThreads)
                  }
                  belongToDealTeam={belongToDealTeam ?? false}
                  dealTeam={dealTeam}
                  permissions={permissions}
                  lender={currentActiveThread?.lender}
                  peTransactionId={transaction?.peTransactionId}
                  conversations={conversations}
                  setConversations={setConversations}
                />
              )}
            </div>
          </div>
          <Modal
            closable={true}
            open={multipleCommentModal}
            maskClosable={false}
            onCancel={(): void =>
              setMultipleCommentModal(!multipleCommentModal)
            }
            title={"Create Topic for Multiple Institutions"}
            okText={"Send Conversations"}
            footer={false}
            destroyOnClose={true}
            width={"50%"}
          >
            <MultipleComment
              institutions={transaction?.lenderDTOs.filter(
                (item) => item.userDTOs?.length
              )}
              onSubmit={(values): void => {
                addMultipleConversations(values, transaction?.peTransactionId);
                setMultipleCommentModal(!multipleCommentModal);
              }}
            />
          </Modal>
        </>
      ) : (
        <>
          {transaction && (
            <div className={"flex h-full items-center justify-center"}>
              <Empty
                description={"Not Allowed"}
                className={"m-auto text-gray-400"}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

type addMultipleConversationsType = (
  values: {
    comment: string;
    elementIds: CheckboxValueType[];
  },
  id: string
) => void;

type ThreadDataType = {
  type: ElementType.PETRANSACTION | ElementType.LENDER | null;
  lender: LenderType | null;
  id: string | null;
};

type getThreadDataType = (
  type: ElementType.PETRANSACTION | ElementType.LENDER | null,
  lender: LenderType | null,
  id: string | null
) => ThreadDataType;

type getConversationThreadsType = (
  peTransactionId: string,
  lenderDTOs: LenderType[]
) => Record<string, ElementType.LENDER | ElementType.PETRANSACTION>;

type refreshUnreadCountType = (
  communicationThreads: Record<
    string,
    ElementType.LENDER | ElementType.PETRANSACTION
  >
) => void;

type fetchCommentsType = (
  threadType: ElementType.PETRANSACTION | ElementType.LENDER | null,
  activeThreadId: string | null,
  peTransactionId: string
) => void;
