import React, { FC, useEffect, useState } from "react";
import { BellOutlined } from "@ant-design/icons";
import { CustomSpin } from "../general/CustomSpin";
import { ResponseType, voidType } from "../../utils/uiTypes";
import {
  getNotificationSettings,
  notificationSubscribe,
  notificationUnsubscribe,
} from "../../services/services";
import { message, Switch } from "antd";
import { NotificationSubscriptionType } from "../../utils/types";
import { groupBy, lensPath, prop, set } from "ramda";
import { NotificationSettingType } from "../../utils/enums";

export const Notifications: FC = function () {
  const [loading, setLoading] = useState<{ loading: boolean; label?: string }>({
    loading: false,
    label: "",
  });
  const [settings, setSettings] = useState<
    [string, NotificationSubscriptionType[]][] | null
  >(null);

  /**
   * Fetch Subscriptions from Database
   */
  const fetchNotifications: voidType = () => {
    getNotificationSettings({})
      .then(({ data }: ResponseType<NotificationSubscriptionType[]>) => {
        setSettings(
          Object.entries(groupBy(prop("notificationCategoryLabel"))(data))
        );
      })
      .catch((error: string) => {
        setLoading({ loading: false });
        message.error(error);
      });
  };

  /**
   * Subscribe to a type of notification
   * @param type - is for sending the notification type in the body
   * @param categoryIndex - is for the category index to update on success
   * @param settingIndex - is for the setting index to update on success
   */
  const subscribe: subscribeType = (type, categoryIndex, settingIndex) => {
    notificationSubscribe({
      body: JSON.stringify({
        notificationTypes: [type],
      }),
    })
      .then(() => {
        setSettings(
          set(
            lensPath([categoryIndex, 1, settingIndex, "enabled"]),
            true,
            settings
          ) as [string, NotificationSubscriptionType[]][]
        );
      })
      .catch((error: string) => {
        message.error(error);
      });
  };

  /**
   * Un-Subscribe to a type of notification
   * @param type - is for sending the notification type in the body
   * @param categoryIndex - is for the category index to update on success
   * @param settingIndex - is for the setting index to update on success
   */
  const unsubscribe: subscribeType = (type, categoryIndex, settingIndex) => {
    notificationUnsubscribe({
      body: JSON.stringify({
        notificationTypes: [type],
      }),
    })
      .then(() => {
        setSettings(
          set(
            lensPath([categoryIndex, 1, settingIndex, "enabled"]),
            false,
            settings
          ) as [string, NotificationSubscriptionType[]][]
        );
      })
      .catch((error: string) => {
        message.error(error);
      });
  };

  /**
   * Toggle the notification subscription
   * @param type - is for sending the notification type in the body
   * @param categoryIndex - is for the category index to update on success
   * @param settingIndex - is for the setting index to update on success
   */
  const toggleSubscription: toggleSubscriptionType = (
    type,
    categoryIndex,
    settingIndex,
    enabled
  ) => {
    if (enabled) {
      subscribe(type, categoryIndex, settingIndex);
    } else {
      unsubscribe(type, categoryIndex, settingIndex);
    }
  };

  /**
   * Fetch the subscriptions settings on load
   */
  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      {loading.loading ? (
        <CustomSpin loading={loading.loading} loadingText={loading.label} />
      ) : (
        <div className={"max-h-full h-full w-full p-6"}>
          <div className={"bg-white p-6 h-full overflow-y-auto"}>
            <div className={"max-w-screen-md mx-auto"}>
              <div className={"text-center text-lg font-medium py-6"}>
                <BellOutlined className={"pr-2"} />
                Notification Settings
              </div>
              <div className={"flex flex-col gap-5"}>
                {settings?.map(([title, setting], categoryIndex) => (
                  <div className={"flex flex-col"} key={title}>
                    <span
                      className={
                        "select-none uppercase font-medium text-gray-400 mb-1 border-b"
                      }
                    >
                      {title}
                    </span>
                    <div className={"flex flex-col"}>
                      {setting?.map(
                        (
                          { enabled, notificationType, notificationTypeLabel },
                          settingIndex
                        ) => (
                          <div
                            key={notificationType}
                            className={
                              "select-none flex flex-row w-full justify-between items-center hover:bg-gray-100 border-0 rounded-lg p-1"
                            }
                          >
                            <span>{notificationTypeLabel}</span>
                            <Switch
                              size={"default"}
                              checkedChildren={
                                <span className={"text-xxs"}>ON&nbsp;</span>
                              }
                              unCheckedChildren={
                                <span className="text-xxs">OFF</span>
                              }
                              className={enabled ? "bg-success" : "bg-muted"}
                              checked={enabled}
                              onChange={(v) =>
                                toggleSubscription(
                                  notificationType,
                                  categoryIndex,
                                  settingIndex,
                                  v
                                )
                              }
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

type subscribeType = (
  type: NotificationSettingType,
  category: number,
  settingIndex: number
) => void;
type toggleSubscriptionType = (
  type: NotificationSettingType,
  category: number,
  settingIndex: number,
  enabled: boolean
) => void;
