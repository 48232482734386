import React, { FC, useEffect, useState } from "react";
import { Button, Checkbox, Form, message, Modal, Select } from "antd";
import {
  getAllPortfolioTransactions,
  linkTransactionsToPortfolio,
} from "../../../services/services";
import { ResponseType } from "../../../utils/uiTypes";
import {
  PortfolioTransactionListType,
  TransactionType,
} from "../../../utils/types";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useParams } from "react-router";

export const LinkTransactionModal: FC<LinkTransactionModalType> = ({
  onSuccess,
  transactions,
  visible,
  setVisible,
}: LinkTransactionModalType) => {
  const { portfolioId }: ParamsType = useParams();
  const [form] = Form.useForm();

  const [allTransactions, setAllTransactions] = useState<
    PortfolioTransactionListType[]
  >([]);
  const [loading, setLoading] = useState(false);

  const selectAllTransactions = (e: CheckboxChangeEvent): void => {
    form.setFieldsValue({
      selectedTransactions: e.target.checked
        ? allTransactions.map((tx) => tx.peTransactionId)
        : [],
    });
  };
  const onConfirm = (): void => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    linkTransactionsToPortfolio({
      segments: { portfolioId },
      body: JSON.stringify(formValues.selectedTransactions),
    })
      .then(() => {
        onSuccess();
        setLoading(false);
      })
      .catch((error: string) => {
        console.error(error);
        message.error("Something went wrong, transaction data not saved.", 3);
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllPortfolioTransactions({})
      .then(({ data }: ResponseType<PortfolioTransactionListType[]>) => {
        setAllTransactions(data);
      })
      .catch((err: string): void => {
        console.error(err);
        message.error(
          "Something went wrong, transaction data not retrieved.",
          3
        );
      });
  }, []);
  useEffect(() => {
    if (transactions)
      form.setFieldsValue({
        selectedTransactions: transactions?.map((tx) => tx.peTransactionId),
      });
  }, [transactions]);
  return (
    <Modal
      open={visible}
      width={750}
      className={"px-8"}
      onCancel={(): void => {
        setVisible(false);
        form.setFieldsValue({
          selectedTransactions: transactions?.map((tx) => tx.peTransactionId),
        });
      }}
      destroyOnClose={true}
      title={"Select Transaction(s)"}
      footer={[
        <Button
          disabled={loading}
          key={"cancel"}
          onClick={(): void => setVisible(false)}
          className={"border-primary text-primary"}
        >
          Cancel
        </Button>,
        <Button
          disabled={loading}
          loading={loading}
          key={"link"}
          onClick={(): void => onConfirm()}
          htmlType={"submit"}
          className={"bg-primary text-white border-0"}
        >
          Link Transaction
        </Button>,
      ]}
      centered={true}
    >
      <Form
        form={form}
        className={"mt-4"}
        name="form"
        layout={"vertical"}
        initialValues={{ transactions: [] }}
      >
        <Form.Item name={"selectedTransactions"}>
          <Select
            notFoundContent={"No Transactions Available"}
            getPopupContainer={(trigger): HTMLElement => trigger.parentElement}
            mode="multiple"
            placeholder="Select transactions(s)"
            showArrow={true}
            maxTagCount={10}
            showSearch={true}
            filterOption={(input, option): boolean =>
              option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option
              className={"cursor-pointer"}
              value={"none"}
              disabled={true}
            >
              <Checkbox
                className={"w-full"}
                style={{ lineHeight: "32px" }}
                onChange={selectAllTransactions}
              >
                Select All Transactions
              </Checkbox>
            </Select.Option>
            {allTransactions &&
              allTransactions
                .sort((a, b) =>
                  a.peTransactionTitle.localeCompare(b.peTransactionTitle)
                )
                .map((tx) => (
                  <Select.Option
                    key={tx.peTransactionId}
                    value={tx.peTransactionId}
                    title={tx.peTransactionTitle}
                  >
                    {tx.peTransactionTitle}
                  </Select.Option>
                ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

type LinkTransactionModalType = {
  onSuccess: () => void;
  transactions?: TransactionType[];
  visible: boolean;
  setVisible: (a: boolean) => void;
};

type ParamsType = {
  portfolioId: string;
};
