import React, { FC } from "react";
import { PortfolioKeyTermsList } from "../keyTerms/PortfolioKeyTermsList";
import { MenuRouteType } from "../../../../utils/uiTypes";
import { Switch } from "react-router-dom";
import { PortfolioTermsheet } from "../../../termsheet/terms/PortfolioTermsheet";
import { getRoutes } from "../../../../utils/navigation";

const MENU_OPTIONS: MenuRouteType[] = [
  {
    title: "Overview",
    key: "overview",
    exact: true,
    permission: true,
    Component: PortfolioKeyTermsList,
    path: ["/portfolio/:portfolioId/terms"],
  },
  {
    title: "Key Terms",
    key: "terms",
    exact: false,
    permission: true,
    Component: PortfolioTermsheet,
    path: "/portfolio/:portfolioId/terms/:sectionId",
  },
];

export const PortfolioKeyTerms: FC = () => {
  return <Switch>{getRoutes(MENU_OPTIONS)}</Switch>;
};
