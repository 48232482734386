import React, { FC } from "react";
import { WithTopNav } from "../components/layout/WithTopNav";
import { MenuRouteType } from "../utils/uiTypes";
import { useHistory, useParams } from "react-router";
import { usePageTitle } from "../customHooks/usePageTitle";
import { PrecedentSearch } from "../components/precedent/PrecedentSearch";
import { PrecedentDetails as Old } from "../components/precedent/v1/PrecedentDetails";
import { PrecedentDetails as New } from "../components/precedent/v2/PrecedentDetails";

const COMPANY_MENU_OPTIONS: MenuRouteType[] = [
  {
    title: "Search",
    Component: PrecedentSearch,
    exact: true,
    key: "",
    path: "/search",
    permission: true,
  },
  {
    title: "Details",
    Component: Old,
    exact: true,
    key: "team",
    path: "/search/detailsOld",
    permission: true,
  },
  {
    title: "Details",
    Component: New,
    exact: true,
    key: "team",
    path: "/search/details",
    permission: true,
  },
];

export const Search: FC = () => {
  usePageTitle("Search");
  const history = useHistory();
  const { searchMenu }: ParamsType = useParams();

  return (
    <>
      <WithTopNav
        gotoRoute={(link): void => history.push(`/search/${link.key}`)}
        selectedKeys={[searchMenu]}
        menu={COMPANY_MENU_OPTIONS}
        defaultValue={""}
        showMenu={false}
      />
    </>
  );
};

type ParamsType = {
  searchMenu: string;
};
