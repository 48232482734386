import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { getAllWip, getUserTransactionsCount } from "../services/services";
import { ResponseType } from "../utils/uiTypes";
import {
  Avatar,
  Button,
  Input,
  Popover,
  Segmented,
  Select,
  Table,
  message,
  Tag,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { tableColumnHeader } from "../utils/componentUtils";
import {
  PETransactionCreationType,
  priorityLabelType,
  PriorityType,
  SortDirection,
} from "../utils/enums";
import { IndustryType, PeTransactionFilter, UserType } from "../utils/types";
import { pluck, join, defaultTo, isEmpty, equals } from "ramda";
import { ACTION_BUTTON_CSS } from "../utils/cssConfigs";
import { EditWipDrawer } from "../components/wip/WipDrawer";
import useWindowDimensions from "../customHooks/useWindowDimensions";
import { CustomAvatar } from "../components/general/CustomAvatar";
import { EllipsisOutlined } from "@ant-design/icons";
import { convertDateToFormat } from "../utils/moment";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { isOfflineLender } from "../utils/transaction";

export const Wip: FC = function () {
  const { height: windowHeight } = useWindowDimensions();
  const [wips, setWips] = useState<wipType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();
  const history = useHistory();
  const { Search } = Input;
  const [filters, setFilters] = useState<PeTransactionFilter>({
    direction:
      (new URLSearchParams(search).get("direction") as SortDirection) ??
      SortDirection.DESC,
    sortColumn: new URLSearchParams(search).get("sort") ?? "creationDate",
    searchKey: new URLSearchParams(search).get("searchKey") ?? "",
    pageIndex: Number(defaultTo("0", new URLSearchParams(search).get("page"))),
    size: 10,
  });

  const columns: ColumnsType<wipType> = [
    {
      title: tableColumnHeader("Transaction Name"),
      key: "projectName",
      dataIndex: "projectName",
      fixed: "left",
      className:
        "group-hover:bg-blue-50 min-w-[180px] max-w-[300px] bg-white z-50",
      render: function name(text: string, transaction): ReactNode {
        return (
          <Link
            to={`/transactions/${transaction.peTransactionId}/dashboard`}
            style={{ color: "inherit" }}
            className={`font-medium text-sm cursor-pointer text-primary flex flex-col whitespace-normal items-start`}
          >
            <div className={"flex flex-row items-center gap-2"}>{text}</div>
          </Link>
        );
      },
    },
    {
      title: tableColumnHeader("Company Name"),
      key: "companyId",
      dataIndex: "targetCompany",
      className:
        "group-hover:bg-blue-50 min-w-[150px] empty:after:content-['Not_Specified'] empty:text-muted empty:text-xs",
    },
    {
      title: tableColumnHeader("Next Step"),
      key: "nextStep",
      dataIndex: "nextStep",
      className:
        "group-hover:bg-blue-50 min-w-[150px]  empty:after:content-['Not_Defined'] empty:text-muted empty:text-xs max-w-[300px] whitespace-normal",
    },

    {
      title: tableColumnHeader("Priority"),
      key: "priority",
      dataIndex: "priority",
      className:
        "group-hover:bg-blue-50 min-w-[150px] empty:after:content-['Not_Set'] empty:text-muted empty:text-xs",
      render: (record: PriorityType) => record && priorityLabelType[record],
    },
    {
      title: tableColumnHeader("Date Added"),
      key: "dateAdded",
      dataIndex: "dateAdded",
      className:
        "group-hover:bg-blue-50 min-w-[150px] empty:after:content-['Not_Specified'] empty:text-muted empty:text-xs",
      render: (dateAdded) =>
        dateAdded ? convertDateToFormat(dateAdded, "D.MMM.YY") : "",
    },
    // {
    //   title: tableColumnHeader("Industries"),
    //   key: "industries",
    //   dataIndex: "industries",
    //   className:
    //     "group-hover:bg-blue-50 min-w-[150px] empty:after:content-['Not_Specified'] empty:text-muted empty:text-xs",
    //   render: (text, { industries = [] }): ReactNode =>
    //     industries?.length > 0 &&
    //     join(", ", pluck("name", industries) as string[]),
    // },
    // {
    //   title: tableColumnHeader("Country"),
    //   key: "country",
    //   dataIndex: "country",
    //   className:
    //     "group-hover:bg-blue-50 min-w-[150px] empty:after:content-['Not_Specified'] empty:text-muted empty:text-xs",
    // },
    // {
    //   title: tableColumnHeader("SDP Amount"),
    //   key: "sdpAmount",
    //   dataIndex: "sdpAmount",
    //   className:
    //     "group-hover:bg-blue-50 min-w-[150px] empty:after:content-['Not_Projected'] empty:text-muted empty:text-xs",
    // },
    {
      title: tableColumnHeader("Introducer"),
      key: "introducer",
      dataIndex: "introducer",
      className:
        "group-hover:bg-blue-50 min-w-[150px] empty:after:content-['Not_Specified'] empty:text-muted empty:text-xs",
    },
    // {
    //   title: tableColumnHeader("Sponsor"),
    //   key: "sponsor",
    //   dataIndex: "sponsor",
    //   className: "group-hover:bg-blue-50 min-w-[150px]",
    // },
    // {
    //   title: tableColumnHeader("EBITDA"),
    //   key: "ebitda",
    //   dataIndex: "ebitda",
    //   className:
    //     "group-hover:bg-blue-50 min-w-[150px] empty:after:content-['Not_Projected'] empty:text-muted empty:text-xs",
    // },
    {
      title: tableColumnHeader("Deal Leader"),
      key: "dealLeader",
      dataIndex: "dealLeader",
      className:
        "group-hover:bg-blue-50 min-w-[150px] empty:after:content-['Not_Assigned'] empty:text-muted empty:text-xs",
      render: (dealLeader, record) => {
        const name = record.dealTeam.filter(
          (item: UserType) => item.userId === dealLeader
        )?.[0];
        return `${name?.firstName || ""} ${name?.lastName || ""}`.trim();
      },
    },
    {
      title: tableColumnHeader("Deal Team"),
      key: "dealTeam",
      dataIndex: "dealTeam",
      className: "group-hover:bg-blue-50 min-w-[150px]",
      render: (record) => {
        return (
          <>
            {record?.slice(0, 3).map((team: UserType) => (
              <React.Fragment key={team.userId}>
                <CustomAvatar
                  outlined={false}
                  data={team?.firstName?.[0] + team?.lastName?.[0]}
                  color={team?.firstName?.[0]}
                  size={"small"}
                  label={team?.firstName + " " + team.lastName}
                />
              </React.Fragment>
            ))}
            {record?.length > 3 && (
              <Popover
                placement={"rightTop"}
                title={`Sharing with ${record?.length} Institutions`}
                content={
                  <div className={"flex flex-col gap-y-2"}>
                    {record?.map((team: UserType) => (
                      <div
                        key={team?.userId}
                        className={"flex flex-row gap-x-2"}
                      >
                        <CustomAvatar
                          data={team?.firstName[0] + " " + team?.lastName[0]}
                          color={team?.firstName[0]}
                          size={"small"}
                        />
                        {team?.firstName + " " + team?.lastName}
                      </div>
                    ))}
                  </div>
                }
              >
                <Avatar size={"small"}>
                  <EllipsisOutlined />
                </Avatar>
              </Popover>
            )}
          </>
        );
      },
    },
    // {
    //   title: tableColumnHeader("Total Leverage"),
    //   dataIndex: "totalLeverage",
    //   key: "totalLeverage",
    //   className:
    //     "group-hover:bg-blue-50 min-w-[150px] empty:after:content-['Not_Projected'] empty:text-muted empty:text-xs",
    // },
    {
      title: tableColumnHeader("Comments"),
      key: "comments",
      dataIndex: "comments",
      className:
        "group-hover:bg-blue-50 min-w-[150px] empty:after:content-['None'] empty:text-muted empty:text-xs max-w-[300px] whitespace-normal",
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      align: "right",
      fixed: "right",
      className: "group-hover:bg-blue-50",
      render: function actions(_: string, wip, index): ReactNode {
        const handleButtonClick = (
          event: React.MouseEvent<HTMLButtonElement>
        ): void => {
          event.stopPropagation();
        };

        return (
          <div className="relative flex items-center gap-x-3">
            <Button
              type="text"
              icon={<i className="fas fa-ellipsis-h" />}
              className="absolute right-0 border-0 flex items-center px-2 text-gray-900 block opacity-50 group-hover:opacity-0"
              onClick={handleButtonClick}
            />

            <Button
              icon={<i className="fas fa-pencil-alt" />}
              className={`${ACTION_BUTTON_CSS} hover:text-primary opacity-0 group-hover:opacity-100`}
              onClick={(): void => {
                setTransactionId(wip?.peTransactionId);

                setOpen(true);
              }}
              type={"link"}
            />
          </div>
        );
      },
    },
  ];

  const fetchWips: wipTypeFilters = (updatedFilters) => {
    setLoading(() => true);

    const pageIndex =
      updatedFilters.sortColumn === filters.sortColumn ||
      updatedFilters.direction === filters.direction
        ? updatedFilters.pageIndex
        : 0;

    getAllWip({
      body: JSON.stringify(updatedFilters),
    })
      .then(({ data = [] }: ResponseType<wipType[]>) => {
        setFilters({
          ...updatedFilters,
          pageIndex,
        });

        history.push({
          pathname: "/wip",
          search: `?page=${pageIndex}&direction=${
            updatedFilters.direction
          }&sort=${updatedFilters.sortColumn}${
            !isEmpty(updatedFilters.searchKey)
              ? `&searchKey=${encodeURIComponent(
                  updatedFilters.searchKey ?? ""
                )}`
              : ""
          }`,
        });
        setWips(data);
        setLoading(() => false);
      })
      .catch((error: string) => {
        message.error(error ?? "Error Fetching Industries");
        setLoading(() => false);
      });
  };

  const fetchUserTransactionCount = (searchKey = "") => {
    getUserTransactionsCount({ params: { searchKey } })
      .then(({ data }: ResponseType<string>) => {
        setCount(Number(data));
      })
      .catch(console.error);
  };

  useEffect(() => {
    fetchWips(filters);
    fetchUserTransactionCount("");
  }, []);

  return (
    <div
      className={
        "relative max-h-full w-full h-screen bg-gray-100 flex flex-col pt-6 gap-3"
      }
    >
      <div
        className={"flex flex-wrap items-center w-full px-6 pt-0 pb-3 gap-3"}
      >
        <span className={"text-2xl font-medium mr-auto"}> WIP</span>
        <span>Sort By:</span>
        <Select
          disabled={loading}
          value={filters.sortColumn}
          onChange={(sortColumn) =>
            fetchWips({
              ...filters,
              sortColumn,
              direction:
                equals(sortColumn, filters.sortColumn) &&
                filters.direction === SortDirection.DESC
                  ? SortDirection.ASC
                  : SortDirection.DESC,
            })
          }
          options={[{ value: "creationDate", label: "Create Date" }]}
        />
        <Segmented
          disabled={loading}
          value={filters.direction}
          onChange={(direction) =>
            fetchWips({
              ...filters,
              sortColumn: filters.sortColumn,
              direction: direction as SortDirection,
            })
          }
          options={[
            { value: SortDirection.ASC, label: "Ascending" },
            { value: SortDirection.DESC, label: "Descending" },
          ]}
        />
      </div>
      <div className={`w-full h-full flex flex-col overflow-y-auto p-6 pt-0`}>
        <div className={"pb-4"}>
          <Table
            loading={loading}
            rowKey={"peTransactionId"}
            className={"transition duration-300 ease-out transform border"}
            columns={columns}
            scroll={{ x: true, y: windowHeight - 275 }}
            dataSource={wips}
            rowClassName={`group hover:bg-blue-50`}
            pagination={{
              position: ["bottomCenter"],
              responsive: true,
              total: count,
              showSizeChanger: false,
              showQuickJumper: true,
              showPrevNextJumpers: true,
              onChange: (index) =>
                fetchWips({ ...filters, pageIndex: index - 1 }),
              showTotal: (total, range) => (
                <>
                  Showing {range[0]} - {range[1]} from {total}
                </>
              ),
              current: Number(filters.pageIndex) + 1,
              hideOnSinglePage: true,
            }}
          />
        </div>
      </div>
      <EditWipDrawer
        open={open}
        onCloseDrawer={() => setOpen(false)}
        transactionId={transactionId}
        update={() => fetchWips(filters)}
      />
    </div>
  );
};

export type wipType = {
  id: string;
  companyId: string;
  peTransactionId: string;
  targetCompany: string;
  projectName: string;
  nextStep: string;
  priority: number | undefined;
  dateAdded: string | null;
  industries: IndustryType[];
  country: string;
  sdpAmount: number;
  introducer: string;
  sponsor: string;
  ebitda: number;
  dealLeader: string;
  dealTeam: UserType[];
  totalLeverage: number;
  comments: string;
};

type wipTypeFilters = (filters: PeTransactionFilter) => void;
