import React, { FC, useContext, useEffect, useState } from "react";
import { Button, message } from "antd";
import { TagType } from "../../../utils/types";
import { ResponseType, voidType } from "../../../utils/uiTypes";
import {
  deleteCompanyTag,
  getCompanyTags,
  updateDefaultTag,
  updateTagRank,
} from "../../../services/services";
import { UserContext } from "../../../context/UserContext";
import {
  doNothing,
  getObjectFromPropertyValue,
  removeItemFromArray,
  updateItemInArray,
} from "../../../utils/utils";
import { SortableTags } from "./SortableTags";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { TagModal } from "./TagModal";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import { equals, findIndex } from "ramda";
import { ConfirmAction } from "../../../utils/confirmationModals";

export const CompanyTags: FC = () => {
  const { user } = useContext(UserContext);
  const [tags, setTags] = useState<TagType[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalConfig, setModalConfig] = useState<ModalConfigType | null>(null);

  const fetchTags: voidType = () => {
    setLoading(true);
    getCompanyTags({})
      .then(({ data }: ResponseType<TagType[]>) => {
        setTags(
          data.sort((a, b) => (Number(a.rank) > Number(b.rank) ? 1 : -1))
        );
        setLoading(false);
      })
      .catch((error: string) => {
        console.error(error);
        setLoading(false);
      });
  };

  const deleteTag = (index: number, id: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      deleteCompanyTag({ segments: { id } })
        .then(() => {
          setTags(removeItemFromArray(index, tags));
          setModalConfig(null);
          resolve();
        })
        .catch((error: string) => {
          reject(error);
          ConfirmAction(
            error,
            doNothing,
            "",
            { cancelButtonProps: { hidden: true } },
            "Ok"
          );
        });
    });
  };

  const onSort = (updatedTags: TagType[], tagId: string): void => {
    if (!equals(updatedTags, tags)) {
      const oldRank = getObjectFromPropertyValue("id", tagId, tags)?.rank;
      const newRank = getObjectFromPropertyValue(
        "id",
        tagId,
        updatedTags
      )?.rank;
      updateTagRank({ segments: { tagId, oldRank, newRank } })
        .then(() => {
          setTags(updatedTags);
        })
        .catch(() => {
          message.error("Unable to update Rank");
          fetchTags();
        });
    }
  };

  const setDefaultTag = (tagId: string) => {
    updateDefaultTag({ segments: { tagId } })
      .then(() => {
        const currentDefaultIndex = findIndex((value) => value.default, tags);
        setTags((tags) =>
          tags.reduce<TagType[]>((previousValue, current, currentIndex) => {
            if (equals(tagId, current.id)) {
              return [...previousValue, { ...current, default: true }];
            } else if (equals(currentIndex, currentDefaultIndex)) {
              return [...previousValue, { ...current, default: false }];
            } else {
              return [...previousValue, current];
            }
          }, [])
        );
      })
      .catch(() => {
        message.error("Unable to set tag as default");
      });
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <div className={"relative p-6 h-full w-full flex flex-col"}>
      <div className={"mb-5 flex flex-row items-center justify-between"}>
        <span className="text-2xl font-medium">Company Tags</span>
        {user?.isAdmin && (
          <Button
            onClick={() => setModalConfig({})}
            className={PRIMARY_BUTTON_STYLE}
          >
            Create Tag
          </Button>
        )}
      </div>
      <DndProvider backend={HTML5Backend}>
        <SortableTags
          isAdmin={user?.isAdmin ?? false}
          onChangeDefault={setDefaultTag}
          loading={loading}
          tags={tags}
          onSort={onSort}
          onEdit={(tag) => setModalConfig({ tag })}
          onDelete={deleteTag}
        />
      </DndProvider>
      {user?.isAdmin && modalConfig && (
        <TagModal
          onSubmit={(tag) => {
            fetchTags();
            setModalConfig(null);
          }}
          tag={modalConfig?.tag}
          onClose={() => setModalConfig(null)}
        />
      )}
    </div>
  );
};

type ModalConfigType = {
  tag?: TagType;
};
