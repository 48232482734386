import { UserType } from "./types";

export const TRANSACTIONS_PAGE_URL = "/transactions";
export const ADD_COMPANY_PAGE_URL = "/company/add";
export const LOGIN_PAGE_URL = "/login";
export const VERIFICATIONS_PAGE_URL = "/verification";

export const loginRedirect: loginRedirectType = (user) => {
  const { hasTeam, sms2FaFlowCompleted, sms2FaEnabled } = user;

  if (sms2FaEnabled && !sms2FaFlowCompleted) return VERIFICATIONS_PAGE_URL;
  if (hasTeam) {
    return TRANSACTIONS_PAGE_URL;
  } else {
    return ADD_COMPANY_PAGE_URL;
  }
};

type loginRedirectType = (user: UserType) => string;
