import React, { FC, useState } from "react";
import {
  Alert,
  Button,
  DatePicker,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Tag,
} from "antd";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import {
  AllocationTableMappingType,
  PortfolioSectionTableVersionDTO,
} from "../../../utils/types";
import { findIndex, isEmpty, isNil, omit, propEq } from "ramda";
import { createCapitalStructureVersion } from "../../../services/services";
import { ResponseType } from "../../../utils/uiTypes";
import { stopPropagationOnEnter } from "../../../utils/keyboardUtils";
import moment from "moment";
import { PortfolioSectionEnumType } from "../../../utils/enums";
import {
  getModalContainerForFullScreen,
  getPopupContainerForSelect,
} from "../../../utils/container";

export const NewCapStructureVersionModal: FC<
  NewCapStructureVersionModalType
> = ({
  title,
  visible,
  portfolioId,
  sectionId,
  versions,
  dates,
  onSave,
  onCancel,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [copyFromVersion, setCopyFromVersion] = useState(false);
  const [capStructureVersion, setCapStructureVersion] = useState<
    Partial<PortfolioSectionTableVersionDTO>
  >({
    versionName: "",
  });

  const onClear = () => {
    setCopyFromVersion(false);
    setLoading(false);
    setCapStructureVersion({
      versionName: "",
    });
  };

  const onSubmit = () => {
    setLoading(true);
    setError(null);
    createCapitalStructureVersion({
      segments: {
        portfolioId,
        sectionId,
      },
      body: JSON.stringify({
        ...omit(["id"], capStructureVersion),
        sectionId: sectionId,
        versionDate: capStructureVersion?.versionDate
          ? moment(capStructureVersion.versionDate).valueOf()
          : undefined,
      }),
    })
      .then(({ data }: ResponseType<PortfolioSectionTableVersionDTO>) => {
        onClear();
        onSave(data);
        message.success("Version added successfully");
        setLoading(false);
      })
      .catch((e: string) => {
        setLoading(false);
        setError(e);
      });
  };
  return (
    <Modal
      getContainer={getModalContainerForFullScreen}
      title={title}
      open={visible}
      width={600}
      onCancel={() => {
        onClear();
        onCancel();
      }}
      destroyOnClose={true}
      footer={[
        <div className={"w-full text-right"} key={0}>
          <Button
            disabled={loading}
            onClick={() => {
              onClear();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              loading ||
              !capStructureVersion?.versionName?.trim() ||
              !capStructureVersion.versionDate?.trim()
            }
            loading={loading}
            className={PRIMARY_BUTTON_STYLE}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>,
      ]}
    >
      <div className={"flex flex-col gap-4"}>
        <div className={"grid grid-cols-2 gap-4"}>
          <div>
            <div className={"w-full mb-2"}>Name</div>
            <Input
              placeholder={"Enter version name"}
              value={capStructureVersion?.versionName}
              onChange={(e) =>
                setCapStructureVersion((v) => ({
                  ...v,
                  versionName: e.target.value,
                }))
              }
            />
          </div>
          <div>
            <div className={"w-full mb-2"}>Date</div>
            <DatePicker
              getPopupContainer={getPopupContainerForSelect}
              disabledDate={(current): boolean => {
                const match = dates.find((o) =>
                  moment(o)
                    .startOf("day")
                    .isSame(moment(current).startOf("day"))
                );
                return match != undefined;
              }}
              onChange={(v) => {
                setCapStructureVersion((k) => ({
                  ...k,
                  versionDate: v?.format().toString(),
                }));
              }}
              value={
                !isEmpty(capStructureVersion.versionDate) &&
                !isNil(capStructureVersion.versionDate)
                  ? moment(capStructureVersion.versionDate)
                  : null
              }
              className={`w-full`}
              format={"ll"}
              placeholder={"Select date"}
              onKeyDown={stopPropagationOnEnter}
            />
          </div>
        </div>
        <div>
          <div className={"w-full mb-2"}>Description</div>
          <Input
            placeholder={"Description"}
            value={capStructureVersion?.description}
            onChange={(e) =>
              setCapStructureVersion((v) => ({
                ...v,
                description: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <div>
            Would you like to copy from a pre-existing version of Capital
            Structure ?
          </div>
          <Radio.Group
            className={"mt-2"}
            value={copyFromVersion}
            onChange={(e) => {
              const value = e.target.value;
              setCopyFromVersion(value);
              if (!value) {
                setCapStructureVersion((v) => omit(["id", "copyVersionId"], v));
              }
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>

        {copyFromVersion && (
          <div>
            <div className={"w-full mb-2"}>Select Version</div>
            <Select
              placeholder={"Select Version"}
              getPopupContainer={getPopupContainerForSelect}
              className={"w-60"}
              onSelect={(v: any) => {
                const index = findIndex(propEq("id", v), versions);
                if (index >= 0) {
                  setCapStructureVersion((v) => ({
                    ...v,
                    id: versions[index]?.id,
                    copyVersionId: versions[index]?.id,
                  }));
                }
              }}
              suffixIcon={false}
              value={capStructureVersion.id}
            >
              {versions?.map(({ id, versionName, versionDate }) => (
                <Select.Option key={id} value={id}>
                  <div className={"flex flex-row justify-between"}>
                    <div>{versionName}</div>
                    {!isEmpty(versionDate) && !isNil(versionDate) && (
                      <Tag color={"blue"}>
                        {moment(versionDate).format("ll")}
                      </Tag>
                    )}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        {error && <Alert message={error} type={"error"} className={"mb-2"} />}
      </div>
    </Modal>
  );
};

type NewCapStructureVersionModalType = {
  title: string;
  visible: boolean;
  portfolioId: string;
  sectionId: string;
  versions: PortfolioSectionTableVersionDTO[];
  dates: string[];
  onSave: (value: PortfolioSectionTableVersionDTO) => void;
  onCancel: () => void;
};
