import React, { FC, useState } from "react";
import { Dropdown, Menu } from "antd";
import { convertDateToFormat } from "../../../utils/moment";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExpandOutlined,
} from "@ant-design/icons";
import EmptyBidForm from "../../../images/icons/EmptyBidForm.svg";
import { Link } from "react-router-dom";
import { TextOverFlowHandle } from "../../general/TextOverFlowHandle";
import { isEmptyOrNil } from "../../../utils/utils";

export const WebFormCard: FC<WebFormCardType> = function ({
  name = "",
  path = "/",
  selected = false,
  creator,
  createDate,
  disabled = false,
  showDropdown = true,
  onSelect,
  isAdmin = false,
  onEdit,
  onDelete,
}: WebFormCardType) {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const menu = (
    <Menu>
      {isAdmin && onEdit && (
        <Menu.Item
          key={1}
          onClick={() => {
            setDropdownVisible(false);
            onEdit();
          }}
          icon={<EditOutlined />}
        >
          Rename
        </Menu.Item>
      )}
      {path && (
        <Menu.Item
          key={2}
          icon={<ExpandOutlined />}
          onClick={() => {
            setDropdownVisible(false);
          }}
        >
          <Link to={path} target="_blank">
            Open in new tab
          </Link>
        </Menu.Item>
      )}
      {isAdmin && onDelete && (
        <Menu.Item
          danger
          key={1}
          onClick={() => {
            setDropdownVisible(false);
            onDelete();
          }}
          icon={<DeleteOutlined />}
        >
          Delete
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div>
      <div
        className={`group relative h-36 w-48 bg-gray-50 border m-1 ${
          (selected || dropdownVisible) && !disabled
            ? "border-primary"
            : "border-gray-300"
        } ${
          disabled ? "bg-opacity-50 border-opacity-50" : "hover:border-primary"
        }`}
      >
        <div
          className={`h-24 ${
            disabled ? "pointer-events-none" : "cursor-pointer"
          }`}
          role={"button"}
          onClick={onSelect}
          aria-disabled={disabled}
        >
          <img
            className={"h-12 w-12 mx-auto mt-2"}
            src={EmptyBidForm}
            alt={"Termsheet"}
          />
          <div
            className={`${
              (dropdownVisible || selected) && "text-primary"
            } text-center text-xs font-medium pt-px px-1 ${
              disabled ? "text-muted" : "group-hover:text-primary"
            }`}
          >
            <TextOverFlowHandle text={name} />
          </div>
        </div>
        <div
          className={
            "h-12 absolute bottom-0 px-2 bg-white h-12 w-full border-t border-gray-100"
          }
        >
          <div className={"flex justify-between"}>
            <div
              role={"button"}
              onClick={onSelect}
              aria-disabled={disabled}
              className={`w-44 ${
                disabled ? "pointer-events-none" : "cursor-pointer"
              }`}
            >
              <div
                className={`pl-2 pt-2 text-xs ${
                  (dropdownVisible || selected) && "text-primary"
                } ${disabled ? "text-muted" : "group-hover:text-primary"}`}
              >
                {creator}
              </div>
              <div
                className={`pl-2 text-xxs ${
                  (dropdownVisible || selected) && "text-primary"
                } ${disabled ? "text-muted" : "group-hover:text-primary"}`}
              >
                <span>
                  {isEmptyOrNil(createDate)
                    ? "--"
                    : convertDateToFormat(createDate, "ll", false)}
                </span>
              </div>
            </div>
            {showDropdown && !disabled && (
              <div className={"float-right pt-4"}>
                <div
                  className={`inline-flex justify-center items-center rounded-full hover:bg-gray-100 h-6 w-6 ${
                    dropdownVisible && "bg-gray-100"
                  }`}
                >
                  <Dropdown
                    onOpenChange={setDropdownVisible}
                    trigger={["click"]}
                    overlay={menu}
                    placement={"bottomRight"}
                    overlayClassName={"shadow-sm"}
                  >
                    <EllipsisOutlined
                      rotate={90}
                      className={`${
                        dropdownVisible && "text-primary"
                      } group-hover:text-primary`}
                    />
                  </Dropdown>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

type WebFormCardType = {
  name: string;
  path?: string;
  selected?: boolean;
  creator?: string;
  createDate: string;
  disabled?: boolean;
  showDropdown?: boolean;
  onSelect: () => void;
  onEdit?: () => void;
  isAdmin?: boolean;
  onDelete?: () => void;
};
