import React, { FC, useEffect, useState } from "react";
import {
  AllocationKeyType,
  CountryType,
  DebtType,
  IndustryType,
  KeyValueType,
  PeTransactionFilter,
  TeamMemberType,
} from "../../utils/types";
import { Button, message, Segmented, Select, Tag } from "antd";
import { FileProtectOutlined, UndoOutlined } from "@ant-design/icons";
import { equals, omit, pick } from "ramda";
import {
  DEFAULT_TRANSACTION_FILTERS,
  getSelectedFilters,
} from "../../utils/transaction";
import { TransactionFiltersModal } from "./TransactionFilterModal";
import { ResponseType, voidType } from "../../utils/uiTypes";
import {
  getAllTeamMembers,
  getCountries,
  getDebtType,
  getIndustries,
} from "../../services/services";
import { isEmptyOrNil, removeItemFromArray } from "../../utils/utils";
import {
  CompanyTag,
  DateTag,
  IndustryTag,
  StatusTag,
  TextTag,
} from "../precedent/PrecedentSearchFilters";
import Search from "antd/es/input/Search";
import { InvitationStatusType, SortDirection } from "../../utils/enums";

const OMIT_COLUMNS = ["size", "pageIndex", "direction", "sortColumn"];

export const CreatedByTag: FC<CreatedByTagType> = ({ value }) => {
  return (
    <Tag
      color={(value === "Yes" && "success") || "warning"}
      className={"select-none mt-2"}
      icon={<FileProtectOutlined />}
    >
      {value}
    </Tag>
  );
};

export const TransactionFilters: FC<PrecedentSearchFiltersType> = ({
  selectedFilters,
  loading = false,
  onChange,
  transactionTypes,
  investmentTypes,
}) => {
  const [debtTypes, setDebtTypes] = useState<DebtType[]>([]); // kpi
  const [industries, setIndustries] = useState<IndustryType[]>([]); // kpi
  const [users, setUsers] = useState<TeamMemberType[]>([]);
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [searchKey, setSearchKey] = useState(selectedFilters?.searchKey || "");

  const parseFilters = (values: PeTransactionFilter): void => {
    onChange({
      ...values,
      fromTimestamp: values?.fromTimestamp ?? null,
      toTimestamp: values?.toTimestamp ?? null,
    });
  };

  const fetchCountries: voidType = () => {
    getCountries({})
      .then(({ data = [] }: ResponseType<CountryType[]>) => {
        setCountries(data);
      })
      .catch(console.error);
  };

  const fetchDebtType: voidType = () => {
    getDebtType({})
      .then(({ data = [] }: ResponseType<DebtType[]>) => {
        setDebtTypes(data);
      })
      .catch(console.error);
  };

  const fetchIndustries: voidType = () => {
    getIndustries({})
      .then(({ data = [] }: ResponseType<IndustryType[]>) => {
        setIndustries(data);
      })
      .catch((error: string) => {
        message.error(error ?? "Error Fetching Industries");
      });
  };

  const getTeamMembers: voidType = () => {
    getAllTeamMembers({})
      .then(({ data = [] }: ResponseType<TeamMemberType[]>) => {
        setUsers(
          data.filter(
            (value) =>
              value.invitationStatus === InvitationStatusType.ACCEPTED ||
              value.invitationStatus === InvitationStatusType.REMOVED
          )
        );
      })
      .catch((e: string) => {
        console.error(e);
      });
  };

  useEffect(() => {
    parseFilters(getSelectedFilters());
    fetchDebtType();
    fetchIndustries();
    getTeamMembers();
    fetchCountries();
  }, []);

  return (
    <div className={"flex flex-col items-start w-full"}>
      <div className={"flex flex-row items-center w-full"}>
        <div
          className={"flex flex-wrap items-center w-full gap-1 md:mr-auto mr-0"}
        >
          <Search
            loading={loading}
            disabled={loading}
            className={"max-w-md"}
            placeholder={"Search Transaction"}
            value={searchKey}
            onSearch={(searchKey) => {
              onChange({ ...selectedFilters, searchKey, pageIndex: 0 });
              setSearchKey(searchKey);
            }}
            onChange={(input) => setSearchKey(input.target.value)}
            allowClear={true}
          />
          <TransactionFiltersModal
            selectedFilters={selectedFilters}
            debtType={debtTypes}
            transactionTypes={transactionTypes}
            industries={industries}
            investmentType={investmentTypes}
            users={users}
            loading={loading}
            onChange={parseFilters}
            countries={countries}
          />
          {!equals(
            omit(OMIT_COLUMNS, selectedFilters),
            omit(OMIT_COLUMNS, DEFAULT_TRANSACTION_FILTERS)
          ) && (
            <Button
              loading={loading}
              disabled={loading}
              type={"dashed"}
              danger
              className={`select-none cursor-pointer !font-normal`}
              onClick={(): void => {
                onChange({
                  ...DEFAULT_TRANSACTION_FILTERS,
                  ...pick(OMIT_COLUMNS, selectedFilters),
                });
                setSearchKey("");
              }}
              icon={<UndoOutlined />}
            >
              Reset
            </Button>
          )}
        </div>
        <div className={"flex items-center gap-3"}>
          <div className={"whitespace-nowrap"}>Sort By:</div>
          <Select
            loading={loading}
            disabled={loading}
            value={selectedFilters.sortColumn}
            onChange={(sortColumn) =>
              onChange({
                ...selectedFilters,
                sortColumn,
                direction:
                  equals(sortColumn, selectedFilters.sortColumn) &&
                  selectedFilters.direction === SortDirection.DESC
                    ? SortDirection.ASC
                    : SortDirection.DESC,
              })
            }
            options={[
              { value: "creationDate", label: "Create Date" },
              { value: "lastActivity", label: "Last Activity" },
            ]}
          />
          <Segmented
            disabled={loading}
            value={selectedFilters.direction}
            onChange={(direction) =>
              onChange({
                ...selectedFilters,
                sortColumn: selectedFilters.sortColumn,
                direction: direction as SortDirection,
              })
            }
            options={[
              { value: SortDirection.ASC, label: "Ascending" },
              { value: SortDirection.DESC, label: "Descending" },
            ]}
          />
        </div>
      </div>

      <div className={"hidden"}>
        {!isEmptyOrNil(selectedFilters?.searchKey) && (
          <TextTag value={selectedFilters?.searchKey} />
        )}
        {(selectedFilters.createdByMe && (
          <CreatedByTag
            value={`Created by me : ${
              selectedFilters?.createdByMe?.toString() === "true" ? "Yes" : "No"
            }`}
          />
        )) ||
          ""}
        {debtTypes
          .filter((item) => selectedFilters.debtType?.includes(item.id))
          .slice(0, 5)
          .map((value, index) => (
            <CompanyTag
              key={value.id}
              name={value.name}
              id={value.id}
              onClose={(): void =>
                onChange({
                  ...selectedFilters,
                  debtType: removeItemFromArray(
                    index,
                    selectedFilters.debtType || []
                  ),
                })
              }
            />
          ))}
        {selectedFilters.status?.slice(0, 5).map((value, index) => (
          <StatusTag
            key={value}
            name={value}
            id={value}
            onClose={(): void =>
              onChange({
                ...selectedFilters,
                status: removeItemFromArray(
                  index,
                  selectedFilters.status || []
                ),
              })
            }
          />
        ))}

        {industries
          .filter((item) => selectedFilters.industries?.includes(item.id))
          .slice(0, 5)
          .map((value, index) => (
            <IndustryTag
              key={value.id}
              name={value.name}
              id={value.id}
              onClose={(): void =>
                onChange({
                  ...selectedFilters,
                  industries: removeItemFromArray(
                    index,
                    selectedFilters.industries || []
                  ),
                })
              }
            />
          ))}
        {countries
          .filter((item) => selectedFilters.region?.includes(item.id))
          .slice(0, 5)
          .map((value, index) => (
            <CompanyTag
              key={value.id}
              name={value.name}
              id={value.id}
              onClose={(): void =>
                onChange({
                  ...selectedFilters,
                  region: removeItemFromArray(
                    index,
                    selectedFilters.region || []
                  ),
                })
              }
            />
          ))}

        {investmentTypes
          .filter((item) => selectedFilters.investmentType?.includes(item.id))
          .slice(0, 5)
          .map((value, index) => (
            <CompanyTag
              key={value.id}
              name={value.keyName}
              id={value.id}
              onClose={(): void =>
                onChange({
                  ...selectedFilters,
                  investmentType: removeItemFromArray(
                    index,
                    selectedFilters.investmentType || []
                  ),
                })
              }
            />
          ))}

        {transactionTypes
          .filter((item) => selectedFilters.transactionType?.includes(item.id))
          .slice(0, 5)
          .map((value, index) => (
            <IndustryTag
              key={value.id}
              name={value.keyName}
              id={value.id}
              onClose={(): void =>
                onChange({
                  ...selectedFilters,
                  transactionType: removeItemFromArray(
                    index,
                    selectedFilters.transactionType || []
                  ),
                })
              }
            />
          ))}

        {(selectedFilters.fromTimestamp && (
          <DateTag
            onClose={(): void =>
              onChange({ ...selectedFilters, fromTimestamp: null })
            }
            date={selectedFilters.fromTimestamp.toString()}
          />
        )) ||
          ""}

        {(selectedFilters.toTimestamp && (
          <DateTag
            onClose={(): void =>
              onChange({ ...selectedFilters, toTimestamp: null })
            }
            date={selectedFilters.toTimestamp.toString()}
            startDate={false}
          />
        )) ||
          ""}
      </div>
    </div>
  );
};

type PrecedentSearchFiltersType = {
  loading?: boolean;
  selectedFilters: PeTransactionFilter;
  onChange: (selectedFilters: PeTransactionFilter) => void;
  transactionTypes: AllocationKeyType[];
  investmentTypes: AllocationKeyType[];
};

type IndustryTagType = {
  name: string;
  id: string;
  onClose?: () => void;
};

type CompanyTagType = {
  name: string;
  id: string;
  onClose?: () => void;
};

type DateTagType = {
  date: string;
  onClose?: () => void;
  startDate?: boolean;
};

type StatusTagType = {
  name: string;
  onClose?: () => void;
  id: string;
};
type CreatedByTagType = {
  value: string;
};

type ResponseTagType = {
  value: boolean | null;
};

export type CategorisedFilterType = {
  industry: Record<string, KeyValueType>;
  company: Record<string, KeyValueType>;
  version: Record<string, KeyValueType>;
};
