import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Avatar,
  Button,
  Empty,
  Input,
  message,
  Popover,
  Segmented,
  Table,
  Tooltip,
} from "antd";
import { FileElementType, NoteDataType } from "../../utils/types";
import { findIndex, propEq } from "ramda";
import { ColumnsType } from "antd/es/table";
import {
  ACTION_BUTTON_CSS,
  PRIMARY_BUTTON_STYLE,
} from "../../utils/cssConfigs";
import {
  columnSort,
  fetchWithIdAndParamsType,
  getModule,
  isAdmin,
  ParamsType,
} from "../../utils/relationship";
import { removeItemFromArray } from "../../utils/utils";
import { tableColumnHeader } from "../../utils/componentUtils";
import { UserContext } from "../../context/UserContext";
import {
  createInternalNote,
  deleteInternalNote,
  getCrmNotes,
  updateInternalNote,
  downloadNote,
} from "../../services/services";
import { FileResponseType, ResponseType } from "../../utils/uiTypes";
import { useParams } from "react-router";
import { RelationshipCard } from "./RelationshipCard";
import { useLocation } from "react-router-dom";
import { CustomButton } from "../general/CustomButton";
import { ConfirmDelete, ConfirmDownload } from "../../utils/confirmationModals";
import { ElementType } from "../../utils/enums";
import moment from "moment";
import { EllipsisOutlined, DownloadOutlined } from "@ant-design/icons";
import { CustomAvatar } from "../general/CustomAvatar";
import AddNotesModal from "../modals/AddNotesModal";

export const Notes: FC = () => {
  const { id } = useParams<ParamsType>();
  const { search } = useLocation();
  const { user } = useContext(UserContext);

  const [searchValue, setSearchValue] = useState<string>("");
  const [notes, setNotes] = useState<NoteDataType[]>([]);
  const [internalNotesFilter, setInternalNotesFilter] =
    useState<boolean>(false);
  const [notesModalVisible, setNotesModalVisible] = useState<boolean>(false);
  const [selectedNote, setSelectedNote] = useState<NoteDataType | undefined>();
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileUploading, setFileUploading] = useState(false);

  const updateNotes: updateNotesType = (note) => {
    setFileUploading(true);
    selectedNote?.id ? onUpdate(note) : onCreate(note);
  };
  const downloadNotes = (id: string) => {
    downloadNote({ segments: { id } })
      .then(({ url, filename }: FileResponseType) => {
        message.success({
          key: "download",
          content: `File ready to download`,
        });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        console.error(error);
        message.error({
          content: "Unable to download file",
          key: "download",
        });
      });
  };

  const columns: ColumnsType<NoteDataType> = [
    {
      title: tableColumnHeader("CREATION DATE"),
      key: "interactionDate",
      dataIndex: "interactionDate",
      render: (item, record) => {
        return record?.createDate ? (
          <>
            {moment(new Date(record?.createDate)).format("MMM DD YYYY")}
            <br />
            <span className="text-gray-400 text-xs">
              by {record?.createdByUserDTO?.firstName}&nbsp;
              {record?.createdByUserDTO?.lastName}
            </span>
          </>
        ) : (
          ""
        );
      },
      sorter: (a, b) => columnSort(a.createDate, b.createDate, "DATE"),
      className:
        "group-hover:bg-blue-50 min-w-[180px] max-w-[260px] overflow-hidden",
    },
    {
      title: tableColumnHeader("TYPE"),
      key: "interactionType",
      render: (record) => {
        return (
          <>
            {record.elementType === ElementType.CRM ? "Internal" : "Deal"}
            <br />
            <span className="text-gray-400 text-bold text-xs">
              {record.elementName}
            </span>
          </>
        );
      },
      sorter: (a, b) => columnSort(a.elementType, b.elementType, "TEXT"),
      className:
        "group-hover:bg-blue-50 min-w-[180px] max-w-[260px] overflow-hidden",
    },
    {
      title: tableColumnHeader("Notes"),
      key: "note",
      render: (record) => record.note,
      sorter: (a, b) => columnSort(a.elementType, b.elementType, "TEXT"),
      className:
        "group-hover:bg-blue-50 min-w-[180px] max-w-[260px] overflow-hidden",
    },
    {
      title: tableColumnHeader("INTERACTION DATE"),
      key: "interactionDate",
      dataIndex: "interactionDate",
      render: (item, record) => {
        return +record?.notesExtension?.interactionDate
          ? moment(new Date(+record?.notesExtension?.interactionDate)).format(
              "MMM DD YYYY"
            )
          : "";
      },
      sorter: (a, b) => columnSort(a.createDate, b.createDate, "DATE"),
      className:
        "group-hover:bg-blue-50 min-w-[180px] max-w-[260px] overflow-hidden",
    },
    {
      title: tableColumnHeader("INTERACTION TYPE"),
      key: "interactionType",
      render: (record) => record.notesExtension?.interactionType,
      sorter: (a, b) => columnSort(a.elementType, b.elementType, "TEXT"),
      className:
        "group-hover:bg-blue-50 min-w-[180px] max-w-[260px] overflow-hidden",
    },
    {
      title: tableColumnHeader("Attendees - Internal"),
      key: "attendeesInternal",
      render: (record) => {
        return (
          <div
            className={"flex flex-row items-center min-w-[20px]"}
            onClick={(e): void => e.stopPropagation()}
          >
            {record?.notesExtension?.interactionAttendeesInternal
              ?.slice(0, 4)
              .map((val: externalAttendeesType) => (
                <CustomAvatar
                  key={id}
                  data={val.firstName[0] + val.lastName[0]}
                  size={"small"}
                  label={val.firstName + " " + val.lastName}
                  color={val.firstName[0]}
                />
              ))}
            {record?.notesExtension?.interactionAttendeesInternal.length >
              0 && (
              <Popover
                title={` ${record?.notesExtension?.interactionAttendeesInternal.length} Attendees`}
                placement={"rightTop"}
                content={
                  <div
                    className={"flex flex-col gap-y-2 max-h-60 overflow-y-auto"}
                  >
                    {record?.notesExtension?.interactionAttendeesInternal.map(
                      (val: externalAttendeesType) => (
                        <div
                          key={id}
                          className={"flex flex-row gap-x-2 items-center"}
                        >
                          <CustomAvatar
                            key={id}
                            data={val.firstName[0] + val.lastName[0]}
                            color={val.firstName[0]}
                            size={"small"}
                          />
                          {val.firstName + " " + val.lastName}
                        </div>
                      )
                    )}
                  </div>
                }
              >
                <Avatar className={"ml-1"} size={"small"}>
                  <EllipsisOutlined />
                </Avatar>
              </Popover>
            )}
          </div>
        );
      },

      sorter: (a, b) => columnSort(a.elementType, b.elementType, "TEXT"),
      className:
        "group-hover:bg-blue-50 min-w-[180px] max-w-[260px] overflow-hidden",
    },
    {
      title: tableColumnHeader("Attendees - External"),
      key: "externalUsers",
      render: (record) => {
        return (
          <div
            className={"flex flex-row items-center min-w-[20px]"}
            onClick={(e): void => e.stopPropagation()}
          >
            {record?.notesExtension?.externalUsers}
            {/* {record?.notesExtension?.interactionAttendeesExternal
              ?.slice(0, 4)
              .map((val: externalAttendeesType) => (
                <CustomAvatar
                  key={id}
                  data={val.firstName[0] + val.lastName[0]}
                  size={"small"}
                  label={val.firstName + " " + val.lastName}
                  color={val.firstName[0]}
                />
              ))} */}
            {record?.notesExtension?.interactionAttendeesExternal.length >
              0 && (
              <Popover
                title={` ${record?.notesExtension?.interactionAttendeesExternal.length} Attendees`}
                placement={"rightTop"}
                content={
                  <div
                    className={"flex flex-col gap-y-2 max-h-60 overflow-y-auto"}
                  >
                    {record?.notesExtension?.interactionAttendeesExternal.map(
                      (val: externalAttendeesType) => (
                        <div
                          key={id}
                          className={"flex flex-row gap-x-2 items-center"}
                        >
                          <CustomAvatar
                            key={id}
                            data={val.firstName[0] + val.lastName[0]}
                            color={val.firstName[0]}
                            size={"small"}
                          />
                          {val.firstName + " " + val.lastName}
                        </div>
                      )
                    )}
                  </div>
                }
              >
                <Avatar className={"ml-1"} size={"small"}>
                  <EllipsisOutlined />
                </Avatar>
              </Popover>
            )}
          </div>
        );
      },
      sorter: (a, b) => columnSort(a.elementType, b.elementType, "TEXT"),
      className:
        "group-hover:bg-blue-50 min-w-[180px] max-w-[260px] overflow-hidden",
    },
    {
      title: tableColumnHeader("Purpose"),
      key: "purpose",
      render: (record) => record?.notesExtension?.purpose,
      sorter: (a, b) => columnSort(a.elementType, b.elementType, "TEXT"),
      className:
        "group-hover:bg-blue-50 min-w-[180px] max-w-[260px] overflow-hidden",
    },
    {
      title: tableColumnHeader("Deal / Portfolio Name"),
      key: "dealName",
      render: (record) => record?.notesExtension?.dealName,
      sorter: (a, b) => columnSort(a.elementType, b.elementType, "TEXT"),
      className:
        "group-hover:bg-blue-50 min-w-[180px] max-w-[260px] overflow-hidden",
    },
    {
      title: tableColumnHeader("Attachments"),
      key: "noteType",
      sorter: (a, b) => columnSort(a.elementType, b.elementType, "TEXT"),
      className:
        "group-hover:bg-blue-50 min-w-[180px] max-w-[260px] overflow-hidden",
      render: (record) => {
        return record?.fileElements?.map(
          (item: {
            elementId: string;
            name: string;
            fileDTO: FileElementType;
          }) => (
            <span key={item.elementId}>
              <span className="file-name whitespace-nowrap overflow-hidden text-ellipsis block w-[16ch] hover:overflow-visible">
                {item.fileDTO?.fileName}{" "}
              </span>
              <span className="text-xs text-gray-500">
                ({(+item.fileDTO.byteSize / (1024 * 1024)).toFixed(2)} MB)
              </span>
              <span onClick={() => downloadNotes(item.fileDTO.fileId)}>
                &nbsp; &nbsp;
                <Tooltip title={"Click here to download notes"}>
                  <DownloadOutlined />
                </Tooltip>
              </span>
              <br />
            </span>
          )
        );
      },
    },
    {
      title: tableColumnHeader("Action"),
      dataIndex: "operation",
      key: "operation",
      align: "right" as const,
      width: "100px",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: function actions(_: string, record): ReactNode {
        return (
          <span className={"flex flex-row items-center justify-end gap-x-3"}>
            <Button
              type={"text"}
              icon={<i className="fas fa-ellipsis-h" />}
              className={
                "absolute ml-auto border-0 flex items-center px-2 text-gray-900 hidden md:block opacity-50 group-hover:opacity-0"
              }
            />
            {(record.elementType === ElementType.CRM ||
              record.elementType === ElementType.SPONSOR_COVERAGE) &&
            isAdmin(user) ? (
              <>
                <CustomButton
                  label={"Delete"}
                  type={"default"}
                  className={`${ACTION_BUTTON_CSS} hover:text-red-500 opacity-0 group-hover:opacity-100`}
                  onClick={(): void => {
                    ConfirmDelete(
                      `Are you sure you want to delete this note?`,
                      () => onDelete(record)
                    );
                  }}
                >
                  <i className="fas fa-trash-alt" />
                </CustomButton>
                <CustomButton
                  label={"Edit"}
                  type={"default"}
                  onClick={(): void => {
                    setSelectedNote(record);
                    setNotesModalVisible(true);
                  }}
                  className={`${ACTION_BUTTON_CSS} hover:text-blue-500 opacity-0 group-hover:opacity-100`}
                >
                  <i className="fas fa-pencil-alt" />
                </CustomButton>
              </>
            ) : (
              <CustomButton
                label={"View"}
                type={"default"}
                onClick={(): void => {
                  setSelectedNote(record);
                  setNotesModalVisible(true);
                }}
                className={`${ACTION_BUTTON_CSS} hover:text-blue-500 opacity-0 group-hover:opacity-100`}
              >
                <i className="fa fa-eye" />
              </CustomButton>
            )}
          </span>
        );
      },
    },
  ];

  const onDelete: internalNotesApiType = (note) => {
    setLoading(true);
    deleteInternalNote({
      segments: { crmId: id, id: note.id },
      params: { crmElementType: getModule(user) ?? "" },
    })
      .then(() => {
        setLoading(false);
        setNotes(
          removeItemFromArray(findIndex(propEq("id", note.id), notes), notes)
        );
      })
      .catch((error: string) => {
        setLoading(false);
        message.error(error);
      });
  };

  const onMapNoteData = (note: Partial<NoteDataType>, isUpdate = false) => {
    const data = {
      ...(isUpdate && { id: selectedNote?.id, elementId: id }),
      note: note.note,
      elementType: ElementType.CRM,
      fileElements: note?.fileElements,
      notesExtension: {
        dealName: note.dealName || note.portfolio,
        interactionAttendeesExternal:
          note?.notesExtension?.interactionAttendeesExternal?.map((item) => ({
            id: item,
          })),
        interactionAttendeesInternal:
          note?.notesExtension?.interactionAttendeesInternal?.map((item) => ({
            userId: item,
          })),
        interactionDate: note?.notesExtension?.interactionDate?.valueOf(),
        interactionType: note?.notesExtension?.interactionType,
        purpose: note?.notesExtension?.purpose,
        externalUsers: note?.externalUsers,
      },
    };
    return data;
  };

  const onCreate: internalNotesApiType = (note) => {
    const data = onMapNoteData(note);
    setLoading(true);
    createInternalNote({
      segments: { id: id },
      params: { crmElementType: getModule(user) ?? "" },
      body: JSON.stringify(data),
    })
      .then(({ data }: ResponseType<NoteDataType>) => {
        setSelectedNote(undefined);
        setNotesModalVisible(false);
        setFileUploading(false);
        setLoading(false);
        if (data) setNotes((prev) => [data, ...prev]);
      })
      .catch((error: string) => {
        message.error(error);
        setLoading(false);
        setFileUploading(false);
      });
  };

  const onUpdate: internalNotesApiType = (note) => {
    const data = onMapNoteData(note, true);
    setLoading(true);
    updateInternalNote({
      segments: { id: id },
      params: { crmElementType: getModule(user) ?? "" },
      body: JSON.stringify(data),
    })
      .then(({ data }: ResponseType<NoteDataType>) => {
        setSelectedNote(undefined);
        setNotesModalVisible(false);
        setFileUploading(false);
        setLoading(false);
        if (data)
          setNotes(() => [
            data,
            ...notes.filter((item) => item.id !== data.id),
          ]);
      })
      .catch((error: string) => {
        message.error(error);
        setLoading(false);
        setFileUploading(false);
      });
  };

  const fetchNotes: fetchWithIdAndParamsType = (id, params) => {
    setLoading(true);
    getCrmNotes({ params, segments: { id: id } })
      .then(({ data = [] }: ResponseType<NoteDataType[]>) => {
        setNotes(data);
        setLoading(false);
      })
      .catch((err: string): void => {
        message.error(err ?? "Something went wrong.", 3);
        setLoading(false);
      });
  };

  const filterNotes = useCallback(
    (notes: NoteDataType[], searchValue: string) => {
      if (!searchValue) return notes;
      return notes.filter((note) =>
        Object.values(note).some((value) =>
          value?.toString().toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    },
    [notes, searchValue]
  );
  useEffect(() => {
    fetchNotes(id, search);
  }, [id, search]);
  const filteredNotes = filterNotes(notes, searchValue);

  return (
    <RelationshipCard
      label={"Notes"}
      actions={
        <div className={"flex flex-row items-center gap-x-2"}>
          <Segmented
            value={internalNotesFilter ? 1 : 0}
            onChange={(val) => setInternalNotesFilter(!!val)}
            options={[
              { label: "Show All", value: 0 },
              { label: "Internal", value: 1 },
            ]}
          />
          <Input.Search
            placeholder="Search by text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {isAdmin(user) && (
            <Button
              className={PRIMARY_BUTTON_STYLE}
              onClick={() => setNotesModalVisible(true)}
            >
              + Add Note
            </Button>
          )}
        </div>
      }
    >
      <div className={`relative h-full overflow-y-auto flex border-t`}>
        <Table
          loading={loading}
          locale={{
            emptyText: (
              <Empty
                image={
                  <i className="fa-regular fa-note-sticky text-8xl text-gray-300" />
                }
                className={"text-gray-400"}
                description={"No Notes"}
              />
            ),
          }}
          size="small"
          className={`w-full transition duration-300 ease-out transform`}
          dataSource={
            internalNotesFilter
              ? filteredNotes.filter(
                  (item) => item?.elementType !== ElementType.PETRANSACTION
                )
              : filteredNotes
          }
          columns={columns}
          pagination={false}
          sticky={true}
          scroll={{ x: true, y: window.innerHeight - 300 }}
          rowClassName={`group`}
          rowKey={({ id }) => id}
        />
      </div>
      {notesModalVisible && (
        <AddNotesModal
          notesModalVisible={notesModalVisible}
          setNotesModalVisible={(status) => {
            if (!status) setSelectedNote(undefined);
            setNotesModalVisible(status);
          }}
          userModalvisible={userModalVisible}
          setUserModalVisible={setUserModalVisible}
          updateNotes={updateNotes}
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
          fileUploading={fileUploading}
          setFileUploading={setFileUploading}
        />
      )}
    </RelationshipCard>
  );
};

export type updateNotesType = (note: Partial<NoteDataType>) => void;
type internalNotesApiType = (note: Partial<NoteDataType>) => void;
export type externalAttendeesType = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: any;
  title: string;
  userId: string;
};
