import React, { FC, memo, MouseEventHandler, useMemo } from "react";
import RedLineContent from "../../../termsheet/common/RedLineContent";
import { heatMapComparison } from "../../../../utils/termsheet";
import { RESIZE_COL_BORDER } from "../utils/css";
import { useCopyToClipboard } from "../../../../customHooks/use-copy-to-clipboard";
import { Button, Tooltip } from "antd";
import { isEmpty } from "ramda";

const getCellForSearch = (searchContent: string) => {
  const splitContent = searchContent.split(/(<em>|<\/em>|\n)/);
  const emElements = splitContent.map((content, index) => {
    if (content === "<em>" || content === "</em>") {
      return null;
    } else if (content === "\n") {
      return <br key={index} />;
    } else if (splitContent[index - 1] === "<em>") {
      return (
        <em key={index} className="font-bold w-full">
          {content}
        </em>
      );
    } else return <span key={index}>{content}</span>;
  });

  return emElements.filter(Boolean);
};
const PSCell: FC<precedentCellType> = ({
  text = "",
  className = "",
  redline = false,
  compareText = "",
  isResizing = false,
  highlightedMetric,
  highlight = false,
  heatMap = false,
  hideData = false,
}) => {
  const { copy, copied } = useCopyToClipboard();

  const textCompare = useMemo(() => {
    return heatMapComparison(text, compareText);
  }, [text, compareText]);

  const onClickCopy: MouseEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    copy(text);
  };

  return (
    <td
      scope="col"
      className={`relative border-b border-b-slate-200 group/ps-cell ${
        isResizing ? RESIZE_COL_BORDER : "border-r border-r-slate-200"
      } p-0 align-top w-1/2 ${highlightedMetric ? "bg-green-600/50" : ""} ${
        heatMap
          ? textCompare
            ? "!bg-success bg-opacity-25"
            : "!bg-danger bg-opacity-25"
          : highlight
          ? "bg-green-600/50"
          : ""
      }`}
    >
      {!hideData && (
        <>
          {highlightedMetric ? (
            <div className="p-2 whitespace-normal">
              {getCellForSearch(highlightedMetric)}
            </div>
          ) : redline && !textCompare ? (
            <div className={"px-2 p-1"}>
              <RedLineContent text={text} compareText={compareText} />
            </div>
          ) : (
            <div className={`${className} whitespace-pre-line p-2`}>{text}</div>
          )}
          {!isEmpty(text?.trim()) && (
            <Tooltip
              title={copied ? "Copied to Clipboard" : "Copy to clipboard"}
            >
              <Button
                size={"small"}
                type={"link"}
                className={
                  "absolute bottom-px right-px group-hover/ps-cell:block hidden"
                }
                shape={"circle"}
                icon={
                  copied ? (
                    <i className="fa-solid fa-check text-success"></i>
                  ) : (
                    <i
                      className="fa-regular fa-copy text-primary cursor-pointer"
                      onClick={onClickCopy}
                    />
                  )
                }
              />
            </Tooltip>
          )}
        </>
      )}
    </td>
  );
};

type precedentCellType = {
  text: string;
  className?: string;
  redline?: boolean;
  compareText?: string;
  isResizing?: boolean;
  highlightedMetric?: string;
  highlight?: boolean;
  heatMap?: boolean;
  hideData?: boolean;
};

export default memo(PSCell);
