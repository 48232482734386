import React, { FC, ReactElement, useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { complement, pathOr, remove } from "ramda";
import { Button, Divider, Dropdown, Empty, Tooltip } from "antd";
import { TableColumnHiddenItem } from "./TableColumnHiddenItem";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import {
  CompareColumnsDataMapEditableType,
  CompareColumnsEditableType,
} from "../comparsion-sheet/ComparisonSheet";
import { HTML5Backend } from "react-dnd-html5-backend";
import { updateItemInArray, valOrDefault } from "../../../utils/utils";
import { useClickOutside } from "../../../customHooks/useClickOutside";
import moment from "moment";
const R = require("ramda");

const filterByVisible = ({
  visible = false,
}: CompareColumnsDataMapEditableType) => visible;
export const ColumnSettings: FC<ColumnSettingsProperties> = function ({
  settings,
  onSave,
}: ColumnSettingsProperties) {
  const [open, setOpen] = useState(false);
  const [editedSettings, setEditedSettings] = useState<EditedSettings | null>(
    null
  );
  const ref = useClickOutside(() => setOpen(false));

  const onSelectBaseColumn = (index: number) => {
    if (editedSettings) {
      const base = editedSettings.visibleColumns[index];
      const column = editedSettings.base;
      setEditedSettings({
        ...editedSettings,
        base,
        visibleColumns: updateItemInArray(
          index,
          editedSettings.visibleColumns,
          column
        ),
      });
    }
  };

  const moveColumn = (dragIndex: number, hoverIndex: number) => {
    if (dragIndex === hoverIndex) {
      return;
    }
    setEditedSettings((v) =>
      v
        ? {
            ...v,
            visibleColumns: R.move(dragIndex, hoverIndex, v.visibleColumns),
          }
        : null
    );
  };

  const ColumnItem = ({
    column,
    disabled,
    index,
  }: {
    column: CompareColumnsDataMapEditableType;
    disabled: boolean;
    index: number;
  }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "column",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));

    const [{ isOver, canDrop }, drop] = useDrop<any, any, any>(() => ({
      accept: "column",
      hover: (item: any) => {
        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) return;
        moveColumn(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }));

    const opacity = isDragging ? 0.5 : 1;
    return (
      <div ref={drop} className={`${isOver && canDrop ? "bg-blue-50" : ""}`}>
        <div ref={drag} style={{ opacity }} className={"p-2 group"}>
          <div className={"flex flex-row gap-4 justify-between items-center"}>
            <div className={"text-sm truncate"}>
              <i
                className={
                  "cursor-grab text-xs text-gray-400 fa fa-grip-vertical"
                }
              />
              &nbsp;&nbsp;&nbsp;&nbsp;{column.label} -{" "}
              {moment(column?.date).format("LL")}
            </div>
            <div className={"flex flex-row items-center"}>
              <Tooltip
                title={disabled ? "" : "Mark as base column"}
                placement={"bottom"}
              >
                <Button
                  shape={"circle"}
                  type={"text"}
                  size={"small"}
                  onClick={(v) => {
                    onSelectBaseColumn(index);
                  }}
                >
                  <i className={"fa fa-code-branch text-xs"} />
                </Button>
              </Tooltip>
              <Tooltip title={disabled ? "" : "Hide"} placement={"bottom"}>
                <Button
                  disabled={disabled}
                  className={"p-0"}
                  shape={"circle"}
                  type={"text"}
                  size={"small"}
                  onClick={(v) => {
                    const item = editedSettings?.visibleColumns[
                      index
                    ] as CompareColumnsDataMapEditableType;
                    setEditedSettings((v) =>
                      v
                        ? {
                            ...v,
                            visibleColumns: remove(index, 1, v.visibleColumns),
                            hiddenColumns: [
                              ...v.hiddenColumns,
                              { ...item, visible: false },
                            ],
                          }
                        : null
                    );
                  }}
                >
                  <i className={"fa fa-eye-slash text-xs"} />
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (settings) {
      const columns = valOrDefault([], settings?.columns);
      const visibleColumns = columns.filter(filterByVisible) ?? [];
      const hiddenColumns = columns.filter(complement(filterByVisible));
      setEditedSettings({ base: settings.base, visibleColumns, hiddenColumns });
    }
  }, [settings]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Dropdown
        placement={"bottomRight"}
        open={open}
        overlayClassName={"shadow-xl border"}
        destroyPopupOnHide
        dropdownRender={() => (
          <div ref={ref} className={"bg-white w-96 py-2 text-sm"}>
            <div>
              <div className={"text-sm font-semibold px-4"}>Manage Columns</div>
              <Divider className={"my-4 bg-gray-200"} />
              <div className={"px-4"}>
                <div className={"flex justify-between items-center"}>
                  <div className={"w-44"}>Base Column:</div>
                  <div className={"text-gray-500 truncate"}>
                    {editedSettings?.base?.label} -{" "}
                    {moment(editedSettings?.base?.date).format("LL")}
                  </div>
                </div>
              </div>
              <Divider className={"my-4 bg-gray-200"} />
              <div className={"h-96 overflow-y-scroll hide-scrollbar"}>
                <div className={"flex items-center px-4 pb-2"}>
                  <span className={"text-gray-400"}>Shown</span>
                </div>
                <div className={"px-2 "}>
                  {editedSettings?.visibleColumns?.map((column, index) => (
                    <ColumnItem
                      key={index}
                      disabled={editedSettings?.visibleColumns?.length === 1}
                      column={column}
                      index={index}
                    />
                  ))}
                </div>
                <Divider />
                <div
                  className={
                    "flex flex-row justify-between items-center px-4 pb-2"
                  }
                >
                  <span className={"text-gray-400"}>Hidden</span>
                  <Button
                    type="link"
                    size={"small"}
                    onClick={() => {
                      setEditedSettings((v) =>
                        v
                          ? {
                              ...v,
                              hiddenColumns: [],
                              visibleColumns: [
                                ...v.visibleColumns,
                                ...valOrDefault(
                                  [],
                                  v.hiddenColumns?.map((v) => ({
                                    ...v,
                                    visible: true,
                                  }))
                                ),
                              ],
                            }
                          : null
                      );
                    }}
                  >
                    Show All
                  </Button>
                </div>
                <div className={"px-2"}>
                  {editedSettings?.hiddenColumns &&
                  editedSettings?.hiddenColumns?.length > 0 ? (
                    editedSettings?.hiddenColumns?.map((column, index) => (
                      <TableColumnHiddenItem
                        key={index}
                        label={`${pathOr("", ["label"], column)} - ${moment(
                          pathOr("", ["date"], column)
                        ).format("LL")}`}
                        onShow={() => {
                          setEditedSettings((v) =>
                            v
                              ? {
                                  ...v,
                                  hiddenColumns: remove(
                                    index,
                                    1,
                                    v.hiddenColumns
                                  ),
                                  visibleColumns: [
                                    ...v.visibleColumns,
                                    { ...column, visible: true },
                                  ],
                                }
                              : null
                          );
                        }}
                      />
                    ))
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={"No columns"}
                    />
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <div className={"text-right px-4"}>
              <Button
                type={"primary"}
                className={PRIMARY_BUTTON_STYLE}
                onClick={() => {
                  if (editedSettings) {
                    const {
                      base,
                      visibleColumns = [],
                      hiddenColumns = [],
                    } = editedSettings;
                    onSave({
                      metrics: settings.metrics,
                      base,
                      columns: [...visibleColumns, ...hiddenColumns],
                    });
                    setOpen(false);
                  }
                }}
                size={"small"}
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      >
        <Tooltip title={"Manage Columns"} placement={"bottom"}>
          <Button
            shape={"round"}
            type={"text"}
            className={"px-2"}
            onClick={() => setOpen(true)}
          >
            <i className={"text-base w-5 h-5 fa fa-bars"} />
          </Button>
        </Tooltip>
      </Dropdown>
    </DndProvider>
  );
};

export type EditedSettings = {
  base: CompareColumnsDataMapEditableType;
  visibleColumns: CompareColumnsDataMapEditableType[];
  hiddenColumns: CompareColumnsDataMapEditableType[];
};
type ColumnSettingsProperties = {
  settings: CompareColumnsEditableType;
  onSave: (value: CompareColumnsEditableType) => void;
};

export type ColumnSettingsComponent = (
  properties: ColumnSettingsProperties
) => ReactElement;
