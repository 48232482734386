import { useEffect, useRef, DependencyList } from "react";

export const useInterval: useIntervalType = (
  callback,
  delay,
  dependencies = [],
  callWithoutDelay = true
) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    // call the callback without any delay
    callWithoutDelay && savedCallback.current();

    const id = setInterval(() => savedCallback.current(), delay);
    return (): void => clearInterval(id);
  }, dependencies);
};

type useIntervalType = (
  callback: () => void,
  delay: number,
  dependencies?: DependencyList,
  callWithoutDelay?: boolean
) => void;
