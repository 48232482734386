import React, { FC, ReactNode } from "react";

export const DefaultLayout: FC<DefaultLayoutType> = function ({
  children,
}: DefaultLayoutType) {
  return (
    <div
      className={"min-h-screen min-w-screen font-lato text-dark font-Roboto"}
    >
      {children}
    </div>
  );
};

type DefaultLayoutType = {
  children: ReactNode;
};
