import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { PortfolioCard } from "../PortfolioCard";
import { useParams } from "react-router";
import { Alert, AutoComplete, Button, message, Select, Table } from "antd";
import { ResponseType, voidType } from "../../../../utils/uiTypes";
import {
  AmortizationDataType,
  AmortizationValuesType,
  CapitalStructureValuesType,
  PortfolioSectionType,
} from "../../../../utils/types";
import {
  PermissionType,
  SectionFieldType,
  UnitLabelType,
  UnitType,
} from "../../../../utils/enums";
import { initiateFetchSection } from "../../../../utils/portfolio";
import { ColumnsType } from "antd/es/table";
import {
  assocPath,
  isEmpty,
  isNil,
  path,
  prop,
  sortBy,
  transpose,
} from "ramda";
import EditableTableCell from "../../../general/EditableTableCell";
import {
  addAmortizationColumn,
  addAmortizationRow,
  deleteAmortizationColumn,
  deleteAmortizationRow,
  updateAmortizationRow,
  updateCapitalStructureFields,
} from "../../../../services/services";
import {
  ACTION_BUTTON_CSS,
  PRIMARY_BUTTON_STYLE,
} from "../../../../utils/cssConfigs";
import { ConfirmDelete } from "../../../../utils/confirmationModals";
import moment from "moment";
import { getObjectFromPropertyValue } from "../../../../utils/utils";
import { PortfolioAdminPermissionBlock } from "../../PortfolioAdminPermissionBlock";
import { getContainerForFullScreen } from "../../../../utils/container";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import {
  FullScreenButton,
  FullScreenWidget,
} from "../../../general/FullScreenWidget";
import Title from "antd/es/typography/Title";
import { formatTotal } from "../../../../utils/allocation";

const sortByRank = sortBy<AmortizationDataType>(prop("rank"));

function sum(inputArray: AmortizationValuesType[]) {
  const numbers = inputArray.map(({ value }) =>
    value && !isNaN(Number(value)) ? Number(value) : 0
  );

  const decimalPlaces =
    numbers.length > 0
      ? Math.max(
          ...numbers.map((val = 0) => {
            if (val % 1 !== 0) {
              return val?.toString()?.split(".")[1]?.length;
            }
            return 0;
          })
        )
      : 0;

  const sum = numbers.reduce((acc, val) => acc + val, 0);

  const result = sum.toFixed(decimalPlaces);

  return result;
}

export const Amortization: FC<AmortizationType> = ({
  permissions,
  portfolioId,
}) => {
  const { height: windowHeight } = useWindowDimensions();
  const ref = useRef<HTMLDivElement>(null);

  const { sectionId }: ParamsType = useParams();

  const [updating, setUpdating] = useState(false);
  const [unit, setUnit] = useState<UnitType>(UnitType.MILLION);
  const [section, setSection] = useState<PortfolioSectionType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [amortizationFields, setAmortizationFields] = useState<
    AmortizationDataType[]
  >([]);

  const getFieldTypeData = (
    type: SectionFieldType,
    fieldUnit?: UnitType
  ): FieldDataType | undefined => {
    switch (type) {
      case "TEXT":
        return { type: "textarea" };
      case "DATE":
        return { type: "date" };
      case "MONETARY":
        return {
          type: "number",
          unit: UnitLabelType[fieldUnit ?? UnitType.MILLION],
          currency: section?.reportingCurrency ?? "",
        };
      case "NUMERIC":
        return { type: "number", unit: fieldUnit };
      case "LONG_TEXT":
        return { type: "textarea" };
    }
  };

  const deleteRow = (rank: string): void => {
    deleteAmortizationRow({
      segments: {
        portfolioId,
        sectionId,
        rank,
      },
    })
      .then(({ data }: ResponseType<AmortizationDataType[]>) => {
        setAmortizationFields(() => sortByRank(data));
        message.success("Row removed successfully.");
      })
      .catch((error: string) => {
        console.error(error);
        message.error("Something went wrong. Please try again");
      });
  };

  const deleteColumn = (fieldId: string): void => {
    deleteAmortizationColumn({
      segments: {
        portfolioId,
        sectionId,
        fieldId,
      },
    })
      .then(({ data }: ResponseType<AmortizationDataType[]>) => {
        setAmortizationFields(() => sortByRank(data));
        setUnit(
          () =>
            getObjectFromPropertyValue(
              "fieldType",
              SectionFieldType.MONETARY,
              data
            )?.unit
        );
        message.success("Row removed successfully.");
      })
      .catch((error: string) => {
        console.error(error);
        message.error("Something went wrong. Please try again");
      });
  };

  /*
  * {editable && fieldType === SectionFieldType.MONETARY && <Button size={"small"}
             type={"text"}
             icon={<i className="fas fa-trash-alt" />}
             className={`${ACTION_BUTTON_CSS} hover:text-danger opacity-50 hover:opacity-100`}
             onClick={(): void => {
               ConfirmDelete(
                   "Are you sure you want to remove this column? This will delete all entered data for the column.",
                   () => deleteColumn(id),
                   <></>
               );
             }}
         />}
         * */ 2;

  const getColumns = (
    data: AmortizationDataType[],
    editable: boolean
  ): ColumnsType<AmortizationValuesType[]> =>
    data
      .sort((a, b) => (parseInt(a?.rank) > parseInt(b?.rank) ? 1 : -1))
      .map(({ label, fieldType, rank, unit, id, tag }, fieldIndex) => {
        const tranche =
          fieldType === SectionFieldType.TEXT && tag === "tranche";
        return {
          title: (
            <div
              className={`p-4 justify-between whitespace-nowrap text-white ${
                tranche ? "bg-gray-700" : "bg-gray-500"
              }`}
            >
              {label}
            </div>
          ),
          className: `p-0 whitespace-nowrap min-w-[15rem] ${
            tranche && "!bg-gray-100 !z-20"
          }`,
          dataIndex: fieldIndex,
          fixed: tranche ? "left" : false,
          render: (
            data: AmortizationValuesType,
            record,
            rowIndex
          ): ReactNode => {
            const type = getFieldTypeData(fieldType, unit);
            return tranche && editable ? (
              <div
                className={
                  "group-hover:bg-blue-50 flex items-center w-full focus-within:bg-white focus-within:border focus-within:border-sky-500 focus-within:z-50 focus-within:shadow-cell"
                }
              >
                <AutoComplete
                  getPopupContainer={getContainerForFullScreen}
                  bordered={false}
                  popupClassName={"!z-50"}
                  className="m-0 w-full py-2.5 border border-transparent false bg-transparent "
                  value={data.value}
                  filterOption={(inputValue, option) =>
                    option!.value
                      ?.toString()
                      .toUpperCase()
                      ?.indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onChange={(val): void => {
                    updateRow(
                      { ...data, value: val?.toString() },
                      id,
                      fieldIndex,
                      rowIndex
                    );
                  }}
                  placeholder={"-"}
                  options={getObjectFromPropertyValue(
                    "tag",
                    "name",
                    section?.capitalStructureTableFieldDTOS ?? []
                  )
                    ?.capitalStructureValues?.filter(
                      ({ value }: CapitalStructureValuesType) =>
                        !isEmpty(value) && !isNil(value)
                    )
                    .map(({ value = "" }: CapitalStructureValuesType) => ({
                      value,
                      label: value,
                    }))}
                />
                {editable && (
                  <Button
                    type={"text"}
                    size={"small"}
                    className={`${ACTION_BUTTON_CSS} hover:text-danger opacity-0 group-hover:opacity-100 mr-2`}
                    onClick={(): void => {
                      ConfirmDelete(
                        "Are you sure you want to remove this row? This will delete all entered data for the row.",
                        () => deleteRow(data.rank),
                        <></>
                      );
                    }}
                  >
                    <i className="fas fa-trash-alt" />
                  </Button>
                )}
              </div>
            ) : (
              <div>
                <EditableTableCell
                  editable={editable}
                  bordered={false}
                  OId={data.id}
                  value={data.value}
                  onChange={(val): void => {
                    updateRow(
                      { ...data, value: val?.toString() },
                      id,
                      fieldIndex,
                      rowIndex
                    );
                  }}
                  defaultCell={false}
                  placeholder={"-"}
                  prefix={
                    type?.currency && section?.reportingCurrency
                      ? section.reportingCurrency
                      : undefined
                  }
                  suffix={type?.unit || undefined}
                  type={type?.type}
                />
              </div>
            );
          },
        };
      });

  const getAmortizationData: voidType = () => {
    initiateFetchSection(sectionId, portfolioId)
      .then((data) => {
        setSection(data);
        setAmortizationFields(() => sortByRank(data.amortizationDTOS));
        setUnit(
          () =>
            getObjectFromPropertyValue(
              "fieldType",
              SectionFieldType.MONETARY,
              data.amortizationDTOS
            )?.unit
        );
      })
      .catch((e: string) => e && message.error(e))
      .then((): void => setLoading(false));
  };

  const updateRow = (
    record: AmortizationValuesType,
    fieldId: string,
    fieldIndex: number,
    rowIndex: number
  ): void => {
    const list = sortByRank(
      assocPath<AmortizationDataType[]>(
        [fieldIndex, "amortizationFieldValues", rowIndex],
        record,
        amortizationFields
      )
    );
    setAmortizationFields(() => list);
    updateAmortizationRow({
      segments: { portfolioId, sectionId, fieldId },
      body: JSON.stringify(record),
    }).catch((error: string) => console.error(error));
  };

  const addRow: voidType = () => {
    setUpdating(true);
    addAmortizationRow({
      segments: { portfolioId, sectionId },
    })
      .then(({ data }: ResponseType<AmortizationDataType[]>) => {
        setAmortizationFields(() => sortByRank(data));
        setUpdating(() => false);
        message.success("Added new tranch successfully");
      })
      .catch((error: string) => {
        0;
        setUpdating(() => false);
        message.success("Unable to add new tranch");
        console.error(error);
      });
  };

  const addColumn: voidType = () => {
    setUpdating(true);
    const years = amortizationFields
      .filter((o) => (isNaN(Number(o.label)) ? false : true))
      .map((o) => Number(o.label));
    addAmortizationColumn({
      segments: { portfolioId, sectionId },
      body: JSON.stringify({
        label: years.length > 0 ? Math.max(...years) + 1 : moment().year(),
        unit,
      }),
    })
      .then(({ data }: ResponseType<AmortizationDataType[]>) => {
        setAmortizationFields(() => sortByRank(data));
        setUnit(
          () =>
            getObjectFromPropertyValue(
              "fieldType",
              SectionFieldType.MONETARY,
              data
            )?.unit
        );
        setUpdating(false);
      })
      .catch((error: string) => {
        setUpdating(false);
        console.error(error);
      });
  };

  const onUnitChange = (unit: UnitType) => {
    setUnit(() => unit);
    const monetaryColumn = getObjectFromPropertyValue(
      "fieldType",
      SectionFieldType.MONETARY,
      amortizationFields
    );
    if (monetaryColumn) {
      const updatedFieldsData = amortizationFields.map((o) =>
        o.fieldType === SectionFieldType.MONETARY ? { ...o, unit } : o
      );
      updateCapitalStructureFields({
        segments: { portfolioId, sectionId },
        body: JSON.stringify(updatedFieldsData),
      })
        .then(() => {
          setAmortizationFields(() => sortByRank(updatedFieldsData));
        })
        .catch((error: string) => console.error(error));
    }
  };

  const formatDataSource = (
    fields: AmortizationDataType[]
  ): AmortizationValuesType[][] => {
    const data = fields?.reduce(
      (previousValue, currentValue) => [
        ...previousValue,
        currentValue?.amortizationFieldValues?.sort((a, b) =>
          parseInt(a?.rank) > parseInt(b?.rank) ? 1 : -1
        ),
      ],
      [] as AmortizationValuesType[][]
    );
    return transpose<AmortizationValuesType>(data);
  };

  useEffect(() => {
    if (portfolioId && sectionId) getAmortizationData();
  }, [portfolioId, sectionId]);

  return (
    <PortfolioCard
      permissions={permissions}
      key={section?.id}
      loading={loading}
      bodyStyle={{ padding: "1px 5px 0 5px" }}
      title={section?.sectionName ?? "Amortization"}
    >
      <FullScreenWidget>
        {(isFullScreen, trigger): ReactNode => (
          <div
            className={`${
              isFullScreen &&
              "bg-gray-50 h-screen w-screen overflow-y-scroll p-6"
            } h-full`}
          >
            <div className={"pb-5"}>
              <div className={"flex flex-row items-center gap-2 justify-end"}>
                {isFullScreen && (
                  <Title level={4} className="opacity-90 mr-auto my-auto">
                    Amortization
                  </Title>
                )}
                <PortfolioAdminPermissionBlock permissions={permissions}>
                  <Select
                    getPopupContainer={getContainerForFullScreen}
                    className={"w-36"}
                    value={unit}
                    placeholder={"Select Unit"}
                    onSelect={onUnitChange}
                    dropdownMatchSelectWidth={true}
                    options={[
                      { value: "THOUSAND", label: "Thousand (K)" },
                      { value: "MILLION", label: "Million (M)" },
                    ]}
                  />
                </PortfolioAdminPermissionBlock>
                {section?.reportingCurrency && (
                  <div
                    className={
                      "text-gray-700 rounded-sm border border-[#D9D9D9] p-2 py-1"
                    }
                  >
                    {section?.reportingCurrency}
                  </div>
                )}
                <PortfolioAdminPermissionBlock permissions={permissions}>
                  <Button
                    className={PRIMARY_BUTTON_STYLE}
                    disabled={updating}
                    onClick={addColumn}
                  >
                    + Add Year
                  </Button>
                </PortfolioAdminPermissionBlock>
                <FullScreenButton
                  isFullScreen={isFullScreen}
                  trigger={trigger}
                />
              </div>
            </div>
            <Table<AmortizationValuesType[]>
              ref={ref}
              locale={{ emptyText: "No Tranches Added Yet!" }}
              scroll={{
                x: true,
                y: isFullScreen ? windowHeight - 275 : windowHeight / 2.2,
              }}
              summary={(pageData) => {
                return (
                  <Table.Summary fixed={true}>
                    {unit && (
                      <PortfolioAdminPermissionBlock permissions={permissions}>
                        <Table.Summary.Row className={"bg-gray-100"}>
                          <Table.Summary.Cell
                            index={0}
                            className={"p-0 !z-20"}
                            colSpan={1}
                          >
                            <div
                              className={
                                "text-blue-400 cursor-pointer hover:underline flex w-full"
                              }
                            >
                              <Button
                                disabled={updating}
                                type={"text"}
                                className={
                                  "text-left pl-5 border-0 text-primary disabled:border-opacity-60 font-medium w-full hover:bg-blue-50 bg-white"
                                }
                                onClick={addRow}
                              >
                                + Add Tranche
                              </Button>
                            </div>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </PortfolioAdminPermissionBlock>
                    )}

                    <Table.Summary.Row>
                      {amortizationFields.map(
                        (
                          {
                            tag,
                            rank,
                            amortizationFieldValues,
                            unit,
                            fieldType,
                          },
                          i
                        ) => {
                          return (
                            <Table.Summary.Cell
                              key={i}
                              index={Number(rank)}
                              className={`px-3 !bg-zinc-100 ${
                                tag === "tranche" && "!z-20"
                              }`}
                            >
                              {tag === "tranche" ? (
                                <span className={"px-1 font-medium"}>
                                  Total
                                </span>
                              ) : (
                                <div className={"flex flex-row gap-2"}>
                                  <div>
                                    {section?.reportingCurrency
                                      ? section.reportingCurrency
                                      : undefined}
                                  </div>
                                  <div className={"flex-grow"}>
                                    {formatTotal(sum(amortizationFieldValues))}
                                  </div>
                                  <div>
                                    {getFieldTypeData(fieldType, unit)?.unit}
                                  </div>
                                </div>
                              )}
                            </Table.Summary.Cell>
                          );
                        }
                      )}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
              rowKey={(record) => path([0, "id"], record) ?? ""}
              sticky={true}
              bordered={true}
              pagination={false}
              columns={[
                ...getColumns(
                  amortizationFields,
                  permissions.includes(PermissionType.ADMIN_PORTFOLIO)
                ),
              ]}
              rowClassName={"group"}
              dataSource={formatDataSource(amortizationFields)}
              className={"border overflow-hidden"}
            />
            {!unit && (
              <Alert
                className={"mt-4"}
                type={"warning"}
                message={"Please select unit before proceeding"}
              />
            )}
          </div>
        )}
      </FullScreenWidget>
    </PortfolioCard>
  );
};

type ParamsType = {
  portfolioId: string;
  sectionId: string;
};
type AmortizationType = {
  permissions: PermissionType[];
  portfolioId: string;
};
type FieldDataType = {
  unit?: string;
  currency?: string;
  type: "number" | "select" | "textarea" | "text" | "date" | undefined;
};
