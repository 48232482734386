import { Calendar } from "../pages/Calendar";
import { Notifications } from "../pages/Notifications";
import { Login } from "../pages/Login";
import { FC } from "react";
import { Transaction } from "../pages/Transaction";
import { Logout } from "../pages/Logout";
import { ExternalPageNotFound } from "../pages/ExternalPageNotFound";
import { InternalPageNotFound } from "../pages/InternalPageNotFound";
import { Company } from "../pages/Company";
import { Register } from "../pages/Register";
import { CreateTransaction } from "../pages/CreateTransaction";
import { CompanyModuleType, UserType } from "./types";
import {
  CompanyEnumType,
  ElementType,
  PermissionType,
  RoleType,
} from "./enums";
import { User } from "../pages/User";
import { LoginVerification } from "../pages/LoginVerification";
import { checkElementViewPermission } from "./permissions";
import { RedirectToHomePage } from "../pages/RedirectToHomePage";
import { CompanySetup } from "../pages/CompanySetup";
import { Transactions } from "../pages/Transactions";
import { EditTransaction } from "../components/transaction/EditTransaction";
import { ResetPassword } from "../pages/ResetPassword";
import { JoinTeam } from "../pages/JoinTeam";
import { SystemSettings } from "../pages/SystemSettings";
import { Analytics } from "../components/analytics/Analytics";
import { Portfolio } from "../pages/Portfolio";
import { Search } from "../pages/Search";
import { Relationships } from "../pages/Relationships";
import { VerifyEmail } from "../pages/VerifyEmail";
import { ApproveUser } from "../pages/ApproveUser";
import { DEFAULT_COMPANY_MODULE_CONFIG } from "./module-util";
import { RelationshipDashboard } from "../components/relationship/RelationshipDashboard";
import { equals, isEmpty } from "ramda";
import { getRelationshipModules } from "./relationship";
import { Wip } from "../pages/Wip";

export const AUTH_ROUTES: getAuthRoutesType = (
  { authorities = [], elementPermissions = [], companyDTO },
  modulePreference = DEFAULT_COMPANY_MODULE_CONFIG
) => {
  const hasAuthority: boolean = authorities.includes(RoleType.ROLE_ADMIN);
  return [
    {
      Component: User,
      exact: true,
      path: "/user/:userMenu",
      permission: true,
    },
    {
      Component: Transactions,
      exact: true,
      path: "/transactions",
      permission: true,
    },
    {
      Component: Wip,
      exact: true,
      path: "/wip",
      permission: equals(CompanyEnumType.LENDER, companyDTO?.companyType),
    },
    {
      Component: CreateTransaction,
      exact: true,
      path: [
        "/transactions/create",
        "/transactions/create/:currentTransactionId",
      ],
      permission:
        modulePreference.transaction || modulePreference.offlineTransaction,
    },
    {
      Component: EditTransaction,
      exact: true,
      path: ["/transactions/:editTransactionId/edit"],
      permission:
        modulePreference.transaction || modulePreference.offlineTransaction,
    },
    {
      Component: CompanySetup,
      exact: true,
      path: "/company/add",
      permission: true,
    },
    {
      Component: Company,
      exact: false,
      path: [
        "/company/:companyMenu/:id",
        "/company/settings/:id",
        "/company/templates/:id/create",
        "/company/:companyMenu",
        "/company/:companyMenu/:menu",
      ],
      permission: true,
    },
    {
      Component: Calendar,
      exact: true,
      path: "/calendar",
      permission: true,
    },
    {
      Component: Notifications,
      exact: true,
      path: "/notifications",
      permission: modulePreference.notifications,
    },
    {
      Component: SystemSettings,
      exact: true,
      path: "/admin/:adminMenu",
      permission: hasAuthority,
    },
    {
      Component: Transaction,
      exact: false,
      path: [
        "/transactions/:id/:transactionMenu",
        "/transactions/:id/termsheet/:webFormId",
        "/transactions/:id/completions/:sectionId",
      ],
      permission: true,
    },
    {
      Component: Search,
      exact: true,
      path: ["/search", "/search/:searchMenu"],
      permission:
        modulePreference.precedentSearch &&
        checkElementViewPermission(
          ElementType.PRECEDENT_SEARCH,
          elementPermissions,
          [PermissionType.VIEW_PRECEDENT_SEARCH],
          true
        ),
    },
    {
      Component: Portfolio,
      exact: true,
      path: [
        "/portfolio",
        "/portfolio/:portfolioId",
        "/portfolio/:portfolioId/:portfolioMenu",
        "/portfolio/:portfolioId/:portfolioMenu/:sectionId",
      ],
      permission:
        modulePreference.portfolioManagement &&
        checkElementViewPermission(
          ElementType.PORTFOLIO,
          elementPermissions,
          [PermissionType.VIEW_PORTFOLIO],
          true
        ),
    },
    {
      Component: RedirectToHomePage,
      exact: false,
      path: ["/login"],
      permission: true,
    },
    {
      Component: Analytics,
      exact: true,
      path: "/insights",
      permission:
        modulePreference.insights &&
        checkElementViewPermission(
          ElementType.INSIGHTS,
          elementPermissions,
          [PermissionType.VIEW_INSIGHTS],
          true
        ),
    },
    {
      Component: Relationships,
      exact: true,
      path: ["/relationship"],
      permission: !isEmpty(
        getRelationshipModules(elementPermissions, modulePreference)
      ),
    },
    {
      Component: RelationshipDashboard,
      exact: true,
      path: ["/relationship/:id", "/relationship/:id/:crmMenu"],
      permission: !isEmpty(
        getRelationshipModules(elementPermissions, modulePreference)
      ),
    },
    {
      Component: ApproveUser,
      exact: true,
      path: "/approve-user/:hash/approvedOrRejectedBy/:byUserId",
      permission: true,
    },
    {
      Component: RedirectToHomePage,
      exact: true,
      path: "/resetPassword/:userHash",
      permission: true,
    },
    {
      Component: Logout,
      exact: true,
      path: "/logout",
      permission: true,
    },
    {
      Component: RedirectToHomePage,
      exact: true,
      path: ["/home", "/"],
      permission: true,
    },
    {
      Component: InternalPageNotFound,
      exact: false,
      path: ["*", "/api/*"],
      permission: true,
    },
  ];
};

export const AUTH_REDIRECT_ROUTES: Array<RedirectRouteType> = [
  {
    path: "/user",
    redirectTo: "/user/profile",
    permission: true,
  },
  {
    path: "/portfolio",
    redirectTo: "/portfolio/summary",
    permission: true,
  },
  {
    path: "/company",
    redirectTo: "/company/profile",
    permission: true,
  },
  {
    path: "/company/webhooks",
    redirectTo: "/company/webhooks/overview",
    permission: true,
  },
  {
    path: "/login",
    redirectTo: "/transactions",
    permission: true,
  },
];

export const UNAUTH_ROUTES: Array<RouteType> = [
  {
    Component: ApproveUser,
    exact: true,
    path: "/approve-user/:hash/approvedOrRejectedBy/:byUserId",
    permission: true,
  },
  {
    Component: ResetPassword,
    exact: true,
    path: "/resetPassword/:hash",
    permission: true,
  },
  {
    Component: VerifyEmail,
    exact: true,
    path: "/verify-email/:hash",
    permission: true,
  },
  {
    Component: JoinTeam,
    exact: true,
    path: "/join-team/:hash/company/:companyId",
    permission: true,
  },
  {
    Component: Login,
    exact: false,
    path: "/login",
    permission: true,
  },
  {
    Component: LoginVerification,
    exact: true,
    path: "/verification",
    permission: true,
  },

  {
    Component: Register,
    exact: false,
    path: "/sign-up/696551612",
    permission: true,
  },
  {
    Component: ExternalPageNotFound,
    exact: false,
    path: "*",
    permission: true,
  },
];

export type RouteType = {
  Component: FC;
  exact: boolean;
  path: string | string[];
  permission?: boolean;
};

export type RedirectRouteType = {
  path: string;
  redirectTo: string;
  permission: boolean;
};

type getAuthRoutesType = (
  user: UserType,
  companyModulePreference: CompanyModuleType
) => Array<RouteType>;
