import React, { FC, useEffect, useState } from "react";
import {
  FieldType,
  PortfolioSectionFieldType,
  PortfolioSectionType,
} from "../../../../utils/types";
import { PortfolioInputField } from "../PortfolioInputField";
import { PortfolioCard } from "../PortfolioCard";
import { DisconnectOutlined } from "@ant-design/icons";
import { EmptyPortfolioCard } from "../EmptyPortfolioCard";
import { InputFieldModal } from "../InputFieldModal";
import {
  createNewPortfolioSectionField,
  deletePortfolioSection,
  removeSectionField,
  updatePortfolioRank,
  updatePortfolioSectionField,
  updatePortfolioSectionFieldAnswer,
} from "../../../../services/services";
import { ResponseType } from "../../../../utils/uiTypes";
import { useHistory, useParams } from "react-router";
import { getIndexByKey, updateItemInArray } from "../../../../utils/utils";
import { message } from "antd";
import { findIndex, isEmpty, propEq } from "ramda";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import {
  PermissionType,
  PortfolioSectionEnumType,
} from "../../../../utils/enums";
import { initiateFetchSection } from "../../../../utils/portfolio";
import { ConfirmDelete } from "../../../../utils/confirmationModals";

const R = require("ramda");

type PortfolioGeneralSectionType = {
  permissions: PermissionType[];
  portfolioId: string;
};

export const PortfolioGeneralSection: FC<PortfolioGeneralSectionType> = ({
  permissions,
  portfolioId,
}) => {
  const history = useHistory();
  const { sectionId }: ParamsType = useParams();
  const [section, setSection] = useState<PortfolioSectionType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [editMode, setEditMode] = useState<editModeType>({ edit: false });
  const [isNewInputOpen, setIsNewInputOpen] = useState<boolean>(false);
  const [portfolioSections, setPortfolioSections] = useState<
    PortfolioSectionFieldType[]
  >([]);

  const fetchSection = (sectionId: string): void => {
    setLoading(true);
    initiateFetchSection(sectionId, portfolioId)
      .then(setSection)
      .catch((e: string) => e && message.error(e))
      .then(() => setLoading(false));
  };

  const onUpdate: onUpdateType = (field) => {
    updatePortfolioSectionFieldAnswer({
      body: JSON.stringify({ ...field, portfolioId }),
    })
      .then(() => {
        if (section) {
          setSection({
            ...section,
            portfolioSectionFieldDTOS: updateItemInArray(
              getIndexByKey(
                section?.portfolioSectionFieldDTOS ?? [],
                "id",
                field.id
              ),
              section.portfolioSectionFieldDTOS ?? [],
              field
            ),
          });
        }
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Something went wrong, input data not saved.", 3);
      });
  };

  const getMaxRank: getMaxRankType = (list = []) => {
    const ranks = list.map((v) => (v.rank ? Number(v.rank) : 0));
    return Math.max(...ranks);
  };
  const addNewField: addNewFieldType = (fieldData) => {
    if (!isEmpty(fieldData?.label?.trim())) {
      const maxRank = getMaxRank(section?.portfolioSectionFieldDTOS ?? []);
      createNewPortfolioSectionField({
        body: JSON.stringify({
          fieldName: fieldData.label,
          options: fieldData.options,
          portfolioSectionId: section?.id,
          required: true,
          sectionFieldType: fieldData.sectionFieldType,
          rank:
            (maxRank != null || maxRank != undefined) &&
            section &&
            section?.portfolioSectionFieldDTOS?.length > 0
              ? maxRank + 1
              : 0,
          portfolioId,
        }),
      })
        .then(({ data }: ResponseType<PortfolioSectionFieldType>) => {
          const portfolioSectionFieldDTOS = portfolioSections
            ? [...portfolioSections, data]
            : [data];
          if (section)
            setSection({
              ...section,
              portfolioSectionFieldDTOS,
            });
          setPortfolioSections(portfolioSectionFieldDTOS);
        })
        .catch((err: string): void => {
          console.error(err);
          message.error("Error creating new field.", 3);
        })
        .then((): void => {
          setIsNewInputOpen(false);
          setEditMode({ edit: true });
        });
    }
  };

  const updateSectionField: updateFieldType = (fieldData) => {
    updatePortfolioSectionField({
      body: JSON.stringify(fieldData),
    })
      .then(({ data }: ResponseType<PortfolioSectionFieldType>) => {
        const index = findIndex(propEq("id", data.id))(portfolioSections);
        if (index >= 0) {
          setPortfolioSections((list) => updateItemInArray(index, list, data));
          if (section)
            setSection({
              ...section,
              portfolioSectionFieldDTOS: updateItemInArray(
                index,
                portfolioSections,
                data
              ),
            });
        }
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Error updating field.", 3);
      })
      .then((): void => {
        setIsNewInputOpen(false);
        setEditMode({ edit: true });
      });
  };

  const removeField: onUpdateType = (fieldData) => {
    removeSectionField({
      body: JSON.stringify({ ...fieldData, portfolioId }),
    })
      .then(() => {
        const portfolioSectionFieldDTOS = portfolioSections
          ? portfolioSections.filter((sect) => sect.id !== fieldData.id)
          : [];
        if (section)
          setSection({
            ...section,
            portfolioSectionFieldDTOS,
          });
        setPortfolioSections(portfolioSectionFieldDTOS);
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Error removing field.", 3);
      })
      .then((): void => setIsNewInputOpen(false));
  };

  const getPortfolioStatus: getPortfolioStatusType = (
    portfolioSectionsList
  ) => {
    const statusField = portfolioSectionsList.find(
      (item) => item.fieldNameSystemLabel === "portfolioStatus"
    );
    const activeId = statusField?.options?.find(
      (option) => option.optionValueSystemLabel === "active"
    )?.id;
    return (
      !statusField?.selectedAnswerIds ||
      (statusField?.selectedAnswerIds &&
        statusField?.selectedAnswerIds[0] === activeId)
    );
  };

  const openEditField: onUpdateType = (fieldData) => {
    setEditMode({ ...editMode, field: fieldData });
    setIsNewInputOpen(true);
  };

  const onDragging = (oldIndex: number, newIndex: number): void => {
    setPortfolioSections((portfolios) => [
      ...R.move(oldIndex, newIndex, portfolios).map(
        (val: PortfolioSectionFieldType, index: number) => ({
          ...val,
          rank: index.toString(),
        })
      ),
    ]);
  };
  const onDrop = (): void => {
    const updateRankDTO = portfolioSections.map((item) => ({
      id: item.id,
      rank: item.rank,
      portfolioId,
      portfolioSectionId: section?.id,
    }));
    updatePortfolioRank({
      body: JSON.stringify(updateRankDTO),
    }).catch((err: string): void => {
      console.error(err);
      message.error("Error. Update not saved.", 3);
    });
  };

  const initiateSectionDelete = (sectionId: string): void => {
    ConfirmDelete("Confirm Remove Section?", () => {
      setLoading(true);
      deletePortfolioSection({ segments: { sectionId } })
        .then(() => {
          message.success("Section Removed Successfully! ");
          history.go(0);
        })
        .catch((error: string) => {
          message.error("Unable to Delete Section");
          console.error(error);
        })
        .then(() => setLoading(false));
    });
  };

  useEffect(() => {
    if (section) {
      setPortfolioSections(
        section?.portfolioSectionFieldDTOS?.sort(
          (a, b) => Number(a?.rank) - Number(b?.rank)
        )
      );
    }
  }, [section]);

  useEffect(() => {
    setEditMode({ edit: false });
    fetchSection(sectionId);
  }, [sectionId]);

  return (
    <React.Fragment>
      <PortfolioCard
        permissions={permissions}
        key={section?.id}
        title={section?.sectionName ?? "Information"}
        editMode={editMode.edit}
        loading={loading}
        deleteMode={section?.sectionType === PortfolioSectionEnumType.CUSTOM}
        onClick={(): void => setEditMode(({ edit }) => ({ edit: !edit }))}
        onDelete={(): void => initiateSectionDelete(sectionId)}
      >
        {portfolioSections ? (
          <>
            <DndProvider backend={HTML5Backend}>
              <div className={"flex flex-col gap-y-1"}>
                {portfolioSections
                  ?.filter((field) =>
                    getPortfolioStatus(portfolioSections)
                      ? field.fieldNameSystemLabel !== "exitDate"
                      : true
                  )
                  .map((field, index) => (
                    <PortfolioInputField
                      key={field.id}
                      index={index}
                      onDragging={onDragging}
                      onDrop={onDrop}
                      field={field}
                      disabled={
                        !permissions.includes(PermissionType.ADMIN_PORTFOLIO)
                      }
                      onDelete={removeField}
                      onUpdate={onUpdate}
                      openEditField={openEditField}
                      customizeView={editMode.edit}
                    />
                  ))}
              </div>
            </DndProvider>
            {editMode.edit && (
              <div
                className={"px-4 py-1 w-full text-left md:w-1/3 md:text-right"}
              >
                <span
                  className={"cursor-pointer hover:underline text-blue-400"}
                  onClick={(): void => setIsNewInputOpen(true)}
                >
                  + New Data Field
                </span>
              </div>
            )}
          </>
        ) : (
          <EmptyPortfolioCard
            permissions={permissions}
            message={"No input fields in this section yet"}
            buttonText={"Add new data field"}
            onClick={(): void => setIsNewInputOpen(true)}
            image={
              <DisconnectOutlined className={"py-3 text-gray-400 text-7xl"} />
            }
          />
        )}
      </PortfolioCard>
      <InputFieldModal
        isVisible={isNewInputOpen}
        editField={editMode.field ?? null}
        onUpdate={updateSectionField}
        onClose={(isOpen): void => {
          setIsNewInputOpen(isOpen);
          if (!isOpen && editMode.field)
            setEditMode({ ...editMode, field: undefined });
        }}
        onCreate={addNewField}
      />
    </React.Fragment>
  );
};

type ParamsType = {
  portfolioId: string;
  sectionId: string;
};
type getMaxRankType = (list: PortfolioSectionFieldType[]) => number | undefined;
type onUpdateType = (field: PortfolioSectionFieldType) => void;
type addNewFieldType = (fieldData: FieldType) => void;
type updateFieldType = (fieldData: PortfolioSectionFieldType) => void;
type getPortfolioStatusType = (
  portfolioSectionsList: PortfolioSectionFieldType[]
) => boolean | undefined;
type editModeType = {
  edit: boolean;
  field?: PortfolioSectionFieldType;
};
