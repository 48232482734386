import React, { FC, useState } from "react";
import { Button, Drawer, Switch, Table, Tag, Tooltip } from "antd";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { UserType } from "../../utils/types";
import { ColumnsType } from "antd/es/table";
import { convertDateToFormat } from "../../utils/moment";
import { voidType } from "../../utils/uiTypes";
import { ChangePasswordModal } from "./ChangePasswordModal";
import {
  InfoCircleOutlined,
  UndoOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { PETransactionCreationType } from "../../utils/enums";
import { ViewEmail } from "../general/ViewEmail";

export const ViewTeamModal: FC<ViewTeamModalType> = function ({
  onClose,
  info,
  onSave,
  onReset,
  companyName,
  change2FA,
  hasOnlineTransaction,
  hasOfflineTransaction,
}) {
  const [passwordEmail, setPasswordEmail] = useState<string | null>(null);

  const handleCancelPassword: voidType = () => {
    setPasswordEmail(null);
  };

  const columns: ColumnsType<UserType> = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "25%",
      render: ViewEmail,
    },
    {
      title: "Create Transaction",
      dataIndex: "createTransaction",
      key: "createTransaction",
      width: "15%",
      render: (val: boolean, { email }) => {
        return (
          <div className={"inline-flex items-center gap-x-3"}>
            <Switch
              disabled={!hasOnlineTransaction}
              className={`${val ? "bg-success" : "bg-muted"}`}
              onChange={(val) =>
                onSave(email, PETransactionCreationType.ONLINE, val)
              }
              defaultChecked={val}
              checked={val}
            />
            {!hasOnlineTransaction && (
              <Tooltip
                title={"Company doesnt have access to Transaction Creation"}
              >
                <InfoCircleOutlined className={"text-red-500"} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Create Offline Transaction",
      dataIndex: "createOfflineTransaction",
      key: "createOfflineTransaction",
      width: "15%",
      render: (val: boolean, { email }) => {
        return (
          <div className={"inline-flex items-center gap-x-3"}>
            <Switch
              disabled={!hasOfflineTransaction}
              className={`${val ? "bg-success" : "bg-muted"}`}
              onChange={(val) =>
                onSave(email, PETransactionCreationType.OFFLINE, val)
              }
              defaultChecked={val}
              checked={val}
            />
            {!hasOfflineTransaction && (
              <Tooltip
                title={
                  "Company doesnt have access to Offline Transaction Creation"
                }
              >
                <InfoCircleOutlined className={"text-red-500"} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Change Password",
      dataIndex: "password",
      key: "password",
      width: "fit-content",

      render: (link, record) => {
        return (
          <Button
            onClick={() => setPasswordEmail(record.email)}
            className={PRIMARY_BUTTON_STYLE}
          >
            Set Password
          </Button>
        );
      },
    },
    {
      title: "2FA Enabled",
      dataIndex: "2FAEnabled",
      key: "2FaEnabled",
      width: "10%",
      render: (link, record, index) => {
        return (
          <div className={"inline-flex items-center gap-x-3"}>
            <Switch
              className={`${record.sms2FaEnabled ? "bg-success" : "bg-muted"}`}
              checked={record.sms2FaEnabled}
              disabled={!record.sms2FaPhoneVerified}
              onChange={change2FA(record.userId, index)}
            />
            {!record.sms2FaPhoneVerified && (
              <Tooltip title={"User haven't added an verified phone number"}>
                <InfoCircleOutlined className={"text-red-500"} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Last Login",
      dataIndex: "lastLogin",
      key: "lastLogin",
      width: "fit-content",
      render: (val: string) => {
        return convertDateToFormat(val, "MMM Do YYYY, hh:mm A", false);
      },
    },
    {
      title: "Failed Login",
      dataIndex: "failedLoginCount",
      key: "failedLoginCount",
      width: "15%",
      render: (val: string, record) => {
        return (
          <>
            <span key={"span"} className="mr-4">
              {val ?? "0"}
            </span>
            <Button
              key={"reset"}
              icon={<UndoOutlined />}
              onClick={() => onReset(record.email)}
              className={PRIMARY_BUTTON_STYLE}
            >
              RESET
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Drawer
        bodyStyle={{ padding: 0 }}
        title={
          <div>
            {companyName}&nbsp;Deal Team Members&nbsp;
            <Tag color={"blue"} icon={<UserOutlined />}>
              {info.length}
            </Tag>
          </div>
        }
        visible={true}
        width={"90%"}
        onClose={onClose}
        footer={[
          <Button
            className={PRIMARY_BUTTON_STYLE}
            key={"close"}
            onClick={onClose}
          >
            Close
          </Button>,
        ]}
      >
        <Table
          tableLayout={"auto"}
          dataSource={info.sort((a, b) => (a.email > b.email ? 1 : -1))}
          pagination={false}
          sticky={true}
          columns={columns}
          rowKey="id"
          scroll={{ x: 1000 }}
        />
      </Drawer>

      {passwordEmail && (
        <ChangePasswordModal
          key="ChangePasswordModal"
          email={passwordEmail}
          onClose={handleCancelPassword}
        />
      )}
    </>
  );
};

type ViewTeamModalType = {
  onClose?: voidType;
  info: UserType[];
  onSave: (
    emailId: string,
    type: PETransactionCreationType,
    status: boolean | undefined
  ) => void;
  onReset: (emailId: string) => void;
  change2FA: (userId: string, index: number) => (val: boolean) => void;
  companyName?: string;
  hasOnlineTransaction?: boolean;
  hasOfflineTransaction?: boolean;
};
