import React, { FC, useState, useEffect } from "react";
import { DatePicker, Input, Select } from "antd";
import {
  AnalyticsCurrencyType,
  PortfolioInputType,
} from "../../../utils/enums";
import { PortfolioSectionFieldType } from "../../../utils/types";
import { CaretDownOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import countries from "../../../assets/countryList.json";

export const PortfolioInputs: FC<PortfolioInputsPropType> = ({
  value,
  disabled,
  onUpdate,
}: PortfolioInputsPropType) => {
  const [updated, setUpdated] = useState(false);
  const [field, setField] = useState<PortfolioSectionFieldType | undefined>(
    undefined
  );

  const onChange = (field: PortfolioSectionFieldType) => {
    setUpdated(true);
    setField(field);
  };
  const onMouseLeaveOrOnBlur: onMouseLeaveType = () => {
    if (field) {
      if (updated) {
        onUpdate(field);
        setUpdated(false);
      }
    }
  };

  useEffect(() => {
    if (value) {
      setField(value);
    }
  }, [value]);

  if (field) {
    switch (PortfolioInputType[field.sectionFieldType]) {
      case PortfolioInputType.TEXT:
        return (
          <Input
            onBlur={onMouseLeaveOrOnBlur}
            onMouseLeave={onMouseLeaveOrOnBlur}
            className={`${disabled && "text-black pointer-events-none"}`}
            placeholder={disabled ? "-" : "Add Information"}
            bordered={false}
            onChange={(e): void => {
              onChange({ ...field, answer: e.target.value });
            }}
            defaultValue={field.answer ?? undefined}
            suffix={
              <i
                className={`fa fa-pencil-alt text-transparent ${
                  !disabled &&
                  "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
                }`}
              />
            }
          />
        );
      case PortfolioInputType.NUMERIC:
        return (
          <Input
            onBlur={onMouseLeaveOrOnBlur}
            onMouseLeave={onMouseLeaveOrOnBlur}
            className={`${disabled && "text-black pointer-events-none"}`}
            type={"number"}
            placeholder={disabled ? "-" : "Add Information"}
            bordered={false}
            onChange={(e): void => {
              onChange({ ...field, answer: e.target.value });
            }}
            defaultValue={field.answer ?? undefined}
            suffix={
              <i
                className={`fa fa-pencil-alt text-transparent ${
                  !disabled &&
                  "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
                }`}
              />
            }
          />
        );
      case PortfolioInputType.DATE:
        return (
          <DatePicker
            onBlur={onMouseLeaveOrOnBlur}
            onMouseLeave={onMouseLeaveOrOnBlur}
            className={`w-full ${disabled && "text-black pointer-events-none"}`}
            placeholder={disabled ? "-" : "MM/DD/YYYY"}
            format={"MM/DD/YYYY"}
            bordered={false}
            onChange={(e): void => {
              onChange({
                ...field,
                answer: e ? Date.parse(e?.format()).toString() : undefined,
              });
            }}
            defaultValue={
              field.answer ? moment(Number(field.answer)).utc() : undefined
            }
            suffixIcon={
              <i
                className={`far fa-calendar text-transparent ${
                  !disabled &&
                  "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
                }`}
              />
            }
          />
        );
      case PortfolioInputType.DROP_BOX:
        return (
          <Select
            onBlur={onMouseLeaveOrOnBlur}
            onMouseLeave={onMouseLeaveOrOnBlur}
            notFoundContent={"No Options Available"}
            className={`w-full ${disabled && "text-black pointer-events-none"}`}
            bordered={false}
            placeholder={disabled ? "-" : "Add Information"}
            suffixIcon={
              <CaretDownOutlined
                className={`text-transparent ${
                  !disabled &&
                  "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
                }`}
              />
            }
            defaultValue={
              field.selectedAnswerIds ? field.selectedAnswerIds[0] : undefined
            }
            onChange={(value: string): void => {
              onChange({
                ...field,
                selectedAnswerIds: [value],
              });
            }}
          >
            {field.options?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.optionValue}
              </Select.Option>
            ))}
          </Select>
        );
      case PortfolioInputType.CHECK_BOX:
        return (
          <Select
            onBlur={onMouseLeaveOrOnBlur}
            onMouseLeave={onMouseLeaveOrOnBlur}
            notFoundContent={"No Options Available"}
            className={`w-full ${disabled && "text-black pointer-events-none"}`}
            bordered={false}
            showArrow={true}
            maxTagCount={6}
            placeholder={disabled ? "-" : "Add Information"}
            mode={"multiple"}
            removeIcon={!disabled && <CloseOutlined />}
            suffixIcon={
              <CaretDownOutlined
                className={`text-transparent ${
                  !disabled &&
                  "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
                }`}
              />
            }
            defaultValue={field.selectedAnswerIds ?? []}
            onChange={(value: Array<string>): void => {
              onChange({
                ...field,
                selectedAnswerIds: value,
              });
            }}
          >
            {field.options?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.optionValue}
              </Select.Option>
            ))}
          </Select>
        );
      case PortfolioInputType.CURRENCY:
        return (
          <Select
            onBlur={onMouseLeaveOrOnBlur}
            onMouseLeave={onMouseLeaveOrOnBlur}
            className={`w-full ${disabled && "text-black pointer-events-none"}`}
            bordered={false}
            showArrow={true}
            onChange={(value): void => {
              onChange({
                ...field,
                answer: value,
              });
            }}
            showSearch={true}
            removeIcon={!disabled && <CloseOutlined />}
            defaultValue={field.answer}
            placeholder={disabled ? "-" : "Select Currency"}
            suffixIcon={
              <CaretDownOutlined
                className={`text-transparent ${
                  !disabled &&
                  "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
                }`}
              />
            }
            options={Object.values(AnalyticsCurrencyType).map((val) => ({
              label: val,
              value: val,
            }))}
          />
        );
      case PortfolioInputType.LONG_TEXT:
        return (
          <div className={"relative"}>
            <Input.TextArea
              onBlur={onMouseLeaveOrOnBlur}
              onMouseLeave={onMouseLeaveOrOnBlur}
              maxLength={255}
              className={`min-h-7 ${
                disabled && "text-black pointer-events-none"
              }`}
              placeholder={disabled ? "-" : "Add Information"}
              autoSize={{ minRows: 1, maxRows: 4 }}
              bordered={false}
              onChange={(e): void => {
                onChange({ ...field, answer: e.target.value });
              }}
              defaultValue={field.answer ?? undefined}
            />
            <i
              className={`absolute fa fa-pencil-alt text-transparent ${
                !disabled &&
                "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
              } right-0 bottom-0 mr-3 mb-2`}
            />
          </div>
        );
      case PortfolioInputType.COUNTRY:
        return (
          <Select
            onBlur={onMouseLeaveOrOnBlur}
            onMouseLeave={onMouseLeaveOrOnBlur}
            className={`w-full ${disabled && "text-black pointer-events-none"}`}
            bordered={false}
            showArrow={true}
            placeholder={disabled ? "-" : "Select Country"}
            showSearch={true}
            removeIcon={!disabled && <CloseOutlined />}
            suffixIcon={
              <CaretDownOutlined
                className={`text-transparent ${
                  !disabled &&
                  "group-hover:text-primary cursor-pointer group-focus-within:text-primary"
                }`}
              />
            }
            defaultValue={field.answer}
            onChange={(value): void => {
              onChange({
                ...field,
                answer: value,
              });
            }}
          >
            {countries
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((val) => (
                <Select.Option
                  title={val?.dial_code?.toString()}
                  key={val.code}
                  value={val.name}
                  country={val?.name}
                >
                  <div
                    className={
                      "flex flex-row w-full items-center gap-x-1 h-fit"
                    }
                  >
                    <img
                      alt={val.emoji}
                      src={val.image}
                      style={{ width: "18px", height: "18px" }}
                    />
                    <span className={"truncate"}>{val.name}</span>
                  </div>
                </Select.Option>
              ))}
          </Select>
        );
    }
  }
  return <></>;
};

type onMouseLeaveType = () => void;
type PortfolioInputsPropType = {
  value: PortfolioSectionFieldType;
  disabled: boolean;
  onUpdate: (a: PortfolioSectionFieldType) => void;
};
