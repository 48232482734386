import { find, isNil, path, propEq } from "ramda";
import {
  KeyValueType,
  PortfolioBidMetricType,
  PortfolioDealTeamAskType,
  UiDataType,
  VersionDataType,
  WebFormConfigType,
} from "./types";
import { DefaultColumnType } from "./newTermsheet";

export const DEFAULT_UI_DATA_TYPE: UiDataType = {
  text: "",
  unit: "TEXT",
  style: { background: "#ffffff" },
};

export const EMPTY_WEB_FORM_CONFIG = (position: string, elementId: string) => {
  return {
    id: "",
    position,
    dataMap: { [elementId]: DEFAULT_UI_DATA_TYPE },
    uiKey: DEFAULT_UI_DATA_TYPE,
  };
};

export const EMPTY_PORTFOLIO_DEAL_TEAM_ASK = (
  elementId: string
): PortfolioDealTeamAskType => ({
  id: "",
  styleData: DEFAULT_UI_DATA_TYPE,
  dealTeamAskData: "",
  portfolioBidMetricId: "",
});

export const EMPTY_PORTFOLIO_WEB_FORM_CONFIG = (
  position: string,
  elementId: string
): PortfolioBidMetricType => ({
  id: "",
  styleData: DEFAULT_UI_DATA_TYPE,
  position,
  bidMetricData: "string",
  portfolioWebformId: elementId,
  portfolioDealTeamAskDTO: EMPTY_PORTFOLIO_DEAL_TEAM_ASK(""),
});

export const MESSAGE_CONTENT = {
  saving: "Saving ...",
  publishing: "Publishing",
  switching: "Switching Template ...",
  saved: "Termsheet has been saved successfully!",
  published: "Termsheet has been published successfully!",
  switched: "Termsheet template has been switched successfully",
  saveError: "Unable to save the termsheet",
  publishError: "Unable to publish the termsheet",
  switchError: "Unable to switch termsheet template",
};

export const getMaxVersion: getMaxVersionType = (versionData = {}) => {
  return Math.max(...Object.keys(versionData).map((o) => parseInt(o)));
};
export const isUnpublished = propEq("published", false);

export const hasUnpublishedVersions = (versions: VersionDataType) => {
  return !isNil(find(isUnpublished)(Object.values(versions)));
};

export const updateDataMapByElementId = (
  config: WebFormConfigType,
  id: string,
  style: KeyValueType
) => {
  return {
    ...config,
    dataMap: {
      ...config.dataMap,
      [id]: {
        ...(path(["dataMap", id], config) as UiDataType),
        style,
      },
    },
  };
};

export const camelCase: camelCaseType = (s) =>
  s.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });

export const enforceFontSizeBounds: enforceFontSizeBoundsType = (obj) => {
  const currentSize = parseInt(obj.fontSize, 10);
  if (currentSize < 13) {
    return { ...obj, fontSize: `${13}px` };
  } else if (currentSize > 18) {
    return { ...obj, fontSize: `${18}px` };
  }
  return obj;
};

export const renameStyleProperties: renameStylePropertiesType = (obj = {}) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [camelCase(key)]: obj[key] },
    }),
    {}
  );

export const clearColumn: clearColumnType = (id, type, configs = []) => {
  switch (type) {
    case "BID_METRICS":
      return configs.map((o) => ({
        ...o,
        uiKey: DEFAULT_UI_DATA_TYPE,
        dataMap: { [id]: { ...o.dataMap[id], unit: "TEXT" } },
      }));
    case "DEAL_TEAM_ASK":
      return configs.map((o) => ({
        ...o,
        uiKey: { ...o.uiKey, unit: "TEXT" },
        dataMap: {
          ...o.dataMap,
          [id]: { ...o.dataMap[id], ...DEFAULT_UI_DATA_TYPE },
        },
      }));
    case "INSTITUTION": {
      return configs.map((o) => ({
        ...o,
        dataMap: {
          ...o.dataMap,
          [id]: { ...o.dataMap[id], ...DEFAULT_UI_DATA_TYPE },
        },
      }));
    }
  }
};

export const isDisagreementResponse: isAgreeOrDisagreeResponseType = (
  response
) => {
  const trimmedResponse = response.trim().toLowerCase();
  return trimmedResponse === "no";
};

export const isAgreementResponse: isAgreeOrDisagreeResponseType = (
  response
) => {
  const trimmedResponse = response?.trim().toLowerCase();
  return (
    trimmedResponse === "" ||
    trimmedResponse === "ok" ||
    trimmedResponse === "okay" ||
    trimmedResponse === "fine" ||
    trimmedResponse === "agreed" ||
    trimmedResponse === "agree"
  );
};

export const heatMapComparison = (s1: string, s2: string) =>
  s1?.trim() === s2?.trim() || isAgreementResponse(s1);

export type ColumnElementType = {
  id: string;
  label: string;
};

export type SelectedCellType = {
  rowId?: string;
  rowIndex: number;
  rowPosition: string;
  column: DefaultColumnType | string;
  style?: { [k: string]: string };
};

export type SelectedItemType = {
  type: "CELL" | "COLUMN" | "ROW";
  rowId?: string;
  rowIndex: number;
  rowPosition: string;
  column: DefaultColumnType | string;
  style?: { [k: string]: string };
};

export enum ItemType {
  CELL = "CELL",
  COLUMN = "COLUMN",
  ROW = "ROW",
}

export type RightClickActionType = "ADD_ABOVE" | "ADD_BELOW" | "DELETE";

type getMaxVersionType = (versionData?: VersionDataType) => number;

type enforceFontSizeBoundsType = (obj: { [k: string]: string }) => {
  [k: string]: string;
};
type renameStylePropertiesType = (obj: { [k: string]: string }) => {
  [k: string]: string;
};
type camelCaseType = (string: string) => string;
type isAgreeOrDisagreeResponseType = (response: string) => boolean;
type clearColumnType = (
  id: string,
  updateType: "BID_METRICS" | "DEAL_TEAM_ASK" | "INSTITUTION",
  configs?: Array<WebFormConfigType>
) => Array<WebFormConfigType>;
