import React, { FC } from "react";
import { PortfolioFinanceFieldValueType } from "../../../../utils/types";
import { InputNumber } from "antd";
import { formatTotal } from "../../../../utils/allocation";
import { QuarterType } from "../../../../utils/enums";
import { find, findIndex, pathOr, propEq } from "ramda";
import { insertItemInArray, updateItemInArray } from "../../../../utils/utils";
import {
  CURRENCY_UNIT_ABBREVIATION,
  FinancialsFieldType,
} from "../../../../utils/portfolio";

const getValue = (
  quarter: QuarterType,
  values: Array<PortfolioFinanceFieldValueType> = []
) => {
  const value = find<PortfolioFinanceFieldValueType>(
    propEq("quarter", quarter)
  )(values);
  return value?.value ?? "";
};
export const FinanceField: FC<FinanceFieldType> = ({
  year = "",
  type,
  unit = "",
  currency = "",
  editable,
  quarter,
  record,
  onSave,
}: FinanceFieldType) => {
  return (
    <div className={"flex flex-row items-center"}>
      {(currency?.length ?? 0) === 1 && (
        <div className={"text-muted"}>
          {type === "MONETARY" ? currency : ""}
        </div>
      )}
      <div className={"text-muted"}>
        {(currency?.length ?? 0) > 1 && (type === "MONETARY" ? currency : "")}
      </div>
      {!editable || record?.formula ? (
        <div className={"flex-grow px-[11px]"}>
          {getValue(quarter, record.values)}
        </div>
      ) : (
        <InputNumber
          className={"flex-grow"}
          value={getValue(quarter, record.values)}
          controls={false}
          bordered={false}
          stringMode={true}
          onChange={(value) => {
            const index = findIndex(propEq("quarter", quarter))(record.values);
            onSave({
              ...record,
              values:
                index >= 0
                  ? updateItemInArray(index, record.values, {
                      ...record.values[index],
                      value,
                    })
                  : insertItemInArray(
                      record?.values?.length ?? 0,
                      record?.values ?? [],
                      {
                        quarter,
                        value,
                        year,
                      }
                    ),
            });
          }}
          formatter={(val): string => `${val && formatTotal(val)}`}
        />
      )}
      <div className={"text-muted"}>
        {pathOr(unit, [unit], CURRENCY_UNIT_ABBREVIATION)}&nbsp;
      </div>
    </div>
  );
};

type FinanceFieldType = {
  year?: string;
  type: string;
  unit?: string;
  currency?: string;
  editable: boolean;
  quarter: QuarterType;
  record: FinancialsFieldType;
  onSave: (record: FinancialsFieldType) => void;
};
