import React, { FC } from "react";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { Avatar } from "antd";
import { JSONCodeBlock } from "../general/JSONCodeBlock";
import {
  EVENT_PAYLOAD_DESCRIPTION,
  SAMPLE_EVENT_PAYLOAD,
} from "../../utils/webhook-utils";

export const WebhooksOverview: FC = function () {
  usePageTitle("Webhooks Overview");

  return (
    <div
      className={
        "p-6 relative max-h-full w-full h-screen bg-gray-100 overflow-y-auto flex flex-col h-screen"
      }
    >
      <div className={"bg-white p-8 border border-gray-300 text-gray-700"}>
        <div className="text-lg font-bold mb-2">What are webhooks?</div>

        <p className="mb-6 text-base">
          Webhooks are a way for our system to notify your application when a
          certain event happens. Instead of constantly polling our API to check
          for updates, your application can register a webhook URL with us, and
          we will send a POST request to that URL whenever the event occurs.
        </p>

        <div className="text-lg font-bold mb-2">Subscribing to a webhook</div>

        <p className="mb-4 text-base">
          To subscribe to a webhook, you will need to provide us with a URL that
          can receive POST requests to which we will send the notifications to.
          This URL can either be publicly accessible{" "}
          <span className={"font-semibold"}>OR</span> you must provide us with
          authorization information.
        </p>
        <p className="mb-4 text-base">
          Please fill out the form available on this page to{" "}
          <a href="/company/webhooks" className="underline">
            subscribe
          </a>{" "}
          to a webhook. The form will ask you to provide the following
          information:
        </p>
        <div className={"flex flex-col gap-4 pl-4 text-base mb-4"}>
          <div className={"flex flex-row items-start gap-2"}>
            <div className={"min-w-[1.5rem]"}>
              <Avatar
                size={"small"}
                className={
                  "bg-gray-100 text-gray-700 border border-gray-400 mt-0.5"
                }
              >
                1
              </Avatar>
            </div>
            <div className={"flex-grow"}>
              <span className={"font-medium"}>URL:</span> This is the web
              address to which the webhook event will be sent. It must be
              capable of receiving POST requests in order for the event to be
              properly delivered. You can either provide a publicly accessible
              URL, or provide us with headers that include authorization
              information.
            </div>
          </div>
          <div className={"flex flex-row items-start gap-2"}>
            <div className={"min-w-[1.5rem]"}>
              {" "}
              <Avatar
                size={"small"}
                className={
                  "bg-gray-100 text-gray-700 border border-gray-400 mt-0.5"
                }
              >
                2
              </Avatar>
            </div>
            <div className={"flex-grow"}>
              <span className={"font-medium"}>Event Types:</span> Use this field
              to select the types of events you would like to receive. You can
              choose from a list of available event types.
            </div>
          </div>
          <div className={"flex flex-row items-start gap-2"}>
            <div className={"min-w-[1.5rem]"}>
              <Avatar
                size={"small"}
                className={
                  "bg-gray-100 text-gray-700 border border-gray-400 mt-0.5"
                }
              >
                3
              </Avatar>
            </div>
            <div className={"flex-grow"}>
              <span className={"font-medium"}>Headers:</span> If you need to
              include any headers in the webhook event, you can enter them here.
              Headers are used to provide additional information about the
              request, such as authorization or content type. Please note that
              headers <span className={"font-semibold"}>must</span> be in JSON
              format.
            </div>
          </div>
        </div>
        <p className="mb-4 text-base">
          Once you have filled out the form, click the &#34;Subscribe&#34;
          button to register your webhook.
        </p>
        <p className="mb-6 text-base">
          When subscribing to webhooks, it is important to understand that the
          subscriptions are set at the{" "}
          <span className={"font-semibold"}>company level</span>. This means
          that events triggered by any users within the company will be sent to
          the subscribed URL if there is an enabled subscription present for
          that specific event type. By subscribing at the company level, you
          ensure that your application receives notifications for relevant
          events across all users within your organization.
        </p>
        <div className="text-lg font-bold mb-2">Webhook payload</div>
        <p className="mb-4 text-base">
          When we send a webhook notification, we will include a JSON payload in
          the body of the POST request. The payload will contain information
          about the event that occurred.
        </p>
        <p className="mb-4 text-base">Here is an example payload:</p>
        <JSONCodeBlock json={SAMPLE_EVENT_PAYLOAD} />
        <div className="text-lg font-bold mt-6 mb-2">
          Payload object structure
        </div>
        <div className={"flex flex-col gap-4 pl-4 text-base mb-6"}>
          {EVENT_PAYLOAD_DESCRIPTION.map(
            ({ fieldName, description, type }, i) => {
              return (
                <div key={i} className={"flex flex-row items-start gap-2"}>
                  <div className={"min-w-[1.5rem]"}>
                    <Avatar
                      size={"small"}
                      className={
                        "bg-gray-100 text-gray-700 border border-gray-400 mt-0.5"
                      }
                    >
                      {i + 1}
                    </Avatar>
                  </div>
                  <div className={"flex-grow"}>
                    <span className={"font-medium"}>{fieldName}</span>
                    &nbsp;&nbsp;
                    <span
                      className={
                        "text-xs bg-gray-100 border border-gray-300 p-1"
                      }
                    >
                      {type}
                    </span>
                    &nbsp;&nbsp;{description}
                  </div>
                </div>
              );
            }
          )}
        </div>
        <div className="text-lg font-bold mb-2">
          Handling webhook notifications
        </div>
        <p className="mb-4 text-base">
          When your application receives a webhook notification from Termgrid,
          it should respond with a 2xx HTTP status code to acknowledge receipt
          of the notification. If your application returns a non-2xx status
          code, Termgrid will assume that the notification failed to deliver and
          will retry later.
        </p>
        <p className="mb-4 text-base">
          To ensure optimal performance, it is important to handle webhook
          notifications asynchronously and avoid blocking the main thread of
          your application. This can help prevent performance issues and ensure
          that your application remains responsive to user requests.
        </p>
        <p className="mb-6 text-base">
          It is also worth noting that Termgrid imposes a timeout of 30 seconds
          on webhook notifications. If your application takes too long to
          respond, the webhook notification will be considered failed. In such
          cases, Termgrid will only attempt to retry the notification if the
          maximum retry limit has not been reached.
        </p>
        <div className="text-lg font-bold mb-2">Retry flow</div>
        <p className="mb-6 text-base">
          In case the initial request fails, the retry flow allows for multiple
          attempts to be made to complete the request. The system will retry up
          to a maximum of <span className={"font-semibold"}>5</span> times, with
          each attempt spaced <span className={"font-semibold"}>20</span>{" "}
          minutes apart. If all attempts fail, please contact us to discuss
          alternative options for accessing the data.
        </p>
        <div className="text-lg font-bold mb-2">
          Unsubscribing from webhooks
        </div>
        <p className="mb-4 text-base">
          To stop receiving webhook notifications, you have two options: you can
          either disable the webhook, which will prevent any further
          notifications from being sent to that endpoint,{" "}
          <span className={"font-semibold"}>OR</span> you can delete the
          subscription entirely.
        </p>
      </div>
    </div>
  );
};
