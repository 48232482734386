import { KeyLabelType } from "./uiTypes";
import { AccessLevelType, ModuleType } from "./enums";
import { CompanyModuleAccessType, CompanyModuleType } from "./types";
import React, { ReactNode } from "react";
import { TGBadge } from "../components/general/TGBadge";

export const MODULES: KeyLabelType<ModuleType, ReactNode>[] = [
  { key: ModuleType.OFFLINE_TRANSACTION, label: "Create Offline Transaction" },
  { key: ModuleType.TRANSACTION, label: "Create Normal Transaction" },
  { key: ModuleType.DASHBOARD, label: "Dashboard" },
  { key: ModuleType.NOTIFICATIONS, label: "Notifications" },
  { key: ModuleType.COMMUNICATION, label: "Communications" },
  { key: ModuleType.TERM_SHEET, label: "Termsheet" },
  { key: ModuleType.ALLOCATION, label: "Allocation" },
  { key: ModuleType.FEES, label: "Fees" },
  { key: ModuleType.NOTES, label: "Notes" },
  { key: ModuleType.DATA_ROOM, label: "Data Room" },
  { key: ModuleType.TASK_LIST, label: "Task List" },
  { key: ModuleType.DEAL_TEAM, label: "Deal Team Management" },
  { key: ModuleType.ADD_INSTITUTIONS, label: "Add New Institutions" },
  { key: ModuleType.INVITE_LENDER_USERS, label: "Invite Lender Users" },
  { key: ModuleType.TRACKERS, label: "Trackers" },
  { key: ModuleType.INSIGHTS, label: "Insights" },
  { key: ModuleType.TAGS, label: "Tags" },
  { key: ModuleType.NDA, label: "Click Through" },
  { key: ModuleType.PRECEDENT_SEARCH, label: "Precedent Search" },
  { key: ModuleType.PORTFOLIO_MANAGEMENT, label: "Portfolio Management" },
  { key: ModuleType.CRM, label: "Institution Relationship Management" },
  {
    key: ModuleType.SPONSOR_COVERAGE,
    label: (
      <>
        Sponsor Coverage&nbsp;<TGBadge color={"danger"}>Pre-Alpha</TGBadge>
      </>
    ),
  },
  {
    key: ModuleType.LENDER_ENGAGEMENT,
    label: "Lender Engagement",
  },
  {
    key: ModuleType.DEAL_METRICS,
    label: "Deal Metrics",
  },
  {
    key: ModuleType.COMPLETIONS_MEMO,
    label: "Completion Memo",
  },
  { key: ModuleType.DEAL_CLOUD, label: "Deal Cloud" },
  { key: ModuleType.SSO, label: "Single Sign-On" },
  { key: ModuleType.WEBHOOK, label: "Web Hooks" },
  {
    key: ModuleType.API,
    label: (
      <>
        APIs&nbsp;<TGBadge color={"secondary"}>Future</TGBadge>
      </>
    ),
  },
  {
    key: ModuleType.SALESFORCE,
    label: (
      <>
        Salesforce&nbsp;<TGBadge color={"secondary"}>Future</TGBadge>
      </>
    ),
  },
  {
    key: ModuleType.OUTLOOK_INTEGRATION,
    label: (
      <>
        Outlook Integration&nbsp;<TGBadge color={"secondary"}>Future</TGBadge>
      </>
    ),
  },
  { key: ModuleType.PERMISSIONS, label: "Company User Permissions" },
  { key: ModuleType.SECURITY, label: "Security" },
];
export const DEFAULT_COMPANY_MODULE_CONFIG: CompanyModuleType = {
  [ModuleType.COMPLETIONS_MEMO]: false,
  [ModuleType.DEAL_METRICS]: false,
  [ModuleType.OFFLINE_TRANSACTION]: true,
  [ModuleType.TRANSACTION]: false,
  [ModuleType.DASHBOARD]: true,
  [ModuleType.NOTIFICATIONS]: false,
  [ModuleType.COMMUNICATION]: true,
  [ModuleType.TERM_SHEET]: false,
  [ModuleType.ALLOCATION]: false,
  [ModuleType.FEES]: false,
  [ModuleType.NOTES]: true,
  [ModuleType.DATA_ROOM]: true,
  [ModuleType.TASK_LIST]: true,
  [ModuleType.LENDER_ENGAGEMENT]: true,
  [ModuleType.DEAL_TEAM]: true,
  [ModuleType.ADD_INSTITUTIONS]: true,
  [ModuleType.INVITE_LENDER_USERS]: true,
  [ModuleType.TRACKERS]: true,
  [ModuleType.INSIGHTS]: true,
  [ModuleType.TAGS]: true,
  [ModuleType.NDA]: false,
  [ModuleType.PRECEDENT_SEARCH]: false,
  [ModuleType.PORTFOLIO_MANAGEMENT]: false,
  [ModuleType.CRM]: false,
  [ModuleType.DEAL_CLOUD]: false,
  [ModuleType.SSO]: false,
  [ModuleType.WEBHOOK]: false,
  [ModuleType.API]: false,
  [ModuleType.SALESFORCE]: false,
  [ModuleType.OUTLOOK_INTEGRATION]: false,
  [ModuleType.PERMISSIONS]: true,
  [ModuleType.SECURITY]: false,
  [ModuleType.SPONSOR_COVERAGE]: false,
  [ModuleType.DEAL_EXECUTION]: false,
  [ModuleType.LEAKAGE_ANALYSIS]: false,
};

export const DEFAULT_COMPANY_MODULE_PREFERENCE: CompanyModuleAccessType = {
  accessLevel: AccessLevelType.FREEMIUM,
  companyId: "",
  moduleAccessDTO: DEFAULT_COMPANY_MODULE_CONFIG,
};
