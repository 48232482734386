import React, { FC } from "react";
import { CommentType } from "../../../../utils/types";
import { Empty } from "antd";
import { ElementType, PermissionType } from "../../../../utils/enums";
import { Comment } from "./Comment";

export const CommentsList: FC<CommentsListType> = function ({
  permissions,
  conversations,
  team,
  uploadConversation,
  togglePriority,
  loading,
  setRead,
}: CommentsListType) {
  return (
    <div
      className={`relative h-full flex-grow px-2 ${
        loading.loading ? "overflow-hidden" : "overflow-y-auto"
      } my-1`}
    >
      {conversations.length > 0 ? (
        conversations.map((conversation) => (
          <Comment
            key={conversation.commentId}
            permissions={permissions}
            conversation={conversation}
            team={team}
            uploadConversation={uploadConversation}
            togglePriority={togglePriority}
            setRead={setRead}
          />
        ))
      ) : (
        <span className={"flex flex-row h-full flex-grow"}>
          <span className={"m-auto text-lg text-gray-400"}>
            <Empty description={"No Conversations Yet!"} />
          </span>
        </span>
      )}
    </div>
  );
};

type CommentsListType = {
  conversations: CommentType[];
  team: Record<string, string>;
  uploadConversation?: (
    message: string,
    root: null | string,
    elementId: string,
    elementType: ElementType.PETRANSACTION | ElementType.LENDER,
    commentType: "TEXT"
  ) => void;
  togglePriority: (commentId: string) => void;
  permissions: Array<PermissionType>;
  loading: { loading: boolean; label?: string };
  setRead: (
    elementId: string,
    type: ElementType.PETRANSACTION | ElementType.LENDER
  ) => void;
};
