import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import CompanyBuildingIcon from "../../images/CompanyBuildingIcon.svg";
import {
  addCompanyProfile,
  getCompanyById,
  getGeographies,
  getIndustries,
  updateCompanyProfile,
} from "../../services/services";
import { Button, Form, Input, message, Select } from "antd";
import { UserContext } from "../../context/UserContext";
import {
  CompanyType,
  GeographyType,
  IndustryType,
  UserType,
} from "../../utils/types";
import { ResponseType, voidType } from "../../utils/uiTypes";
import {
  filterOption,
  filterSort,
  getOptionsByKey,
  populateArrayWithPropertyPath,
  valOrDefault,
} from "../../utils/utils";
import { RightOutlined } from "@ant-design/icons";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { CustomSpin } from "../general/CustomSpin";

export const CompanyProfileForm: FC<CompanyProfileFormType> = ({
  initialSetup,
  onSubmit,
}: CompanyProfileFormType) => {
  usePageTitle("Company Profile");

  const { user, setUser } = useContext(UserContext);
  const [form] = Form.useForm();

  const [company, setCompanyProfile] = useState<CompanyType | null>();
  const [industries, setIndustries] = useState<IndustryType[]>([]);
  const [geographies, setGeographies] = useState<GeographyType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const getCompanyProfile: getCompanyType = (companyId) => {
    getCompanyById({
      segments: {
        companyId,
      },
      params: { isLite: false },
    })
      .then(({ data }: ResponseType<CompanyType>) => {
        setCompanyProfile(data);
        form.setFieldsValue({
          name: data?.name,
          geographies: populateArrayWithPropertyPath(["id"], data?.geographies),
          industries: populateArrayWithPropertyPath(["id"], data?.industries),
        });
      })
      .then(() => setLoading(false));
  };

  const getIndustryOptions: voidType = () => {
    getIndustries({}).then(({ data = [] }: ResponseType<IndustryType[]>) => {
      setIndustries(data);
    });
  };

  const getGeographyOptions: voidType = () => {
    getGeographies({}).then(({ data = [] }: ResponseType<GeographyType[]>) => {
      setGeographies(data);
    });
  };

  const getDataForSave: getDataForSaveType = (data, company) => {
    return {
      ...company,
      ...data,
      ...{
        geographies: getOptionsByKey(data.geographies ?? [], geographies, "id"),
        industries: getOptionsByKey(data.industries ?? [], industries, "id"),
      },
    };
  };

  const addCompany: companyType = (values, company, initialSetup) => {
    setSubmitting(true);
    addCompanyProfile({
      body: JSON.stringify(getDataForSave(values, company)),
    })
      .then(({ data }: ResponseType<CompanyType>) => {
        setCompanyProfile(data);
        if (initialSetup && data.id) {
          setUser({
            ...user,
            companyDTO: data,
            companyId: data.id,
            isAdmin: true,
          } as UserType);
          onSubmit({ companyData: data });
        }
        setSubmitting(false);
        message.success("Company Created Successfully", 1);
      })
      .catch((error: string) => {
        message.error(
          error ? error : "Something went wrong. Please try again."
        );
        setSubmitting(false);
      });
  };

  const updateCompany: companyType = (values, company, initialSetup) => {
    setSubmitting(true);
    updateCompanyProfile({
      body: JSON.stringify(getDataForSave(values, company)),
    })
      .then(({ data }: ResponseType<CompanyType>) => {
        setCompanyProfile(data);
        setSubmitting(false);
        if (initialSetup) {
          onSubmit({ companyData: data });
        }
        message.success("Company Updated Successfully", 1);
      })
      .catch((error: string) => {
        message.error(
          error ? error : "Something went wrong. Please try again."
        );
        setSubmitting(false);
      });
  };

  const onFormSubmit: companyType = (values, company, initialSetup) => {
    company?.id
      ? updateCompany(values, company, initialSetup)
      : addCompany(values, company, initialSetup);
  };

  const disableFields = (initialSetup: boolean, isAdmin: boolean): boolean =>
    initialSetup ? false : !isAdmin;

  useEffect(() => {
    getIndustryOptions();
    getGeographyOptions();
  }, []);

  useEffect(() => {
    if (user?.companyId) {
      getCompanyProfile(user.companyId);
    } else {
      setLoading(false);
    }
  }, [user?.companyId]);

  return (
    <div
      className={"relative max-h-full w-full h-full bg-gray-100 flex flex-col"}
    >
      <CustomSpin loading={loading} />
      <div className={"mb-5 flex flex-row items-center p-6 pb-0"}>
        <span className="text-2xl font-medium">Company setup</span>
      </div>
      <div className={"bg-white mx-6 mb-6 h-full h-full overflow-y-auto"}>
        <div className={"max-w-screen-md mx-auto"}>
          <div className={"pt-5"}>
            <img
              src={CompanyBuildingIcon}
              className={"h-20 mx-auto"}
              alt={"Company Profile"}
            />
          </div>
          <p
            className={
              "max-w-xs text-center text-gray-400 font-light mx-auto py-2"
            }
          >
            Create your company profile, add team members and create grid
            templates
          </p>
          <hr />
          {
            <Form
              layout={"vertical"}
              onFinish={(values): void => {
                onFormSubmit(values, valOrDefault({}, company), initialSetup);
              }}
              form={form}
              className={"flex flex-col gap-y-3 h-full"}
            >
              <Form.Item
                className={"m-0 my-2 border-0"}
                label={"Company"}
                name={"name"}
                rules={[
                  {
                    required: true,
                    message: `Enter company name`,
                  },
                ]}
              >
                <Input
                  disabled={disableFields(initialSetup, user?.isAdmin ?? false)}
                  placeholder={"Company Name"}
                  autoFocus={true}
                />
              </Form.Item>
              <Form.Item
                className={"m-0 my-2 border-0"}
                name={"industries"}
                label={"Industries"}
              >
                <Select
                  notFoundContent={"No Industries Available"}
                  getPopupContainer={(trigger): HTMLElement =>
                    trigger.parentElement
                  }
                  disabled={disableFields(initialSetup, user?.isAdmin ?? false)}
                  showSearch
                  mode={"multiple"}
                  maxTagCount={"responsive"}
                  placeholder={"Select Industries"}
                  showArrow={true}
                  dropdownRender={(menu): ReactElement => {
                    return <div className={"p-0 m-0"}>{menu}</div>;
                  }}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  filterSort={filterSort}
                >
                  {industries &&
                    industries.map((o) => {
                      return (
                        <Select.Option key={o.id} value={o.id}>
                          {o.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                className={"m-0 my-2 border-0"}
                name={"geographies"}
                label={"Geography (Select all that apply)"}
                rules={[
                  {
                    required: true,
                    message: `Select at least one geography`,
                  },
                ]}
              >
                <Select
                  notFoundContent={"No Geographies Available"}
                  disabled={disableFields(initialSetup, user?.isAdmin ?? false)}
                  getPopupContainer={(trigger): HTMLElement =>
                    trigger.parentElement
                  }
                  mode={"multiple"}
                  maxTagCount={"responsive"}
                  placeholder={"Select at least one geography"}
                  showArrow={true}
                  dropdownRender={(menu): ReactElement => {
                    return <div className={"p-0 m-0"}>{menu}</div>;
                  }}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  filterSort={filterSort}
                >
                  {geographies &&
                    geographies.map((o) => {
                      return (
                        <Select.Option key={o.id} value={o.id}>
                          {o.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              {!disableFields(initialSetup, user?.isAdmin ?? false) && (
                <Button
                  type={"primary"}
                  loading={submitting}
                  className={
                    "mt-auto ml-auto bg-primary hover:bg-hover text-white w-32"
                  }
                  onClick={(): void => {
                    form.submit();
                  }}
                >
                  {initialSetup ? "Next" : company?.id ? "Update" : "Create"}
                  {initialSetup && <RightOutlined />}
                </Button>
              )}
            </Form>
          }
        </div>
      </div>
    </div>
  );
};

type CompanyProfileFormType = {
  initialSetup: boolean;
  onSubmit: (o: { companyData: CompanyType }) => void;
};
type getDataForSaveType = (
  data: CompanyType,
  company: CompanyType
) => CompanyType;
type companyType = (
  values: CompanyType,
  company: CompanyType,
  initialSetup: boolean
) => void;
type getCompanyType = (companyId: string) => void;
