import React, { FC, useEffect, useState } from "react";
import { message, Tag } from "antd";
import { curry, filter, path } from "ramda";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { usePageTitle } from "../../../customHooks/usePageTitle";
import { LoadingType, ResponseType, voidType } from "../../../utils/uiTypes";
import {
  AllocationKeyType,
  AnalyticsConfigType,
  AnalyticsType,
  InstitutionType,
} from "../../../utils/types";
import {
  getAllocationKeys,
  getAnalyticsDashboard,
  getInstitutions,
} from "../../../services/services";
import { CustomSpin } from "../../general/CustomSpin";
import { PMAnalyticsFilters } from "./PMAnalyticsFilters";
import {
  AllocationTableType,
  AnalyticsCurrencyType,
} from "../../../utils/enums";
import { AnalyticsSection } from "../../analytics/AnalyticsSection";
import moment from "moment";
import { getParams } from "../../../utils/url-params";

/**
 * Analytics Component
 * @constructor
 */

const FILTERED_KEYS = ["Role", "Demand"];
const getKeyName = ({ keyName }: AllocationKeyType) => keyName;
const filterByKey = curry(
  (value: AllocationTableType, list: Array<AllocationKeyType>) =>
    filter(
      ({ keyName, allocationTableType }) =>
        !FILTERED_KEYS.includes(keyName) && allocationTableType === value,
      list
    )
);
const showLessData = (v: string[]) =>
  v.length > 4
    ? `${v.slice(0, 4).join(", ")}, ${v.length - 4} more`
    : v.join(", ");

const FILTER_KEY_NAMES = {
  funds: (v: string[]) => `Funds: ${showLessData(v)}`,
  facilityTypes: (v: string[]) => `Facilities: ${showLessData(v)}`,
  status: (v: string[]) => `Statuses: ${showLessData(v)}`,
  fxRateTargetCurrency: (v: AnalyticsCurrencyType) => `Currency: ${v}`,
  fxRateDate: (v: string) =>
    `FX Rate (as of): ${moment(v).format("YYYY-MM-DD")}`,
};

const TAG_COLORS = ["blue", "purple", "magenta", "green", "gold"];

export const PMAnalytics: FC = () => {
  usePageTitle("Insights");
  const history = useHistory();
  const location = useLocation();
  const [config, setConfig] = useState<AnalyticsConfigType | null>(null);
  const [loading, setLoading] = useState<LoadingType>({
    loading: false,
    label: "",
  });
  const [filters, setFilters] = useState<FiltersType | null>(null);
  const [allocationKeys, setAllocationKeys] = useState<AllocationKeyType[]>([]);
  const [institutions, setInstitutions] = useState<InstitutionType[]>([]);

  const parseParams = (filters: FiltersType): string => {
    return new URLSearchParams([
      [
        "fxRateTargetCurrency",
        filters.fxRateTargetCurrency ?? AnalyticsCurrencyType.USD,
      ],
      ["fxRateDate", filters.fxRateDate ?? moment().format("YYYY-MM-DD")],
      ...(filters.funds ?? []).map((val) => ["funds", val]),
      ...(filters.facilityTypes ?? []).map((val) => ["facilityTypes", val]),
      ...(filters.status ?? []).map((val) => ["status", val]),
    ]).toString();
  };

  const setDefaultValues = (
    keys: AllocationKeyType[],
    currency?: string | null,
    date?: string | null,
    funds?: Array<string>,
    facilities?: Array<string>,
    status?: Array<string>
  ) => {
    setFilters({
      fxRateTargetCurrency:
        (currency as AnalyticsCurrencyType) ?? AnalyticsCurrencyType.USD,
      fxRateDate: date ?? moment().format("YYYY-MM-DD"),
      funds:
        funds && funds?.length > 0
          ? funds
          : filterByKey(AllocationTableType.FUND, keys).map(getKeyName),
      facilityTypes:
        facilities && facilities?.length > 0
          ? facilities
          : filterByKey(AllocationTableType.FINAL_HOLD, keys).map(getKeyName),
      status: status && status?.length > 0 ? status : ["Active", "Past"],
    });
  };

  /**
   * Fetch Institutions from Database
   */
  const fetchCompanies: voidType = () => {
    getInstitutions({
      params: { isLite: true },
    })
      .then(({ data = [] }: ResponseType<InstitutionType[]>) => {
        setInstitutions(data);
      })
      .catch((error: string) => {
        message.error(error ?? "Error Loading Industries");
      });
  };

  /**
   * Fetch Allocation Keys from Database and set default filters
   */
  const fetchAllocationKeys: voidType = () => {
    getAllocationKeys({
      params: { isLite: true },
    })
      .then(({ data = [] }: ResponseType<AllocationKeyType[]>) => {
        const params = new URLSearchParams(location.search);
        setAllocationKeys(
          data.filter(
            ({ allocationTableType }) =>
              allocationTableType === AllocationTableType.FUND ||
              allocationTableType === AllocationTableType.FINAL_HOLD
          )
        );
        setDefaultValues(
          data,
          params.get("fxRateTargetCurrency"),
          params.get("fxRateDate"),
          params.getAll("funds"),
          params.getAll("facilityTypes"),
          params.getAll("status")
        );
      })
      .catch((error: string) => {
        message.error(error ?? "Error Loading Industries");
      });
  };

  /**
   * Fetch Analytics Dashboard from Database
   */
  const fetchAnalyticsDashboard: voidType = () => {
    setLoading({ loading: true, label: "Loading Analytics" });
    getAnalyticsDashboard({
      params: {
        name: "PORTFOLIO MANAGEMENT",
      },
    })
      .then(({ data }: ResponseType<AnalyticsType>) => {
        setConfig(JSON.parse(data.config)?.sections[0] ?? null);
      })
      .then(() => {
        setLoading({ loading: false });
      })
      .catch((error: string) => {
        setLoading({ loading: false });
        message.error(error ?? "Error Loading Analytics");
      });
  };

  /**
   * Fetch Required datasets on load
   */
  useEffect(() => {
    fetchAnalyticsDashboard();
    fetchAllocationKeys();
    fetchCompanies();
  }, []);

  /**
   * Parse the query params from url and update filters
   */
  useEffect(() => {
    const params = new URLSearchParams(
      filters ? parseParams(filters) : ""
    ).toString();
    history.push({
      pathname: "/portfolio/insights",
      search: params,
    });
  }, [filters]);

  return (
    <div
      className={"relative max-h-full w-full h-full bg-gray-100 flex flex-col"}
    >
      <CustomSpin
        loading={loading.loading}
        loadingText={loading.label}
        transparent={false}
      />
      <div className={"sticky z-[1000] p-4 py-1"}>
        <PMAnalyticsFilters
          filters={filters}
          allocationKeys={allocationKeys}
          onChange={setFilters}
          onReset={() => {
            setDefaultValues(allocationKeys);
          }}
        />
        <div className={"flex flex-row gap-1 flex-wrap px-2 py-2"}>
          {filters &&
            Object.entries(filters).map(([key, value], i) => (
              <Tag key={key} color={TAG_COLORS[i]}>
                {path<any>([key], FILTER_KEY_NAMES)(value)}
              </Tag>
            ))}
        </div>
      </div>
      <div
        className={`w-full max-h-full overflow-y-scroll bg-gray-100 p-4 pt-0 pb-2 flex-col h-full"`}
      >
        {!loading.loading && filters && (
          <AnalyticsSection
            isActive={true}
            config={config}
            params={getParams(filters)}
            filterOptions={{ INSTITUTIONS: institutions }}
          />
        )}
      </div>
    </div>
  );
};
type FiltersType = {
  funds?: string[];
  facilityTypes?: string[];
  status?: string[];
  fxRateTargetCurrency?: AnalyticsCurrencyType;
  fxRateDate?: string;
};
