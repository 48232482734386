import React, { FC, ReactNode } from "react";
import { Avatar, Tooltip } from "antd";
import { getColorPallet } from "../../utils/colors";

export const CustomAvatar: FC<CustomAvatarType> = ({
  data = "",
  color,
  size = "default",
  label,
  outlined = false,
  className = "font-light",
}: CustomAvatarType) => {
  return (
    <Tooltip title={label} placement={"bottom"}>
      <Avatar
        size={size}
        className={`select-none cursor-default mx-1 items-center inline-flex uppercase ${
          outlined ? "bg-transparent" : getColorPallet(color, "bg")
        } 
      ${outlined ? getColorPallet(color, "text") : "text-white"} 
      ${outlined ? getColorPallet(color, "border") : "border-0"} ${className}`}
      >
        {data}
      </Avatar>
    </Tooltip>
  );
};

type CustomAvatarType = {
  data: ReactNode;
  color: string;
  size?: "default" | "small" | "large";
  label?: ReactNode;
  outlined?: boolean;
  className?: string;
};
