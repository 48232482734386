import React, { FC, ReactNode, useEffect, useState } from "react";
import {
  createNewPortfolioTermsheet,
  deleteTemplateById,
  getPortfolioTermsheets,
  renamePortfolioTermsheet,
} from "../../../../services/services";
import { Button, Form, Input, message, Modal, Space } from "antd";
import { ResponseType } from "../../../../utils/uiTypes";
import { convertDateToFormat } from "../../../../utils/moment";
import {
  ACTION_BUTTON_CSS,
  PRIMARY_BUTTON_STYLE,
} from "../../../../utils/cssConfigs";
import { tableColumnHeader } from "../../../../utils/componentUtils";
import { ConfirmDelete } from "../../../../utils/confirmationModals";
import { PortfolioTermType, UserType } from "../../../../utils/types";
import { ColumnsType } from "antd/es/table";
import { useHistory, useParams } from "react-router";
import { PlusOutlined } from "@ant-design/icons";
import { WebFormCard } from "../../../termsheet/common/WebFormCard";
import { updateItemInArray } from "../../../../utils/utils";
import { PortfolioAdminPermissionBlock } from "../../PortfolioAdminPermissionBlock";
import { useUser } from "../../../../hooks/useUser";
import { ElementType, PermissionType } from "../../../../utils/enums";

export const PortfolioKeyTermsList: FC = () => {
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const history = useHistory();
  const { user } = useUser();
  const [templates, setTemplates] = useState<PortfolioTermType[]>([]);
  const [loading, setLoading] = useState(true);
  const [createModal, setCreateModal] = useState(false);
  const [selectedWebForm, setSelectedWebForm] =
    useState<EditWebFormType | null>(null);

  const fetchTemplates = (id: string): void => {
    getPortfolioTermsheets({
      segments: { id },
    })
      .then(({ data = [] }: ResponseType<PortfolioTermType[]>) => {
        setTemplates(data);
        setLoading(false);
      })
      .catch((error: string) => {
        setLoading(false);
        message.error(error ? error : "Error Fetching Templates!");
      });
  };

  const onDelete: onDeleteType = (templateId, companyId) => {
    deleteTemplateById({
      segments: {
        companyId,
        templateId: templateId,
      },
    })
      .then(({ data }: ResponseType<PortfolioTermType[]>) => {
        setTemplates(data);
        message.success("Template has been deleted successfully!");
      })
      .catch((error: string) => {
        message.error(error ? error : "Error deleting Template!");
      });
  };

  const createTemplate = (values: { name: string }): void => {
    setLoading(true);
    createNewPortfolioTermsheet({
      body: JSON.stringify({ name: values.name, portfolioId }),
    })
      .then(({ data }: ResponseType<PortfolioTermType>) => {
        setTemplates([...templates, data]);
        history.push(`/portfolio/${portfolioId}/terms/${data.id}`);
        setCreateModal(false);
        setLoading(false);
      })
      .catch((error: string) => {
        message.error(error);
        console.error(error);
        setLoading(false);
      });
  };

  const columns: ColumnsType<PortfolioTermType> = [
    {
      title: tableColumnHeader("Templates"),
      dataIndex: "name",
      key: "name",
      width: "22%",
      className: "group-hover:bg-blue-50",
    },
    {
      title: tableColumnHeader("Created Date"),
      dataIndex: "createDate",
      align: "left",
      key: "createDate",
      width: "13%",
      className: "group-hover:bg-blue-50",
      render: function parseDate(createDate: string): ReactNode {
        return <div>{convertDateToFormat(createDate, "D.MMM.YY", false)}</div>;
      },
      sorter: {
        compare: (a: PortfolioTermType, b: PortfolioTermType): number => {
          return parseInt(a.createDate) - parseInt(b.createDate);
        },
      },
    },
    {
      title: tableColumnHeader("Created By"),
      dataIndex: "createdByUserDTO",
      key: "createdByUserDTO",
      align: "left",
      width: "13%",
      className: "group-hover:bg-blue-50",
      render: function createdBy(createdByUser: UserType): ReactNode {
        return `${createdByUser?.firstName || ""} ${
          createdByUser?.lastName || ""
        }`;
      },
    },
    {
      title: tableColumnHeader("Actions"),
      dataIndex: "operation",
      key: "operation",
      align: "right",
      width: "4%",
      className: "group-hover:bg-blue-50",
      render: function (_: string, record: PortfolioTermType): ReactNode {
        return (
          <span
            className={
              "relative flex flex-row flex-row-reverse items-center gap-x-3"
            }
            onClick={(event): void => event.stopPropagation()}
          >
            <Button
              type={"text"}
              icon={<i className="fas fa-ellipsis-h" />}
              className={
                "absolute right-0 border-0 flex items-center px-2 text-gray-900 block opacity-50 group-hover:opacity-0"
              }
            />
            <Button
              className={`${ACTION_BUTTON_CSS} hover:text-red-500 opacity-0 group-hover:opacity-100`}
              icon={<i className="fas fa-trash-alt" />}
              onClick={(): void => {
                ConfirmDelete(
                  "Confirm Delete Template?",
                  () => {
                    onDelete(record.id, portfolioId);
                  },
                  `Template Name: ${record.name}`
                );
              }}
            />
            <Button
              onClick={(): void => {
                history.push(`/portfolio/${portfolioId}/terms/${record.id}`);
              }}
              type={"link"}
              icon={<i className="fas fa-pencil-alt" />}
              className={`${ACTION_BUTTON_CSS} hover:text-primary opacity-0 group-hover:opacity-100`}
            />
          </span>
        );
      },
    },
  ];

  const renameWebform = (
    id: string,
    portfolioId: string,
    name: string,
    index: number
  ): void => {
    setLoading(true);
    renamePortfolioTermsheet({
      body: JSON.stringify({ id, portfolioId, name }),
    })
      .then(() => {
        setTemplates((templates) =>
          updateItemInArray(index, templates, { ...templates[index], name })
        );
        setSelectedWebForm(null);
        setLoading(false);
      })
      .catch((error: string) => {
        console.error(error);
        message.error("Unable to update terms.");
        setLoading(false);
      });
  };

  const permissions =
    user?.elementPermissions?.find(
      (item) => item?.elementType === ElementType.PORTFOLIO
    )?.permissions ?? [];

  useEffect(() => {
    fetchTemplates(portfolioId);
  }, [portfolioId]);

  return (
    <div
      className={
        "relative max-h-full w-full h-full bg-gray-100 overflow-y-auto flex flex-col"
      }
    >
      <div className={"p-6 flex flex-row flex-wrap gap-2"}>
        {templates.map(({ id, name = "", createDate, creatorName }, index) => {
          return (
            <WebFormCard
              key={id}
              name={name}
              isAdmin={permissions.includes(PermissionType.ADMIN_PORTFOLIO)}
              path={`/portfolio/${portfolioId}/terms/${id}`}
              creator={creatorName}
              onEdit={(): void => {
                setSelectedWebForm({ name, portfolioId, id, index });
              }}
              createDate={createDate}
              onSelect={() => {
                history.push(`/portfolio/${portfolioId}/terms/${id}`);
              }}
            />
          );
        })}
        <PortfolioAdminPermissionBlock permissions={permissions}>
          <div
            onClick={() => setCreateModal(true)}
            role={"button"}
            className={
              "group h-36 w-48 m-1 bg-white border border-gray-300 shadow-sm hover:border-blue-400"
            }
          >
            <div className={"pt-10 text-center"}>
              <PlusOutlined
                className={"text-3xl text-gray-400 group-hover:text-blue-400"}
              />
              <div
                className={"text-xs text-gray-400 group-hover:text-blue-400"}
              >
                New Key Terms
              </div>
            </div>
          </div>
        </PortfolioAdminPermissionBlock>
      </div>

      <Modal
        open={createModal}
        title={"Create New Key Term"}
        onCancel={(): void => setCreateModal(false)}
        destroyOnClose={true}
        footer={false}
        confirmLoading={loading}
      >
        <Form onFinish={createTemplate}>
          <Form.Item name={"name"} label={"Name"}>
            <Input disabled={loading} />
          </Form.Item>
          <div className={"flex flex-row items-center justify-end gap-2"}>
            <Button
              loading={loading}
              className={`${PRIMARY_BUTTON_STYLE}`}
              htmlType={"submit"}
            >
              Create
            </Button>
            <Button
              disabled={loading}
              onClick={(): void => setCreateModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal>
      {selectedWebForm && (
        <Modal
          width={400}
          destroyOnClose={true}
          open={true}
          confirmLoading={loading}
          onCancel={() => {
            setSelectedWebForm(null);
          }}
          title={
            <span className={"text-xs text-secondary"}>Rename Termsheet</span>
          }
          closable={true}
          footer={
            <Space className={"flex justify-end"}>
              <Button
                className={"w-20"}
                type={"default"}
                onClick={() => {
                  setSelectedWebForm(null);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  renameWebform(
                    selectedWebForm.id,
                    portfolioId,
                    selectedWebForm.name,
                    selectedWebForm.index
                  );
                }}
                type={"primary"}
                className={"bg-primary hover:bg-hover text-white w-20"}
              >
                Save
              </Button>
            </Space>
          }
        >
          <div className={"pb-4"}>
            Please enter a new name for the termsheet
          </div>
          <Input
            value={selectedWebForm?.name}
            onChange={(e): void => {
              setSelectedWebForm(
                (webForm) => webForm && { ...webForm, name: e.target.value }
              );
            }}
          />
        </Modal>
      )}
    </div>
  );
};

type onDeleteType = (templateId: string, companyId: string) => void;
type EditWebFormType = {
  index: number;
  id: string;
  name: string;
  portfolioId: string;
};
