import { join } from "ramda";

export const getKeyByType: getKeyByType = (
  identifier,
  type,
  typeIdentifier
) => {
  if (type === "BID_METRIC") return join("-", [identifier, type.toString()]);
  else if (type === "ASK") return join("-", [identifier, type.toString()]);
  else if (type === "RESPONSE")
    return join("-", [identifier, type.toString(), typeIdentifier ?? ""]);
  else if (type === "KEYWORD_MATCH")
    return join("-", [identifier, type.toString(), typeIdentifier ?? ""]);
  else return "";
};

type getKeyByType = (
  identifier: string,
  type: "BID_METRIC" | "ASK" | "RESPONSE" | "KEYWORD_MATCH",
  typeIdentifier?: string
) => string;
