import React, { FC, useContext, useEffect, useState } from "react";
import { Badge, Empty, Input, message, Table } from "antd";
import { CrmDealActivity } from "../../utils/types";
import {
  append,
  filter,
  isEmpty,
  or,
  propEq,
  uniq,
  without,
  length,
} from "ramda";
import {
  dealActivityColumns,
  fetchWithIdAndParamsType,
  getModule,
  isCRM,
  ParamsType,
} from "../../utils/relationship";
import { filterListOfObjectsByStringValue } from "../../utils/utils";
import { transactionTagsArray } from "../../utils/transaction";
import { PETransactionTagType } from "../../utils/enums";
import { getAllDealActivity } from "../../services/services";
import { ResponseType } from "../../utils/uiTypes";
import { useParams } from "react-router";
import { MultipleSelect } from "../general/MultipleSelect";
import { DollarOutlined } from "@ant-design/icons";
import { StatisticValue } from "../general/StatisticValue";
import { useLocation } from "react-router-dom";
import { RelationshipCard } from "./RelationshipCard";
import { UserContext } from "../../context/UserContext";

const filterDealsByStatus = (
  status: PETransactionTagType,
  data: CrmDealActivity[]
) => filter(propEq("peTransactionTagType", status), data);

export const DealActivity: FC = () => {
  const { id } = useParams<ParamsType>();
  const { search } = useLocation();
  const { user } = useContext(UserContext);
  const [searchValue, setSearchValue] = useState("");
  const [dealActivity, setDealActivity] = useState<CrmDealActivity[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedTypes, setSelectedTypes] = useState<PETransactionTagType[]>(
    []
  );

  const fetchDealActivity: fetchWithIdAndParamsType = (id, params) => {
    setLoading(true);

    getAllDealActivity({ segments: { id }, params })
      .then(({ data }: ResponseType<CrmDealActivity[]>) => {
        setLoading(false);
        setDealActivity(data);
      })
      .catch((err: string): void => {
        message.error(
          err ?? "Something went wrong, unable to fetch deal activities.",
          3
        );
        setLoading(false);
      });
  };

  const updateList: updateListType = (data, value, callback) => () => {
    if (data.includes(value)) {
      callback(without([value], data));
    } else {
      callback(uniq(append(value, data)));
    }
  };

  useEffect(() => {
    fetchDealActivity(id, search);
  }, [id, search]);

  return (
    <RelationshipCard
      label={"Deal Activities"}
      actions={
        <div className={"grid grid-cols-2 gap-x-2"}>
          <Input
            placeholder={"Search"}
            allowClear={true}
            className={"max-w-xs"}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <MultipleSelect
            options={transactionTagsArray.map(({ value, label, color }) => ({
              value: value,
              label: (
                <span className={"flex flex-row"}>
                  {color && <Badge offset={[0, 0]} dot color={color} />}
                  &nbsp;&nbsp;
                  {label}
                </span>
              ),
            }))}
            value={selectedTypes}
            placeholder={"Select Statuses"}
            label={"Status"}
            className={"max-w-xs"}
            onChange={(value) =>
              setSelectedTypes(value as PETransactionTagType[])
            }
          />
        </div>
      }
    >
      <div className={"flex flex-row items-center"}>
        <StatisticValue
          label={"Total Deals"}
          loading={loading}
          onClick={() => setSelectedTypes([])}
        >
          {dealActivity.length}
        </StatisticValue>
        {transactionTagsArray.map(({ value, label }) => (
          <StatisticValue
            key={value}
            label={label}
            loading={loading}
            onClick={updateList(selectedTypes, value, setSelectedTypes)}
            isActive={selectedTypes.includes(value)}
          >
            {length(filterDealsByStatus(value, dealActivity))}
          </StatisticValue>
        ))}
      </div>
      <div className={`relative h-full overflow-y-auto flex border-t`}>
        <Table<CrmDealActivity>
          locale={{
            emptyText: (
              <Empty
                image={
                  <DollarOutlined className={"text-8xl mt-1 text-gray-300"} />
                }
                className={"text-gray-400"}
                description={"No Activity"}
              />
            ),
          }}
          size="small"
          rowClassName={"group"}
          className={`w-full`}
          sticky={true}
          dataSource={filterListOfObjectsByStringValue(
            searchValue,
            dealActivity
          ).filter(({ peTransactionTagType }) =>
            or(
              isEmpty(selectedTypes),
              peTransactionTagType &&
                selectedTypes.includes(peTransactionTagType)
            )
          )}
          columns={dealActivityColumns(isCRM(user), false, true)}
          loading={loading}
          rowKey={({ peTransactionId }) => peTransactionId}
          pagination={false}
          scroll={{
            x: true,
          }}
        />
      </div>
    </RelationshipCard>
  );
};

type updateListType = <T>(
  data: T[],
  value: T,
  callback: (data: T[]) => void
) => () => void;
