import React, { FC, useEffect, useState } from "react";
import { Alert, Button, Checkbox, Form, Input, Modal, Spin } from "antd";
import { useHistory, useParams } from "react-router";
import { usePageTitle } from "../customHooks/usePageTitle";
import TermgridLogo from "../images/logo/TermgridLogoLight.svg";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Terms } from "../static/Terms";
import {
  getCompanyById,
  getCompanyByIdAndHash,
  joinTeam,
} from "../services/services";
import { ResponseType, voidType } from "../utils/uiTypes";
import { CompanyType } from "../utils/types";
import { getMinPassword } from "../utils/password-criteria";

const PASSWORD_CRITERIA = (min: number | undefined = 8) => [
  {
    valid: false,
    regex: new RegExp("^.{" + min + ",}$"),
    text: `Must be at least ${min} characters.`,
  },
  {
    valid: false,
    regex: /^.*\d.*$/,
    text: "Must contain at least 1 Number.",
  },
  {
    valid: false,
    regex: /^.*[A-Z].*$/,
    text: "Must contain at least 1 Letter Upper Case.",
  },
  {
    valid: false,
    regex: /^.*[a-z].*$/,
    text: "Must contain at least 1 Letter Lower Case.",
  },
  {
    valid: false,
    regex: /^.*[\W_].*$/,
    text: "Must contain at least 1 Special Character.",
  },
];

export const JoinTeam: FC = function () {
  usePageTitle("Join Team", false);
  const { hash, companyId }: ParamsType = useParams();

  const history = useHistory();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [company, setCompany] = useState<CompanyType>();
  const [passwordUpdated, setPasswordUpdated] = useState<boolean>(false);
  const [passwordCriteria, setPasswordCriteria] = useState<criteriaType[]>([]);

  const onCreatePassword: onResetPasswordType = (formValues) => {
    const { confirmPassword, newPassword } = formValues;

    if (newPassword !== confirmPassword) {
      setError("Passwords don't match");
    } else {
      setError(() => null);
      setLoading(true);
      joinTeam({
        segments: { hash, companyId },
        body: JSON.stringify({ confirmPassword, newPassword }),
      })
        .then(() => {
          setLoading(false);
          setPasswordUpdated(true);
        })
        .catch((error: string) => {
          setError(() => error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (companyId && hash)
      getCompanyByIdAndHash({
        segments: {
          companyId,
          hash,
        },
      })
        .then(({ data }: ResponseType<CompanyType>) => {
          setCompany(data);
        })
        .then(() => {
          setLoading(false);
        });
  }, [companyId]);

  // get company config for min password
  useEffect(() => {
    getMinPassword(hash, onSuccess, onFailure, companyId);
  }, [hash, companyId]);

  const onSuccess: onSuccessType = (data: string) => {
    setPasswordCriteria(PASSWORD_CRITERIA(Number(data)));
    setLoading(false);
  };

  const onFailure: voidType = () => {
    setLoading(false);
  };

  return (
    <div
      style={{
        backgroundImage: "url('../../../images/hero.jpg')",
      }}
      className={
        "h-screen w-screen bg-cover bg-center overflow-y-auto hide-scrollbar"
      }
    >
      <img
        className={"mx-auto pt-5 w-48"}
        src={TermgridLogo}
        alt={"Termgrid Logo"}
      />
      <div className={"px-5 mx-auto"}>
        <div className={"bg-white border mx-auto mt-16 sm:mt-24 p-10 max-w-lg"}>
          <div>
            <div className={"text-2xl font-medium"}>
              Join {company?.name} Team
            </div>
            <div className={"text-sm font-normal mt-2 mb-8"}>
              Enter new password here
            </div>
            <Form
              onFinish={(values): void => {
                onCreatePassword(values);
              }}
            >
              <Form.Item
                name="newPassword"
                className={"mb-0"}
                rules={[{ required: true, message: "Please enter password." }]}
              >
                <Input.Password
                  placeholder={"Password"}
                  type={"password"}
                  onChange={(e): void =>
                    setPasswordCriteria((list) =>
                      list.map((criteria) => ({
                        ...criteria,
                        valid: criteria.regex.test(e.target.value),
                      }))
                    )
                  }
                />
              </Form.Item>
              <div className={"mt-2 mb-6"}>
                {passwordCriteria.map((item) => (
                  <p
                    key={item.text}
                    className={`${
                      item.valid ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {item.valid ? (
                      <CheckCircleOutlined />
                    ) : (
                      <CloseCircleOutlined />
                    )}{" "}
                    {item.text}
                  </p>
                ))}
              </div>
              <Form.Item
                name="confirmPassword"
                rules={[
                  { required: true, message: "Please confirm your password!" },
                ]}
              >
                <Input.Password
                  placeholder={"Confirm Password"}
                  type={"password"}
                />
              </Form.Item>
              <Form.Item
                name={"terms"}
                valuePropName={"checked"}
                rules={[
                  {
                    validator: (_, value): Promise<void> =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error("Must accept agreement")),
                  },
                ]}
              >
                <Checkbox>
                  I have read and agree to the{" "}
                  <Button
                    type={"link"}
                    className={"px-0 hover:border-b"}
                    onClick={(): void => {
                      Modal.info({
                        width: 900,
                        title: (
                          <>
                            <p className={"text-xl my-0"}>Terms of Service</p>
                            <span className="text-sm text-gray-500">
                              Effective Date: April 2022
                            </span>
                          </>
                        ),
                        content: <Terms />,
                        okText: "Close",
                        okButtonProps: {
                          className: "bg-primary hover:bg-hover text-white",
                        },
                      });
                    }}
                  >
                    terms of service
                  </Button>
                  .
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <>
                  {passwordUpdated && (
                    <Alert
                      className={"mb-4"}
                      message={
                        <p className={"text-green-500"}>
                          Account Created Successfully.{" "}
                          <a
                            onClick={(): void => history.push("/login")}
                            className={"text-primary"}
                          >
                            Click here for Login.
                          </a>
                        </p>
                      }
                      type="success"
                    />
                  )}
                  {error && (
                    <Alert className={"mb-4"} message={error} type="error" />
                  )}
                  <Button
                    className={
                      "w-32 bg-primary hover:bg-hover text-white border-0"
                    }
                    disabled={
                      passwordUpdated ||
                      loading ||
                      passwordCriteria.filter((item) => !item.valid).length > 0
                    }
                    htmlType={"submit"}
                  >
                    {loading && <Spin className={"mr-2"} size={"small"} />}
                    Join
                  </Button>
                </>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

type ParamsType = {
  hash: string;
  companyId: string;
};
type onResetPasswordType = (formValues: {
  confirmPassword: string;
  newPassword: string;
}) => void;
type criteriaType = {
  valid: boolean;
  regex: RegExp;
  text: string;
};
type onSuccessType = (data: string) => void;
