import React, { createContext, FC, ReactNode, useState } from "react";
import {
  ElementObjectType,
  LenderType,
  WebFormRedLineType,
} from "../utils/types";
import { PETransactionCreatorType } from "../utils/enums";
import { ComparisonSheet } from "../components/termsheet/comparsion-sheet/ComparisonSheet";

export const TermsheetContext = createContext<TermsheetContextType>({
  transactionId: "",
  webFormId: "",
  isAdmin: false,
  isNonAdmin: false,
  isInstitution: false,
  element: null,
  isOffline: false,
  institutions: [],
  transactionType: PETransactionCreatorType.BORROWER,
  columns: null,
  setColumns: () => {},
  onReloadVersions: (
    transactionId: string,
    webFormId: string,
    selectedVersion: string
  ) => {},
});

export const TermsheetProvider: FC<TermsheetProviderType> = function ({
  transactionId,
  webFormId,
  isAdmin,
  isNonAdmin,
  isInstitution,
  element,
  institutions,
  onReloadVersions,
  children,
  isOffline,
  transactionType,
}: TermsheetProviderType) {
  const [columns, setColumns] = useState<WebFormRedLineType | null>(null);

  return (
    <TermsheetContext.Provider
      value={{
        isOffline,
        transactionType,
        transactionId,
        webFormId,
        isAdmin,
        isNonAdmin,
        isInstitution,
        element,
        institutions,
        columns,
        setColumns,
        onReloadVersions,
      }}
    >
      {columns ? <ComparisonSheet value={columns} /> : children}
    </TermsheetContext.Provider>
  );
};

type TermsheetProviderType = {
  transactionId: string;
  webFormId: string;
  isAdmin: boolean;
  isNonAdmin: boolean;
  isInstitution: boolean;
  isOffline: boolean;
  element: ElementObjectType;
  institutions: Array<LenderType>;
  transactionType: PETransactionCreatorType;
  onReloadVersions: (
    transactionId: string,
    webFormId: string,
    selectedVersion: string
  ) => void;
  children: ReactNode;
};
type TermsheetContextType = {
  transactionId: string;
  webFormId: string;
  isAdmin: boolean;
  isNonAdmin: boolean;
  isInstitution: boolean;
  isOffline: boolean;
  element: ElementObjectType | null;
  institutions: Array<LenderType>;
  transactionType: PETransactionCreatorType;
  columns: WebFormRedLineType | null;
  setColumns: (v: WebFormRedLineType | null) => void;
  onReloadVersions: (
    transactionId: string,
    webFormId: string,
    selectedVersion: string
  ) => void;
};
