import React, { FC, useContext, useState } from "react";
import { Button, Form, Input, message, Modal, Spin } from "antd";
import { formItemRequiredRule } from "../../utils/formUtils";
import { createInstitution } from "../../services/services";
import { TransactionContext } from "../../context/TransactionContext";
import { CompanyType, LenderType } from "../../utils/types";
import { ResponseType } from "../../utils/uiTypes";
import { newInstitutionType } from "../transaction/menu/institutions/AddInstitutions";

export const CreateInstitutionModal: FC<NewTransactionModalType> = ({
  onClose,
  onCreate,
}) => {
  const { transaction } = useContext(TransactionContext);
  const [loading, setLoading] = useState<boolean>(false);

  const onCreateInstitution: newInstitutionType = (value) => {
    setLoading(true);
    createInstitution({
      body: JSON.stringify(value),
    })
      .then(({ data }: ResponseType<LenderType>) => {
        message.success("Added New Institution to the list");
        onCreate(data);
        setLoading(false);
        onClose();
      })
      .catch((error: string) => {
        message.error(error ? error : "Error adding new Institution!");
        setLoading(false);
      });
  };
  return (
    <Spin spinning={loading}>
      <Modal
        open={true}
        onCancel={(): void => onClose()}
        title={"Create New Institution"}
        closable={true}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form
          onFinish={(values): void => {
            onCreateInstitution(values, transaction?.lenderDTOs || []);
          }}
        >
          <Form.Item
            name={"name"}
            label={"Institution Name"}
            rules={[formItemRequiredRule]}
          >
            <Input placeholder={"Name"} />
          </Form.Item>
          <Form.Item className={"w-full"}>
            <Button
              loading={loading}
              className={"w-full bg-primary hover:bg-hover text-white border-0"}
              htmlType={"submit"}
            >
              Create Institution
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};
type NewTransactionModalType = {
  onClose: () => void;
  onCreate: (value: LenderType) => void;
};
