import React, { FC, useEffect, useState } from "react";
import { MultipleSelect } from "../../general/MultipleSelect";
import { AnalyticsFilters, InstitutionType } from "../../../utils/types";
import { Button } from "antd";
import { isEmpty } from "ramda";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";

export const InternalFilters: FC<InternalFiltersType> = ({
  filters,
  filterOptions,
  onChange,
}) => {
  const [localFilters, setLocalFilters] = useState<string[]>([]);

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);
  return (
    <div
      className={
        "flex flex-row gap-x-4 gap-y-2 items-center justify-between p-3"
      }
    >
      <div className={"w-64"}>
        <MultipleSelect
          value={localFilters}
          defaultValue={new URLSearchParams(location.search).getAll(
            "companies"
          )}
          placeholder={"Select Institutions"}
          onChange={(e): void => {
            setLocalFilters(e as string[]);
          }}
          label={"Institutions"}
          options={filterOptions.INSTITUTIONS.sort((a, b) =>
            a.name > b.name ? 1 : -1
          ).map(({ id, name }) => ({
            value: id,
            label: name,
            filterValue: name,
          }))}
        />
      </div>
      <div className={"flex flex-row gap-2"}>
        <Button
          disabled={isEmpty(localFilters)}
          onClick={(): void => {
            onChange(localFilters);
          }}
          className={PRIMARY_BUTTON_STYLE}
        >
          Apply Filters
        </Button>
        <Button
          disabled={isEmpty(filters)}
          onClick={(): void => {
            onChange([]);
            setLocalFilters([]);
          }}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

type InternalFiltersType = {
  filters: Array<string>;
  filterOptions: Record<AnalyticsFilters, Array<InstitutionType>>;
  onChange: (filters: Array<string>) => void;
};
