import { CopyToClipboard } from "./CopyToClipboard";
import { Tooltip } from "antd";
import { TextOverFlowHandle } from "./TextOverFlowHandle";
import React, { FC, ReactNode } from "react";

export const ViewEmail: ViewEmailType = (email) => {
  return (
    <CopyToClipboard value={email}>
      <Tooltip placement="topLeft" title={email}>
        <div className={"max-w-xs truncate w-fit"}>
          <TextOverFlowHandle text={email} />
        </div>
      </Tooltip>
    </CopyToClipboard>
  );
};

type ViewEmailType = (email: string) => ReactNode;
