import {
  CompanyEnumType,
  PermissionType,
  PortfolioEnumType,
  PortfolioSectionEnumType,
  UnitType,
} from "./enums";
import React, { ReactNode } from "react";
import {
  CompanyType,
  FinancialFieldDataType,
  PortfolioSectionType,
  PortfolioTableFieldType,
} from "./types";
import { PortfolioGeneralSection } from "../components/portfolio/portfolioView/portfolioCards/PortfolioGeneralSection";
import { KeyContacts } from "../components/portfolio/portfolioView/portfolioCards/KeyContacts";
import { MandatedAllocation } from "../components/portfolio/portfolioView/portfolioCards/MandatedAllocation";
import { PortfolioFees } from "../components/portfolio/portfolioView/portfolioCards/PortfolioFees";
import { Financials } from "../components/portfolio/portfolioView/portfolioCards/Financials";
import { PastTransactionsList } from "../components/portfolio/portfolioView/portfolioCards/pastTransactions/PastTransactionsList";
import { CapitalStructure } from "../components/portfolio/portfolioView/portfolioCards/CapitalStructure";
import { always, cond, equals, isEmpty, isNil, pathOr, T } from "ramda";
import { getPortfolioSection } from "../services/services";
import { ResponseType } from "./uiTypes";
import { Amortization } from "../components/portfolio/portfolioView/portfolioCards/Amortization";
import { Hedging } from "../components/portfolio/portfolioView/portfolioCards/Hedging";
import { MandatedAllocationFees } from "../components/portfolio/portfolioView/portfolioCards/MandatedAllocationFees";
import { Covenant } from "../components/portfolio/portfolioView/portfolioCards/Covenant";
import { UnderwritingTerms } from "../components/portfolio/portfolioView/portfolioCards/UnderwritingTerms";
import { KpiComponent } from "../components/transaction/menu/dashboard/Kpi";
import { Leakage } from "../components/transaction/menu/leakage/Leakage";
import { Allocation } from "../components/transaction/menu/allocation/Allocation";
import { Fees } from "../components/transaction/menu/Fees";

export const getPortfolioType = (
  companyType?: CompanyEnumType
): PortfolioEnumType =>
  equals(CompanyEnumType.LENDER, companyType)
    ? PortfolioEnumType.LENDER_PORTFOLIO
    : PortfolioEnumType.SPONSOR_PORTFOLIO;

export const CURRENCY_UNIT_ABBREVIATION = {
  THOUSAND: "K",
  MILLION: "M",
};

export const getUnit: getUnitType = (unit) => {
  return Object.keys(CURRENCY_UNIT_ABBREVIATION).includes(unit)
    ? pathOr("", [unit], CURRENCY_UNIT_ABBREVIATION)
    : unit;
};

// Pass financial year for Financials Component
export const initiateFetchSection = (
  sectionId: string,
  portfolioId: string,
  financialYear?: string | number,
  tableId?: string
): Promise<PortfolioSectionType> =>
  new Promise<PortfolioSectionType>((resolve, reject) =>
    !isNil(sectionId) && !isNaN(+sectionId)
      ? getPortfolioSection({
          segments: {
            sectionId,
            portfolioId,
          },
          params:
            financialYear && tableId
              ? { year: financialYear, tableId }
              : financialYear || tableId
              ? financialYear
                ? { year: financialYear }
                : { tableId }
              : {},
        })
          .then(({ data }: ResponseType) => {
            resolve(data);
          })
          .catch((e: string) => {
            reject(e ?? "Error Fetching Section");
          })
      : reject()
  );

export const getSection: getSectionType = (
  section,
  permission,
  companies,
  sectionList,
  portfolioId
) => {
  switch (section) {
    case PortfolioSectionEnumType.GENERAL_INFORMATION:
    case PortfolioSectionEnumType.CUSTOM:
      return (
        <PortfolioGeneralSection
          permissions={permission}
          portfolioId={portfolioId}
        />
      );
    case PortfolioSectionEnumType.COVENANT:
      return <Covenant permissions={permission} />;
    case PortfolioSectionEnumType.KEY_CONTACTS:
      return (
        <KeyContacts
          companies={companies}
          permissions={permission}
          portfolioId={portfolioId}
        />
      );
    case PortfolioSectionEnumType.ALLOCATION:
      return (
        <MandatedAllocationFees
          companies={companies}
          permissions={permission}
          feesId={
            sectionList.find((section) => section.sectionName === "Fees")?.id ??
            ""
          }
          portfolioId={portfolioId}
        />
      );
    case PortfolioSectionEnumType.FEES:
      return (
        <PortfolioFees
          companies={companies}
          permissions={permission}
          portfolioId={portfolioId}
        />
      );
    case PortfolioSectionEnumType.FINANCIALS:
      return <Financials permissions={permission} portfolioId={portfolioId} />;
    case PortfolioSectionEnumType.PAST_TRANSACTION:
      return (
        <PastTransactionsList
          permissions={permission}
          companies={companies}
          portfolioId={portfolioId}
        />
      );
    case PortfolioSectionEnumType.CAPITAL_STRUCTURE:
      return (
        <CapitalStructure permissions={permission} portfolioId={portfolioId} />
      );
    case PortfolioSectionEnumType.HEDGING:
      return <Hedging companies={companies} permissions={permission} />;
    case PortfolioSectionEnumType.AMORTIZATION:
      return (
        <Amortization permissions={permission} portfolioId={portfolioId} />
      );
    case PortfolioSectionEnumType.KPI:
      return <KpiComponent orientation={"vertical"} />;
    case PortfolioSectionEnumType.LEAKAGE_ANALYSIS:
      return <Leakage />;
    case PortfolioSectionEnumType.TRANSACTION_ALLOCATION:
      return <Allocation />;
    case PortfolioSectionEnumType.TRANSACTION_FEES:
      return <Fees />;
    default:
      return <></>;
    case PortfolioSectionEnumType.UNDERWRITING_TERMS:
      return <UnderwritingTerms permissions={permission} />;
  }
};

export const getSummaryData: getSummaryDataType = (summaryField) => {
  return {
    ltmrevenue: summaryField?.find((item) => item.tag === "ltmrevenue"),
    ltmadjebitda: summaryField?.find((item) => item.tag === "ltmadjebitda"),
    netdebt: summaryField?.find((item) => item.tag === "netdebt"),
    netleverageratio: summaryField?.find(
      (item) => item.tag === "netleverageratio"
    ),
    lastUpdated:
      summaryField && summaryField?.length > 0
        ? summaryField[0]?.quarter + " " + summaryField[0]?.year
        : "-",
  };
};

export const inputFieldTypes = [
  { label: "Text", value: "TEXT" },
  { label: "Long Text", value: "LONG_TEXT" },
  { label: "Numeric", value: "NUMERIC" },
  { label: "Single Select Dropdown", value: "DROP_BOX" },
  { label: "Multi Select Dropdown", value: "CHECK_BOX" },
  { label: "Date", value: "DATE" },
  { label: "Country", value: "COUNTRY" },
  { label: "Currency", value: "CURRENCY" },
];

export const UNIT_KEY_LABEL = [
  { key: "THOUSAND", label: "Thousand (K)" },
  { key: "MILLION", label: "Million (M)" },
];

export const defaultToNull = (v: any) => (isEmpty(v) ? undefined : v);

export const makeTree = (
  nodes: Array<PortfolioTableFieldType>,
  root: string | undefined = undefined
): any =>
  nodes
    .filter(({ rootFieldId }) => rootFieldId === root)
    .reduce(
      (tree: Array<PortfolioTableFieldType>, node: PortfolioTableFieldType) =>
        [
          ...tree,
          {
            ...node,
            nestedFields: defaultToNull(makeTree(nodes, node.id)),
          },
        ].sort((a, b) =>
          a?.rank.localeCompare(b?.rank, undefined, { numeric: true })
        ),
      []
    );

export type FinancialsType = {
  year: string;
  fields: Array<FinancialsFieldType>;
};
export type FinancialsFieldType = PortfolioTableFieldType & {
  affected?: boolean;
};

type getUnitType = (unit: string) => string;

type getSectionType = (
  section: PortfolioSectionEnumType,
  permissions: PermissionType[],
  companies: CompanyType[],
  sectionList: PortfolioSectionType[],
  portfolioId: string
) => ReactNode;
type getSummaryDataType = (
  summaryField: FinancialFieldDataType[] | undefined
) => ExtraFinancialFieldsType;

type ExtraFinancialFieldsType = {
  ltmrevenue?: FinancialFieldDataType;
  ltmadjebitda?: FinancialFieldDataType;
  netdebt?: FinancialFieldDataType;
  netleverageratio?: FinancialFieldDataType;
  lastUpdated?: string;
  unit?: UnitType;
};
