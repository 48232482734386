import React, { FC, useState } from "react";
import { Button, Form, Input, message, Modal, Tooltip } from "antd";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { voidType } from "../../utils/uiTypes";
import { setUserPassword } from "../../services/services";

export const ChangePasswordModal: FC<ChangePasswordModalType> = ({
  email,
  onClose,
}) => {
  const [password, setPassword] = useState<string>("");
  const onChange: onChangeType = (e) => {
    setPassword(e.target.value);
  };

  const updatePassword: updatePasswordType = (email, password) => {
    setUserPassword({
      segments: {
        emailId: email,
      },
      body: JSON.stringify({
        newPassword: password,
      }),
    })
      .then(() => {
        message.success("Password successfully updated");
        setPassword("");
        onClose();
      })
      .catch((error: string) => {
        message.error(error ?? "Try again later!");
      });
  };

  return (
    <>
      <Modal
        open={true}
        onCancel={onClose}
        title="Change Email Password"
        footer={[
          <Button
            key="submit"
            onClick={() => updatePassword(email, password)}
            className={PRIMARY_BUTTON_STYLE}
            disabled={password.length === 0}
          >
            Update
          </Button>,
        ]}
      >
        <Form name="basic" layout={"vertical"} className="flex justify-center">
          <Form.Item className="mr-4" label="User Email" name="email">
            <Tooltip placement="topLeft" title={String(email)}>
              <Input
                className="w-52 truncate ..."
                defaultValue={String(email)}
              />
            </Tooltip>
          </Form.Item>

          <Form.Item label="Set Password" name="password">
            <Input
              className="w-52 truncate ..."
              onChange={onChange}
              defaultValue={password}
              autoFocus
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

type ChangePasswordModalType = {
  email: string | null;
  onClose: voidType;
};

type onChangeType = (e: React.ChangeEvent<HTMLInputElement>) => void;

type updatePasswordType = (email: string | null, password: string) => void;
