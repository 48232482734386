import moment from "moment";

export const convertDateToFormat: convertDateToFormatType = (
  date,
  format,
  isUnixTimestamp = true
) => {
  const dateMoment = moment(isUnixTimestamp ? Number(date) : date);
  if (dateMoment.isValid()) {
    return dateMoment.format(format).toString();
  } else {
    return "";
  }
};

type DateFormatType =
  | "D.MMM.YY"
  | "hh:mm a"
  | "ll"
  | "LL"
  | "MMM Do YYYY, hh:mm A"
  | "DD.MMM.YYYY - HH:mma"
  | "DD MMM YYYY"
  | "DD MMMM YYYY"
  | "DD.MMM.YYYY - hh:mm A"
  | "MM/DD/YYYY";
type convertDateToFormatType = (
  date: string,
  format: DateFormatType,
  isUnixTimestamp?: boolean
) => string;
