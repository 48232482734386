import React, { FC, useEffect, useState } from "react";
import { Button, DatePicker, Dropdown, message, Select } from "antd";
import moment from "moment";
import { curry, equals, filter, omit } from "ramda";
import {
  MultipleSelect,
  MultiSelectOptionType,
} from "../../general/MultipleSelect";
import {
  ACTION_BUTTON_CSS,
  PRIMARY_BUTTON_STYLE,
} from "../../../utils/cssConfigs";
import { AllocationKeyType } from "../../../utils/types";
import {
  AllocationTableType,
  AnalyticsCurrencyType,
} from "../../../utils/enums";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadAmortization } from "../../../services/services";
import { FileResponseType } from "../../../utils/uiTypes";
import { ConfirmDownload } from "../../../utils/confirmationModals";

const STATUSES = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Past",
    label: "Past",
  },
];
const FILTERED_KEYS = ["Role", "Demand"];

const DEFAULT_ANALYTICS_FILTERS: FiltersType = {
  funds: [],
};

const mapkeys: mapKeysType = ({ keyName, id }) => ({
  value: keyName,
  label: keyName,
  filterValue: keyName,
});

const filterByKey = curry(
  (value: AllocationTableType, list: Array<AllocationKeyType>) =>
    filter(
      ({ keyName, allocationTableType }) =>
        !FILTERED_KEYS.includes(keyName) && allocationTableType === value,
      list
    )
);

/**
 * Filters Component for Analytics Component
 * @param filters - current filters in the Analytics/Parent Component
 * @param onChange - is for sending back the updated filters to the parent component
 * @param keys - is for getting the list of allocation Keys
 * @constructor
 * @returns FC
 * @type AnalyticsFiltersType
 */
export const PMAnalyticsFilters: FC<AnalyticsFiltersType> = ({
  filters,
  allocationKeys,
  onChange,
  onReset,
}) => {
  const [currentFilters, setCurrentFilters] = useState<FiltersType | null>(
    null
  );

  useEffect(() => {
    setCurrentFilters(filters);
  }, [filters]);

  return (
    <div className={"flex flex-wrap gap-x-4 gap-y-2 items-center p-1.5 pb-2"}>
      <span>Filter By: </span>
      <MultipleSelect
        value={currentFilters?.funds}
        className={"md:max-w-[50%] grow overflow-hidden max-h-[32px]"}
        placeholder={"Select Funds"}
        onChange={(e): void => {
          setCurrentFilters({ ...currentFilters, funds: e as string[] });
        }}
        label={"Funds"}
        options={filterByKey(AllocationTableType.FUND, allocationKeys).map(
          mapkeys
        )}
      />
      <MultipleSelect
        value={currentFilters?.facilityTypes}
        className={"md:max-w-[50%] grow overflow-hidden max-h-[32px]"}
        placeholder={"Select Facilities"}
        onChange={(e): void => {
          setCurrentFilters({
            ...currentFilters,
            facilityTypes: e as string[],
          });
        }}
        label={"Facilities"}
        options={filterByKey(
          AllocationTableType.FINAL_HOLD,
          allocationKeys
        ).map(mapkeys)}
      />
      <MultipleSelect
        value={currentFilters?.status}
        className={"md:max-w-[50%] grow overflow-hidden max-h-[32px]"}
        placeholder={"Select Statuses"}
        onChange={(e): void => {
          setCurrentFilters({ ...currentFilters, status: e as string[] });
        }}
        label={"Status"}
        options={STATUSES}
      />
      <div className={"flex flex-row items-center gap-2"}>
        <span>Currency:</span>
        <Select<AnalyticsCurrencyType>
          className={"w-32 overflow-hidden max-h-[32px]"}
          placeholder={"Choose Currency"}
          options={Object.values(AnalyticsCurrencyType).map((value) => ({
            label: AnalyticsCurrencyType[value],
            value,
          }))}
          value={currentFilters?.fxRateTargetCurrency}
          onChange={(value): void => {
            setCurrentFilters((filters) => ({
              ...filters,
              fxRateTargetCurrency: value,
            }));
          }}
        />
      </div>
      <div className={"flex flex-row items-center gap-2"}>
        <span>FX Rate (as of):</span>
        <DatePicker
          disabledDate={(currentDate) =>
            moment(currentDate).valueOf() > moment().valueOf()
          }
          className={"w-32 overflow-hidden max-h-[32px]"}
          value={moment(currentFilters?.fxRateDate)}
          format={"YYYY-MM-DD"}
          onChange={(value): void => {
            if (value) {
              setCurrentFilters({
                ...currentFilters,
                fxRateDate: value.format("YYYY-MM-DD").toString(),
              });
            } else {
              setCurrentFilters(omit(["fxRateDate"], currentFilters));
            }
          }}
        />
      </div>
      <Button
        disabled={
          filters && currentFilters
            ? equals(filters, currentFilters) ?? false
            : true
        }
        onClick={(): void => {
          if (currentFilters) {
            onChange(currentFilters);
          }
        }}
        className={PRIMARY_BUTTON_STYLE}
      >
        Apply Filters
      </Button>
      <Button
        disabled={equals(filters, DEFAULT_ANALYTICS_FILTERS)}
        onClick={onReset}
      >
        Reset Filters
      </Button>
      <Dropdown
        trigger={["click"]}
        menu={{
          items: [
            {
              key: 1,
              icon: <DownloadOutlined />,
              label: "Download Amortization Report",
              onClick: () =>
                downloadAmortization({})
                  .then(({ url, filename }: FileResponseType) => {
                    message.success({
                      key: "download",
                      content: `File ready to download`,
                    });
                    ConfirmDownload(filename, url);
                  })
                  .catch((error: any) => {
                    console.error(error);
                    message.error({
                      content: "Unable to download file",
                      key: "download",
                    });
                  }),
            },
          ],
        }}
      >
        <Button className={ACTION_BUTTON_CSS}>
          <DownloadOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

type mapKeysType = (
  allocationKeyType: AllocationKeyType
) => MultiSelectOptionType;
type FiltersType = {
  fxRateTargetCurrency?: AnalyticsCurrencyType;
  fxRateDate?: string;
  funds?: string[];
  status?: string[];
  facilityTypes?: string[];
};
type AnalyticsFiltersType = {
  allocationKeys: AllocationKeyType[];
  filters: FiltersType | null;
  onChange: (filters: FiltersType) => void;
  onReset: () => void;
};
