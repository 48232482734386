import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { usePageTitle } from "../../../customHooks/usePageTitle";
import { useHistory } from "react-router";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import { TransactionContext } from "../../../context/TransactionContext";
import {
  getAllocationTableKeys,
  getPrimaryList,
  getSecondaryList,
  TableDataType,
} from "../../../utils/allocation";
import {
  AllocationKeyType,
  AllocationType,
  CompanyType,
} from "../../../utils/types";
import {
  getAllCompanies,
  getAllocationsForTable,
  getAllocationTable,
} from "../../../services/services";
import {
  AllocationTableType,
  ElementType,
  PermissionType,
  PortfolioSectionEnumType,
} from "../../../utils/enums";
import { ResponseType } from "../../../utils/uiTypes";
import { anyPass, filter, isEmpty, pathOr, propEq } from "ramda";
import { Button, Empty, message } from "antd";
import { CustomSpin } from "../../general/CustomSpin";
import { AllocationTable } from "./allocation/AllocationTable";
import { AddAllocationModal } from "./allocation/AddAllocationModal";
import {
  FullScreenButton,
  FullScreenWidget,
} from "../../general/FullScreenWidget";

export const Fees: FC = () => {
  usePageTitle("Fees");
  const history = useHistory();
  const { height: windowHeight } = useWindowDimensions();

  const { transactionId, transaction, permissions } =
    useContext(TransactionContext);

  const [loading, setLoading] = useState(false);
  const [tableConfig, setTableConfig] = useState<TableDataType | null>(null);
  const [tableData, setTableData] = useState<AllocationType[]>([]);
  const [allocationTableKeys, setAllocationTableKeys] = useState<
    AllocationKeyType[]
  >([]);
  const [allCompanies, setAllCompanies] = useState<CompanyType[]>([]);

  const fetchAllocationKeys = (transactionId: string): void => {
    getAllocationTable({
      segments: {
        elementId: transactionId,
        allocationElementType: ElementType.PETRANSACTION,
      },
      params: { allocationType: AllocationTableType.FEES },
    })
      .then(({ data = [] }: ResponseType<TableDataType[]>) => {
        if (data.length > 0) {
          setTableConfig(data[0]);
          fetchAllocationTableData(transactionId, pathOr("", [0, "id"], data));
        }
      })
      .then(() => setLoading(false));
  };

  const fetchAllocationTableData: getAllocationTableDataType = (
    elementId,
    tableId
  ) => {
    getAllocationsForTable({
      segments: {
        elementId,
        tableId,
        allocationElementType: ElementType.PETRANSACTION,
      },
    })
      .then(({ data = [] }: ResponseType<AllocationType[]>) => {
        setTableData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        message.error("Unable to get Allocation Data");
      });
  };

  const initiateKeysData = (keys: AllocationKeyType[]): void => {
    setAllocationTableKeys(keys);
  };

  useEffect(() => {
    getAllCompanies({})
      .then(({ data = [] }: ResponseType<CompanyType[]>) => {
        setAllCompanies(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    getAllocationTableKeys(initiateKeysData);
  }, []);

  useEffect(() => {
    if (transactionId) {
      fetchAllocationKeys(transactionId);
    }
  }, [transactionId]);

  return (
    <FullScreenWidget>
      {(isFullScreen, trigger): ReactNode => (
        <div
          className={`${
            isFullScreen && "bg-gray-50 h-screen w-screen overflow-y-scroll"
          } h-full`}
        >
          {loading && (
            <div className={"w-full h-full absolute top-0 left-0"}>
              <CustomSpin loading={loading} />
            </div>
          )}
          {transaction?.lenderDTOs.length === 0 ? (
            <div
              className={
                "p-0 grid grid-cols-12 h-full max-h-full flex items-center"
              }
            >
              <Empty
                className={"col-span-12 text-gray-400"}
                description={"No Institutions History!"}
              >
                <Button
                  className={"bg-primary hover:bg-hover border-0 text-white"}
                  icon={<i className="fas fa-plus mr-1" />}
                  onClick={(): void => {
                    transaction &&
                      history.push(
                        `/transactions/${transaction.peTransactionId}/institutions`
                      );
                  }}
                >
                  Add New Institution
                </Button>
              </Empty>
            </div>
          ) : (
            <>
              <div className={"p-6"}>
                <AllocationTable
                  loading={
                    loading || isEmpty(tableConfig) || isEmpty(allCompanies)
                  }
                  extraOptions={
                    <FullScreenButton
                      isFullScreen={isFullScreen}
                      trigger={trigger}
                    />
                  }
                  hasDate={false}
                  title={"Fees"}
                  pageType={ElementType.PETRANSACTION}
                  companies={allCompanies}
                  editPermission={permissions.includes(
                    PermissionType.ADMIN_PETRANSACTION
                  )}
                  sectionType={PortfolioSectionEnumType.FEES}
                  peTransactionIdOrPortfolioId={transactionId ?? ""}
                  keys={filter(
                    anyPass([
                      propEq("allocationTableType", AllocationTableType.FEES),
                    ]),
                    allocationTableKeys
                  )}
                  config={tableConfig}
                  data={tableData}
                  toolbar={true}
                  tableHeight={windowHeight - 325}
                  institutionModal={(
                    onSubmit,
                    selectedCompanies,
                    disabled
                  ): ReactNode => (
                    <AddAllocationModal
                      disabled={disabled}
                      onSubmit={onSubmit}
                      companies={getPrimaryList(
                        selectedCompanies,
                        transaction?.lenderDTOs ?? []
                      )}
                      listSecondary={getSecondaryList(
                        selectedCompanies,
                        transaction?.lenderDTOs ?? [],
                        allCompanies
                      )}
                      onAddCompany={(company): void =>
                        setAllCompanies((companies) => [...companies, company])
                      }
                    />
                  )}
                >
                  {() => <div className={"w-full flex justify-between"}></div>}
                </AllocationTable>
              </div>
            </>
          )}
        </div>
      )}
    </FullScreenWidget>
  );
};

type getAllocationTableDataType = (elementId: string, tableId: string) => void;
