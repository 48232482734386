import React, { FC, ReactNode, useState } from "react";
import { Button, Modal } from "antd";
import {
  ACTION_BUTTON_CSS,
  PRIMARY_BUTTON_STYLE,
} from "../../../../utils/cssConfigs";
import { EyeOutlined } from "@ant-design/icons";
import { isEmpty, isNil } from "ramda";
import { PDFFileViewer } from "../../../general/PDFFileViewer";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";

export const ClickThroughDocument: FC<NDATextModalType> = ({
  fileId,
  configId,
  transactionId,
  buttonClassName,
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const { height } = useWindowDimensions();

  return (
    <>
      {!isEmpty(fileId) && !isNil(fileId) && (
        <Button
          className={
            buttonClassName ?? `hover:text-primary ${ACTION_BUTTON_CSS}`
          }
          onClick={(): void => {
            setOpen(true);
          }}
          icon={<EyeOutlined />}
        >
          {children}
        </Button>
      )}
      <Modal
        bodyStyle={{ padding: 4, height: height - 200 }}
        title={"Document Preview"}
        width={"80%"}
        okButtonProps={{ className: PRIMARY_BUTTON_STYLE }}
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
        destroyOnClose={true}
      >
        <PDFFileViewer
          loadingText={"Loading Document ..."}
          url={`${window.location.origin}/api/rest/media/${fileId}?ndaConfigId=${configId}&transactionId=${transactionId}`}
        />
      </Modal>
    </>
  );
};

type NDATextModalType = {
  fileId: string;
  configId: string;
  transactionId: string;
  children?: ReactNode;
  buttonClassName?: string;
};
