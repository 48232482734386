import React, { FC, ReactNode, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Calendar as CalendarComponent,
  Card,
  Col,
  Divider,
  List,
  message,
  Popover,
  Radio,
  Row,
  Select,
  Tooltip,
} from "antd";
import {
  LeftCircleFilled,
  LeftOutlined,
  RightCircleFilled,
  RightOutlined,
} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import moment, { Moment } from "moment";
import {
  getAllDeadlines,
  getTransactionsForCalendar,
  updatePETransactionColor,
} from "../services/services";
import { CalendarTransactionType, DeadLineType } from "../utils/types";
import { DeadlineEventModal } from "../components/modals/DeadlineEventModal";
import { CalendarPopover } from "../components/calendar/CalendarPopover";
import { ColorPicker } from "../components/general/ColorPicker";
import { getObjectFromPropertyValue, updateItemInArray } from "../utils/utils";
import { ResponseType, voidType } from "../utils/uiTypes";
import { CustomSpin } from "../components/general/CustomSpin";
import { usePageTitle } from "../customHooks/usePageTitle";
import { findIndex, propEq } from "ramda";

export const Calendar: FC = function () {
  usePageTitle("Calendar");

  const [deadLines, setDeadLines] = useState<Array<DeadLineType>>([]);
  const [displayTransaction, setDisplayTransaction] = useState<string | null>();
  const [showLegend, setShowLegend] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<
    Array<CalendarTransactionType>
  >([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [yearView, setYearView] = useState<boolean>(false);
  const [calendarDate, setCalendarDate] = useState<Moment>(moment());

  const showModal: voidType = () => setVisible(true);
  const handleCancel: voidType = () => setVisible(false);

  const getDeadlines: voidType = () => {
    getAllDeadlines({})
      .then(({ data = [] }: ResponseType<Array<DeadLineType>>) => {
        setShowLegend(true);
        setDeadLines(data);
      })
      .catch(() => message.error("Error getting deadlines!"))
      .then(() => setLoading(false));
  };
  const getDeadlinesByDate: getDeadlinesByDateType = (date) =>
    deadLines
      .filter((deadline) =>
        displayTransaction
          ? deadline.peTransactionId === displayTransaction
          : deadline
      )
      .filter(
        (deadline) =>
          moment(Number(deadline.targetDate)).format("L") === date.format("L")
      );
  const addDeadline = (deadline: DeadLineType): void =>
    setDeadLines((deadlines) => [...deadlines, deadline]);
  const deleteDeadline: deleteDeadlineType = (id) =>
    setDeadLines(deadLines.filter((deadline) => deadline.id !== id));
  const updateDeadline: updateDeadlineType = (deadline) => {
    const index = findIndex(propEq("id", deadline.id))(deadLines);
    if (index >= 0)
      setDeadLines((list) => updateItemInArray(index, list, deadline));
  };
  const getDeadlinesByMonth: getDeadlinesByDateType = (date) =>
    deadLines.filter(
      (dl) =>
        date.isSame(moment(Number(dl.targetDate)).format(), "month") &&
        date.isSame(moment(Number(dl.targetDate)).format(), "year")
    );
  const dateCellRender: dateCellRenderType = (value) => {
    const deadlinesOnDate = value.isSame(calendarDate, "month")
      ? getDeadlinesByDate(value)
      : [];
    return (
      <ul>
        {deadlinesOnDate.map((deadline) => (
          <li key={deadline.id}>
            <CalendarPopover
              deadline={deadline}
              editPermission={getObjectFromPropertyValue(
                "peTransactionId",
                deadline.peTransactionId,
                transactions
              )?.permissionDTO.permissions.includes("ADMIN_PETRANSACTION")}
              transaction={getObjectFromPropertyValue(
                "peTransactionId",
                deadline.peTransactionId,
                transactions
              )}
              onUpdate={updateDeadline}
              onCreate={addDeadline}
              onDelete={deleteDeadline}
            />
          </li>
        ))}
      </ul>
    );
  };
  const updateTXColor: updateTXColorType = (peTransactionId, color) => {
    updatePETransactionColor({
      body: JSON.stringify({
        peTransactionId,
        color,
      }),
    })
      .then(() => {
        const newDeadlines = deadLines.map((deadline) =>
          deadline.peTransactionId === peTransactionId
            ? { ...deadline, color }
            : deadline
        );
        const index = findIndex(propEq("peTransactionId", peTransactionId))(
          transactions
        );
        setDeadLines(newDeadlines);
        if (index >= 0)
          setTransactions((list) =>
            updateItemInArray(index, list, { ...list[index], color })
          );
      })
      .catch((error: string) => console.error(error));
  };

  const monthCellElement = (date: Moment): React.ReactNode => {
    return getTransactionsForMonth(date, deadLines).map((tx) => {
      return (
        <div key={tx.peTransactionId}>
          <Badge
            style={{
              backgroundColor: tx.color,
              borderColor: tx.color,
              display: "inline-block",
            }}
            key={tx.peTransactionId}
            text={
              <div
                className={
                  "whitespace-nowrap w-full text-ellipsis overflow-hidden inline-block leading-relaxed"
                }
              >
                <Tooltip title={tx.peTransactionTitle} placement="topLeft">
                  {tx.peTransactionTitle}
                </Tooltip>
              </div>
            }
            count={
              getDeadlinesByMonth(date).filter(
                (dl) => dl.peTransactionId === tx.peTransactionId
              ).length
            }
            className={"w-3/4 whitespace-nowrap"}
          />
        </div>
      );
    });
  };

  const getTransactionsForMonth = (
    date: Moment,
    deadlines: Array<DeadLineType>
  ): CalendarTransactionType[] => {
    const deadlinesInMonth = deadlines
      .filter(
        (dl) =>
          date.isSame(moment(Number(dl.targetDate)).format(), "month") &&
          date.isSame(moment(Number(dl.targetDate)).format(), "year")
      )
      .map((dl) => dl.peTransactionId);
    return transactions.filter((tx) =>
      deadlinesInMonth.includes(tx.peTransactionId)
    );
  };
  useEffect(() => getDeadlines(), []);
  useEffect(() => {
    setLoading(true);
    getTransactionsForCalendar({})
      .then(({ data = [] }: ResponseType<Array<CalendarTransactionType>>) =>
        setTransactions(data)
      )
      .catch(() => setLoading(false));
  }, []);
  return (
    <div
      className={
        "relative max-h-full w-full h-screen bg-gray-100 overflow-y-auto flex flex-col"
      }
    >
      <div className={"w-full h-full absolute top-0 left-0"}>
        <CustomSpin loading={loading} />
      </div>
      <div className={"p-6"} style={{ minWidth: "750px" }}>
        <Title level={3}>Calendar</Title>
        <div className={"md:flex"}>
          <Card>
            <CalendarComponent
              monthCellRender={monthCellElement}
              dateCellRender={dateCellRender}
              disabledDate={(value): boolean =>
                !value.isSame(calendarDate, "month")
              }
              headerRender={({
                value,
                type,
                onChange,
                onTypeChange,
              }): ReactNode => {
                const start = 0;
                const end = 12;
                const monthOptions = [];

                const current = value.clone();
                const localeData = value.localeData();
                const months = [];
                for (let i = 0; i < 12; i++) {
                  current.month(i);
                  months.push(localeData.monthsShort(current));
                }

                for (let index = start; index < end; index++) {
                  monthOptions.push(
                    <Select.Option
                      className="month-item"
                      key={`${index}`}
                      value={months[index]}
                    >
                      {months[index]}
                    </Select.Option>
                  );
                }
                const month = value.format("MMM");

                const year = value.year();
                const options = [];
                for (let i = 2000; i <= 2050; i += 1) {
                  options.push(
                    <Select.Option key={i} value={i} className="year-item">
                      {i}
                    </Select.Option>
                  );
                }
                return (
                  <div style={{ padding: 8 }}>
                    <Title className={"float-left"} level={4}>
                      {value.format("MMMM") + " " + year}
                      <LeftOutlined
                        className={"text-2xl cursor-pointer absolute"}
                        onClick={(): void => {
                          const newValue = value.clone();
                          newValue.subtract(1, "month").format("MMM");
                          onChange(newValue);
                          setCalendarDate(newValue);
                        }}
                      />
                      <RightOutlined
                        className={"text-2xl cursor-pointer ml-7 absolute"}
                        onClick={(): void => {
                          const newValue = value.clone();
                          newValue.add(1, "month").format("MMM");
                          onChange(newValue);
                          setCalendarDate(newValue);
                        }}
                      />
                    </Title>
                    <Row gutter={8} justify={"end"}>
                      <Col>
                        <Radio.Group
                          onChange={(e): void => {
                            setYearView(e.target.value === "year");
                            onTypeChange(e.target.value);
                          }}
                          value={type}
                        >
                          <Radio.Button value="month">Month</Radio.Button>
                          <Radio.Button value="year">Year</Radio.Button>
                        </Radio.Group>
                      </Col>
                      <Col>
                        <Select
                          dropdownMatchSelectWidth={false}
                          className="my-year-select"
                          onChange={(newYear): void => {
                            const now = value.clone().year(Number(newYear));
                            onChange(now);
                            setCalendarDate(now);
                          }}
                          value={String(year)}
                        >
                          {options}
                        </Select>
                      </Col>
                      <Col>
                        <Select
                          dropdownMatchSelectWidth={false}
                          value={String(month)}
                          onChange={(selectedMonth): void => {
                            const newValue = value.clone();
                            newValue.month(selectedMonth);
                            onChange(newValue);
                            setCalendarDate(newValue);
                          }}
                        >
                          {monthOptions}
                        </Select>
                      </Col>
                      <Col>
                        {transactions.filter(
                          (transaction: CalendarTransactionType) => {
                            return (
                              transaction.permissionDTO.permissions.indexOf(
                                "ADMIN_PETRANSACTION"
                              ) > -1
                            );
                          }
                        ).length > 0 && (
                          <Button
                            className={
                              "bg-primary hover:bg-hover text-white border-0"
                            }
                            onClick={showModal}
                          >
                            + New Entry
                          </Button>
                        )}
                      </Col>
                      <Col>
                        {transactions.length > 0 && (
                          <Tooltip title="View transactions">
                            <Popover
                              className={"block md:hidden"}
                              placement="bottomLeft"
                              content={
                                <>
                                  <div
                                    className={
                                      "text-xs text-muted font-medium uppercase"
                                    }
                                  >
                                    Transaction Legend
                                    <Divider className={"mt-3 mb-0"} />
                                  </div>
                                  <div
                                    className={
                                      "h-48 md:h-72 xl:h-96 overflow-y-auto"
                                    }
                                  >
                                    <List
                                      itemLayout="horizontal"
                                      dataSource={transactions}
                                      renderItem={(
                                        {
                                          color,
                                          peTransactionTitle = "",
                                          peTransactionId,
                                        }: CalendarTransactionType,
                                        index
                                      ): React.ReactElement => (
                                        <List.Item
                                          className={
                                            "px-2 h-8 w-64 hover:bg-hover hover:bg-opacity-50 "
                                          }
                                        >
                                          <ColorPicker
                                            previewClassName={
                                              "fa fa-square border"
                                            }
                                            selected={color}
                                            onSelect={(selColor): void => {
                                              const updatedArr =
                                                updateItemInArray(
                                                  index,
                                                  transactions,
                                                  {
                                                    ...transactions[index],
                                                    selColor,
                                                  }
                                                );
                                              setTransactions(updatedArr);
                                              updateTXColor(
                                                peTransactionId,
                                                selColor
                                              );
                                            }}
                                            disabled={
                                              !getObjectFromPropertyValue(
                                                "peTransactionId",
                                                peTransactionId,
                                                transactions
                                              )?.permissionDTO.permissions.includes(
                                                "ADMIN_PETRANSACTION"
                                              )
                                            }
                                          />
                                          <span className={"text-xs"}>
                                            {peTransactionTitle}
                                          </span>
                                        </List.Item>
                                      )}
                                    />
                                  </div>
                                </>
                              }
                              trigger="click"
                            >
                              <Button shape="circle">$</Button>
                            </Popover>
                          </Tooltip>
                        )}
                      </Col>
                    </Row>
                  </div>
                );
              }}
            />
          </Card>
          <Card
            className={"hidden md:block bg-gray-100"}
            bodyStyle={{
              paddingLeft: "2px",
              paddingRight: "2px",
              height: "100%",
              lineHeight: "50",
            }}
          >
            <Tooltip title={showLegend ? "Hide Legend" : "Show Legend"}>
              {showLegend ? (
                <RightCircleFilled
                  onClick={(): void => setShowLegend(false)}
                  className={"text-3xl text-primary cursor-pointer"}
                />
              ) : (
                <LeftCircleFilled
                  onClick={(): void => setShowLegend(true)}
                  className={"text-3xl text-primary cursor-pointer"}
                />
              )}
            </Tooltip>
          </Card>
          {showLegend && (
            <Card className={"hidden md:block"}>
              <div className={"text-xs text-gray-400 font-medium uppercase"}>
                Transaction Legend
                <Divider className={"mt-3 mb-0"} />
              </div>
              <div
                className={"overflow-y-auto h-full"}
                style={{
                  maxHeight: "800px",
                }}
              >
                <List
                  itemLayout="horizontal"
                  dataSource={
                    yearView
                      ? transactions
                      : getTransactionsForMonth(calendarDate, deadLines)
                  }
                  renderItem={(
                    {
                      color,
                      peTransactionTitle = "",
                      peTransactionId,
                    }: CalendarTransactionType,
                    index
                  ): React.ReactElement => (
                    <List.Item
                      className={"h-8 w-64 hover:bg-hover hover:bg-opacity-50 "}
                    >
                      <div
                        className={"flex w-full items-center m-0 px-2"}
                        onMouseEnter={(): void =>
                          setDisplayTransaction(peTransactionId)
                        }
                        onMouseLeave={(): void => setDisplayTransaction(null)}
                      >
                        <ColorPicker
                          previewClassName={"fa fa-square border"}
                          selected={color}
                          onSelect={(selColor): void => {
                            const updatedArr = updateItemInArray(
                              index,
                              transactions,
                              {
                                ...transactions[index],
                                selColor,
                              }
                            );
                            setTransactions(updatedArr);
                            updateTXColor(peTransactionId, selColor);
                          }}
                          disabled={
                            !getObjectFromPropertyValue(
                              "peTransactionId",
                              peTransactionId,
                              transactions
                            )?.permissionDTO.permissions.includes(
                              "ADMIN_PETRANSACTION"
                            )
                          }
                        />
                        <span className={"text-xs pl-2"}>
                          {peTransactionTitle}
                        </span>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          )}
        </div>
        <DeadlineEventModal
          onUpdate={updateDeadline}
          onCreate={addDeadline}
          transactions={transactions.filter(
            (transaction: CalendarTransactionType) => {
              return (
                transaction.permissionDTO.permissions.indexOf(
                  "ADMIN_PETRANSACTION"
                ) >= 0
              );
            }
          )}
          visible={visible}
          handleCancel={handleCancel}
        />
      </div>
    </div>
  );
};

type getDeadlinesByDateType = (date: Moment) => Array<DeadLineType>;
type dateCellRenderType = (value: Moment) => ReactNode;
type updateTXColorType = (id: string, color: string) => void;
type deleteDeadlineType = (id: string) => void;
type updateDeadlineType = (deadline: DeadLineType) => void;
