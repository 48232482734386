import React, { FC, ReactNode, useEffect, useState } from "react";
import { PortfolioCard } from "../PortfolioCard";
import { useParams } from "react-router";
import {
  AllocationElementType,
  AllocationTableType,
  ElementType,
  PermissionType,
  PortfolioSectionEnumType,
} from "../../../../utils/enums";
import {
  AllocationTableVersionTypes,
  getSecondaryList,
  percentAllocatedColumn,
  TableDataType,
} from "../../../../utils/allocation";
import {
  AllocationKeyType,
  AllocationTableMappingType,
  AllocationType,
  CompanyType,
} from "../../../../utils/types";
import {
  AllocationComparisonType,
  AllocationTable,
} from "../../../transaction/menu/allocation/AllocationTable";
import moment from "moment";
import { Button, Divider, message, Select, Tag, Tooltip } from "antd";
import {
  filter,
  findIndex,
  isEmpty,
  isNil,
  pathOr,
  pluck,
  propEq,
} from "ramda";
import {
  FullScreenButton,
  FullScreenWidget,
} from "../../../general/FullScreenWidget";
import { ACTION_BUTTON_CSS } from "../../../../utils/cssConfigs";
import { AddAllocationModal } from "../../../transaction/menu/allocation/AddAllocationModal";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { initiateFetchSection } from "../../../../utils/portfolio";
import { ResponseType } from "../../../../utils/uiTypes";
import {
  deleteAllocationTableVersion,
  getAllocationTableVersions,
} from "../../../../services/services";
import { NewTableVersionModal } from "../../portfolioModals/NewTableVersionModal";
import { PortfolioAdminPermissionBlock } from "../../PortfolioAdminPermissionBlock";
import { EditVersionModal } from "../../portfolioModals/EditVersionModal";
import {
  removeItemFromArray,
  updateItemInArray,
} from "../../../../utils/utils";
import { ConfirmDelete } from "../../../../utils/confirmationModals";
import { getPopupContainerForSelect } from "../../../../utils/container";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { usePageTitle } from "../../../../customHooks/usePageTitle";

export const MandatedAllocationFees: FC<MandatedAllocationType> = ({
  companies,
  permissions,
  feesId,
  portfolioId,
}) => {
  usePageTitle("Allocations");

  const { sectionId }: ParamsType = useParams();
  const { height: windowHeight } = useWindowDimensions();
  const [newHoldingsModal, setNewHoldingsModal] = useState(false);
  const [editMandatedVersion, setEditMandatedVersion] = useState(false);
  const [mandatedLoading, setMandatedLoading] = useState(false);
  const [mandatedTableConfig, setMandatedTableConfig] =
    useState<TableDataType | null>(null);
  const [mandatedTableData, setMandatedTableData] = useState<AllocationType[]>(
    []
  );
  const [selectedMandatedVersion, setSelectedMandatedVersion] =
    useState<AllocationTableMappingType | null>(null);
  const [allocationTableVersions, setAllocationTableVersions] = useState<
    AllocationTableMappingType[]
  >([]);
  const [allocationTableKeys, setAllocationTableKeys] = useState<
    AllocationKeyType[]
  >([]);
  const [lastUpdated, setLastUpdated] = useState<string>("--/--/----");

  const [newFeesVersion, setNewFeesVersion] = useState(false);
  const [editFeesVersion, setEditFeesVersion] = useState(false);
  const [feesTableConfig, setFeesTableConfig] = useState<TableDataType | null>(
    null
  );
  const [feesLoading, setFeesLoading] = useState(false);

  const [feesTableData, setFeesTableData] = useState<AllocationType[]>([]);
  const [feesAllocationTableKeys, setFeesAllocationTableKeys] = useState<
    AllocationKeyType[]
  >([]);
  const [selectedFeesVersion, setSelectedFeesVersion] =
    useState<AllocationTableMappingType | null>(null);
  const [feesAllocationTableVersions, setFeesAllocationTableVersions] =
    useState<AllocationTableMappingType[]>([]);

  const [comparisonConfig, setComparisonConfig] = useState<
    [AllocationComparisonType, AllocationComparisonType] | null
  >(null);
  const [ComparisonValue, setComparisonValue] = useState<string>("");
  /** Gets allocation table data **/
  const getFeesData = (tableId: string) => {
    initiateFetchSection(feesId, portfolioId, "", tableId)
      .then(({ allocationAndFeesDTO }) => {
        setFeesTableConfig(
          allocationAndFeesDTO.allocationTableMappingDTOList[0] ?? []
        );
        setFeesAllocationTableKeys(
          allocationAndFeesDTO?.allocationKeyDTOList ?? []
        );
        setFeesTableData(allocationAndFeesDTO?.allocationDTOList ?? []);
      })
      .catch((e: string) => e && message.error(e))
      .then(() => setFeesLoading(false));
  };

  const getFeesVersions = (version?: AllocationTableMappingType) => {
    if (portfolioId) {
      getAllocationTableVersions({
        params: {
          elementId: portfolioId,
          allocationElementType: AllocationElementType.PORTFOLIO,
          allocationType: AllocationTableType.FEES,
        },
      })
        .then(({ data }: ResponseType<AllocationTableMappingType[]>) => {
          const v = version ?? data[0];
          setFeesAllocationTableVersions(data);
          setSelectedFeesVersion(v);
          getFeesData(v.id);
        })
        .catch((e: string) => e && message.error(e));
    }
  };

  const deleteFeesAllocationVersion = (id: string) => {
    deleteAllocationTableVersion({
      segments: {
        id,
      },
    })
      .then(() => {
        const index = findIndex(propEq("id", id), feesAllocationTableVersions);
        if (index >= 0) {
          const list = removeItemFromArray(index, feesAllocationTableVersions);
          setFeesAllocationTableVersions(list);
          if (list.length > 0) {
            setSelectedFeesVersion(list[0]);
            getFeesData(list[0].id);
          } else {
            setSelectedFeesVersion(null);
            setFeesTableConfig(null);
            setFeesTableData([]);
            setFeesAllocationTableKeys([]);
          }
        }
      })
      .catch(() => {
        message.error("Unable to delete this version");
      });
  };

  const getMandatedData = (tableId: string) => {
    initiateFetchSection(sectionId, portfolioId, "", tableId)
      .then(({ allocationAndFeesDTO }) => {
        setMandatedTableConfig(
          allocationAndFeesDTO.allocationTableMappingDTOList[0]
        );
        setAllocationTableKeys(allocationAndFeesDTO.allocationKeyDTOList ?? []);
        setMandatedTableData(allocationAndFeesDTO.allocationDTOList ?? []);

        allocationAndFeesDTO.allocationTableMappingDTOList[0].allocationDate &&
          setLastUpdated(
            moment(
              allocationAndFeesDTO.allocationTableMappingDTOList[0]
                .allocationDate
            ).format("MM/DD/YYYY")
          );
      })
      .then(() => setMandatedLoading(false))
      .catch((e: string) => e && message.error(e));
  };

  const getComparisonData = ([t1, t2]: [string, string]) => {
    Promise.all([
      initiateFetchSection(sectionId, portfolioId, "", t1),
      initiateFetchSection(sectionId, portfolioId, "", t2),
    ])
      .then(([d1, d2]) => {
        setComparisonConfig([
          {
            config: d1.allocationAndFeesDTO?.allocationTableMappingDTOList[0],
            tableKeys: d1.allocationAndFeesDTO.allocationKeyDTOList,
            data: d1.allocationAndFeesDTO.allocationDTOList,
          },
          {
            config: d2.allocationAndFeesDTO?.allocationTableMappingDTOList[0],
            tableKeys: d2.allocationAndFeesDTO.allocationKeyDTOList,
            data: d2.allocationAndFeesDTO.allocationDTOList,
          },
        ]);
      })
      .catch((e: string) => e && message.error(e));
  };

  const getMandatedVersions = (version?: AllocationTableMappingType) => {
    if (portfolioId) {
      setMandatedLoading(true);
      getAllocationTableVersions({
        params: {
          elementId: portfolioId,
          allocationElementType: AllocationElementType.PORTFOLIO,
          allocationType: AllocationTableType.FINAL_HOLD,
        },
      })
        .then(({ data }: ResponseType<AllocationTableMappingType[]>) => {
          const v = version ?? data[0];
          setAllocationTableVersions(data);
          setSelectedMandatedVersion(v);
          getMandatedData(v.id);
        })
        .catch((e: string) => e && message.error(e));
    }
  };

  const deleteMandatedAllocationVersion = (id: string) => {
    deleteAllocationTableVersion({
      segments: {
        id,
      },
    })
      .then(() => {
        const index = findIndex(propEq("id", id), allocationTableVersions);
        if (index >= 0) {
          const list = removeItemFromArray(index, allocationTableVersions);
          setAllocationTableVersions(list);
          if (list.length > 0) {
            setSelectedMandatedVersion(list[0]);
            getMandatedData(list[0].id);
          } else {
            setSelectedMandatedVersion(null);
            setMandatedTableConfig(null);
            setMandatedTableData([]);
            setAllocationTableKeys([]);
          }
        }
      })
      .catch(() => {
        message.error("Unable to delete this version");
      });
  };
  useEffect(() => {
    getMandatedVersions();
    getFeesVersions();
  }, [portfolioId]);

  return (
    <PortfolioCard
      permissions={permissions}
      loading={false}
      title={""}
      bodyStyle={{ padding: "0 5px" }}
      header={<></>}
    >
      <FullScreenWidget>
        {(isFullScreen, trigger): ReactNode => (
          <div
            className={`${
              isFullScreen &&
              "bg-gray-50 h-screen w-screen overflow-y-scroll p-10"
            } h-full`}
          >
            <>
              <AllocationTable
                extraOptions={
                  <FullScreenButton
                    isFullScreen={isFullScreen}
                    trigger={trigger}
                  />
                }
                sectionType={PortfolioSectionEnumType.ALLOCATION}
                loading={mandatedLoading || isEmpty(mandatedTableConfig)}
                hasDate={true}
                pageType={ElementType.PORTFOLIO}
                editPermission={permissions.includes(
                  PermissionType.ADMIN_PORTFOLIO
                )}
                title={"Allocations"}
                peTransactionIdOrPortfolioId={portfolioId ?? ""}
                companies={companies}
                keys={filter(
                  propEq("allocationTableType", AllocationTableType.FINAL_HOLD),
                  allocationTableKeys
                )}
                config={mandatedTableConfig}
                data={mandatedTableData}
                extraColumns={percentAllocatedColumn}
                toolbar={true}
                tableHeight={
                  isFullScreen ? windowHeight - 320 : windowHeight / 3
                }
                institutionModal={(
                  onSubmit,
                  selectedCompanies,
                  disabled
                ): ReactNode => (
                  <AddAllocationModal
                    disabled={disabled}
                    onSubmit={onSubmit}
                    companies={getSecondaryList(
                      selectedCompanies,
                      [],
                      companies
                    )}
                  />
                )}
                comparisonConfig={comparisonConfig}
                onCloseComparison={() => {
                  setComparisonConfig(null);
                  setComparisonValue("");
                }}
              >
                {(isUpdated, isLoading) => (
                  <div className={"w-full flex justify-between mt-2"}>
                    <div className={"flex flex-row gap-2 items-center"}>
                      <Select
                        disabled={isUpdated || isLoading}
                        getPopupContainer={getPopupContainerForSelect}
                        className={"w-96 shadow"}
                        onSelect={(v) => {
                          const index = findIndex(
                            propEq("id", v),
                            allocationTableVersions
                          );
                          if (index >= 0) {
                            setSelectedMandatedVersion(
                              allocationTableVersions[index]
                            );
                            getMandatedData(allocationTableVersions[index].id);
                          }
                        }}
                        suffixIcon={
                          <i className="fa-solid fa-angle-down text-primary"></i>
                        }
                        value={selectedMandatedVersion?.id}
                      >
                        {allocationTableVersions?.map(
                          ({ id, name, allocationDate, tableVersionType }) => (
                            <Select.Option key={id} value={id}>
                              <div
                                className={
                                  "flex flex-row gap-2 justify-between"
                                }
                              >
                                <div className={"ellipsis truncate"}>
                                  {name}
                                </div>
                                <div className={"italic text-right gap-x-2"}>
                                  {!isEmpty(tableVersionType) &&
                                    !isNil(tableVersionType) && (
                                      <Tag color={"blue"}>
                                        {pathOr(
                                          tableVersionType,
                                          [tableVersionType, "label"],
                                          AllocationTableVersionTypes
                                        )}
                                      </Tag>
                                    )}
                                  {!isEmpty(allocationDate) &&
                                    !isNil(allocationDate) && (
                                      <Tag color={"blue"}>
                                        {moment(allocationDate).format("ll")}
                                      </Tag>
                                    )}
                                </div>
                              </div>
                            </Select.Option>
                          )
                        )}
                      </Select>
                      <PortfolioAdminPermissionBlock permissions={permissions}>
                        <div className={"flex flex-row gap-2 group"}>
                          <Tooltip title="Add New Allocation">
                            <Button
                              title={"Add New Allocation"}
                              icon={<PlusOutlined className={"text-primary"} />}
                              disabled={isUpdated || isLoading}
                              onClick={(): void => {
                                setNewHoldingsModal(true);
                              }}
                              className={ACTION_BUTTON_CSS}
                            />
                          </Tooltip>
                          <Tooltip title="Edit Allocation">
                            <Button
                              title={"Edit Allocation Information"}
                              icon={<EditOutlined />}
                              disabled={
                                !selectedMandatedVersion ||
                                isUpdated ||
                                isLoading
                              }
                              onClick={(): void => {
                                setEditMandatedVersion(true);
                              }}
                              className={ACTION_BUTTON_CSS}
                            />
                          </Tooltip>
                          {allocationTableVersions.length > 1 && (
                            <Tooltip title="Delete Allocation">
                              <Button
                                title={"Delete Allocation"}
                                disabled={
                                  !selectedMandatedVersion ||
                                  isUpdated ||
                                  isLoading
                                }
                                icon={
                                  <DeleteOutlined className={"text-danger"} />
                                }
                                onClick={(): void => {
                                  ConfirmDelete(
                                    "Are you sure you want to delete this version? All entered data will be lost.",
                                    () => {
                                      if (selectedMandatedVersion?.id) {
                                        deleteMandatedAllocationVersion(
                                          selectedMandatedVersion?.id
                                        );
                                      }
                                    },
                                    <></>
                                  );
                                }}
                                className={ACTION_BUTTON_CSS}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </PortfolioAdminPermissionBlock>
                      <Select
                        disabled={isUpdated || isLoading}
                        value={ComparisonValue || undefined}
                        getPopupContainer={getPopupContainerForSelect}
                        className="min-w-[250px] pr-0 shadow"
                        onSelect={(v) => {
                          setComparisonValue(v);
                          if (selectedMandatedVersion) {
                            getComparisonData([selectedMandatedVersion.id, v]);
                          }
                        }}
                        placeholder="Select Comparison Version"
                        options={allocationTableVersions?.map(
                          ({ id, name, allocationDate }) => ({
                            disabled: id === selectedMandatedVersion?.id,
                            label: (
                              <div className="flex flex-row gap-2 justify-between">
                                <div className="ellipsis truncate">{name}</div>
                                <div className="italic text-right">
                                  {!isEmpty(allocationDate) &&
                                    !isNil(allocationDate) && (
                                      <Tag color="blue">
                                        {moment(allocationDate).format("ll")}
                                      </Tag>
                                    )}
                                </div>
                              </div>
                            ),
                            value: id,
                          })
                        )}
                        suffixIcon={
                          <i className="fa-solid fa-angle-down text-primary"></i>
                        }
                      />
                    </div>
                  </div>
                )}
              </AllocationTable>

              <div className={"w-full text-right p-1 text-muted"}>
                Last Updated: {lastUpdated}
              </div>
              <NewTableVersionModal
                sectionType={PortfolioSectionEnumType.ALLOCATION}
                visible={newHoldingsModal}
                portfolioId={portfolioId}
                sectionId={sectionId}
                dates={pluck("allocationDate", allocationTableVersions)}
                versions={allocationTableVersions}
                onSave={(version) => {
                  getMandatedVersions(version);
                  setNewHoldingsModal(false);
                }}
                onCancel={() => {
                  setNewHoldingsModal(false);
                }}
                title={"Add New Holdings"}
              />
              {selectedMandatedVersion && (
                <EditVersionModal
                  sectionType={PortfolioSectionEnumType.ALLOCATION}
                  visible={editMandatedVersion}
                  version={selectedMandatedVersion}
                  dates={pluck("allocationDate", allocationTableVersions)}
                  onSave={(version) => {
                    const index = findIndex(
                      propEq("id", version.id),
                      allocationTableVersions
                    );
                    if (index >= 0) {
                      setAllocationTableVersions(
                        updateItemInArray(
                          index,
                          allocationTableVersions,
                          version
                        )
                      );
                      setSelectedMandatedVersion(version);
                      getMandatedData(version.id);
                    }
                    setEditMandatedVersion(false);
                  }}
                  onCancel={() => {
                    setEditMandatedVersion(false);
                  }}
                />
              )}
            </>
            <Divider />
            <div className={"pb-5"}>
              <AllocationTable
                extraOptions={
                  <FullScreenButton
                    isFullScreen={isFullScreen}
                    trigger={trigger}
                  />
                }
                sectionType={PortfolioSectionEnumType.FEES}
                loading={feesLoading || isEmpty(feesTableConfig)}
                hasDate={false}
                title={"Fees"}
                pageType={ElementType.PORTFOLIO}
                editPermission={permissions.includes(
                  PermissionType.ADMIN_PORTFOLIO
                )}
                peTransactionIdOrPortfolioId={portfolioId ?? ""}
                keys={filter(
                  propEq("allocationTableType", AllocationTableType.FEES),
                  feesAllocationTableKeys
                )}
                config={feesTableConfig}
                companies={companies}
                data={feesTableData}
                toolbar={true}
                tableHeight={windowHeight / 3}
                institutionModal={(
                  onSubmit,
                  selectedCompanies,
                  disabled
                ): ReactNode => {
                  return (
                    <AddAllocationModal
                      disabled={disabled}
                      onSubmit={onSubmit}
                      companies={getSecondaryList(
                        selectedCompanies,
                        [],
                        companies
                      )}
                    />
                  );
                }}
              >
                {(isUpdated, isLoading) => (
                  <div className={"w-full flex justify-between mt-2"}>
                    <div className={"flex flex-row gap-2 items-center"}>
                      <Select
                        disabled={isUpdated || isLoading}
                        getPopupContainer={getPopupContainerForSelect}
                        className={"w-96 pr-0 shadow"}
                        onSelect={(v) => {
                          const index = findIndex(
                            propEq("id", v),
                            feesAllocationTableVersions
                          );
                          if (index >= 0) {
                            setSelectedFeesVersion(
                              feesAllocationTableVersions[index]
                            );
                            getFeesData(feesAllocationTableVersions[index].id);
                          }
                        }}
                        suffixIcon={
                          <i className="fa-solid fa-angle-down text-primary"></i>
                        }
                        value={selectedFeesVersion?.id}
                      >
                        {feesAllocationTableVersions?.map(
                          ({ id, name, allocationDate }) => (
                            <Select.Option key={id} value={id}>
                              <div
                                className={
                                  "flex flex-row gap-2 justify-between"
                                }
                              >
                                <div className={"ellipsis truncate"}>
                                  {name}
                                </div>
                                <div className={"italic text-right"}>
                                  {!isEmpty(allocationDate) &&
                                    !isNil(allocationDate) && (
                                      <Tag color={"blue"}>
                                        {moment(allocationDate).format("ll")}
                                      </Tag>
                                    )}
                                </div>
                              </div>
                            </Select.Option>
                          )
                        )}
                      </Select>
                      <PortfolioAdminPermissionBlock permissions={permissions}>
                        <div className={"flex flex-row gap-2 group"}>
                          <Tooltip title="Add New Fees">
                            <Button
                              title={"Add New Fees"}
                              icon={<PlusOutlined className={"text-primary"} />}
                              disabled={isUpdated || isLoading}
                              onClick={(): void => setNewFeesVersion(true)}
                              className={ACTION_BUTTON_CSS}
                            />
                          </Tooltip>
                          <Tooltip title="Edit Fees">
                            <Button
                              title={"Edit Fees Information"}
                              icon={<EditOutlined />}
                              disabled={
                                !selectedFeesVersion || isUpdated || isLoading
                              }
                              onClick={(): void => setEditFeesVersion(true)}
                              className={ACTION_BUTTON_CSS}
                            />
                          </Tooltip>
                          {feesAllocationTableVersions.length > 1 && (
                            <Tooltip title="Delete Fees">
                              <Button
                                title={"Delete Fees"}
                                disabled={
                                  !selectedFeesVersion || isUpdated || isLoading
                                }
                                icon={
                                  <DeleteOutlined className={"text-danger"} />
                                }
                                onClick={(): void => {
                                  ConfirmDelete(
                                    "Are you sure you want to delete this version? All entered data will be lost.",
                                    () => {
                                      if (selectedFeesVersion?.id) {
                                        deleteFeesAllocationVersion(
                                          selectedFeesVersion?.id
                                        );
                                      }
                                    },
                                    <></>
                                  );
                                }}
                                className={ACTION_BUTTON_CSS}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </PortfolioAdminPermissionBlock>
                    </div>
                  </div>
                )}
              </AllocationTable>

              <NewTableVersionModal
                title={"Add New Fees"}
                sectionType={PortfolioSectionEnumType.FEES}
                visible={newFeesVersion}
                portfolioId={portfolioId}
                sectionId={feesId}
                versions={feesAllocationTableVersions}
                dates={pluck("allocationDate", feesAllocationTableVersions)}
                onSave={(version) => {
                  getFeesVersions(version);
                  setNewFeesVersion(false);
                }}
                onCancel={() => setNewFeesVersion(false)}
              />
              {selectedFeesVersion && (
                <EditVersionModal
                  sectionType={PortfolioSectionEnumType.FEES}
                  visible={editFeesVersion}
                  version={selectedFeesVersion}
                  dates={pluck("allocationDate", allocationTableVersions)}
                  onSave={(version) => {
                    const index = findIndex(
                      propEq("id", version.id),
                      feesAllocationTableVersions
                    );
                    if (index >= 0) {
                      setFeesAllocationTableVersions(
                        updateItemInArray(
                          index,
                          feesAllocationTableVersions,
                          version
                        )
                      );
                      setSelectedFeesVersion(version);
                      getFeesData(version.id);
                    }
                    setEditFeesVersion(false);
                  }}
                  onCancel={() => setEditFeesVersion(false)}
                />
              )}
            </div>
          </div>
        )}
      </FullScreenWidget>
    </PortfolioCard>
  );
};

type ParamsType = {
  portfolioId: string;
  sectionId: string;
};

type MandatedAllocationType = {
  companies: CompanyType[];
  permissions: PermissionType[];
  feesId: string;
  portfolioId: string;
};
