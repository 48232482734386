import React, { FC, ReactNode, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
} from "antd";
import {
  CalendarTransactionType,
  DeadLineType,
  TransactionType,
} from "../../utils/types";
import {
  createDeadlineEvent,
  updateDeadlineEvent,
} from "../../services/services";
import moment from "moment";
import { ResponseType, voidType } from "../../utils/uiTypes";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export const DeadlineEventModal: FC<DeadlineEventModalType> = function ({
  editDeadline = undefined,
  transactions = [],
  handleCancel,
  visible,
  onUpdate,
  onCreate,
  dashboardTransaction,
  isOffline = false,
}: DeadlineEventModalType) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [sendNote, setSendNote] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<
    CalendarTransactionType | TransactionType
  >();

  const createDeadline: voidType = () => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    formValues.lenderDTOS = selectedTransaction
      ? selectedTransaction.lenderDTOs.filter(
          (lender) => formValues.lenderDTOS?.indexOf(lender.id) > -1
        )
      : [];
    createDeadlineEvent({
      body: JSON.stringify({
        lenderDTOS: formValues.lenderDTOS ? formValues.lenderDTOS : [],
        peTransactionId:
          formValues.peTransactionId || dashboardTransaction?.peTransactionId,
        sendEmail: formValues.sendEmail,
        targetDate: Date.parse(moment.utc(formValues.targetDate).format()),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        title: formValues.title,
        note: formValues.note,
      }),
    })
      .then(({ data }: ResponseType<DeadLineType>) => {
        onCreate(data);
        resetFields();
        handleCancel();
        setLoading(false);
      })
      .catch(() => {
        message.error("Something went wrong", 5);
        setLoading(false);
      });
  };
  const updateDeadlineEventInfo = (): void => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    formValues.lenderDTOS = selectedTransaction
      ? selectedTransaction.lenderDTOs.filter(
          (lender) => formValues.lenderDTOS.indexOf(lender.id) > -1
        )
      : [];
    updateDeadlineEvent({
      body: JSON.stringify({
        ...editDeadline,
        lenderDTOS: formValues.lenderDTOS ? formValues.lenderDTOS : [],
        sendEmail: formValues.sendEmail ?? false,
        targetDate: Date.parse(moment.utc(formValues.targetDate).format()),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        title: formValues.title,
        note: formValues.note,
      }),
    })
      .then(({ data }: ResponseType<DeadLineType>) => {
        setLoading(false);
        onUpdate(data);
        resetFields();
        handleCancel();
      })
      .catch(() => {
        message.error("Update Failed", 5);
        setLoading(false);
      });
  };

  const onChange: onChangeType = (e) => setSendNote(e.target.value);
  const updateTransaction: updateTransactionType = (value) => {
    form.resetFields(["deadline", "lenders"]);
    setSelectedTransaction(
      transactions.find((tx) => tx.peTransactionId === value)
    );
  };
  const resetFields: voidType = () => {
    form.resetFields();
    form.setFieldsValue(
      editDeadline
        ? {
            title: editDeadline.title,
            targetDate: moment(Number(editDeadline.targetDate)).utc(),
            lenderDTOS: editDeadline.lenderDTOS
              ? editDeadline.lenderDTOS.map((lender) => lender.id)
              : [],
            sendEmail: false,
          }
        : {
            sendEmail: false,
            lenderDTOS: [],
          }
    );
    setSendNote(false);
  };
  const selectAllLenders = (e: CheckboxChangeEvent): void => {
    if (selectedTransaction)
      form.setFieldsValue({
        lenderDTOS: e.target.checked
          ? selectedTransaction.lenderDTOs.map((lender) => lender.id)
          : [],
      });
  };
  useEffect(() => {
    if (editDeadline) {
      setSelectedTransaction(
        transactions.find(
          (tx) => editDeadline.peTransactionId === tx.peTransactionId
        )
      );
    }
  }, [transactions, editDeadline]);
  useEffect(() => {
    if (dashboardTransaction) setSelectedTransaction(dashboardTransaction);
  }, [transactions, dashboardTransaction]);
  useEffect(() => {
    resetFields();
  }, [editDeadline, visible]);
  return (
    <Modal
      title={editDeadline ? "Edit Event" : "Add Event"}
      open={visible}
      onCancel={handleCancel}
      destroyOnClose={true}
      footer={[
        <Button
          disabled={loading}
          key="cancel"
          htmlType="button"
          onClick={(): void => handleCancel()}
        >
          Cancel
        </Button>,
        <Button
          loading={loading}
          disabled={loading}
          form={"form"}
          className={"bg-primary hover:bg-hover text-white border-0"}
          key="submit"
          htmlType="submit"
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="form"
        layout={"vertical"}
        onFinish={editDeadline ? updateDeadlineEventInfo : createDeadline}
      >
        {!(editDeadline || transactions.length === 0) && (
          <Form.Item
            name={"peTransactionId"}
            label="Select Transaction"
            rules={[
              {
                required: true,
                message: "Please select a transaction",
              },
            ]}
          >
            <Select
              disabled={loading}
              notFoundContent={"No Transactions Available"}
              getPopupContainer={(trigger): HTMLElement =>
                trigger.parentElement
              }
              onSelect={updateTransaction}
              placeholder={"Select transaction"}
            >
              {transactions.map((tx) => (
                <Select.Option
                  key={tx.peTransactionId}
                  value={tx.peTransactionId}
                >
                  <span
                    className={"fa fa-square  mr-2"}
                    style={{ color: tx.color }}
                  />
                  {tx.peTransactionTitle}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name={"title"}
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please input a title",
                },
              ]}
            >
              <Input
                disabled={loading}
                placeholder="Enter title"
                className={"w-full"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Target Date"
              name={"targetDate"}
              rules={[
                {
                  required: true,
                  message: "Please input a target date",
                },
              ]}
            >
              <DatePicker disabled={loading} className={"w-full"} />
            </Form.Item>
          </Col>
        </Row>

        {selectedTransaction && (
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues): boolean =>
              prevValues?.lenderDTOS?.length !==
              currentValues?.lenderDTOS?.length
            }
          >
            {({ getFieldValue }): ReactNode => (
              <Form.Item label="Select institutions" name={"lenderDTOS"}>
                <Select
                  disabled={loading}
                  notFoundContent={"No Institutions Available"}
                  getPopupContainer={(trigger): HTMLElement =>
                    trigger.parentElement
                  }
                  mode="multiple"
                  maxTagCount={"responsive"}
                  placeholder="Select institution(s)"
                  filterOption={(input, option): boolean =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  <Select.Option
                    className={"cursor-pointer"}
                    value={"none"}
                    disabled={true}
                  >
                    <Checkbox
                      disabled={loading}
                      className={"w-full"}
                      style={{ lineHeight: "32px" }}
                      onChange={selectAllLenders}
                      checked={
                        getFieldValue("lenderDTOS")?.length ===
                        selectedTransaction?.lenderDTOs?.length
                      }
                    >
                      Select All Institutions
                    </Checkbox>
                  </Select.Option>
                  {selectedTransaction &&
                    selectedTransaction.lenderDTOs
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((lender) => (
                        <Select.Option
                          key={lender.id}
                          value={lender.id}
                          title={lender.name}
                        >
                          {lender.name}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
        )}
        {!isOffline && (
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues): boolean =>
              prevValues?.lenderDTOS?.length !==
              currentValues?.lenderDTOS?.length
            }
          >
            {({ getFieldValue }): ReactNode =>
              getFieldValue("lenderDTOS")?.length > 0 ? (
                <Form.Item
                  name={"sendEmail"}
                  label="Would you like to notify institutions?"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <Radio.Group
                    disabled={isOffline || loading}
                    onChange={onChange}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              ) : null
            }
          </Form.Item>
        )}
        {sendNote && (
          <Form.Item name={"note"}>
            <Input.TextArea
              disabled={loading}
              placeholder={"(Optional) Write a message..."}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

type DeadlineEventModalType = {
  editDeadline?: DeadLineType;
  transactions?: Array<CalendarTransactionType>;
  handleCancel: () => void;
  visible: boolean;
  onUpdate: (a: DeadLineType) => void;
  onCreate: (a: DeadLineType) => void;
  dashboardTransaction?: CalendarTransactionType | TransactionType | null;
  isOffline?: boolean;
};

type onChangeType = (e: RadioChangeEvent) => void;
type updateTransactionType = (value: string) => void;
