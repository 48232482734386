export const getAbbreviation: GetAbbreviation = (str) => {
  // Split the string into words
  const words = getCleanString(str).split(" ");

  // Extract the first letter of each word
  const initials = words.map((word) => word[0]);

  // Join the initials together to form the abbreviation
  return initials.join("").toUpperCase();
};

export const getCleanString: GetCleanString = (str) => {
  // Replace all non-alphanumeric characters with an empty string
  return str.replace(/[^0-9a-zA-Z]/g, " ");
};

type GetAbbreviation = (str: string) => string;
type GetCleanString = (str: string) => string;
