import React, { FC, useContext } from "react";
import { MenuRouteType } from "../utils/uiTypes";
import { UserContext } from "../context/UserContext";
import { useHistory, useParams } from "react-router";
import { WithTopNav } from "../components/layout/WithTopNav";
import { UserProfile } from "../components/user/UserProfile";
import { Notifications } from "../components/user/Notifications";
import { Security } from "../components/user/Security";
import { Settings } from "../components/user/Settings";
import { usePageTitle } from "../customHooks/usePageTitle";
import { InternalPageNotFound } from "./InternalPageNotFound";

const USER_MENU_OPTIONS: MenuRouteType[] = [
  {
    title: "Profile",
    Component: UserProfile,
    exact: false,
    key: "profile",
    path: "/user/profile",
    permission: true,
  },
  {
    title: "Security",
    Component: Security,
    exact: false,
    key: "security",
    path: "/user/security",
  },
  {
    title: "Notifications",
    Component: Notifications,
    exact: false,
    key: "notifications",
    path: "/user/notifications",
    permission: true,
  },
  {
    Component: InternalPageNotFound,
    exact: false,
    path: "*",
    permission: true,
    props: { url: "/user/profile" },
    title: "",
    key: "not-found",
    visible: false,
  },
];

export const User: FC = () => {
  usePageTitle("User Profile");

  const { user } = useContext(UserContext);
  const { userMenu }: ParamsType = useParams();
  const history = useHistory();

  return (
    <WithTopNav
      gotoRoute={(link): void => history.push(`/user/${link.key}`)}
      selectedKeys={[userMenu]}
      menu={user ? USER_MENU_OPTIONS : []}
      title={user ? user.firstName + " " + user.lastName : "Account"}
      defaultValue={"profile"}
    />
  );
};

type ParamsType = {
  userMenu: string;
  id: string;
};
