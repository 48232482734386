import React, { FC, useEffect, useState } from "react";
import { NotesType } from "../../../../utils/types";
import { Drawer, Tooltip } from "antd";
import { fetchWithIdType, ResponseType } from "../../../../utils/uiTypes";
import { getNotesByLenderId } from "../../../../services/services";
import { NotesThread } from "../notes/NotesThread";
import { CustomSpin } from "../../../general/CustomSpin";
import { useHistory } from "react-router";

export const DashboardTrackerNotes: FC<DashboardTrackerNotesType> = ({
  transactionId,
  lenderId,
}) => {
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState<NotesType | null>(null);

  const fetchLenderNotesById: fetchWithIdType = (id: string) => {
    getNotesByLenderId({
      segments: { id },
      params: { peTransactionId: transactionId },
    })
      .then(({ data }: ResponseType<NotesType>) => {
        setNotes(data);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (open) {
      fetchLenderNotesById(lenderId);
    } else {
      setNotes(null);
    }
  }, [open]);

  return (
    <>
      <Drawer
        open={open}
        width={"90%"}
        destroyOnClose={true}
        footer={false}
        bodyStyle={{ padding: 0 }}
        title={`${notes?.lenderDTO?.name ?? "Loading"} Notes`}
        onClose={() => setOpen(false)}
      >
        <div className={"h-full max-h-full bg-gray-100 overflow-hidden"}>
          {notes ? (
            <NotesThread
              isDealTeam={true}
              navigateToLender={(lenderId, noteId) =>
                history.push(
                  `/transactions/${transactionId}/notes?lenderId=${lenderId}&noteId=${noteId}`
                )
              }
              notes={notes}
              activeLender={Number(lenderId)}
              transactionId={transactionId}
              readOnly={true}
            />
          ) : (
            <CustomSpin />
          )}
        </div>
      </Drawer>
      <Tooltip title={"View Notes"} placement={"right"}>
        <i
          className="fa-regular fa-note-sticky text-blue-500 hover:text-blue-700"
          onClick={() => {
            setOpen(true);
          }}
        ></i>
      </Tooltip>
    </>
  );
};

type DashboardTrackerNotesType = {
  lenderId: string;
  transactionId: string;
};
