import React, { FC, useState, MouseEvent } from "react";
import { CustomAvatar } from "../../../general/CustomAvatar";
import { Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { CommentInputForm } from "../../../forms/CommentInputForm";
import { CommentType } from "../../../../utils/types";
import { ElementType, PermissionType } from "../../../../utils/enums";
import { valOrDefault } from "../../../../utils/utils";
import { convertDateToFormat } from "../../../../utils/moment";

export const Comment: FC<ConversationType> = function ({
  conversation,
  team,
  root = true,
  uploadConversation,
  togglePriority,
  setRead,
  permissions,
}: ConversationType) {
  const [openComments, setOpenComments] = useState<boolean>(false);
  const onClickRootConversation: onClickRootConversation = (
    root,
    conversation,
    openComments
  ) => {
    root && setOpenComments(!openComments);
    if (!conversation.read) {
      setRead && setRead(conversation.commentId, conversation.elementType);
    }
  };

  const toggleRootConversationPriority: toggleRootConversationPriorityType = (
    e: MouseEvent
  ) => {
    e.stopPropagation();
    togglePriority && togglePriority(conversation.commentId);
  };

  return (
    <div className={"mb-2 last:mb-0 rounded-sm"} tabIndex={0}>
      <div
        className={`${root ? "bg-white border-b-0" : "bg-transparent"}`}
        onClick={(): void =>
          onClickRootConversation(root, conversation, openComments)
        }
      >
        <div className={`m-0 p-3 ${root && "cursor-pointer"}`}>
          <div className={"flex flex-row items-center gap-x-2"}>
            <CustomAvatar
              data={
                conversation.createdByUserDTO.firstName
                  ?.charAt(0)
                  ?.toUpperCase() +
                conversation.createdByUserDTO.lastName?.charAt(0)?.toUpperCase()
              }
              color={
                team[conversation.createdByUserDTO.userId]
                  ? team[conversation.createdByUserDTO.userId]
                  : "gray-500"
              }
              size={"small"}
              outlined={
                team[conversation.createdByUserDTO.userId] === "default"
              }
            />
            <span
              className={"text-sm font-semibold"}
              style={{ color: "#496d90" }}
            >
              {conversation.createdByUserDTO.firstName}&nbsp;
              {conversation.createdByUserDTO.lastName}
              {" - "}
              {convertDateToFormat(conversation.createDate, "D.MMM.YY", false) +
                " " +
                convertDateToFormat(conversation.createDate, "hh:mm a", false)}
            </span>
            {root && togglePriority && (
              <Button
                size={"small"}
                shape={"circle"}
                className={
                  "ml-auto border-0 p-0 shadow flex items-center justify-center bg-gray-50 motion-safe:hover:font-medium text-gray-500 shadow-[0_2px_4px_rgba(0,0,0,0.18)]"
                }
                onClick={toggleRootConversationPriority}
              >
                {!conversation.priorities ? (
                  <i className="far fa-star text-gray-400" />
                ) : (
                  <i className="fas fa-star text-blue-500" />
                )}
              </Button>
            )}
          </div>
          <div className={"mb-4 px-8 py-1 flex"}>
            <div
              id={"conversation"}
              className={"prose max-w-full"}
              dangerouslySetInnerHTML={{
                __html: conversation?.comment
                  ?.replaceAll(/\\"/g, "")
                  .replaceAll(/\\n/g, "")
                  .replace(/<img[^>]*>/g, "")
                  .toString(),
              }}
            />
          </div>
          {root && (
            <div
              className={
                "select-none flex flex-row items-center text-gray-500 font-medium"
              }
            >
              <span
                className={`ml-auto text-xs ${
                  conversation.read !== undefined &&
                  valOrDefault(false, !conversation.read) &&
                  conversation.commentDTOs?.length > 0 &&
                  "text-red-500"
                }`}
              >
                {conversation.commentDTOs ? conversation.commentDTOs.length : 0}
                &nbsp; Comments
              </span>
              {openComments ? (
                <UpOutlined style={{ fontSize: 9 }} className={"mx-2"} />
              ) : (
                <DownOutlined style={{ fontSize: 9 }} className={"mx-2"} />
              )}
            </div>
          )}
        </div>
      </div>
      {root && (
        <div
          className={`overflow-hidden border border-gray-200 ${
            openComments ? "h-auto" : "h-0"
          }`}
        >
          <div className={"m-3"}>
            <CommentInputForm
              onSubmit={({ message }): void => {
                return (
                  uploadConversation &&
                  uploadConversation(
                    message,
                    conversation.commentId,
                    conversation.elementId,
                    conversation.elementType,
                    "TEXT"
                  )
                );
              }}
            />
            {conversation.commentDTOs?.map((comment) => (
              <Comment
                permissions={permissions}
                key={`subComment-${conversation.commentId}-${comment.commentId}`}
                conversation={comment}
                team={team}
                root={false}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
type ConversationType = {
  conversation: CommentType;
  team: Record<string, string>;
  root?: boolean;
  uploadConversation?: (
    message: string,
    root: null | string,
    elementId: string,
    elementType: ElementType.PETRANSACTION | ElementType.LENDER,
    commentType: "TEXT"
  ) => void;
  togglePriority?: (commentId: string) => void;
  permissions: Array<PermissionType>;
  setRead?: (
    elementId: string,
    type: ElementType.PETRANSACTION | ElementType.LENDER
  ) => void;
};
type onClickRootConversation = (
  root: boolean,
  conversation: CommentType,
  openComments: boolean
) => void;
type toggleRootConversationPriorityType = (e: MouseEvent<HTMLElement>) => void;
