import React, { FC, ReactNode, useState } from "react";
import { MonetaryRangeType } from "../../utils/types";
import { InputNumber, Select } from "antd";
import {
  CurrencySymbolType,
  CurrencyType,
  UnitLabelType,
  UnitTitleType,
  UnitType,
  UnitValueType,
} from "../../utils/enums";
import { formatTotal } from "../../utils/allocation";
import { getContainerForFullScreen } from "../../utils/container";
import { anyPass, isEmpty, isNil } from "ramda";

const isNilOrEmpty = anyPass([isEmpty, isNil, (val) => val < 0.00000001]);

export const renderMonetaryRange: renderRangeType = ({
  max = 0,
  min = 0,
  unit,
  currency,
}) => {
  if (isNilOrEmpty(max) && isNilOrEmpty(min)) {
    return <span className={`text-muted text-xs`}>Not Specified</span>;
  } else if (!isNilOrEmpty(min) && isNilOrEmpty(max)) {
    return formatter(min, currency, unit) + "+";
  } else if (isNilOrEmpty(min) && !isNilOrEmpty(max)) {
    return formatter(max, currency, unit) + " or less";
  } else {
    return (
      formatter(min, currency, unit) + " - " + formatter(max, currency, unit)
    );
  }
};

export const formatter = (
  value = 0,
  currency = CurrencyType.DOLLAR,
  unit = UnitType.THOUSAND
) =>
  `${CurrencySymbolType[currency ?? CurrencyType.DOLLAR]}${
    value / UnitValueType[unit]
  }${UnitLabelType[unit]}`;

export const MonetaryRangeInput: FC<MonetaryRangeInputType> = ({
  onChange,
  value,
  disabled,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);

  return isEditMode ? (
    <div className={"flex items-center gap-x-2"}>
      <div className={"flex flex-row items-center gap-x-1"}>
        <InputNumber
          disabled={disabled}
          className={"!min-w-8 !max-w-none"}
          value={value?.min && value?.min / UnitValueType[value?.unit]}
          placeholder={"Min"}
          controls={false}
          formatter={(val): string => `${val && formatTotal(val)}`}
          status={
            (value?.max ?? Number.MAX_SAFE_INTEGER) <
            (value?.min ?? Number.MIN_SAFE_INTEGER)
              ? "error"
              : ""
          }
          min={0}
          onChange={(min) => {
            onChange({
              ...value,
              min: (min ?? 0) * UnitValueType[value?.unit],
            } as MonetaryRangeType);
          }}
        />
        -
        <InputNumber
          disabled={disabled}
          className={"!min-w-8 !max-w-none"}
          value={value?.max && value?.max / UnitValueType[value?.unit]}
          placeholder={"Max"}
          formatter={(val): string => `${val && formatTotal(val)}`}
          controls={false}
          status={
            (value?.max ?? Number.MAX_SAFE_INTEGER) <
            (value?.min ?? Number.MIN_SAFE_INTEGER)
              ? "error"
              : ""
          }
          onChange={(max) => {
            onChange({
              ...value,
              max: max ? max * UnitValueType[value?.unit] : null,
            } as MonetaryRangeType);
          }}
        />
      </div>
      <Select
        disabled={disabled}
        getPopupContainer={getContainerForFullScreen}
        onChange={(newUnit) => {
          if (value)
            onChange({
              ...value,
              min: value?.min
                ? (value?.min / UnitValueType[value?.unit]) *
                  UnitValueType[newUnit]
                : undefined,
              max: value?.max
                ? (value?.max / UnitValueType[value?.unit]) *
                  UnitValueType[newUnit]
                : undefined,
              unit: newUnit,
            });
        }}
        placeholder={"Unit"}
        value={value?.unit}
        options={Object.values(UnitType).map((value) => ({
          label: UnitTitleType[value],
          value,
        }))}
      />
      <Select
        disabled={disabled}
        onChange={(currency) =>
          onChange({ ...value, currency } as MonetaryRangeType)
        }
        getPopupContainer={getContainerForFullScreen}
        placeholder={"Currency"}
        value={value?.currency ?? CurrencyType.DOLLAR}
        options={Object.values(CurrencyType).map((value) => ({
          label: CurrencySymbolType[value],
          value,
        }))}
      />
    </div>
  ) : (
    <div
      className={"flex items-center gap-x-2 justify-between cursor-pointer"}
      onClick={() => !disabled && setIsEditMode(true)}
    >
      <div className={"px-2 py-1"}>{value && renderMonetaryRange(value)}</div>
      {!disabled && (
        <i
          className={`fa fa-pencil-alt text-transparent group-hover:text-primary cursor-pointer group-focus-within:text-primary right-0 bottom-0 mr-3`}
        />
      )}
    </div>
  );
};

type MonetaryRangeInputType = {
  value: MonetaryRangeType;
  onChange: (value: MonetaryRangeType) => void;
  disabled?: boolean;
};

type renderRangeType = (value: MonetaryRangeType) => ReactNode;
