import React, { FC, useEffect, useState } from "react";
import { fetchWithIdType, ResponseType } from "../../utils/uiTypes";
import { getCompanyInfo } from "../../services/services";
import {
  CompanyInfoType,
  PortfolioType,
  TeamType,
  TransactionType,
} from "../../utils/types";
import { CustomSpin } from "../general/CustomSpin";
import { isNil } from "ramda";
import { Collapse, Descriptions, List, Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const { Item } = Descriptions;

export const CompanyDetails: FC<CompanyDetailsType> = ({ id }) => {
  const [info, setInfo] = useState<CompanyInfoType | null>(null);

  const fetchCompanyDetails: fetchWithIdType = (id: string) => {
    getCompanyInfo({ segments: { id } })
      .then(({ data }: ResponseType<CompanyInfoType>) => {
        setInfo(data);
      })
      .catch(console.error);
  };

  useEffect(() => {
    setInfo(null);
    if (id) fetchCompanyDetails(id);
  }, [id]);

  return (
    <div
      className={
        "max-h-full max-w-full items-stretch justify-center flex flex-col gap-5"
      }
    >
      <Spin
        tip={"Loading Company Information..."}
        size={"large"}
        spinning={!info && !!id}
        indicator={<LoadingOutlined spin />}
      />
      {info && (
        <Space direction={"vertical"} className={"gap-y-2"}>
          <Collapse>
            <Collapse.Panel key={1} header={"Company Information"}>
              <Descriptions bordered column={1}>
                <Item label="Company Name">{info?.companyDTO?.name}</Item>
                <Item label="Team Members Count">
                  {info?.teamDTOList?.length ?? 0}
                </Item>
                <Item label="Transactions Count">
                  {info?.transactionDTOS?.length ?? 0}
                </Item>
                <Item label="Portfolios Count">
                  {info?.portfolios?.length ?? 0}
                </Item>
              </Descriptions>
            </Collapse.Panel>
            <Collapse.Panel
              key={2}
              header={"Team Members"}
              extra={`Count: ${info?.teamDTOList?.length ?? 0}`}
            >
              <List<TeamType>
                split={true}
                size={"large"}
                locale={{ emptyText: "No Team Members" }}
                dataSource={info?.teamDTOList ?? []}
                renderItem={({ userDTO }) => (
                  <div
                    className={
                      "flex flex-row items-center justify-between border-b"
                    }
                  >
                    <div>
                      {userDTO?.firstName}&nbsp;{userDTO?.lastName}
                    </div>
                    <div>{userDTO?.email}</div>
                  </div>
                )}
              />
            </Collapse.Panel>
            <Collapse.Panel
              key={3}
              header={"Transactions"}
              extra={`Count: ${info?.transactionDTOS?.length ?? 0}`}
            >
              <List<TransactionType>
                locale={{ emptyText: "No Transactions" }}
                split={true}
                size={"large"}
                dataSource={info?.transactionDTOS ?? []}
                renderItem={({ peTransactionTitle }) => (
                  <div
                    className={
                      "flex flex-row items-center justify-between border-b"
                    }
                  >
                    {peTransactionTitle}
                  </div>
                )}
              />
            </Collapse.Panel>
            <Collapse.Panel
              key={4}
              header={"Portfolios"}
              extra={`Count: ${info?.portfolios?.length ?? 0}`}
            >
              <List<PortfolioType>
                split={true}
                locale={{ emptyText: "No Portfolios" }}
                size={"large"}
                dataSource={info?.portfolios ?? []}
                renderItem={({ portfolioCompanyName }) => (
                  <div
                    className={
                      "flex flex-row items-center justify-between border-b"
                    }
                  >
                    {portfolioCompanyName}
                  </div>
                )}
              />
            </Collapse.Panel>
          </Collapse>
        </Space>
      )}
    </div>
  );
};

type CompanyDetailsType = {
  id?: string | null;
};
