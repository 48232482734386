import React, {
  FC,
  HTMLAttributes,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  FileElementType,
  FileType,
  TransactionMappingType,
  UserType,
} from "../../../../utils/types";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  Select,
  Table,
  Tooltip,
  Upload,
} from "antd";
import FolderIcon from "../../../../images/icons/FolderIcon.svg";
import { convertDateToFormat } from "../../../../utils/moment";
import { ResponseType } from "../../../../utils/uiTypes";
import {
  addFile,
  addFolder,
  deleteFile,
  downloadFolder,
  getAllPortfolioTransactionMapping,
  getDataRoom,
  renameFileOrFolder,
  searchFilesAndFolders,
} from "../../../../services/services";
import { assocPath, path } from "ramda";
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
import {
  removeItemFromArray,
  updateItemInArray,
  valOrDefault,
} from "../../../../utils/utils";
import { NewFolderForm } from "../../../forms/NewFolderForm";
import { HomeFilled } from "@ant-design/icons";
import { ElementType, PermissionType } from "../../../../utils/enums";
import { ConfirmDelete } from "../../../../utils/confirmationModals";
import { usePageTitle } from "../../../../customHooks/usePageTitle";
import { ACTION_BUTTON_CSS } from "../../../../utils/cssConfigs";
import { tableColumnHeader } from "../../../../utils/componentUtils";
import { FileIcon } from "../../../general/FileIcon";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { UploadFile } from "antd/es/upload/interface";
import { FileOrFolderRenameForm } from "../../../forms/FileOrFolderRenameForm";
import { TextOverFlowHandle } from "../../../general/TextOverFlowHandle";
import { useLocation } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { ColumnsType } from "antd/es/table";
import { UserContext } from "../../../../context/UserContext";
import { PortfolioAdminPermissionBlock } from "../../PortfolioAdminPermissionBlock";

export const PortfolioDataRoom: FC = function () {
  usePageTitle("Portfolio Documents");

  const { portfolioId } = useParams<{ portfolioId: string }>();
  const { user } = useContext(UserContext);
  const { height: windowHeight } = useWindowDimensions();
  const { search: transactionId, pathname } = useLocation();
  const history = useHistory();

  const [permissions, setPermissions] = useState<PermissionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTransactions, setLoadingTransactions] =
    useState<boolean>(false);
  const [files, setFiles] = useState<FileElementType[]>([]);
  const [currentUploadFiles, setCurrentUploadFiles] = useState<UploadFile[]>(
    []
  );
  const [currentTransaction, setCurrentTransaction] = useState<string | null>(
    new URLSearchParams(transactionId).get("transactionId") ?? null
  );
  const [{ location, currentPath, currentLocationStructure }, setNavigation] =
    useState<DataRoomFilesNavigationType>({
      location: [],
      currentLocationStructure: null,
      currentPath: [],
    });
  const [areResultsFiltered, setAreResultsFiltered] = useState<boolean>(false);

  const [portfolioTransactions, setPortfolioTransactions] = useState<
    TransactionMappingType[]
  >([]);

  const fetchPortfolioTransactions = (id: string): void => {
    setLoadingTransactions(true);
    getAllPortfolioTransactionMapping({
      segments: { id },
      params: { portfolioDataroom: true },
    })
      .then(({ data }: ResponseType<TransactionMappingType[]>) => {
        setPortfolioTransactions(data);
        setLoadingTransactions(false);
      })
      .catch(() => {
        setLoadingTransactions(false);
      });
  };
  const onFileUploadChange: onFileUploadChangeType = (
    petransactionId,
    location,
    file,
    fileList,
    parentFileElement
  ) => {
    setCurrentUploadFiles(fileList);
    if (file.status === "uploading") {
      message.loading({
        key: `uploading-${file.uid}`,
        content: `Uploading ${file.name}`,
        duration: 0,
        className: "bottom-0 right-0",
      });
    } else if (file.status === "done") {
      message.loading({
        key: `uploading-${file.uid}`,
        content: `Adding ${file.name} to your location`,
        duration: 2,
      });
      addNewFileToData(
        petransactionId,
        file.response,
        location,
        parentFileElement
      ).then(() => {
        message.success({
          key: `uploading-${file.uid}`,
          content: `Successfully added ${file.name} to your location.`,
          duration: 3,
        });
      });
    } else if (file.status === "error") {
      message.error({
        key: `uploading-${file.uid}`,
        content: `Error Uploading ${file.name}`,
        duration: 3,
      });
    }
  };

  const addNewFileToData: addNewFileToDataType = (
    petransactionId,
    file,
    location,
    parentFileElement
  ) => {
    return new Promise((resolve, reject) => {
      addFile({
        headers: {
          Accept: ["application/json", "text/plain", "*/*"],
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          fileDTO: file,
          fileElementType: ElementType.PORTFOLIO,
          fileURL: file.fileURL,
          isFolder: false,
          name: file.fileName,
          parentFileElement,
          petransactionId,
        }),
      })
        .then(({ data }: ResponseType<FileElementType>) => {
          setFiles((files) =>
            assocPath(
              location,
              [
                ...(valOrDefault(
                  [],
                  path(location, files)
                ) as Array<FileElementType>),
                { ...data, fileId: data?.fileDTO?.fileId },
              ],
              files
            )
          );
        })
        .then(() => resolve())
        .catch((error: string) => {
          setLoading(false);
          reject(
            valOrDefault(
              `Error uploading file "${file.fileName}" to your location`,
              error
            )
          );
        });
    });
  };

  const deleteFileFromData: deleteFileFromData = (
    transactionId,
    fileId,
    index,
    location
  ) => {
    deleteFile({
      segments: {
        transactionId,
        fileId,
      },
    })
      .then(() => {
        setFiles((files) =>
          assocPath(
            location,
            [
              ...valOrDefault(
                [],
                removeItemFromArray(
                  index,
                  valOrDefault(
                    [],
                    path(location, files) as Array<FileElementType>
                  )
                )
              ),
            ],
            files
          )
        );
      })
      .catch((error: string) => {
        message.error(valOrDefault("Error deleting file from server", error));
      });
  };

  const addFolderToFileStructure: addFolderToFileStructureType = (
    name,
    petransactionId,
    location,
    currentLocationStructure
  ) => {
    setLoading(true);
    addFolder({
      body: JSON.stringify({
        fileElementType: ElementType.PORTFOLIO,
        isFolder: true,
        name,
        petransactionId,
        parentFileElement:
          location.length > 2 && currentLocationStructure
            ? { elementId: currentLocationStructure?.elementId }
            : undefined,
      }),
    })
      .then(({ data }: ResponseType<FileElementType>) => {
        setFiles((files) =>
          assocPath(
            location,
            [
              ...(valOrDefault(
                [],
                path(location, files)
              ) as Array<FileElementType>),
              data,
            ],
            files
          )
        );
      })
      .then(() => {
        setLoading(false);
      })
      .catch((error: string) => {
        setLoading(false);
        message.error(valOrDefault("Error uploading folder to server", error));
      });
  };

  const rename: renameType = (
    name,
    fileData,
    index,
    petransactionId,
    location
  ) => {
    renameFileOrFolder({
      body: JSON.stringify({
        name,
        elementId: fileData.elementId,
        petransactionId,
      }),
    })
      .then(() => {
        const currentData: Array<FileElementType> = path(
          location,
          files
        ) as Array<FileElementType>;
        setFiles((files) =>
          assocPath(
            location,
            updateItemInArray(index, currentData, { ...fileData, name }),
            files
          )
        );
      })
      .catch((error: string) => {
        message.error(valOrDefault("Error renaming the file", error));
      });
  };

  const fetchDataRoom: fetchDataRoomType = (id) => {
    getDataRoom({
      segments: {
        id,
        type: "getFoldersByIdInPortfolio",
      },
    })
      .then(({ data = [] }: ResponseType<FileElementType[]>) => {
        setFiles(data);
        setNavigation({
          location: [0, "childFileElements"],
          currentLocationStructure: path([0], data) as FileElementType,
          currentPath: ["Home"],
        });
        setAreResultsFiltered(false);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((error: string) => {
        message.error(valOrDefault("Error fetching files and folders", error));
        setLoading(false);
      });
  };

  const searchFilesInDataRoom: fetchFileStructureType = (id, search) => {
    searchFilesAndFolders({
      segments: {
        id,
      },
      params: {
        search,
      },
    })
      .then(({ data = [] }: ResponseType<FileElementType[]>) => {
        setFiles(data);
        setNavigation({
          location: [0, "childFileElements"],
          currentLocationStructure: path([0], data) as FileElementType,
          currentPath: ["Home"],
        });
        setAreResultsFiltered(true);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        message.error("Error Searching File or Folder");
        setLoading(false);
      });
  };

  const fetchFileStructure: fetchFileStructureType = (id, search) => {
    setLoading(true);
    setAreResultsFiltered(false);

    search ? searchFilesInDataRoom(id, search) : fetchDataRoom(id);
  };

  const initiateFolderDownload: initiateFileDownloadType = (id, elementId) => {
    message.loading({
      content: "Processing Folder",
      duration: 0,
      key: "download-" + elementId,
    });
    downloadFolder({
      segments: {
        elementId,
        id,
      },
      params: currentTransaction ? { portfolioId } : null,
    })
      .then(() => {
        message.info({
          content: "Folder Link sent successfully sent to your email.",
          key: "download-" + elementId,
        });
      })
      .catch((error: string) => {
        message.error({
          content: valOrDefault("Error Downloading Folder!", error),
          key: "download-" + elementId,
        });
      });
  };

  const navigateIntoFolder: navigateIntoFolderType = (
    file,
    index,
    location
  ): void => {
    if (file.isFolder) {
      setNavigation(({ currentPath }) => ({
        location: [...location, index, "childFileElements"],
        currentLocationStructure: path(
          [...location, index],
          files
        ) as FileElementType,
        currentPath: [...currentPath, file?.name],
      }));
    }
  };

  const columns: ColumnsType<FileElementType> = [
    {
      title: tableColumnHeader("Name"),
      align: "left" as const,
      key: "name",
      dataIndex: "name",
      width: "30%",
      className: "group-hover:bg-blue-50 min-w-[85px] max-w-[300px]",
      render: function display(
        name: string,
        fileData: FileElementType
      ): ReactNode {
        return (
          <div className={`flex flex-row gap-x-2 items-center`}>
            {fileData.isFolder ? (
              <img
                alt={""}
                src={FolderIcon}
                style={{ height: 35, width: 35 }}
              />
            ) : (
              <FileIcon name={name} />
            )}
            {fileData.isFolder ? (
              <TextOverFlowHandle text={name} />
            ) : (
              <a
                rel={"noreferrer"}
                href={`/api/rest/media/${
                  fileData.fileId ?? fileData.fileDTO?.fileId
                }?portfolioId=${portfolioId}&transactionId=${
                  currentTransaction ?? portfolioId
                }`}
                target={"_blank"}
                className={
                  "hover:underline underline-offset-2 text-ellipsis overflow-hidden flex flex-wrap"
                }
              >
                <TextOverFlowHandle text={name} />
              </a>
            )}
          </div>
        );
      },
    },
    {
      title: tableColumnHeader("Last Edited"),
      align: "left" as const,
      key: "modifiedDate",
      dataIndex: "modifiedDate",
      width: "10%",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: (data: string): ReactNode =>
        convertDateToFormat(data, "D.MMM.YY", false),
    },
    {
      title: tableColumnHeader("Created By"),
      align: "left" as const,
      key: "createdByUserDTO",
      dataIndex: "createdByUserDTO",
      width: "15%",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: (data: UserType): ReactNode =>
        data && data.firstName + " " + data.lastName,
    },
    {
      title: tableColumnHeader("Actions"),
      width: "20%",
      dataIndex: "elementId",
      key: "elementId",
      align: "right" as const,
      className: "group-hover:bg-blue-50",
      render: function actions(
        fileId: string,
        fileData: FileElementType,
        index: number
      ): ReactNode {
        return (
          <div
            className={"relative flex flex-row items-center"}
            onClick={(e): void => e.stopPropagation()}
          >
            <Button
              type={"text"}
              icon={<i className="fas fa-ellipsis-h" />}
              className={
                "absolute mx-1 right-0 border-0 px-2 text-gray-900 opacity-50 group-hover:opacity-0"
              }
            />
            <div
              className={
                "shrink flex flex-row gap-x-2 items-center opacity-0 group-hover:opacity-100 ml-auto"
              }
            >
              {!currentTransaction && (
                <PortfolioAdminPermissionBlock permissions={permissions}>
                  <Tooltip title={"Delete"}>
                    <Button
                      className={`${ACTION_BUTTON_CSS} hover:text-red-500 opacity-0 group-hover:opacity-100`}
                      icon={<i className="fas fa-trash-alt" />}
                      onClick={(): void => {
                        ConfirmDelete(
                          `Confirm Delete ${
                            fileData.isFolder ? "Folder" : "File"
                          }?`,
                          () => {
                            deleteFileFromData(
                              portfolioId,
                              fileId,
                              index,
                              location
                            );
                          },
                          `${fileData.isFolder ? "Folder" : "File"} Name: ${
                            fileData.name
                          }`
                        );
                      }}
                    />
                  </Tooltip>
                </PortfolioAdminPermissionBlock>
              )}
              {!currentTransaction && (
                <PortfolioAdminPermissionBlock permissions={permissions}>
                  <FileOrFolderRenameForm
                    isFolder={valOrDefault(false, fileData.isFolder)}
                    formData={{ name: fileData.name }}
                    onSubmit={({ name }): void => {
                      rename(name, fileData, index, portfolioId, location);
                    }}
                  />
                </PortfolioAdminPermissionBlock>
              )}
              <Tooltip
                title={fileData.isFolder ? "Download Folder" : "Download File"}
              >
                {fileData.isFolder ? (
                  <Button
                    className={`${ACTION_BUTTON_CSS} opacity-0 group-hover:opacity-100 hover:text-green-500`}
                    icon={<i className="fas fa-download" />}
                    onClick={(): void => {
                      initiateFolderDownload(
                        currentTransaction ?? portfolioId,
                        fileData.elementId
                      );
                    }}
                  />
                ) : (
                  <Button
                    className={`${ACTION_BUTTON_CSS} opacity-0 group-hover:opacity-100 hover:text-green-500`}
                    icon={<i className="fas fa-download" />}
                    rel={"noreferrer"}
                    href={`/api/rest/media/${
                      fileData.fileId ?? fileData.fileDTO?.fileId
                    }?portfolioId=${portfolioId}&transactionId=${
                      currentTransaction ?? portfolioId
                    }`}
                    target={"_blank"}
                  />
                )}
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchPortfolioTransactions(portfolioId);
    setPermissions(
      user?.elementPermissions?.find(
        (item) => item?.elementType === ElementType.PORTFOLIO
      )?.permissions ?? []
    );
  }, [portfolioId, user]);

  useEffect(() => {
    if (currentTransaction) {
      fetchFileStructure(currentTransaction);
    } else {
      fetchFileStructure(portfolioId);
    }
  }, [portfolioId, currentTransaction]);

  return (
    <div className={"w-full"}>
      <div className={"p-6 h-full"}>
        <div className={"flex flex-col lg:flex-row items-start gap-3"}>
          <div className={"flex flex-col w-full lg:w-5/12"}>
            <Input.Search
              loading={loading}
              placeholder={"Search File/Folder by Name"}
              className={"w-full "}
              allowClear={true}
              onSearch={(value): void => {
                fetchFileStructure(currentTransaction ?? portfolioId, value);
              }}
            />
          </div>
          <PortfolioAdminPermissionBlock permissions={permissions}>
            <>
              {!currentTransaction && (
                <div
                  className={
                    "mx-auto lg:mr-0 flex flex-col sm:flex-row items-center gap-2"
                  }
                >
                  <NewFolderForm
                    onSubmit={({ name }): void => {
                      addFolderToFileStructure(
                        name,
                        portfolioId,
                        location,
                        currentLocationStructure
                      );
                    }}
                  />
                  <Upload
                    disabled={loading}
                    fileList={currentUploadFiles}
                    type={"select"}
                    action={"/api/rest/media/uploadFile/1"}
                    listType={"text"}
                    showUploadList={false}
                    maxCount={15}
                    multiple={true}
                    beforeUpload={(): void => {
                      setCurrentUploadFiles([]);
                    }}
                    onChange={({ file, fileList }): void => {
                      onFileUploadChange(
                        portfolioId,
                        location,
                        file,
                        fileList,
                        location.length > 2 && currentLocationStructure
                          ? { elementId: currentLocationStructure?.elementId }
                          : undefined
                      );
                    }}
                  >
                    <Button
                      disabled={loading}
                      className={
                        "text-white w-full bg-primary hover:bg-hover text-white border-0"
                      }
                      icon={<i className="fas fa-upload mr-2" />}
                    >
                      Upload Files (Max 15)
                    </Button>
                  </Upload>
                </div>
              )}
            </>
          </PortfolioAdminPermissionBlock>
        </div>
        <div
          className={"p-2 flex flex-row items-center gap-x-3 justify-between"}
        >
          <div className={"flex flex-row items-center"}>
            <HomeFilled
              className={"text-blue-500 cursor-pointer hover:shadow"}
              onClick={(): void => {
                setNavigation({
                  location: location.slice(0, 2),
                  currentLocationStructure: path([0], files) as FileElementType,
                  currentPath: currentPath.slice(0, 1),
                });
              }}
            />
            &nbsp;&nbsp;
            <Breadcrumb className={"text-lg flex flex-wrap"}>
              {currentPath?.map((name, index) => (
                <BreadcrumbItem
                  key={`${name}-${index}`}
                  className={`${
                    index === currentPath?.length - 1
                      ? "text-blue-500 cursor-default"
                      : "cursor-pointer"
                  }`}
                  onClick={(): void => {
                    setNavigation(({ location, currentPath }) => ({
                      location: location.slice(0, (index + 1) * 2),
                      currentLocationStructure: path(
                        [...location.slice(0, (index + 1) * 2 - 1)],
                        files
                      ) as FileElementType,
                      currentPath: currentPath.slice(0, index + 1),
                    }));
                  }}
                >
                  {name}
                </BreadcrumbItem>
              ))}
            </Breadcrumb>
          </div>
          <div className={"flex-initial flex-row items-center gap-2"}>
            View Linked Data Room&nbsp;&nbsp;
            <Select
              value={currentTransaction ?? null}
              disabled={loadingTransactions}
              loading={loadingTransactions}
              className={"w-48"}
              defaultValue={portfolioId}
              onChange={(value): void => {
                setCurrentTransaction(value);
                const params = new URLSearchParams();
                value && params.append("transactionId", value);

                history.push({
                  pathname: pathname,
                  search: params.toString(),
                });
              }}
              options={[
                { value: null, label: "Home" },
                ...portfolioTransactions.map((value) => ({
                  label: value.peTransactionTitle,
                  value: value.peTransactionId,
                })),
              ]}
            />
          </div>
        </div>

        {areResultsFiltered && (
          <div
            className={
              "flex w-full bg-emerald-500 p-1 text-xs text-white border border-b-0 border-gray-200"
            }
          >
            Showing Filtered Results...
          </div>
        )}
        <Table
          loading={loading}
          locale={{
            emptyText: (
              <span className={"select-none"}>
                No files have been shared yet!
              </span>
            ),
          }}
          className={"border"}
          dataSource={path(location, files)}
          columns={columns}
          pagination={false}
          rowKey={"elementId"}
          rowClassName={(fileData): string =>
            `group editable-row hover:bg-blue-50 ${
              fileData.isFolder ? "cursor-pointer" : "cursor-default"
            }`
          }
          scroll={{ x: true, y: windowHeight - 320 }}
          onRow={(fileData, index): HTMLAttributes<HTMLElement> => ({
            onClick: (): void => {
              navigateIntoFolder(fileData, valOrDefault(0, index), location);
            },
          })}
        />
      </div>
    </div>
  );
};

type locationType = (string | number)[];
type fetchDataRoomType = (id: string) => void;
type fetchFileStructureType = (transactionId: string, search?: string) => void;
type renameType = (
  name: string,
  fileData: FileElementType,
  index: number,
  petransactionId: string,
  location: locationType
) => void;
type initiateFileDownloadType = (transactionId: string, fileId: string) => void;
type addFolderToFileStructureType = (
  name: string,
  petransactionId: string,
  location: locationType,
  currentLocationStructure: FileElementType | null
) => void;
type deleteFileFromData = (
  transactionId: string,
  fileId: string,
  index: number,
  location: locationType
) => void;
type addNewFileToDataType = (
  petransactionId: string,
  file: FileType,
  location: locationType,
  parentFileElement?: { elementId: string }
) => Promise<void>;
type addFilesToDataType = (
  petransactionId: string,
  file: UploadFile[],
  location: locationType,
  parentFileElement?: { elementId: string }
) => void;
type navigateIntoFolderType = (
  file: FileElementType,
  index: number,
  location: locationType
) => void;
type DataRoomFilesNavigationType = {
  location: locationType;
  currentPath: string[];
  currentLocationStructure: FileElementType | null;
};
type onFileUploadChangeType = (
  petransactionId: string,
  location: locationType,
  file: UploadFile,
  files: UploadFile[],
  parentFileElement?: { elementId: string }
) => void;
