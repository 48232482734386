import React, { FC, useCallback, useRef, useState } from "react";
import { isAgreementResponse } from "../../../utils/termsheet";
import { valOrDefault } from "../../../utils/utils";
import { HeightWidthMapType } from "../../../utils/newTermsheet";
import { CompareColumnsType } from "../../../utils/types";
import { ColumnHeaderCell } from "../common/ColumnHeaderCell";
import RowIndexCell from "../common/RowIndexCell";
import Cell from "../common/Cell";
import { isEmpty } from "ramda";
import { useIntersectionView } from "../../../customHooks/useIntersectionView";
import Row from "../common/Row";
import {
  CompareSheetSelectedCell,
  ComparisonColumnType,
} from "../../../utils/comparison-sheet";
import { CompareColumnsEditableType } from "./ComparisonSheet";
import moment from "moment";

export const ComparisonSheetRows: FC<ComparisonSheetRowsType> = function ({
  redLine = false,
  heatMap = false,
  data,
  rows,
  selectedCell,
  onSelectCell,
  onChange,
}: ComparisonSheetRowsType) {
  const ref = useRef();
  const [columnWidth, setColumnWidth] = useState<HeightWidthMapType>({});

  const onSelect = useCallback(
    (i: any, _: any, elementId: any) => {
      const type =
        elementId === ComparisonColumnType?.BASE
          ? ComparisonColumnType.BASE
          : elementId === ComparisonColumnType?.METRICS
          ? ComparisonColumnType.METRICS
          : ComparisonColumnType.COLUMNS;
      onSelectCell({
        rowIndex: i,
        columnIndex:
          type === ComparisonColumnType.COLUMNS ? Number(elementId) : undefined,
        type,
      });
    },
    [data]
  );

  const [minIndex, maxIndex] = useIntersectionView(
    ".termsheet-row",
    ref.current,
    [data]
  );

  return (
    <>
      <div className={"sticky top-0 z-[90] bg-gray-100 table-row"}>
        <div className={`flex border-b border-gray-200 z-[10]`}>
          <div className={"sticky left-0 z-[100]"}>
            <div
              className={
                "h-full w-10 min-w-[2.5rem] text-xxs border-gray-200 bg-gray-50 text-gray-400"
              }
            />
          </div>
          <div className={"border-l border-r"}>
            <ColumnHeaderCell
              selected={selectedCell?.type === ComparisonColumnType.METRICS}
              width={valOrDefault(
                300,
                columnWidth[ComparisonColumnType.METRICS]
              )}
              content={"Bid Metrics"}
              onSelect={() => {}}
              onWidthChange={(o) => {
                setColumnWidth((columnWidth) => ({
                  ...columnWidth,
                  [ComparisonColumnType.METRICS]: o,
                }));
              }}
            />
          </div>
          <div className={"border-r"}>
            <ColumnHeaderCell
              selected={selectedCell?.type === ComparisonColumnType.BASE}
              width={valOrDefault(300, columnWidth[ComparisonColumnType.BASE])}
              content={`${data.base.label} - ${moment(data.base.date).format(
                "LL"
              )}`}
              onSelect={() => {}}
              onWidthChange={(o) => {
                setColumnWidth({
                  ...columnWidth,
                  [ComparisonColumnType.BASE]: o,
                });
              }}
            />
          </div>
          {data.columns.map((v, i) => {
            const selectedColumn =
              selectedCell?.type === ComparisonColumnType.COLUMNS &&
              selectedCell?.columnIndex === i;
            return (
              <div key={i} className={"border-r"}>
                <ColumnHeaderCell
                  onSelect={() => {}}
                  selected={selectedColumn}
                  width={valOrDefault(300, columnWidth[i])}
                  content={`${v.label} - ${moment(v.date).format("LL")}`}
                  onWidthChange={(o) => {
                    setColumnWidth({
                      ...columnWidth,
                      [i]: o,
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        ref={ref.current}
        className={"border-l border-gray-200 table-row-group scroll-smooth"}
      >
        {Array(rows)
          .fill(0)
          .map((_, i) => {
            return (
              <div key={i} data-rowindex={i} className={"termsheet-row"}>
                <Row key={i} id={i.toString()} index={i}>
                  <div
                    className={`flex bg-white border-b border-gray-200 z-[10] min-h-[35px]`}
                  >
                    <RowIndexCell
                      position={i + 1}
                      selected={selectedCell?.rowIndex === i}
                      onSelect={() => {
                        onSelectCell({
                          type: ComparisonColumnType.COLUMNS,
                          rowIndex: i,
                        });
                      }}
                    />
                    {i >= minIndex && i <= maxIndex + 35 && (
                      <>
                        <div className={"bg-white border-r"}>
                          <Cell
                            index={i}
                            selectedCell={
                              selectedCell?.rowIndex === i &&
                              selectedCell?.type ===
                                ComparisonColumnType.METRICS
                            }
                            selectable={true}
                            elementId={ComparisonColumnType.METRICS}
                            style={JSON.stringify(data.metrics.data[i]?.style)}
                            text={data.metrics.data[i]?.text}
                            width={valOrDefault(
                              300,
                              columnWidth[ComparisonColumnType.METRICS]
                            )}
                            onChange={() => {}}
                            onSelect={onSelect}
                          />
                        </div>
                        <div className={"bg-white border-r"}>
                          <Cell
                            index={i}
                            selectedCell={
                              selectedCell?.rowIndex === i &&
                              selectedCell?.type === ComparisonColumnType.BASE
                            }
                            onChange={() => {}}
                            selectable={true}
                            elementId={ComparisonColumnType.BASE}
                            style={JSON.stringify(data.base.data[i]?.style)}
                            text={data.base.data[i]?.text}
                            width={valOrDefault(
                              300,
                              columnWidth[ComparisonColumnType.BASE]
                            )}
                            onSelect={onSelect}
                          />
                        </div>
                        {data.columns.map((v, index) => {
                          const dealTeamAsk = valOrDefault(
                            "",
                            data.base.data[i]?.text
                          );
                          const text = valOrDefault(
                            "",
                            data.columns[index].data[i]?.text
                          );
                          const style = valOrDefault(
                            {},
                            data.columns[index].data[i]?.style
                          );
                          return (
                            <div key={index} className={"bg-white border-r"}>
                              <Cell
                                selectedCell={
                                  selectedCell?.rowIndex === i &&
                                  selectedCell?.type ===
                                    ComparisonColumnType.COLUMNS &&
                                  selectedCell?.columnIndex === index
                                }
                                index={i}
                                onChange={() => {}}
                                elementId={index.toString()}
                                selectable={true}
                                showRedLine={
                                  redLine && !isAgreementResponse(text)
                                }
                                style={JSON.stringify(style)}
                                text={text}
                                width={valOrDefault(300, columnWidth[""])}
                                heatMap={
                                  heatMap && !isEmpty(dealTeamAsk.trim())
                                }
                                compareText={dealTeamAsk}
                                onSelect={onSelect}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </Row>
              </div>
            );
          })}
      </div>
    </>
  );
};

type ComparisonSheetRowsType = {
  redLine?: boolean;
  heatMap: boolean;
  rows: number;
  data: CompareColumnsEditableType;
  selectedCell: CompareSheetSelectedCell | null;
  onSelectCell: (cell: CompareSheetSelectedCell) => void;
  onChange: (value: CompareColumnsType) => void;
};
