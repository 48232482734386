import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Upload,
} from "antd";
import { useLocation, useParams } from "react-router";
import { UserContext } from "../../context/UserContext";
import {
  getModalContainerForFullScreen,
  getPopupContainerForSelect,
} from "../../utils/container";
import {
  UploadOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import {
  addContacts,
  getAllTeamMembers,
  getDealsForNotes,
  getExternalContacts,
  getPortfoliosForNotes,
  addFile,
} from "../../services/services";
import { columnSort, isAdmin } from "../../utils/relationship";
import { CreateNewUserModal } from "../relationship/NewUserModal";
import moment from "moment";
import {
  CrmDealActivity,
  NoteDataType,
  PortfolioType,
  TeamMemberType,
  UserType,
} from "../../utils/types";
import { ResponseType } from "../../utils/uiTypes";
import { externalAttendeesType, updateNotesType } from "../relationship/Notes";
import { ElementType } from "../../utils/enums";
import { CustomAvatar } from "../general/CustomAvatar";
import { getPortfolioType } from "../../utils/portfolio";

const { TextArea } = Input;

const interactionTypes = [
  { id: "Call", label: "Call" },
  { id: "Email", label: "Email" },
  { id: "Meeting", label: "Meeting" },
  { id: "Meal", label: "Meal" },
];

const AddNotesModal: React.FC<AddNotesModalProps> = ({
  notesModalVisible,
  setNotesModalVisible,
  updateNotes,
  selectedNote,
  setUserModalVisible,
  userModalvisible,
  setSelectedNote,
  setFileUploading,
  fileUploading,
}) => {
  const [form] = Form.useForm();
  const [loadSelectors, setLoadSelectors] = useState<selectorsType>({
    externalContacts: false,
    internalContacts: false,
    deals: false,
    portfolios: false,
  });
  const { user } = useContext(UserContext);

  const [externalCompanyMembers, setExternalCompanyMembers] = useState<
    externalMembersType[]
  >([]);
  const [dealActivity, setDealActivity] = useState<CrmDealActivity[]>([]);
  const { search } = useLocation();
  const [portfolioActivity, setPortfolioActivity] = useState<PortfolioType[]>(
    []
  );
  const [interactionDate, setInteractionDate] = useState<any>(null);
  const [internalCompanyMembers, setInternalCompanyMembers] = useState<
    TeamMemberType[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState<any[]>([]);
  const disabled =
    selectedNote?.elementType === ElementType.PETRANSACTION || !isAdmin(user);

  const { id } = useParams<paramsType>();

  const fetchExternalAttendees = (): void => {
    setLoadSelectors((prev) => ({ ...prev, externalContacts: true }));

    getExternalContacts({ segments: { id } })
      .then(({ data }: ResponseType<externalMembersType[]>) => {
        setExternalCompanyMembers(data);
        setLoadSelectors((prev) => ({ ...prev, externalContacts: false }));
      })
      .catch(() => {
        message.error("Error Fetching Portfolio Data!", 5);
        setLoadSelectors((prev) => ({ ...prev, externalContacts: false }));
      });
  };

  const fetchDeals = (): void => {
    setLoadSelectors((prev) => ({ ...prev, deals: true }));
    getDealsForNotes({})
      .then(({ data }: ResponseType<CrmDealActivity[]>) => {
        setDealActivity(data);
        setLoadSelectors((prev) => ({ ...prev, deals: false }));
      })
      .catch(() => {
        setLoadSelectors((prev) => ({ ...prev, deals: false }));
        message.error("Error Fetching Deal Activity Data!");
      });
  };

  const fetchPortfolios = (): void => {
    setLoadSelectors((prev) => ({ ...prev, portfolios: true }));
    getPortfoliosForNotes({
      params: {
        type: getPortfolioType(user?.companyDTO?.companyType),
      },
    })
      .then(({ data }: ResponseType<PortfolioType[]>) => {
        setPortfolioActivity(data);
        setLoadSelectors((prev) => ({ ...prev, portfolios: false }));
      })
      .catch(() => {
        message.error("Error Fetching Allocations Summary!", 5);
        setLoadSelectors((prev) => ({ ...prev, portfolios: false }));
      });
  };

  const fetchInternalAttendees = (): void => {
    setLoadSelectors((prev) => ({ ...prev, internalContacts: true }));

    getAllTeamMembers({})
      .then(({ data }: ResponseType<TeamMemberType[]>) => {
        setInternalCompanyMembers(data);
        setLoadSelectors((prev) => ({ ...prev, internalContacts: false }));
      })
      .catch(() => {
        message.error("Error Fetching Portfolio Data!", 5);
        setLoadSelectors((prev) => ({ ...prev, internalContacts: false }));
      });
  };

  const onSave = (values: Record<string, externalAttendeesType>) => {
    setLoading(true);
    addContacts({
      params: {
        mappingId: id,
      },
      body: JSON.stringify(values),
    })
      .then(({ data }: { data: externalAttendeesType }) => {
        setLoading(false);
        setExternalCompanyMembers((prev) => [data, ...prev]);
        message.success("External user added successfully");
        setUserModalVisible(false);
      })
      .catch((error: string) => {
        setLoading(false);
        message.error(error ? error : "Something went wrong. Please try again");
      });
  };

  const onUpdate = (values: NoteDataType) => {
    const format = selectedNote?.fileElements?.map((item: any) => {
      return {
        elementId: item.elementId,
        name: item.name,
        level: item.level,
        deleted: item.deleted,
        isFolder: item.isFolder,
        fileDTO: {
          elementId: item.fileDTO.elementId,
          fileId: item.fileDTO.fileId,
          fileName: item.fileDTO.fileName,
          fileURL: item.fileDTO.fileURL,
          bucketName: item.fileDTO.bucketName,
          objectKey: item.fileDTO.objectKey,
          byteSize: item.fileDTO.byteSize,
        },
      };
    });

    values = {
      ...values,
      notesExtension: {
        ...values.notesExtension,
        interactionDate: values?.interactionDate?.valueOf(),
        interactionAttendeesExternal: values?.interactionAttendeesExternal,
        interactionAttendeesInternal: values.interactionAttendeesInternal,
        interactionType: values.interactionType,
        purpose: values.purpose,
        dealName: values.dealName || values.purpose,
        externalUsers: values.externalUsers,
      },
      fileElements: selectedNote ? format : attachments,
    };
    updateNotes(values);
  };

  const onSaveAttachment = (file: any) => {
    setFileUploading(true);
    if (file) {
      if (selectedNote) {
        addFile({
          body: JSON.stringify({
            fileDTO: file,
            fileElementType: ElementType.NOTE,
            fileURL: file?.fileURL,
            isFolder: false,
            name: file.fileName,
            petransactionId: selectedNote?.id,
            objectKey: file.objectKey,
          }),
        })
          .then(({ data }: ResponseType<any>) => {
            setFileUploading(false);
            const _selectedNote = [...(selectedNote?.fileElements || []), data];
            if (selectedNote) {
              setSelectedNote({ ...selectedNote, fileElements: _selectedNote });
            }
          })
          .catch((error: string) => {
            message.error(error ?? "Something went wrong.", 3);
            setFileUploading(false);
          });
      } else {
        setFileUploading(false);
        const format = {
          fileDTO: {
            fileName: file.fileName,
            fileURL: file.fileURL,
            bucketName: file?.bucketName,
            objectKey: file.objectKey,
            byteSize: file.byteSize,
            contentType: file.contentType,
          },
          fileElementType: "NOTE",
          fileURL: file.fileURL,
          isFolder: false,
          name: file.fileName,
        };
        setAttachments([...attachments, format]);
      }
    }
  };

  const onDeleteAttachment = (file: any) => {
    if (selectedNote) {
      const elements = selectedNote?.fileElements?.filter((item: any) => {
        return (
          item?.fileDTO?.objectKey !== file?.response?.objectKey &&
          file?.fileDTO?.objectKey !== item?.fileDTO?.objectKey
        );
      });

      setSelectedNote((prev: any) => ({ ...prev, fileElements: elements }));
    } else {
      const attachmentsCopy = attachments.filter((item) => {
        return item.fileDTO.objectKey !== file.response.objectKey;
      });
      setAttachments(attachmentsCopy);
    }
  };

  const formReset = () => {
    setSelectedNote(null);
    form.resetFields();
    setNotesModalVisible(false);
    setFileUploading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        fetchInternalAttendees(),
        fetchExternalAttendees(),
        fetchDeals(),
        fetchPortfolios(),
      ])
        .then(() => setLoading(false))
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching data:", error);
        });
    };

    fetchData();
  }, [id, search]);
  const beforeUpload = (file: any) => {
    const isLt10M = file.size / 1024 / 1024 <= 10;
    if (!isLt10M) {
      message.error("File must be smaller than 10MB!");
    }
    return isLt10M || Upload.LIST_IGNORE;
  };

  useEffect(() => {
    form.setFieldsValue({
      interactionDate:
        selectedNote &&
        +selectedNote.notesExtension?.interactionDate &&
        moment(new Date(+selectedNote.notesExtension?.interactionDate)),
      interactionAttendeesInternal:
        selectedNote?.notesExtension?.interactionAttendeesInternal.map(
          (item: UserType) => item.userId
        ),
      interactionType: selectedNote?.notesExtension?.interactionType,
      note: selectedNote?.note,
      purpose: selectedNote?.notesExtension?.purpose,
      dealName: selectedNote?.notesExtension?.dealName,
      interactionAttendeesExternal:
        selectedNote?.notesExtension?.interactionAttendeesExternal.map(
          (item: externalAttendeesType) => item.id
        ),
      portfolio: selectedNote?.notesExtension?.dealName,
      externalUsers: selectedNote?.notesExtension?.externalUsers,
    });
    // setSelectedNote({
    //   ...selectedNote,
    //   fileElements: selectedNote?.fileElements,
    // });
  }, [selectedNote]);

  return (
    <>
      <Modal
        getContainer={getModalContainerForFullScreen}
        closable
        open={notesModalVisible}
        onCancel={() => formReset()}
        title={selectedNote ? "Update Note" : "Add Note"}
        width="50%"
        destroyOnClose
        afterClose={() => formReset()}
        footer={[
          <Button
            key="Notesclose"
            disabled={disabled || fileUploading}
            onClick={() => formReset()}
          >
            Close
          </Button>,
          <Button
            key="Notescreate"
            className={PRIMARY_BUTTON_STYLE}
            onClick={() => form.submit()}
            loading={fileUploading}
            disabled={disabled || fileUploading}
          >
            {selectedNote ? "Update Note" : "Add Note"}
          </Button>,
        ]}
      >
        {disabled && isAdmin(user) && (
          <span className="text-red-500">
            This note is linked to a deal or portfolio company and cannot be
            edited here. Please navigate to the associated deal or portfolio
            company page to make changes.
          </span>
        )}
        <Form
          layout="vertical"
          onFinish={(values) => onUpdate(values)}
          form={form}
          className="flex flex-col gap-y-3 h-full"
          onValuesChange={(changedValues) => {
            if (
              changedValues.purpose === "DEAL" ||
              changedValues.purpose === "PORTFOLIO"
            ) {
              form.setFieldsValue({
                portfolio: undefined,
                dealName: undefined,
              });
            }
          }}
        >
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                className="m-0 my-2 border-0"
                label="Interaction date"
                name="interactionDate"
              >
                <DatePicker
                  className="w-full"
                  placeholder="Interaction date"
                  disabled={disabled}
                  value={interactionDate ?? undefined}
                  onChange={(val) => {
                    if (val) {
                      setInteractionDate(val);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="m-0 my-2 border-0"
                label="Interaction type"
                name="interactionType"
              >
                <Select
                  placeholder="Select Interaction Type"
                  disabled={disabled}
                  showSearch
                >
                  {interactionTypes.map(({ id, label }) => (
                    <Select.Option key={id} value={id}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                className="m-0 my-2 border-0"
                label="Purpose"
                name="purpose"
              >
                <Select disabled={disabled} placeholder="Purpose" showSearch>
                  <Select.Option value="DEAL">Deal</Select.Option>
                  <Select.Option value="PORTFOLIO">Portfolio</Select.Option>
                  <Select.Option value="OTHER">Other</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => (
                  <>
                    {getFieldValue("purpose") === "DEAL" && (
                      <Form.Item
                        className="m-0 my-2 border-0"
                        label="Deal Name"
                        name="dealName"
                        rules={[{ required: true, message: "Enter Deal name" }]}
                      >
                        <Select
                          getPopupContainer={getPopupContainerForSelect}
                          autoFocus
                          disabled={disabled || loadSelectors.deals}
                          loading={loadSelectors.deals}
                          placeholder="Deal name"
                        >
                          {dealActivity.map(
                            ({ peTransactionTitle, peTransactionId }) => (
                              <Select.Option
                                key={peTransactionId}
                                value={peTransactionTitle}
                              >
                                {peTransactionTitle}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    )}
                    {getFieldValue("purpose") === "PORTFOLIO" && (
                      <Form.Item
                        className="m-0 my-2 border-0"
                        label="Portfolio"
                        name="portfolio"
                        rules={[{ required: true, message: "Enter portfolio" }]}
                      >
                        <Select
                          disabled={disabled || loadSelectors.portfolios}
                          loading={loadSelectors.portfolios}
                          options={portfolioActivity
                            ?.map((k) => ({
                              value: k.portfolioCompanyName,
                              label: k.portfolioCompanyName,
                              key: k.id,
                            }))
                            .sort((a, b) =>
                              columnSort(a?.label, b?.label, "TEXT")
                            )}
                        />
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            className="m-0 my-2 border-0"
            label="Interaction Attendees - Internal"
            name="interactionAttendeesInternal"
          >
            <Select
              disabled={disabled || loadSelectors.internalContacts}
              getPopupContainer={getPopupContainerForSelect}
              showSearch
              placeholder="Select Interaction Attendees - Internal"
              loading={loadSelectors.internalContacts}
              mode="multiple"
              filterOption={(input, option) => {
                const user = option?.props?.children;
                if (!user) return false;
                const fullName =
                  user.props.children[1].props.children[0].props.children
                    .toString()
                    .toLowerCase();
                return fullName.includes(input.toLowerCase());
              }}
              tagRender={({ label, value, closable, onClose }) => (
                <div className="ant-select-item p-1">
                  <div className="ant-select-item-option-content flex items-center justify-between border pr-2 bg-gray-50 rounded">
                    <span>{label}</span>
                    {closable && (
                      <span
                        className="ant-select-item-option-remove ml-1"
                        onClick={onClose}
                      >
                        <CloseOutlined />
                      </span>
                    )}
                  </div>
                </div>
              )}
            >
              {internalCompanyMembers.map(({ id, userDTO }, index) => (
                <Select.Option key={index} value={userDTO.userId}>
                  <div className="flex flex-row items-start p-1">
                    <CustomAvatar
                      data={userDTO.firstName[0] + userDTO.lastName[0]}
                      color={userDTO.firstName[0]}
                      size="small"
                    />
                    <div className="leading-none flex flex-col">
                      <span className="font-medium">
                        {userDTO.firstName} {userDTO.lastName}
                      </span>
                      <span className="text-secondary text-xs">
                        {userDTO.email}
                      </span>
                    </div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="m-0 my-2 border-0"
            label="Interaction Attendees - External"
            name="externalUsers"
          >
            <Input
              disabled={disabled || loadSelectors?.externalContacts}
              placeholder="Enter Interaction Attendees - External"
            />
          </Form.Item>
          {/* <Form.Item
            className="m-0 my-2 border-0"
            label="Interaction Attendees - External"
            name="interactionAttendeesExternal"
          >
            <Select
              getPopupContainer={getPopupContainerForSelect}
              showSearch
              loading={loadSelectors.externalContacts}
              disabled={disabled || loadSelectors.externalContacts}
              placeholder="Select Interaction Attendees - External"
              filterOption={(input, option) => {
                const user = option?.props?.children;
                if (!user) return false;
                const fullName =
                  user.props.children[1].props.children[0].props.children
                    .toString()
                    .toLowerCase();
                return fullName.includes(input.toLowerCase());
              }}
              tagRender={({ label, value, closable, onClose }) => (
                <div className="ant-select-item p-1">
                  <div className="ant-select-item-option-content flex items-center justify-between border pr-2 bg-gray-50 rounded">
                    <span>{label}</span>
                    {closable && (
                      <span
                        className="ant-select-item-option-remove ml-1"
                        onClick={onClose}
                      ></span>
                    )}
                  </div>
                </div>
              )}
            >
              <Input placeholder="Enter " />
              {externalCompanyMembers?.map((userDTO) => (
                <Select.Option key={userDTO.id} value={userDTO.id}>
                  <div className="flex items-center">
                    <CustomAvatar
                      data={userDTO.firstName[0] + userDTO.lastName[0]}
                      color={userDTO.firstName[0]}
                      size="small"
                    />
                    <div className="leading-none flex flex-col">
                      <span className="font-medium">
                        {userDTO.firstName} {userDTO.lastName}
                      </span>
                      <span className="text-secondary text-xs">
                        {userDTO.email}
                      </span>
                    </div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item
            className="m-0 my-2 border-0"
            label="Notes"
            name="note"
            rules={[
              { required: true, message: "Enter notes" },
              { min: 5, message: "Please enter at least 5 characters" },
            ]}
          >
            <TextArea placeholder="Notes" disabled={disabled} rows={5} />
          </Form.Item>
          <Form.Item
            className="m-0 my-2 border-0"
            label="Attachments"
            name="attachments"
          >
            <Upload
              disabled={disabled}
              name="file"
              beforeUpload={beforeUpload}
              showUploadList={{
                showRemoveIcon: true,
                removeIcon: <DeleteOutlined />,
              }}
              type={"select"}
              accept={".pdf , .doc , .docx , .xls , .xlsx"}
              defaultFileList={selectedNote?.fileElements}
              action={"/api/rest/media/uploadFile/1"}
              onChange={({ file }): void => {
                if (!file.status || file.status !== "removed") {
                  if (file && file.size && file.size / 1024 / 1024 <= 10) {
                    onSaveAttachment(file.response);
                  } else {
                    setFileUploading(false);
                  }
                }
              }}
              onRemove={(file) => {
                onDeleteAttachment(file);
              }}
              onDrop={(e) => {
                message.info("Files dropped");
              }}
            >
              <Button
                disabled={disabled || fileUploading}
                icon={<UploadOutlined />}
              >
                Attach files
              </Button>
            </Upload>
            <span className="text-gray-400">
              Supported formats : PDF,DOC,DOCX,XLS,XLSX(max 10MB)
            </span>
          </Form.Item>
        </Form>
      </Modal>
      <CreateNewUserModal
        visible={userModalvisible}
        setVisible={setUserModalVisible}
        userModalVisible={() => setUserModalVisible(!userModalvisible)}
        onAdd={onSave}
      />
    </>
  );
};

export type externalMembersType = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};
type AddNotesModalProps = {
  notesModalVisible: boolean;
  setNotesModalVisible: (notesModalVisible: boolean) => void;
  updateNotes: updateNotesType;
  selectedNote: NoteDataType | undefined;
  userModalvisible: boolean;
  setUserModalVisible: (userModalvisible: boolean) => void;
  setSelectedNote: any;
  fileUploading: boolean;
  setFileUploading: (fileUploading: boolean) => void;
};
type paramsType = { id: string };
export default AddNotesModal;
type selectorsType = {
  externalContacts: boolean;
  internalContacts: boolean;
  deals: boolean;
  portfolios: boolean;
};
