import {
  ElementType,
  LenderEngagementAction,
} from "../../../../../utils/enums";

export const LENDER_ACTION_LABELS: Record<LenderEngagementAction, string> = {
  [LenderEngagementAction.NDA_ACCEPTED]: "Nda Accepted",
  [LenderEngagementAction.NDA_REJECTED]: "Nda Rejected",
  [LenderEngagementAction.SAVED]: "Saved",
  [LenderEngagementAction.VIEWED]: "Viewed",
  [LenderEngagementAction.DOWNLOAD]: "Download",
  [LenderEngagementAction.PUBLISHED]: "Published",
};

export const MODULE_LABELS = {
  OFFLINE_TRANSACTION: "Offline Transaction",
  TRANSACTION: "Transaction",
  DASHBOARD: "Dashboard",
  NOTIFICATIONS: "Notifications",
  COMMUNICATION: "Communication",
  TERM_SHEET: "Term Sheet",
  ALLOCATION: "Allocation",
  FEES: "Fees",
  NOTES: "Notes",
  DATA_ROOM: "Data Room",
  TASK_LIST: "Task List",
  DEAL_TEAM: "Deal Team",
  ADD_INSTITUTIONS: "Add Institutions",
  INVITE_LENDER_USERS: "Invite Lender Users",
  TRACKERS: "Trackers",
  INSIGHTS: "Insights",
  TAGS: "Tags",
  NDA: "NDA",
  PRECEDENT_SEARCH: "Precedent Search",
  PORTFOLIO_MANAGEMENT: "Portfolio Management",
  CRM: "CRM",
  DEAL_CLOUD: "Deal Cloud",
  SSO: "SSO",
  WEBHOOK: "Webhooks",
  API: "API",
  SALESFORCE: "Salesforce",
  OUTLOOK_INTEGRATION: "Outlook Integration",
  PERMISSIONS: "Permissions",
  SECURITY: "Security",
  SPONSOR_COVERAGE: "Sponsor Coverage",
  LENDER_ENGAGEMENT: "Lender Engagement",
};

export const ELEMENT_TYPE_LABELS: Record<ElementType, string> = {
  [ElementType.DEAL_EXECUTION]: "Deal Execution",
  [ElementType.PETRANSACTION]: "PeTransaction",
  [ElementType.FILE]: "File",
  [ElementType.LENDER]: "Lender",
  [ElementType.TASK]: "Task",
  [ElementType.COMMENT]: "Comment",
  [ElementType.NOTE]: "Note",
  [ElementType.TERMSHEET]: "Term Sheet",
  [ElementType.FOLDER]: "Folder",
  [ElementType.COMPANY]: "Company",
  [ElementType.PORTFOLIO]: "Portfolio",
  [ElementType.INSIGHTS]: "Insights",
  [ElementType.PORTFOLIO_SECTION]: "Portfolio Section",
  [ElementType.PRECEDENT_SEARCH]: "Precedent Search",
  [ElementType.CRM]: "CRM",
  [ElementType.DEAL_CLOUD]: "Deal Cloud",
  [ElementType.SPONSOR_COVERAGE]: "Sponsor Coverage",
};
