import React, { FC } from "react";
import { Button, Checkbox, Spin, Tooltip } from "antd";
import { LoadingOutlined, MenuOutlined } from "@ant-design/icons";
import { MileStoneType, TagType } from "../../../../../utils/types";
import { useDrag } from "react-dnd";
import { TrackersTypes } from "../../../../../utils/uiTypes";
import { TextOverFlowHandle } from "../../../../general/TextOverFlowHandle";
import { DashboardTrackerNotes } from "../DashboardTrackerNotes";

export const TrackerLenderRow: FC<TrackerLenderRowType> = ({
  loading,
  milestones,
  toggleMilestoneData,
  id,
  name,
  canEdit,
  tag,
  showNotes,
  transactionId,
  shuffling,
  usersCount = 0,
}) => {
  const [, drag, preview] = useDrag({
    type: TrackersTypes.LENDER,
    item: { lenderId: id, tagDTO: tag, usersCount },
    isDragging(monitor) {
      const item = monitor.getItem();
      return id === item.lenderId;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={preview}
      className={`transition-all duration-50 grid hover:bg-blue-50 py-2 items-center gap-x-2 active:scale-99 odd:bg-gray-50 even:bg-white group`}
      style={{
        gridTemplateColumns: `200px 10px repeat(${milestones.length}, minmax(3px, 1fr))`,
      }}
    >
      <div className={"ml-2 gap-x-2 flex items-center"}>
        <div className={" !w-6"}>
          <Tooltip placement={"right"} title={"Hold and Drag"}>
            {canEdit && (
              <Button
                disabled={shuffling}
                ref={drag}
                icon={
                  shuffling ? <LoadingOutlined spin={true} /> : <MenuOutlined />
                }
                type={"text"}
                className={"bg-white group-hover:bg-blue200 cursor-grab shadow"}
                size={"small"}
              />
            )}
          </Tooltip>
        </div>
        <div className={"text-ellipsis overflow-hidden w-full"}>
          <div className={"inline-flex justify-between w-full items-center"}>
            <TextOverFlowHandle text={name} />
          </div>
        </div>
      </div>
      <div>
        {showNotes && (
          <DashboardTrackerNotes lenderId={id} transactionId={transactionId} />
        )}
      </div>
      {milestones.map(({ key, mileStoneId, name, done }, index) => (
        <div key={index} className={"justify-self-center"}>
          <Tooltip title={name}>
            <div className={"inline-flex gap-x-2 items-center justify-center"}>
              <Checkbox
                defaultChecked={done ?? false}
                disabled={!canEdit}
                onChange={(): void => toggleMilestoneData(id, mileStoneId)}
              />
              {loading(id, mileStoneId) && (
                <Spin
                  spinning={true}
                  indicator={<LoadingOutlined />}
                  size={"small"}
                />
              )}
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

type TrackerLenderRowType = {
  transactionId: string;
  showNotes: boolean;
  name: string;
  id: string;
  milestones: MileStoneType[];
  canEdit: boolean | null;
  loading: (lenderId: string, milestoneId: string) => boolean;
  toggleMilestoneData: toggleMilestoneDataType;
  tag: TagType | null;
  shuffling: boolean;
  usersCount: number;
};

type toggleMilestoneDataType = (lenderId: string, milestoneId: string) => void;
