import React, { FC, useContext, useEffect, useState } from "react";

import { PortfolioViewNav } from "../PortfolioViewNav";
import { getSection } from "../../../../utils/portfolio";
import { CompanyType, PortfolioSectionType } from "../../../../utils/types";
import {
  ElementType,
  PermissionType,
  PortfolioSectionEnumType,
} from "../../../../utils/enums";
import { UserContext } from "../../../../context/UserContext";
import {
  createNewPortfolioSection,
  getAllCompanies,
} from "../../../../services/services";
import { ResponseType } from "../../../../utils/uiTypes";
import { isEmpty } from "ramda";
import { message } from "antd";
import { useParams } from "react-router";
import { getObjectFromPropertyValue } from "../../../../utils/utils";

export const PortfolioOverview: FC<PortfolioOverviewType> = ({
  companyTitle,
  companyId,
  portfolioId,
  sectionList,
  onChangeSectionList,
  permissions,
  onChangeSection,
  allowCreateNewSection,
}) => {
  const { sectionId } = useParams<ParamsType>();

  const { user } = useContext(UserContext);
  const [section, setSection] = useState<PortfolioSectionType | null>(null);
  const [companies, setCompanies] = useState<CompanyType[]>([]);

  const getCompanies = (): void => {
    getAllCompanies({
      params: { isLite: true },
    })
      .then(({ data = [] }: ResponseType<Array<CompanyType>>) =>
        setCompanies(data)
      )
      .catch(console.error);
  };

  useEffect(() => getCompanies(), []);
  useEffect(() => {
    setSection(getObjectFromPropertyValue("id", sectionId, sectionList));
  }, [sectionId, sectionList]);

  return (
    <>
      <div
        className={
          "relative md:flex h-full max-h-full md:overflow-y-hidden w-full max-w-full"
        }
      >
        <PortfolioViewNav
          onSelectSection={onChangeSection}
          companyId={companyId}
          portfolioId={portfolioId}
          companyTitle={companyTitle}
          sectionList={sectionList}
          onCreateNewSection={onChangeSectionList}
          permissions={
            user?.elementPermissions?.find(
              (item) => item?.elementType === ElementType.PORTFOLIO
            )?.permissions ?? []
          }
          allowCreateNewSection={allowCreateNewSection}
        />
        {section && (
          <div className={"md:w-[80%]"}>
            {getSection(
              section.sectionType,
              permissions,
              companies,
              sectionList,
              portfolioId ?? ""
            )}
          </div>
        )}
      </div>
    </>
  );
};

type PortfolioOverviewType = {
  companyTitle?: string;
  sectionList: PortfolioSectionType[];
  companyId: string;
  portfolioId?: string;
  onChangeSectionList: OnChangeSectionList;
  permissions: PermissionType[];
  onChangeSection: (sectionId: string) => void;
  allowCreateNewSection: boolean;
};

type OnChangeSectionList = (sections: PortfolioSectionType[]) => void;
type ParamsType = { sectionId: string };
