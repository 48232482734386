export const tableSelectCss = (enabled = true): string =>
  `w-full ${
    enabled && "group-hover:border-b"
  } border-gray-200 focus-within:border-blue-500`;

export const ACTION_BUTTON_CSS =
  "text-gray-500 bg-white border-0 shadow hover:font-medium block cursor-pointer disabled:bg-gray-50 disabled:shadow-none disabled:opacity-60 disabled:cursor-not-allowed";

export const PRIMARY_BUTTON_STYLE =
  "text-white bg-primary border-primary hover:bg-hover hover:border-hover disabled:bg-primary disabled:bg-opacity-60 disabled:!text-gray-500 disabled:border-opacity-60 font-medium";

export const SUCCESS_BUTTON_STYLE =
  "text-white bg-success border-success hover:bg-success-light hover:border-success-light disabled:bg-success disabled:bg-opacity-60 disabled:border-opacity-60 font-medium";

export const DANGER_BUTTON_STYLE =
  "text-white bg-danger border-danger hover:bg-danger-light hover:border-danger-light disabled:bg-danger disabled:bg-opacity-60 disabled:border-opacity-60 font-medium";

export const HIGHLIGHT_BUTTON_CSS =
  "bg-light border-light hover:bg-hover hover:border-hover disabled:bg-highlight disabled:bg-opacity-60 disabled:border-opacity-60 font-medium";
