import React, { FC } from "react";
import { Input, Select } from "antd";
import { PhoneNumber } from "../../utils/formUtils";
import { valOrDefault } from "../../utils/utils";
import countries from "../../assets/countryList.json";
import { getCountryCallingCode } from "libphonenumber-js";
import { getPopupContainerForSelect } from "../../utils/container";

export const PhoneNumberInput: FC<PhoneNumberInputType> = ({
  value,
  onChange,
  placeholder,
  disabled = false,
}: PhoneNumberInputType) => {
  return (
    <>
      <Input
        placeholder={placeholder}
        disabled={disabled}
        addonBefore={
          <Select
            disabled={disabled}
            getPopupContainer={getPopupContainerForSelect}
            dropdownMatchSelectWidth={300}
            dropdownStyle={{
              overflow: "visible",
            }}
            value={valOrDefault(null, value?.short?.toString())}
            showSearch={true}
            optionFilterProp={"country"}
            filterOption={true}
            style={{ width: 98 }}
            suffixIcon={<></>}
            onChange={(val): void =>
              onChange &&
              onChange({
                ...value,
                short: val,
                code: getCountryCallingCode(val),
              } as PhoneNumber)
            }
            className={
              "caret-transparent p-0 flex flex-row items-center cursor-pointer"
            }
          >
            {countries
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((val) => (
                <Select.Option
                  title={val?.dial_code?.toString()}
                  key={val.code}
                  value={val.code}
                  country={val?.name + " " + val?.dial_code}
                >
                  <div
                    className={
                      "flex flex-row w-full items-center gap-x-3 h-fit"
                    }
                  >
                    <img
                      alt={val.emoji}
                      src={val.image}
                      style={{ width: "18px", height: "18px" }}
                    />
                    <span>+{val.dial_code}</span>
                    <span className={"truncate ml-auto pl-4"}>{val.name}</span>
                  </div>
                </Select.Option>
              ))}
          </Select>
        }
        value={valOrDefault("", value?.phone)}
        onChange={(val): void =>
          onChange &&
          onChange({ ...value, phone: val.target.value } as PhoneNumber)
        }
      />
    </>
  );
};

type PhoneNumberInputType = {
  value?: { short: string; code: string; phone: string };
  onChange?: (value: PhoneNumber) => void;
  placeholder?: string;
  disabled?: boolean;
};
