import React, { FC, useContext, useState } from "react";
import { LenderType, MileStoneType, TagType } from "../../../../../utils/types";
import {
  linkTagToElement,
  unLinkTagFromElement,
} from "../../../../../services/services";
import { tableColumnHeader } from "../../../../../utils/componentUtils";
import { findIndex, groupBy, path, pathOr, propEq } from "ramda";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { TrackerTagRow } from "./TrackerTagRow";
import { TextOverFlowHandle } from "../../../../general/TextOverFlowHandle";
import { TransactionContext } from "../../../../../context/TransactionContext";
import { ElementType, SmartTagActionType } from "../../../../../utils/enums";
import { updateItemInArray } from "../../../../../utils/utils";
import { fetchWithIdType } from "../../../../../utils/uiTypes";
import { message } from "antd";
import { ConfirmAction } from "../../../../../utils/confirmationModals";
import { PRIMARY_BUTTON_STYLE } from "../../../../../utils/cssConfigs";

export const DashboardTrackersTags: FC<DashboardTrackersTagsType> = ({
  transactionId,
  canEdit,
  loading,
  isFullScreen = false,
  toggleMilestoneData,
  tags,
  showNotes,
  lenders,
}) => {
  const { setTransaction } = useContext(TransactionContext);

  const [shuffleLoading, setShuffleLoading] = useState<ShuffleLoadingType>({});

  const getTagLenderMapping = (
    lenders: LenderType[],
    tags: TagType[]
  ): TagLenderMapping[] => {
    const grouped = groupBy(
      ({ tagDTOs }) => pathOr(-1, [0, "id"], tagDTOs),
      lenders
    );
    return [
      ...tags.reduce<TagLenderMapping[]>((previousValue, tag) => {
        return [
          ...previousValue,
          {
            tagDTO: tag,
            lenders: pathOr([], [tag.id], grouped),
          },
        ];
      }, []),
      { tagDTO: null, lenders: pathOr([], ["-1"], grouped) },
    ];
  };

  const getMilestonesFromLenders = (lenders: LenderType[]): MileStoneType[] =>
    pathOr([], [0, "milestoneDTOs"], lenders);

  const linkTag: linkTagType = (tagDTO, lenderId) => {
    linkTagToElement({
      segments: {
        tagId: tagDTO?.id,
        elementType: ElementType.LENDER,
        elementId: lenderId,
      },
      params: {
        smartAction: true,
      },
    })
      .then(() => {
        const lenderIndex = findIndex(propEq("id", lenderId), lenders);
        setTransaction((transaction) => {
          return transaction
            ? {
                ...transaction,
                lenderDTOs: updateItemInArray(
                  lenderIndex,
                  transaction?.lenderDTOs ?? [],
                  {
                    ...transaction?.lenderDTOs?.[lenderIndex],
                    userDTOs: tagDTO?.smartActions?.includes(
                      SmartTagActionType.REMOVE_ALL_USERS
                    )
                      ? []
                      : transaction?.lenderDTOs?.[lenderIndex]?.userDTOs,
                    tagDTOs: [tagDTO],
                  } as LenderType
                ),
              }
            : null;
        });
        setShuffleLoading({ ...shuffleLoading, [lenderId]: false });
      })
      .catch((error: string) => {
        message.error("Unable to tag the Institution");
        console.error(error);
        setShuffleLoading({ ...shuffleLoading, [lenderId]: false });
      });
  };

  const unlinkTag: fetchWithIdType = (lenderId) => {
    unLinkTagFromElement({
      segments: {
        elementType: ElementType.LENDER,
        elementId: lenderId,
      },
    })
      .then(() => {
        const lenderIndex = findIndex(propEq("id", lenderId), lenders);
        setTransaction((transaction) => {
          return transaction
            ? {
                ...transaction,
                lenderDTOs: updateItemInArray(
                  lenderIndex,
                  transaction?.lenderDTOs ?? [],
                  {
                    ...transaction?.lenderDTOs[lenderIndex],
                    tagDTOs: [],
                  } as LenderType
                ),
              }
            : null;
        });
        setShuffleLoading({ ...shuffleLoading, [lenderId]: false });
      })
      .catch((error: string) => {
        message.error("Unable to remove the tag");
        console.error(error);
        setShuffleLoading({ ...shuffleLoading, [lenderId]: false });
      });
  };

  const onReshuffle: onReshuffleType = ({ tagDTO, lenderId, usersCount }) => {
    const initiateReshuffle = (): void => {
      setShuffleLoading({ ...shuffleLoading, [lenderId]: true });
      if (tagDTO) {
        linkTag(tagDTO, lenderId);
      } else {
        unlinkTag(lenderId);
      }
    };

    if ((tagDTO?.smartActions ?? [])?.length > 0 && usersCount > 0) {
      ConfirmAction(
        "Smart Action Warning",
        initiateReshuffle,
        <div>
          {tagDTO?.smartActions?.[0]?.replaceAll("_", " ")} will be executed
          after assigning the institution to this particular tag. To remove the
          smart action, please update the smart actions for the tag from manage
          tags under the company settings
        </div>,
        { okButtonProps: { className: PRIMARY_BUTTON_STYLE } },
        "Update"
      );
    } else {
      initiateReshuffle();
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={`w-full bg-white shadow ${
          isFullScreen && "overflow-y-auto overflow-x-visible"
        } max-h-full`}
      >
        <div className={`divide-y relative`}>
          <div
            className={`grid p-2 divide-x-2 ${
              isFullScreen && "sticky top-0 z-50 bg-white border-b"
            }`}
            style={{
              gridTemplateColumns: `200px 10px repeat(${
                getMilestonesFromLenders(lenders).length
              }, minmax(3px, 1fr))`,
            }}
          >
            <div>{tableColumnHeader("Institutions")}</div>
            <div className={"!border-l-transparent"} />
            {getMilestonesFromLenders(lenders).map(({ key, name }) => (
              <div
                key={key}
                className={
                  "text-ellipsis overflow-hidden flex flex-wrap text-center border-l px-1"
                }
              >
                {tableColumnHeader(
                  <TextOverFlowHandle text={name} />,
                  "text-center"
                )}
              </div>
            ))}
          </div>
          <div className={"divide-y"}>
            {getTagLenderMapping(lenders, tags).map(({ tagDTO, lenders }) => (
              <TrackerTagRow
                showNotes={showNotes}
                onDrop={onReshuffle}
                key={tagDTO?.id ?? -1}
                milestones={getMilestonesFromLenders(lenders)}
                lenders={lenders}
                transactionId={transactionId}
                toggleMilestoneData={toggleMilestoneData}
                loading={(lenderId, milestoneId): boolean =>
                  pathOr(false, [lenderId, milestoneId], loading)
                }
                shuffling={(lenderId): boolean =>
                  pathOr(false, [lenderId], shuffleLoading)
                }
                canEdit={canEdit}
                tag={tagDTO}
              />
            ))}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

type DashboardTrackersTagsType = {
  transactionId: string;
  canEdit: boolean | null;
  loading: LoadingType;
  isFullScreen?: boolean;
  toggleMilestoneData: toggleMilestoneDataType;
  tags: TagType[];
  showNotes: boolean;
  lenders: LenderType[];
};

type TagLenderMapping = {
  tagDTO: TagType | null;
  lenders: LenderType[];
};

type toggleMilestoneDataType = (lenderId: string, milestoneId: string) => void;

type LoadingType = {
  [id: string]: Record<string, boolean>;
};

type ShuffleLoadingType = {
  [id: string]: boolean;
};

type DropDataType = {
  lenderId: string;
  tagDTO: TagType | null;
  usersCount: number;
};

type onReshuffleType = (data: DropDataType) => void;

type linkTagType = (tagDTO: TagType, lenderId: string) => void;
