import React, { FC, useEffect, useState } from "react";
import {
  Alert,
  AutoComplete,
  Button,
  Checkbox,
  Form,
  Input,
  Select,
} from "antd";
import { getCompanyTeam, getInstitutions } from "../../services/services";
import { ResponseType } from "../../utils/uiTypes";
import {
  CompanyType,
  TeamMemberType,
  TransactionType,
  UserType,
} from "../../utils/types";
import {
  filterOption,
  filterOptionWithTitle,
  getObjectFromPropertyValue,
  populateArrayWithPropertyPath,
  valOrDefault,
} from "../../utils/utils";
import { CustomAvatar } from "../general/CustomAvatar";
import {
  formItemRequiredRule,
  namePatternRule,
  parsePhoneNumber,
  phoneNumberPatternRule,
  reverseParsePhoneNumber,
} from "../../utils/formUtils";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { PhoneNumberInput } from "../general/PhoneNumberInput";
import { invitationStatusTag } from "../../utils/componentUtils";
import { getPopupContainerForSelect } from "../../utils/container";

export const DealTeamForm: FC<DealTeamFormType> = ({
  onSubmit,
  transaction,
  dealTeam,
  formStatus = FormStatusType.NONE,
  message,
}: DealTeamFormType) => {
  const [form] = Form.useForm();

  const [selectedMember, setSelectedMember] = useState<TeamMemberType | null>(
    null
  );
  const [teamMembers, setTeamMembers] = useState<TeamMemberType[]>([]);
  const [companies, setCompanies] = useState<CompanyType[]>([]);

  const fetchCompanyTeam: fetchCompanyTeamType = (id, dealTeam) => {
    getCompanyTeam({
      segments: {
        id,
      },
    }).then(({ data = [] }: ResponseType<TeamMemberType[]>) => {
      const userIds = populateArrayWithPropertyPath(["userId"], dealTeam);
      const team: Array<TeamMemberType> = data.filter(
        (val: TeamMemberType) => !userIds?.includes(val.userDTO.userId)
      );

      setTeamMembers(team);
    });
  };

  const onAdminCheckboxChange: onAdminCheckboxChangeType = (e) => {
    e.target.checked = !e.target.checked;
    form.setFieldsValue({
      ...form.getFieldsValue(),
      ["isAdmin"]: !e.target.checked,
    });
  };

  const onFinish: onFinishType = (values) => {
    const data = {
      ...values,
      companyDTO: getObjectFromPropertyValue(
        "id",
        form.getFieldValue("companyId"),
        companies
      ),
      admin: form.getFieldValue("isAdmin"),
      phoneNumber: parsePhoneNumber(values.phoneNumber),
    };
    onSubmit(data);
  };

  useEffect(() => {
    fetchCompanyTeam(transaction.companyId, dealTeam);
  }, [transaction?.companyId, dealTeam]);

  useEffect(() => {
    getInstitutions({}).then(({ data = [] }: ResponseType<CompanyType[]>) => {
      setCompanies(data);
    });
  }, []);

  return (
    <>
      <Form
        form={form}
        className={`flex flex-col items-start flex items-stretch`}
        onFinish={onFinish}
        layout={"vertical"}
      >
        <Form.Item style={{ width: 0 }} name={"userId"} noStyle={true}>
          <></>
        </Form.Item>
        <Form.Item
          label={"First Name"}
          name={"firstName"}
          rules={[formItemRequiredRule, namePatternRule]}
        >
          <AutoComplete
            notFoundContent={"No Users"}
            disabled={formStatus === FormStatusType.LOADING}
            filterOption={filterOptionWithTitle}
            placeholder={"First Name"}
            showSearch={true}
            autoFocus={true}
            onSelect={(val: string): void => {
              const data: UserType = JSON.parse(val);
              form.setFieldsValue({
                ...data,
                phoneNumber: reverseParsePhoneNumber(
                  valOrDefault("", data?.phoneNumber)
                ),
                companyId: transaction?.companyId,
              });
              setSelectedMember(JSON.parse(val));
            }}
            dropdownStyle={{
              overflow: "visible",
            }}
          >
            {teamMembers?.map(({ userDTO, invitationStatus }) => (
              <Select.Option
                title={`${userDTO?.firstName} ${userDTO?.lastName} ${userDTO?.email}`}
                key={userDTO?.userId}
                value={JSON.stringify(userDTO)}
                className={"w-full container"}
              >
                <div className={"flex flex-row items-start py-1"}>
                  <CustomAvatar
                    data={userDTO?.firstName[0] + userDTO?.lastName[0]}
                    color={userDTO?.firstName[0]}
                    size={"small"}
                  />
                  <div className={"leading-none flex flex-col"}>
                    {userDTO?.firstName} {userDTO?.lastName}
                    <span className={"text-secondary text-xs"}>
                      {userDTO?.email}
                    </span>
                  </div>
                  <div className={"flex-grow text-right"}>
                    {invitationStatus && invitationStatusTag(invitationStatus)}
                  </div>
                </div>
              </Select.Option>
            ))}
          </AutoComplete>
        </Form.Item>
        <Form.Item
          label={"Last Name"}
          name={"lastName"}
          rules={[formItemRequiredRule, namePatternRule]}
        >
          <Input
            disabled={
              selectedMember !== null || formStatus === FormStatusType.LOADING
            }
            placeholder={"Last Name"}
          />
        </Form.Item>
        <Form.Item
          label={"Email"}
          name={"email"}
          rules={[formItemRequiredRule]}
        >
          <Input
            type={"email"}
            disabled={
              selectedMember !== null || formStatus === FormStatusType.LOADING
            }
            placeholder={"Email"}
          />
        </Form.Item>
        <Form.Item
          label={"Phone Number"}
          name={"phoneNumber"}
          initialValue={{
            short: "GB",
            code: "44",
          }}
          rules={[phoneNumberPatternRule()]}
        >
          <PhoneNumberInput
            disabled={
              selectedMember !== null || formStatus === FormStatusType.LOADING
            }
            placeholder={"Phone Number"}
          />
        </Form.Item>
        <Form.Item
          label={"Company"}
          name={"companyId"}
          rules={[formItemRequiredRule]}
        >
          <Select
            notFoundContent={"No Companies Available"}
            getPopupContainer={getPopupContainerForSelect}
            filterOption={filterOption}
            placeholder={"Select Company"}
            disabled={
              selectedMember !== null || formStatus === FormStatusType.LOADING
            }
            showSearch={true}
            dropdownStyle={{
              overflow: "visible",
            }}
            dropdownMatchSelectWidth={400}
          >
            {companies?.map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {formStatus === FormStatusType.FAILED && (
          <Alert message={message} type={"error"} className={"mb-2"} />
        )}

        <div className={"flex flex-row"}>
          <Form.Item
            valuePropName={"checked"}
            className={"inline-block"}
            name={"isAdmin"}
          >
            <Checkbox
              disabled={formStatus === FormStatusType.LOADING}
              className={"flex justify-center"}
              defaultChecked={false}
              checked={form.getFieldValue("isAdmin")}
              onChange={onAdminCheckboxChange}
            >
              Make Admin
            </Checkbox>
          </Form.Item>

          <Form.Item className={"flex flex-row gap-y-1 w-3/4 m-0 ml-auto"}>
            <div className={"flex flex-row gap-x-2"}>
              <Button
                disabled={formStatus === FormStatusType.LOADING}
                className={
                  "ml-auto bg-white border hover:border-primary hover:text-primary w-full"
                }
                onClick={(): void => {
                  form.resetFields();
                  setSelectedMember(null);
                }}
              >
                Clear
              </Button>
              <Button
                disabled={formStatus === FormStatusType.LOADING}
                loading={formStatus === FormStatusType.LOADING}
                onClick={(): void => form.submit()}
                className={
                  "bg-primary hover:bg-hover text-white border-0 w-full"
                }
              >
                Add
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

type DealTeamFormType = {
  onSubmit: (o: UserType) => void;
  transaction: TransactionType;
  dealTeam: UserType[];
  onCloseModal: () => void;
  formStatus?: FormStatusType;
  message?: string;
};
type fetchCompanyTeamType = (id: string, dealTeam: UserType[]) => void;
type onAdminCheckboxChangeType = (e: CheckboxChangeEvent) => void;
type onFinishType = (values: any) => void;

enum FormStatusType {
  LOADING,
  SUCCESS,
  FAILED,
  NONE,
}
