import { NoteDataType, UserType } from "../../../../utils/types";
import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { Button, Empty, Tag, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import { ShareNoteForm } from "../../../forms/ShareNoteForm";
import { ConfirmDelete } from "../../../../utils/confirmationModals";
import { useConfirmReload } from "../../../../customHooks/useConfirmReload";
import moment from "moment";
import { convertDateToFormat } from "../../../../utils/moment";
import { CustomAvatar } from "../../../general/CustomAvatar";
import { FullScreenWidget } from "../../../general/FullScreenWidget";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { doNothing } from "../../../../utils/utils";
import { isNil } from "ramda";
import { getColorPallet } from "../../../../utils/colors";

export const Note: FC<NoteType> = function ({
  note,
  togglePriority = doNothing,
  removeNote = doNothing,
  updateNote,
  shareNote = doNothing,
  dealTeam = [],
  setEditingNote = doNothing,
  transactionId,
  readOnly = false,
  onView,
  offlineMode = false,
  isDealTeam,
}: NoteType) {
  const [currentNote, setCurrentNote] = useState<string>("New Note");
  const [noteSaved, setNoteSaved] = useState<boolean>(true);

  useConfirmReload(!noteSaved, [noteSaved]);

  const onMouseLeaveOrNoteBlur = (
    e: MouseEvent<HTMLTextAreaElement> | FocusEvent<HTMLTextAreaElement>
  ): void => {
    if (!noteSaved && updateNote)
      updateNote(note?.id, e.currentTarget.value, transactionId);
  };

  const onNoteChange = (e: ChangeEvent<HTMLTextAreaElement>): void =>
    setCurrentNote(e.target.value);

  const onKeyDownNote = (event: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.ctrlKey && event.code === "KeyS")
      if (!noteSaved && updateNote)
        updateNote(note?.id, event.currentTarget.value, transactionId);
  };

  const getNoteDescription = (
    firstName = "",
    lastName = "",
    date = "",
    lenderName = ""
  ): ReactNode => (
    <div>
      Last Modified on&nbsp;
      {convertDateToFormat(
        moment(date).valueOf().toString(),
        "MMM Do YYYY, hh:mm A"
      )}
      <br />
      Created by {firstName + " " + lastName}
      <br />
      <Tag
        className={`${getColorPallet(
          lenderName?.charAt(0)?.toUpperCase(),
          "text"
        )} ${getColorPallet(lenderName?.charAt(0)?.toUpperCase(), "border")}`}
      >
        {lenderName}
      </Tag>
    </div>
  );

  useEffect(() => {
    const isNoteSaved =
      (note?.note === "" && currentNote === "New Note") ||
      note?.note === currentNote;
    setNoteSaved(isNoteSaved);
    setEditingNote(!isNoteSaved);
  }, [currentNote, note]);

  useEffect(() => {
    setCurrentNote(note?.note);
  }, [note?.id, note?.note, note?.createDate]);

  return note ? (
    <FullScreenWidget>
      {(isFullScreen, trigger) => (
        <div className={"m-0 p-0 flex flex-col h-full w-full"}>
          {!offlineMode && note?.sharedWith && (
            <div className={"flex flex-row items-center font-semibold pb-3"}>
              Sharing with:{" "}
              {note?.sharedWith?.map(({ userId, firstName, lastName }) => (
                <CustomAvatar
                  key={userId}
                  data={firstName[0] + lastName[0]}
                  color={firstName[0]}
                  label={firstName + " " + lastName}
                  size={"small"}
                />
              ))}
            </div>
          )}
          <div className={"bg-white px-4 border h-full flex flex-col pb-4"}>
            <div
              className={
                "flex flex-row text-gray-400 text-xs font-medium items-center my-3"
              }
            >
              {getNoteDescription(
                note?.createdByUserDTO?.firstName,
                note?.createdByUserDTO?.lastName,
                note?.modifiedDate,
                note?.lenderName
              )}
              <span
                className={
                  "ml-auto flex flex-row text-xs text-gray-400 font-medium items-center gap-x-2 p-2"
                }
              >
                {!isFullScreen && !offlineMode && isDealTeam && (
                  <ShareNoteForm
                    hidden={readOnly}
                    noteSharedWith={note.sharedWith}
                    onSubmit={({ userIds }): void => {
                      shareNote(note.id, userIds);
                    }}
                    dealTeam={dealTeam}
                  />
                )}

                {!readOnly && (
                  <Button
                    className={
                      "border-0 p-0 shadow flex items-center justify-center bg-gray-50 text-gray-500 shadow-[0_2px_4px_rgba(0,0,0,0.18)] group rounded-full disabled:bg-gray-500"
                    }
                    type={"text"}
                    icon={
                      isFullScreen ? (
                        <FullscreenExitOutlined
                          className={"text-gray-400 font-bold"}
                        />
                      ) : (
                        <FullscreenOutlined
                          className={"text-gray-400 font-bold"}
                        />
                      )
                    }
                    disabled={isNil(trigger)}
                    onClick={trigger ?? (() => {})}
                  />
                )}

                {!readOnly && (
                  <Tooltip title={"Mark as Important"}>
                    <Button
                      disabled={!noteSaved}
                      className={
                        "border-0 p-0 shadow flex items-center justify-center bg-gray-50 text-gray-500 shadow-[0_2px_4px_rgba(0,0,0,0.18)] group rounded-full disabled:bg-gray-500"
                      }
                      type={"text"}
                      icon={
                        !note?.priorities ? (
                          <i className="far fa-star text-gray-400" />
                        ) : (
                          <i className="fas fa-star text-blue-500" />
                        )
                      }
                      onClick={(): void => {
                        togglePriority(note?.id);
                      }}
                    />
                  </Tooltip>
                )}

                {!isFullScreen && !readOnly && (
                  <Tooltip title={"Delete Note"} placement={"bottom"}>
                    <Button
                      disabled={!noteSaved}
                      className={
                        "border-0 p-0 shadow flex items-center justify-center bg-gray-50 text-gray-500 shadow-[0_2px_4px_rgba(0,0,0,0.18)] group rounded-full disabled:bg-gray-500"
                      }
                      icon={
                        <i className="far fa-trash-alt text-gray-400 group-hover:text-red-400" />
                      }
                      type={"text"}
                      onClick={(): void => {
                        ConfirmDelete("Confirm DeleteNote?", () =>
                          removeNote(note?.id)
                        );
                      }}
                    />
                  </Tooltip>
                )}
                {readOnly && onView && (
                  <Tooltip title={"Edit Note"} placement={"bottom"}>
                    <Button
                      className={
                        "border-0 p-0 shadow flex items-center justify-center bg-gray-50 text-gray-500 shadow-[0_2px_4px_rgba(0,0,0,0.18)] group rounded-full disabled:bg-gray-500"
                      }
                      icon={
                        <i className="fas fa-pencil-alt text-gray-400 group-hover:text-emerald-400" />
                      }
                      type={"text"}
                      onClick={(): void => {
                        onView(note.lenderId, note.id);
                      }}
                    />
                  </Tooltip>
                )}
              </span>
            </div>
            <TextArea
              disabled={readOnly}
              placeholder={"New Note"}
              value={currentNote}
              onChange={onNoteChange}
              className={
                "disabled:text-black disabled:cursor-not-allowed first-line:font-semibold first-line:text-lg border-0 border-none bg-transparent ring-0 cursor-text outline-none focus:outline-none focus:ring-0 focus:border-none focus:border-0 p-0 mb-4 h-full note-area"
              }
              autoSize={true}
              onBlur={onMouseLeaveOrNoteBlur}
              onKeyDown={onKeyDownNote}
            />
            <div className={"flex items-center justify-end"}>
              <p className={"text-xs inline-flex gap-x-1 items-center"}>
                {!noteSaved ? (
                  <i className="fas fa-times-circle text-red-500" />
                ) : (
                  <i className="fas fa-check-circle text-green-500" />
                )}
                {!noteSaved ? "Unsaved" : "Saved"}
              </p>
              <p className={"ml-auto text-gray-400 text-xs"}>
                <span
                  className={
                    "py-1 px-2 mx-0.5 text-xs border rounded-md shadow-xxs"
                  }
                >
                  Ctrl
                </span>
                +
                <span
                  className={
                    "py-1 px-2 mx-0.5 text-xs border rounded-md shadow-xxs"
                  }
                >
                  S
                </span>{" "}
                to save
              </p>
            </div>
          </div>
        </div>
      )}
    </FullScreenWidget>
  ) : (
    <Empty
      className={
        "text-gray-400 flex flex-col h-full w-full items-center justify-center"
      }
      image={<i className="fa-regular fa-note-sticky text-8xl" />}
      description={"No Note Selected"}
    />
  );
};

type NoteType = {
  note: NoteDataType;
  togglePriority?: (id: string) => void;
  removeNote?: (id: string) => void;
  updateNote?: (noteId: string, note: string, elementId: string) => void;
  shareNote?: (elementId: string, userIds: string[]) => void;
  dealTeam?: UserType[];
  transactionId: string;
  offlineMode?: boolean;
  setEditingNote?: (editing: boolean) => void;
  readOnly?: boolean;
  onView?: (lenderId: string, noteId: string) => void;
  isDealTeam?: boolean;
};
