import React, { FC } from "react";
import { QuarterType } from "../../../../utils/enums";
import moment from "moment";
import { getUnit } from "../../../../utils/portfolio";

export const HistoryItem: FC<HistoryItemType> = ({
  user,
  field,
  unit,
  fieldType,
  quarter,
  year,
  value,
  lastUpdated,
  previousValue,
  currency = "",
}: HistoryItemType) => {
  return (
    <div className={"w-full p-2 hover:bg-gray-100"}>
      <span className={"font-bold"}>{moment(lastUpdated).format("LLL")}:</span>
      <br />
      <span className={"font-semibold"}>{user}</span> changed {field} for{" "}
      {quarter} {year}
      <br />
      Before:&nbsp;
      {previousValue
        ? `${previousValue} ${getUnit(unit)} ${
            fieldType === "MONETARY" ? currency : ""
          }`
        : "N/A"}
      <br />
      After:&nbsp;
      {value
        ? `${value} ${getUnit(unit)} ${
            fieldType === "MONETARY" ? currency : ""
          }`
        : "N/A"}
    </div>
  );
};

type HistoryItemType = {
  user: string;
  field: string;
  unit: string;
  fieldType: string;
  quarter: QuarterType;
  year: string;
  value: string;
  lastUpdated: string;
  previousValue?: string | null;
  currency?: string;
};
