import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import {
  descend,
  isEmpty,
  isNil,
  Morphism,
  Ordered,
  prop,
  sortWith,
} from "ramda";
import { DRAWER_TYPES, DrawerType } from "../../../utils/newTermsheet";
import { Button, Drawer, Empty, Input, message, Space, Spin } from "antd";
import { TemplateResponseType, WebFormType } from "../../../utils/types";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  getAllWebFormsByTransaction,
  getWebformTemplates,
} from "../../../services/services";
import { ResponseType, voidType } from "../../../utils/uiTypes";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import { TemplateCard } from "../template/TemplateCard";
import { WebFormCard } from "../common/WebFormCard";
import { TermsheetContext } from "../../../context/TermsheetContext";

export const TermsheetDrawers: FC<TermsheetDrawersType> = function ({
  transactionId,
  webformId,
  type,
  onClose,
  onSelect,
}: TermsheetDrawersType) {
  const { isInstitution } = useContext(TermsheetContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [templates, setTemplates] = useState<Array<TemplateResponseType>>([]);
  const [webForms, setWebForms] = useState<WebFormType[]>([]);

  const onCloseModal: voidType = () => {
    setSearch("");
    setLoading(false);
    setSelectedId(null);
    setTemplates([]);
    setWebForms([]);
    onClose();
  };
  const getDrawerContent: getDrawerContentType = (type) => {
    switch (type) {
      case "TEMPLATES":
        return (
          <div className={"flex flex-col w-full h-full"}>
            <div className={"mb-6"}>
              <ExclamationCircleOutlined className={"text-danger mr-1"} />{" "}
              Select one of the following templates. Please note any changes you
              have made will be lost.
            </div>
            <div className={"overflow-y-scroll hide-scrollbar max-h-full pb-6"}>
              <div className={"grid grid-cols-2 gap-4"}>
                {templates
                  .filter(({ templateName }) =>
                    templateName?.toLowerCase().includes(search)
                  )
                  .map(
                    (
                      { webFormId, templateName, createDate, createdByUserDTO },
                      i
                    ) => {
                      return (
                        <TemplateCard
                          key={i}
                          name={templateName}
                          createDate={createDate}
                          creator={createdByUserDTO}
                          selected={selectedId === webFormId}
                          onSelect={() => {
                            setSelectedId(webFormId);
                          }}
                        />
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        );
      case "TERMSHEETS":
        return (
          <div>
            {isEmpty(webForms) ? (
              <Empty
                description={
                  <span className={"text-muted"}>
                    The owner has not yet published a Term Sheet
                  </span>
                }
              />
            ) : (
              <>
                <div
                  className={
                    "flex w-full justify-center overflow-y-scroll hide-scrollbar pb-6"
                  }
                >
                  <div className={"grid grid-cols-3 gap-4"}>
                    {webForms
                      .filter(({ name }) =>
                        name?.toLowerCase().includes(search.toLowerCase())
                      )
                      .map(
                        ({
                          id,
                          name,
                          createdByUserDTO,
                          createDate,
                          sponsorPublishedDate,
                        }) => {
                          return (
                            <WebFormCard
                              key={id}
                              name={name}
                              disabled={id === webformId}
                              creator={
                                createdByUserDTO
                                  ? `${createdByUserDTO.firstName} ${createdByUserDTO.lastName}`
                                  : ""
                              }
                              createDate={
                                isInstitution
                                  ? sponsorPublishedDate ?? ""
                                  : createDate
                              }
                              selected={selectedId === id}
                              showDropdown={false}
                              onSelect={() => {
                                setSelectedId(id);
                              }}
                            />
                          );
                        }
                      )}
                  </div>
                </div>
              </>
            )}
          </div>
        );
    }
  };

  /** Fetch all available termsheets for this transaction **/
  const getTermsheets: getTermsheetsType = (id) => {
    getAllWebFormsByTransaction({
      segments: {
        id,
      },
    })
      .then(({ data = [] }: ResponseType<Array<WebFormType>>) => {
        setWebForms(data);
      })
      .catch(() => {
        message.error("Something went wrong. Please try again");
      })
      .then(() => {
        setLoading(false);
      });
  };

  /** Fetch all available templates **/
  const getTemplates: getTemplatesType = (id, webformId) => {
    getWebformTemplates({
      segments: {
        id,
        webformId,
      },
    })
      .then(({ data = [] }: ResponseType<Array<TemplateResponseType>>) => {
        setTemplates(
          sortWith([descend(prop("createDate") as Morphism<unknown, Ordered>)])(
            data
          ) as Array<TemplateResponseType>
        );
      })
      .catch(() => {
        message.error("Something went wrong. Please try again");
      })
      .then(() => {
        setLoading(false);
      });
  };

  const getDrawerData: getDrawerDataType = (type, transactionId, webformId) => {
    switch (type) {
      case "TERMSHEETS":
        getTermsheets(transactionId);
        return;
      case "TEMPLATES":
        getTemplates(transactionId, webformId);
        return;
    }
  };
  useEffect(() => {
    setLoading(true);
    getDrawerData(type, transactionId, webformId);
  }, [type, transactionId, webformId]);

  return (
    <Drawer
      bodyStyle={{ paddingBottom: 0 }}
      destroyOnClose
      closable={!loading}
      maskClosable={!loading}
      extra={
        <Input.Search
          placeholder={"Search"}
          className={"w-64"}
          allowClear={true}
          onChange={(e): void => {
            setSearch(e.target.value);
          }}
        />
      }
      footer={
        <div className={"flex justify-end gap-2"}>
          <Space className={"gap-2"}>
            <Button
              type={"primary"}
              disabled={isNil(selectedId)}
              className={PRIMARY_BUTTON_STYLE}
              onClick={() => {
                selectedId && onSelect(selectedId);
              }}
            >
              Switch
            </Button>
            <Button type={"default"} disabled={loading} onClick={onCloseModal}>
              Cancel
            </Button>
          </Space>
        </div>
      }
      width={700}
      visible={!isNil(type)}
      title={DRAWER_TYPES[type]}
      placement="right"
      onClose={onCloseModal}
    >
      {loading ? (
        <div
          className={"inline-flex h-full w-full items-center justify-center"}
        >
          <Spin spinning={loading} />
        </div>
      ) : (
        getDrawerContent(type)
      )}
    </Drawer>
  );
};

type TermsheetDrawersType = {
  transactionId: string;
  webformId: string;
  type: DrawerType;
  onClose: () => void;
  onSelect: (id: string) => void;
};
type getDrawerContentType = (type: DrawerType) => ReactNode;
type getDrawerDataType = (
  type: DrawerType,
  transactionId: string,
  webformId: string
) => void;
type getTermsheetsType = (id: string) => void;
type getTemplatesType = (id: string, webformId: string) => void;
