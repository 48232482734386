import React, { FC, useEffect, useState } from "react";
import { Button, Input } from "antd";
import {
  RelationshipInstitutionLabelType,
  RelationshipInstitutionType,
  RelationshipStructureLabelType,
  RelationshipStructureType,
} from "../../utils/enums";
import {
  GeographyType,
  IndustryType,
  PortfolioType,
  RelationShipDashboardFilterType,
} from "../../utils/types";
import { MultipleSelect } from "../general/MultipleSelect";
import { equals } from "ramda";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { UndoOutlined } from "@ant-design/icons";
import { columnSort } from "../../utils/relationship";

export const RelationshipDashboardFilters: FC<AnalyticsFiltersType> = ({
  filters,
  onChange,
  onReset,
  portfolios,
  industries,
  geographies,
}) => {
  const [currentFilters, setCurrentFilters] =
    useState<RelationShipDashboardFilterType | null>(null);
  useEffect(() => {
    setCurrentFilters(filters);
  }, [filters]);
  return (
    <div className={"flex flex-wrap gap-x-4 gap-y-2 items-center pb-2"}>
      <Input.Search
        className="w-56 mr-2"
        // disabled={loading.fetching}
        placeholder="Search by text"
        value={currentFilters?.searchKey}
        onChange={(e) =>
          setCurrentFilters({
            ...currentFilters,
            searchKey: e.target.value,
          })
        }
      />
      <MultipleSelect
        defaultValue={filters?.geographies}
        value={currentFilters?.geographies}
        className={"md:max-w-[50%] grow overflow-hidden max-h-[32px]"}
        placeholder={"Select Geographies"}
        onChange={(e): void => {
          setCurrentFilters({
            ...currentFilters,
            geographies: e as string[],
          });
        }}
        label={"Geographies"}
        options={geographies.map(({ id, name }) => ({
          key: id,
          value: id,
          label: name,
        }))}
      />
      <MultipleSelect
        defaultValue={filters?.industries}
        value={currentFilters?.industries}
        className={"md:max-w-[50%] grow overflow-hidden max-h-[32px]"}
        placeholder={"Select Industries"}
        onChange={(e): void => {
          setCurrentFilters({
            ...currentFilters,
            industries: e as string[],
          });
        }}
        label={"Industries"}
        options={industries.map(({ id, name }) => ({
          key: id,
          value: id,
          label: name,
        }))}
      />
      <MultipleSelect
        defaultValue={filters?.portfolios}
        value={currentFilters?.portfolios}
        className={"md:max-w-[50%] grow overflow-hidden max-h-[32px]"}
        placeholder={"Select Portfolio of Interest"}
        onChange={(e): void => {
          setCurrentFilters({
            ...currentFilters,
            portfolios: e as string[],
          });
        }}
        label={"Portfolio of Interest"}
        options={portfolios.map(({ id, portfolioCompanyName }) => ({
          key: id,
          value: id,
          label: portfolioCompanyName,
        }))}
      />
      <MultipleSelect
        defaultValue={filters?.institutionTypes}
        value={currentFilters?.institutionTypes}
        className={"md:max-w-[50%] grow overflow-hidden max-h-[32px]"}
        placeholder={"Select Institution Type"}
        onChange={(e): void => {
          setCurrentFilters({
            ...currentFilters,
            institutionTypes: e as string[],
          });
        }}
        label={"Institution Type"}
        options={Object.keys(RelationshipInstitutionType)
          .sort((a, b) => columnSort(a, b, "TEXT"))
          .map((value) => ({
            key: value,
            value: value,
            label:
              RelationshipInstitutionLabelType[
                value as RelationshipInstitutionType
              ],
          }))}
      />

      <MultipleSelect
        defaultValue={filters?.structuresTypes}
        value={currentFilters?.structuresTypes}
        className={"md:max-w-[50%] grow overflow-hidden max-h-[32px]"}
        placeholder={"Select Structures"}
        onChange={(e): void => {
          setCurrentFilters({
            ...currentFilters,
            structuresTypes: e as string[],
          });
        }}
        label={"Structures"}
        options={Object.keys(RelationshipStructureType)
          .sort((a, b) => columnSort(a, b, "TEXT"))
          .map((value) => ({
            key: value,
            value: value,
            label:
              RelationshipStructureLabelType[
                value as RelationshipStructureType
              ],
          }))}
      />

      <Button
        disabled={
          filters && currentFilters
            ? equals(filters, currentFilters) ?? false
            : true
        }
        onClick={(): void => {
          if (currentFilters) {
            onChange(currentFilters);
          }
        }}
        className={PRIMARY_BUTTON_STYLE}
      >
        Apply Filters
      </Button>
      <Button onClick={onReset} icon={<UndoOutlined />} />
    </div>
  );
};

type AnalyticsFiltersType = {
  filters: RelationShipDashboardFilterType | null;
  onChange: (filters: RelationShipDashboardFilterType) => void;
  industries: IndustryType[];
  geographies: GeographyType[];
  portfolios: PortfolioType[];
  onReset: () => void;
};
