import React, { FC, useState } from "react";
import { usePageTitle } from "../../../customHooks/usePageTitle";
import { createConfig, updateConfig } from "../../../services/services";
import { ResponseType } from "../../../utils/uiTypes";
import {
  CompanySettingConfigType,
  DealCloudConfigType,
} from "../../../utils/types";
import { eqProps } from "ramda";
import { CustomSpin } from "../../general/CustomSpin";
import { SettingOutlined } from "@ant-design/icons";
import {
  PRIMARY_BUTTON_STYLE,
  tableSelectCss,
} from "../../../utils/cssConfigs";
import { Button, Form, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import { formItemRequiredRule } from "../../../utils/formUtils";
import {
  ConfigType,
  ConfigurationSettingType,
  ElementType,
} from "../../../utils/enums";
import { useConfigs } from "../../../hooks/useConfigs";
import { useUser } from "../../../hooks/useUser";

const DEFAULT_CONFIG_VALUE: Partial<DealCloudConfigType> = {
  grantType: "client_credentials",
  scope: "data",
};

export const DealCloud: FC = () => {
  usePageTitle("DealCloud Settings");
  const { user } = useUser();
  const { configValues, setConfigValues } = useConfigs();
  const [form] = useForm<DealCloudConfigType>();

  const [loading, setLoading] = useState(false);

  const initiateCreateConfig: initiateCreateConfigType = (values) => {
    setLoading(true);
    createConfig({ body: JSON.stringify(values) })
      .then(
        ({
          data,
        }: ResponseType<CompanySettingConfigType<DealCloudConfigType>>) => {
          setConfigValues(ConfigType.DEAL_CLOUD_CONFIG, data);
          message.success("Credentials Added Successfully");
          setLoading(false);
        }
      )
      .catch((error: string) => {
        console.error(error);
        setLoading(false);
        message.error("Unable to add credentials");
      });
  };

  const initiateUpdateConfig: initiateUpdateConfigType = (
    companyId,
    values
  ) => {
    if (
      !eqProps(
        "configValue",
        values,
        configValues[ConfigType.DEAL_CLOUD_CONFIG]
      )
    ) {
      setLoading(true);
      updateConfig({
        segments: { id: companyId },
        body: JSON.stringify(values),
      })
        .then(
          ({
            data,
          }: ResponseType<CompanySettingConfigType<DealCloudConfigType>>) => {
            setConfigValues(ConfigType.DEAL_CLOUD_CONFIG, data);
            setLoading(false);
            message.success("Updated Credentials Successfully");
          }
        )
        .catch((error: string) => {
          console.error(error);
          setLoading(false);
          message.error("Unable to update credentials");
        });
    } else {
      message.info("Please update the setting details to update");
    }
  };

  const submitValues: submitValuesType = (config) => (values) => {
    const DEFAULT_VALUE: CompanySettingConfigType<DealCloudConfigType> = {
      elementType: ElementType.COMPANY,
      configType: ConfigType.DEAL_CLOUD_CONFIG,
      elementId: user?.companyId ?? "",
      settingType: ConfigurationSettingType.INTEGRATIONS,
    };

    if (config) {
      initiateUpdateConfig(user?.companyId ?? "", {
        ...DEFAULT_VALUE,
        ...config,
        configValue: { ...values, ...DEFAULT_CONFIG_VALUE },
      });
    } else {
      initiateCreateConfig({
        ...DEFAULT_VALUE,
        configValue: { ...values, ...DEFAULT_CONFIG_VALUE },
      });
    }
  };

  return (
    <div
      className={"relative w-full h-full bg-gray-100 flex flex-col pt-5 group"}
    >
      <CustomSpin loading={loading} />
      <div className={"bg-white h-full mx-6 mb-6"}>
        <div className={"mx-auto py-7"}>
          <p
            className={"max-w-xs text-center mx-auto py-2 text-2xl font-medium"}
          >
            <SettingOutlined className={"mr-2"} />
            DealCloud API Settings
          </p>
        </div>
        <Form<DealCloudConfigType>
          disabled={!user?.isAdmin}
          autoComplete={"off"}
          initialValues={
            configValues[ConfigType.DEAL_CLOUD_CONFIG]?.configValue
          }
          onFinish={submitValues(configValues[ConfigType.DEAL_CLOUD_CONFIG])}
          className={"max-w-xl mx-auto"}
          layout={"horizontal"}
          aria-autocomplete={"none"}
          form={form}
        >
          <FormItem
            className={tableSelectCss()}
            name={"clientId"}
            label={"Client ID"}
            required={true}
            rules={[formItemRequiredRule]}
          >
            <Input
              type={"text"}
              autoComplete={"new-client-id"}
              placeholder={"Enter Id"}
              bordered={false}
            />
          </FormItem>
          <FormItem
            className={tableSelectCss()}
            name={"clientSecret"}
            label={"Client Secret"}
            required={true}
            rules={[formItemRequiredRule]}
          >
            <Input.Password
              type={"text"}
              autoComplete={"new-password"}
              placeholder={"Enter Secret"}
              bordered={false}
            />
          </FormItem>
          <FormItem
            className={tableSelectCss()}
            name={"domain"}
            label={"Domain"}
            required={true}
            rules={[formItemRequiredRule]}
          >
            <Input
              autoComplete={"false"}
              type={"url"}
              placeholder={"Enter URL"}
              bordered={false}
            />
          </FormItem>
          <div className={"flex flex-row items-center justify-end gap-x-2"}>
            <Button
              onClick={() =>
                form.setFieldsValue(
                  configValues[ConfigType.DEAL_CLOUD_CONFIG]?.configValue ?? {}
                )
              }
            >
              Reset
            </Button>
            <Button
              onClick={() => form.submit()}
              className={PRIMARY_BUTTON_STYLE}
            >
              {configValues[ConfigType.DEAL_CLOUD_CONFIG]?.configValue
                ? "Update"
                : "Connect"}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

type getCompanySettingConfigurationType = (companyId: string) => void;
type submitValuesType = (
  config: CompanySettingConfigType<DealCloudConfigType> | null
) => (values: DealCloudConfigType) => void;

type initiateCreateConfigType = (
  values: CompanySettingConfigType<DealCloudConfigType>
) => void;
type initiateUpdateConfigType = (
  companyId: string,
  values: CompanySettingConfigType<DealCloudConfigType>
) => void;
