import React, { FC, useContext, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { TransactionContext } from "../../../../context/TransactionContext";
import { DeadlineEventModal } from "../../../modals/DeadlineEventModal";
import emptyCommunication from "../../../../images/icons/EmptyCommunacations_grey.svg";
import emptyDataroom from "../../../../images/icons/EmptyDataRoom.svg";
import emptyBidForm from "../../../../images/icons/EmptyBidForm.svg";
import emptyTaskList from "../../../../images/icons/EmptyTasklistIconDashboard.svg";
import {
  getAllDeadlinesForTransaction,
  getDashboardNotifications,
  markReadForTransaction,
} from "../../../../services/services";
import { fetchWithIdType, ResponseType } from "../../../../utils/uiTypes";
import { useParams } from "react-router";
import { DashboardTimeline } from "./DashboardTimeline";
import {
  NotificationActivityEnumType,
  PermissionType,
  PETransactionCreationType,
} from "../../../../utils/enums";
import {
  DashboardNotificationType,
  DeadLineType,
} from "../../../../utils/types";

import { usePageTitle } from "../../../../customHooks/usePageTitle";
import { NotificationsCard } from "./NotificationsCard";
import { any, equals, findIndex, path, propEq } from "ramda";
import {
  sortByPathToProperty,
  updateItemInArray,
} from "../../../../utils/utils";
import { DashboardTrackers } from "./dashboardTrackers/DashboardTrackers";
import { DashboardNotificationsModal } from "./DashboardNotificationsModal";

const FILTERS_ENABLED = [
  NotificationActivityEnumType.NOTIFICATION_FOR_COMMENT,
  NotificationActivityEnumType.NOTIFICATION_FOR_BID_FORM_UPDATE,
  NotificationActivityEnumType.NOTIFICATION_FOR_CREATE_FILE,
];

export const Dashboard: FC = function () {
  usePageTitle("Dashboard");
  const { id }: ParamType = useParams();

  const {
    transaction,
    permissions,
    belongToDealTeam,
    modules,
    setTransaction,
  } = useContext(TransactionContext);
  const [loadingTrackers, setLoadingTrackers] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [deadlines, setDeadlines] = useState<Array<DeadLineType>>([]);
  const [notifications, setNotifications] =
    useState<DashboardNotificationType | null>(null);
  const [selectedNotifications, setSelectedNotifications] =
    useState<NotificationNameInfoType | null>(null);

  const getNotifications: getNotificationsType = (transactionId) => {
    getDashboardNotifications({
      segments: {
        transactionId,
        pageIndex: 0,
        onlyTop5: false,
      },
    }).then(({ data }: ResponseType<DashboardNotificationType>) =>
      setNotifications(data)
    );
  };
  const getDeadlineInformation: getNotificationsType = (transactionId) => {
    getAllDeadlinesForTransaction({
      segments: {
        transactionId,
      },
    }).then(({ data = [] }: ResponseType<Array<DeadLineType>>) =>
      setDeadlines(sortByPathToProperty(["targetDate"], data))
    );
  };
  const deleteDeadline: getNotificationsType = (deadlineId) => {
    setDeadlines(deadlines.filter((o) => o.id !== deadlineId));
  };
  const addDeadline: updateDeadlineType = (deadline) => {
    setDeadlines(
      sortByPathToProperty(["targetDate"], [...deadlines, deadline])
    );
  };
  const updateDeadline: updateDeadlineType = (deadline) => {
    const index = findIndex(propEq("id", deadline.id))(deadlines);
    if (index >= 0)
      setDeadlines((list) => updateItemInArray(index, list, deadline));
  };

  const markActivitiesAsRead: fetchWithIdType = (transactionId) => {
    markReadForTransaction({ segments: { transactionId } }).catch(
      console.error
    );
  };

  useEffect(() => {
    markActivitiesAsRead(id);
  }, []);

  useEffect(() => {
    getDeadlineInformation(id);
    getNotifications(id);
  }, [id, belongToDealTeam]);

  useEffect(() => {
    if (!belongToDealTeam && transaction) {
      setLoadingTrackers(false);
    }
  }, [transaction, belongToDealTeam]);
  return (
    <div className={"relative max-h-full h-full w-full overflow-y-auto p-6"}>
      <Card
        bodyStyle={{ padding: "20px" }}
        className={"min-h-[18.75rem] min-w-[50rem]"}
        size="small"
        title={
          <div className={"w-full text-gray-400"}>
            TIMELINE
            {permissions.includes(PermissionType.ADMIN_PETRANSACTION) && (
              <Button
                className={
                  "float-right bg-primary hover:bg-hover text-white border-0"
                }
                onClick={(): void => setModalOpen(true)}
              >
                + Add Event
              </Button>
            )}
          </div>
        }
      >
        <DashboardTimeline
          transaction={transaction}
          setTransaction={setTransaction}
          editPermission={permissions.includes(
            PermissionType.ADMIN_PETRANSACTION
          )}
          deadlines={deadlines}
          onUpdate={updateDeadline}
          onCreate={addDeadline}
          onDelete={deleteDeadline}
        />
      </Card>

      <div className={"mt-4"}>
        {transaction && (
          <DashboardTrackers
            transactionId={id}
            loading={loadingTrackers}
            canEdit={permissions.includes(PermissionType.ADMIN_PETRANSACTION)}
            showNotes={permissions.includes(
              PermissionType.NON_ADMIN_PETRANSACTION
            )}
          />
        )}
      </div>
      {transaction?.peTransactionCreationType !==
        PETransactionCreationType.OFFLINE && (
        <div className={"mt-4 grid grid-cols-1 md:grid-cols-2 gap-2"}>
          {modules && modules?.communication && (
            <NotificationsCard
              id={id}
              ActivityEnum={
                NotificationActivityEnumType.NOTIFICATION_FOR_COMMENT
              }
              notifications={notifications}
              icon={emptyCommunication}
              title={"COMMUNICATIONS"}
              setSelected={setSelectedNotifications}
            />
          )}
          {modules && modules?.dataroom && (
            <NotificationsCard
              id={id}
              ActivityEnum={
                NotificationActivityEnumType.NOTIFICATION_FOR_CREATE_FILE
              }
              notifications={notifications}
              icon={emptyDataroom}
              title={"DATAROOM"}
              setSelected={setSelectedNotifications}
            />
          )}
          {modules && modules?.termsheet && (
            <NotificationsCard
              id={id}
              ActivityEnum={
                NotificationActivityEnumType.NOTIFICATION_FOR_BID_FORM_UPDATE
              }
              notifications={notifications}
              icon={emptyBidForm}
              title={"TERMSHEET"}
              setSelected={setSelectedNotifications}
            />
          )}
          {belongToDealTeam && modules?.taskList && (
            <NotificationsCard
              id={id}
              ActivityEnum={
                NotificationActivityEnumType.NOTIFICATION_FOR_TASK_ASSIGNED
              }
              notifications={notifications}
              icon={emptyTaskList}
              title={"TASKLIST"}
              setSelected={setSelectedNotifications}
            />
          )}
          {belongToDealTeam && modules?.notes && (
            <NotificationsCard
              id={id}
              ActivityEnum={
                NotificationActivityEnumType.NOTIFICATION_FOR_UPDATE_NOTE
              }
              notifications={notifications}
              icon={emptyBidForm}
              title={"NOTES"}
              setSelected={setSelectedNotifications}
            />
          )}
        </div>
      )}
      <DashboardNotificationsModal
        showFilters={
          (belongToDealTeam ?? false) &&
          any(equals(selectedNotifications?.notificationType), FILTERS_ENABLED)
        }
        name={selectedNotifications?.name}
        onClose={(): void => setSelectedNotifications(null)}
        transactionId={transaction?.peTransactionId ?? ""}
        notifications={path(
          [selectedNotifications?.notificationType ?? "", "activityDTOs"],
          notifications ?? {}
        )}
      />
      <DeadlineEventModal
        isOffline={equals(
          PETransactionCreationType.OFFLINE,
          transaction?.peTransactionCreationType
        )}
        onUpdate={updateDeadline}
        onCreate={addDeadline}
        dashboardTransaction={transaction}
        visible={modalOpen}
        handleCancel={(): void => setModalOpen(false)}
      />
    </div>
  );
};

type NotificationNameInfoType = {
  name: string;
  notificationType: NotificationActivityEnumType;
};
type getNotificationsType = (transactionId: string) => void;
type updateDeadlineType = (deadline: DeadLineType) => void;
type ParamType = { id: string };
