export const TRANSACTION = {
  createDate: "2023-04-15T02:44:17.000+0000",
  createdByUserDTO: {
    email: "aasim+ali@inncretech.com",
    userId: "3952087667591611651",
    firstName: "aasim",
    lastName: "Ali",
    isActive: true,
    emailVerified: true,
  },
  modifiedDate: "2023-04-15T02:53:34.000+0000",
  peTransactionId: "270609910624945754",
  peTransactionTitle: "Test",
  lenderDTOs: [
    {
      id: "270609997598033526",
      name: "aasim nov28",
      milestoneDTOs: [
        {
          petransactionId: null,
          mileStoneId: "878635",
          mileStoneTemplateId: "878633",
          name: "KICK OFF MEETING",
          rank: "0",
          done: false,
        },
        {
          petransactionId: null,
          mileStoneId: "878638",
          mileStoneTemplateId: "878633",
          name: "NDA",
          rank: "1",
          done: false,
        },
        {
          petransactionId: null,
          mileStoneId: "878636",
          mileStoneTemplateId: "878633",
          name: "VDR ACCESS",
          rank: "2",
          done: false,
        },
        {
          petransactionId: null,
          mileStoneId: "878639",
          mileStoneTemplateId: "878633",
          name: "GRID AGREED",
          rank: "3",
          done: false,
        },
        {
          petransactionId: null,
          mileStoneId: "878634",
          mileStoneTemplateId: "878633",
          name: "DD MEETINGS",
          rank: "4",
          done: false,
        },
        {
          petransactionId: null,
          mileStoneId: "878637",
          mileStoneTemplateId: "878633",
          name: "CREDIT APPROVAL",
          rank: "5",
          done: false,
        },
      ],
      companyDTO: {
        id: "9148",
        name: "aasim nov28",
        tncApproved: true,
      },
    },
  ],
  milestonesTemplateId: "878633",
  companyId: "2",
  companyName: "Termgrid UAT",
  peTransactionNotificationCount: "0",
  targetDate: "1682564005000",
  color: "#808080",
  watermark: false,
  peTransactionTagType: "LIVE",
  peTransactionCreatorType: "BORROWER",
  peTransactionCreationType: "ONLINE",
  creationDate: "1681444800000",
  deleted: false,
};
export const ALLOCATION_FEES = {
  columns: [
    {
      id: "396744",
      name: "Role",
      canDelete: false,
      fieldType: "TEXT",
    },
    {
      id: "396746",
      name: "Demand",
      canDelete: false,
      fieldType: "NUMERIC",
    },
    {
      id: "396830",
      name: "Facility 1",
      canDelete: true,
      fieldType: "NUMERIC",
    },
  ],
  rows: [
    {
      id: "76931",
      name: "Account 1",
      columns: {
        "396744": {
          id: "848954",
          currency: "DOLLAR",
          value: "",
          unit: "",
        },
        "396746": {
          id: "848955",
          currency: "DOLLAR",
          value: "",
          unit: "THOUSAND",
        },
        "396830": {
          id: "848956",
          currency: "DOLLAR",
          value: "",
          unit: "THOUSAND",
        },
      },
    },
  ],
};
const DEADLINE = {
  id: "878646",
  peTransactionId: "270609910624945754",
  lenderDTOS: [
    {
      id: "270609997598033526",
      name: "aasim nov28",
      companyDTO: {
        createdBy: "3672413172049904647",
        id: "9148",
        name: "aasim nov28",
      },
    },
  ],
  targetDate: "1682479323000",
  timeZone: "America/New_York",
  note: "Comment",
  title: "Deadline",
  color: "#808080",
  active: true,
  sendEmail: true,
};
const TASK = {
  createDate: "2023-04-15T03:24:31.363+0000",
  createdByUserDTO: {
    email: "aasim+ali@inncretech.com",
    userId: "3952087667591611651",
    firstName: "aasim",
    lastName: "Ali",
    companyId: "2",
    isActive: true,
    phoneNumber: "+447393618778",
    title: "Tile",
  },
  modifiedDate: "2023-04-15T03:24:31.363+0000",
  id: "878648",
  taskTitle: "saasa",
  taskDetails: "Task Description",
  taskAssigneesDTO: [
    {
      createdBy: "3952087667591611651",
      id: "878649",
      assignedBy: "3952087667591611651",
      assignToId: "3952087667591611651",
      active: true,
      userDTO: {
        email: "aasim+ali@inncretech.com",
        userId: "3952087667591611651",
        firstName: "aasim",
        lastName: "Ali",
        companyId: "2",
        isActive: true,
        phoneNumber: "+447393618778",
        title: "Tile",
      },
      completed: false,
    },
  ],
  dueDate: "1683257058000",
  taskStatus: "INITIAL",
  taskSectionId: "878640",
  transactionId: "270609910624945754",
  active: true,
};
const TRACKERS = {
  id: "878633",
  name: "Default",
  milestoneDTOs: [
    {
      petransactionId: null,
      mileStoneId: "878634",
      mileStoneTemplateId: "878633",
      name: "DD MEETINGS",
      rank: "4",
      done: null,
    },
    {
      petransactionId: null,
      mileStoneId: "878635",
      mileStoneTemplateId: "878633",
      name: "KICK OFF MEETING",
      rank: "0",
      done: null,
    },
    {
      petransactionId: null,
      mileStoneId: "878639",
      mileStoneTemplateId: "878633",
      name: "GRID AGREED",
      rank: "3",
      done: null,
    },
    {
      petransactionId: null,
      mileStoneId: "878638",
      mileStoneTemplateId: "878633",
      name: "NDA",
      rank: "1",
      done: null,
    },
    {
      petransactionId: null,
      mileStoneId: "878636",
      mileStoneTemplateId: "878633",
      name: "VDR ACCESS",
      rank: "2",
      done: null,
    },
    {
      petransactionId: null,
      mileStoneId: "878637",
      mileStoneTemplateId: "878633",
      name: "CREDIT APPROVAL",
      rank: "5",
      done: null,
    },
  ],
};
export const SAMPLE_EVENT_PAYLOAD = {
  timestamp: "2023-05-18T17:21:29.461-0400",
  eventType: "user.updated",
  data: {
    name: "John Doe",
    age: 35,
    email: "johndoe@example.com",
  },
  entityId: 12345,
  entityType: "USER",
  version: "1",
};

export const EVENT_PAYLOAD_DESCRIPTION = [
  {
    fieldName: "timestamp",
    type: "Timestamp",
    description:
      "The purpose of the timestamp field is to record the time of when the event was triggered. It should be utilized to ensure the correct sequence of all events, particularly in the event of system failures that may cause an older event to be transmitted before a newer one.",
  },
  {
    fieldName: "eventType",
    type: "String",
    description: "The type of the event.",
  },
  {
    fieldName: "data",
    type: "Map<String, Object>",
    description:
      "A map containing the actual data of the entity being tracked, along with information about what was updated. The keys in the map represent the entity's properties, and the values represent the new values of those properties.",
  },
  {
    fieldName: "entityId",
    type: "Long",
    description: "The ID of the entity associated with the event.",
  },
  {
    fieldName: "entityType",
    type: "String",
    description: "The type of the entity associated with the event.",
  },
  {
    fieldName: "version",
    type: "String",
    description:
      "The version field is used to track any changes made to the data structure for the entity. This can be useful for ensuring backward compatibility and tracking the evolution of the entity's data over time. The value of version can be updated whenever there are changes made to the data structure.",
  },
];

export const EVENT_TYPES = [
  {
    name: "transaction.created",
    description: "This event is sent whenever a new transaction is created.",
    sample: JSON.stringify(TRANSACTION),
  },
  {
    name: "transaction.updated",
    description:
      "This event is triggered when a transaction is modified, including changes to its status, name, associated institutions, or other associated metadata.",
    sample: JSON.stringify(TRANSACTION),
  },
  {
    name: "allocation.updated",
    description: "This event is sent whenever allocations table is updated.",
    sample: JSON.stringify(ALLOCATION_FEES),
  },
  {
    name: "fee.updated",
    description: "This event is sent whenever fees table is updated.",
    sample: JSON.stringify(ALLOCATION_FEES),
  },
  {
    name: "deadline.created",
    description:
      "This event is sent when a new deadline is created for an institution on the system. A deadline represents a specific time or date by which an action or task must be completed.",
    sample: JSON.stringify(DEADLINE),
  },
  {
    name: "deadline.updated",
    description:
      "This event is sent when an existing deadline is updated for an institution on the system.",
    sample: JSON.stringify(DEADLINE),
  },
  {
    name: "task.created",
    description:
      "This event is sent whenever a new task is created in the system. Tasks represent units of work that need to be completed by users. An event is sent with details such as the task name, description, due date, and assigned user or group.",
    sample: JSON.stringify(TASK),
  },
  {
    name: "task.updated",
    description:
      " This event is sent whenever a task is updated in the system. Task updates can include changes to the task name, description, due date, assigned user or group, or status. When a task is updated, this event is sent with the updated details to inform the subscribed parties of the change.",
    sample: JSON.stringify(TASK),
  },
  {
    name: "trackers.updated",
    description:
      "This event is triggered whenever there is an update to the trackers. It provides information about any changes made to existing trackers or new ones that were added. Examples of changes include modifications to tracker names, their order/rank, or additions/deletions of trackers.",
    sample: JSON.stringify(TRACKERS),
  },
];
