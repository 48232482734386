import React, { FC, useState } from "react";
import { AnalyticsDataRenderType } from "./AnalyticsData";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highcharts";
import Annotations from "highcharts/modules/annotations";
import Heatmap from "highcharts/modules/heatmap";
import TreeMap from "highcharts/modules/treemap";
import Exporting from "highcharts/modules/exporting";
import Offline from "highcharts/modules/offline-exporting";
import ExportData from "highcharts/modules/export-data";
import NoData from "highcharts/modules/no-data-to-display";
import { CustomSpin } from "../general/CustomSpin";
import { getHighchartsConfig } from "../../utils/charts";
import { arrayToMapConversion, doNothing } from "../../utils/utils";
import { Button } from "antd";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import { compose, join, last, map, split } from "ramda";
import { AnalyticsChartModal } from "./AnalyticsChartModal";

Annotations(Highcharts);
Heatmap(Highcharts);
TreeMap(Highcharts);
Exporting(Highcharts);
Offline(Highcharts);
ExportData(Highcharts);
NoData(Highcharts);

const getNameFromClickEvent = (array: Array<string> = []) =>
  join(", ", map(compose(last, split(":")), array));

export const AnalyticsChart: FC<AnalyticsDataRenderType> = ({
  data,
  loading,
  chartType,
  onRefresh,
  label,
  name,
  params,
  filters,
  onDownload,
  isClickable = true,
}) => {
  const [chartItemModal, setChartItemModal] = useState<{
    name: string;
    params: string;
  } | null>(null);
  const onClickChart: onClickChartType = (e) => {
    setChartItemModal({
      name: getNameFromClickEvent(e),
      params:
        e.reduce((prev, current) => {
          return prev + `&filterBy=${encodeURIComponent(current)}`;
        }, params) ?? "",
    });
  };

  return (
    <div className={"p-1.5 w-full md:w-1/2 grow"}>
      <div
        className={
          "bg-white flex items-stretch flex-col drop-shadow-sm border border-gray-200"
        }
      >
        <div
          className={
            "flex flex-row items-center p-2 border-b border-gray-200 bg-gray-50 select-all"
          }
        >
          {label}
          {onRefresh && (
            <Button
              className={"ml-auto"}
              type={"text"}
              icon={<ReloadOutlined className={"text-primary"} />}
              onClick={onRefresh}
            />
          )}
          {onDownload && (
            <Button
              type={"text"}
              icon={<DownloadOutlined className={"text-primary"} />}
              onClick={onDownload}
            />
          )}
        </div>
        {filters}
        <div className={"h-full w-full relative min-h-[500px]"}>
          {!loading && chartType ? (
            <HighchartsReact
              allowChartUpdate={true}
              highcharts={Highcharts}
              options={getHighchartsConfig(
                true,
                "",
                chartType,
                data?.data ?? [],
                undefined,
                undefined,
                [],
                false,
                arrayToMapConversion(
                  JSON.parse(data?.configuration ?? "{}")?.properties ?? [],
                  "key"
                ),
                isClickable ? onClickChart : doNothing
              )}
            />
          ) : (
            <></>
          )}
          <CustomSpin
            loading={loading}
            loadingText={""}
            size={"small"}
            transparent={false}
          />
          {chartItemModal && (
            <AnalyticsChartModal
              visible={chartItemModal !== null}
              title={`${label} - ${chartItemModal?.name}`}
              name={name}
              params={chartItemModal?.params}
              onClose={(): void => setChartItemModal(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

type onClickChartType = (e: string[]) => void;
