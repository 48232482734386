import React from "react";

export const preventNonNumericText: keyboardEventUtil = (event) => {
  if (event.key.length === 1 && isNaN(Number(event.key)) && event.key !== ".")
    event.preventDefault();
};
export const stopPropagationOnEnter: keyboardEventUtil = (event) => {
  if (event.code === "Enter") {
    event.stopPropagation();
  }
};

type keyboardEventUtil = (event: React.KeyboardEvent) => void;
