import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { usePageTitle } from "../customHooks/usePageTitle";
import TermgridLogo from "../images/logo/TermgridLogoLight.svg";
import { LoginForm } from "../components/login/LoginForm";
import { ResetPasswordForm } from "../components/login/ResetPasswordForm";
import { SsoForm } from "../components/login/SsoForm";
import { useLocation } from "react-router-dom";
import { any, equals, isNil, path, pathOr } from "ramda";
import { useForm } from "antd/es/form/Form";
import { Alert, Button, Form } from "antd";
import { ErrorType, ResponseType } from "../utils/uiTypes";
import {
  checkLoginType,
  forgotPassword,
  login,
  send2FAPhoneCode,
} from "../services/services";
import { ConfigurationSettingType } from "../utils/enums";
import { CompanySettingConfigType, UserType } from "../utils/types";
import { loginRedirect } from "../utils/redirect";
import { UserContext } from "../context/UserContext";
import { useHistory } from "react-router";

export const Login: FC = function () {
  usePageTitle("Login", false);
  const [form] = useForm();
  const { setUser } = useContext(UserContext);
  const history = useHistory();

  const { search } = useLocation();

  const [loginState, setLoginState] = useState<LoginFormState>(
    LoginFormState.SSO
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType | null>(null);

  useEffect(() => {
    const ssoEmail = new URLSearchParams(search).get("email");
    if (!isNil(ssoEmail)) {
      form.setFieldValue("email", ssoEmail);
    }

    const ssoError = new URLSearchParams(search).get("error");
    if (!isNil(ssoError)) {
      setError({ message: ssoError });
    }
  }, [search]);

  const initiateLoginCheck = (form: LoginFormState, email: string): void => {
    checkLoginType({
      params: {
        email,
      },
    })
      .then(({ data }: ResponseType<CompanySettingConfigType<any>>) => {
        if (pathOr(false, ["configValue", "isSsoMember"], data)) {
          window.location.href =
            window.location.origin +
            `/api/rest/users/oauth_login?email=${email}`;
        } else {
          setLoginState(LoginFormState.LOGIN);
        }
        setLoading(false);
      })
      .catch((error: string) => onFailure(error));
  };

  const render = (state: LoginFormState): ReactNode => {
    switch (state) {
      case LoginFormState.LOGIN:
      case LoginFormState.SUCCESS:
        return (
          <LoginForm setFormState={setLoginState} currentState={loginState} />
        );
      case LoginFormState.SSO:
        return (
          <SsoForm setFormState={setLoginState} currentState={loginState} />
        );
      default:
        return (
          <ResetPasswordForm
            setFormState={setLoginState}
            currentState={loginState}
          />
        );
    }
  };

  const send2FaPhoneVerificationCode = (user: UserType): void => {
    send2FAPhoneCode({
      body: JSON.stringify({}),
    }).then(() => {
      onSuccess(user);
    });
  };
  const signIn: signInType = ({ email, password }) => {
    login({
      body: JSON.stringify({
        email: email.trim(),
        password,
      }),
    })
      .then(({ data }: ResponseType<UserType>) => {
        if (data?.sms2FaEnabled) {
          send2FaPhoneVerificationCode(data);
        } else {
          onSuccess(data);
        }
      })
      .catch((error: string) => {
        onFailure(error);
      });
  };

  const onSuccess: onSuccessType = (data) => {
    setUser(data);
    history.push(loginRedirect(data));
    setLoading(false);
  };
  const onFailure: onFailureType = (message) => {
    setError({ error: true, message });
    setLoading(false);
  };

  const resetPassword: resetPasswordType = (email) => {
    forgotPassword({
      body: JSON.stringify({ email }),
    })
      .then(() => {
        setLoading(false);
        setError({ message: "Check your email for instructions." });
      })
      .catch((error: string) => {
        setLoading(false);
        setError({ error });
      });
  };

  const onSubmit = (loginState: LoginFormState, values: LoginType) => {
    setLoading(true);
    if (
      any(equals(loginState), [
        LoginFormState.LOGIN,
        LoginFormState.SUCCESS,
        LoginFormState.FAILURE,
      ])
    ) {
      signIn({ email: values.email, password: values.password });
    } else if (
      any(equals(loginState), [LoginFormState.RESET, LoginFormState.FORGOT])
    ) {
      resetPassword(values.email);
    } else {
      initiateLoginCheck(loginState, values.email);
    }
  };

  return (
    <div
      style={{
        backgroundImage: "url('../../images/hero.jpg')",
      }}
      className={
        "h-screen w-screen bg-cover bg-center overflow-y-auto hide-scrollbar"
      }
    >
      <img
        className={"mx-auto pt-5 w-48"}
        src={TermgridLogo}
        alt={"Termgrid Logo"}
      />
      <div className={"px-5 mx-auto"}>
        <div className={"bg-white border mx-auto my-16 sm:my-24 p-10 max-w-lg"}>
          <Form
            form={form}
            initialValues={{ remember: true }}
            onFinish={(values): void => {
              onSubmit(loginState, values);
            }}
          >
            {render(loginState)}
            {error && (
              <Alert
                className={"my-4"}
                message={error.message}
                closable={true}
                type="error"
              />
            )}
            {any(equals(loginState), [
              LoginFormState.RESET,
              LoginFormState.FORGOT,
            ]) && (
              <Button
                className={`w-full `}
                disabled={loading}
                onClick={(): void => {
                  setLoginState(LoginFormState.LOGIN);
                }}
              >
                Back
              </Button>
            )}
            <Form.Item>
              <Button
                className={`w-full bg-primary hover:bg-hover text-white border-0`}
                disabled={loading}
                htmlType={"submit"}
                loading={loading}
              >
                {any(equals(loginState), [
                  LoginFormState.LOGIN,
                  LoginFormState.SSO,
                  LoginFormState.SUCCESS,
                ])
                  ? "Sign In"
                  : "Reset"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export enum LoginFormState {
  SUCCESS,
  FAILURE,
  FORGOT,
  RESET,
  LOGIN,
  SSO,
}

export type LoginFormType = {
  setFormState: (state: LoginFormState) => void;
  currentState: LoginFormState;
};

type LoginType = {
  email: string;
  password: string;
};
type signInType = (loginCredentials: LoginType) => void;
type onSuccessType = (data: UserType) => void;
type onFailureType = (error: string) => void;
type resetPasswordType = (email: string) => void;
