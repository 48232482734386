import React, { FC, ReactNode, useState } from "react";
import { Steps } from "antd";
import { BankOutlined, ShopOutlined, TeamOutlined } from "@ant-design/icons";

import { CompanyProfileForm } from "../components/company/CompanyProfileForm";
import { TeamManage } from "../components/company/TeamManage";
import { TermsheetTemplateRoutes } from "../components/company/TermsheetTemplateRoutes";
import { usePageTitle } from "../customHooks/usePageTitle";

export const CompanySetup: FC = function () {
  usePageTitle("Company Setup");

  const { Step } = Steps;

  const [current, setCurrent] = useState<number>(0);

  const renderStep = (current: number): ReactNode => {
    switch (current) {
      case 0:
        return (
          <CompanyProfileForm
            initialSetup={true}
            onSubmit={(): void => {
              setCurrent(1);
              renderStep(1);
            }}
          />
        );
      case 1:
        return (
          <TeamManage
            initialSetup={true}
            onSubmit={(val): void => {
              setCurrent(val);
              renderStep(val);
            }}
          />
        );
      case 2:
        return (
          <TermsheetTemplateRoutes
            initialSetup={true}
            onSubmit={(val): void => {
              setCurrent(val);
              renderStep(val);
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={"w-full h-screen flex flex-col"}>
      <Steps
        size={"small"}
        current={current}
        type={"navigation"}
        className={"bg-gray-100 z-50"}
      >
        <Step
          className={`${"cursor-pointer"}`}
          icon={
            <span className={"flex flex-col"}>
              <BankOutlined style={{ fontSize: "20px" }} />
              <span className={"font-medium"} style={{ fontSize: "12px" }}>
                SET UP YOUR COMPANY
              </span>
            </span>
          }
          key={1}
          onClick={(): void => {
            setCurrent(0);
          }}
        />
        <Step
          className={`${(current === 1 || current === 2) && "cursor-pointer"}`}
          icon={
            <span className={"flex flex-col"}>
              <TeamOutlined style={{ fontSize: "20px" }} />
              <span className={"font-medium"} style={{ fontSize: "12px" }}>
                ADD TEAM MEMBERS
              </span>
            </span>
          }
          key={2}
          onClick={(): void => {
            if (current === 1 || current === 2) {
              setCurrent(1);
            }
          }}
        />
        <Step
          className={`${current === 2 && "cursor-pointer"}`}
          icon={
            <span className={"flex flex-col"}>
              <ShopOutlined style={{ fontSize: "20px" }} />
              <span className={"font-medium"} style={{ fontSize: "12px" }}>
                CREATE TEMPLATES
              </span>
            </span>
          }
          key={3}
          onClick={(): void => {
            if (current === 2) {
              setCurrent(2);
            }
          }}
        />
      </Steps>
      {renderStep(current)}
    </div>
  );
};
