import React, { FC, ReactNode, useEffect, useState } from "react";

import { PortfolioCard } from "../PortfolioCard";
import { useParams } from "react-router";
import {
  AllocationKeyType,
  AllocationTableMappingType,
  AllocationType,
  CompanyType,
} from "../../../../utils/types";
import { getSecondaryList, TableDataType } from "../../../../utils/allocation";
import {
  AllocationElementType,
  AllocationTableType,
  ElementType,
  PermissionType,
  PortfolioSectionEnumType,
} from "../../../../utils/enums";
import { initiateFetchSection } from "../../../../utils/portfolio";
import { Button, message, Select, Tag, Tooltip } from "antd";
import { filter, findIndex, isEmpty, isNil, pluck, propEq } from "ramda";
import { AddAllocationModal } from "../../../transaction/menu/allocation/AddAllocationModal";
import { AllocationTable } from "../../../transaction/menu/allocation/AllocationTable";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import {
  ACTION_BUTTON_CSS,
  PRIMARY_BUTTON_STYLE,
} from "../../../../utils/cssConfigs";
import {
  deleteAllocationTableVersion,
  getAllocationTableVersions,
} from "../../../../services/services";
import { ResponseType } from "../../../../utils/uiTypes";
import moment from "moment";
import { NewTableVersionModal } from "../../portfolioModals/NewTableVersionModal";
import { PortfolioAdminPermissionBlock } from "../../PortfolioAdminPermissionBlock";
import { ConfirmDelete } from "../../../../utils/confirmationModals";
import {
  removeItemFromArray,
  updateItemInArray,
} from "../../../../utils/utils";
import { EditVersionModal } from "../../portfolioModals/EditVersionModal";
import { getPopupContainerForSelect } from "../../../../utils/container";
import {
  FullScreenButton,
  FullScreenWidget,
} from "../../../general/FullScreenWidget";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { usePageTitle } from "../../../../customHooks/usePageTitle";

export const PortfolioFees: FC<PortfolioFeesType> = ({
  companies,
  permissions,
  portfolioId,
}) => {
  usePageTitle("Fees");

  const { sectionId }: ParamsType = useParams();
  const { height: windowHeight } = useWindowDimensions();
  const [newVersion, setNewVersion] = useState(false);
  const [editVersion, setEditVersion] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [tableConfig, setTableConfig] = useState<TableDataType | null>(null);
  const [tableData, setTableData] = useState<AllocationType[]>([]);
  const [allocationTableKeys, setAllocationTableKeys] = useState<
    AllocationKeyType[]
  >([]);
  const [selectedVersion, setSelectedVersion] =
    useState<AllocationTableMappingType | null>(null);
  const [allocationTableVersions, setAllocationTableVersions] = useState<
    AllocationTableMappingType[]
  >([]);

  /** Gets allocation table data **/
  const getData = (tableId: string) => {
    initiateFetchSection(sectionId, portfolioId, "", tableId)
      .then(({ allocationAndFeesDTO }) => {
        setTableConfig(
          allocationAndFeesDTO.allocationTableMappingDTOList[0] ?? []
        );
        setAllocationTableKeys(
          allocationAndFeesDTO?.allocationKeyDTOList ?? []
        );
        setTableData(allocationAndFeesDTO?.allocationDTOList ?? []);
      })
      .catch((e: string) => e && message.error(e))
      .then(() => setLoading(false));
  };

  const getVersions = (version?: AllocationTableMappingType) => {
    if (portfolioId) {
      getAllocationTableVersions({
        params: {
          elementId: portfolioId,
          allocationElementType: AllocationElementType.PORTFOLIO,
          allocationType: AllocationTableType.FEES,
        },
      })
        .then(({ data }: ResponseType<AllocationTableMappingType[]>) => {
          const v = version ?? data[0];
          setAllocationTableVersions(data);
          setSelectedVersion(v);
          getData(v.id);
        })
        .catch((e: string) => e && message.error(e));
    }
  };

  const deleteAllocationVersion = (id: string) => {
    deleteAllocationTableVersion({
      segments: {
        id,
      },
    })
      .then(() => {
        const index = findIndex(propEq("id", id), allocationTableVersions);
        if (index >= 0) {
          const list = removeItemFromArray(index, allocationTableVersions);
          setAllocationTableVersions(list);
          if (list.length > 0) {
            setSelectedVersion(list[0]);
            getData(list[0].id);
          } else {
            setSelectedVersion(null);
            setTableConfig(null);
            setTableData([]);
            setAllocationTableKeys([]);
          }
        }
      })
      .catch(() => {
        message.error("Unable to delete this version");
      });
  };

  useEffect(() => {
    getVersions();
  }, [portfolioId]);

  return (
    <PortfolioCard
      permissions={permissions}
      title={"Fees"}
      bodyStyle={{ padding: "0 5px" }}
      loading={loading}
    >
      <>
        <FullScreenWidget>
          {(isFullScreen, trigger): ReactNode => (
            <div
              className={`${
                isFullScreen &&
                "bg-gray-50 h-screen w-screen overflow-y-scroll p-6"
              } h-full`}
            >
              <AllocationTable
                sectionType={PortfolioSectionEnumType.FEES}
                extraOptions={
                  <FullScreenButton
                    isFullScreen={isFullScreen}
                    trigger={trigger}
                  />
                }
                loading={loading || isEmpty(tableConfig)}
                hasDate={false}
                pageType={ElementType.PORTFOLIO}
                editPermission={permissions.includes(
                  PermissionType.ADMIN_PORTFOLIO
                )}
                peTransactionIdOrPortfolioId={portfolioId ?? ""}
                keys={filter(
                  propEq("allocationTableType", AllocationTableType.FEES),
                  allocationTableKeys
                )}
                config={tableConfig}
                companies={companies}
                data={tableData}
                toolbar={true}
                tableHeight={
                  isFullScreen ? windowHeight - 300 : windowHeight / 3
                }
                institutionModal={(
                  onSubmit,
                  selectedCompanies,
                  disabled
                ): ReactNode => {
                  return (
                    <AddAllocationModal
                      disabled={disabled}
                      onSubmit={onSubmit}
                      companies={getSecondaryList(
                        selectedCompanies,
                        [],
                        companies
                      )}
                    />
                  );
                }}
              >
                {(isUpdated, isLoading) => (
                  <div className={"flex flex-row gap-2 items-center"}>
                    <Select
                      disabled={isUpdated || isLoading}
                      getPopupContainer={getPopupContainerForSelect}
                      className={"w-96 pr-0 shadow"}
                      onSelect={(v: any) => {
                        const index = findIndex(
                          propEq("id", v),
                          allocationTableVersions
                        );
                        if (index >= 0) {
                          setSelectedVersion(allocationTableVersions[index]);
                          getData(allocationTableVersions[index].id);
                        }
                      }}
                      suffixIcon={
                        <i className="fa-solid fa-angle-down text-primary"></i>
                      }
                      value={selectedVersion?.id}
                    >
                      {allocationTableVersions?.map(
                        ({ id, name, allocationDate }) => (
                          <Select.Option key={id} value={id}>
                            <div
                              className={"flex flex-row gap-2 justify-between"}
                            >
                              <div className={"ellipsis truncate"}>{name}</div>
                              <div className={"italic text-right"}>
                                {!isEmpty(allocationDate) &&
                                  !isNil(allocationDate) && (
                                    <Tag color={"blue"}>
                                      {moment(allocationDate).format("ll")}
                                    </Tag>
                                  )}
                              </div>
                            </div>
                          </Select.Option>
                        )
                      )}
                    </Select>
                    <PortfolioAdminPermissionBlock permissions={permissions}>
                      <div className={"flex flex-row gap-2 group"}>
                        <Tooltip title="Add New Fees">
                          <Button
                            title={"Add New Fees"}
                            icon={<PlusOutlined className={"text-primary"} />}
                            disabled={isUpdated || isLoading}
                            onClick={(): void => {
                              setNewVersion(true);
                            }}
                            className={ACTION_BUTTON_CSS}
                          />
                        </Tooltip>
                        <Tooltip title="Edit Key Information">
                          <Button
                            title={"Edit Key Information"}
                            icon={<EditOutlined />}
                            disabled={
                              !selectedVersion || isUpdated || isLoading
                            }
                            onClick={(): void => {
                              setEditVersion(true);
                            }}
                            className={ACTION_BUTTON_CSS}
                          />
                        </Tooltip>
                        {allocationTableVersions.length > 1 && (
                          <Tooltip title="Delete">
                            <Button
                              title={"Delete"}
                              disabled={isUpdated || isLoading}
                              icon={
                                <DeleteOutlined className={"text-danger"} />
                              }
                              onClick={(): void => {
                                ConfirmDelete(
                                  "Are you sure you want to delete this version? All entered data will be lost.",
                                  () => {
                                    if (selectedVersion?.id) {
                                      deleteAllocationVersion(
                                        selectedVersion?.id
                                      );
                                    }
                                  },
                                  <></>
                                );
                              }}
                              className={ACTION_BUTTON_CSS}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </PortfolioAdminPermissionBlock>
                  </div>
                )}
              </AllocationTable>
            </div>
          )}
        </FullScreenWidget>
        <NewTableVersionModal
          title={"Add New Fees"}
          sectionType={PortfolioSectionEnumType.FEES}
          visible={newVersion}
          portfolioId={portfolioId}
          sectionId={sectionId}
          versions={allocationTableVersions}
          dates={pluck("allocationDate", allocationTableVersions)}
          onSave={(version) => {
            getVersions(version);
            setNewVersion(false);
          }}
          onCancel={() => {
            setNewVersion(false);
          }}
        />
        {selectedVersion && (
          <EditVersionModal
            sectionType={PortfolioSectionEnumType.FEES}
            visible={editVersion}
            version={selectedVersion}
            dates={pluck("allocationDate", allocationTableVersions)}
            onSave={(version) => {
              const index = findIndex(
                propEq("id", version.id),
                allocationTableVersions
              );
              if (index >= 0) {
                setAllocationTableVersions(
                  updateItemInArray(index, allocationTableVersions, version)
                );
                setSelectedVersion(version);
                getData(version.id);
              }
              setEditVersion(false);
            }}
            onCancel={() => {
              setEditVersion(false);
            }}
          />
        )}
      </>
    </PortfolioCard>
  );
};

type ParamsType = {
  portfolioId: string;
  sectionId: string;
};

type PortfolioFeesType = {
  companies: CompanyType[];
  permissions: PermissionType[];
  portfolioId: string;
};
