import React, { FC } from "react";
import { UserType } from "../../../utils/types";
import { convertDateToFormat } from "../../../utils/moment";
import EmptyBidForm from "../../../images/icons/EmptyBidForm.svg";

export const TemplateCard: FC<TemplateCardType> = function ({
  name = "",
  selected = false,
  creator,
  createDate,
  onSelect,
}: TemplateCardType) {
  return (
    <div
      className={`relative h-12 w-full bg-gray-50 border shadow-xs hover:border-primary ${
        selected && "border-primary text-primary"
      } hover:text-primary`}
    >
      <div role={"button"} onClick={onSelect}>
        <div>
          <div className={"float-left ml-1 mr-2"}>
            <img className={"h-8 w-8 m-0"} src={EmptyBidForm} alt={""} />
          </div>
        </div>
        <div className={"m-2"}>
          <div className={"text-xs"}>{name}</div>
          <div className={"mt-px flex justify-between"}>
            <div className={"text-xxs"}>
              {creator?.firstName} {creator?.lastName}
            </div>
            <div className={"text-xxs"}>
              <span>{convertDateToFormat(createDate, "ll", false)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type TemplateCardType = {
  name: string;
  selected?: boolean;
  creator?: UserType;
  createDate: string;
  onSelect: () => void;
};
