import React, { FC, useEffect, useState } from "react";
import { usePageTitle } from "../customHooks/usePageTitle";
import { Button, message, Table, Tag } from "antd";
import {
  addCRMCompanyMapping,
  deleteThirdPartyIntegrations,
  getCrmMappings,
  getCrmMappingsExposures,
  getDealCloudCompanies,
  getGeographies,
  getIndustries,
  getPortfoliosForCrmMappings,
  mapInstitutionstoRelationship,
  updateCRMCompanyMapping,
} from "../services/services";
import { fetchWithIdType, ResponseType, voidType } from "../utils/uiTypes";
import {
  CompanyType,
  CRMCompanyMappingType,
  DealCloudCompanyDTOType,
  DealCloudGetCompanyResponseDTOType,
  GeographyType,
  IndustryType,
  PortfolioType,
  RelationShipDashboardFilterType,
  RelationshipExposureResponse,
} from "../utils/types";
import {
  all,
  defaultTo,
  equals,
  findIndex,
  intersection,
  join,
  lensPath,
  omit,
  or,
  propEq,
  set,
} from "ramda";
import { CustomSpin } from "../components/general/CustomSpin";
import { ColumnsType } from "antd/es/table";
import useWindowDimensions from "../customHooks/useWindowDimensions";
import { Link } from "react-router-dom";
import { RelationshipDealCloudIntegration } from "../components/relationship/RelationshipDealCloudIntegration";
import { isEmptyOrNil, updateItemInArray, valOrDefault } from "../utils/utils";
import {
  AnalyticsCurrencyType,
  ConfigType,
  ElementType,
  PermissionType,
  RelationshipInstitutionLabelType,
  RelationshipInstitutionType,
  RelationshipInvestmentLabelType,
  RelationshipInvestmentType,
  RelationshipStructureLabelType,
  RelationshipStructureType,
  ThirdPartyIntegrationType,
  UnitLabelType,
} from "../utils/enums";
import { columnSort, getModule, showPercentage } from "../utils/relationship";
import { checkElementViewPermission } from "../utils/permissions";
import { ACTION_BUTTON_CSS } from "../utils/cssConfigs";
import { renderMonetaryRange } from "../components/general/MonetaryRangeInput";
import { tableColumnHeader } from "../utils/componentUtils";
import { convertDateToFormat } from "../utils/moment";
import { useConfigs } from "../hooks/useConfigs";
import { useUser } from "../hooks/useUser";
import { RelationshipDashboardFilters } from "../components/relationship/RelationshipDashboardFilters";
import moment from "moment";
import { formatDecimalAndAddCommas } from "../utils/allocation";
import { SpinnerOverlay } from "../components/general/SpinnerOverlay";
import { LoadingOutlined } from "@ant-design/icons";
import RelationshipInstitutionModal from "../components/modals/RelationShipInstitutionModal";

const DEFAULT_REL_CRM_ANALYTICS_FILTERS: RelationShipDashboardFilterType = {
  geographies: [],
  industries: [],
  portfolios: [],
  institutionTypes: [],
  structuresTypes: [],
  fxRateTargetCurrency: AnalyticsCurrencyType.USD,
  fxRateDate: moment().format("YYYY-MM-DD").toString(),
};

export const Relationships: FC = function () {
  usePageTitle("Relationships");

  const { user, companyModulePreference } = useUser();
  const { configValues } = useConfigs();
  const { height: windowHeight } = useWindowDimensions();

  const [industries, setIndustries] = useState<IndustryType[]>([]);
  const [geographies, setGeographies] = useState<GeographyType[]>([]);
  const [portfolios, setPortfolios] = useState<PortfolioType[]>([]);
  const [newLenderModalVisible, setNewLenderModalVisible] = useState(false);
  const [userCrm, setUserCrm] = useState<CRMCompanyMappingType[]>([]);
  const [exposureData, setExposureData] =
    useState<RelationshipExposureResponse | null>(null);
  const [loading, setLoading] = useState<LoadingType>({});
  const [integrations, setIntegrations] = useState<DealCloudCompanyDTOType[]>(
    []
  );
  const [filters, setFilters] = useState<RelationShipDashboardFilterType>(
    DEFAULT_REL_CRM_ANALYTICS_FILTERS
  );

  const fetchDealCloudCompanies: voidType = () => {
    setLoading((loading) => ({ ...loading, integrations: true }));

    getDealCloudCompanies({})
      .then(({ data }: ResponseType<DealCloudGetCompanyResponseDTOType>) => {
        setIntegrations(
          () =>
            data?.rows.sort((a, b) =>
              a?.CompanyName?.name?.localeCompare(b?.CompanyName?.name)
            ) ?? []
        );
        setLoading((loading) => ({ ...loading, integrations: false }));
      })
      .catch((e: string) => {
        console.error(e);
        // message.error(e);
        setLoading((loading) => ({ ...loading, integrations: false }));
      });
  };

  const initiateUpdateCRMCompanyMapping = (
    thirdPartyCompanyId: string,
    thirdPartyCompanyName: string,
    crmCompanyMappingId: string,
    record: CRMCompanyMappingType
  ) => {
    message.loading({
      key: `update-${record?.id}`,
      content: "Updating Deal Institution",
      duration: 0,
    });
    updateCRMCompanyMapping({
      body: JSON.stringify({
        ...record,
        thirdPartyCompanyId,
        crmCompanyMappingId,
        thirdPartyCompanyName,
        companyName: record?.companyDTO?.name,
        thirdPartyIntegrationType: ThirdPartyIntegrationType.DEAL_CLOUD,
      }),
    })
      .then(() => {
        const index = findIndex(propEq("id", record?.id), userCrm);
        setUserCrm(
          updateItemInArray(index, userCrm, {
            ...record,
            thirdPartyCompanyId,
            thirdPartyCompanyName,
          })
        );
        message.success({
          key: `update-${record?.id}`,
          content: "Updated Deal Institution",
          duration: 2,
        });
      })
      .catch((error: string) => {
        console.error(error);
        message.error({
          key: `update-${record?.id}`,
          content: "Unable to update Deal Institution",
          duration: 3,
        });
      });
  };

  const initiateAddCRMCompanyMapping = (
    thirdPartyCompanyId: string,
    thirdPartyCompanyName: string,
    crmCompanyMappingId: string,
    record: CRMCompanyMappingType
  ): void => {
    message.loading({
      key: `add-${record?.id}`,
      content: "Connecting Deal Institution",
      duration: 0,
    });
    addCRMCompanyMapping({
      body: JSON.stringify({
        ...record,
        thirdPartyCompanyId,
        crmCompanyMappingId,
        thirdPartyCompanyName,
        companyName: record?.companyDTO?.name,
        thirdPartyIntegrationType: ThirdPartyIntegrationType.DEAL_CLOUD,
      }),
    })
      .then(() => {
        const index = findIndex(propEq("id", record?.id), userCrm);
        setUserCrm(
          updateItemInArray(index, userCrm, {
            ...record,
            thirdPartyCompanyId,
            thirdPartyCompanyName,
          })
        );
        message.success({
          key: `add-${record?.id}`,
          content: "Connected Deal Institution",
          duration: 2,
        });
      })
      .catch((error: string) => {
        console.error(error);
        message.error({
          key: `add-${record?.id}`,
          content: "Unable to connect Deal Institution",
          duration: 3,
        });
      });
  };

  const columns: ColumnsType<CRMCompanyMappingType> = [
    {
      title: tableColumnHeader("Institution"),
      dataIndex: ["companyDTO"],
      ellipsis: true,
      key: "companyName",
      render: ({ name }: CompanyType, record) => (
        <div className={"inline-flex gap-3 items-center"}>
          <Link
            className={`font-medium text-sm cursor-pointer text-primary flex flex-col whitespace-normal items-start`}
            style={{ color: "inherit" }}
            to={`/relationship/${record.id}`}
          >
            {name}
          </Link>
          {record.thirdPartyCompanyName &&
            valOrDefault(false, companyModulePreference?.dealCloud) && (
              <RelationshipDealCloudIntegration
                integrations={integrations}
                disabled={loading.fetching}
                onSubmit={(
                  thirdPartyCompanyId,
                  thirdPartyCompanyName
                ): void => {
                  initiateUpdateCRMCompanyMapping(
                    thirdPartyCompanyId,
                    thirdPartyCompanyName,
                    record.id,
                    record
                  );
                }}
              >
                <Tag
                  icon={<i className="fa-solid fa-plug text-inherit mr-1"></i>}
                  color={"gold"}
                >
                  {record.thirdPartyCompanyName}
                </Tag>
              </RelationshipDealCloudIntegration>
            )}
        </div>
      ),
      sorter: (a, b) =>
        columnSort(a.companyDTO.name, b.companyDTO.name, "TEXT"),
    },
    {
      title: tableColumnHeader("Investment Type"),
      dataIndex: ["investmentTypes"],
      width: "12%",
      ellipsis: true,
      render: (types: RelationshipInvestmentType[]) =>
        join(
          ", ",
          defaultTo([], types).map(
            (value) => RelationshipInvestmentLabelType[value]
          )
        ),
    },
    {
      title: tableColumnHeader("Institution Type"),
      dataIndex: ["institutionTypes"],
      width: "12%",
      ellipsis: true,
      render: (types: RelationshipInstitutionType[]) =>
        join(
          ", ",
          defaultTo([], types).map(
            (value) => RelationshipInstitutionLabelType[value]
          )
        ),
    },
    {
      title: tableColumnHeader("Structures"),
      dataIndex: ["structuresTypes"],
      width: "12%",
      ellipsis: true,
      render: (types: RelationshipStructureType[]) =>
        join(
          ", ",
          defaultTo([], types).map(
            (value) => RelationshipStructureLabelType[value]
          )
        ),
    },
    {
      title: tableColumnHeader("Since"),
      dataIndex: ["since"],
      ellipsis: true,
      key: "since",
      width: "8%",
      sorter: (a, b) => columnSort(a.since, b.since, "NUMERIC"),
      render: (since) => convertDateToFormat(since, "D.MMM.YY"),
    },
    {
      title: tableColumnHeader("Target EBITDA"),
      width: "10%",
      ellipsis: true,
      dataIndex: ["ebitdaRangeDTO"],
      render: renderMonetaryRange,
    },
    {
      title: tableColumnHeader("Target Hold Size"),
      width: "10%",
      ellipsis: true,
      dataIndex: ["targetHoldSizeDTO"],
      render: renderMonetaryRange,
    },
    {
      title: (
        <div className={"flex items-center"}>
          {tableColumnHeader("% Exposure")}
          {loading.exposures && (
            <SpinnerOverlay
              spinning={loading.exposures}
              size={"small"}
              opaque={false}
              indicator={<LoadingOutlined spin={true} />}
            >
              <div />
            </SpinnerOverlay>
          )}
        </div>
      ),
      width: "12%",
      sorter: (a, b) =>
        columnSort(
          exposureData?.CRM?.exposuresByCompany?.[a?.companyDTO.id] || 0,
          exposureData?.CRM?.exposuresByCompany?.[b?.companyDTO.id] || 0,
          "NUMERIC"
        ),
      ellipsis: true,
      render: (_, record) => {
        return exposureData ? (
          <div className={"flex flex-col"}>
            <div>
              {showPercentage(
                exposureData?.CRM?.exposuresByCompany?.[record?.companyDTO.id],
                exposureData?.CRM?.overallExposure
              )}
            </div>
            <div className={"text-xs text-muted"}>
              {
                AnalyticsCurrencyType[
                  filters.fxRateTargetCurrency ?? AnalyticsCurrencyType.USD
                ]
              }
              &nbsp;
              {formatDecimalAndAddCommas(
                exposureData?.CRM?.exposuresByCompany?.[
                  record?.companyDTO.id
                ] || 0
              )}
              &nbsp;
              {UnitLabelType.MILLION}
            </div>
          </div>
        ) : (
          ""
        );
      },
    },
    {
      title: tableColumnHeader("Action"),
      dataIndex: ["companyDTO"],
      key: "action",
      width: "8%",
      align: "right",
      render: (_, record) => (
        <span
          className={"relative flex flex-row-reverse items-center gap-x-3"}
          onClick={(event): void => event.stopPropagation()}
        >
          <Button
            type={"text"}
            icon={<i className="fas fa-ellipsis-h" />}
            className={
              "absolute right-0 border-0 flex items-center px-2 text-gray-900 opacity-50 group-hover:opacity-0"
            }
          />

          {!record.thirdPartyCompanyId && (
            <RelationshipDealCloudIntegration
              integrations={integrations}
              disabled={loading.fetching}
              onSubmit={(thirdPartyCompanyId, thirdPartyCompanyName): void => {
                initiateAddCRMCompanyMapping(
                  thirdPartyCompanyId,
                  thirdPartyCompanyName,
                  record.id,
                  record
                );
              }}
            />
          )}
          {record?.thirdPartyCompanyId && (
            <Button
              type={"text"}
              icon={<i className="fas fa-trash-alt" />}
              className={`${ACTION_BUTTON_CSS} hover:text-danger opacity-0 group-hover:opacity-100`}
              onClick={(): void => {
                setCRMMappingInactive(record?.id);
              }}
            />
          )}
        </span>
      ),
    },
  ];

  const fetchUserCrmMapping: voidType = () => {
    setLoading((loading) => ({ ...loading, fetching: true }));

    getCrmMappings({
      params: {
        elementType: getModule(user),
      },
    })
      .then(({ data = [] }: ResponseType<CRMCompanyMappingType[]>) => {
        setLoading((loading) => ({ ...loading, fetching: false }));

        setUserCrm(
          data.sort((a, b) => (a.companyDTO.name > b.companyDTO.name ? 1 : -1))
        );
      })
      .catch((error: string) => {
        console.error(error);
        setLoading((loading) => ({ ...loading, fetching: false }));
      });
  };

  const fetchExposures = (
    fxRateTargetCurrency?: AnalyticsCurrencyType,
    fxRateDate?: string
  ): void => {
    setLoading((prevState) => ({ ...prevState, exposures: true }));
    getCrmMappingsExposures({
      params: {
        fxRateTargetCurrency,
        fxRateDate,
      },
    })
      .then(({ data }: ResponseType<RelationshipExposureResponse>) => {
        setExposureData(data);
        setLoading((prevState) => ({ ...prevState, exposures: false }));
      })
      .catch((error: string) => {
        console.error(error);
        setLoading((loading) => ({ ...loading, exposures: false }));
      });
  };

  const setCRMMappingInactive: fetchWithIdType = (id) => {
    setLoading((loading) => ({ ...loading, updating: true }));
    deleteThirdPartyIntegrations({ segments: { id } })
      .then(() => {
        setLoading((loading) => ({ ...loading, updating: false }));
        setUserCrm((prevState) => {
          const index = findIndex(propEq("id", id))(userCrm);
          return set(
            lensPath([index]),
            omit(
              ["thirdPartyCompanyId", "thirdPartyCompanyName"],
              prevState[index]
            ),
            prevState
          ) as CRMCompanyMappingType[];
        });
      })
      .catch((error: string) => {
        console.error(error);
        setLoading((loading) => ({ ...loading, updating: false }));
      });
  };

  const getFilteredList = (
    list: CRMCompanyMappingType[],
    filters: RelationShipDashboardFilterType
  ): CRMCompanyMappingType[] => {
    const isEmptyFilters = all(isEmptyOrNil, Object.values(filters));
    if (isEmptyFilters) return list;
    return list.filter((value) => {
      const hasStructureTypes =
        isEmptyOrNil(filters.structuresTypes) ||
        intersection(value.structuresTypes ?? [], filters.structuresTypes ?? [])
          ?.length > 0;
      const hasInstitutionTypes =
        isEmptyOrNil(filters.institutionTypes) ||
        intersection(
          value.institutionTypes ?? [],
          filters.institutionTypes ?? []
        )?.length > 0;
      const hasGeographies =
        isEmptyOrNil(filters.geographies) ||
        intersection(value.geographies ?? [], filters.geographies ?? [])
          ?.length > 0;
      const hasIndustries =
        isEmptyOrNil(filters.industries) ||
        intersection(value.industries ?? [], filters.industries ?? [])?.length >
          0;
      const hasPortfolios =
        isEmptyOrNil(filters.portfolios) ||
        intersection(value.portfolioIds ?? [], filters.portfolios ?? [])
          ?.length > 0;
      const hasMatchingText =
        isEmptyOrNil(filters.searchKey) ||
        value.companyDTO.name
          .toLowerCase()
          .includes(filters.searchKey.toLowerCase());

      return all(equals(true), [
        hasStructureTypes,
        hasInstitutionTypes,
        hasGeographies,
        hasIndustries,
        hasPortfolios,
        hasMatchingText,
      ]);
    });
  };

  useEffect(() => {
    getIndustries({})
      .then(({ data = [] }: ResponseType<IndustryType[]>) => {
        setIndustries(data.sort((a, b) => columnSort(a.name, b.name, "TEXT")));
      })
      .catch(console.error);

    getGeographies({})
      .then(({ data = [] }: ResponseType<GeographyType[]>) => {
        setGeographies(data.sort((a, b) => columnSort(a.name, b.name, "TEXT")));
      })
      .catch(console.error);

    getPortfoliosForCrmMappings({
      params: location.search,
    })
      .then(({ data = [] }: ResponseType<PortfolioType[]>) => {
        setPortfolios(
          data.sort((a, b) =>
            columnSort(a.portfolioCompanyName, b.portfolioCompanyName, "TEXT")
          )
        );
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    fetchExposures(filters.fxRateTargetCurrency, filters.fxRateDate);
  }, [filters.fxRateDate, filters.fxRateTargetCurrency]);

  useEffect(() => {
    if (
      companyModulePreference?.crm ||
      companyModulePreference?.sponsorCoverage
    ) {
      fetchUserCrmMapping();
    }
  }, [companyModulePreference]);

  useEffect(() => {
    if (
      companyModulePreference?.dealCloud &&
      configValues[ConfigType.DEAL_CLOUD_CONFIG]?.active
    ) {
      fetchDealCloudCompanies();
    }
  }, [configValues[ConfigType.DEAL_CLOUD_CONFIG]]);

  const mapInstituion = (values: any) => {
    const parse_institutions = values?.companyDTO?.map((item: any) =>
      JSON.parse(item)
    );
    const institutions = parse_institutions?.map((item: any) => {
      return {
        relationCompanyId: item.id,
        companyDTO: {
          id: item.id,
          name: item.name,
          companyType: item.companyName,
          abbreviation: item.abbreviation,
        },
      };
    });

    mapInstitutionstoRelationship({
      body: JSON.stringify(institutions),
    })
      .then(({ data }: { data: CRMCompanyMappingType[] }) => {
        fetchUserCrmMapping();
        message.success("Institutions mapped successfully");
      })
      .catch(() => {
        message.error("Something went wrong!");
      });
    setNewLenderModalVisible(false);
  };
  return (
    <div
      className={
        "relative max-h-full w-full h-screen bg-gray-100 flex flex-col"
      }
    >
      {or(loading.fetching, loading.integrations) ? (
        <CustomSpin />
      ) : (
        <>
          <div
            className={
              "mb-5 flex flex-row items-center justify-between p-6 pb-0"
            }
          >
            <div className={"text-2xl font-medium flex items-center"}>
              Relationship Dashboard
            </div>
            {/* <RelationshipInstitutionModal
              mapInstituion={mapInstituion}
              newLenderModalVisible={newLenderModalVisible}
              setNewLenderModalVisible={setNewLenderModalVisible}
              existingInstitution={getFilteredList(userCrm, filters)}
            /> */}
          </div>
          <div
            className={`w-full h-full flex flex-col overflow-y-auto p-6 pt-0`}
          >
            <RelationshipDashboardFilters
              industries={industries}
              geographies={geographies}
              portfolios={portfolios}
              filters={filters}
              onChange={setFilters}
              onReset={() => {
                setFilters(DEFAULT_REL_CRM_ANALYTICS_FILTERS);
              }}
            />
            <Table
              locale={{ emptyText: "No Institutions Added Yet!" }}
              dataSource={getFilteredList(userCrm, filters)}
              columns={columns.filter(({ key }) =>
                checkElementViewPermission(
                  ElementType.CRM,
                  user?.elementPermissions || [],
                  [PermissionType.ADMIN_CRM],
                  true
                ) && equals("action", key ?? "")
                  ? valOrDefault(
                      false,
                      companyModulePreference?.dealCloud &&
                        configValues[ConfigType.DEAL_CLOUD_CONFIG]?.active
                    )
                  : true
              )}
              className={"transform border"}
              rowClassName={`group hover:bg-blue-50`}
              scroll={{ y: windowHeight - 250 }}
              sticky={true}
              pagination={false}
              rowKey={({ id }) => id}
            />
          </div>
        </>
      )}
    </div>
  );
};

type LoadingType = {
  integrations?: boolean;
  fetching?: boolean;
  exposures?: boolean;
};
