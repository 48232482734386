import { checkElementViewPermission } from "./permissions";
import { ElementType, PermissionType, PETransactionTagType } from "./enums";
import { PSFiltersType } from "../components/precedent/PrecedentSearch";
import { ElementPermissionsType } from "./types";
import { concat } from "ramda";

export const mapResponses = (value: string | number): boolean | null => {
  switch (value) {
    case "ANY":
      return null;
    case "RESPONDED":
      return true;
    case "NOT RESPONDED":
      return false;
    default:
      return null;
  }
};

export const mapResponsesReverse = (value: boolean | null): string => {
  switch (value) {
    case true:
      return "RESPONDED";
    case false:
      return "NOT RESPONDED";
    default:
      return "ANY";
  }
};

export const getDefaultPrecedentFilters: getDefaultPrecedentFilters = (
  permissions = []
): PSFiltersType => {
  const isAboveTheWall = checkElementViewPermission(
    ElementType.PRECEDENT_SEARCH,
    permissions ?? [],
    [PermissionType.ABOVE_THE_WALL_PRECEDENT_SEARCH],
    true
  );
  return {
    size: 25,
    companyIds: [],
    fromTimestamp: null,
    toTimestamp: null,
    pageToken: null,
    industryIds: [],
    // bidMetricSearchText: "",
    lenderPublished: true,
    dealStatuses: concat(isAboveTheWall ? [PETransactionTagType.LIVE] : [], [
      PETransactionTagType.COMPLETED,
      PETransactionTagType.DROPPED,
      PETransactionTagType.LOST,
      PETransactionTagType.SIGNED_NOT_CLOSED,
      "NOT SPECIFIED",
    ]),
    listType: "ALL",
    versions: "LAST",
  };
};

type getDefaultPrecedentFilters = (
  permissions?: ElementPermissionsType[] | null
) => PSFiltersType;
