import React, { FC, useContext } from "react";
import { message } from "antd";
import { useHistory } from "react-router";
import { LOGIN_PAGE_URL, loginRedirect } from "../utils/redirect";
import { ResponseType, voidType } from "../utils/uiTypes";
import { Verify2FaPhoneCodeLoginType } from "../utils/types";
import TermgridLogo from "../images/logo/TermgridLogoLight.svg";
import { CodeVerification } from "../components/2fa/CodeVerification";
import { verify2FAPhoneCode } from "../services/services";
import { UserContext } from "../context/UserContext";

export const LoginVerification: FC = function () {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);

  const resendToSmsOrEmailOption: resendToSmsOrEmailOptionType = (
    setResendCode,
    setResendSMSOrEmailCodeFromCodeVerification
  ) => {
    setResendCode(null);
    setResendSMSOrEmailCodeFromCodeVerification(true);
  };

  const backToSignIn: voidType = () => {
    history.push(LOGIN_PAGE_URL);
  };

  const verify2FaLoginPhoneCode: verify2FaLoginPhoneCodeType = (
    code,
    verifyType
  ) => {
    verify2FAPhoneCode({
      segments: {
        verifyType: verifyType.toLowerCase(),
      },
      body: JSON.stringify({
        code: code,
      }),
    })
      .then(({ data }: ResponseType<Verify2FaPhoneCodeLoginType>) => {
        if (data.otpStatus === "VALID") {
          if (user) {
            setUser({ ...user, sms2FaFlowCompleted: true });
            history.push(loginRedirect({ ...user, sms2FaFlowCompleted: true }));
          }
        } else {
          message.error("Your code is incorrect");
        }
      })
      .catch(() => {
        message.error("Please match the requested format");
      });
  };

  return (
    <div
      style={{
        backgroundImage: "url('../../images/hero.jpg')",
      }}
      className={
        "h-screen w-screen bg-cover bg-center overflow-y-auto hide-scrollbar"
      }
    >
      <img
        className={"mx-auto pt-15 w-48"}
        src={TermgridLogo}
        alt={"Termgrid Logo"}
      />
      <div className={"px-5 mx-auto"}>
        <div className={"bg-white border mx-auto my-16 sm:my-24 p-10 max-w-lg"}>
          <div>
            <a
              onClick={backToSignIn}
              className="text-primary hover:underline font-medium"
            >
              Go back
            </a>

            <CodeVerification
              onSubmit={verify2FaLoginPhoneCode}
              showEmailOrSmsOption={resendToSmsOrEmailOption}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

type resendToSmsOrEmailOptionType = (
  setResendCode: React.Dispatch<React.SetStateAction<string | null>>,
  setResendSMSOrEmailCodeFromCodeVerification: React.Dispatch<
    React.SetStateAction<boolean>
  >
) => void;
type verify2FaLoginPhoneCodeType = (code: string, type: string) => void;
