import React, { FC, useEffect } from "react";
import { UserProvider } from "./context/UserContext";
import { Routes } from "./components/routes/Routes";
import { message } from "antd";
import { getModalContainerForFullScreen } from "./utils/container";

export const App: FC = function () {
  useEffect(() => {
    message.config({ getContainer: getModalContainerForFullScreen });
  }, []);

  return (
    <UserProvider>
      <Routes />
    </UserProvider>
  );
};
