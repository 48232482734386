import React, { FC, ReactElement, ReactNode } from "react";
import { PermissionType } from "../../utils/enums";

export const PortfolioAdminPermissionBlock: FC<
  AdminPermissionBlockProperties
> = ({ permissions, children }) => {
  return (
    <>
      {permissions.includes(PermissionType.ADMIN_PORTFOLIO) ? children : <></>}
    </>
  );
};

type AdminPermissionBlockProperties = {
  permissions: Array<PermissionType>;
  children: ReactNode | ReactNode[];
};
