import React, { FC, useContext, useEffect, useState } from "react";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { Button, Table, message, Input, Tooltip, Segmented } from "antd";
import {
  approveOrRejectTeam,
  getCreateJoinLink,
} from "../../services/services";
import { InviteType } from "../../utils/types";
import { ResponseType, voidType } from "../../utils/uiTypes";
import { ColumnsType } from "antd/es/table";
import { convertDateToFormat } from "../../utils/moment";
import useWindowDimensions from "../../customHooks/useWindowDimensions";
import moment from "moment";
import { pathOr } from "ramda";
import { TextOverFlowHandle } from "../general/TextOverFlowHandle";
import { ViewEmail } from "../general/ViewEmail";
import { UserContext } from "../../context/UserContext";
import { RoleType } from "../../utils/enums";
import {
  DANGER_BUTTON_STYLE,
  PRIMARY_BUTTON_STYLE,
} from "../../utils/cssConfigs";

const updateInvite: UpdateInviteType = (
  updatedInvite,
  { companyId, email, title },
  allInvites
) => {
  if (updatedInvite) {
    return allInvites.map((item) => {
      if (item.companyId === companyId && item.email === email) {
        return { ...updatedInvite, title };
      } else {
        return item;
      }
    });
  } else {
    return allInvites.filter(
      (item) => !(item.companyId === companyId && item.email === email)
    );
  }
};

const filterInvites: FilterInvitesType = (data, type) => {
  switch (type) {
    case "APPROVED":
      return data.filter((item) =>
        pathOr(false, ["systemAdminApproved"], item)
      );
    case "PENDING":
      return data.filter(
        (item) => !pathOr(false, ["systemAdminApproved"], item)
      );
    default:
      return data;
  }
};

export const PendingTeam: FC = () => {
  usePageTitle("Pending Team");
  const { height: windowHeight } = useWindowDimensions();
  const { user } = useContext(UserContext);

  const [company, setCompany] = useState<InviteType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [value, setValue] = useState<string>("");
  const [filter, setFilter] = useState<FilterType>("ALL");

  const initiateApproveOrRejectTeam = (
    selectedInvite: InviteType,
    isApproved: boolean
  ) => {
    setLoading(true);
    approveOrRejectTeam({
      segments: {
        companyId: selectedInvite.companyId,
        isApproved,
        email: selectedInvite.email,
      },
    })
      .then(({ data }: ResponseType<InviteType>) => {
        setCompany(() => updateInvite(data, selectedInvite, company));
        setLoading(false);
        message.success(`${data ? "Approved" : "Rejected"} User successfully`);
      })
      .catch((err: string): void => {
        setLoading(false);
        message.error(err ?? "Something went wrong.", 3);
      });
  };

  const columns: ColumnsType<InviteType> = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      className: "bg-transparent",
      width: "12%",
      ellipsis: true,
      render: (val: string, data: InviteType, index) => {
        return `${data.firstName} ${data.lastName}`;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "bg-transparent",
      render: ViewEmail,
    },
    {
      title: "Transaction",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      width: "12%",
      className:
        "empty:text-muted empty:after:content-['-'] bg-transparent max-w-xs",
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
      ellipsis: true,
      width: "12%",
      className:
        "empty:text-muted empty:after:content-['-'] bg-transparent max-w-xs",
      render: (company, record, index) => (
        <Tooltip key={index} placement="topLeft" title={company}>
          {company}
        </Tooltip>
      ),
    },
    {
      title: "Create Date",
      dataIndex: "invitedDate",
      key: "inviteDate",
      className: "bg-transparent",
      ellipsis: true,
      width: "14%",
      render: (val: string, record, index) => {
        return (
          <span key={index}>
            {convertDateToFormat(val, "MMM Do YYYY, hh:mm A", false)}
          </span>
        );
      },
    },
    {
      title: "Link",
      dataIndex: "inviteLink",
      key: "inviteLink",
      className: "bg-transparent",
      width: "15%",
      render: (link: string, record, index) => {
        return (
          <div className="w-40" key={index}>
            {pathOr(false, ["systemAdminApproved"], record) ? (
              <div className="truncate ...">
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(link),
                      message.success("Copied link to clipboard");
                  }}
                  className="opacity-0 group-hover:opacity-100 border-dashed text-primary border-primary bg-white absolute w-40"
                  type="dashed"
                  size={"small"}
                >
                  Copy
                </Button>
                {link}
              </div>
            ) : user && user.authorities.includes(RoleType.ROLE_ADMIN) ? (
              <div className={"w-full space-x-2 flex w-full justify-center"}>
                <Button
                  className={DANGER_BUTTON_STYLE}
                  onClick={(): void =>
                    initiateApproveOrRejectTeam(record, false)
                  }
                >
                  Reject
                </Button>
                <Button
                  className={PRIMARY_BUTTON_STYLE}
                  onClick={(): void =>
                    initiateApproveOrRejectTeam(record, true)
                  }
                >
                  Approve
                </Button>
              </div>
            ) : (
              <div className={"text-danger"}>Admin Approval Required</div>
            )}
          </div>
        );
      },
    },
  ];
  const getCompanies: voidType = () => {
    getCreateJoinLink({})
      .then(({ data }: ResponseType<InviteType[]>) => {
        setLoading(false);
        setCompany(
          data.sort((a, b) =>
            moment(b?.invitedDate).diff(moment(a.invitedDate))
          )
        );
      })
      .catch((error: string) => {
        message.error(error ?? "Try again later!");
      })
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <div>
      <div className="p-6">
        <div className="flex justify-between">
          <h1
            className={`${
              loading ? "text-muted text-3xl mb-2" : "text-3xl mb-2"
            }`}
          >
            Pending Team
          </h1>
          <div>
            <Segmented
              className={"mx-3"}
              disabled={loading}
              value={filter}
              onChange={(val) => {
                setFilter(val as FilterType);
              }}
              options={[
                {
                  label: "All",
                  value: "ALL",
                },
                {
                  label: "Pending",
                  value: "PENDING",
                },
                {
                  label: "Approved",
                  value: "APPROVED",
                },
              ]}
            />
            <Input.Search
              className="w-56"
              disabled={loading}
              placeholder="Search by email"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </div>

        <Table
          dataSource={filterInvites(company, filter)?.filter(({ email }) =>
            email.toLowerCase().includes(value.toLowerCase() ?? "")
          )}
          columns={columns}
          loading={loading}
          pagination={false}
          rowKey="id"
          rowClassName={"group hover:bg-blue-50"}
          scroll={{ x: 1000, y: windowHeight - 250 }}
        />
      </div>
    </div>
  );
};

type UpdateInviteType = (
  updatedInvite: InviteType,
  selectedInvite: InviteType,
  allInvites: InviteType[]
) => InviteType[];

type FilterType = "ALL" | "APPROVED" | "PENDING";

type FilterInvitesType = (data: InviteType[], type: FilterType) => InviteType[];
