import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { call, equals, isEmpty, isNil, or, path, pathOr, prop } from "ramda";
import {
  ALPHABETS,
  MODAL_PRIMARY_ACTION_TEXT,
  MODAL_SECONDARY_ACTION_TEXT,
  MODAL_SECONDARY_ICON,
  MODAL_TYPES,
  ModalType,
} from "../../../utils/newTermsheet";
import {
  Button,
  Form,
  InputNumber,
  message,
  Modal,
  Select,
  TreeSelect,
} from "antd";
import {
  isWordDocument,
  populateArrayWithPropertyPath,
} from "../../../utils/utils";
import {
  FileType,
  LenderExcelDTOType,
  LenderType,
  TermsheetUploadType,
  VersionDataType,
  VersionType,
  WebFormRedLineType,
} from "../../../utils/types";
import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import {
  addLendersForOfflineTermsheet,
  addOrRemindLendersForWebformVersion,
  generateReadlineExcel,
  pastUploadExcelFile,
  removeLenderFromWebformVersion,
  uploadExcelFile,
} from "../../../services/services";
import {
  FileResponseType,
  ResponseType,
  voidType,
} from "../../../utils/uiTypes";
import { ConfirmDownload } from "../../../utils/confirmationModals";
import Dragger from "antd/lib/upload/Dragger";
import { DataNode } from "antd/lib/tree";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import { TermsheetContext } from "../../../context/TermsheetContext";
import FormItem from "antd/es/form/FormItem";
import { formItemRequiredRule } from "../../../utils/formUtils";
import { FuzzySearchSelect } from "../../general/FuzzySearchSelect";

const LENDER_MODALS = [
  "PUBLISH_DEAL_TEAM",
  "ADD_LENDER",
  "REMOVE_LENDER",
  "UPLOAD",
];

export const TermsheetModals: FC<TermsheetModalsType> = function ({
  type,
  currentVersion,
  versions = {},
  onCloseOrCancel,
  onDealTeamPublish,
  onInstitutionPublish,
  onUpload,
  published,
}: TermsheetModalsType) {
  const {
    transactionId,
    webFormId,
    institutions,
    isAdmin,
    isInstitution,
    onReloadVersions,
    isOffline,
    element,
    setColumns,
  } = useContext(TermsheetContext);
  const [form] = useForm();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [secondaryLoading, setSecondaryLoading] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<FileType | null>(null);
  const [filteredLenders, setFilteredLenders] = useState<Array<LenderType>>([]);
  const [originalInstitutions, setOriginalInstitutions] = useState<
    Array<DataNode>
  >([]);
  const [compareInstitutions, setCompareInstitutions] = useState<
    Array<DataNode>
  >([]);

  useEffect(() => {
    console.log(element);
  }, [element]);

  const onModalClose: voidType = () => {
    setConfirmLoading(false);
    setVisible(false);
  };

  /** Helper methods to compare columns between webform versions **/
  const getCompareOptions: getCompareOptionsType = (
    data = {},
    lenders,
    webFormId,
    transactionId,
    disableRootNote: boolean,
    value = ""
  ) => {
    return Object.entries(data).map(([o, v]) => {
      return {
        key: o,
        title: isInstitution
          ? moment(v.createDate).format("ll")
          : `${v.name} - ${moment(v.createDate).format("ll")}`,
        value: o,
        disabled: disableRootNote,
        children: getCompareOptionsChildren(
          o,
          v,
          lenders,
          webFormId,
          transactionId,
          value
        ),
      };
    });
  };

  const filterInstitutionsForUpload: filterInstitutionsForUploadType = (
    institutions,
    mapping
  ) => {
    console.log(mapping, form.getFieldsValue());
    const ids = populateArrayWithPropertyPath(["lenderId"], mapping ?? []);
    return institutions.filter(({ id }) => !ids.includes(id)) ?? [];
  };

  const getCompareOptionsChildren: getCompareOptionsChildrenType = (
    version,
    { lenderIds = [], respondStatus = {}, createDate },
    lenders,
    webFormId,
    transactionId,
    value
  ) => {
    const dealTeamValue = JSON.stringify({
      webFormId,
      peTransactionId: transactionId,
      entityId: transactionId,
      version,
      createDate: createDate,
    });
    const dealTeamNode = [
      {
        title: "Deal Team Ask",
        key: dealTeamValue,
        value: dealTeamValue,
        disabled: value === dealTeamValue,
      },
    ];
    const lenderNodes = lenders
      ?.filter(({ id }) => (isInstitution ? true : respondStatus[id]))
      ?.map(({ id, name }) => {
        const lenderValue = JSON.stringify({
          webFormId,
          peTransactionId: transactionId,
          entityId: id,
          version,
          createDate: createDate,
        });
        return {
          key: lenderValue,
          value: lenderValue,
          title: name,
          disabled: value === lenderValue,
        };
      });
    return [...dealTeamNode, ...lenderNodes] as Array<DataNode>;
  };

  const onCompareModalValueChange: onCompareModalValueChangeType = (
    changedValues,
    allValues
  ) => {
    const originalInstitution = path(
      ["originalInstitution"],
      changedValues
    ) as string;
    if (originalInstitution) {
      const compareInstitutions = path(
        ["compareInstitutions"],
        allValues
      ) as Array<string>;
      setCompareInstitutions(
        getCompareOptions(
          versions,
          institutions,
          webFormId,
          transactionId,
          false,
          originalInstitution
        )
      );
      form.setFieldsValue({
        ...allValues,
        compareInstitutions: compareInstitutions?.filter(
          (institution: string) => institution !== originalInstitution
        ),
      });
    }
  };

  const onCompareModalDownload: onCompareModalFinishType = (values) => {
    setSecondaryLoading(true);
    const originalInstitutions = path(
      ["originalInstitution"],
      values
    ) as string;
    const compareInstitutions = path(
      ["compareInstitutions"],
      values
    ) as Array<string>;
    generateReadlineExcel({
      body: JSON.stringify({
        originalColumn: JSON.parse(originalInstitutions),
        changedColumn: compareInstitutions?.map((o: string) => JSON.parse(o)),
        bidMetricColumn: {
          webFormId,
          peTransactionId: transactionId,
        },
      }),
    })
      .then(({ url, filename }: FileResponseType) => {
        message.success({ key: "download", content: "File Ready to Download" });
        setSecondaryLoading(false);
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        console.error(error);
        setSecondaryLoading(false);
        message.error("Invalid column selection");
      });
  };

  const onCompareModalFinish: onCompareModalFinishType = (values) => {
    setConfirmLoading(true);
    const originalInstitutions = path(
      ["originalInstitution"],
      values
    ) as string;
    const compareInstitutions = path(
      ["compareInstitutions"],
      values
    ) as Array<string>;
    compareColumns({
      originalColumn: JSON.parse(originalInstitutions),
      changedColumn: compareInstitutions?.map((o: string) => JSON.parse(o)),
      bidMetricColumn: {
        webFormId,
        peTransactionId: transactionId,
      },
    });
  };

  /** Logic for when lender form has been submitted based on modal type **/
  const onLenderFormSubmit: onLenderFormSubmitType = (type, lenders = []) => {
    switch (type) {
      case "PUBLISH_DEAL_TEAM": {
        onDealTeamPublish(form.getFieldValue("lenders"));
        return;
      }
      case "ADD_LENDER": {
        addLenders(
          transactionId,
          webFormId,
          currentVersion.toString(),
          form.getFieldValue("lenders") as Array<LenderType>,
          isOffline
        );
        return;
      }
      case "REMOVE_LENDER": {
        const selectedLenders: Array<string> = form.getFieldValue("lenders");
        const lenderList: Array<LenderType> = lenders.filter(({ id }) =>
          selectedLenders?.includes(id)
        );
        removeLendersFromVersion(
          transactionId,
          webFormId,
          currentVersion.toString(),
          lenderList
        );
        return;
      }
    }
  };

  /** Filter lenders list based on modal type **/
  const getLenderFilteredList: getLenderFilteredList = (
    type,
    lenders,
    currentVersion,
    versions
  ) => {
    switch (type) {
      case "PUBLISH_DEAL_TEAM":
        return lenders;
      case "ADD_LENDER":
        return lenders?.filter(
          ({ id, userDTOs = [] }) =>
            !versions[currentVersion]?.lenderIds?.includes(id)
        );
      case "UPLOAD":
      case "REMOVE_LENDER": {
        return lenders?.filter(
          ({ id }) =>
            versions[currentVersion]?.lenderIds?.includes(id) &&
            (!versions[currentVersion].respondStatus[id] ||
              (isOffline && !published))
        );
      }
      default:
        return [];
    }
  };

  /** Disable primary button logic based on modal type **/
  const isDisabled: isDisabledType = (type) => {
    switch (type) {
      case "UPLOAD":
        return isNil(uploadedFile);
      default:
        return false;
    }
  };

  /** Primary button action based on modal type **/
  const onPrimaryOkClick: onOkClickType = (type) => {
    switch (type) {
      case "PUBLISH_DEAL_TEAM":
      case "ADD_LENDER":
      case "REMOVE_LENDER":
      case "COMPARE":
      case "UPLOAD":
        form.submit();
        return;
      case "PUBLISH_INSTITUTION":
        onInstitutionPublish();
        return;
    }
  };

  const onSecondaryOkClick: onOkClickType = (type) => {
    switch (type) {
      case "COMPARE":
        form.validateFields().then(
          (v) => {
            onCompareModalDownload(v);
          },
          () => {}
        );
        return;
      default:
        return;
    }
  };

  /** Check if there are users in lender or not **/
  const checkIfNoUsers: CheckIfNoUsersType = ({ userDTOs = [] }, type) =>
    or((equals(type, "ADD_LENDER"), equals(type, "PUBLISH_DEAL_TEAM"))) &&
    !isOffline &&
    isEmpty(userDTOs);

  /** Various modals throughout the termsheet module **/
  const getModalContent: getModalContentType = (
    type,
    filteredLenders,
    isOffline
  ) => {
    switch (type) {
      case "PUBLISH_DEAL_TEAM":
      case "ADD_LENDER":
      case "REMOVE_LENDER": {
        return (
          <Form
            form={form}
            layout={"vertical"}
            onFinish={() => {
              setConfirmLoading(true);
              onLenderFormSubmit(type, filteredLenders);
            }}
            className={"flex flex-col gap-y-3"}
          >
            <Form.Item
              name={"lenders"}
              label={"Institutions"}
              required={true}
              rules={[
                {
                  required: true,
                  message: "* Required",
                },
              ]}
            >
              <FuzzySearchSelect<LenderType>
                items={filteredLenders}
                getOptionKey={pathOr("", ["id"])}
                getOptionValue={pathOr("", ["id"])}
                getAbbrevationPath={pathOr("", ["name"])}
                getOptionLabel={(item) =>
                  (
                    <div className={"w-full inline-flex justify-between"}>
                      <span>{item.name}</span>
                      {checkIfNoUsers(item, type) && (
                        <span className={"italic"}>No Users</span>
                      )}
                    </div>
                  ) ?? ""
                }
                isOptionDisabled={(item) => checkIfNoUsers(item, type)}
                searchPaths={["name"]}
                notFoundContent={"No Institutions Available"}
                getPopupContainer={(trigger): HTMLElement =>
                  trigger.parentElement
                }
                className={"placeholder-red-300"}
                placeholder={"Select Institution(s)"}
                mode={"multiple"}
                autoFocus={true}
                showArrow={true}
                maxTagCount={"responsive"}
              />
            </Form.Item>
          </Form>
        );
      }
      case "PUBLISH_INSTITUTION":
        return (
          <div>
            <div className={"flex flex-row gap-4"}>
              <ExclamationCircleOutlined
                className={"text-[22px] text-danger"}
              />
              <div>
                You are about to submit your responses to the deal team. Please
                note that once published you will be not be able to edit this
                version any further.
              </div>
            </div>
          </div>
        );
      case "COMPARE":
        return (
          <Form
            form={form}
            layout={"vertical"}
            onFinish={onCompareModalFinish}
            onValuesChange={onCompareModalValueChange}
            className={"flex flex-col gap-y-3"}
          >
            <Form.Item
              name={"originalInstitution"}
              label={"Select Original Column"}
              required={true}
              rules={[
                {
                  required: true,
                  message: "* Required",
                },
              ]}
            >
              <TreeSelect
                getPopupContainer={(trigger): HTMLElement =>
                  trigger.parentElement
                }
                showSearch
                placeholder="Choose Institution"
                allowClear
                treeDefaultExpandAll
                treeNodeFilterProp={"title"}
                treeData={originalInstitutions}
              />
            </Form.Item>
            <Form.Item
              shouldUpdate
              name={"compareInstitutions"}
              label={"Compare With"}
              required={true}
              rules={[
                {
                  required: true,
                  message: "* Required",
                },
              ]}
            >
              <TreeSelect
                getPopupContainer={(trigger): HTMLElement =>
                  trigger.parentElement
                }
                showSearch
                placeholder="Choose Institution(s)"
                allowClear
                treeDefaultExpandAll
                treeNodeFilterProp={"title"}
                treeData={compareInstitutions}
                treeCheckable
                multiple={true}
              />
            </Form.Item>
          </Form>
        );
      case "UPLOAD":
        return (
          <div>
            <div className={"flex flex-row gap-4"}>
              <div>
                {isInstitution ? (
                  <li>Please select an Excel or Word file to upload</li>
                ) : isOffline ? (
                  <li>Please select an Excel or Word file to upload</li>
                ) : (
                  <li>
                    Please select an Excel or Word file that has only one
                    worksheet or table with two columns corresponding to “Bid
                    Metrics” and “Deal Team Ask”.
                  </li>
                )}
                <ol>
                  <li>
                    Values are uploaded with most of the formatting intact
                  </li>
                  <li>Maximum rows of a table that can be uploaded are 350</li>
                </ol>
              </div>
            </div>
            <div className={"w-full h-full p-5"}>
              <Dragger
                className={"group hover:border-primary"}
                type={"select"}
                action={"/api/rest/media/uploadFile/1"}
                listType={"text"}
                showUploadList={true}
                accept={".xlsx,.xls,.docx,.docs,.doc"}
                maxCount={1}
                onChange={({ file }): void => {
                  if (file && file?.status === "done") {
                    setUploadedFile(file.response);
                  }
                }}
                onRemove={() => {
                  setUploadedFile(null);
                  form.resetFields();
                }}
              >
                <i className="fa fa-upload text-2xl text-secondary group-hover:text-primary"></i>
                <div className="text-xs text-secondary group-hover:text-primary">
                  Click or drag file to this area to upload
                </div>
              </Dragger>
            </div>
            {uploadedFile && (
              <div>
                <Form<TermsheetUploadType>
                  form={form}
                  layout={"vertical"}
                  onFinish={(o) => {
                    setConfirmLoading(true);
                    if (!o.endRow) {
                      o.endRow = 350; // Set the value to 350 if endRow is not provided and isWordDoc is true
                    }
                    uploadFile({
                      ...o,
                      transactionId,
                      versionId: currentVersion,
                      webformId: webFormId,
                      fileDTO: uploadedFile,
                    });
                  }}
                  className={"flex flex-col gap-y-3"}
                >
                  <div className={"grid grid-cols-2 gap-4"}>
                    <Form.Item
                      tooltip={
                        "Enter the row number from where you want to start uploading the values. Number should be less than 200."
                      }
                      name={"startRow"}
                      label={"Starting Row Number"}
                      initialValue={1}
                      required={true}
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                        {
                          validator: (_, startRow) => {
                            return startRow <= 0
                              ? Promise.reject(
                                  new Error(
                                    "Starting row number has to be positive"
                                  )
                                )
                              : Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        type={"number"}
                        className={"w-full"}
                        defaultValue={1}
                        precision={0}
                        max={5000}
                        placeholder="Enter start row"
                      />
                    </Form.Item>
                    {or(isAdmin, isOffline) && (
                      <Form.Item
                        tooltip={
                          "Write the row number where you want to stop uploading the values. It must be greater than the starting row. if no end row entered then system will upload until the maximum allowed row."
                        }
                        name={"endRow"}
                        label={"Ending Row Number"}
                        required={false}
                        rules={[
                          {
                            required: false,
                            message: "",
                          },
                          {
                            validator: (_, endRow) => {
                              if (!endRow && endRow !== 0) {
                                return Promise.resolve();
                              }
                              if (endRow <= 0) {
                                return Promise.reject(
                                  new Error(
                                    "Ending row number has to be positive"
                                  )
                                );
                              }
                              const startRow = form.getFieldValue("startRow");
                              return isNil(startRow) || startRow <= endRow
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Ending row cannot be lower than starting row"
                                    )
                                  );
                            },
                          },
                        ]}
                      >
                        <InputNumber
                          type={"number"}
                          className={"w-full"}
                          precision={0}
                          max={350}
                          placeholder={"Enter end row (Optional)"}
                        />
                      </Form.Item>
                    )}
                    {or(isAdmin, isOffline) && (
                      <Form.Item
                        tooltip={
                          isWordDoc
                            ? "Enter the column number (1 to 26) for the Bid Metrics."
                            : "Enter the letter of the column you wish to upload as Bid Metrics. Only up to column letter Z is allowed."
                        }
                        name={"bidMetricColNum"}
                        label={"Bid Metric Column"}
                        required={true}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Select
                          getPopupContainer={(trigger): HTMLElement =>
                            trigger.parentElement
                          }
                        >
                          {ALPHABETS.map((c, i) => {
                            return (
                              <Select.Option key={c} value={i + 1}>
                                {isWordDoc ? i + 1 : c}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    )}
                    <Form.Item
                      tooltip={
                        isInstitution
                          ? isWordDoc
                            ? "Enter the column number (1 to 26) you wish to copy from."
                            : "Enter the letter of the column you wish to copy."
                          : isWordDoc
                          ? "Enter the column number (1 to 26) for the corresponding Deal Team Ask."
                          : "Enter the letter of the column you wish to upload as corresponding Deal Team Ask. Only up to column letter Z is allowed."
                      }
                      name={"columnNumber"}
                      label={
                        isInstitution
                          ? isOffline
                            ? "Deal Team Ask Column"
                            : "Select Column"
                          : "Deal Team Ask Column"
                      }
                      required={true}
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Select
                        getPopupContainer={(trigger): HTMLElement =>
                          trigger.parentElement
                        }
                      >
                        {ALPHABETS.map((c, i) => {
                          return (
                            <Select.Option key={c} value={i + 1}>
                              {isWordDoc ? i + 1 : c}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    {isOffline && (
                      <Form.List
                        initialValue={
                          isOffline && isInstitution
                            ? [
                                {
                                  lenderId: element?.elementId ?? "",
                                  excelColNum: null,
                                },
                              ]
                            : []
                        }
                        name={["lenderExcelDTOS"]}
                        rules={[
                          {
                            validator: async (_, lenders) => {
                              const lenderIds = populateArrayWithPropertyPath(
                                ["lenderId"],
                                lenders
                              );
                              if (lenderIds.length > institutions.length) {
                                return Promise.reject(
                                  new Error(
                                    "No more Institutions Available to Add"
                                  )
                                );
                              } else if (
                                lenderIds.length !== new Set(lenderIds).size
                              ) {
                                return new Error(
                                  "Duplicate Lenders Not Allowed"
                                );
                              } else return true;
                            },
                          },
                        ]}
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ name, ...rest }, index) => (
                              <>
                                {element?.elementType !== "LENDER" && (
                                  <FormItem
                                    {...rest}
                                    label={`Lender Name`}
                                    rules={[formItemRequiredRule]}
                                    tooltip={
                                      "Select the Lender you wish to upload"
                                    }
                                    required={true}
                                    shouldUpdate={(prevValues, curValues) => {
                                      return prevValues !== curValues;
                                    }}
                                    name={[name, "lenderId"]}
                                  >
                                    <Select
                                      getPopupContainer={(
                                        trigger
                                      ): HTMLElement => trigger.parentElement}
                                    >
                                      {filteredLenders.map(({ id, name }) => {
                                        return (
                                          <Select.Option key={id} value={id}>
                                            {name}
                                          </Select.Option>
                                        );
                                      })}
                                    </Select>
                                  </FormItem>
                                )}
                                <FormItem
                                  {...rest}
                                  label={`Select Response Column`}
                                  required={element?.elementType !== "LENDER"}
                                  rules={[formItemRequiredRule]}
                                  name={[name, "excelColNum"]}
                                  shouldUpdate={(
                                    prevValues: any[],
                                    curValues: any[]
                                  ) =>
                                    prop("additional", prevValues) !==
                                    prop("additional", curValues)
                                  }
                                  tooltip={
                                    "Enter the letter of the column you wish to upload for the corresponding Lender. Only up to column letter Z is allowed."
                                  }
                                >
                                  <Select
                                    className={"w-full"}
                                    getPopupContainer={(trigger): HTMLElement =>
                                      trigger.parentElement
                                    }
                                    suffixIcon={
                                      element?.elementType !== "LENDER" && (
                                        <Button
                                          icon={<MinusCircleOutlined />}
                                          type={"text"}
                                          onClick={() => remove(index)}
                                        />
                                      )
                                    }
                                  >
                                    {ALPHABETS.map((c, i) => {
                                      return (
                                        <Select.Option key={c} value={i + 1}>
                                          {isWordDoc ? i + 1 : c}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </FormItem>
                              </>
                            ))}
                            {!isEmpty(filteredLenders) &&
                              fields?.length <
                                (filteredLenders ?? [])?.length && (
                                <Button
                                  className={"w-full col-span-2"}
                                  type={"dashed"}
                                  onClick={() => add(fields?.length)}
                                >
                                  + Add Lender
                                </Button>
                              )}
                          </>
                        )}
                      </Form.List>
                    )}
                    {isWordDoc && (
                      <FormItem
                        tooltip={
                          "Specify the table number you wish to upload. By default we are uploading biggest table in doc.Note that tables are typically separated by free-form text in your document."
                        }
                        name={"tableNumber"}
                        label={"Table Number"}
                        required={false}
                        rules={[
                          {
                            validator: (_, tableNumber) => {
                              if (
                                tableNumber === null ||
                                tableNumber === undefined
                              ) {
                                return Promise.resolve();
                              }
                              return tableNumber <= 0
                                ? Promise.reject(
                                    new Error(
                                      "Table Number should be greater than zero"
                                    )
                                  )
                                : Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <InputNumber
                          precision={0}
                          placeholder={"Table Number (optional)"}
                          className={"w-full"}
                        />
                      </FormItem>
                    )}
                  </div>
                </Form>
                {isWordDoc && (
                  <div>
                    <li>
                      For Word file, you can choose to upload a table from the
                      file
                    </li>
                  </div>
                )}
                {!isWordDoc && (
                  <div>
                    <li>
                      If the Excel file has multiple worksheets, the system will
                      take the values from the first sheet in the file
                    </li>
                  </div>
                )}
              </div>
            )}
          </div>
        );
    }
  };

  /** Add or remind Lenders that haven't published to the deal team **/
  const addLenders: addLendersType = (
    id,
    webformId,
    version,
    lenders,
    isOffline
  ) => {
    if (isOffline) {
      addLendersForOfflineTermsheet({
        segments: {
          id,
          webformId,
          versionId: version,
        },
        params: {
          isReminder: false,
        },
        body: JSON.stringify(lenders),
      })
        .then(() => {
          message.success("Institution has been added successfully!");
          onReloadVersions(transactionId, webFormId, version);
          onModalClose();
          onCloseOrCancel();
        })
        .catch(() => {
          message.error("Unable to add an institution at this time!");
          setConfirmLoading(false);
        });
    } else
      addOrRemindLendersForWebformVersion({
        segments: {
          id,
          webformId,
          version,
        },
        params: {
          isReminder: false,
        },
        body: JSON.stringify(lenders),
      })
        .then(() => {
          message.success("Institution has been added successfully!");
          onReloadVersions(transactionId, webFormId, version);
          onModalClose();
          onCloseOrCancel();
        })
        .catch(() => {
          message.error("Unable to add an institution at this time!");
          setConfirmLoading(false);
        });
  };

  /** Remove lenders from published webform version **/
  const removeLendersFromVersion: removeLendersFromVersionType = (
    id,
    webformId,
    version,
    lenders
  ) => {
    removeLenderFromWebformVersion({
      segments: {
        id,
        webformId,
        version,
      },
      body: JSON.stringify(lenders),
    })
      .then(() => {
        message.success("Institution has been removed successfully!");
        onReloadVersions(transactionId, webFormId, version);
        onModalClose();
        onCloseOrCancel();
      })
      .catch(() => {
        message.error("Unable to remove institution at this time!");
        setConfirmLoading(false);
      });
  };

  /** Compare two or more columns **/
  const compareColumns: compareColumnsType = (webFormRedLine) => {
    setColumns(webFormRedLine);
  };

  /** Upload File **/
  const uploadFile: uploadFileType = (data) => {
    call<Promise<ResponseType>>(
      isOffline ? pastUploadExcelFile : uploadExcelFile,
      {
        body: JSON.stringify(data),
      }
    )
      .then(() => {
        message.success("File uploaded successfully");
        onModalClose();
        onUpload();
      })
      .catch((error: string) => {
        message.error(error ?? "Unable to upload file at this time");
        setConfirmLoading(false);
      });
  };

  useEffect(() => {
    if (LENDER_MODALS.includes(type)) {
      setFilteredLenders(
        getLenderFilteredList(type, institutions, currentVersion, versions)
      );
    }
  }, [type, institutions, currentVersion, versions]);

  useEffect(() => {
    if (type === "COMPARE") {
      setOriginalInstitutions(
        getCompareOptions(
          versions,
          institutions,
          webFormId,
          transactionId,
          true
        )
      );
      setCompareInstitutions(
        getCompareOptions(
          versions,
          institutions,
          webFormId,
          transactionId,
          false
        )
      );
    }
  }, [type, institutions, versions, webFormId, transactionId]);

  useEffect(() => {
    type && setVisible(true);
  }, [type]);

  const isWordDoc =
    uploadedFile &&
    isWordDocument(uploadedFile.fileName, uploadedFile.contentType);

  return (
    <Modal
      destroyOnClose={true}
      width={700}
      open={visible}
      closable={true}
      maskClosable={false}
      footer={
        <div className={"flex justify-between"}>
          <div>
            {isWordDoc ? (
              <Button
                target={"_blank"}
                type={"link"}
                href={
                  isInstitution
                    ? "https://app.arcade.software/share/peq9RkVrXqExSJoRqF6r"
                    : "https://app.arcade.software/share/H5koCCqu37jSpS3c2VbV"
                }
              >
                Tutorial
              </Button>
            ) : (
              <Button
                type={"link"}
                icon={
                  path([type], MODAL_SECONDARY_ICON) ? (
                    <>
                      <i className={path([type], MODAL_SECONDARY_ICON)}></i>
                      &nbsp;&nbsp;
                    </>
                  ) : undefined
                }
                hidden={isNil(path([type], MODAL_SECONDARY_ACTION_TEXT))}
                loading={secondaryLoading}
                disabled={secondaryLoading}
                onClick={() => {
                  onSecondaryOkClick(type);
                }}
              >
                {pathOr("", [type], MODAL_SECONDARY_ACTION_TEXT)}
              </Button>
            )}
          </div>
          <div className={"flex flex-row gap-1"}>
            <Button
              disabled={confirmLoading}
              key="back"
              onClick={onCloseOrCancel}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              className={PRIMARY_BUTTON_STYLE}
              loading={confirmLoading}
              disabled={isDisabled(type)}
              onClick={() => {
                onPrimaryOkClick(type);
              }}
            >
              {MODAL_PRIMARY_ACTION_TEXT[type]}
            </Button>
          </div>
        </div>
      }
      onCancel={onCloseOrCancel}
      title={
        <span className={"text-xs text-secondary"}>
          {type && MODAL_TYPES[type]}
        </span>
      }
    >
      {getModalContent(type, filteredLenders, isOffline)}
    </Modal>
  );
};

type TermsheetModalsType = {
  type: ModalType;
  currentVersion: number;
  versions?: VersionDataType;
  onCloseOrCancel: () => void;
  onDealTeamPublish: (values: Array<string>) => void;
  onInstitutionPublish: () => void;
  onUpload: () => void;
  published: boolean;
};

type getCompareOptionsType = (
  data: VersionDataType,
  lenders: Array<LenderType>,
  webFormId: string,
  transactionId: string,
  disableRootNote: boolean,
  value?: string
) => Array<DataNode>;
type getCompareOptionsChildrenType = (
  version: string,
  versionType: VersionType,
  lenders: Array<LenderType>,
  webFormId: string,
  transactionId: string,
  value?: string
) => Array<DataNode>;
type onCompareModalValueChangeType = (
  changedValues: any,
  allValues: any
) => void;
type onCompareModalFinishType = (values: any) => void;
type onLenderFormSubmitType = (
  type: LenderModalType,
  lenders: Array<LenderType>
) => void;
type getLenderFilteredList = (
  type: ModalType,
  lenders: Array<LenderType>,
  currentVersion: number,
  versions: VersionDataType
) => Array<LenderType>;
type onOkClickType = (type: ModalType) => void;
type isDisabledType = (type: ModalType) => boolean;
type getModalContentType = (
  type: ModalType,
  lenders: Array<LenderType>,
  isOffline: boolean
) => ReactNode;
type addLendersType = (
  id: string,
  webformId: string,
  version: string,
  lenders: Array<LenderType>,
  isOffline?: boolean
) => void;
type removeLendersFromVersionType = (
  transactionId: string,
  webformId: string,
  version: string,
  lenders: Array<LenderType>
) => void;
type compareColumnsType = (webFormRedLine: WebFormRedLineType) => void;
type uploadFileType = (data: TermsheetUploadType) => void;
type LenderModalType = "PUBLISH_DEAL_TEAM" | "ADD_LENDER" | "REMOVE_LENDER";
type filterInstitutionsForUploadType = (
  institutions: LenderType[],
  mappings: LenderExcelDTOType[]
) => LenderType[];
type CheckIfNoUsersType = (lender: LenderType, type: ModalType) => boolean;
