import { ElementPermissionsType } from "./types";
import { ElementType, PermissionType } from "./enums";
import { find, isEmpty, isNil, propEq } from "ramda";
import { containsAll, containsAny } from "./utils";

export const checkElementViewPermission: getElementPermissionsType = (
  elementType,
  elementPermissions = [],
  permissions,
  matchAllPermissions
) => {
  const elementPermission: ElementPermissionsType = find(
    propEq("elementType", elementType)
  )(elementPermissions) as ElementPermissionsType;
  if (!isNil(elementPermission) && !isEmpty(elementPermission.permissions)) {
    return matchAllPermissions
      ? containsAll(permissions, elementPermission.permissions)
      : containsAny(permissions, elementPermission.permissions);
  }
  return false;
};

type getElementPermissionsType = (
  elementType: ElementType,
  elementPermissions: Array<ElementPermissionsType>,
  permissions: Array<PermissionType>,
  matchAllPermissions: boolean
) => boolean;
