import { FileType } from "./types";
import { fileUpload } from "../services/request";
import { map, path } from "ramda";

export const uploadFile: uploadFilesType = (file, mediaURL) => {
  const formData = new FormData();
  formData.append("file", file);
  return fileUpload(`${mediaURL}`, {
    body: formData,
  }) as Promise<UploadFilesResponseType>;
};

export const uploadMultipleFiles: uploadMultipleFilesType = ({
  onSuccess,
  onFailure = () => {},
  files,
  mediaURL,
}) => {
  Promise.all(files.map((o) => uploadFile(o, mediaURL)))
    .then((v) => {
      const dataList = map(path(["data"]), v) as Array<FileType>;
      onSuccess(dataList);
    })
    .catch(onFailure);
};

type UploadFilesResponseType = Record<string, any> & { data: FileType };

type uploadFilesType = (
  file: File,
  mediaURL: string,
  apiURL?: string
) => Promise<UploadFilesResponseType>;

type UploadMultipleProps = {
  files: Array<File>;
  mediaURL: string;
  apiURL?: string;
  onSuccess: onSuccessType;
  onFailure: VoidFunction;
};
type uploadMultipleFilesType = (props: UploadMultipleProps) => void;
type onSuccessType = (files: Array<FileType>) => void;
