import React, { FC, useContext } from "react";
import { TransactionContext } from "../../../context/TransactionContext";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { usePageTitle } from "../../../customHooks/usePageTitle";
import { TermsheetList } from "./TermsheetList";
import { TermsheetContent } from "./TermsheetContent";
import {
  PermissionType,
  PETransactionCreationType,
} from "../../../utils/enums";
import { UserContext } from "../../../context/UserContext";

export const TermsheetRoutes: FC = function () {
  usePageTitle("Termsheet");
  const { transactionId, permissions, transaction } =
    useContext(TransactionContext);
  const { user } = useContext(UserContext);

  const { path } = useRouteMatch();

  const isAdmin = transaction
    ? transaction.peTransactionCreationType ===
      PETransactionCreationType.OFFLINE
      ? permissions.includes(PermissionType.ADMIN_LENDER) ||
        permissions.includes(PermissionType.EDIT_PETRANSACTION)
      : permissions.includes(PermissionType.EDIT_PETRANSACTION)
    : false;

  const isInstitution =
    permissions.includes(PermissionType.ADMIN_LENDER) ||
    permissions.includes(PermissionType.NON_ADMIN_LENDER);
  return (
    <>
      {transactionId && transaction && permissions && (
        <Switch>
          <Route exact={true} path={path}>
            <TermsheetList
              transactionId={transactionId}
              isAdmin={isAdmin}
              isInstitution={isInstitution}
            />
          </Route>
          <Route exact={true} path={`${path}/:webFormId`}>
            <TermsheetContent
              transactionType={transaction.peTransactionCreatorType}
              transactionId={transaction.peTransactionId}
              element={transaction.teamDTO}
              institutions={transaction.lenderDTOs}
              isOffline={
                transaction.peTransactionCreationType ===
                PETransactionCreationType.OFFLINE
              }
              isAdmin={isAdmin}
              isNonAdmin={
                !permissions.includes(PermissionType.EDIT_PETRANSACTION) &&
                permissions.includes(PermissionType.NON_ADMIN_PETRANSACTION)
              }
              isInstitution={isInstitution}
              transactionCompany={transaction.companyName}
            />
          </Route>
        </Switch>
      )}
    </>
  );
};
