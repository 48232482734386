import React, { FC, useEffect, useState } from "react";
import { PortfolioCard } from "../../PortfolioCard";
import { Button, message, Timeline } from "antd";
import { DisconnectOutlined } from "@ant-design/icons";
import { EmptyPortfolioCard } from "../../EmptyPortfolioCard";
import { LinkTransactionModal } from "../../../portfolioModals/LinkTransactionModal";
import { initiateFetchSection } from "../../../../../utils/portfolio";
import {
  AllocationKeyType,
  CompanyType,
  PortfolioSectionType,
} from "../../../../../utils/types";
import { useParams } from "react-router";
import { PermissionType } from "../../../../../utils/enums";
import { getAllocationKeys } from "../../../../../services/services";
import { ResponseType } from "../../../../../utils/uiTypes";
import { PastTransaction } from "./PastTransaction";

export const PastTransactionsList: FC<PastTransactionsType> = ({
  permissions,
  companies,
  portfolioId,
}) => {
  const { sectionId }: ParamsType = useParams();

  const [section, setSection] = useState<PortfolioSectionType | null>(null);
  const [linkTxModal, setLinkTxModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [allocationKeys, setAllocationKeys] = useState<AllocationKeyType[]>([]);

  const getPortfolioTransactions = (isAddNew: boolean): void => {
    initiateFetchSection(sectionId, portfolioId)
      .then((section) => {
        setSection(section);
        if (isAddNew) message.success("Transaction(s) added successfully.", 3);
      })
      .catch((error: string): void => {
        console.error(error);
        error && message.error("Error retrieving transaction data.", 3);
        if (isAddNew) setLinkTxModal(true);
      })
      .then(() => setLoading(false));
  };

  const fetchAllocationKeys = (): void => {
    getAllocationKeys({})
      .then(({ data }: ResponseType<AllocationKeyType[]>) => {
        setAllocationKeys(data);
      })
      .catch(console.error);
  };

  const onTransactionAdd = (): void => {
    setLoading(true);
    setLinkTxModal(false);
    getPortfolioTransactions(true);
  };

  useEffect(() => {
    if (portfolioId) {
      getPortfolioTransactions(false);
      fetchAllocationKeys();
    }
  }, [portfolioId]);

  return (
    <>
      <PortfolioCard
        permissions={permissions}
        key={section?.id}
        loading={loading}
        title={section?.sectionName ?? "Past Transactions"}
        header={
          permissions.includes(PermissionType.ADMIN_PORTFOLIO) && (
            <div className={"text-right md:float-right text-blue-400"}>
              {!loading && (
                <Button
                  key={1}
                  className={`text-blue-400 border-blue-400 disabled:opacity-50`}
                  type={"default"}
                  onClick={(): void => setLinkTxModal(true)}
                >
                  Link transactions
                </Button>
              )}
            </div>
          )
        }
        editMode={false}
      >
        {section?.privateEquityTransactionDTOList &&
        section?.privateEquityTransactionDTOList?.length > 0 ? (
          <>
            <Timeline
              className={"relative pl-36"}
              pending={<></>}
              pendingDot={<></>}
            >
              {section?.privateEquityTransactionDTOList
                .sort((a, b) => Number(b.creationDate) - Number(a.creationDate))
                .map((tx) => (
                  <PastTransaction
                    key={tx.peTransactionId}
                    transaction={tx}
                    companies={companies}
                    allocationKeys={allocationKeys ?? []}
                  />
                ))}
            </Timeline>
          </>
        ) : (
          <EmptyPortfolioCard
            permissions={permissions}
            message={
              "No transactions have been added yet. Please link transaction in order to view past transactions with this company."
            }
            image={
              <DisconnectOutlined className={"py-3 text-gray-400 text-7xl"} />
            }
          />
        )}
      </PortfolioCard>

      <LinkTransactionModal
        visible={linkTxModal}
        transactions={section?.privateEquityTransactionDTOList}
        setVisible={setLinkTxModal}
        onSuccess={onTransactionAdd}
      />
    </>
  );
};

type ParamsType = { portfolioId: string; sectionId: string };
type PastTransactionsType = {
  companies: CompanyType[];
  permissions: PermissionType[];
  portfolioId: string;
};
