import React, { FC, useEffect, useState } from "react";
import { AutoComplete, Button, Form, Input, Modal, Select } from "antd";
import { getCompanyTeamById } from "../../../services/services";
import { ResponseType } from "../../../utils/uiTypes";
import {
  CompanyTagType,
  CompanyType,
  TeamMemberType,
} from "../../../utils/types";
import { SendContactType } from "../portfolioView/portfolioCards/KeyContacts";
import {
  formItemRequiredRule,
  namePatternRule,
} from "../../../utils/formUtils";
import { filterOptionWithTitle } from "../../../utils/utils";
import { CustomAvatar } from "../../general/CustomAvatar";
import { isNil } from "ramda";

export const CreateContactModal: FC<LinkTransactionModalType> = ({
  onSuccess,
  companies,
  visible,
  companyTags,
  setVisible,
}: LinkTransactionModalType) => {
  const [form] = Form.useForm();

  const [teamMembers, setTeamMembers] = useState<TeamMemberType[]>([]);
  const [loading, setLoading] = useState(false);

  const onCompanySelect = (id: string): void => {
    getCompanyTeamById({
      segments: {
        id,
      },
    }).then(({ data = [] }: ResponseType<TeamMemberType[]>) => {
      setTeamMembers(data);
    });
  };

  const onUserSelect = (val: string): void => {
    const data = JSON.parse(val);
    form.setFieldsValue({
      name: `${data?.firstName} ${data?.lastName}`,
      emailId: data.email,
      title: data.title,
    });
  };

  const onFinish = (values: SendContactType): void => {
    setLoading(true);
    onSuccess(values)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    form.resetFields(["name", "tagId", "companyName", "title", "emailId"]);
  }, [visible]);

  return (
    <Modal
      open={visible}
      onCancel={(): void => setVisible(false)}
      destroyOnClose={true}
      title={"Create Contact"}
      footer={[
        <Button
          key={"cancel"}
          disabled={loading}
          onClick={(): void => setVisible(false)}
          className={"border-primary text-primary"}
        >
          Cancel
        </Button>,
        <Button
          key={"submit"}
          disabled={loading}
          loading={loading}
          form={"form"}
          htmlType={"submit"}
          className={"bg-primary text-white border-0"}
        >
          Create
        </Button>,
      ]}
    >
      <Form<SendContactType>
        form={form}
        onFinish={onFinish}
        name="form"
        layout={"vertical"}
        initialValues={{ transactions: [] }}
      >
        <Form.Item
          name={"companyName"}
          label={"Company"}
          rules={[formItemRequiredRule]}
        >
          <Select
            notFoundContent={"No Companies Available"}
            getPopupContainer={(trigger): HTMLElement => trigger.parentElement}
            placeholder="Select Company"
            showSearch={true}
            optionFilterProp={"title"}
            onSelect={(_: string, option: { key: string }): void => {
              onCompanySelect(option.key);
              form.setFieldValue("companyId", option.key);
            }}
          >
            {companies?.map((company) => (
              <Select.Option
                key={company.id}
                value={company.name}
                title={company.name}
              >
                {company.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className={"hidden"} name={"companyId"} />
        <Form.Item
          label={"Name"}
          name={"name"}
          rules={[formItemRequiredRule, namePatternRule]}
        >
          <AutoComplete
            notFoundContent={"No Users"}
            disabled={isNil(form.getFieldValue("companyName"))}
            filterOption={filterOptionWithTitle}
            placeholder={"Name"}
            showSearch={true}
            onSelect={(val: string): void => onUserSelect(val)}
            dropdownStyle={{
              overflow: "visible",
            }}
          >
            {teamMembers?.map(({ userDTO }) => (
              <Select.Option
                title={`${userDTO?.firstName} ${userDTO?.lastName} ${userDTO?.email}`}
                key={userDTO?.userId}
                value={JSON.stringify(userDTO)}
                className={"w-full container"}
              >
                <div className={"flex flex-row items-start py-1"}>
                  <CustomAvatar
                    data={userDTO?.firstName[0] + userDTO?.lastName[0]}
                    color={userDTO?.firstName[0]}
                    size={"small"}
                  />
                  <div className={"leading-none flex flex-col"}>
                    {userDTO?.firstName} {userDTO?.lastName}
                    <span className={"text-secondary text-xs"}>
                      {userDTO?.email}
                    </span>
                  </div>
                </div>
              </Select.Option>
            ))}
          </AutoComplete>
        </Form.Item>
        <Form.Item
          label={"Email"}
          name={"emailId"}
          rules={[formItemRequiredRule]}
        >
          <Input
            disabled={isNil(form.getFieldValue("companyName"))}
            type={"email"}
            placeholder={"Email"}
          />
        </Form.Item>
        <Form.Item
          label={"Title"}
          name={"title"}
          rules={[formItemRequiredRule]}
        >
          <Input
            disabled={isNil(form.getFieldValue("companyName"))}
            type={"text"}
            placeholder={"Title"}
          />
        </Form.Item>
        <Form.Item name={"tagId"} label={"Role"} rules={[formItemRequiredRule]}>
          <Select
            notFoundContent={"No Roles Added Yet!"}
            disabled={isNil(form.getFieldValue("companyName"))}
            getPopupContainer={(trigger): HTMLElement => trigger.parentElement}
            placeholder="Select Role"
            showSearch={true}
            filterOption={(input, option): boolean =>
              option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSelect={onCompanySelect}
          >
            {companyTags?.map((company) => (
              <Select.Option
                key={company.id}
                value={company.id}
                title={company.label}
              >
                {company.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

type LinkTransactionModalType = {
  onSuccess: (a: SendContactType) => Promise<void>;
  visible: boolean;
  companies?: CompanyType[] | null;
  companyTags?: CompanyTagType[];
  setVisible: (a: boolean) => void;
};
