import React, { FC, ReactNode, useEffect, useState } from "react";
import { message, Table, Tag } from "antd";
import {
  getAllocationKeys,
  getCrmAllocation,
  getCrmAllocationSummary,
} from "../../services/services";
import { ResponseType } from "../../utils/uiTypes";
import {
  AllocationKeyType,
  AllocationType,
  CRMAllocationType,
} from "../../utils/types";
import { ColumnsType } from "antd/es/table";
import { tableColumnHeader } from "../../utils/componentUtils";
import {
  AllocationTableType,
  AnalyticsCurrencyType,
  UnitLabelType,
} from "../../utils/enums";
import { convertDateToFormat } from "../../utils/moment";
import { equals, isEmpty, isNil, or, pathOr } from "ramda";
import { arrayToMapConversion } from "../../utils/utils";
import {
  columnSort,
  fetchWithIdAndParamsType,
  ParamsType,
} from "../../utils/relationship";
import { useParams } from "react-router";
import { MultipleSelect } from "../general/MultipleSelect";
import { RelationshipCard } from "./RelationshipCard";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
import { formatDecimalAndAddCommas } from "../../utils/allocation";

const getCellValue: GetCellValueType = (
  { value, unit, currency },
  fieldType
) => {
  if (isEmpty(value) || isNil(value)) {
    return <span className={"text-muted"}>-</span>;
  }
  switch (fieldType) {
    case "TEXT" || "DROP_DOWN":
      return value;
    case "DATE":
      return convertDateToFormat(value, "D.MMM.YY", true);
    case "NUMERIC":
      return `${AnalyticsCurrencyType[currency]} ${formatDecimalAndAddCommas(
        value
      )} ${UnitLabelType[unit]}`;
    default:
      return value;
  }
};

export const FeesInfoTable: FC<AllocationInfoTableType> = ({ tableType }) => {
  const { id } = useParams<ParamsType>();
  const { search } = useLocation();

  const [allocationData, setAllocationData] = useState<CRMAllocationType[]>([]);
  const [keys, setKeys] = useState<AllocationKeyType[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedPortfolios, setSelectedPortfolios] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchAllocations: fetchWithIdAndParamsType = (id, params) => {
    setLoading(true);
    (tableType === AllocationTableType.FINAL_HOLD
      ? getCrmAllocationSummary
      : getCrmAllocation)({ segments: { id, type: tableType }, params })
      .then(({ data = [] }: ResponseType<CRMAllocationType[]>) => {
        setLoading(false);
        setAllocationData(data);
      })
      .catch((err: string): void => {
        message.error(err ?? "Something went wrong.", 3);
      });
  };

  const fetchAllocationKeys: GetAllocationTableKeysType = () => {
    getAllocationKeys({}).then(
      ({ data = [] }: ResponseType<AllocationKeyType[]>) => {
        setKeys(
          data.filter(({ allocationTableType, canDelete }) =>
            equals(allocationTableType, tableType)
          )
        );
      }
    );
  };

  useEffect(() => {
    fetchAllocationKeys();
  }, []);

  useEffect(() => {
    fetchAllocations(id, search);
  }, [id, search]);

  const columns = (
    keys: Record<string, AllocationKeyType>
  ): ColumnsType<CRMAllocationType> => [
    {
      title: tableColumnHeader("PORTFOLIO COMPANY NAME"),
      key: "portfolioName",
      render: (record) =>
        !isNil(record.portfolioName) ? (
          <span>{record.portfolioName}</span>
        ) : (
          <span className={"text-muted"}>-</span>
        ),
      sorter: (a, b) =>
        columnSort(a.portfolioName ?? "", b.portfolioName ?? "", "TEXT"),
      width: "25%",
      className: "group-hover:bg-blue-50 min-w-[150px]",
    },
    {
      title: tableColumnHeader("VERSION"),
      key: "date",
      render: (record: CRMAllocationType) => (
        <div className={"flex flex-row gap-2 justify-between"}>
          <div className={"ellipsis truncate"}>{record?.versionName}</div>
          <div className={"italic text-right"}>
            {!isEmpty(record.asOfDate) && !isNil(record.asOfDate) ? (
              <Tag color={"blue"}>{moment(record.asOfDate).format("ll")}</Tag>
            ) : (
              <Tag color={"red"}>Not Specified</Tag>
            )}
          </div>
        </div>
      ),
      width: "25%",
      className: "group-hover:bg-blue-50 min-w-[120px]",
    },
    {
      key: "facilities",
      title: tableColumnHeader(
        tableType === AllocationTableType.FEES ? "FEES TYPE" : "FACILITY TYPE"
      ),
      width: "25%",
      className: "group-hover:bg-blue-50 min-w-[120px]",
      render: ({ allocationKeyDTO }: AllocationType): ReactNode => {
        return pathOr("", [allocationKeyDTO.id, "keyName"], keys);
      },
    },
    {
      key: "Value",
      title: tableColumnHeader("VALUE"),
      width: "25%",
      className: "group-hover:bg-blue-50 min-w-[120px]",
      render: (record: CRMAllocationType): ReactNode =>
        getCellValue(
          record,
          pathOr("", [record.allocationKeyDTO.id, "fieldType"], keys)
        ),
    },
  ];

  return (
    <RelationshipCard
      label={
        equals(tableType, AllocationTableType.FEES) ? "Fees" : "Allocation"
      }
      actions={
        <div className={"inline-flex items-center gap-x-2 ml-auto"}>
          <MultipleSelect
            options={keys
              .filter(({ canDelete }) => canDelete)
              .map(({ keyName, id }) => ({
                value: id,
                label: keyName,
              }))}
            value={selectedIds}
            placeholder={
              equals(tableType, AllocationTableType.FEES)
                ? "Select Funds"
                : "Select Facilities"
            }
            label={
              equals(tableType, AllocationTableType.FEES)
                ? "Funds"
                : "Facilities"
            }
            className={"max-w-xs"}
            onChange={setSelectedIds}
          />
          <MultipleSelect
            options={Object.entries(
              arrayToMapConversion(allocationData, "portfolioId")
            ).map(([k, v]) => ({
              value: k,
              label: (v as CRMAllocationType)?.portfolioName ?? "",
            }))}
            value={selectedPortfolios}
            placeholder={"Select Portfolios"}
            label={"Portfolios"}
            className={"max-w-xs"}
            onChange={setSelectedPortfolios}
          />
          {/*<AllocationsExcel id={id} tableType={tableType} />*/}
        </div>
      }
    >
      <div className={`relative h-full overflow-y-auto flex border-t`}>
        <Table<CRMAllocationType>
          bordered={true}
          className={`w-full`}
          size={"small"}
          dataSource={allocationData.filter(
            ({ allocationKeyDTO, portfolioId = "" }) =>
              or(
                isEmpty(selectedIds),
                selectedIds.includes(allocationKeyDTO.id)
              ) &&
              or(
                isEmpty(selectedPortfolios),
                selectedPortfolios.includes(portfolioId)
              )
          )}
          columns={
            tableType === AllocationTableType.FINAL_HOLD
              ? columns(arrayToMapConversion(keys, "id")).filter(
                  (value) => value.key !== "date"
                )
              : columns(arrayToMapConversion(keys, "id"))
          }
          loading={loading}
          pagination={false}
          rowClassName={"group"}
          sticky={true}
          rowKey={({ id }) => id}
          scroll={{
            x: true,
          }}
        />
      </div>
    </RelationshipCard>
  );
};

type GetAllocationTableKeysType = () => void;
type AllocationInfoTableType = {
  tableType: AllocationTableType;
  companyId: string;
};
type GetCellValueType = (
  val: CRMAllocationType,
  fieldType: string
) => ReactNode;
