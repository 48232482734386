import React, { FC, ReactElement, useContext, useState } from "react";
import { LenderType, VersionDataType } from "../../../utils/types";
import { VersionTab } from "../common/VersionTab";
import {
  Button,
  Dropdown,
  Form,
  Menu,
  message,
  Modal,
  Select,
  Tooltip,
} from "antd";
import moment from "moment";
import { TermsheetContext } from "../../../context/TermsheetContext";
import { assocPath, isNil, omit, pathOr } from "ramda";
import { addOrRemindLendersForWebformVersion } from "../../../services/services";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import { useForm } from "antd/es/form/Form";
import { filterOption, filterSort } from "../../../utils/utils";
import { voidType } from "../../../utils/uiTypes";
import { getMaxVersion } from "../../../utils/termsheet";
import { ElementType } from "../../../utils/enums";

export const TermsheetFooter: FC<TermsheetFooterType> = function ({
  hasUnpublishedVersion,
  currentVersion = null,
  versions,
  onVersionsChange,
  onVersionSelect,
  onCreateNewVersion,
}: TermsheetFooterType) {
  const [form] = useForm();
  const {
    transactionId,
    webFormId,
    isAdmin,
    isNonAdmin,
    institutions,
    isInstitution,
    element,
    isOffline,
  } = useContext(TermsheetContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<ModalType | null>(null);
  const [reminderVersion, setReminderVersion] = useState<string | null>(null);

  const onCancel: voidType = () => {
    setLoading(false);
    setModal(null);
    setReminderVersion(null);
    form.resetFields();
  };

  const getVersionHistoryMenu: getVersionHistoryMenuType = () => {
    return (
      <Menu
        className={"w-72 max-h-80 overflow-y-scroll"}
        selectedKeys={!isNil(currentVersion) ? [currentVersion.toString()] : []}
      >
        {Object.keys(versions).map((version) => {
          const {
            createDate,
            published,
            numberOfRespondants,
            lenderIds = [],
          } = versions[version];
          const totalLenders = lenderIds.length;
          const totalRespondants = isNaN(Number(numberOfRespondants))
            ? 0
            : Number(numberOfRespondants);
          return (
            <Menu.Item
              key={version}
              onClick={() => {
                onVersionSelect(Number(version));
              }}
            >
              <div className={"flex justify-between px-1"}>
                <div className={"flex-1"}>
                  <div className={"font-medium text-xs"}>
                    {moment(createDate).format("ll")}
                  </div>
                  <div className={"font-light text-xxs leading-tight"}>
                    {moment(createDate).format("LT")}
                  </div>
                </div>
                {!isOffline && (
                  <div className={"flex-initial"}>
                    <div>
                      {published ? (
                        <>
                          {totalLenders === totalRespondants ? (
                            <div>
                              <div className={"text-xs"}>
                                <i className="mx-1 text-secondary fa fa-circle fa-xs"></i>
                                Complete
                              </div>
                              {(isAdmin || isNonAdmin) && (
                                <div
                                  className={
                                    "font-light text-xxs text-right leading-tight"
                                  }
                                >
                                  {totalRespondants} / {totalLenders}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              <div className={"text-xs"}>
                                <i className="mx-1 text-success fa fa-circle fa-xs"></i>
                                Live
                              </div>
                              {(isAdmin || isNonAdmin) && (
                                <div
                                  className={
                                    "font-light text-xxs text-right leading-tight"
                                  }
                                >
                                  {totalRespondants} / {totalLenders}
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        <div>
                          <div className={"text-xs"}>
                            <i className="mx-1 text-primary fa fa-circle fa-xs"></i>
                            Draft
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  /** Add or remind Lenders that haven't published to the deal team **/
  const remindLenders: remindLendersType = (
    id,
    webformId,
    version,
    lenders
  ) => {
    addOrRemindLendersForWebformVersion({
      segments: {
        id,
        webformId,
        version,
      },
      params: {
        isReminder: true,
      },
      body: JSON.stringify(lenders),
    })
      .then(() => {
        message.success("A reminder was sent successfully!");
        onCancel();
      })
      .catch(() => {
        message.error("Uh oh! Something went wrong, please try again!");
        setLoading(false);
      });
  };

  return (
    <>
      <div className={"absolute bottom-0 w-full h-10 z-[100]"}>
        <div className={"flex flex-row bg-gray-50 border h-full"}>
          <div className={"text-center h-full"}>
            <Dropdown
              overlay={getVersionHistoryMenu()}
              placement="topLeft"
              arrow={true}
            >
              <div
                role={"button"}
                className={
                  "bg-gray-50 group inline-flex h-full items-center justify-center w-10 min-w-[2.5rem] border-x hover:bg-white"
                }
              >
                <i className="text-gray-400 group-hover:text-primary fa fa-list-ul" />
              </div>
            </Dropdown>
          </div>
          <div
            className={
              "flex flex-row overflow-x-scroll overscroll-none hide-scrollbar"
            }
          >
            {Object.keys(versions).map((version) => {
              const {
                name,
                createDate,
                published,
                numberOfRespondants,
                respondStatus,
                lenderIds = [],
                sponsorPublishDate,
              } = versions[version];
              const filteredInstitutions = institutions.filter(({ id }) =>
                lenderIds.includes(id)
              );
              const isLastVersion = getMaxVersion(versions) === Number(version);
              return (
                <VersionTab
                  versionName={isAdmin || isNonAdmin ? name : ""}
                  versionId={version}
                  webformId={webFormId}
                  isLastVersion={isLastVersion}
                  isOnlyVersion={
                    (Object.keys(versions ?? {})?.length ?? 0) <= 1
                  }
                  isOffline={isOffline}
                  key={version}
                  sponsorPublishDate={sponsorPublishDate}
                  selected={version === currentVersion?.toString()}
                  date={
                    isInstitution && element?.elementType === ElementType.LENDER
                      ? pathOr(
                          "",
                          [element.elementId],
                          sponsorPublishDate ?? {}
                        )
                      : createDate
                  }
                  published={published}
                  numberOfLenders={lenderIds?.length}
                  numberOfRespondants={
                    isNaN(Number(numberOfRespondants))
                      ? 0
                      : Number(numberOfRespondants)
                  }
                  respondStatus={respondStatus}
                  showRespondantData={
                    ((isAdmin || isNonAdmin) && published) || isOffline
                  }
                  canEdit={isAdmin}
                  canRemind={isOffline ? false : isAdmin}
                  institutions={filteredInstitutions}
                  onSelect={() => {
                    onVersionSelect(Number(version));
                  }}
                  onDelete={() => {
                    const updatedVersion = omit([version], versions);
                    onVersionsChange(
                      updatedVersion,
                      getMaxVersion(updatedVersion)
                    );
                  }}
                  onRemind={() => {
                    setReminderVersion(version);
                    setModal(ModalType.REMINDER);
                  }}
                  onRename={(newName) => {
                    onVersionsChange(
                      assocPath([version, "name"], newName, versions)
                    );
                  }}
                />
              );
            })}
          </div>
          {isAdmin && !hasUnpublishedVersion && (
            <Tooltip placement={"topRight"} title={"New Termsheet"}>
              <div
                role={"button"}
                onClick={onCreateNewVersion}
                className={
                  "bg-gray-50 group inline-flex h-full items-center justify-center w-10 min-w-[2.5rem] border-x hover:bg-white"
                }
              >
                <i className="text-gray-400 group-hover:text-primary fa fa-plus" />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      {modal === ModalType.REMINDER && reminderVersion && (
        <Modal
          width={700}
          destroyOnClose
          visible
          closable
          maskClosable={false}
          footer={[
            <Button key="back" onClick={onCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              className={PRIMARY_BUTTON_STYLE}
              loading={loading}
              onClick={() => {
                form.submit();
              }}
            >
              Remind
            </Button>,
          ]}
          onCancel={onCancel}
          title={
            <span className={"text-xs text-secondary"}>
              CHOOSE THE INSTITUTION(S) YOU WANT TO SEND REMINDER TO
            </span>
          }
        >
          <Form
            form={form}
            layout={"vertical"}
            onFinish={() => {
              setLoading(true);
              remindLenders(
                transactionId,
                webFormId,
                reminderVersion,
                form.getFieldValue("lenders") as Array<string>
              );
            }}
            className={"flex flex-col gap-y-3"}
          >
            <Form.Item
              name={"lenders"}
              label={"Institutions"}
              required={true}
              rules={[
                {
                  required: true,
                  message: "* Required",
                },
              ]}
            >
              <Select
                notFoundContent={"No Institutions Available"}
                className={"placeholder-red-300"}
                getPopupContainer={(trigger): HTMLElement =>
                  trigger.parentElement
                }
                showSearch
                allowClear={true}
                mode={"multiple"}
                maxTagCount={"responsive"}
                placeholder={"Select Institution(s)"}
                filterOption={filterOption}
                filterSort={filterSort}
              >
                {institutions
                  .filter(({ id }) => {
                    return (
                      versions[reminderVersion].respondStatus[id] === false
                    );
                  })
                  .map(({ id, name = "", userDTOs = [] }: LenderType) => {
                    return (
                      <Select.Option key={id} value={id}>
                        <div className={"w-full inline-flex justify-between"}>
                          <span>{name}</span>
                        </div>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

enum ModalType {
  REMINDER = "REMINDER",
}
type TermsheetFooterType = {
  hasUnpublishedVersion: boolean;
  currentVersion: number | null;
  versions: VersionDataType;
  onVersionsChange: (versions: VersionDataType, goToVersion?: number) => void;
  onVersionSelect: (v: number) => void;
  onCreateNewVersion: () => void;
};

type getVersionHistoryMenuType = () => ReactElement<any, string>;
type remindLendersType = (
  id: string,
  webformId: string,
  version: string,
  lenders: Array<string>
) => void;
