import React, { FC } from "react";
import { FileElementType, TransactionType } from "../../../../utils/types";
import FolderIcon from "../../../../images/icons/FolderIcon.svg";
import { FileIcon } from "../../../general/FileIcon";
import { TextOverFlowHandle } from "../../../general/TextOverFlowHandle";

type FileNameType = {
  name: string;
  fileData: FileElementType;
  transaction: TransactionType | null;
  onMove?: boolean;
};

export const FileName: FC<FileNameType> = function ({
  name,
  fileData,
  transaction,
  onMove,
}: FileNameType) {
  return (
    <div className={`flex flex-row gap-x-2 items-center`}>
      {fileData.isFolder ? (
        <img
          alt={""}
          src={FolderIcon}
          style={onMove ? { height: 20, width: 20 } : { height: 35, width: 35 }}
        />
      ) : (
        <FileIcon name={name ?? ""} size={onMove ? "small" : "middle"} />
      )}
      {fileData.isFolder ? (
        <TextOverFlowHandle text={name} />
      ) : onMove ? (
        <TextOverFlowHandle text={name} />
      ) : (
        <a
          rel={"noreferrer"}
          href={`/api/rest/media/${
            fileData.fileId ?? fileData.fileDTO?.fileId
          }?transactionId=${transaction?.peTransactionId ?? ""}`}
          target={"_blank"}
          className={
            "hover:underline underline-offset-2 text-ellipsis overflow-hidden flex flex-wrap"
          }
        >
          <TextOverFlowHandle text={name} />
        </a>
      )}
    </div>
  );
};
