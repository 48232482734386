import { PortfolioSectionType } from "../../../../utils/types";
import { PortfolioSectionEnumType } from "../../../../utils/enums";

export const getLongFormTemplateSectionList = (
  s: PortfolioSectionType[]
): PortfolioSectionType[] => [
  {
    elementId: "deal-metrics",
    id: "deal-metrics",
    sectionElementType: "deal-metrics",
    sectionName: "Deal Metrics",
    sectionType: PortfolioSectionEnumType.KPI,
  } as PortfolioSectionType,
  {
    elementId: "documents",
    id: "documents",
    rank: "0",
    sectionElementType: "documents",
    sectionName: "Documentation",
    sectionType: PortfolioSectionEnumType.LEAKAGE_ANALYSIS,
  } as PortfolioSectionType,
  ...s,
];
