import React, { FC, ReactElement, useContext, useState } from "react";
import { LenderType, MileStoneType } from "../../../../utils/types";
import { Checkbox, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { tableColumnHeader } from "../../../../utils/componentUtils";
import { equals, isEmpty, isNil, pathOr, slice } from "ramda";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { getObjectFromPropertyPathValue } from "../../../../utils/utils";
import { TransactionContext } from "../../../../context/TransactionContext";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { TextOverFlowHandle } from "../../../general/TextOverFlowHandle";
import { DashboardTrackerNotes } from "./DashboardTrackerNotes";
import { ElementTag } from "../../../company/tags/ElementTag";

export const DashboardTrackersLenders: FC<DashboardTrackersNewType> = ({
  loading,
  canEdit,
  toggleMilestoneData,
  showNotes,
  id,
  showTags,
  lenders,
}) => {
  const { height } = useWindowDimensions();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const columns = (milestones: MileStoneType[]): ColumnsType<LenderType> => [
    {
      title: tableColumnHeader("Institutions"),
      className: "p-2 text-gray-400 min-w-[200px]",
      dataIndex: "name",
      render: (record, lender) => (
        <div className={"inline-flex justify-between w-full items-center"}>
          <TextOverFlowHandle key={record} text={record} />
          {showNotes && (
            <DashboardTrackerNotes lenderId={lender.id} transactionId={id} />
          )}
        </div>
      ),
    },
    ...milestones.map(({ mileStoneId, name }) => ({
      title: tableColumnHeader(name),
      className: "p-2 text-center text-gray-400",
      dataIndex: "id",
      align: "center" as const,
      render: (lenderId: string, record: LenderType) => (
        <div className={"inline-flex gap-x-2 items-center justify-center"}>
          <>
            <Checkbox
              defaultChecked={
                getObjectFromPropertyPathValue(
                  ["mileStoneId"],
                  mileStoneId,
                  record?.milestoneDTOs ?? []
                )?.done ?? false
              }
              disabled={!canEdit}
              onChange={(): void => toggleMilestoneData(lenderId, mileStoneId)}
            />
            {pathOr(false, [lenderId, mileStoneId], loading) && (
              <Spin
                spinning={true}
                indicator={<LoadingOutlined />}
                size={"small"}
              />
            )}
          </>
        </div>
      ),
    })),
    {
      align: "right",
      key: "tags",
      className: "p-2 text-center text-gray-400",
      render: (_, lender) => {
        return !isNil(lender.tagDTOs) && !isEmpty(lender.tagDTOs) ? (
          <ElementTag tooltipPlacement={"right"} tag={lender.tagDTOs?.[0]} />
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <Table<LenderType>
      className={"shadow"}
      rowClassName="cursor-pointer p-0"
      pagination={false}
      scroll={{ y: height - 250 }}
      locale={{
        emptyText: (
          <p className={"text-gray-400"}>
            No institutions in this transaction yet!
          </p>
        ),
      }}
      rowKey={(record) => record.id}
      expandable={{
        childrenColumnName: "lenders",
        defaultExpandAllRows: false,
        showExpandColumn: true,
        expandRowByClick: true,
        rowExpandable: (): boolean => true,
      }}
      columns={columns(
        pathOr([], [0, "milestoneDTOs"], lenders) as MileStoneType[]
      ).filter(({ key }) => (showTags ? true : !equals(key, "tags")))}
      dataSource={slice(0, isExpanded ? Infinity : 7, lenders)}
      summary={(data): ReactElement => (
        <Table.Summary fixed={true}>
          {lenders.length > 7 && (
            <Table.Summary.Row className={"w-full hover:bg-blue-50"}>
              <Table.Summary.Cell
                index={0}
                colSpan={data?.[0]?.milestoneDTOs?.length + 1}
                className={"py-1.5 text-gray-400 col-span-full"}
              >
                {isExpanded ? (
                  <p
                    className={"text-center cursor-pointer"}
                    onClick={(): void => setIsExpanded(false)}
                  >
                    Collapse <CaretUpOutlined />
                  </p>
                ) : (
                  <p
                    className={"text-center cursor-pointer"}
                    onClick={(): void => setIsExpanded(true)}
                  >
                    Expand <CaretDownOutlined />
                  </p>
                )}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        </Table.Summary>
      )}
    ></Table>
  );
};

type DashboardTrackersNewType = {
  id: string;
  canEdit: boolean | null;
  loading: LoadingType;
  toggleMilestoneData: toggleMilestoneDataType;
  showNotes: boolean;
  showTags: boolean;
  lenders: LenderType[];
};
type toggleMilestoneDataType = (lenderId: string, milestoneId: string) => void;

type LoadingType = {
  [id: string]: Record<string, boolean>;
};
