import React, { FC } from "react";
import { UserNdaConsentType } from "../../../../utils/types";
import { voidType } from "../../../../utils/uiTypes";
import { Drawer, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { equals, flatten } from "ramda";
import { tableColumnHeader } from "../../../../utils/componentUtils";
import { convertDateToFormat } from "../../../../utils/moment";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { TextOverFlowHandle } from "../../../general/TextOverFlowHandle";

export const NdaLogsTable: FC<NdaLogsTableType> = ({
  logs,
  onClose,
  open,
  lenderId,
}) => {
  const { height } = useWindowDimensions();

  const columns: ColumnsType<UserNdaConsentType> = [
    {
      title: tableColumnHeader("Name"),
      render: (_, { firstName, lastName }) => `${firstName} ${lastName}`,
    },
    {
      title: tableColumnHeader("Email"),
      dataIndex: "email",
      render: (value) => (
        <div className={"inline-flex justify-between w-full items-center"}>
          <TextOverFlowHandle tooltip={true} text={value} />
        </div>
      ),
    },
    { title: tableColumnHeader("Institution"), dataIndex: "lenderName" },
    {
      title: tableColumnHeader("Date & Time"),
      dataIndex: "consentDate",
      sortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.consentDate).valueOf() - new Date(b.consentDate).valueOf(),
      render: (value) =>
        convertDateToFormat(value, "DD.MMM.YYYY - hh:mm A", false),
    },
    {
      title: tableColumnHeader("Status"),
      dataIndex: "ndaAccepted",
      render: (ndaAccepted: boolean) => (
        <Tag color={ndaAccepted ? "success" : "error"}>
          {ndaAccepted ? "ACCEPTED" : "DECLINED"}
        </Tag>
      ),
    },
  ];

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={"Click Through logs"}
      width={"90%"}
      destroyOnClose={true}
      bodyStyle={{ padding: 0 }}
    >
      <Table<UserNdaConsentType>
        pagination={false}
        scroll={{ y: height - 200 }}
        rowKey={({ userId }) => userId}
        dataSource={logs.filter((log) =>
          equals(log.lenderId, lenderId ?? log.lenderId)
        )}
        columns={columns}
        locale={{ emptyText: `No Logs ${lenderId ? "for Institution" : ""}` }}
      />
    </Drawer>
  );
};

type NdaLogsTableType = {
  logs: UserNdaConsentType[];
  open: boolean;
  onClose: voidType;
  lenderId?: string | null;
};
