import React, { FC, ReactNode, useState } from "react";
import { Avatar, Badge, Popover, Tooltip } from "antd";
import {
  CalendarTransactionType,
  DeadLineType,
  LenderType,
} from "../../utils/types";
import moment from "moment";
import { DeadlineEventModal } from "../modals/DeadlineEventModal";
import { getColorPallet } from "../../utils/colors";
import { DeleteDeadlineModal } from "../modals/DeleteDeadlineModal";
import { CalendarOutlined } from "@ant-design/icons";
import { TextOverFlowHandle } from "../general/TextOverFlowHandle";

export const CalendarPopover: FC<CalendarPopoverType> = function ({
  deadline,
  editPermission,
  transaction,
  onUpdate,
  onCreate,
  onDelete,
}: CalendarPopoverType) {
  const [visible, setVisible] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const content: contentType = (item) => (
    <div className={"w-72"}>
      <div className={"border-b-2 pb-4 h-10"}>
        <Badge
          color={item.color ? item.color : transaction?.color}
          text={
            <div className={"font-bold w-[90%] inline-flex"}>
              <TextOverFlowHandle text={deadline.title} />
            </div>
          }
          className={"w-3/4 whitespace-nowrap"}
        />
        <i
          className={
            "float-right mr-2 mt-2  fa fa-times opacity-50 cursor-pointer"
          }
          onClick={(): void => setVisible(false)}
        />
        {editPermission && (
          <>
            {/*{!moment(Number(deadline.targetDate)).isBefore(*/}
            {/*  moment().subtract(1, "days")*/}
            {/*) && (*/}
            <i
              className="float-right fa mr-2 mt-2 fa-pencil-alt opacity-50 cursor-pointer"
              onClick={(): void => {
                setVisible(false);
                setModalOpen(true);
              }}
            />
            {/*)}*/}
            <i
              className="fa fa-trash-alt float-right mr-2 mt-2 opacity-50 cursor-pointer"
              onClick={(): void => {
                setVisible(false);
                setDeleteModalOpen(true);
              }}
            />
          </>
        )}
        <p className="text-xs -mt-1 ml-3">
          <TextOverFlowHandle text={item.transactionName} />
        </p>
      </div>
      <div className={"border-b-2 py-2"}>
        <span className="opacity-50 text-xs font-semibold">DUE DATE</span>
        <span className="text-xs font-semibold float-right mt-1">
          {moment(Number(item.targetDate)).format("DD MMM YYYY")}
        </span>
        <CalendarOutlined className={"float-right mr-1 mt-1"} />
      </div>
      {editPermission && (
        <div className={"pt-2 h-10"}>
          <span className="opacity-50 text-xs font-semibold">INSTITUTIONS</span>
          <span className="float-right text-xs">
            <Avatar.Group
              maxCount={2}
              size="small"
              maxStyle={{ backgroundColor: "#808080" }}
            >
              {deadline.lenderDTOS &&
                deadline.lenderDTOS.map((lender: LenderType) => {
                  return (
                    <Tooltip
                      key={lender.id}
                      title={lender.name}
                      placement="top"
                    >
                      <Avatar
                        className={`${getColorPallet(
                          lender?.name?.charAt(0),
                          "bg"
                        )} uppercase`}
                        size="small"
                      >
                        {lender?.name?.charAt(0)}
                      </Avatar>
                    </Tooltip>
                  );
                })}
            </Avatar.Group>
          </span>
        </div>
      )}
    </div>
  );
  return (
    <>
      <Popover
        content={content(deadline)}
        trigger="click"
        open={visible}
        onOpenChange={(): void => setVisible(!visible)}
      >
        <Badge
          color={deadline.color ? deadline.color : transaction?.color}
          text={deadline.title}
          className={"hover:bg-yellow-100 px-3 w-full whitespace-nowrap"}
        />
      </Popover>
      <DeadlineEventModal
        onUpdate={onUpdate}
        onCreate={onCreate}
        visible={modalOpen}
        editDeadline={deadline}
        handleCancel={(): void => setModalOpen(false)}
        dashboardTransaction={transaction}
      />
      <DeleteDeadlineModal
        deadline={deadline}
        visible={deleteModalOpen}
        setVisible={setDeleteModalOpen}
        onDelete={onDelete}
      />
    </>
  );
};

type CalendarPopoverType = {
  deadline: DeadLineType;
  editPermission: boolean;
  transaction: CalendarTransactionType;
  onUpdate: (a: DeadLineType) => void;
  onCreate: (a: DeadLineType) => void;
  onDelete: (id: string) => void;
};
type contentType = (item: DeadLineType) => ReactNode;
