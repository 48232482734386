import moment from "moment";

const DATE_FORMATS = ["YYYY-MM-DD", "YYYY-MM-DDTHH:mm:ss.SSSZ", "DD-MMM-YY"];

export const onSort: OnSort = (aValue, bValue) => {
  if (
    aValue === null ||
    aValue === undefined ||
    bValue === null ||
    bValue === undefined
  ) {
    return aValue === bValue
      ? 0
      : aValue === null || aValue === undefined
      ? 1
      : -1;
  }

  const aNumber = /^\d+(\.\d+)?(E-\d+)?$/.test(aValue) ? Number(aValue) : NaN;
  const bNumber = /^\d+(\.\d+)?(E-\d+)?$/.test(bValue) ? Number(bValue) : NaN;

  if (!isNaN(aNumber) && !isNaN(bNumber)) {
    return aNumber - bNumber;
  } else {
    let aMoment;
    let bMoment;
    for (let i = 0; i < DATE_FORMATS.length; i++) {
      const format = DATE_FORMATS[i];
      if (
        moment(aValue, format, true).isValid() &&
        moment(bValue, format, true).isValid()
      ) {
        aMoment = moment(aValue, format);
        bMoment = moment(bValue, format);
        break;
      }
    }
    if (aMoment && bMoment) {
      return aMoment.diff(bMoment);
    } else {
      return String(aValue).localeCompare(String(bValue));
    }
  }
};

type OnSort = (a: any, b: any) => number;
