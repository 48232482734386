import React, { FC, useState } from "react";
import { Button, Form, Input, Popover } from "antd";
import { useForm } from "antd/es/form/Form";
import { PlusOutlined } from "@ant-design/icons";

export const NewFolderForm: FC<NewFolderFormType> = ({
  onSubmit,
}: NewFolderFormType) => {
  const [form] = useForm();
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      trigger={"click"}
      placement={"bottom"}
      title={"Create New Folder"}
      open={visible}
      onOpenChange={setVisible}
      content={
        <Form
          form={form}
          className={"flex flex-row items-center"}
          layout={"inline"}
          onFinish={(val): void => {
            onSubmit(val);
            setVisible(false);
            form.resetFields();
          }}
        >
          <Form.Item name={"name"} className={"m-0"}>
            <Input placeholder={"Folder Name"} autoFocus={true} />
          </Form.Item>

          <Form.Item className={"m-0 ml-2 flex flex-row"}>
            <Button
              className={"bg-primary hover:bg-hover text-white border-0"}
              htmlType={"submit"}
            >
              Add folder
            </Button>
          </Form.Item>
        </Form>
      }
    >
      <Button
        className={"bg-primary hover:bg-hover text-white border-0 w-full"}
        icon={<PlusOutlined />}
      >
        New Folder
      </Button>
    </Popover>
  );
};

type NewFolderFormType = {
  onSubmit: (o: { name: string }) => void;
};
