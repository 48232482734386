import React, { FC, Key, useContext, useEffect, useState } from "react";
import { Table, Empty, message, Collapse, Button } from "antd";
import { CrmUserType } from "../../utils/types";
import { ColumnsType } from "antd/es/table";
import { tableColumnHeader } from "../../utils/componentUtils";
import { isEmpty, isNil, length } from "ramda";
import {
  columnSort,
  dealActivityColumns,
  fetchWithIdAndParamsType,
  isCRM,
  ParamsType,
  portfolioColumns,
} from "../../utils/relationship";
import { ViewEmail } from "../general/ViewEmail";
import { addContacts, getAllKeyPersonnel } from "../../services/services";
import { ResponseType } from "../../utils/uiTypes";
import { useParams } from "react-router";
import { UserContext } from "../../context/UserContext";
import { StatisticValue } from "../general/StatisticValue";
import { RelationshipCard } from "./RelationshipCard";
import { useLocation } from "react-router-dom";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { CreateNewUserModal } from "./NewUserModal";
import { EditOutlined } from "@ant-design/icons";

export const KeyPersonnel: FC = () => {
  const { id } = useParams<ParamsType>();
  const { search } = useLocation();
  const { user } = useContext(UserContext);
  const [visible, setVisible] = useState<boolean>(false);
  const [keyPersonnel, setKeyPersonnel] = useState<CrmUserType[]>([]);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState<readonly Key[]>([]);
  const [add, setAdd] = useState(false);
  const [users, setUsers] = useState<CrmUserType | null>(null);

  const fetchKeyPersonnel: fetchWithIdAndParamsType = (id, params) => {
    getAllKeyPersonnel({ segments: { id }, params })
      .then(({ data = [] }: ResponseType<CrmUserType[]>) => {
        setLoading(false);
        setKeyPersonnel(
          data.sort(
            (a, b) =>
              (b?.transactionDTOs?.length ?? 0) -
              (a?.transactionDTOs?.length ?? 0)
          )
        );
      })
      .catch((err: string): void => {
        message.error(
          err ?? "Something went wrong, unable to fetch deal activities.",
          3
        );
      });
  };

  const columns: ColumnsType<CrmUserType> = [
    {
      title: tableColumnHeader("Name"),
      render: (record) =>
        !isNil(record) ? (
          <span>{`${record?.firstName ?? ""} ${record?.lastName ?? ""}`}</span>
        ) : (
          <span className={"text-muted"}>-</span>
        ),
      sorter: (a, b) => columnSort(a.firstName, b.firstName, "TEXT"),
      className: "group-hover:bg-blue-50 min-w-[130px]",
    },
    {
      title: tableColumnHeader("Phone"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (record) =>
        !isNil(record) ? (
          <span>{record}</span>
        ) : (
          <span className={"text-muted"}>-</span>
        ),
      sorter: (a, b) => columnSort(a.phoneNumber, b.phoneNumber, "NUMERIC"),
      className: "group-hover:bg-blue-50 min-w-[130px]",
    },
    {
      title: tableColumnHeader("Email"),
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => columnSort(a.email, b.email, "TEXT"),
      className: "group-hover:bg-blue-50 min-w-[130px]",
      render: ViewEmail,
    },
    {
      title: tableColumnHeader("Deals"),
      dataIndex: "transactionDTOs",
      key: "transactionDTOs",
      render: (record) => record?.length ?? 0,
      sorter: (a, b) =>
        columnSort(
          a?.transactionDTOs?.length ?? 0,
          b?.transactionDTOs?.length ?? 0,
          "NUMERIC"
        ),
      className: "group-hover:bg-blue-50 min-w-[120px]",
    },
    {
      title: tableColumnHeader("Portfolios"),
      dataIndex: "portfolioDTOS",
      key: "portfolioDTOS",
      render: (record) => record?.length ?? 0,
      sorter: (a, b) =>
        columnSort(
          a?.portfolioDTOS?.length ?? 0,
          b?.portfolioDTOS?.length ?? 0,
          "NUMERIC"
        ),
      className: "group-hover:bg-blue-50 min-w-[120px]",
    },
    // {
    //   title: tableColumnHeader("Action"),
    //   key: "action",
    //   render: (text, record) => (
    //     <Button
    //       icon={<EditOutlined />}
    //       onClick={() => {
    //         setUsers(record);
    //         setAdd(true);
    //       }}
    //     />
    //   ),
    //   className: "group-hover:bg-blue-50 min-w-[100px]",
    // },
  ];

  const onAdd = (values: any) => {
    console.log(values, keyPersonnel);
    addContacts({
      params: {
        mappingId: id,
      },
      body: JSON.stringify(values),
    })
      .then(() => {
        message.success("User added successfully");
        setVisible(false);
      })
      .catch((error: string) => {
        message.error(error ?? "Try again later!");
      });
  };

  useEffect(() => {
    fetchKeyPersonnel(id, search);
  }, [id, search]);

  return (
    <RelationshipCard label={"Key Personnel"}>
      <div className={"flex"}>
        <StatisticValue label={"Total Relationships"} loading={loading}>
          {length(keyPersonnel)}
        </StatisticValue>
      </div>
      {/* <div className="flex justify-end p-2">
        <Button
          className={PRIMARY_BUTTON_STYLE}
          loading={loading}
          onClick={() => {
            setVisible(true);
            setUsers(null);
          }}
        >
          Add Contact
        </Button>
      </div> */}
      <CreateNewUserModal
        visible={visible}
        setVisible={() => setVisible(!visible)}
        onAdd={onAdd}
        initialValues={users}
      />
      <div
        className={`relative h-full overflow-y-auto flex print:overflow-y-visible print:block border-t`}
      >
        <Table
          locale={{
            emptyText: (
              <Empty
                image={
                  <i className="fa-solid fa-users-line text-8xl text-gray-300"></i>
                }
                className={"text-gray-400"}
                description={"No Users"}
              />
            ),
          }}
          expandable={{
            expandedRowKeys: expandedRows,
            onExpandedRowsChange: setExpandedRows,
            expandRowByClick: true,
            expandedRowClassName: () => "m-2",
            rowExpandable: ({ transactionDTOs, portfolioDTOS }) =>
              (!isEmpty(transactionDTOs) && !isNil(transactionDTOs)) ||
              (!isEmpty(portfolioDTOS) && !isNil(portfolioDTOS)),
            expandedRowRender: ({
              transactionDTOs = [],
              portfolioDTOS = [],
            }) => (
              <Collapse ghost={true}>
                {!isEmpty(transactionDTOs) && !isNil(transactionDTOs) && (
                  <Collapse.Panel key={"transactions"} header={"Deals"}>
                    <Table
                      bordered={true}
                      size="small"
                      sticky={true}
                      dataSource={transactionDTOs ?? []}
                      columns={dealActivityColumns(
                        isCRM(user),
                        isCRM(user),
                        true
                      )}
                      rowClassName={"group"}
                      pagination={false}
                    />
                  </Collapse.Panel>
                )}
                {!isEmpty(portfolioDTOS) && !isNil(portfolioDTOS) && (
                  <Collapse.Panel key={"portfolios"} header={"Portfolios"}>
                    <Table
                      bordered={true}
                      size="small"
                      sticky={true}
                      dataSource={portfolioDTOS ?? []}
                      columns={portfolioColumns}
                      rowClassName={"group"}
                      pagination={false}
                    />
                  </Collapse.Panel>
                )}
              </Collapse>
            ),
          }}
          size={"small"}
          className={`w-full relative`}
          rowClassName={({ email }) =>
            `${
              expandedRows.includes(email)
                ? "!bg-highlight shadow sticky top-0 z-[200] relative"
                : "group"
            }`
          }
          dataSource={keyPersonnel}
          columns={columns}
          loading={loading}
          rowKey={(record) => record?.email}
          pagination={false}
          sticky={true}
          scroll={{
            x: true,
          }}
        />
      </div>
    </RelationshipCard>
  );
};
