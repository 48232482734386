import { getMinPasswordByHash } from "../services/services";
import { ResponseType, voidType } from "./uiTypes";

export const getMinPassword: getMinPasswordType = (
  hash,
  onSuccess,
  onFailure,
  companyId
) => {
  getMinPasswordByHash({
    segments: { hash },
    params: companyId ? { companyId } : {},
  })
    .then(({ data = "8" }: ResponseType<string>) => {
      onSuccess(data);
    })
    .catch(onFailure);
};
type callbackFunctionType = (criteria: string) => void;
type getMinPasswordType = (
  hash: string,
  onSuccess: callbackFunctionType,
  onFailure: voidType,
  companyId?: string
) => void;
