import React, { FC, useContext, useEffect } from "react";
import { logout } from "../services/services";
import { UserContext } from "../context/UserContext";
import { usePageTitle } from "../customHooks/usePageTitle";

export const Logout: FC = function () {
  usePageTitle("Termgrid", false);

  const { setUser, setCompanyModulePreference } = useContext(UserContext);

  useEffect(() => {
    logout({})
      .then(() => {
        setUser(null);
        setCompanyModulePreference(null);
      })
      .catch(console.error);
  }, []);
  return <></>;
};
