import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Button, message, Space } from "antd";
import { useParams } from "react-router";
import { CustomSpin } from "../../general/CustomSpin";
import { ToolBar } from "../common/ToolBar";
import {
  getObjectFromPropertyValue,
  insertItemInArray,
  removeItemFromArray,
  updateItemInArray,
  valOrDefault,
} from "../../../utils/utils";
import { DefaultColumnType } from "../../../utils/newTermsheet";
import {
  KeyValueType,
  PortfolioBidMetricType,
  PortfolioWebFormDataType,
  UiDataType,
} from "../../../utils/types";
import { dropLast, findIndex, isNil, last, path, propEq, sort } from "ramda";
import {
  addPortfolioTermsheetRow,
  deletePortfolioTermsheetRow,
  generateKeyTermsExcel,
  getPortfolioTermsheetData,
  savePortfolioTermsheet,
  uploadKeyTermsExcel,
} from "../../../services/services";
import {
  FileResponseType,
  ResponseType,
  voidType,
} from "../../../utils/uiTypes";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import { PortfolioTermsheetRows } from "./PortfolioTermsheetRows";
import {
  RightClickActionType,
  SelectedItemType,
} from "../../../utils/termsheet";
import { ConfirmDownload } from "../../../utils/confirmationModals";
import { KeyTermsUpload, KeyTermsUploadData } from "./KeyTermsUpload";
import { useUser } from "../../../hooks/useUser";
import { ElementType, PermissionType } from "../../../utils/enums";
import { PortfolioAdminPermissionBlock } from "../../portfolio/PortfolioAdminPermissionBlock";

export const MESSAGE_CONTENT = {
  saving: "Saving ...",
  creating: "Creating ...",
  saved: "Template has been saved successfully!",
  created: "Template has been created successfully!",
  saveError: "Unable to save the template",
  createError: "Unable to create the template",
};

export const PortfolioTermsheet: FC = function () {
  const { sectionId, portfolioId } = useParams<ParamsType>();
  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const [undoItems, setUndoItems] = useState<
    Array<Array<PortfolioBidMetricType>>
  >([]);
  const [redoItems, setRedoItems] = useState<
    Array<Array<PortfolioBidMetricType>>
  >([]);
  const [selectedItem, setSelectedItem] = useState<SelectedItemType | null>(
    null
  );
  const [webformConfigs, setWebformConfigs] = useState<
    Array<PortfolioBidMetricType>
  >([]);
  const [webformTemplate, setWebformTemplate] =
    useState<PortfolioWebFormDataType | null>(null);
  const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);

  /** Update Cell Content **/
  const onUpdateRow: onUpdateRowType = (row, i) => {
    setUndoItems((items) =>
      insertItemInArray(items.length, items, webformConfigs)
    );
    setWebformConfigs((configs) => updateItemInArray(i, configs, row));
  };

  const parseTemplate = (data: PortfolioWebFormDataType): void => {
    setWebformTemplate(data);
    try {
      let configs: Array<PortfolioBidMetricType> = data?.bidMetricDTOList ?? [];
      configs = sort(
        (a: PortfolioBidMetricType, b: PortfolioBidMetricType) =>
          parseInt(a.position) - parseInt(b.position),
        configs
      );
      setWebformConfigs(configs);
    } catch {
      message.error("Something went wrong! Please refresh or try again later!");
    }
  };

  const onStyleChange: onStyleChangeType = (style) => {
    if (webformConfigs && selectedItem) {
      const config = webformConfigs[selectedItem.rowIndex];
      if (selectedItem.column === DefaultColumnType.BID_METRICS) {
        onUpdateRow(
          { ...config, styleData: { ...config.styleData, style } },
          selectedItem.rowIndex
        );
      } else {
        onUpdateRow(
          {
            ...config,
            portfolioDealTeamAskDTO: {
              ...config.portfolioDealTeamAskDTO,
              styleData: {
                ...(path(
                  ["portfolioDealTeamAskDTO", "styleData"],
                  config
                ) as UiDataType),
                style,
              },
            },
          },
          selectedItem.rowIndex
        );
      }
      setSelectedItem((cell) => cell && { ...cell, style });
    }
  };

  const resetFields: voidType = () => {
    setSelectedItem(null);
    setUndoItems([]);
    setRedoItems([]);
  };

  /** Undo and Redo functionality **/
  const undoOrRedo: undoOrRedoType = (
    removeArray,
    insertArray,
    insertCallback,
    dropCallback
  ) => {
    const item = last(removeArray);
    if (!isNil(item)) {
      insertCallback(
        insertItemInArray(insertArray.length, insertArray, webformConfigs)
      );
      setWebformConfigs(() => item);
      dropCallback(dropLast(1, removeArray));
    }
  };

  const onSave = (body: PortfolioWebFormDataType): void => {
    setLoading(true);
    savePortfolioTermsheet({
      segments: {
        id: sectionId,
      },
      body: JSON.stringify(body),
    })
      .then(({ data }: ResponseType<PortfolioWebFormDataType>) => {
        message.success("Template has been saved successfully!");
        parseTemplate(data);
        setLoading(false);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(
          "Something went wrong! Please refresh or try again later!"
        );
        setLoading(false);
      });
  };

  const editTemplate = (id: string): void => {
    getPortfolioTermsheetData({
      segments: {
        id: id,
      },
    })
      .then(({ data }: ResponseType<PortfolioWebFormDataType>) => {
        parseTemplate(data);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(
          "Something went wrong! Please refresh or try again later!"
        );
      })
      .then(() => {
        setLoading(false);
      });
  };

  /** Right click event logic  **/
  const positionIncrementAtIndex: positionIncrementAtIndexType = (
    config,
    index,
    configs = []
  ) => {
    return [
      ...configs.slice(0, index),
      config,
      ...configs.slice(index).map((o) => {
        return { ...o, position: (parseInt(o.position) + 1).toString() };
      }),
    ];
  };

  /** Add new row **/
  const onAddRow: onAddRowType = (position, webFormConfigDTOs) => {
    addPortfolioTermsheetRow({
      body: JSON.stringify({
        portfolioWebformId: sectionId,
        position: parseInt(position),
      }),
    })
      .then(({ data }: ResponseType<PortfolioWebFormDataType>) => {
        const item = getObjectFromPropertyValue(
          "position",
          position,
          data?.bidMetricDTOList ?? []
        );
        if (item) {
          const index = findIndex(propEq("position", position))(
            webFormConfigDTOs
          );
          setWebformConfigs(
            positionIncrementAtIndex(item, index, webformConfigs)
          );
          resetFields();
        }
      })
      .catch(() => {
        message.error("Unable to add row");
      });
  };

  const addOrDeleteRow: addOrDeleteRowType = (action, id, index) => {
    switch (action) {
      case "ADD_BELOW":
      case "ADD_ABOVE": {
        const position = webformConfigs[index]?.position;
        return onAddRow(position, webformConfigs);
      }

      case "DELETE": {
        setLoading(true);
        deletePortfolioTermsheetRow({
          body: JSON.stringify({
            id: webformConfigs[index]?.id,
            portfolioWebformId: webformConfigs[index]?.portfolioWebformId,
          }),
        })
          .then(() => {
            return setWebformConfigs((webformConfigs) => {
              resetFields();
              setLoading(false);
              return removeItemFromArray(index, webformConfigs);
            });
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  const downloadFile: downloadFileType = (webFormId) => {
    message.loading({
      content: "Downloading File...",
      duration: 0,
      key: "download",
    });
    generateKeyTermsExcel({
      segments: { webFormId },
    })
      .then(({ url, filename }: FileResponseType) => {
        message.success({
          key: "download",
          content: `File ready to download`,
        });
        ConfirmDownload(filename, url);
      })
      .catch((error: any) => {
        console.error(error);
        message.error({
          content: "Unable to download file",
          key: "download",
        });
      });
  };

  const onUpload = (data: KeyTermsUploadData): void => {
    data.endRow = data.endRow || "400";
    setIsUploadLoading(true);
    uploadKeyTermsExcel({ body: JSON.stringify(data) })
      .then(({ data }: ResponseType<PortfolioWebFormDataType>) => {
        message.success("Template has been saved successfully!");
        parseTemplate(data);
        setLoading(false);
        setIsUploadLoading(false);
        setIsUploadOpen(false);
      })
      .catch((error: string) => {
        console.error(error);
        message.error({
          content: "Unable to Upload file",
        });
        setIsUploadLoading(false);
      });
  };

  const permissions =
    user?.elementPermissions?.find(
      (item) => item?.elementType === ElementType.PORTFOLIO
    )?.permissions ?? [];

  useEffect(() => {
    editTemplate(sectionId);
  }, [sectionId]);

  return (
    <div className={"relative max-h-full h-full w-full bg-gray-100"}>
      <CustomSpin loading={loading} />
      <div className={"h-full max-h-full w-full overflow-y-hidden pb-[45px]"}>
        <div className={"absolute sticky top-0 z-[100]"}>
          <ToolBar
            name={valOrDefault("New Template", webformTemplate?.name)}
            selectedStyle={selectedItem?.style}
            showStyleChangeButtons={permissions.includes(
              PermissionType.ADMIN_PORTFOLIO
            )}
            onStyleChange={onStyleChange}
            editName={false}
            onNameChange={(): void => {}}
            showUploadButton={permissions.includes(
              PermissionType.ADMIN_PORTFOLIO
            )}
            downloadItems={[
              {
                label: "Download Key Terms",
                key: "DOWNLOAD_PORTFOLIO_EXCEL",
                show: permissions.includes(PermissionType.ADMIN_PORTFOLIO),
              },
            ]}
            onButtonClick={(action): void => {
              switch (action) {
                case "UNDO":
                  return undoOrRedo(
                    undoItems,
                    redoItems,
                    setRedoItems,
                    setUndoItems
                  );
                case "REDO":
                  return undoOrRedo(
                    redoItems,
                    undoItems,
                    setUndoItems,
                    setRedoItems
                  );
                case "UPLOAD":
                  setIsUploadOpen(true);
                  return;
                case "DOWNLOAD_PORTFOLIO_EXCEL":
                  return downloadFile(sectionId);
              }
            }}
          >
            <PortfolioAdminPermissionBlock permissions={permissions}>
              <Space className={"gap-2"}>
                <Button
                  disabled={loading}
                  loading={loading}
                  type={"primary"}
                  className={PRIMARY_BUTTON_STYLE}
                  onClick={(): void => {
                    setLoading(true);
                    webformTemplate &&
                      onSave({
                        ...webformTemplate,
                        bidMetricDTOList: webformConfigs,
                      });
                  }}
                >
                  {"Save"}
                </Button>
              </Space>
            </PortfolioAdminPermissionBlock>
          </ToolBar>
        </div>
        {!loading && webformConfigs && (
          <div className={"max-h-full h-full w-full"}>
            <div
              className={
                "relative max-h-full w-full overflow-x-scroll overscroll-none scroll-smooth"
              }
            >
              <PortfolioTermsheetRows
                elementId={sectionId}
                editable={permissions.includes(PermissionType.ADMIN_PORTFOLIO)}
                configs={webformConfigs}
                selectedItem={selectedItem}
                onSelectItem={setSelectedItem}
                onAddOrDeleteRow={addOrDeleteRow}
                onWebFormConfigUpdate={(configs): void => {
                  setUndoItems((items) =>
                    insertItemInArray(items.length, items, webformConfigs)
                  );
                  setWebformConfigs(configs);
                }}
              />
            </div>
          </div>
        )}
        {isUploadOpen && (
          <KeyTermsUpload
            onSubmit={onUpload}
            visible={isUploadOpen}
            onClose={(): void => setIsUploadOpen(false)}
            loading={isUploadLoading}
            portfolioId={portfolioId}
            webformId={sectionId}
          />
        )}
      </div>
    </div>
  );
};

type ParamsType = {
  sectionId: string;
  portfolioId: string;
};

type onStyleChangeType = (style: KeyValueType) => void;
type onUpdateRowType = (row: PortfolioBidMetricType, index: number) => void;
type undoOrRedoType = (
  removeArray: Array<Array<PortfolioBidMetricType>>,
  insertArray: Array<Array<PortfolioBidMetricType>>,
  insertCallback: Dispatch<
    SetStateAction<Array<Array<PortfolioBidMetricType>>>
  >,
  dropCallback: Dispatch<SetStateAction<Array<Array<PortfolioBidMetricType>>>>
) => void;
type addOrDeleteRowType = (
  key: RightClickActionType,
  id: string,
  index: number
) => void;
type positionIncrementAtIndexType = (
  config: PortfolioBidMetricType,
  index: number,
  configs: Array<PortfolioBidMetricType>
) => Array<PortfolioBidMetricType>;
type onAddRowType = (
  position: string,
  config: Array<PortfolioBidMetricType>
) => void;
type downloadFileType = (webFormId: string) => void;
