import { KeyboardEvent } from "react";
import {
  CountryCode,
  isValidNumber,
  parse,
  ParsedNumber,
} from "libphonenumber-js";
import { valOrDefault } from "./utils";
import { ValidatorRule } from "rc-field-form/lib/interface";

/**
 * Rule for the required Form Item
 */
export const formItemRequiredRule = {
  required: true,
  message: `Required`,
};

/**
 * To Validate the naming convention
 * */
export const namePatternRule = (): ValidatorRule => ({
  message: "Invalid Characters In Name",
  validator: (_, value): Promise<void> => {
    if (value && new RegExp(/^[ 'a-zA-ZÀ-ÿ\u00f1\u00d1.-]*$/g).test(value)) {
      return Promise.resolve();
    } else return Promise.reject();
  },
});

/**
 * To Validate the phone number
 * @param required Param to make sure whether the empty phone number could be passed or not
 */
export const phoneNumberPatternRule = (required = false): ValidatorRule => ({
  message: "Invalid Phone Number",
  validator: (_, value): Promise<void> => {
    if (
      (!required && !value.phone) ||
      isValidNumber(`${value.code}${value.phone}`, value?.short ?? "GB")
    )
      return Promise.resolve();
    else return Promise.reject();
  },
});

/**
 * Reverse Parse the phone number from string to an object for the PhoneNumberInput component value
 * @param phoneNumber phone number string
 */
export const reverseParsePhoneNumber = (
  phoneNumber: string | null
): PhoneNumber => {
  const phoneNumberData: ParsedNumber = parse(valOrDefault("", phoneNumber), {
    extended: true,
  });
  return {
    short: valOrDefault("GB", phoneNumberData.country),
    code: valOrDefault(44, Number(phoneNumberData.countryCallingCode)),
    phone: valOrDefault("", phoneNumberData.phone),
  };
};

/**
 * Parse the phone number from PhoneNumber object to string
 * @param phoneNumber Phone Number Object
 */
export const parsePhoneNumber = (phoneNumber: PhoneNumber): string | null => {
  const phoneNumberData: ParsedNumber = parse(
    valOrDefault(
      "",
      !phoneNumber.phone ? null : `+${phoneNumber.code}${phoneNumber.phone}`
    ),
    {
      extended: true,
    }
  );
  return phoneNumberData?.phone
    ? `+${phoneNumberData.countryCallingCode}${phoneNumberData.phone}`
    : null;
};

/**
 * Submit form on ENTER
 * @param event
 * @param callback function to call on ENTER
 */
export const formSubmitOnEnter = (
  event: KeyboardEvent<HTMLFormElement>,
  callback: void
): void => {
  if (event.code === "Enter" || event.code === "NumpadEnter") callback;
};

/**
 * PhoneNumber for PhoneNumberInput component
 */
export type PhoneNumber = {
  short: CountryCode;
  code: string;
  phone: string;
};

/**
 * Returns a validation rule that checks whether the entered input is a valid JSON string.
 * If the input is not valid JSON, the validation will fail.
 * @returns A validation rule object with a message and a validator function.
 */
export const isJsonRule = (): ValidatorRule => ({
  message: "Please enter a valid JSON string",
  validator: (_, value): Promise<void> => {
    if (value) {
      try {
        JSON.parse(value);
        return Promise.resolve();
      } catch (err) {
        return Promise.reject();
      }
    }
    return Promise.reject();
  },
});
