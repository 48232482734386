import React, { FC, ReactElement, useCallback } from "react";
import { Dropdown, Menu } from "antd";
import { RightClickActionType } from "../../../utils/termsheet";

const Row: FC<RowType> = function ({
  id,
  index,
  allowRightClick,
  children,
  onAddOrDeleteRow,
}: RowType) {
  const onRightClick = (e: any) => {
    onAddOrDeleteRow &&
      onAddOrDeleteRow(
        e.key as RightClickActionType,
        id,
        e.key === "ADD_BELOW" ? index + 1 : index
      );
  };

  return allowRightClick ? (
    <Dropdown
      key={id}
      overlay={
        <Menu onClick={onRightClick}>
          <Menu.Item key={"ADD_ABOVE"}>Add Above</Menu.Item>
          <Menu.Item key={"ADD_BELOW"}>Add Below</Menu.Item>
          <Menu.Item key={"DELETE"}>Delete Row</Menu.Item>
        </Menu>
      }
      trigger={["contextMenu"]}
    >
      {children}
    </Dropdown>
  ) : (
    children
  );
};

type RowType = {
  id: string;
  index: number;
  allowRightClick?: boolean;
  children: ReactElement;
  onAddOrDeleteRow?: (
    key: RightClickActionType,
    id: string,
    index: number
  ) => void;
};
export default Row;
