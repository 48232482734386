import React, { ReactElement } from "react";

export const Terms = (): ReactElement => {
  return (
    <div className="text-sm h-72 overflow-y-scroll hide-scrollbar border shadow-inner p-1.5">
      <p>
        Welcome to Termgrid. Please read on to learn the rules and restrictions
        that govern your use of our platform and services available via the
        platform (the “Services”). If you have any questions, comments, or
        concerns regarding these terms or the Services, please contact us
        at&nbsp;
        <a
          href="mailto:support@termgrid.com text-base"
          className="text-primary font-bold underline"
        >
          support@termgrid.com
        </a>
        .
      </p>
      <br />
      <p>
        You must agree to and accept all of these Terms of Service (the
        “Terms”), or you don’t have the right to use the Services. Your using
        the Services in any way means that you agree to and accept all of these
        Terms, which form a binding contract between you and Termgrid, Inc.
        (“Termgrid,” “we” and “us”), D/B/A Termgrid. These Terms will remain in
        effect while you use the Services. These Terms include the provisions in
        this document, as well as those in our Privacy Policy, which also
        governs your use of the Services.
      </p>
      <br />
      <p>
        These Terms apply to you in whatever capacity you access and use the
        Services – whether you are accessing the Services on behalf of your
        organization, have been invited to access the Services by a client of
        ours, or otherwise.
      </p>
      <p className={"text-xl mt-5 font-semibold mb-1"}>Confidentiality</p>
      <p>
        The information contained on the Termgrid platform is confidential. As a
        condition to receiving access to the platform, you agree to treat all
        information which is contained herein as confidential, unless and until
        such information has been made generally available to the public or
        unless the relevant owner of the information agrees in writing to remove
        this restriction. The information contained on the platform may not, in
        whole or part, be reproduced or distributed to others save as expressly
        permitted in these Terms.
      </p>
      <br />
      <p>
        Furthermore, you agree that the information included herein shall be
        used solely for the purpose of evaluating the transaction you are
        concerned with. Nothing herein shall limit or impair your right to
        disclose information, if legally required to do so, in any judicial,
        administrative or governmental proceeding, subject to your prior
        notification of the relevant owner of the information.
      </p>
      <br />
      <p>
        Your obligations of confidentiality and non-disclosure may also be set
        forth in more detail in a separate agreement and nothing in this
        platform will be deemed to supersede or modify your obligations under
        that agreement. If you do not believe you are bound by such a
        non-disclosure agreement, you must exit the platform now.
      </p>
      <p className={"text-xl mt-5 font-semibold mb-1"}>
        Will these Terms ever change?
      </p>
      <p>
        We are constantly trying to improve our Services, so these Terms may
        need to change along with the Services. We reserve the right to change
        the Terms at any time, but if we do, we will bring it to your attention
        by placing a notice on the&nbsp;
        <a
          className="text-primary font-bold underline"
          href="https://www.termgrid.com"
        >
          www.termgrid.com
        </a>
        &nbsp;website, by sending you an email, and/or by some other
        means.&nbsp;
      </p>
      <br />
      <p>
        If you don’t agree with the new Terms, you are free to reject them;
        unfortunately, that means you will no longer be able to use the
        Services. If you use the Services in any way after a change to the Terms
        is effective, that means you agree to all of the changes. Except for
        changes by us as described here, no other amendment or modification of
        these Terms will be effective unless in writing and signed by both you
        and us.&nbsp;
      </p>
      <p className={"text-xl mt-5 font-semibold mb-1"}>
        What are the basics of using Termgrid?
      </p>
      <p>
        You may be required to sign up for an account and select a password and
        username in order to access the Services (“User ID”). You promise to
        provide us with accurate, complete, and updated registration information
        about yourself. You may not select as your User ID a name that you don’t
        have the right to use, or another person’s name with the intent to
        impersonate that person. You may not transfer, or give access to, your
        account to anyone else without our prior written permission.
      </p>
      <br />
      <p>
        Unless we otherwise agree, you will only use the Services for your
        internal use, and not on behalf of or for the benefit of any third
        party, and only in a manner that complies with these Terms and all laws
        that apply to you. If your use of the Services is prohibited by
        applicable laws, then you aren’t authorized to use the Services. We are
        not responsible for your using the Services in any way that breaks the
        law. You will not share your account or password with anyone, and you
        must protect the security of your account and your password. You are
        responsible for any activity associated with your account.
      </p>
      <p className={"text-xl mt-5 font-semibold mb-1"}>
        Your use of the Services is subject to the following additional
        restrictions:
      </p>
      <p>
        You agree that you will not contribute any Content or User Submission
        (each of those terms is defined below) or otherwise use the Services or
        interact with the Services in a manner that:
      </p>
      <br />
      <ul className="list-disc ml-3 m-0">
        <li>
          Infringes or violates the intellectual property rights or any other
          rights of anyone else (including Termgrid);
        </li>
        <li>
          Violates any law or regulation, including, without limitation, any
          applicable export control laws;
        </li>
        <li>
          Is harmful, fraudulent, deceptive, threatening, harassing, defamatory,
          obscene, or otherwise objectionable;
        </li>
        <li>
          Jeopardizes the security of your Termgrid account or anyone else’s
          (such as allowing someone else to log in to the Services as you);
        </li>
        <li>
          Attempts, in any manner, to obtain the password, account, or other
          security information from any other user;
        </li>
        <li>
          Violates the security of any computer network, or cracks any passwords
          or security encryption codes;
        </li>
        <li>
          Runs Maillist, Listserv, any form of auto-responder or “spam” on the
          Services, or any processes that run or are activated while you are not
          logged into the Services, or that otherwise interfere with the proper
          working of the Services (including by placing an unreasonable load on
          the Services’ infrastructure);
        </li>
        <li>
          “Crawls,” “scrapes,” or “spiders” any page, data, or portion of or
          relating to the Services or Content (through use of manual or
          automated means);
        </li>
        <li> Copies or stores any significant portion of the Content;</li>
        <li>
          Decompiles, reverse engineers, or otherwise attempts to obtain the
          source code or underlying ideas or information of or relating to the
          Services.
        </li>
      </ul>
      <br />
      <p>
        A violation of any of the foregoing is grounds for termination of your
        right to use or access the Services.
      </p>
      <p className={"text-xl mt-5 font-semibold mb-1"}>
        What are my rights in the Services?
      </p>
      <p>
        The materials displayed or performed or available on or through the
        Services, including, but not limited to, text, graphics, data, articles,
        photos, images, illustrations, User Submissions, and so forth (all of
        the foregoing, the “Content”) are protected by copyright and/or other
        intellectual property laws. You promise to abide by all copyright
        notices, trademark rules, information, and restrictions contained in any
        Content you access through the Services, and you won’t use, copy,
        reproduce, modify, translate, publish, broadcast, transmit, distribute,
        perform, upload, display, license, sell, commercialize or otherwise
        exploit for any purpose any Content not owned by you, (i) without the
        prior consent of the owner of that Content or (ii) in a way that
        violates someone else’s (including Termgrid’s) rights.
      </p>
      <br />
      <p>
        You understand that Termgrid owns the Services. You won’t modify,
        publish, transmit, participate in the transfer or sale of, reproduce
        (except as expressly provided in this Section), create derivative works
        based on, or otherwise exploit any of the Services.
      </p>
      <br />
      <p>
        The Services may allow you to copy or download certain Content; please
        remember that just because this functionality exists, doesn’t mean that
        all the restrictions above don’t apply – they do! If the functionality
        is not available to you, you don’t have the right to copy (through any
        medium) or download Content.
      </p>
      <p className={"text-xl mt-5 font-semibold mb-1"}>
        Do I have to grant any licenses to Termgrid or to other users?
      </p>
      <p>
        Anything you post, upload, share, store, or otherwise provide through
        the Services is your “User Submission.” Some User Submissions may be
        viewable by other users. In order to display your User Submissions on
        the Services, and to allow other users to enjoy them (where applicable),
        you grant us certain rights in those User Submissions. Please note that
        all of the following licenses are subject to our Privacy Policy to the
        extent they relate to User Submissions that include
        personally-identifiable information.
      </p>
      <br />
      <p>
        For all User Submissions, you hereby grant Termgrid a license to
        translate, modify (for technical purposes, for example making sure your
        content is viewable on an iPhone as well as a computer) and reproduce
        and otherwise act with respect to such User Submissions, in each case to
        enable us to operate the Services, as described in more detail below.
        This is a license only – your ownership in User Submissions is not
        affected.
      </p>
      <br />
      <p>
        If you store a User Submission in your own personal Termgrid account, in
        a manner that is not viewable by any other user except you (a “Personal
        User Submission”), you grant Termgrid the license above, as well as a
        license to display, perform, and distribute your Personal User
        Submission for the sole purpose of making that Personal User Submission
        accessible to you and providing the Services necessary to do so.
      </p>
      <br />
      <p>
        If you share a User Submission only in a manner that only certain
        specified users can view (for example, a private message to one or more
        other users) (a “Limited Audience User Submission”), then you grant
        Termgrid the licenses above, as well as a license to display, perform,
        and distribute your Limited Audience User Submission for the sole
        purpose of making that Limited Audience User Submission accessible to
        such other specified users, and providing the Services necessary to do
        so. Also, you grant such other specified users a license to access that
        Limited Audience User Submission, and to use and exercise all rights in
        it, as permitted by the functionality of the Services. You are solely
        responsible for checking and confirming the accuracy of the specified
        users being granted access to such Limited Audience User Submission.
      </p>
      <br />
      <p>
        If you share a User Submission publicly on the Services and/or in a
        manner that more than just you or certain specified users can view, or
        if you provide us (in a direct email or otherwise) with any feedback,
        suggestions, improvements, enhancements, and/or feature requests
        relating to the Services (each of the foregoing, a “Public User
        Submission”), then you grant Termgrid the licenses above, as well as a
        license to display, perform, and distribute your Public User Submission
        for the purpose of making that Public User Submission accessible to all
        Termgrid users and providing the Services necessary to do so, as well as
        all other rights necessary to use and exercise all rights in that Public
        User Submission in connection with the Services and/or otherwise in
        connection with Termgrid’s business for any purpose, provided that
        Termgrid will try to notify you if it uses your Public User Submission
        for any reason other than displaying it on the Services. Also, you grant
        all other users of the Services a license to access that Public User
        Submission, and to use and exercise all rights in it, as permitted by
        the functionality of the Services.
      </p>
      <br />
      <p>
        You agree that the licenses you grant are royalty-free, perpetual,
        sublicensable, irrevocable, and worldwide, provided that when you delete
        your Termgrid account, we will stop displaying your User Submissions
        (other than Public User Submissions, which may remain fully available)
        to other users (if applicable), but you understand and agree that it may
        not be possible to completely delete that content from Termgrid’s
        records, and that your User Submissions may remain viewable elsewhere to
        the extent that they were copied or stored by other users.
      </p>
      <br />
      <p>
        Finally, you understand and agree that Termgrid, in performing the
        required technical steps to provide the Services to our users (including
        you), may need to make changes to your User Submissions to conform and
        adapt those User Submissions to the technical requirements of connection
        networks, devices, services, or media, and the foregoing licenses
        include the rights to do so.
      </p>
      <p className={"text-xl mt-5 font-semibold mb-1"}>
        Who is responsible for what I see and do on the Services?
      </p>
      <p>
        Any information or content publicly posted or privately transmitted
        through the Services is the sole responsibility of the person from whom
        such content originated, and you access all such information and content
        at your own risk. We aren’t liable for any errors, inaccuracies or
        omissions in that information or content or for any damages or loss you
        might suffer in connection with it. We cannot control and have no duty
        to take any action regarding how you may interpret and use the Content
        or what actions you may take as a result of having been exposed to the
        Content, and you hereby release us from all liability that may arise
        resulting from you having acquired or not acquired Content through the
        Services. We can’t guarantee the identity of any users with whom you
        interact in using the Services and are not responsible for which users
        gain access to the Services.
      </p>
      <br />
      <p>
        You are responsible for all Content you contribute, in any manner, to
        the Services, and you represent and warrant you have all rights
        necessary to do so, in the manner in which you contribute it. You will
        keep all your registration information accurate and current. You are
        responsible for all your activity in connection with the Services.
      </p>
      <br />
      <p>
        The Services may contain links or connections to third party websites or
        services that are not owned or controlled by Termgrid. When you access
        third party websites or use third party services, you accept that there
        are risks in doing so, and that Termgrid is not responsible for such
        risks. We encourage you to be aware when you leave the Services and to
        read the terms and conditions and privacy policy of each third party
        website or service that you visit or utilize. Termgrid has no control
        over, and assumes no responsibility for, the content, accuracy, privacy
        policies, or practices of or opinions expressed in any third party
        websites or by any third party that you interact with through the
        Services. In addition, Termgrid will not and cannot monitor, verify,
        censor or edit the content of any third party site or service. By using
        the Services, you release and hold us harmless from any and all
        liability arising from your use of any third party website or service.
      </p>
      <br />
      <p>
        Your interactions with organizations and/or individuals found on or
        through the Services, including payment and delivery of goods or
        services, and any other terms, conditions, warranties or representations
        associated with such dealings, are solely between you and such
        organizations and/or individuals. You should make whatever investigation
        you feel necessary or appropriate before proceeding with any online or
        offline transaction with any of these third parties. You agree that
        Termgrid shall not be responsible or liable for any loss or damage of
        any sort incurred as the result of any such dealings.
      </p>
      <br />
      <p>
        If there is a dispute between participants on this site, or between
        users and any third party, you agree that Termgrid is under no
        obligation to become involved. In the event that you have a dispute with
        one or more other users, you release Termgrid, its directors, officers,
        employees, agents, and successors from claims, demands, and damages of
        every kind or nature, known or unknown, suspected or unsuspected,
        disclosed or undisclosed, arising out of or in any way related to such
        disputes and/or our Services. If you are a California resident, you
        shall and hereby do waive California Civil Code Section 1542, which
        says: “A general release does not extend to claims which the creditor
        does not know or suspect to exist in his favor at the time of executing
        the release, which, if known by him must have materially affected his
        settlement with the debtor.”
      </p>
      <p className={"text-xl mt-5 font-semibold mb-1"}>
        Will Termgrid ever change the Services?
      </p>
      <p>
        We’re always trying to improve the Services, so they may change over
        time. We may suspend or discontinue any part of the Services, or we may
        introduce new features or impose limits on certain features or restrict
        access to parts or all of the Services. We reserve the right to remove
        any Content from the Services at any time, for any reason (including,
        but not limited to, if someone alleges you contributed that Content in
        violation of these Terms), in our sole discretion, and without notice.
      </p>
      <p className={"text-xl mt-5 font-semibold mb-1"}>
        What if I want to stop using the Services?
      </p>
      <p>
        You’re free to do that at any time, by contacting us at&nbsp;
        <a
          className="text-primary font-bold underline"
          href="mailto:support@termgrid.com"
        >
          support@termgrid.com
        </a>
        ; please refer to our Privacy Policy, as well as the licenses above, to
        understand how we treat information you provide to us after you have
        stopped using our Services.
      </p>
      <br />
      <p>
        Termgrid is also free to terminate (or suspend access to) your use of
        the Services or your account, for any reason in our discretion,
        including your breach of these Terms. Termgrid has the sole right to
        decide (but will acting reasonably in doing so) whether you are in
        violation of any of the restrictions set forth in these Terms.
      </p>
      <br />
      <p>
        Account termination may result in destruction of any Content associated
        with your account, so keep that in mind before you decide to terminate
        your account. We will try to provide advance notice to you prior to our
        terminating your account so that you are able to retrieve any important
        User Submissions you may have stored in your account (to the extent
        allowed by law and these Terms), but we may not do so if we determine it
        would be impractical, illegal, not in the interest of someone’s safety
        or security, or otherwise harmful to the rights or property of Termgrid.
      </p>
      <br />
      <p>
        Provisions that, by their nature, should survive termination of these
        Terms shall survive termination. By way of example, all of the following
        will survive termination: any obligation you have to pay us or indemnify
        us, any limitations on our liability, any terms regarding ownership or
        intellectual roperty rights, and terms regarding disputes between us.
      </p>
      <p className={"text-xl mt-5 font-semibold mb-1"}>
        What else do I need to know?
      </p>
      <p>
        <em>Warranty Disclaimer</em>. Neither Termgrid nor its licensors or
        suppliers make any representations or warranties concerning any content
        contained in or accessed through the Services, and we will not be
        responsible or liable for the accuracy, copyright compliance, legality,
        or decency of material contained in or accessed through the Services. We
        (and our licensors and suppliers) make no representations or warranties
        regarding suggestions or recommendations of services or products offered
        or purchased through the Services. Products and services purchased or
        offered (whether or not following such recommendations and suggestions)
        through the Services are provided “AS IS” and without any warranty of
        any kind from Termgrid or others (unless, with respect to such others
        only, provided expressly and unambiguously in writing by a designated
        third party for a specific product). THE SERVICES AND CONTENT ARE
        PROVIDED BY TERMGRID (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS”
        BASIS, WITHOUT WARRANTIES OR ANY KIND, EITHER EXPRESS OR IMPLIED,
        INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE
        SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW
        LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE
        LIMITATIONS MAY NOT APPLY TO YOU.
      </p>
      <br />
      <p>
        <em>Limitation of Liability</em>. TO THE FULLEST EXTENT ALLOWED BY
        APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY
        (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR
        OTHERWISE) SHALL TERMGRID (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO
        YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR
        CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS,
        LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER
        FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS
        OF THE LESSER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO TERMGRID IN
        CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING
        THIS APPLICABLE CLAIM, OR (C) ANY MATTER BEYOND OUR REASONABLE CONTROL.
        SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES,
        SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
      </p>
      <br />
      <p>
        Please note, different limitations and exclusions may apply under the
        agreement we have in place with your organization. The terms of that
        agreement are not affected by these Terms.
      </p>
      <br />
      <p>
        <em>Indemnity</em>. To the fullest extent allowed by applicable law, You
        agree to indemnify and hold Termgrid, its affiliates, officers, agents,
        employees, and partners harmless from and against any and all claims,
        liabilities, damages (actual and consequential), losses and expenses
        (including attorneys’ fees) arising from or in any way related to any
        third party claims relating to (a) your use of the Services (including
        any actions taken by a third party using your account), and (b) your
        violation of these Terms. In the event of such a claim, suit, or action
        (“Claim”), we will attempt to provide notice of the Claim to the contact
        information we have for your account (provided that failure to deliver
        such notice shall not eliminate or reduce your indemnification
        obligations hereunder).
      </p>
      <br />
      <p>
        <em>Assignment.</em> You may not assign, delegate or transfer these
        Terms or your rights or obligations hereunder, or your Services account,
        in any way (by operation of law or otherwise) without Termgrid’s prior
        written consent. We may transfer, assign, or delegate these Terms and
        our rights and obligations without consent.
      </p>
      <br />
      <p>
        <em>Choice of Law</em>. These Terms are governed by and will be
        construed under the Federal Arbitration Act, applicable federal law, and
        the laws of the State of New York, without regard to the conflicts of
        laws provisions thereof. All disputes arising out of or in connection
        with these Terms shall be finally settled under the Rules of Arbitration
        of the International Chamber of Commerce by three arbitrators appointed
        in accordance with the said Rules.
      </p>
      <br />
      <p>
        <em>Miscellaneous</em>. You will be responsible for paying, withholding,
        filing, and reporting all taxes, duties, and other governmental
        assessments associated with your activity in connection with the
        Services, provided that the Termgrid may, in its sole discretion, do any
        of the foregoing on your behalf or for itself as it sees fit. The
        failure of either you or us to exercise, in any way, any right herein
        shall not be deemed a waiver of any further rights hereunder. If any
        provision of these Terms is found to be unenforceable or invalid, that
        provision will be limited or eliminated, to the minimum extent
        necessary, so that these Terms shall otherwise remain in full force and
        effect and enforceable. You and Termgrid agree that these Terms are the
        complete and exclusive statement of the mutual understanding between you
        and Termgrid, and that these Terms supersede and cancel all previous
        written and oral agreements, communications and other understandings
        relating to the subject matter of these Terms. You hereby acknowledge
        and agree that you are not an employee, agent, partner, or joint venture
        of Termgrid, and you do not have any authority of any kind to bind
        Termgrid in any respect whatsoever.
      </p>
      <br />
      <p>
        Except as expressly set forth in these Terms, you and Termgrid agree
        there are no third party beneficiaries intended under these Terms.
      </p>
    </div>
  );
};
