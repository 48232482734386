import React, { FC, memo, useMemo } from "react";
import { Input } from "antd";
import { renameStyleProperties } from "../../../utils/termsheet";
import { valOrDefault } from "../../../utils/utils";
import RedLineContent from "../../termsheet/common/RedLineContent";

const { TextArea } = Input;

const PrecedentSearchCell: FC<CellType> = function ({
  text = "",
  className = "",
  style = JSON.stringify({}),
  showRedLine = false,
  compareText = "",
}) {
  const formattedStyle: StyleType = useMemo(
    () => renameStyleProperties(JSON.parse(style)),
    [style]
  );

  const cellStyle = useMemo(
    () => ({
      color: "rgba(0, 0, 0, 0.85)",
      ...formattedStyle,
      background: valOrDefault("transparent", formattedStyle["background"]),
    }),
    [formattedStyle]
  );

  return (
    <div
      style={{
        ...formattedStyle,
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
      }}
      className={`relative min-h-full h-full group overflow-hidden ${className}`}
    >
      {showRedLine ? (
        <RedLineContent
          text={text}
          style={cellStyle}
          compareText={compareText}
        />
      ) : (
        <TextArea
          disabled={true}
          autoSize={true}
          bordered={false}
          className={`z-10 min-h-full hide-scrollbar rounded-none py-1 resize-none border-none outline-none ${className}`}
          style={{ paddingLeft: 0, paddingRight: 0, ...cellStyle }}
          value={text}
        />
      )}
    </div>
  );
};

type CellType = {
  text: string;
  style?: string;
  className?: string;
  showRedLine?: boolean;
  compareText?: string;
};

type StyleType = { [k: string]: string };

export default memo(PrecedentSearchCell);
