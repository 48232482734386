import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  CompanyModuleAccessType,
  CompanyModuleType,
  UserType,
} from "../utils/types";
import { getModulePreferenceByCompany, getUser } from "../services/services";
import { ResponseType } from "../utils/uiTypes";
import { useHistory } from "react-router";
import { loginRedirect } from "../utils/redirect";

import { DEFAULT_COMPANY_MODULE_CONFIG } from "../utils/module-util";
export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {
    return;
  },
  companyModulePreference: DEFAULT_COMPANY_MODULE_CONFIG,
  setCompanyModulePreference: () => {
    return;
  },
});

export const UserProvider: FC<UserProviderType> = function ({
  children,
}: UserProviderType) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [companyModulePreference, setCompanyModulePreference] =
    useState<CompanyModuleType | null>(null);
  const [user, setUser] = useState<UserType | null>(null);

  const getModulePreference: getModulePreferenceType = (id) => {
    getModulePreferenceByCompany({
      segments: {
        id,
      },
    }).then(({ data }: ResponseType<CompanyModuleAccessType>) => {
      setCompanyModulePreference(data.moduleAccessDTO);
    });
  };

  const identifyAppCues = ({ userId, lastName, firstName }: UserType) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Appcues.identify(
      userId, // unique, required
      {
        // recommended (optional) properties
        accountId: userId, // Current user's account ID
        firstName: firstName, // current user's first name
        lastName: lastName, // current user's last name
      }
    );
  };

  useEffect(() => {
    if (user?.userId) {
      user?.companyId
        ? getModulePreference(user.companyId)
        : setCompanyModulePreference(DEFAULT_COMPANY_MODULE_CONFIG);
    }
  }, [user?.userId, user?.companyId, user?.sms2FaFlowCompleted]);

  useEffect(() => {
    getUser({})
      .then(({ data }: ResponseType<UserType>) => {
        setUser(data);
        identifyAppCues(data);
      })
      .then(() => {
        setLoading(false);
        if (user) {
          history?.push(loginRedirect(user));
        }
      })
      .catch((error: string) => {
        console.error(error);
      })
      .then(() => {
        setLoading(false);
        if (user) {
          history?.push(loginRedirect(user));
        }
      });
  }, []);

  return (
    <>
      <UserContext.Provider
        value={{
          user,
          setUser,
          companyModulePreference,
          setCompanyModulePreference,
        }}
      >
        {!loading && children}
      </UserContext.Provider>
    </>
  );
};

type UserProviderType = {
  children: ReactNode;
};

type UserContextType = {
  user: null | UserType;
  setUser: Dispatch<SetStateAction<UserType | null>>;
  companyModulePreference: null | CompanyModuleType;
  setCompanyModulePreference: (c: CompanyModuleType | null) => void;
};

type getModulePreferenceType = (id: string) => void;
