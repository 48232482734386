import React, { FC, useState } from "react";
import { Button, Modal, Tooltip } from "antd";
import ButtonGroup from "antd/es/button/button-group";
import { LenderType } from "../../../../utils/types";
import { TransactionTags } from "../../TransactionTags";
import { valOrDefault } from "../../../../utils/utils";

export const ShareOptions: FC<ShareOptionsType> = function ({
  lenders,
  onSubmit,
  shareWith,
  isFolder,
}: ShareOptionsType) {
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [checkedList, setCheckedList] = useState<string[]>([]);

  return (
    <>
      <Modal
        width={"50%"}
        open={shareModal}
        destroyOnClose={true}
        footer={
          isFolder && (
            <span className={"text-left block text-xs text-gray-500"}>
              NOTE: <br />
              Please note that all documents added to this folder in the future
              will also be automatically shared with the selected institutions.
            </span>
          )
        }
        onCancel={(): void => {
          setShareModal(false);
          setCheckedList(valOrDefault([], shareWith));
        }}
        title={
          <>
            Share {isFolder ? "Folder" : "File"}
            <br />
          </>
        }
      >
        <TransactionTags
          lenders={lenders}
          checkedList={checkedList}
          setCheckedList={setCheckedList}
          shareWith={shareWith}
        />
        <ButtonGroup className={"mt-6 w-full flex flex-row gap-x-2"}>
          <Button
            className={"w-full"}
            onClick={(): void => {
              setShareModal(false);
              // setCheckedList([]);
              // setCheckAll(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className={"w-full bg-primary hover:bg-hover text-white border-0"}
            onClick={(): void => {
              onSubmit(checkedList);
              setShareModal(false);
            }}
          >
            Share
          </Button>
        </ButtonGroup>
      </Modal>
      <Tooltip title={isFolder ? "Share Folder" : "Share File"}>
        <Button
          className={
            "border-0 shadow bg-white text-gray-500 hover:border-primary hover:text-primary hover:border"
          }
          onClick={(): void => {
            setShareModal(true);
          }}
        >
          Share
        </Button>
      </Tooltip>
    </>
  );
};

type ShareOptionsType = {
  lenders: LenderType[];
  shareWith: string[];
  isFolder: boolean;
  onSubmit: (o: string[]) => void;
};
