import { NotificationActivityEnumType } from "./enums";
import React, { ReactNode } from "react";
import { Avatar } from "antd";
import { getColorPallet } from "./colors";
import {
  FileOutlined,
  FolderOutlined,
  UndoOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { convertDateToFormat } from "./moment";
import { concat, join, pathOr, split } from "ramda";
import { removeMultipleSpaces } from "./utils";

const getNameLiterals = (name: string) => {
  const [firstName = "", lastName = ""] = split(
    " ",
    removeMultipleSpaces(name)
  );
  return (
    pathOr<string, string>("", [0], removeMultipleSpaces(firstName)) +
    pathOr<string, string>("", [0], removeMultipleSpaces(lastName))
  ).toUpperCase();
};

export const getActivityTypeSubHeader: getActivitySubHeaderType = (
  activityType,
  lenderTitle,
  creatorName,
  company,
  createDate,
  sectionName
) => {
  switch (activityType) {
    case NotificationActivityEnumType.NOTIFICATION_FOR_COMMENT:
      if (lenderTitle && lenderTitle !== company) {
        return join(" ", [
          removeMultipleSpaces(creatorName),
          company,
          ".",
          lenderTitle,
          "Thread",
        ]);
      } else if (!lenderTitle) {
        return join(" ", [
          removeMultipleSpaces(creatorName),
          company,
          "· General Thread ·",
          convertDateToFormat(createDate, "DD.MMM.YYYY - HH:mma", false),
        ]);
      } else {
        return join(" ", [
          removeMultipleSpaces(creatorName),
          "(",
          lenderTitle,
          ")",
          ".",
          lenderTitle,
          "Thread",
          ".",
          convertDateToFormat(createDate, "DD.MMM.YYYY - HH:mma", false),
        ]);
      }
    case NotificationActivityEnumType.NOTIFICATION_FOR_CREATE_FILE:
      return join(" ", [
        removeMultipleSpaces(creatorName),
        "(",
        company,
        ")",
        ".",
        convertDateToFormat(createDate, "DD.MMM.YYYY - HH:mma", false),
      ]);
    case NotificationActivityEnumType.NOTIFICATION_FOR_TASK_ASSIGNED:
      return join(" ", [
        removeMultipleSpaces(creatorName),
        "-",
        sectionName,
        "Section -",
        convertDateToFormat(createDate, "DD.MMM.YYYY - HH:mma", false),
      ]);
    case NotificationActivityEnumType.NOTIFICATION_FOR_CREATE_NOTE:
    case NotificationActivityEnumType.NOTIFICATION_FOR_UPDATE_NOTE:
      return join(" ", [
        removeMultipleSpaces(creatorName),
        "-",
        convertDateToFormat(createDate, "DD.MMM.YYYY - HH:mma", false),
      ]);
    default:
      return removeMultipleSpaces(creatorName);
  }
};

export const CELL_STYLE = "py-2.5 px-4 align-top";

/*
--------------------------------------
AKSHANT WILL UPDATE THE FUNCTION BELOW:
--------------------------------------
*/
export const getNotificationIcon: getNotificationIconType = (
  activityType,
  creatorName,
  details,
  userName,
  title,
  propertyId,
  lenderTitle,
  dashboard = false
) => {
  const ICON_CSS = `absolute ${dashboard ? "top-1" : "top-4"} text-blue-400`;

  switch (activityType) {
    case NotificationActivityEnumType.NOTIFICATION_FOR_COMMENT:
      return (
        <Avatar
          className={`${ICON_CSS} ${
            propertyId
              ? getColorPallet(creatorName?.charAt(0), "bg")
              : "bg-white border border-blue-400"
          }`}
          size="small"
        >
          <span className={`${propertyId && "text-white"}`}>
            {getNameLiterals(creatorName)}
          </span>
        </Avatar>
      );
    case NotificationActivityEnumType.NOTIFICATION_FOR_CREATE_FILE:
      return details ? (
        <FolderOutlined className={`${ICON_CSS}`} />
      ) : (
        <FileOutlined className={`${ICON_CSS}`} />
      );
    case NotificationActivityEnumType.NOTIFICATION_FOR_TASK_ASSIGNED:
      if (!userName) {
        return (
          <Avatar
            className={`${ICON_CSS} bg-transparent border border-blue-400`}
            size="small"
          >
            {getNameLiterals(creatorName)}
          </Avatar>
        );
      }
      return userName && details ? (
        <UnorderedListOutlined className={`${ICON_CSS}`} />
      ) : (
        <UndoOutlined className={`${ICON_CSS}`} />
      );
    case NotificationActivityEnumType.NOTIFICATION_FOR_CREATE_NOTE:
    case NotificationActivityEnumType.NOTIFICATION_FOR_SHARED_NOTE:
      return <FileOutlined className={`${ICON_CSS}`} />;
    default:
      if (!title && details) {
        return <UploadOutlined className={`${ICON_CSS}`} color={"blue"} />;
      } else if (!title && !details) {
        return <UndoOutlined className={`${ICON_CSS}`} />;
      }
      return (
        <Avatar
          className={
            `${ICON_CSS} ` +
            (!lenderTitle && `bg-transparent border border-blue-400`)
          }
          size="small"
        >
          {getNameLiterals(creatorName)}
        </Avatar>
      );
  }
};

type getNotificationIconType = (
  activityType: string,
  creatorName: string,
  details?: string,
  userName?: string,
  title?: string,
  propertyId?: string,
  lenderTitle?: string,
  dashboard?: boolean
) => ReactNode;

type getActivitySubHeaderType = (
  activityType: NotificationActivityEnumType,
  lenderTitle: string,
  creatorName: string,
  company: string,
  createDate: string,
  sectionName: string
) => string | undefined;
