import React, {
  FC,
  HTMLAttributes,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CommentType,
  taskAssigneesDTO,
  TaskDTOType,
  TaskType,
  UserType,
} from "../../../../utils/types";
import {
  addComment,
  addNewSection,
  addNewTask,
  changeTaskStatus,
  cloneTasks,
  deleteCurrentTask,
  exportTasks,
  getComments,
  getDealTeam,
  getTasks,
  updateCurrentTask,
} from "../../../../services/services";
import {
  Alert,
  Avatar,
  Button,
  Comment,
  Drawer,
  message,
  Modal,
  Popover,
  Select,
  Table,
  Tag,
} from "antd";
import { CustomAvatar } from "../../../general/CustomAvatar";
import {
  findItemByPropertyValue,
  populateArrayWithPropertyPath,
  removeItemFromArray,
  updateItemInArray,
  valOrDefault,
} from "../../../../utils/utils";
import moment, { Moment } from "moment";
import { CloneTasks } from "./CloneTasks";
import {
  fetchWithIdType,
  FileResponseType,
  LoadingType,
  ResponseType,
  voidType,
} from "../../../../utils/uiTypes";
import { convertDateToFormat } from "../../../../utils/moment";
import { TransactionContext } from "../../../../context/TransactionContext";
import { CustomSpin } from "../../../general/CustomSpin";
import { TaskForm } from "../../../forms/TaskForm";
import { CommentInputForm } from "../../../forms/CommentInputForm";
import { NewSectionForm } from "../../../forms/SectionForm";
import {
  ConfirmDelete,
  ConfirmDownload,
} from "../../../../utils/confirmationModals";
import { usePageTitle } from "../../../../customHooks/usePageTitle";
import { ElementType } from "../../../../utils/enums";
import {
  getSelectOptionWithBadge,
  SelectWithBadgeOptionType,
  tableColumnHeader,
} from "../../../../utils/componentUtils";
import { DownloadOutlined, EllipsisOutlined } from "@ant-design/icons";
import { PRIMARY_BUTTON_STYLE } from "../../../../utils/cssConfigs";
import { ColumnsType } from "antd/es/table";

const isTaskDTO = (data: TaskType | TaskDTOType): data is TaskDTOType => {
  return (data as TaskDTOType).taskSectionId !== undefined;
};

const taskStatusArray: SelectWithBadgeOptionType[] = [
  { value: "COMPLETED", color: "green", label: "Completed" },
  { value: "INITIAL", color: "blue", label: "Active" },
];

export const TaskList: FC = function () {
  usePageTitle("TaskList");

  const { transaction } = useContext(TransactionContext);

  const [tasks, setTasks] = useState<TaskType[]>([]);
  const [loading, setLoading] = useState<LoadingType>({
    loading: false,
    label: "",
  });
  const [cloneModal, setCloneModal] = useState<boolean>(false);
  const [modalObject, setModalObject] = useState<
    "NEW_TASK" | "NEW_SECTION" | null
  >(null);
  const [editTask, setEditTask] = useState<TaskEditType | null>(null);
  const [taskComments, setTaskComments] = useState<CommentType[]>([]);
  const [dealTeam, setDealTeam] = useState<UserType[]>([]);
  const [formLoading, setFormLoading] = useState<FormLoadingType>({
    message: "",
    status: FormStatusType.NONE,
  });

  const fetchDealTeam: fetchWithIdType = (id) => {
    getDealTeam({
      segments: {
        id,
      },
      params: {
        isTeamPage: true,
        pageName: "communication",
      },
    })
      .then(({ data = [] }: ResponseType<UserType[]>) => {
        setDealTeam(data);
      })
      .catch((error: string) => {
        message.error(error ? error : "Failed Fetching the Deal Team!");
      });
  };

  const fetchTasks: fetchWithIdType = (id) => {
    getTasks({
      segments: {
        id,
      },
      params: {
        clone: false,
      },
    })
      .then(({ data = [] }: ResponseType<TaskType[]>) => {
        setTasks(data);
      })
      .catch((error: string) => {
        message.error(error ? error : "Error fetching Tasks!");
      });
  };

  const manageEditTask: manageEditTaskType = (values) => {
    const data = populateArrayWithPropertyPath(
      ["userDTO", "userId"],
      values?.taskAssigneesDTO
    );
    setEditTask({
      ...values,
      dueDate: values.dueDate
        ? moment(values.dueDate.toString(), "x")
        : moment(),
      taskAssigneesDTO: data ? data : [],
    });
  };

  const changeStatus: changeStatusType = (status, id, sectionId, tasks) => {
    message.loading({
      content: "Updating Task Status",
      key: "statusChange",
      duration: 0,
    });
    changeTaskStatus({
      segments: { status, id },
    })
      .then(() => {
        const sectionIndex = tasks.findIndex((val) => val.id === sectionId);
        const section = tasks[sectionIndex];

        const taskIndex = section.taskDTOs.findIndex((val) => val.id === id);

        setTasks(
          updateItemInArray(sectionIndex, tasks, {
            ...section,
            taskDTOs: updateItemInArray(taskIndex, section.taskDTOs, {
              ...section.taskDTOs[taskIndex],
              taskStatus: status,
            }),
          })
        );
      })
      .then(() =>
        message.success({
          content: "Updated Task Status",
          key: "statusChange",
          duration: 2,
        })
      )
      .catch((error: string) => {
        message.error({
          content: error ? error : "Error updating task status!",
          key: "statusChange",
          duration: 3,
        });
      });
  };

  const deleteTask: deleteTaskType = (id, sectionId, tasks) => {
    setLoading({ loading: true, label: "Deleting Task..." });
    id &&
      sectionId &&
      deleteCurrentTask({
        segments: {
          id,
        },
      })
        .then(() => {
          const sectionIndex = findItemByPropertyValue(tasks, sectionId, [
            "id",
          ]);
          const section = tasks[sectionIndex];
          const taskIndex = section.taskDTOs.findIndex((val) => val.id === id);
          setTasks(
            updateItemInArray(sectionIndex, tasks, {
              ...section,
              taskDTOs: removeItemFromArray(taskIndex, section.taskDTOs),
            })
          );
        })
        .then(() => {
          message.success("Deleted Task!", 0.5);
          setEditTask(null);
          setLoading({ loading: false });
        })
        .catch((error: string) => {
          message.error(error ? error : "Error Deleting Task!");
          setLoading({ loading: false });
        });
  };

  const initiateClone: initiateCloneType = (id, values, from) => {
    setFormLoading({ message: "", status: FormStatusType.LOADING });
    cloneTasks({
      segments: {
        from,
        to: id,
      },
      body: JSON.stringify(values),
    })
      .then(() => {
        fetchTasks(id);
      })
      .then(() => {
        setFormLoading({ message: "", status: FormStatusType.SUCCESS });
        message.success("Cloned Tasks", 0.5);
        setCloneModal(false);
      })
      .catch((error: string) => {
        setFormLoading({
          message: error ? error : "Error cloning tasks",
          status: FormStatusType.FAILED,
        });
        message.error(error ? error : "Error cloning tasks..");
      });
  };

  const uploadConversation: uploadConversationType = (
    comment,
    elementId,
    transactionId,
    taskComments
  ) => {
    message.loading({
      content: "Uploading comment...",
      key: elementId,
      duration: 0,
    });
    const data = {
      comment,

      elementType: ElementType.TASK,
      commentType: "TEXT",
      elementId,
      peTransactionId: transactionId,
      petransactionId: transactionId,
    };

    addComment({
      body: JSON.stringify(data),
    })
      .then(({ data }: ResponseType<CommentType>) => {
        setTaskComments([data, ...valOrDefault([], taskComments)]);
      })
      .then(() => {
        message.success({
          content: "Uploaded Comment",
          key: elementId,
        });
      })
      .catch((error: string) => {
        message.error({
          content: error ? error : "Error Uploading Comment",
          key: elementId,
        });
      });
  };

  const updateTask: updateOrAddTaskType = (values, transactionId, tasks) => {
    setFormLoading({ message: "", status: FormStatusType.LOADING });
    updateCurrentTask({
      body: JSON.stringify({
        ...editTask,
        ...{
          ...values,
          transactionId,
          taskAssigneesDTO: values.taskAssigneesDTO.map((val: string) => ({
            assignToId: val,
          })),
          dueDate: moment(values.dueDate).unix() * 1000,
        },
      }),
    })
      .then(({ data }: ResponseType<TaskDTOType>) => {
        const sectionIndex = findItemByPropertyValue(
          tasks,
          data.taskSectionId,
          ["id"]
        );

        const section = tasks[sectionIndex];
        const taskIndex = section.taskDTOs.findIndex(
          (val) => val.id === data.id
        );

        setTasks(
          updateItemInArray(sectionIndex, tasks, {
            ...section,
            taskDTOs: updateItemInArray(taskIndex, section.taskDTOs, data),
          })
        );
      })
      .then(() => {
        setFormLoading({ message: "", status: FormStatusType.SUCCESS });
        message.success("Updated Task", 0.5);
      })
      .then(() => {
        setEditTask(null);
      })
      .catch((error: string) => {
        setFormLoading({
          message: error ? error : "Error updating task",
          status: FormStatusType.FAILED,
        });
        message.error(error ? error : "Error updating task!");
      });
  };

  const addTask: updateOrAddTaskType = (values, transactionId, tasks) => {
    setFormLoading({ message: "", status: FormStatusType.LOADING });
    addNewTask({
      body: JSON.stringify({
        ...values,
        transactionId,
        taskAssigneesDTO: values.taskAssigneesDTO.map((val: string) => ({
          assignToId: val,
        })),
        dueDate: moment(values.dueDate).unix() * 1000,
      }),
    })
      .then(({ data }: ResponseType<TaskDTOType>) => {
        const sectionIndex = findItemByPropertyValue(
          tasks,
          data.taskSectionId,
          ["id"]
        );
        const section = tasks[sectionIndex];

        setTasks(
          updateItemInArray(sectionIndex, tasks, {
            ...section,
            taskDTOs: [...valOrDefault([], section?.taskDTOs), data],
          })
        );
      })
      .then(() => {
        message.success("Added New Task", 0.5);
        setFormLoading({ message: "", status: FormStatusType.SUCCESS });
        resetModal();
      })
      .catch((error: string) => {
        setFormLoading({
          message: error ? error : "Error adding new Task!",
          status: FormStatusType.FAILED,
        });
        message.error(error ? error : "Error Adding new Task!");
      });
  };

  const addSection: addSectionType = (values, tasks) => {
    setFormLoading({ message: "", status: FormStatusType.LOADING });
    addNewSection({
      body: JSON.stringify(values),
    })
      .then(({ data }: ResponseType<TaskType>) => {
        setTasks([...tasks, data]);
      })
      .then(() => {
        resetModal();
        setFormLoading({ message: "", status: FormStatusType.SUCCESS });
        message.success("Added New Section", 0.5);
      })
      .catch((error: string) => {
        setFormLoading({
          message: error ? error : "Error adding new section",
          status: FormStatusType.FAILED,
        });
        message.error(error ? error : "Error adding new section");
      });
  };

  const initiateExportTasks: fetchWithIdType = (id) => {
    message.loading({
      content: "Processing Tasks",
      duration: 0,
      key: "export-tasks",
    });
    exportTasks({
      segments: {
        id,
      },
    })
      .then(({ url, filename }: FileResponseType) => {
        message.info({
          content: "File Ready to Download",
          key: "export-tasks",
        });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        message.error({
          content: valOrDefault("Error Downloading File!", error),
          key: "export-tasks",
        });
      });
  };

  const resetModal: voidType = () => {
    setLoading({ loading: false });
    setModalObject(null);
  };

  const columns: ColumnsType<TaskType | TaskDTOType> = [
    {
      title: tableColumnHeader("Name"),
      align: "left" as const,
      width: "fit-content",
      key: "sectionHeading",
      dataIndex: "sectionHeading",
      className: "group-hover:bg-blue-50 min-w-[85px]",

      render: function name(sectionHeading: string, record): ReactNode {
        return !isTaskDTO(record) ? (
          <span className={"flex flex-row"}>
            {sectionHeading}
            <Tag
              color={"blue"}
              className={"ml-auto flex flex-initial items-center"}
            >
              <i className="fas fa-tasks mr-1" /> :&nbsp;
              {record.taskDTOs ? record.taskDTOs.length : 0}
            </Tag>
          </span>
        ) : (
          record?.taskTitle
        );
      },
    },
    {
      title: tableColumnHeader("Assigned To"),
      align: "left" as const,
      width: "fit-content",
      dataIndex: "taskAssigneesDTO",
      className: "group-hover:bg-blue-50 min-w-[85px]",

      render: function assignedTo(
        taskAssigneesDTO: taskAssigneesDTO[]
      ): ReactNode {
        return (
          taskAssigneesDTO && (
            <div className={"flex flex-row items-center"}>
              {taskAssigneesDTO?.slice(0, 3)?.map((assignee) => (
                <CustomAvatar
                  key={assignee.id}
                  data={
                    assignee.userDTO.firstName[0] + assignee.userDTO.lastName[0]
                  }
                  label={
                    assignee.userDTO.firstName + " " + assignee.userDTO.lastName
                  }
                  color={assignee.userDTO.firstName[0]}
                  size={"small"}
                />
              ))}
              {taskAssigneesDTO?.length > 3 && (
                <Popover
                  placement={"rightTop"}
                  title={`Sharing with ${taskAssigneesDTO?.length} Institutions`}
                  content={
                    <div className={"flex flex-col gap-y-2"}>
                      {taskAssigneesDTO?.map((assignee) => (
                        <div
                          key={assignee?.id}
                          className={"flex flex-row gap-x-2"}
                        >
                          <CustomAvatar
                            data={
                              assignee?.userDTO?.firstName[0] +
                              assignee?.userDTO?.lastName[0]
                            }
                            color={assignee?.userDTO?.firstName[0]}
                            size={"small"}
                          />
                          {assignee?.userDTO?.firstName +
                            assignee?.userDTO?.lastName}
                        </div>
                      ))}
                    </div>
                  }
                >
                  <Avatar size={"small"}>
                    <EllipsisOutlined />
                  </Avatar>
                </Popover>
              )}
            </div>
          )
        );
      },
    },
    {
      title: tableColumnHeader("Due Date"),
      align: "left" as const,
      width: "fit-content",
      dataIndex: "dueDate",
      className: "group-hover:bg-blue-50 min-w-[85px]",

      render: function date(dueDate: string, record): ReactNode {
        return (
          dueDate && (
            <span
              className={`${
                moment(dueDate?.toString(), "x") <
                  moment().subtract(1, "days").endOf("day") &&
                isTaskDTO(record) &&
                record.taskStatus !== "COMPLETED" &&
                "text-red-500"
              }`}
            >
              {convertDateToFormat(dueDate, "D.MMM.YY")}
            </span>
          )
        );
      },
    },
    {
      title: tableColumnHeader("Current Status"),
      align: "left" as const,
      width: "fit-content",
      dataIndex: "taskStatus",
      className: "group-hover:bg-blue-50 min-w-[85px]",
      render: function showStatus(status: string, record): ReactNode {
        return (
          status &&
          isTaskDTO(record) && (
            <div onClick={(e): void => e.stopPropagation()}>
              <Select
                dropdownMatchSelectWidth={120}
                className={`w-full group-hover:border-b border-gray-200 focus-within:border-blue-500`}
                suffixIcon={
                  <i className="fas fa-sort-down text-blue-500 opacity-0 group-hover:opacity-100" />
                }
                bordered={false}
                placeholder={"Select Status"}
                size={"small"}
                value={status}
                onChange={(value): void => {
                  changeStatus(value, record.id, record.taskSectionId, tasks);
                }}
              >
                {getSelectOptionWithBadge(taskStatusArray)}
              </Select>
            </div>
          )
        );
      },
    },
  ];

  useEffect(() => {
    if (transaction) {
      fetchTasks(transaction?.peTransactionId);
      fetchDealTeam(transaction?.peTransactionId);
    }
  }, [transaction?.peTransactionId]);

  useEffect(() => {
    editTask &&
      getComments({
        segments: {
          type: ElementType.TASK,
          id: editTask.id,
          transactionId: valOrDefault("", transaction?.peTransactionId),
        },
      }).then(({ data = [] }: ResponseType<CommentType[]>) => {
        setTaskComments(data);
      });
  }, [editTask?.id, transaction?.peTransactionId]);

  return (
    <div
      className={
        "relative max-h-full h-full w-full overflow-y-scroll hide-scrollbar"
      }
    >
      <Alert
        className={"py-1.5 text-xs"}
        type={"warning"}
        showIcon={true}
        banner={true}
        message={"The tasks are private within your organization."}
      />
      {transaction && (
        <>
          <CustomSpin loading={loading.loading} loadingText={loading.label} />
          <div
            className={
              "h-full max-h-full relative flex flex-col p-6 !pt-3 mx-auto"
            }
          >
            <div
              className={
                "flex flex-col sm:flex-row gap-4 items-center col-span-1"
              }
            >
              <p className={"m-0 text-2xl sm:text-xl "}>Deal Team Task List</p>
              <div
                className={`sm:flex hidden flex-row gap-3 ml-auto mr-auto sm:mr-0`}
              >
                <Button
                  className={`hidden md:block ${PRIMARY_BUTTON_STYLE}`}
                  icon={<DownloadOutlined />}
                  onClick={(): void =>
                    initiateExportTasks(transaction.peTransactionId)
                  }
                >
                  Export
                </Button>
                <Button
                  className={`hidden md:block bg-primary hover:bg-hover text-white border-0`}
                  onClick={(): void => setCloneModal(true)}
                >
                  Clone
                </Button>
                <Button
                  className={`hidden md:block bg-primary hover:bg-hover text-white border-0`}
                  onClick={(): void => setModalObject("NEW_TASK")}
                >
                  + Add Task
                </Button>
                <Button
                  className={`hidden md:block bg-primary hover:bg-hover text-white border-0`}
                  onClick={(): void => setModalObject("NEW_SECTION")}
                >
                  + Add Section
                </Button>
              </div>
            </div>
            &nbsp;
            <div className={"relative max-h-full pt-5"}>
              <Table
                className={`w-full border border-b-0`}
                rowKey={"id"}
                pagination={false}
                size={"middle"}
                loading={!transaction}
                columns={columns}
                dataSource={tasks}
                rowClassName={(record: TaskType | TaskDTOType): string =>
                  `relative group group hover:bg-blue-50 ${
                    isTaskDTO(record) ? "cursor-pointer" : "font-semibold"
                  }`
                }
                expandable={{
                  childrenColumnName: "taskDTOs",
                  defaultExpandAllRows: false,
                  expandRowByClick: true,
                  rowExpandable: (): boolean => true,
                }}
                onRow={(
                  record: TaskType | TaskDTOType
                ): HTMLAttributes<HTMLElement> => ({
                  onClick: (): void => {
                    isTaskDTO(record) && manageEditTask(record);
                  },
                })}
              />
            </div>
          </div>
          <Modal
            destroyOnClose={true}
            open={modalObject !== null}
            title={
              modalObject === "NEW_SECTION"
                ? "Create New Section"
                : "Create New Task"
            }
            closable={true}
            onOk={resetModal}
            onCancel={resetModal}
            footer={false}
          >
            {modalObject === "NEW_SECTION" ? (
              <NewSectionForm
                onSubmit={(values): void => {
                  addSection(
                    {
                      ...values,
                      transactionId: transaction.peTransactionId,
                    },
                    tasks
                  );
                }}
                formStatus={formLoading.status}
                message={formLoading.message}
              />
            ) : (
              <TaskForm
                onSubmit={(values, id): void => addTask(values, id, tasks)}
                transactionId={transaction?.peTransactionId}
                tasks={tasks}
                dealTeam={dealTeam}
                formStatus={formLoading.status}
                message={formLoading.message}
              />
            )}
          </Modal>
          <Modal
            open={cloneModal}
            title={"Clone Tasks"}
            onCancel={(): void => setCloneModal(false)}
            footer={false}
            destroyOnClose={true}
          >
            <CloneTasks
              disabled={formLoading.status === FormStatusType.LOADING}
              transactionId={transaction.peTransactionId}
              initiateClone={initiateClone}
              cancel={(): void => setCloneModal(false)}
            />
          </Modal>
          {editTask && (
            <Drawer
              visible={true}
              destroyOnClose={true}
              closable={true}
              headerStyle={{ padding: "10px 20px" }}
              title={
                <div className={"flex flex-row items-center"}>
                  Edit Task
                  <Button
                    className={
                      "bg-danger hover:bg-hover text-white border-0 ml-auto"
                    }
                    onClick={(): void => {
                      ConfirmDelete("Confirm Delete Task?", () =>
                        deleteTask(editTask?.id, editTask?.taskSectionId, tasks)
                      );
                    }}
                    icon={<i className={"fa fa-trash-alt"} />}
                  />
                </div>
              }
              maskClosable={true}
              onClose={(): void => {
                setEditTask(null);
              }}
              width={500}
            >
              <div
                className={`flex flex-col gap-y-1 bg-white h-full overflow-hidden`}
              >
                <TaskForm
                  dealTeam={dealTeam}
                  onClear={(): void => setEditTask(null)}
                  edit={true}
                  formData={editTask}
                  transactionId={transaction.peTransactionId}
                  onSubmit={(values, id): void => updateTask(values, id, tasks)}
                  formStatus={formLoading.status}
                  message={formLoading.message}
                />
                <div
                  className={"bg-gray-50 p-2 mt-auto overflow-y-scroll mt-5"}
                >
                  {taskComments?.map(
                    ({ comment, commentId, createdByUserDTO, createDate }) => (
                      <Comment
                        key={commentId}
                        content={
                          <div
                            className={"prose max-w-full"}
                            dangerouslySetInnerHTML={{
                              __html: comment
                                ?.replaceAll(/\\"/g, "")
                                .replaceAll(/\\n/g, ""),
                            }}
                          />
                        }
                        author={
                          createdByUserDTO?.firstName +
                          " " +
                          createdByUserDTO?.lastName
                        }
                        datetime={
                          convertDateToFormat(createDate, "D.MMM.YY", false) +
                          " " +
                          convertDateToFormat(createDate, "hh:mm a", false)
                        }
                        avatar={
                          <CustomAvatar
                            outlined={true}
                            data={
                              createdByUserDTO.firstName[0] +
                              createdByUserDTO.lastName[0]
                            }
                            color={createdByUserDTO.firstName[0]}
                            size={"small"}
                            label={
                              createdByUserDTO.firstName +
                              " " +
                              createdByUserDTO.lastName
                            }
                          />
                        }
                      />
                    )
                  )}
                </div>
                <CommentInputForm
                  onSubmit={({ message }): void => {
                    uploadConversation(
                      message,
                      valOrDefault("", editTask?.id),
                      transaction.peTransactionId,
                      taskComments
                    );
                  }}
                />
              </div>
            </Drawer>
          )}
        </>
      )}
    </div>
  );
};

type TaskEditType = {
  id: string;
  dueDate: Moment;
  taskAssigneesDTO: string[];
  taskSectionId: string;
  active: boolean;
  createDate: string;
  createdBy: string;
  createdByUserDTO: UserType;
  modifiedDate: string;
  taskStatus: string;
  taskTitle: string;
  transactionId: string;
};

type submitTaskType = {
  dueDate: Moment;
  taskSectionId: string;
  taskDetails: string;
  taskAssigneesDTO: string[];
  taskTitle: string;
};
type updateOrAddTaskType = (
  values: submitTaskType,
  transactionId: string,
  tasks: TaskType[]
) => void;
type manageEditTaskType = (values: TaskDTOType) => void;
type deleteTaskType = (
  id: string,
  sectionId: string,
  tasks: TaskType[]
) => void;
type initiateCloneType = (id: string, values: string[], from: string) => void;
type uploadConversationType = (
  comment: string,
  elementId: string,
  transactionId: string,
  taskComments: CommentType[]
) => void;
type addSectionType = (
  values: Record<string, string>,
  tasks: TaskType[]
) => void;
type changeStatusType = (
  status: string,
  id: string,
  sectionId: string,
  tasks: TaskType[]
) => void;
enum FormStatusType {
  LOADING,
  SUCCESS,
  FAILED,
  NONE,
}
type FormLoadingType = {
  status: FormStatusType;
  message: string;
};
