import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { PortfolioView } from "../components/portfolio/portfolioView/PortfolioView";
import { PortfoliosSummary } from "../components/portfolio/PortfoliosSummary";
import { usePageTitle } from "../customHooks/usePageTitle";
import { MenuRouteType, ResponseType } from "../utils/uiTypes";
import {
  CompanyModuleType,
  ElementPermissionsType,
  PortfolioType,
} from "../utils/types";
import { WithTopNav } from "../components/layout/WithTopNav";
import { useHistory, useParams } from "react-router";
import { getPortfolios } from "../services/services";
import { message } from "antd";
import { getPortfolioType, getSummaryData } from "../utils/portfolio";
import { PMAnalytics } from "../components/portfolio/insights/PMAnalytics";
import { checkElementViewPermission } from "../utils/permissions";
import { ElementType, PermissionType, PortfolioEnumType } from "../utils/enums";
import { UserContext } from "../context/UserContext";
import { DEFAULT_COMPANY_MODULE_CONFIG } from "../utils/module-util";

type getAuthRoutesType = (
  portfolios: PortfolioType[],
  setPortfolios: Dispatch<SetStateAction<PortfolioType[]>>,
  refreshPortfolioList: () => void,
  elementPermissions: Array<ElementPermissionsType>,
  companyModulePreference: CompanyModuleType
) => Array<MenuRouteType>;

const MENU_OPTIONS: getAuthRoutesType = (
  portfolios: PortfolioType[],
  setPortfolios: Dispatch<SetStateAction<PortfolioType[]>>,
  refreshPortfolioList: () => void,
  elementPermissions,
  modulePreference
): MenuRouteType[] => [
  {
    title: "Summary",
    Component: PortfoliosSummary,
    props: {
      portfolios,
      setPortfolios,
      refreshPortfolioList,
    },
    key: "summary",
    path: ["/portfolio/summary"],
    exact: true,
    permission: true,
  },
  {
    title: "Insights",
    Component: PMAnalytics,
    key: "insights",
    path: ["/portfolio/insights"],
    exact: true,
    permission:
      modulePreference.insights &&
      checkElementViewPermission(
        ElementType.PORTFOLIO,
        elementPermissions,
        [PermissionType.VIEW_PORTFOLIO],
        true
      ),
  },
  {
    title: `Portfolio`,
    key: "company",
    path: "",
    exact: true,
    permission: true,
    subMenu: portfolios.map(({ id, portfolioCompanyName }) => ({
      key: id,
      path: [
        `/portfolio/:portfolioId/:portfolioMenu`,
        `/portfolio/:portfolioId/:portfolioMenu/:sectionId`,
      ],
      permission: true,
      title: portfolioCompanyName,
      exact: true,
      Component: PortfolioView,
    })),
  },
];

export const Portfolio: FC = () => {
  usePageTitle("Portfolio");
  const { portfolioId }: ParamsType = useParams();
  const history = useHistory();
  const { user, companyModulePreference } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [portfolioList, setPortfolioList] = useState<PortfolioType[]>([]);

  const getPortfoliosList = (type: PortfolioEnumType) => (): void => {
    setLoading(true);
    getPortfolios({
      params: { type },
    })
      .then(({ data = [] }: ResponseType<Array<PortfolioType>>) => {
        setPortfolioList(
          data
            .sort((a, b) =>
              a.portfolioCompanyName.toLowerCase() >
              b.portfolioCompanyName.toLowerCase()
                ? 1
                : -1
            )
            .map((item) => ({
              ...item,
              ...getSummaryData(item?.summaryField?.data),
            }))
        );
        setLoading(false);
      })
      .catch((error: string) => {
        message.error(
          error ? error : "Could not fetch portfolio data. Please try again.",
          2
        );
        setLoading(false);
      });
  };

  useEffect(
    getPortfoliosList(getPortfolioType(user?.companyDTO?.companyType)),
    [user?.companyDTO?.companyType]
  );

  return (
    <WithTopNav
      defaultValue={"summary"}
      selectedKeys={[portfolioId]}
      menu={MENU_OPTIONS(
        portfolioList,
        setPortfolioList,
        getPortfoliosList(getPortfolioType(user?.companyDTO?.companyType)),
        user?.elementPermissions ?? [],
        companyModulePreference ?? DEFAULT_COMPANY_MODULE_CONFIG
      ).filter(({ permission }) => permission)}
      loading={loading}
      title={
        <div className={"flex flex-row items-center justify-between w-full"}>
          Portfolio Management
        </div>
      }
      gotoRoute={({ key }): void =>
        history.push(
          Number(key) ? `/portfolio/${key}/overview` : `/portfolio/${key}`
        )
      }
    />
  );
};

type ParamsType = {
  portfolioId: string;
  portfolioMenu: string;
};
