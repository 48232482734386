import { CompareSheetType } from "../../../../utils/types";
import { any, sum } from "ramda";
import { getKeyByType } from "./key-by-type";

export const prepareSheetsSelection: prepareSheetsSelectionType = (
  sheets = [],
  selectedIds = []
) => {
  return sheets?.reduce<SheetsSelectionType[]>((p, c) => {
    if (selectedIds?.includes(c.sheetId)) {
      return [
        ...p,
        {
          ...c,
          showAsk: true,
          showMetric: true,
          visibleResponseKeys: c.lenderKeys,
          showResponses: true,
          colCount: 2 + c.lenderKeys?.length,
        },
      ];
    }
    if (
      selectedIds?.includes(c.sheetId) ||
      any((v) => v.includes(c.sheetId), selectedIds ?? [])
    ) {
      const hasBidMetric = selectedIds?.includes(
        getKeyByType(c.sheetId, "BID_METRIC")
      );
      const hasAsk = selectedIds?.includes(getKeyByType(c.sheetId, "ASK"));
      const visibleResponseKeys = selectedIds.includes(
        getKeyByType(c.sheetId, "RESPONSE", "ALL")
      )
        ? c.lenderKeys
        : (c.lenderKeys ?? []).filter((v) =>
            selectedIds?.includes(getKeyByType(c.sheetId, "RESPONSE", v))
          );
      const count = sum([
        hasBidMetric ? 1 : 0,
        hasAsk ? 1 : 0,
        visibleResponseKeys?.length ?? 0,
      ]);

      return count > 0
        ? [
            ...p,
            {
              ...c,
              showAsk: hasAsk,
              showMetric: hasBidMetric,
              visibleResponseKeys: visibleResponseKeys,
              showResponses: visibleResponseKeys?.length !== 0,
              colCount: count,
            },
          ]
        : p;
    } else return p;
  }, []);
};

export type SheetsSelectionType = CompareSheetType & {
  showAsk: boolean;
  showMetric: boolean;
  showResponses: boolean;
  visibleResponseKeys: string[];
  colCount: number;
};

type prepareSheetsSelectionType = (
  sheets: CompareSheetType[],
  selectedIds: string[]
) => SheetsSelectionType[];
