import React, { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { Button, Modal, Table } from "antd";
import { isEmpty, lensPath, pathOr, set, unnest } from "ramda";
import { getModalContainerForFullScreen } from "../../../../utils/container";
import {
  CurrencySymbolType,
  CurrencyType,
  UnitLabelType,
  UnitType,
} from "../../../../utils/enums";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { AllocationKeyType, CompanyType } from "../../../../utils/types";
import { isFooter } from "../../../../utils/allocation";
import { ColumnsType } from "antd/es/table";
import {
  getDeltaPercentage,
  getObjectFromPropertyPathValue,
  getObjectFromPropertyValue,
} from "../../../../utils/utils";
import { AllocationComparisonType } from "./AllocationTable";
import moment from "moment";

export const AllocationComparison: FC<AllocationSummaryModalComponent> = ({
  config,
  title,
  companies,
  keys,
  onClose,
}) => {
  const { height: windowHeight } = useWindowDimensions();
  const [excludeIds, setExcludeIds] = useState<string[]>([]);
  const getCMP = useCallback(
    ([c1, c2]: [AllocationComparisonType, AllocationComparisonType]): CMP => {
      const institutionSet = new Set<string>();
      const allocationKeySet = new Set<string>();
      const recordAllocationMappingToKey: Record<string, string> = {};
      let data: CMP["data"] = {};

      for (const i of [
        ...c1.config.allocationKeyTableMappingDTOS,
        ...c2.config.allocationKeyTableMappingDTOS,
      ]) {
        if (
          i.allocationKeyDTO.keyName !== "Role" &&
          i.allocationKeyDTO.keyName !== "Demand"
        ) {
          allocationKeySet.add(i.allocationKeyDTO.id);
          recordAllocationMappingToKey[i.id] = i.allocationKeyDTO.id;
        } else {
          setExcludeIds((prevIds: string[]) => {
            if (!prevIds.includes(i.allocationKeyDTO.id)) {
              return [...prevIds, i.allocationKeyDTO.id];
            }
            return prevIds;
          });
        }
      }

      for (const i of c1.data) {
        institutionSet.add(i.elementId);
        data = set(
          lensPath([i.elementId, i.allocationKeyDTO.id]),
          [isNaN(Number(i.value)) ? 0 : Number(i.value), 0],
          data
        ) as Record<string, Record<string, [number, number]>>;
      }

      for (const i of c2.data) {
        institutionSet.add(i.elementId);
        data = set(
          lensPath([i.elementId, i.allocationKeyDTO.id, 1]),
          isNaN(Number(i.value)) ? 0 : Number(i.value),
          data
        ) as Record<string, Record<string, [number, number]>>;
      }

      return {
        cols: Array.from(allocationKeySet),
        data,
        rows: Array.from(institutionSet).map((value) => ({ id: value })),
        currency: c1?.data?.[0]?.currency,
        unit: c1?.data?.[0]?.unit,
      };
    },
    []
  );

  const cmp = useMemo(() => getCMP(config), [config]);

  const calculateTotalForColumn: calCTotalForCompany = useCallback(
    (data, columnId) => {
      return Object.values(data)?.reduce(
        ([p1, p2], currentValue) => [
          p1 + (currentValue?.[columnId]?.[0] ?? 0),
          p2 + (currentValue?.[columnId]?.[1] ?? 0),
        ],
        [0, 0]
      );
    },
    []
  );

  const calculateAllTotal = (data: CMP["data"]) => {
    const excludeSet = new Set(excludeIds);
    const result = Object.values(data)
      ?.flat()
      ?.map((obj) => {
        const filteredObj: Record<string, [number, number]> = {};
        for (const key in obj) {
          if (!excludeSet.has(key)) {
            filteredObj[key] = obj[key];
          }
        }
        return filteredObj;
      })
      ?.flatMap(Object.values)
      ?.reduce(
        (acc, curr) => {
          return [
            (acc[0] || 0) + (curr[0] || 0),
            (acc[1] || 0) + (curr[1] || 0),
          ];
        },
        [0, 0]
      );

    return result;
  };
  const calculateTotalForCompany: calCTotalForCompany = useCallback(
    (data, companyId) => {
      const companyData = data?.[companyId];
      if (!companyData) return [0, 0];

      return Object.values(companyData)
        ?.slice(2)
        ?.reduce(
          ([p1, p2], currentValue) => [
            p1 + (currentValue?.[0] ?? 0),
            p2 + (currentValue?.[1] ?? 0),
          ],
          [0, 0]
        );
    },
    []
  );

  const getInstitutionName: getInstitutionNameType = useCallback(
    (id, companies) => {
      return pathOr(
        "",
        ["name"],
        getObjectFromPropertyValue("id", id, companies)
      );
    },
    []
  );

  const sortColumn: sortColumnType = useCallback(
    (a, b, data) => {
      if (isFooter(a.id) || isFooter(b.id)) return 0;

      const deltaA =
        (!isNaN(calculateTotalForCompany(data, a.id)[0])
          ? calculateTotalForCompany(data, a.id)[0]
          : 0) - calculateTotalForCompany(data, a.id)[1];

      const deltaB =
        (!isNaN(calculateTotalForCompany(data, b.id)[0])
          ? calculateTotalForCompany(data, b.id)[0]
          : 0) - calculateTotalForCompany(data, b.id)[1];

      return deltaA - deltaB;
    },
    [isFooter, calculateTotalForCompany]
  );

  const renderValues: rendervaluesTypes = (val, currency, unit) => {
    if (!currency || !unit) return;
    return (
      <span className="p-1 flex justify-between items-center">
        <span>
          <span className="text-gray-300">{CurrencySymbolType[currency]}</span>
          <span className="ml-2">
            {val === undefined ? "N/A" : val.toFixed(2)}
          </span>
        </span>
        <span className="text-gray-300 ml-2">{UnitLabelType[unit]}</span>
      </span>
    );
  };

  const institutionColumn: institutionColumnType = (companies) => [
    {
      title: (
        <span className="p-2 text-white whitespace-nowrap">Institution</span>
      ),
      dataIndex: "lenderName",
      align: "left",
      fixed: true,
      key: "lenderName",
      className: "p-0 whitespace-nowrap !z-20 bg-gray-700 min-w-[300px]",
      render: (_, { id }: { id: string }, index: number) => (
        <div
          className={`m-0 p-1 ${index >= 0 ? "bg-gray-100 text-gray-700" : ""}`}
        >
          {getInstitutionName(id, companies)}
        </div>
      ),
    },
  ];

  const getClass = (value: number) =>
    value <= -1 ? "bg-red-100 " : value >= 1 ? "bg-emerald-100 " : "";

  const renderCol: renderValueType = (col, id, type) => {
    switch (type) {
      case "value2":
        return renderValues(
          pathOr(0, [col?.id, id, 1], cmp?.data) as number,
          cmp?.currency,
          cmp?.unit
        );
      case "abs":
        return (
          <div
            className={getClass(
              (pathOr(0, [col?.id, id, 1], cmp?.data) as number) -
                (pathOr(0, [col?.id, id, 0], cmp?.data) as number)
            )}
          >
            {renderValues(
              (pathOr(0, [col?.id, id, 1], cmp?.data) as number) -
                (pathOr(0, [col?.id, id, 0], cmp?.data) as number),
              cmp.currency,
              cmp.unit
            )}
          </div>
        );
      case "percent": {
        const val = getDeltaPercentage(
          pathOr(0, [col?.id, id, 1], cmp?.data),
          pathOr(0, [col?.id, id, 0], cmp?.data)
        );

        return (
          <div className={`p-1 ${getClass(val)}`}>
            {pathOr(0, [col?.id, id, 0], cmp?.data) ? val + "%" : "N/A"}
          </div>
        );
      }

      default:
        return renderValues(
          pathOr(0, [col?.id, id, 0], cmp?.data) as number,
          cmp?.currency,
          cmp?.unit
        );
    }
  };

  const renderColumn: renderColumnType = (id, dateIndex, type) => {
    return {
      title: (
        <div className="pl-1 text-white bg-gray-500 whitespace-nowrap">
          {config?.[dateIndex]?.config?.name} -
          {moment(config?.[dateIndex]?.config?.allocationDate).format(
            "MMM D YYYY"
          )}
        </div>
      ),
      className: "p-0 min-w-[200px]",
      align: "left",
      render: (col: { id: string }) => renderCol(col, id, type),
    };
  };

  const getCalculatingColumns: getAllocationColumnType = (data, cols) =>
    cols
      ? cols.map((id) => {
          const allocationKeyDTO: AllocationKeyType =
            getObjectFromPropertyPathValue(["id"], id, keys);

          return {
            title: (
              <div className="text-white bg-gray-500 whitespace-nowrap min-w-[200px]">
                {allocationKeyDTO?.keyName}
              </div>
            ),
            key: id,
            align: "left",
            className: "p-0",
            children: [
              renderColumn(id, 0, "value1"),
              renderColumn(id, 1, "value2"),
              {
                title: (
                  <div className="text-white bg-gray-500 whitespace-nowrap pl-1 ">
                    &Delta; (Abs.)
                  </div>
                ),
                className: "p-0 text-sm min-w-[200px]",
                align: "left",
                sorter: (a, b) => sortColumn(a, b, data),
                render: (col: { id: string }) => renderCol(col, id, "abs"),
              },
              {
                title: (
                  <div className="text-white bg-gray-500 whitespace-nowrap pl-1">
                    &Delta; (%)
                  </div>
                ),
                className: "p-0 text-sm min-w-[200px]",
                align: "left",
                sorter: (a, b) => sortColumn(a, b, data),
                render: (col: { id: string }) => renderCol(col, id, "percent"),
              },
            ],
          };
        })
      : [];

  const totalColumn: getAllocationColumnType = (data) => [
    {
      title: (
        <div className="text-white bg-gray-700 whitespace-nowrap">Total</div>
      ),
      dataIndex: "total",
      key: "total",
      children: [
        {
          title: (
            <div className="pl-1 text-white bg-gray-700 whitespace-nowrap ">
              {config?.[0]?.config?.name}
            </div>
          ),
          className: "text-sm p-0 min-w-[200px]",
          align: "left",
          render: (_, { id }: { id: string }): ReactNode => {
            const value = calculateTotalForCompany(data, id)[0];
            return renderValues(value, cmp.currency, cmp.unit);
          },
        },
        {
          title: (
            <div className="pl-1 text-white bg-gray-700 whitespace-nowrap">
              {config?.[1]?.config?.name}
            </div>
          ),
          align: "left",
          className: "text-sm p-0 min-w-[200px]",
          render: (_, { id }: { id: string }): ReactNode => {
            const value = calculateTotalForCompany(data, id)[1];
            return renderValues(value, cmp?.currency, cmp?.unit);
          },
        },
        {
          title: (
            <div className="pl-1 text-white bg-gray-700 whitespace-nowrap">
              &Delta; (Abs.)
            </div>
          ),
          className: "p-0 text-sm min-w-[200px]",
          align: "left",
          sorter: (a, b) => sortColumn(a, b, data),
          render: (_, { id }: { id: string }) => {
            const value =
              calculateTotalForCompany(data, id)[1] -
              calculateTotalForCompany(data, id)[0];

            return (
              <div className={getClass(value)}>
                {renderValues(value, cmp?.currency, cmp?.unit)}
              </div>
            );
          },
        },
        {
          title: (
            <div className="pl-1 text-white bg-gray-700 whitespace-nowrap">
              &Delta; (%)
            </div>
          ),
          align: "left",
          className: "text-sm p-0 min-w-[200px]",
          sorter: (a, b) => sortColumn(a, b, data),
          render: (_, { id }: { id: string }) => {
            const value = getDeltaPercentage(
              calculateTotalForCompany(data, id)[1],
              calculateTotalForCompany(data, id)[0]
            );

            return (
              <div className={`p-1 ${getClass(value)}`}>
                {calculateTotalForCompany(data, id)[0] ? value + "%" : "N/A"}
              </div>
            );
          },
        },
      ],
      className: "p-0",
    },
  ];

  const columns = useMemo(() => {
    return [
      ...institutionColumn(companies),
      ...getCalculatingColumns(cmp?.data, cmp?.cols),
      ...totalColumn(cmp?.data),
    ];
  }, [cmp, companies, keys]);

  const data0 = config?.[0]?.data;
  const data1 = config?.[1]?.data;

  const tradingReportEmpty = data0?.length === 0 || data1?.length === 0;

  const isSameCurrency =
    data0?.[0]?.currency &&
    data1?.[0]?.currency &&
    data0[0]?.currency === data1[0]?.currency;

  const isSameUnit =
    data0?.[0]?.unit && data1?.[0]?.unit && data0?.[0]?.unit === data1[0]?.unit;

  const hasRows = cmp?.rows?.length > 0;

  return (
    <Modal
      getContainer={getModalContainerForFullScreen}
      title={title}
      width={isSameCurrency && hasRows && isSameUnit ? "85%" : "50%"}
      open
      closable
      onCancel={onClose}
      destroyOnClose
      footer={
        <div className="flex flex-row justify-end items-center">
          <Button onClick={onClose}>Cancel</Button>
        </div>
      }
    >
      {tradingReportEmpty ? (
        <span className="p-5  text-[15px]">
          Report can’t be generated because one of the Allocation versions is
          empty.
        </span>
      ) : !isSameCurrency ? (
        <span className="p-5  text-[15px]">
          Report can’t be generated because the Allocation versions use
          different currencies.
        </span>
      ) : (
        !isSameUnit && (
          <span className="p-5  text-[15px]">
            Report can’t be generated because the Allocation versions use
            different units.
          </span>
        )
      )}
      {isSameCurrency && hasRows && isSameUnit ? (
        <Table<AllocationComparisonTableType>
          loading={false}
          pagination={false}
          columns={columns}
          rowKey={(record, index) => pathOr("", [0, "id"], record) ?? index}
          dataSource={cmp.rows}
          scroll={{ x: "max-content", y: windowHeight / 2 }}
          bordered
          className={toolbar ? "shadow" : ""}
          size="small"
          locale={{
            emptyText:
              isEmpty(cmp.rows) &&
              "There are no institutions in this table yet.",
          }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className="bg-gray-100">
                <Table.Summary.Cell
                  className="p-0 !z-20"
                  key="addRow"
                  index={0}
                  align={"center"}
                >
                  <div className="p-3 !bg-gray-500  whitespace-nowrap">
                    TOTAL SUM
                  </div>
                </Table.Summary.Cell>
                {cmp.cols.map((colId, index) => (
                  <React.Fragment key={colId}>
                    <Table.Summary.Cell index={index + 1} align="left">
                      <div className="!bg-gray-100  whitespace-nowrap">
                        {renderValues(
                          calculateTotalForColumn(cmp?.data, colId)?.[0],
                          cmp?.currency,
                          cmp?.unit
                        )}
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={index + 2} align="left">
                      <div className="!bg-gray-100  whitespace-nowrap">
                        {renderValues(
                          calculateTotalForColumn(cmp?.data, colId)?.[1],
                          cmp?.currency,
                          cmp?.unit
                        )}
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={index + 3} colSpan={1}>
                      {renderValues(
                        calculateTotalForColumn(cmp?.data, colId)?.[1] -
                          calculateTotalForColumn(cmp?.data, colId)?.[0],
                        cmp?.currency,
                        cmp?.unit
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={index + 3} colSpan={1}>
                      {calculateTotalForColumn(cmp?.data, colId)?.[0]
                        ? getDeltaPercentage(
                            calculateTotalForColumn(cmp?.data, colId)?.[1],
                            calculateTotalForColumn(cmp?.data, colId)?.[0]
                          ) + "%"
                        : "N/A"}
                    </Table.Summary.Cell>
                  </React.Fragment>
                ))}
                <Table.Summary.Cell index={cmp.cols.length + 1}>
                  <div className="!bg-gray-100  whitespace-nowrap">
                    {renderValues(
                      calculateAllTotal(cmp?.data)?.[0],
                      cmp?.currency,
                      cmp?.unit
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell className="p-0" index={cmp.cols.length + 2}>
                  <div className="!bg-gray-100  whitespace-nowrap">
                    {renderValues(
                      calculateAllTotal(cmp?.data)?.[1],
                      cmp?.currency,
                      cmp?.unit
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  className="p-0"
                  index={cmp?.cols?.length + 3}
                  colSpan={1}
                >
                  {renderValues(
                    calculateAllTotal(cmp?.data)?.[1] -
                      calculateAllTotal(cmp?.data)?.[0],
                    cmp?.currency,
                    cmp?.unit
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  className="p-0"
                  index={cmp?.cols?.length + 3}
                  colSpan={1}
                >
                  {calculateAllTotal(cmp?.data)?.[0]
                    ? getDeltaPercentage(
                        calculateAllTotal(cmp?.data)?.[1],
                        calculateAllTotal(cmp?.data)?.[0]
                      ) + "%"
                    : "N/A"}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      ) : isSameCurrency && isSameUnit ? (
        <span className="text-muted">
          No Allocation Comparison as data entered on one of the versions
          selected is empty
        </span>
      ) : null}
    </Modal>
  );
};

type AllocationSummaryModalComponent = {
  config: [AllocationComparisonType, AllocationComparisonType];
  title: string;
  companies: CompanyType[];
  keys: AllocationKeyType[];
  onClose: VoidFunction;
};

type AllocationComparisonTableType = {
  id: string;
  [key: string]: string | number;
};

type CMP = {
  rows: { id: string }[];
  cols: string[];
  data: Record<string, Record<string, [number, number]>>;
  currency: CurrencyType;
  unit: UnitType;
};

type ColumnType = ColumnsType<AllocationComparisonTableType>;

type getAllocationColumnType = (
  data: CMP["data"],
  keys?: string[]
) => ColumnType;

type institutionColumnType = (companies: CompanyType[]) => ColumnType;

type getInstitutionNameType = (
  record: string,
  companies: CompanyType[]
) => string;

type sortColumnType = (
  a: { id: string },
  b: { id: string },
  data: CMP["data"]
) => number;

type renderValueType = (
  col: { id: string },
  id: string,
  type: string
) => ReactNode;

type calCTotalForCompany = (
  data: CMP["data"],
  companyId: string
) => [number, number];

type rendervaluesTypes = (
  val: number,
  currency: CurrencyType,
  unit: UnitType
) => ReactNode;

type renderColumnType = (
  id: string,
  dateIndex: number,
  type: string
) => {
  title: JSX.Element;
  className: string;
  align: string;
  render: (col: { id: string }) => void;
};
