import React, { FC } from "react";
import {
  AnalyticsConfigType,
  AnalyticsFilters,
  InstitutionType,
} from "../../utils/types";
import { AnalyticsData } from "./AnalyticsData";

/**
 * Get the Section for the Analytics Dashboard
 * @param config - is for the configuration of the Section
 * @param params - is for the params to filter out the results from Database
 * @param isActive - is to check whether the current section is active or not
 * @constructor
 * @returns FC
 * @type AnalyticsSectionType
 */
export const AnalyticsSection: FC<AnalyticsSectionType> = ({
  config,
  params,
  isActive = false,
  filterOptions,
}) => {
  return (
    <>
      {isActive && (
        <div className={"flex flex-col h-full w-full bg-gray flex flex-col"}>
          {config?.rows?.map(({ columns }, index) => (
            <div key={index} className={"flex flex-wrap w-full relative"}>
              {columns.map(({ name, label, chartType, filters }, index) => (
                <AnalyticsData
                  key={index}
                  name={name}
                  label={label}
                  chartType={chartType}
                  params={params}
                  localFilters={filters}
                  filterOptions={filterOptions}
                />
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

type AnalyticsSectionType = {
  config: AnalyticsConfigType | null;
  params: string;
  isActive?: boolean;
  filterOptions?: Record<AnalyticsFilters, Array<InstitutionType>>;
};
