import React, { FC, useContext, useEffect, useState } from "react";
import { usePageTitle } from "../../../../customHooks/usePageTitle";
import { Prompt, useParams } from "react-router";
import { LeakageTable } from "./LeakageTable";
import {
  CompanyType,
  LeakageData,
  LeakageDataRow,
  LeakageTemplate,
} from "../../../../utils/types";
import {
  getAllCompanies,
  getLeakageData,
  getLeakageTemplateCompany,
  updateLeakageBulkdata,
} from "../../../../services/services";
import {
  fetchWithIdType,
  ResponseType,
  voidType,
} from "../../../../utils/uiTypes";
import { Button, message } from "antd";
import { ElementType, PermissionType } from "../../../../utils/enums";
import { PRIMARY_BUTTON_STYLE } from "../../../../utils/cssConfigs";
import { TransactionContext } from "../../../../context/TransactionContext";
import { useConfirmReload } from "../../../../customHooks/useConfirmReload";

export const Leakage: FC = () => {
  usePageTitle("Documentation");
  const { id } = useParams<Record<string, string>>();
  const { permissions } = useContext(TransactionContext);
  const [template, setTemplate] = useState<LeakageTemplate | null>(null);
  const [leakageData, setLeakageData] = useState<LeakageData | null>(null);
  const [update, setUpdate] = useState<boolean>(false);
  const [companies, setCompanies] = useState<CompanyType[]>([]);
  const [isUpdated, setIsUpdated] = useState(false);

  useConfirmReload(!!isUpdated, [isUpdated]);

  const canEdit =
    permissions.includes(PermissionType.ADMIN_PETRANSACTION) ||
    permissions.includes(PermissionType.ADMIN_LENDER);

  const fetchCompanyTemplate: voidType = () => {
    getLeakageTemplateCompany({
      segments: {
        showHidden: false,
      },
    })
      .then(({ data }: ResponseType<LeakageTemplate>) => {
        setTemplate(data);
        fetchTransactionData(id);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(error ?? "Error Fetching template");
      });
  };

  const fetchTransactionData: fetchWithIdType = (elementId) => {
    getLeakageData({
      segments: {
        elementType: ElementType.PETRANSACTION,
        elementId,
      },
    })
      .then(({ data }: ResponseType<LeakageData>) => {
        setLeakageData(data);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(error ?? "Error Fetching template");
      });
  };

  const onUpdateData = (row: LeakageDataRow) => {
    setIsUpdated(true);
    const _leakageData = { ...leakageData };

    if (_leakageData?.leakageElementKeyDataDTOMap) {
      _leakageData.leakageElementKeyDataDTOMap[row.keyId] = {
        ...row,
        templateId: row.templateId || "",
      } as LeakageDataRow;
      setLeakageData(_leakageData as LeakageData);
    }
    setUpdate(true);
  };

  const saveLeakageData = () => {
    updateLeakageBulkdata({
      body: JSON.stringify(leakageData),
    })
      .then(({ data }: ResponseType<LeakageData>) => {
        setLeakageData(data);
        setUpdate(false);
        message.success("Data updated successfully");
        setIsUpdated(false);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(error ? error : "Failed Updating the notification!");
      });
  };

  useEffect(() => {
    fetchCompanyTemplate();
    getAllCompanies({})
      .then(({ data = [] }: ResponseType<CompanyType[]>) => {
        setCompanies(data);
      })
      .catch(console.error);
  }, [id]);

  return (
    <div
      className={
        "relative mx-auto p-6 h-full max-h-full overflow-y-auto flex flex-col"
      }
    >
      <Prompt
        when={isUpdated}
        message={"Changes you made might not be saved!"}
      />
      <div
        className={"flex justify-between flex-col sm:flex-row mb-3 p-0 w-full"}
      >
        <p className={"text-xl font-medium mb-1"}>Documentation</p>
        {update && (
          <Button
            className={PRIMARY_BUTTON_STYLE}
            onClick={() => saveLeakageData()}
          >
            Save
          </Button>
        )}
      </div>
      <div className={"h-full"}>
        {template && leakageData && (
          <LeakageTable
            editable={canEdit}
            elementId={id}
            onUpdateData={onUpdateData}
            data={leakageData}
            template={template}
            companies={companies}
          />
        )}
      </div>
    </div>
  );
};
