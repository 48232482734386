import React, { FC, useEffect, useState } from "react";
import { Button, Form, message, Modal, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { CustomAvatar } from "../general/CustomAvatar";
import { UserType } from "../../utils/types";
import { filterOption, populateArrayWithPropertyPath } from "../../utils/utils";
import { formItemRequiredRule } from "../../utils/formUtils";

export const ShareNoteForm: FC<ShareNoteFormType> = ({
  noteSharedWith,
  onSubmit,
  dealTeam,
  hidden,
}: ShareNoteFormType) => {
  const [form] = useForm();

  const [shareModal, setShareModal] = useState<boolean>(false);
  const [noteTeam, setNoteTeam] = useState<UserType[]>([]);

  useEffect(() => {
    const userSet = new Set(
      populateArrayWithPropertyPath(["userId"], noteSharedWith)
    );
    setNoteTeam(
      dealTeam.filter(({ userId }: UserType) => !userSet.has(userId))
    );
  }, [noteSharedWith, dealTeam]);

  return (
    <>
      {!hidden && (
        <Button
          className={
            "border-0 p-0 shadow flex items-center justify-center bg-gray-50 motion-safe:hover:font-medium text-gray-500 shadow-[0_2px_4px_rgba(0,0,0,0.18)]"
          }
          onClick={(): void => {
            noteTeam.length > 0 && noteSharedWith?.length !== dealTeam?.length
              ? setShareModal(true)
              : message.info({
                  key: "already-sharing",
                  content: "Already sharing with all Deal Team Members",
                });
          }}
          shape={"circle"}
          icon={
            <i
              className={`far fa-share-square ${
                noteSharedWith?.length > 1 ? "text-blue-500" : "text-gray-400"
              }`}
            />
          }
        />
      )}
      <Modal
        open={shareModal}
        title={"Share Note with Users"}
        closable={true}
        onCancel={(): void => setShareModal(false)}
        onOk={(): void => {
          setShareModal(false);
        }}
        getContainer={false}
        footer={false}
      >
        <Form
          form={form}
          onFinish={(val): void => {
            onSubmit(val);
            form.resetFields();
            setShareModal(false);
          }}
        >
          <Form.Item
            name={"userIds"}
            style={{ width: "100%" }}
            rules={[formItemRequiredRule]}
          >
            <Select
              notFoundContent={"No Users Available"}
              getPopupContainer={(trigger): HTMLElement =>
                trigger.parentElement
              }
              mode={"multiple"}
              maxTagCount={"responsive"}
              filterOption={filterOption}
              placeholder={"Select Users"}
              autoFocus={true}
            >
              {noteTeam?.map(({ userId, firstName, lastName }) => (
                <Select.Option key={userId} value={userId}>
                  <div className={"container w-full"}>
                    <div className={"flex flex-row"}>
                      <CustomAvatar
                        data={firstName[0] + lastName[0]}
                        color={firstName[0]}
                        size={"small"}
                      />
                      {firstName} {lastName}
                    </div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item className={"m-0"}>
            <div className={"flex flex-row gap-y-1 gap-x-2"}>
              <Button
                className={
                  "bg-primary hover:bg-hover text-white border-0 ml-auto"
                }
                htmlType={"submit"}
              >
                Share
              </Button>
              <Button
                className={
                  "bg-white border hover:border-primary hover:text-primary"
                }
                onClick={(): void => {
                  form.resetFields();
                }}
              >
                Clear
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

type ShareNoteFormType = {
  noteSharedWith: UserType[];
  onSubmit: (o: { userIds: string[] }) => void;
  dealTeam: UserType[];
  hidden: boolean;
};
