import React, { FC, ReactNode, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
} from "antd";
import { DeadLineType } from "../../utils/types";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { voidType } from "../../utils/uiTypes";
import { deleteDeadlineEvent } from "../../services/services";
import moment from "moment";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { isEmpty } from "ramda";

export const DeleteDeadlineModal: FC<DeleteDeadlineFormType> = ({
  deadline,
  visible,
  setVisible,
  onDelete,
}: DeleteDeadlineFormType) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const handleFormValuesChange: handleFormValuesChangeType = (e) =>
    form.setFieldsValue({ sendEmail: e.target.value });

  const deleteDeadlineEventItem: voidType = () => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    formValues.lenderDTOS = deadline.lenderDTOS.filter(
      (lender) => formValues.lenderDTOS?.indexOf(lender.id) >= 0
    );
    const deleteDeadline = {
      ...deadline,
      lenderDTOS: formValues.lenderDTOS,
      sendEmail: formValues.sendEmail,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      note: formValues.note,
    };
    deleteDeadlineEvent({
      body: JSON.stringify(deleteDeadline),
    })
      .then(() => {
        onDelete(deadline.id);
        setLoading(false);
      })
      .then(() => {
        setVisible(false);
        setLoading(false);
      });
  };
  const selectAllLenders = (e: CheckboxChangeEvent): void => {
    if (deadline)
      form.setFieldsValue({
        lenderDTOS: e.target.checked
          ? deadline.lenderDTOS.map((lender) => lender.id)
          : [],
      });
  };
  return (
    <Modal
      open={visible}
      className={"px-8"}
      closable={false}
      destroyOnClose={true}
      title={
        <div>
          <QuestionCircleOutlined className={"text-xl text-red-500 mr-4"} />
          Confirm Delete Deadline?
        </div>
      }
      footer={[
        <Button
          disabled={loading}
          key={"cancel"}
          onClick={(): void => setVisible(false)}
          className={"border-primary text-primary"}
        >
          Cancel
        </Button>,
        <Button
          loading={loading}
          disabled={loading}
          key={"delete"}
          className={"bg-danger text-white border-0"}
          onClick={(): void => deleteDeadlineEventItem()}
        >
          Delete
        </Button>,
      ]}
      centered={true}
    >
      <React.Fragment>
        Title: {deadline.title}
        <br />
        Target Date: {moment(Number(deadline.targetDate)).format("DD MMM YYYY")}
        <br />
      </React.Fragment>
      <Form
        form={form}
        className={"mt-4"}
        name="form"
        layout={"vertical"}
        onFinish={(): void => {
          setVisible(false);
          deleteDeadlineEventItem();
        }}
        initialValues={{ lenderDTOS: [], sendEmail: false }}
      >
        <Form.Item
          name={"sendEmail"}
          label="Would you like to notify institutions?"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group onChange={handleFormValuesChange}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues): boolean =>
            prevValues.sendEmail !== currentValues.sendEmail
          }
        >
          {({ getFieldValue }): ReactNode =>
            getFieldValue("sendEmail") === true ? (
              <>
                <Form.Item label="Select institutions" name={"lenderDTOS"}>
                  <Select
                    notFoundContent={"No Institutions Available"}
                    getPopupContainer={(trigger): HTMLElement =>
                      trigger.parentElement
                    }
                    mode="multiple"
                    maxTagCount={"responsive"}
                    placeholder="Select institution(s)"
                    filterOption={(input, option): boolean =>
                      option?.title
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {!isEmpty(deadline.lenderDTOS ?? []) && (
                      <Select.Option
                        className={"cursor-pointer"}
                        value={"none"}
                        disabled={true}
                      >
                        <Checkbox
                          className={"w-full"}
                          style={{ lineHeight: "32px" }}
                          onChange={selectAllLenders}
                        >
                          Select All Institutions
                        </Checkbox>
                      </Select.Option>
                    )}
                    {deadline.lenderDTOS &&
                      deadline.lenderDTOS
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((lender) => (
                          <Select.Option
                            key={lender.id}
                            value={lender.id}
                            title={lender.name}
                          >
                            {lender.name}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
                <Form.Item name={"note"}>
                  <Input.TextArea
                    placeholder={"(Optional) Write a message..."}
                  />
                </Form.Item>
              </>
            ) : null
          }
        </Form.Item>
      </Form>
    </Modal>
  );
};

type DeleteDeadlineFormType = {
  deadline: DeadLineType;
  visible: boolean;
  setVisible: (a: boolean) => void;
  onDelete: (id: string) => void;
};
type handleFormValuesChangeType = (e: RadioChangeEvent) => void;
