import React, { FC } from "react";
import { Button, Modal } from "antd";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import { voidType } from "../../../utils/uiTypes";

export const CreateVersionConfirmationModal: FC<CreateVersionConfirmationModalType> =
  function ({
    loading,
    open,
    onConfirm,
    onCancel,
  }: CreateVersionConfirmationModalType) {
    return open ? (
      <Modal
        width={700}
        destroyOnClose
        visible
        closable
        maskClosable={false}
        footer={[
          <Button key="back" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            loading={loading}
            disabled={loading}
            key="submit"
            type="primary"
            className={PRIMARY_BUTTON_STYLE}
            onClick={onConfirm}
          >
            Proceed
          </Button>,
        ]}
        onCancel={onCancel}
        title={<span className={"text-xs text-secondary"}>CONFIRMATION</span>}
      >
        <div>
          <p className={"text-xs"}>
            Please note: Creating a new version will lock all previous versions.
            They will remain accessible but uneditable. Do you wish to proceed?
          </p>
        </div>
      </Modal>
    ) : (
      <></>
    );
  };

type CreateVersionConfirmationModalType = {
  loading: boolean;
  open: boolean;
  onCancel: voidType;
  onConfirm: voidType;
};
