import React from "react";
import { CompareSheetType } from "../../../../utils/types";
import { TreeSelectProps } from "antd";
import { getKeyByType } from "./key-by-type";

export const getSheetsForTree: getSheetsForTreeType = (sheets = []) => {
  return sheets?.map((item) => {
    return {
      title: (
        <div>
          <div>
            {item?.termSheetName}&nbsp;
            <span className={"text-xxs"}>(v.{item?.version})</span>
          </div>
          <div className={"text-xxs leading-tight"}>
            {item?.transactionName}
          </div>
        </div>
      ),
      label: item?.termSheetName,
      value: item?.sheetId,
      key: item?.sheetId,
      children: [
        {
          title: item.isPortfolio ? "Category" : "Bid Metric",
          value: getKeyByType(item?.sheetId, "BID_METRIC"),
          key: getKeyByType(item?.sheetId, "BID_METRIC"),
        },
        {
          title: item.isPortfolio
            ? "Agreed Position"
            : `${item?.dealTeamName || "Deal Pe"} Ask`,
          value: getKeyByType(item?.sheetId, "ASK"),
          key: getKeyByType(item?.sheetId, "ASK"),
        },
        ...(item?.lenderKeys && item?.lenderKeys.length > 0
          ? [
              {
                title: "Responses",
                value: getKeyByType(item?.sheetId, "RESPONSE", "ALL"),
                key: getKeyByType(item?.sheetId, "RESPONSE", "ALL"),
                children: item?.lenderKeys.map((val: string) => ({
                  title: val,
                  value: getKeyByType(item?.sheetId, "RESPONSE", val),
                  key: getKeyByType(item?.sheetId, "RESPONSE", val),
                })),
              },
            ]
          : []),
      ],
    };
  });
};

type getSheetsForTreeType = (
  sheets: CompareSheetType[]
) => TreeSelectProps["treeData"];
