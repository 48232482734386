import React, { FC, ReactNode, useState } from "react";
import {
  getModalContainerForFullScreen,
  getPopupContainerForSelect,
} from "../../../../utils/container";
import { Modal, Select, Table, DatePicker, Button } from "antd";
import { CurrencySymbolType, CurrencyType } from "../../../../utils/enums";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import { isEmpty, path, pathOr } from "ramda";
import EditableTableCell from "../../../general/EditableTableCell";
import {
  AllocationSummaryWrapper,
  SummaryTableDataType,
} from "../../../../utils/types";
import moment from "moment/moment";
import { AllocationTableVersionTypes } from "../../../../utils/allocation";
import { ColumnType } from "antd/lib/table";
import { onSort } from "../../../../utils/table-utils";
import { ACTION_BUTTON_CSS } from "../../../../utils/cssConfigs";
import { DownloadOutlined } from "@ant-design/icons";
export const AllocationSummaryModal: FC<AllocationSummaryModalComponent> = ({
  title,
  visible,
  onCancel,
  loading,
  tableData,
  fxRateDate,
  onChangeDate,
  onDownload,
}) => {
  const { height: windowHeight } = useWindowDimensions();
  const [currency, setCurrency] = useState<CurrencyType>(CurrencyType.DOLLAR);

  const data = remapData(tableData?.allocationSummaryDTOS ?? [], currency);
  const { columns, dataSource, totalRow } = transformTableData(data, currency);

  const renderFXRate = (targetCurrency: CurrencyType) => {
    if (!tableData?.fxRateMap) {
      return ``;
    }

    const rateValues: Record<CurrencyType, string> = pathOr(
      {} as Record<CurrencyType, string>,
      ["fxRateMap", targetCurrency],
      tableData
    );

    if (!isEmpty(rateValues)) {
      return Object.entries(rateValues)
        .map(([key, value]) => {
          return `1 ${CurrencySymbolType[key as CurrencyType]} = ${value} ${
            CurrencySymbolType[targetCurrency]
          }`;
        })
        .join(`  |  `);
    }
  };

  return (
    <Modal
      getContainer={getModalContainerForFullScreen}
      title={title}
      open={visible}
      width={1000}
      onCancel={onCancel}
      destroyOnClose={true}
      footer={null}
    >
      <div className={"flex flex-row justify-between group items-center"}>
        <Select
          getPopupContainer={getPopupContainerForSelect}
          className={"select-none"}
          value={currency}
          onSelect={(_: string, option): void => {
            setCurrency(option.value);
          }}
          options={Object.values(CurrencyType).map((value) => ({
            label: CurrencySymbolType[value],
            value,
          }))}
        />

        <div>
          <div className={"flex flex-row gap-x-2 items-center justify-end"}>
            {"FX Rate (as of):"}
            <DatePicker
              value={moment(fxRateDate)}
              onChange={(date) =>
                onChangeDate(moment(date).format("YYYY-MM-DD"))
              }
              allowClear={false}
              disabledDate={(current) => current > moment().endOf("day")}
              disabled={loading}
            />
            <Button
              type={"text"}
              icon={<DownloadOutlined />}
              onClick={() => onDownload(fxRateDate, currency)}
              className={`${ACTION_BUTTON_CSS}`}
              disabled={!dataSource.length}
            />
          </div>
        </div>
      </div>

      <Table<SummaryTableDataType[]>
        loading={false}
        pagination={false}
        columns={columns}
        rowKey={(record, index): string =>
          pathOr("", [0, "id"], record) ?? index
        }
        dataSource={dataSource}
        scroll={{ x: "max-content", y: windowHeight / 3 }}
        bordered={true}
        className={toolbar ? "mt-4 shadow" : ""}
        size={"middle"}
        locale={{
          emptyText:
            isEmpty(tableData) &&
            "There are no institutions in this table yet.",
        }}
        summary={(): ReactNode => (
          <Table.Summary fixed={true}>
            <Table.Summary.Row className={"bg-gray-100"}>
              <Table.Summary.Cell
                className={"p-0 !z-20"}
                key={"addRow"}
                index={0}
              >
                <div className="p-4 !bg-gray-500 font-bold whitespace-nowrap">
                  {"TOTAL SUM"}
                </div>
              </Table.Summary.Cell>

              {Object.values(totalRow).map((value, index) => (
                <Table.Summary.Cell
                  key={index}
                  index={index}
                  colSpan={1}
                  className={"p-0"}
                >
                  {value as ReactNode}
                </Table.Summary.Cell>
              ))}
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
      <div className={"pt-4 whitespace-pre-wrap"}>
        Summary ={" "}
        {tableData?.versions
          ?.map(
            ({ id, name, allocationDate, tableVersionType }) =>
              `${name} ${
                tableVersionType || allocationDate
                  ? `(${
                      tableVersionType
                        ? `${AllocationTableVersionTypes[tableVersionType]?.label},`
                        : ""
                    } ${
                      allocationDate ? moment(allocationDate).format("ll") : ""
                    })`
                  : ""
              }`
          )
          .join(" + ")}
      </div>
      <div className={"mt-2"}>{renderFXRate(currency)}</div>
    </Modal>
  );
};

const remapData = (
  dataset: SummaryTableDataType[],
  currency: CurrencyType
): any[][] => {
  const uniqueAllocationKeys = Array.from(
    new Set(dataset.map((item) => item.allocationKeyDTO.keyName))
  ).sort();
  const uniqueCompanies = Array.from(
    new Set(dataset.map((item) => item.companyDTO.name))
  ).sort();

  const result: any[][] = [];

  // Header row
  const headerRow = ["Institution", ...uniqueAllocationKeys];
  result.push(headerRow);

  // Data rows
  uniqueCompanies.forEach((companyName) => {
    const companyData = dataset.filter(
      (item) => item.companyDTO.name === companyName
    );
    const dataRow = [companyName];

    uniqueAllocationKeys.forEach((allocationKey) => {
      const matchingItem = companyData.find(
        (item) => item.allocationKeyDTO.keyName === allocationKey
      );
      if (currency === CurrencyType.DOLLAR) {
        dataRow.push(
          matchingItem ? matchingItem.totalConvertedAmount.DOLLAR : "0.0"
        );
      } else if (currency === CurrencyType.EURO) {
        dataRow.push(
          matchingItem ? matchingItem.totalConvertedAmount.EURO : "0.0"
        );
      } else if (currency === CurrencyType.POUND) {
        dataRow.push(
          matchingItem ? matchingItem.totalConvertedAmount.POUND : "0.0"
        );
      } else if (currency === CurrencyType.KRONA) {
        dataRow.push(
          matchingItem ? matchingItem.totalConvertedAmount.KRONA : "0.0"
        );
      } else if (currency === CurrencyType.KRONER) {
        dataRow.push(
          matchingItem ? matchingItem.totalConvertedAmount.KRONER : "0.0"
        );
      } else if (currency === CurrencyType.KRONE) {
        dataRow.push(
          matchingItem ? matchingItem.totalConvertedAmount.KRONE : "0.0"
        );
      } else if (currency === CurrencyType.AUSTRALIAN_DOLLAR) {
        dataRow.push(
          matchingItem
            ? matchingItem.totalConvertedAmount.AUSTRALIAN_DOLLAR
            : "0.0"
        );
      } else if (currency === CurrencyType.CANADIAN_DOLLAR) {
        dataRow.push(
          matchingItem
            ? matchingItem.totalConvertedAmount.CANADIAN_DOLLAR
            : "0.0"
        );
      } else if (currency === CurrencyType.HONG_KONG_DOLLAR) {
        dataRow.push(
          matchingItem
            ? matchingItem.totalConvertedAmount.HONG_KONG_DOLLAR
            : "0.0"
        );
      } else if (currency === CurrencyType.YEN) {
        dataRow.push(
          matchingItem ? matchingItem.totalConvertedAmount.YEN : "0.0"
        );
      } else if (currency === CurrencyType.SINGAPORE_DOLLAR) {
        dataRow.push(
          matchingItem
            ? matchingItem.totalConvertedAmount.SINGAPORE_DOLLAR
            : "0.0"
        );
      } else {
        dataRow.push(
          matchingItem ? matchingItem.totalConvertedAmount.FRANC : "0.0"
        );
      }
    });

    result.push(dataRow);
  });

  return result;
};

const transformTableData = (
  inputArray: any[][],
  currency: CurrencyType
): {
  columns: ColumnType<SummaryTableDataType[]>[];
  dataSource: any[];
  totalRow: any;
} => {
  const columns: ColumnType<SummaryTableDataType[]>[] = [];
  const dataSource: any[] = [];

  // Extract header row for columns
  const headerRow = inputArray[0];
  const institutionColumn = {
    title: (
      <div className="p-4 text-white bg-gray-700 whitespace-nowrap">
        {headerRow[0]}
      </div>
    ),
    dataIndex: headerRow[0],
    key: headerRow[0],
    className: "p-0 whitespace-nowrap !z-20 !bg-gray-100",
    fixed: true,
    width: 240,
  };
  columns.push(institutionColumn);

  const columnTotals: { [key: string]: number } = {};

  for (let i = 1; i < headerRow.length; i++) {
    const column = {
      title: (
        <div className="p-4 text-white bg-gray-500 whitespace-nowrap">
          {headerRow[i]}
        </div>
      ),
      sorter: (a: SummaryTableDataType[], b: SummaryTableDataType[]) => {
        return onSort(
          path([headerRow[i], "props", "value"], a),
          path([headerRow[i], "props", "value"], b)
        );
      },
      dataIndex: headerRow[i],
      key: headerRow[i],
      className: "p-0 whitespace-nowrap",
      width: 240,
    };
    columns.push(column);
    columnTotals[headerRow[i]] = 0; // Initialize column total
  }

  const totalColumn = {
    title: (
      <div className="p-4 text-white bg-gray-700 whitespace-nowrap">
        {"Total"}
      </div>
    ),
    dataIndex: "Total",
    key: "Total",
    className: "p-0 whitespace-nowrap !bg-gray-100",
    width: 240,
    sorter: (a: SummaryTableDataType[], b: SummaryTableDataType[]) => {
      return onSort(
        path(["Total", "props", "value"], a),
        path(["Total", "props", "value"], b)
      );
    },
  };
  columns.push(totalColumn);

  // Extract data rows for dataSource
  for (let i = 1; i < inputArray.length; i++) {
    const dataRow = inputArray[i];
    const row: any = {
      key: dataRow[0],
      Institution: <div className="p-4 whitespace-nowrap">{dataRow[0]}</div>,
    };

    let rowTotal = 0;

    for (let j = 1; j < dataRow.length; j++) {
      row[headerRow[j]] = (
        <EditableTableCell
          OId={`${j}-total`}
          value={Number(parseFloat(dataRow[j])).toFixed(2)}
          defaultCell={false}
          editable={false}
          type={"number"}
          placeholder={"-"}
          prefix={
            <span className={"text-muted"}>{CurrencySymbolType[currency]}</span>
          }
          className={"!bg-zinc-50"}
        />
      );
      rowTotal += Number(parseFloat(dataRow[j]).toFixed(2));
      columnTotals[headerRow[j]] += Number(parseFloat(dataRow[j]).toFixed(2));
    }

    row.Total = (
      <EditableTableCell
        OId={`${i}-total`}
        value={Number(rowTotal).toFixed(2)}
        defaultCell={false}
        editable={false}
        type={"number"}
        placeholder={"-"}
        prefix={
          <span className={"text-muted"}>{CurrencySymbolType[currency]}</span>
        }
        className={"!bg-zinc-50"}
      />
    );
    dataSource.push(row);
  }

  const totalRow: any = {};

  for (let i = 1; i < headerRow.length; i++) {
    totalRow[headerRow[i]] = (
      <EditableTableCell
        OId={`total-${i}`}
        value={Number(columnTotals[headerRow[i]]).toFixed(2)}
        defaultCell={false}
        editable={false}
        type={"number"}
        placeholder={"-"}
        prefix={
          <span className={"text-muted"}>{CurrencySymbolType[currency]}</span>
        }
        className={"!bg-zinc-50 font-bold"}
      />
    );
  }

  totalRow.Total = (
    <EditableTableCell
      OId={"total-total"}
      value={Number(
        Object.values(columnTotals).reduce((sum, value) => sum + value, 0)
      ).toFixed(2)}
      defaultCell={false}
      editable={false}
      type={"number"}
      placeholder={"-"}
      prefix={
        <span className={"text-muted"}>{CurrencySymbolType[currency]}</span>
      }
      className={"!bg-zinc-50 font-bold"}
    />
  );

  return { columns, dataSource, totalRow };
};

export type onDownloadType = (
  fxRateDate: string,
  currency: CurrencyType
) => void;

type AllocationSummaryModalComponent = {
  title: string;
  visible: boolean;
  onCancel: () => void;
  loading: boolean;
  tableData: AllocationSummaryWrapper | null;
  fxRateDate: string;
  onChangeDate: (fxRateDate: string) => void;
  onDownload: onDownloadType;
};
