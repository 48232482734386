import React, { FC, useContext, useEffect, useState } from "react";
import { Button, message, Space, Switch, Tooltip } from "antd";
import {
  CompareColumnsType,
  UiDataType,
  WebFormRedLineType,
} from "../../../utils/types";
import { TermsheetContext } from "../../../context/TermsheetContext";
import { DANGER_BUTTON_STYLE } from "../../../utils/cssConfigs";
import { ComparisonSheetRows } from "./ComparisonSheetRows";
import { CompareSheetSelectedCell } from "../../../utils/comparison-sheet";
import {
  generateReadlineExcel,
  getTermsheetColumns,
} from "../../../services/services";
import { FileResponseType, ResponseType } from "../../../utils/uiTypes";
import { ConfirmDownload } from "../../../utils/confirmationModals";
import { Spinner } from "../../general/Spinner";
import { ColumnSettings } from "../common/ColumnSettings";
import { ToolBar } from "../common/ToolBar";
import { pluck, values } from "ramda";

const getMaxDataLength = (columns: CompareColumnsDataMapEditableType[]) => {
  const datas: Record<number, UiDataType>[] = pluck("data")(columns);
  const valueLengths = datas.map((v) => values(v).length);
  return Math.max(...valueLengths);
};

export const ComparisonSheet: FC<TermsheetType> = function ({
  value,
}: TermsheetType) {
  const { setColumns } = useContext(TermsheetContext);
  const [downloading, setDownloading] = useState(false);
  const [data, setData] = useState<CompareColumnsEditableType | null>(null);
  const [heatMap, setHeatMap] = useState<boolean>(false);
  const [redLine, setRedLine] = useState<boolean>(false);
  const [selectedCell, setSelectedCell] =
    useState<CompareSheetSelectedCell | null>(null);

  const onDownload: onDownloadType = (wordFormat) => {
    setDownloading(true);
    generateReadlineExcel({
      params: {
        generateRedLine: redLine,
        generateHeatMap: heatMap,
        wordFormat,
      },
      body: JSON.stringify(value),
    })
      .then(({ url, filename }: FileResponseType) => {
        message.success({ key: "download", content: "File Ready to Download" });
        setDownloading(false);
        ConfirmDownload(
          filename,
          url,
          wordFormat
            ? "Open downloaded Word Document in Web Layout format on MS Word for better visibility of multiple columns"
            : ""
        );
      })
      .catch((error: string) => {
        console.error(error);
        setDownloading(false);
        message.error("Invalid column selection");
      });
  };

  useEffect(() => {
    getTermsheetColumns({
      body: JSON.stringify(value),
    })
      .then(({ data }: ResponseType<CompareColumnsType>) => {
        setData({
          metrics: { ...data.metrics, visible: true },
          base: { ...data.base, visible: true },
          columns: data?.columns?.map((v) => ({ ...v, visible: true })) ?? [],
        });
      })
      .catch((error: string) => {
        console.error(error);
        setColumns(null);
        message.error("Invalid column selection");
      });
  }, []);

  const visibleColumns = data?.columns?.filter((v) => v.visible) ?? [];
  return data ? (
    <div className={`relative h-full w-full pb-[50px]`}>
      <ToolBar
        editName={false}
        name={"Comparison Sheet"}
        disableActions={false}
        downloading={downloading}
        downloadItems={[
          {
            label: "Download as Excel Sheet",
            key: "DOWNLOAD",
            show: true,
          },
          {
            label: "Download as Word",
            key: "DOWNLOAD_WORD",
            show: true,
          },
        ]}
        onStyleChange={() => {}}
        onButtonClick={(action) => {
          switch (action) {
            case "DOWNLOAD":
              return onDownload(false);
            case "DOWNLOAD_WORD":
              return onDownload(true);
            default:
              return;
          }
        }}
        onNameChange={() => {}}
      >
        <Space className={"gap-2 inline-flex h-full items-center"}>
          <ColumnSettings settings={data} onSave={setData} />

          <Tooltip placement="bottom" title={"Heat Map"}>
            <Switch
              defaultChecked={false}
              checked={heatMap}
              className={`${heatMap ? "bg-primary" : "bg-muted"}`}
              onChange={setHeatMap}
            />
            <span className={"text-xs font-medium pl-2"}>Heat Map</span>
          </Tooltip>
          <Tooltip placement="bottom" title={"Redline"}>
            <Switch
              defaultChecked={false}
              checked={redLine}
              className={`${redLine ? "bg-primary" : "bg-muted"}`}
              onChange={setRedLine}
            />
            <span className={"text-xs font-medium pl-2"}>Redline</span>
          </Tooltip>
          <Button
            className={DANGER_BUTTON_STYLE}
            onClick={() => {
              setColumns(null);
            }}
          >
            Close
          </Button>
        </Space>
      </ToolBar>
      <div className={"max-h-full h-full w-full"}>
        <div
          className={
            "relative max-h-full w-full overflow-x-auto overscroll-none scroll-smooth"
          }
        >
          <ComparisonSheetRows
            rows={getMaxDataLength(visibleColumns)}
            redLine={redLine}
            heatMap={heatMap}
            selectedCell={selectedCell}
            onSelectCell={setSelectedCell}
            data={{ ...data, columns: visibleColumns }}
            onChange={setData}
          />
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

type onDownloadType = (wordFormat: boolean) => void;
export type CompareColumnsDataMapEditableType = {
  date: string;
  visible?: boolean;
  label: string;
  data: Record<number, UiDataType>;
};
export type CompareColumnsEditableType = {
  metrics: CompareColumnsDataMapEditableType;
  base: CompareColumnsDataMapEditableType;
  columns: CompareColumnsDataMapEditableType[];
};

type TermsheetType = {
  value: WebFormRedLineType;
};
