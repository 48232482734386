import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { PortfolioCard } from "../PortfolioCard";
import { useParams } from "react-router";
import { Button, message, Select, Table } from "antd";
import {
  FileResponseType,
  ResponseType,
  voidType,
} from "../../../../utils/uiTypes";
import {
  CapitalStructureValuesType,
  CompanyType,
  CovenantTableFieldDTO,
  HedgingDataType,
  HedgingValuesType,
  PortfolioSectionFieldType,
  PortfolioSectionType,
} from "../../../../utils/types";
import {
  PermissionType,
  SectionFieldType,
  UnitLabelType,
  UnitType,
} from "../../../../utils/enums";
import moment from "moment";
import { initiateFetchSection } from "../../../../utils/portfolio";
import { ColumnsType } from "antd/es/table";
import {
  assocPath,
  equals,
  findIndex,
  isEmpty,
  isNil,
  pathOr,
  prop,
  sortBy,
  transpose,
} from "ramda";
import EditableTableCell from "../../../general/EditableTableCell";
import {
  addHedgingRow,
  deleteHedgingRow,
  downloadHedgingStructure,
  updateHedgingFields,
  updateHedgingRow,
  updatePortfolioSectionFieldAnswer,
} from "../../../../services/services";
import { ColumnType } from "antd/es/table/interface";
import { ACTION_BUTTON_CSS } from "../../../../utils/cssConfigs";
import {
  ConfirmDelete,
  ConfirmDownload,
} from "../../../../utils/confirmationModals";
import {
  getObjectFromPropertyValue,
  valOrDefault,
} from "../../../../utils/utils";
import { PortfolioAdminPermissionBlock } from "../../PortfolioAdminPermissionBlock";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import {
  FullScreenButton,
  FullScreenWidget,
} from "../../../general/FullScreenWidget";
import Title from "antd/es/typography/Title";
import { getContainerForFullScreen } from "../../../../utils/container";
import { DownloadOutlined } from "@ant-design/icons";
const sortByRank = sortBy<HedgingDataType>(prop("rank"));

export const Hedging: FC<HedgingType> = ({ permissions, companies }) => {
  const { height: windowHeight } = useWindowDimensions();

  const { portfolioId, sectionId }: ParamsType = useParams();

  const [section, setSection] = useState<PortfolioSectionType | null>(null);
  const [addRowLoading, setAddRowLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [lastUpdated, setLastUpdated] = useState<string>("--/--/----");
  const [hedgingTableFields, setHedgingTableFields] = useState<
    HedgingDataType[]
  >([]);

  const getFieldTypeData = (
    type: SectionFieldType,
    fieldUnit?: UnitType
  ): FieldDataType => {
    switch (type) {
      case "TEXT":
        return { type: "textarea" };
      case "DROP_BOX":
        return { type: "select" };
      case "DATE":
        return { type: "date" };
      case "MONETARY":
        return {
          type: "number",
          unit: UnitLabelType[fieldUnit ?? UnitType.MILLION],
          currency: section?.reportingCurrency ?? "",
        };
      case "NUMERIC":
        return { type: "number", unit: fieldUnit };
      case "LONG_TEXT":
        return { type: "textarea" };
      default:
        return { type: "textarea" };
    }
  };

  const deleteRow = (rank: string): void => {
    deleteHedgingRow({
      segments: {
        portfolioId,
        sectionId,
        rank,
      },
    })
      .then(({ data }: ResponseType<HedgingDataType[]>) => {
        setHedgingTableFields(() => sortByRank(data));
      })
      .then(updateAsOfDate)
      .catch((error: string) => {
        console.error(error);
        message.error("Something went wrong. Please try again");
      });
  };

  const deleteCol: ColumnType<Record<string, HedgingValuesType>> = {
    title: <div className="p-4 whitespace-nowrap bg-gray-700">&nbsp;</div>,
    dataIndex: "",
    key: "",
    width: "full",
    fixed: "right",
    align: "right" as const,
    className:
      "p-0 whitespace-nowrap  group-hover:bg-blue-50 transition-none min-w-[50px] !bg-gray-100",
    render: function actions(_: string, record, index): ReactNode {
      return (
        <span
          className={
            "relative flex flex-row flex-row-reverse items-center gap-x-3 pr-2"
          }
          onClick={(event): void => event.stopPropagation()}
        >
          <Button
            type={"text"}
            icon={<i className="fas fa-ellipsis-h" />}
            className={
              "absolute right-0 border-0 flex items-center px-2 text-gray-900 block opacity-50 group-hover:opacity-0 mr-2"
            }
          />
          <Button
            type={"text"}
            icon={<i className="fas fa-trash-alt" />}
            className={`${ACTION_BUTTON_CSS}  hover:text-primary opacity-0 group-hover:opacity-100`}
            onClick={(): void => {
              ConfirmDelete(
                "Are you sure you want to remove this row? This will erase all previously saved data.",
                () => deleteRow(pathOr("", ["counterparty", "rank"], record)),
                <></>
              );
            }}
          />
        </span>
      );
    },
  };

  const getHedgingColumns = (
    data: HedgingDataType[]
  ): ColumnsType<Record<string, HedgingValuesType>> =>
    data
      .sort((a, b) => (parseInt(a?.rank) > parseInt(b?.rank) ? 1 : -1))
      .filter(({ tag }) => tag !== "currency")
      .map(({ label, fieldType, rank, unit, id = "", options, tag }) => {
        const isCounterParty = tag === "counterparty";
        return {
          title: (
            <div
              className={`p-4 whitespace-nowrap text-white ${
                equals(Number(rank), 0) ? "bg-gray-700" : "bg-gray-500"
              }`}
            >
              {label}
            </div>
          ),
          className: `p-0 whitespace-nowrap min-w-[10rem] ${
            equals(Number(rank), 0) && "!bg-gray-100 !z-20"
          }`,
          fixed: equals(Number(rank), 0) ? "left" : false,
          dataIndex: tag,
          render: (data: HedgingValuesType): ReactNode => {
            const type = getFieldTypeData(fieldType, unit);
            const dropdownOptions =
              isEmpty(options) || isNil(options) ? [] : JSON.parse(options);

            return (
              <EditableTableCell
                editable={permissions.includes(PermissionType.ADMIN_PORTFOLIO)}
                bordered={false}
                OId={data?.id ?? ""}
                value={data.value}
                onChange={(val): void => {
                  updateRow({ ...data, value: val.toString() }, id);
                }}
                defaultCell={false}
                placeholder={"-"}
                prefix={
                  type?.currency && section?.reportingCurrency
                    ? section.reportingCurrency
                    : undefined
                }
                suffix={type?.unit || unit}
                type={type?.type}
                multiple={isCounterParty}
                selectOptions={
                  isCounterParty
                    ? companies.map(({ name }) => ({
                        value: name,
                        label: name,
                        key: name,
                      }))
                    : fieldType === SectionFieldType.DROP_BOX
                    ? dropdownOptions
                    : undefined
                }
              />
            );
          },
        };
      });

  const filterDataByActiveRanks = (data: HedgingDataType[]) => {
    const activeRanks: any = {};

    // Find the objects with tags "effectiveDate" and "maturityDate"
    const effectiveDateObj = data.find((item) => item.tag === "effectivedate");
    const maturityDateObj = data.find((item) => item.tag === "maturitydate");

    // Check if the objects are found
    if (effectiveDateObj && maturityDateObj) {
      // Iterate through their hedgingStructureValues
      effectiveDateObj.hedgingStructureValues.forEach((effective) => {
        const maturity = maturityDateObj.hedgingStructureValues.find(
          (value) => value.rank === effective.rank
        );

        const effectiveDate = moment(parseInt(effective.value ?? ""));
        const maturityDate = moment(parseInt(maturity?.value ?? ""));

        activeRanks[effective.rank] = !!(
          maturity && moment().isBetween(effectiveDate, maturityDate)
        );
      });
    }

    return data.map((item) => {
      return {
        ...item,
        hedgingStructureValues: item.hedgingStructureValues.filter(
          (value) => activeRanks[value.rank]
        ),
      };
    });
  };

  const getSummaryData = (tag: string) => {
    const filteredData = filterDataByActiveRanks(hedgingTableFields);

    if (tag === "numberOfHedges") {
      const productCol = filteredData.find((col) => col.tag === "product");
      return productCol?.hedgingStructureValues.length;
    }

    if (tag === "hedgesInPlace") {
      const productCol = filteredData.find((col) => col.tag === "product");
      return productCol?.hedgingStructureValues
        ?.map(
          ({ value = "" }) =>
            value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase()
        )
        .join(", ");
    }

    if (tag === "notionalamount") {
      const notionalCol = filteredData.find(
        (col) => col.tag === "notionalamount"
      );
      return notionalCol?.hedgingStructureValues.reduce(
        (acc, item) => acc + Number(item.value),
        0
      );
    }

    if (tag === "percentHedged") {
      const hedgedCol = filteredData.find((col) => col.tag === "hedged");
      return hedgedCol?.hedgingStructureValues.reduce(
        (acc, item) => acc + Number(item.value),
        0
      );
    }

    if (tag === "averageRate") {
      const notionalCol = filteredData.find(
        (col) => col.tag === "notionalamount"
      );
      const ratesCol = filteredData.find((col) => col.tag === "ratecap");

      const numerator = notionalCol?.hedgingStructureValues.reduce(
        (acc, { value = "", rank }) => {
          const rateIndex = findIndex(
            (rate) => rank.toString() === rate.rank.toString(),
            ratesCol?.hedgingStructureValues ?? []
          );
          const rateValue =
            ratesCol?.hedgingStructureValues[rateIndex].value ?? "";

          return acc + Number(value) * Number(rateValue);
        },
        0
      );
      const denominator = notionalCol?.hedgingStructureValues.reduce(
        (acc, { value = "" }) => {
          return acc + Number(value);
        },
        0
      );

      return numerator && denominator
        ? (numerator / denominator).toFixed(2)
        : "--";
    }
    if (tag === "averageMaturity") {
      let result = 0;
      const maturityCol = filteredData.find(
        (item) => item.tag === "maturitydate"
      );
      const effectiveCol = filteredData.find(
        (item) => item.tag === "effectivedate"
      );
      const notionalCol = filteredData.find(
        (col) => col.tag === "notionalamount"
      );

      const totalNotional =
        notionalCol?.hedgingStructureValues.reduce(
          (acc, item) => acc + Number(item.value),
          0
        ) ?? 0;

      notionalCol?.hedgingStructureValues.forEach((notional) => {
        const maturity = maturityCol?.hedgingStructureValues.find(
          (value) => value.rank === notional.rank
        );

        const effective = effectiveCol?.hedgingStructureValues.find(
          (value) => value.rank === notional.rank
        );

        const maturityDate = moment(parseInt(maturity?.value ?? ""));
        const effectiveDate = moment(parseInt(effective?.value ?? ""));

        //tenure
        //notional.value is notional amount
        //totalNotional is total notional amount
        const tenure = maturityDate.diff(effectiveDate, "days");

        const value = (Number(notional.value) / totalNotional) * tenure;
        result += value;
      });
      return isNaN(result) ? "-" : result.toFixed(2);
    }
  };

  const formatSummary = (data: HedgingSummaryData[]) => {
    const summaryObject: any = {};
    data.map((item) => {
      summaryObject[item.tag] = getSummaryData(item.tag);
    });
    return [summaryObject];
  };

  const getSummaryColumns = () =>
    SUMMARY_COLUMNS.map(({ label, fieldType, unit, tag }) => {
      const value = getSummaryData(tag);
      const type =
        unit === "%"
          ? getFieldTypeData(fieldType)
          : getFieldTypeData(fieldType, unit);
      return {
        title: (
          <div className={`p-2 whitespace-nowrap text-white bg-gray-500`}>
            {label}
          </div>
        ),
        className: `p-0 whitespace-nowrap min-w-[12rem]`,
        fixed: false,
        dataIndex: tag,
        render: (): ReactNode => (
          <EditableTableCell
            editable={false}
            bordered={false}
            OId={""}
            value={value}
            defaultCell={false}
            placeholder={"-"}
            prefix={
              type?.currency && section?.reportingCurrency
                ? section.reportingCurrency
                : undefined
            }
            suffix={unit === "%" ? "%" : type?.unit}
            type={type?.type}
            padding={"px-1"}
          />
        ),
      };
    });

  const getHedgingData: voidType = () => {
    initiateFetchSection(sectionId, portfolioId)
      .then((data) => {
        setSection(data);
        setHedgingTableFields(() =>
          sortByRank(data.hedgingStructureTableFieldDTOS)
        );

        const asOfDate: PortfolioSectionFieldType = getObjectFromPropertyValue(
          "fieldNameSystemLabel",
          "asOfDate",
          data?.portfolioSectionFieldDTOS ?? []
        );
        asOfDate &&
          setLastUpdated(moment(Number(asOfDate.answer)).format("MM/DD/YYYY"));
      })
      .catch((e: string) => e && message.error(e))
      .then((): void => setLoading(false));
  };

  const updateAsOfDate: voidType = () => {
    const field = section?.portfolioSectionFieldDTOS.find(
      (item) => item.fieldNameSystemLabel === "asOfDate"
    );
    updatePortfolioSectionFieldAnswer({
      body: JSON.stringify({
        ...field,
        portfolioId,
        answer: moment().valueOf(),
      }),
    })
      .then(() => {
        setLastUpdated(moment(moment().valueOf()).format("MM/DD/YYYY"));
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Something went wrong, input data not saved.", 3);
      });
  };

  const updateRow = (record: HedgingValuesType, fieldId: string): void => {
    updateHedgingRow({
      segments: { portfolioId, sectionId, fieldId },
      body: JSON.stringify(record),
    })
      .then(({ data }: ResponseType<HedgingValuesType>) => {
        const index = findIndex(({ id }) => id === fieldId, hedgingTableFields);
        const valueIndex = findIndex(
          ({ id }) => id.toString() === data.id.toString(),
          hedgingTableFields[index].hedgingStructureValues
        );
        setHedgingTableFields(
          assocPath(
            [index, "hedgingStructureValues", valueIndex],
            data,
            hedgingTableFields
          )
        );
        updateAsOfDate();
      })
      .catch((error: string) => console.error(error));
  };

  const addRow: voidType = () => {
    setAddRowLoading(true);
    addHedgingRow({ segments: { portfolioId, sectionId } })
      .then(({ data }: ResponseType<HedgingDataType[]>) => {
        setHedgingTableFields(() => sortByRank(data));
        setAddRowLoading(false);
      })
      .then(() => updateAsOfDate())
      .catch((error: string) => {
        console.error(error);
        setAddRowLoading(false);
      });
  };

  const onUnitChange = (unit: UnitType) => {
    const updatedFieldsData = hedgingTableFields.map((o) =>
      o.fieldType === SectionFieldType.MONETARY ? { ...o, unit } : o
    );

    updateHedgingFields({
      segments: { portfolioId, sectionId },
      body: JSON.stringify(updatedFieldsData),
    })
      .then(() => {
        setHedgingTableFields(() => sortByRank(updatedFieldsData));
      })
      .then(updateAsOfDate)
      .catch((error: string) => console.error(error));
  };

  const SUMMARY_COLUMNS: HedgingSummaryData[] = useMemo(
    () => [
      {
        fieldType: SectionFieldType.NUMERIC,
        tag: "numberOfHedges",
        label: "# of Hedges",
      },
      {
        fieldType: SectionFieldType.TEXT,
        tag: "hedgesInPlace",
        label: "Hedges in Place",
      },
      {
        fieldType: SectionFieldType.MONETARY,
        tag: "notionalamount",
        label: "Notional Amount",
        unit: getObjectFromPropertyValue(
          "fieldType",
          SectionFieldType.MONETARY,
          hedgingTableFields
        )?.unit,
      },
      {
        fieldType: SectionFieldType.NUMERIC,
        tag: "averageRate",
        label: "Weighted Average Rate",
        unit: "%",
      },
      {
        fieldType: SectionFieldType.NUMERIC,
        tag: "averageMaturity",
        label: "Weighted Average Maturity",
      },
    ],
    [hedgingTableFields]
  );

  const onDownload = (): void => {
    message.loading({
      content: "Processing File",
      duration: 0,
      key: "download-" + name,
    });
    downloadHedgingStructure({
      segments: {
        portfolioId,
        sectionId,
      },
    })
      .then(({ url, filename }: FileResponseType) => {
        setLoading(false);
        message.info({
          content: "File Ready to Download",
          key: "download-" + name,
        });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        setLoading(false);
        message.error({
          content: valOrDefault("Error Downloading File!", error),
          key: "download-" + name,
        });
      });
  };
  useEffect(() => {
    if (portfolioId && sectionId) getHedgingData();
  }, [portfolioId, sectionId]);

  return (
    <PortfolioCard
      permissions={permissions}
      key={section?.id}
      loading={loading}
      bodyStyle={{ padding: "1px 5px 0 5px" }}
      title={section?.sectionName ?? "Hedging"}
    >
      <FullScreenWidget>
        {(isFullScreen, trigger): ReactNode => (
          <div
            className={`${
              isFullScreen &&
              "bg-gray-50 h-screen w-screen overflow-y-scroll p-6"
            } h-full`}
          >
            <div className={"pb-5"}>
              <div className={"flex flex-row items-center gap-2 justify-end"}>
                {isFullScreen && (
                  <Title level={4} className="opacity-90 mr-auto my-auto">
                    Hedging
                  </Title>
                )}
                <PortfolioAdminPermissionBlock permissions={permissions}>
                  <Select
                    getPopupContainer={getContainerForFullScreen}
                    className={"w-36"}
                    value={
                      getObjectFromPropertyValue(
                        "fieldType",
                        SectionFieldType.MONETARY,
                        hedgingTableFields
                      )?.unit
                    }
                    placeholder={"Select Unit"}
                    onSelect={onUnitChange}
                    dropdownMatchSelectWidth={true}
                    options={[
                      { value: "THOUSAND", label: "Thousand (K)" },
                      { value: "MILLION", label: "Million (M)" },
                    ]}
                  />
                </PortfolioAdminPermissionBlock>
                <Button className={`bg-white pointer-events-none`}>
                  {section?.reportingCurrency}
                </Button>
                <Button
                  type={"text"}
                  disabled={!transformData(hedgingTableFields)?.length}
                  icon={<DownloadOutlined />}
                  onClick={onDownload}
                  className={`${ACTION_BUTTON_CSS}`}
                />
                <FullScreenButton
                  isFullScreen={isFullScreen}
                  trigger={trigger}
                />
              </div>
            </div>
            <div className={"mb-10"}>
              <Table
                locale={{ emptyText: "No Data" }}
                className={"border"}
                scroll={{
                  x: true,
                  y: isFullScreen ? windowHeight - 250 : windowHeight / 2.2,
                }}
                bordered={true}
                pagination={false}
                columns={[
                  ...getHedgingColumns(hedgingTableFields),
                  ...(permissions.includes(PermissionType.ADMIN_PORTFOLIO)
                    ? [deleteCol]
                    : []),
                ]}
                rowClassName={"group"}
                rowKey={(record, index) => index ?? ""}
                dataSource={transformData(hedgingTableFields)}
                summary={(data) => (
                  <Table.Summary fixed={true}>
                    <PortfolioAdminPermissionBlock permissions={permissions}>
                      <Table.Summary.Row className={"bg-gray-100"}>
                        <Table.Summary.Cell index={0} className={"p-0 !z-20"}>
                          <div
                            className={
                              "cursor-pointer hover:underline text-blue-400"
                            }
                          >
                            <Button
                              loading={addRowLoading}
                              disabled={addRowLoading}
                              type={"text"}
                              className={
                                "text-left pl-5 border-0 text-primary disabled:border-opacity-60 font-medium w-full hover:bg-blue-50"
                              }
                              onClick={addRow}
                            >
                              + Add New Row
                            </Button>
                          </div>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </PortfolioAdminPermissionBlock>
                  </Table.Summary>
                )}
              />
            </div>

            <Title level={5} className="opacity-90 w-full mb-4 my-auto">
              {"Summary"}
            </Title>
            <Table
              locale={{ emptyText: "No Summary" }}
              className={"border"}
              scroll={{
                x: true,
                y: isFullScreen ? windowHeight - 250 : windowHeight / 2.2,
              }}
              bordered={true}
              pagination={false}
              columns={getSummaryColumns()}
              dataSource={formatSummary(SUMMARY_COLUMNS)}
              rowClassName={"group"}
              rowKey={(record, index) => index ?? ""}
            />
            <div
              className={`flex flex-row items-center justify-between pt-3 px-5`}
            >
              <div className={"text-right ml-auto text-muted"}>
                Last Updated: {lastUpdated}
              </div>
            </div>
          </div>
        )}
      </FullScreenWidget>
    </PortfolioCard>
  );
};

const transformData = (originalData: HedgingDataType[]) => {
  const numRows = originalData[0]?.hedgingStructureValues.length || 0;
  const transformedData: any[] = [];

  for (let i = 0; i < numRows; i++) {
    const row: any = { key: (i + 1).toString() };

    originalData.forEach((field) => {
      const scheduleValue = field.hedgingStructureValues[i];
      if (scheduleValue) {
        row[field.tag] = scheduleValue;
      }
    });
    transformedData.push(row);
  }

  return transformedData;
};

const formatDataSource = (fields: HedgingDataType[]): HedgingValuesType[][] =>
  transpose<HedgingValuesType>(
    fields.reduce(
      (previousValue, currentValue) => [
        ...previousValue,
        currentValue?.hedgingStructureValues?.sort((a, b) =>
          parseInt(a?.rank) > parseInt(b?.rank) ? 1 : -1
        ),
      ],
      [] as HedgingValuesType[][]
    )
  );

type HedgingSummaryData = {
  fieldType: SectionFieldType;
  tag: string;
  label: string;
  unit?: UnitType | "%";
};

type ParamsType = {
  portfolioId: string;
  sectionId: string;
};
type HedgingType = {
  companies: CompanyType[];
  permissions: PermissionType[];
};
type FieldDataType = {
  unit?: string;
  currency?: string;
  type: "number" | "select" | "textarea" | "text" | "date" | undefined;
};
