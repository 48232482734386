import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  removeItemFromArray,
  updateItemInArray,
  valOrDefault,
} from "../../../utils/utils";
import {
  DefaultColumnType,
  HeightWidthMapType,
} from "../../../utils/newTermsheet";
import { KeyValueType, WebFormConfigType } from "../../../utils/types";
import { ColumnHeaderCell } from "../common/ColumnHeaderCell";
import {
  EMPTY_WEB_FORM_CONFIG,
  ItemType,
  RightClickActionType,
  SelectedItemType,
} from "../../../utils/termsheet";
import RowIndexCell from "../common/RowIndexCell";
import Cell from "../common/Cell";
import { useIntersectionView } from "../../../customHooks/useIntersectionView";
import Row from "../common/Row";

export const TemplateTermsheetRows: FC<TemplateTermsheetRowsType> = function ({
  elementId,
  configs = [],
  selectedItem = null,
  onSelectItem,
  onWebFormConfigUpdate,
}: TemplateTermsheetRowsType) {
  const ref = useRef();
  const [rowHeight, setRowHeight] = useState<HeightWidthMapType>({});
  const [columnWidth, setColumnWidth] = useState<HeightWidthMapType>({});
  const [webFormConfigs, setWebFormConfigs] = useState<
    Array<WebFormConfigType>
  >([]);

  /** Right click event logic **/
  const positionIncrementAtIndex: positionIncrementAtIndexType = (
    config,
    index,
    configs = []
  ) => {
    return [
      ...configs.slice(0, index),
      config,
      ...configs.slice(index).map((o) => {
        return { ...o, position: (parseInt(o.position) + 1).toString() };
      }),
    ];
  };

  const onRightClick: onRightClickType = (action, id, index) => {
    switch (action) {
      case "ADD_BELOW":
      case "ADD_ABOVE": {
        const position = webFormConfigs[index]?.position;
        return onWebFormConfigUpdate(
          positionIncrementAtIndex(
            EMPTY_WEB_FORM_CONFIG(position, elementId),
            index,
            webFormConfigs
          )
        );
      }

      case "DELETE": {
        return onWebFormConfigUpdate(
          removeItemFromArray(index, webFormConfigs)
        );
      }
    }
  };

  const onChangeA = useCallback(
    (i: number, text: string) => {
      const config = webFormConfigs[i];
      onWebFormConfigUpdate(
        updateItemInArray(i, webFormConfigs, {
          ...config,
          uiKey: { ...config.uiKey, text },
        })
      );
    },
    [webFormConfigs]
  );

  const onSelectA = useCallback(
    (i: number, style: KeyValueType) => {
      const config = webFormConfigs[i];
      onSelectItem({
        type: ItemType.CELL,
        rowId: config.id,
        rowIndex: i,
        rowPosition: config.position,
        column: DefaultColumnType.BID_METRICS,
        style: style,
      });
    },
    [webFormConfigs]
  );

  const onChangeB = useCallback(
    (i: number, text: string) => {
      const config = webFormConfigs[i];
      onWebFormConfigUpdate(
        updateItemInArray(i, webFormConfigs, {
          ...config,
          dataMap: {
            ...config.dataMap,
            [elementId]: { ...config.dataMap[elementId], text },
          },
        })
      );
    },
    [webFormConfigs, elementId]
  );

  const onSelectB = useCallback(
    (i: number, style: KeyValueType) => {
      const config = webFormConfigs[i];
      onSelectItem({
        type: ItemType.CELL,
        rowId: config.id,
        rowIndex: i,
        rowPosition: config.position,
        column: elementId,
        style: style,
      });
    },
    [webFormConfigs, elementId]
  );

  const [minIndex, maxIndex] = useIntersectionView(
    ".termsheet-row",
    ref.current,
    [webFormConfigs]
  );

  useEffect(() => {
    setWebFormConfigs(configs);
  }, [configs]);

  return (
    <>
      <div className={"sticky top-0 z-[1000] bg-gray-100 table-row"}>
        <div className={`flex border-b border-gray-200 z-[10]`}>
          <div className={"sticky left-0 z-[100]"}>
            <div
              className={
                "h-full w-10 min-w-[2.5rem] text-xxs border-gray-200 bg-gray-50 text-gray-400"
              }
            />
          </div>
          <div className={"border-l border-r"}>
            <ColumnHeaderCell
              selected={selectedItem?.column === DefaultColumnType.BID_METRICS}
              width={valOrDefault(
                300,
                columnWidth[DefaultColumnType.BID_METRICS]
              )}
              content={"Bid Metrics"}
              onSelect={() => {
                /*onSelectItem({
                  column: DefaultColumnType.BID_METRICS,
                  rowId: webFormConfigs[0].id,
                  rowIndex: 0,
                  rowPosition: webFormConfigs[0].position,
                  style: webFormConfigs[0].uiKey.style,
                  type: ItemType.COLUMN,
                });*/
              }}
              onWidthChange={(o) => {
                setColumnWidth((columnWidth) => ({
                  ...columnWidth,
                  [DefaultColumnType.BID_METRICS]: o,
                }));
              }}
              editColumn={false}
              onClear={() => {
                /*ConfirmDelete(
                  "Are you sure you want to clear the bid metrics column?",
                  () => {
                    onWebFormConfigUpdate(
                      clearColumn(transactionId, "BID_METRICS", webFormConfigs)
                    );
                  }
                );*/
              }}
            />
          </div>
          <div className={"border-r"}>
            <ColumnHeaderCell
              selected={selectedItem?.column === elementId}
              width={valOrDefault(300, columnWidth[elementId])}
              content={"Deal Team Ask"}
              onSelect={() => {
                /*onSelectItem({
                  column: elementId,
                  rowId: webFormConfigs[0].id,
                  rowIndex: 0,
                  rowPosition: webFormConfigs[0].position,
                  style: webFormConfigs[0].dataMap[elementId].style,
                  type: ItemType.COLUMN,
                });*/
              }}
              onWidthChange={(o) => {
                setColumnWidth({
                  ...columnWidth,
                  [elementId]: o,
                });
              }}
              editColumn={false}
              onClear={() => {
                /*ConfirmDelete(
                  `Are you sure you want to clear ${
                    id === transactionId
                      ? "the deal team ask column"
                      : "this column"
                  }?`,
                  () =>
                    onWebFormConfigUpdate(
                      clearColumn(
                        id,
                        id === transactionId
                          ? "DEAL_TEAM_ASK"
                          : "INSTITUTION",
                        webFormConfigs
                      )
                    )
                );*/
              }}
            />
          </div>
        </div>
      </div>
      <div
        ref={ref.current}
        className={"border-l border-gray-200 table-row-group scroll-smooth"}
      >
        {webFormConfigs.map(({ id, position, uiKey, dataMap }, i) => {
          return (
            <Row
              key={i}
              id={i.toString()}
              index={i}
              allowRightClick={true}
              onAddOrDeleteRow={onRightClick}
            >
              <div
                data-rowindex={i}
                className={`termsheet-row flex bg-white border-b border-gray-200 z-[10] min-h-[35px]`}
              >
                <RowIndexCell
                  position={i + 1}
                  selected={
                    selectedItem?.rowPosition === position &&
                    selectedItem.rowId === id &&
                    selectedItem?.rowIndex === i
                  }
                  onSelect={() => {
                    /*onSelectItem({
                      type: ItemType.ROW,
                      column: DefaultColumnType.BID_METRICS,
                      rowId: id,
                      rowIndex: i,
                      rowPosition: position,
                      style: uiKey.style,
                    });*/
                  }}
                />
                {i >= minIndex && i <= maxIndex + 35 && (
                  <>
                    <div className={"bg-white border-r"}>
                      <Cell
                        index={i}
                        selectable={true}
                        className={
                          selectedItem?.rowIndex === i &&
                          (selectedItem?.type === ItemType.ROW ||
                            selectedItem?.type === ItemType.COLUMN) &&
                          selectedItem?.rowIndex === i
                            ? "border-l border-primary"
                            : ""
                        }
                        selectedCell={
                          selectedItem?.type === ItemType.CELL &&
                          selectedItem?.rowIndex === i &&
                          selectedItem?.column === DefaultColumnType.BID_METRICS
                        }
                        selectedSeries={
                          (selectedItem?.type === ItemType.ROW &&
                            selectedItem?.rowIndex === i) ||
                          (selectedItem?.type === ItemType.COLUMN &&
                            selectedItem.column ===
                              DefaultColumnType.BID_METRICS)
                        }
                        elementId={DefaultColumnType.BID_METRICS}
                        editable={true}
                        style={JSON.stringify(uiKey.style)}
                        text={uiKey.text}
                        width={valOrDefault(
                          300,
                          columnWidth[DefaultColumnType.BID_METRICS]
                        )}
                        onChange={onChangeA}
                        onSelect={onSelectA}
                      />
                    </div>
                    <div className={"bg-white border-r"}>
                      <Cell
                        index={i}
                        elementId={elementId}
                        selectable={true}
                        className={
                          selectedItem?.type === ItemType.ROW &&
                          selectedItem?.rowIndex === i
                            ? "border-r border-primary"
                            : ""
                        }
                        selectedCell={
                          selectedItem?.type === ItemType.CELL &&
                          selectedItem?.rowIndex === i &&
                          selectedItem?.column === elementId
                        }
                        selectedSeries={
                          selectedItem?.type === ItemType.ROW &&
                          selectedItem?.rowIndex === i
                        }
                        editable={true}
                        style={JSON.stringify(
                          valOrDefault({}, dataMap[elementId]?.style)
                        )}
                        text={valOrDefault("", dataMap[elementId]?.text)}
                        width={valOrDefault(300, columnWidth[elementId])}
                        onChange={onChangeB}
                        onSelect={onSelectB}
                      />
                    </div>
                  </>
                )}
              </div>
            </Row>
          );
        })}
      </div>
    </>
  );
};

type TemplateTermsheetRowsType = {
  elementId: string;
  configs: Array<WebFormConfigType>;
  selectedItem: SelectedItemType | null;
  onSelectItem: (o: SelectedItemType) => void;
  onWebFormConfigUpdate: (o: Array<WebFormConfigType>) => void;
};
type onRightClickType = (
  key: RightClickActionType,
  id: string,
  index: number
) => void;
type positionIncrementAtIndexType = (
  config: WebFormConfigType,
  index: number,
  configs: Array<WebFormConfigType>
) => Array<WebFormConfigType>;
