import React, { FC, MouseEventHandler, ReactNode, useState } from "react";
import { Tooltip } from "antd";
import { useCopyToClipboard } from "../../customHooks/use-copy-to-clipboard";

export const CopyToClipboard: FC<CopyToClipboardType> = ({
  children,
  value,
}) => {
  const { copy, copied } = useCopyToClipboard();

  const onClickCopy: MouseEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    copy(value);
  };

  return (
    <div className={"inline-flex items-center gap-x-2"}>
      <div>{children}</div>
      <Tooltip title={copied ? "Copied to Clipboard" : "Copy to clipboard"}>
        {copied ? (
          <i className="fa-solid fa-check text-success"></i>
        ) : (
          <i
            className="fa-regular fa-copy text-primary cursor-pointer"
            onClick={onClickCopy}
          />
        )}
      </Tooltip>
    </div>
  );
};

type CopyToClipboardType = {
  value: string | number;
  children: ReactNode | ReactNode[];
};
