import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, message, Select } from "antd";
import { formItemRequiredRule, isJsonRule } from "../../utils/formUtils";
import { WebhookEventType, SubscriptionType } from "../../utils/types";
import { addSubscription, editSubscription } from "../../services/services";
import { ResponseType } from "../../utils/uiTypes";

export const WebhookForm: FC<WebhookFormProperties> = ({
  eventTypes = [],
  value,
  onSubmit,
}: WebhookFormProperties) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onUpdate = (data: SubscriptionType) => {
    if (value) {
      setLoading(true);
      editSubscription({
        segments: {
          id: value.id,
        },
        body: JSON.stringify(data),
      })
        .then(({ data }: ResponseType<SubscriptionType>) => {
          form.resetFields();
          setLoading(false);
          onSubmit(data);
        })
        .catch(() => {
          setLoading(false);
          message.error("Unable to edit the subscription");
        });
    }
  };

  const onAdd = (data: SubscriptionType) => {
    if (data) {
      setLoading(true);
      addSubscription({
        body: JSON.stringify(data),
      })
        .then(({ data }: ResponseType<SubscriptionType>) => {
          form.resetFields();
          setLoading(false);
          onSubmit(data);
        })
        .catch(() => {
          setLoading(false);
          message.error("Unable to add the subscription");
        });
    }
  };

  useEffect(() => {
    if (value) {
      form.setFieldsValue({
        ...value,
        eventTypes: value.eventTypes.map((v) => v.id),
        headers: value?.headers ? JSON.stringify(value.headers) : "",
      });
    }
  }, [value]);

  return (
    <Form
      form={form}
      initialValues={{
        ["headers"]: "{}",
      }}
      className={`flex flex-col items-start items-stretch`}
      onFinish={(data): void => {
        const updatedData = {
          ...(value ? value : {}),
          ...data,
          eventTypes: eventTypes.filter((v) =>
            data?.eventTypes?.includes(v.id)
          ),
          headers: data.headers ? JSON.parse(data.headers) : {},
        };
        value?.id ? onUpdate(updatedData) : onAdd(updatedData);
      }}
      layout={"vertical"}
    >
      <Form.Item rules={[formItemRequiredRule]} name={"url"} label={"URL"}>
        <Input placeholder={"Enter url"} autoFocus={true} />
      </Form.Item>
      <Form.Item name={"headers"} label={"Headers (JSON)"} rules={[isJsonRule]}>
        <Input placeholder={"Provide headers in JSON format"} />
      </Form.Item>
      <Form.Item
        name={"eventTypes"}
        label={"Event Types"}
        rules={[formItemRequiredRule]}
      >
        <Select
          getPopupContainer={(trigger): HTMLElement => trigger.parentElement}
          allowClear={true}
          mode={"multiple"}
          maxTagCount={"responsive"}
          placeholder={"Select event types"}
          showSearch={true}
          optionFilterProp={"title"}
        >
          {eventTypes.map((v: WebhookEventType) => {
            return (
              <Select.Option key={v.id} value={v.id} title={v.name}>
                <div className={"w-full inline-flex justify-between"}>
                  <span>{v.name}</span>
                </div>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item className={"m-0"}>
        <div className={"flex flex-row items-center justify-end"}>
          <Button
            disabled={loading}
            loading={loading}
            htmlType={"submit"}
            className={"bg-primary hover:bg-hover text-white border-0"}
          >
            {value ? "Edit Subscription" : "Subscribe"}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

type WebhookFormProperties = {
  eventTypes: Array<WebhookEventType>;
  value?: SubscriptionType;
  onSubmit: (o: SubscriptionType) => void;
};
