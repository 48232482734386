import React, { FC, useRef } from "react";
import { Button } from "antd";
import { valOrDefault } from "../../utils/utils";
import { voidType } from "../../utils/uiTypes";

export const CommentInputForm: FC<CommentInputFormType> = ({
  onSubmit,
}: CommentInputFormType) => {
  const ref = useRef<HTMLDivElement>(null);

  const onClickSubmit: voidType = () => {
    onSubmit({
      message: valOrDefault(
        "",
        ref?.current?.innerHTML.replace(/<img[^>]*>/g, "").toString()
      ),
    });
    while (ref?.current?.firstChild) {
      ref?.current?.removeChild(ref?.current?.firstChild);
    }
  };

  return (
    <div
      className={
        "flex flex-row items-end bg-white p-1 gap-x-2 border items-center"
      }
    >
      <div className={"w-full m-0"}>
        <div
          id={"conversation"}
          ref={ref}
          contentEditable={true}
          className={
            "prose cursor-text empty:after:content-['Type_your_message_here...'] empty:text-gray-400 p-1 min-w-full focus:ring-0 focus:outline-none focus:border-none border-none hide-scrollbar"
          }
        />
      </div>

      <Button
        className={"bg-primary hover:bg-hover text-white border-0 self-end"}
        onClick={onClickSubmit}
      >
        Comment
      </Button>
    </div>
  );
};

type CommentInputFormType = {
  onSubmit: (o: { message: string }) => void;
};
