/** Transaction **/
export enum PETransactionTagType {
  LIVE = "LIVE",
  COMPLETED = "COMPLETED",
  LOST = "LOST",
  DROPPED = "DROPPED",
  SIGNED_NOT_CLOSED = "SIGNED_NOT_CLOSED",
}

export enum PETransactionCreatorType {
  LENDER = "LENDER",
  BORROWER = "BORROWER",
}

export enum PETransactionCreationType {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

export enum LenderEngagementAction {
  DOWNLOAD = "DOWNLOAD",
  PUBLISHED = "PUBLISHED",
  SAVED = "SAVED",
  NDA_ACCEPTED = "NDA_ACCEPTED",
  NDA_REJECTED = "NDA_REJECTED",
  VIEWED = "VIEWED",
}

/** Element **/
export enum ElementType {
  PETRANSACTION = "PETRANSACTION",
  FILE = "FILE",
  LENDER = "LENDER",
  TASK = "TASK",
  COMMENT = "COMMENT",
  NOTE = "NOTE",
  TERMSHEET = "TERMSHEET",
  FOLDER = "FOLDER",
  COMPANY = "COMPANY",
  PORTFOLIO = "PORTFOLIO",
  INSIGHTS = "INSIGHTS",
  PORTFOLIO_SECTION = "PORTFOLIO_SECTION",
  PRECEDENT_SEARCH = "PRECEDENT_SEARCH",
  CRM = "CRM",
  DEAL_CLOUD = "DEAL_CLOUD",
  SPONSOR_COVERAGE = "SPONSOR_COVERAGE",
  DEAL_EXECUTION = "DEAL_EXECUTION",
}

/** Allocation **/
export enum AllocationTableType {
  FINAL_HOLD = "FINAL_HOLD",
  FEES = "FEES",
  FUND = "FUND",
  TRANSACTION = "TRANSACTION",
  INVESTMENT = "INVESTMENT",
  CONTACT_ROLE = "CONTACT_ROLE",
}

export enum AllocationTableVersionType {
  ALLOCATION_AT_CLOSE = "ALLOCATION_AT_CLOSE",
  ALLOCATION_INCREMENTAL = "ALLOCATION_INCREMENTAL",
  ALLOCATION_REFINANCE = "ALLOCATION_REFINANCE",
  ALLOCATION_HOLDINGS = "ALLOCATION_HOLDINGS",
}

/** Permissions **/
export enum PermissionType {
  CREATE_PETRANSACTION = "CREATE_PETRANSACTION",
  CREATE_LENDER = "CREATE_LENDER",
  VIEW_LENDER = "VIEW_LENDER",
  EDIT_LENDER = "EDIT_LENDER",
  COMMENT_LENDER = "COMMENT_LENDER",
  VIEW_COMMUNICATION = "VIEW_COMMUNICATION",
  CREATE_COMMUNICATION = "CREATE_COMMUNICATION",
  CREATE_BID_FORM = "CREATE_BID_FORM",
  VIEW_BID_FORM = "VIEW_BID_FORM",
  EDIT_BID_FORM = "EDIT_BID_FORM",
  VIEW_PETRANSACTION = "VIEW_PETRANSACTION",
  EDIT_PETRANSACTION = "EDIT_PETRANSACTION",
  DELETE_PETRANSACTION = "DELETE_PETRANSACTION",
  COMMENT_PETRANSACTION = "COMMENT_PETRANSACTION",
  CREATE_TASK = "CREATE_TASK",
  EDIT_TASK = "EDIT_TASK",
  VIEW_TASK = "VIEW_TASK",
  DELETE_TASK = "DELETE_TASK",
  CREATE_FILE = "CREATE_FILE",
  EDIT_FILE = "EDIT_FILE",
  VIEW_FILE = "VIEW_FILE",
  DELETE_FILE = "DELETE_FILE",
  COMMENT_TASK = "COMMENT_TASK",
  REVOKE_PERMISSION = "REVOKE_PERMISSION",
  COMMENT_FILE = "COMMENT_FILE",
  CREATE_TOPIC = "CREATE_TOPIC",
  EDIT_TOPIC = "EDIT_TOPIC",
  VIEW_TOPIC = "VIEW_TOPIC",
  DELETE_TOPIC = "DELETE_TOPIC",
  CREATE_MILESTONE = "CREATE_MILESTONE",
  EDIT_MILESTONE = "EDIT_MILESTONE",
  DELETE_MILESTONE = "DELETE_MILESTONE",
  VIEW_MILESTONE = "VIEW_MILESTONE",
  ADMIN_PETRANSACTION = "ADMIN_PETRANSACTION",
  NON_ADMIN_PETRANSACTION = "NON_ADMIN_PETRANSACTION",
  NON_ADMIN_LENDER = "NON_ADMIN_LENDER",
  ADMIN_LENDER = "ADMIN_LENDER",
  VIEW_NOTE = "VIEW_NOTE",
  CREATE_NOTE = "CREATE_NOTE",
  DELETE_NOTE = "DELETE_NOTE",
  EDIT_NOTE = "EDIT_NOTE",
  VIEW_DATA_ROOM = "VIEW_DATA_ROOM",
  VIEW_ALLOCATION = "VIEW_ALLOCATION",
  VIEW_FEES = "VIEW_FEES",
  ADMIN = "ADMIN",
  NON_ADMIN = "NON_ADMIN",
  ABOVE_THE_WALL = "ABOVE_THE_WALL",
  ADMIN_PORTFOLIO = "ADMIN_PORTFOLIO",
  NON_ADMIN_PORTFOLIO = "NON_ADMIN_PORTFOLIO",
  VIEW_PORTFOLIO = "VIEW_PORTFOLIO",
  EDIT_PORTFOLIO = "EDIT_PORTFOLIO",
  ADMIN_INSIGHTS = "ADMIN_INSIGHTS",
  NON_ADMIN_INSIGHTS = "NON_ADMIN_INSIGHTS",
  VIEW_INSIGHTS = "VIEW_INSIGHTS",
  EDIT_INSIGHTS = "EDIT_INSIGHTS",
  ADMIN_PRECEDENT_SEARCH = "ADMIN_PRECEDENT_SEARCH",
  VIEW_PRECEDENT_SEARCH = "VIEW_PRECEDENT_SEARCH",
  NONE = "NONE",
  ADMIN_CRM = "ADMIN_CRM",
  NON_ADMIN_CRM = "NON_ADMIN_CRM",
  VIEW_CRM = "VIEW_CRM",
  ADMIN_SPONSOR_COVERAGE = "ADMIN_SPONSOR_COVERAGE",
  NON_ADMIN_SPONSOR_COVERAGE = "NON_ADMIN_SPONSOR_COVERAGE",
  VIEW_SPONSOR_COVERAGE = "VIEW_SPONSOR_COVERAGE",
  ABOVE_THE_WALL_PRECEDENT_SEARCH = "ABOVE_THE_WALL_PRECEDENT_SEARCH",
  VIEW_DEAL_METRICS = "VIEW_DEAL_METRICS",
  VIEW_COMPLETION_MEMO = "VIEW_COMPLETION_MEMO",
}

/** Roles **/
export enum RoleType {
  ROLE_ADMIN = "ROLE_ADMIN",
}

/** Invitation Status **/
export enum InvitationStatusType {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  REMOVED = "REMOVED",
}

/** Notifications **/
export enum NotificationActivityEnumType {
  NOTIFICATION_FOR_BID_FORM_COMMENT = "NOTIFICATION_FOR_BID_FORM_COMMENT",
  NOTIFICATION_FOR_BID_FORM_COMMENT_LENDER = "NOTIFICATION_FOR_BID_FORM_COMMENT_LENDER",
  NOTIFICATION_FOR_BID_FORM_UPDATE = "NOTIFICATION_FOR_BID_FORM_UPDATE",
  NOTIFICATION_FOR_BID_FORM_UPDATE_DEAL_TEAM = "NOTIFICATION_FOR_BID_FORM_UPDATE_DEAL_TEAM",
  NOTIFICATION_FOR_BID_FORM_UPDATE_LENDER = "NOTIFICATION_FOR_BID_FORM_UPDATE_LENDER",
  NOTIFICATION_FOR_BID_FORM_UPDATE_LENDER_OWN_TEAM = "NOTIFICATION_FOR_BID_FORM_UPDATE_LENDER_OWN_TEAM",
  NOTIFICATION_FOR_BID_FORM_REMINDER = "NOTIFICATION_FOR_BID_FORM_REMINDER",
  NOTIFICATION_FOR_BID_FORM_REMINDER_UPDATE_DEAL_TEAM = "NOTIFICATION_FOR_BID_FORM_REMINDER_UPDATE_DEAL_TEAM",
  NOTIFICATION_FOR_COMMENT = "NOTIFICATION_FOR_COMMENT",
  NOTIFICATION_FOR_COMMENT_TOPIC = "NOTIFICATION_FOR_COMMENT_TOPIC",
  NOTIFICATION_FOR_CREATE_FOLDER = "NOTIFICATION_FOR_CREATE_FOLDER",
  NOTIFICATION_FOR_SHARE_FOLDER = "NOTIFICATION_FOR_SHARE_FOLDER",
  NOTIFICATION_FOR_COMMENT_FILE = "NOTIFICATION_FOR_COMMENT_FILE",
  NOTIFICATION_FOR_CREATE_FILE = "NOTIFICATION_FOR_CREATE_FILE",
  NOTIFICATION_FOR_SHARE_FILE = "NOTIFICATION_FOR_SHARE_FILE",
  NOTIFICATION_FOR_SHARE_FILE_UPDATE_DEAL_TEAM = "NOTIFICATION_FOR_SHARE_FILE_UPDATE_DEAL_TEAM",
  NOTIFICATION_FOR_DOWNLOAD_FILE = "NOTIFICATION_FOR_DOWNLOAD_FILE",
  NOTIFICATION_FOR_CREATE_NOTE = "NOTIFICATION_FOR_CREATE_NOTE",
  NOTIFICATION_FOR_UPDATE_NOTE = "NOTIFICATION_FOR_UPDATE_NOTE",
  NOTIFICATION_FOR_SHARED_NOTE = "NOTIFICATION_FOR_SHARED_NOTE",
  NOTIFICATION_FOR_TASK_ASSIGNED = "NOTIFICATION_FOR_TASK_ASSIGNED",
  NOTIFICATION_FOR_TASK_EDITED = "NOTIFICATION_FOR_TASK_EDITED",
  NOTIFICATION_FOR_TASK_STATUS_CHANGED = "NOTIFICATION_FOR_TASK_STATUS_CHANGED",
  NOTIFICATION_FOR_TASK_STATUS_CHANGED_TO_INITIAL = "NOTIFICATION_FOR_TASK_STATUS_CHANGED_TO_INITIAL",
  NOTIFICATION_FOR_TASK_COMMENT = "NOTIFICATION_FOR_TASK_COMMENT",
  NOTIFICATION_FOR_LENDER_THREAD_COMMENT = "NOTIFICATION_FOR_LENDER_THREAD_COMMENT",
  NOTIFICATION_FOR_LENDER_THREAD_COMMENT_TOPIC = "NOTIFICATION_FOR_LENDER_THREAD_COMMENT_TOPIC",
}

export enum NotificationActivityTitleEnumType {
  NOTIFICATION_FOR_BID_FORM_COMMENT = "Termsheet",
  NOTIFICATION_FOR_BID_FORM_COMMENT_LENDER = "Termsheet",
  NOTIFICATION_FOR_BID_FORM_UPDATE = "Termsheet",
  NOTIFICATION_FOR_BID_FORM_UPDATE_DEAL_TEAM = "Termsheet",
  NOTIFICATION_FOR_BID_FORM_UPDATE_LENDER = "Termsheet",
  NOTIFICATION_FOR_BID_FORM_UPDATE_LENDER_OWN_TEAM = "Termsheet",
  NOTIFICATION_FOR_BID_FORM_REMINDER = "Termsheet",
  NOTIFICATION_FOR_BID_FORM_REMINDER_UPDATE_DEAL_TEAM = "Termsheet",
  NOTIFICATION_FOR_COMMENT = "Communications",
  NOTIFICATION_FOR_COMMENT_TOPIC = "Communications",
  NOTIFICATION_FOR_CREATE_FOLDER = "Data Room",
  NOTIFICATION_FOR_SHARE_FOLDER = "Data Room",
  NOTIFICATION_FOR_COMMENT_FILE = "Data Room",
  NOTIFICATION_FOR_CREATE_FILE = "Data Room",
  NOTIFICATION_FOR_SHARE_FILE = "Data Room",
  NOTIFICATION_FOR_SHARE_FILE_UPDATE_DEAL_TEAM = "Data Room",
  NOTIFICATION_FOR_DOWNLOAD_FILE = "Data Room",
  NOTIFICATION_FOR_CREATE_NOTE = "Notes",
  NOTIFICATION_FOR_UPDATE_NOTE = "Notes",
  NOTIFICATION_FOR_SHARED_NOTE = "Notes",
  NOTIFICATION_FOR_TASK_ASSIGNED = "TaskList",
  NOTIFICATION_FOR_TASK_EDITED = "TaskList",
  NOTIFICATION_FOR_TASK_STATUS_CHANGED = "TaskList",
  NOTIFICATION_FOR_TASK_STATUS_CHANGED_TO_INITIAL = "TaskList",
  NOTIFICATION_FOR_TASK_COMMENT = "TaskList",
  NOTIFICATION_FOR_LENDER_THREAD_COMMENT = "Communications",
  NOTIFICATION_FOR_LENDER_THREAD_COMMENT_TOPIC = "Communications",
}

export enum NotificationSettingType {
  COMMENT = "COMMENT",
  FILE_SHARE_DEAL_TEAM_UPDATE = "FILE_SHARE_DEAL_TEAM_UPDATE",
  FILE_UPLOADED = "FILE_UPLOADED",
  GRID_PUBLISHED = "GRID_PUBLISHED",
  GRID_RESPONDED = "GRID_RESPONDED",
  TASK_EDITED = "TASK_EDITED",
  TASK_STATUS_UPDATED = "TASK_STATUS_UPDATED",
}

/** Currencies **/

export enum AnalyticsCurrencyType {
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  SEK = "SEK",
  NOK = "NOK",
  DKK = "DKK",
  AUD = "AUD",
  CAD = "CAD",
  CHF = "CHF",
  HKD = "HKD",
  SGD = "SGD",
  JPY = "JPY",
}

export enum CurrencyType {
  DOLLAR = "DOLLAR",
  EURO = "EURO",
  POUND = "POUND",
  KRONA = "KRONA",
  KRONE = "KRONE",
  KRONER = "KRONER",
  AUSTRALIAN_DOLLAR = "AUSTRALIAN_DOLLAR",
  CANADIAN_DOLLAR = "CANADIAN_DOLLAR",
  FRANC = "FRANC",
  HONG_KONG_DOLLAR = "HONG_KONG_DOLLAR",
  SINGAPORE_DOLLAR = "SINGAPORE_DOLLAR",
  YEN = "YEN",
}

export enum CurrencySymbolType {
  DOLLAR = "USD",
  EURO = "EUR",
  POUND = "GBP",
  KRONA = "SEK",
  KRONE = "NOK",
  KRONER = "DKK",
  AUSTRALIAN_DOLLAR = "AUD",
  CANADIAN_DOLLAR = "CAD",
  FRANC = "CHF",
  HONG_KONG_DOLLAR = "HKD",
  SINGAPORE_DOLLAR = "SGD",
  YEN = "JPY",
}

/** Units **/
export enum UnitType {
  THOUSAND = "THOUSAND",
  MILLION = "MILLION",
}

/** Units **/
export enum UnitValueType {
  THOUSAND = 1000,
  MILLION = 1000000,
}

export enum UnitLabelType {
  THOUSAND = "K",
  MILLION = "M",
}

export enum UnitTitleType {
  THOUSAND = "Thousand(K)",
  MILLION = "Million(M)",
}

// Invitation
export enum InvitationType {
  LENDER = "Lender",
  PETRANSACTION = "PeTransaction",
  LENDER_TRANSACTION = "Lender Transaction",
  COMPANY = "Company",
}

export enum AnalyticsChartType {
  SINGLE_VALUE = "SINGLE_VALUE",
  STACKED_BAR = "STACKED_BAR",
  PIE = "PIE",
  BAR = "BAR",
  HORIZONTAL_BAR = "HORIZONTAL_BAR",
  GROUPED_BAR = "GROUPED_BAR",
  LINE = "LINE",
  TABLE = "TABLE",
  TREEMAP = "TREEMAP",
}

/** Portfolio **/
export enum SectionFieldType {
  TEXT = "TEXT",
  NUMERIC = "NUMERIC",
  DROP_BOX = "DROP_BOX",
  CHECK_BOX = "CHECK_BOX",
  DATE = "DATE",
  COUNTRY = "COUNTRY",
  CURRENCY = "CURRENCY",
  PETRANSACTION = "PETRANSACTION",
  ALLOCATION = "ALLOCATION",
  FEES = "FEES",
  LONG_TEXT = "LONG_TEXT",
  FUND = "FUND",
  CURRENCY_UNIT = "CURRENCY_UNIT",
  INDUSTRY = "INDUSTRY",
  MONETARY = "MONETARY",
  COMPANY_ID = "COMPANY_ID",
}

export enum PortfolioInputType {
  TEXT = "TEXT",
  NUMERIC = "NUMERIC",
  DROP_BOX = "DROP_BOX",
  CHECK_BOX = "CHECK_BOX",
  DATE = "DATE",
  COUNTRY = "COUNTRY",
  CURRENCY = "CURRENCY",
  PETRANSACTION = "CHECK_BOX",
  ALLOCATION = "ALLOCATION",
  FEES = "FEES",
  LONG_TEXT = "LONG_TEXT",
  FUND = "CHECK_BOX",
  CURRENCY_UNIT = "CURRENCY_UNIT",
  INDUSTRY = "CHECK_BOX",
  MONETARY = "MONETARY",
  COMPANY_ID = "COMPANY_ID",
}

export enum PortfolioSectionEnumType {
  GENERAL_INFORMATION = "GENERAL_INFORMATION",
  COVENANT = "COVENANT",
  CUSTOM = "CUSTOM",
  ALLOCATION = "ALLOCATION",
  FEES = "FEES",
  FINANCIALS = "FINANCIALS",
  PAST_TRANSACTION = "PAST_TRANSACTION",
  CAPITAL_STRUCTURE = "CAPITAL_STRUCTURE",
  KEY_CONTACTS = "KEY_CONTACTS",
  AMORTIZATION = "AMORTIZATION",
  UNDERWRITING_TERMS = "UNDERWRITING_TERMS",
  HEDGING = "HEDGING",
  LEAKAGE_ANALYSIS = "LEAKAGE_ANALYSIS",
  KPI = "KPI",
  TRANSACTION_FEES = "TRANSACTION_FEES",
  TRANSACTION_ALLOCATION = "TRANSACTION_ALLOCATION",
}

export enum QuarterType {
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4",
}

export enum AttributeType {
  PROPERTY_ID = "PROPERTY_ID",
  ELEMENT_ID = "ELEMENT_ID",
  LENDER_ID = "LENDER_ID",
  ELEMENT_TYPE = "ELEMENT_TYPE",
  CREATE_DATE = "CREATE_DATE",
  UPDATE_DATE = "UPDATE_DATE",
  USER_ID = "USER_ID",
  COMMENT = "COMMENT",
  TITLE = "TITLE",
  DETAILS = "DETAILS",
  COMMENT_ID = "COMMENT_ID",
  CREATOR_ID = "CREATOR_ID",
  CREATOR_NAME = "CREATOR_NAME",
  CREATOR_EMAIL = "CREATOR_EMAIL",
  TARGET_LIST = "TARGET_LIST",
  USER_NAME = "USER_NAME",
  TASK_ID = "TASK_ID",
  SECTION_ID = "SECTION_ID",
  SECTION_NAME = "SECTION_NAME",
  STATUS = "STATUS",
  PROJECT_NAME = "PROJECT_NAME",
  TASK_NAME = "TASK_NAME",
  LENDER_TITLE = "LENDER_TITLE",
  TOPIC = "TOPIC",
  DOWNLOADED_BY = "DOWNLOADED_BY",
  COMPANY = "COMPANY",
  ROW = "ROW",
}

export enum ConfigType {
  PASSWORD_POLICY = "PASSWORD_POLICY",
  DEAL_CLOUD_CONFIG = "DEAL_CLOUD_CONFIG",
}

export enum AllocationElementType {
  PETRANSACTION = "PETRANSACTION",
  LENDER_WITH_TX = "LENDER_WITH_TX",
  LENDER_WITHOUT_TX = "LENDER_WITHOUT_TX",
  OTHER = "OTHER",
  PORTFOLIO = "PORTFOLIO",
}

export enum FileElementEnumType {
  PETRANSACTION = "PETRANSACTION",
  PORTFOLIO = "PORTFOLIO",
  NDA = "NDA",
  NOTE = "NOTE",
}

export enum SortDirection {
  DESC = "DESC",
  ASC = "ASC",
}

export enum ThirdPartyIntegrationType {
  DEAL_CLOUD = "DEAL_CLOUD",
}

export enum ConfigurationSettingType {
  INTEGRATIONS = "INTEGRATIONS",
  SETTINGS = "SETTINGS",
}

export enum AccessLevelType {
  FREEMIUM = "FREEMIUM",
  LITE = "LITE",
  STANDARD = "STANDARD",
  ADVANCED = "ADVANCED",
  PREMIUM = "PREMIUM",
  LENDER_DEFAULT = "LENDER_DEFAULT",
  CUSTOM = "CUSTOM",
}

export enum ModuleType {
  OFFLINE_TRANSACTION = "offlineTransaction",
  TRANSACTION = "transaction",
  DASHBOARD = "dashboard",
  NOTIFICATIONS = "notifications",
  COMMUNICATION = "communication",
  TERM_SHEET = "termsheet",
  ALLOCATION = "allocation",
  FEES = "fees",
  NOTES = "notes",
  DATA_ROOM = "dataroom",
  TASK_LIST = "taskList",
  DEAL_TEAM = "dealTeam",
  ADD_INSTITUTIONS = "addInstitutions",
  INVITE_LENDER_USERS = "inviteLenderUsers",
  TRACKERS = "trackers",
  INSIGHTS = "insights",
  TAGS = "tags",
  NDA = "nda",
  PRECEDENT_SEARCH = "precedentSearch",
  PORTFOLIO_MANAGEMENT = "portfolioManagement",
  CRM = "crm",
  DEAL_CLOUD = "dealCloud",
  SSO = "sso",
  WEBHOOK = "webhooks",
  API = "api",
  SALESFORCE = "salesforce",
  OUTLOOK_INTEGRATION = "outlookIntegration",
  PERMISSIONS = "permissions",
  SECURITY = "security",
  SPONSOR_COVERAGE = "sponsorCoverage",
  DEAL_EXECUTION = "dealExecution",
  LEAKAGE_ANALYSIS = "leakageAnalysis",
  LENDER_ENGAGEMENT = "lenderEngagement",
  DEAL_METRICS = "dealMetrics",
  COMPLETIONS_MEMO = "completionsMemo",
}

export enum CompanyEnumType {
  SPONSOR = "SPONSOR",
  LENDER = "LENDER",
  DEBT_ADVISOR_COMPANY = "DEBT_ADVISOR_COMPANY",
  LAW_FIRM = "LAW_FIRM",
  AGENT_BANK = "AGENT_BANK",
  LENDER_SPONSOR = "LENDER_SPONSOR",
}

export enum MonetaryType {
  EBITDA = "EBITDA",
  HOLD = "HOLD",
  AUM = "AUM",
  TARGET_HOLD = "TARGET_HOLD",
}

export enum RelationshipInvestmentType {
  LEAD = "LEAD",
  CO_LEAD = "CO_LEAD",
  CLUB_MEMBER = "CLUB_MEMBER",
  PARTICIPANT = "PARTICIPANT",
}

export enum RelationshipInstitutionType {
  BANK = "BANK",
  CREDIT_FUND_DIRECT = "CREDIT_FUND_DIRECT",
  CREDIT_FUND_CLO = "CREDIT_FUND_CLO",
  PE_SPONSOR = "PE_SPONSOR",
  LENDER = "LENDER",
  LAW_FIRM = "LAW_FIRM",
  DEBT_ADVISOR = "DEBT_ADVISOR",
  AGENT = "AGENT",
  LIMITED_PARTNER = "LIMITED_PARTNER",
  OTHER = "OTHER",
}

export enum RelationshipStructureType {
  LIEN_ONE = "LIEN_ONE",
  LIEN_TWO = "LIEN_TWO",
  REVOLVER = "REVOLVER",
  DDTL = "DDTL",
  UNITRANCHE = "UNITRANCHE",
  ARR_BASED_LOANS = "ARR_BASED_LOANS",
  MEZZANINE_SUBORDINATED = "MEZZANINE_SUBORDINATED",
  SENIOR_SECURED_NOTES = "SENIOR_SECURED_NOTES",
  SENIOR_UNSECURED_NOTES = "SENIOR_UNSECURED_NOTES",
  PIKS_PIYC = "PIKS_PIYC",
  EQUITY_SECURITIES = "EQUITY_SECURITIES",
}

export enum RelationshipInstitutionLabelType {
  BANK = "Bank",
  CREDIT_FUND_DIRECT = "Credit Fund (Direct)",
  CREDIT_FUND_CLO = "Credit Fund (CLO)",
  PE_SPONSOR = "PE Sponsor",
  LENDER = "Lender",
  LAW_FIRM = "Law Firm",
  DEBT_ADVISOR = "Debt Advisor",
  AGENT = "Agent",
  LIMITED_PARTNER = "Limited Partner",
  OTHER = "Other",
}

export enum RelationshipStructureLabelType {
  REVOLVER = "Revolver",
  DDTL = "DDTL",
  UNITRANCHE = "Unitranche",
  ARR_BASED_LOANS = "ARR Based Loans",
  MEZZANINE_SUBORDINATED = "Mezzanine / Subordinated",
  SENIOR_SECURED_NOTES = "Senior Secured Notes",
  SENIOR_UNSECURED_NOTES = "Senior Unsecured Notes",
  PIKS_PIYC = "PIKs / PIYC",
  EQUITY_SECURITIES = "Equity Securities",
  LIEN_ONE = "1L",
  LIEN_TWO = "2L",
}

export enum RelationshipInvestmentLabelType {
  LEAD = "Lead",
  CO_LEAD = "Co-Lead",
  CLUB_MEMBER = "Club Member",
  PARTICIPANT = "Participant",
}

export enum RelationshipModuleLabelType {
  CRM = "Relationships",
  SPONSOR_COVERAGE = "Sponsor Coverage",
}

export enum PortfolioEnumType {
  LENDER_PORTFOLIO = "LENDER_PORTFOLIO",
  SPONSOR_PORTFOLIO = "SPONSOR_PORTFOLIO",
}
export enum PriorityType {
  HIGH = 1,
  MEDIUM = 2,
  LOW = 3,
}

export const priorityLabelType = {
  [PriorityType.HIGH]: "High",
  [PriorityType.MEDIUM]: "Medium",
  [PriorityType.LOW]: "Low",
};

export enum debtLabelType {
  SYNDICATED_LOAN = "Syndicated Loan",
  BRIDGE_TO_BOND = "Bridge to Bond",
  CLUB_BANK_DEAL = "Club Bank Deal",
  PRIVATE_DIRECT_LENDING_DEAL = "Private direct lending deal",
}
export enum debtType {
  SYNDICATED_LOAN = "SYNDICATED_LOAN",
  BRIDGE_TO_BOND = "BRIDGE_TO_BOND",
  CLUB_BANK_DEAL = "CLUB_BANK_DEAL",
  PRIVATE_DIRECT_LENDING_DEAL = "PRIVATE_DIRECT_LENDING_DEAL",
}
export enum debtStructureLabelType {
  ARR_LOAN = "ARR Loan",
  INCREMENTAL_LOAN = "Incremental Loan",
  BRIDGE_LOAN = "Bridge Loan",
  UNITRANCHE = "Unitranche",
  STRECHED_SECURED = "Stretched Secured",
  FIRST_LIEN_SECOND_LIEN = "First Lien Second Lien",
  SYNDICATED_TLB_ONLY = "Syndicated TLB Only",
  ALL_BOND = "All Bond",
}
export enum debtStructureType {
  ARR_LOAN = "ARR_LOAN",
  INCREMENTAL_LOAN = "INCREMENTAL_LOAN",
  BRIDGE_LOAN = "BRIDGE_LOAN",
  UNITRANCHE = "UNITRANCHE",
  STRECHED_SECURED = "STRECHED_SECURED",
  FIRST_LIEN_SECOND_LIEN = "FIRST_LIEN_SECOND_LIEN",
  SYNDICATED_TLB_ONLY = "SYNDICATED_TLB_ONLY",
  ALL_BOND = "ALL_BOND",
}
export enum ownershipType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}
export enum ownershipLabelType {
  PUBLIC = "Public",
  PRIVATE = "Private",
}
export enum noYesType {
  NO = "NO",
  YES = "YES",
}
export enum noYesLabelType {
  NO = "No",
  YES = "Yes",
}

export enum LeakageTemplateType {
  MASTER = "MASTER",
  COMPANY = "COMPANY",
}

export enum LeakageDataTypes {
  NUMBER = "NUMBER",
  PERCENTAGE = "PERCENTAGE",
  RATIO = "RATIO",
  DAYS = "DAYS",
  MONTHS = "MONTHS",
  STRING = "STRING",
  CURRENCY = "CURRENCY",
  FORMULA = "FORMULA",
  COMPANY = "COMPANY",
  DECISION_ENUM = "DECISION_ENUM",
  MONTHS_RANGE_ENUM = "MONTHS_RANGE_ENUM",
  MARGIN_ENUM = "MARGIN_ENUM",
  COLOR_ENUM = "COLOR_ENUM",
  BOOLEAN_ENUM = "BOOLEAN_ENUM",
}

export enum LeakageDataLabelTypes {
  STRING = "Text",
  NUMBER = "Number",
  CURRENCY = "Monetory",
}

export enum StatusType {
  ACTIVE = "active",
  PAST = "past",
}

export enum SmartTagActionType {
  REMOVE_ALL_USERS = "REMOVE_ALL_USERS",
}
export enum getCurrency {
  "USD ($)" = "USD",
  "EUR (€)" = "EUR",
  "GBP (£)" = "GBP",
  "SEK (kr)" = "SEK",
  "NOK (kr)" = "NOK",
  "DKK (kr)" = "DKK",
  "AUD ($)" = "AUD",
  "CAD ($)" = "CAD",
  "CHF (₣)" = "CHF",
  "HKD (HK$)" = "HKD",
  "SGD (S$)" = "SGD ",
  "JPY (¥)" = "JPY",
}
