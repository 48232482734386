import React, { FC, useEffect } from "react";
import { useHistory } from "react-router";
import { LOGIN_PAGE_URL } from "../utils/redirect";
import { usePageTitle } from "../customHooks/usePageTitle";

export const ExternalPageNotFound: FC = function () {
  usePageTitle("Termgrid", false);

  const history = useHistory();

  useEffect(() => {
    history.replace(LOGIN_PAGE_URL);
  }, []);
  return <></>;
};
