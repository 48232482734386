import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button, Spin } from "antd";
import { verifyEmail } from "../services/services";
import { LOGIN_PAGE_URL } from "../utils/redirect";
import { usePageTitle } from "../customHooks/usePageTitle";

export const VerifyEmail: FC = function () {
  usePageTitle("Email Verification");

  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { hash } = useParams<VerificationParams>();

  const [verifyMessage, setVerifyMessage] = useState<{
    style: string;
    message: string;
  }>(
    hash
      ? { style: "text-gray-900", message: "Verifying..." }
      : { style: "text-red-500 font-medium", message: "Invalid Link..." }
  );

  useEffect(() => {
    setVerifyMessage(
      hash
        ? { style: "text-gray-900", message: "Verifying..." }
        : { style: "text-red-500 font-medium", message: "Invalid Link..." }
    );

    hash &&
      verifyEmail({
        segments: {
          hash,
        },
      })
        .then(
          (
            data = {
              data: {
                message: "Email Verified",
              },
            }
          ) => {
            console.log(data);
            setLoading(false);
            setVerifyMessage({
              style: "text-green-500 font-bold",
              message: data.data.message,
            });
          }
        )
        .catch((error: string) => {
          setLoading(false);
          setVerifyMessage({
            style: "text-red-500 font-bold",
            message: error,
          });
        });
  }, [hash]);

  return (
    <div
      style={{
        backgroundImage: "url('../../images/hero.jpg')",
      }}
      className={
        "h-screen w-screen bg-cover bg-center overflow-y-auto hide-scrollbar"
      }
    >
      <img
        className={"mx-auto pt-15 w-48"}
        src={
          "https://app.termgrid.com/lib_qKfuUmedNUDfEwXT/00w6zm7jt9lojh4f.png?w=400"
        }
        alt={"termgrid"}
      />
      <div className={"px-5 mx-auto"}>
        <div className={"bg-white border mx-auto my-16 sm:my-24 p-10 max-w-lg"}>
          <div className={"flex flex-col items-center justify-center"}>
            <div className={"text-center text-2xl font-medium"}>
              Email Verification
            </div>
            <div className={"text-center text-sm font-normal mt-2 mb-8"}>
              {loading && <Spin spinning={loading} />}
              <span className={verifyMessage.style}>
                {verifyMessage.message}
              </span>
            </div>
            <Button
              className={"bg-primary hover:bg-hover text-white border-0"}
              onClick={(): void => history.push(LOGIN_PAGE_URL)}
            >
              Go Back To Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

type VerificationParams = {
  hash: string;
};
