import { find, keys, path, pathOr, propEq, sum } from "ramda";
import { tableColumnHeader } from "../../utils/componentUtils";
import { Badge, Button, Table } from "antd";
import React, { FC, ReactNode, useState } from "react";
import { AnalyticsDataRenderType } from "./AnalyticsData";
import {
  TRANSACTION_STATUSES,
  TransactionsTagsMap,
} from "../../utils/transaction";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import { SpinnerOverlay } from "../general/SpinnerOverlay";
import useWindowDimensions from "../../customHooks/useWindowDimensions";
import { AnalyticsChartModal } from "./AnalyticsChartModal";
import { onSort } from "../../utils/table-utils";
import { roundAndFormat } from "../../utils/roundAndFormat";

const totalAmount = (data: Record<string, string>[], key: string) =>
  sum(data.map((v) => Number(pathOr("0", [key], v))));

const BLACKLIST_COLUMNS = ["Portfolio Id", "Lender Id"];
export const AnalyticsTableWrapper: FC<AnalyticsDataRenderType> = ({
  name,
  params,
  loading,
  data,
  label,
  onRefresh,
  onDownload,
  footer,
  showActionItems,
}) => {
  const { height } = useWindowDimensions();
  const summation: SummationType | undefined = data?.configuration
    ? pathOr(undefined, ["summation"], JSON.parse(data.configuration))
    : undefined;
  const parseValues = (val: string | number): ReactNode => {
    const statuses = TRANSACTION_STATUSES.map((val) => val.toString());
    if (statuses.includes(val?.toString())) {
      const { color, label } = TransactionsTagsMap[val];
      return (
        <span className={"flex flex-row"}>
          {color && <Badge offset={[0, 0]} dot color={color} />}&nbsp;&nbsp;
          {label}
        </span>
      );
    }
    const isNumber = !isNaN(Number(val)) && val !== "" && val !== null;
    return isNumber
      ? roundAndFormat(val)
      : val?.toString()?.replaceAll(", ", `\n`);
  };

  const renderValues = (val: string | number): ReactNode => {
    return val?.toString()?.replaceAll(", ", `\n`);
  };

  const [chartItemModal, setChartItemModal] = useState<{
    name: string;
    params: string;
  } | null>(null);

  const onClickChart: onClickChartType = (e) => {
    const properties =
      JSON.parse(data?.configuration ?? "{}")?.properties ?? [];
    const xKey = find(propEq("key", "xKey"), properties) as any;
    const value: string | undefined = path(["value"], xKey);
    if (value && value != null) {
      setChartItemModal({
        params:
          [`${value}:${e[value]}`].reduce((prev, current) => {
            return prev + `&filterBy=${encodeURIComponent(current)}`;
          }, params) ?? "",
        name: `${label} -  ${e[value]}`,
      });
    } else {
      setChartItemModal(null);
    }
  };

  return (
    <div className={"p-1.5 w-full"}>
      <div
        className={
          "bg-white flex items-stretch flex-col drop-shadow-sm border border-gray-200"
        }
      >
        {showActionItems && !footer && (
          <div
            className={
              "flex flex-row items-center p-2 border-b border-gray-200 bg-gray-50 select-all sticky"
            }
          >
            {label}
            {onRefresh && (
              <Button
                className={"ml-auto"}
                type={"text"}
                icon={<ReloadOutlined className={"text-primary"} />}
                onClick={onRefresh}
              />
            )}
            {onDownload && (
              <Button
                type={"text"}
                icon={<DownloadOutlined className={"text-primary"} />}
                onClick={onDownload}
              />
            )}
          </div>
        )}
        <SpinnerOverlay hidden={false} opaque={true} spinning={loading}>
          <div className={"w-full"} style={{ minHeight: "150px" }}>
            <Table
              size={"small"}
              rowClassName={`hover:bg-blue-50 cursor-pointer`}
              dataSource={
                data?.data?.map((val: any, index) => ({
                  ...val,
                  index,
                })) ?? []
              }
              columns={keys(path(["0"], data?.data ?? [])).map((val) => ({
                title: tableColumnHeader(val),
                dataIndex: val,
                sorter: (a: any, b: any) => {
                  return onSort(a[val], b[val]);
                },
                className:
                  "whitespace-pre-wrap bg-transparent flex-initial items-start justify-start min-w-[180px] ",
                render: (v) => {
                  return BLACKLIST_COLUMNS.includes(val)
                    ? renderValues(v)
                    : parseValues(v);
                },
              }))}
              summary={() => {
                const list = data?.data ?? [];
                return list?.length > 0 &&
                  summation &&
                  summation.fields.length > 0 ? (
                  <Table.Summary fixed={true}>
                    <Table.Summary.Row className={"bg-gray-50 font-medium"}>
                      {keys(list[0]).map((key, i) => {
                        const column = find(
                          propEq("key", key),
                          summation?.fields ?? []
                        );
                        let value = "-";
                        if (column?.calculate === "NONE") {
                          value = column?.value ?? value;
                        } else if (column?.calculate === "SUM_TOTAL") {
                          value = roundAndFormat(
                            totalAmount(list, key)
                          )?.toString();
                        } else if (column?.calculate === "IDENTITY_FIRST") {
                          value = list[0][key];
                        }
                        return (
                          <Table.Summary.Cell index={i} key={key}>
                            {value}
                          </Table.Summary.Cell>
                        );
                      })}
                    </Table.Summary.Row>
                  </Table.Summary>
                ) : (
                  <></>
                );
              }}
              scroll={{ x: true, y: height - 400 }}
              pagination={false}
              rowKey={"index"}
              onRow={(record) => {
                return {
                  onClick: (event) => {
                    onClickChart(record);
                  },
                };
              }}
            />
          </div>
        </SpinnerOverlay>
      </div>
      {chartItemModal && (
        <AnalyticsChartModal
          visible={chartItemModal !== null}
          title={chartItemModal.name}
          name={name}
          params={chartItemModal.params}
          onClose={(): void => setChartItemModal(null)}
        />
      )}
    </div>
  );
};

type SummationFieldType = {
  key: string;
  value?: string;
  calculate?: "NONE" | "IDENTITY_FIRST" | "SUM_TOTAL";
};
type SummationType = {
  fields: Array<SummationFieldType>;
};

type onClickChartType = (e: any) => void;
