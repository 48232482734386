import { getAllocationKeys } from "../services/services";
import {
  AllocationKeyType,
  AllocationType,
  CompanyType,
  LenderType,
} from "./types";
import { Moment } from "moment";
import {
  filterByKeyAndVal,
  getIndexByKeyVal,
  getObjectFromPropertyPathValue,
  getObjectFromPropertyValue,
  getPercentage,
  groupByProperty,
} from "./utils";
import { ResponseType } from "./uiTypes";
import {
  AllocationElementType,
  AllocationTableType,
  AllocationTableVersionType,
  CurrencyType,
  SectionFieldType,
  UnitType,
} from "./enums";
import {
  and,
  defaultTo,
  equals,
  find,
  flatten,
  isEmpty,
  pathOr,
  propEq,
} from "ramda";
import { ColumnsType } from "antd/lib/table";
import React, { ReactElement } from "react";
import EditableTableCell from "../components/general/EditableTableCell";
import { onSort } from "./table-utils";

export const getAllocationTableKeys: getAllocationTableKeysType = (
  callback
) => {
  getAllocationKeys({
    segments: {
      id: "",
    },
  }).then(({ data = [] }: ResponseType) => {
    callback(data);
  });
};

export const customDateFormat: customDateFormatType = (value) =>
  value ? `Closing date: ${value.format("DD MMM YY")}` : "No Date Set";

export const addCommasToNumber: addCommasToNumberType = (number) => {
  const value = number.toString();
  const splitValue = value.split(".");
  const commaEveryThreeRegex = /\B(?=(\d{3})+(?!\d))/g;
  return `${splitValue[0].toString().replaceAll(commaEveryThreeRegex, ",")}${
    splitValue[1] ? "." + splitValue[1] : ""
  }`;
};

export const removeCommas: removeCommasType = (number) => {
  return defaultTo(0, Number(number.toString().replaceAll(",", "")));
};

export const formatTotal: formatTotalType = (number) =>
  addCommasToNumber(parseFloat(Number(number).toFixed(8)));

export const isFooter = equals("footer");

export const getAllocationFooterRow: getAllocationFooterRowType = (data) => {
  if (!isEmpty(data)) {
    const rows: AllocationType[][] = groupByProperty(data, "elementId");
    return [
      rows[0].map((record) => ({
        ...record,
        id: "footer",
        value: "0",
      })),
    ];
  } else return [];
};

export const getAllocationFieldType: Record<
  string,
  "date" | "text" | "number" | "select" | "textarea"
> = {
  [SectionFieldType.TEXT]: "text",
  [SectionFieldType.NUMERIC]: "number",
  [SectionFieldType.DATE]: "date",
  [SectionFieldType.CURRENCY]: "number",
  [SectionFieldType.LONG_TEXT]: "text",
  [SectionFieldType.MONETARY]: "number",
};

export const calculateAllTotal: calculateAllTotalType = (data, keys) => {
  return data?.reduce((previousValue, allocation) => {
    return isCalculatingColumn(allocation.allocationKeyTableMappingId, keys)
      ? Number(removeCommas(allocation.value ?? "0")) + previousValue
      : previousValue;
  }, 0);
};

export const calculateTotalForColumn: calculateTotalForColumnType = (
  mappingId,
  data
) => {
  return (
    filterByKeyAndVal(
      data,
      "allocationKeyTableMappingId",
      mappingId
    ) as AllocationType[]
  ).reduce((previousValue, currentValue) => {
    return previousValue + Number(removeCommas(currentValue.value));
  }, 0);
};

export const isCalculatingColumn: isCalculatingColumnType = (id, keys) => {
  return pathOr(
    false,
    ["allocationKeyDTO", "canDelete"],
    getObjectFromPropertyPathValue(["id"], id, keys)
  );
};

export const calculateTotalForCompany: calculateTotalForCompanyType = (
  record,
  mappings
): number => {
  return record
    .filter(({ allocationKeyTableMappingId }) =>
      isCalculatingColumn(allocationKeyTableMappingId, mappings)
    )
    .reduce((previousValue, currentValue) => {
      return previousValue + Number(removeCommas(currentValue.value));
    }, 0);
};

export const getAllocationDataInOrder: getAllocationDataInOrderType = (
  data,
  companies
) => {
  return data.sort((a, b) => {
    const companyA =
      getObjectFromPropertyValue("id", a[0].elementId, companies)?.name ?? "";
    const companyB =
      getObjectFromPropertyValue("id", b[0].elementId, companies)?.name ?? "";
    return companyA.toLowerCase() > companyB.toLowerCase() ? 1 : -1;
  });
};

export const onAllocationSort: OnAllocationSort = (a, b, keyId) => {
  const findElement = (arr: AllocationType[]) =>
    find<Partial<AllocationType>>(
      propEq("allocationKeyTableMappingId", keyId),
      arr
    ) || {};
  const valA = findElement(a)?.value || "";
  const valB = findElement(b)?.value || "";
  return onSort(valA, valB);
};

export const onAllocationSortTotal: OnAllocationSortTotal = (
  a,
  b,
  mappings
) => {
  return onSort(
    calculateTotalForCompany(a, mappings),
    calculateTotalForCompany(b, mappings)
  );
};

export const percentAllocatedColumn: percentAllocatedColumnType = (
  mappings,
  data
) => [
  {
    title: (
      <div className="p-4 text-white bg-gray-500 whitespace-nowrap">
        % Allocated
      </div>
    ),
    className: "p-0 whitespace-nowrap",
    dataIndex: "perAllocated",
    width: 140,
    key: "perAllocated",
    render: (text, record): ReactElement => {
      const demandId = getObjectFromPropertyPathValue(
        ["allocationKeyDTO", "keyName"],
        "Demand",
        mappings
      )?.id;

      const getValue = (isFooter: boolean) => {
        if (isFooter) {
          const numerator = calculateAllTotal(data, mappings);
          const denominator = calculateTotalForColumn(demandId, data);
          return denominator === 0
            ? "N/A"
            : getPercentage(numerator, denominator);
        } else {
          const demandValue =
            getObjectFromPropertyValue(
              "allocationKeyTableMappingId",
              demandId,
              record
            )?.value ?? 0;

          return and(!isEmpty(demandValue), !equals(Number(demandValue), 0))
            ? getPercentage(
                calculateTotalForCompany(record, mappings) /
                  Number(removeCommas(demandValue))
              )
            : "N/A";
        }
      };

      return (
        <EditableTableCell
          OId={`${record[0]?.elementId}-total`}
          value={getValue(isFooter(record[0].id))}
          defaultCell={false}
          editable={false}
          type={"number"}
          placeholder={"Enter Value"}
          suffix={<span className={"text-muted"}>%</span>}
          className={`${!isFooter(record[0]?.id) && "!bg-zinc-100"}`}
        />
      );
    },
  },
];

export const getPrimaryList: getPrimaryListType = (selected, lenders) => {
  return lenders
    .filter(({ companyDTO: { id } }) => !selected.includes(id))
    .map(({ companyDTO }) => companyDTO);
};

export const getSecondaryList: getSecondaryListType = (
  selected,
  lenders,
  companies
) => {
  const ids = [...selected, ...lenders.map(({ companyDTO: { id } }) => id)];
  return companies.filter(({ id }) => !ids.includes(id));
};

export const getInstitutionName: getInstitutionNameType = (
  record,
  companies
) => {
  return isFooter(record[0]?.id)
    ? "TOTAL SUM"
    : pathOr(
        "",
        ["name"],
        getObjectFromPropertyValue("id", record[0]?.elementId, companies)
      );
};

export const getCellConfig: getCellConfigType = (id, record, data) => {
  const valueIndex = getIndexByKeyVal(
    record,
    "allocationKeyTableMappingId",
    id
  );
  const dataIndex = getIndexByKeyVal(data ?? [], "id", record[valueIndex]?.id);
  return { valueIndex, dataIndex };
};

export const getNewAllocation: getNewAllocationType = (
  allocationKeys,
  keys,
  elementIds,
  id,
  currency,
  unit
) => {
  return flatten<Partial<AllocationType>>(
    elementIds?.map((elementId) =>
      keys.map((allocationKeyTableMappingId) => {
        return {
          peTransactionIdOrPortfolioId: id,
          allocationElementType: AllocationElementType.LENDER_WITH_TX,
          allocationKeyTableMappingId:
            getObjectFromPropertyPathValue(
              ["allocationKeyDTO", "id"],
              allocationKeyTableMappingId,
              allocationKeys ?? []
            )?.id ?? "",
          currency: defaultTo(CurrencyType.DOLLAR, currency),
          elementId,
          unit: defaultTo(UnitType.THOUSAND, unit),
          value: "",
        } as Partial<AllocationType>;
      })
    )
  );
};
export const formatDecimalAndAddCommas: formatDecimalAndAddCommasType = (
  number
) => {
  if (isNaN(Number(number))) {
    return number;
  }
  return addCommasToNumber(
    Number.isInteger(+number) ? number : Number(number).toFixed(2)
  );
};

export const AllocationTableVersionTypes = {
  ALLOCATION_AT_CLOSE: {
    value: AllocationTableVersionType.ALLOCATION_AT_CLOSE,
    label: "At Close",
  },
  ALLOCATION_INCREMENTAL: {
    value: AllocationTableVersionType.ALLOCATION_INCREMENTAL,
    label: "Incremental",
  },
  ALLOCATION_REFINANCE: {
    value: AllocationTableVersionType.ALLOCATION_REFINANCE,
    label: "Refinance",
  },
  ALLOCATION_HOLDINGS: {
    value: AllocationTableVersionType.ALLOCATION_HOLDINGS,
    label: "Holdings",
  },
};

/**
 * Types
 * **/

type getNewAllocationType = (
  allocationKeys: AllocationTableKeyType[],
  keys: string[],
  elementIds: string[],
  id: string,
  currency?: CurrencyType,
  unit?: UnitType
) => Partial<AllocationType>[];

type cellConfigType = {
  dataIndex: number;
  valueIndex: number;
};

type getCellConfigType = (
  id: string,
  record: AllocationType[],
  data: AllocationType[]
) => cellConfigType;

/** Exported Types **/
export type TableDataType = {
  name: string;
  id: string;
  elementId: string;
  tableVersionType: AllocationTableVersionType;
  allocationTableType: AllocationTableType;
  allocationDate?: string | number;
  allocationKeyTableMappingDTOS: Array<AllocationTableKeyType>;
};
export type AllocationTableKeyType = {
  id: string;
  allocationKeyDTO: {
    id: string;
    keyName: string;
    canDelete: boolean;
  };
};

/** Local Types **/
type getAllocationTableKeysType = (
  callback: (a: Array<AllocationKeyType>) => void
) => void;
export type ToolbarData = {
  asOfDate?: string;
  title?: string;
  currency?: CurrencyType;
  unit?: UnitType;
  selectedKeys?: string[];
};
type getInstitutionNameType = (
  record: AllocationType[],
  companies: CompanyType[]
) => string;
type getSecondaryListType = (
  selected: string[],
  lenders: LenderType[],
  companies: CompanyType[]
) => CompanyType[];
type getPrimaryListType = (
  selected: string[],
  lenders: LenderType[]
) => CompanyType[];
type percentAllocatedColumnType = (
  mappings: AllocationTableKeyType[],
  data: AllocationType[]
) => ColumnsType<AllocationType[]>;
type getAllocationDataInOrderType = (
  data: AllocationType[][],
  companies: CompanyType[]
) => AllocationType[][];
type calculateTotalForCompanyType = (
  record: AllocationType[],
  mappings: AllocationTableKeyType[]
) => number;
type isCalculatingColumnType = (
  id: string,
  keys: AllocationTableKeyType[]
) => boolean;
type calculateTotalForColumnType = (
  mappingId: string,
  data: AllocationType[]
) => number;
type calculateAllTotalType = (
  data: AllocationType[],
  keys: AllocationTableKeyType[]
) => number;
type getAllocationFooterRowType = (
  data: AllocationType[]
) => AllocationType[][];
type formatTotalType = (number: number | string) => number | string;
type removeCommasType = (number: string) => number;
type addCommasToNumberType = (number: number | string) => string;
type customDateFormatType = (value: Moment | null) => string;
type formatDecimalAndAddCommasType = (
  number: number | string
) => string | number;
type OnAllocationSortTotal = (
  a: AllocationType[],
  b: AllocationType[],
  mappings: AllocationTableKeyType[]
) => number;
type OnAllocationSort = (
  a: AllocationType[],
  b: AllocationType[],
  keyId: string
) => number;
