import React, { FC, useEffect, useState } from "react";
import { LoadingType, ResponseType, voidType } from "../../utils/uiTypes";
import {
  getAnalyticsDashboard,
  getIndustries,
  getInstitutions,
} from "../../services/services";
import {
  AnalyticsConfigType,
  AnalyticsType,
  IndustryType,
  InstitutionType,
} from "../../utils/types";
import { CustomSpin } from "../general/CustomSpin";
import { usePageTitle } from "../../customHooks/usePageTitle";
import { Empty, Menu, message } from "antd";
import { AnalyticsSection } from "./AnalyticsSection";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { AnalyticsFilters } from "./AnalyticsFilters";
import { isEmpty } from "ramda";

/**
 * Analytics Component
 * @constructor
 */
export const Analytics: FC = () => {
  usePageTitle("Analytics");
  const history = useHistory();
  const location = useLocation();
  const [config, setConfig] = useState<AnalyticsConfigType[]>([]);
  const [loading, setLoading] = useState<LoadingType>({
    loading: false,
    label: "",
  });
  const [industries, setIndustries] = useState<IndustryType[]>([]);
  const [institutions, setInstitutions] = useState<InstitutionType[]>([]);
  const [filters, setFilters] = useState<string | null>(null);

  const [currentActiveSection, setCurrentActiveSection] = useState<string>(
    new URLSearchParams(location.search).get("section") ?? "0"
  );

  /**
   * Fetch Institutions from Database
   */
  const fetchCompanies: voidType = () => {
    getInstitutions({
      params: { isLite: true },
    })
      .then(({ data = [] }: ResponseType<InstitutionType[]>) => {
        setInstitutions(data);
      })
      .catch((error: string) => {
        message.error(error ?? "Error Loading Industries");
      });
  };

  /**
   * Fetch Analytics Dashboard from Database
   */
  const fetchAnalyticsDashboard: voidType = () => {
    setLoading({ loading: true, label: "Loading Analytics" });
    getAnalyticsDashboard({})
      .then(({ data }: ResponseType<AnalyticsType>) => {
        setConfig(JSON.parse(data.config)?.sections ?? null);
      })
      .then(() => {
        setLoading({ loading: false });
      })
      .catch((error: string) => {
        setLoading({ loading: false });
        message.error(error ?? "Error Loading Analytics");
      });
  };

  /**
   * Fetch Industries from Database
   */
  const fetchIndustries: voidType = () => {
    getIndustries({})
      .then(({ data = [] }: ResponseType<IndustryType[]>) => {
        setIndustries(data);
      })
      .catch((error: string) => {
        message.error(error ?? "Error Fetching Industries");
      });
  };

  /**
   * Fetch Required datasets on load
   */
  useEffect(() => {
    fetchAnalyticsDashboard();
    fetchIndustries();
    fetchCompanies();
  }, []);

  /**
   * Parse the query params from url and update filters
   */
  useEffect(() => {
    const params = new URLSearchParams(
      filters + `&section=${currentActiveSection}`
    ).toString();

    history.push({
      pathname: "/insights",
      search: params,
    });
  }, [filters, currentActiveSection]);

  return (
    <div
      className={
        "relative max-h-screen w-full h-screen bg-gray-100 flex flex-col"
      }
    >
      <CustomSpin
        loading={loading.loading}
        loadingText={loading.label}
        transparent={false}
      />
      {!loading.loading && isEmpty(config) && (
        <Empty
          image={
            <i className="fa-solid fa-building-columns text-8xl text-gray-300"></i>
          }
          className={"text-gray-400"}
          description={"No Institutions have been added!"}
        />
      )}
      <div className={"flex flex-col pt-3 gap-2 px-6 bg-white"}>
        <span className={"text-2xl font-medium"}>Analytics</span>
      </div>
      <Menu
        className={
          "w-full px-2 uppercase font-medium text-gray-400 z-[100] border-b-2 border-gray-200"
        }
        defaultSelectedKeys={[
          (new URLSearchParams(location.search).get("section") ?? 0).toString(),
        ]}
        mode={"horizontal"}
        onClick={({ key }): void => {
          setCurrentActiveSection(key);
        }}
        items={config?.map(({ name }, index) => ({
          key: index.toString(),
          className: "select-none",
          label: name,
        }))}
      />

      <div
        className={`w-full max-h-full overflow-y-scroll bg-gray-100 p-4 py-2 flex-col h-full"`}
      >
        <AnalyticsFilters
          filters={filters}
          onChange={(filter): void => {
            setFilters(filter);
          }}
          institutions={institutions}
          industries={industries}
        />
        {!loading.loading &&
          currentActiveSection !== null &&
          filters &&
          config?.map((config, index) => (
            <AnalyticsSection
              isActive={currentActiveSection === index.toString()}
              key={index}
              config={config}
              params={filters}
            />
          ))}
      </div>
    </div>
  );
};
