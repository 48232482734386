import React, { FC, useEffect, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const PDFFileViewer: FC<PdfFileViewer> = ({ url, loadingText }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        setFile(window.URL.createObjectURL(blob));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div
      className={
        "relative h-full w-full flex items-center justify-center bg-transparent"
      }
    >
      <Spin
        spinning={loading}
        size={"small"}
        tip={loadingText}
        indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
      />
      {!loading && file && <object data={file} className={"w-full h-full"} />}
    </div>
  );
};

type PdfFileViewer = {
  url: string;
  loadingText?: string;
};
