import React, { FC, useRef, useState } from "react";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { valOrDefault } from "../../../../utils/utils";
import { Button } from "antd";
import { LenderType } from "../../../../utils/types";
import { TransactionTags } from "../../TransactionTags";

export const MultipleComment: FC<MultipleCommentType> = function ({
  institutions = [],
  onSubmit,
}: MultipleCommentType) {
  const ref = useRef<HTMLDivElement>(null);

  const [checkedList, setCheckedList] = useState<string[]>([]);

  const validateAndSubmit: validateAndSubmitType = (checkedList, element) => {
    const comment = element.innerHTML.replace(/<img[^>]*>/g, "").toString();
    if (comment.length !== 0 && checkedList.length !== 0) {
      onSubmit({ comment, elementIds: checkedList });
    }
  };

  return (
    <div className={"flex flex-col gap-y-5"}>
      <TransactionTags
        lenders={institutions}
        checkedList={checkedList}
        setCheckedList={setCheckedList}
      />
      <div
        ref={ref}
        contentEditable={true}
        id={"conversation"}
        className={
          "prose cursor-text empty:after:content-['Type_your_message_here...'] empty:text-gray-400 p-2 min-w-full focus:ring-0 focus:outline-none border hide-scrollbar min-h-[4rem]"
        }
      />
      <Button
        disabled={
          ref?.current?.innerHTML.toString() === "" || checkedList?.length === 0
        }
        onClick={(): void => {
          ref.current &&
            validateAndSubmit(checkedList, valOrDefault("", ref?.current));
        }}
        className={"bg-primary hover:bg-hover text-white border-0"}
      >
        Submit
      </Button>
    </div>
  );
};

type MultipleCommentType = {
  institutions: LenderType[];
  onSubmit: (values: {
    comment: string;
    elementIds: CheckboxValueType[];
  }) => void;
};
type validateAndSubmitType = (
  checklist: CheckboxValueType[],
  comment: HTMLDivElement
) => void;
