import React, { FC, ReactNode, useEffect, useState } from "react";
import { ModuleAccessLevelType } from "../../utils/types";
import { getApplicationModuleLevels } from "../../services/services";
import { KeyLabelType, ResponseType } from "../../utils/uiTypes";
import { Checkbox, Table } from "antd";
import { MODULES } from "../../utils/module-util";
import { AccessLevelType, ModuleType } from "../../utils/enums";
import { ColumnsType } from "antd/es/table";
import { pathOr } from "ramda";
import { tableColumnHeader } from "../../utils/componentUtils";

const LEVELS_ORDER: AccessLevelType[] = [
  AccessLevelType.FREEMIUM,
  AccessLevelType.LITE,
  AccessLevelType.STANDARD,
  AccessLevelType.ADVANCED,
  AccessLevelType.PREMIUM,
  AccessLevelType.LENDER_DEFAULT,
];

export const ModuleAccessLevelSettings: FC = () => {
  const [moduleSettings, setModuleSettings] = useState<ModuleAccessLevelType[]>(
    []
  );

  const columns: ColumnsType<KeyLabelType<ModuleType, ReactNode>> = [
    { title: tableColumnHeader("Module"), dataIndex: "label" },
    ...moduleSettings.map(({ level, config }) => ({
      title: tableColumnHeader(level.replaceAll("_", " ")),
      dataIndex: "key",
      align: "center" as const,
      render: (key: string) =>
        pathOr(false, [key], config) ? (
          <i className="fa-solid fa-check-double text-emerald-600" />
        ) : (
          <i className="fa-solid fa-xmark text-danger" />
        ),
    })),
  ];

  useEffect(() => {
    getApplicationModuleLevels({})
      .then(({ data }: ResponseType<ModuleAccessLevelType[]>) => {
        setModuleSettings(
          data.sort((a, b) =>
            LEVELS_ORDER.indexOf(a.level) > LEVELS_ORDER.indexOf(b.level)
              ? 1
              : -1
          )
        );
      })
      .catch(console.error);
  }, []);

  return (
    <div className={`w-full h-full flex flex-col overflow-y-auto p-6`}>
      <div className={"overflow-y-auto max-h-full border-b"}>
        <Table
          sticky={true}
          columns={columns}
          dataSource={MODULES}
          pagination={false}
          rowKey={(record) => record.key}
          size={"small"}
          rowClassName={"group hover:bg-blue-50 odd:bg-gray-50"}
        />
      </div>
    </div>
  );
};
