export const HEADER_CLASSNAME =
  "after:!border-b after:!border-b-slate-200 before:!border-t before:!border-t-slate-200 " +
  "after:content-[''] after:absolute after:left-0 after:w-full before:content-[''] before:absolute before:left-0 before:w-full";

export const SHEETS_SEPARATION_HEADER_BORDER_COLOR =
  "after:!border-b after:!border-b-blue-300 before:!border-t before:!border-t-blue-300 " +
  "after:content-[''] after:absolute after:left-0 after:w-full before:content-[''] before:absolute before:left-0 before:w-full";

export const SHEETS_SEPARATION_BORDER_COLOR =
  "bg-blue-300 !border-y !border-y-blue-300";

export const SHEETS_SEPARATION_COL_WIDTH = "4px";

export const RESIZE_COL = "bg-primary";

export const RESIZE_COL_BORDER = "border-r-[3px] !border-r-primary";

export enum PSFontSize {
  SMALL = "text-xxs",
  NORMAL = "text-[13px]",
  LARGE = "text-base",
}

export enum PSColWidth {
  NARROW = 175,
  STANDARD = 200,
  WIDE = 250,
  EXTRA_WIDE = 300,
}
