import React, { FC } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import { Covenant } from "../../../portfolio/portfolioView/portfolioCards/Covenant";
import { CovenantTypeEnum } from "../../../../utils/types";

export const AddCovenantModal: FC<PropType> = function ({
  open,
  onOpen,
  onClose,
  onSubmit,
  isSubmitting,
}: PropType) {
  return (
    <>
      <Button
        type={"text"}
        disabled={isSubmitting}
        className={
          "text-left pl-5 border-0 text-primary disabled:border-opacity-60 font-medium w-full hover:bg-blue-50"
        }
        onClick={onOpen}
      >
        + Add Row
      </Button>
      <Modal
        open={open}
        title={"Add New Covenant"}
        closable={true}
        footer={false}
        destroyOnClose={true}
        onCancel={onClose}
      >
        <Form
          onFinish={(values): void => onSubmit(values)}
          className={"gap-y-2"}
          layout={"vertical"}
        >
          <Form.Item
            name={"name"}
            label={"Covenant Name"}
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input placeholder={"Name"} />
          </Form.Item>
          <Form.Item
            name={"type"}
            label={"Covenant Type"}
            rules={[{ required: true, message: "Type is required" }]}
          >
            <Select className={`w-full group`} placeholder={"Type"}>
              {[
                { label: "Multiple (x)", value: CovenantTypeEnum.MULTIPLE },
                {
                  label: "Currency",
                  value: CovenantTypeEnum.CURRENCY,
                },
                { label: "Numeric", value: CovenantTypeEnum.NUMERIC },
              ]?.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label || value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className={"w-full mb-0 mt-1 text-right"}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              loading={isSubmitting}
              className={"ml-2 bg-primary hover:bg-hover text-white border-0"}
              htmlType={"submit"}
            >
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

type PropType = {
  open: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  isSubmitting: boolean;
  onSubmit: (value: { name: string; type: string }) => void;
};
