import React, { FC, useContext, useEffect, useState } from "react";
import { Button, Form, Input, message, Spin } from "antd";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { UserContext } from "../../context/UserContext";
import { getCompanyById, updateUserProfile } from "../../services/services";
import { CompanyType, UserType } from "../../utils/types";
import { ResponseType } from "../../utils/uiTypes";
import { CustomSpin } from "../general/CustomSpin";
import {
  formItemRequiredRule,
  namePatternRule,
  parsePhoneNumber,
  phoneNumberPatternRule,
  reverseParsePhoneNumber,
} from "../../utils/formUtils";
import { valOrDefault } from "../../utils/utils";
import { PhoneNumberInput } from "../general/PhoneNumberInput";

export const UserProfile: FC = function () {
  const { user, setUser } = useContext(UserContext);
  const [editProfile, setEditProfile] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [userProfile, setUserProfile] = useState(user);
  const [loading, setLoading] = useState<{ loading: boolean; label?: string }>({
    loading: false,
    label: "",
  });
  const [company, setCompanyProfile] = useState<CompanyType>();

  const [form] = Form.useForm();
  const submitUserProfile = (data: any): void => {
    setSubmitting(true);
    updateUserProfile({
      body: JSON.stringify({
        ...data,
        phoneNumber: parsePhoneNumber(data.phoneNumber),
      }),
    })
      .then(({ data }: ResponseType<UserType>) => {
        setSubmitting(false);
        setUser(data);
        setUserProfile(data);
        setEditProfile(false);
        message.success("User Updated Successfully", 1);
      })
      .catch((error: string) => {
        message.error(
          error ? error : "Something went wrong. Please try again."
        );
        setSubmitting(false);
      });
  };

  const getCompanyProfile = (): void => {
    setLoading({ loading: true, label: "Loading Profile..." });
    getCompanyById({
      segments: {
        companyId: user?.companyId,
      },
      params: { isLite: false },
    })
      .then(({ data }: ResponseType<CompanyType>) => {
        setCompanyProfile(data);
      })
      .then(() => {
        setLoading({ loading: false });
      });
  };

  useEffect(() => {
    if (user?.companyId) {
      getCompanyProfile();
    }
  }, []);

  return (
    <>
      {loading.loading ? (
        <CustomSpin loading={loading.loading} loadingText={loading.label} />
      ) : (
        <div className={"max-h-full h-full w-full p-6"}>
          <div className={"bg-white p-6 h-full overflow-y-auto"}>
            <div className={"max-w-screen-md mx-auto"}>
              <div className={"text-center text-lg font-medium py-6"}>
                <UserOutlined className={"pr-2"} />
                My profile
              </div>
              {userProfile && editProfile ? (
                <Form
                  layout={"vertical"}
                  onFinish={submitUserProfile}
                  form={form}
                  className={"flex flex-col gap-y-3 h-full overflow-y-auto"}
                  initialValues={{
                    firstName: userProfile.firstName,
                    lastName: userProfile.lastName,
                    email: userProfile.email,
                    title: userProfile.title,
                    phoneNumber: reverseParsePhoneNumber(
                      valOrDefault("", userProfile?.phoneNumber)
                    ),
                    companyName: company?.name,
                  }}
                >
                  <Form.Item
                    className={`m-0 border-0 p-0`}
                    label={"First Name"}
                    name={"firstName"}
                    rules={[formItemRequiredRule, namePatternRule]}
                  >
                    <Input placeholder={"First Name"} autoFocus={true} />
                  </Form.Item>
                  <Form.Item
                    className={"m-0 my-2 border-0"}
                    label={"Last Name"}
                    name={"lastName"}
                    rules={[formItemRequiredRule, namePatternRule]}
                  >
                    <Input placeholder={"Last Name"} />
                  </Form.Item>
                  <Form.Item
                    className={"m-0 my-2 border-0"}
                    name={"email"}
                    label={"E-Mail"}
                  >
                    <Input
                      disabled={true}
                      type={"email"}
                      placeholder={"Enter e-mail"}
                      prefix={<MailOutlined className={" text-gray-400"} />}
                    />
                  </Form.Item>
                  <Form.Item
                    className={"m-0 my-2 border-0"}
                    name={"phoneNumber"}
                    label={"Phone Number"}
                    rules={[phoneNumberPatternRule()]}
                    initialValue={{
                      short: "GB",
                      code: "44",
                    }}
                  >
                    <PhoneNumberInput placeholder={"Phone Number"} />
                  </Form.Item>
                  <Form.Item
                    className={"m-0 my-2 border-0"}
                    label={"Title"}
                    name={"title"}
                  >
                    <Input placeholder={"Enter title"} />
                  </Form.Item>
                  <Form.Item
                    className={"m-0 my-2 border-0"}
                    label={"Company"}
                    name={"companyName"}
                  >
                    <Input placeholder={"Company name"} disabled={true} />
                  </Form.Item>
                  <div className={"mt-auto flex justify-end"}>
                    <Button
                      className={" w-32 mr-6"}
                      onClick={(): void => {
                        setEditProfile(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={
                        "mt-auto w-32 bg-primary hover:bg-hover text-white border-0"
                      }
                      onClick={(): void => {
                        form.submit();
                      }}
                      disabled={submitting}
                      icon={
                        submitting && <Spin className={"mr-2"} size={"small"} />
                      }
                    >
                      {submitting ? "Updating" : "Update"}
                    </Button>
                  </div>
                </Form>
              ) : (
                <div>
                  <div>
                    <div className={"opacity-70 pt-2 pb-1"}>First Name</div>
                    <div className={"pb-4"}>{userProfile?.firstName}</div>
                  </div>
                  <div>
                    <div className={"opacity-70 pt-2 pb-1"}>Last Name</div>
                    <div className={"pb-4"}>{userProfile?.lastName}</div>
                  </div>
                  <div>
                    <div className={"opacity-70 pt-2 pb-1"}>Email</div>
                    <div className={"pb-4"}>{userProfile?.email}</div>
                  </div>
                  <div>
                    <div className={"opacity-70 pt-2 pb-1"}>Phone Number</div>
                    <div
                      className={
                        "pb-4 empty:after:content-['Not_Specified'] empty:text-xs empty:text-red-500"
                      }
                    >
                      {userProfile?.phoneNumber}
                    </div>
                  </div>
                  <div>
                    <div className={"opacity-70 pt-2 pb-1"}>Title</div>
                    <div
                      className={
                        "pb-4 empty:after:content-['Not_Specified'] empty:text-xs empty:text-red-500"
                      }
                    >
                      {userProfile?.title}
                    </div>
                  </div>
                  <div>
                    <div className={"opacity-70 pt-2 pb-1"}>Company</div>
                    <div
                      className={
                        "pb-4 empty:after:content-['Not_part_of_a_company!'] empty:text-xs empty:text-red-500"
                      }
                    >
                      {company?.name}
                    </div>
                  </div>
                  <Button
                    className={
                      "my-2 w-32 bg-primary hover:bg-hover text-white border-0"
                    }
                    type={"primary"}
                    onClick={(): void => {
                      setEditProfile(true);
                    }}
                  >
                    Edit Profile
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
