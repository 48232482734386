import React, { FC, ReactNode, useState } from "react";
import { Badge, Button, Layout, Menu, Tooltip } from "antd";
import { Route, Switch } from "react-router-dom";
import { MenuRouteType } from "../../utils/uiTypes";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { equals, flatten } from "ramda";
import { CustomSpin } from "../general/CustomSpin";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { getMenu, getRoutes } from "../../utils/navigation";
import { Content } from "antd/es/layout/layout";

export const WithSideNav: FC<WithSideNavType> = ({
  title,
  menu,
  loading = false,
  defaultSelectedKeys,
  selectedKeys,
  defaultValue,
  gotoRoute,
  clipBody = false,
  height = "screen",
  showMenu = true,
  header,
  footer,
}: WithSideNavType) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout className={`${equals(height, "screen") ? "h-screen" : "h-full"}`}>
      {title && (
        <div
          className={`py-3 px-5 text-lg font-medium capitalize w-full block bg-white`}
        >
          {title}
        </div>
      )}
      <Layout.Header className={"py-2 px-5 bg-transparent !h-min leading-none"}>
        {header}
      </Layout.Header>
      <Layout>
        {showMenu && (
          <Layout.Sider
            collapsedWidth={60}
            collapsed={collapsed}
            trigger={null}
            collapsible={true}
            className={`flex flex-col relative w-full flex-1 max-h-full mt-2 mb-5 ml-5 max-w-full bg-white shadow-xxs print:hidden z-[500]`}
          >
            <Button
              size={"small"}
              shape={"circle"}
              className={"trigger absolute -right-3 top-5 z-50 bg-white"}
              onClick={() => setCollapsed(!collapsed)}
              icon={
                collapsed ? (
                  <i className="fa-solid fa-chevron-up rotate-90 text-sm"></i>
                ) : (
                  <i className="fa-solid fa-chevron-up -rotate-90 text-sm"></i>
                )
              }
            />
            <div className={"max-h-full overflow-y-auto hide-scrollbar"}>
              <Menu
                className={`w-full max-h-full overflow-y-auto`}
                defaultValue={defaultValue}
                selectedKeys={selectedKeys}
                defaultSelectedKeys={defaultSelectedKeys}
                mode={"vertical"}
                onClick={gotoRoute}
              >
                {getMenu(menu)}
              </Menu>
            </div>
          </Layout.Sider>
        )}

        <Content
          className={`relative flex-auto w-full h-full max-h-full ${
            height === "screen" ? "max-h-screen" : "max-h-full"
          } ${clipBody ? "overflow-y-clip" : "overflow-y-auto"}`}
        >
          <CustomSpin loading={loading} />
          <Switch>{flatten(getRoutes(menu))}</Switch>
        </Content>
      </Layout>
      <Layout.Footer className={"p-0 bg-transparent !h-min leading-none"}>
        {footer}
      </Layout.Footer>
    </Layout>
  );
};

type WithSideNavType = {
  title?: ReactNode;
  menu: MenuRouteType[];
  defaultSelectedKeys?: string[];
  selectedKeys?: string[];
  defaultValue?: string;
  gotoRoute: MenuClickEventHandler;
  clipBody?: boolean;
  height?: "screen" | "full";
  showMenu?: boolean;
  loading?: boolean;
  header?: ReactNode | ReactNode[];
  footer?: ReactNode | ReactNode[];
};
