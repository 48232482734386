import React, { FC, ReactElement } from "react";
import { Button, Col, Row } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { PortfolioSectionFieldType } from "../../../utils/types";
import { ConfirmDelete } from "../../../utils/confirmationModals";
import { DraggableElement } from "../../general/DraggableElement";
import { TrackersTypes } from "../../../utils/uiTypes";
import { ACTION_BUTTON_CSS } from "../../../utils/cssConfigs";
import { PortfolioInputs } from "./PortfolioInputs";

export const PortfolioInputField: FC<PortfolioInputFieldType> = ({
  field,
  disabled,
  onDragging,
  onDrop,
  onDelete,
  onUpdate,
  openEditField,
  index,
  customizeView = false,
}: PortfolioInputFieldType) => {
  return (
    <DraggableElement
      index={Number(field.rank)}
      className={`py-0 md:mb-0 ${
        field.canDelete ? "hover:!bg-gray-100" : "hover:!bg-gray-100/80"
      }`}
      accept={TrackersTypes.PORTFOLIO_INPUT}
      canDrag={customizeView}
      id={field.id}
      onDragging={onDragging}
      onDrop={onDrop}
    >
      {(ref, drag, drop): ReactElement => {
        drag(drop(ref));
        return (
          <div className={"flex flex-row items-start"}>
            {customizeView && (
              <MenuOutlined
                ref={drag}
                className={"float-center mx-2 my-3 cursor-grab"}
              />
            )}
            <Row
              className={`py-1 relative w-full ${
                customizeView && !field.canDelete && "!opacity-50"
              }`}
            >
              <Col xs={24} md={8}>
                <div className={"text-left md:text-right w-full h-full"}>
                  <p
                    className={
                      "px-4 text-gray-400/90 inline align-sub font-medium"
                    }
                  >
                    {field.fieldName.toUpperCase()}
                  </p>
                </div>
              </Col>
              <Col xs={24} md={16}>
                <div
                  className={`min-h-[33px] mx-1 md:px-2 flex flex-row items-start justify-between w-full`}
                >
                  <div
                    className={`border-b w-fit min-w-[50%] ${
                      !disabled &&
                      !customizeView &&
                      "group-hover:border-blue-400 group-focus-within:border-blue-400"
                    }  md:border-transparent`}
                  >
                    <PortfolioInputs
                      value={field}
                      disabled={customizeView || disabled}
                      onUpdate={onUpdate}
                    />
                  </div>
                  {customizeView && field.canDelete && (
                    <div className={"inline-flex gap-1"}>
                      <Button
                        type={"text"}
                        icon={<i className="fa-regular fa-trash-can" />}
                        className={`${ACTION_BUTTON_CSS} text-danger opacity-0 group-hover:opacity-100 !border !shadow-none !border-gray-200 !duration-50 hover:bg-danger hover:text-white hover:border-danger`}
                        onClick={(): void => {
                          ConfirmDelete(
                            "Are you sure you want to remove this field?",
                            () => onDelete(field),
                            <></>
                          );
                        }}
                      />
                      <Button
                        type={"text"}
                        icon={<i className="fas fa-pencil-alt" />}
                        className={`${ACTION_BUTTON_CSS} text-primary opacity-0 group-hover:opacity-100 !border !shadow-none !border-gray-200 !duration-50 hover:bg-primary hover:text-white hover:border-primary`}
                        onClick={(): void => {
                          openEditField(field);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        );
      }}
    </DraggableElement>
  );
};

type PortfolioInputFieldType = {
  field: PortfolioSectionFieldType;
  disabled: boolean;
  onDragging: (oldIndex: number, newIndex: number) => void;
  onDrop: () => void;
  onDelete: (a: PortfolioSectionFieldType) => void;
  onUpdate: (field: PortfolioSectionFieldType) => void;
  openEditField: (field: PortfolioSectionFieldType) => void;
  index: number;
  customizeView?: boolean;
};
