import React, { FC, useMemo } from "react";
import { indexBy } from "ramda";
import { Collapse, Tag } from "antd";

export const SemanticGlossary: FC<SemanticGlossaryType> = ({ glossary }) => {
  const categorizedGlossary = useMemo(() => {
    const s = glossary.map((value) => value.split(","));
    return indexBy((v) => v[0], s);
  }, [glossary]);

  console.log(categorizedGlossary);

  return (
    <Collapse bordered={false}>
      {Object.entries(categorizedGlossary).map(([k, v]) => (
        <Collapse.Panel key={k} header={k.trim()}>
          <div className={"flex items-center gap-y-2 flex-wrap"}>
            {v.map((v) => (
              <Tag key={v}>{v.trim()}</Tag>
            ))}
          </div>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

type SemanticGlossaryType = {
  glossary: string[];
};
