import React, { FC, ReactNode, useEffect, useState } from "react";
import { PortfolioCard } from "../PortfolioCard";
import { useParams } from "react-router";
import { AutoComplete, Button, message, Select, Table, Tag } from "antd";
import {
  FileResponseType,
  ResponseType,
  voidType,
} from "../../../../utils/uiTypes";
import {
  AllocationKeyType,
  CapitalStructureTableFieldDTO,
  CapitalStructureValuesType,
  PortfolioSectionFieldType,
  PortfolioSectionTableVersionDTO,
  PortfolioSectionType,
} from "../../../../utils/types";
import {
  AllocationTableType,
  AnalyticsCurrencyType,
  getCurrency,
  PermissionType,
  SectionFieldType,
  UnitLabelType,
  UnitType,
} from "../../../../utils/enums";
import moment from "moment";
import { initiateFetchSection } from "../../../../utils/portfolio";
import { ColumnsType } from "antd/es/table";
import {
  ascend,
  assocPath,
  equals,
  find,
  findIndex,
  isEmpty,
  isNil,
  pathOr,
  pluck,
  prop,
  propEq,
  sort,
  sortBy,
  transpose,
} from "ramda";
import EditableTableCell, {
  SelectOptionType,
} from "../../../general/EditableTableCell";
import {
  addCapitalStructureRow,
  deleteCapitalStructureRow,
  deleteCapitalStructureVersion,
  downloadCaptialStructure,
  getAllFacilityKeys,
  getCapitalStructureVersion,
  updateCapitalStructureFields,
  updateCapitalStructureRow,
  updatePortfolioSectionFieldAnswer,
} from "../../../../services/services";
import { ColumnType } from "antd/es/table/interface";
import {
  ACTION_BUTTON_CSS,
  PRIMARY_BUTTON_STYLE,
} from "../../../../utils/cssConfigs";
import {
  ConfirmDelete,
  ConfirmDownload,
} from "../../../../utils/confirmationModals";
import {
  doNothing,
  getObjectFromPropertyValue,
  updateItemInArray,
  valOrDefault,
} from "../../../../utils/utils";
import { PortfolioAdminPermissionBlock } from "../../PortfolioAdminPermissionBlock";
import useWindowDimensions from "../../../../customHooks/useWindowDimensions";
import {
  FullScreenButton,
  FullScreenWidget,
} from "../../../general/FullScreenWidget";
import Title from "antd/es/typography/Title";
import {
  getContainerForFullScreen,
  getPopupContainerForSelect,
} from "../../../../utils/container";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { NewCapStructureVersionModal } from "../../portfolioModals/NewCapStructureVersionModal";
import { EditCapStructureVersionModal } from "../../portfolioModals/EditCapStructureVersionModal";
import { usePageTitle } from "../../../../customHooks/usePageTitle";

const parseAndCompareByRank = ascend<CapitalStructureTableFieldDTO>((a) =>
  Number(prop("rank", a))
);
const sortByRank: sortByRankType = (array) =>
  sort(parseAndCompareByRank, array);

const FORMULA_VARIABLES = ["amount", "availableamount"];

function replaceVariablesInFormula(
  formula: string,
  variables: Record<string, any>
) {
  let modifiedFormula = formula;

  // Sort keys by length in descending order to replace longer names first
  Object.keys(variables)
    .sort((a, b) => b.length - a.length)
    .forEach((variable) => {
      // Use a regular expression to match whole words only to prevent partial matches
      const regex = new RegExp(`\\b${variable}\\b`, "g");
      modifiedFormula = modifiedFormula.replace(regex, variables[variable]);
    });

  return modifiedFormula;
}

export const CapitalStructure: FC<CapitalStructureType> = ({
  permissions,
  portfolioId,
}) => {
  usePageTitle("Capital Structure");

  const { height: windowHeight } = useWindowDimensions();

  const { sectionId }: ParamsType = useParams();

  const [section, setSection] = useState<PortfolioSectionType | null>(null);

  const [addRowLoading, setAddRowLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [lastUpdated, setLastUpdated] = useState<string>("--/--/----");
  const [capitalStructureTableFields, setCapitalStructureTableFields] =
    useState<CapitalStructureTableFieldDTO[]>([]);
  const [selectedVersion, setSelectedVersion] =
    useState<PortfolioSectionTableVersionDTO | null>(null);
  const [newVersionModal, setNewVersionModal] = useState(false);
  const [editVersion, setEditVersion] = useState(false);
  const [capitalStructureTableVersions, setCapitalStructureTableVersions] =
    useState<PortfolioSectionTableVersionDTO[]>([]);
  const [allocationTableKeys, setAllocationTableKeys] = useState<
    AllocationKeyType[]
  >([]);
  const getFieldTypeData = (
    type: SectionFieldType,
    fieldUnit?: UnitType,
    formula?: string
  ): FieldDataType | undefined => {
    switch (type) {
      case "TEXT":
        return { type: "textarea" };
      case "DROP_BOX":
        return { type: "select" };
      case "CURRENCY":
        return { type: "select" };
      case "DATE":
        return { type: "date" };
      case "MONETARY":
        return !isEmpty(formula) && !isNil(formula)
          ? {
              type: "formula",
              unit: UnitLabelType[fieldUnit ?? UnitType.MILLION],
              currency: section?.reportingCurrency ?? "",
              formula,
            }
          : {
              type: "number",
              unit: UnitLabelType[fieldUnit ?? UnitType.MILLION],
              currency: section?.reportingCurrency ?? "",
            };
      case "NUMERIC":
        return { type: "number", unit: fieldUnit };
      case "LONG_TEXT":
        return { type: "textarea" };
    }
  };

  const getOptions = (
    type: SectionFieldType,
    dropdownOptions?: any[]
  ): SelectOptionType[] | undefined => {
    switch (type) {
      case "DROP_BOX":
        return dropdownOptions;
      case "CURRENCY":
        return dropdownOptions?.map((o: SelectOptionType) => {
          const value: string = pathOr(o.value, [o.key], AnalyticsCurrencyType);
          return { key: o.key, value };
        });
      case "LONG_TEXT":
        return undefined;
    }
  };

  const deleteRow = (rank: string): void => {
    deleteCapitalStructureRow({
      segments: {
        portfolioId,
        sectionId,
        rank,
      },
      params: { tableVersionId: selectedVersion?.id },
    })
      .then(({ data }: ResponseType<CapitalStructureTableFieldDTO[]>) => {
        setCapitalStructureTableFields(() => sortByRank(data));
        message.success("Row removed successfully.");
      })
      .then(updateAsOfDate)
      .catch((error: string) => {
        console.error(error);
        message.error("Something went wrong. Please try again");
      });
  };

  const formatDataSource = (
    fields: CapitalStructureTableFieldDTO[]
  ): CapitalStructureValuesType[][] =>
    transpose<CapitalStructureValuesType>(
      fields.reduce(
        (previousValue, currentValue) => [
          ...previousValue,
          currentValue?.capitalStructureValues?.sort((a, b) =>
            parseInt(a?.rank) > parseInt(b?.rank) ? 1 : -1
          ),
        ],
        [] as CapitalStructureValuesType[][]
      )
    );

  const tableData = formatDataSource(capitalStructureTableFields);
  const deleteCol: ColumnType<CapitalStructureValuesType[]> = {
    title: <div className="p-4 whitespace-nowrap bg-gray-700">&nbsp;</div>,
    dataIndex: "",
    key: "",
    width: "full",
    fixed: tableData.length > 0 ? "right" : false,
    align: "right" as const,
    className:
      "p-0 whitespace-nowrap  group-hover:bg-blue-50 transition-none min-w-[50px] !bg-gray-100",
    render: function actions(_: string, record, index): ReactNode {
      return (
        <span
          className={
            "relative flex flex-row flex-row-reverse items-center gap-x-3 pr-2"
          }
          onClick={(event): void => event.stopPropagation()}
        >
          <Button
            type={"text"}
            icon={<i className="fas fa-ellipsis-h" />}
            className={
              "absolute right-0 border-0 flex items-center px-2 text-gray-900 block opacity-50 group-hover:opacity-0 mr-2"
            }
          />
          <Button
            type={"text"}
            icon={<i className="fas fa-trash-alt" />}
            className={`${ACTION_BUTTON_CSS}  hover:text-primary opacity-0 group-hover:opacity-100`}
            onClick={(): void => {
              ConfirmDelete(
                "Are you sure you want to remove this row? This will erase all previously saved data.",
                () => deleteRow(record[index].rank),
                <></>
              );
            }}
          />
        </span>
      );
    },
  };

  const getColumns = (
    data: CapitalStructureTableFieldDTO[],
    editable: boolean
  ): ColumnsType<CapitalStructureValuesType[]> =>
    data.map(
      (
        { label, fieldType, rank, tag, unit, id = "", options, formula },
        fieldIndex
      ) => {
        const nameCol = fieldType === SectionFieldType.TEXT && tag === "name";
        return {
          title: (
            <div
              className={`p-4 whitespace-nowrap text-white ${
                equals(Number(rank), 0) ? "bg-gray-700" : "bg-gray-500"
              }`}
            >
              {label}
            </div>
          ),
          className: `p-0 whitespace-nowrap min-w-[15rem] ${
            equals(Number(rank), 0) && "!bg-gray-100 !z-20"
          }`,
          fixed: equals(Number(rank), 0) ? "left" : false,
          dataIndex: fieldIndex,
          render: (
            data: CapitalStructureValuesType,
            record,
            rowIndex
          ): ReactNode => {
            const dropdownOptions =
              isEmpty(options) || isNil(options) ? [] : JSON.parse(options);
            const type = getFieldTypeData(fieldType, unit, formula);
            const selectOptions = getOptions(fieldType, dropdownOptions);
            const isFormula = !isEmpty(formula) && !isNil(formula);
            let formulaValue = "";
            if (isFormula) {
              const parsedFormula = JSON.parse(formula);
              const formulaVariables = parsedFormula.variables;
              const formulaValues = formulaVariables.reduce(
                (acc: string, variable: string) => {
                  const field = capitalStructureTableFields.find(
                    (field) => field.tag === variable
                  );
                  const value = pathOr(
                    "0",
                    ["capitalStructureValues", rowIndex, "value"],
                    field ?? {}
                  );
                  return acc.replace(variable, value as string);
                },
                parsedFormula.expression
              );
              formulaValue = eval(formulaValues);
            }
            return nameCol && editable ? (
              <AutoComplete
                getPopupContainer={getContainerForFullScreen}
                bordered={false}
                popupClassName={"!z-50"}
                className="m-0 w-full py-2.5 border border-transparent false bg-transparent "
                value={data.value}
                filterOption={(inputValue, option) =>
                  option!.value
                    ?.toString()
                    .toUpperCase()
                    ?.indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={(val): void => {
                  updateRow(
                    { ...data, value: val?.toString() },
                    id,
                    fieldIndex,
                    rowIndex
                  );
                }}
                placeholder={"-"}
                options={allocationTableKeys
                  .filter(
                    ({ allocationTableType, canDelete }) =>
                      allocationTableType === AllocationTableType.FINAL_HOLD &&
                      canDelete
                  )
                  .map(({ keyName }) => ({
                    value: keyName,
                    label: keyName,
                  }))}
              />
            ) : (
              <EditableTableCell
                editable={permissions.includes(PermissionType.ADMIN_PORTFOLIO)}
                bordered={false}
                OId={data?.id ?? ""}
                value={isFormula ? formulaValue : data.value}
                onChange={(val): void => {
                  updateRow(
                    { ...data, value: val.toString() },
                    id,
                    fieldIndex,
                    rowIndex
                  );
                }}
                defaultCell={false}
                placeholder={"-"}
                prefix={
                  type?.currency && section?.reportingCurrency
                    ? section.reportingCurrency
                    : undefined
                }
                suffix={type?.unit || unit}
                type={type?.type}
                selectOptions={selectOptions}
                padding={"px-1"}
              />
            );
          },
        };
      }
    );

  const getCapitalStructureData: getCapitalStructureDataType = (versionId) => {
    initiateFetchSection(sectionId, portfolioId)
      .then((data) => {
        setSection(data);
        setLoading(false);
        setCapitalStructureTableFields(() =>
          sortByRank(data.capitalStructureTableFieldDTOS)
        );
        setCapitalStructureTableVersions(
          data.portfolioSectionTableVersionDTOS ?? []
        );
        const selectedVersion = versionId
          ? find(propEq("id", versionId), data.portfolioSectionTableVersionDTOS)
          : data.portfolioSectionTableVersionDTOS[0];
        setSelectedVersion(
          selectedVersion ?? data.portfolioSectionTableVersionDTOS[0]
        );
      })
      .catch((e: string) => e && message.error(e));
  };

  const getCapitalStructureVersionData = (versionId: string) => {
    getCapitalStructureVersion({
      segments: { portfolioId, sectionId },
      params: { versionId: versionId },
    })
      .then((data: ResponseType<PortfolioSectionType>) => {
        setSection(data.data);
        setCapitalStructureTableVersions(
          data?.data.portfolioSectionTableVersionDTOS
        );

        setCapitalStructureTableFields(() =>
          sortByRank(data.data.capitalStructureTableFieldDTOS)
        );
        const asOfDate: PortfolioSectionFieldType = getObjectFromPropertyValue(
          "fieldNameSystemLabel",
          "asOfDate",
          data?.data.portfolioSectionFieldDTOS ?? []
        );
        asOfDate &&
          setLastUpdated(moment(Number(asOfDate.answer)).format("MM/DD/YYYY"));
      })
      .catch((error: string) => console.error(error));
  };

  const updateAsOfDate: voidType = () => {
    const field = section?.portfolioSectionFieldDTOS.find(
      (item) => item.fieldNameSystemLabel === "asOfDate"
    );
    updatePortfolioSectionFieldAnswer({
      body: JSON.stringify({
        ...field,
        portfolioId,
        answer: moment().valueOf(),
      }),
    })
      .then(() => {
        setLastUpdated(moment(moment().valueOf()).format("MM/DD/YYYY"));
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Something went wrong, input data not saved.", 3);
      });
  };

  const deleteCapStructVersion = (versionId: string) => {
    deleteCapitalStructureVersion({
      segments: {
        portfolioId: portfolioId,
        sectionId: sectionId,
        versionId: versionId,
      },
    })
      .then((data: ResponseType<PortfolioSectionTableVersionDTO>) => {
        message.success("Version deleted successfully.", 3);
        getCapitalStructureData();
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Something went wrong, input data not saved.", 3);
      });
  };

  const getFormulaValue = (
    formulaVariables: string[],
    expression: string,
    value: Record<string, any>,
    rowIndex: number
  ) => {
    const formulaValues = formulaVariables.reduce(
      (acc: Record<string, any>, variable: string) => {
        const field = capitalStructureTableFields.find(
          (field) => field.tag === variable
        );
        const value = pathOr(
          "0",
          ["capitalStructureValues", rowIndex, "value"],
          field ?? {}
        );
        return { ...acc, [field?.tag ?? ""]: value };
      },
      {}
    );
    return eval(
      replaceVariablesInFormula(expression, { ...formulaValues, ...value })
    );
  };
  const updateRow = (
    record: CapitalStructureValuesType,
    fieldId: string,
    fieldIndex: number,
    rowIndex: number
  ): void => {
    setCapitalStructureTableFields(() =>
      sortByRank(
        assocPath<CapitalStructureTableFieldDTO[]>(
          [fieldIndex, "capitalStructureValues", rowIndex],
          record,
          capitalStructureTableFields
        )
      )
    );
    updateCapitalStructureRow({
      segments: { portfolioId, sectionId, fieldId },
      body: JSON.stringify(record),
    })
      .then(() => {
        const field = find(propEq("id", fieldId), capitalStructureTableFields);
        if (field && FORMULA_VARIABLES.includes(field.tag)) {
          const formulaField = find(
            propEq("tag", "totalamount"),
            capitalStructureTableFields
          );
          if (formulaField && formulaField.formula) {
            const row = formulaField?.capitalStructureValues?.find(
              (v) => v.rank === record.rank
            );
            const parsedFormula = JSON.parse(formulaField.formula);
            const expression = parsedFormula.expression;
            updateCapitalStructureRow({
              segments: { portfolioId, sectionId, fieldId: formulaField.id },
              body: JSON.stringify({
                ...row,
                value: getFormulaValue(
                  parsedFormula.variables,
                  expression,
                  { [field.tag]: record.value },
                  rowIndex
                ),
              }),
            })
              .then(doNothing)
              .catch(doNothing);
          }
        }
      })
      .catch((error: string) => console.error(error));
  };
  const onDownload = (): void => {
    message.loading({
      content: "Processing File",
      duration: 0,
      key: "download-" + name,
    });
    downloadCaptialStructure({
      segments: {
        tableVersionId: selectedVersion?.id,
        portfolioId,
        sectionId,
      },
    })
      .then(({ url, filename }: FileResponseType) => {
        setLoading(false);
        message.info({
          content: "File Ready to Download",
          key: "download-" + name,
        });
        ConfirmDownload(filename, url);
      })
      .catch((error: string) => {
        setLoading(false);
        message.error({
          content: valOrDefault("Error Downloading File!", error),
          key: "download-" + name,
        });
      });
  };

  const addRow = (tableVersionId: string) => {
    setAddRowLoading(true);
    addCapitalStructureRow({
      segments: { portfolioId, sectionId },
      params: { tableVersionId: tableVersionId },
    })
      .then(({ data }: ResponseType<CapitalStructureTableFieldDTO[]>) => {
        setCapitalStructureTableFields(() => sortByRank(data));
        setAddRowLoading(false);
      })
      .then(() => updateAsOfDate())
      .catch((error: string) => {
        console.error(error);
        setAddRowLoading(false);
      });
  };

  const onUnitChange = (unit: UnitType) => {
    const updatedFieldsData = capitalStructureTableFields.map((o) =>
      o.fieldType === SectionFieldType.MONETARY ? { ...o, unit } : o
    );

    updateCapitalStructureFields({
      segments: { portfolioId, sectionId },
      body: JSON.stringify(updatedFieldsData),
    })
      .then(() => {
        setCapitalStructureTableFields(() => sortByRank(updatedFieldsData));
      })
      .then(updateAsOfDate)
      .catch((error: string) => console.error(error));
  };

  useEffect(() => {
    if (portfolioId && sectionId) {
      getCapitalStructureData();
      getAllFacilityKeys({}).then((data: ResponseType<AllocationKeyType[]>) => {
        setAllocationTableKeys(data.data);
      });
    }
  }, [portfolioId, sectionId]);

  return (
    <PortfolioCard
      permissions={permissions}
      key={section?.id}
      loading={loading}
      bodyStyle={{ padding: "1px 5px 0 5px" }}
      title={section?.sectionName ?? "Capital Structure"}
    >
      <FullScreenWidget>
        {(isFullScreen, trigger): ReactNode => (
          <div
            className={`${
              isFullScreen &&
              "bg-gray-50 h-screen w-screen overflow-y-scroll p-6"
            } h-full`}
          >
            <div className={"pb-5"}>
              <div className={"flex flex-row justify-end gap-2 mb-2"}>
                <PortfolioAdminPermissionBlock permissions={permissions}>
                  <Select
                    className={"w-36"}
                    getPopupContainer={getContainerForFullScreen}
                    value={
                      getObjectFromPropertyValue(
                        "fieldType",
                        SectionFieldType.MONETARY,
                        capitalStructureTableFields
                      )?.unit
                    }
                    placeholder={"Select Unit"}
                    onSelect={onUnitChange}
                    dropdownMatchSelectWidth={true}
                    options={[
                      { value: "THOUSAND", label: "Thousand (K)" },
                      { value: "MILLION", label: "Million (M)" },
                    ]}
                  />
                </PortfolioAdminPermissionBlock>

                {section?.reportingCurrency && (
                  <Button>{section?.reportingCurrency}</Button>
                )}

                <Button
                  type={"text"}
                  icon={<DownloadOutlined />}
                  disabled={!tableData.length}
                  onClick={onDownload}
                  className={`${ACTION_BUTTON_CSS}`}
                />
                <FullScreenButton
                  isFullScreen={isFullScreen}
                  trigger={trigger}
                />
              </div>
              <div className={"flex flex-row items-center gap-2 justify-end"}>
                <>
                  {isFullScreen && (
                    <Title level={4} className="opacity-90 mr-auto my-auto">
                      Capital Structure
                    </Title>
                  )}

                  <div className={"w-full flex justify-between"}>
                    <div className={"flex flex-row gap-2 items-center"}>
                      <Select
                        getPopupContainer={getPopupContainerForSelect}
                        className={"w-96 shadow"}
                        onSelect={(v: any) => {
                          const index = findIndex(
                            propEq("id", v),
                            capitalStructureTableVersions
                          );
                          if (index >= 0) {
                            setSelectedVersion(
                              capitalStructureTableVersions[index]
                            );
                            getCapitalStructureVersionData(
                              capitalStructureTableVersions[index].id
                            );
                          }
                        }}
                        suffixIcon={
                          <i className="fa-solid fa-angle-down text-primary"></i>
                        }
                        value={selectedVersion?.id}
                        options={capitalStructureTableVersions?.map(
                          ({ id, versionName, versionDate }) => ({
                            label: (
                              <div
                                className={
                                  "flex flex-row gap-2 justify-between"
                                }
                              >
                                <div className={"ellipsis truncate"}>
                                  {versionName}
                                </div>
                                <div className={"italic text-right"}>
                                  {!isEmpty(versionDate) &&
                                    !isNil(versionDate) && (
                                      <Tag color={"blue"}>
                                        {moment(versionDate).format("ll")}
                                      </Tag>
                                    )}
                                </div>
                              </div>
                            ),
                            value: id,
                          })
                        )}
                      />
                      <PortfolioAdminPermissionBlock permissions={permissions}>
                        <div className={"flex flex-row gap-2 group"}>
                          <Button
                            title={"Edit Key Information"}
                            icon={<EditOutlined />}
                            disabled={!selectedVersion}
                            onClick={(): void => {
                              setEditVersion(true);
                            }}
                            className={`${ACTION_BUTTON_CSS}`}
                          />
                          {capitalStructureTableVersions.length > 1 && (
                            <Button
                              disabled={!selectedVersion}
                              title={"Delete Version"}
                              icon={<DeleteOutlined />}
                              onClick={(): void => {
                                ConfirmDelete(
                                  "Are you sure you want to delete this version? All entered data will be lost.",
                                  () => {
                                    if (selectedVersion?.id) {
                                      deleteCapStructVersion(
                                        selectedVersion?.id
                                      );
                                    }
                                  },
                                  <></>
                                );
                              }}
                              className={`${ACTION_BUTTON_CSS}`}
                            />
                          )}
                        </div>
                      </PortfolioAdminPermissionBlock>
                    </div>
                    <PortfolioAdminPermissionBlock permissions={permissions}>
                      <Button
                        className={PRIMARY_BUTTON_STYLE}
                        onClick={() => {
                          setNewVersionModal(true);
                        }}
                      >
                        Add New Version
                      </Button>
                    </PortfolioAdminPermissionBlock>
                  </div>
                </>
              </div>
            </div>
            <Table
              size={"small"}
              locale={{ emptyText: "No Data" }}
              className={"border"}
              scroll={{
                x: true,
                y: isFullScreen ? windowHeight - 250 : windowHeight / 2.2,
              }}
              bordered={true}
              pagination={false}
              columns={[
                ...getColumns(
                  capitalStructureTableFields,
                  permissions.includes(PermissionType.ADMIN_PORTFOLIO)
                ),
                ...(permissions.includes(PermissionType.ADMIN_PORTFOLIO)
                  ? [deleteCol]
                  : []),
              ]}
              rowClassName={"group"}
              rowKey={(record): string => pathOr("", [0, "id"], record)}
              dataSource={tableData}
              summary={(data) => (
                <Table.Summary fixed={true}>
                  <PortfolioAdminPermissionBlock permissions={permissions}>
                    <Table.Summary.Row className={"bg-gray-100"}>
                      <Table.Summary.Cell index={0} className={"p-0 !z-20"}>
                        <div
                          className={
                            "cursor-pointer hover:underline text-blue-400"
                          }
                        >
                          <Button
                            loading={addRowLoading}
                            disabled={addRowLoading}
                            type={"text"}
                            className={
                              "text-left pl-5 border-0 text-primary disabled:border-opacity-60 font-medium w-full hover:bg-blue-50"
                            }
                            onClick={() => {
                              if (selectedVersion?.id) {
                                addRow(selectedVersion?.id);
                              }
                            }}
                          >
                            + Add New Row
                          </Button>
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </PortfolioAdminPermissionBlock>
                </Table.Summary>
              )}
            />
          </div>
        )}
      </FullScreenWidget>

      <NewCapStructureVersionModal
        visible={newVersionModal}
        portfolioId={portfolioId}
        sectionId={sectionId}
        dates={pluck("versionDate", capitalStructureTableVersions)}
        versions={capitalStructureTableVersions}
        onSave={(version) => {
          setSelectedVersion(version);
          getCapitalStructureVersionData(version.id);
          setNewVersionModal(false);
        }}
        onCancel={() => {
          setNewVersionModal(false);
        }}
        title={"Add New Version"}
      />
      {selectedVersion && (
        <EditCapStructureVersionModal
          visible={editVersion}
          version={selectedVersion}
          dates={pluck("versionDate", capitalStructureTableVersions)}
          portfolioId={portfolioId}
          sectionId={sectionId}
          onSave={(version) => {
            const index = findIndex(
              propEq("id", version.id),
              capitalStructureTableVersions
            );
            if (index >= 0) {
              setCapitalStructureTableVersions(
                updateItemInArray(index, capitalStructureTableVersions, version)
              );
              setSelectedVersion(version);
              getCapitalStructureData(version.id);
            }
            setEditVersion(false);
          }}
          onCancel={() => {
            setEditVersion(false);
          }}
        />
      )}
    </PortfolioCard>
  );
};

type sortByRankType = (
  array: CapitalStructureTableFieldDTO[]
) => CapitalStructureTableFieldDTO[];
type getCapitalStructureDataType = (versionId?: string) => void;
type ParamsType = {
  portfolioId: string;
  sectionId: string;
};
type CapitalStructureType = {
  permissions: PermissionType[];
  portfolioId: string;
};
type FieldDataType = {
  unit?: string;
  currency?: string;
  formula?: string;
  type:
    | "number"
    | "select"
    | "textarea"
    | "text"
    | "date"
    | "formula"
    | undefined;
};
