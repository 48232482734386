import { Alert, Button, Form, Input, Spin } from "antd";
import React, { FC, useState } from "react";
import { forgotPassword } from "../../services/services";
import { LoginFormState, LoginFormType } from "../../pages/Login";
import { ErrorType } from "../../utils/uiTypes";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { useForm } from "antd/es/form/Form";

export const ResetPasswordForm: FC<LoginFormType> = ({ currentState }) => {
  return (
    <div>
      <div className={"text-left text-2xl font-medium"}>
        {currentState === LoginFormState.FORGOT && "Forgot Password?"}
        {currentState === LoginFormState.RESET && "Reset Password?"}
      </div>
      <div className={"text-left text-sm font-normal mt-2 mb-8 select-none"}>
        Please enter your email so we can help you reset your password
      </div>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "Please enter your email address",
            whitespace: false,
          },
        ]}
        validateTrigger={[]}
      >
        <Input placeholder={"Email"} autoFocus={true} />
      </Form.Item>
    </div>
  );
};
