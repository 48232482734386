import React, { FC, useState } from "react";
import {
  cloneTracker,
  getTrackersByTransactionId,
} from "../../../../services/services";
import { ResponseType, voidType } from "../../../../utils/uiTypes";
import { MileStoneType } from "../../../../utils/types";
import { CloneTaskForm } from "../../../forms/CloneTaskForm";
import { Button, Checkbox, message } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/es/checkbox/Group";

export const CloneTrackers: FC<CloneTrackersType> = function ({
  transactionId,
  milestonesTemplateId,
  onClone,
  onClose,
}) {
  const [loading, setLoading] = useState(false);
  const [trackers, setTrackers] = useState<MileStoneType[]>([]);
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [selectedTransactionId, setSelectedTransactionId] = useState<
    string | null
  >(null);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setCheckAll(checkedValues.length === trackers.length);
    setCheckedList(checkedValues);
  };

  const getCloneTrackers = (id: string): void => {
    getTrackersByTransactionId({ segments: { id } }).then(
      ({ data = [] }: ResponseType<MileStoneType[]>) => {
        setTrackers(
          data.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
        );
      }
    );
  };
  const cloneTrackerByTransaction: cloneTrackerByTransactionType = (
    transactionId,
    milestonesTemplateId
  ) => {
    setLoading(true);
    cloneTracker({
      segments: {
        transactionId,
        milestonesTemplateId,
      },
      body: JSON.stringify(checkedList),
    })
      .then(() => {
        message.success("Cloned Tasks", 0.5);
        onClone();
        onClose();
        setLoading(false);
      })
      .catch((error: string) => {
        message.error(error ? error : "Error cloning tasks..");
        onClose();
        setLoading(false);
      });
  };

  const onCheckAllChange: onCheckAllChangeType = (e, trackerList) => {
    setCheckedList(
      e.target.checked
        ? trackerList?.map((tracker) => tracker.mileStoneId) ?? []
        : []
    );
    setCheckAll(e.target.checked);
  };

  const onCancel: voidType = () => {
    setSelectedTransactionId(null);
    setCheckedList([]);
    setCheckAll(false);
    onClose();
  };

  return (
    <div>
      <CloneTaskForm
        loading={loading}
        transactionId={transactionId}
        onSubmit={({ peTransactionId }) => {
          setSelectedTransactionId(peTransactionId);
          getCloneTrackers(peTransactionId);
        }}
      />
      {trackers.length > 0 && (
        <div>
          <Checkbox
            disabled={loading}
            onChange={(e): void => onCheckAllChange(e, trackers)}
            checked={checkAll}
          >
            Select All
          </Checkbox>

          <Checkbox.Group
            disabled={loading}
            className={"w-full"}
            onChange={onChange}
            value={checkedList}
          >
            <div className={"flex flex-col gap-2"}>
              {trackers.map(({ mileStoneId, name }) => (
                <Checkbox
                  disabled={loading}
                  className={"ml-0"}
                  key={mileStoneId}
                  value={mileStoneId}
                >
                  <div>{name}</div>
                </Checkbox>
              ))}
            </div>
          </Checkbox.Group>
        </div>
      )}
      <div className={"flex flex-row flex-row-reverse gap-x-2 mt-8"}>
        <Button
          loading={loading}
          className={"bg-primary hover:bg-hover text-white border-0"}
          onClick={(): void => {
            selectedTransactionId &&
              cloneTrackerByTransaction(
                selectedTransactionId,
                milestonesTemplateId
              );
          }}
        >
          Clone Trackers
        </Button>
        <Button
          disabled={loading}
          className={"bg-white border hover:border-primary hover:text-primary"}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
type CloneTrackersType = {
  transactionId: string;
  milestonesTemplateId: string;
  onClone: voidType;
  onClose: voidType;
};
type onCheckAllChangeType = (
  e: CheckboxChangeEvent,
  trackerList: MileStoneType[]
) => void;
type cloneTrackerByTransactionType = (
  transactionId: string,
  milestonesTemplateId: string
) => void;
