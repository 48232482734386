import React, { ChangeEvent, ReactElement, useRef } from "react";
import { message } from "antd";

export const FilePicker: FilePickerComponent = function ({
  disabled = false,
  accept = "",
  multiple = false,
  onDragEnter = () => {},
  onDragLeave = () => {},
  onChange,
  children,
  max = 1,
  sizeLimit = 600000000,
}: FilePickerProperties) {
  const ref = useRef<HTMLInputElement | null>(null);

  const onDrop = (e: DragEvent) => {
    onLeave(e);
    onChange(e?.dataTransfer?.files ? Array.from(e.dataTransfer.files) : []);
  };

  const onEnter = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onDragEnter();
  };

  const onLeave = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onDragLeave();
  };

  const onClick = () => {
    ref.current?.click();
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : [];
    const sizeUnderLimit = files.reduce(
      (previousValue, currentValue) =>
        previousValue && currentValue.size <= sizeLimit,
      true
    );
    if (files.length > max) {
      message.error(`Maximum Number of Files Allowed is ${max}`);
    } else if (!sizeUnderLimit) {
      message.error(
        `Maximum single file size Allowed is ${sizeLimit * 0.000001} MB`
      );
    } else {
      onChange(files);
    }
  };

  return (
    <>
      {React.cloneElement(children, {
        className: `${children.props.className} ${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        }`,
        onClick,
        onDrop,
        onDragEnter: onEnter,
        onDragLeave: onLeave,
        onDragOver: onEnter,
      })}
      <input
        disabled={disabled}
        ref={ref}
        accept={accept}
        multiple={multiple}
        type={"file"}
        className={"hidden"}
        onChange={onInputChange}
      />
    </>
  );
};

export type FilePickerProperties = {
  disabled?: boolean;
  accept?: string;
  multiple?: boolean;
  onDragEnter?: VoidFunction;
  onDragLeave?: VoidFunction;
  onChange: (files: Array<File> | null) => void;
  children: ReactElement;
  max?: number;
  sizeLimit?: number;
};
export type FilePickerComponent = (props: FilePickerProperties) => ReactElement;
