import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { useForm } from "antd/es/form/Form";
import {
  formItemRequiredRule,
  PhoneNumber,
  phoneNumberPatternRule,
  reverseParsePhoneNumber,
} from "../../../utils/formUtils";
import { ResponseType, voidType } from "../../../utils/uiTypes";
import {
  disable2FA,
  enable2FA,
  sendPhoneVerificationCode,
} from "../../../services/services";
import { Alert, Button, Form, message, Switch } from "antd";
import { valOrDefault } from "../../../utils/utils";
import { LockOutlined } from "@ant-design/icons";
import { PhoneNumberInput } from "../../general/PhoneNumberInput";
import { isValidNumber } from "libphonenumber-js";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import { VerificationSettings } from "../../modals/VerificationSettings";

export const TwoFactorAuthSetup = () => {
  const { user, setUser } = useContext(UserContext);
  const [form] = useForm();
  const [verificationModal, setVerificationModal] =
    useState<VerificationModalType>({ isVisible: false });
  const [phoneNumberSaved, setPhoneNumberSaved] = useState<PhoneNumber>(
    reverseParsePhoneNumber(user?.sms2FaPhoneNumber ?? "")
  );

  const enable2FaSwitch: voidType = () => {
    enable2FA({
      body: JSON.stringify({}),
    })
      .then(({ data }: ResponseType) => {
        setUser((user) =>
          user
            ? {
                ...user,
                ...data,
              }
            : null
        );
        message.success("2FA enabled successfully");
      })
      .catch(() => {
        message.error("Cannot enable 2FA, please try again!");
      });
  };

  const disable2FaSwitch: voidType = () => {
    disable2FA({
      body: JSON.stringify({}),
    })
      .then(({ data }: ResponseType) => {
        setUser((user) =>
          user
            ? {
                ...user,
                ...data,
              }
            : null
        );
        message.warning("2FA disabled successfully");
      })
      .catch(() => {
        message.error("Cannot disable 2FA, please try again!");
      });
  };

  const onVerify: onVerifyType = () => {
    setUser((user) =>
      user
        ? {
            ...user,
            sms2FaPhoneNumber: `+${phoneNumberSaved?.code}${phoneNumberSaved?.phone}`,
            sms2FaPhoneVerified: true,
          }
        : null
    );
    message.success("Phone verification successful");
  };

  const sendVerificationCode: sendVerificationCodeType = (phoneNumber) => {
    sendPhoneVerificationCode({
      body: JSON.stringify({
        phoneNumber: phoneNumber,
      }),
    })
      .then(console.info)
      .catch(console.error);
  };

  const onFinish: VoidFunction = () => {
    if (user && !user?.isSsoMember) {
      sendVerificationCode(
        `+${phoneNumberSaved?.code}${phoneNumberSaved?.phone}`
      );
      setVerificationModal({
        isVisible: true,
        phoneNumber: `+${phoneNumberSaved?.code}${phoneNumberSaved?.phone}`,
      });
      setUser({
        ...user,
        sms2FaEnabled: false,
        sms2FaPhoneVerified: false,
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      phoneNumber: reverseParsePhoneNumber(
        valOrDefault("", user?.sms2FaPhoneNumber)
      ),
    });
  }, [user?.sms2FaPhoneNumber]);

  return (
    <div className={"bg-white mx-6 mb-6"}>
      <p className={"max-w-xs text-center mx-auto py-2 text-2xl font-medium"}>
        <LockOutlined className="mr-3" />
        Two factor Authentication
      </p>
      <div className={"max-w-screen-md mx-auto w-3/6 py-7"}>
        {user?.isSsoMember && (
          <Alert
            type={"error"}
            message={
              "Login Security for your account is handled through Single Sign-on"
            }
          />
        )}
        <div>
          <h1 className="text-base mt-3 font-bold">Your Phone Number</h1>
          <div className="mt-3">
            <p>
              We will only use this number for account security. To add this
              number for other contact purposes, please go to your profile.
            </p>
          </div>
          <div className="mt-3">
            <Form
              disabled={user?.isSsoMember}
              form={form}
              layout={"vertical"}
              className={"flex flex-col gap-y-3 h-full"}
              onFinish={onFinish}
            >
              <div className="flex items-start w-full flex-row gap-4 items-center">
                <Form.Item
                  className={"m-0 border-0 float-left"}
                  name={"phoneNumber"}
                  rules={[formItemRequiredRule, phoneNumberPatternRule()]}
                  initialValue={{
                    short: "GB",
                    code: "44",
                  }}
                >
                  <PhoneNumberInput
                    disabled={user?.isSsoMember}
                    placeholder={"Phone Number"}
                    onChange={(val): void => {
                      setPhoneNumberSaved(val);
                    }}
                  />
                </Form.Item>

                {user?.sms2FaPhoneVerified && (
                  <span>Phone Number Verified!</span>
                )}

                <Button
                  hidden={
                    user?.sms2FaPhoneVerified &&
                    user?.sms2FaPhoneNumber ===
                      `+${phoneNumberSaved?.code}${phoneNumberSaved?.phone}`
                  }
                  disabled={
                    user?.isSsoMember ||
                    !isValidNumber(
                      phoneNumberSaved.phone,
                      phoneNumberSaved.short
                    )
                  }
                  htmlType={"submit"}
                  className={`${PRIMARY_BUTTON_STYLE} float-right ml-auto`}
                >
                  Verify Number
                </Button>

                <VerificationSettings
                  visible={verificationModal.isVisible}
                  onClose={(): void => {
                    setVerificationModal({ isVisible: false });
                  }}
                  onVerify={onVerify}
                  phoneNumber={verificationModal.phoneNumber}
                />
              </div>
            </Form>
          </div>
        </div>
        <div>
          <h1 className="text-base mt-7 font-bold">
            Enable 2FA
            <Switch
              disabled={!user?.sms2FaPhoneVerified || user?.isSsoMember}
              className={`float-right ${
                user?.sms2FaEnabled ? "bg-success" : "bg-muted"
              }`}
              checked={!!user?.sms2FaEnabled}
              onChange={(status): void => {
                status ? enable2FaSwitch() : disable2FaSwitch();
              }}
            />
          </h1>

          <div className="mt-3">
            <p>
              After your phone number is verified you can enable 2-factor
              <br />
              authentication for extra security and receive a 6-digit
              verification code when signing in.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

type VerificationModalType = {
  isVisible: boolean;
  phoneNumber?: string;
};
type sendVerificationCodeType = (phoneNumber: string) => void;
type onVerifyType = () => void;
