import React, { FC, ReactElement, RefObject, useEffect, useRef } from "react";
import {
  ConnectDragSource,
  ConnectDropTarget,
  useDrag,
  useDrop,
  XYCoord,
} from "react-dnd";
import { SourceType } from "dnd-core";
import { equals } from "ramda";

export const DraggableElement: FC<DraggableElementType> = ({
  id,
  index,
  onDragging,
  children,
  onDrop,
  canDrag,
  accept,
  className,
}: DraggableElementType) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      onDragging(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: accept,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    previewOptions: { captureDraggingState: false },
    canDrag,
  });

  return (
    <div ref={ref}>
      <div
        ref={preview}
        className={`${
          isOver ? "!bg-blue-100 border-dashed border shadow z-50" : "bg-white"
        } ${canDrop && "!bg-blue-50 shadow z-10"} group ${className}`}
      >
        {children(ref, drag, drop)}
      </div>
    </div>
  );
};

export type DraggableElementType = {
  id: string;
  index: number;
  onDragging: (oldIndex: number, newIndex: number) => void;
  onDrop: (item: DragItem) => void;
  children: (
    ref: RefObject<HTMLDivElement>,
    drag: ConnectDragSource,
    drop: ConnectDropTarget
  ) => ReactElement;
  canDrag: boolean;
  accept: SourceType;
  className?: string;
};

type DragItem = {
  index: number;
  id: string;
  type: string;
};
