import React, { FC } from "react";
import { useHistory } from "react-router";
import {
  NotificationActivityType,
  NotificationType,
} from "../../../../utils/types";
import {
  getActivityTypeSubHeader,
  getNotificationIcon,
} from "../../../../utils/notifications";
import { isEmpty } from "ramda";

export const DashboardNotifications: FC<DashboardNotificationType> = function ({
  icon,
  notifications = [],
  title,
  limit,
  id,
}: DashboardNotificationType) {
  const history = useHistory();
  return (
    <>
      {isEmpty(notifications) ? (
        <div>
          <img className={"mx-auto h-32"} src={icon} alt="empty" />
          <p className={"text-center text-gray-400"}>
            No notifications for you yet!
          </p>
        </div>
      ) : (
        (limit ? notifications.slice(0, 4) : notifications).map(
          (activity: NotificationActivityType) => {
            return (
              <div
                key={activity.id}
                className={"relative cursor-pointer"}
                onClick={(): void =>
                  history.push(`/transactions/${id}/${title}`)
                }
              >
                {getNotificationIcon(
                  activity.activityType,
                  activity.dataMap.CREATOR_NAME,
                  activity.dataMap.DETAILS,
                  activity.dataMap.USER_NAME,
                  activity.dataMap.TITLE,
                  activity.dataMap.PROPERTY_ID,
                  activity.dataMap.LENDER_TITLE,
                  true
                )}

                <p className="m-0 ml-8 relative">{activity.message}</p>
                <span className="ml-8 text-gray-400 text-xs">
                  {getActivityTypeSubHeader(
                    activity.activityType,
                    activity.dataMap.LENDER_TITLE,
                    activity.dataMap.CREATOR_NAME,
                    activity.dataMap.COMPANY,
                    activity.createDate,
                    activity.dataMap.SECTION_NAME
                  )}
                </span>
              </div>
            );
          }
        )
      )}
    </>
  );
};

type DashboardNotificationType = {
  icon: string;
  notifications?: NotificationActivityType[];
  title: string;
  limit?: boolean;
  id: string;
};
