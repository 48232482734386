import React, {
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { voidType } from "../../utils/uiTypes";
import { any, complement, equals, isNil, or, path } from "ramda";
import { CustomSpin } from "./CustomSpin";
import useWindowDimensions from "../../customHooks/useWindowDimensions";
import { Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ACTION_BUTTON_CSS } from "../../utils/cssConfigs";

const TIMEOUT = 400;

export const FullScreenButton: FC<FullScreenButtonType> = ({
  trigger,
  isFullScreen,
}) => {
  return (
    <Button
      disabled={isNil(trigger)}
      onClick={trigger ?? (() => {})}
      className={ACTION_BUTTON_CSS}
    >
      {isFullScreen ? (
        <i className="fa-solid fa-minimize"></i>
      ) : (
        <i className="fa-solid fa-maximize"></i>
      )}
    </Button>
  );
};

export const FullScreenWidget: FC<FullScreenWidgetType> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [status, setStatus] = useState<StatusType>({
    loading: false,
  });

  const minimize: voidType = () => {
    if (document?.fullscreenElement) {
      document
        ?.exitFullscreen()
        .then(() =>
          setTimeout(
            () => setStatus(() => ({ loading: false, loadingText: "" })),
            TIMEOUT
          )
        );
    }
  };

  const maximize: maximizeType = (ref: HTMLElement | null) => {
    ref
      ?.requestFullscreen()
      .then(() =>
        setTimeout(
          () => setStatus(() => ({ loading: false, loadingText: "" })),
          TIMEOUT
        )
      );
  };

  const trigger: triggerType = (isFullScreen: boolean) => () => {
    setStatus(() => ({
      loading: true,
      loadingText: isFullScreen ? "Minimizing..." : "Maximizing...",
    }));
    setTimeout(
      () => (isFullScreen ? minimize() : maximize(ref?.current)),
      TIMEOUT
    );
  };

  const fullscreenchanged = () => {
    if (document.fullscreenElement) {
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    return minimize;
  }, []);

  useEffect(() => {
    document.addEventListener("fullscreenchange", fullscreenchanged);
    return () =>
      document.removeEventListener("fullscreenchange", fullscreenchanged);
  }, []);

  return (
    <div className={"static h-full"}>
      <div id={"full-screen"} className={`relative h-full`} ref={ref}>
        {status?.loading && (
          <div
            className={`bg-gray-100 fixed top-0 left-0 h-screen w-screen bg-opacity-100 cursor-not-allowed flex items-center justify-center z-[200]`}
          >
            <Spin
              indicator={<LoadingOutlined spin={true} />}
              tip={status?.loadingText}
              spinning={true}
              size={"large"}
            />
          </div>
        )}
        <div
          className={`h-full w-full ${status.loading && "hidden invisible"}`}
        >
          {children(
            isFullScreen,
            isNil(ref) ? null : trigger(isFullScreen, null)
          )}
        </div>
      </div>
    </div>
  );
};

type FullScreenWidgetType = {
  children: (isFullScreen: boolean, trigger: voidType | null) => ReactNode;
};
type maximizeType = (ref: HTMLElement | null) => void;
type triggerType = (
  isFullScreen: boolean,
  ref: HTMLElement | null
) => () => void;
type StatusType = { loading: boolean; loadingText?: string };

type FullScreenButtonType = {
  trigger: voidType | null;
  isFullScreen: boolean;
};
