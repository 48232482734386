import React, { FC, useState } from "react";
import { usePageTitle } from "../../customHooks/usePageTitle";
import {
  addAdminPortfolioSection,
  addPaymentDates,
  addSectionField,
  addTableField,
} from "../../services/services";
import { PortfolioSectionEnumType, SectionFieldType } from "../../utils/enums";
import {
  PortfolioSectionFieldType,
  PortfolioSectionType,
  PortfolioTableFieldType,
} from "../../utils/types";
import { Button, message } from "antd";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";

const SECTION_FIELDS = [
  PortfolioSectionEnumType.GENERAL_INFORMATION,
  PortfolioSectionEnumType.FINANCIALS,
  PortfolioSectionEnumType.FEES,
  PortfolioSectionEnumType.CUSTOM,
  PortfolioSectionEnumType.PAST_TRANSACTION,
  PortfolioSectionEnumType.COVENANT,
  PortfolioSectionEnumType.CAPITAL_STRUCTURE,
  PortfolioSectionEnumType.HEDGING,
  PortfolioSectionEnumType.ALLOCATION,
  PortfolioSectionEnumType.KEY_CONTACTS,
  PortfolioSectionEnumType.AMORTIZATION,
];
const SECTION_FIELD_LABELS: Partial<Record<PortfolioSectionEnumType, string>> =
  {
    [PortfolioSectionEnumType.GENERAL_INFORMATION]: "General Information",
    [PortfolioSectionEnumType.FINANCIALS]: "Financials",
    [PortfolioSectionEnumType.FEES]: "Fees",
    [PortfolioSectionEnumType.CUSTOM]: "Custom",
    [PortfolioSectionEnumType.PAST_TRANSACTION]: "Past Transaction",
    [PortfolioSectionEnumType.COVENANT]: "Covenant",
    [PortfolioSectionEnumType.CAPITAL_STRUCTURE]: "Capital Structure",
    [PortfolioSectionEnumType.HEDGING]: "Hedging",
    [PortfolioSectionEnumType.ALLOCATION]: "Mandated Allocation",
    [PortfolioSectionEnumType.KEY_CONTACTS]: "Key Contacts",
    [PortfolioSectionEnumType.AMORTIZATION]: "Amortization",
  };
export const PortfolioSettings: FC = () => {
  usePageTitle("Portfolio Settings");

  const [amortizationLoading, setAmortizationLoading] = useState(false);
  const [covenantLoading, setCovenantLoading] = useState(false);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [capitalStructureLoading, setCapitalStructureLoading] = useState(false);
  const [trancheLoading, setTrancheLoading] = useState(false);
  const [totalAmountLoading, setTotalAmountLoading] = useState(false);
  const [currencyLoading, setCurrencyLoading] = useState(false);
  const [ebitdaLoading, setEbitdaLoading] = useState(false);
  const [dateLoading, setDateLoading] = useState(false);

  const addNewSectionField: AddNewSectionField = (type, field) => {
    return new Promise((resolve, reject) => {
      addSectionField({
        segments: {
          sectionType: type,
        },
        body: JSON.stringify(field),
      })
        .then(() => {
          resolve(null);
        })
        .catch(reject);
    });
  };

  const addNewTableField: AddNewTableField = (type, field) => {
    return new Promise((resolve, reject) => {
      addTableField({
        segments: {
          sectionType: type,
        },
        body: JSON.stringify(field),
      })
        .then(() => {
          resolve(null);
        })
        .catch(reject);
    });
  };

  const addNewSection: AddNewSection = (type, section) => {
    return new Promise((resolve, reject) => {
      addAdminPortfolioSection({
        segments: {
          sectionType: type,
        },
        body: JSON.stringify(section),
      })
        .then(() => {
          resolve(null);
        })
        .catch(reject);
    });
  };

  const addAmortization = () => {
    setAmortizationLoading(true);
    addNewSection(PortfolioSectionEnumType.AMORTIZATION, {
      sectionName: "Amortization",
      sectionType: PortfolioSectionEnumType.AMORTIZATION,
      canDelete: false,
    })
      .then(() => {
        addNewTableField(PortfolioSectionEnumType.AMORTIZATION, {
          fieldType: "TEXT",
          label: "Tranche",
        })
          .then(() => {
            setAmortizationLoading(false);
            message.success("Amortization has been added successfully");
          })
          .catch(() => {
            setAmortizationLoading(false);
            message.error("Amortization cannot be added at this moment");
          });
      })
      .catch(() => {
        setAmortizationLoading(false);
        message.error("Amortization cannot be added at this moment");
      });
  };

  const addTransactionType = () => {
    setTransactionLoading(true);
    addNewSectionField(PortfolioSectionEnumType.GENERAL_INFORMATION, {
      fieldName: "Transaction Type",
      options: [
        {
          id: "",
          portfolioSectionFieldId: "",
          optionValue: "Bank Loan",
        },
        {
          id: "",
          portfolioSectionFieldId: "",
          optionValue: "Syndicated Loan",
        },
        {
          id: "",
          portfolioSectionFieldId: "",
          optionValue: "Private Debt",
        },
        {
          id: "",
          portfolioSectionFieldId: "",
          optionValue: "HY Bond",
        },
      ],
      required: true,
      canDelete: false,
      sectionFieldType: SectionFieldType.DROP_BOX,
    })
      .then(() => {
        setTransactionLoading(false);
        message.success("Transaction Type has been added successfully");
      })
      .catch(() => {
        setTransactionLoading(false);
        message.error("Transaction Type cannot be added at the moment!");
      });
  };

  const addCovenantType = () => {
    setCovenantLoading(true);
    addNewSectionField(PortfolioSectionEnumType.COVENANT, {
      fieldName: "Covenant Type",
      options: [
        {
          id: "",
          portfolioSectionFieldId: "",
          optionValue: "Cov-lite",
        },
        {
          id: "",
          portfolioSectionFieldId: "",
          optionValue: "One Test",
        },
        {
          id: "",
          portfolioSectionFieldId: "",
          optionValue: "2+ Tests",
        },
      ],
      required: true,
      canDelete: false,
      sectionFieldType: SectionFieldType.DROP_BOX,
    })
      .then(() => {
        setCovenantLoading(false);
        message.success("Covenant Type has been added successfully");
      })
      .catch(() => {
        setCovenantLoading(false);
        message.error("Covenant Type cannot be added at the moment!");
      });
  };

  const addTotalAmountFormula = () => {
    setTotalAmountLoading(true);
    addNewTableField(PortfolioSectionEnumType.CAPITAL_STRUCTURE, {
      label: "Total Amount",
      fieldType: "MONETARY",
      formula:
        '{\\n   \\"variables\\":[\\n      \\"amount\\",\\n      \\"availableamount\\"\\n   ],\\n   \\"expression\\":\\"(amount+availableamount)\\"\\n}',
    })
      .then(() => {
        setTotalAmountLoading(false);
        message.success("Total Amount has been updated successfully");
      })
      .catch(() => {
        setTotalAmountLoading(false);
        message.error("Total Amount cannot be updated at this moment");
      });
  };

  const addTrancheType = () => {
    setTrancheLoading(true);
    addNewTableField(PortfolioSectionEnumType.CAPITAL_STRUCTURE, {
      fieldType: "DROP_BOX",
      label: "Tranche Type",
      options: JSON.stringify([
        { key: "BANK", value: "Bank" },
        { key: "SYNDICATED", value: "Syndicated" },
        { key: "PRIVATE", value: "Private" },
        { key: "HY BOND", value: "HY Bond" },
      ]),
    })
      .then(() => {
        setTrancheLoading(false);
        message.success("Tranche type has been updated successfully");
      })
      .catch(() => {
        setTrancheLoading(false);
        message.error("Tranche type cannot be updated at this moment");
      });
  };

  const addCurrency = () => {
    setCurrencyLoading(true);
    addNewTableField(PortfolioSectionEnumType.CAPITAL_STRUCTURE, {
      fieldType: "CURRENCY",
      label: "CurrencyCapitalStructure",
    })
      .then(() => {
        setCurrencyLoading(false);
        message.success("Currency has been updated successfully");
      })
      .catch(() => {
        setCurrencyLoading(false);
        message.error("Currency cannot be updated at this moment");
      });
  };

  const addEbitda = () => {
    setEbitdaLoading(true);
    addNewTableField(PortfolioSectionEnumType.CAPITAL_STRUCTURE, {
      fieldType: "NUMERIC",
      label: "xLTM Adj. EBITDA",
      unit: "x",
    })
      .then(() => {
        setEbitdaLoading(false);
        message.success("Ebitda has been updated successfully");
      })
      .catch(() => {
        setEbitdaLoading(false);
        message.error("Ebitda cannot be updated at this moment");
      });
  };

  const addDate = () => {
    setDateLoading(true);
    addNewTableField(PortfolioSectionEnumType.CAPITAL_STRUCTURE, {
      fieldType: "DATE",
      label: "DDTL Expiry Date",
    })
      .then(() => {
        setDateLoading(false);
        message.success("Date has been updated successfully");
      })
      .catch(() => {
        setDateLoading(false);
        message.error("Date cannot be updated at this moment");
      });
  };

  const addPaymentDatesFn = () => {
    return new Promise((resolve, reject) => {
      addPaymentDates({})
        .then(() => {
          resolve(null);
        })
        .catch(reject);
    });
  };

  return (
    <div className="p-6">
      <div className={"bg-white border p-6"}>
        <div className={"flex flex-col gap-4 w-fit"}>
          {/*<Button*/}
          {/*  type={"primary"}*/}
          {/*  className={PRIMARY_BUTTON_STYLE}*/}
          {/*  htmlType={"submit"}*/}
          {/*  onClick={addAmortization}*/}
          {/*  loading={amortizationLoading}*/}
          {/*  disabled={*/}
          {/*    amortizationLoading || covenantLoading || transactionLoading*/}
          {/*  }*/}
          {/*>*/}
          {/*  Add Amortization*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  type={"primary"}*/}
          {/*  className={PRIMARY_BUTTON_STYLE}*/}
          {/*  htmlType={"submit"}*/}
          {/*  onClick={addTransactionType}*/}
          {/*  loading={transactionLoading}*/}
          {/*>*/}
          {/*  Add Transaction Type*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  type={"primary"}*/}
          {/*  className={PRIMARY_BUTTON_STYLE}*/}
          {/*  htmlType={"submit"}*/}
          {/*  onClick={addCovenantType}*/}
          {/*  loading={covenantLoading}*/}
          {/*>*/}
          {/*  Add Covenant Type*/}
          {/*</Button>*/}
          <Button
            type={"primary"}
            className={PRIMARY_BUTTON_STYLE}
            htmlType={"submit"}
            onClick={addTotalAmountFormula}
            loading={totalAmountLoading}
          >
            Add Total Amount Field
          </Button>
          <Button
            type={"primary"}
            className={PRIMARY_BUTTON_STYLE}
            htmlType={"submit"}
            onClick={addTrancheType}
            loading={trancheLoading}
          >
            Add Tranche Type
          </Button>
          {/* <Button
            type={"primary"}
            className={PRIMARY_BUTTON_STYLE}
            htmlType={"submit"}
            onClick={addCurrency}
            loading={currencyLoading}
          >
            Add Currency
          </Button>*/}
          <Button
            type={"primary"}
            className={PRIMARY_BUTTON_STYLE}
            htmlType={"submit"}
            onClick={addEbitda}
            loading={ebitdaLoading}
          >
            Add xLTM Ebitda
          </Button>
          <Button
            type={"primary"}
            className={PRIMARY_BUTTON_STYLE}
            htmlType={"submit"}
            onClick={addDate}
            loading={dateLoading}
          >
            Add DDT Expiry Date
          </Button>
          {/*<Button*/}
          {/*  type={"primary"}*/}
          {/*  className={PRIMARY_BUTTON_STYLE}*/}
          {/*  htmlType={"submit"}*/}
          {/*  onClick={addPaymentDatesFn}*/}
          {/*  loading={capitalStructureLoading}*/}
          {/*>*/}
          {/*  Trigger Adding Payment Dates*/}
          {/*</Button>*/}
        </div>
      </div>
    </div>
  );
};

type AddNewTableField = (
  type: PortfolioSectionEnumType,
  field: Partial<PortfolioTableFieldType>
) => Promise<null>;
type AddNewSectionField = (
  type: PortfolioSectionEnumType,
  field: Partial<PortfolioSectionFieldType>
) => Promise<null>;
type AddNewSection = (
  type: PortfolioSectionEnumType,
  section: Partial<PortfolioSectionType>
) => Promise<null>;
