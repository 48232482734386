import React, { FC, useEffect, useState } from "react";
import { Form, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { TransactionType } from "../../utils/types";
import { getTransactionsForCalendar } from "../../services/services";
import { ResponseType } from "../../utils/uiTypes";
import { filterOption } from "../../utils/utils";
import { formItemRequiredRule } from "../../utils/formUtils";
import { getPopupContainerForSelect } from "../../utils/container";
import { and, equals } from "ramda";
import { PETransactionCreationType } from "../../utils/enums";

export const CloneTaskForm: FC<CloneTaskFormType> = ({
  onSubmit,
  transactionId,
  loading = false,
  disabled = false,
}: CloneTaskFormType) => {
  const [form] = useForm();

  const [transactions, setTransactions] = useState<TransactionType[]>([]);

  useEffect(() => {
    getTransactionsForCalendar({}).then(
      ({ data = [] }: ResponseType<TransactionType[]>) => {
        setTransactions(data);
      }
    );
  }, []);

  return (
    <Form
      form={form}
      className={"flex flex-row items-center gap-x-2"}
      layout={"horizontal"}
      onFinish={onSubmit}
    >
      <Form.Item
        name={"peTransactionId"}
        style={{ width: "100%" }}
        className={"m-0"}
        rules={[formItemRequiredRule]}
      >
        <Select
          disabled={loading || disabled}
          notFoundContent={"No Transactions Available"}
          getPopupContainer={getPopupContainerForSelect}
          placeholder={"Transaction Name"}
          showSearch={true}
          filterOption={filterOption}
          autoFocus={true}
          onSelect={(): void => {
            form.submit();
          }}
        >
          {transactions
            ?.filter(({ peTransactionId, peTransactionCreationType }) =>
              and(
                !equals(peTransactionId, transactionId),
                !equals(
                  peTransactionCreationType,
                  PETransactionCreationType.OFFLINE
                )
              )
            )
            .map(({ peTransactionId, peTransactionTitle }) => (
              <Select.Option value={peTransactionId} key={peTransactionId}>
                {peTransactionTitle}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

type CloneTaskFormType = {
  loading?: boolean;
  transactionId: string;
  onSubmit: (o: { peTransactionId: string }) => void;
  onClear?: () => void;
  disabled?: boolean;
};
