import React, { FC, useEffect, useState } from "react";
import { LoginFormState, LoginFormType } from "../../pages/Login";
import { Alert, Button, Form, Input, Spin } from "antd";
import { ErrorType } from "../../utils/uiTypes";
import { useLocation } from "react-router-dom";
import { isNil } from "ramda";
import { useForm } from "antd/es/form/Form";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";

export const SsoForm: FC<LoginFormType> = () => {
  const { search } = useLocation();

  const [loading, setLoading] = useState(false);

  return (
    <div>
      <div className={"text-center text-2xl font-medium select-none"}>
        Welcome Back
      </div>
      <div className={"text-center text-sm font-normal mt-2 mb-8 select-none"}>
        Sign in with your TermGrid/Work email
      </div>

      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "Please enter your email address",
            whitespace: false,
          },
        ]}
        validateTrigger={[]}
      >
        <Input placeholder={"Email"} disabled={loading} autoFocus={true} />
      </Form.Item>
    </div>
  );
};
