import React, { FC, ReactNode, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Input,
  Menu,
  Select,
  Space,
  Tooltip,
  MenuProps,
} from "antd";
import {
  CaretDownOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { ColorPicker } from "../../general/ColorPicker";
import { KeyValueType } from "../../../utils/types";
import { isEmpty, isNil } from "ramda";
import { ActionType } from "../../../utils/newTermsheet";

const BUTTON_CSS =
  "w-8 text-gray-700 bg-transparent hover:bg-gray-200 border-none inline-flex justify-center items-center disabled:opacity-60 disabled:bg-transparent";

const FONT_SIZES = [
  {
    value: "13px",
    label: 13,
  },
  {
    value: "14px",
    label: 14,
  },
  {
    value: "15px",
    label: 15,
  },
  {
    value: "16px",
    label: 16,
  },
  {
    value: "17px",
    label: 17,
  },
  {
    value: "18px",
    label: 18,
  },
];

const INDENTATION = 20;
const adjustIndentation: adjustIndentationType = (
  currentPadding,
  direction
) => {
  const currentPaddingValue = parseInt(
    currentPadding.replace("px", "").trim(),
    10
  );
  if (direction === IndentDirection.LEFT) {
    return `${currentPaddingValue + INDENTATION}px`;
  } else {
    return `${Math.max(currentPaddingValue - INDENTATION, 0)}px`;
  }
};

export const ToolBar: FC<ToolBarType> = function ({
  name = "",
  editName = true,
  selectedStyle = {},
  showTemplatesButton = false,
  showSwitchTermsheetButton = false,
  showUploadButton = false,
  downloadItems,
  showCompareButton = false,
  showStyleChangeButtons = false,
  downloading = false,
  disableActions = false,
  onStyleChange,
  onNameChange,
  onButtonClick,
  children,
}: ToolBarType) {
  const [updatedName, setUpdatedName] = useState("");

  const onNameUpdate = () => {
    if (name !== updatedName) {
      onNameChange(updatedName);
    }
  };
  useEffect(() => {
    setUpdatedName(name);
  }, [name]);
  //todo: fix css make aligned
  return (
    <div className={`border-b p-2 bg-gray-50 shadow-sm`}>
      <div className={"flex justify-between items-center"}>
        <div className={"inline-flex items-center"}>
          <div className={"inline-block px-2.5"}>
            <div className={"group"}>
              {editName ? (
                <Input
                  suffix={
                    <EditOutlined
                      className={
                        "inline-block text-secondary opacity-0 group-hover:opacity-100"
                      }
                    />
                  }
                  className={"h-8 text-xs"}
                  value={updatedName}
                  onChange={(e) => {
                    setUpdatedName(e.target.value);
                  }}
                  onMouseLeave={onNameUpdate}
                  onBlur={onNameUpdate}
                />
              ) : (
                <Tooltip title={name} placement={"bottom"}>
                  <div
                    className={
                      "h-8 text-dark border whitespace-nowrap overflow-hidden w-[194px] px-[11px] py-[4px]"
                    }
                  >
                    <div className={"inline-block w-full"}>{name}</div>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          {(showSwitchTermsheetButton || showTemplatesButton) && (
            <>
              <Divider className={"bg-muted mx-2"} type={"vertical"} />
              <div className={"inline-block"}>
                <Space className={"gap-0"}>
                  {showSwitchTermsheetButton && (
                    <Tooltip placement="bottom" title={"Switch Termsheet"}>
                      <Button
                        disabled={disableActions}
                        type={"text"}
                        onClick={() => {
                          onButtonClick("TERMSHEETS");
                        }}
                        className={`${BUTTON_CSS}`}
                      >
                        <i className="fa fa-bars"></i>
                      </Button>
                    </Tooltip>
                  )}
                  {showTemplatesButton && (
                    <Tooltip
                      placement="bottom"
                      title={"Open a different template"}
                    >
                      <Button
                        disabled={disableActions}
                        type={"text"}
                        onClick={() => {
                          onButtonClick("TEMPLATES");
                        }}
                        className={`${BUTTON_CSS}`}
                      >
                        <i className="fa fa-folder-open"></i>
                      </Button>
                    </Tooltip>
                  )}
                </Space>
              </div>
            </>
          )}
          {showStyleChangeButtons && (
            <>
              <Divider className={"bg-muted mx-2"} type={"vertical"} />
              <div className={"inline-block"}>
                <Space className={"gap-0"}>
                  <Tooltip placement="bottom" title={"Undo"}>
                    <div>
                      <Button
                        disabled={disableActions}
                        type={"text"}
                        className={`${BUTTON_CSS}`}
                        onClick={() => {
                          onButtonClick("UNDO");
                        }}
                      >
                        <i className="fa fa-undo fa-xs"></i>
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip placement="bottom" title={"Redo"}>
                    <div>
                      <Button
                        disabled={disableActions}
                        type={"text"}
                        className={`${BUTTON_CSS}`}
                        onClick={() => {
                          onButtonClick("REDO");
                        }}
                      >
                        <i className="fa fa-redo fa-xs"></i>
                      </Button>
                    </div>
                  </Tooltip>
                </Space>
              </div>
              <Divider className={"bg-muted mx-2"} type={"vertical"} />
              <Select
                disabled={disableActions}
                bordered={false}
                suffixIcon={
                  <CaretDownOutlined className={"pointer-events-none"} />
                }
                onSelect={(fontSize: string) => {
                  onStyleChange({ ...selectedStyle, fontSize });
                }}
                className={"py-1 w-16 text-xs hover:bg-gray-200"}
                size={"small"}
                defaultValue={FONT_SIZES[1].value}
                value={
                  !isEmpty(selectedStyle?.fontSize) &&
                  !isNil(selectedStyle?.fontSize)
                    ? selectedStyle.fontSize
                    : FONT_SIZES[1].value
                }
              >
                {FONT_SIZES.map(({ value, label }) => (
                  <Select.Option
                    key={value}
                    value={value}
                    className={"text-center"}
                  >
                    {label}
                  </Select.Option>
                ))}
              </Select>
              <Divider className={"bg-muted mx-2"} type={"vertical"} />
              <div className={"inline-block"}>
                <Space className={"gap-1"}>
                  <Tooltip placement="bottom" title={"Bold"}>
                    <div
                      className={`${
                        selectedStyle.fontWeight === "bold" &&
                        "bg-light hover:bg-light rounded-sm"
                      }`}
                    >
                      <Button
                        disabled={disableActions}
                        type={"text"}
                        className={`${BUTTON_CSS}`}
                        onClick={() => {
                          onStyleChange({
                            ...selectedStyle,
                            fontWeight:
                              selectedStyle.fontWeight === "bold"
                                ? "normal"
                                : "bold",
                          });
                        }}
                      >
                        <div className={"font-bold"}>B</div>
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip placement="bottom" title={"Italic"}>
                    <div
                      className={`${
                        selectedStyle.fontStyle === "italic" &&
                        "bg-light hover:bg-light rounded-sm"
                      }`}
                    >
                      <Button
                        disabled={disableActions}
                        type={"text"}
                        className={`${BUTTON_CSS}`}
                        onClick={() => {
                          onStyleChange({
                            ...selectedStyle,
                            fontStyle:
                              selectedStyle.fontStyle === "italic"
                                ? "normal"
                                : "italic",
                          });
                        }}
                      >
                        <i className="fa fa-italic"></i>
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip placement="bottom" title={"Underline"}>
                    <div
                      className={`${
                        selectedStyle.textDecoration === "underline" &&
                        "bg-light hover:bg-light rounded-sm"
                      }`}
                    >
                      <Button
                        disabled={disableActions}
                        type={"text"}
                        className={`${BUTTON_CSS}`}
                        onClick={() => {
                          onStyleChange({
                            ...selectedStyle,
                            textDecoration:
                              selectedStyle.textDecoration === "underline"
                                ? "none"
                                : "underline",
                          });
                        }}
                      >
                        <div className={"underline"}>U</div>
                      </Button>
                    </div>
                  </Tooltip>
                </Space>
              </div>
              <Divider className={"bg-muted mx-2"} type={"vertical"} />
              <div className={"inline-block"}>
                <Space className={"gap-0"}>
                  <Tooltip placement="bottom" title={"Indent less"}>
                    <div>
                      <Button
                        disabled={disableActions}
                        type={"text"}
                        className={`${BUTTON_CSS}`}
                        onClick={() => {
                          onStyleChange({
                            ...selectedStyle,
                            paddingLeft: adjustIndentation(
                              selectedStyle?.paddingLeft ?? "0",
                              IndentDirection.RIGHT
                            ),
                          });
                        }}
                      >
                        <i className="fas fa-indent rotate-180"></i>
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip placement="bottom" title={"Indent more"}>
                    <div>
                      <Button
                        disabled={disableActions}
                        type={"text"}
                        className={`${BUTTON_CSS}`}
                        onClick={() => {
                          onStyleChange({
                            ...selectedStyle,
                            paddingLeft: adjustIndentation(
                              selectedStyle?.paddingLeft ?? "0",
                              IndentDirection.LEFT
                            ),
                          });
                        }}
                      >
                        <i className="fas fa-indent"></i>
                      </Button>
                    </div>
                  </Tooltip>
                </Space>
              </div>
              <Divider className={"bg-muted mx-2"} type={"vertical"} />
              <div className={"inline-block"}>
                <Space className={"gap-0"}>
                  <ColorPicker
                    disabled={disableActions}
                    previewClassName="mx-auto w-6 h-2 border border-gray-200"
                    buttonClassName={`w-8 border-none bg-transparent p-0 ${
                      disableActions ? "" : "text-gray-700 hover:bg-gray-200"
                    }`}
                    onSelect={(color) => {
                      onStyleChange({ ...selectedStyle, color });
                    }}
                    selected={
                      !isEmpty(selectedStyle.color) &&
                      !isNil(selectedStyle.color)
                        ? selectedStyle.color
                        : "#000000"
                    }
                  >
                    <div>A</div>
                  </ColorPicker>
                  <ColorPicker
                    disabled={disableActions}
                    previewClassName="mx-auto w-6 h-2 border border-gray-200"
                    buttonClassName={`w-8 border-none bg-transparent p-0 ${
                      disableActions ? "" : "text-gray-700 hover:bg-gray-200"
                    }`}
                    onSelect={(color) => {
                      onStyleChange({ ...selectedStyle, background: color });
                    }}
                    selected={
                      !isEmpty(selectedStyle.background) &&
                      !isNil(selectedStyle.background)
                        ? selectedStyle.background
                        : "#ffffff"
                    }
                  >
                    <i className="fa fa-fill fa-sm"></i>
                  </ColorPicker>
                </Space>
              </div>
            </>
          )}
          {showCompareButton && (
            <>
              <Divider className={"bg-muted mx-2"} type={"vertical"} />
              <div className={"inline-block"}>
                <Space className={"gap-0"}>
                  <Tooltip placement="bottom" title={"Compare"}>
                    <Button
                      disabled={disableActions}
                      type={"text"}
                      className={`group ${BUTTON_CSS}`}
                      onClick={() => {
                        onButtonClick("COMPARE");
                      }}
                    >
                      <div
                        className={
                          "inline-block divide-x divide-dashed divide-gray-700 group-hover:divide-primary"
                        }
                      >
                        <span
                          className={"pr-1 text-xxs group-hover:text-primary"}
                        >
                          A
                        </span>
                        <span
                          className={"pl-1 text-xxs group-hover:text-primary"}
                        >
                          B
                        </span>
                      </div>
                    </Button>
                  </Tooltip>
                </Space>
              </div>
            </>
          )}

          {(showUploadButton || downloadItems) && (
            <>
              <Divider className={"bg-muted mx-2"} type={"vertical"} />
              <div className={"inline-block"}>
                <Space className={"gap-0"}>
                  {showUploadButton && (
                    <Tooltip placement="bottom" title={"Upload File"}>
                      <Button
                        disabled={disableActions}
                        type={"text"}
                        className={`${BUTTON_CSS}`}
                        onClick={() => {
                          onButtonClick("UPLOAD");
                        }}
                      >
                        <i className="fa fa-upload"></i>
                      </Button>
                    </Tooltip>
                  )}

                  {downloadItems && (
                    <Dropdown
                      disabled={downloading}
                      className={`${BUTTON_CSS} cursor-pointer h-8 ${
                        downloading && "bg-gray-200"
                      }`}
                      overlay={
                        <Menu
                          onClick={(e: any) => {
                            onButtonClick(e.key);
                          }}
                          mode="vertical"
                        >
                          {downloadItems
                            .filter((item) => item.show)
                            .map(({ key, label, subItems }) =>
                              subItems ? (
                                <Menu.SubMenu key={key} title={label}>
                                  {subItems.map(
                                    ({ label: subLabel, key: subKey }) => (
                                      <Menu.Item key={subKey}>
                                        {subLabel}
                                      </Menu.Item>
                                    )
                                  )}
                                </Menu.SubMenu>
                              ) : (
                                <Menu.Item key={key}>{label}</Menu.Item>
                              )
                            )}
                        </Menu>
                      }
                    >
                      <span>
                        {downloading ? (
                          <LoadingOutlined />
                        ) : (
                          <i className="fa fa-download"></i>
                        )}
                      </span>
                    </Dropdown>
                  )}
                </Space>
              </div>
            </>
          )}
        </div>
        <div className={"px-2.5"}>{children}</div>
      </div>
    </div>
  );
};

type SubMenuItem = {
  label: string;
  key: string;
};

type DownloadItem = {
  label: string;
  key: string;
  show: boolean;
  subItems?: SubMenuItem[];
};
enum IndentDirection {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}
type adjustIndentationType = (
  currentPadding: string,
  direction: IndentDirection
) => string;

type ToolBarType = {
  name: string | undefined;
  editName?: boolean;
  selectedStyle?: KeyValueType;
  showTemplatesButton?: boolean;
  showSwitchTermsheetButton?: boolean;
  showUploadButton?: boolean;
  downloadItems?: DownloadItem[];
  showCompareButton?: boolean;
  showStyleChangeButtons?: boolean;
  downloading?: boolean;
  disableActions?: boolean;
  onStyleChange: (o: KeyValueType) => void;
  onNameChange: (o: string) => void;
  onButtonClick: (o: ActionType) => void;
  children?: ReactNode;
};
