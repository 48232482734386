import React, { FC } from "react";
import { UNIT_KEY_LABEL } from "../../../../utils/portfolio";
import { Button, Dropdown, Menu } from "antd";
import { find, isNil, path, propEq } from "ramda";
import { DownOutlined } from "@ant-design/icons";
import { getPopupContainerForSelect } from "../../../../utils/container";

export const UnitSelector: FC<UnitSelectorType> = ({
  value,
  onSelect,
}: UnitSelectorType) => {
  return (
    <Dropdown
      getPopupContainer={getPopupContainerForSelect}
      trigger={["click"]}
      overlay={
        <Menu
          onClick={(e) => {
            onSelect(e.key);
          }}
          items={UNIT_KEY_LABEL}
        />
      }
    >
      <Button>
        {isNil(value)
          ? "Unit"
          : path(["label"], find(propEq("key", value))(UNIT_KEY_LABEL) ?? [])}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

type UnitSelectorType = {
  value: string;
  onSelect: (unit: string) => void;
};
