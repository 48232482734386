export const DEFAULT_SIZES: defaultSizeType = {
  h: 7,
  v: 40,
  minW: 150,
};

type defaultSizeType = {
  h: number;
  minW: number;
  v: number;
};
