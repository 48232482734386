import { valOrDefault } from "../utils/utils";
import { DependencyList, useEffect, useState } from "react";
import { voidType } from "../utils/uiTypes";

export const useIntersectionView: useIntersectionViewType = function (
  className = "parent",
  target = document,
  dependencies = []
) {
  const [rowIndex, setRowIndex] = useState<[number, number]>([0, 0]);

  const observeChildren = function (entries: any[]): void {
    let minIndex = 0;
    let maxIndex = 0;
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const currentIndex = valOrDefault(
          0,
          Number(entry.target.dataset.rowindex)
        );
        if (currentIndex > rowIndex[1]) maxIndex = currentIndex;
        else if (currentIndex < rowIndex[0]) minIndex = currentIndex;
      }
    });
    setRowIndex(([min, max]) => [
      min < minIndex ? min : minIndex,
      max > maxIndex ? max : maxIndex,
    ]);
  };

  const reset: voidType = () => {
    setRowIndex([0, 0]);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observeChildren);
    const targets = target?.querySelectorAll(className);
    targets?.forEach(function (target) {
      observer.observe(target);
    });
    return (): void => {
      observer.disconnect();
    };
  }, dependencies);

  return [...rowIndex, reset];
};

type useIntersectionViewType = (
  className?: string,
  target?: Document | HTMLElement,
  dependencies?: DependencyList
) => [number, number, voidType];
