import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { message, Steps } from "antd";
import {
  BankOutlined,
  ShopOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { NewTransactionForm } from "../../forms/NewTransactionForm";
import { TransactionType } from "../../../utils/types";
import {
  createNewTransaction,
  editTransaction,
} from "../../../services/services";
import { NewTransactionTeam } from "./NewTransactionTeam";
import { NewTransactionLender } from "./NewTransactionLenders";
import { NewTransactionMilestones } from "./NewTransactionMilestones";
import { useHistory, useParams } from "react-router";
import { UserContext } from "../../../context/UserContext";
import { ResponseType, voidType } from "../../../utils/uiTypes";
import { usePageTitle } from "../../../customHooks/usePageTitle";
import { NewOrEditTransactionValueType } from "../../../pages/CreateTransaction";
import { TransactionContext } from "../../../context/TransactionContext";
import { PETransactionCreationType } from "../../../utils/enums";
import { defaultTo } from "ramda";
import { isOffline, isOfflineLender } from "../../../utils/transaction";

export const NewTransaction: FC = function () {
  usePageTitle("Create Transaction");

  const { currentTransactionId } = useParams<ParamsType>();

  const { transaction, setTransaction, transactionId, setTransactionId } =
    useContext(TransactionContext);
  const { user } = useContext(UserContext);

  const history = useHistory();

  const [current, setCurrent] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const nextStep: voidType = () => setCurrent((prevCurrent) => prevCurrent + 1);
  const backStep: voidType = () => setCurrent((prevCurrent) => prevCurrent - 1);

  const gotoDashboard = (
    transactionId: string,
    offline: boolean
  ): ReactNode => {
    history.push(
      `/transactions/${transactionId}/${
        isOfflineLender(transaction) ? "termsheet" : "dashboard"
      }`
    );
    return <></>;
  };

  const renderStep: renderStepType = (
    current,
    companyId,
    transaction,
    transactionId,
    onNext,
    onBack
  ) => {
    switch (current) {
      case 0:
        return (
          <NewTransactionForm
            transaction={transaction}
            transactionId={transactionId}
            onNext={createTransaction}
            companyId={companyId}
            loading={loading}
            isOffline={
              isOffline(transaction as TransactionType) ||
              new URLSearchParams(location.search).get("isOffline") === "true"
            }
          />
        );
      case 1:
        if (isOfflineLender(transaction as TransactionType)) {
          setCurrent(4);
          return <></>;
        } else
          return (
            <NewTransactionTeam
              transactionId={transactionId}
              onNext={onNext}
              onBack={onBack}
              companyId={companyId}
            />
          );
      case 2:
        if (isOfflineLender(transaction as TransactionType)) {
          setCurrent(4);
          return <></>;
        } else
          return (
            <NewTransactionLender
              transactionId={transactionId}
              onNext={onNext}
              onBack={backStep}
              transaction={transaction}
              companyId={companyId}
            />
          );
      case 3:
        if (isOfflineLender(transaction as TransactionType)) {
          setCurrent(4);
          return <></>;
        } else
          return (
            <NewTransactionMilestones
              transactionId={transactionId}
              onNext={(): void => {
                gotoDashboard(
                  defaultTo("", transactionId),
                  transaction?.peTransactionCreationType ===
                    PETransactionCreationType.OFFLINE
                );
              }}
              onBack={onBack}
              companyId={companyId}
            />
          );
      default:
        return gotoDashboard(
          defaultTo("", transactionId),
          isOfflineLender(transaction as TransactionType)
        );
    }
  };

  const initiateCreateTransaction: transactionParamsType = (data) => {
    setLoading(true);
    createNewTransaction({
      body: JSON.stringify(data),
    })
      .then(({ data }: ResponseType<TransactionType>) => {
        setTransactionId(data.peTransactionId);
        setLoading(false);
        history.replace(`/transactions/create/${data.peTransactionId}`);
        nextStep();
      })
      .catch((error: string) => {
        message.error(error ? error : "Error creating new Transaction!");
        setLoading(false);
      });
  };
  const initiateEditTransaction: transactionParamsType = (
    data,
    transaction
  ) => {
    setLoading(true);
    editTransaction({
      body: JSON.stringify({ ...transaction, ...data }),
    })
      .then(({ data }: ResponseType<TransactionType>) => {
        setTransaction(data);
        setLoading(false);
        nextStep();
      })
      .catch((error: string) => {
        message.error(
          error ? error : "Error making changes to the Transaction!"
        );
        setLoading(false);
      });
  };
  const createTransaction: transactionParamsType = (data, transaction) => {
    if (user) {
      if (transaction !== null) {
        initiateEditTransaction(data, transaction);
      } else {
        initiateCreateTransaction(data, transaction);
      }
    }
  };

  useEffect(() => {
    if (currentTransactionId) {
      setTransactionId(currentTransactionId);
    }
  }, [currentTransactionId]);

  return (
    <div className={"w-full h-screen flex flex-col"}>
      <Steps
        size={"small"}
        current={current}
        type={"navigation"}
        className={"bg-secondary-background z-50"}
      >
        <Steps.Step
          className={`${transaction && "cursor-pointer"}`}
          icon={
            <span className={"flex flex-col"}>
              <BankOutlined style={{ fontSize: "20px" }} />
              <span className={"font-medium"} style={{ fontSize: "12px" }}>
                {transaction ? "Edit Transaction" : "Add Transaction"}
              </span>
            </span>
          }
          key={1}
          onClick={(): void => {
            transaction && setCurrent(0);
          }}
        />
        <Steps.Step
          className={`${transaction && "cursor-pointer"}`}
          icon={
            <span className={"flex flex-col"}>
              <TeamOutlined style={{ fontSize: "20px" }} />
              <span className={"font-medium"} style={{ fontSize: "12px" }}>
                Add Your Team
              </span>
            </span>
          }
          key={2}
          onClick={(): void => {
            transaction && setCurrent(1);
          }}
        />
        <Steps.Step
          className={`${transaction && "cursor-pointer"}`}
          disabled={!transaction || isOfflineLender(transaction)}
          icon={
            <span className={"flex flex-col"}>
              <ShopOutlined style={{ fontSize: "20px" }} />
              <span className={"font-medium"} style={{ fontSize: "12px" }}>
                Add Institutions
              </span>
            </span>
          }
          key={3}
          onClick={(): void => {
            transaction && setCurrent(2);
          }}
        />
        <Steps.Step
          className={`${transaction && "cursor-pointer"}`}
          icon={
            <span className={"flex flex-col"}>
              <UnorderedListOutlined style={{ fontSize: "20px" }} />
              <span className={"font-medium"} style={{ fontSize: "12px" }}>
                Add Trackers
              </span>
            </span>
          }
          key={4}
          onClick={(): void => {
            transaction && setCurrent(3);
          }}
        />
      </Steps>
      {(new URLSearchParams(location.search).get("isOffline") === "true" ||
        isOffline(transaction)) && (
        <div
          className={
            "flex items-center w-full text-xxs p-1 tracking-widest text-white font-bold text-white cursor-default select-none bg-amber-500 justify-center"
          }
        >
          <div>OFFLINE TRANSACTION</div>
        </div>
      )}
      <div className={"bg-white h-full m-2 overflow-y-auto"}>
        {user &&
          user.companyId &&
          renderStep(
            current,
            user.companyId,
            transaction,
            transactionId,
            nextStep,
            backStep
          )}
      </div>
    </div>
  );
};

type transactionParamsType = (
  data: NewOrEditTransactionValueType,
  transaction: TransactionType | null
) => void;
type renderStepType = (
  current: number,
  companyId: string,
  transaction: NewOrEditTransactionValueType | null,
  transactionId: string | null,
  onNext: (o?: any) => void,
  onBack: () => void
) => ReactNode;

export type NewTransactionPaginationType = {
  onNext: (data?: any, transaction?: any) => void; // TODO: Resolve any type
  onBack?: () => void;
  onClose?: () => void;
  transaction?: NewOrEditTransactionValueType | null;
  companyId: string;
  transactionId: string | null;
  loading?: boolean;
  isOffline?: boolean;
};
type ParamsType = { currentTransactionId: string | undefined };
