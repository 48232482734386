import React, { FC } from "react";
import { NewTransaction } from "../components/transaction/newTransaction/NewTransaction";
import { TransactionProvider } from "../context/TransactionContext";
import { usePageTitle } from "../customHooks/usePageTitle";
import { IndustryType } from "../utils/types";
import {
  PETransactionCreationType,
  PETransactionCreatorType,
} from "../utils/enums";

export const CreateTransaction: FC = () => {
  usePageTitle("Create Transaction");

  return (
    <TransactionProvider>
      <NewTransaction />
    </TransactionProvider>
  );
};

export type NewOrEditTransactionValueType = {
  companyId: string;
  companyToBuy: string;
  description: string;
  industryDTOSet: Array<IndustryType>;
  peTransactionTitle: string;
  targetDate: string | null;
  peTransactionTypeId: string;
  peTransactionTypeName: string;
  investmentTypeId: string;
  investmentTypeName: string;
  peTransactionCreatorType: PETransactionCreatorType;
  peTransactionCreationType: PETransactionCreationType;
  dealTeamCompanyId: string;
  creationDate: string;
  thirdPartyId: string;
  sponsorId?: string;
};
