import React, { FC, Fragment, useState } from "react";
import { Button, Checkbox, message, Modal, Tooltip } from "antd";
import { NoteDataType, NotesType } from "../../../../utils/types";
import TextArea from "antd/es/input/TextArea";
import { valOrDefault } from "../../../../utils/utils";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { flatten } from "ramda";
import {
  printNotes,
  downloadNotesAsExcel,
} from "../../../../services/services";
import { FileResponseType } from "../../../../utils/uiTypes";
import { ConfirmDownload } from "../../../../utils/confirmationModals";
import { ACTION_BUTTON_CSS } from "../../../../utils/cssConfigs";

export const PrintNotes: FC<PrintNotesType> = ({
  notes,
  transactionId,
}: PrintNotesType) => {
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [type, setType] = useState<ModalType>(ModalType.NONE);

  const downloadNotes =
    (
      download: (args: {
        segments: { transactionId: string };
        body: string;
      }) => Promise<FileResponseType>
    ) =>
    (selectedIds: string[]): void => {
      setType(ModalType.NONE);
      message.loading({
        content: "Processing Notes",
        duration: 0,
        key: "print-notes",
      });
      download({
        segments: {
          transactionId,
        },
        body: JSON.stringify(selectedIds),
      })
        .then(({ url, filename }: FileResponseType) => {
          message.success({
            content: "File Ready to Download",
            key: "print-notes",
          });
          ConfirmDownload(filename, url);
          setCheckedList([]);
          setCheckAll(false);
        })
        .catch((error: string) => {
          message.error({
            content: valOrDefault("Error Downloading File!", error),
            key: "print-notes",
          });
        });
    };

  const onChange: onChangeType = (list, notes) => {
    const notesList = flatten(
      (valOrDefault([], notes) as NotesType[]).map((note) =>
        (valOrDefault([], note?.notes) as NoteDataType[]).map((curr) => curr.id)
      )
    );
    if (notes) {
      setCheckedList(list);
      setCheckAll(list?.length === notesList.length);
    }
  };

  const onCheckAllChange: onCheckAllChangeType = (e, notes) => {
    if (notes) {
      setCheckedList(
        e.target.checked
          ? flatten(
              (valOrDefault([], notes) as NotesType[]).map((note) =>
                (valOrDefault([], note?.notes) as NoteDataType[]).map(
                  (curr) => curr.id
                )
              )
            )
          : []
      );
      setCheckAll(e.target.checked);
    }
  };

  return (
    <>
      <div className="flex gap-1">
        <Tooltip title={"Click here to download in excel format"}>
          <Button
            type={"text"}
            icon={<i className="fas fa-download" />}
            className={`${ACTION_BUTTON_CSS} text-gray-400 ml-auto`}
            onClick={(): void => {
              setType(ModalType.EXCEL);
            }}
          />
        </Tooltip>
        <Tooltip title={"Click here to download in pdf format"}>
          <Button
            type={"text"}
            icon={<i className="fas fa-print" />}
            className={`${ACTION_BUTTON_CSS} text-gray-400 ml-auto`}
            onClick={(): void => {
              setType(ModalType.PDF);
            }}
          />
        </Tooltip>
      </div>
      <Modal
        width={"700px"}
        open={type !== ModalType.NONE}
        onCancel={(): void => {
          setType(ModalType.NONE);
          setCheckedList([]);
          setCheckAll(false);
        }}
        destroyOnClose={true}
        title={"Print Notes"}
        okButtonProps={{ className: "bg-primary hover:bg-hover" }}
        onOk={(): void =>
          downloadNotes(
            type === ModalType.PDF ? printNotes : downloadNotesAsExcel
          )(checkedList)
        }
      >
        <Checkbox
          checked={checkAll}
          onChange={(e): void => {
            onCheckAllChange(e, notes);
          }}
        >
          Select All
        </Checkbox>
        <div className={"flex flex-col mt-2 gap-y-1 divide-y divide-gray-200"}>
          <Checkbox.Group
            value={checkedList}
            onChange={(list): void => onChange(list as string[], notes)}
            className={"grid grid-cols-3 divide-y border"}
          >
            <div className={"text-lg p-1"}>Institution Name</div>
            <div className={"col-span-2 text-lg p-1 border-t-0"}>Note</div>
            {notes?.map(({ lenderDTO, notes }) => {
              return notes
                ?.filter(({ note }) => note.length > 0)
                .map(({ note, id }) => (
                  <Fragment key={id}>
                    <div className={"p-1 col-span-1"}>
                      <Checkbox value={id} className={"mr-2"} />
                      {lenderDTO.name}
                    </div>
                    <div className={"col-span-2 p-1"}>
                      <TextArea
                        className={
                          "select-none hide-scrollbar overflow-hidden text-black cursor-default"
                        }
                        disabled={true}
                        autoSize={{ maxRows: 2, minRows: 2 }}
                        value={note}
                      />
                    </div>
                  </Fragment>
                ));
            })}
          </Checkbox.Group>
        </div>
      </Modal>
    </>
  );
};

type PrintNotesType = {
  notes: NotesType[];
  transactionId: string;
};
type onChangeType = (list: string[], lenders: NotesType[]) => void;
type onCheckAllChangeType = (
  e: CheckboxChangeEvent,
  lenders: NotesType[]
) => void;

enum ModalType {
  PDF,
  EXCEL,
  NONE,
}
