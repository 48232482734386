import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { Button, Checkbox, message, Table, Tag, Tooltip } from "antd";
import {
  LenderType,
  PermissionDataType,
  TagType,
  UserType,
} from "../../../../utils/types";
import {
  getDealTeam,
  getUserTransactionModulePermissions,
  updateUserTransactionModulePermissions,
} from "../../../../services/services";
import { fetchWithIdType, ResponseType } from "../../../../utils/uiTypes";
import { TransactionContext } from "../../../../context/TransactionContext";
import { tableColumnHeader } from "../../../../utils/componentUtils";
import { UserContext } from "../../../../context/UserContext";
import { find, pathOr, propEq } from "ramda";
import { PermissionType } from "../../../../utils/enums";
import { useConfirmReload } from "../../../../customHooks/useConfirmReload";
import { Prompt } from "react-router";

export const ManagePermission: FC<DashboardTrackersTagsType> = () => {
  const [dealTeam, setDealTeam] = useState<UserType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [permissionsList, setPermissionsList] = useState<PermissionDataType[]>(
    []
  );
  const [isUpdated, setIsUpdated] = useState(false);

  const { belongToDealTeam, modules, transaction } =
    useContext(TransactionContext);
  const { user } = useContext(UserContext);

  useConfirmReload(!!isUpdated, [isUpdated]);

  const implicitTag = (status: string): ReactNode => {
    return (
      <Tooltip
        title={"Part of deal because of Deal Execution Admin Permission"}
        key={0}
        className={"uppercase"}
      >
        <Tag color={"cyan"} key={0} className={"uppercase"}>
          {status}
        </Tag>
      </Tooltip>
    );
  };
  const columns = [
    {
      title: tableColumnHeader("Name"),
      key: "userId",
      dataIndex: "userId",
      render: (transactionName: string, record: PermissionDataType) => {
        const filtered_user = dealTeam.find(
          ({ userId }) => userId === transactionName
        );
        const elementPermission = find(propEq("elementType", "DEAL_EXECUTION"))(
          filtered_user?.elementPermissions ?? []
        );
        const isImplicit = elementPermission
          ? pathOr(false, ["implicit"], elementPermission)
          : false;
        const adminAccessTag = isImplicit ? implicitTag("ADMIN ACCESS") : null;

        return (
          <>
            {filtered_user?.firstName} {filtered_user?.lastName} &nbsp;
            {adminAccessTag}
          </>
        );
      },
    },
    {
      title: tableColumnHeader("Communication"),
      key: "communication",
      dataIndex: "communication",
      align: "center" as const,
      render: (_: string, record: PermissionDataType) => (
        <Checkbox
          disabled={record.userId === user?.userId}
          checked={record.permissions.includes(
            PermissionType.VIEW_COMMUNICATION
          )}
          onChange={(e) =>
            onPermissionChange(
              record.userId,
              PermissionType.VIEW_COMMUNICATION,
              e.target.checked
            )
          }
        />
      ),
    },
    {
      title: tableColumnHeader("Termsheet"),
      key: "termsheet",
      dataIndex: "termsheet",
      align: "center" as const,
      render: (_: string, record: PermissionDataType) => (
        <Checkbox
          disabled={record.userId === user?.userId}
          checked={record.permissions.includes(PermissionType.VIEW_BID_FORM)}
          onChange={(e) =>
            onPermissionChange(
              record.userId,
              PermissionType.VIEW_BID_FORM,
              e.target.checked
            )
          }
        />
      ),
    },
    {
      title: tableColumnHeader("Dataroom"),
      key: "dataroom",
      align: "center" as const,
      dataIndex: "dataroom",
      render: (transactionName: string, record: PermissionDataType) => (
        <Checkbox
          disabled={record.userId === user?.userId}
          checked={record.permissions.includes(PermissionType.VIEW_DATA_ROOM)}
          onChange={(e) =>
            onPermissionChange(
              record.userId,
              PermissionType.VIEW_DATA_ROOM,
              e.target.checked
            )
          }
        />
      ),
    },
    {
      title: tableColumnHeader("Allocation"),
      key: "allocation",
      align: "center" as const,
      dataIndex: "allocation",
      render: (transactionName: string, record: PermissionDataType) => (
        <Checkbox
          disabled={record.userId === user?.userId}
          checked={record.permissions.includes(PermissionType.VIEW_ALLOCATION)}
          onChange={(e) =>
            onPermissionChange(
              record.userId,
              PermissionType.VIEW_ALLOCATION,
              e.target.checked
            )
          }
        />
      ),
    },
    {
      title: tableColumnHeader("Fees"),
      key: "fees",
      align: "center" as const,
      dataIndex: "fees",
      render: (transactionName: string, record: PermissionDataType) => (
        <Checkbox
          disabled={record.userId === user?.userId}
          checked={record.permissions.includes(PermissionType.VIEW_FEES)}
          onChange={(e) =>
            onPermissionChange(
              record.userId,
              PermissionType.VIEW_FEES,
              e.target.checked
            )
          }
        />
      ),
    },
    {
      title: tableColumnHeader("Notes"),
      key: "notes",
      dataIndex: "notes",
      align: "center" as const,
      render: (transactionName: string, record: PermissionDataType) => (
        <Checkbox
          disabled={record.userId === user?.userId}
          checked={record.permissions.includes(PermissionType.VIEW_NOTE)}
          onChange={(e) =>
            onPermissionChange(
              record.userId,
              PermissionType.VIEW_NOTE,
              e.target.checked
            )
          }
        />
      ),
    },
    {
      title: tableColumnHeader("Task List"),
      key: "taskList",
      align: "center" as const,
      dataIndex: "taskList",
      render: (transactionName: string, record: PermissionDataType) => (
        <Checkbox
          disabled={record.userId === user?.userId}
          checked={record.permissions.includes(PermissionType.VIEW_TASK)}
          onChange={(e) =>
            onPermissionChange(
              record.userId,
              PermissionType.VIEW_TASK,
              e.target.checked
            )
          }
        />
      ),
    },
    {
      title: tableColumnHeader("Deal Metrics"),
      key: "dealMetrics",
      align: "center" as const,
      dataIndex: "deal_metrics",
      render: (transactionName: string, record: PermissionDataType) => (
        <Checkbox
          disabled={record.userId === user?.userId}
          checked={record.permissions.includes(
            PermissionType.VIEW_DEAL_METRICS
          )}
          onChange={(e) =>
            onPermissionChange(
              record.userId,
              PermissionType.VIEW_DEAL_METRICS,
              e.target.checked
            )
          }
        />
      ),
    },
    {
      title: tableColumnHeader("Completions Memo"),
      key: "completionsMemo",
      align: "center" as const,
      dataIndex: "completions_memo",
      render: (transactionName: string, record: PermissionDataType) => (
        <Checkbox
          disabled={record.userId === user?.userId}
          checked={record.permissions.includes(
            PermissionType.VIEW_COMPLETION_MEMO
          )}
          onChange={(e) =>
            onPermissionChange(
              record.userId,
              PermissionType.VIEW_COMPLETION_MEMO,
              e.target.checked
            )
          }
        />
      ),
    },
  ];

  const fetchDealTeam: fetchWithIdType = (id) => {
    setLoading(true);
    getDealTeam({
      segments: {
        id,
      },
      params: {
        isTeamPage: true,
      },
    })
      .then(({ data = [] }: ResponseType<UserType[]>) => {
        setDealTeam(data);
        setLoading(false);
      })
      .catch((error: string) => {
        setLoading(false);

        message.error(error ? error : "Failed Fetching the Deal Team!");
      });
  };

  useEffect(() => {
    transaction && fetchDealTeam(transaction.peTransactionId);
    if (transaction?.peTransactionId) {
      getUserTransactionModulePermissions({
        segments: {
          id: transaction?.peTransactionId,
        },
      })
        .then(({ data }: ResponseType<PermissionDataType[]>) => {
          setPermissionsList(data);
        })
        .catch((error: string) => {
          message.error(error ? error : "Error fetching transaction details!");
        });
    }
  }, [transaction?.peTransactionId]);

  const onPermissionChange: changePermissionType = (userId, key, value) => {
    setIsUpdated(true);

    const index = permissionsList.findIndex(
      (x: PermissionDataType) => x.userId === userId
    );
    if (index !== -1) {
      let permissions = permissionsList[index]?.permissions ?? [];

      if (value) {
        permissions.push(key);
      } else {
        permissions = permissions.filter((item) => item !== key);
      }

      permissionsList[index].permissions = permissions;
      setPermissionsList([...permissionsList]);
    }
  };

  const updateUserPermission = () => {
    setLoading(true);
    updateUserTransactionModulePermissions({
      segments: {
        id: transaction?.peTransactionId,
      },
      body: JSON.stringify(permissionsList),
    })
      .then(({ data }: ResponseType<PermissionDataType[]>) => {
        setPermissionsList(data);
        setLoading(false);
        setIsUpdated(false);
        message.success("Permissions updated Successfully", 1);
      })
      .catch((error: string) => {
        setLoading(false);
        message.error(error ? error : "Error updating permission details!");
      });
  };

  const getColumns = () => {
    if (belongToDealTeam && modules) {
      const _modules: ModuleType = modules;
      return columns.filter(
        (item) => _modules[item.key] || item.key === "userId"
      );
    }
    return columns;
  };

  return (
    <div
      className={
        "relative mx-auto p-6 h-full max-h-full overflow-y-auto flex flex-col"
      }
    >
      <Prompt
        when={isUpdated}
        message={"Changes you made might not be saved!"}
      />
      <div className={"flex flex-col sm:flex-row mb-3 p-0 w-full"}>
        <p className={"text-xl font-medium mb-1"}>Manage Permissions</p>
        <Button
          className={
            "border-0 bg-primary hover:bg-hover text-white border-0 sm:ml-auto"
          }
          onClick={() => updateUserPermission()}
        >
          Update
        </Button>
      </div>

      <div className={"h-full"}>
        <Table
          loading={loading}
          dataSource={permissionsList}
          columns={getColumns()}
          pagination={false}
        />
      </div>
    </div>
  );
};

type DashboardTrackersTagsType = {
  transactionId: string;
  canEdit: boolean | null;
  loading: LoadingType;
  isFullScreen?: boolean;
  toggleMilestoneData: toggleMilestoneDataType;
  tags: TagType[];
  lenders: LenderType[];
};

type toggleMilestoneDataType = (lenderId: string, milestoneId: string) => void;

type changePermissionType = (
  userId: string,
  key: PermissionType,
  value: boolean
) => void;

type LoadingType = {
  [id: string]: Record<string, boolean>;
};
type ModuleType = {
  [key: string]: boolean;
};
