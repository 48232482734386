import React, { FC, useEffect, useState } from "react";
import {
  ElementObjectType,
  LenderType,
  VersionDataType,
  VersionType,
  WebFormType,
} from "../../../utils/types";
import { useParams } from "react-router";
import { CustomSpin } from "../../general/CustomSpin";
import {
  cloneVersion,
  cloneVersionOfflineLender,
  getVersionsByTransactionAndWebform,
} from "../../../services/services";
import { ResponseType, voidType } from "../../../utils/uiTypes";
import { TermsheetFooter } from "./TermsheetFooter";
import { message } from "antd";
import { assocPath, find, propEq } from "ramda";
import { TermsheetProvider } from "../../../context/TermsheetContext";
import { TermsheetBody } from ".//TermsheetBody";
import { ElementType, PETransactionCreatorType } from "../../../utils/enums";
import { valOrDefault } from "../../../utils/utils";
import {
  ColumnElementType,
  hasUnpublishedVersions,
} from "../../../utils/termsheet";
import { CreateVersionConfirmationModal } from "../modals/CreateVersionConfirmationModal";

export const TermsheetContent: FC<TermsheetType> = function ({
  transactionId,
  element,
  institutions = [],
  isAdmin,
  isNonAdmin,
  isInstitution,
  isOffline,
  transactionType,
  transactionCompany = "",
}) {
  const { webFormId } = useParams<ParamsType>();
  const [createVersionModal, setCreateVersionModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<LoadingType | null>(
    LoadingType.GET_VERSIONS
  );
  const [termsheetData, setTermsheetData] = useState<TermsheetDataType>({
    versions: {},
    currentVersion: null,
    elements: [],
    webFormId: null,
  });

  const onReset: voidType = () => {
    setLoading(LoadingType.GET_VERSIONS);
    setTermsheetData({
      webFormId: null,
      versions: {},
      currentVersion: null,
      elements: [],
    });
  };
  const getMaxVersion: getMaxVersionType = (versionData = {}) => {
    return Math.max(...Object.keys(versionData).map((o) => parseInt(o)));
  };

  const findInstitutionById: findInstitutionByIdType = (id) => {
    const institution: LenderType = find(propEq("id", id))(
      institutions
    ) as LenderType;
    return valOrDefault(id, institution?.name);
  };

  /** Get list of elements for a specific verson **/
  const getColumnElements: getColumnElementsType = (
    { lenderIds = [], respondStatus = {} },
    { elementType, elementId }
  ) => {
    switch (elementType) {
      case ElementType.PETRANSACTION:
        return isAdmin || isNonAdmin || isOffline
          ? [
              { id: elementId, label: "Deal Team Ask" },
              ...institutions
                .filter(({ id }) => lenderIds.includes(id) && respondStatus[id])
                .map(({ id, name }) => {
                  return { id, label: name };
                }),
            ]
          : [];
      case ElementType.LENDER:
        return isInstitution || isOffline
          ? [
              {
                id: transactionId,
                label: isOffline
                  ? `${transactionCompany} Ask`
                  : "Deal Team Ask",
              },
              {
                id: elementId,
                label: findInstitutionById(elementId),
              },
            ]
          : [];
      default:
        return [];
    }
  };

  const createVersionsObject: createVersionsObject = (
    versions,
    currentVersion,
    webFormId
  ) => {
    return {
      webFormId,
      versions,
      currentVersion,
      elements: getColumnElements(versions[currentVersion], element),
    };
  };

  /** Create a new version **/
  const createNewVersion: createNewVersionType = (id, webFormId, version) => {
    setLoading(LoadingType.CREATE_VERSION);
    if (isOffline && isInstitution) {
      cloneVersionOfflineLender({
        segments: {
          id,
          webFormId,
        },
      })
        .then(({ data }: ResponseType<VersionDataType>) => {
          message.success("Successfully created a new version!");
          setTermsheetData(
            createVersionsObject(data, getMaxVersion(data), webFormId)
          );
          setCreateVersionModal(false);
          setLoading(null);
        })
        .catch(() => {
          message.error(
            "Something went wrong. Unable to create a new  version!"
          );
          setLoading(null);
        });
    } else {
      cloneVersion({
        segments: {
          id,
          webFormId,
          version: version?.toString(),
        },
      })
        .then(({ data }: ResponseType<WebFormType>) => {
          message.success("Successfully created a new version!");
          setTermsheetData(
            createVersionsObject(
              data.versionData,
              getMaxVersion(data.versionData),
              webFormId
            )
          );
          setCreateVersionModal(false);
          setLoading(null);
        })
        .catch(() => {
          message.error(
            "Something went wrong. Unable to create a new  version!"
          );
          setLoading(null);
        });
    }
  };

  /** Get all available versions for this termsheet **/
  const getVersions: getVersionsType = (
    transactionId,
    webFormId,
    selectedVersion
  ) => {
    onReset();
    getVersionsByTransactionAndWebform({
      segments: {
        transactionId,
        webFormId,
      },
    })
      .then(({ data }: ResponseType<VersionDataType>) => {
        setTermsheetData(
          createVersionsObject(
            data,
            valOrDefault(getMaxVersion(data), selectedVersion),
            webFormId
          )
        );
      })
      .then(() => {
        setLoading(null);
      });
  };

  const onCreateNewVersion = () => {
    if (isOffline) {
      setCreateVersionModal(true);
    } else {
      createNewVersion(
        transactionId,
        webFormId,
        getMaxVersion(termsheetData.versions).toString()
      );
    }
  };

  useEffect(() => {
    getVersions(transactionId, webFormId);
  }, [transactionId, webFormId]);

  return (
    <TermsheetProvider
      transactionType={transactionType}
      transactionId={transactionId}
      webFormId={webFormId}
      isAdmin={isAdmin}
      isOffline={isOffline}
      isNonAdmin={isNonAdmin}
      isInstitution={isInstitution}
      element={element}
      institutions={institutions}
      onReloadVersions={getVersions}
    >
      <div className={"relative max-h-full h-full w-full bg-gray-100"}>
        <CustomSpin
          loading={loading === LoadingType.GET_VERSIONS}
          loadingText={"Loading Termsheet ..."}
        />
        <div className={"h-full max-h-full w-full overflow-y-hidden"}>
          {!loading &&
            webFormId === termsheetData.webFormId &&
            termsheetData.currentVersion !== null && (
              <TermsheetBody
                versions={termsheetData.versions}
                version={termsheetData.currentVersion}
                elements={termsheetData.elements}
                published={
                  isOffline
                    ? getMaxVersion(termsheetData.versions) !==
                      Number(termsheetData.currentVersion)
                    : termsheetData.versions[termsheetData.currentVersion]
                        .published
                }
                onCreateNewVersion={onCreateNewVersion}
              />
            )}
          <TermsheetFooter
            onVersionsChange={(versions, goToVersion) => {
              if (typeof goToVersion === "number" && !isNaN(goToVersion)) {
                setTermsheetData(
                  createVersionsObject(versions, goToVersion, webFormId)
                );
              } else {
                setTermsheetData((prev) =>
                  assocPath(["versions"], versions, prev)
                );
              }
            }}
            hasUnpublishedVersion={hasUnpublishedVersions(
              termsheetData.versions
            )}
            currentVersion={termsheetData.currentVersion}
            versions={termsheetData.versions}
            onVersionSelect={(currentVersion) => {
              setTermsheetData(
                createVersionsObject(
                  termsheetData.versions,
                  currentVersion,
                  webFormId
                )
              );
            }}
            onCreateNewVersion={onCreateNewVersion}
          />
          <CreateVersionConfirmationModal
            loading={loading === LoadingType.CREATE_VERSION}
            onConfirm={() => {
              createNewVersion(
                transactionId,
                webFormId,
                getMaxVersion(termsheetData.versions).toString()
              );
            }}
            open={createVersionModal}
            onCancel={() => setCreateVersionModal(false)}
          />
        </div>
      </div>
    </TermsheetProvider>
  );
};

enum LoadingType {
  CREATE_VERSION = "CREATE_VERSION",
  GET_VERSIONS = "GET_VERSIONS",
}
type ParamsType = {
  webFormId: string;
};

type TermsheetType = {
  transactionId: string;
  element: ElementObjectType;
  institutions: Array<LenderType>;
  isAdmin: boolean;
  isNonAdmin: boolean;
  isInstitution: boolean;
  isOffline: boolean;
  transactionType: PETransactionCreatorType;
  transactionCompany?: string;
};

type TermsheetDataType = {
  webFormId: string | null;
  versions: VersionDataType;
  currentVersion: number | null;
  elements: Array<ColumnElementType>;
};

type getMaxVersionType = (versionData?: VersionDataType) => number;
type findInstitutionByIdType = (id: string) => string;
type getColumnElementsType = (
  version: VersionType,
  element: ElementObjectType
) => Array<ColumnElementType>;
type createVersionsObject = (
  versions: VersionDataType,
  currentVersion: number,
  webFormId: string
) => TermsheetDataType;
type createNewVersionType = (
  transactionId: string,
  webFormId: string,
  version: string
) => void;
type getVersionsType = (
  transactionId: string,
  webFormId: string,
  selectedVersion?: string
) => void;
