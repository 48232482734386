import React, { FC, useState } from "react";
import {
  AllocationKeyType,
  CountryType,
  DebtType,
  IndustryType,
  PeTransactionFilter,
  TeamMemberType,
} from "../../utils/types";
import { Button, DatePicker, Form, Input, Modal, Segmented } from "antd";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import { MultipleSelect } from "../general/MultipleSelect";
import moment, { Moment } from "moment";
import {
  CalendarOutlined,
  FilterOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { transactionTagsArray } from "../../utils/transaction";
import { useLocation } from "react-router";

export const TransactionFiltersModal: FC<FiltersModalType> = ({
  onChange,
  selectedFilters,
  debtType = [],
  transactionTypes = [],
  investmentType = [],
  users = [],
  industries = [],
  loading,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { search } = useLocation();

  const onSubmit = ({
    timeStamp,
    ...values
  }: PeTransactionFilter & { timeStamp: [Moment, Moment] }): void => {
    onChange({
      ...values,
      // createdByMe: values.createdByMe,
      createdByMe: (values.createdByMe as unknown as string) === "true",
      fromTimestamp: timeStamp?.[0] ? timeStamp[0].valueOf() : null,
      toTimestamp: timeStamp?.[1] ? timeStamp[1].valueOf() : null,
    });
    setIsOpen(false);
  };

  return (
    <>
      <Button
        loading={loading}
        disabled={loading}
        className={`select-none cursor-pointer ${PRIMARY_BUTTON_STYLE} !font-normal`}
        onClick={(): void => setIsOpen(true)}
        icon={<FilterOutlined />}
      >
        Advanced Filters
      </Button>
      <Modal
        width={"600px"}
        open={isOpen}
        destroyOnClose
        title={"Transaction Search"}
        footer={null}
        bodyStyle={{ padding: 0 }}
        onCancel={(): void => setIsOpen(false)}
      >
        <Form
          initialValues={{
            ...selectedFilters,
            createdByMe: selectedFilters.createdByMe ? "true" : "false", // Convert to string
            timeStamp: [
              new URLSearchParams(search).get("fromTimestamp")
                ? moment(
                    Number(new URLSearchParams(search).get("fromTimestamp"))
                  )
                : null,
              new URLSearchParams(search).get("toTimestamp")
                ? moment(Number(new URLSearchParams(search).get("toTimestamp")))
                : null,
            ],
          }}
          onFinish={onSubmit}
          labelAlign={"right"}
          labelCol={{ flex: "150px" }}
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
        >
          <div className={"px-6 pt-5"}>
            <Form.Item
              name={"searchKey"}
              label={
                <div>
                  <SearchOutlined /> Title & Description
                </div>
              }
            >
              <Input
                defaultValue={selectedFilters.searchKey}
                placeholder={"Search by Title or Description"}
              />
            </Form.Item>

            <Form.Item
              name={"status"}
              label={
                <div>
                  <UserOutlined /> Status
                </div>
              }
            >
              <MultipleSelect
                label={"Status"}
                placeholder={"Select Status"}
                options={transactionTagsArray.map(({ value, label }) => ({
                  label,
                  value,
                  filterValue: label?.toString(),
                }))}
              />
            </Form.Item>

            <Form.Item
              name="timeStamp"
              label={
                <div>
                  <CalendarOutlined /> Created on
                </div>
              }
            >
              <DatePicker.RangePicker
                ranges={{
                  "Last 3 months": [moment().subtract(3, "months"), moment()],
                  "Last 6 months": [moment().subtract(6, "months"), moment()],
                  "Last 12 months": [moment().subtract(1, "year"), moment()],
                }}
                format={"DD MMMM YYYY"}
              />
            </Form.Item>

            <Form.Item
              name={"createdByMe"}
              label={
                <div>
                  <i className="fa-solid fa-check-double" /> Created by Me
                </div>
              }
            >
              <Segmented
                options={[
                  { label: "Yes", value: "true" }, // String value
                  { label: "No", value: "false" }, // String value
                ]}
              />
            </Form.Item>

            <Form.Item
              name={"debtType"}
              label={"Sponsor Debt Type"}
              className={"hidden"}
            >
              <MultipleSelect
                label={"Debt Type"}
                placeholder={"Select Debt Type"}
                options={debtType.map(({ id, name }) => ({
                  label: name,
                  value: id,
                  filterValue: name,
                }))}
              />
            </Form.Item>

            <Form.Item name={"transactionType"} label={"Transaction Type"}>
              <MultipleSelect
                label={"Transaction Type"}
                placeholder={"Select Transaction Type"}
                options={transactionTypes.map(({ id, keyName }) => ({
                  label: keyName,
                  value: id,
                  filterValue: keyName,
                }))}
              />
            </Form.Item>

            <Form.Item name={"investmentType"} label={"Investment Type"}>
              <MultipleSelect
                label={"Investment Type"}
                placeholder={"Select Investment Type"}
                options={investmentType.map(({ id, keyName }) => ({
                  label: keyName,
                  value: id,
                  filterValue: keyName,
                }))}
              />
            </Form.Item>

            <Form.Item
              name={"industries"}
              label={"Industries"}
              className={"hidden"}
            >
              <MultipleSelect
                label={"Industries"}
                placeholder={"Select Industries"}
                options={industries.map(({ id, name }) => ({
                  label: name,
                  value: id,
                  filterValue: name,
                }))}
              />
            </Form.Item>

            <Form.Item
              name={"createdBy"}
              label={
                <div>
                  <UserOutlined /> Deal Created By
                </div>
              }
            >
              <MultipleSelect
                label={"Created By"}
                placeholder={"Select Users"}
                options={users.map(({ userDTO }) => ({
                  label: (
                    <div className={"flex flex-row w-full justify-between"}>
                      <div>
                        {userDTO.firstName} {userDTO.lastName}
                      </div>
                      <div className={"text-xxs text-muted"}>
                        {userDTO.email}
                      </div>
                    </div>
                  ),
                  value: userDTO.userId,
                  filterValue: `${userDTO.firstName} ${userDTO.lastName} ${userDTO.email}`,
                }))}
              />
            </Form.Item>

            {/*<Form.Item name={"region"} label={"Region"}>*/}
            {/*  <MultipleSelect*/}
            {/*    label={"Regions"}*/}
            {/*    placeholder={"Select Regions"}*/}
            {/*    options={countries.map(({ id, name }) => ({*/}
            {/*      label: name,*/}
            {/*      value: id,*/}
            {/*      filterValue: name,*/}
            {/*    }))}*/}
            {/*  />*/}
            {/*</Form.Item>*/}
          </div>
          <div
            className={
              "border-t flex flex-row items-center justify-end gap-2 px-6 py-3"
            }
          >
            <Button className={PRIMARY_BUTTON_STYLE} htmlType={"submit"}>
              Update Filter
            </Button>
            <Button onClick={(): void => setIsOpen(false)}>Cancel</Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

type FiltersModalType = {
  transactionTypes: AllocationKeyType[];
  investmentType: AllocationKeyType[];
  debtType: DebtType[];
  industries: IndustryType[];
  users: TeamMemberType[];
  countries: CountryType[];
  selectedFilters: PeTransactionFilter;
  onChange: (filters: PeTransactionFilter) => void;
  loading?: boolean;
};
