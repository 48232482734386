import React, { FC, ReactNode, useContext, useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Tooltip,
} from "antd";
import Table from "../../../images/icons/TableIcon.svg";
import NewTable from "../../../images/icons/NewTableIcon.svg";
import { InfoCircleOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserContext";
import { PortfolioType } from "../../../utils/types";
import { ResponseType, voidType } from "../../../utils/uiTypes";
import { createPortfolio } from "../../../services/services";
import { getPortfolioType } from "../../../utils/portfolio";

export const CreatePortfolioModal: FC<CreatePortfolioModalType> = ({
  onSuccess,
  visible,
  setVisible,
  portfolios,
}: CreatePortfolioModalType) => {
  const [form] = Form.useForm();
  const { user } = useContext(UserContext);
  const [step, setStep] = useState<Steps>(Steps.NAME);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [portfolioCompanyName, setPortfolioCompanyName] = useState<string>();
  const [loading, setLoading] = useState(false);

  const onConfirm: voidType = () => {
    const { copyFromPortfolioId } = form.getFieldsValue();
    setLoading(true);
    createPortfolio({
      body: JSON.stringify({
        companyId: user?.companyId,
        portfolioCompanyName,
        copyFromPortfolioId,
        portfolioType: getPortfolioType(user?.companyDTO?.companyType),
      }),
    })
      .then(({ data }: ResponseType<PortfolioType>) => {
        onSuccess(data);
        setLoading(false);
      })
      .catch((err: string): void => {
        console.error(err);
        message.error("Error creating portfolio.", 5);
        setLoading(false);
      });
  };

  const onNext: voidType = () => {
    const { portfolioCompanyName } = form.getFieldsValue();
    if (
      step === Steps.NAME &&
      !!portfolioCompanyName &&
      portfolioCompanyName.replace(/ /g, "").length > 0 &&
      form.getFieldError("portfolioCompanyName").length === 0
    ) {
      setStep(Steps.CREATE_OPTIONS);
      setPortfolioCompanyName(portfolioCompanyName);
    } else if (step === Steps.CREATE_OPTIONS) setStep(Steps.CHOOSE_TEMPLATE);
  };

  const onBack: voidType = () =>
    setStep(step === Steps.CHOOSE_TEMPLATE ? Steps.CREATE_OPTIONS : Steps.NAME);

  const footerButtonRender: ReactNode = [
    step !== "NAME" && (
      <Button
        key={"back"}
        disabled={loading}
        onClick={(): void => onBack()}
        className={"bg-primary text-white border-0 float-left"}
      >
        Go Back
      </Button>
    ),
    step !== "CHOOSE_TEMPLATE" && (step === "NAME" || isTemplate) && (
      <Button
        disabled={loading}
        form={"form"}
        key={"next"}
        htmlType={"submit"}
        loading={loading}
        onClick={(): void => onNext()}
        className={"bg-primary text-white border-0"}
      >
        Next
      </Button>
    ),
    step !== "NAME" && (step === "CHOOSE_TEMPLATE" || !isTemplate) && (
      <Button
        disabled={loading}
        form={"form"}
        key={"submit"}
        htmlType={"submit"}
        loading={loading}
        onClick={onConfirm}
        className={"bg-primary text-white border-0"}
      >
        Submit
      </Button>
    ),
  ];

  return (
    <Modal
      open={visible}
      className={"px-8"}
      onCancel={(): void => {
        setVisible(false);
        form.setFieldsValue({
          portfolioCompanyName: "",
          isTemplate: false,
          copyFromPortfolioId: undefined,
        });
      }}
      destroyOnClose={true}
      title={
        step === "CHOOSE_TEMPLATE"
          ? "Select company template you want to duplicate"
          : "Add a new portfolio company"
      }
      footer={footerButtonRender}
      centered={true}
      cancelButtonProps={{ disabled: loading }}
    >
      <Form
        form={form}
        className={"mt-4"}
        name="form"
        layout={"vertical"}
        initialValues={{
          portfolioCompanyName: "",
          isTemplate: false,
          copyFromPortfolioId: undefined,
        }}
      >
        {step === "NAME" && (
          <Form.Item
            name={"portfolioCompanyName"}
            rules={[
              {
                required: true,
                message: "Please enter company name.",
                validateTrigger: "onChange",
              },
            ]}
          >
            <Input placeholder={"Enter company name"} />
          </Form.Item>
        )}
        {step === "CREATE_OPTIONS" && (
          <>
            <Form.Item name={"isTemplate"}>
              <Radio.Group
                className={"md:flex text-center"}
                onChange={(val): void => setIsTemplate(val.target.value)}
              >
                <Radio
                  checked={!isTemplate}
                  className={"mx-auto flex-1 px-1 mb-4"}
                  value={false}
                >
                  <img
                    className={"mb-4 mx-auto"}
                    alt={"Default Template"}
                    src={NewTable}
                  />
                  Start with default template
                  <Tooltip
                    className={"ml-1"}
                    title={
                      "Create a new company data set with standard settings."
                    }
                    trigger={"click"}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Radio>
                <Radio
                  checked={isTemplate}
                  className={"mx-auto flex-1 px-1"}
                  value={true}
                >
                  <img
                    className={"mb-4 mx-auto "}
                    alt={"Existing Template"}
                    src={Table}
                  />
                  Reuse template from your portfolio
                  <Tooltip
                    className={"ml-1"}
                    title={
                      "Duplicate a data set that you have customized for another portfolio company."
                    }
                    trigger={"click"}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}
        {step === "CHOOSE_TEMPLATE" && (
          <>
            <Form.Item
              name={"copyFromPortfolioId"}
              rules={[
                {
                  required: true,
                  message: "Please select company.",
                  validateTrigger: "onChange",
                },
              ]}
            >
              <Select
                notFoundContent={"No Portfolios Available"}
                filterOption={true}
                showSearch={true}
                optionFilterProp={"children"}
                placeholder={"Search/select"}
              >
                {portfolios &&
                  portfolios.map((port) => (
                    <Select.Option key={port.id} value={port.id}>
                      {port.portfolioCompanyName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

type CreatePortfolioModalType = {
  onSuccess: (a: PortfolioType) => void;
  visible: boolean;
  setVisible: (a: boolean) => void;
  portfolios?: PortfolioType[];
};

enum Steps {
  NAME = "NAME",
  CREATE_OPTIONS = "CREATE_OPTIONS",
  CHOOSE_TEMPLATE = "CHOOSE_TEMPLATE",
}
