import {
  Modal,
  Form,
  Input,
  Button,
  message,
  AutoComplete,
  Select,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { PRIMARY_BUTTON_STYLE } from "../../utils/cssConfigs";
import {
  formItemRequiredRule,
  namePatternRule,
  parsePhoneNumber,
} from "../../utils/formUtils";
import { externalAttendeesType } from "./Notes";
import { CRMCompanyMappingType, TeamMemberType } from "../../utils/types";
import { CustomAvatar } from "../general/CustomAvatar";
import {
  getCompanyTeamById,
  getCrmCompanyProfile,
} from "../../services/services";
import { useParams } from "react-router";
import { fetchWithIdType, ResponseType } from "../../utils/uiTypes";
import { PhoneNumberInput } from "../general/PhoneNumberInput";

export const CreateNewUserModal = ({
  visible,
  setVisible,
  onAdd,
  initialValues,
}: any) => {
  const [form] = Form.useForm();
  const { id }: paramsType = useParams();
  const [loading, setLoading] = useState(false);
  const [teamRecord, setTeamRecord] = useState<TeamMemberType[]>([]);
  const [selectedUserId, setSelectedUserid] = useState<string>("");
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const getCompanyTeam = (id: string) => {
    getCompanyTeamById({
      segments: {
        id: id,
      },
    })
      .then(({ data = [] }: ResponseType<TeamMemberType[]>) => {
        setTeamRecord(data);
      })
      .catch((error: string) => {
        message.error(error ? error : "Error fetching the users list!");
      });
  };

  const fetchCompanyProfile: fetchWithIdType = (id) => {
    setLoading(true);
    getCrmCompanyProfile({
      segments: { id },
    })
      .then(({ data }: ResponseType<CRMCompanyMappingType>) => {
        setLoading(false);
        getCompanyTeam(data.companyDTO.id);
      })
      .catch((error: string) => {
        console.error(error);
        message.error(error);
        setLoading(false);
      });
  };

  const handleFinish = (values: externalAttendeesType) => {
    values.phoneNumber =
      values.phoneNumber && parsePhoneNumber(values.phoneNumber);
    values.userId = selectedUserId;
    console.log(values);

    onAdd(values);
  };

  useEffect(() => {
    fetchCompanyProfile(id);
  }, [0]);

  const setSelection = (val: string, record: any) => {
    console.log(val, record);
    setSelectedUserid(val);
    const selectedUser = teamRecord.find((user) => user.userDTO.userId === val);
    if (selectedUser) {
      form.setFieldsValue({
        ...selectedUser.userDTO,
        userId: selectedUser?.userDTO?.userId,
      });
    }
  };

  return (
    <Modal
      open={visible}
      title={"Map Contact"}
      footer={[
        <Button
          key="close"
          onClick={() => {
            form.resetFields();
            setVisible(false);
          }}
        >
          Close
        </Button>,
        <Button
          key="create"
          className={PRIMARY_BUTTON_STYLE}
          onClick={() => form.submit()}
        >
          Save Contact
        </Button>,
      ]}
      onCancel={() => {
        form.resetFields();
        setVisible(false);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          handleFinish({ ...values });
        }}
      >
        <Form.Item
          name={"firstName"}
          label={"First Name"}
          rules={[formItemRequiredRule, namePatternRule]}
        >
          <Select
            placeholder={"First Name"}
            showSearch={true}
            onSelect={(val: string, record): void => {
              setSelection(val, record);
            }}
            filterOption={(a, b): boolean => {
              return b?.title?.toLowerCase()?.indexOf(a?.toLowerCase()) >= 0;
            }}
            dropdownStyle={{
              overflow: "visible",
            }}
          >
            {teamRecord?.map(({ userDTO }: TeamMemberType) => (
              <AutoComplete.Option
                key={userDTO.userId}
                firstName={userDTO?.firstName}
                lastName={userDTO?.lastName}
                email={userDTO?.email}
                phone={userDTO?.phoneNumber}
                title={
                  userDTO?.firstName +
                  " " +
                  userDTO?.lastName +
                  " " +
                  userDTO?.email
                }
                value={userDTO.userId}
              >
                <div className={"flex flex-row items-start py-1"}>
                  <CustomAvatar
                    data={userDTO?.firstName[0] + userDTO?.lastName[0]}
                    color={userDTO?.firstName[0]}
                    size={"small"}
                  />
                  <div className={"leading-none flex flex-col"}>
                    {userDTO?.firstName} {userDTO?.lastName}
                    <span className={"text-secondary text-xs"}>
                      {userDTO?.email}
                    </span>
                  </div>
                </div>
              </AutoComplete.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={"Last Name"}
          name={"lastName"}
          rules={[formItemRequiredRule, namePatternRule]}
        >
          <Input disabled placeholder={"Last Name"} />
        </Form.Item>
        <Form.Item
          label={"Email"}
          name={"email"}
          rules={[formItemRequiredRule]}
        >
          <Input type={"email"} disabled placeholder={"Email"} />
        </Form.Item>
        <Form.Item
          label={"Phone Number"}
          name={"phoneNumber"}
          initialValue={{
            short: "GB",
            code: "44",
          }}
        >
          <PhoneNumberInput disabled placeholder={"Phone Number"} />
        </Form.Item>
        <Form.Item
          label="Notes"
          name="note"
          rules={[{ required: true, message: "Please enter the notes!" }]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

type paramsType = {
  id: string;
};
