import React, { FC } from "react";
import { TwoFactorAuthSetup } from "./security/TwoFactorAuthSetup";
import { TokenKeysInfo } from "./security/TokenKeysInfo";

export const Security: FC = () => {
  return (
    <div
      className={"relative max-h-full w-full h-full bg-gray-100 flex flex-col"}
    >
      <div className={"mb-5 flex flex-row items-center p-6 pb-0"}>
        <span className="text-2xl font-medium">Account Security</span>
      </div>
      <TwoFactorAuthSetup />
      <TokenKeysInfo />
    </div>
  );
};
