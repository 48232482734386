import React, { FC, useCallback, useEffect, useState } from "react";
import { MileStoneType, TagType } from "../../../utils/types";
import { ACTION_BUTTON_CSS } from "../../../utils/cssConfigs";
import { Button, Checkbox, Tooltip } from "antd";
import { ConfirmDelete } from "../../../utils/confirmationModals";
import { convertDateToFormat } from "../../../utils/moment";
import { isNil } from "ramda";
import { ColorPicker } from "../../general/ColorPicker";
import { ElementTag } from "./ElementTag";
import { MenuOutlined } from "@ant-design/icons";
import { PermissionType } from "../../../utils/enums";
import { TrackersTypes } from "../../../utils/uiTypes";
import { DraggableElement } from "../../general/DraggableElement";
import { tableColumnHeader } from "../../../utils/componentUtils";
const R = require("ramda");

export const SortableTags: FC<SortableTagsType> = ({
  tags,
  onSort,
  onEdit,
  onDelete,
  onChangeDefault,
  isAdmin,
}) => {
  const [sortedTags, setSortedTags] = useState<TagType[]>([]);

  const onDrag: onDragType = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setSortedTags((prevCards: TagType[]) => [
        ...R.move(dragIndex, hoverIndex, prevCards).map(
          (val: MileStoneType, index: number) => ({
            ...val,
            rank: index.toString(),
          })
        ),
      ]);
    },
    []
  );

  useEffect(() => {
    setSortedTags(tags);
  }, [tags]);

  return (
    <div className={"shadow border"}>
      <div className={"bg-white grid grid-cols-12"}>
        <div className={"col-span-1 p-4"}>{tableColumnHeader("")}</div>
        <div className={"col-span-3 p-4"}>{tableColumnHeader("Title")}</div>
        <div className={"col-span-3 p-4"}>
          {tableColumnHeader("Description")}
        </div>
        <div className={"col-span-3 p-4"}>
          {tableColumnHeader("Smart Actions")}
        </div>
        <div className={"p-4 text-center"}>{tableColumnHeader("Default")}</div>
        <div className={"col-span-1 p-4 text-right"}>
          {isAdmin && tableColumnHeader("Action")}
        </div>
      </div>
      <div className={"max-h-full overflow-y-scroll"}>
        {sortedTags.map((tag, index) => (
          <DraggableElement
            key={index}
            id={tag.id}
            index={index}
            onDragging={onDrag}
            canDrag={true}
            accept={TrackersTypes.TAGS}
            onDrop={(tag): void => {
              onSort(sortedTags, tag.id);
            }}
          >
            {(ref, drag, drop) => {
              isAdmin && drag(drop(ref));
              return (
                <div
                  key={tag.id}
                  className={"grid grid-cols-12 border-t group items-center"}
                >
                  <div className={"col-span-1 p-4"}>
                    {isAdmin && (
                      <Button
                        ref={drag}
                        icon={<MenuOutlined />}
                        type={"text"}
                        className={
                          "bg-white group-hover:bg-blue-200 cursor-grab shadow"
                        }
                        size={"small"}
                      />
                    )}
                  </div>
                  <div className={"col-span-3 p-4 flex items-center gap-x-2"}>
                    <ElementTag tag={tag} />
                  </div>
                  <div
                    className={
                      "col-span-3 p-4 empty:after:content-['No_Description'] empty:text-muted"
                    }
                  >
                    {tag.description}
                  </div>
                  <div className={"col-span-3 p-4 "}>
                    {tag.smartActions?.join(", ")}
                  </div>
                  <div className={"flex items-center justify-center"}>
                    <Checkbox
                      disabled={!isAdmin}
                      onChange={() =>
                        !tag.default && onChangeDefault(tag.id, index)
                      }
                      checked={tag.default}
                    />
                  </div>
                  {isAdmin && (
                    <div
                      className={
                        "col-span-1 p-2.5 relative flex flex-row flex-row-reverse gap-x-2"
                      }
                    >
                      <Button
                        type={"text"}
                        icon={<i className="fas fa-ellipsis-h" />}
                        className={
                          "absolute right-0 border-0 flex items-center px-2 text-gray-900 block opacity-50 group-hover:opacity-0 mr-2"
                        }
                      />
                      <Button
                        type={"text"}
                        icon={<i className="fas fa-trash-alt" />}
                        className={`${ACTION_BUTTON_CSS} hover:text-primary opacity-0 group-hover:opacity-100`}
                        onClick={(): void => {
                          ConfirmDelete(
                            "Are you sure you want to delete this tag?  This is irreversible and you will lose the data in transactions in which you have utilized this tag to mark the status of institutions.  Furthermore, this will also not show up in your analytics activity reports.",
                            () => onDelete(index, tag.id)
                          );
                        }}
                      />
                      <Button
                        type={"text"}
                        icon={<i className="fas fa-pencil-alt" />}
                        className={`${ACTION_BUTTON_CSS} hover:text-primary opacity-0 group-hover:opacity-100`}
                        onClick={(): void => onEdit(tag)}
                      />
                    </div>
                  )}
                </div>
              );
            }}
          </DraggableElement>
        ))}
      </div>
    </div>
  );
};

type SortableTagsType = {
  tags: TagType[];
  onSort: (tags: TagType[], id: string) => void;
  loading: boolean;
  onEdit: (tag: TagType) => void;
  onDelete: (index: number, id: string) => void;
  onChangeDefault: (id: string, index: number) => void;
  isAdmin: boolean;
};
type onDragType = (oldIndex: number, newIndex: number) => void;
