import React, { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import { Dropdown, Input, Menu, Tag } from "antd";
import {
  CheckOutlined,
  CloseCircleFilled,
  DownOutlined,
} from "@ant-design/icons";
import { indexOf, remove } from "ramda";
import { getPopupContainerForSelect } from "../../utils/container";

/**
 * Multiple Select Component for Filters to show number of selected items from the dropdown instead of tags
 * @param defaultValue - is for default selected values on load
 * @param value - is for the current selected value
 * @param placeholder - is for the placeholder to show on the input
 * @param onChange - is to send back the values to the parent on every change
 * @param disabled - is to disable or enable the select component
 * @param options - is for the list of items to be shown for selection
 * @param className - is for the className of the Component
 * @param label - is for the label to be shown for the Component
 * @constructor
 * @returns FC
 * @type MultipleSelectType
 */
export const MultipleSelect: FC<MultipleSelectType> = ({
  defaultValue = [],
  value,
  placeholder,
  onChange,
  disabled,
  options = [],
  className = "",
  label = "",
}) => {
  const [values, setValues] = useState<string[]>(defaultValue);
  const [visible, setVisible] = useState(false);
  const [filterInput, setFilterInput] = useState<string>("");

  /**
   * Update the value if the current State
   * @param value - is for the selected value to either add or remove from the selected list
   */
  const addRemoveValue: addRemoveValueType = (value) => {
    const index = indexOf(value, values);
    if (index === -1) {
      setValues([...values, value]);
      onChange && onChange([...values, value]);
    } else {
      const newValues = remove(index, 1, values);
      setValues(newValues);
      onChange && onChange(newValues);
    }
    setFilterInput("");
  };

  /**
   * Handle the visibility of the dropdown
   * @param flag
   */
  const handleVisibleChange = (flag: boolean): void => {
    setVisible(flag);
  };

  useEffect(() => {
    setValues(value ?? []);
  }, [value]);

  return (
    <div
      className={`relative overflow-visible group flex-1 bg-white border border-solid border-zinc-300 rounded-sm hover:border-primary cursor-pointer ${
        visible && "border-primary ring-1 ring-primary/50"
      } ${className}`}
    >
      <Dropdown
        getPopupContainer={getPopupContainerForSelect}
        disabled={disabled}
        placement={"bottomRight"}
        menu={{
          getPopupContainer: (node): HTMLElement =>
            node.parentElement ?? document.body,
          className: "max-h-56 overflow-y-auto",
          items: options
            .filter(({ filterValue, label }) => {
              return (typeof label === "string" ? label : filterValue)
                ?.toLowerCase()
                ?.includes(filterInput?.toLowerCase());
            })
            .map((item) => ({
              key: item.value,
              className: "cursor-pointer p-0",
              label: (
                <div
                  className={`px-2 py-1.5 flex flex-row items-center justify-between w-full ${
                    values.includes(item.value) && "bg-primary/10 font-semibold"
                  }`}
                  onClick={(): void => addRemoveValue(item.value)}
                >
                  <div className={"w-full"}>{item.label || item.value}</div>
                  {values.includes(item.value) && (
                    <CheckOutlined className={"text-primary"} />
                  )}
                </div>
              ),
            })),
        }}
        trigger={["click"]}
        onOpenChange={handleVisibleChange}
        open={visible}
        className={`max-w-full flex-initial flex-row items-center z-[100]`}
        overlayClassName={"max-w-full"}
        openClassName={"max-w-full"}
      >
        <div
          className={"flex flex-row items-center pr-2"}
          onClick={(): void => {
            if (!disabled) {
              setVisible(!visible);
            }
          }}
        >
          <div className={"w-full flex flex-row items-center"}>
            <Input
              disabled={disabled}
              value={filterInput}
              onChange={(e): void => {
                setFilterInput(e.target.value);
              }}
              className={`m-0 b-0 ${
                values.length > 0 && "placeholder-black focus:placeholder-muted"
              }`}
              bordered={false}
              placeholder={values.length > 0 ? label : placeholder}
            />
            {values.length > 0 && (
              <Tag.CheckableTag className={"bg-primary-500 ml-auto"} checked>
                {values.length}
              </Tag.CheckableTag>
            )}
          </div>
          {values.length > 0 ? (
            <CloseCircleFilled
              className={"text-xs text-gray-300 hover:text-gray-400 mt-1"}
              onClick={(): void => {
                if (!disabled) {
                  setValues([]);
                  onChange && onChange([]);
                  setFilterInput("");
                }
              }}
            />
          ) : (
            <DownOutlined />
          )}
        </div>
      </Dropdown>
    </div>
  );
};

export type MultiSelectOptionType = {
  label: ReactNode;
  value: string;
  filterValue?: string;
  subtitle?: ReactNode;
  groupLabel?: string;
};
type MultipleSelectType = {
  className?: string;
  defaultValue?: string[];
  value?: string[];
  placeholder?: string;
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  options: Array<MultiSelectOptionType>;
  label?: string;
};
type OptionType = { label: ReactNode; value: string; filterValue?: string };
type getMenuType = (options: Array<OptionType>) => ReactElement;
type addRemoveValueType = (value: string) => void;
