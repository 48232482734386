import { Button, Form, InputNumber, Modal, Select } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { isNil } from "ramda";
import { ALPHABETS } from "../../../utils/newTermsheet";
import React, { FC, useContext, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { FileType } from "../../../utils/types";
import { PRIMARY_BUTTON_STYLE } from "../../../utils/cssConfigs";
import { isWordDocument } from "../../../utils/utils";
import { TermsheetContext } from "../../../context/TermsheetContext";

export const KeyTermsUpload: FC<KeyTermsUploadType> = ({
  onSubmit,
  visible,
  onClose,
  portfolioId,
  webformId,
  loading,
}) => {
  const [form] = useForm();
  const [uploadedFile, setUploadedFile] = useState<FileType | null>(null);
  const { isInstitution } = useContext(TermsheetContext);
  const isWordDoc =
    uploadedFile &&
    isWordDocument(uploadedFile.fileName, uploadedFile.contentType);

  return (
    <Modal
      confirmLoading={loading}
      title={
        <span className={"text-xs text-secondary uppercase"}>
          {"Key Terms Upload"}
        </span>
      }
      open={visible}
      destroyOnClose={true}
      onOk={(): void => {
        form.submit();
      }}
      width={700}
      onCancel={onClose}
      okButtonProps={{ className: PRIMARY_BUTTON_STYLE }}
      footer={
        <div className={"flex justify-between"}>
          <div>
            {isWordDoc ? (
              <Button
                target={"_blank"}
                type={"link"}
                href={
                  isInstitution
                    ? "https://app.arcade.software/share/peq9RkVrXqExSJoRqF6r"
                    : "https://app.arcade.software/share/H5koCCqu37jSpS3c2VbV"
                }
              >
                Tutorial
              </Button>
            ) : (
              <></>
            )}
          </div>
          <div className={"flex flex-row gap-1"}>
            <Button disabled={loading} key="back" onClick={onClose}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              className={PRIMARY_BUTTON_STYLE}
              loading={loading}
              onClick={() => {
                form.submit();
              }}
            >
              Update
            </Button>
          </div>
        </div>
      }
    >
      <div>
        <div className={"flex flex-row gap-4"}>
          <div>
            <li>
              Please select an Excel or Word file that has only one worksheet
              with two columns corresponding to “Bid Metrics” and “Deal Team
              Ask”.
            </li>
            <li>Values are uploaded without any formatting.</li>

            <li>Maximum rows of a table that can be uploaded are 400</li>
          </div>
        </div>
        <div className={"w-full h-full p-5"}>
          <Dragger
            className={"group hover:border-primary"}
            type={"select"}
            action={"/api/rest/media/uploadFile/1"}
            listType={"text"}
            showUploadList={true}
            accept={".xlsx,.xls,.docx,.docs,.doc"}
            maxCount={1}
            onChange={({ file }): void => {
              if (file && file?.status === "done") {
                setUploadedFile(file.response);
              }
            }}
            onRemove={(): void => {
              setUploadedFile(null);
              form.resetFields();
            }}
          >
            <i className="fa fa-upload text-2xl text-secondary group-hover:text-primary"></i>
            <div className="text-xs text-secondary group-hover:text-primary">
              Click or drag file to this area to upload
            </div>
          </Dragger>
        </div>
        {uploadedFile && (
          <div>
            <Form
              form={form}
              layout={"vertical"}
              onFinish={(o): void => {
                onSubmit({
                  ...o,
                  fileDTO: uploadedFile,
                  portfolioId,
                  webformId,
                });
              }}
              className={"flex flex-col gap-y-3"}
            >
              <div className={"grid grid-cols-2 gap-4"}>
                <Form.Item
                  tooltip={
                    "Write the row number from where you want to start uploading the values. Number should be less than 200."
                  }
                  name={"startRow"}
                  label={"Starting Row Number"}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    {
                      validator: (_, startRow) => {
                        return startRow <= 0
                          ? Promise.reject()
                          : Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber
                    type={"number"}
                    className={"w-full"}
                    precision={0}
                    max={5000}
                    placeholder="Enter start row"
                  />
                </Form.Item>
                <Form.Item
                  tooltip={
                    "Write the row number where you want to stop uploading the values. It must be greater than the starting row."
                  }
                  name={"endRow"}
                  label={"Ending Row Number"}
                  rules={[
                    {
                      validator: (_, endRow) => {
                        const startRow = form.getFieldValue("startRow");
                        if (!endRow && endRow !== 0) {
                          return Promise.resolve();
                        }
                        if (
                          endRow <= 0 ||
                          endRow <= startRow ||
                          endRow > startRow + 400
                        ) {
                          let errorMessage = "";
                          if (endRow <= 0) {
                            errorMessage =
                              "Ending row number has to be positive";
                          } else if (endRow <= startRow) {
                            errorMessage =
                              "End row must be greater than the start row.";
                          } else if (endRow > startRow + 400) {
                            errorMessage =
                              "You can only select 400 records at a time.";
                          }
                          return Promise.reject(new Error(errorMessage));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber
                    type={"number"}
                    className={"w-full"}
                    precision={0}
                    max={5000}
                    placeholder="Enter end row (Optional)"
                  />
                </Form.Item>
                <Form.Item
                  tooltip={
                    "Enter the letter of the column you wish to upload as Bid Metrics. Only up to column letter Z is allowed."
                  }
                  className={"mb-0"}
                  name={"bidMetricColNum"}
                  label={"Category Column"}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger): HTMLElement =>
                      trigger.parentElement
                    }
                  >
                    {ALPHABETS.map((c, i) => {
                      return (
                        <Select.Option key={c} value={i + 1}>
                          {isWordDoc ? i + 1 : c}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  tooltip={
                    "Enter the letter of the column you wish to upload as corresponding Deal Team Ask. Only up to column letter Z is allowed."
                  }
                  className={"mb-0"}
                  name={"columnNumber"}
                  label={"Description Column"}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger): HTMLElement =>
                      trigger.parentElement
                    }
                  >
                    {ALPHABETS.map((c, i) => {
                      return (
                        <Select.Option key={c} value={i + 1}>
                          {isWordDoc ? i + 1 : c}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                {isWordDoc && (
                  <Form.Item
                    tooltip={
                      "Specify the table number you wish to upload. By default we are uploading biggest table in doc.Note that tables are typically separated by free-form text in your document."
                    }
                    className="mt-5"
                    name={"tableNumber"}
                    label={"Table Number"}
                    required={false}
                    rules={[
                      {
                        validator: (_, tableNumber) => {
                          if (
                            tableNumber === null ||
                            tableNumber === undefined
                          ) {
                            return Promise.resolve();
                          }
                          return tableNumber <= 0
                            ? Promise.reject(
                                new Error(
                                  "Table Number should be greater than zero"
                                )
                              )
                            : Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      precision={0}
                      placeholder={"Table Number (optional)"}
                      className={"w-full"}
                    />
                  </Form.Item>
                )}
              </div>
            </Form>
            {isWordDoc && (
              <div>
                <li>
                  For Word file, you can choose to upload a table from the file
                </li>
              </div>
            )}
            {!isWordDoc && (
              <div>
                <li>
                  If the Excel file has multiple worksheets, the system will
                  take the values from the first sheet in the file
                </li>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export type KeyTermsUploadData = {
  webformId: string;
  portfolioId: string;
  fileDTO: FileType;
  startRow: string;
  endRow: string;
  bidMetricColNum: string;
  columnNumber: string;
};

type KeyTermsUploadType = {
  onSubmit: (data: KeyTermsUploadData) => void;
  visible: boolean;
  onClose: () => void;
  loading: boolean;
  portfolioId: string;
  webformId: string;
};
